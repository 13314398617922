import createService from './createService'
import validateCreateServiceStep from './validateCreateServiceStep'
import showServiceDetails from './showServiceDetails'
import updateServiceDetails from './updateServiceDetails'
import updateServiceSettings from './updateServiceSettings'
import updateServiceProvision from './updateServiceProvision'
import updateServiceAssignedForms from './updateServiceAssignedForms'

export default [
  createService,
  validateCreateServiceStep,
  showServiceDetails,
  updateServiceDetails,
  updateServiceSettings,
  updateServiceProvision,
  updateServiceAssignedForms,
]
