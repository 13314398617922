import React from 'react'
import PropTypes from 'prop-types'
import { sortBy, prop } from 'ramda'

import isPresent from 'utils/isPresent'
import BookingPriceComponent from './component'

class BookingPrice extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
  }

  get sortedTransactions() {
    const {
      booking: { transactions },
    } = this.props
    if (!isPresent(transactions)) return []

    return sortBy(prop('paymentType'), transactions)
  }

  render() {
    return <BookingPriceComponent {...this.props} transactions={this.sortedTransactions} />
  }
}

export default BookingPrice
