import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const createChat = makeFormSubmitAction(types.CREATE_CHAT)
export const updateChat = makeFormSubmitAction(types.UPDATE_CHAT)
export const addChatMembers = makeFormSubmitAction(types.ADD_CHAT_MEMBERS)

export const createMessage = (chatId, text, messageUuid = null) => ({
  type: types.CREATE_MESSAGE,
  chatId,
  text,
  messageUuid,
})

export const createDirectChat = chatUserId => ({
  type: types.CREATE_DIRECT_CHAT,
  chatUserId,
})

export const createMessageOptimistic = ({ id, chatId, text, attachmentIds }) => ({
  type: types.CREATE_MESSAGE_OPTIMISTIC,
  id,
  chatId,
  text,
  attachmentIds,
})

export const addSendingMessage = id => ({
  type: types.ADD_SENDING_MESSAGE_ID,
  id,
})

export const removeSendingMessage = id => ({
  type: types.REMOVE_SENDING_MESSAGE_ID,
  id,
})

export const addResendingMessage = id => ({
  type: types.ADD_RESENDING_MESSAGE_ID,
  id,
})

export const removeResendingMessage = id => ({
  type: types.REMOVE_RESENDING_MESSAGE_ID,
  id,
})

export const createAttachmentUpload = (chatId, fileUpload) => ({
  type: types.CREATE_ATTACHMENT_UPLOAD,
  chatId,
  fileUpload,
})

export const uploadAttachment = (uploadId, file, meta) => ({
  type: types.UPLOAD_ATTACHMENT,
  uploadId,
  file,
  meta,
})

export const updateAttachmentUpload = (uploadId, file, meta) => ({
  type: types.UPDATE_ATTACHMENT_UPLOAD,
  uploadId,
  file,
  meta,
})

export const cancelAttachmentUpload = uploadIds => ({
  type: types.CANCEL_ATTACHMENT_UPLOAD,
  uploadIds,
})

export const setUploadLoadingStatus = (uploadId, isLoading) => ({
  type: types.SET_UPLOAD_LOADING_STATUS,
  uploadId,
  isLoading,
})

export const removeUploadLoadingStatus = uploadIds => ({
  type: types.REMOVE_UPLOAD_LOADING_STATUS,
  uploadIds,
})

export const linkUploadToAttachment = (uploadId, attachmentId) => ({
  type: types.LINK_UPLOAD_TO_ATTACHMENT,
  uploadId,
  attachmentId,
})

export const unlinkUploadToAttachment = uploadIds => ({
  type: types.UNLINK_UPLOAD_TO_ATTACHMENT,
  uploadIds,
})

export const resetUploadToAttachmentLinks = () => ({
  type: types.RESET_UPLOAD_TO_ATTACHMENT_LINKS,
})

export const setUploadProgress = (uploadId, progress) => ({
  type: types.SET_UPLOAD_PROGRESS,
  uploadId,
  progress,
})

export const removeUploadProgress = uploadIds => ({
  type: types.REMOVE_UPLOAD_PROGRESS,
  uploadIds,
})

export const addUploadError = uploadId => ({
  type: types.ADD_UPLOAD_ERROR,
  uploadId,
})

export const removeUploadError = uploadId => ({
  type: types.REMOVE_UPLOAD_ERROR,
  uploadId,
})

export const updateMessage = (messageId, text) => ({
  type: types.UPDATE_MESSAGE,
  messageId,
  text,
})

export const setEditingMessage = message => ({
  type: types.SET_EDITING_MESSAGE,
  message,
})

export const setHasVoiceMessage = state => ({
  type: types.SET_HAS_VOICE_MESSAGE,
  state,
})

export const removeMessage = messageId => ({
  type: types.REMOVE_MESSAGE,
  messageId,
})

export const removeMessageSuccess = messageId => ({
  type: types.REMOVE_MESSAGE_SUCCESS,
  messageId,
})

export const receiveMessages = messages => ({
  type: types.RECEIVE_MESSAGES,
  messages,
})

export const receiveChatData = data => ({
  type: types.RECEIVE_CHAT_DATA,
  data,
})

export const receiveChatMessages = messages => ({
  type: types.RECEIVE_CHAT_MESSAGES,
  messages,
})

export const setMessages = messageIds => ({
  type: types.SET_MESSAGES,
  messageIds,
})

export const flushMessages = () => ({
  type: types.FLUSH_MESSAGES,
})

export const fetchMessages = (chatId, pageParams = {}, options = {}) => ({
  type: types.FETCH_MESSAGES,
  chatId,
  pageParams,
  ...options,
})

export const markMessagesAsRead = (chatId, readCursor) => ({
  type: types.MARK_MESSAGES_AS_READ,
  chatId,
  readCursor,
})

export const fetchAttachments = (chatId, pageParams = {}, filter) => ({
  type: types.FETCH_ATTACHMENTS,
  chatId,
  pageParams,
  filter,
})

export const addAttachments = attachmentsIds => ({
  type: types.ADD_ATTACHMENTS,
  attachmentsIds,
})

export const resetAttachments = () => ({
  type: types.RESET_ATTACHMENTS,
})

export const removeAttachment = attachmentId => ({
  type: types.REMOVE_ATTACHMENT,
  attachmentId,
})

export const removeAttachmentSuccess = attachmentId => ({
  type: types.REMOVE_ATTACHMENT_SUCCESS,
  attachmentId,
})

export const setOnline = () => ({
  type: types.SET_ONLINE,
})

export const setOffline = () => ({
  type: types.SET_OFFLINE,
})

export const setUnreadIndicatorVisibility = isUnreadIndicatorVisible => ({
  type: types.SET_UNREAD_INDICATOR_VISIBILITY,
  isUnreadIndicatorVisible,
})

export const markAllMessagesAsRead = chatId => ({
  type: types.MARK_ALL_MESSAGES_AS_READ,
  chatId,
})

export const setChat = chatId => ({
  type: types.SET_CHAT,
  chatId,
})

export const dragFiles = files => ({
  type: types.DRAG_FILES,
  files,
})

export const getChat = chatId => ({
  type: types.GET_CHAT,
  chatId,
})

export const leaveChat = chatId => ({
  type: types.LEAVE_CHAT,
  chatId,
})

export const deleteChat = chatId => ({
  type: types.DELETE_CHAT,
  chatId,
})

export const getChatMembers = chatId => ({
  type: types.GET_CHAT_MEMBERS,
  chatId,
})

export const setChatMemberIds = chatMemberIds => ({
  type: types.SET_CHAT_MEMBER_IDS,
  chatMemberIds,
})

export const removeChatMember = (chat, memberId) => ({
  type: types.REMOVE_CHAT_MEMBER,
  chat,
  memberId,
})

export const receiveRemovedChat = data => ({
  type: types.RECEIVE_REMOVED_CHAT,
  data,
})

export const receiveLeftChat = data => ({
  type: types.RECEIVE_LEFT_CHAT,
  data,
})

export const receiveRemovedMember = data => ({
  type: types.RECEIVE_REMOVED_MEMBER,
  data,
})

export const receiveChatUnreadMessagesCount = data => ({
  type: types.RECEIVE_CHAT_UNREAD_MESSAGES_COUNT,
  data,
})

export const sendMessage = clientId => ({
  type: types.SEND_MESSAGE,
  clientId,
})
