import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import { CATEGORY_STATUSES } from 'lib/constants/serviceCategories'
import * as types from './types'

export const getServiceCategories = (status = CATEGORY_STATUSES.all) => ({
  type: types.GET_SERVICE_CATEGORIES,
  status,
})

export const searchServiceCategories = () => ({
  type: types.SEARCH_SERVICE_CATEGORIES,
})

export const setBlankState = isBlankState => ({
  type: types.SET_SERVICE_CATEGORIES_BLANK_STATE,
  isBlankState,
})

export const setServiceCategories = serviceCategoriesIds => ({
  type: types.SET_SERVICE_CATEGORIES,
  serviceCategoriesIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_SERVICE_CATEGORIES_PAGE,
  pageNumber,
})

export const setSortOrder = sortKey => ({
  type: types.SET_SERVICE_CATEGORIES_SORT_ORDER,
  sortKey,
})

export const setFilterParams = filterParams => ({
  type: types.SET_SERVICE_CATEGORIES_FILTER_PARAMS,
  filterParams,
})

export const filterServiceCategories = filters => ({
  type: types.SET_SERVICE_CATEGORIES_FILTER,
  filters,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const deactivateServiceCategories = serviceCategoriesIds => ({
  type: types.DEACTIVATE_SERVICE_CATEGORIES,
  serviceCategoriesIds,
})

export const activateServiceCategories = serviceCategoriesIds => ({
  type: types.ACTIVATE_SERVICE_CATEGORIES,
  serviceCategoriesIds,
})

export const removeServiceCategories = serviceCategoriesIds => ({
  type: types.REMOVE_SERVICE_CATEGORIES,
  serviceCategoriesIds,
})

export const setSelectedServiceCategories = serviceCategoriesIds => ({
  type: types.SET_SERVICE_CATEGORIES_SELECTED,
  serviceCategoriesIds,
})

export const filterServicesByCategories = () => ({
  type: types.FILTER_SERVICES_BY_CATEGORIES,
})

export const createServiceCategory = makeFormSubmitAction(types.CREATE_SERVICE_CATEGORY)
export const editServiceCategory = makeFormSubmitAction(types.EDIT_SERVICE_CATEGORY)
