import normalize from 'json-api-normalizer'
import { trim } from 'ramda'
import { createLogic } from 'redux-logic'

import { usersAccountProfileRoute } from 'lib/apiRoutes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { employeeProfileRoute } from 'lib/routes'
import { setTimezone } from 'state/concepts/bookings/actions'
import { UPDATE_EMPLOYEE_PROFILE } from 'state/concepts/employee/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'

const updateEmployeeProfileOperation = createLogic({
  type: UPDATE_EMPLOYEE_PROFILE,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const formData = new FormData()

    try {
      formData.append('first_name', trim(values.firstName))
      formData.append('last_name', trim(values.lastName))
      formData.append('position', values.position)
      formData.append('about', values.about)
      formData.append('timezone', values.timezone)

      if (values.avatar === null) {
        formData.append('remove_avatar', true)
      } else if (values.avatar instanceof Blob) {
        formData.append('avatar', values.avatar, values.avatar.name)
      }

      const { data } = await httpClient.put(usersAccountProfileRoute, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })

      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(setTimezone(values.timezone))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.employeeProfileUpdated' },
        }),
      )
      redirect({ href: employeeProfileRoute, workspace: currentWorkspaceCodeSelector(getState()) })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateEmployeeProfileOperation
