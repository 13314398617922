import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const LivePreview = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M91.235 409.599c-24.672 0-37.818-2.538-51.516-9.864-12.805-6.849-23.008-17.051-29.855-29.856C2.538 356.181 0 343.036 0 318.364V91.234c0-24.672 2.538-37.818 9.864-51.516C16.713 26.913 26.915 16.71 39.719 9.863 53.417 2.537 66.562-.001 91.235-.001h329.53c24.673 0 37.817 2.538 51.515 9.864 12.805 6.849 23.007 17.051 29.856 29.855C509.462 53.416 512 66.561 512 91.234v227.13c0 24.673-2.538 37.817-9.864 51.515-6.849 12.805-17.051 23.007-29.856 29.856-13.698 7.326-26.843 9.864-51.515 9.864H332.8v51.2H384c14.139 0 25.601 11.462 25.601 25.601S398.139 512.001 384 512.001H127.999c-14.138 0-25.6-11.462-25.6-25.601s11.462-25.601 25.6-25.601h51.2v-51.2H91.234zM425.103 51.224l-346.022.252c-7.82.462-11.49 1.544-15.215 3.536-3.883 2.077-6.776 4.971-8.852 8.852-2.561 4.788-3.617 9.487-3.788 23.032v235.805l.252 7.817c.462 7.82 1.544 11.49 3.536 15.215 2.077 3.882 4.971 6.776 8.852 8.853 4.788 2.561 9.487 3.617 23.032 3.788l346.022-.252c7.82-.462 11.49-1.545 15.215-3.536 3.882-2.077 6.776-4.971 8.853-8.853 2.561-4.788 3.617-9.487 3.788-23.032V86.896l-.252-7.817c-.462-7.82-1.545-11.49-3.536-15.215-2.077-3.883-4.971-6.776-8.853-8.852-4.788-2.561-9.487-3.617-23.033-3.788z" />
  </svg>
)

LivePreview.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

LivePreview.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default LivePreview
