const refundsFeature = {
  'refundsFeature.alert.text': 'To enable refunds you have to enable accepting payments first.',
  'refundsFeature.alert.action': 'Accept payments',
  'refundsFeature.subtitle': '<span>in</span> Payments',
  'refundsFeature.description': 'Set up a clear refund policy and automate your refunds.',
  'refundsFeature.overview': 'Refunds overview',
  'refundsFeature.makeItEasy': 'Streamline your refunds, making sure you always meet your obligations to _(clients).',
  'refundsFeature.policyEnforcement': 'Policy enforcement:',
  'refundsFeature.policyEnforcement.stepOne':
    'Set up a clear refund policy for all bookings within your workspace, protecting yourself and your team from late cancellations and no-shows.',
  'refundsFeature.policyEnforcement.stepTwo': 'Let ExpertBox automatically process refunds in case of cancellation.',
  'refundsFeature.flexibility': 'Flexibility:',
  'refundsFeature.flexibility.stepOne': 'Refund any payment previously made by a _(client).',
  'refundsFeature.paymentTracking': 'Payment tracking:',
  'refundsFeature.paymentTracking.stepOne':
    'Once a refund is issued, both you and your _(client) will receive an email with the transaction status and a credit note.',
  'refundsFeature.paymentTracking.stepTwo':
    'Easily access information about refunds and their statuses from your payment history.',
  'refundsFeature.helpCenter':
    'To learn more, read our help center article on <link>refunding payments and _(appointments)</link>.',
  'refundsFeature.manageSettings': 'Set refund policy',
  'refundsFeature.upgradePlan': 'Upgrade plan',
  'refundsFeature.availableOnlyForAdvanced': 'Refunds is a Premium feature.',
  'refundsFeature.disableRefunds': 'Disable refunds feature?',
  'refundsFeature.disableRefunds.bodyText':
    'Your _(clients) won’t get refunds for the cancelled _(appointments) anymore.',
}

export default refundsFeature
