import { path } from 'ramda'
import build from 'redux-object'
import { createSelector } from '@reduxjs/toolkit'

import { loadingSelector, dataSelector } from 'state/data/selectors'
import { getPaymentEndpoint, getPaymentCheckoutEndpoint, sendPaymentEndpoint } from './endpoints'

export const paymentIdSelector = path(['payment', 'paymentId'])

export const paymentSelector = createSelector(paymentIdSelector, dataSelector, (id, data) =>
  id ? build(data, 'transaction', id) : null,
)

export const paymentLoadingSelector = (state, paymentId) =>
  loadingSelector(state, getPaymentEndpoint(paymentId).endpoint)

export const paymentCheckoutLoadingSelector = (state, paymentId) =>
  loadingSelector(state, getPaymentCheckoutEndpoint(paymentId).endpoint)

export const paymentResendLoadingSelector = (state, paymentId) =>
  loadingSelector(state, sendPaymentEndpoint(paymentId).endpoint)
