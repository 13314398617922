const namespace = 'clientContacts'

export const CREATE_CLIENT_CONTACT = `${namespace}/CREATE_CLIENT_CONTACT`
export const UPDATE_CLIENT_CONTACT = `${namespace}/UPDATE_CLIENT_CONTACT`
export const FETCH_CLIENT_CONTACT = `${namespace}/FETCH_CLIENT_CONTACT`
export const FETCH_CLIENT_CONTACTS = `${namespace}/FETCH_CLIENT_CONTACTS`
export const ADD_CLIENT_CONTACTS_IDS = `${namespace}/ADD_CLIENT_CONTACTS_IDS`
export const RESET_CLIENT_CONTACTS = `${namespace}/RESET_CLIENT_CONTACTS`
export const DELETE_CLIENT_CONTACT = `${namespace}/DELETE_CLIENT_CONTACT`
export const REMOVE_CLIENT_CONTACT = `${namespace}/REMOVE_CLIENT_CONTACT`
export const SEND_DELETE_CONFIRMATION_CODE = `${namespace}/SEND_DELETE_CONFIRMATION_CODE`
export const DELETE_CLIENT_CONTACT_OWN_ACCOUNT = `${namespace}/DELETE_CLIENT_CONTACT_OWN_ACCOUNT`
