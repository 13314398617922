import React from 'react'
import PropTypes from 'prop-types'

const Agreements = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={className}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M102.4 0C74.387 0 51.2 23.187 51.2 51.2v409.6c0 28.014 23.187 51.2 51.2 51.2h307.2c28.014 0 51.2-23.186 51.2-51.2V153.6L307.2 0H102.4zm0 51.2h179.2v128h128v281.6H102.4V51.2zm114.1 153.6c-23.07 0-41.515 14.889-50.75 30.3s-12.691 31.816-12.15 47.401c.808 23.086 10.007 44.505 21.75 64.2-7.584 3.541-15.927 11.699-21.75 11.699v51.2c21.409 0 40.198-8.568 57.5-19.799 13.733 11.202 29.047 19.799 48.1 19.799 26.243 0 43.555-14.743 55.8-27.899 1.825 3.748 1.554 7.324 4.649 10.949 8.642 10.115 23.659 16.95 38.75 16.95v-76.649-.151a25.609 25.609 0 00-7.498-18.102 25.609 25.609 0 00-18.102-7.498c-15.353 0-26.503 8.63-33.4 15.55s-11.655 13.696-16.35 19.399c-9.39 11.41-14.369 16.251-23.85 16.251-.913 0-5.726-4.913-7.85-5.849 16.729-20.913 29.756-44.265 30.25-70.451v-.048c.284-15.593-3.988-31.248-13.55-46.5s-27.689-30.75-51.55-30.75zm0 51.2c2.738 0 4.528.973 8.15 6.75s5.799 15.716 5.75 18.399c-.125 6.17-8.996 17.236-14.25 27.1-4.091-9.759-11.081-19.85-11.35-27.55-.175-5.048 1.835-14.269 4.85-19.3S213.253 256 216.5 256z" />
  </svg>
)

Agreements.defaultProps = {
  size: 24,
  color: '#b1bac9',
  className: '',
  dataCy: 'agreements-icon',
}

Agreements.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Agreements
