import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { mergeDeepRight } from 'ramda'

import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { CONNECT_STRIPE } from 'state/concepts/paymentSettings/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { connectStripeEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { updateStripePos } from 'state/concepts/paymentSettings/actions'
import { STRIPE_POS_STATUSES } from 'lib/constants/stripePos'
import { isConnectStripePosSelector, paymentAccountSelector } from 'state/concepts/paymentSettings/selectors'
import updateDataHelper from 'utils/updateDataHelper'

const connectStripeOperation = createLogic({
  type: CONNECT_STRIPE,
  latest: true,

  async process({ httpClient, action: { state, code }, getState }, dispatch, done) {
    const { url, endpoint } = connectStripeEndpoint
    const connectStripePos = isConnectStripePosSelector(getState())
    const { id } = paymentAccountSelector(getState())

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, { state, code })
      const updatedResponse = updateDataHelper(getState().data, 'paymentAccount', id, {
        relationships: {
          stripeAccount: { data: { id: data.data.id, type: 'stripeAccount' } },
        },
      })
      const response = normalize(data)

      dispatch(
        dataApiSuccess({
          endpoint,
          response: mergeDeepRight(updatedResponse, response),
        }),
      )
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.stripeWasConnected' },
        }),
      )

      connectStripePos && dispatch(updateStripePos(STRIPE_POS_STATUSES.enabled))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default connectStripeOperation
