import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import Button from 'views/shared/Button'
import ArrowDown from 'views/shared/icons/ArrowDown'
import VideoO from 'views/shared/icons/VideoO'

const JoinButton = ({ joinUrl, toggleJoinLink, showJoinLink }) => {
  if (!isPresent(joinUrl)) {
    return null
  }

  return (
    <div className="d-flex gap-16">
      <div className="w-20">
        <VideoO size={18} className="mt-12" color="#6E798D" />
      </div>
      <div>
        <a href={joinUrl} target="_blank" rel="noopener noreferrer">
          <Button text={{ id: 'calendarEvents.joinVideoCall' }} size="medium" />
        </a>
        <div className="mt-8">
          <div className="d-flex">{showJoinLink && <span className="brake-word">{joinUrl}</span>}</div>
          <button className="in-blue-600" onClick={toggleJoinLink}>
            <FormattedMessage id={showJoinLink ? 'shared.showLess' : 'shared.showDetails'} />
            <ArrowDown className="in-blue-600 ml-8" size={8} />
          </button>
        </div>
      </div>
    </div>
  )
}

JoinButton.defaultProps = {
  joinUrl: null,
  toggleJoinLink: undefined,
  showJoinLink: false,
}

JoinButton.propTypes = {
  joinUrl: PropTypes.string,
  toggleJoinLink: PropTypes.func,
  showJoinLink: PropTypes.bool,
}

export default JoinButton
