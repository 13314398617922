import { createLogic } from 'redux-logic'

import { receiveData } from 'state/concepts/webSockets/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { dataDelete } from 'state/data/actions'
import { PAYPAL_ACCOUNT_TYPES } from 'lib/constants/paymentSettings'
import { setIsConnectPayPal } from 'state/concepts/alert/actions'
import { RECEIVE_PAYPAL_ACCOUNT } from '../types'
import { payPalAccountSelector } from '../selectors'

const receivePayPalAccountOperation = createLogic({
  type: RECEIVE_PAYPAL_ACCOUNT,
  latest: true,

  process({ action: { data }, getState }, dispatch, done) {
    const isCreation = data.meta.type === PAYPAL_ACCOUNT_TYPES.created
    if (isCreation) {
      dispatch(receiveData(data))
    } else {
      dispatch(hideModal())
      dispatch(dataDelete({ kind: 'paypalAccount', ids: [payPalAccountSelector(getState()).id] }))
    }
    dispatch(setIsConnectPayPal(false))
    dispatch(
      showNotification({
        messageObject: { id: `notifications.${isCreation ? 'paypalWasConnected' : 'paypalWasDisconnected'}` },
      }),
    )
    done()
  },
})

export default receivePayPalAccountOperation
