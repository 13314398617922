const switchBillingCycle = {
  'switchBillingCycle.monthly': 'Monthly',
  'switchBillingCycle.annual': 'Annual',
  'switchBillingCycle.perUserMonth': 'per user/month',
  'switchBillingCycle.monthly.switch': 'Switch to annual',
  'switchBillingCycle.annual.switch': 'Switch to monthly',
  'switchBillingCycle.monthly.switch?': 'Switch to annual billing?',
  'switchBillingCycle.fromMonthly.keepInMind':
    'Keep in mind: Once you switch to the annual plan, it cannot be canceled. You can go back to monthly subscription in 12 months’ time.',
  'switchBillingCycle.fromMonthly.switchAndSave':
    'Switch to annual subscription and save {discount}% on your subscription fee for the next 12 months!',
  'switchBillingCycle.fromMonthly.howItWorks': `Here's how it works: You'll be charged for the first month of your annual subscription on {paymentDate}. Starting then, you'll be billed monthly on the same date for the following month's services. The total cost will depend on your plan details, including seats, storage, and additional features.`,
  'switchBillingCycle.annual.switch?': 'Switch to monthly billing?',
  'switchBillingCycle.fromAnnual.wantToSave?':
    'Want to save {discount}% on your subscription fee? Switch back to annual anytime!',
  'switchBillingCycle.fromAnnual.planIsActiveUntil':
    'Your annual subscription is active until {annualPaymentDate}. Starting from that date you will be billed monthly on the same date. The full cost will depend on your plan details.',
  'switchBillingCycle.fromAnnual.youCanCancel':
    'If you change your mind, you can cancel anytime before {annualPaymentDate}.',
  'switchBillingCycle.cancelSwitchToMonthly.cancelRequest': 'Cancel request',
  'switchBillingCycle.cancelSwitchToMonthly.annualIsActive':
    'Your annual {currentPlanName} subscription will be active until {annualPaymentDate}. Starting from that date you will switch to monthly {plannedPlanName}.',
  'switchBillingCycle.cancelSwitchToMonthly.confirmModal.title': 'Cancel request?',
  'switchBillingCycle.cancelSwitchToMonthly.confirmModal.body':
    'Your annual subscription will be automatically renewed after the end of current commitment',
  'switchBillingCycle.cancelSwitchToMonthly.confirmModal.submit': 'Cancel request',
}

export default switchBillingCycle
