const groupVideoCallsFeature = {
  'groupVideoCallsFeature.title': 'ExpertBox group video calls',
  'groupVideoCallsFeature.subtitle': '<span>in</span> Video calls',
  'groupVideoCallsFeature.description': 'Have high-quality video meetings with _(clients) inside your workspace.',
  'groupVideoCallsFeature.overview': 'ExpertBox group video calls overview',
  'groupVideoCallsFeature.subheader':
    'Connect with your _(clients) in a personal way with high-quality video and perfect sound!',
  'groupVideoCallsFeature.invite': 'Invite up to 12 people to join a video call.',
  'groupVideoCallsFeature.getNotified': 'Get notified when a participant replies to your invitation.',
  'groupVideoCallsFeature.enrich': 'Enrich the meeting experience by sharing your screen.',
  'groupVideoCallsFeature.send': 'Send instant messages in the in-call chat.',
  'groupVideoCallsFeature.secondSubheader':
    'Everyone invited to the _(appointment) will get a link to the video call, which will become available 15 minutes before the scheduled start time.',
  'groupVideoCallsFeature.toLearnMores':
    'To learn more, read our help center article on <link>holding an ExpertBox video call</link>.',
  'groupVideoCallsFeature.disableModalTitle': 'Disable group ExpertBox video calls feature?',
  'groupVideoCallsFeature.disableModalAlertText': 'This won’t affect any of the scheduled _(appointments).',
  'groupVideoCallsFeature.disableModalWarning': `You won't be able to have ExpertBox group video calls inside your workspace anymore.`,
  'groupVideoCallsFeature.alertText':
    'To enable ExpertBox group video calls, you need to start using Accept video calls feature on ExpertBox.',
  'groupVideoCallsFeature.alertButtonText': 'Enable Accept video call',
}

export default groupVideoCallsFeature
