import { uniq, without } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const clientFormsIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_CLIENT_FORMS_IDS:
      return uniq([...state, ...action.ids])
    case types.RESET_CLIENT_FORMS:
      return []
    case types.REMOVE_CLIENT_FORM:
      return without([action.id], state)
    default:
      return state
  }
}

export default combineReducers({
  clientFormsIds,
})
