import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import dayjs from 'lib/dayjs'
import { dateTimeFromJSDate } from 'utils/dateTime'
import TimePickerFieldComponent from './component'

class TimePickerField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
      name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape({}),
      touched: PropTypes.shape({}),
      setFieldTouched: PropTypes.func,
    }).isRequired,
    mainWrapperClass: PropTypes.string,
  }

  static defaultProps = {
    mainWrapperClass: null,
  }

  state = {
    isOpen: false,
    defaultValue: dayjs(),
  }

  handleOpenChange = open => {
    const {
      field,
      form: { setFieldTouched },
    } = this.props

    if (!open) setFieldTouched(field.name, true, false)
    this.setState({ isOpen: open })
  }

  handleChange = time => {
    const {
      field,
      form: { setFieldTouched },
    } = this.props
    const date = time ? dateTimeFromJSDate(time.toDate()) : null

    if (!time) setFieldTouched(field.name, true, false)
    this.setState({ defaultValue: time })
    field.onChange({ target: { name: field.name, value: date } })
  }

  componentDidMount() {
    const {
      field: { value },
    } = this.props

    this.setState({ defaultValue: value ? dayjs(value) : null })
  }

  componentDidUpdate(prevProps) {
    const {
      field: { value },
    } = this.props

    if (prevProps.field.value !== value) {
      this.setState({ defaultValue: value ? dayjs(value) : null })
    }
  }

  render() {
    const { isOpen, defaultValue } = this.state
    const { mainWrapperClass } = this.props

    return (
      <TimePickerFieldComponent
        defaultValue={defaultValue}
        isOpen={isOpen}
        mainWrapperClass={mainWrapperClass}
        onChange={this.handleChange}
        onOpenChange={this.handleOpenChange}
        {...this.props}
      />
    )
  }
}

export default injectIntl(TimePickerField)
