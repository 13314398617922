import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { removeClientContact } from '../actions'
import { deleteClientContactEndpoint } from '../endpoints'
import { DELETE_CLIENT_CONTACT } from '../types'

const deleteClientContactOperation = createLogic({
  type: DELETE_CLIENT_CONTACT,
  latest: true,

  async process({ action: { contactId, feedId }, httpClient }, dispatch, done) {
    const { url, endpoint } = deleteClientContactEndpoint(contactId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeClientContact(contactId))
      feedId && dispatch(removeClientFeedIds([feedId]))
      dispatch(dataDelete({ kind: 'clientContact', ids: [contactId] }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.contactWasDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteClientContactOperation
