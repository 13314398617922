import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { pageCountSelector, currentPageSelector } from 'state/data/selectors'
import Spinner from 'views/shared/icons/Spinner'

class Loader extends React.Component {
  static propTypes = {
    loadMoreAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    spinnerProps: PropTypes.shape(),
    loaderProps: PropTypes.shape(),
    filter: PropTypes.shape(),
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
    spinnerProps: {},
    loaderProps: {},
    filter: undefined,
  }

  handleLoadMore = () => {
    const { loadMoreAction, currentPage, pageCount, filter } = this.props

    if (pageCount >= currentPage + 1) {
      loadMoreAction({ page: currentPage + 1, filter })
    }
  }

  render() {
    const { isLoading, spinnerProps, loaderProps } = this.props
    if (isLoading !== false) {
      return <Spinner {...spinnerProps} />
    }

    return <Waypoint onEnter={this.handleLoadMore} {...loaderProps} />
  }
}

const mapStateToProps = (state, { endpoint }) => ({
  pageCount: pageCountSelector(state, endpoint),
  currentPage: currentPageSelector(state, endpoint),
})

export { Loader as LoaderContainer }
export default connect(mapStateToProps)(Loader)
