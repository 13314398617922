import { Field, Formik } from 'formik'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { SIGNATURE_TYPE_OPTIONS, SIGNATURE_TYPES } from 'lib/constants/eSignature'
import { MAX_SIGNATURE_LENGTH } from 'lib/constants/etherpadDocuments'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { signatureDisclosureRoute } from 'lib/routes'
import signDocumentSchema from 'lib/yupLocalised/schemas/signDocumentSchema'
import { signEtherpadDocument } from 'state/concepts/etherpadDocuments/actions'
import { formattedShortDate, formattedTime } from 'utils/dateTime'
import Button from 'views/shared/Button'
import CheckboxField from 'views/shared/CheckboxField'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import Link from 'views/shared/Link'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import { useSelector } from 'react-redux'
import { currentSignatureDisclosureAcceptedAtSelector } from 'state/concepts/session/selectors'
import DrawSignature from './DrawSignature'
import ESignatureDropzone from './ESignatureDropzone'

const SignDocumentModal = ({ onClose, document, isSignerClient, clientId }) => {
  const intl = useIntl()
  const defaultNameLocale = isSignerClient
    ? 'etherpadDocuments.signModal.name.placeholder.client'
    : 'etherpadDocuments.signModal.name.placeholder.expert'

  const defaultDisplayedName = intl.formatMessage({ id: defaultNameLocale })
  const currentDate = useMemo(() => DateTime.now().toUTC(), [])
  const handleSubmit = useFormSubmit(signEtherpadDocument)
  const currentSignatureDisclosureAcceptedAt = useSelector(currentSignatureDisclosureAcceptedAtSelector)

  const eSignatureEnabled = useWorkspaceFeature(FEATURE_KEYS.signature)
  const disclosureEnabled = path(['workspaceSignatureSetting', 'disclosureEnabled'], document)
  const disclosureName = path(['workspaceSignatureSetting', 'signatureDisclosure', 'name'], document)
  const isEsignatureDisclosureShown = !currentSignatureDisclosureAcceptedAt && disclosureEnabled && eSignatureEnabled

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="etherpadDocuments.signModal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            documentId: document.id,
            name: '',
            clientId,
            signatureType: SIGNATURE_TYPES.type,
            isEsignatureDisclosureShown,
            eSignatureAgree: false,
            signatureImage: null,
            signatureDraw: null,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={signDocumentSchema}
        >
          {({ values }) => (
            <>
              <div className="main-modal__body">
                <p className="text-header font-700">
                  <FormattedMessage id="etherpadDocuments.signModal.description" />
                </p>
                <Field
                  id="signatureType"
                  name="signatureType"
                  wrapperClassName="main-radio--segment-control mb-16 d-inline-flex"
                  isSegmentControl
                  className="mb-0"
                  options={SIGNATURE_TYPE_OPTIONS}
                  component={RadioField}
                />
                {(values.signatureImage || values.signatureType !== SIGNATURE_TYPES.image) && (
                  <Field
                    className="mb-0 main-input--input-top-count"
                    name="name"
                    component={InputWithCounterField}
                    id="name"
                    type="text"
                    icon="close"
                    label={{ id: 'etherpadDocuments.signModal.name.label' }}
                    maxChars={MAX_SIGNATURE_LENGTH}
                    placeholder={{ id: defaultNameLocale }}
                  />
                )}
                {values.signatureType === SIGNATURE_TYPES.type && (
                  <div className="signature p-16 mt-16">
                    <p className="signature__element text-headline font-400">{values.name || defaultDisplayedName}</p>
                    <p className="text-body mb-0 in-blue-gray-300">
                      <FormattedMessage
                        id="etherpadDocuments.document.signedBy"
                        values={{
                          fullName: values.name || defaultDisplayedName,
                        }}
                      />
                    </p>
                    <p className="text-body mb-0 in-blue-gray-300 mt-2">
                      <FormattedMessage
                        id="etherpadDocuments.document.signedBy.date"
                        values={{
                          date: `${formattedShortDate(currentDate)} ${formattedTime(currentDate)}`,
                        }}
                      />
                    </p>
                  </div>
                )}
                {values.signatureType === SIGNATURE_TYPES.image && (
                  <ESignatureDropzone defaultDisplayedName={defaultDisplayedName} currentDate={currentDate} />
                )}
                {values.signatureType === SIGNATURE_TYPES.draw && (
                  <DrawSignature currentDate={currentDate} defaultDisplayedName={defaultDisplayedName} />
                )}
                {isEsignatureDisclosureShown && (
                  <div className="bg-gray-200 border-radius-wrap p-16 mt-24">
                    <p className="mb-8">
                      <FormattedMessage id="eSignature.eSignatureDisclosure.read" />
                      <span className="in-blue-600 text-underline ml-4">
                        <Link href={signatureDisclosureRoute} isRoot>
                          <a target="_blank">{disclosureName}</a>
                        </Link>
                      </span>
                    </p>
                    <Field
                      className="mb-0"
                      id="eSignatureAgree"
                      name="eSignatureAgree"
                      component={CheckboxField}
                      label={{ id: 'eSignature.eSignatureDisclosure.agree' }}
                    />
                  </div>
                )}
              </div>
              <div className="main-modal__footer">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton
                  className="main-modal__footer-action"
                  text={{ id: 'etherpadDocuments.signModal.submitText' }}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

SignDocumentModal.defaultProps = {
  isSignerClient: false,
  clientId: null,
}

SignDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    currentSignatory: PropTypes.shape().isRequired,
  }).isRequired,
  isSignerClient: PropTypes.bool,
  clientId: PropTypes.string,
}

export default SignDocumentModal
