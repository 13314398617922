import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { removeClientNotesIds } from 'state/concepts/companyClientNotes/actions'
import { DELETE_CLIENT_NOTE } from 'state/concepts/companyClientNotes/types'
import { showNotification } from 'state/notifications/actions'
import { deleteClientNoteEndpoint } from '../endpoints'

const deleteClientNoteOperation = createLogic({
  type: DELETE_CLIENT_NOTE,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { clientId, noteId, feedId } = action
    const { url } = deleteClientNoteEndpoint(clientId, noteId)

    try {
      await httpClient.delete(url)

      dispatch(removeClientNotesIds([noteId]))
      dispatch(removeClientFeedIds([feedId]))
      dispatch(showNotification({ messageObject: { id: 'notifications.clientNoteWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteClientNoteOperation
