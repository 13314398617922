import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { CancelToken, isCancel } from 'axios'
import { includes } from 'ramda'
import getBlobDuration from 'get-blob-duration'

import isNetworkError from 'utils/isNetworkError'
import { isDurationParsable } from 'utils/file'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { uploadLinksSelector } from 'state/concepts/chat/selectors'
import {
  removeUploadProgress,
  setUploadLoadingStatus,
  addUploadError,
  removeUploadError,
} from 'state/concepts/chat/actions'
import { UPDATE_ATTACHMENT_UPLOAD, CANCEL_ATTACHMENT_UPLOAD } from 'state/concepts/chat/types'
import { updateAttachmentUploadEndpoint } from 'state/concepts/chat/endpoints'

const updateAttachmentUploadOperation = createLogic({
  type: UPDATE_ATTACHMENT_UPLOAD,
  latest: false,

  async process({ httpClient, getState, action, action$ }, dispatch, done) {
    const { uploadId, file, meta } = action
    const key = meta.fields.key.match(/^uploads\/cache\/(.+)/)[1]
    const uploadLink = uploadLinksSelector(getState())

    const { endpoint, url } = updateAttachmentUploadEndpoint(uploadLink[uploadId])

    dispatch(dataApiRequest({ endpoint }))

    try {
      const source = CancelToken.source()
      action$.subscribe(newAction => {
        const isCancelAllowed = newAction.type === CANCEL_ATTACHMENT_UPLOAD && includes(uploadId, newAction.uploadIds)

        if (isCancelAllowed) {
          source.cancel()
        }
      })

      const metadata = {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }

      if (isDurationParsable(file.type)) {
        metadata.duration = await getBlobDuration(file)
      }

      const { data } = await httpClient.put(
        url,
        {
          file: {
            id: key,
            storage: 'cache',
            metadata,
          },
        },
        {
          cancelToken: source.token,
        },
      )
      const response = normalize(data, { endpoint })
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(removeUploadProgress(uploadId))
      dispatch(setUploadLoadingStatus(uploadId, false))
      dispatch(removeUploadError(uploadId))
    } catch (error) {
      if (isNetworkError(error)) {
        dispatch(addUploadError(uploadId))
      } else if (!isCancel(error)) {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default updateAttachmentUploadOperation
