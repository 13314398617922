import snakeCase from 'lodash/snakeCase'
import { map, pipe, toPairs, mergeAll } from 'ramda'

const parseToJSONAPIFormat = data =>
  pipe(
    toPairs,
    map(value => ({ [snakeCase(value[0])]: value[1] })),
    mergeAll,
  )(data)

export default parseToJSONAPIFormat
