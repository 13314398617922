import React from 'react'
import PropTypes from 'prop-types'
import { compose, prop, propOr } from 'ramda'
import { withFormik } from 'formik'
import { connect } from 'react-redux'

import validationSchema from 'lib/yupLocalised/schemas/inviteMembersSchema'
import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import { isChatDirect, isChatGroup } from 'utils/chat'
import { CHAT_TYPES, CHAT_DETAILS_MODAL_ACTIONS } from 'lib/constants/chat'
import { loadingSelector } from 'state/data/selectors'
import { addChatMembers, createChat as createChatAction } from 'state/concepts/chat/actions'
import { searchChatUsers as searchChatUsersAction } from 'state/concepts/chatUsers/actions'
import { searchedChatUsersSelector } from 'state/concepts/chatUsers/selectors'
import { isBlankStateSearchResultsSelector } from 'state/searchResults/selectors'
import { searchChatUsersEndpoint } from 'state/concepts/chatUsers/endpoints'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import ModalSpinner from 'views/shared/ModalSpinner'
import { showModal as showModalAction } from 'state/modal/actions'
import NoMembersToAddModal from './NoMembersToAddModal'
import InviteMembersModalComponent from './component'

class InviteMembersModal extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isNoMembersToAdd: PropTypes.bool,
    chatUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    searchChatUsers: PropTypes.func.isRequired,
    chat: PropTypes.shape({
      chatUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    chatType: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.shape({
      chatUserIds: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    showModal: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: undefined,
    isNoMembersToAdd: undefined,
    chat: null,
    chatType: CHAT_TYPES.direct,
  }

  get isLoading() {
    const { isLoading, isNoMembersToAdd } = this.props

    return isLoading !== false && isNoMembersToAdd !== false
  }

  componentDidMount() {
    const { searchChatUsers, chat } = this.props

    searchChatUsers({ chat, query: '', resetResults: true })
  }

  handleSubmit = () => {
    const {
      handleSubmit,
      values: { chatUserIds },
      setStatus,
      setErrors,
      setSubmitting,
      resetForm,
      setValues,
      values,
      showModal,
      chat,
      chatType,
      createChat,
    } = this.props

    if (chat) {
      handleSubmit()
      return
    }

    if (isChatDirect({ chatType })) {
      createChat(values, setErrors, setSubmitting, setStatus, resetForm, setValues, {
        chatUserIds: [chatUserIds],
        chatType,
      })
      return
    }

    showModal({
      modalType: 'CHAT_DETAILS_MODAL',
      modalProps: {
        actionType: CHAT_DETAILS_MODAL_ACTIONS.create,
        onSubmit: createChat,
        chatUserIds,
        chatType,
      },
    })
  }

  render() {
    const { isNoMembersToAdd } = this.props

    if (this.isLoading) {
      return <ModalSpinner />
    }

    if (isNoMembersToAdd) {
      return <NoMembersToAddModal {...this.props} />
    }

    return <InviteMembersModalComponent {...this.props} handleSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, searchChatUsersEndpoint.endpoint),
  chatUsers: searchedChatUsersSelector(state),
  isNoMembersToAdd: isBlankStateSearchResultsSelector(state, SEARCH_RESULTS_ENTITIES.chatUsers),
})

const mapDispatchToProps = {
  onSubmit: addChatMembers,
  searchChatUsers: searchChatUsersAction,
  showModal: showModalAction,
  createChat: createChatAction,
}

export { InviteMembersModal as InviteMembersModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ chat, chatType }) => ({
      chat: { chatUserIds: propOr([], 'chatUserIds', chat), ownerId: prop('ownerId', chat) },
      chatType,
      chatUserIds: isChatGroup({ chatType }) ? [] : undefined,
    }),
    validationSchema,
    handleSubmit: handleSubmitWithProps(['chat', 'chatType']),
  }),
)(InviteMembersModal)
