import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import ProfileIdentityComponent from './component'

class ProfileIdentity extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    isClient: PropTypes.bool,
  }

  static defaultProps = {
    isClient: false,
  }

  get isCurrentUser() {
    const { isClient, user, currentUser } = this.props

    return !isClient && user.id === currentUser.id
  }

  render() {
    return <ProfileIdentityComponent {...this.props} isCurrentUser={this.isCurrentUser} />
  }
}

const mapStateToProps = state => ({
  currentUser: currentEmployeeSelector(state),
})

export { ProfileIdentity as ProfileIdentityContainer }
export default connect(mapStateToProps)(ProfileIdentity)
