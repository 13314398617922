import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_CALENDAR_VIEW_SETTING } from 'state/concepts/employee/types'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import updateDataHelper from 'utils/updateDataHelper'

import { hideModal } from 'state/modal/actions'
import { updateCalendarViewSettingEndpoint } from '../endpoints'

const updateCalendarViewSetting = createLogic({
  type: UPDATE_CALENDAR_VIEW_SETTING,
  latest: true,

  async process({ action: { view }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const employee = currentEmployeeSelector(state)
    const { endpoint, url } = updateCalendarViewSettingEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, { calendar_view_setting: view })

      const response = updateDataHelper(state.data, 'userProfile', employee.id, {
        attributes: {
          calendarViewSetting: view,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.updateCalendarViewSetting' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    dispatch(hideModal())
    done()
  },
})

export default updateCalendarViewSetting
