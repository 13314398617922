import { createLogic } from 'redux-logic'
import { map, pick, mergeDeepRight } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_OUTLOOK_CALENDAR_CONNECTION } from 'state/concepts/calendarIntegrations/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { updateOutlookCalendarConnectionEndpoint } from '../endpoints'

const updateOutlookCalendarConnectionOperation = createLogic({
  type: UPDATE_OUTLOOK_CALENDAR_CONNECTION,
  latest: true,

  async process(
    {
      action: {
        calendar: { id, outlookCalendarListItems },
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = updateOutlookCalendarConnectionEndpoint(id)
    const state = getState()
    try {
      const params = {
        outlook_calendar_list_items: map(pick(['id', 'active', 'busy']), outlookCalendarListItems),
      }
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.put(url, params)

      const response = outlookCalendarListItems.reduce(
        (acc, listItem) =>
          mergeDeepRight(
            acc,
            updateDataHelper(state.data, 'outlookCalendarListItem', listItem.id, {
              attributes: pick(['active', 'busy'], listItem),
            }),
          ),
        {},
        outlookCalendarListItems,
      )

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateOutlookCalendarConnectionOperation
