import PropTypes from 'prop-types'

import LocationOIcon from 'views/shared/icons/LocationO'

const OptionContent = ({ location: { name, addressLine } }) => (
  <div className="d-flex align-items-center" data-cy="appointment-select-location">
    <LocationOIcon size={21} color="#B2BAC7" />
    <div className="pl-12 truncate-option--with-icon">
      <p className="mb-0 truncate-text">{name}</p>
      <p className="in-blue-gray-300 text-caption mb-0 truncate-text">{addressLine}</p>
    </div>
  </div>
)

OptionContent.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    addressLine: PropTypes.string,
  }).isRequired,
}

export default OptionContent
