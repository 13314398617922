import { path } from 'ramda'
import { createLogic } from 'redux-logic'

import { transactionCheckoutPaymentIntentRoute } from 'lib/apiRoutes'
import { SENTRY_ENV } from 'lib/constants'
import { cardReaderByIdSelector } from 'state/concepts/cardReaders/selectors'
import { CHARGE_WITH_CARD_READER } from 'state/concepts/payment/types'
import { currentUserTimezoneOffsetSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { getLocalTime } from 'utils/dateTime'
import updateDataHelper from 'utils/updateDataHelper'

const chargeWithCardReaderOperation = createLogic({
  type: CHARGE_WITH_CARD_READER,
  warnTimeout: 0,

  async process(
    {
      action: {
        values: { cardReaderId, testCardNumber, testPaymentMethod },
        terminal,
        transaction,
        form,
      },
      getState,
      httpClient,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const { id: paymentId, amount } = transaction
    const cardReader = cardReaderByIdSelector(state, cardReaderId)
    const timezoneOffset = currentUserTimezoneOffsetSelector(state)

    try {
      const { error: connectError } = await terminal.connectReader({
        ...cardReader,
        ip_address: cardReader.ipAddress,
        serial_number: cardReader.serialNumber,
      })

      if (connectError) {
        throw connectError
      }

      if (SENTRY_ENV !== 'production') {
        terminal.setSimulatorConfiguration({
          testPaymentMethod,
          testCardNumber,
        })
      }

      const { data } = await httpClient.post(transactionCheckoutPaymentIntentRoute(paymentId), {
        amount,
        terminal_name: cardReader.label,
      })

      const { paymentIntent, error: collectPaymentError } = await terminal.collectPaymentMethod(
        data.meta.payment_intent_client_secret,
      )

      if (collectPaymentError) {
        throw collectPaymentError
      }

      const { error: confirmError, paymentIntent: paymentResult } = await terminal.processPayment(paymentIntent)

      if (confirmError) {
        throw confirmError
      }

      const paymentDetails = path(['charges', 'data', 0, 'payment_method_details', 'card_present'], paymentResult)
      const currentTime = getLocalTime(timezoneOffset)
      const response = updateDataHelper(state.data, 'transaction', paymentId, {
        attributes: {
          status: 'success',
          chargeableStatus: 'success',
          lastDigits: paymentDetails.last4,
          paymentMethod: paymentDetails.brand,
          paymentDate: currentTime.toFormat('LLL dd, yyyy'),
          paymentTime: currentTime.toFormat('hh:mm a'),
          terminalName: cardReader.label,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.paymentWasSuccessful' } }))
      dispatch(hideModal())
    } catch (error) {
      form.setStatus({ base: error.message })
      form.setSubmitting(false)
      terminal.disconnectReader()
    }

    done()
  },
})

export default chargeWithCardReaderOperation
