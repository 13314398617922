import PropTypes from 'prop-types'
import { Formik } from 'formik'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import yup from 'lib/yupLocalised'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { editServiceCategory as editServiceCategoryAction } from 'state/concepts/serviceCategories/actions'
import PersistServiceCategoriesModal from '../PersistModal'

const ServiceCategoryEditModal = ({ serviceCategory: { id, name, color }, onClose }) => {
  const handleEditServiceCategory = useDispatchAction(editServiceCategoryAction)

  return (
    <Formik
      initialValues={{ id, name, color }}
      validationSchema={yup.object().shape({
        name: yup.string().max(MAX_INPUT_CONTENT_LENGTH).required(),
      })}
      onSubmit={handleEditServiceCategory}
    >
      {props => (
        <PersistServiceCategoriesModal
          title={{ id: 'serviceCategories.editCategory.modal.title' }}
          submitText={{ id: 'shared.save' }}
          onClose={onClose}
          isSubmitDisabled={isSubmitDisabled(props)}
        />
      )}
    </Formik>
  )
}

ServiceCategoryEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
}

export default ServiceCategoryEditModal
