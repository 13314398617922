const bookingWidgetsFeature = {
  'bookingWidgetsFeature.title': 'Booking widgets',
  'bookingWidgetsFeature.subtitle': '<span>in</span> Booking',
  'bookingWidgetsFeature.description':
    'Add a booking widget to your website as a floating button or an embedded booking panel.',
  'bookingWidgetsFeature.manageSettings': 'Go to booking pages',
  'bookingWidgetsFeature.overview': 'Booking widgets overview',
  'bookingWidgetsFeature.secondDescription':
    'An ExpertBox booking widget is your digital booking assistant that lets you easily convert your website visitors into _(clients).',
  'bookingWidgetsFeature.chooseHowToIntegrate': 'Choose how you want to integrate your booking page:',
  'bookingWidgetsFeature.sideWidgets':
    'A side widget serves as a floating button that launches a booking pop-up form from any page on your website;',
  'bookingWidgetsFeature.embeddedWidgets':
    'An embedded widget will provide a seamless booking experience to your _(clients) on a dedicated website page.',
  'bookingWidgetsFeature.setUp': 'Easily set up your booking widget:',
  'bookingWidgetsFeature.copyAndAdd':
    'Simply copy and add a widget code to your website. The setup is easy and does not require any hardcoding skills;',
  'bookingWidgetsFeature.styleCustomizing':
    'Make sure the side widget reflects your style by customizing its color and position.',
  'bookingWidgetsFeature.automateExperience': 'Automate the booking experience:',
  'bookingWidgetsFeature.letClients':
    'Let _(clients) select the time for themselves according to your and your team’s availability and booking rules;',
  'bookingWidgetsFeature.enableOnlinePayments': 'Enable online payments to get paid instantly;',
  'bookingWidgetsFeature.getNotified': 'Get notified about every new booking.',
  'bookingWidgetsFeature.toLearnMore':
    'To learn more, please read our  help center articles on how to <setUpLink>set up online booking</setUpLink> and <shareLink>share a booking page link with your _(clients).</shareLink>',
  'bookingWidgetsFeature.relatedFeatures': 'Related features',
  'bookingWidgetsFeature.disableModalTitle': 'Disable _(client) portal feature?',
  'bookingWidgetsFeature.disableModalWarning': `_(Clients) won’t be able to log into the _(client) portal anymore. They still can interact with the workspace via email: access their _(appointments), get payment requests, complete forms, etc.`,
}

export default bookingWidgetsFeature
