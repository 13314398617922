import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Intake = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 16 20"
    data-cy={dataCy}
  >
    <path d="M2 0C0.905745 0 0 0.905745 0 2V18C0 19.0943 0.905745 20 2 20H14C15.0943 20 16 19.0943 16 18V6L10 0H2ZM2 2H9V7H14V18H2V2ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H5ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14H5Z" />
  </svg>
)

Intake.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'intake-icon',
}

Intake.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Intake
