import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { SEND_RECURRING_PAYMENT } from 'state/concepts/recurringPayment/types'
import { sendRecurringPaymentEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { showNotification } from 'state/notifications/actions'
import { fetchTransactions } from 'state/concepts/recurringPayment/actions'

const sendRecurringPaymentOperation = createLogic({
  type: SEND_RECURRING_PAYMENT,
  latest: true,

  async process({ action: { recurringPaymentId, refetchTransactions, hideNotification }, httpClient }, dispatch, done) {
    const { endpoint, url } = sendRecurringPaymentEndpoint(recurringPaymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ endpoint, response }))
      if (refetchTransactions) {
        dispatch(fetchTransactions(recurringPaymentId))
      }
      if (!hideNotification) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.recurringPaymentSent',
            },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default sendRecurringPaymentOperation
