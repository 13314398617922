const employeeProfile = {
  'employeeProfile.generalInfo': 'General information',
  'employeeProfile.you': 'You',
  'employeeProfile.youWithRoundBrackets': '(you)',
  'employeeProfile.generalInfo.position': 'Position',
  'employeeProfile.generalInfo.about': 'About',
  'employeeProfile.generalInfo.timezone': 'Timezone',
  'employeeProfile.generalInfo.phoneNumber': 'Phone number',
  'employeeProfile.generalInfo.videoCallProviderAlert':
    'This _(expert) is using ExpertBox video to meet _(clients) online. To start having online _(appointments) using the default video call provider of the workspace, they should set up video call integration for their account.',
  'employeeProfile.generalInfo.videoCallProviderAlertForCurrentUser':
    'You are using ExpertBox video to meet _(clients) online. To start having online _(appointments) using {videoCallProvider}, set up video call integration for your account.',
  'employeeProfile.services': '_(Services)',
  'employeeProfile.generalInfo.zoom': 'Zoom',
  'employeeProfile.generalInfo.google_meet': 'Google Meet',
  'employeeProfile.provision.service': '_(Service)',
  'employeeProfile.provision.category': 'Category',
  'employeeProfile.provision.status': 'Status',
  'employeeProfile.provision.pricePerSession': 'Price per session',
  'employeeProfile.regularAvailability': 'Regular availability',
  'employeeProfile.recurringAvailability.day': 'Day',
  'employeeProfile.recurringAvailability.workingHours': 'Working hours',
  'employeeProfile.BackToView': 'Back to view',
  'employeeProfile.doYouWantToRemoveAvatar': 'Do you want to remove the profile image of {name}? You can add it later',
  'employeeProfile.editProfile': 'Edit profile',
  'employeeProfile.editTeamMember': 'Edit team member',

  'employeeProfile.tabs.profile': 'Profile',
  'employeeProfile.tabs.services': '_(Services)',
  'employeeProfile.tabs.regularAvailability': 'Regular availability',
  'employeeProfile.tabs.DateAvailability': 'Day-specific availability',
  'employeeProfile.tabs.account': 'Account',

  'employeeAccount.2fa.title': 'Two-step verification',
  'employeeAccount.2fa.resendButton': 'Reset two-factor verification',
  'employeeAccount.2fa.enabled': 'Enabled',
  'employeeAccount.2fa.disabled': 'Disabled',
  'employeeAccount.2fa.modal.title': 'Reset two-step verification for {name}?',
  'employeeAccount.2fa.modal.body':
    'This will disable the two-step verification for {name}. They can set it up again the next time they log in.',
  'employeeAccount.2fa.modal.reset': 'Reset',
  'employeeAccount.deleteUser': 'Delete account',
  'employeeAccount.deleteUserTooltip': 'Only deactivated team members can be removed from the workspace',
  'employeeAccount.changeRole': 'Change role',
  'employeeAccount.changeRole.role': 'Role',
  'employeeAccount.changeRole.description':
    'Change team member’s role to give them different permissions and access level.',
  'employeeAccount.changeRole.roleOptions.administrator': 'Administrator',
  'employeeAccount.changeRole.roleOptions.administrator.description':
    'Can manage the team, all _(appointments), _(services), _(clients), documentation, billing, workspace subscription, and more.',
  'employeeAccount.changeRole.roleOptions.user': '_(Expert)',
  'employeeAccount.changeRole.roleOptions.user.description':
    'Can manage their own _(appointments) and _(clients), and access related documentation.',
  'employeeAccount.changeRole.roleOptions.scheduler': '_(Scheduler)',
  'employeeAccount.changeRole.roleOptions.scheduler.description':
    'Can schedule and manage _(appointments) and _(clients) for all team members.',
  'employeeAccount.changeRole.cantRevertScheduler':
    '{fullName} won’t be able to go back to being a _(Scheduler). They can only switch between Administrator and _(Expert) roles in the future.',
  'employeeAccount.teamMemberStatus': 'Team member status',
  'employeeAccount.activated': 'Activated',
  'employeeAccount.deactivated': 'Deactivated',
  'employeeAccount.removeFromCompany':
    "Deleting team member’s account will permanently erase all associated data and can't be undone.",
  'employeeAccount.deactivateWarning': 'Deactivated team members lose access to the workspace.',
  'profileStatus.active': 'Active',
  'profileStatus.deactivated': 'Deactivated',
  'profileStatus.pending': 'Pending',

  'editEmployeeProfile.generalInfo': 'General information',
  'editEmployeeProfile.generalInfo.description': 'Help your team identify you by adding your name and photo.',
  'editEmployeeProfile.firstName.label': 'First Name',
  'editEmployeeProfile.lastName.label': 'Last Name',
  'editEmployeeProfile.position.label': 'Position',
  'editEmployeeProfile.about.label': 'About <span>(optional)</span>',
  'editEmployeeProfile.timezone': 'Timezone',
  'editEmployeeProfile.timezone.description':
    'Set your timezone to easily schedule and manage _(appointments) for team members worldwide.',
  'editEmployeeProfile.timezone.label': 'Timezone',
}

export default employeeProfile
