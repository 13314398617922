import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import classNames from 'clsx'
import { length } from 'ramda'

import Button from 'views/shared/Button'
import Download from 'views/shared/icons/Download'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import Slide from './Slide'

const FilesPreviewModal = React.forwardRef(
  (
    {
      onClose,
      initialSlide,
      onSlideChange,
      onSlideRemove,
      currentFiles,
      currentSlide: { url, filename },
      canRemove,
      canDownload,
      withFileName,
      sliderClassName,
      nextArrowTooltip,
      prevArrowTooltip,
      withImageLoader,
    },
    ref,
  ) => (
    <div className="files-preview files-preview--modal">
      <div className="files-preview__header">
        <div className="wh-32-32 d-flex align-items-center justify-content-center mr-10">
          <Button
            onClick={onClose}
            className="wh-24-24 d-flex align-items-center justify-content-center"
            isIcon
            icon="arrow-left"
            iconSize={12}
            iconClassName="in-white"
            dataCy="files-preview-back-button"
          />
        </div>
        {withFileName && (
          <p className="text-subheader mb-0 font-600 truncate-text mr-auto" data-cy="preview-carousel-file-title">
            {filename}
          </p>
        )}
        <div
          className={classNames('preview-carousel__header-actions d-flex align-items-center', {
            'ml-auto': !withFileName,
          })}
        >
          {canDownload && (
            <a
              href={url}
              download
              className="wh-40-40 d-flex align-items-center justify-content-center files-preview__button-link files-preview__download-link"
              data-cy="files-preview-download-link"
            >
              <Download size={20} className="va-middle in-white" />
            </a>
          )}
          {canRemove && (
            <Button
              className="wh-40-40 d-flex align-items-center justify-content-center files-preview__button-link ml-12"
              onClick={onSlideRemove}
              isIcon
              iconSize={20}
              icon="delete"
              iconClassName="in-white"
              dataCy="files-preview-remove-button"
            />
          )}
        </div>
      </div>
      <Slider
        ref={ref}
        nextArrow={<NextArrow nextArrowTooltip={nextArrowTooltip} />}
        prevArrow={<PrevArrow prevArrowTooltip={prevArrowTooltip} />}
        initialSlide={initialSlide}
        beforeChange={onSlideChange}
        className={classNames('files-preview__content', sliderClassName)}
        arrows={length(currentFiles) > 1}
      >
        {currentFiles.map(file => (
          <Slide key={file.id} withImageLoader={withImageLoader} {...file} />
        ))}
      </Slider>
    </div>
  ),
)

FilesPreviewModal.defaultProps = {
  currentFiles: [],
  canDownload: true,
  withFileName: true,
  withImageLoader: true,
  sliderClassName: '',
  nextArrowTooltip: false,
  prevArrowTooltip: false,
}

FilesPreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSlideChange: PropTypes.func.isRequired,
  onSlideRemove: PropTypes.func.isRequired,
  initialSlide: PropTypes.number.isRequired,
  currentFiles: PropTypes.arrayOf(PropTypes.shape()),
  currentSlide: PropTypes.shape({
    url: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape()]),
    filename: PropTypes.string.isRequired,
  }).isRequired,
  canRemove: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool,
  withFileName: PropTypes.bool,
  withImageLoader: PropTypes.bool,
  sliderClassName: PropTypes.string,
  nextArrowTooltip: PropTypes.bool,
  prevArrowTooltip: PropTypes.bool,
}

FilesPreviewModal.displayName = 'FilesPreviewModal'

export default FilesPreviewModal
