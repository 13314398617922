import { FormattedMessage } from 'react-intl'

export const GENERAL_TRANSACTION_TYPES = {
  refund: 'refund',
  payment: 'payment',
}

export const TRANSACTION_STATUSES = {
  success: 'success',
  pending: 'pending',
  cancelled: 'cancelled',
  draft: 'draft',
  overdue: 'overdue',
}

export const TRANSACTION_CHARGEABLE_STATUSES = {
  success: 'success',
  failure: 'failure',
}

export const TRANSACTION_PAYMENT_TYPES = {
  paymentForAppointment: 'payment_for_appointment',
  paymentRequest: 'payment_request',
  paymentRecord: 'payment_record',
  refund: 'refund',
}

export const TRANSACTION_PAYMENT_METHODS = {
  creditCard: 'credit_card',
  cash: 'cash',
  check: 'check',
  transfer: 'transfer',
}

export const TRANSACTION_STATUS_FILTERS = [
  {
    value: TRANSACTION_STATUSES.draft,
    label: (
      <div className="main-badge main-badge--draft">
        <FormattedMessage id="payments.status.draft" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.pending,
    label: (
      <div className="main-badge main-badge--pending">
        <FormattedMessage id="payments.status.pending" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.success,
    label: (
      <div className="main-badge main-badge--success">
        <FormattedMessage id="payments.status.success" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.overdue,
    label: (
      <div className="main-badge main-badge--overdue">
        <FormattedMessage id="payments.status.overdue" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.cancelled,
    label: (
      <div className="main-badge main-badge--cancelled">
        <FormattedMessage id="payments.status.cancelled" />
      </div>
    ),
  },
]

export const TRANSACTION_STATUS_CLIENT_FILTERS = [
  {
    value: TRANSACTION_STATUSES.overdue,
    label: (
      <div className="main-badge main-badge--overdue">
        <FormattedMessage id="payments.status.overdue" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.pending,
    label: (
      <div className="main-badge main-badge--pending">
        <FormattedMessage id="payments.status.pending" />
      </div>
    ),
  },
  {
    value: TRANSACTION_STATUSES.success,
    label: (
      <div className="main-badge main-badge--completed">
        <FormattedMessage id="payments.status.success" />
      </div>
    ),
  },
]

export const TRANSACTION_PAYMENT_METHOD_OPTIONS = [
  {
    key: TRANSACTION_PAYMENT_METHODS.cash,
    value: TRANSACTION_PAYMENT_METHODS.cash,
    label: { id: 'payment.paymentMethod.cash' },
  },
  {
    key: TRANSACTION_PAYMENT_METHODS.creditCard,
    value: TRANSACTION_PAYMENT_METHODS.creditCard,
    label: { id: 'payment.paymentMethod.creditCard' },
  },
  {
    key: TRANSACTION_PAYMENT_METHODS.transfer,
    value: TRANSACTION_PAYMENT_METHODS.transfer,
    label: { id: 'payment.paymentMethod.transfer' },
  },
  {
    key: TRANSACTION_PAYMENT_METHODS.check,
    value: TRANSACTION_PAYMENT_METHODS.check,
    label: { id: 'payment.paymentMethod.check' },
  },
]

export const PREVIEW_AND_PDF_TYPES = {
  refund: 'creditNote',
  invoice: 'invoice',
  receipt: 'receipt',
}

export const defaultReceiptTemplate = {
  userProfile: {
    firstName: 'Name',
    lastName: 'Surname',
    email: 'provider@email.com',
  },
  clientProfile: {
    firstName: 'Josh',
    lastName: 'Adams',
    email: 'client@email.com',
  },
  id: '1',
  transactionNumber: '#00000003',
  amount: '100',
  paymentDate: 'Mar 4, 2020',
  issueDate: 'Mar 2, 2020',
  dueDate: 'Mar 5, 2020',
  lastDigits: '1234',
  paymentMethod: 'credit_card',
  transactionItems: [
    {
      id: '1',
      name: 'Service name',
      quantity: 2,
      price: '50',
      total: '100',
    },
  ],
  refundedTransaction: { transactionNumber: '#00000001', issueDate: 'Mar 2, 2020' },
}

export const PAYMENT_FORM_BASE_VALUES = {
  clientId: undefined,
  services: [{ service: undefined, quantity: 1, price: 1 }],
}

export const PAYMENT_METHODS = {
  stripe: 'stripe',
  paypal: 'paypal',
}

export const RECEIPT_TEMPLATE_STATUSES = {
  active: 'active',
  inactive: 'inactive',
}

export const PAYMENTS_TABS = {
  payments: 'payments',
  recurringPayments: 'recurring_payments',
}

export const QUANTITY_MIN_PRICE = 1
export const QUANTITY_MAX_PRICE = 100

export const TEST_PAYMENT_METHODS = [
  { label: 'visa', value: 'visa', key: 'visa' },
  { label: 'visa_debit', value: 'visa_debit', key: 'visa_debit' },
  { label: 'mastercard', value: 'mastercard', key: 'mastercard' },
  { label: 'mastercard_debit', value: 'mastercard_debit', key: 'mastercard_debit' },
  { label: 'mastercard_prepaid', value: 'mastercard_prepaid', key: 'mastercard_prepaid' },
  { label: 'amex', value: 'amex', key: 'amex' },
  { label: 'amex2', value: 'amex2', key: 'amex2' },
  { label: 'discover', value: 'discover', key: 'discover' },
  { label: 'discover2', value: 'discover2', key: 'discover2' },
  { label: 'diners', value: 'diners', key: 'diners' },
  { label: 'diners_14digits', value: 'diners_14digits', key: 'diners_14digits' },
  { label: 'jcb', value: 'jcb', key: 'jcb' },
  { label: 'unionpay', value: 'unionpay', key: 'unionpay' },
  { label: 'interac', value: 'interac', key: 'interac' },

  // specific responses and errors
  { label: 'refund_fail', value: 'refund_fail', key: 'refund_fail' },
  { label: 'charge_declined', value: 'charge_declined', key: 'charge_declined' },
  {
    label: 'charge_declined_insufficient_funds',
    value: 'charge_declined_insufficient_funds',
    key: 'charge_declined_insufficient_funds',
  },
  { label: 'charge_declined_lost_card', value: 'charge_declined_lost_card', key: 'charge_declined_lost_card' },
  {
    label: 'charge_declined_stolen_card',
    value: 'charge_declined_stolen_card',
    key: 'charge_declined_stolen_card',
  },
  {
    label: 'charge_declined_expired_card',
    value: 'charge_declined_expired_card',
    key: 'charge_declined_expired_card',
  },
  {
    label: 'charge_declined_processing_error',
    value: 'charge_declined_processing_error',
    key: 'charge_declined_processing_error',
  },
]
export const CHECKOUT_REFERRER_PAGE = {
  booking: 'booking',
  payment: 'payment',
}
