import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { valueWithUnitToSeconds } from 'utils/duration'
import { isAutomaticallyPredicate, isNotificationOnlyPredicate } from 'utils/waitlistRequests'
import { setIsWaitlistScheduleType } from 'state/concepts/alert/actions'
import { updateWaitlistSettingEndpoint } from '../endpoints'
import { UPDATE_WAITLIST_SETTING } from '../types'

const updateWaitlistSettingOperation = createLogic({
  type: UPDATE_WAITLIST_SETTING,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = updateWaitlistSettingEndpoint
    const { waitlistEnabled } = values

    const params = {
      enabled_on_booking_page: waitlistEnabled,
      schedule_type: values.scheduleType,
      ...(isAutomaticallyPredicate(values.scheduleType) && {
        time_to_respond: valueWithUnitToSeconds(values.timeToRespondType, values.timeToRespond),
      }),
      ...(isNotificationOnlyPredicate(values.scheduleType) && { sms_notifications_enabled: values.smsNotifications }),
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.waitlistSettingsUpdated',
          },
        }),
      )
      if (values.initialScheduleType !== values.scheduleType) dispatch(setIsWaitlistScheduleType(true))
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateWaitlistSettingOperation
