// eslint-disable-next-line import/prefer-default-export
export const SEARCH_RESULTS_ENTITIES = {
  services: 'services',
  clients: 'clients',
  employees: 'employees',
  chatUsers: 'chatUsers',
  serviceCategories: 'serviceCategories',
  locations: 'locations',
  formCategories: 'formCategories',
  clientRelationshipTypes: 'clientRelationshipTypes',
  contactClientProfiles: 'contactClientProfiles',
  clientContacts: 'clientContacts',
}
