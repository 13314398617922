import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import parseToJSONAPIFormat from 'utils/parseToJsonApiFormat'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS } from 'state/concepts/notificationSettings/types'
import { updateEmployeeAccountNotificationSettingsEndpoint } from '../endpoints'

const updateEmployeeAccountNotificationSettingsOperation = createLogic({
  type: UPDATE_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url } = updateEmployeeAccountNotificationSettingsEndpoint

    const params = parseToJSONAPIFormat(values)

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data)
      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.notificationSettingsHaveBeenUpdated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateEmployeeAccountNotificationSettingsOperation
