import { createLogic } from 'redux-logic'

import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { fetchFormCategories } from '../actions'
import { createFormCategoryEndpoint } from '../endpoints'
import { CREATE_FORM_CATEGORY } from '../types'

const createFormCategoryOperation = createLogic({
  type: CREATE_FORM_CATEGORY,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = createFormCategoryEndpoint
    const { name, color } = values
    const params = {
      name,
      color,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.formCategoryCreated',
          },
        }),
      )
      dispatch(fetchFormCategories())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default createFormCategoryOperation
