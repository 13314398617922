import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, path } from 'ramda'
import { withFormik } from 'formik'

import validationSchema from 'lib/yupLocalised/schemas/employeesProvision'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { fetchProvision as fetchProvisionAction, updateProvision } from 'state/concepts/employees/actions'
import { provisionSelector } from 'state/concepts/employees/selectors'
import AssignServicesModalComponent from './component'

class AssignServicesModal extends React.Component {
  static propTypes = {
    fetchProvision: PropTypes.func.isRequired,
    employee: PropTypes.shape().isRequired,
    resetForm: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fetchProvision, employee } = this.props
    fetchProvision(employee.id)
  }

  render = () => <AssignServicesModalComponent {...this.props} />
}

const makeMapStateToProps = (_initialState, initialProps) => {
  const employeeId = path(['employee', 'id'], initialProps)

  return state => ({
    provision: provisionSelector(state, employeeId),
  })
}

const mapDispatchToProps = {
  onSubmit: updateProvision,
  fetchProvision: fetchProvisionAction,
}

export { AssignServicesModal as AssignServicesModalContainer }
export default compose(
  connect(makeMapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ provision }) => ({
      provision: provision.length ? provision : [{ services: [], price: undefined, key: 0, isFreePrice: false }],
    }),
    enableReinitialize: true,
    handleSubmit: handleSubmitWithProps(['employee']),
    validationSchema,
  }),
)(AssignServicesModal)
