import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CheckedSquare = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M853.333 0c94.259 0 170.667 76.41 170.667 170.667v682.666c0 94.259-76.407 170.667-170.667 170.667h-682.666c-94.256 0-170.667-76.407-170.667-170.667v-682.666c0-94.256 76.41-170.667 170.667-170.667h682.666zM780.993 329.551c-22.221-22.216-58.237-22.216-80.458 0l-301.104 301.108-103.204-103.206c-22.217-22.216-58.237-22.216-80.453 0s-22.216 58.235 0 80.456l143.431 143.428c22.217 22.215 58.237 22.215 80.453 0l341.336-341.333c22.215-22.217 22.215-58.237 0-80.453z" />
  </svg>
)

CheckedSquare.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CheckedSquare.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CheckedSquare
