import parseHtml from 'html-react-parser'
import { lowerFirst } from 'lodash'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useBoolean } from 'react-use'

import { ALERT_KINDS } from 'lib/constants'
import { companyCalendarEventRoute } from 'lib/routes'
import { calendarEventByIdSelector } from 'state/concepts/calendarEvent/selectors'
import sanitizeEmptyLines from 'utils/calendarEvents/sanitizeEmptyLines'
import { calendarEventIconByType } from 'utils/calendarsEvent'
import Attachments from 'views/company/CalendarEventDetails/Attachments'
import JoinButton from 'views/company/CalendarEventDetails/JoinButton'
import Locations from 'views/company/CalendarEventDetails/Locations'
import Members from 'views/company/CalendarEventDetails/Members'
import Alert from 'views/shared/Alert'
import BookingDateInfo from 'views/shared/AppointmentDetails/BookingDateInfo'
import EllipsisWithToggle from 'views/shared/EllipsisWithToggle'
import Link from 'views/shared/Link'
import Cross from 'views/shared/icons/Cross'
import External from 'views/shared/icons/External'
import Paragraph from 'views/shared/icons/Paragraph'

const ExternalEventSidebar = ({ id, onClose, slotStart }) => {
  const event = useSelector(state => calendarEventByIdSelector(state, id))
  const [showJoinLink, toggleJoinLink] = useBoolean(false)
  const [moreMembers, toggleMoreMembers] = useBoolean(false)

  const logo = calendarEventIconByType[event.calendarListItemType]

  return (
    <section className="booking-tooltip pt-16" data-cy="booking-tooltip">
      <div className="booking-tooltip__toolbar mb-0 align-items-center">
        <p className="main-badge main-badge-calendar">
          <Image src={logo} alt="calendar-event-logo" width={20} height={20} />
          <span className="ml-8">
            <FormattedMessage id={`calendarEvents.${lowerFirst(event.calendarListItemType)}`} />
          </span>
        </p>
        <div>
          <Link href={companyCalendarEventRoute(id)}>
            <a
              className="main-dropdown-btn mr-24"
              target="_blank"
              data-cy="expand-booking-details-button"
              rel="noreferrer"
            >
              <External className="main-dropdown-btn__icon" size={16} />
            </a>
          </Link>

          <button type="button" onClick={onClose} data-cy="close-sidebar-button">
            <Cross size={10} dataCy="cross-icon" className="in-gray-600 va-middle" />
          </button>
        </div>
      </div>

      <div className="booking-tooltip__content flex-grow-0">
        <div className="d-flex flex-column gap-16">
          <div>
            {event.calendarName && (
              <div className="d-flex align-items-center mb-2">
                <span className="main-card__category" style={{ backgroundColor: '#B2BAC7' }} />
                <p className="in-blue-gray-300 d-inline-block mb-0">{event.calendarName}</p>
              </div>
            )}
            <div className="ml-36 mb-12">
              {event.name && (
                <h3 className="booking-tooltip__info-title mb-8" data-cy="booking-service-title">
                  {event.name}
                </h3>
              )}
              <BookingDateInfo className="gap-4" booking={event} slotStart={slotStart} withoutIcon />
            </div>
          </div>
          {event.calendarBusy && (
            <Alert
              className="align-items-center"
              iconSize={20}
              type={ALERT_KINDS.lock}
              message={{ id: 'bookings.syncEvents.info.timeUnavailable' }}
            />
          )}

          <Locations locations={event.locations} />
          <JoinButton joinUrl={event.joinUrl} toggleJoinLink={toggleJoinLink} showJoinLink={showJoinLink} />
          <Members members={event.attendees} moreMembers={moreMembers} toggleMembersTruncate={toggleMoreMembers} />
          {event.description && (
            <div className="d-flex gap-16">
              <span className="d-inline-block w-20 text-center">
                <Paragraph className="mr-16 in-blue-gray-200" size={18} />
              </span>
              <EllipsisWithToggle
                text={parseHtml(sanitizeEmptyLines(event.description)).replace(/#<tel:(.+?)#>/g, 'tel:$1')}
                rows={2}
                className="mb-8"
              />
            </div>
          )}

          <Attachments attachments={event.attachments} />
        </div>
      </div>
    </section>
  )
}

ExternalEventSidebar.defaultProps = {
  slotStart: undefined,
}

ExternalEventSidebar.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  slotStart: PropTypes.string,
}

export default ExternalEventSidebar
