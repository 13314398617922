import { createSelector } from '@reduxjs/toolkit'
import { find, isEmpty, path, pipe, prop, propOr, toString } from 'ramda'
import build from 'redux-object'

import { MAX_SUBSCRIPTION_PLAN, MIN_SUBSCRIPTION_PLAN } from 'lib/constants/subscriptionPlans'
import {
  billingDetailSelector,
  isTrialSelector,
  storagePlanSelector,
  subscriptionPlanSelector,
} from 'state/concepts/billing/selectors'
import {
  fetchChangeSubscriptionDetailsEndpoint,
  fetchSubscriptionPlansEndpoint,
} from 'state/concepts/subscriptionPlans/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import { isAnnual, isFreePlan, isPremiumPlan, isPremiumPlusPlan } from 'utils/billing'

export const subscriptionPlansIdsSelector = path(['subscriptionPlans', 'subscriptionPlansIds'])
export const storagePlansIdsSelector = path(['subscriptionPlans', 'storagePlansIds'])
export const changeSubscriptionDetailsIdSelector = path(['subscriptionPlans', 'changeSubscriptionDetailsId'])

export const subscriptionPlansSelector = createSelector([subscriptionPlansIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'realSubscriptionPlan', ids) : [],
)

export const subscriptionPlanByPlanTypeSelector = createSelector(
  [subscriptionPlansSelector, (_, planType) => planType],
  (plans, planType) => plans.find(plan => plan.planType === planType),
)

export const subscriptionPlanByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'realSubscriptionPlan', id) : null,
)

export const maxSubscriptionPlanSelector = pipe(
  subscriptionPlansSelector,
  find(({ name }) => name === MAX_SUBSCRIPTION_PLAN),
)

export const minSubscriptionPlanSelector = pipe(
  subscriptionPlansSelector,
  find(({ name }) => name === MIN_SUBSCRIPTION_PLAN),
)

export const storagePlansSelector = createSelector([storagePlansIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'storagePlan', ids) : [],
)

// eslint-disable-next-line react/display-name
const makePlanIdSelector = propName => subscription =>
  // eslint-disable-next-line react/destructuring-assignment
  propOr(null, propName, subscription) ? toString(parseInt(subscription[propName], 10)) : null

export const currentSubscriptionPlanIdSelector = createSelector(subscriptionPlanSelector, makePlanIdSelector('planId'))

export const currentStoragePlanIdSelector = createSelector(storagePlanSelector, makePlanIdSelector('planId'))

export const plannedSubscriptionPlanIdSelector = createSelector(
  billingDetailSelector,
  makePlanIdSelector('plannedSubscriptionPlanId'),
)

export const plannedStoragePlanIdSelector = createSelector(
  billingDetailSelector,
  makePlanIdSelector('plannedStoragePlanId'),
)

export const currentSubscriptionPlanSelector = createSelector(
  currentSubscriptionPlanIdSelector,
  dataSelector,
  (id, data) => (id ? build(data, 'realSubscriptionPlan', id) : null),
)

export const currentStoragePlanSelector = createSelector([currentStoragePlanIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'storagePlan', id) : null,
)

export const fetchChangeSubscriptionDetailsLoadingSelector = state =>
  loadingSelector(state, fetchChangeSubscriptionDetailsEndpoint.endpoint)

export const fetchSubscriptionPlansLoadingSelector = state =>
  loadingSelector(state, fetchSubscriptionPlansEndpoint.endpoint)

export const changeSubscriptionDetailsSelector = createSelector(
  [changeSubscriptionDetailsIdSelector, dataSelector],
  (id, data) => build(data, 'changeSubscriptionPlanBundle', id),
)

export const plannedSubscriptionPlanSelector = createSelector(
  [changeSubscriptionDetailsSelector, subscriptionPlanByIdSelector],
  (changeSubscriptionDetails, subscriptionPlanById) =>
    propOr(subscriptionPlanById, 'plannedSubscriptionPlanId', changeSubscriptionDetails),
)

export const isPaymentRequiredSelector = createSelector(
  [
    (_, plannedSubscriptionCycleType) => plannedSubscriptionCycleType,
    changeSubscriptionDetailsSelector,
    isTrialSelector,
  ],
  (plannedSubscriptionCycleType, changeSubscriptionDetails, isTrial) =>
    isTrial ||
    isFreePlan(prop('currentSubscriptionPlan', changeSubscriptionDetails)) ||
    (!isAnnual(changeSubscriptionDetails) &&
      isPremiumPlan(prop('currentSubscriptionPlan', changeSubscriptionDetails)) &&
      isPremiumPlusPlan(prop('plannedSubscriptionPlan', changeSubscriptionDetails)) &&
      isAnnual({ subscriptionCycleType: plannedSubscriptionCycleType })),
)

export const subscriptionDebtsSelector = createSelector(
  [(_, discountPercentage) => discountPercentage, changeSubscriptionDetailsSelector],
  (discountPercentage, changeSubscriptionDetails) => {
    const result =
      propOr(0, 'lastFailedTransactionTotal', changeSubscriptionDetails) +
      (propOr(0, 'unpaidResourcesTotal', changeSubscriptionDetails) * (100 - discountPercentage || 0)) / 100 +
      (propOr(0, 'usedResourcesTotal', changeSubscriptionDetails) * (100 - discountPercentage || 0)) / 100

    return Number(result.toFixed(2))
  },
)

export const storagePlanByIdSelector = createSelector(
  [(_, storagePlanId) => storagePlanId, changeSubscriptionDetailsSelector],
  (storagePlanId, changeSubscriptionDetails) =>
    propOr([], 'storagePlans', changeSubscriptionDetails).find(({ id }) => id === storagePlanId),
)
