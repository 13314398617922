import { createLogic } from 'redux-logic'
import { trim } from 'ramda'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_EMPLOYEE_PROFILE } from 'state/concepts/employees/types'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { userRoute } from 'lib/apiRoutes'

const updateProfileOperation = createLogic({
  type: UPDATE_EMPLOYEE_PROFILE,
  latest: true,

  async process({ action: { values, form, employeeId }, httpClient }, dispatch, done) {
    const formData = new FormData()
    const url = userRoute(employeeId)

    try {
      formData.append('first_name', trim(values.firstName))
      formData.append('last_name', trim(values.lastName))
      formData.append('position', values.position)
      formData.append('about', values.about)
      formData.append('timezone', values.timezone)

      if (values.avatar === null) {
        formData.append('remove_avatar', true)
      } else if (values.avatar instanceof Blob) {
        formData.append('avatar', values.avatar, values.avatar.name)
      }

      const { data } = await httpClient.put(url, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })

      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.profileHasBeenUpdated' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateProfileOperation
