import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { isRescheduled } from 'utils/bookings'
import EllipsisWithToggle from 'views/shared/EllipsisWithToggle'
import TextWithBrackets from 'views/shared/icons/TextWithBrackets'

const RescheduleReason = ({ booking, isShort }) =>
  isRescheduled(booking) && (
    <>
      <div className="d-flex gap-16">
        <div className="w-20 text-center">
          <TextWithBrackets size={18} className="in-blue-gray-200" />
        </div>
        <div className="booking-tooltip__content-warp">
          <div className="in-blue-gray-300 mb-4" data-cy="booking-reschedule-reason-title">
            <FormattedMessage id={`bookings.rescheduleReason.${booking.reschedule.performedBy}`} />
          </div>
          <p className="mb-0" data-cy="booking-reschedule-reason">
            <EllipsisWithToggle text={booking.reschedule.rescheduleReason} rows={3} className="text-body" />
          </p>
        </div>
      </div>
      {!isShort && <div className="divider divider--light m-0" />}
    </>
  )

RescheduleReason.defaultProps = {
  isShort: false,
}

RescheduleReason.propTypes = {
  booking: PropTypes.shape().isRequired,
  isShort: PropTypes.bool,
}

export default RescheduleReason
