const manageStorage = {
  'manageStorage.hasDowngrade.alert': 'You’ll switch to {size} storage plan on {date}.',
  'manageStorage.reviewStorage': 'Review workspace storage',
  'manageStorage.reviewStorage.alert.exceedLimit':
    'ExpertBox will hide older files that exceed the storage limit. Upgrade within 30 days to restore them, or they’ll be deleted permanently.',
  'manageStorage.currentStorage': 'Current workspace storage',
  'manageStorage.currentStorage.includedInSubscriptionPlan': '(included in {name})',
  'manageStorage.currentStorage.storagePlanSize': '+ {size}',
  'manageStorage.currentStorage.noStoragePlan': 'No additional storage',
  'manageStorage.updatedStorage': 'Updated workspace storage',
  'manageStorage.updatedStorage.usageProgress': '{current} of {max} used',
  'manageStorage.changeStoragePlan': 'Change storage plan',
  'manageStorage.pricePerMonth': '{price} / month',
  'manageStorage.radio.cancel': 'Cancel current storage plan ({size} — {price} / month)',
  'manageStorage.summary.alert.upgrade': 'Upgrade now and pay a prorated fee for the new plan on {date}.',
  'manageStorage.summary.alert.downgrade': 'Your storage will be downgraded on {date}.',
  'manageStorage.summary.alert.cancel': 'Your storage plan will be cancelled on {date}.',
  'manageStorage.autoUpgrade.switch': 'Enable auto-upgrade',
  'manageStorage.autoUpgrade.tooltip': 'Your plan will be auto-upgraded when you exceed the storage limit.',
  'manageStorage.supportDescription':
    'Please get in <link>touch with our support team</link> if you need storage space exceeding 100 GB.',

  'manageSubscription.paymentMethod': 'Payment method',
  'manageSubscription.paymentMethod.link': 'Update payment method',
  'manageSubscription.paymentMethod.cardEndingIn': '{cardBrand} ending in {cardLastDigits}',
  'manageSubscription.paymentMethod.cardExpires': 'Expires {cardExp}',

  'manageSubscription.summary': 'Summary',
  'manageSubscription.summary.total': 'Total amount',
  'manageSubscription.summary.storage': 'Storage',
  'manageSubscription.summary.storagePricePerMonth': '{size} x {price}/month',

  'manageStorage.validation.required': 'Please select a storage plan',
}

export default manageStorage
