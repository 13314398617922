import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadingSelector } from 'state/data/selectors'
import { deactivateServiceEndpoint } from 'state/concepts/services/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

const { endpoint } = deactivateServiceEndpoint

class LogoRemoveModal extends React.Component {
  static defaultProps = {
    closeAction: null,
  }

  static propTypes = {
    removeAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    closeAction: PropTypes.func,
    removeBodyText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  handleRemoveImage = () => {
    const { onClose, removeAction } = this.props

    onClose()
    removeAction()
  }

  handleClose = () => {
    const { onClose, closeAction } = this.props

    onClose()
    closeAction && closeAction()
  }

  render = () => (
    <ConfirmModal
      {...this.props}
      onClose={this.handleClose}
      onSubmit={this.handleRemoveImage}
      title={{ id: 'uploadLogo.deleteImage' }}
      bodyText={this.props.removeBodyText}
      submitText={{ id: 'uploadLogo.remove' }}
      kind="danger"
    />
  )
}

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, endpoint),
})

export { LogoRemoveModal as LogoRemoveModalContainer }
export default connect(mapStateToProps)(LogoRemoveModal)
