import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_TWO_FACTOR_SETTINGS } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { hideModal, showModal } from 'state/modal/actions'
import { updateTwoFactorSettingsEndpoint } from '../endpoints'

const updateTwoFactorSettingsOperation = createLogic({
  type: UPDATE_TWO_FACTOR_SETTINGS,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { endpoint, url } = updateTwoFactorSettingsEndpoint
    const { password, phoneNumber, otpCode, methodChecker } = values
    const params = {
      password,
      phone_number: phoneNumber,
      otp_code: otpCode,
      method_checker: methodChecker,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })
      const backupCodes = data.meta?.backup_codes

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.employeeTwoFactorSettingsUpdated',
          },
        }),
      )
      dispatch(
        showModal({
          modalType: 'FRESH_BACKUP_CODES_MODAL',
          modalProps: {
            backupCodes,
            setupMethodChecker: methodChecker,
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form?.setSubmitting(false)
    }
    done()
  },
})

export default updateTwoFactorSettingsOperation
