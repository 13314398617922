import fetchPaymentSettings from './fetchPaymentSettings'
import connectStripe from './connectStripe'
import disconnectStripe from './disconnectStripe'
import getStripeConnectLink from './getStripeConnectLink'
import getPayPalConnectLink from './getPayPalConnectLink'
import disconnectManualPayment from './disconnectManualPayment'
import updateManualPayment from './updateManualPayment'
import createManualPayment from './createManualPayment'
import receivePayPalAccount from './receivePayPalAccount'
import disconnectPaypal from './disconnectPaypal'
import validateDisconnectStripe from './validateDisconnectStripe'
import updateStripePos from './updateStripePos'

export default [
  fetchPaymentSettings,
  connectStripe,
  disconnectStripe,
  getStripeConnectLink,
  getPayPalConnectLink,
  disconnectManualPayment,
  updateManualPayment,
  updateStripePos,
  createManualPayment,
  receivePayPalAccount,
  disconnectPaypal,
  validateDisconnectStripe,
]
