import ceil from 'lodash/ceil'
import { DateTime, Duration, FixedOffsetZone, Interval } from 'luxon'
import { __, curry, head, keys, pipe } from 'ramda'

import { DAYS_PER_WEEK, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'
import isPresent from 'utils/isPresent'

export const formatDate = date => date.toFormat('MMMM d, yyyy')

export const secondsToTimeFromMidnight = (seconds, isShort = false) =>
  DateTime.fromSeconds(seconds, { zone: 'utc' })
    .toFormat(isShort ? 'h:mm' : 'h:mm a')
    .replace(':00', '')
    .toLowerCase()

export const formattedIntervalFromSeconds = (start, end) => {
  const parsedStart = DateTime.fromSeconds(start, { zone: 'utc' })
  const parsedEnd = DateTime.fromSeconds(end, { zone: 'utc' })
  const startTime = parsedStart.toFormat('h:mm').replace(':00', '')
  const endTime = parsedEnd.toFormat('h:mm').replace(':00', '')
  const startMeridian = parsedStart.toFormat('a')
  const endMeridian = parsedEnd.toFormat('a')

  if (startMeridian === endMeridian) {
    return `${startTime}–${endTime} ${endMeridian}`.toLowerCase()
  }

  return `${startTime} ${startMeridian}–${endTime} ${endMeridian}`.toLowerCase()
}

export const timeFromISO = ISOString => DateTime.fromISO(ISOString).toFormat('t')

export const fullDateFromISO = ISOString => DateTime.fromISO(ISOString).toFormat('t, MMMM d, yyyy')

export const formatDateFromISOString = ISOString => formatDate(DateTime.fromISO(ISOString))

export const isDaysEquals = (date, otherDate) =>
  date.year === otherDate.year && date.month === otherDate.month && date.day === otherDate.day

export const isWeeksEquals = (date, otherDate) =>
  date.year === otherDate.year && date.weekNumber === otherDate.weekNumber

export const lastDateOfMonthISO = date => date.endOf('month').toISODate()

export const firstDateOfMonthISO = date => date.startOf('month').toISODate()

export const dateToISO = date => date.toISODate()

export const dateTimeFromISO = (ISOString, timezoneOffset) => {
  if (timezoneOffset) {
    return DateTime.fromISO(ISOString, { zone: FixedOffsetZone.parseSpecifier(`UTC${timezoneOffset}`) })
  }
  return DateTime.fromISO(ISOString)
}

export const timeToLuxon = (ISOString, timezoneOffset) => {
  const zone = FixedOffsetZone.parseSpecifier(`UTC${timezoneOffset}`)

  return DateTime.fromISO(ISOString, { zone }).setZone('local', { keepLocalTime: true })
}

export const dateTimeFromJSDate = time => DateTime.fromJSDate(time)

export const getLocalTime = timezoneOffset => {
  if (timezoneOffset) {
    return DateTime.local({ zone: FixedOffsetZone.parseSpecifier(`UTC${timezoneOffset}`) })
  }
  return DateTime.local()
}

export const isPastDate = (ISOString, timezoneOffset) =>
  dateTimeFromISO(ISOString, timezoneOffset).endOf('day') < getLocalTime(timezoneOffset).endOf('day')

export const isToday = (ISOString, timezoneOffset) =>
  dateTimeFromISO(ISOString, timezoneOffset).day === getLocalTime(timezoneOffset).day

export const isFutureDate = (ISOString, timezoneOffset) =>
  dateTimeFromISO(ISOString, timezoneOffset).endOf('day') > getLocalTime(timezoneOffset).endOf('day')

export const dateHour = date => DateTime.fromISO(date, { setZone: true }).hour

export const haveSameMonth = (date, otherDate) => date.hasSame(otherDate, 'month')

export const formattedTime = date => DateTime.fromISO(date, { setZone: true }).toFormat('t').toLowerCase()

export const formattedShortDate = date => DateTime.fromISO(date).toFormat('DDD')

export const formattedShortestDate = date => DateTime.fromISO(date).toFormat('DD')

export const formattedShortDateForTrial = date => DateTime.fromISO(date).toFormat('d MMMM, yyyy')

export const formattedDate = date => `${formattedShortestDate(date)}, ${formattedTime(date)}`

export const nearestSlotsDate = pipe(keys, head, curry(DateTime.fromISO)(__, { setZone: true }))

export const formattedCardExpiry = date => DateTime.fromISO(date).toFormat('MM/yy')

export const checkCardExpired = date => parseInt(DateTime.fromISO(date).diffNow('months').values.months, 10) <= 0

export const formatDay = day => {
  const date = DateTime.fromISO(day)

  if (isDaysEquals(date, DateTime.local())) {
    return { id: 'shared.today' }
  }
  if (isDaysEquals(date, DateTime.local().minus({ days: 1 }))) {
    return { id: 'shared.yesterday' }
  }
  if (date.hasSame(DateTime.local(), 'year')) {
    return { id: 'shared.otherDay', values: { date: date.toFormat('EEEE, MMM d') } }
  }
  return { id: 'shared.otherDay', values: { date: date.toFormat('EEEE, MMM d, yyyy') } }
}

export const createdAtDayTime = (day, isEdited = false) => {
  const date = DateTime.fromISO(day)

  const localeKey = isEdited ? 'shared.updatedAt' : 'shared.createdAt'

  if (isDaysEquals(date, DateTime.local())) {
    return { id: `${localeKey}.today`, values: { time: date.toFormat('t').toLowerCase() } }
  }
  if (isDaysEquals(date, DateTime.local().minus({ days: 1 }))) {
    return { id: `${localeKey}.yesterday`, values: { time: date.toFormat('t').toLowerCase() } }
  }
  if (date.hasSame(DateTime.local(), 'year')) {
    return {
      id: `${localeKey}.otherDay`,
      values: { date: date.toFormat('MMM d'), time: date.toFormat('t').toLowerCase() },
    }
  }
  return {
    id: `${localeKey}.otherDay`,
    values: { date: date.toFormat('MMM d, yyyy'), time: date.toFormat('t').toLowerCase() },
  }
}

export const reviewCreatedAtDay = day => {
  const date = DateTime.fromISO(day)
  const localDateTime = DateTime.local()
  const { days } = Interval.fromDateTimes(date, localDateTime).toDuration(['days']).toObject()
  const daysCount = Math.trunc(days)

  if (daysCount === 0) {
    return { id: 'shared.today' }
  }
  if (daysCount === 1) {
    return { id: 'shared.yesterday' }
  }
  if (daysCount < DAYS_PER_WEEK) {
    return { id: 'shared.formatTimeDays', values: { count: daysCount } }
  }
  if (daysCount === DAYS_PER_WEEK) {
    return { id: 'shared.weekAgo' }
  }
  if (date.hasSame(localDateTime, 'year')) {
    return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d') } }
  }
  return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d, yyyy') } }
}

export const reviewUpdatedAtDay = day => {
  const date = DateTime.fromISO(day)
  const localDateTime = DateTime.local()
  const { days } = Interval.fromDateTimes(date, localDateTime).toDuration(['days']).toObject()
  const daysCount = Math.trunc(days)

  if (isDaysEquals(date, localDateTime)) {
    return { id: 'company.reviews.timeStamp.updatedToday' }
  }
  if (isDaysEquals(date, localDateTime.minus({ days: 1 }))) {
    return { id: 'company.reviews.timeStamp.updatedYesterday' }
  }
  if (daysCount < DAYS_PER_WEEK) {
    return { id: 'company.reviews.timeStamp.updatedFormatTimeDays', values: { count: daysCount } }
  }
  if (daysCount === DAYS_PER_WEEK) {
    return { id: 'company.reviews.timeStamp.updatedWeekAgo' }
  }
  if (date.hasSame(localDateTime, 'year')) {
    return { id: 'company.reviews.timeStamp.updatedOtherDay', values: { date: date.toFormat('MMM d') } }
  }
  return { id: 'company.reviews.timeStamp.updatedOtherDay', values: { date: date.toFormat('MMM d, yyyy') } }
}

export const reviewTimestamp = (createdAt, updatedAt) =>
  createdAt === updatedAt ? reviewCreatedAtDay(createdAt) : reviewUpdatedAtDay(updatedAt)

export const formatTime = startTime => {
  const time = Interval.fromDateTimes(DateTime.fromISO(startTime), DateTime.local())
    .toDuration(['days', 'hours', 'minutes'])
    .toObject()
  const { days, hours, minutes } = time

  if (days) {
    return { id: 'shared.formatTimeDays', values: { count: days } }
  }
  if (hours) {
    return { id: 'shared.formatTimeHours', values: { count: hours } }
  }
  return { id: 'shared.formatTimeMinutes', values: { count: ceil(minutes) || 1 } }
}

export const formattedTimeZoned = (ISOString, timezoneOffset) =>
  dateTimeFromISO(ISOString, timezoneOffset).toFormat('t').toLowerCase()

export const formattedShortDateZoned = (ISOString, timezoneOffset) =>
  dateTimeFromISO(ISOString, timezoneOffset).toFormat('DDD')

export const formatInputDate = date => date.toFormat('MMM d, yyyy')

export const taskDueDateDay = (day, timezoneOffset) => {
  const date = dateTimeFromISO(day, timezoneOffset)
  const localTime = getLocalTime(timezoneOffset)

  if (isDaysEquals(date, localTime)) {
    return { id: 'shared.today' }
  }
  if (isDaysEquals(date, localTime.minus({ days: 1 }))) {
    return { id: 'shared.yesterday' }
  }
  if (isDaysEquals(date, localTime.plus({ days: 1 }))) {
    return { id: 'shared.tomorrow' }
  }
  if (date.hasSame(localTime, 'year')) {
    return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d') } }
  }
  return { id: 'shared.otherDay', values: { date: date.toFormat('MMM d, yyyy') } }
}

export const formattedMonthDate = date => DateTime.fromISO(date).toFormat('d MMM')

export const formattedDayRange = (start, end) => {
  const startedAt = formattedMonthDate(start)
  const endingAt = formattedMonthDate(end)

  return `${startedAt} - ${endingAt}`
}

export const formattedFullDate = date => DateTime.fromISO(date).toFormat('MMMM, d, yyyy')

export const formatCountdownSeconds = seconds => Duration.fromObject({ seconds }).toFormat('mm:ss')

export const secondsFromDateTime = dateTime =>
  dateTime.hour * MINUTES_PER_HOUR * SECONDS_PER_MINUTE + dateTime.minute * SECONDS_PER_MINUTE + dateTime.second

export const toJSDate = date => {
  const timeZoneOffset = date?.zone?.fixed

  const d = date?.toJSDate()

  if (isPresent(timeZoneOffset)) {
    d.setTime(d.getTime() + timeZoneOffset * 60000)
  }

  return d
}

export const formExpiryDateDay = (expiresAt, timezoneOffset) =>
  formattedShortestDate(dateTimeFromISO(expiresAt, timezoneOffset))

export const expiryDateDay = (destroyRequestedAt, totalExpiryDays) =>
  parseInt(totalExpiryDays - Math.abs(DateTime.fromISO(destroyRequestedAt).diffNow('days').values.days), 10)
