import { combineReducers } from '@reduxjs/toolkit'

import { SET_CHANGE_SUBSCRIPTION_DETAILS_ID, SET_STORAGE_PLANS, SET_SUBSCRIPTION_PLANS } from './types'

const subscriptionPlansIds = (state = [], action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_PLANS:
      return action.subscriptionPlansIds
    default:
      return state
  }
}

const storagePlansIds = (state = [], action) => {
  switch (action.type) {
    case SET_STORAGE_PLANS:
      return action.storagePlansIds
    default:
      return state
  }
}

const changeSubscriptionDetailsId = (state = null, action) => {
  switch (action.type) {
    case SET_CHANGE_SUBSCRIPTION_DETAILS_ID:
      return action.changeSubscriptionDetailsId
    default:
      return state
  }
}

const subscriptionPlansReducer = combineReducers({
  subscriptionPlansIds,
  storagePlansIds,
  changeSubscriptionDetailsId,
})

export default subscriptionPlansReducer
