import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import emptyAttachments from 'assets/images/chat/empty_files.svg'
import isPresent from 'utils/isPresent'
import AttachedFile from 'views/shared/AttachedFile'
import Spinner from 'views/shared/icons/Spinner'
import Loader from './Loader'

const AttachmentsListComponent = ({ isInitialLoading, chat, attachments, onShowPreview }) => {
  if (isInitialLoading !== false) {
    return <Spinner />
  }

  return isPresent(attachments) ? (
    <div className="overflow-y-auto" data-cy="chat-info-modal-files">
      {attachments.map(attachment => (
        <AttachedFile {...attachment} isAttachmentsList key={attachment.id} onClick={onShowPreview} />
      ))}
      <Loader chat={chat} />
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center full-height">
      <div className="text-center">
        <div className="chat-msgs__chat-img">
          <Image src={emptyAttachments} alt="No results" data-cy="main-page-empty-image" height={136} width={136} />
        </div>
        <p className="chat-msgs__chat-empty-txt mb-0" data-cy="main-page-empty-text">
          <FormattedMessage id="chat.detailsModal.attachments.empty" />
        </p>
      </div>
    </div>
  )
}

AttachmentsListComponent.defaultProps = {
  isInitialLoading: undefined,
  attachments: [],
}

AttachmentsListComponent.propTypes = {
  isInitialLoading: PropTypes.bool,
  chat: PropTypes.shape().isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  onShowPreview: PropTypes.func.isRequired,
}

export default AttachmentsListComponent
