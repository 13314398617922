import { FormattedMessage } from 'react-intl'

import CalendarO from 'views/shared/icons/CalendarO'
import CircleChecked from 'views/shared/icons/CircleChecked'
import Text from 'views/shared/icons/Text'
import CheckedSquare from 'views/shared/icons/CheckedSquare'

export const MAX_CUSTOM_FIELD_NAME_LENGTH = 64

export const MAX_OPTION_NAME_LENGTH = 250

export const CUSTOM_FIELD_MODAL_ACTIONS = {
  create: 'create',
  update: 'update',
}

export const FIELD_TYPES = {
  text: 'text',
  radioButton: 'radio_button',
  checkBox: 'check_box',
  date: 'date',
}

export const FIELD_TYPES_OPTIONS = [
  {
    value: FIELD_TYPES.text,
    key: FIELD_TYPES.text,
    content: (
      <div className="d-flex align-items-center">
        <Text size={18} color="#B2BAC7" className="mr-12 custom-field-icon" />
        <div className="option-description-wrapper">
          <FormattedMessage id="clientFields.fieldType.text" />
          <p className="text-caption in-blue-gray-300 option-description mb-0">
            <FormattedMessage id="clientFields.fieldType.description.text" />
          </p>
        </div>
      </div>
    ),
  },
  {
    value: FIELD_TYPES.radioButton,
    key: FIELD_TYPES.radioButton,
    content: (
      <div className="d-flex align-items-center">
        <CircleChecked size={18} color="#B2BAC7" className="mr-12 custom-field-icon" />
        <div className="option-description-wrapper">
          <FormattedMessage id="clientFields.fieldType.radioButton" />
          <p className="text-caption in-blue-gray-300 option-description mb-0">
            <FormattedMessage id="clientFields.fieldType.description.radioButton" />
          </p>
        </div>
      </div>
    ),
  },
  {
    value: FIELD_TYPES.checkBox,
    key: FIELD_TYPES.checkBox,
    content: (
      <div className="d-flex align-items-center">
        <CheckedSquare size={18} color="#B2BAC7" className="mr-12 custom-field-icon" />
        <div className="option-description-wrapper">
          <FormattedMessage id="clientFields.fieldType.checkBox" />
          <p className="text-caption in-blue-gray-300 option-description mb-0">
            <FormattedMessage id="clientFields.fieldType.description.checkBox" />
          </p>
        </div>
      </div>
    ),
  },
  {
    value: FIELD_TYPES.date,
    key: FIELD_TYPES.date,
    content: (
      <div className="d-flex align-items-center">
        <CalendarO size={20} color="#B2BAC7" className="mr-12 custom-field-icon" />
        <div className="option-description-wrapper">
          <FormattedMessage id="clientFields.fieldType.date" />
          <p className="text-caption in-blue-gray-300 option-description mb-0">
            <FormattedMessage id="clientFields.fieldType.description.date" />
          </p>
        </div>
      </div>
    ),
  },
]

export const MAX_FIELD_OPTIONS_COUNT = 50
