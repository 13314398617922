import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import axios from 'axios'

import requestErrorHandler from 'lib/requestErrorHandler'
import { API_HOST } from 'lib/constants'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_TIMEZONES } from 'state/concepts/timezones/types'
import { getTimezonesEndpoint } from '../endpoints'

const getTimezonesOperation = createLogic({
  type: GET_TIMEZONES,
  latest: true,

  async process(_params, dispatch, done) {
    const { endpoint, url } = getTimezonesEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await axios({ method: 'get', url, baseURL: `${API_HOST}/api/v1` })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getTimezonesOperation
