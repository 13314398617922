import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { DEFAULT_ENTITIES_PAGE_SIZE } from 'lib/constants/website'
import requestErrorHandler from 'lib/requestErrorHandler'
import { websiteHttpClient } from 'lib/httpClient'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_PUBLIC_WEBSITE_REVIEWS } from 'state/concepts/website/types'
import { fetchPublicWebsiteReviewsEndpoint } from 'state/concepts/website/endpoints'
import { websiteSelector } from 'state/concepts/website/selectors'
import { addReviewIds } from 'state/concepts/website/actions'

const fetchPublicWebsiteReviewsOperation = createLogic({
  type: FETCH_PUBLIC_WEBSITE_REVIEWS,
  latest: true,

  async process({ action: { pageParams }, getState }, dispatch, done) {
    const state = getState()
    const {
      widget: { id },
      workspace: { uniqCode },
    } = websiteSelector(state)
    const { url, endpoint } = fetchPublicWebsiteReviewsEndpoint(uniqCode, id)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const params = {
        include: 'service,user-profile,client-profile',
        page: {
          size: DEFAULT_ENTITIES_PAGE_SIZE,
          ...pageParams,
        },
      }

      const { data } = await websiteHttpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addReviewIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchPublicWebsiteReviewsOperation
