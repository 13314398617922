import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { guestLinkExpiredRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import { ACCEPT_BOOKING } from 'state/concepts/guestBookings/types'
import { acceptBookingEndpoint } from 'state/concepts/booking/endpoints'

const acceptBookingOperation = createLogic({
  type: ACCEPT_BOOKING,
  latest: true,

  async process({ httpClient, action: { workspaceCode, uniqCode, res } }, dispatch, done) {
    const { url } = acceptBookingEndpoint(uniqCode)
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    try {
      await httpClient.post(url, {}, config)
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }
    done()
  },
})

export default acceptBookingOperation
