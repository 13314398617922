import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { ADD_CLIENT_FEED_IDS, RESET_CLIENT_FEED, REMOVE_CLIENT_FEED_IDS } from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_FEED_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_CLIENT_FEED_IDS:
      return without(action.ids, state)
    case RESET_CLIENT_FEED:
      return []
    default:
      return state
  }
}

export default combineReducers({
  ids,
})
