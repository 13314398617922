import * as types from './types'

export const fetchDateAvailability = ({ startDate, endDate, userId, timezone, needToSetEmployeeTimezone = false }) => ({
  type: types.FETCH_DATE_AVAILABILITY,
  startDate,
  endDate,
  userId,
  needToSetEmployeeTimezone,
  timezone,
})

export const updateDateAvailability = (availabilities, startDate, endDate, userId, form) => ({
  type: types.UPDATE_DATE_AVAILABILITY,
  availabilities,
  startDate,
  endDate,
  userId,
  form,
})

export const setDateAvailability = dateAvailabilityIds => ({
  type: types.SET_DATE_AVAILABILITY,
  dateAvailabilityIds,
})

export const resetDateAvailability = () => ({
  type: types.RESET_DATE_AVAILABILITY,
})

export const setTimezone = timezone => ({
  type: types.SET_TIMEZONE,
  timezone,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})
