import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import SelectField from 'views/shared/SelectField'
import { formCategoriesForFilterSelector } from 'state/concepts/formCategories/selectors'
import { deleteFormCategory } from 'state/concepts/formCategories/actions'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { boldText } from 'utils/locales'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'

const RemoveFormCategoryModal = ({ onClose, formCategory }) => {
  const { id, formsCount } = formCategory
  const hasForms = formsCount > 0
  const formCategories = useSelector(formCategoriesForFilterSelector)
  const filteredFormCategories = formCategories.filter(item => item.value !== id)
  const handleSubmit = useFormSubmit(deleteFormCategory)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="formCategories.removeModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            id,
            newFormCategoryId: undefined,
          }}
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              {!hasForms && (
                <p>
                  <FormattedMessage id="formCategories.removeModal.noForms" />
                </p>
              )}
              {hasForms && (
                <>
                  <p>
                    <FormattedMessage
                      id="formCategories.removeModal.formsAssigned"
                      values={{
                        formsCount,
                        b: boldText,
                      }}
                    />
                  </p>
                  <p>
                    <FormattedMessage id="formCategories.removeModal.selectNewCategory" />
                  </p>
                  <Field
                    className="mt-4"
                    name="newFormCategoryId"
                    component={SelectField}
                    id="category"
                    label={{ id: 'formCategories.removeModal.newCategoryLabel' }}
                    showSearch
                    options={filteredFormCategories}
                    placeholder={{ id: 'formCategories.removeModal.newCategoryPlaceholder' }}
                  />
                </>
              )}
            </div>
            <div className="main-modal__footer">
              <div className="d-flex justify-content-end">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton
                  allowPristine={!hasForms}
                  kind="danger"
                  text={{ id: 'formCategories.removeModal.submitText' }}
                />
              </div>
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

RemoveFormCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formCategory: PropTypes.shape({
    id: PropTypes.string,
    formsCount: PropTypes.number,
  }).isRequired,
}

export default RemoveFormCategoryModal
