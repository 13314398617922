import { DateTime } from 'luxon'
import { concat, difference, find, pluck, propEq } from 'ramda'

import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import { CLIENT_TYPES, TIME_PREFERENCES_TYPES } from 'lib/constants/waitlistRequests'

const dateFromJSDate = date => (date ? DateTime.fromJSDate(date) : null)

const requestDateTime = (date, timezoneOffset) => {
  if (!date) return null

  return timezoneOffset ? date.setZone(`UTC${timezoneOffset}`, { keepLocalTime: true }).toISO() : date.toISO()
}

const buildAvailabilityParams = ({ id, destroy, dateRange, timeType, startTime, endTime }, timezoneOffset) => {
  const isAnyTime = timeType === TIME_PREFERENCES_TYPES.anyTime
  const isBetween = timeType === TIME_PREFERENCES_TYPES.between

  return {
    id,
    _destroy: destroy,
    time_type: timeType,
    start_date: requestDateTime(dateFromJSDate(dateRange[0]), timezoneOffset),
    end_date: requestDateTime(dateFromJSDate(dateRange[1]), timezoneOffset),
    ...(!isAnyTime && {
      start_time: requestDateTime(startTime, timezoneOffset),
      end_time: isBetween ? requestDateTime(endTime, timezoneOffset) : null,
    }),
  }
}

const updatedAvailabilityPreferences = ({ availabilityPreferences, initialAvailabilityPreferences }) => {
  const getIds = pluck('id')

  const availabilityPreferencesRemoved = difference(
    getIds(initialAvailabilityPreferences),
    getIds(availabilityPreferences),
  ).map(id => ({
    id,
    destroy: true,
    ...find(propEq(id, 'id'))(initialAvailabilityPreferences),
  }))

  return concat(availabilityPreferences, availabilityPreferencesRemoved)
}

export const updateClientWaitlistRequestValuesToParams = (values, timezoneOffset) => ({
  availability_preferences: updatedAvailabilityPreferences(values).map(availability =>
    buildAvailabilityParams(availability, timezoneOffset),
  ),
})

export const buildCreateParams = (
  {
    userProfileId,
    serviceId,
    clientProfileId,
    noteText,
    locationId,
    availabilityPreferences,
    clientType,
    clientProfile,
  },
  timezoneOffset,
) => {
  const isExistingClient = clientType === CLIENT_TYPES.existing

  return {
    user_profile_id: userProfileId,
    service_id: serviceId,
    client_profile_id: isExistingClient ? clientProfileId : null,
    location_id: locationId === ONLINE_LOCATION_OPTION.value ? '' : locationId,
    note_text: noteText,
    availability_preferences: availabilityPreferences.map(availability =>
      buildAvailabilityParams(availability, timezoneOffset),
    ),
    ...(!isExistingClient && {
      client_profile: {
        first_name: clientProfile.firstName,
        last_name: clientProfile.lastName,
        email: clientProfile.email,
        phone_number: clientProfile.phoneNumber,
      },
    }),
  }
}

export const buildUpdateParams = (values, timezoneOffset) => {
  const { userProfileId, serviceId, clientProfileId, noteText, locationId } = values

  return {
    user_profile_id: userProfileId,
    service_id: serviceId,
    client_profile_id: clientProfileId,
    location_id: locationId === ONLINE_LOCATION_OPTION.value ? '' : locationId,
    note_text: noteText,
    availability_preferences: updatedAvailabilityPreferences(values).map(availability =>
      buildAvailabilityParams(availability, timezoneOffset),
    ),
  }
}
