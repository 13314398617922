import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, not, omit, path, pipe, propEq, values } from 'ramda'
import build from 'redux-object'

import {
  currentPageSelector,
  dataSelector,
  endpointMetaSelector,
  loadingSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchMyBusinessFilesEndpoint, fetchMyFolderToMoveEndpoint } from './endpoints'

export const myBusinessFileIdsSelector = path(['myBusinessFiles', 'ids'])
export const currentFolderIdSelector = path(['myBusinessFiles', 'currentFolderId'])
export const isDownloadingSectionOpenSelector = path(['myBusinessFiles', 'isDownloadingSectionOpen'])
export const isUploadingSectionOpenSelector = path(['myBusinessFiles', 'isUploadingSectionOpen'])

export const myBusinessFilesSelector = createSelector(
  [myBusinessFileIdsSelector, dataSelector],
  buildCollection('businessStorage'),
)

export const myBusinessFileByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'businessStorage', id) : {},
)

export const currentFolderSelector = createSelector([currentFolderIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'businessStorage', id) : {},
)

export const isBlankStateSelector = path(['myBusinessFiles', 'isBlankState'])

export const filtersSelector = path(['myBusinessFiles', 'filters'])

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(compose(not, isEmpty)), values, omit(['name'])),
)

export const appliedFiltersSelector = (state, name) => path(['myBusinessFiles', 'filters', name], state)

export const fetchMyBusinessFilesLoadingSelector = state =>
  loadingSelector(state, fetchMyBusinessFilesEndpoint.endpoint)

export const selectedIdsSelector = path(['myBusinessFiles', 'selectedIds'])

export const myBusinessFilesMetaSelector = state => endpointMetaSelector(state, fetchMyBusinessFilesEndpoint.endpoint)
export const myBusinessFilesCurrentPageSelector = state =>
  currentPageSelector(state, fetchMyBusinessFilesEndpoint.endpoint)
export const myBusinessFilesNextPageSelector = state => nextPageSelector(state, fetchMyBusinessFilesEndpoint.endpoint)
export const myBusinessFilesPageCountSelector = state => pageCountSelector(state, fetchMyBusinessFilesEndpoint.endpoint)

export const uploadFilesSelector = path(['myBusinessFiles', 'uploadFiles'])

export const downloadingFilesSelector = path(['myBusinessFiles', 'downloadFiles'])

export const pendingFilesDownloadSelector = path(['myBusinessFiles', 'pendingFilesDownload'])

export const isLoadingDownloadSelector = createSelector(
  downloadingFilesSelector,
  pipe(values, any(propEq(true, 'isLoading'))),
)

export const foldersToMoveIdsSelector = path(['myBusinessFiles', 'folderToMoveIds'])

export const foldersToMoveSelector = createSelector(
  [foldersToMoveIdsSelector, dataSelector],
  buildCollection('businessStorage'),
)

export const myFoldersPathSelector = createSelector(
  [(state, fileId) => endpointMetaSelector(state, fetchMyFolderToMoveEndpoint(fileId).endpoint), dataSelector],
  (meta, data) => meta?.path?.map(([id, name]) => [build(data, 'businessStorage', id), name]) || [],
)
