import { createLogic } from 'redux-logic'
import snakeCase from 'lodash/snakeCase'

import requestErrorHandler from 'lib/requestErrorHandler'
import { SKIP_STEP } from 'state/concepts/tutorial/types'
import { updateTutorialStepEndpoint } from 'state/concepts/tutorial/endpoints'
import { dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { currentEmployeeTutorialSelector } from 'state/concepts/session/selectors'

const skipStepOperation = createLogic({
  type: SKIP_STEP,
  latest: true,

  async process({ action: { step }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = updateTutorialStepEndpoint

    try {
      const state = getState()

      await httpClient.put(url, { step: snakeCase(step) })

      const response = updateDataHelper(state.data, 'userTutorial', currentEmployeeTutorialSelector(state).id, {
        attributes: { [step]: true },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default skipStepOperation
