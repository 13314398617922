import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CardCustom = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M420.765 51.2c24.673 0 37.817 2.538 51.515 9.864 12.805 6.849 23.007 17.051 29.856 29.855 7.326 13.698 9.864 26.843 9.864 51.516v227.13c0 24.673-2.538 37.817-9.864 51.516-6.849 12.805-17.051 23.007-29.856 29.856-13.698 7.326-26.843 9.864-51.515 9.864H91.235c-24.672 0-37.818-2.538-51.516-9.864-12.805-6.849-23.008-17.051-29.855-29.856C2.538 407.383 0 394.238 0 369.566v-227.13c0-24.672 2.538-37.817 9.864-51.516 6.849-12.805 17.051-23.007 29.855-29.855 13.698-7.326 26.843-9.864 51.516-9.864zm40.01 179.2H51.2l.025 143.503c.171 13.545 1.227 18.244 3.788 23.033 2.077 3.882 4.971 6.776 8.852 8.853 5.267 2.817 10.426 3.813 27.37 3.813l333.868-.025c13.545-.171 18.244-1.227 23.033-3.788 3.882-2.077 6.776-4.971 8.853-8.853 2.817-5.267 3.813-10.426 3.813-27.37l-.026-139.165zm-332.776 76.8c14.139 0 25.6 11.462 25.6 25.601s-11.462 25.601-25.6 25.601c-14.139 0-25.6-11.462-25.6-25.601s11.462-25.601 25.6-25.601zm179.201 0c14.139 0 25.601 11.462 25.601 25.601s-11.462 25.601-25.601 25.601h-76.8c-14.138 0-25.601-11.462-25.601-25.601S216.261 307.2 230.4 307.2h76.8zm117.903-204.775H86.898c-13.545.171-18.244 1.227-23.032 3.788-3.883 2.076-6.776 4.971-8.852 8.853-2.816 5.267-3.813 10.426-3.813 27.369v36.764h409.575v-41.102c-.152-12.04-1.003-17.09-2.989-21.427l-.798-1.605c-2.077-3.882-4.971-6.776-8.853-8.852-5.267-2.817-10.426-3.813-27.37-3.813z" />
  </svg>
)

CardCustom.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CardCustom.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CardCustom
