import SettingsO from 'views/shared/icons/SettingsO'
import { companyFeaturesRoute, companySettingsRoute } from 'lib/routes'
import Puzzle from 'views/shared/icons/Puzzle'
import { FormattedMessage } from 'react-intl'

export const MAX_POSITION_INPUT_LENGTH = 70

export const ONBOARDING_STEPS_NAMES = {
  generalInfo: 'general_info',
  workspaceProfile: 'workspace_profile',
  completed: 'completed',
}

export const ONBOARDING_STEPS = {
  [ONBOARDING_STEPS_NAMES.completed]: 0,
  [ONBOARDING_STEPS_NAMES.generalInfo]: 1,
  [ONBOARDING_STEPS_NAMES.workspaceProfile]: 2,
}

export const EMPLOYEE_ROLES = {
  workspace_owner: 'employees.roles.workspaceOwner',
  user: 'employees.invitations.roleOptions.user',
  administrator: 'employees.roles.administrator',
  scheduler: 'employees.roles.scheduler',
}

export const ALL_EMPLOYEE_ROLES = ['workspace_owner', 'administrator', 'user', 'scheduler']

export const EMPLOYEES_ROLES_FILTERS = [
  {
    value: 'administrator',
    label: <FormattedMessage id="employeesFilters.roles.administrator" />,
  },
  {
    value: 'scheduler',
    label: <FormattedMessage id="employeesFilters.roles.scheduler" />,
  },
  {
    value: 'user',
    label: <FormattedMessage id="employeesFilters.roles.experts" />,
  },
]

export const EMPLOYEE_STATUS = {
  pending: 'pending',
  active: 'active',
  inactive: 'inactive',
}

export const EMPLOYEES_STATUSES_FILTERS = [
  {
    value: EMPLOYEE_STATUS.active,
    label: <FormattedMessage id="shared.filters.statuses.active" />,
  },
  {
    value: EMPLOYEE_STATUS.inactive,
    label: <FormattedMessage id="shared.filters.statuses.inactive" />,
  },
  {
    value: EMPLOYEE_STATUS.pending,
    label: <FormattedMessage id="shared.filters.statuses.pending" />,
  },
]

export const getEmployeesSidebarBottomLinks = permissions => [
  {
    label: 'companySidebar.features',
    Icon: Puzzle,
    href: companyFeaturesRoute,
    dataCy: 'sidebar-nav-menu-item-features',
    isHidden: !permissions.manageFeaturesAndIntegrations,
  },
  {
    label: 'settings.title',
    dataCy: 'sidebar-nav-menu-item-settings',
    Icon: SettingsO,
    href: companySettingsRoute,
  },
]

export const EMPLOYEE_REMOVE_VALUE = {
  deleteFiles: 'delete_files',
  newFileOwner: 'new_file_owner',
}

export const EMPLOYEE_REMOVE_OPTIONS = [
  {
    value: EMPLOYEE_REMOVE_VALUE.deleteFiles,
    label: { id: 'employeesRemoveModal.option.deleteFiles' },
  },
  {
    value: EMPLOYEE_REMOVE_VALUE.newFileOwner,
    label: { id: 'employeesRemoveModal.option.transferOwnershipToAnotherTeamMember' },
  },
]
