import { createSelector } from '@reduxjs/toolkit'
import { ascend, filter, identity, map, path, pipe, pluck, prop, sort } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import isWidgetServiceDisabled from 'utils/widgets/isWidgetServiceDisabled'
import { fetchWidgetEndpoint, fetchWidgetServicesEndpoint } from './endpoints'

export const widgetActiveTabSelector = path(['widget', 'activeTab'])
export const widgetIdSelector = path(['widget', 'widgetId'])
export const widgetServicesIdsSelector = path(['widget', 'widgetServicesIds'])
export const isBlankStateSelector = path(['widget', 'isBlankState'])

export const widgetSelector = createSelector([dataSelector, (_, id) => id], (data, id) =>
  id ? build(data, 'widget', id) : null,
)

export const currentWidgetSelector = createSelector([dataSelector, widgetIdSelector], (data, id) =>
  id ? build(data, 'widget', id) : null,
)

export const widgetLogoSelector = createSelector(currentWidgetSelector, prop('logoUrl'))

export const widgetServicesSelector = createSelector(
  [widgetServicesIdsSelector, dataSelector],
  buildCollection('service'),
)

export const widgetServicesOptionsSelector = createSelector(
  widgetServicesSelector,
  map(service => ({
    key: service.id,
    value: service.id,
    label: service.name,
    parent: path(['serviceCategory', 'name'], service),
    disabled: isWidgetServiceDisabled(service),
    users: service.userProfiles,
  })),
)

export const connectedToWidgetServicesIdsSelector = createSelector(
  widgetServicesSelector,
  pipe(filter(prop('connectedToWidget')), pluck('id'), sort(ascend(identity))),
)

export const widgetServicesLoadingSelector = state => loadingSelector(state, fetchWidgetServicesEndpoint.endpoint)
export const widgetLoadingSelector = (state, widgetId) => loadingSelector(state, fetchWidgetEndpoint(widgetId).endpoint)

export const connectedToWidgetServicesSelector = createSelector(
  [connectedToWidgetServicesIdsSelector, dataSelector],
  buildCollection('service'),
)

export const disabledWidgetServicesOptionsSelector = createSelector(
  connectedToWidgetServicesSelector,
  filter(isWidgetServiceDisabled),
)
