import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { addOutlookCalendarConnectionIds } from '../actions'
import { CONNECT_OUTLOOK_CALENDAR } from '../types'
import { connectOutlookCalendarEndpoint } from '../endpoints'

const connectOutlookCalendar = createLogic({
  type: CONNECT_OUTLOOK_CALENDAR,
  latest: true,

  async process({ action: { authenticationCode, state }, httpClient }, dispatch, done) {
    const { url, endpoint } = connectOutlookCalendarEndpoint
    try {
      const params = {
        code: authenticationCode,
        state,
        include: 'outlook-calendar-list-items',
      }
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addOutlookCalendarConnectionIds([data.data.id]))

      dispatch(showNotification({ messageObject: { id: 'notifications.calendarAdded' } }))
    } catch (error) {
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.failedToAddCalendar' },
          kind: 'error',
        }),
      )
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default connectOutlookCalendar
