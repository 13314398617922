import normalize from 'json-api-normalizer'
import { dissoc } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchNotificationSettingsEndpoint, updateNotificationSettingsEndpoint } from 'state/concepts/client/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import createNotificationParams from 'utils/guest/createNotificationParams'
import { GUEST_SUBSCRIBE } from '../types'

const guestSubscribe = createLogic({
  type: GUEST_SUBSCRIBE,
  latest: true,

  async process({ httpClient, action: { workspaceCode, isSubscribe } }, dispatch, done) {
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    const { url: fetchNotificationURL, endpoint: fetchNotificationEndpoint } = fetchNotificationSettingsEndpoint
    const { url: updateNotificationURL } = updateNotificationSettingsEndpoint

    dispatch(dataApiRequest({ endpoint: fetchNotificationEndpoint }))

    try {
      const { data } = await httpClient.get(fetchNotificationURL, config)

      let notificationSettings = build(normalize(data), 'clientNotificationSetting', data.data.id)
      notificationSettings = dissoc('id', notificationSettings)
      const params = createNotificationParams(notificationSettings, isSubscribe)

      await httpClient.put(updateNotificationURL, params, config)

      dispatch(dataApiSuccess({ endpoint: fetchNotificationEndpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }
    done()
  },
})

export default guestSubscribe
