import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isPresent from 'utils/isPresent'
import { currentChatUserIdSelector } from 'state/concepts/session/selectors'
import * as modalActions from 'state/modal/actions'
import { attachmentsSelector, attachmentsLoadingSelector } from 'state/concepts/chat/selectors'
import {
  fetchAttachments as fetchAttachmentsAction,
  resetAttachments as resetAttachmentsAction,
  removeAttachment as removeAttachmentAction,
} from 'state/concepts/chat/actions'
import AttachmentsListComponent from './component'

class AttachmentsList extends React.Component {
  static propTypes = {
    chat: PropTypes.shape().isRequired,
    fetchAttachments: PropTypes.func.isRequired,
    resetAttachments: PropTypes.func.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape()),
    showModal: PropTypes.func.isRequired,
    currentChatUserId: PropTypes.number.isRequired,
    removeAttachment: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    attachments: [],
    isLoading: undefined,
  }

  get isInitialLoading() {
    const { isLoading, attachments } = this.props

    return isLoading !== false && !isPresent(attachments)
  }

  componentDidMount() {
    const { chat, fetchAttachments } = this.props

    fetchAttachments(chat.id)
  }

  componentWillUnmount() {
    const { resetAttachments } = this.props

    resetAttachments()
  }

  canRemove = file => {
    const { currentChatUserId } = this.props

    return file.chatUserId === currentChatUserId
  }

  handleShowPreview = initialFileId => {
    const { showModal, attachments, removeAttachment } = this.props

    showModal({
      modalType: 'FILES_PREVIEW_MODAL',
      modalProps: {
        files: attachments,
        initialFileId,
        canRemove: this.canRemove,
        onRemoveFile: removeAttachment,
      },
    })
  }

  render = () => (
    <AttachmentsListComponent
      {...this.props}
      onShowPreview={this.handleShowPreview}
      isInitialLoading={this.isInitialLoading}
    />
  )
}

const mapDispatchToProps = {
  fetchAttachments: fetchAttachmentsAction,
  resetAttachments: resetAttachmentsAction,
  removeAttachment: removeAttachmentAction,
  ...modalActions,
}

const mapStateToProps = (state, { chat: { id } }) => ({
  attachments: attachmentsSelector(state),
  isLoading: attachmentsLoadingSelector(state, id),
  currentChatUserId: currentChatUserIdSelector(state),
})

export { AttachmentsList as AttachmentsListContainer }
export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsList)
