import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { openConnection as openConnectionAction } from 'state/concepts/webSockets/actions'
import { isClosedConnectionSelector, isOpenedConnectionSelector } from 'state/concepts/webSockets/selectors'

const WebSocketConnection = () => {
  const openConnection = useDispatchAction(openConnectionAction)
  const isOpened = useSelector(isOpenedConnectionSelector)
  const isClosed = useSelector(isClosedConnectionSelector)
  const openConnectionTimer = useRef(null)

  useMount(() => {
    if (!isOpened) {
      openConnection()
    }
  }, [isOpened])

  useEffect(() => {
    if (isClosed && !openConnectionTimer.current) {
      openConnectionTimer.current = setInterval(() => {
        openConnection()
      }, 3000)
    }

    if (isOpened && openConnectionTimer.current) {
      clearInterval(openConnectionTimer.current)
      openConnectionTimer.current = null
    }

    return () => {
      if (openConnectionTimer.current) {
        clearInterval(openConnectionTimer.current)
      }
    }
  }, [isOpened, isClosed, openConnection])

  return null
}

export default WebSocketConnection
