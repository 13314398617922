import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const widgetId = (state = null, action) => {
  switch (action.type) {
    case types.SET_WIDGET:
      return action.widgetId
    default:
      return state
  }
}

const widgetServicesIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_WIDGET_SERVICES:
      return action.widgetServicesIds
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_WIDGET_SERVICES_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const widgetReducer = combineReducers({
  widgetId,
  widgetServicesIds,
  isBlankState,
})

export default widgetReducer
