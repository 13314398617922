import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import redirect from 'utils/redirect'
import { hideModal as hideModalAction } from 'state/modal/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsEmployeesRoute } from 'lib/routes'
import NoMembersToAddModalComponent from './component'

class NoMembersToAddModal extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    currentWorkspaceCode: PropTypes.string.isRequired,
  }

  handleManageTeam = () => {
    const { hideModal, currentWorkspaceCode } = this.props

    hideModal()
    redirect({
      workspace: currentWorkspaceCode,
      href: companySettingsEmployeesRoute,
    })
  }

  render() {
    const { hideModal } = this.props

    return <NoMembersToAddModalComponent {...this.props} onManageTeam={this.handleManageTeam} onClose={hideModal} />
  }
}

const mapStateToProps = state => ({
  currentWorkspaceCode: currentWorkspaceCodeSelector(state),
})

const mapDispatchToProps = {
  hideModal: hideModalAction,
}

export { NoMembersToAddModal as NoMembersToAddModalContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps))(NoMembersToAddModal)
