import { createLogic } from 'redux-logic'

import { FILTER_SERVICES } from 'state/concepts/services/types'
import { setFilterParams, setCurrentPage, fetchServices } from 'state/concepts/services/actions'

const filterServicesOperation = createLogic({
  type: FILTER_SERVICES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(fetchServices())
    done()
  },
})

export default filterServicesOperation
