import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { ACCEPT_BOOKING } from 'state/concepts/booking/types'
import { acceptBookingEndpoint } from 'state/concepts/booking/endpoints'
import {
  removeClientAccountBookings,
  decrementPendingBookingsCount,
} from 'state/concepts/clientAccountBookings/actions'
import { showNotification } from 'state/notifications/actions'

const acceptBookingOperation = createLogic({
  type: ACCEPT_BOOKING,
  latest: true,

  async process({ httpClient, action: { uniqCode } }, dispatch, done) {
    const { endpoint, url } = acceptBookingEndpoint(uniqCode)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, {
        include: ['client-profile-forms', 'client-profile-forms.workspace', 'transactions', 'video-conference'],
      })
      const response = normalize(data)

      dispatch(removeClientAccountBookings([data.data.id]))
      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingWasAccepted' } }))
      dispatch(decrementPendingBookingsCount())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default acceptBookingOperation
