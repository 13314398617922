import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { updateEtherpadDocumentClientAccessEndpoint } from '../endpoints'
import { UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS } from '../types'

const updateEtherpadDocumentClientAccess = createLogic({
  type: UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { role, documentId, permissionId, clientId } = values
    const { url, endpoint } = updateEtherpadDocumentClientAccessEndpoint({ clientId, documentId, permissionId })
    const params = { role }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentClientAccessWasUpdated',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default updateEtherpadDocumentClientAccess
