import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_FORM_DETAILS } from 'state/concepts/intakeForm/types'
import { validateFormDetailsEndpoint } from 'state/concepts/intakeForm/endpoints'

const validateFormDetailsOperation = createLogic({
  type: VALIDATE_FORM_DETAILS,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { form, values, onNextStep } = action
    const { url } = validateFormDetailsEndpoint

    try {
      const params = {
        name: values.name,
        description: values.description,
        status: values.status,
        frequency: values.frequency,
        respondent: values.respondent,
        expiration_period: values.isExpiryPeriod ? values.expiryPeriod : 0,
        form_category_id: values.formCategoryId,
      }

      await httpClient.post(url, params)

      onNextStep()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default validateFormDetailsOperation
