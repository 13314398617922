export const namespace = 'paymentSettings'

export const FETCH_PAYMENT_SETTINGS = `${namespace}/FETCH_PAYMENT_SETTINGS`
export const DISCONNECT_STRIPE = `${namespace}/DISCONNECT_STRIPE`
export const CONNECT_STRIPE = `${namespace}/CONNECT_STRIPE`
export const GET_STRIPE_CONNECT_LINK = `${namespace}/GET_STRIPE_CONNECT_LINK`
export const SET_STRIPE_CONNECT_LINK = `${namespace}/SET_STRIPE_CONNECT_LINK`
export const DISCONNECT_MANUAL_PAYMENT = `${namespace}/DISCONNECT_MANUAL_PAYMENT`
export const UPDATE_MANUAL_PAYMENT = `${namespace}/UPDATE_MANUAL_PAYMENT`
export const CREATE_MANUAL_PAYMENT = `${namespace}/CREATE_MANUAL_PAYMENT`
export const SET_PAYPAL_CONNECT_LINK = `${namespace}/SET_PAYPAL_CONNECT_LINK`
export const GET_PAYPAL_CONNECT_LINK = `${namespace}/GET_PAYPAL_CONNECT_LINK`
export const RECEIVE_PAYPAL_ACCOUNT = `${namespace}/RECEIVE_PAYPAL_ACCOUNT`
export const DISCONNECT_PAYPAL = `${namespace}/DISCONNECT_PAYPAL`
export const VALIDATE_DISCONNECT_STRIPE = `${namespace}/VALIDATE_DISCONNECT_STRIPE`
export const SET_STRIPE_DISCONNECT_ERROR = `${namespace}/SET_STRIPE_DISCONNECT_ERROR`
export const RESET_STRIPE_DISCONNECT_ERROR = `${namespace}/RESET_STRIPE_DISCONNECT_ERROR`
export const UPDATE_STRIPE_POS = `${namespace}/UPDATE_STRIPE_POS`
export const SET_IS_CONNECT_STRIPE_POS = `${namespace}/SET_IS_CONNECT_STRIPE_POS`
