import {
  without,
  pipe,
  propEq,
  props,
  prop,
  values,
  all,
  equals,
  and,
  or,
  cond,
  T,
  always,
  propOr,
  any,
  not,
  when,
  propIs,
  lensProp,
  set,
  compose,
  filter,
  __,
} from 'ramda'
import { ACCOUNT_SETTINGS_ACTIONS, ACCOUNT_SETTINGS_GROUPS } from '../lib/constants/accountSettings'

const makeIsGroup = predicate => groupName =>
  pipe(
    values, // (values->all) because 'all` operates on List, optimize: `every` from lodash
    all(
      cond([
        [propEq(groupName, 'group'), predicate],
        [T, always(true)],
      ]),
    ),
  )

// ------------- Determine Disabled Helpers ---------------------------------

const makeIsNotificationTypeDisabled = type => pipe(prop(type), equals(undefined))

export const isGroupAppsDisabled = makeIsGroup(makeIsNotificationTypeDisabled('web'))
export const isGroupEmailDisabled = makeIsGroup(makeIsNotificationTypeDisabled('email'))
export const isGroupSmsDisabled = makeIsGroup(makeIsNotificationTypeDisabled('sms'))

// ------------- Determine Checked Helpers ---------------------------------

export const makeSettingChecked = (checked, settings = ACCOUNT_SETTINGS_GROUPS) =>
  pipe(props(settings), without([undefined]), all(equals(checked)))

export const isSettingChecked = makeSettingChecked(true)

const makeIsNotificationTypeChecked = type => pipe(propOr(true, type), equals(true))

export const isGroupChecked = groupName =>
  pipe(
    values, // (values->all) because 'all` operates on List, optimize: `every` from lodash
    filter(propEq(groupName, 'group')),
    any(pipe(props(ACCOUNT_SETTINGS_GROUPS), without([undefined]), any(equals(true)))),
  )

const isGroupAppsCheckedOrDisabled = makeIsGroup(makeIsNotificationTypeChecked('web'))
const isGroupEmailCheckedOrDisabled = makeIsGroup(makeIsNotificationTypeChecked('email'))
const isGroupSmsCheckedOrDisabled = makeIsGroup(makeIsNotificationTypeChecked('sms'))

export const isGroupAppsChecked = groupName => settings =>
  and(not(isGroupAppsDisabled(groupName)(settings)), isGroupAppsCheckedOrDisabled(groupName)(settings))

export const isGroupEmailChecked = groupName => settings =>
  and(not(isGroupEmailDisabled(groupName)(settings)), isGroupEmailCheckedOrDisabled(groupName)(settings))

export const isGroupSmsChecked = groupName => settings =>
  and(not(isGroupSmsDisabled(groupName)(settings)), isGroupSmsCheckedOrDisabled(groupName)(settings))

export const isEverythingUnchecked = pipe(
  values, // (values->all) because 'all` operates on List, optimize: `every` from lodash
  all(makeSettingChecked(false)),
)

export const isSmsUnchecked = pipe(values, all(makeSettingChecked(false, ['sms'])))

export const isEmailUnchecked = pipe(values, all(makeSettingChecked(false, ['email'])))

// ------------- Determine Partial Checked Helpers --------------------------

const makeGroupHasUnchecked = makePredicate => groupName =>
  pipe(
    values, // (values->all) because 'all` operates on List, optimize: `every` from lodash
    settings =>
      and(
        any(
          cond([
            [propEq(groupName, 'group'), makePredicate(false)],
            [T, always(false)],
          ]),
        )(settings),
        any(
          cond([
            [propEq(groupName, 'group'), makePredicate(true)],
            [T, always(false)],
          ]),
        )(settings),
      ),
  )

export const settingHasUnchecked = pipe(props(ACCOUNT_SETTINGS_GROUPS), without([undefined]), setting =>
  and(any(equals(false))(setting), any(equals(true))(setting)),
)

export const groupHasUnchecked = makeGroupHasUnchecked(
  value => setting => or(propEq(value, 'web', setting), propEq(value, 'email', setting), propEq(value, 'sms', setting)),
)
export const groupAppsHasUnchecked = makeGroupHasUnchecked(propEq(__, 'web'))
export const groupEmailHasUnchecked = makeGroupHasUnchecked(propEq(__, 'email'))
export const groupSmsHasUnchecked = makeGroupHasUnchecked(propEq(__, 'sms'))

export const changeAllSettingsValues = bool =>
  compose(
    when(propIs(Boolean, 'email'), set(lensProp('email'), bool)),
    when(propIs(Boolean, 'web'), set(lensProp('web'), bool)),
    when(propIs(Boolean, 'sms'), set(lensProp('sms'), bool)),
  )

export const isDeleteAction = equals(ACCOUNT_SETTINGS_ACTIONS.delete)

export const changeSmsValues = bool => when(propIs(Boolean, 'sms'), set(lensProp('sms'), bool))
export const changeEmailValues = bool => when(propIs(Boolean, 'email'), set(lensProp('email'), bool))

export const getIndeterminateCheckboxNextState = event => {
  const {
    checked,
    dataset: { indeterminate },
  } = event.target
  return checked && !(indeterminate === 'true')
}
