import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'
import { nanoid } from 'nanoid'

import TickO from 'views/shared/icons/TickO'
import LivePreview from 'views/shared/icons/LivePreview'
import Time from 'views/shared/icons/Time'
import Plus from 'views/shared/icons/Plus'
import Link from 'views/shared/Link'
import EditPencil from 'views/shared/icons/EditPencil'
import PaperClip from 'views/shared/icons/PaperClip'
import Note from 'views/shared/icons/Note'
import Intake from 'views/shared/icons/Intake'
import DeleteO from 'views/shared/icons/DeleteO'
import Message from 'views/shared/icons/Message'

const ICON_COMPONENTS = {
  time: Time,
  add: Plus,
  'live-preview': LivePreview,
  'edit-pencil': EditPencil,
  note: Note,
  'paper-clip': PaperClip,
  document: Intake,
  delete: DeleteO,
  tick: TickO,
  message: Message,
}

const DropdownItem = ({
  id,
  className,
  icon,
  text,
  label,
  onClick,
  disabled,
  children,
  isLink,
  isNextLink,
  isSeparated,
  href,
  target,
  dataCy,
  query,
}) => {
  const itemClassNames = classNames(className, 'main-dropdown__item', {
    'main-dropdown__item--separated': isSeparated,
  })

  const IconComponent = ICON_COMPONENTS[icon]

  const iconClassNames = 'mr-12 in-gray-600'

  if (children) {
    return (
      <button id={id} className={itemClassNames} type="button" disabled={disabled} onClick={onClick}>
        {children}
      </button>
    )
  }

  if (isLink) {
    return (
      <a id={id} data-cy={dataCy} href={href} className={itemClassNames} onClick={onClick} target={target}>
        <span className="main-dropdown__item-link-wrapper">
          {IconComponent && (
            <span className={iconClassNames}>
              <IconComponent />
            </span>
          )}
          <FormattedMessage {...text} />
        </span>
      </a>
    )
  }

  if (isNextLink) {
    return (
      <Link href={href} query={query} legacyBehavior={false} target={target}>
        <button id={id} className={itemClassNames} type="button" data-cy={dataCy}>
          <span>
            {IconComponent && (
              <span className={iconClassNames}>
                <IconComponent />
              </span>
            )}
            <FormattedMessage {...text} />
            {label && (
              <span className="main-dropdown__item-label ml-28">
                <FormattedMessage {...label} />
              </span>
            )}
          </span>
        </button>
      </Link>
    )
  }

  return (
    <button id={id} className={itemClassNames} type="button" disabled={disabled} onClick={onClick} data-cy={dataCy}>
      <span>
        {IconComponent && (
          <span className={iconClassNames}>
            <IconComponent />
          </span>
        )}
        <FormattedMessage {...text} />
        {label && (
          <span className="main-dropdown__item-label ml-28">
            <FormattedMessage {...label} />
          </span>
        )}
      </span>
    </button>
  )
}

DropdownItem.defaultProps = {
  id: `dropdown-item-${nanoid()}`,
  className: null,
  disabled: false,
  onClick: () => {},
  icon: null,
  label: null,
  text: null,
  children: null,
  isLink: false,
  isNextLink: false,
  isSeparated: false,
  href: undefined,
  query: {},
  target: undefined,
  dataCy: undefined,
}

DropdownItem.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  text: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  isLink: PropTypes.bool,
  isNextLink: PropTypes.bool,
  isSeparated: PropTypes.bool,
  href: PropTypes.string,
  query: PropTypes.shape(),
  target: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DropdownItem
