import PropTypes from 'prop-types'
import classNames from 'clsx'

import { SERVICE_STATUSES } from 'lib/constants/services'

const OptionLabel = ({ service: { name, status }, mode }) =>
  mode === 'default' ? (
    name
  ) : (
    <span
      className={classNames('dropdown-multiselect-choise', {
        'dropdown-multiselect-choise--deactiv': status === SERVICE_STATUSES.inactive,
      })}
    >
      <span className="dropdown-multiselect-choise__txt">{name}</span>
    </span>
  )

OptionLabel.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  mode: PropTypes.string.isRequired,
}

export default OptionLabel
