const namespace = 'payments'

export const FETCH_TRANSACTIONS = `${namespace}/FETCH_TRANSACTIONS`
export const SEARCH_TRANSACTIONS = `${namespace}/SEARCH_TRANSACTIONS`
export const SET_ACTIVE_TRANSACTION_ID = `${namespace}/SET_ACTIVE_TRANSACTION_ID`
export const SET_TRANSACTIONS = `${namespace}/SET_TRANSACTIONS`
export const SET_TRANSACTIONS_PAGE = `${namespace}/SET_TRANSACTIONS_PAGE`
export const SET_TRANSACTIONS_BLANK_STATE = `${namespace}/SET_TRANSACTIONS_BLANK_STATE`
export const SET_TRANSACTIONS_FILTER = `${namespace}/SET_TRANSACTIONS_FILTER`
export const SET_TRANSACTIONS_FILTER_PARAMS = `${namespace}/SET_TRANSACTIONS_FILTER_PARAMS`
export const RESET_TRANSACTIONS_FILTER_PARAMS = `${namespace}/RESET_TRANSACTIONS_FILTER_PARAMS`
export const FETCH_RECEIPT_TEMPLATE = `${namespace}/FETCH_RECEIPT_TEMPLATE`
export const SET_RECEIPT_TEMPLATE = `${namespace}/SET_RECEIPT_TEMPLATE`
export const UPDATE_RECEIPT_TEMPLATE = `${namespace}/UPDATE_RECEIPT_TEMPLATE`
export const FETCH_CLIENT_TRANSACTIONS = `${namespace}/FETCH_CLIENT_TRANSACTIONS`
export const SET_CLIENT_TRANSACTIONS_FILTER = `${namespace}/SET_CLIENT_TRANSACTIONS_FILTER`
export const RESET_CLIENT_TRANSACTIONS_FILTER = `${namespace}/RESET_CLIENT_TRANSACTIONS_FILTER`
export const CANCEL_PAYMENT = `${namespace}/CANCEL_PAYMENT`
export const REQUEST_PAYMENT = `${namespace}/REQUEST_PAYMENT`
export const SAVE_AS_DRAFT = `${namespace}/SAVE_AS_DRAFT`
