import axios from 'axios'
import { forEach, keys } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setUploadProgress } from 'state/concepts/form/actions'
import { createAttachmentUploadEndpoint } from 'state/concepts/form/endpoints'
import { CREATE_ATTACHMENT_UPLOAD } from 'state/concepts/form/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const createAttachmentUploadOperation = createLogic({
  type: CREATE_ATTACHMENT_UPLOAD,
  latest: false,

  async process({ httpClient, action }, dispatch, done) {
    const {
      fileUpload,
      fileUpload: { id: uploadId, file },
      id,
      onSuccess,
    } = action
    const { name: filename } = file
    const { endpoint, url } = createAttachmentUploadEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const {
        data: { meta },
      } = await httpClient.post(url, { filename, client_profile_form_id: id })

      const params = new FormData()
      forEach(key => params.append(key, meta.fields[key]), keys(meta.fields))
      params.append('file', file)

      await axios.post(meta.url, params)

      dispatch(setUploadProgress(uploadId, 100))

      dispatch(dataApiSuccess({ endpoint }))
      onSuccess(fileUpload, meta)
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default createAttachmentUploadOperation
