import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Title = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M0 102.4v819.2h153.6v-358.4h307.2v358.4h153.6v-819.2h-153.6v307.2h-307.2v-307.2h-153.6zM1006.399 102.4l-289.599 108.302v134.4l153.6-48.9v625.399h153.6v-819.2h-17.601z" />
  </svg>
)

Title.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Title.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Title
