import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Tooltip from 'views/shared/Tooltip'
import Download from 'views/shared/icons/Download'

const DownloadButton = ({ url }) => (
  <Tooltip
    placement="top"
    on={['hover']}
    overlayClassName="main-tooltip main-tooltip--no-pointer"
    icon={
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        className="d-flex align-items-center main-icon-btn no-shrink ml-12"
      >
        <Download className="main-icon-btn__icon hover-blue-600" size={20} />
      </a>
    }
  >
    <FormattedMessage id="shared.download" />
  </Tooltip>
)

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
}

export default DownloadButton
