const paymentSettings = {
  'paymentSettings.title': 'Payment settings',
  'paymentSettings.stripe': 'Stripe',
  'paymentSettings.stripeAccount': 'Stripe account',
  'paymentSettings.createNewAccount': 'Create a new account, or log in to your existing account.',
  'paymentSettings.stripeInfo':
    'is a payments provider with a simple fee structure and no hidden costs. No other gateway or merchant account is required.',
  'paymentSettings.apiKey': 'Secret key',
  'paymentSettings.publishableApiKey': 'Publishable key',
  'paymentSettings.accountIsConnected': 'Account is connected',
  'paymentSettings.disconnect': 'Disconnect',
  'paymentSettings.keepConnected': 'Keep connected',

  'paymentSettings.disconnectModal.title': 'Disconnect Stripe?',
  'paymentSettings.disconnectModal.body': 'You won’t be able to collect payments online.',
  'paymentSettings.disconnectModal.settingsWillBeChanged':
    'After you disconnect stripe account from your company all your _(services) configured as "Display a price and collect payments" will be reset to "Display a price but do not collect payments" default option.',
  'paymentSettings.disconnectModal.areYouSure': 'Are you sure you want to disconnect stripe account?',
  'paymentSettings.connectStripe': 'Connect with stripe',
  'paymentSettings.connectPayPal': 'Connect with PayPal',
  'paymentSettings.methods': 'Payment methods',
  'paymentSettings.methods.description': 'Choose how your _(clients) can pay for your _(services).',
  'paymentSettings.connected': 'Connected',
  'paymentSettings.connect.description': 'Connect to accept Credit/Debit Cards online.',
  'paymentSettings.connect': 'Connect',
  'paymentSettings.enable': 'Enable',
  'paymentSettings.manualPayments': 'Manual payments',
  'paymentSettings.manualPayments.description':
    'Connect to accept payments offline, e.g. cash, checks, bank transfers etc.',
  'paymentSettings.alertMessage': 'Set up payment template to generate invoices and receipts.',
  'paymentSettings.alertButtonText': 'Set up payment template',
  'manualPayment.modal.title.connect': 'Manual payment method',
  'manualPayment.modal.title.update': 'Edit manual payment information',
  'manualPayment.modal.instructionLabel': 'Payment instructions',
  'manualPayment.modal.body':
    'Tell your _(clients) how to pay (e.g. by cash, bank transfer, etc). They will see these instructions in payment requests, emails and invoices.',
  'paymentSettings.manualPayment.disconnectModal.title': 'Disconnect manual payments?',
  'paymentSettings.manualPayment.disconnectModal.body':
    'Your _(client)’s won’t be able to pay for _(services) using Manual payments.',
  'paymentSettings.payPal': 'PayPal',
  'paymentSettings.payPal.connect.description': 'Connect to accept PayPal payments.',
  'paymentSettings.payPal.alert': 'Connecting to the payment method may take some time.',
  'paymentSettings.disconnectPaypalModal.title': 'Disconnect PayPal?',
  'paymentSettings.disconnectPaypalModal.body': 'You won’t be able to collect payments with PayPal.',
  'paymentSettings.stripePos': 'Stripe Point of Sale',
  'paymentSettings.stripePos.connect.description':
    'Connect a Stripe card reader to accept in-person payments. Requires hardware.',
  'paymentSettings.stripePos.viewCardReaders': 'View card readers',
  'paymentSettings.disconnectStripePosModal.title': 'Disconnect Stripe Point of Sale?',
  'paymentSettings.disconnectStripePosModal.body':
    'You won’t be able to accept in-person payments with Stripe card readers.',
  'paymentSettings.disconnectStripePosModal.warning':
    '{count, plural, ' +
    '=0 {You won’t be accepting payments anymore, because no other payment method is connected.} ' +
    'one {You’re still accepting {methodOne}.}' +
    '=2 {You’re still accepting {methodOne}, and {methodTwo}.}' +
    'other {You’re still accepting {methodOne}, {methodTwo}, and {methodThree}.}}',
  'paymentSettings.disconnectStripePosModal.stripeOnlinePayments': 'Stripe online payments',
  'paymentSettings.disconnectStripePosModal.manualPayments': 'Manual payments',
  'paymentSettings.disconnectStripePosModal.customPayments': 'Custom payments',
  'paymentSettings.disconnectStripePosModal.stripePosPayments': 'Stripe point of sale payments',
  'paymentSettings.stripe.feeInfo':
    'From 2.9% + 30¢ per transaction. ExpertBox does not collect additional fees. <a>Learn more</a>',
  'paymentSettings.stripePos.feeInfo':
    'From 2.7% + 5¢ per transaction. ExpertBox does not collect additional fees. <a>Learn more</a>',
  'paymentSettings.paypal.feeInfo': 'Fees may vary. ExpertBox does not collect additional fees. <a>Learn more</a>',
}

export default paymentSettings
