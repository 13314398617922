import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import redirect from 'utils/redirect'
import { guestLinkExpiredRoute } from 'lib/routes'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setClientAccountBookingsIds } from 'state/concepts/clientAccountBookings/actions'
import { FETCH_BOOKING_RECURRENCE } from 'state/concepts/guestBookings/types'
import { fetchBookingRecurrenceEndpoint } from 'state/concepts/guestBookings/endpoints'
import { lastClientAccountBookingIdSelector } from 'state/concepts/clientAccountBookings/selectors'

const fetchBookingRecurrenceOperation = createLogic({
  type: FETCH_BOOKING_RECURRENCE,
  latest: true,

  async process({ httpClient, getState, action: { bookingRecurrenceId, res } }, dispatch, done) {
    const { endpoint, url } = fetchBookingRecurrenceEndpoint(bookingRecurrenceId)

    const params = {
      include: ['user-profile', 'service', 'service-category', 'workspace', 'booking-recurrence', 'location'],
      page: { after: lastClientAccountBookingIdSelector(getState()) },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setClientAccountBookingsIds(pluck('id', data.data)))
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }
    done()
  },
})

export default fetchBookingRecurrenceOperation
