import { createLogic } from 'redux-logic'
import { prop, propEq, head } from 'ramda'

import { UPDATE_UNREAD_COUNTER } from 'state/concepts/chats/types'
import updateDataHelper from 'utils/updateDataHelper'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { unreadMessagesCountSelector, readCursorSelector } from 'state/concepts/chats/selectors'
import { isUnreadMessage } from 'utils/chat'
import { dataApiSuccess } from 'state/data/actions'

const updateUnreadCounterOperation = createLogic({
  type: UPDATE_UNREAD_COUNTER,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const state = getState()
    const { messages } = action
    const receivedMessage = head(messages)
    const chatId = prop('chatId', receivedMessage)
    const { chatUserId } = currentUserProfileSelector(state)
    const readCursor = readCursorSelector(state, chatId)

    const isNotEdited = propEq(null, 'editedAt', receivedMessage)
    const isDestroyed = propEq(true, 'destroyed', receivedMessage)
    const isUnread = isUnreadMessage(receivedMessage, chatUserId, readCursor)

    if ((isDestroyed || isNotEdited) && isUnread) {
      const unreadMessagesCount = unreadMessagesCountSelector(state, chatId)
      const delta = isDestroyed ? -1 : 1

      const response = updateDataHelper(state.data, 'chat', [chatId], {
        attributes: {
          unreadMessagesCount: unreadMessagesCount + delta,
        },
      })

      dispatch(dataApiSuccess({ response }))
    }

    done()
  },
})

export default updateUnreadCounterOperation
