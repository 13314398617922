import { createLogic } from 'redux-logic'

import { fetchMyFiles, setMyFilesCurrentPage, setMyFilesFilterParams } from '../actions'
import { FILTER_MY_FILES } from '../types'

const filterMyFilesOperation = createLogic({
  type: FILTER_MY_FILES,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setMyFilesFilterParams(filters))
    dispatch(setMyFilesCurrentPage(1))
    dispatch(fetchMyFiles())
    done()
  },
})

export default filterMyFilesOperation
