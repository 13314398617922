import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { switchBillingCycle as switchBillingCycleAction } from 'state/concepts/subscriptionPlans/actions'
import MonthlyToAnnual from './MonthlyToAnnual'
import AnnualToMonthly from './AnnualToMonthly'

const SWITCH_BILLING_CYCLE_COMPONENTS = {
  monthly: MonthlyToAnnual,
  annual: AnnualToMonthly,
}

const SwitchBillingCycleModal = ({ onClose, subscriptionPlan }) => {
  const { subscriptionCycleType } = subscriptionPlan
  const switchBillingCycle = useDispatchAction(switchBillingCycleAction, subscriptionCycleType)
  const CurrentComponent = SWITCH_BILLING_CYCLE_COMPONENTS[subscriptionCycleType]

  return (
    <Modal className="main-modal main-modal--md freemium-modal" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title font-600">
            <FormattedMessage id={`switchBillingCycle.${subscriptionCycleType}.switch?`} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close w-24 h-24">
            <Cross size={10} dataCy="cross-icon" />
          </button>
        </div>
        <CurrentComponent subscriptionPlan={subscriptionPlan} />
        <div className="main-modal__footer gap-16">
          <Button className="mr-0" kind="flat" text={{ id: 'shared.cancel' }} onClick={onClose} />
          <Button text={{ id: `switchBillingCycle.${subscriptionCycleType}.switch` }} onClick={switchBillingCycle} />
        </div>
      </div>
    </Modal>
  )
}

SwitchBillingCycleModal.propTypes = {
  subscriptionPlan: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SwitchBillingCycleModal
