import CheckboxItemFile from 'views/shared/CheckboxItemFile'
import DocIcon from 'views/shared/icons/DocIcon'
import FolderO from 'views/shared/icons/FolderO'
import ImageIcon from 'views/shared/icons/ImageIcon'
import Pdf from 'views/shared/icons/Pdf'
import VideoIcon from 'views/shared/icons/VideoIcon'

export const FILE_KINDS = {
  folder: 'folder',
  file: 'file',
}

export const PREVIEW_SHARED_WITH_COUNT = 3

export const PREFILLED_LAST_MODIFIED_RANGES = {
  today: 'today',
  lastWeek: 'lastWeek',
  lastMonth: 'lastMonth',
  thisYear: 'thisYear',
  lastYear: 'lastYear',
}

export const CUSTOM_LAST_MODIFIED_RANGE_TYPE = 'customRange'

export const PREFILLED_TRASHED_RANGES = {
  today: 'today',
  yesterday: 'yesterday',
  thisWeek: 'thisWeek',
  lastWeek: 'lastWeek',
}

export const CUSTOM_TRASHED_RANGE_TYPE = 'customRange'

export const CUSTOM_SHARED_RANGE_TYPE = 'customRange'

export const FILES_TYPE_OPTIONS = [
  {
    key: 'files-type-option-1',
    label: <CheckboxItemFile icon={Pdf} text={{ id: 'trashedFiles.filter.pdf.label' }} />,
    labelPreview: { id: 'trashedFiles.filter.pdf.label' },
    value: 'pdf',
  },
  {
    key: 'files-type-option-2',
    label: <CheckboxItemFile icon={ImageIcon} text={{ id: 'trashedFiles.filter.imageAndPhoto.label' }} />,
    labelPreview: { id: 'trashedFiles.filter.imageAndPhoto.label' },
    value: 'image_and_photo',
  },
  {
    key: 'files-type-option-3',
    label: <CheckboxItemFile icon={VideoIcon} text={{ id: 'trashedFiles.filter.videoAndAudio.label' }} />,
    labelPreview: { id: 'trashedFiles.filter.videoAndAudio.label' },
    value: 'video_and_audio',
  },
  {
    key: 'files-type-option-4',
    label: <CheckboxItemFile icon={FolderO} text={{ id: 'trashedFiles.filter.folder.label' }} />,
    labelPreview: { id: 'trashedFiles.filter.folder.label' },
    value: 'folder',
  },
  {
    key: 'files-type-option-5',
    label: <CheckboxItemFile icon={DocIcon} text={{ id: 'trashedFiles.filter.otherFile.label' }} />,
    labelPreview: { id: 'trashedFiles.filter.otherFile.label' },
    value: 'other_file',
  },
]

export const PERMISSION_TYPES = {
  reader: 'reader',
  writer: 'writer',
}

export const DEFAULT_PERMISSION = PERMISSION_TYPES.writer

export const SHARE_FILE_STEPS = {
  sharedWith: 'sharedWith',
  addPermissions: 'addPermissions',
  discard: 'discard',
  updateAccess: 'updateAccess',
}

export const UPLOAD_OPTIONS = [
  { value: 'true', label: { id: 'myBusinessFiles.uploadOptions.replaceAll' } },
  { value: 'false', label: { id: 'myBusinessFiles.uploadOptions.keepAll' } },
]

export const MAX_FILE_SIZE = 1024 * 1024 * 1024 // 1 Gb
export const DAYS_UNTIL_FILES_DELETION = 30

export const CONCEPT_TYPES = {
  myFiles: 'myFiles',
  shared: 'shared',
}

export const PERMISSIONS_DESTROYED_TYPE = 'permissions_destroyed'

export const MAX_FOLDER_ANCESTRY_DEPTH = 9
