import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const emptyArgs = []

const useDispatchAction = (actionCreator, ...args) => {
  const staticArgs = args.length ? args : emptyArgs
  const dispatch = useDispatch()

  return useCallback(
    (...dynamicArgs) => {
      dispatch(actionCreator(...staticArgs, ...dynamicArgs))
    },
    [actionCreator, dispatch, staticArgs],
  )
}

export default useDispatchAction
