import { createLogic } from 'redux-logic'

import { FILTER_EMPLOYEES } from 'state/concepts/employees/types'
import { setFilterParams, setCurrentPage, fetchEmployees } from 'state/concepts/employees/actions'

const filterEmployeesOperation = createLogic({
  type: FILTER_EMPLOYEES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(fetchEmployees())
    done()
  },
})

export default filterEmployeesOperation
