import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ListView = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M51.2 51.2c-13.578 0.002-26.6 5.396-36.202 14.998s-14.997 22.623-14.998 36.202v307.2c0.002 13.579 5.396 26.601 14.998 36.202s22.623 14.996 36.202 14.998h921.6c13.578-0.002 26.599-5.396 36.203-14.998 9.6-9.601 14.997-22.623 14.997-36.202v-307.2c0-13.578-5.396-26.6-14.997-36.202-9.605-9.601-22.625-14.996-36.203-14.998h-921.6zM102.4 153.6h819.2v204.8h-819.2v-204.8zM51.2 563.2c-13.578 0-26.6 5.396-36.202 14.997-9.601 9.605-14.997 22.625-14.998 36.203v307.2c0.002 13.578 5.396 26.599 14.998 36.203 9.601 9.6 22.623 14.997 36.202 14.997h921.6c13.578 0 26.599-5.396 36.203-14.997 9.6-9.605 14.997-22.625 14.997-36.203v-307.2c0-13.578-5.396-26.599-14.997-36.203-9.605-9.6-22.625-14.997-36.203-14.997h-921.6zM102.4 665.6h819.2v204.8h-819.2v-204.8z" />
  </svg>
)

ListView.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ListView.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ListView
