import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'
import build from 'redux-object'

import { dataSelector } from 'state/data/selectors'

export const bookingSettingsIdSelector = path(['workspaceBookingSettings', 'bookingSettingsId'])

export const workspaceBookingSettingsSelector = createSelector(
  [dataSelector, bookingSettingsIdSelector],
  (data, bookingSettingsId) => build(data, 'workspaceBookingSetting', bookingSettingsId),
)
