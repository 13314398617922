import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import * as types from './types'

const websiteId = (state = null, action) => {
  switch (action.type) {
    case types.SET_WEBSITE:
      return action.websiteId
    default:
      return state
  }
}

const providerIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_PROVIDERS:
      return uniq([...state, ...action.ids])
    default:
      return state
  }
}

const serviceIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_SERVICES:
      return uniq([...state, ...action.ids])
    default:
      return state
  }
}

const reviewIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_REVIEW_IDS:
      return uniq([...state, ...action.reviewIds])
    default:
      return state
  }
}

const locationIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_LOCATIONS:
      return uniq([...state, ...action.ids])
    default:
      return state
  }
}

const websiteDraft = (state = null, action) => {
  switch (action.type) {
    case types.SET_WEBSITE_DRAFT:
      return action.websiteDraft
    case types.RESET_WEBSITE_DRAFT:
      return null
    default:
      return state
  }
}

const websiteReducer = combineReducers({
  websiteId,
  providerIds,
  serviceIds,
  reviewIds,
  locationIds,
  websiteDraft,
})

export default websiteReducer
