import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { sortableElement } from 'react-sortable-hoc'

import { FIELD_TYPES } from 'lib/constants/customField'
import CircleO from 'views/shared/icons/CircleO'
import Tooltip from 'views/shared/Tooltip'
import InputField from 'views/shared/InputField'
import Cross from 'views/shared/icons/Cross'
import SquareO from 'views/shared/icons/SquareO'
import DragHandle from '../DragHandle'

const SortableOption = ({ fieldType, optionIndex, onRemove, canRemove }) => (
  <div className="mb-16  custom-field__drag-wrap" key={optionIndex}>
    <div className="d-flex align-items-center question-option">
      <DragHandle />
      {fieldType === FIELD_TYPES.checkBox ? (
        <SquareO size={18} color="#B2BAC7" className="mr-16 ml-8" />
      ) : (
        <CircleO size={18} color="#B2BAC7" className="mr-16 ml-8" />
      )}
      <Field
        className="mb-0"
        name={`fieldOptions.${optionIndex}.name`}
        id={`fieldOptions.${optionIndex}.name`}
        component={InputField}
        icon="alert"
        dataCy={`option-name-${optionIndex}`}
      />
      {canRemove && (
        <Tooltip
          overlayClassName="main-tooltip main-tooltip--sm"
          icon={
            <button
              data-cy="option-remove-button"
              type="button"
              className="remove-items ml-16 in-disabled-gray hover-blue-600"
              onClick={onRemove}
            >
              <Cross size={10} color="currentcolor" />
            </button>
          }
          placement="top"
        >
          <FormattedMessage id="shared.delete" />
        </Tooltip>
      )}
    </div>
  </div>
)

SortableOption.propTypes = {
  fieldType: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
}

export default sortableElement(SortableOption)
