import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const ZoomInfo = ({ providerUrl, meetingId, passcode }) => (
  <>
    <p className="mb-0 mt-4">
      <a href={providerUrl} target="_blank" className="in-blue-600 text-underline">
        {providerUrl}
      </a>
    </p>
    <p className="mb-0">
      <FormattedMessage id="consultation.details.zoom.meetingId" values={{ meetingId }} />
    </p>
    <p className="mb-0">
      <FormattedMessage id="consultation.details.zoom.passcode" values={{ passcode }} />
    </p>
  </>
)

ZoomInfo.propTypes = {
  providerUrl: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  passcode: PropTypes.string.isRequired,
}

export default ZoomInfo
