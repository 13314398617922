import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsDocumentTemplatesRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { publishDocumentTemplateEndpoint } from 'state/concepts/documentTemplates/endpoints'
import { PUBLISH_DOCUMENT_TEMPLATE } from 'state/concepts/documentTemplates/types'

const publishDocumentTemplateOperation = createLogic({
  type: PUBLISH_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ httpClient, action: { documentTemplateId }, getState }, dispatch, done) {
    const { url, endpoint } = publishDocumentTemplateEndpoint(documentTemplateId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.documentTemplatePublished' } }))
      redirect({ href: companySettingsDocumentTemplatesRoute, workspace: workspaceCode })
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        dispatch(dataApiFailure({ endpoint }))
        dispatch(showNotification({ messageObject: { id: 'notifications.documentTemplatePublished' } }))
        redirect({ href: companySettingsDocumentTemplatesRoute, workspace: workspaceCode })
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default publishDocumentTemplateOperation
