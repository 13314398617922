import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { RECEIPT_TEMPLATE_STATUSES } from 'lib/constants/payments'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { fetchReceiptTemplate } from 'state/concepts/payments/actions'
import { UPDATE_RECEIPT_TEMPLATE } from 'state/concepts/payments/types'
import { updateReceiptTemplateEndpoint } from 'state/concepts/payments/endpoints'

const updateReceiptTemplateOperation = createLogic({
  type: UPDATE_RECEIPT_TEMPLATE,
  latest: true,

  async process({ httpClient, action: { form, values } }, dispatch, done) {
    const { url } = updateReceiptTemplateEndpoint
    const params = {
      tax_id: values.taxId,
      message: values.message,
      status: values.active ? RECEIPT_TEMPLATE_STATUSES.active : RECEIPT_TEMPLATE_STATUSES.inactive,
    }

    try {
      const { data } = await httpClient.put(url, params)
      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(fetchReceiptTemplate())
      dispatch(showNotification({ messageObject: { id: 'notifications.receiptTemplateUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateReceiptTemplateOperation
