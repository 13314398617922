import { createLogic } from 'redux-logic'

import { setCurrentPage } from 'state/tableData/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { fetchFormCategories, setFilterParams } from '../actions'
import { FILTER_FORM_CATEGORIES } from '../types'

const filterFormCategoriesOperation = createLogic({
  type: FILTER_FORM_CATEGORIES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(TABLE_ENTITIES.formCategories, 1))
    dispatch(fetchFormCategories())
    done()
  },
})

export default filterFormCategoriesOperation
