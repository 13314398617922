/* eslint-disable react/no-danger */
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import ogLogo from 'assets/images/og-logo.png'
import twitterLogo from 'assets/images/twitter-logo.png'
import favicon57 from 'assets/images/favicon/apple-touch-icon-57x57.png'
import favicon114 from 'assets/images/favicon/apple-touch-icon-114x114.png'
import favicon72 from 'assets/images/favicon/apple-touch-icon-72x72.png'
import favicon144 from 'assets/images/favicon/apple-touch-icon-144x144.png'
import favicon60 from 'assets/images/favicon/apple-touch-icon-60x60.png'
import favicon120 from 'assets/images/favicon/apple-touch-icon-120x120.png'
import favicon76 from 'assets/images/favicon/apple-touch-icon-76x76.png'
import favicon152 from 'assets/images/favicon/apple-touch-icon-152x152.png'
import favicon196 from 'assets/images/favicon/favicon-196x196.png'
import favicon96 from 'assets/images/favicon/favicon-96x96.png'
import favicon32 from 'assets/images/favicon/favicon-32x32.png'
import favicon16 from 'assets/images/favicon/favicon-16x16.png'
import favicon128 from 'assets/images/favicon/favicon-128.png'

const dynamicScriptHelpCenter =
  '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})'
const dynamicScriptWindowBeacon = "window.Beacon('init', 'dd184564-b870-452c-8f54-aad89e04a29c')"

const MetaComponent = ({ pageTitle }) => {
  const { formatMessage } = useIntl()

  const title = pageTitle ? formatMessage(pageTitle) : formatMessage({ id: 'pageTitle.expertBox' })

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={formatMessage({ id: 'seo.description' })} />
      <meta name="robots" content="NOINDEX, NOFOLLOW" />
      <link rel="apple-touch-icon" sizes="57x57" href={favicon57.src} />
      <link rel="apple-touch-icon" sizes="114x114" href={favicon114.src} />
      <link rel="apple-touch-icon" sizes="72x72" href={favicon72.src} />
      <link rel="apple-touch-icon" sizes="144x144" href={favicon144.src} />
      <link rel="apple-touch-icon" sizes="60x60" href={favicon60.src} />
      <link rel="apple-touch-icon" sizes="120x120" href={favicon120.src} />
      <link rel="apple-touch-icon" sizes="76x76" href={favicon76.src} />
      <link rel="apple-touch-icon" sizes="152x152" href={favicon152.src} />
      <link rel="icon" type="image/png" href={favicon196.src} sizes="196x196" />
      <link rel="icon" type="image/png" href={favicon96.src} sizes="96x96" />
      <link rel="icon" type="image/png" href={favicon32.src} sizes="32x32" />
      <link rel="icon" type="image/png" href={favicon16.src} sizes="16x16" />
      <link rel="icon" type="image/png" href={favicon128.src} sizes="128x128" />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no maximum-scale=1.0 user-scalable=no, viewport-fit=cover"
      />
      <meta content="ie=edge" httpEquiv="x-ua-compatible" />
      <meta charSet="utf-8" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={formatMessage({ id: 'seo.description' })} />
      <meta property="og:image" content={ogLogo} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={formatMessage({ id: 'seo.description' })} />
      <meta property="twitter:image" content={twitterLogo} />
      <script dangerouslySetInnerHTML={{ __html: dynamicScriptHelpCenter }} />
      <script dangerouslySetInnerHTML={{ __html: dynamicScriptWindowBeacon }} />
    </Head>
  )
}

MetaComponent.defaultProps = {
  pageTitle: undefined,
}

MetaComponent.propTypes = {
  pageTitle: PropTypes.shape(),
}

export default MetaComponent
