const documentTemplates = {
  'documentTemplates.noSearchResults': 'Try adjusting your search or filters',
  'documentTemplates.renameModal.title': 'Rename document template',
  'documentTemplates.renameModal.name.label': 'Name',
  'documentTemplates.deleteModal.title': 'Delete document template?',
  'documentTemplates.deleteModal.description':
    'You are about to delete this document template permanently. Once deleted, the template will no longer be available for generating documents.',
  'documentTemplates.deleteModal.submit': 'Delete template',
  'documentTemplates.search.placeholder': 'Search by name',
  'documentTemplates.addTemplate': 'Add template',
  'documentTemplates.actions.rename': 'Rename',
  'documentTemplates.actions.deactivate': 'Deactivate',
  'documentTemplates.actions.activate': 'Activate',
  'documentTemplates.actions.publish': 'Publish',
  'documentTemplates.unpublishedChanges': 'Unpublished changes',
  'documentTemplates.status.active': 'Active',
  'documentTemplates.status.inactive': 'Deactivated',
  'documentTemplates.status.all': 'All',
  'documentTemplates.table.name.title': 'Name',
  'documentTemplates.table.updatedAt.title': 'Last modified',
  'documentTemplates.table.status.title': 'Status',
  'documentTemplates.table.filter.status': 'By status',
  'documentTemplates.createTemplate': 'Create template',
  'documentTemplates.empty': 'No document templates',
  'documentTemplates.empty.description': 'Add templates to generate documents in a few clicks.',
  'documentTemplates.manageTemplates': 'Manage templates',
  'documentTemplates.templatesUpdatedModal.title': 'The document template has been updated',
  'documentTemplates.templatesUpdatedModal.body':
    'Please reload the page to access the current version. Unpublished changes will be discarded. ',

  'documentTemplates.selectTemplate.noSearchResults': 'Try adjusting your search',
  'documentTemplates.selectTemplate.noDocumentTemplates': 'No document templates',
  'documentTemplates.modal.deactivate.title': 'Deactivate template?',
  'documentTemplates.modal.deactivate.body': 'You won’t be able to create a document using this template.',
  'documentTemplates.modal.activate.title': 'Activate template?',
  'documentTemplates.modal.activate.body': 'Activate the document template to create documents using this template.',
  'documentTemplates.modal.discard.title': 'Discard changes?',
  'documentTemplates.modal.discard.body':
    'This will permanently delete all the changes you made since this page was last published.',
  'documentTemplates.modal.discard.submit': 'Discard changes',
}

export default documentTemplates
