import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Image from 'next/legacy/image'

import Modal from 'views/shared/Modal'
import { FormattedMessage } from 'react-intl'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import SliderPadding from './SliderPadding'

const WelcomeModalComponent = ({
  onClose,
  steps,
  sliderRef,
  onSlideChange,
  onNext,
  isLastSlide,
  currentSlideIndex,
  title,
}) => (
  <Modal className="main-modal main-modal--welcome-type" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title in-blue-gray-800">
          <FormattedMessage {...(title || steps[currentSlideIndex].title)} />
        </h2>
        <span data-cy="close-welcome-modal" role="button" onClick={onClose}>
          <Cross dataCy="cross-icon" type="button" className="main-modal__close" />
        </span>
      </div>
      <div className="main-modal__body main-modal__body-slider">
        <Slider
          ref={sliderRef}
          arrows={false}
          beforeChange={onSlideChange}
          dots
          dotsClass="dots-indicator mb-16"
          customPaging={SliderPadding}
        >
          {steps.map(({ key, imageUrl }) => (
            <div className="main-modal__slider-item" key={key}>
              <Image src={imageUrl} alt="" data-cy="welcome-modal-slider-image" />
            </div>
          ))}
        </Slider>
        <p className="max-h-20">
          <FormattedMessage {...steps[currentSlideIndex].description} />
        </p>
      </div>
      <div className="main-modal__footer">
        <Button className="main-modal__footer-action" text={{ id: 'shared.skip' }} kind="flat" onClick={onClose} />
        <Button
          className="main-modal__footer-action"
          type="submit"
          text={{ id: isLastSlide ? 'shared.finish' : 'shared.next' }}
          onClick={isLastSlide ? onClose : onNext}
        />
      </div>
    </div>
  </Modal>
)

WelcomeModalComponent.defaultProps = {
  title: undefined,
}

WelcomeModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sliderRef: PropTypes.shape().isRequired,
  onSlideChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isLastSlide: PropTypes.bool.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

export default WelcomeModalComponent
