import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const VideoO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M332.8 128v256H51.199V128H332.8zm51.2 0c0-28.237-22.963-51.2-51.2-51.2H51.199c-28.236 0-51.2 22.963-51.2 51.2v256c0 28.236 22.963 51.2 51.2 51.2H332.8c28.236 0 51.2-22.963 51.2-51.2v-74.701l86.4 69.12c4.339 3.469 9.907 5.566 15.964 5.566 4.053 0 7.888-.94 11.298-2.613l-.151.067c8.641-4.212 14.49-12.93 14.49-23.015v-.026.001-204.8c-.024-14.133-11.488-25.582-25.625-25.582a25.524 25.524 0 0 0-16.025 5.627l.05-.039-86.4 69.094v-74.701z" />
  </svg>
)

VideoO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

VideoO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default VideoO
