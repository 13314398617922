const companyProfile = {
  'companyProfile.companyName': 'Company Name',
  'companyProfile.industry': 'Industry',
  'companyProfile.address': 'Address',
  'companyProfile.city': 'City',
  'companyProfile.country': 'Country',
  'companyProfile.email': 'Email',
  'companyProfile.phoneNumber': 'Phone Number',
  'companyProfile.zipCode': 'Postal / ZIP code',
}

export default companyProfile
