const employeeSignUp = {
  'employeeInvitationSignup.inviteTokenStale': 'This invite link is no longer active',
  'employeeInvitationSignup.createNewAccount': 'Create a new account',
  'employeeInvitationSignup.signingAs': 'You’re signing up as {email} ',
  'employeeSignUp.signup': 'Sign up',
  'employeeSignUp.logIn': 'Log in',
  'employeeSignUp.alreadyHaveAccount': 'Already have an account?',
  'employeeSignUp.resendConfirmation': 'Re-send confirmation link',
  'employeeSignUp.checkEmail': 'Check your Email',
  'employeeSignUp.sentRecoveryLink': 'We sent a recovery link to your email address:',
  'employeeSignUp.didntReceiveEmail': "Didn't receive the email? Check your junk mail or request another one.",
  'employeeSignUp.goToPricePage': 'Go to Price page',
  'employeeSignUp.acceptTermsCheckbox': 'Yes, I agree to the {termsLink} and the {privacyLink} of ExpertBox',
  'employeeSignUp.termsAndConditions': 'Terms & Conditions',
  'employeeSignUp.privacyPolicy': 'Privacy Policy',
  'employeeSignUp.acceptTermsCheckboxError':
    'You have to agree to the Terms & Conditions and the Privacy Policy of ExpertBox to sign up',
  'employeeSignUp.brand': 'ExpertBox',
  'employeeSignUp.workEmail': 'Work email',
  'employeeSignUp.startFreeTrial': 'Start your free trial',
  'employeeSignUp.createWorkspace': 'Create a workspace',
  'employeeSignUp.signUpAs': 'You’re signing up as {email}',
  'employeeSignUp.enterEmail': 'Please enter your email address to start using ExpertBox',
  'employeeSignUp.proceed': 'Proceed',
  'employeeSignUp.alreadyHasAccount':
    '<div><pmb8>Looks like you already have a Personal Workspace. Do you want to {link}?</pmb8><pmb0>Or click ‘Proceed’ to sign up for a Team Workspace.</pmb0></div>',
  'employeeSignUp.workspaceName': 'Workspace name',
  'employeeSignUp.workspaceUrl': 'Workspace URL',
  'employeeSignUp.workspaceNameHint':
    'This name will be used to log to your ExpertBox workspace — choose something your _(clients) and team will recognize.',
  'employeeSignUp.workspaceUrlDescription':
    'You need URL to get an access to your workspace subdomain. It’s generated based on your workspace name.',
  'employeeSignUp.workspaceUrlRules':
    'Can only contain lowercase letters, numbers and dashes (and must start with a letter or number).',
  'employeeSignUp.perfect': 'Perfect',
  'employeeSignUp.workspaceName.label': 'Workspace name',
  // eslint-disable-next-line expbox/custom-name-validation
  'employeeSignUp.workspaceUrl.placeholder': 'e.g., expert-box',

  'employeeSignUp.pricingCard.freeTrial': 'Free trial',
  'employeeSignUp.pricingCard.noCardRequired': '(No credit card required)',
  'employeeSignUp.pricingCard.nDays': '{days} days free trial',
  'employeeSignUp.pricingCard.monthlyPayment': 'Monthly payment',
  'employeeSignUp.pricingCard.monthlyPaymentAfterTrial': 'Monthly payment (after trial)',
  'employeeSignUp.pricingCard.videoCallHours': 'Video call hours',
  'employeeSignUp.pricingCard.onlinePayments': 'Online _(client) payments',
  'employeeSignUp.pricingCard.recording': 'Recording',
  'employeeSignUp.pricingCard.additionalHoursBilled': '*Additional hours billed at 1$/hr thereafter',
  'employeeSignUp.pricingCard.changePlan': 'Change plan',
  'employeeSignUp.pricingCard.mobileApp': 'Mobile app',
  'employeeSignUp.pricingCard.customizedBookingPanel': 'Customized booking panel',
  'employeeSignUp.pricingCard.advancedScheduling': 'Advanced scheduling',
  'employeeSignUp.pricingCard.perMonth': ' per month',
  'employeeSignUp.pricingCard.perMember': ' per member',
  'employeeSignUp.pricingCard.youSave': 'You save {discountPercentage}%',
  'employeeSignUp.pricingCard.codeNotBeenAdded': 'Promo code <b>{code}</b> has not been added',
  'employeeSignUp.pricingCard.youHaveAddedPromocode': 'You have added promo code <b>{code}</b>',
  'employeeSignUp.pricingCard.havePromocode': 'Have a promo code?',
  'employeeSignUp.pricingCard.haveAnotherPromocode': 'Have another promo code?',
  'employeeSignUp.pricingCard.enterPromocode': 'Enter a promo code',
  'employeeSignUp.pricingCard.add': 'Add',
  'employeeSignUp.pricingCard.cancel': 'Cancel',

  'employeeSignUp.changePlanModal.team': 'Team',
  'employeeSignUp.changePlanModal.personal': 'Personal',
  'employeeSignUp.changePlanModal.title': 'Subscription plans',
}

export default employeeSignUp
