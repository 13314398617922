import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'
import { mergeAll } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import { getTaskCounter } from 'utils/tasks'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { MARK_TASK_AS_COMPLETED } from 'state/concepts/tasks/types'
import { markTaskAsCompletedEndpoint } from 'state/concepts/tasks/endpoints'
import { removeTaskId, decrementTasksCount } from 'state/concepts/tasks/actions'

const markTaskAsCompletedOperation = createLogic({
  type: MARK_TASK_AS_COMPLETED,
  latest: true,

  async process({ httpClient, getState, action: { task, feedId, skipRemoveFromList } }, dispatch, done) {
    const { endpoint, url } = markTaskAsCompletedEndpoint(task.id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      const state = getState()
      const responseUpdates = []

      responseUpdates.push(
        updateDataHelper(state.data, 'task', task.id, {
          attributes: {
            completedAt: DateTime.now().toISO(),
          },
        }),
      )

      if (feedId) {
        responseUpdates.push(
          updateDataHelper(state.data, 'clientFeed', feedId, {
            attributes: { planned: false },
          }),
        )
      }

      const response = mergeAll(responseUpdates)

      dispatch(dataApiSuccess({ endpoint, response }))

      if (!skipRemoveFromList) {
        dispatch(removeTaskId(task.id))
        dispatch(decrementTasksCount(getTaskCounter(task), true))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default markTaskAsCompletedOperation
