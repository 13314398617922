import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import * as types from './types'

const widgetIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_WIDGETS:
      return uniq([...state, ...action.widgetIds])
    case types.RESET_WIDGETS:
      return []
    default:
      return state
  }
}

const initialState = { number: 1, size: 10 }
const pagination = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WIDGETS_PAGE:
      return { ...state, number: action.pageNumber }
    case types.RESET_WIDGETS:
      return initialState
    default:
      return state
  }
}

const isBlankState = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const widgetsReducer = combineReducers({
  widgetIds,
  pagination,
  isBlankState,
})

export default widgetsReducer
