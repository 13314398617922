import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchLocations = () => ({
  type: types.FETCH_LOCATIONS,
})

export const setLocations = locationIds => ({
  type: types.SET_LOCATIONS,
  locationIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const setTotalCount = count => ({
  type: types.SET_TOTAL_COUNT,
  count,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_CURRENT_PAGE,
  pageNumber,
})

export const resetLocations = () => ({
  type: types.RESET_LOCATIONS,
})

export const filterLocations = filterParams => ({
  type: types.FILTER_LOCATIONS,
  filterParams,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const searchLocations = ({ page, resetResults, filter, size } = {}) => ({
  type: types.SEARCH_LOCATIONS,
  page,
  resetResults,
  filter,
  size,
})

export const toggleLocationStatus = locationId => ({
  type: types.TOGGLE_LOCATION_STATUS,
  locationId,
})

export const createLocation = makeFormSubmitAction(types.CREATE_LOCATION)

export const editLocation = makeFormSubmitAction(types.EDIT_LOCATION)
export const deleteLocation = makeFormSubmitAction(types.DELETE_LOCATION)

export const removeLocation = id => ({
  type: types.REMOVE_LOCATION,
  id,
})

export const getLocation = id => ({
  type: types.GET_LOCATION,
  id,
})
