import { any, compose, isEmpty, not, omit, path, values } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'

import buildCollection from 'utils/buildCollection'
import {
  currentPageSelector,
  dataSelector,
  loadingSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import { fetchStorageFilesEndpoint } from './endpoints'

export const storageFilesIdsSelector = path(['storage', 'ids'])
export const isBlankStateSelector = path(['storage', 'isBlankState'])
export const filtersSelector = path(['storage', 'filters'])
export const selectedIdsSelector = path(['storage', 'selectedIds'])
export const storageSpaceSelector = path(['storage', 'storageSpace'])
export const autoUpgradeSelector = path(['storage', 'autoUpgrade'])

export const storageFilesSelector = createSelector(
  [storageFilesIdsSelector, dataSelector],
  buildCollection('storageFile'),
)
export const storageFilesByIdsSelector = createSelector([(_, ids) => ids, dataSelector], buildCollection('storageFile'))

export const fetchStorageFilesLoadingSelector = state => loadingSelector(state, fetchStorageFilesEndpoint.endpoint)

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(compose(not, isEmpty)), values, omit(['name'])),
)

export const appliedFiltersSelector = (state, name) => path(['storage', 'filters', name], state)

export const storageFilesCurrentPageSelector = state => currentPageSelector(state, fetchStorageFilesEndpoint.endpoint)
export const storageFilesNextPageSelector = state => nextPageSelector(state, fetchStorageFilesEndpoint.endpoint)
export const storageFilesPageCountSelector = state => pageCountSelector(state, fetchStorageFilesEndpoint.endpoint)
