import { isEmpty } from 'ramda'
import { snakeCase } from 'lodash'

import { SORT_TYPES } from 'lib/constants'

const generateSortString = orderDirections => {
  const keys = Object.keys(orderDirections)

  if (isEmpty(keys)) {
    return ''
  }

  const directionKey = keys[0]
  const sortKey = directionKey.split('.').map(snakeCase).join('.')
  const sortDirection = orderDirections[directionKey] === SORT_TYPES.descend ? '-' : ''

  return `${sortDirection}${sortKey}`
}

export default generateSortString
