import React from 'react'

import { clientBookingsRoute, companyBookingsRoute, loginRoute } from 'lib/routes'
import checkIsClientLoggedIn from 'utils/client/isClientLoggedIn'
import redirect from 'utils/redirect'
import currentWorkspaceCode from 'utils/workspace/currentWorkspaceCode'
import Button from 'views/shared/Button'

class ErrorButtonContainer extends React.Component {
  state = {
    workspaceCode: null,
    isClientLoggedIn: null,
  }

  componentDidMount() {
    this.setState({
      workspaceCode: currentWorkspaceCode(),
      isClientLoggedIn: checkIsClientLoggedIn(),
    })
  }

  get href() {
    const { isClientLoggedIn, workspaceCode } = this.state

    if (isClientLoggedIn) {
      return clientBookingsRoute(workspaceCode)
    }
    if (workspaceCode) {
      return `/${workspaceCode}${companyBookingsRoute}`
    }
    return loginRoute
  }

  onClick = () => redirect({ href: this.href, reload: true })

  render() {
    return <Button className="main-btn--sm-100" onClick={this.onClick} text={{ id: 'clientErrorPage.button' }} />
  }
}

export default ErrorButtonContainer
