import { createSelector } from '@reduxjs/toolkit'
import { camelCase } from 'lodash'
import { compose, groupBy, last, path } from 'ramda'

import { FORM_TYPES } from 'lib/constants/forms'
import {
  fetchDocumentsEndpoint,
  fetchFilesEndpoint,
  fetchFormsEndpoint,
  fetchMyFilesEndpoint,
} from 'state/concepts/clientAccountDocuments/endpoints'
import { dataSelector, endpointMetaSelector, loadingSelector, totalCountSelector } from 'state/data/selectors'
import { currentClientSelector } from 'state/concepts/session/selectors'
import buildCollection from 'utils/buildCollection'
import { isSignerSentForSignature, isSignerYou, isWaitingForSignature } from 'utils/etherpadDocuments'
import { clientGroupedForms } from 'utils/forms'
import isPresent from 'utils/isPresent'

export const agreementIdsSelector = path(['clientAccountDocuments', 'formIds', FORM_TYPES.agreement])

export const intakeFormIdsSelector = path(['clientAccountDocuments', 'formIds', FORM_TYPES.intake])

export const agreementsSelector = createSelector(
  [agreementIdsSelector, dataSelector],
  buildCollection('clientProfileForm'),
)

export const intakeFormsSelector = createSelector(
  [intakeFormIdsSelector, dataSelector],
  buildCollection('clientProfileForm'),
)

export const filesIdsSelector = path(['clientAccountDocuments', 'fileIds'])
export const myFilesIdsSelector = path(['clientAccountDocuments', 'myFileIds'])

export const filesSelector = createSelector([filesIdsSelector, dataSelector], buildCollection('sharedFile'))
export const myFilesSelector = createSelector([myFilesIdsSelector, dataSelector], buildCollection('clientAttachment'))

export const lastClientFormIdSelector = (state, formType) =>
  compose(last, path(['clientAccountDocuments', 'formIds', formType]))(state)

export const lastFileIdSelector = createSelector(filesIdsSelector, last)

export const clientFormsLoadingSelector = state =>
  loadingSelector(state, fetchFormsEndpoint(FORM_TYPES.intake).endpoint)
export const clientFormsTotalCountSelector = state =>
  totalCountSelector(state, fetchFormsEndpoint(FORM_TYPES.intake).endpoint)
export const clientFormsGroupedSelector = createSelector(intakeFormsSelector, clientGroupedForms)
export const isFormsBlankStateSelector = path(['clientAccountDocuments', 'isBlankState', 'intakeForms'])

export const clientAgreementsLoadingSelector = state =>
  loadingSelector(state, fetchFormsEndpoint(FORM_TYPES.agreement).endpoint)
export const clientAgreementsTotalCountSelector = state =>
  totalCountSelector(state, fetchFormsEndpoint(FORM_TYPES.agreement).endpoint)
export const clientAgreementsGroupedSelector = createSelector(agreementsSelector, clientGroupedForms)
export const isAgreementsBlankStateSelector = path(['clientAccountDocuments', 'isBlankState', 'agreementForms'])

export const clientFilesLoadingSelector = state => loadingSelector(state, fetchFilesEndpoint.endpoint)
export const clientFilesTotalCountSelector = state => totalCountSelector(state, fetchFilesEndpoint.endpoint)
export const clientFilesGroupedSelector = createSelector(
  filesSelector,
  groupBy(file => (isPresent(file.viewedAt) ? 'viewed' : 'pending')),
)
export const isFilesBlankStateSelector = path(['clientAccountDocuments', 'isFilesBlankState'])

export const clientFormsMetaSelector = (state, formType) =>
  endpointMetaSelector(state, fetchFormsEndpoint(formType).endpoint)
export const clientFilesMetaSelector = state => endpointMetaSelector(state, fetchFilesEndpoint.endpoint)

export const formsPaginationSelector = (state, formType) =>
  path(['clientAccountDocuments', 'formsPagination', formType], state)

export const filesPaginationSelector = path(['clientAccountDocuments', 'filesPagination'])

export const documentsPaginationSelector = path(['clientAccountDocuments', 'documentsPagination'])
export const clientDocumentsLoadingSelector = state => loadingSelector(state, fetchDocumentsEndpoint.endpoint)
export const clientDocumentsTotalCountSelector = state => totalCountSelector(state, fetchDocumentsEndpoint.endpoint)
export const clientDocumentsMetaSelector = state => endpointMetaSelector(state, fetchDocumentsEndpoint.endpoint)
export const isDocumentsBlankStateSelector = path(['clientAccountDocuments', 'isDocumentsBlankState'])
export const documentsIdsSelector = path(['clientAccountDocuments', 'documentIds'])
export const documentsSelector = createSelector([documentsIdsSelector, dataSelector], buildCollection('document'))
export const clientDocumentsGroupedSelector = createSelector(
  documentsSelector,
  currentClientSelector,
  (documents, currentClient) =>
    groupBy(document => {
      const { currentSignatory, documentSignatories = [] } = document
      const currentSigner = documentSignatories.find(
        documentSignatory =>
          isSignerYou({ documentSignatory, currentSignatory, currentClient }) &&
          isSignerSentForSignature(camelCase(documentSignatory.status)),
      )

      return isWaitingForSignature(camelCase(document?.status)) && isPresent(currentSigner) ? 'pending' : 'viewed'
    })(documents),
)
export const clientDocumentsFiltersSelector = path(['clientAccountDocuments', 'documentsFilters'])
export const clientFilesFiltersSelector = path(['clientAccountDocuments', 'filesFilters'])
export const clientMyFilesFiltersSelector = path(['clientAccountDocuments', 'myFilesFilters'])
export const myFilesPaginationSelector = path(['clientAccountDocuments', 'myFilesPagination'])
export const isMyFilesBlankStateSelector = path(['clientAccountDocuments', 'isMyFilesBlankState'])
export const clientUploadedFilesLoadingSelector = state => loadingSelector(state, fetchMyFilesEndpoint.endpoint)
