import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import usePermission from 'hooks/shared/redirect/usePermission'
import { DEFAULT_APPOINTMENT_MEMBERS_COUNT } from 'lib/constants/videoCalls'
import { companyClientRoute, companySettingsEmployeeRoute } from 'lib/routes'
import profileName from 'utils/profileName'
import InvitedClientProfile from 'views/shared/AppointmentDetails/ClientDetails/InvitedClientProfile'
import PendingClientProfile from 'views/shared/AppointmentDetails/ClientDetails/PendingClientProfile'
import Avatar from 'views/shared/Avatar'
import Link from 'views/shared/Link'
import UserAltO from 'views/shared/icons/UserAltO'
import InviteMembersToAppointment from '../InviteMembersToAppointment'

const Members = ({ booking: { clientProfile, userProfile }, booking }) => {
  const isCanSeeEmployeeDetails = !usePermission('manageTeam')

  return (
    <div className="d-flex gap-16">
      <div className="w-20 text-center">
        <UserAltO className="mr-16 in-blue-gray-200" size={20} />
      </div>
      <div className="flex-1 members-appointment-wrap">
        <p className="in-blue-gray-800 mb-8">
          <FormattedMessage id="bookings.members" />
          <span className="tooltip-doc-counter bg-gray-200 d-inline-flex lh-1 font-400 min-w-16 ml-4 pl-4 pr-4 pt-2 pb-2">
            {DEFAULT_APPOINTMENT_MEMBERS_COUNT}
          </span>
        </p>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex align-items-center">
            {isCanSeeEmployeeDetails ? (
              <>
                <Link
                  href={companySettingsEmployeeRoute(userProfile.id)}
                  legacyBehavior={false}
                  className="member-link"
                >
                  <span className="member-link__info">
                    <Avatar
                      avatarClassName="mr-12"
                      sizeClassName="main-userpic main-userpic--xs"
                      imageSize="small"
                      profile={userProfile}
                    />
                    <p className="mb-0 text-body truncate-text" data-cy="expert-fullname">
                      {profileName(userProfile)}
                    </p>
                  </span>
                </Link>
                <span className="in-gray-700 ml-12" data-cy="expert-title">
                  <FormattedMessage id="bookings.expert" />
                </span>
              </>
            ) : (
              <>
                <Avatar
                  avatarClassName="mr-12"
                  sizeClassName="main-userpic main-userpic--xs"
                  imageSize="small"
                  profile={userProfile}
                />
                <div className="d-flex align-items-center">
                  <p className="mb-0 text-body truncate-text" data-cy="expert-fullname">
                    {profileName(userProfile)}
                  </p>
                  <span className="in-gray-700 ml-12">
                    <FormattedMessage id="bookings.expert" />
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column flex-1 w-100">
              <div className="d-flex align-items-center">
                <Link href={companyClientRoute(clientProfile.id)}>
                  <a className="member-link">
                    <span className="member-link__info">
                      {clientProfile.firstName ? (
                        <InvitedClientProfile clientProfile={clientProfile} />
                      ) : (
                        <PendingClientProfile clientProfile={clientProfile} />
                      )}
                    </span>
                  </a>
                </Link>
                <span className="in-gray-700 ml-12" data-cy="client-title">
                  <FormattedMessage id="bookings.client" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <InviteMembersToAppointment booking={booking} />
      </div>
    </div>
  )
}

Members.propTypes = {
  booking: PropTypes.shape({
    clientProfile: PropTypes.shape().isRequired,
    userProfile: PropTypes.shape().isRequired,
  }).isRequired,
}

export default Members
