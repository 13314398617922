import PropTypes from 'prop-types'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Field } from 'formik'
import { negate } from 'ramda'

import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'

const IssueRefundModalComponent = ({ onClose, handleSubmit, transaction: { amount } }) => (
  <Modal className="main-modal main-modal--md">
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title in-blue-gray-800" data-cy="issue-refund-modal-title">
          <FormattedMessage id="payment.issueRefund.modal.title" />
        </h2>
        <button type="button" className="main-modal__close" onClick={onClose}>
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body min-h-a pb-0">
        <div className="d-flex justify-content-between mb-24">
          <span className="bold-700">
            <FormattedMessage id="payment.issueRefund.modal.refundAmount" />
          </span>
          <span className="payment-tag payment-tag--red" data-cy="issue-refund-modal-amount">
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber value={negate(amount)} style="currency" currency="USD" />
          </span>
        </div>
        <Field
          name="cancellationReason"
          id="cancellationReason"
          component={TextAreaField}
          label={{ id: 'payment.issueRefund.modal.cancellationReason.label' }}
          wrapperClassName="mb-0"
          autoSize={{ minRows: 4, maxRows: 6 }}
          className="main-input--textarea-top-count"
          placeholder={{ id: 'payment.issueRefund.modal.cancellationReason.placeholder' }}
          showCount
          maxLength={MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH}
          icon="alert"
          data-cy="issue-refund-modal-reason-cancel"
        />
      </div>
      <div className="main-modal__footer">
        <Button
          className="mr-16"
          text={{ id: 'shared.leave' }}
          kind="flat"
          onClick={onClose}
          dataCy="issue-refund-modal-cancel"
        />
        <SubmitFormButton
          onClick={handleSubmit}
          text={{ id: 'payment.issueRefund.modal.submit' }}
          dataCy="issue-refund-modal-submit"
        />
      </div>
    </div>
  </Modal>
)

IssueRefundModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  transaction: PropTypes.shape().isRequired,
}

export default IssueRefundModalComponent
