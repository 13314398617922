import endpoint from 'utils/endpoint'

import { tasksRoute, taskRoute, taskCompleteRoute } from 'lib/apiRoutes'
import {
  FETCH_TASKS,
  DELETE_TASK,
  CREATE_TASK,
  UPDATE_TASK_PRIORITY,
  MARK_TASK_AS_COMPLETED,
  MARK_TASK_AS_TODO,
  UPDATE_TASK,
} from './types'

export const fetchTasksEndpoint = endpoint(FETCH_TASKS, 'GET', tasksRoute)
export const deleteTaskEndpoint = id => endpoint(DELETE_TASK, 'DELETE', taskRoute(id))
export const createTaskEndpoint = endpoint(CREATE_TASK, 'POST', tasksRoute)
export const updateTaskPriorityEndpoint = id => endpoint(UPDATE_TASK_PRIORITY, 'PUT', taskRoute(id))
export const markTaskAsCompletedEndpoint = id => endpoint(MARK_TASK_AS_COMPLETED, 'POST', taskCompleteRoute(id))
export const markTaskAsTodoEndpoint = id => endpoint(MARK_TASK_AS_TODO, 'DELETE', taskCompleteRoute(id))
export const updateTaskEndpoint = id => endpoint(UPDATE_TASK, 'PUT', taskRoute(id))
