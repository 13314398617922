import { createLogic } from 'redux-logic'
import snakeCase from 'lodash/snakeCase'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { currentOnboardingContentSelector } from 'state/concepts/session/selectors'
import { UPDATE_USER_ONBOARDING_CONTENT } from 'state/concepts/tutorial/types'
import { updateUserOnboardingContentEndpoint } from 'state/concepts/tutorial/endpoints'

const updateUserOnboardingContentOperation = createLogic({
  type: UPDATE_USER_ONBOARDING_CONTENT,
  latest: true,

  async process({ action: { page }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = updateUserOnboardingContentEndpoint

    try {
      const state = getState()

      await httpClient.put(url, { page: snakeCase(page) })

      const response = updateDataHelper(
        state.data,
        'userOnboardingContent',
        currentOnboardingContentSelector(state).id,
        { attributes: { [page]: true } },
      )

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateUserOnboardingContentOperation
