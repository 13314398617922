import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import useExpertActions from 'hooks/trashedFiles/useExpertActions'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const RestoreFolderModal = ({ trashedFile, onClose }) => {
  const { id } = trashedFile
  const { onRestoreTrashedFiles } = useExpertActions({ trashedFileIds: [id] })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container d-block">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="trashedFiles.restoreFolderModal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose} data-cy="close-button">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <p className="main-modal__body-text mb-0">
            <FormattedMessage id="trashedFiles.restoreFolderModal.description" />
          </p>
        </div>
        <div className="main-modal__footer">
          <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
          <Button
            className="main-modal__footer-action"
            type="submit"
            text={{ id: 'trashedFiles.actions.restore' }}
            onClick={onRestoreTrashedFiles}
            dataCy="restore-button"
          />
        </div>
      </div>
    </Modal>
  )
}

RestoreFolderModal.propTypes = {
  trashedFile: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RestoreFolderModal
