import { EDIT_WEBSITE_PREVIEW_STEPS } from 'lib/constants/website'
import MainMenu from './MainStep'
import EnableSections from './EnableSections'
import GoogleSeo from './GoogleSeo'

// eslint-disable-next-line import/prefer-default-export
export const STEPS = {
  [EDIT_WEBSITE_PREVIEW_STEPS.main]: MainMenu,
  [EDIT_WEBSITE_PREVIEW_STEPS.enableSections]: EnableSections,
  [EDIT_WEBSITE_PREVIEW_STEPS.googleSeo]: GoogleSeo,
}
