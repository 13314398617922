import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { REMOVE_CLIENT_ACCESS } from 'state/concepts/companyClientAttachments/types'
import { showNotification } from 'state/notifications/actions'
import { dataClearRelationship } from 'state/data/actions'
import { deleteClientAccessEndpoint } from '../endpoints'

const removeClientAccessOperation = createLogic({
  type: REMOVE_CLIENT_ACCESS,
  latest: true,

  async process({ action: { sharedFileId, attachmentId }, httpClient }, dispatch, done) {
    const { url } = deleteClientAccessEndpoint(sharedFileId)

    try {
      await httpClient.delete(url)

      dispatch(
        dataClearRelationship({
          kind: 'clientAttachment',
          ids: attachmentId,
          relationship: 'sharedFile',
        }),
      )

      dispatch(showNotification({ messageObject: { id: 'notifications.fileAccessWasRevoked' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default removeClientAccessOperation
