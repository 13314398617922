import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const employeeLogin = makeFormSubmitAction(types.EMPLOYEE_LOGIN)
export const employeeSubmitTwoFa = makeFormSubmitAction(types.EMPLOYEE_SUBMIT_TWO_FA)

export const employeeLoginSuccess = (currentEmployeeId, isEmailAlertShown = false) => ({
  type: types.EMPLOYEE_LOGIN_SUCCESS,
  currentEmployeeId,
  isEmailAlertShown,
})

export const clientSendCode = makeFormSubmitAction(types.CLIENT_SEND_CODE)
export const clientLogin = makeFormSubmitAction(types.CLIENT_LOGIN)

export const clientContactLogin = (id, redirectTo = undefined) => ({
  type: types.CLIENT_CONTACT_LOGIN,
  id,
  redirectTo,
})

export const clientResendCode = email => ({
  type: types.CLIENT_SEND_CODE,
  values: { email },
})

export const clientLoginSuccess = currentClientId => ({
  type: types.CLIENT_LOGIN_SUCCESS,
  currentClientId,
})

export const employeeLogout = ({ isInactive, withoutBroadcast } = {}) => ({
  type: types.EMPLOYEE_LOGOUT,
  isInactive,
  withoutBroadcast,
})

export const employeeLogoutSuccess = workspaceId => ({
  type: types.EMPLOYEE_LOGOUT_SUCCESS,
  workspaceId,
})

export const employeePostLogout = ({ isInactive, withoutBroadcast } = {}) => ({
  type: types.EMPLOYEE_POST_LOGOUT,
  isInactive,
  withoutBroadcast,
})

export const bulkEmployeeLogout = () => ({
  type: types.BULK_EMPLOYEE_LOGOUT,
})

export const bulkEmployeeLogoutSuccess = () => ({
  type: types.BULK_EMPLOYEE_LOGOUT_SUCCESS,
})

export const clientLogoutSuccess = () => ({
  type: types.CLIENT_LOGOUT_SUCCESS,
})

export const clientLogout = ({ isInactive, withoutBroadcast } = {}) => ({
  type: types.CLIENT_LOGOUT,
  isInactive,
  withoutBroadcast,
})

export const clientPostLogout = ({ isInactive, withoutBroadcast } = {}) => ({
  type: types.CLIENT_POST_LOGOUT,
  isInactive,
  withoutBroadcast,
})

export const keepAlive = () => ({
  type: types.KEEP_ALIVE,
})

export const guestLoginSuccess = currentGuestId => ({
  type: types.GUEST_LOGIN_SUCCESS,
  currentGuestId,
})

export const guestLogoutSuccess = () => ({
  type: types.GUEST_LOGOUT_SUCCESS,
})

export const resendOtpCodeOnLogin = () => ({
  type: types.RESEND_OTP_CODE_ON_LOGIN,
})
