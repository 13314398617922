import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchCardReadersEndpoint } from './endpoints'

export const cardReadersIds = path(['cardReaders', 'ids'])

export const cardReadersSelector = createSelector([cardReadersIds, dataSelector], buildCollection('cardReader'))

export const cardReadersLoadingSelector = state => loadingSelector(state, fetchCardReadersEndpoint.endpoint)

export const cardReaderByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'cardReader', id) : null,
)
