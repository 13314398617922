const payment = {
  'payment.header.title.payment': 'Payment {paymentNumber}',
  'payment.header.title.refund': 'Refund {paymentNumber}',
  'payment.header.action.cancelPayment': 'Cancel payment',
  'payment.header.action.resendRequest': 'Resend request',
  'payment.header.action.issueReceipt': 'Issue receipt',
  'payment.header.action.editPayment': 'Edit payment',
  'payment.header.action.deleteDraft': 'Delete draft',
  'payment.header.action.view.invoice': 'View invoice',
  'payment.header.action.view.receipt': 'View receipt',
  'payment.header.action.view.creditNote': 'View credit note',
  'payment.header.action.downloadPDF.invoice': 'Download invoice',
  'payment.header.action.downloadPDF.receipt': 'Download receipt',
  'payment.header.action.downloadPDF.creditNote': 'Download credit note',
  'payment.header.action.issueRefund': 'Issue refund',
  'payment.header.action.viewAppointment': 'View _(appointment)',
  'payment.header.action.editRecurringPayment': 'Edit recurring payment',
  'payment.header.action.cancelRecurringPayment': 'Cancel recurring payment',
  'payment.header.button.sendPayment': 'Send payment',
  'payment.header.button.markAsPaid': 'Mark as paid',
  'payment.header.button.chargeWithCardReader': 'Charge with card reader',
  'payment.header.button.collectPayment': 'Collect payment',

  'payment.generalInformation.title': 'General information',
  'payment.generalInformation.billTo': 'Bill to',
  'payment.generalInformation.client': '_(Client)',
  'payment.generalInformation.billedTo': 'Billed to',
  'payment.generalInformation.paidBy': 'Paid by',
  'payment.generalInformation.paymentType': 'Payment type',
  'payment.generalInformation.paymentType.payment_for_appointment': 'Payment for the _(appointment)',
  'payment.generalInformation.paymentType.payment_request': 'Payment request',
  'payment.generalInformation.paymentType.recurringPaymentRequest': 'Recurring payment request',
  'payment.generalInformation.paymentType.payment_record': 'Payment Record',
  'payment.generalInformation.paymentType.refund': 'Refund for {refundedPayment}',
  'payment.generalInformation.issueDate': 'Issue date',
  'payment.generalInformation.dueDate': 'Due date',

  'payment.paymentDetails.title': 'Payment details',
  'payment.paymentDetails.amount': 'Amount',
  'payment.paymentDetails.paymentCreatedBy': 'Payment created by',
  'payment.paymentDetails.paymentCancelledBy': 'Payment cancelled by',
  'payment.paymentDetails.paymentInstructions': 'Payment instructions',
  'payment.paymentDetails.chargeableStatus': 'Transaction status',
  'payment.paymentDetails.chargeableStatus.failure': 'Failed',
  'payment.paymentDetails.chargeableStatus.success': 'Successful',
  'payment.paymentDetails.paymentMethod': 'Payment method',
  'payment.paymentDetails.paidOn': 'Paid on',
  'payment.paymentDetails.refundedOn': 'Refunded on',
  'payment.paymentDetails.cancelledOn': 'Cancelled on',
  'payment.paymentDetails.refundReason': 'Refund reason',

  'payment.paymentMethod.creditCard': 'Credit card',
  'payment.paymentMethod.creditCardWithDigits': 'Credit card, **** **** **** {lastDigits}',
  'payment.paymentMethod.creditCardWithDigitsAndTerminalName':
    'Credit card, **** **** **** {lastDigits} ({terminalName})',
  'payment.paymentMethod.cash': 'Cash',
  'payment.paymentMethod.check': 'Check',
  'payment.paymentMethod.transfer': 'Bank transfer',
  'payment.paymentMethod.payPal': 'PayPal ({payerEmail})',

  'payment.services.title': '_(Services)',
  'payment.services.service': '_(Service)',
  'payment.services.price': 'Price',
  'payment.services.quantity': 'Quantity',
  'payment.services.total': 'Total',
  'payment.services.totalAmount': 'Total amount:',
  'payment.services.paid': 'Paid:',
  'payment.services.refundAmount': 'Refund amount:',
  'payment.services.refundedAmount': 'Refunded:',
  'payment.services.serviceNameWith': '{name} <span>with</span> {provider}',
  'payment.paymentDetails': 'Payment details',

  'paymentCheckout.summary': 'Summary',
  'paymentCheckout.title': 'Payment',
  'paymentCheckout.issueDate': 'Issue date:',
  'paymentCheckout.dueDate': 'Due date:',
  'paymentCheckout.recurringAlert': 'You will be charged {times} times for {amount}',
  'paymentCheckout.paymentTotal': 'Total amount:',

  'markAsPaid.modal.payment.title': 'Mark as paid',
  'markAsPaid.modal.payment.total': 'Total amount:',
  'markAsPaid.modal.payment.paidOn.label': 'Paid on',
  'markAsPaid.modal.payment.submit': 'Mark as paid',
  'markAsPaid.modal.refund.title': 'Mark as refunded',
  'markAsPaid.modal.refund.total': 'Refund amount:',
  'markAsPaid.modal.refund.paidOn.label': 'Refunded on',
  'markAsPaid.modal.refund.submit': 'Mark as refunded',
  'markAsPaid.modal.paymentMethod.label': 'Payment method',
  'markAsPaid.modal.paymentMethod.placeholder': 'Select payment method',

  'payment.deleteDraft.confirmModal.title': 'Delete draft payment?',
  'payment.deleteDraft.confirmModal.body': 'Once deleted, it cannot be restored.',
  'payment.deleteDraft.confirmModal.submit': 'Delete draft',
  'payment.number.payment_for_appointment': 'Payment {number}',
  'payment.number.payment_request': 'Payment {number}',
  'payment.number.payment_record': 'Payment {number}',
  'payment.number.refund': 'Refund {number}',
  'payment.paymentForm.updatePayment': 'Send updated request',
  'payment.paymentForm.saveAndSend': 'Save and send',
  'payment.edit.header.title': 'Edit payment {paymentNumber}',
  'payment.paymentMethod.card': 'Card',
  'payment.paymentMethod.creditOrDebit': 'Credit or debit',
  'payment.paymentMethod.paypalTitle': 'PayPal external payment method. Click to “Pay with PayPal” to finish payment.',
  'payment.payPal': 'PayPal',
  'payment.issueRefund.modal.title': 'Issue refund',
  'payment.issueRefund.modal.refundAmount': 'Refund amount:',
  'payment.issueRefund.modal.cancellationReason.label': 'Refund reason',
  'payment.issueRefund.modal.cancellationReason.placeholder': 'Add here reason of cancelation',
  'payment.issueRefund.modal.submit': 'Issue refund',

  'payment.chargeWithCardReader.modal.title': 'Charge with card reader',
  'payment.chargeWithCardReader.modal.total': 'Total amount',
  'payment.chargeWithCardReader.modal.processing': 'Please, wait. Processing payment...',
  'payment.chargeWithCardReader.modal.chargePayment': 'Charge payment',

  'payment.chargeWithCardReader.terminalSelect.label': 'Select terminal you want to use for payment.',
  'payment.chargeWithCardReader.terminalSelect.placeholder': 'Select terminal',

  'payment.chargeWithCardReader.cardNumber': 'Card number',
  'payment.chargeWithCardReader.cardNumber.placeholder': '0000 0000 0000 0000',
}

export default payment
