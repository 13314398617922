import yup from 'lib/yupLocalised'
import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import { ACTIVE_BOOKINGS, RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'

export default ({ cancellationRules, booking: { status } }) =>
  yup.object().shape({
    cancellationReason: yup
      .string()
      .max(MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH)
      .when('recurringOption', {
        is: val =>
          (val === RECURRING_BOOKING_OPTIONS.all && cancellationRules.validateCancellationReason) ||
          ACTIVE_BOOKINGS.includes(status),
        then: schema => schema.required(),
      }),
  })
