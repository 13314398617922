import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import { formsDeclineSuccessRoute } from 'utils/forms'
import {
  currentWorkspaceCodeSelector,
  isEmployeeLoggedInSelector,
  isGuestLoggedInSelector,
} from 'state/concepts/session/selectors'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { declineToSignFormEndpoint } from '../endpoints'
import { DECLINE_TO_SIGN_FORM } from '../types'

const declineToSignFormOperation = createLogic({
  type: DECLINE_TO_SIGN_FORM,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { declineReason, clientProfileFormId } = values
    const { url, endpoint } = declineToSignFormEndpoint(clientProfileFormId)
    const state = getState()

    const workspaceCode = currentWorkspaceCodeSelector(state)
    const isEmployeeLoggedIn = isEmployeeLoggedInSelector(state)
    const isGuestLoggedIn = isGuestLoggedInSelector(state)

    const params = {
      decline_reason: declineReason,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(hideModal())

      redirect({
        href: formsDeclineSuccessRoute({ isEmployeeLoggedIn, workspaceCode, isGuestLoggedIn }),
        workspace: isEmployeeLoggedIn ? workspaceCode : null,
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default declineToSignFormOperation
