import createWidget from './createWidget'
import fetchWidget from './fetchWidget'
import updateWidget from './updateWidget'
import sendInstructions from './sendInstructions'
import deleteWidget from './deleteWidget'
import toggleWidgetStatus from './toggleWidgetStatus'
import fetchWidgetServices from './fetchWidgetServices'
import updateWidgetServices from './updateWidgetServices'
import validateWidgetSettings from './validateWidgetSettings'

export default [
  createWidget,
  fetchWidget,
  updateWidget,
  sendInstructions,
  deleteWidget,
  toggleWidgetStatus,
  fetchWidgetServices,
  updateWidgetServices,
  validateWidgetSettings,
]
