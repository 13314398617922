import { prop, reject } from 'ramda'

import { CLIENT_CONTACTS_TABS, CLIENT_CONTACT_TYPE } from 'lib/constants/clientContacts'

export const getClientContactsTabOptions = (excludeKeys = []) =>
  [
    {
      value: CLIENT_CONTACTS_TABS.existingClient,
      key: CLIENT_CONTACTS_TABS.existingClient,
      label: { id: 'clientContacts.addContact.contactTab.existingClient' },
    },
    {
      value: CLIENT_CONTACTS_TABS.existingContact,
      key: CLIENT_CONTACTS_TABS.existingContact,
      label: { id: 'clientContacts.addContact.contactTab.existingContact' },
    },
    {
      value: CLIENT_CONTACTS_TABS.newContact,
      key: CLIENT_CONTACTS_TABS.newContact,
      label: { id: 'clientContacts.addContact.contactTab.newContact' },
    },
  ].filter(tab => !excludeKeys.includes(tab.value))

export const createContactValuesToParams = values => {
  const {
    contactFrom,
    clientId,
    clientRelationshipType,
    emergencyContact,
    billingResponsible,
    note,
    existingClientId,
    existingContactId,
    firstName,
    lastName,
    phoneNumber,
    email,
  } = values

  if (contactFrom === CLIENT_CONTACTS_TABS.existingClient || contactFrom === CLIENT_CONTACTS_TABS.existingContact)
    return {
      client_profile_id: clientId,
      client_relationship_type_id: clientRelationshipType,
      emergency_contact: emergencyContact,
      responsible_for_billing: billingResponsible,
      note_text: note,
      contact_client_profile_id:
        contactFrom === CLIENT_CONTACTS_TABS.existingClient ? existingClientId : existingContactId,
    }

  return {
    client_profile_id: clientId,
    client_relationship_type_id: clientRelationshipType,
    emergency_contact: emergencyContact,
    responsible_for_billing: billingResponsible,
    note_text: note,
    contact_client_profile: {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      client_account: { email },
    },
  }
}

export const hasOneContactAccount = clientProfile =>
  !clientProfile.accountActive && clientProfile.associatedClientContacts?.length === 1

export const soleAssociatedContact = clientProfile =>
  hasOneContactAccount(clientProfile) ? clientProfile.associatedClientContacts?.[0] : null

export const activeClientContacts = clientContacts => reject(prop('destroyRequestedAt'), clientContacts)

export const isEntityContactType = entity => entity?.type === CLIENT_CONTACT_TYPE
