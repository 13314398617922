import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { GET_MICROSOFT_TEAMS_CONNECT_LINK } from '../types'
import { getMicrosoftTeamsConnectLinkEndpoint } from '../endpoints'
import { setMicrosoftTeamsConnectLink } from '../actions'

const getMicrosoftTeamsConnectLinkOperation = createLogic({
  type: GET_MICROSOFT_TEAMS_CONNECT_LINK,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = getMicrosoftTeamsConnectLinkEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setMicrosoftTeamsConnectLink(data.meta.url))
    } catch (error) {
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.microsoftTeamsConnectionFailed' },
          kind: 'error',
        }),
      )
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getMicrosoftTeamsConnectLinkOperation
