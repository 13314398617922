import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_REVIEW_SETTINGS } from 'state/concepts/reviewSettings/types'
import { setReviewSettingId } from 'state/concepts/reviewSettings/actions'
import { fetchReviewSettingsEndpoint } from 'state/concepts/reviewSettings/endpoints'

const fetchReviewSettingsOperation = createLogic({
  type: FETCH_REVIEW_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchReviewSettingsEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)

      dispatch(setReviewSettingId(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchReviewSettingsOperation
