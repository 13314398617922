import * as types from './types'

export const showSidebar = ({ sidebarType, sidebarProps, showOverlay, animate }) => ({
  type: types.SHOW_SIDEBAR,
  sidebarType,
  sidebarProps,
  showOverlay,
  animate,
})

export const hideSidebar = () => ({
  type: types.HIDE_SIDEBAR,
})

export const updateSidebar = ({ sidebarProps }) => ({
  type: types.UPDATE_SIDEBAR,
  sidebarProps,
})
