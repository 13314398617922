import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_PAYMENT } from 'state/concepts/payment/types'
import { setPaymentId } from 'state/concepts/payment/actions'
import { getPaymentEndpoint } from '../endpoints'

const getPaymentOperation = createLogic({
  type: GET_PAYMENT,
  latest: true,

  async process({ action: { transactionId }, httpClient }, dispatch, done) {
    const { endpoint, url } = getPaymentEndpoint(transactionId)

    const params = {
      include: [
        'user-profile',
        'client-profile',
        'transaction-items',
        'booking',
        'receipt-template',
        'refunded-transaction',
        'refund',
        'workspace',
        'recurring-payment',
        'contact-payer',
        'booking.cancelled_by.client-relationship-type',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setPaymentId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getPaymentOperation
