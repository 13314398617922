import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import widgetFormToParams from 'utils/widgets/widgetFormToParams'
import { dataApiSuccess } from 'state/data/actions'
import { UPDATE_WIDGET } from 'state/concepts/widget/types'
import { widgetIdSelector } from 'state/concepts/widget/selectors'
import { showNotification } from 'state/notifications/actions'
import { updateWidgetEndpoint } from 'state/concepts/widget/endpoints'

const updateWidgetOperation = createLogic({
  type: UPDATE_WIDGET,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const { form, values } = action
    const state = getState()
    const id = widgetIdSelector(state)
    const { url } = updateWidgetEndpoint(id)

    try {
      const params = widgetFormToParams(values)

      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'widget', id, {
        attributes: {
          ...values,
          buttonAsIcon: !values.buttonAsText,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingPageHasBeenUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateWidgetOperation
