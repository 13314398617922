import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Field } from 'formik'

import colors from 'lib/constants/categoriesColors'
import Modal from 'views/shared/Modal'
import InputField from 'views/shared/InputField'
import RadioColorField from 'views/shared/RadioColorField'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const PersistServiceCategoriesModal = ({
  onClose,
  isSubmitDisabled,
  title,
  label,
  placeholder,
  submitText,
  dismissText,
}) => (
  <Modal className="main-modal" onClose={onClose}>
    <div className="main-modal__container">
      <Form>
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="modal-title">
            <FormattedMessage {...title} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <Field
            className="mb-24"
            component={InputField}
            type="text"
            name="name"
            id="name"
            icon="alert"
            placeholder={placeholder}
          />
          <div className="main-input__label mb-16" data-cy="select-category-color-text">
            <FormattedMessage {...label} />
          </div>
          <div>
            <Field className="category-color" name="color" component={RadioColorField} options={colors} />
          </div>
        </div>
        <div className="main-modal__footer">
          <Button className="main-modal__footer-action" text={dismissText} onClick={onClose} kind="flat" />
          <SubmitFormButton
            className="main-modal__footer-action"
            text={submitText}
            kind={isSubmitDisabled ? 'outline' : null}
          />
        </div>
      </Form>
    </div>
  </Modal>
)

PersistServiceCategoriesModal.defaultProps = {
  label: { id: 'serviceCategories.selectColors.modal.label' },
  placeholder: { id: 'serviceCategories.selectColors.modal.placeholder' },
  dismissText: { id: 'shared.cancel' },
}

PersistServiceCategoriesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  title: PropTypes.shape({ id: PropTypes.string }).isRequired,
  label: PropTypes.shape({ id: PropTypes.string }),
  placeholder: PropTypes.shape({ id: PropTypes.string }),
  submitText: PropTypes.shape({ id: PropTypes.string }).isRequired,
  dismissText: PropTypes.shape({ id: PropTypes.string }),
}

export default PersistServiceCategoriesModal
