import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import TextAreaField from 'views/shared/TextAreaField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { declineToSignForm } from 'state/concepts/form/actions'
import { MAX_TEXTAREA_CONTENT_NORMAL_LENGTH } from 'lib/constants'

const DeclineToSignFormModal = ({ onClose, respondent, clientProfileFormId }) => {
  const handleSubmit = useFormSubmit(declineToSignForm)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="formDeclining.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            declineReason: '',
            clientProfileFormId,
          }}
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              <p className="mb-16">
                <FormattedMessage id={`formDeclining.description.${respondent}`} />
              </p>
              <Field
                className="main-input--textarea-top-count"
                component={TextAreaField}
                showCount
                maxLength={MAX_TEXTAREA_CONTENT_NORMAL_LENGTH}
                label={{ id: 'formDeclining.declineReason.label' }}
                name="declineReason"
                id="declineReason"
                wrapperClassName="mb-0"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </div>
            <div className="main-modal__footer">
              <div className="d-flex justify-content-end">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton kind="danger" text={{ id: 'formDeclining.submit' }} />
              </div>
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

DeclineToSignFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  respondent: PropTypes.string.isRequired,
  clientProfileFormId: PropTypes.string.isRequired,
}

export default DeclineToSignFormModal
