const calendarEvents = {
  'calendarEvents.outlookCalendarListItem': 'Microsoft Outlook',
  'calendarEvents.googleCalendarListItem': 'Google Meet',
  'calendarEvents.appleCalendarListItem': 'iCloud Calendar',
  'calendarEvents.members': '{count} members',
  'calendarEvents.showAllMembers': 'Show all members',
  'calendarEvents.joinVideoCall': 'Join video call',
}

export default calendarEvents
