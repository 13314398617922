import PropTypes from 'prop-types'
import { RemoveScroll } from 'react-remove-scroll'

import Tooltip from 'views/shared/Tooltip'
import BookingCalendarTooltipContent from './TooltipContent'

const BookingCalendarTooltipComponent = ({ visible, onClose, onVisibleChange, booking }) =>
  visible ? (
    <Tooltip open={visible} onOpenChange={onVisibleChange} overlayClassName="booking-tooltip-wrapper">
      <RemoveScroll enabled={visible}>
        <BookingCalendarTooltipContent onClose={onClose} onCancel={onClose} booking={booking} />
      </RemoveScroll>
    </Tooltip>
  ) : null

BookingCalendarTooltipComponent.defaultProps = {
  booking: {},
  onVisibleChange: () => {},
}

BookingCalendarTooltipComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onVisibleChange: PropTypes.func,
  booking: PropTypes.shape(),
}

export default BookingCalendarTooltipComponent
