const namespace = 'payment'

export const GET_PAYMENT = `${namespace}/GET_PAYMENT`
export const SET_PAYMENT_ID = `${namespace}/SET_PAYMENT_ID`
export const ACCEPT_AND_PAY = `${namespace}/ACCEPT_AND_PAY`
export const GET_PAYMENT_CHECKOUT = `${namespace}/GET_PAYMENT_CHECKOUT`
export const MARK_AS_PAID = `${namespace}/MARK_AS_PAID`
export const DELETE_DRAFT_PAYMENT = `${namespace}/DELETE_DRAFT_PAYMENT`
export const SEND_PAYMENT = `${namespace}/SEND_PAYMENT`
export const UPDATE_PAYMENT = `${namespace}/UPDATE_PAYMENT`
export const ISSUE_REFUND = `${namespace}/ISSUE_REFUND`
export const DISABLE_PAYMENT_CANCELLATION = `${namespace}/DISABLE_PAYMENT_CANCELLATION`
export const CHARGE_WITH_CARD_READER = `${namespace}/CHARGE_WITH_CARD_READER`
