import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { RESET_TWO_FACTOR_AUTH } from 'state/concepts/employees/types'
import { dataApiRequest, dataClearRelationship } from 'state/data/actions'
import { resetTwoFactorAuthEndpoint } from 'state/concepts/employees/endpoints'
import profileName from 'utils/profileName'

const resetTwoFactorAuthOperation = createLogic({
  type: RESET_TWO_FACTOR_AUTH,
  latest: true,

  async process({ action: { employee }, httpClient }, dispatch, done) {
    const { url, endpoint } = resetTwoFactorAuthEndpoint(employee.id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(
        dataClearRelationship({
          kind: 'userProfile',
          ids: [employee.id],
          relationship: 'userProfileTwoFactorSetting',
        }),
      )

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.resetTwoFactorAuth', values: { name: profileName(employee) } },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default resetTwoFactorAuthOperation
