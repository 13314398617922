import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import isPresent from 'utils/isPresent'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import EmailsInputField from 'views/shared/EmailsInputField'
import { addMembers } from 'state/concepts/inviteAppointmentMembers/actions'
import { APPOINTMENT_INVITED_MEMBERS_LIMIT } from 'lib/constants/videoCalls'

const InviteMembersToAppointmentModal = ({ onClose, invitedMembersEmails, bookingUniqCode, isVideoConference }) => {
  const isCurrentUserEmployee = isPresent(useSelector(currentEmployeeIdSelector))
  const handleSubmit = useFormSubmit(addMembers)

  return (
    <Modal className="main-modal main-modal--md">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="inviteMembers.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            emails: [],
            bookingUniqCode,
            isVideoConference,
          }}
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              <p className="mb-12 text-body">
                <FormattedMessage
                  id={
                    isCurrentUserEmployee ? 'inviteMembers.modal.inviteByExpert' : 'inviteMembers.modal.inviteByClient'
                  }
                  values={{
                    maxItems: APPOINTMENT_INVITED_MEMBERS_LIMIT,
                  }}
                />
              </p>
              <Field
                mainInputClassName="mb-0"
                component={EmailsInputField}
                name="emails"
                id="emails"
                label={{ id: 'inviteMembers.modal.inputLabel' }}
                maxItems={APPOINTMENT_INVITED_MEMBERS_LIMIT}
                invitedMembersCount={invitedMembersEmails.length}
                invitedMembersEmails={invitedMembersEmails}
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton
                className="main-modal__footer-action"
                text={{ id: 'inviteMembers.modal.submitButtonText' }}
              />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

InviteMembersToAppointmentModal.defaultProps = {
  isVideoConference: false,
}

InviteMembersToAppointmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitedMembersEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  bookingUniqCode: PropTypes.string.isRequired,
  isVideoConference: PropTypes.bool,
}

export default InviteMembersToAppointmentModal
