import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GENERATE_BACKUP_CODES } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { generateBackupCodesEndpoint } from '../endpoints'

const generateBackupCodesOperation = createLogic({
  type: GENERATE_BACKUP_CODES,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess } }, dispatch, done) {
    const { endpoint, url } = generateBackupCodesEndpoint
    const { password } = values
    const params = { password }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data, { endpoint }) }))
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default generateBackupCodesOperation
