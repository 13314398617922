import { combineReducers } from '@reduxjs/toolkit'

import { SET_CLIENT, SET_IS_CLIENT_DELETED, SET_NOTIFICATION_SETTING_ID } from './types'

const clientId = (state = '', action) => {
  switch (action.type) {
    case SET_CLIENT:
      return action.clientId
    default:
      return state
  }
}

const notificationSettingId = (state = null, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_SETTING_ID:
      return action.notificationSettingId
    default:
      return state
  }
}

const isClientDeleted = (state = false, action) => {
  switch (action.type) {
    case SET_IS_CLIENT_DELETED:
      return action.isDeleted
    default:
      return state
  }
}

export default combineReducers({
  clientId,
  notificationSettingId,
  isClientDeleted,
})
