import { createLogic } from 'redux-logic'
import cookies from 'component-cookie'

import { rootHttpClient } from 'lib/httpClient'
import { CLIENT_LOGOUT } from 'state/concepts/session/types'
import { clientsSessionRoute } from 'lib/apiRoutes'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { clientPostLogout } from 'state/concepts/session/actions'

const clientLogoutOperation = createLogic({
  type: CLIENT_LOGOUT,
  latest: true,

  async process({ action: { isInactive, withoutBroadcast }, getState }, dispatch, done) {
    try {
      const { uniqCode } = workspaceSelector(getState())
      const { refresh: refreshToken } = JSON.parse(cookies('tokens'))

      await rootHttpClient.delete(`/workspaces/${uniqCode}${clientsSessionRoute}`, {
        headers: { 'X-Refresh-Token': refreshToken },
      })
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      dispatch(clientPostLogout({ isInactive, withoutBroadcast }))
      done()
    }
  },
})

export default clientLogoutOperation
