import fetchFilesSharedWithMe from './fetchFilesSharedWithMe'
import filterFilesSharedWithMe from './filterFilesSharedWithMe'
import moveToFolder from './moveToFolder'
import moveFilesSharedWithMeToTrash from './moveFilesSharedWithMeToTrash'
import fetchFoldersToMove from './fetchFoldersToMove'
import removeMyAccess from './removeMyAccess'
import receivedBusinessStorage from './receivedBusinessStorage'
import receiveBusinessStoragePermissions from './receiveBusinessStoragePermissions'

export default [
  fetchFilesSharedWithMe,
  filterFilesSharedWithMe,
  moveToFolder,
  fetchFoldersToMove,
  moveFilesSharedWithMeToTrash,
  removeMyAccess,
  receivedBusinessStorage,
  receiveBusinessStoragePermissions,
]
