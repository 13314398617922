import { createLogic } from 'redux-logic'
import { path } from 'ramda'

import { showNotification } from 'state/notifications/actions'
import { trashedFilesByIdsSelector } from 'state/concepts/trashedFiles/selectors'
import { fetchTrashedFiles, removeTrashedFileIds } from 'state/concepts/trashedFiles/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { DELETE_TRASHED_FILES } from '../types'
import { deleteTrashedFilesEndpoint } from '../endpoints'

const deleteTrashedFilesOperation = createLogic({
  type: DELETE_TRASHED_FILES,
  latest: true,

  async process({ httpClient, action: { ids, onNextSlide }, getState }, dispatch, done) {
    const state = getState()
    const { url, endpoint } = deleteTrashedFilesEndpoint
    const trashedFiles = trashedFilesByIdsSelector(state, ids)
    const params = {
      business_storage_ids: ids,
    }

    try {
      const { data } = await httpClient.delete(url, { params })
      const { deleted_count: deletedCount } = data.meta

      dispatch(hideModal())
      if (deletedCount) {
        dispatch(removeTrashedFileIds(ids))
        onNextSlide?.()
        !onNextSlide && dispatch(fetchTrashedFiles(undefined, { append: true }))

        if (ids.length === 1) {
          dispatch(
            showNotification({
              messageObject: { id: `notifications.trashedFiles.${path([0, 'kind'], trashedFiles)}Deleted` },
            }),
          )
        } else {
          dispatch(
            showNotification({
              messageObject: {
                id: 'notifications.trashedItemsDeleted',
                values: { count: deletedCount },
              },
            }),
          )
        }
      } else {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: 'error',
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteTrashedFilesOperation
