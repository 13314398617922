import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_FORMS_ID,
  SET_UPLOAD_PROGRESS,
  RESET_UPLOAD_PROGRESS,
  SET_FORM_SUBMISSION_ID,
  SET_IS_AUTO_SAVE_ERROR,
} from './types'

const formId = (state = null, action) => {
  switch (action.type) {
    case SET_FORMS_ID:
      return action.id
    default:
      return state
  }
}

const formSubmissionId = (state = null, action) => {
  switch (action.type) {
    case SET_FORM_SUBMISSION_ID:
      return action.id
    default:
      return state
  }
}

const isAutoSaveError = (state = false, action) => {
  switch (action.type) {
    case SET_IS_AUTO_SAVE_ERROR:
      return action.isAutoSaveError
    default:
      return state
  }
}

const uploadProgress = (state = {}, action) => {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.uploadId]: action.progress,
      }
    case RESET_UPLOAD_PROGRESS:
      return {}
    default:
      return state
  }
}

const formReducer = combineReducers({
  formId,
  formSubmissionId,
  isAutoSaveError,
  uploadProgress,
})

export default formReducer
