import { combineReducers } from '@reduxjs/toolkit'
import { uniq, assocPath, pipe, reduce, tail, without } from 'ramda'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.ADD_MY_BUSINESS_FILE_IDS:
      return uniq([...state, ...action.ids])
    case types.SET_MY_BUSINESS_FILE_IDS:
      return action.ids
    case types.RESET_MY_BUSINESS_FILE_IDS:
      return []
    case types.INSERT_BEFORE_MY_BUSINESS_FILE_ID: {
      const { newId, beforeId } = action.payload
      const index = state.indexOf(beforeId)

      if (index === -1) return uniq([...state, newId])

      const newState = [...state]
      newState.splice(index, 0, newId)
      return uniq(newState)
    }
    case types.REMOVE_MY_BUSINESS_FILE_IDS:
      return without(action.myBusinessFileIds, state)
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_BLANK_STATE:
      return action.isBlankState
    case types.ADD_UPLOAD_FILE:
      return false
    default:
      return state
  }
}

const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SELECTED_IDS:
      return action.myBusinessFileIds
    case types.RESET_SELECTED_IDS:
    case types.RESET_MY_BUSINESS_FILE_IDS:
      return []
    case types.REMOVE_MY_BUSINESS_FILE_IDS:
      return without(action.myBusinessFileIds, state)
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
  employees: [],
  types: [],
  lastModified: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const currentFolderId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_FOLDER_ID:
      return action.folderId
    default:
      return state
  }
}

const uploadFiles = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_UPLOAD_FILE:
      return {
        ...state,
        [action.uploadId]: {
          id: action.uploadId,
          file: action.file,
          meta: action.meta,
          isLoading: true,
          replaceOldFile: action.replaceOldFile,
          parentId: action.parentId,
        },
      }
    case types.COMPLETE_FILE_LOADING:
      return assocPath([action.uploadId], { ...state[action.uploadId], isLoading: false }, state)
    case types.FAIL_FILE_LOADING:
      return assocPath([action.uploadId], { ...state[action.uploadId], isFailed: true, isLoading: false }, state)
    case types.CANCEL_FILE_UPLOAD:
      return pipe(
        reduce(
          (acc, uploadId) => assocPath([uploadId], { ...acc[uploadId], isCancelled: true, isLoading: false }, acc),
          state,
        ),
      )(action.uploadIds)
    case types.REMOVE_UPLOADED_FILES:
      return {}
    default:
      return state
  }
}

const downloadFiles = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_FILES_DOWNLOAD:
      return {
        ...state,
        [action.downloadId]: {
          id: action.downloadId,
          fileIds: action.fileIds,
          isLoading: true,
        },
      }
    case types.FILES_DOWNLOAD_SUCCESS:
      return assocPath([action.downloadId], { ...state[action.downloadId], isLoading: false }, state)
    case types.CANCEL_FILES_DOWNLOAD_SUCCESS:
      return assocPath([action.downloadId], { ...state[action.downloadId], isCancelled: true, isLoading: false }, state)
    case types.REMOVE_FILES_DOWNLOAD:
      return {}
    default:
      return state
  }
}

const pendingFilesDownload = (state = [], action) => {
  switch (action.type) {
    case types.ADD_PENDING_FILES_DOWNLOAD:
      return [...state, action.fileIds]
    case types.ADD_FILES_DOWNLOAD:
      return tail(state)
    case types.REMOVE_FILES_DOWNLOAD:
      return []
    default:
      return state
  }
}

const folderToMoveIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_FOLDER_TO_MOVE_IDS:
      return uniq([...state, ...action.ids])
    case types.SET_FOLDER_TO_MOVE_IDS:
      return action.ids
    case types.RESET_FOLDER_TO_MOVE_IDS:
      return []
    default:
      return state
  }
}

const isDownloadingSectionOpen = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_DOWNLOADING_SECTION_OPEN:
      return action.isOpen
    default:
      return state
  }
}

const isUploadingSectionOpen = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_UPLOADING_SECTION_OPEN:
      return action.isOpen
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isBlankState,
  selectedIds,
  filters,
  currentFolderId,
  uploadFiles,
  downloadFiles,
  pendingFilesDownload,
  folderToMoveIds,
  isDownloadingSectionOpen,
  isUploadingSectionOpen,
})
