import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { FETCH_REMIND_WORKSPACES } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { setRemindWorkspaces } from '../actions'
import { fetchRemindWorkspacesEndpoint } from '../endpoints'

const fetchRemindWorkspacesOperation = createLogic({
  type: FETCH_REMIND_WORKSPACES,
  latest: true,

  async process({ action: { token } }, dispatch, done) {
    const { endpoint, url } = fetchRemindWorkspacesEndpoint
    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await workspacesHttpClient.get(url, { params: { token } })
      const workspaces = build(normalize(data), 'workspace')

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setRemindWorkspaces(workspaces))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchRemindWorkspacesOperation
