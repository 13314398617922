import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck, isEmpty, trim, without } from 'ramda'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import { currentChatUserIdSelector } from 'state/concepts/session/selectors'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SEARCH_CHAT_USERS } from 'state/concepts/chatUsers/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { searchChatUsersEndpoint } from 'state/concepts/chatUsers/endpoints'
import { addSearchResults, setBlankState, resetSearchResults } from 'state/searchResults/actions'

const searchChatUsersOperation = createLogic({
  type: SEARCH_CHAT_USERS,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { chat, query, page, resetResults } = action
    const { endpoint, url } = searchChatUsersEndpoint
    const currentChatUserId = currentChatUserIdSelector(getState())
    const params = {
      page: {
        size: 20,
        number: page || 1,
      },
      filter: {
        name: trim(query),
      },
    }
    if (chat) {
      params.chat_id = chat.id
    }

    try {
      dispatch(dataApiRequest({ endpoint }))

      if (resetResults) {
        dispatch(resetSearchResults('chatUsers'))
      }

      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const chatUsersExceptCurrentUser = without([String(currentChatUserId)], pluck('id', data.data))

      dispatch(
        setBlankState({ [SEARCH_RESULTS_ENTITIES.chatUsers]: isEmpty(query) && isEmpty(chatUsersExceptCurrentUser) }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.chatUsers]: chatUsersExceptCurrentUser }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default searchChatUsersOperation
