import { noop } from 'lodash'
import { PropTypes } from 'prop-types'
import { isEmpty, length, pathEq } from 'ramda'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import emptySearchImg from 'assets/images/auth/empty_search.svg'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  fetchFoldersToMove as fetchFoldersToMoveAction,
  moveToFolder as moveToFolderAction,
  resetFolderToMoveIds as resetFolderToMoveIdsAction,
} from 'state/concepts/filesSharedWithMe/actions'
import { fetchMyFolderToMoveEndpoint } from 'state/concepts/filesSharedWithMe/endpoints'
import {
  filesSharedWithMetaSelector,
  foldersToMoveSelector,
  myFolderToMovePathSelector,
} from 'state/concepts/filesSharedWithMe/selectors'
import { dataMetaClear } from 'state/data/actions'
import { endpointMetaSelector, loadingSelector } from 'state/data/selectors'
import { showModal as showModalAction } from 'state/modal/actions'
import isPresent from 'utils/isPresent'
import Breadcrumbs from 'views/company/MyBusinessFiles/MoveToFolderModal/Breadcrumbs'
import Button from 'views/shared/Button'
import EmptyState from 'views/shared/EmptyState'
import Loader from 'views/shared/Loader'
import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import Spinner from 'views/shared/icons/Spinner'
import Directory from './Directory'

const MoveToFolderModal = ({
  onClose,
  myBusinessFile,
  initialSelectedFolder,
  myBusinessFile: { id: fileId, kind },
  onSuccess,
}) => {
  const [backFolder, setBackFolder] = useState(initialSelectedFolder)
  const [selectedFolder, setSelectedFolder] = useState(initialSelectedFolder)

  const { endpoint } = fetchMyFolderToMoveEndpoint(fileId)
  const selectedFolderId = selectedFolder?.id

  const isLoading = useSelector(state => loadingSelector(state, endpoint))
  const endpointMeta = useSelector(state => endpointMetaSelector(state, endpoint))
  const foldersToMove = useSelector(foldersToMoveSelector, fileId, selectedFolderId)
  const { path: metaPath } = useSelector(filesSharedWithMetaSelector)
  const isOnFirstLevel = length(metaPath) === 1

  const fetchFoldersToMove = useDispatchAction(fetchFoldersToMoveAction)
  const moveToFolder = useDispatchAction(moveToFolderAction)
  const resetFolderToMoveIds = useDispatchAction(resetFolderToMoveIdsAction)
  const showModal = useDispatchAction(showModalAction)
  const clearMeta = useDispatchAction(dataMetaClear, endpoint)

  const isPermissionsNotUpdated =
    !isPresent(selectedFolder?.businessStoragePermissions) &&
    !isPresent(myBusinessFile.businessStoragePermissions) &&
    !isPresent(myBusinessFile.ancestorsPermissions)

  const canNavigate = useCallback(
    folder => !!folder?.childrenFoldersCount && endpointMeta?.hasNestedFolders,
    [endpointMeta],
  )

  const handleSelect = (folder, isForce = false) => {
    setSelectedFolder(folder)

    if (canNavigate(folder) || isForce) {
      fetchFoldersToMove(fileId, folder?.id)
      setBackFolder(folder)
    }
  }

  const handleMove = () => {
    if (isPermissionsNotUpdated) {
      moveToFolder(fileId, kind, selectedFolderId, onSuccess)
    } else {
      showModal({
        modalType: 'SHARED_FILES_MOVE_TO_FOLDER_WARNING_MODAL',
        modalProps: { myBusinessFile, selectedFolder, onSuccess, backFolder },
      })
    }
  }

  const loadMoreFolders = ({ page }) => {
    fetchFoldersToMove(fileId, selectedFolderId, page)
  }

  useMount(() => {
    fetchFoldersToMove(fileId, selectedFolderId)
  })

  useUnmount(() => {
    resetFolderToMoveIds()
    clearMeta()
  })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container min-h-528">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="myBusinessFiles.moveItemTo" values={{ kind }} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body p-0 min-h-100 relative">
          <Breadcrumbs
            className="pt-8 pb-8 pl-24 pr-24 bg-blue-100"
            breadcrumbClassName="pb-0"
            breadcrumbLinkClassName="d-flex align-items-center lh-1 h-24"
            breadcrumbTextClassName="d-flex align-items-center lh-1 h-24"
            isHasSelectedFolder={!!selectedFolder}
            initialSelectedFolder={initialSelectedFolder}
            pathSelector={myFolderToMovePathSelector}
            firstCrumbLabel={{ id: 'myBusinessFiles.sharedFiles' }}
            onSelect={handleSelect}
            fileId={fileId}
          />
          {isLoading !== false && isEmpty(foldersToMove) && <Spinner isAbsolute />}
          {isLoading === false && isEmpty(foldersToMove) && (
            <EmptyState
              imgProps={{
                alt: 'empty',
                src: emptySearchImg,
                height: 136,
                width: 136,
              }}
              wrapperClassName="d-flex justify-content-center mt-84 mb-84"
              textWrapperClassName="mb-0"
              extraWrapperClassName="max-w-340 text-center"
              titleText={{ id: 'myBusinessFiles.noAvailableFolders' }}
            />
          )}
          {foldersToMove.map(folder => (
            <Directory
              key={folder.id}
              name={folder.name}
              isSelected={selectedFolderId === folder.id}
              hasChildren={canNavigate(folder)}
              onClick={() => handleSelect(folder)}
            />
          ))}
          {isPresent(foldersToMove) && (
            <Loader
              endpoint={endpoint}
              isLoading={isLoading}
              loadMoreAction={loadMoreFolders}
              spinnerProps={{
                isAbsolute: true,
              }}
              loaderProps={{
                bottomOffset: '-100px',
              }}
            />
          )}
        </div>
        <div className="main-modal__footer">
          <div className="d-flex justify-content-end">
            <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
            <Button
              text={{ id: 'shared.move' }}
              onClick={handleMove}
              disabled={isOnFirstLevel && pathEq(selectedFolderId, [0, 0], metaPath)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

MoveToFolderModal.defaultProps = {
  initialSelectedFolder: null,
  onSuccess: noop,
}

MoveToFolderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  myBusinessFile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    businessStoragePermissions: PropTypes.arrayOf(PropTypes.shape()),
    ancestorsPermissions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  initialSelectedFolder: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
}

export default MoveToFolderModal
