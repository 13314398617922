import { createLogic } from 'redux-logic'

import { setCurrentPage } from 'state/tableData/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { fetchClientRelationshipTypes, setFilterParams } from '../actions'
import { FILTER_CLIENT_RELATIONSHIP_TYPES } from '../types'

const filterClientRelationshipTypesOperation = createLogic({
  type: FILTER_CLIENT_RELATIONSHIP_TYPES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(TABLE_ENTITIES.clientRelationshipTypes, 1))
    dispatch(fetchClientRelationshipTypes())
    done()
  },
})

export default filterClientRelationshipTypesOperation
