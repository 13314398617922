import { combineReducers } from '@reduxjs/toolkit'

import { ALL_FILTER_OPTIONS } from 'lib/constants/forms'
import {
  SET_FORM_IDS,
  RESET_FORMS,
  SET_FORMS_BLANK_STATE,
  SET_FILTER_PARAMS,
  RESET_FILTERS,
  RESET_ALL_FILTERS,
} from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case SET_FORM_IDS:
      return action.ids
    case RESET_FORMS:
      return []
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_FORMS_BLANK_STATE:
      return action.isBlankState
    case RESET_FORMS:
      return true
    default:
      return state
  }
}

export const filtersInitialState = {
  status: ALL_FILTER_OPTIONS.allStatuses,
  respondent: ALL_FILTER_OPTIONS.allRespondents,
  name: '',
  formCategoryIds: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return { ...filtersInitialState, name: state.name }
    case RESET_ALL_FILTERS:
      return filtersInitialState
    default:
      return state
  }
}

const formsReducer = combineReducers({
  ids,
  isBlankState,
  filters,
})

export default formsReducer
