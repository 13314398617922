const recurringAppointmentFeature = {
  'recurringAppointmentFeature.advancedFeature': 'Advanced feature',
  'recurringAppointmentFeature.title': 'Recurring _(appointments)',
  'recurringAppointmentFeature.subtitle': '<span>in</span> Scheduling',
  'recurringAppointmentFeature.description': 'Set a recurring schedule for your _(clients)’ _(appointments).',
  'recurringAppointmentFeature.overview': 'Recurring _(appointments) overview',
  'recurringAppointmentFeature.schedule': 'Schedule regular _(appointments) with _(clients) from your calendar:',
  'recurringAppointmentFeature.scheduleRecurring':
    'Set up recurring _(appointments) to meet with your _(clients) on a regular basis.',
  'recurringAppointmentFeature.scheduleFrequency':
    'Choose the frequency, duration, and number of _(appointments) in just a few clicks.',
  'recurringAppointmentFeature.control': 'Give _(clients) control over their schedule:',
  'recurringAppointmentFeature.controlAccept':
    'Let your _(clients) accept invitations and pay for each _(appointment) separately.',
  'recurringAppointmentFeature.controlInformed':
    'Keep your _(clients) informed about any changes to recurring _(appointments) with automated email notifications.',
  'recurringAppointmentFeature.controlReschedule':
    'Allow _(clients) to reschedule individual _(appointments) within a recurring series based on your availability.',
  'recurringAppointmentFeature.stay': 'Stay up to date:',
  'recurringAppointmentFeature.stayReceive': 'Receive a notification when your _(appointment) invitation is confirmed.',
  'recurringAppointmentFeature.stayBoth':
    'Both you and your _(clients) will get automated reminders when an _(appointment) is coming up so that everyone gets to the meeting!',
  'recurringAppointmentFeature.toLearnMores':
    'To learn more, read our help center article on <link>scheduling recurring _(appointments)</link>.',
  'recurringAppointmentFeature.relatedFeatures': 'Related features',
  'recurringAppointmentFeature.disableRecurringBookingWarning':
    'You will not be able to create recurring _(appointments) anymore.',
  'recurringAppointmentFeature.disableRecurringBookingDescription':
    'This won’t affect any of the _(appointments) booked previously.',
  'recurringAppointmentFeature.disableRecurringBooking': 'Disable recurring _(appointments) feature?',
}

export default recurringAppointmentFeature
