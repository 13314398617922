import { createLogic } from 'redux-logic'

import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import redirect from 'utils/redirect'
import { buildUpdateClientProfileFieldsParams } from 'utils/clientFields'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyClientsRoute, companyClientRoute } from 'lib/routes'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import { CREATE_CLIENT } from 'state/concepts/client/types'
import { incrementTotalCount } from 'state/concepts/clients/actions'
import { setActiveTab } from 'state/concepts/companyClientProfile/actions'
import { createClientEndpoint } from '../endpoints'

const createClientOperation = createLogic({
  type: CREATE_CLIENT,
  latest: true,

  async process({ action: { clientId, contactId, form, values }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = createClientEndpoint
    const workspace = currentWorkspaceCodeSelector(getState())
    const isEnableCRM = isEnableWorkspaceFeatureSelector(getState(), FEATURE_KEYS.crm)
    const { firstName, lastName, phoneNumber, email, userProfileIds, profileFields, sendInvitation } = values
    const clientProfileFields = isEnableCRM
      ? { client_profile_fields_attributes: buildUpdateClientProfileFieldsParams(profileFields) }
      : {}

    const params = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
      user_profile_ids: userProfileIds,
      send_invitation: sendInvitation,
      ...clientProfileFields,
    }

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(incrementTotalCount())
      if (clientId && contactId) {
        dispatch(setActiveTab(CLIENT_PROFILE_TABS.contacts))
        redirect({ href: companyClientRoute(clientId), workspace })
      } else {
        redirect({ href: companyClientsRoute, workspace })
      }
      dispatch(showNotification({ messageObject: { id: 'notifications.newClientWasCreated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint, form })
    }

    done()
  },
})

export default createClientOperation
