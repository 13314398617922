import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const DocumentSuccess = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path
      fill="#b2bac7"
      d="M170.667 42.667c-47.061 0-85.333 38.272-85.333 85.333v682.667c0 47.061 38.272 85.333 85.333 85.333h285.167c-12.629-26.539-21.44-55.168-25.749-85.333h-259.418v-682.667h298.667v213.333h213.333v131.332c13.952-2.005 28.16-3.418 42.667-3.418s28.715 1.412 42.667 3.418v-173.999l-256-256h-341.333z"
    />
    <path
      fill="#22c9a4"
      d="M725.334 554.667c-117.803 0-213.333 95.531-213.333 213.333s95.531 213.333 213.333 213.333c117.803 0 213.333-95.531 213.333-213.333s-95.531-213.333-213.333-213.333zM812.834 667.751l59.665 59.75-168.499 168.499-125.833-125.833 59.665-59.75 66.167 66.082 108.834-108.749z"
    />
  </svg>
)

DocumentSuccess.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

DocumentSuccess.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DocumentSuccess
