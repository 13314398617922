import normalize from 'json-api-normalizer'
import { omit, pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import { companyBusinessFilesShareRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { ALERT_KINDS } from 'lib/constants'
import { PAGINATION_ITEMS_PER_PAGE, TABLE_ENTITIES } from 'lib/constants/tableData'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { tableOrderDirectionsSelector } from 'state/tableData/selectors'
import generateSortString from 'utils/generateSortString'
import isBlankState from 'utils/isBlankState'
import { addFileSharedWithMeIds, setBlankState, setFileSharedWithMeIds } from '../actions'
import { fetchFilesSharedWithMeEndpoint } from '../endpoints'
import { currentFolderIdSelector, filtersSelector } from '../selectors'
import { FETCH_FILES_SHARED_WITH_ME } from '../types'

const fetchFilesSharedWithMe = createLogic({
  type: FETCH_FILES_SHARED_WITH_ME,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const state = getState()
    const {
      pageParams: { page = 1, size },
      options: { append = true, preview = false },
    } = action

    const { name, employees, types, shared } = filtersSelector(state)
    const workspace = currentWorkspaceCodeSelector(state)
    const dates = shared?.[0]

    const currentFolderId = currentFolderIdSelector(state)
    const { url, endpoint } = fetchFilesSharedWithMeEndpoint
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.filesSharedWithMe))

    const filter = {
      name,
      shared_by_user_profile_ids: currentFolderId ? undefined : employees,
      shared_with_user_profile_ids: currentFolderId ? employees : undefined,
      types,
      modified_after: currentFolderId ? dates?.modifiedAfter?.toISO() : undefined,
      modified_before: currentFolderId ? dates?.modifiedBefore?.toISO() : undefined,
      shared_after: currentFolderId ? undefined : dates?.modifiedAfter?.toISO(),
      shared_before: currentFolderId ? undefined : dates?.modifiedBefore?.toISO(),
      parent_id: currentFolderId || undefined,
      preview,
    }

    const params = {
      page: { number: page, size: size || PAGINATION_ITEMS_PER_PAGE },
      include: [
        'user-profile',
        'business_storage_permissions.user-profile',
        'business_storage_permissions.shared_by',
        'parent',
        'parent.business_storage_permissions',
        'ancestors-permissions.user-profile',
      ],
      parent_id: currentFolderId || undefined,
      sort,
      filter,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      const blankStateParams = omit(['parent_id', 'preview'], filter)

      dispatch(setBlankState(isBlankState({ data: data.data, params: blankStateParams })))
      dispatch(dataApiSuccess({ response, endpoint }))

      const ids = pluck('id', data.data)
      append ? dispatch(addFileSharedWithMeIds(ids)) : dispatch(setFileSharedWithMeIds(ids))
    } catch (error) {
      if (isErrorStatusForbidden(error) && preview) {
        redirect({ href: companyBusinessFilesShareRoute, workspace })
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchFilesSharedWithMe
