import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Link = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M843.524 696.178l-73.671-73.671 73.671-73.671c101.718-101.717 101.718-266.638 0-368.357s-266.642-101.72-368.36 0l-73.672 73.672-73.672-73.672 73.672-73.672c142.408-142.407 373.294-142.407 515.702 0s142.408 373.293 0 515.7l-73.671 73.671zM696.177 843.525l-73.671 73.671c-142.403 142.403-373.293 142.403-515.7 0-142.407-142.408-142.407-373.299 0-515.702l73.672-73.672 73.672 73.672-73.672 73.672c-101.719 101.717-101.719 266.636 0 368.359 101.719 101.718 266.64 101.718 368.357 0l73.671-73.676 73.671 73.676zM659.341 290.986l73.676 73.672-368.36 368.355-73.672-73.671 368.356-368.356z" />
  </svg>
)

Link.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Link.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Link
