const assignServerProviderErrorToForm = (error, provision, groupedProvision) => {
  const { errors } = error.response.data
  const formattedErrors = []

  errors.forEach(el => {
    const { pointer } = el.source
    const providerErrorIndex = pointer.match(/\d+/)?.[0]
    if (!providerErrorIndex) {
      formattedErrors.push(el)
      return
    }
    const providerErrorId = provision[providerErrorIndex].user_profile_id
    const index = groupedProvision.findIndex(e => e.employees.includes(providerErrorId))
    const newPointer = pointer.replace(providerErrorIndex, index)

    if (!formattedErrors.find(e => e.source.pointer === newPointer)) {
      formattedErrors.push({ ...el, source: { pointer: newPointer } })
    }
  })

  return formattedErrors
}

export default assignServerProviderErrorToForm
