import { createLogic } from 'redux-logic'
import cookies from 'component-cookie'

import { EMPLOYEE_LOGOUT } from 'state/concepts/session/types'
import { employeePostLogout } from 'state/concepts/session/actions'
import { employeeLogoutEndpoint } from 'state/concepts/session/endpoints'

const employeeLogoutOperation = createLogic({
  type: EMPLOYEE_LOGOUT,
  latest: true,

  async process({ action: { isInactive }, workspacesHttpClient }, dispatch, done) {
    const { url } = employeeLogoutEndpoint

    try {
      const refreshToken = JSON.parse(cookies('tokens')).refresh

      await workspacesHttpClient.delete(url, { headers: { 'X-Refresh-Token': refreshToken } })
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      dispatch(employeePostLogout({ isInactive }))
      done()
    }
  },
})

export default employeeLogoutOperation
