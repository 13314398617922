const namespace = 'clients'

export const FETCH_CLIENT = `${namespace}/FETCH_CLIENT`
export const CREATE_CLIENT = `${namespace}/CREATE_CLIENT`
export const UPDATE_CLIENT = `${namespace}/UPDATE_CLIENT`
export const DELETE_CLIENT = `${namespace}/DELETE_CLIENT`
export const UPDATE_CLIENT_OWN_PROFILE = `${namespace}/UPDATE_CLIENT_OWN_PROFILE`
export const UPLOAD_CLIENT_OWN_AVATAR = `${namespace}/UPLOAD_CLIENT_OWN_AVATAR`
export const REMOVE_CLIENT_OWN_AVATAR = `${namespace}/REMOVE_CLIENT_OWN_AVATAR`
export const SET_CLIENT = `${namespace}/SET_CLIENT`
export const GET_CLIENT_OWN_PROFILE = `${namespace}/GET_CLIENT_OWN_PROFILE`
export const DELETE_CLIENT_OWN_ACCOUNT = `${namespace}/DELETE_CLIENT_OWN_ACCOUNT`
export const CONFIRM_CLIENT_OWN_EMAIL = `${namespace}/CONFIRM_CLIENT_OWN_EMAIL`
export const VALIDATE_CONFIRMATION_TOKEN = `${namespace}/VALIDATE_CONFIRMATION_TOKEN`
export const RESEND_EMAIL_CONFIRMATION = `${namespace}/RESEND_EMAIL_CONFIRMATION`
export const RESEND_EMAIL_CHANGE_CONFIRMATION = `${namespace}/RESEND_EMAIL_CHANGE_CONFIRMATION`
export const UPDATE_LOGOUT_INTERVAL = `${namespace}/UPDATE_LOGOUT_INTERVAL`
export const FETCH_NOTIFICATION_SETTINGS = `${namespace}/FETCH_NOTIFICATION_SETTINGS`
export const SET_NOTIFICATION_SETTING_ID = `${namespace}/SET_NOTIFICATION_SETTING_ID`
export const UPDATE_NOTIFICATION_SETTINGS = `${namespace}/UPDATE_NOTIFICATION_SETTINGS`
export const UPDATE_CLIENT_PROFILE_FIELDS = `${namespace}/UPDATE_CLIENT_PROFILE_FIELDS`
export const SHARE_FILES = `${namespace}/SHARE_FILES`
export const SEND_DOCUMENTS = `${namespace}/SEND_DOCUMENTS`
export const SET_IS_CLIENT_DELETED = `${namespace}/SET_IS_CLIENT_DELETED`
export const UPDATE_CLIENT_GENERAL_SETTINGS = `${namespace}/UPDATE_CLIENT_GENERAL_SETTINGS`
export const UPDATE_CLIENT_CONTACT_DETAILS = `${namespace}/UPDATE_CLIENT_CONTACT_DETAILS`
export const SEND_DELETE_CONFIRMATION_CODE = `${namespace}/SEND_DELETE_CONFIRMATION_CODE`
export const RESTORE_ACCOUNT = `${namespace}/RESTORE_ACCOUNT`
export const ASSIGN_CLIENT_EXPERTS = `${namespace}/ASSIGN_CLIENT_EXPERTS`
export const SEND_INVITATION_TO_CLIENT_PORTAL = `${namespace}/SEND_INVITATION_TO_CLIENT_PORTAL`
export const RESTORE_CONTACT_ACCOUNT = `${namespace}/RESTORE_CONTACT_ACCOUNT`
