import { pathOr, without } from 'ramda'
import { createLogic } from 'redux-logic'

import isBlankStateFunc from 'utils/isBlankState'
import { fetchTrashedFiles, removeTrashedFileIds } from '../actions'
import { RECEIVE_RESTORED_FILES } from '../types'
import { filtersSelector, trashedFileIdsSelector } from '../selectors'

const receiveRestoredFilesOperation = createLogic({
  type: RECEIVE_RESTORED_FILES,
  latest: true,

  async process({ getState, action: { data } }, dispatch, done) {
    const state = getState()
    const restoredFileIds = pathOr([], ['meta', 'restored_file_ids'], data).map(String)
    const ids = trashedFileIdsSelector(state)
    const filterParams = filtersSelector(state)

    dispatch(removeTrashedFileIds(restoredFileIds))
    const isBlankState = isBlankStateFunc({ params: filterParams, data: without(restoredFileIds, ids) })
    isBlankState && dispatch(fetchTrashedFiles(undefined, { append: false }))

    done()
  },
})

export default receiveRestoredFilesOperation
