import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import CompanySection from '../CompanySection'
import BillSection from '../BillSection'
import TableSection from '../TableSection'
import TotalSection from '../TotalSection'

const InvoiceComponent = ({
  amount,
  transactionNumber,
  dueDate,
  issueDate,
  receiptTemplate,
  clientProfile,
  transactionItems,
  paymentInstructions,
}) => (
  <>
    <CompanySection receiptTemplate={receiptTemplate}>
      <p className="preview-invoice__title">
        <FormattedMessage id="payments.review.invoiceNumber" values={{ transactionNumber }} />
      </p>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="payment-due-date-title">
          <FormattedMessage id="payments.review.dueDate" />
        </span>
        <span data-cy="payment-due-date">{dueDate}</span>
      </div>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="payment-issue-date-title">
          <FormattedMessage id="payments.review.issueDate" />
        </span>
        <span data-cy="payment-issue-date">{issueDate}</span>
      </div>
    </CompanySection>
    <div className="divider mb-24 mt-16" />
    <BillSection clientProfile={clientProfile} />
    <TableSection transactionItems={transactionItems} />
    <div className="preview-invoice__payment-total-wrap">
      <TotalSection title={{ id: 'payments.review.total.totalAmount' }} amount={amount} />
    </div>
    <div className="divider mt-24 mb-24" />
    {isPresent(paymentInstructions) && (
      <div className="main-card__container">
        <p className="preview-invoice__subtitle">
          <FormattedMessage id="payments.review.paymentDetails" />
        </p>
        <p className="mb-0">{paymentInstructions}</p>
      </div>
    )}
  </>
)

InvoiceComponent.defaultProps = {
  paymentInstructions: null,
}

InvoiceComponent.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
  receiptTemplate: PropTypes.shape().isRequired,
  transactionItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amount: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  issueDate: PropTypes.string.isRequired,
  transactionNumber: PropTypes.string.isRequired,
  paymentInstructions: PropTypes.string,
}

export default InvoiceComponent
