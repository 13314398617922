import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { removeMessageSuccess } from 'state/concepts/chat/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { REMOVE_MESSAGE } from 'state/concepts/chat/types'
import { removeChatMessageEndpoint } from '../endpoints'

const removeMessageOperation = createLogic({
  type: REMOVE_MESSAGE,
  latest: true,

  async process({ httpClient, action: { messageId } }, dispatch, done) {
    const { url, endpoint } = removeChatMessageEndpoint(messageId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(removeMessageSuccess(messageId))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeMessageOperation
