import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { uploadClientAttachments } from 'state/concepts/companyClientAttachments/actions'
import { PRESIGN_CLIENT_ATTACHMENTS } from 'state/concepts/companyClientAttachments/types'
import { clientAttachmentsPresignEndpoint } from 'state/concepts/companyClientAttachments/endpoints'
import { MAX_FILE_SIZE } from 'lib/constants/businessFiles'
import { showNotification } from 'state/notifications/actions'
import { getFileUploadInfo } from 'utils/file'

const presignClientAttachmentsOperation = createLogic({
  type: PRESIGN_CLIENT_ATTACHMENTS,
  latest: false,

  async process({ httpClient, action }, dispatch, done) {
    const { clientId, files } = action
    const { endpoint, url } = clientAttachmentsPresignEndpoint(clientId)

    dispatch(dataApiRequest({ endpoint }))

    const allowedBySize = files.filter(file => file.size <= MAX_FILE_SIZE)
    const hasLargeFiles = allowedBySize.length < files.length

    if (hasLargeFiles) {
      dispatch(showNotification({ messageObject: { id: 'myBusinessFiles.uploadSizeExceeded' }, kind: 'error' }))
    }

    try {
      const {
        data: { meta },
      } = await httpClient.post(url, {
        files: allowedBySize.map(file => getFileUploadInfo(file)),
      })

      const filesMeta = meta.files

      filesMeta.forEach((fileMeta, index) => {
        dispatch(uploadClientAttachments(allowedBySize[index], fileMeta, clientId))
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default presignClientAttachmentsOperation
