import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import CheckboxField from 'views/shared/CheckboxField'
import DropdownUserField from 'views/shared/DropdownUserField'
import Modal from 'views/shared/Modal'
import PasswordInputField from 'views/shared/PasswordInputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const TransferOwnershipModalComponent = ({
  status,
  isSubmitDisabled,
  onClose,
  companyName,
  currentEmployee,
  handleSubmit,
  values: { keepAdministrator },
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="workspaceSettings.transferOwnership" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <div className="mb-16">
          <FormattedMessage id="workspaceSettings.transferOwnerShip.transferToCompany" values={{ companyName }} />
        </div>
        {status && status.base && <Alert className="auth__alert" message={status.base} />}
        <Field
          className="main-input__field--transfer-ownership"
          type="text"
          name="employeeId"
          component={DropdownUserField}
          id="employeeId"
          label={{ id: 'workspaceSettings.transferOwnerShip.employeeId' }}
          placeholder={{ id: 'placeholders.searchUser' }}
          icon="alert"
          selectOnBlur={false}
          clearable
          excludeEmployees={[currentEmployee.id]}
          statuses={['active']}
          withAvatar
        />
        <Field
          name="password"
          id="password"
          component={PasswordInputField}
          label={{ id: 'shared.password' }}
          icon="alert"
          visiblePassword
          autoComplete="new-password"
        />
        <Field
          className="mb-0"
          name="keepAdministrator"
          component={CheckboxField}
          id="keepAdministrator"
          label={{ id: 'workspaceSettings.transferOwnerShip.keepMeAsAdministrator' }}
        />
        <p className="mt-16">
          <FormattedMessage
            id={`workspaceSettings.transferOwnership.${
              keepAdministrator ? 'keepAdministratorChecked' : 'keepAdministratorUnchecked'
            }`}
          />
        </p>
      </div>
      <div className="main-modal__footer">
        <div>
          <Button
            extraClasses="main-modal__footer-action"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onClose}
          />
          <SubmitFormButton
            extraClasses="main-modal__footer-action"
            text={{ id: 'workspaceSettings.transferOwnership' }}
            onClick={handleSubmit}
            kind={isSubmitDisabled ? 'outline' : null}
          />
        </div>
      </div>
    </div>
  </Modal>
)

TransferOwnershipModalComponent.defaultProps = {
  status: undefined,
}

TransferOwnershipModalComponent.propTypes = {
  status: PropTypes.shape(),
  values: PropTypes.shape({
    keepAdministrator: PropTypes.bool,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  currentEmployee: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

export default TransferOwnershipModalComponent
