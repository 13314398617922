import { createSelector } from '@reduxjs/toolkit'
import { compose, filter, prop, propEq, propOr, values } from 'ramda'

import isPresent from 'utils/isPresent'
import sortRecentWorkspaces from 'utils/workspace/sortRecentWorkspaces'

const recentWorkspaces = prop('recentWorkspaces')

export const recentWorkspacesSelector = createSelector(
  recentWorkspaces,
  compose(filter(propEq('completed', 'onboardingStatus')), values),
)

export const recentWorkspaceSelector = createSelector([(_, workspaceId) => workspaceId, recentWorkspaces], propOr({}))

export const hasRecentWorkspacesSelector = createSelector(recentWorkspacesSelector, isPresent)

export const sortedRecentWorkspaceSelector = createSelector(recentWorkspacesSelector, sortRecentWorkspaces)
