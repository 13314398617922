import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { keys, without, equals, pick } from 'ramda'
import draftToHtml from 'draftjs-to-html'

import isPresent from 'utils/isPresent'

export const isDraftEditorFormDirty = (editorValueKey, values, initialValues) => {
  const valueKeysWithoutEditorKey = without([editorValueKey], keys(values))
  const isInitialValues = equals(
    pick(valueKeysWithoutEditorKey, values),
    pick(valueKeysWithoutEditorKey, initialValues),
  )

  const isInitialEditorValue = equals(
    convertToRaw(values[editorValueKey].getCurrentContent()),
    convertToRaw(initialValues[editorValueKey].getCurrentContent()),
  )

  return !isInitialEditorValue || !isInitialValues
}

export const editorInitialValue = (valueKey, entity = {}) => {
  const entityValue = entity[valueKey]

  return EditorState.set(
    isPresent(entityValue)
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(entityValue)))
      : EditorState.createEmpty(),
    { allowUndo: false },
  )
}

export const prepareEditorValue = value => JSON.stringify(convertToRaw(value.getCurrentContent()))

export const contentToHtml = content => {
  try {
    return draftToHtml(JSON.parse(content))
  } catch {
    return ''
  }
}

export const draftJsToMarkup = content => ({ __html: contentToHtml(content) })
