import { includes } from 'ramda'
import { createLogic } from 'redux-logic'

import { Views } from 'lib/constants/bookings'
import requestErrorHandler from 'lib/requestErrorHandler'
import { addCalendarEvent, fetchCalendarEvents } from 'state/concepts/bookings/actions'
import { bookingsViewSelector } from 'state/concepts/bookings/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideSidebar } from 'state/sidebar/actions'
import updateRescheduleRecurringFormToParams from 'utils/bookings/updateRescheduleRecurringFormToParams'
import { getBooking } from '../actions'
import { rescheduleBookingEndpoint, rescheduleRecurringBookingsEndpoint } from '../endpoints'
import { RESCHEDULE_RECURRING_BOOKINGS } from '../types'

const rescheduleRecurringBookingsOperation = createLogic({
  type: RESCHEDULE_RECURRING_BOOKINGS,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const { uniqCode, isSingle } = values
    const { timezoneOffset } = currentEmployeeSelector(getState())
    const bookingsView = bookingsViewSelector(getState())
    const { url, endpoint } = isSingle
      ? rescheduleBookingEndpoint(uniqCode)
      : rescheduleRecurringBookingsEndpoint(uniqCode)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, updateRescheduleRecurringFormToParams(values, timezoneOffset))

      dispatch(dataApiSuccess({ endpoint }))
      if (includes(bookingsView, [Views.AGENDA, Views.WEEK])) {
        dispatch(fetchCalendarEvents())
      } else {
        dispatch(getBooking(uniqCode))
        dispatch(addCalendarEvent(uniqCode))
      }
      dispatch(hideSidebar())
      dispatch(showNotification({ messageObject: { id: 'notifications.appointmentWasRescheduled' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form, endpoint })
      form.setSubmitting(false)
    }
    done()
  },
})

export default rescheduleRecurringBookingsOperation
