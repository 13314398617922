import { useSelector } from 'react-redux'
import { filter, head, path } from 'ramda'
import { useIntl } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import isPresent from 'utils/isPresent'
import { showModal as showModalAction } from 'state/modal/actions'
import {
  deleteTrashedFiles as deleteTrashedFilesAction,
  emptyTrash as emptyTrashAction,
  restoreTrashedFiles as restoreTrashedFilesAction,
} from 'state/concepts/trashedFiles/actions'
import { trashedFilesByIdsSelector } from 'state/concepts/trashedFiles/selectors'
import FileBadge from 'views/company/TrashedFiles/FileBadge'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { boldText } from 'utils/locales'

const useExpertActions = ({ trashedFileIds, onNextSlide }) => {
  const { formatMessage } = useIntl()
  const currentEmployeeId = useSelector(currentEmployeeIdSelector)
  const trashedFiles = useSelector(state => trashedFilesByIdsSelector(state, trashedFileIds))

  const parentTrashedFiles = filter(({ parent }) => isPresent(parent?.trashedAt), trashedFiles)
  const isMultipleParentTrashedFiles = parentTrashedFiles.length > 1
  const isMultipleTrashedFiles = trashedFiles.length > 1
  const restoreTrashedFiles = useDispatchAction(restoreTrashedFilesAction, trashedFileIds, onNextSlide)
  const firstParentTrashedFile = head(parentTrashedFiles)
  const showModal = useDispatchAction(showModalAction)
  const emptyTrash = useDispatchAction(emptyTrashAction)
  const deleteTrashedFiles = useDispatchAction(deleteTrashedFilesAction, trashedFileIds, onNextSlide)
  const isOwnerOfFile = firstParentTrashedFile?.userProfile?.id === currentEmployeeId
  const directionName = formatMessage({ id: `employeeSidebar.${isOwnerOfFile ? 'myFiles' : 'sharedWithMeFiles'}` })

  const showTrashedFilesModal = () =>
    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: {
          id: `trashedFiles.actions.restore.modal.${isMultipleParentTrashedFiles ? 'multiple' : 'single'}.title`,
        },
        bodyText: [
          {
            id: `trashedFiles.actions.restore.modal.${isMultipleParentTrashedFiles ? 'multiple' : 'single'}.description`,
            values: {
              name: <FileBadge file={firstParentTrashedFile} />,
              directionName,
            },
          },
        ],
        submitText: { id: 'trashedFiles.actions.restore' },
        dismissText: { id: 'shared.cancel' },
        onSubmit: restoreTrashedFiles,
      },
    })

  const onRestoreTrashedFiles = isPresent(parentTrashedFiles) ? showTrashedFilesModal : restoreTrashedFiles

  const onEmptyTrash = () => {
    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: { id: 'trashedFiles.emptyTrash.modal.title' },
        bodyText: [{ id: 'trashedFiles.emptyTrash.modal.body' }],
        submitText: { id: 'trashedFiles.emptyTrash.modal.button' },
        dismissText: { id: 'shared.cancel' },
        kind: 'danger',
        onSubmit: emptyTrash,
      },
    })
  }

  const onDeleteTrashedFiles = () => {
    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: { id: 'trashedFiles.actions.delete.modal.title' },
        bodyText: [
          {
            id: `trashedFiles.actions.delete.modal.${isMultipleTrashedFiles ? 'multiple' : 'single'}.description`,
            values: {
              name: path([0, 'name'], trashedFiles),
              b: boldText,
              count: trashedFiles.length,
            },
          },
        ],
        submitText: { id: 'trashedFiles.actions.deleteForever' },
        dismissText: { id: 'shared.cancel' },
        kind: 'danger',
        onSubmit: deleteTrashedFiles,
      },
    })
  }

  return {
    onRestoreTrashedFiles,
    onEmptyTrash,
    onDeleteTrashedFiles,
  }
}

export default useExpertActions
