import fetchChangeSubscriptionDetails from './fetchChangeSubscriptionDetails'
import fetchSubscriptionPlans from './fetchSubscriptionPlans'
import updateStoragePlan from './updateStoragePlan'
import updateSubscriptions from './updateSubscriptions'
import switchBillingCycleOperation from './switchBillingCycle'
import cancelSwitchBillingCycleOperation from './cancelSwitchBillingCycle'
import cancelPlanDowngradeOperation from './cancelPlanDowngrade'
import downgradeToFreeOperation from './downgradeToFree'
import updateStoragePlanSubscription from './updateStoragePlanSubscription'

export default [
  fetchSubscriptionPlans,
  updateSubscriptions,
  fetchChangeSubscriptionDetails,
  updateStoragePlan,
  switchBillingCycleOperation,
  cancelSwitchBillingCycleOperation,
  cancelPlanDowngradeOperation,
  downgradeToFreeOperation,
  updateStoragePlanSubscription,
]
