import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchClients } from 'state/concepts/clients/actions'
import { showNotification } from 'state/notifications/actions'
import { DELETE_CLIENT } from 'state/concepts/client/types'
import { deleteClientEndpoint } from '../endpoints'

const deleteClientOperation = createLogic({
  type: DELETE_CLIENT,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { clientId } = action
    const { url } = deleteClientEndpoint(clientId)

    try {
      await httpClient.delete(url)
      dispatch(fetchClients())
      dispatch(showNotification({ messageObject: { id: 'notifications.clientProfileIsDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteClientOperation
