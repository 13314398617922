import endpoint from 'utils/endpoint'
import {
  businessStoragesMoveRoute,
  businessStoragesSharedFoldersMoveRoute,
  businessStoragesTrashRoute,
  myBusinessFilePermissionsRoute,
  sharedFilesRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const fetchFilesSharedWithMeEndpoint = endpoint(types.FETCH_FILES_SHARED_WITH_ME, 'GET', sharedFilesRoute)
export const moveToFolderEndpoint = fileId => endpoint(types.MOVE_TO_FOLDER, 'PUT', businessStoragesMoveRoute(fileId))

export const fetchMyFolderToMoveEndpoint = fileId =>
  endpoint(types.FETCH_FOLDERS_TO_MOVE, 'GET', businessStoragesSharedFoldersMoveRoute(fileId))

export const moveFilesSharedWithMeToTrashEndpoint = endpoint(
  types.MOVE_FILES_SHARED_WITH_ME_TO_TRASH,
  'POST',
  businessStoragesTrashRoute,
)
export const removeMyAccessEndpoint = sharedFileId =>
  endpoint(types.REMOVE_MY_ACCESS, 'DELETE', myBusinessFilePermissionsRoute(sharedFileId))
