import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Field } from 'formik'

import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import { RECURRING_BOOKING_RADIO_FIELD_OPTIONS } from 'lib/constants/bookings'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import Cross from 'views/shared/icons/Cross'
import RadioField from 'views/shared/RadioField'

const CancelFormModalComponent = ({
  onClose,
  handleSubmit,
  cancellationRules: { description },
  isSubmitDisabled,
  isFree,
  isRecurring,
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <Form>
      <div className="main-modal__container" data-cy="cancel-modal">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="cancel-modal-title">
            <FormattedMessage
              id={isRecurring ? 'cancelRecurringBookingModal.title' : 'cancelBookingModal.cancelAppointment'}
            />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          {isRecurring && (
            <div className="mb-12" data-cy="cancel-modal-options">
              <Field name="recurringOption" component={RadioField} options={RECURRING_BOOKING_RADIO_FIELD_OPTIONS} />
            </div>
          )}
          {!isFree && <p data-cy="cancel-modal-description">{description}</p>}
          <Field
            className="textarea-reason mb-0 full-width main-input--textarea-top-count"
            name="cancellationReason"
            component={TextAreaField}
            id="cancellationReason"
            label={{ id: 'cancelBookingModal.reason' }}
            icon="alert"
            data-cy="cancellation-reason"
            showCount
            maxLength={MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH}
          />
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="main-modal__footer-action mr-16"
              text={{ id: 'shared.leave' }}
              onClick={onClose}
              kind="flat"
            />
            <SubmitFormButton
              className="main-modal__footer-action main-btn--danger"
              text={{ id: 'cancelBookingModal.cancelAppointment.submit' }}
              onClick={handleSubmit}
              kind="danger"
              isDisabled={isSubmitDisabled}
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

CancelFormModalComponent.defaultProps = {
  cancellationRules: undefined,
}

CancelFormModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  cancellationRules: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }),
  isRecurring: PropTypes.bool.isRequired,
}

export default CancelFormModalComponent
