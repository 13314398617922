import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchEmployeeAccountNotificationSettings = () => ({
  type: types.FETCH_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
})

export const setEmployeeAccountNotificationSettings = notificationSettingsId => ({
  type: types.SET_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS_ID,
  notificationSettingsId,
})

export const updateEmployeeAccountNotificationSettings = makeFormSubmitAction(
  types.UPDATE_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
)
