const namespace = 'employees'

export const SET_EMPLOYEES = `${namespace}/SET_EMPLOYEES`
export const FETCH_EMPLOYEES = `${namespace}/FETCH_EMPLOYEES`
export const SET_EMPLOYEES_PAGE = `${namespace}/SET_EMPLOYEES_PAGE`
export const INVITE_EMPLOYEES = `${namespace}/INVITE_EMPLOYEES`
export const DEACTIVATE_EMPLOYEE = `${namespace}/DEACTIVATE_EMPLOYEE`
export const ACTIVATE_EMPLOYEE = `${namespace}/ACTIVATE_EMPLOYEE`
export const REMOVE_EMPLOYEE = `${namespace}/REMOVE_EMPLOYEE`
export const REMOVE_EMPLOYEE_INVITATION = `${namespace}/REMOVE_EMPLOYEE_INVITATION`
export const RESEND_EMPLOYEE_INVITATION = `${namespace}/RESEND_EMPLOYEE_INVITATION`
export const CHANGE_EMPLOYEE_ROLE = `${namespace}/CHANGE_EMPLOYEE_ROLE`
export const SET_EMPLOYEES_FILTER_PARAMS = `${namespace}/SET_EMPLOYEES_FILTER_PARAMS`
export const SET_EMPLOYEES_SORT_ORDER = `${namespace}/SET_EMPLOYEES_SORT_ORDER`
export const FILTER_EMPLOYEES = `${namespace}/FILTER_EMPLOYEES`
export const UPDATE_EMPLOYEE_PROVISION = `${namespace}/UPDATE_EMPLOYEE_PROVISION`
export const FETCH_EMPLOYEES_PROVISION = `${namespace}/FETCH_EMPLOYEES_PROVISION`
export const FETCH_EMPLOYEES_PROFILE = `${namespace}/FETCH_EMPLOYEES_PROFILE`
export const UPDATE_EMPLOYEE_PROFILE = `${namespace}/UPDATE_EMPLOYEE_PROFILE`
export const SET_EMPLOYEES_BLANK_STATE = `${namespace}/SET_EMPLOYEES_BLANK_STATE`
export const RESET_TWO_FACTOR_AUTH = `${namespace}/RESET_TWO_FACTOR_AUTH`
export const FETCH_EMPLOYEES_AVAILABILITIES = `${namespace}/FETCH_EMPLOYEES_AVAILABILITIES`
