import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import { ONLINE_LOCATION_ICONS } from 'lib/constants/locations'
import { FormattedMessage } from 'react-intl'

const OnlineLocationDetail = ({ videoCallProvider }) => (
  <div className="d-flex align-items-center gap-16">
    <Image src={ONLINE_LOCATION_ICONS[videoCallProvider]} width={20} height={20} alt="Video call provider logo" />
    <span className="pt-1">
      <FormattedMessage id="bookings.onlineAppointment" />
    </span>
  </div>
)

OnlineLocationDetail.propTypes = {
  videoCallProvider: PropTypes.string.isRequired,
}

export default OnlineLocationDetail
