import { TIME_UNITS_DAY, TIME_UNITS_HOUR, TIME_UNITS_MINUTE } from 'lib/constants/timeUnits'

export const WAITLIST_STATUSES = {
  appointmentRequested: 'appointment_requested',
  waitingForAppointment: 'waiting_for_appointment',
  expired: 'expired',
  slotsAvailable: 'slots_available',
  suspended: 'suspended',
}

export const WAITLIST_SETTING_SCHEDULE_TYPES = {
  automatically: 'automatically',
  manually: 'manually',
  notificationOnly: 'notification_only',
}

export const TIME_PREFERENCES_TYPES = {
  anyTime: 'any_time',
  before: 'before',
  after: 'after',
  between: 'between',
}

export const CLIENT_TYPES = {
  new: 'new',
  existing: 'existing',
}

export const CLIENT_TYPES_OPTIONS = [
  { key: CLIENT_TYPES.existing, value: CLIENT_TYPES.existing, label: { id: 'createWaitlistRequests.existingClients' } },
  { key: CLIENT_TYPES.new, value: CLIENT_TYPES.new, label: { id: 'createWaitlistRequests.newClient' } },
]

export const TIME_TYPE_OPTIONS = [
  {
    key: TIME_PREFERENCES_TYPES.anyTime,
    value: TIME_PREFERENCES_TYPES.anyTime,
    label: { id: 'createWaitlistRequests.timeOption.anyTime' },
  },
  {
    key: TIME_PREFERENCES_TYPES.before,
    value: TIME_PREFERENCES_TYPES.before,
    label: { id: 'createWaitlistRequests.timeOption.before' },
  },
  {
    key: TIME_PREFERENCES_TYPES.after,
    value: TIME_PREFERENCES_TYPES.after,
    label: { id: 'createWaitlistRequests.timeOption.after' },
  },
  {
    key: TIME_PREFERENCES_TYPES.between,
    value: TIME_PREFERENCES_TYPES.between,
    label: { id: 'createWaitlistRequests.timeOption.between' },
  },
]

export const MAX_AVAILABILITY_PREFERENCES = 10

export const SCHEDULE_TYPES = {
  automatically: 'automatically',
  manually: 'manually',
  notificationOnly: 'notification_only',
}

export const TIME_TO_RESPOND_OPTIONS = [
  {
    key: TIME_UNITS_MINUTE,
    value: TIME_UNITS_MINUTE,
    label: { id: `timeUnits.${TIME_UNITS_MINUTE}` },
  },
  {
    key: TIME_UNITS_HOUR,
    value: TIME_UNITS_HOUR,
    label: { id: `timeUnits.${TIME_UNITS_HOUR}` },
  },
  {
    key: TIME_UNITS_DAY,
    value: TIME_UNITS_DAY,
    label: { id: `timeUnits.${TIME_UNITS_DAY}` },
  },
]

export const WAITLIST_REQUEST_ACTIONS = {
  removeWaitlistRequest: 'remove_waitlist_request',
}
