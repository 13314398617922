import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { removeAppleCalendarConnectionIds } from 'state/concepts/calendarIntegrations/actions'
import { dataApiSuccess } from 'state/data/actions'
import { RECEIVE_APPLE_CALENDAR } from '../types'

const receiveAppleCalendarOperation = createLogic({
  type: RECEIVE_APPLE_CALENDAR,
  latest: true,

  process({ action: { data } }, dispatch, done) {
    const {
      data: {
        attributes: { destroyed },
        id,
      },
    } = data

    if (destroyed) {
      dispatch(removeAppleCalendarConnectionIds(id))
    } else {
      dispatch(dataApiSuccess({ response: normalize(data) }))
    }
    done()
  },
})

export default receiveAppleCalendarOperation
