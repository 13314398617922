import PropTypes from 'prop-types'
import { FormattedNumber, FormattedMessage } from 'react-intl'

import { FREE_PRICE } from 'lib/constants/bookings'

const FormattedPrice = ({ price }) =>
  price === FREE_PRICE ? (
    <FormattedMessage id="shared.free" />
  ) : (
    <FormattedNumber
      value={price}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="USD"
    />
  )

FormattedPrice.propTypes = {
  price: PropTypes.string.isRequired,
}

export default FormattedPrice
