export const MIME_TYPES = {
  image: [
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/svg+xml',
    'image/tiff',
    'image/vnd.microsoft.icon',
    'image/vnd.wap.wbmp',
    'image/webp',
    'image/png',
  ],
  video: [
    'video/mpeg',
    'video/mp4',
    'video/avi',
    'video/mov',
    'video/webm',
    'video/mkv',
    'video/ogg',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/3gpp',
    'video/3gpp2',
    'video/x-msvideo',
    'video/x-ms-asf',
    'application/smil',
  ],
  audio: [
    'audio/webm',
    'audio/ogg',
    'audio/flac',
    'audio/x-flac',
    'audio/aac',
    'audio/wav',
    'audio/x-wav',
    'audio/wma',
    'audio/mp4',
    'audio/mpeg',
    'audio/x-aiff',
    'audio/mpeg-4',
    'audio/mpeg4-generic',
    'audio/m4a',
    'audio/x-m4a',
    'audio/x-ms-wma',
    'audio/x-ms-wax',
    'audio/vnd.wave',
    'audio/vnd.rn-realaudio',
  ],
  pdf: ['application/pdf'],
}

export const PLAYABLE_VIDEO_MIME_TYPES = ['video/mp4', 'video/ogg', 'video/webm']
export const DURATION_PARSABLE_MIME_TYPES = {
  audio: MIME_TYPES.audio,
  video: [
    'video/mp4',
    'video/mov',
    'video/mkv',
    'video/ogg',
    'video/quicktime',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
  ],
}

export const IMAGE_SQUARE_ASPECT_RATIO = 1
export const MAX_FILE_CHUNK_SIZE = 50000000
