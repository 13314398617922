import { createSelector } from '@reduxjs/toolkit'
import { find, isNil, path, propEq, reject } from 'ramda'
import build from 'redux-object'

import { dataSelector } from 'state/data/selectors'

export const promocodeIdsSelector = path(['promocodes', 'promocodeIds'])

export const appliedTrialCodeSelector = path(['promocodes', 'trialCode'])

export const appliedDiscountCodeSelector = path(['promocodes', 'discountCode'])

export const appliedCodesSelector = createSelector(
  [appliedTrialCodeSelector, appliedDiscountCodeSelector],
  (trial, discount) => reject(isNil, [trial, discount]),
)

export const promocodesSelector = createSelector([promocodeIdsSelector, dataSelector], (ids, data) => {
  const result = build(data, 'promocode', ids) || []

  return reject(isNil, result)
})

export const appliedTrialPromocodeSelector = createSelector(
  [appliedTrialCodeSelector, promocodesSelector],
  (code, promocodes) => find(propEq(code, 'code'), promocodes),
)

export const appliedDiscountPromocodeSelector = createSelector(
  [appliedDiscountCodeSelector, promocodesSelector],
  (code, promocodes) => find(propEq(code, 'code'), promocodes),
)
