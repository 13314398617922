const notificationPanel = {
  'notificationPanel.title': 'Notifications',
  'notificationPanel.markAsRead': 'Mark as read',
  'notificationPanel.markAsUnread': 'Mark as unread',
  'notificationPanel.empty': 'There are no notifications yet.',
  'notificationPanel.notificationRemoved': 'Notification removed',
  'notificationPanel.undo': 'Undo',
  'notificationPanel.dateTime': '{date} at {time}',
  'notificationPanel.admin.profileUpdatedByAdmin': '{userName} updated {updatedUserName} profile',
  'notificationPanel.admin.profileUpdatedByAdminV2': "{updatedUserName}'s profile was updated.",
  'notificationPanel.admin.serviceAssigned': 'You were assigned to "{serviceName}" _(service).',
  'notificationPanel.admin.serviceUnassigned': 'You were unassigned from "{serviceName}" _(service).',
  'notificationPanel.admin.serviceCreated': 'New "{serviceName}" _(service) was created.',
  'notificationPanel.admin.updateAssignedService': '"{serviceName}" _(service) was updated.',
  'notificationPanel.admin.serviceActivated': '{userName} activated "{serviceName}" _(service)',
  'notificationPanel.admin.serviceDeactivated': '{userName} deactivated "{serviceName}" _(service).',
  'notificationPanel.admin.serviceDeleted': '{userName} deleted "{serviceName}" _(service)',
  'notificationPanel.admin.serviceAssignedToTeamMember':
    '{userName} assigned {updatedUserName} to "{serviceName}" _(service)',
  'notificationPanel.admin.serviceUnassignedFromTeemMember':
    '{userName} removed {updatedUserName} from "{serviceName}" _(service)',
  'notificationPanel.admin.myServiceActivated': '"{serviceName}" _(service) is now active.',
  'notificationPanel.admin.myServiceDeactivated': '"{serviceName}" _(service) was deactivated.',
  'notificationPanel.admin.myServiceDeleted': '"{serviceName}" _(service) was deleted.',
  'notificationPanel.admin.myProfileUpdated': '{userName} updated your profile',
  'notificationPanel.admin.serviceUpdated': '{userName} updated "{serviceName}" _(service).',
  'notificationPanel.admin.overduePayment': "{clientName}'s payment {paymentNumber} is overdue from {overdueDate}",
  'notificationPanel.admin.upgradeStorageNow': 'Upgrade {hasAutoUpgrade, select, yes {storage} other {}} now.',
  'notificationPanel.admin.disableAutoUpgrade':
    '{hasAutoUpgrade, select, yes {Disable auto-upgrade.} other {Upgrade your storage}}',
  'notificationPanel.admin.capacityReached': `Your storage space is nearly full.
    {hasAutoUpgrade, select,
      yes {It will be auto-upgraded when you run out of space. {upgradeStorageLink}}
      no {To ensure your files remain safe and accessible, consider upgrading your storage today. {upgradeStorageLink}}
      other {}
    }`,
  'notificationPanel.admin.capacityWasAutoUpgraded': `{hasAutoUpgrade, select,
    yes {You were out of space and your storage was auto-upgraded.}
    no {You are out of space.}
    other {}}
    {hasAutoUpgrade, select, yes {{disableAutoUpgradeLink}} no {{upgradeStorageLink} now to keep your files safe.} other {}}`,
  'notificationPanel.appointments.appointmentForProviderCreated': `{appointmentForProviderCreatedType, select,
    pure {You have a new "{appointmentServiceName}" _(appointment) on {startTime}}
    withPendingForms {You have a new "{appointmentServiceName}" _(appointment) on {startTime}. Please complete the forms before it starts.}
    other {}
  }`,
  'notificationPanel.appointments.appointmentForAdminCreated':
    '{userName} has a new "{appointmentServiceName}" _(appointment) on {startTime}.',
  'notificationPanel.appointments.userCancelBooking': `{isImperativeAction, select,
    yes {{userName} cancelled {updatedUserName}'s "{appointmentServiceName}" _(appointment), scheduled for {startTime}.}
    no {{userName} cancelled their "{appointmentServiceName}" _(appointment), scheduled for {startTime}.}
    other {}
  }`,
  'notificationPanel.appointments.myAppointmentHasBeenCancelled': `{isClient, select,
    yes {{clientName} cancelled their "{appointmentServiceName}" _(appointment), scheduled for {startTime}.}
    no {{userName} cancelled your "{appointmentServiceName}" _(appointment) with {clientName}, scheduled for {startTime}.}
    other {}
  }`,
  'notificationPanel.appointments.myAppointmentHasBeenCancelledV2': `{userName} cancelled your "{appointmentServiceName}" _(appointment) with {clientName}, scheduled for {startTime}.`,
  'notificationPanel.appointments.myRecurringAppointmentHasBeenCancelled': `{isClient, select,
    yes {{clientName} cancelled {cancelledCount} "{appointmentServiceName}" appointments.}
    no {{userName} cancelled {cancelledCount} of your "{appointmentServiceName}" _(appointments) with {clientName}.}
    other {}
  }`,
  'notificationPanel.appointments.myRecurringAppointmentHasBeenCancelledV2': `{isClient, select,
    yes {{clientName} cancelled {cancelledCount} "{appointmentServiceName}" appointments.}
    no {{userName} cancelled {cancelledCount} of your "{appointmentServiceName}" _(appointments) with {clientName}.}
    other {}
  }`,
  'notificationPanel.appointments.myRecurringAppointmentsHasBeenDeclinedByClient':
    '{clientName} declined {declinedCount} "{appointmentServiceName}" {declinedCount, plural, one {_(appointment)} other {_(appointments)}}',
  'notificationPanel.appointments.myRecurringAppointmentsHasBeenDeclinedByClientV2':
    '{clientName} declined {declinedCount} "{appointmentServiceName}" {declinedCount, plural, one {_(appointment)} other {_(appointments)}}.',
  'notificationPanel.appointments.appointmentForAdminCancelled':
    '{clientName} cancelled their "{appointmentServiceName}" _(appointment) with {updatedUserName}, scheduled for {startTime}.',
  'notificationPanel.appointments.iWasAssignedToNewAppointment':
    'You have a new "{appointmentServiceName}" _(appointment) on {startTime}, scheduled by {userName}.',
  'notificationPanel.appointments.iWasAssignedToNewAppointmentV2':
    'You have a new "{appointmentServiceName}" _(appointment) on {startTime}, scheduled by {userName}.',
  'notificationPanel.appointments.clientCreateRescheduleForUser':
    '{clientName} has made a reschedule for "{appointmentServiceName}" _(service) from {startTime}',
  'notificationPanel.appointments.clientCreateRescheduleForUserV2': `{appointmentForProviderCreatedType, select,
    pure {{clientName} rescheduled their "{appointmentServiceName}" _(appointment) from {oldStartTime} to {startTime}.}
    withPendingForms {{clientName} rescheduled their "{appointmentServiceName}" _(appointment) from {oldStartTime} to {startTime}. Please complete the forms before it starts.}
    other {}
  }`,
  'notificationPanel.appointments.appointmentForAdminRescheduled':
    '_(Client) has made a reschedule for "{appointmentServiceName}" _(service) with {userName} from {startTime}',
  'notificationPanel.appointments.appointmentForAdminRescheduledV2':
    '_(Client) rescheduled their "{appointmentServiceName}" _(service) with {userName} {oldStartTime} to {startTime}.',
  'notificationPanel.appointments.clientSubmitsIntakeForm': '{clientFullName} submitted their Form {formName}.',
  'notificationPanel.appointments.recurringAvailabilitiesUpdated': "{userName}'s regular availability was updated.",
  'notificationPanel.appointments.dateAvailabilitiesUpdated': "{userName}'s day-specific availability was updated.",
  'notificationPanel.appointments.recurringAvailabilitiesByAdminUpdated':
    '{userName} updated regular availability of {updatedUserName}',
  'notificationPanel.appointments.recurringAvailabilitiesByAdminUpdatedV2':
    "{updatedUserName}'s regular availability was updated.",
  'notificationPanel.appointments.dateAvailabilitiesByAdminUpdated':
    '{userName} updated day-specific availability of {updatedUserName}',
  'notificationPanel.appointments.dateAvailabilitiesByAdminUpdatedV2':
    "{updatedUserName}'s day-specific availability was updated.",
  'notificationPanel.appointments.adminUpdatedMyRecurringAvailability': '{userName} updated your regular availability.',
  'notificationPanel.appointments.adminUpdatedMyDateAvailability': '{userName} updated your day-specific availability.',

  'notificationPanel.appointments.appointmentInADay': `{appointmentForProviderCreatedType, select,
    pure {Your "{appointmentServiceName}" _(appointment) with {clientName} is in 24 hours.}
    withPendingForms {Your "{appointmentServiceName}" _(appointment) with {clientName} is in 24 hours. Please complete the forms before it starts.}
    other {}
  }`,
  'notificationPanel.appointments.appointmentIn30Minutes': `{appointmentForProviderCreatedType, select,
    pure {Your "{appointmentServiceName}" _(appointment) with {clientName} starts in 30 minutes.}
    withPendingForms {Your "{appointmentServiceName}" _(appointment) with {clientName} starts in 30 minutes. Please complete the forms before it starts.}
    other {}
  }`,
  'notificationPanel.appointments.appointmentHasStarted':
    'Your "{appointmentServiceName}" _(appointment) with {clientName} has just started',

  'notificationPanel.appointments.myAppointmentHasBeenConfirmedByClient':
    '"{appointmentServiceName}" _(appointment) has been accepted by {clientName}',
  'notificationPanel.appointments.myAppointmentHasBeenConfirmedByClientV2':
    '{clientName} accepted "{appointmentServiceName}" _(appointment) on {startTime}.',

  'notificationPanel.appointments.myAppointmentHasBeenDeclinedByClient':
    '"{appointmentServiceName}" _(appointment) has been declined by {clientName}',
  'notificationPanel.appointments.myAppointmentHasBeenDeclinedByClientV2':
    '"{appointmentServiceName}" _(appointment) has been declined by {clientName}',

  'notificationPanel.appointments.myAppointmentHasBeenUpdated':
    '{userName} updated your "{appointmentServiceName}" _(appointment)',
  'notificationPanel.appointments.myAppointmentHasBeenUpdatedV2':
    '{userName} updated your "{appointmentServiceName}" _(appointment) with {clientName}.',

  'notificationPanel.appointments.myAppointmentHasBeenRescheduled':
    '{userName} has rescheduled your {appointmentServiceName} with {clientName}',
  'notificationPanel.appointments.myAppointmentHasBeenRescheduledV2':
    '{userName} rescheduled your "{appointmentServiceName}" with {clientName} from {oldStartTime} to {startTime}.',

  'notificationPanel.appointments.iWasAssignedToRecurringAppointments':
    'You have {assignedCount} new {appointmentServiceName} _(appointments), scheduled by {userName}.',
  'notificationPanel.appointments.iWasAssignedToRecurringAppointmentsV2':
    'You have {assignedCount} new {appointmentServiceName} _(appointments), scheduled by {userName}.',
  'notificationPanel.appointments.myRecurringAppointmentsHasBeenRescheduled':
    '{userName} updated {rescheduledCount} of your "{appointmentServiceName}" _(appointments)',

  'notificationPanel.appointments.myAppointmentHasBeenReviewed': 'You got a new review from {clientName}.',

  'notificationPanel.appointments.myFormIsDeclined': '{clientName} has declined to sign the {formName} form.',
  'notificationPanel.appointments.whenAppointmentStart':
    'Please complete the forms during your {appointmentServiceName} _(appointment).',
  'notificationPanel.appointments.reviewFormsAfterAppointment':
    'You have new forms to complete following your {appointmentServiceName} _(appointment).',
  'notificationPanel.appointments.unfilledFormsReminder':
    'You still have forms to complete regarding your _(services) for {clientName}.',
  'notificationPanel.appointments.receiveFormAfterClientSigned':
    'Please review and sign the {formName} form from {clientName}.',
  'notificationPanel.appointments.receiveAFormToFillOut': 'You have the {formName} form to complete.',

  'notificationPanel.chat.newDocumentReceived': `{isClient, select,
    yes {You have a new file in "{chatName}" chat from {clientName}}
    no {You have a new file in "{chatName}" chat from {userName}}
    other {}
  }`,
  'notificationPanel.chat.iHaveNewMessage': `{isClient, select,
    yes {You have a new message in "{chatName}" chat from {clientName}}
    no {You have a new message in "{chatName}" chat from {userName}}
    other {}
  }`,
  'notificationPanel.system.activeVideoRoom':
    'Your "{appointmentServiceName}" _(appointment) starts in 15 minutes. Join the video call anytime you\'re ready!',
  'notificationPanel.system.videoIsReady':
    'Your conference video for "{serviceName}" _(service) from {startTime} is ready',
  'notificationPanel.system.videoIsReadyV2':
    'Your video recording for "{appointmentServiceName}" _(appointment) with {clientName} is ready.',

  'notificationPanel.system.myGoogleCalendarHasGotDisconnected':
    'Your google calendar has been disconnected. {reconnect}',
  'notificationPanel.system.expireTrialTomorrow':
    '{ownerName}, your ExpertBox Premium Plus trial is ending today. Upgrade now to keep using all the features!',
  'notificationPanel.system.expireTrialInThreeDays': `Time's running out, {ownerName}! Only 3 days left of your ExpertBox Premium Plus trial. Upgrade now to keep full control of your client interactions and billing.`,
  'notificationPanel.system.expireTrialInSevenDays': `Your ExpertBox trial is ticking away, and you've got only one week left. Ready to take it to the next level? Upgrade today and keep the momentum going!`,
  'notificationPanel.system.myOutlookCalendarHasGotDisconnected':
    'Your outlook calendar has been disconnected. {reconnect}',
  'notificationPanel.system.myAppleCalendarHasGotDisconnected':
    'Your apple calendar has been disconnected. {reconnect}',
  'notificationPanel.system.iWasAssignedToClient': '{userName} assigned you to a new _(client). {viewClient}',
  'notificationPanel.system.viewClient': 'View _(client)',
  'notificationPanel.system.waitlistRequest': 'waitlist request',
  'notificationPanel.system.reconnect': 'Reconnect',
  'notificationPanel.system.subscriptionToFreeOnDateOwner': 'Your subscription plan will be changed to Free on {date}',
  'notificationPanel.system.subscriptionToFreeOnDateExpert':
    'Your subscription plan will be changed to Free and account deactivated on {date}',
  'notificationPanel.system.endingAnnualSubscription':
    'Your annual subscription will be changed to monthly on {endingAt}',
  'notificationPanel.system.realDeletingFilesInOneDay':
    '{sizeDeletedFiles} of your files will be deleted Tomorrow. Update your storage to restore them',
  'notificationPanel.system.realDeletingFilesInSevenDays':
    '{sizeDeletedFiles} of your files will be deleted on {removalDate}. Update your storage to restore them',
  'notificationPanel.system.defaultVideoCallProviderChanged':
    '{videoCallProvider} chosen as default for your workspace. Please, check your connection to this video call provider, to provide _(services) online.',
  'notificationPanel.system.defaultVideoCallProviderChangedToTeams':
    'Microsoft Teams chosen as default for your workspace. Please, check your Microsoft Teams account to provide _(services) online. You need to be a member of your work/school Team.',
  'notificationPanel.system.videoCallProvider.expertbox': 'ExpertBox video call',
  'notificationPanel.system.videoCallProvider.google_meet': 'Google Meet',
  'notificationPanel.system.videoCallProvider.microsoft_teams': 'Microsoft Teams',
  'notificationPanel.system.videoCallProvider.zoom': 'Zoom',
  'notificationPanel.system.textDocumentIsVoided': `{hasReason, select,
    yes {{clientName} has voided {documentNameLink}. Reason for voiding: {reason}}
    no {{userName} has voided {documentNameLink}}
    other {}
  }`,
  'notificationPanel.system.textDocumentIsDeleted': '{userName} has voided {documentName}',
  'notificationPanel.system.documentSenderSignatureIsNotRequired': 'You don’t need to sign {documentNameLink} anymore.',
  'notificationPanel.system.textDocumentIsCompleted': '{documentNameLink} is signed by all.',
  'notificationPanel.system.textDocumentSignaturePostponed':
    "Your signing order for {documentNameLink} is updated. Please wait until it's your turn to sign.",
  'notificationPanel.system.textDocumentIsDeclined': '{signatoryName} has declined to sign {documentNameLink}.',
  'notificationPanel.system.textDocumentReceivedForSign': '{documentNameLink} is waiting for your signature.',
  'notificationPanel.system.waitlistRequestWasDestroyedDyUserProfile': `{userFullName} deleted {clientFullName}'s waitlist request for {serviceName}.`,
  'notificationPanel.system.waitlistRequestWasDestroyedByClientProfile':
    '{clientFullName} deleted a waitlist request for {serviceNames}.',
  'notificationPanel.system.waitlistRequestUpdated': `{hasUserFullName, select,
    yes {{userFullName} updated {clientFullName}'s {waitlistRequestLink} for {serviceName}.}
    no {{clientFullName} updated a {waitlistRequestLink} for {serviceName}.}
    other {}
  }`,
  'notificationPanel.system.waitlistRequestAdded':
    '{clientFullName} has a new {waitlistRequestLink} for {serviceName}.',
  'notificationPanel.team.invitationAccepted': '{userName} joined your workspace!',
  'notificationPanel.team.teamMemberJoined': '{userName} added new team member {updatedUserName}',
  'notificationPanel.team.teamMemberJoinedV2': '{updatedUserName} joined your workspace!',
  'notificationPanel.team.teamMemberRoleChanged': '{userName} changed role of {updatedUserName} to {newRole}',
  'notificationPanel.team.teamMemberRoleChangedV2': "{updatedUserName}'s role was changed to {newRole}.",
  'notificationPanel.team.teamMemberDeactivated': '{userName} deactivated {updatedUserName}',
  'notificationPanel.team.teamMemberDeactivatedV2': "{updatedUserName}'s account was deactivated.",
  'notificationPanel.team.teamMemberActivated': '{userName} activated {updatedUserName}',
  'notificationPanel.team.teamMemberActivatedV2': "{updatedUserName}'s account is now active.",
  'notificationPanel.team.teamMemberRemoved': '{userName} removed {updatedUserName}',
  'notificationPanel.team.teamMemberRemovedV2': '{updatedUserName} left the workspace.',
  'notificationPanel.team.teamMemberProfileUpdated': "{userName}'s profile was updated.",
  'notificationPanel.team.myRoleChanged': 'Your role was changed to {newRole}.',
  'notificationPanel.team.ownershipTransferred': '{userName} has made {updatedUserName} a new workspace owner.',
  'notificationPanel.team.teamMemberHasAppointmentReviewed': '{userName} got a new review.',
  'notificationPanel.team.iHaveANewSharedFile': '{sharedByName} shared {storageKind} with you.',
  'notificationPanel.team.transferFilesOwnership': '{deletedUserFirstName}’s files have been transferred to you.',
  'notificationPanel.tasks.providerAssignedTaskToMe': '{userName} assigned a task to you. {viewTasks}',
  'notificationPanel.tasks.myTaskHasADueDateToday':
    'You have {tasksCount} {tasksCount, plural, one {task} other {tasks}} due today. {viewTasks}',
  'notificationPanel.tasks.viewTasks': 'View tasks',
  'notificationPanel.notificationMessage.notImplemented': 'Not implemented',
}

export default notificationPanel
