import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { paginationSelector } from 'state/concepts/companyClientBookings/selectors'
import { FETCH_CLIENT_BOOKINGS } from 'state/concepts/companyClientBookings/types'
import { fetchClientBookingsEndpoint } from 'state/concepts/companyClientBookings/endpoints'
import { setClientBookings } from 'state/concepts/companyClientBookings/actions'

const fetchBookingsOperation = createLogic({
  type: FETCH_CLIENT_BOOKINGS,
  latest: true,

  async process({ httpClient, getState, action: { clientId } }, dispatch, done) {
    const state = getState()
    const employee = currentEmployeeSelector(state)
    const { endpoint, url } = fetchClientBookingsEndpoint(clientId)

    const params = {
      include: [
        'source.service',
        'source.service-category',
        'source.client-profile',
        'source.user-profile',
        'source.reschedule',
        'source.video-records',
        'source.booking-recurrence',
      ],
      sort: '-id',
      page: paginationSelector(state),
      filter: {
        timezone: employee.timezone,
      },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setClientBookings(map(prop('id'), data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchBookingsOperation
