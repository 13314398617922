import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import InputField from 'views/shared/InputField'
import PhoneInputField from 'views/shared/PhoneInputField'
import ResendCode from './ResendCode'

const SmsMethodFields = ({ setStep, verifyNumberStep, isStepWithOtp }) => {
  const {
    setFieldValue,
    values: { phoneNumber },
  } = useFormikContext()

  const resetSmsValues = () => {
    setFieldValue('phoneNumber', '')
    setFieldValue('otpCode', '')
  }
  const onClear = () => {
    resetSmsValues()
    setStep(verifyNumberStep)
  }

  return (
    <>
      <p className="text-body">
        <FormattedMessage id="employeeTwoFactorSettings.setSms.description" />
      </p>
      <Field
        type="tel"
        id="phoneNumber"
        name="phoneNumber"
        component={PhoneInputField}
        mainInputClassName="mb-0"
        className="phone-with-country-wrap mb-0"
        label={{ id: 'employeeOnboarding.phone' }}
        icon="alert"
        placeholder={{ id: 'employeeOnboarding.phone.placeholder' }}
        data-cy="phone-input"
        disabled={isStepWithOtp}
        isClearShown={isStepWithOtp}
        onClear={onClear}
      />
      {isStepWithOtp && (
        <>
          <p className="text-body mt-24">
            <FormattedMessage id="employeeTwoFactorSettings.setSms.codeIsSent" />
          </p>
          <Field
            id="otpCode"
            icon="alert"
            name="otpCode"
            component={InputField}
            label={{ id: 'employeeTwoFactorSettings.setSms.otpCode.label' }}
            className="mb-0 main-input"
            iconSize={16}
          />
          <ResendCode phoneNumber={phoneNumber} />
        </>
      )}
    </>
  )
}

SmsMethodFields.propTypes = {
  setStep: PropTypes.func.isRequired,
  verifyNumberStep: PropTypes.number.isRequired,
  isStepWithOtp: PropTypes.bool.isRequired,
}

export default SmsMethodFields
