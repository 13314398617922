import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { prepareEditorValue } from 'utils/reactDraftEditor'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { updateSignatureSettingEndpoint } from '../endpoints'
import { UPDATE_SIGNATURE_SETTING } from '../types'

const updateSignatureSettingOperation = createLogic({
  type: UPDATE_SIGNATURE_SETTING,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { url, endpoint } = updateSignatureSettingEndpoint
    const { name, content, auditTrailEnabled, disclosureEnabled } = values
    const params = {
      signature_disclosure: {
        name,
        content: prepareEditorValue(content),
      },
      audit_trail_enabled: auditTrailEnabled,
      disclosure_enabled: disclosureEnabled,
      include: ['signature-disclosure'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.signatureSettingsUpdated',
          },
        }),
      )
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateSignatureSettingOperation
