import PropTypes from 'prop-types'

const RadioColorField = ({ options, field }) => (
  <>
    {options.map(color => (
      <div key={color} className="category-color-wrap">
        <label htmlFor={`${field.name}-${color}`} className="category-color">
          <input
            className="category-color__input"
            type="radio"
            id={`${field.name}-${color}`}
            value={color}
            checked={color === field.value}
            onChange={field.onChange(field.name)}
            name={field.name}
          />
          <span className="category-color__dot" style={{ backgroundColor: color }} />
        </label>
      </div>
    ))}
  </>
)

RadioColorField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  field: PropTypes.shape().isRequired,
}

export default RadioColorField
