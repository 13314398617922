import Envelope from 'views/shared/icons/Envelope'
import ChatHistory from 'views/shared/icons/ChatHistory'
import Authentication from 'views/shared/icons/Authentication'

export const TWO_FACTOR_SETTING_METHODS = {
  email: 'email',
  sms: 'sms',
  app: 'app',
}

export const TWO_FACTOR_SETTING_ICONS = {
  [TWO_FACTOR_SETTING_METHODS.email]: Envelope,
  [TWO_FACTOR_SETTING_METHODS.sms]: ChatHistory,
  [TWO_FACTOR_SETTING_METHODS.app]: Authentication,
}

export const TWO_FACTOR_SETTING_STEPS = {
  password: 0,
  selectMethod: 1,
  verifyNumber: 2,
  enableSms: 3,
  enableApp: 4,
}

export const SETUP_TWO_FACTOR_SETTING_STEPS = {
  selectMethod: 0,
  verifyNumber: 1,
  enableSms: 2,
  enableApp: 3,
  backupCodes: 4,
}
