const account = {
  'account.account': 'Account',
  'account.myProfile': 'My Profile',
  'account.accountSettings': 'Account settings',
  'account.reviews': 'Reviews',
  'account.logout': 'Log out',
  'account.logoutFrom': 'Logout from',
  'account.logoutOfAll': 'Log out of all workspaces',
  'account.modal.doYouWantToLogoutFromAllWorkspaces': 'Do you want to log out from all workspaces?',
  'account.modal.youWillBeLoggedOut': 'You will be logged out from all company and personal workspaces.',
  'account.more': 'More..',
  'account.personalProfile': 'Personal profile',
  'account.addWorkspace': 'Add another workspace',
  'account.paymentHistory': 'Payment History',
  'account.myReviews': 'My Reviews',
  'account.helpCenter': 'Help Center',
  'account.workspaceList.title': 'Workspaces',
  'account.integrations': 'Integrations',
  'account.signedAs': 'Signed in as:',
  'account.portalName': '{userName}`s Portal',
  'account.clientPortals': '_(Client) portals',
}

export default account
