import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ChatHistory = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M234.325 0c-27.868 0-50.46 22.591-50.46 50.46s22.592 50.46 50.46 50.46h655.985v454.143c0 27.869 22.591 50.46 50.46 50.46s50.46-22.591 50.46-50.46v-454.143c0-55.759-45.162-100.921-100.921-100.921h-655.985zM82.943 201.842c-55.506 0-100.428 45.415-100.428 100.921l-0.453 695.939c-0.014 22.485 27.171 33.758 43.071 17.858l158.731-158.733h504.604c55.506 0 100.921-45.415 100.921-100.921v-454.143c0-55.506-45.415-100.921-100.921-100.921h-605.525zM82.943 302.762h605.525v454.143h-546.391l-59.133 59.134v-513.278z" />
  </svg>
)

ChatHistory.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ChatHistory.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ChatHistory
