import endpoint from 'utils/endpoint'
import {
  googleVideoCallIntegrationRoute,
  microsoftTeamsIntegrationLinkRoute,
  microsoftTeamsIntegrationRoute,
  videoCallIntegrationsRoute,
  zoomIntegrationLinkRoute,
  zoomIntegrationRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const updateVideoCallProviderEndpoint = endpoint(
  types.UPDATE_VIDEO_CALL_PROVIDER,
  'PUT',
  videoCallIntegrationsRoute,
)

export const connectGoogleMeetProviderEndpoint = endpoint(
  types.CONNECT_GOOGLE_MEET_PROVIDER,
  'POST',
  googleVideoCallIntegrationRoute,
)

export const disconnectGoogleMeetProviderEndpoint = endpoint(
  types.DISCONNECT_GOOGLE_MEET_PROVIDER,
  'DELETE',
  googleVideoCallIntegrationRoute,
)

export const getMicrosoftTeamsConnectLinkEndpoint = endpoint(
  types.GET_MICROSOFT_TEAMS_CONNECT_LINK,
  'POST',
  microsoftTeamsIntegrationLinkRoute,
)

export const connectMicrosoftTeamsProviderEndpoint = endpoint(
  types.CONNECT_MICROSOFT_TEAMS_PROVIDER,
  'POST',
  microsoftTeamsIntegrationRoute,
)

export const disconnectMicrosoftTeamsProviderEndpoint = endpoint(
  types.DISCONNECT_MICROSOFT_TEAMS_PROVIDER,
  'DELETE',
  microsoftTeamsIntegrationRoute,
)

export const getZoomConnectLinkEndpoint = endpoint(types.GET_ZOOM_CONNECT_LINK, 'POST', zoomIntegrationLinkRoute)

export const connectZoomProviderEndpoint = endpoint(types.CONNECT_ZOOM_PROVIDER, 'POST', zoomIntegrationRoute)

export const disconnectZoomProviderEndpoint = endpoint(types.DISCONNECT_ZOOM_PROVIDER, 'DELETE', zoomIntegrationRoute)
