import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'

import emptySearch from 'assets/images/auth/empty_search.svg'

const NotFound = () => (
  <div className="d-flex justify-content-center align-items-center full-width">
    <div className="text-center">
      <div className="chat-msgs__chat-img">
        <Image width={320} height={320} src={emptySearch} alt="No results" data-cy="add-chat-users-empty-image" />
      </div>
      <p className="chat-msgs__chat-empty-txt" data-cy="add-chat-users-empty-text">
        <FormattedMessage id="chat.inviteChatMembers.modal.noChatUsers" />
      </p>
      <p className="chat-msgs__chat-empty-txt" data-cy="add-chat-users-empty-try-another">
        <FormattedMessage id="chat.inviteChatMembers.modal.tryAnotherRequest" />
      </p>
    </div>
  </div>
)

export default NotFound
