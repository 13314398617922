import { Field } from 'formik'
import { useSelector } from 'react-redux'

import DropdownLocationField from 'views/shared/DropdownLocationField'
import LocationOIcon from 'views/shared/icons/LocationO'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const LocationSelect = () => {
  const { videoCallProvider } = useSelector(workspaceSelector)

  return (
    <div className="booking-tooltip__info booking-tooltip__info-divider">
      <div className="d-flex">
        <div className="mr-16 pt-36">
          <LocationOIcon size={20} color="#6e798d" />
        </div>
        <div id="select_location" className="max-w-280 w-100">
          <Field
            className="mb-0 booking-tooltip__select-dropdown"
            name="locationId"
            id="locationId"
            component={DropdownLocationField}
            label={{ id: 'scheduleAppointment.location' }}
            placeholder={{ id: 'scheduleAppointment.location.placeholder' }}
            icon="alert"
            data-cy="location-select-field"
            videoCallProvider={videoCallProvider}
          />
        </div>
      </div>
    </div>
  )
}

export default LocationSelect
