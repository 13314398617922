import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const createClientContact = makeFormSubmitAction(types.CREATE_CLIENT_CONTACT)
export const updateClientContact = makeFormSubmitAction(types.UPDATE_CLIENT_CONTACT)

export const fetchClientContact = (contactId, workspaceCode) => ({
  type: types.FETCH_CLIENT_CONTACT,
  contactId,
  workspaceCode,
})

export const fetchClientContacts = (clientId, pageParams = {}) => ({
  type: types.FETCH_CLIENT_CONTACTS,
  clientId,
  pageParams,
})

export const addClientContactsIds = ids => ({
  type: types.ADD_CLIENT_CONTACTS_IDS,
  ids,
})

export const resetClientContacts = () => ({
  type: types.RESET_CLIENT_CONTACTS,
})

export const deleteClientContact = (contactId, feedId) => ({
  type: types.DELETE_CLIENT_CONTACT,
  contactId,
  feedId,
})

export const removeClientContact = id => ({
  type: types.REMOVE_CLIENT_CONTACT,
  id,
})

export const sendDeleteConfirmationCode = makeFormSubmitAction(types.SEND_DELETE_CONFIRMATION_CODE)
export const resendDeleteConfirmationCode = currentContact => ({
  type: types.SEND_DELETE_CONFIRMATION_CODE,
  currentContact,
})
export const deleteClientContactOwnAccount = makeFormSubmitAction(types.DELETE_CLIENT_CONTACT_OWN_ACCOUNT)
