import { createLogic } from 'redux-logic'

import { subscriptionsChangeStoragePlanSubscriptionRoute } from 'lib/apiRoutes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_STORAGE_PLAN_SUBSCRIPTION } from 'state/concepts/subscriptionPlans/types'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { setAutoUpgrade } from 'state/concepts/storage/actions'

const updateStoragePlanSubscriptionOperation = createLogic({
  type: UPDATE_STORAGE_PLAN_SUBSCRIPTION,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const {
      values: { autoUpgrade },
      form,
    } = action
    const params = {
      auto_upgrade: autoUpgrade,
    }
    try {
      await httpClient.put(subscriptionsChangeStoragePlanSubscriptionRoute, params)

      dispatch(hideModal())
      dispatch(setAutoUpgrade(autoUpgrade))
      dispatch(showNotification({ messageObject: { id: 'notifications.autoUpgradeSettingsUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateStoragePlanSubscriptionOperation
