import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { companySettingsWebsitePreviewRoute } from 'lib/routes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { websiteRoute } from 'lib/apiRoutes'
import redirect from 'utils/redirect'
import { prepareEditorValue } from 'utils/reactDraftEditor'
import { dataApiSuccess } from 'state/data/actions'
import { CREATE_WEBSITE } from 'state/concepts/website/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const createWebsiteOperation = createLogic({
  type: CREATE_WEBSITE,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    try {
      const params = {
        widget_id: values.widgetId,
        header_title: values.headerTitle,
        header_subtitle: values.headerSubtitle,
        website_setting: {
          privacy_policy_enabled: values.privacyPolicyEnabled,
          privacy_policy: prepareEditorValue(values.privacyPolicy),
          terms_and_conditions_enabled: values.termsAndConditionsEnabled,
          terms_and_conditions: prepareEditorValue(values.termsAndConditions),
        },
      }

      const { data } = await httpClient.post(websiteRoute, params)

      dispatch(dataApiSuccess({ response: normalize(data) }))
      redirect({ href: companySettingsWebsitePreviewRoute, workspace: currentWorkspaceCodeSelector(getState()) })
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default createWebsiteOperation
