import { combineReducers } from '@reduxjs/toolkit'

import { SET_EMPLOYEE_INVITATION_ID, SET_REMIND_WORKSPACES, SET_EMPLOYEE_ID } from './types'

const employeeInvitationId = (state = null, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_INVITATION_ID:
      return action.id
    default:
      return state
  }
}

const remindWorkspaces = (state = [], action) => {
  switch (action.type) {
    case SET_REMIND_WORKSPACES:
      return action.workspaces
    default:
      return state
  }
}

const employeeId = (state = null, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_ID:
      return action.id
    default:
      return state
  }
}

const employeeReducer = combineReducers({
  employeeInvitationId,
  remindWorkspaces,
  employeeId,
})

export default employeeReducer
