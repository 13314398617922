import { equals, identity, ifElse, isNil } from 'ramda'
import { createLogic } from 'redux-logic'

import { UPDATE_CLIENT_GENERAL_SETTINGS } from 'state/concepts/client/types'
import { currentClientSelector } from 'state/concepts/session/selectors'
import isPresent from 'utils/isPresent'
import updateDataHelper from 'utils/updateDataHelper'
import { dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { clientsAccountLogoutIntervalEndpoint, updateClientContactDetailsEndpoint } from '../endpoints'

const updateClientGeneralSettingsOperation = createLogic({
  type: UPDATE_CLIENT_GENERAL_SETTINGS,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { logoutInterval: initialLogoutInterval, newEmail, phoneNumber, id, email } = currentClientSelector(state)
    const isLogoutEnabled = isPresent(initialLogoutInterval)
    const logoutInterval = initialLogoutInterval?.toString() || null
    const isSameEmail = newEmail ? values.newEmail === newEmail : values.newEmail === email
    const isSamePhoneNumber = values.phoneNumber === phoneNumber
    const attributes = {}

    try {
      if (!equals([newEmail, phoneNumber], [values.newEmail, values.phoneNumber])) {
        const { url } = updateClientContactDetailsEndpoint

        const params = {}

        if (!isSameEmail) {
          params.new_email = values.newEmail
          attributes.newEmail = values.newEmail
        }

        if (!isSamePhoneNumber) {
          params.phone_number = values.phoneNumber
          attributes.phoneNumber = values.phoneNumber
        }

        await httpClient.put(url, params)
      }

      if (!equals([isLogoutEnabled, logoutInterval], [values.isLogoutEnabled, values.logoutInterval])) {
        const { url } = clientsAccountLogoutIntervalEndpoint

        const params = { logout_interval: values.logoutInterval }

        await httpClient.put(url, params)

        attributes.logoutInterval = ifElse(isNil, identity, Number)(values.logoutInterval)
      }

      const response = updateDataHelper(state.data, 'clientProfile', id, {
        attributes,
      })
      dispatch(dataApiSuccess({ response }))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.updateGeneralSettings' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateClientGeneralSettingsOperation
