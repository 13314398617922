import { createLogic } from 'redux-logic'

import { LOCATION_STATUS } from 'lib/constants/locations'
import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { toggleLocationEndpoint } from 'state/concepts/locations/endpoints'
import { TOGGLE_LOCATION_STATUS } from 'state/concepts/locations/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { locationSelector } from 'state/concepts/locations/selectors'

const toggleLocationStatusOperation = createLogic({
  type: TOGGLE_LOCATION_STATUS,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const { locationId } = action
    const { url, endpoint } = toggleLocationEndpoint(locationId)

    try {
      const state = getState()
      const { status } = locationSelector(state, locationId)
      const isActive = status === LOCATION_STATUS.active
      const notificationId = isActive
        ? 'notifications.locationHasBeenDeactivated'
        : 'notifications.locationHasBeenActivated'

      const params = {
        status: isActive ? LOCATION_STATUS.deactivated : LOCATION_STATUS.active,
      }

      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'location', locationId, {
        attributes: {
          status: params.status,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: notificationId } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default toggleLocationStatusOperation
