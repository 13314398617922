import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import BreadcrumbsComponent from 'views/shared/Breadcrumbs/component'

const Breadcrumbs = ({
  isHasSelectedFolder,
  initialSelectedFolder,
  pathSelector,
  onSelect,
  firstCrumbLabel,
  fileId,
  ...restProps
}) => {
  const path = useSelector(state => pathSelector(state, fileId))

  const crumbsMapper = ([folder, name], index) => ({
    label: name,
    role: 'button',
    onClick: index === path.length - 1 ? undefined : () => onSelect(folder),
  })

  const isWithOverflowMenu = path.length > 1
  const crumbs = isWithOverflowMenu ? path.slice(-1).map(crumbsMapper) : path.map(crumbsMapper)
  const overflowMenuCrumbs = isWithOverflowMenu ? path.slice(0, -1).map(crumbsMapper) : []

  const computedCrubms = [
    {
      label: firstCrumbLabel,
      role: 'button',
      onClick: isHasSelectedFolder ? () => onSelect(initialSelectedFolder, true) : undefined,
    },
    ...(isWithOverflowMenu
      ? [{ id: 'three-dots-menu-breadcrubs', label: '...', menu: { items: overflowMenuCrumbs } }]
      : []),
    ...crumbs,
  ]

  return <BreadcrumbsComponent crumbs={computedCrubms} {...restProps} />
}

Breadcrumbs.defaultProps = {
  isHasSelectedFolder: false,
  initialSelectedFolder: undefined,
  onSelect: noop,
}

Breadcrumbs.propTypes = {
  fileId: PropTypes.string.isRequired,
  isHasSelectedFolder: PropTypes.bool,
  initialSelectedFolder: PropTypes.shape({}),
  onSelect: PropTypes.func,
  pathSelector: PropTypes.func.isRequired,
  firstCrumbLabel: PropTypes.shape().isRequired,
}

export default Breadcrumbs
