import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { selectedTimezoneSelector } from 'state/concepts/bookings/selectors'
import { addSearchResults, resetSearchResults } from 'state/searchResults/actions'
import { fetchEmployeesAvailabilitiesEndpoint } from '../endpoints'
import { FETCH_EMPLOYEES_AVAILABILITIES } from '../types'

const fetchEmployeesAvailabilitiesOperation = createLogic({
  type: FETCH_EMPLOYEES_AVAILABILITIES,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const {
      date,
      excludeEmployees,
      pageParams: { nextCursor = null },
      resetResults,
    } = action
    const { endpoint, url } = fetchEmployeesAvailabilitiesEndpoint
    const timezone = selectedTimezoneSelector(getState())

    const params = {
      date,
      timezone,
      exclude_users: excludeEmployees,
      page: {
        after: nextCursor,
        size: 20,
      },
      include: ['date-availability'],
    }

    dispatch(dataApiRequest({ endpoint }))

    if (resetResults) {
      dispatch(resetSearchResults('employees'))
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const ids = pluck('id', data.data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ employees: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchEmployeesAvailabilitiesOperation
