import { any, propEq, pathOr } from 'ramda'

import isPresent from 'utils/isPresent'

const isStripePaymentError = error => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error)

  return isPresent(errors) && any(propEq({ type: 'stripe_payment_error' }, 'meta'), errors)
}

export default isStripePaymentError
