import React from 'react'
import { connect } from 'react-redux'

import { hideSidebar } from 'state/sidebar/actions'
import {
  sidebarTypeSelector,
  sidebarPropsSelector,
  sidebarShowOverlaySelector,
  sidebarAnimateSelector,
} from 'state/sidebar/selectors'
import SidebarRootComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class SidebarRoot extends React.Component {
  render() {
    return <SidebarRootComponent {...this.props} />
  }
}

const mapStateToProps = state => ({
  sidebarType: sidebarTypeSelector(state),
  sidebarProps: sidebarPropsSelector(state),
  showOverlay: sidebarShowOverlaySelector(state),
  animate: sidebarAnimateSelector(state),
})

const mapDispatchToProps = {
  onClose: hideSidebar,
}

export { SidebarRoot as SidebarRootContainer }
export default connect(mapStateToProps, mapDispatchToProps)(SidebarRoot)
