import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const AlertO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M298.275 42.426 506.11 423.457c12.737 23.351 4.132 52.605-19.219 65.342a48.166 48.166 0 0 1-23.062 5.881H48.159c-26.598 0-48.16-21.563-48.16-48.16a48.158 48.158 0 0 1 5.881-23.062L213.715 42.427c12.737-23.351 41.991-31.956 65.342-19.218a48.176 48.176 0 0 1 19.219 19.218zm-42.28 23.062L48.16 446.519h415.67L255.995 65.488zm0 296.75c13.299 0 24.081 10.781 24.081 24.081S269.295 410.4 255.995 410.4c-13.299 0-24.081-10.781-24.081-24.081s10.782-24.081 24.081-24.081zm0-180.602c13.299 0 24.081 10.782 24.081 24.081v96.322c0 13.299-10.781 24.081-24.081 24.081s-24.081-10.781-24.081-24.081v-96.322c0-13.299 10.782-24.081 24.081-24.081z" />
  </svg>
)

AlertO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

AlertO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default AlertO
