import PropTypes from 'prop-types'

import profileName from 'utils/profileName'
import { formattedDate } from 'utils/dateTime'
import { fileSize } from 'utils/file'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'

const File = ({ size, createdAt, chatUser, filename, isAttachmentsList }) => (
  <>
    <div className="chat-msg-file__name" data-cy="chat-message-file-name">
      <TruncatedTextByWidthTooltip text={filename} overlayClassName="ant-modal-tooltip" on={['hover']} />
    </div>
    <div className="d-flex">
      <div className="chat-msg-file__details" data-cy="chat-message-file-size">
        <p className="mb-0">{fileSize(size)}</p>
      </div>
      {isAttachmentsList && (
        <>
          <div className="chat-msg-file__details" data-cy="chat-message-chat-user-name">
            <p className="mb-0">{profileName(chatUser)}</p>
          </div>
          <div className="chat-msg-file__details" data-cy="chat-message-attached-date">
            <p className="mb-0">{formattedDate(createdAt)}</p>
          </div>
        </>
      )}
    </div>
  </>
)

File.defaultProps = {
  chatUser: null,
  isAttachmentsList: false,
}

File.propTypes = {
  size: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  chatUser: PropTypes.shape(),
  isAttachmentsList: PropTypes.bool,
}

export default File
