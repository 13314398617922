import { createLogic } from 'redux-logic'

import { FILTER_LOCATIONS } from 'state/concepts/locations/types'
import { setFilterParams, fetchLocations } from 'state/concepts/locations/actions'
import { setCurrentPage } from 'state/tableData/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'

const filterLocationsOperation = createLogic({
  type: FILTER_LOCATIONS,
  latest: true,

  async process({ action: { filterParams } }, dispatch, done) {
    dispatch(setFilterParams(filterParams))
    dispatch(setCurrentPage(TABLE_ENTITIES.location, 1))
    dispatch(fetchLocations())
    done()
  },
})

export default filterLocationsOperation
