import fetchDocumentTemplates from './fetchDocumentTemplates'
import filterDocumentTemplates from './filterDocumentTemplates'
import createDocumentTemplate from './createDocumentTemplate'
import getDocumentTemplate from './getDocumentTemplate'
import renameDocumentTemplate from './renameDocumentTemplate'
import deleteDocumentTemplate from './deleteDocumentTemplate'
import previewDocumentTemplate from './previewDocumentTemplate'
import deactivateDocumentTemplate from './deactivateDocumentTemplate'
import activateDocumentTemplate from './activateDocumentTemplate'
import discardDocumentTemplate from './discardDocumentTemplate'
import touchDocumentTemplate from './touchDocumentTemplate'
import publishDocumentTemplate from './publishDocumentTemplate'

export default [
  fetchDocumentTemplates,
  filterDocumentTemplates,
  createDocumentTemplate,
  getDocumentTemplate,
  renameDocumentTemplate,
  deleteDocumentTemplate,
  previewDocumentTemplate,
  deactivateDocumentTemplate,
  activateDocumentTemplate,
  discardDocumentTemplate,
  touchDocumentTemplate,
  publishDocumentTemplate,
]
