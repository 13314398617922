import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { decUnreadNotificationsCount } from 'state/concepts/notifications/actions'
import { unreadNotificationsCountSelector } from 'state/concepts/notifications/selectors'
import { MARK_NOTIFICATION_AS_READ } from 'state/concepts/notifications/types'
import { markNotificationAsReadEndpoint } from 'state/concepts/notifications/endpoints'

const markNotificationAsReadOperation = createLogic({
  type: MARK_NOTIFICATION_AS_READ,
  latest: true,

  async process({ httpClient, getState, action: { notificationId } }, dispatch, done) {
    const { endpoint, url } = markNotificationAsReadEndpoint(notificationId)
    const state = getState()
    const unreadCount = unreadNotificationsCountSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.post(url)

      const response = updateDataHelper(state.data, 'notification', notificationId, {
        attributes: { readAt: DateTime.utc() },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(decUnreadNotificationsCount(unreadCount))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default markNotificationAsReadOperation
