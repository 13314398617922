import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import { SERVICE_STATUSES } from 'lib/constants/services'

const OptionContent = ({ service: { name, status } }) => (
  <span
    className={classNames('dropdown-multiselect-item', {
      'dropdown-multiselect-item--deactiv': status === SERVICE_STATUSES.inactive,
    })}
    data-cy="appointment-select-service"
  >
    <span className="dropdown-multiselect-item__name">{name}</span>
    {status === SERVICE_STATUSES.inactive && (
      <span className="dropdown-multiselect-item__status">
        <FormattedMessage id="status.inactive" />
      </span>
    )}
  </span>
)

OptionContent.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

export default OptionContent
