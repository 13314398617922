import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { viewFilesEndpoint } from 'state/concepts/clientAccountDocuments/endpoints'
import { VIEW_FILES } from 'state/concepts/clientAccountDocuments/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const viewFilesOperation = createLogic({
  type: VIEW_FILES,
  latest: true,

  async process({ httpClient, action: { sharedFileIds } }, dispatch, done) {
    const { endpoint, url } = viewFilesEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.post(url, { shared_file_ids: sharedFileIds })

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default viewFilesOperation
