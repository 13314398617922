import SessionExpiringModal from 'views/AutomaticLogoutTimer/SessionExpiringModal'
import InvitePeopleModal from 'views/Chat/InviteMembersModal'
import RemoveChatMessageModal from 'views/Chat/MessagesList/Message/RemoveModal'
import ChatDetailsModal from 'views/Chats/ChatDetailsModal'
import ChatInfoModal from 'views/Chats/ChatInfoModal'
import AccessForbiddenModal from 'views/VideoConference/modals/AccessForbiddenModal'
import VideoConferenceSettingsModal from 'views/VideoConference/modals/SettingsModal'
import WebsiteWysiwygModal from 'views/Website/WysiwygModal'
import ClientDeleteAccountModal from 'views/client/AccountSettings/General/DeleteAccount/DeleteModal'
import DeclineRecurringAppointmentsModal from 'views/client/Booking/DeclineRecurringAppointmentsModal'
import DeclineWaitlistAppointmentModal from 'views/client/Booking/DeclineWaitlistAppointmentModal'
import ClientCancelBookingModal from 'views/client/ConsultationPage/CancelModal'
import ClientRescheduleBookingModal from 'views/client/ConsultationPage/RescheduleModal'
import EditReviewModal from 'views/client/EditReviewAppointmentModal'
import DeclineToSignFormModal from 'views/client/Forms/DeclineToSignFormModal'
import SubscriptionTransactionPreviewModal from 'views/company/Billing/PaymentHistory/SubscriptionTransactionPreviewModal'
import PromocodeModal from 'views/company/Billing/PromocodeModal'
import SwitchBillingCycleModal from 'views/company/Billing/SwitchBillingCycleModal'
import CalendarSettingsModal from 'views/company/Bookings/CalendarSettingsModal'
import CancelModal from 'views/company/Bookings/CancelModal'
import CancelRecurringBookingModal from 'views/company/Bookings/CancelRecurringBookingModal'
import RescheduleRecurringBookingModal from 'views/company/Bookings/RescheduleRecurringBookingModal'
import CustomFieldModal from 'views/company/ClientFields/CustomFieldModal'
import RemoveClientRelationshipTypeModal from 'views/company/ClientRelationshipTypes/RemoveClientRelationshipTypeModal'
import ReceiptTemplatePreviewModal from 'views/company/CustomizeReceipt/ReceiptTemplatePreviewModal'
import DeleteWebsiteModal from 'views/company/EditWebsite/General/DeleteWebsite/DeleteWebsiteModal'
import EmployeesActivateModal from 'views/company/Employees/ActivateModal'
import AssignServicesModal from 'views/company/Employees/AssignServicesModal'
import ChangeRoleModal from 'views/company/Employees/ChangeRoleModal'
import EmployeesDeactivateModal from 'views/company/Employees/DeactivateModal'
import EmployeesDeactivateSchedulerModal from 'views/company/Employees/DeactivateSchedulerModal'
import EmployeesErrorModal from 'views/company/Employees/ErrorModal'
import InvitationModal from 'views/company/Employees/InvitationModal'
import RenameDocumentModal from 'views/company/Client/EtherpadDocuments/RenameDocumentModal'
import RenameDocumentTemplateModal from 'views/company/DocumentTemplates/RenameDocumentTemplateModal'
import VoidDocumentModal from 'views/company/Client/EtherpadDocuments/VoidDocumentModal'
import SignDocumentModal from 'views/company/Client/EtherpadDocuments/SignDocumentModal'
import ShareDocumentWithClientModal from 'views/company/Client/EtherpadDocuments/ShareDocumentWithClientModal'
import EmployeesRemoveInvitationModal from 'views/company/Employees/RemoveInvitationModal'
import EmployeesRemoveModal from 'views/company/Employees/RemoveModal'
import DisableLocationsFeatureModal from 'views/company/Features/Locations/DisableLocationsFeatureModal'
import PersistFormCategoryModal from 'views/company/FormCategories/PersistFormCategoryModal'
import RemoveFormCategoryModal from 'views/company/FormCategories/RemoveFormCategoryModal'
import DeleteLocationModal from 'views/company/Locations/DeleteLocationModal'
import ChargeWithCardReaderModal from 'views/company/Payment/ChargeWithCardReaderModal'
import IssueRefundModal from 'views/company/Payment/IssueRefundModal'
import MarkAsPaidModal from 'views/company/Payment/MarkAsPaidModal'
import ManualPaymentModal from 'views/company/PaymentSettings/ManualPayments/ManualPaymentModal'
import DisconnectPaypalModal from 'views/company/PaymentSettings/PayPal/DisconnectModal'
import DisconnectModalPaymentSettingsModal from 'views/company/PaymentSettings/Stripe/DisconnectModal'
import TransactionPreviewModal from 'views/company/Payments/TransactionPreviewModal'
import ServiceCategoriesActivateModal from 'views/company/ServiceCategories/ActivateModal'
import ServiceCategoriesBulkActivateModal from 'views/company/ServiceCategories/BulkActivateModal'
import ServiceCategoriesBulkDeactivateModal from 'views/company/ServiceCategories/BulkDeactivateModal'
import ServiceCategoriesBulkRemoveModal from 'views/company/ServiceCategories/BulkRemoveModal'
import ServiceCategoryCreateModal from 'views/company/ServiceCategories/CreateModal'
import ServiceCategoriesDeactivateModal from 'views/company/ServiceCategories/DeactivateModal'
import ServiceCategoryEditModal from 'views/company/ServiceCategories/EditModal'
import ServiceCategoryRemoveModal from 'views/company/ServiceCategories/RemoveModal'
import ServicesActivateModal from 'views/company/Services/ActivateModal'
import ServicesActivateWarningModal from 'views/company/Services/ActivateWarningModal'
import AssignEmployeesModal from 'views/company/Services/AssignEmployeesModal'
import ServicesBulkActivateModal from 'views/company/Services/BulkActivateModal'
import ServicesBulkDeactivateModal from 'views/company/Services/BulkDeactivateModal'
import ServicesBulkRemoveModal from 'views/company/Services/BulkRemoveModal'
import ServicesDeactivateModal from 'views/company/Services/DeactivateModal'
import ServicesRemoveModal from 'views/company/Services/RemoveModal'
import SwitchToPremiumModal from 'views/company/SubscriptionPlans/SwitchToPremiumModal'
import CompanyIsNotDeletedModal from 'views/company/WorkspaceSettings/CompanyIsNotDeletedModal'
import TransferOwnershipModal from 'views/company/WorkspaceSettings/TransferOwnershipModal'
import EmployeeDeactivateModal from 'views/employee/EmployeeAccountSettings/General/DeactivateAccount/DeactivateModal'
import BackupCodesModal from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettings/BackupCodesModal'
import FreshBackupCodesModal from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettings/FreshBackupCodesModal'
import TwoFactorSettingsModal from 'views/employee/EmployeeAccountSettings/Security/TwoFactorSettingsModal'
import AppleDisconnectModal from 'views/employee/Integrations/Calendars/AppleDisconnectModal'
import ConnectAppleCalendarModal from 'views/employee/Integrations/Calendars/ConnectAppleCalendarModal'
import GoogleDisconnectModal from 'views/employee/Integrations/Calendars/GoogleDisconnectModal'
import OutlookDisconnectModal from 'views/employee/Integrations/Calendars/OutlookDisconnectModal'
import InviteMembersToAppointmentModal from 'views/shared/AppointmentDetails/InviteMembersToAppointmentModal'
import RecordingRemoveModal from 'views/shared/AppointmentDetails/Recordings/RemoveModal'
import ChatUnavailableModal from 'views/shared/ChatUnavailableModal'
import ConfirmModal from 'views/shared/ConfirmModal'
import DeleteCompanyModal from 'views/shared/DeleteCompanyModal'
import ErrorModal from 'views/shared/ErrorModal'
import FilesPreviewModal from 'views/shared/FilesPreviewModal'
import RemoveFileModal from 'views/shared/RemoveFileModal'
import ManageMyBusinessFilePermissionsModal from 'views/company/MyBusinessFiles/ManagePermissionsModal'
import ErrorUploadModal from 'views/shared/UploadLogo/ErrorUploadModal'
import LogoModal from 'views/shared/UploadLogo/LogoModal'
import LogoRemoveModal from 'views/shared/UploadLogo/LogoRemoveModal'
import WelcomeModal from 'views/shared/WelcomeModal'
import UpdateEtherpadDocumentClientAccessModal from 'views/company/Client/EtherpadDocuments/UpdateEtherpadDocumentClientAccessModal'
import DeclineSignatureModal from 'views/company/Client/EtherpadDocuments/DeclineSignatureModal'
import CreateFolderModal from 'views/company/MyBusinessFiles/CreateFolderModal'
import RenameBusinessFileModal from 'views/company/MyBusinessFiles/RenameBusinessFileModal'
import BusinessFilesUploadOptionsModal from 'views/company/MyBusinessFiles/UploadOptionsModal'
import BusinessFilesMoveToFolderModal from 'views/company/MyBusinessFiles/MoveToFolderModal'
import SharedFilesMoveToFolderModal from 'views/company/FilesSharedWithMe/MoveToFolderModal'
import BusinessFileMoveToFolderWarningModal from 'views/company/MyBusinessFiles/MoveToFolderWarningModal'
import SharedFilesMoveToFolderWarningModal from 'views/company/FilesSharedWithMe/MoveToFolderWarningModal'
import RestoreFolderModal from 'views/company/TrashedFiles/RestoreFolderModal'
import AutoUpgradeSettingsModal from 'views/company/Storage/AutoUpgradeSettingsModal'

const MODAL_COMPONENTS = {
  CONFIRM_MODAL: ConfirmModal,
  ERROR_MODAL: ErrorModal,
  INVITATION_MODAL: InvitationModal,
  EMPLOYEES_DEACTIVATE_MODAL: EmployeesDeactivateModal,
  EMPLOYEES_DEACTIVATE_SCHEDULER_MODAL: EmployeesDeactivateSchedulerModal,
  EMPLOYEE_DEACTIVATE_MODAL: EmployeeDeactivateModal,
  EMPLOYEES_ACTIVATE_MODAL: EmployeesActivateModal,
  EMPLOYEES_REMOVE_MODAL: EmployeesRemoveModal,
  EMPLOYEES_ERROR_MODAL: EmployeesErrorModal,
  EMPLOYEES_REMOVE_INVITATION_MODAL: EmployeesRemoveInvitationModal,
  LOGO_MODAL: LogoModal,
  UPLOAD_ERROR_MODAL: ErrorUploadModal,
  RENAME_DOCUMENT_MODAL: RenameDocumentModal,
  RENAME_DOCUMENT_TEMPLATE_MODAL: RenameDocumentTemplateModal,
  VOID_DOCUMENT_MODAL: VoidDocumentModal,
  SIGN_DOCUMENT_MODAL: SignDocumentModal,
  SHARE_DOCUMENT_WITH_CLIENT_MODAL: ShareDocumentWithClientModal,
  CHANGE_ROLE_MODAL: ChangeRoleModal,
  TRANSFER_OWNERSHIP_MODAL: TransferOwnershipModal,
  DELETE_COMPANY_MODAL: DeleteCompanyModal,
  LOGO_REMOVE_MODAL: LogoRemoveModal,
  SERVICES_ACTIVATE_MODAL: ServicesActivateModal,
  SERVICES_ACTIVATE_WARNING_MODAL: ServicesActivateWarningModal,
  SERVICES_DEACTIVATE_MODAL: ServicesDeactivateModal,
  SERVICES_BULK_ACTIVATE_MODAL: ServicesBulkActivateModal,
  SERVICES_BULK_DEACTIVATE_MODAL: ServicesBulkDeactivateModal,
  SERVICES_BULK_REMOVE_MODAL: ServicesBulkRemoveModal,
  REMOVE_CLIENT_RELATIONSHIP_TYPE_MODAL: RemoveClientRelationshipTypeModal,
  SERVICES_REMOVE_MODAL: ServicesRemoveModal,
  SERVICE_CATEGORIES_ACTIVATE_MODAL: ServiceCategoriesActivateModal,
  SERVICE_CATEGORIES_DEACTIVATE_MODAL: ServiceCategoriesDeactivateModal,
  SERVICE_CATEGORIES_CREATE_MODAL: ServiceCategoryCreateModal,
  SERVICE_CATEGORIES_EDIT_MODAL: ServiceCategoryEditModal,
  SERVICE_CATEGORY_REMOVE_MODAL: ServiceCategoryRemoveModal,
  SERVICE_CATEGORIES_BULK_REMOVE_MODAL: ServiceCategoriesBulkRemoveModal,
  SERVICE_CATEGORIES_BULK_ACTIVATE_MODAL: ServiceCategoriesBulkActivateModal,
  SERVICE_CATEGORIES_BULK_DEACTIVATE_MODAL: ServiceCategoriesBulkDeactivateModal,
  ASSIGN_SERVICES_MODAL: AssignServicesModal,
  ASSIGN_EMPLOYEES_MODAL: AssignEmployeesModal,
  CANCEL_BOOKING_MODAL: CancelModal,
  CANCEL_RECURRING_BOOKING_MODAL: CancelRecurringBookingModal,
  DISCONNECT_PAYMENT_SETTINGS_MODAL: DisconnectModalPaymentSettingsModal,
  DISCONNECT_PAYPAL_MODAL: DisconnectPaypalModal,
  CLIENT_CANCEL_BOOKING_MODAL: ClientCancelBookingModal,
  CLIENT_RESCHEDULE_BOOKING_MODAL: ClientRescheduleBookingModal,
  VIDEO_CONFERENCE_SETTINGS_MODAL: VideoConferenceSettingsModal,
  FILES_PREVIEW_MODAL: FilesPreviewModal,
  ADD_CHAT_MEMBERS_MODAL: InvitePeopleModal,
  REMOVE_CHAT_MESSAGE_MODAL: RemoveChatMessageModal,
  REMOVE_FILE_MODAL: RemoveFileModal,
  MANAGE_BUSINESS_FILE_PERMISSIONS_MODAL: ManageMyBusinessFilePermissionsModal,
  TRANSACTION_PREVIEW_MODAL: TransactionPreviewModal,
  RECEIPT_TEMPLATE_PREVIEW_MODAL: ReceiptTemplatePreviewModal,
  SUBSCRIPTION_TRANSACTION_PREVIEW_MODAL: SubscriptionTransactionPreviewModal,
  WELCOME_MODAL: WelcomeModal,
  CLIENT_DELETE_ACCOUNT_MODAL: ClientDeleteAccountModal,
  ACCESS_FORBIDDEN_MODAL: AccessForbiddenModal,
  SESSION_EXPIRING_MODAL: SessionExpiringModal,
  PROMOCODE_MODAL: PromocodeModal,
  CHAT_DETAILS_MODAL: ChatDetailsModal,
  CHAT_INFO_MODAL: ChatInfoModal,
  CHAT_UNAVAILABLE_MODAL: ChatUnavailableModal,
  RECORDING_REMOVE_MODAL: RecordingRemoveModal,
  CUSTOM_FIELD_MODAL: CustomFieldModal,
  EDIT_REVIEW_MODAL: EditReviewModal,
  CLIENT_DECLINE_RECURRING_APPOINTMENTS_MODAL: DeclineRecurringAppointmentsModal,
  CLIENT_DECLINE_WAITLIST_APPOINTMENT_MODAL: DeclineWaitlistAppointmentModal,
  RESCHEDULE_RECURRING_BOOKING_MODAL: RescheduleRecurringBookingModal,
  MANUAL_PAYMENT_MODAL: ManualPaymentModal,
  DELETE_LOCATION_MODAL: DeleteLocationModal,
  MARK_AS_PAID_MODAL: MarkAsPaidModal,
  ISSUE_REFUND_MODAL: IssueRefundModal,
  DISABLE_LOCATIONS_FEATURE_MODAL: DisableLocationsFeatureModal,
  WEBSITE_WYSIWYG_MODAL: WebsiteWysiwygModal,
  DELETE_WEBSITE_MODAL: DeleteWebsiteModal,
  FRESH_BACKUP_CODES_MODAL: FreshBackupCodesModal,
  GOOGLE_DISCONNECT_MODAL: GoogleDisconnectModal,
  OUTLOOK_DISCONNECT_MODAL: OutlookDisconnectModal,
  CONNECT_APPLE_CALENDAR_MODAL: ConnectAppleCalendarModal,
  APPLE_DISCONNECT_MODAL: AppleDisconnectModal,
  PERSIST_FORM_CATEGORY_MODAL: PersistFormCategoryModal,
  EMPLOYEE_TWO_FACTOR_SETTINGS_MODAL: TwoFactorSettingsModal,
  REMOVE_FORM_CATEGORY_MODAL: RemoveFormCategoryModal,
  DECLINE_TO_SIGN_FORM_MODAL: DeclineToSignFormModal,
  SWITCH_TO_PREMIUM_MODAL: SwitchToPremiumModal,
  SWITCH_BILLING_CYCLE_MODAL: SwitchBillingCycleModal,
  COMPANY_IS_NOT_DELETED_MODAL: CompanyIsNotDeletedModal,
  INVITE_MEMBERS_TO_APPOINTMENT_MODAL: InviteMembersToAppointmentModal,
  CHARGE_WITH_CARD_READER_MODAL: ChargeWithCardReaderModal,
  UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS_MODAL: UpdateEtherpadDocumentClientAccessModal,
  DECLINE_ETHERPAD_DOCUMENT_SIGNING: DeclineSignatureModal,
  BACKUP_CODES_MODAL: BackupCodesModal,
  CALENDAR_SETTINGS_MODAL: CalendarSettingsModal,
  CREATE_BUSINESS_FILES_FOLDER_MODAL: CreateFolderModal,
  RENAME_BUSINESS_FILE_MODAL: RenameBusinessFileModal,
  BUSINESS_FILES_UPLOAD_OPTIONS_MODAL: BusinessFilesUploadOptionsModal,
  BUSINESS_FILES_MOVE_TO_FOLDER_MODAL: BusinessFilesMoveToFolderModal,
  SHARED_FILES_MOVE_TO_FOLDER_MODAL: SharedFilesMoveToFolderModal,
  MOVE_TO_FOLDER_WARNING_MODAL: BusinessFileMoveToFolderWarningModal,
  SHARED_FILES_MOVE_TO_FOLDER_WARNING_MODAL: SharedFilesMoveToFolderWarningModal,
  RESTORE_FOLDER_MODAL: RestoreFolderModal,
  AUTO_UPGRADE_SETTINGS_MODAL: AutoUpgradeSettingsModal,
}

export default MODAL_COMPONENTS
