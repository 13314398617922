import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import { DOCUMENT_TEMPLATE_STATUSES } from 'lib/constants/documentTemplates'
import {
  RESET_DOCUMENT_TEMPLATE_ID,
  RESET_FILTER_PARAMS,
  SET_BLANK_STATE,
  SET_DOCUMENT_TEMPLATES_IDS,
  SET_DOCUMENT_TEMPLATE_ID,
  SET_FILTER_PARAMS,
  ADD_DOCUMENT_TEMPLATES_IDS,
  RESET_DOCUMENT_TEMPLATES_IDS,
} from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case SET_DOCUMENT_TEMPLATES_IDS:
      return action.ids
    case ADD_DOCUMENT_TEMPLATES_IDS:
      return uniq([...state, ...action.ids])
    case RESET_DOCUMENT_TEMPLATES_IDS:
      return []
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
  status: DOCUMENT_TEMPLATE_STATUSES.allStatuses,
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_BLANK_STATE:
      return action.isBlankState
    case RESET_DOCUMENT_TEMPLATES_IDS:
      return true
    default:
      return state
  }
}

const id = (state = null, action) => {
  switch (action.type) {
    case SET_DOCUMENT_TEMPLATE_ID:
      return action.documentTemplateId
    case RESET_DOCUMENT_TEMPLATE_ID:
      return null
    default:
      return state
  }
}

const documentTemplatesReducer = combineReducers({
  ids,
  filters,
  isBlankState,
  id,
})

export default documentTemplatesReducer
