import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchDocumentTemplates = options => ({
  type: types.FETCH_DOCUMENT_TEMPLATES,
  options,
})

export const setDocumentTemplatesIds = ids => ({
  type: types.SET_DOCUMENT_TEMPLATES_IDS,
  ids,
})

export const addDocumentTemplatesIds = ids => ({
  type: types.ADD_DOCUMENT_TEMPLATES_IDS,
  ids,
})

export const resetDocumentTemplatesIds = () => ({
  type: types.RESET_DOCUMENT_TEMPLATES_IDS,
})

export const renameDocumentTemplate = makeFormSubmitAction(types.RENAME_DOCUMENT_TEMPLATE)

export const deleteDocumentTemplate = ({ documentTemplateId, reloadAfterDelete }) => ({
  type: types.DELETE_DOCUMENT_TEMPLATE,
  documentTemplateId,
  reloadAfterDelete,
})

export const filterDocumentTemplates = (filters = {}) => ({
  type: types.FILTER_DOCUMENT_TEMPLATES,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const createDocumentTemplate = () => ({
  type: types.CREATE_DOCUMENT_TEMPLATE,
})

export const getDocumentTemplate = (documentTemplateId, workspace, ctx) => ({
  type: types.GET_DOCUMENT_TEMPLATE,
  documentTemplateId,
  workspace,
  ctx,
})

export const setDocumentTemplateId = documentTemplateId => ({
  type: types.SET_DOCUMENT_TEMPLATE_ID,
  documentTemplateId,
})

export const resetDocumentTemplateId = () => ({
  type: types.RESET_DOCUMENT_TEMPLATE_ID,
})

export const previewDocumentTemplate = documentTemplateId => ({
  type: types.PREVIEW_DOCUMENT_TEMPLATE,
  documentTemplateId,
})

export const deactivateDocumentTemplate = documentTemplateId => ({
  type: types.DEACTIVATE_DOCUMENT_TEMPLATE,
  documentTemplateId,
})

export const activateDocumentTemplate = documentTemplateId => ({
  type: types.ACTIVATE_DOCUMENT_TEMPLATE,
  documentTemplateId,
})

export const discardDocumentTemplate = documentTemplateId => ({
  type: types.DISCARD_DOCUMENT_TEMPLATE,
  documentTemplateId,
})

export const touchDocumentTemplate = id => ({
  type: types.TOUCH_DOCUMENT_TEMPLATE,
  id,
})

export const publishDocumentTemplate = documentTemplateId => ({
  type: types.PUBLISH_DOCUMENT_TEMPLATE,
  documentTemplateId,
})
