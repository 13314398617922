import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import { loadingSelector } from 'state/data/selectors'
import { searchServices as searchServicesAction } from 'state/concepts/services/actions'
import { searchedServicesSelector, selectedProvidedServicesSelector } from 'state/concepts/services/selectors'
import { fetchServicesEndpoint } from 'state/concepts/services/endpoints'
import isPresent from 'utils/isPresent'
import DropdownServiceFieldComponent from './component'

class DropdownServiceField extends React.Component {
  static propTypes = {
    services: PropTypes.shape().isRequired,
    selectedServices: PropTypes.shape().isRequired,
    searchServices: PropTypes.func.isRequired,
    excludeServices: PropTypes.arrayOf(PropTypes.string),
    userProfileId: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.string),
    customService: PropTypes.shape(),
    form: PropTypes.shape({
      values: PropTypes.shape({
        services: PropTypes.arrayOf(PropTypes.shape()),
      }).isRequired,
    }),
  }

  static defaultProps = {
    excludeServices: null,
    form: null,
    userProfileId: undefined,
    statuses: [],
    customService: undefined,
  }

  state = {
    isOpen: false,
    searchQuery: '',
  }

  get customServiceGroup() {
    const { customService } = this.props

    return customService ? { noService: [customService] } : {}
  }

  get servicesOptions() {
    const { services, selectedServices, form } = this.props
    const { isOpen } = this.state
    const defaultServices = {
      default: form?.values.services?.map(({ service, ...rest }) => ({
        ...rest,
        id: service,
      })),
    }
    const preSelectedServices =
      isPresent(selectedServices) || !isPresent(form?.values.services) ? selectedServices : defaultServices

    return isOpen ? services : { ...preSelectedServices, ...this.customServiceGroup }
  }

  debouncedSearchServices = debounce(() => {
    const { searchServices, excludeServices, userProfileId, statuses } = this.props
    const { searchQuery } = this.state

    searchServices({ query: searchQuery, exclude: excludeServices, userProfileId, statuses, resetResults: true })
  }, 250)

  handleSearch = searchQuery => {
    this.setState(
      {
        isOpen: true,
        searchQuery,
      },
      this.debouncedSearchServices,
    )
  }

  handleDropdownVisibleChange = open => {
    const { searchServices, excludeServices, userProfileId, statuses } = this.props

    if (open) {
      searchServices({ exclude: excludeServices, userProfileId, statuses, resetResults: true })
    }

    this.setState({ isOpen: open })
  }

  render() {
    return (
      <DropdownServiceFieldComponent
        {...this.props}
        {...this.state}
        servicesOptions={this.servicesOptions}
        handleSearch={this.handleSearch}
        handleDropdownVisibleChange={this.handleDropdownVisibleChange}
      />
    )
  }
}

const mapStateToProps = (state, { field }) => ({
  isLoading: loadingSelector(state, fetchServicesEndpoint.endpoint),
  services: searchedServicesSelector(state),
  selectedServices: selectedProvidedServicesSelector(state, field.value),
})

const mapDispatchToProps = {
  searchServices: searchServicesAction,
}

export { DropdownServiceField as DropdownServiceFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DropdownServiceField)
