import { uniq, without } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.ADD_WAITLIST_REQUEST_IDS:
      return uniq([...state, ...action.ids])
    case types.RESET_WAITLIST_REQUEST_IDS:
      return []
    case types.REMOVE_WAITLIST_REQUEST:
      return without(action.ids, state)
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

export const filtersInitialState = {
  employees: [],
  clients: [],
  services: [],
  locations: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isBlankState,
  filters,
})
