import * as types from './types'

export const fetchTrashedFiles = (pageParams = {}, options = {}) => ({
  type: types.FETCH_TRASHED_FILES,
  pageParams,
  options,
})

export const filterTrashedFiles = (filters = {}) => ({
  type: types.FILTER_TRASHED_FILES,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const addTrashedFileIds = ids => ({
  type: types.ADD_TRASHED_FILE_IDS,
  ids,
})

export const setTrashedFileIds = ids => ({
  type: types.SET_TRASHED_FILE_IDS,
  ids,
})

export const removeTrashedFileIds = ids => ({
  type: types.REMOVE_TRASHED_FILE_IDS,
  ids,
})

export const resetTrashedFileIds = () => ({
  type: types.RESET_TRASHED_FILE_IDS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const setSelectedIds = trashedFileIds => ({
  type: types.SET_SELECTED_IDS,
  trashedFileIds,
})

export const restoreTrashedFiles = (trashedFileIds, onNextSlide) => ({
  type: types.RESTORE_TRASHED_FILES,
  trashedFileIds,
  onNextSlide,
})

export const emptyTrash = () => ({
  type: types.EMPTY_TRASH,
})

export const deleteTrashedFiles = (ids, onNextSlide) => ({
  type: types.DELETE_TRASHED_FILES,
  ids,
  onNextSlide,
})

export const receiveRestoredFiles = data => ({
  type: types.RECEIVE_RESTORED_FILES,
  data,
})
