import { createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux'

// eslint-disable-next-line import/prefer-default-export
export const deepCreateSelector = createSelectorCreator({
  memoize: lruMemoize,
  memoizeOptions: {
    resultEqualityCheck: shallowEqual,
  },
})
