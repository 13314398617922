import PropTypes from 'prop-types'
import { Modal as AntdModal } from 'antd'

const Modal = ({ children, visible, footer, closable, onClose, className, centered, transitionName, ...props }) => (
  <AntdModal
    transitionName={transitionName}
    open={visible}
    footer={footer}
    closable={closable}
    onCancel={onClose}
    className={className}
    centered={centered}
    styles={{
      body: {
        padding: 0,
      },
    }}
    style={{
      width: '100px',
    }}
    {...props}
  >
    {children}
  </AntdModal>
)

Modal.defaultProps = {
  visible: true,
  centered: true,
  footer: null,
  closable: false,
  onClose: undefined,
  className: null,
  transitionName: '',
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node).isRequired, PropTypes.node]).isRequired,
  centered: PropTypes.bool,
  visible: PropTypes.bool,
  footer: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  transitionName: PropTypes.string,
}

export default Modal
