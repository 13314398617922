import PropTypes from 'prop-types'
import Image from 'next/legacy/image'
import classNames from 'clsx'
import { prop } from 'ramda'

import logo from 'assets/images/logo.svg'
import logoMobile from 'assets/images/logo-mob.svg'
import Link from 'views/shared/Link'

const Logo = ({ urlOptions, displayGrid }) => (
  <Link {...urlOptions} isFullLink>
    <a className="main-header__logo" target={prop('target', urlOptions)}>
      <span className={classNames('align-items-center d-none', `d-${displayGrid}-flex`)}>
        <Image src={logo} alt="ExpertBox" />
      </span>
      <span className={classNames('align-items-center d-flex', `d-${displayGrid}-none`)}>
        <Image src={logoMobile} alt="ExpertBox" />
      </span>
    </a>
  </Link>
)

Logo.defaultProps = {
  displayGrid: 'md',
}

Logo.propTypes = {
  urlOptions: PropTypes.shape().isRequired,
  displayGrid: PropTypes.string,
}

export default Logo
