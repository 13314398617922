import { createLogic } from 'redux-logic'

import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataRemoveRelationship } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { REMOVE_VIDEO_RECORD } from 'state/concepts/booking/types'
import { removeVideoRecordEndpoint } from '../endpoints'

const removeVideoRecordOperation = createLogic({
  type: REMOVE_VIDEO_RECORD,
  latest: true,

  async process({ httpClient, action: { videoRecordId, bookingId } }, dispatch, done) {
    const { url, endpoint } = removeVideoRecordEndpoint(videoRecordId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(
        dataRemoveRelationship({
          kind: 'booking',
          ownerId: bookingId,
          relationship: 'videoRecords',
          ids: [videoRecordId],
        }),
      )
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.recordingWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeVideoRecordOperation
