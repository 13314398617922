import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchClientContactEndpoint } from '../endpoints'
import { FETCH_CLIENT_CONTACT } from '../types'

const fetchClientContactOperation = createLogic({
  type: FETCH_CLIENT_CONTACT,
  latest: true,

  async process({ httpClient, action: { contactId, workspaceCode } }, dispatch, done) {
    const { url, endpoint } = fetchClientContactEndpoint(contactId)

    const config = workspaceCode ? buildCustomBaseUrl(['workspaces', workspaceCode]) : {}

    const params = {
      ...config,
      include: ['contact_client_profile', 'client_profile', 'client_relationship_type'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchClientContactOperation
