import React from 'react'
import PropTypes from 'prop-types'

const Google = ({ size, color, className, dataCy }) => (
  <svg width={`${size}px`} height={`${size}px`} className={className} fill={color} viewBox="0 0 16 16" data-cy={dataCy}>
    <path
      d="M15.6277488,8.15051982 C15.6277488,7.49494077 15.5745525,7.0165403 15.4594261,6.52042807 L7.97334125,6.52042807 L7.97334125,9.47938968 L12.3675043,9.47938968 C12.2789455,10.2147327 11.800545,11.3221446 10.7374125,12.0662824 L10.7225102,12.165346 L13.0894718,13.9990008 L13.2534582,14.0153689 C14.7595066,12.6244468 15.6277488,10.5779457 15.6277488,8.15051983"
      id="Path"
      fill="#4285F4"
      fillRule="nonzero"
    />
    <path
      d="M7.97334125,15.9466825 C10.1261129,15.9466825 11.9333832,15.2379071 13.2534582,14.0153689 L10.7374125,12.0662824 C10.0641217,12.535827 9.16045603,12.8636165 7.97334125,12.8636165 C5.86484903,12.8636165 4.07529045,11.4727555 3.43736208,9.55029775 L3.34385625,9.5582375 L0.8826559,11.4629835 L0.850469375,11.5524584 C2.16162748,14.1570629 4.85485175,15.9466825 7.97334125,15.9466825"
      id="Path"
      fill="#34A853"
      fillRule="nonzero"
    />
    <path
      d="M3.43736207,9.55029775 C3.26903937,9.05418553 3.17162475,8.52258873 3.17162475,7.97334125 C3.17162475,7.4240327 3.26903937,6.89249698 3.4285062,6.39638475 L3.42404772,6.290725 L0.9320045,4.3553804 L0.850469375,4.39416303 C0.310077775,5.4750073 -1.09245946e-13,6.68875078 -1.09245946e-13,7.97334125 C-1.09245946e-13,9.25793173 0.310077775,10.4716141 0.850469375,11.5524584 L3.43736207,9.55029775"
      id="Path"
      fill="#FBBC05"
      fillRule="nonzero"
    />
    <path
      d="M7.97334125,3.08300493 C9.4705338,3.08300493 10.48047,3.7297281 11.0563462,4.27018078 L13.3065935,2.07306873 C11.9245884,0.78847825 10.1261129,1.82076576e-14 7.97334125,1.82076576e-14 C4.85485175,1.82076576e-14 2.16162748,1.78955858 0.850469375,4.39416303 L3.4285062,6.39638475 C4.07529045,4.47392698 5.86484903,3.08300493 7.97334125,3.08300493"
      id="Path"
      fill="#EB4335"
      fillRule="nonzero"
    />
  </svg>
)

Google.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Google.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Google
