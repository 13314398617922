const tasks = {
  'tasks.title': 'Tasks',
  'tasks.noTasksYet': 'No tasks yet',
  'tasks.noTasksYet.description': 'Add tasks to manage your work and keep track of the deadlines.',
  'tasks.addTask': 'Add task',
  'tasks.assignedTo': 'Assigned to:',
  'tasks.unassigned': 'Unassigned',
  'tasks.createdBy': 'Created by:',
  'tasks.deleteModal.title': 'Delete task?',
  'tasks.deleteModal.body': 'Delete this task? Once deleted, it cannot be restored.',
  'tasks.filters.status.todo': 'To do',
  'tasks.filters.status.completed': 'Completed',
  'tasks.assignedToYou': '{label} <span>(Assigned to you)</span>',
  'tasks.noResults': 'No results',
  'tasks.adjustSearch': 'Try adjusting your search or filters',
  'tasks.noSearchResult.assignee': 'No open tasks for {name}',
  'tasks.noSearchResult.assignees': 'No open tasks for selected _(experts)',
  'tasks.seeAllTasks': 'See all tasks',
  'tasks.createTask.name.placeholder': 'Write your task here and hit enter',
  'tasks.createTask.assignExpert.placeholder': 'Assign to your team',
  'tasks.createTask.assignExpert.assignToYou': '(Assign to you)',
  'tasks.createTask.assignExpert.value': 'Assigned to: <span>{employee}</span>',
  'tasks.createTask.linkToClient': 'Link to _(client)',
  'tasks.type.upcoming': 'Upcoming',
  'tasks.type.overdue': 'Overdue',
  'tasks.type.noDue': 'No due date',
  'tasks.listView': 'List view',
  'tasks.dateView': 'Date view',
  'tasks.allDone.title': 'All done!',
  'tasks.allDone.description': 'Enjoy your day or add a new task.',
  'tasks.markAsToDo': 'Mark as to do',
  'tasks.markComplete': 'Mark complete',
}

export default tasks
