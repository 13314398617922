import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Text = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M0.11 0.124v227.501h113.75v-113.75h284.375v796.251h-113.75v113.75h341.25v-113.75h-113.75v-796.251h284.375v113.75h113.75v-227.501h-910.001z" />
  </svg>
)

Text.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Text.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Text
