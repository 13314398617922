import React from 'react'
import PropTypes from 'prop-types'
import { filter, indexOf, toLower, trim } from 'ramda'

import ClientTimezoneSelectComponent from './component'

class ClientTimezoneSelect extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  state = {
    options: this.props.options,
  }

  componentDidUpdate({ options: prevOptions }) {
    const { options } = this.props
    if (prevOptions.length !== options.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ options })
    }
  }

  filterTimezones = ({ name }) => {
    const { options } = this.props
    this.setState({
      options: filter(({ label }) => indexOf(toLower(trim(name)), toLower(label)) !== -1, options),
    })
  }

  render() {
    return <ClientTimezoneSelectComponent {...this.props} {...this.state} filterTimezones={this.filterTimezones} />
  }
}

export default ClientTimezoneSelect
