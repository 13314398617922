import { pipe, map, path, uniq } from 'ramda'
import { max, min } from 'lodash'

import { WEEKDAYS_MESSAGES, WEEKDAYS_MAPPING } from 'lib/constants/calendar'
import { slotStartTime, slotEndTime, getWeekDay } from 'lib/calendar/calendarMapping'
import { formattedIntervalFromSeconds } from 'utils/dateTime'

export const selectedDays = (selectedCells, daysMapping, isRecurring) =>
  pipe(
    map(({ props: { dayIndex } }) => {
      const index = isRecurring ? dayIndex : getWeekDay(daysMapping[dayIndex])
      const dayOfWeek = WEEKDAYS_MAPPING[index]

      return WEEKDAYS_MESSAGES[dayOfWeek]
    }),
    uniq,
  )(selectedCells)

export const selectedInterval = (selectedCells, step) => {
  const slotIndexes = map(path(['props', 'slotIndex']))(selectedCells)

  const start = slotStartTime(min(slotIndexes), step)
  const end = slotEndTime(max(slotIndexes), step)

  return formattedIntervalFromSeconds(start, end)
}
