const locations = {
  'locations.createLocation': 'Create location',
  'locations.addLocation': 'Add location',
  'locations.empty.title': 'No locations yet',
  'locations.empty.description': 'Add one or multiple locations to have _(appointments) in person.',
  'locations.table.header.name': 'Location name',
  'locations.table.header.address': 'Address',
  'locations.table.header.status': 'Status',
  'locations.table.column.status.active': 'Active',
  'locations.table.column.status.inactive': 'Deactivated',
  'locations.search.placeholder': 'Search by name or address',
  'locations.emptyState.description': 'There are no results for your search request.',

  'locations.activation.confirmModal.title': 'Activate this location?',
  'locations.activation.confirmModal.body':
    '_(Experts) and _(services) will be available for booking at this location. This won’t affect any of _(appointments) you already have. ',
  'locations.deactivation.confirmModal.title': 'Deactivate this location?',
  'locations.deactivation.confirmModal.body.part_one':
    'This will remove _(experts)’ availability from this location, and neither you nor _(clients) won’t be able to book _(appointments) there.',
  'locations.deactivation.confirmModal.body.part_two': 'This won’t affect any of _(appointments) you already have.',
  'locations.deactivation.confirmModal.errorAlert': `The last location couldn't be deactivated. Create another location or enable "Accept Video Calls" feature to provide services online.`,

  'locations.create.nameField.label': 'Location name',
  'locations.create.nameField.placeholder': 'Enter location name',
  'locations.create.status': 'Location status',
  'locations.create.status.description':
    'Active locations will be visible for _(clients). You can deactivate location anytime to hide it for _(clients).',
  'locations.create.status.activated': 'Activated',
  'locations.create.status.deactivated': 'Deactivated',
  'locations.create.status.alertText': `The last location couldn't be deactivated.`,
  'locations.create.addressField.label': 'Address line',
  'locations.create.addressField.placeholder': 'Find address',
  'locations.delete.modal.title': 'Delete this location?',
  'locations.delete.modal.subtitle': 'Availability and _(service) actions',
  'locations.delete.modal.text': 'What do you want to do with availability and _(services) at this location?',
  'locations.delete.modal.alert': 'This won’t affect any of the _(appointments) booked previously.',
  'locations.delete.modal.errorAlert': `The last location couldn't be deleted. Create another location or enable "Accept Video Calls" feature to provide services online.`,
  'locations.delete.modal.deleteLocation': 'Delete location',
  'locations.delete.modal.actionField.remove': 'Remove all availability and _(services) from this location',
  'locations.delete.modal.actionField.move': 'Move to another location',
  'locations.delete.modal.newLocation': 'New location',
  'locations.delete.modal.selectLocation': 'Select location',
  'locations.filter.online': 'Online _(appointments)',
  'locations.filter.offline': 'Offline locations',
  'locations.create': 'Create location',

  'locations.dropdown.emptyState.title': 'You have no offline locations. To add one, go to',
  'locations.dropdown.emptyState.link': 'Location settings.',
}

export default locations
