import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ArrowDown = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M511.996 597.329l398.226-398.22 113.777 113.778-512.003 512.004-511.997-512.004 113.778-113.778 398.219 398.22z" />
  </svg>
)

ArrowDown.defaultProps = {
  size: 12,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ArrowDown.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ArrowDown
