import * as types from './types'

export const fetchClientBookings = clientId => ({
  type: types.FETCH_CLIENT_BOOKINGS,
  clientId,
})

export const resetClientBookings = () => ({
  type: types.RESET_CLIENT_BOOKINGS,
})

export const setClientBookings = clientBookingsIds => ({
  type: types.SET_CLIENT_BOOKINGS,
  clientBookingsIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_CLIENT_BOOKINGS_PAGE,
  pageNumber,
})

export const removeClientBookings = ids => ({
  type: types.REMOVE_CLIENT_BOOKINGS,
  ids,
})
