import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_WIDGET_SETTINGS } from 'state/concepts/widget/types'
import { validateWidgetSettingsEndpoint } from 'state/concepts/widget/endpoints'

const validateWidgetSettingsOperation = createLogic({
  type: VALIDATE_WIDGET_SETTINGS,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { form, values, onNextStep } = action
    const { url } = validateWidgetSettingsEndpoint

    try {
      const params = {
        name: values.name,
        url_slug: values.urlSlug,
        color: values.color,
      }

      await httpClient.post(url, params)

      onNextStep()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default validateWidgetSettingsOperation
