import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { PENDING_BOOKINGS, BOOKINGS_TYPES } from 'lib/constants/clientAccountBookings'
import {
  SET_BOOKINGS,
  RESET_BOOKINGS,
  SET_SORT_ORDER,
  SET_FILTER_PARAMS,
  REMOVE_BOOKINGS,
  SET_PENDING_BOOKINGS_COUNT,
  DECREMENT_PENDING_BOOKINGS_COUNT,
} from './types'

const clientAccountBookingsIds = (state = [], action) => {
  switch (action.type) {
    case SET_BOOKINGS:
      return uniq([...state, ...action.clientAccountBookingsIds])
    case RESET_BOOKINGS:
      return []
    case REMOVE_BOOKINGS:
      return without(action.bookingIds, state)
    default:
      return state
  }
}

const sort = (state = { sortKey: 'start_time', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_SORT_ORDER:
      return { ...state, ...action.sortOrder }
    default:
      return state
  }
}

const filtersInitialState = {
  past: false,
  timezone: null,
  statuses: PENDING_BOOKINGS,
  bookingsType: BOOKINGS_TYPES.pending,
  employees: [],
  services: [],
}

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const pendingBookingsCount = (state = 0, action) => {
  switch (action.type) {
    case SET_PENDING_BOOKINGS_COUNT:
      return action.count
    case DECREMENT_PENDING_BOOKINGS_COUNT:
      return state > 0 ? state - action.amount : 0
    default:
      return state
  }
}

export default combineReducers({
  clientAccountBookingsIds,
  pendingBookingsCount,
  sort,
  filters,
})
