import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { deleteClientAccountReviewEndpoint } from 'state/concepts/clientAccountReview/endpoints'
import { DELETE_REVIEW } from 'state/concepts/clientAccountReview/types'
import { dataApiRequest, dataApiSuccess, dataClearRelationship } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'

const deleteClientAccountReviews = createLogic({
  type: DELETE_REVIEW,
  latest: true,

  async process({ httpClient, action: { reviewId, bookingId } }, dispatch, done) {
    const { endpoint, url } = deleteClientAccountReviewEndpoint(reviewId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)

      dispatch(
        dataClearRelationship({
          kind: 'booking',
          ids: [bookingId],
          relationship: 'review',
        }),
      )

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.reviewDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteClientAccountReviews
