import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchWaitlistRequestEndpoint } from 'state/concepts/waitlistRequests/endpoints'
import { FETCH_WAITLIST_REQUEST } from '../types'

const fetchWaitlistRequestOperation = createLogic({
  type: FETCH_WAITLIST_REQUEST,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { id } = action
    const { url, endpoint } = fetchWaitlistRequestEndpoint(id)
    const params = {
      include: [
        'user-profile',
        'client-profile',
        'location',
        'service',
        'first-preference',
        'availability-preferences',
        'created-by',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchWaitlistRequestOperation
