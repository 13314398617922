import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import emailPlaceholder from 'assets/images/email-placeholder-fill.svg'

const PendingClientProfile = ({ clientProfile }) => (
  <>
    <span className="main-userpic main-userpic--xs mr-12">
      <Image src={emailPlaceholder} alt="" layout="fill" objectFit="cover" />
    </span>
    <span>{clientProfile.email}</span>
  </>
)

PendingClientProfile.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
}

export default PendingClientProfile
