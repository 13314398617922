import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { rescheduleBookingEndpoint } from 'state/concepts/booking/endpoints'
import { RESCHEDULE_BOOKING } from 'state/concepts/guestBookings/types'

const clientRescheduleBookingOperation = createLogic({
  type: RESCHEDULE_BOOKING,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess } }, dispatch, done) {
    const { uniqCode, timezone, startTime, rescheduleReason } = values
    const { endpoint, url } = rescheduleBookingEndpoint(uniqCode)

    const params = {
      reschedule_reason: rescheduleReason,
      start_time: DateTime.fromISO(startTime).toISO(),
      client_timezone: timezone,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))

      onSuccess()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default clientRescheduleBookingOperation
