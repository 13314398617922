const namespace = 'employee'

export const GET_EMPLOYEE_OWN_PROFILE = `${namespace}/GET_EMPLOYEE_OWN_PROFILE`
export const UPDATE_EMPLOYEE_ACCOUNT_GENERAL = `${namespace}/UPDATE_EMPLOYEE_ACCOUNT_GENERAL`
export const UPDATE_EMPLOYEE_ACCOUNT_SECURITY = `${namespace}/UPDATE_EMPLOYEE_ACCOUNT_SECURITY`
export const VALIDATE_INVITATION_TOKEN = `${namespace}/VALIDATE_INVITATION_TOKEN`
export const SET_EMPLOYEE_INVITATION_ID = `${namespace}/SET_EMPLOYEE_INVITATION_ID`
export const SIGNUP_FROM_INVITATION = `${namespace}/SIGNUP_FROM_INVITATION`
export const SUBMIT_ONBOARDING_GENERAL = `${namespace}/SUBMIT_ONBOARDING_GENERAL`
export const SUBMIT_ONBOARDING_COMPANY_PROFILE = `${namespace}/SUBMIT_ONBOARDING_COMPANY_PROFILE`
export const GET_EMPLOYEE_ONBOARDING = `${namespace}/GET_EMPLOYEE_ONBOARDING`
export const CONFIRM_EMPLOYEE_EMAIL = `${namespace}/CONFIRM_EMPLOYEE_EMAIL`
export const UPDATE_ONBOARDING_STEP = `${namespace}/UPDATE_ONBOARDING_STEP`
export const UPDATE_EMPLOYEE_PROFILE = `${namespace}/UPDATE_EMPLOYEE_PROFILE`
export const VALIDATE_CONFIRMATION_TOKEN = `${namespace}/VALIDATE_CONFIRMATION_TOKEN`
export const UPDATE_LOGOUT_INTERVAL = `${namespace}/UPDATE_LOGOUT_INTERVAL`
export const RESET_PASSWORD_REQUEST_LOGIN = `${namespace}/RESET_PASSWORD_REQUEST_LOGIN`
export const RESET_PASSWORD_REQUEST_ACCOUNT = `${namespace}/RESET_PASSWORD_REQUEST_ACCOUNT`
export const RESET_PASSWORD_CONFIRM = `${namespace}/RESET_PASSWORD_CONFIRM`
export const VALIDATE_RESET_PASSWORD_TOKEN = `${namespace}/VALIDATE_RESET_PASSWORD_TOKEN`
export const RESEND_EMAIL_CONFIRMATION = `${namespace}/RESEND_EMAIL_CONFIRMATION`
export const RESEND_EMAIL_CHANGE_CONFIRMATION = `${namespace}/RESEND_EMAIL_CHANGE_CONFIRMATION`
export const REMIND_WORKSPACES_REQUEST = `${namespace}/REMIND_WORKSPACES_REQUEST`
export const SET_REMIND_WORKSPACES = `${namespace}/SET_REMIND_WORKSPACES`
export const FETCH_REMIND_WORKSPACES = `${namespace}/FETCH_REMIND_WORKSPACES`
export const UPDATE_EMPLOYEE_PASSWORD = `${namespace}/UPDATE_EMPLOYEE_PASSWORD`
export const VALIDATE_ACCESS_TO_CLIENT = `${namespace}/VALIDATE_ACCESS_TO_CLIENT`
export const VALIDATE_PASSWORD_ON_TWO_FACTOR = `${namespace}/VALIDATE_PASSWORD_ON_TWO_FACTOR`
export const ENABLE_TWO_FACTOR_SETTINGS = `${namespace}/ENABLE_TWO_FACTOR_SETTINGS`
export const DISABLE_TWO_FACTOR_SETTINGS = `${namespace}/DISABLE_TWO_FACTOR_SETTINGS`
export const GET_OTP_CODE_BY_SMS = `${namespace}/GET_OTP_CODE_BY_SMS`
export const GET_APP_AUTH_URI = `${namespace}/GET_APP_AUTH_URI`
export const UPDATE_TWO_FACTOR_SETTINGS = `${namespace}/UPDATE_TWO_FACTOR_SETTINGS`
export const GENERATE_BACKUP_CODES = `${namespace}/GENERATE_BACKUP_CODES`
export const UPDATE_CALENDAR_VIEW_SETTING = `${namespace}/UPDATE_CALENDAR_VIEW_SETTING`
export const SET_EMPLOYEE_ID = `${namespace}/SET_EMPLOYEE_ID`
