import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const OptionsVertical = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256 398.222c31.418 0 56.889 25.47 56.889 56.889S287.419 512 256 512c-31.418 0-56.889-25.47-56.889-56.889s25.47-56.889 56.889-56.889zm0-199.111c31.418 0 56.889 25.47 56.889 56.889s-25.47 56.889-56.889 56.889c-31.418 0-56.889-25.47-56.889-56.889s25.47-56.889 56.889-56.889zM256-.001c31.418 0 56.889 25.47 56.889 56.889s-25.47 56.889-56.889 56.889c-31.418 0-56.889-25.47-56.889-56.889S224.581-.001 256-.001z" />
  </svg>
)

OptionsVertical.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

OptionsVertical.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default OptionsVertical
