import React from 'react'
import PropTypes from 'prop-types'

import { isPDF, isVideo, isPicture } from 'utils/file'

import Pdf from 'views/shared/icons/Pdf'
import ImageIcon from 'views/shared/icons/ImageIcon'
import VideoIcon from 'views/shared/icons/VideoIcon'
import DocIcon from 'views/shared/icons/DocIcon'

const FileIcon = ({ mimeType, className, size, dataCy }) => {
  if (isPDF(mimeType)) {
    return <Pdf className={className} size={size} dataCy={dataCy} />
  }

  if (isVideo(mimeType)) {
    return <VideoIcon className={className} size={size} dataCy={dataCy} />
  }

  if (isPicture(mimeType)) {
    return <ImageIcon className={className} size={size} dataCy={dataCy} />
  }

  return <DocIcon className={className} size={size} dataCy={dataCy} />
}

FileIcon.defaultProps = {
  className: '',
  size: 20,
  dataCy: undefined,
}

FileIcon.propTypes = {
  mimeType: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  dataCy: PropTypes.string,
}

export default FileIcon
