import { FormattedMessage } from 'react-intl'
import Image from 'next/legacy/image'

import errorImg from 'assets/images/errors/ill-500-error.svg'
import ErrorLayout from 'views/layouts/Error'

const ServerError = () => (
  <ErrorLayout>
    <div className="row align-items-md-center justify-content-center">
      <div className="col-sm-8 col-md-5" data-cy="error-page-info">
        <div className="error-page__info">
          <div className="error-page-title" data-cy="error-page-title">
            <FormattedMessage id="serverErrorPage.title" />
          </div>
          <p className="error-page-txt text-subheader mb-0" data-cy="error-page-body">
            <FormattedMessage id="serverErrorPage.body" />
          </p>
        </div>
      </div>
      <div className="col-sm-8 col-md-5">
        <div className="error-page-img">
          <Image src={errorImg} alt="Server error" layout="fill" data-cy="error-page-img" />
        </div>
      </div>
    </div>
  </ErrorLayout>
)

export default ServerError
