const handleDownload = fileUrl => {
  const link = document.createElement('a')
  link.href = fileUrl
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default handleDownload
