import { createLogic } from 'redux-logic'

import { SET_REVIEWS_FILTER } from 'state/concepts/reviews/types'
import { setFilterParams, setCurrentPage, fetchReviews, resetReviews } from 'state/concepts/reviews/actions'

const filterReviewsOperation = createLogic({
  type: SET_REVIEWS_FILTER,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(resetReviews())
    dispatch(fetchReviews())
    done()
  },
})

export default filterReviewsOperation
