import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { ALERT_KINDS } from 'lib/constants'
import { updateSidebar } from 'state/sidebar/actions'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Tooltip from 'views/shared/Tooltip'
import CrossIcon from 'views/shared/icons/Cross'
import ClientSelect from '../ClientSelect'
import DateTime from '../DateTime'
import ExpertSelect from '../ExpertSelect'
import InviteMembers from '../InviteMembers'
import LocationSelect from '../LocationSelect'
import Price from '../Price'
import ServiceSelect from '../ServiceSelect'

const TooltipFormComponent = ({
  onClose,
  handleSubmit,
  handleSubmitAndAddNew,
  values: { serviceId, userProfileId, isFreePrice, price },
  values,
  setFieldValue,
  status,
  isEdit,
  id: bookingId,
  customService,
  customServiceSelected,
  showClientForm,
  showLocationsSelect,
  invitedMembers,
  isWaitlist,
  availabilityPreferences,
}) => {
  const updateSidebarProps = useDispatchAction(updateSidebar)
  useEffect(() => {
    updateSidebarProps({ sidebarProps: { userProfileId, resourceId: userProfileId } })
  }, [updateSidebarProps, userProfileId])

  return (
    <Tooltip open overlayClassName="booking-tooltip-wrapper">
      <section className="booking-tooltip booking-tooltip-with-header">
        <div className="booking-tooltip__toolbar">
          <h3 className="booking-tooltip__info-title" data-cy="tooltip-title">
            <FormattedMessage id={isEdit ? 'scheduleAppointment.editTitle' : 'scheduleAppointment.title'} />
          </h3>
          <div>
            <button className="in-gray-600" type="button" onClick={onClose}>
              <CrossIcon size={10} className="va-middle" />
            </button>
          </div>
        </div>
        <div className="booking-tooltip__body">
          {isEdit && (
            <Alert
              className="auth__alert ml-24 mr-24"
              type={ALERT_KINDS.info}
              message={{
                id: invitedMembers?.length
                  ? 'scheduleAppointment.edit.info.withInviteMembers'
                  : 'scheduleAppointment.edit.info',
              }}
            />
          )}
          <ServiceSelect
            userProfileId={userProfileId}
            customService={customService}
            customServiceSelected={customServiceSelected}
            isWaitlist={isWaitlist}
          />
          <ExpertSelect serviceId={serviceId} setFieldValue={setFieldValue} />
          <Price
            serviceId={serviceId}
            userProfileId={userProfileId}
            isFreePrice={isFreePrice}
            price={price}
            setFieldValue={setFieldValue}
          />
          <DateTime
            bookingId={bookingId}
            status={status}
            values={values}
            setFieldValue={setFieldValue}
            showRepeat={!isEdit}
            isTooltip
            isWaitlist={isWaitlist}
            availabilityPreferences={availabilityPreferences}
          />
          {showLocationsSelect && <LocationSelect />}
          <ClientSelect isEdit={isEdit} showClientForm={showClientForm} isWaitlist={isWaitlist} />
          <InviteMembers invitedMembers={invitedMembers} isEdit={isEdit} />
        </div>

        <div className="booking-tooltip__btns">
          <Button className="mr-16 mr-xs-4" kind="flat" text={{ id: 'shared.cancel' }} onClick={onClose} />
          {!isEdit && (
            <SubmitFormButton
              className="mr-16 mr-xs-4"
              text={{ id: 'scheduleAppointment.saveAndAddNew' }}
              type="submit"
              kind="secondary"
              onClick={handleSubmitAndAddNew}
              allowPristine
            />
          )}
          <SubmitFormButton
            text={{ id: isEdit ? 'shared.saveChanges' : 'shared.save' }}
            type="submit"
            onClick={handleSubmit}
            allowPristine={isWaitlist}
          />
        </div>
      </section>
    </Tooltip>
  )
}

TooltipFormComponent.defaultProps = {
  status: undefined,
  isEdit: false,
  id: undefined,
  handleSubmitAndAddNew: undefined,
  customService: undefined,
  invitedMembers: [],
  isWaitlist: false,
  availabilityPreferences: undefined,
}

TooltipFormComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customServiceSelected: PropTypes.bool.isRequired,
  showClientForm: PropTypes.bool.isRequired,
  showLocationsSelect: PropTypes.bool.isRequired,
  handleSubmitAndAddNew: PropTypes.func,
  status: PropTypes.shape(),
  isEdit: PropTypes.bool,
  id: PropTypes.string,
  customService: PropTypes.shape(),
  invitedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    }),
  ),
  isWaitlist: PropTypes.bool,
  availabilityPreferences: PropTypes.arrayOf(PropTypes.shape()),
}

export default TooltipFormComponent
