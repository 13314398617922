import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setNotificationSettingId } from 'state/concepts/client/actions'
import { FETCH_NOTIFICATION_SETTINGS } from 'state/concepts/client/types'
import { fetchNotificationSettingsEndpoint } from 'state/concepts/client/endpoints'

const fetchNotificationSettings = createLogic({
  type: FETCH_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchNotificationSettingsEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params: { include: 'sms-notification-setting' } })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setNotificationSettingId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchNotificationSettings
