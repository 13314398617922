import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { RENEW_SUBSCRIPTION } from 'state/concepts/billing/types'
import { renewSubscriptionEndpoint } from 'state/concepts/billing/endpoints'

const renewSubscriptionOperation = createLogic({
  type: RENEW_SUBSCRIPTION,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = renewSubscriptionEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, { include: 'billing-account,workspace.current-billing-period' })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors

        dispatch(dataApiFailure({ endpoint }))
        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default renewSubscriptionOperation
