import React from 'react'

import PreviewPdfComponent from './component'

class PreviewPdf extends React.Component {
  state = {
    pageCount: 0,
  }

  handleLoadSuccess = ({ numPages }) => this.setState({ pageCount: numPages })

  render() {
    return <PreviewPdfComponent {...this.props} {...this.state} onLoadSuccess={this.handleLoadSuccess} />
  }
}

export default PreviewPdf
