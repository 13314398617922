import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { removeServices } from 'state/concepts/services/actions'
import { removeServicesLoadingSelector } from 'state/concepts/services/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServicesRemoveModal = ({ service: { id, name }, onClose }) => {
  const isLoading = useSelector(removeServicesLoadingSelector)
  const handleRemoveService = useDispatchAction(removeServices, [id])

  return (
    <ConfirmModal
      title={{ id: 'servicesRemoveModal.title' }}
      bodyText={[
        [
          { id: 'servicesRemoveModal.body.start' },
          {
            id: 'servicesRemoveModal.body.boldText',
            values: { name },
            isBold: true,
          },
          { id: 'servicesRemoveModal.body.final' },
        ],
        {
          id: 'servicesRemoveModal.warning',
        },
      ]}
      submitText={{ id: 'servicesActions.remove' }}
      kind="danger"
      onClose={onClose}
      onSubmit={handleRemoveService}
      isLoading={isLoading}
    />
  )
}

ServicesRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ServicesRemoveModal
