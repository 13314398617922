import normalize from 'json-api-normalizer'
import { head, pluck } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import { setVideoConference, setVideoRecordEvents } from 'state/concepts/videoConference/actions'
import { fetchVideoConferenceEndpoint } from 'state/concepts/videoConference/endpoints'
import { FETCH_VIDEO_CONFERENCE_DETAILS } from 'state/concepts/videoConference/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isPresent from 'utils/isPresent'
import redirect from 'utils/redirect'
import { workspacifyClientProfile } from 'utils/videoConference'

const fetchVideoConferenceDetails = createLogic({
  type: FETCH_VIDEO_CONFERENCE_DETAILS,
  latest: true,

  async process({ action: { uniqCode }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = fetchVideoConferenceEndpoint(uniqCode)
    dispatch(dataApiRequest({ endpoint }))

    const params = {
      include: [
        'booking.user-profile',
        'booking.client-profile',
        'booking.client-profile-forms',
        'booking.client-profile-forms.form-submission.client-signature',
        'booking.client-profile-forms.form-submission.client-signature',
        'booking.client-profile-forms.form',
        'booking.client-profile.workspace-client-profile',
        'booking.service',
        'booking.workspace',
        'booking.invited-members',
        'booking-setting',
        'video-record-events',
        'participants',
      ],
    }

    try {
      const { data } = await httpClient.get(url, { params, ...buildCustomBaseUrl(['workspaces']) })

      const response = workspacifyClientProfile(currentWorkspaceIdSelector(getState()), normalize(data))
      const { id, videoRecordEvents, providerUrl } = head(build(response, 'videoConference'))

      if (isPresent(providerUrl)) {
        redirect({ href: providerUrl, reload: true })
      } else {
        dispatch(dataApiSuccess({ response, endpoint }))
        dispatch(setVideoConference(id))
        dispatch(setVideoRecordEvents(pluck('id', videoRecordEvents)))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchVideoConferenceDetails
