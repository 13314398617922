import { Rate } from 'antd'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { reviewCreatedAtDay } from 'utils/dateTime'
import profileName from 'utils/profileName'
import ShowLessButton from 'views/Website/ShowLessButton'
import ShowMoreButton from 'views/Website/ShowMoreButton'
import Avatar from 'views/shared/Avatar'
import ShowMoreText from 'views/shared/ShowMoreText/container'
import Service from 'views/shared/icons/Service/component'

const Review = ({ clientProfile, updatedAt, text, service, rating, color }) => (
  <div className="wp-sidebar-review__item">
    <div className="d-flex align-items-center">
      <Avatar avatarClassName="mr-16" sizeClassName="main-userpic" profile={clientProfile} />
      <div className="overflow-x-hidden">
        <p className="mb-0 text-subheader font-600 truncate-text" data-cy="client-name">
          {profileName(clientProfile)}
        </p>
      </div>
    </div>
    <div className="d-flex justify-content-between align-items-center mt-12">
      <div className="d-flex align-items-center">
        <Rate value={rating} disabled className="mr-12 stars-size-subheader" />
        <span className="in-blue-gray-300" data-cy="review-date">
          <FormattedMessage {...reviewCreatedAtDay(updatedAt)} />
        </span>
      </div>
    </div>
    <div className="mt-12 expert-info__gray">
      <Service size={20} color="#AAB3C5" dataCy="service-icon" />
      <span className="ml-12 text-subheader" data-cy="service-name">
        {service.name}
      </span>
    </div>
    {text && (
      <ShowMoreText
        lines={4}
        text={text}
        className="mt-16 mb-0 text-subheader"
        showMoreComponent={ShowMoreButton}
        showLessComponent={ShowLessButton}
        style={{
          color,
        }}
      />
    )}
  </div>
)

Review.defaultProps = {
  text: undefined,
}

Review.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
  service: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  updatedAt: PropTypes.string.isRequired,
  text: PropTypes.string,
  rating: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default Review
