import { isEmpty, not } from 'ramda'

import assignFormErrors from 'utils/form/assignFormErrors'

const makeHandleForm = (form, error, formStatusKeys) => () => {
  if (not(isEmpty(form))) {
    assignFormErrors(form, error, formStatusKeys)
  }
}

export default makeHandleForm
