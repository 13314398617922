import { DateTime } from 'luxon'

import { durationByStartAndEndTime } from 'utils/bookings'

const getBookingToEditProps = props => {
  const { startTime, endTime } = props
  const dateTime = DateTime.fromISO(startTime)
  const duration = durationByStartAndEndTime(startTime, endTime)

  return {
    date: dateTime,
    time: dateTime,
    duration,
    ...props,
  }
}

export default getBookingToEditProps
