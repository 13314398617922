const login = {
  'login.loginToYourAccount': 'Log in to your account',
  'login.email': 'Email',
  'login.verificationCode': 'Verification code',
  'login.verificationCode.didNotGetCode': 'Didn’t get the code?',
  'login.verificationCode.resend': 'Resend code',
  'login.verificationCode.resendAvailableIn': 'Resend available in {remainingTime}',
  'login.verificationCode.lostAccess.email': 'Lost access to your email?',
  'login.verificationCode.lostAccess.sms': 'Lost access to your phone number?',
  'login.verificationCode.lostAccess.app': 'Lost access to your device?',
  'login.withBackupCode': 'Log in with backup code',
  'login.haveProblems': 'Have problems with access to your account?',
  'login.haveProblems.contactOwner': 'Contact your workspace owner to reset two-step verification.',
  'login.haveProblems.contactUs': 'Contact support',
  'login.backupCodes.description':
    'You may have received these codes while enabling two-step verification or generating them manually in the account settings.',
  'login.password': 'Password',
  'login.rememberMe': 'Remember me',
  'login.forgotPassword': 'Forgot password?',
  'login.agreementFooterText': 'By logging in, you agree to our {termsLink} and that you have read our {privacyLink}.',
  'login.agreementTermsAndConditions': 'Terms & Conditions',
  'login.agreementPrivacyPolicy': 'Privacy Policy',
  'login.logIn': 'Log in',
  'login.continue': 'Continue',
  'login.workspaceUrl': 'Workspace URL',
  // eslint-disable-next-line expbox/custom-name-validation
  'login.workspaceUrlPlaceholder': 'e.g., expert-box',
  'login.findWorkspace': 'Find your workspace',
  'login.teamWorkspace': 'Team workspace',
  'login.companyWorkspaceTooltipText':
    'Your workspace URL can only contain lowercase letters, numbers and dashes (and must start with a letter or number)',
  'login.personalWorkspace': 'Personal workspace',
  'login.signUp': 'Sign Up',
  'login.DoNotHaveAccount': 'Don’t have an account?',
  'login.workspaceNotFound': 'There is no workspace with such name',
  'login.isClientLogin': 'Have a booking with _a(Expert)?',
  'login.isEmployeeLogin': '_(Expert) profile?',
  'login.logInHere': 'Log in here',
  'login.loginToExpertboxAccount': 'Log in to ExpertBox account',
  'login.loginToClientAccount': 'Log in to _(Client) account',
  'login.enterCode': 'Enter verification code',
  'login.methodChecker.app':
    'To access your account, please enter the verification code generated by your authentication app.',
  'login.methodChecker.sms': 'We’ve sent a verification code to phone number ending in {last4}.',
  'login.methodChecker.email': 'We’ve sent a verification code to your email {email}.',
  'login.inValidCode': 'The code you entered is not valid.',

  'recentWorkspace.title': 'Recent workspaces:',
  'recentWorkspace.teamWorkspaces': 'Team workspaces:',
  'recentWorkspace.remove': 'Remove from the list',

  'remindWorkspaces.title': 'Find your workspace',
  'remindWorkspaces.info':
    "We'll send you an email to confirm your address and find existing workspaces you've joined or can join",
  'remindWorkspaces.backToLogin': 'Back To Login',
  'remindWorkspaces.continue': 'Continue',
  'remindWorkspaces.checkEmail': 'Check your email',
  'remindWorkspaces.linkSentTo': "We've emailed a special link to:",
  'remindWorkspaces.clickTheLink': 'Click the link to confirm your address and get started.',
  'remindWorkspaces.back': 'Back',
  'remindWorkspaces.tokenStale': 'Remind workspaces link has expired',
  'remindWorkspaces.yourWorkspaces': 'Your workspaces',
  'remindWorkspaces.listOfWorkspaces': 'The list of workspaces which you are member of',
  'remindWorkspaces.teamWorkspaces': 'Team workspaces:',
  'remindWorkspaces.personalWorkspaces': 'Personal workspaces:',
  'remindWorkspaces.open': 'Open',

  'clientLogin.loginToPortal': 'Log in to {name} _(client) portal',
  'clientLogin.loginToContactPortal': 'Log in to {name}`s _(Client) Portal',
  'clientLogin.loginDescription':
    'To keep your account safe, we’ll send a login code to your email to verify it’s you.',
  'clientLogin.emailAddress': 'Email address',
  'clientLogin.submitEmail': 'Get login code',
  'clientLogin.isExpertLogin': '_(Expert) profile?',
  'clientLogin.loginCodeDescription': 'We just sent you a temporary login code. Please check your inbox.',
  'clientLogin.loginCode': 'Login code',
  'clientLogin.loginCode.validation.incorrectCode': 'Incorrect code',
  'clientLogin.loginCode.placeholder': 'Enter login code',
  'clientLogin.submitCode': 'Continue with login code',
  'clientLogin.didNotGetCode': 'Didn’t get the code?',
  'clientLogin.resendCode': 'Resend code',
  'clientLogin.yourClientPortals': 'Your _(client) portals',
  'clientLogin.listPortalsFor': 'The list of _(client) portals for',
  'clientLogin.open': 'Open',
  'clientLogin.restore': 'Restore',
}

export default login
