import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchSubscriptionPlans = () => ({
  type: types.FETCH_SUBSCRIPTION_PLANS,
})

export const setSubscriptionPlans = subscriptionPlansIds => ({
  type: types.SET_SUBSCRIPTION_PLANS,
  subscriptionPlansIds,
})

export const setStoragePlans = storagePlansIds => ({
  type: types.SET_STORAGE_PLANS,
  storagePlansIds,
})

export const fetchChangeSubscriptionDetails = planId => ({
  type: types.FETCH_CHANGE_SUBSCRIPTION_DETAILS,
  planId,
})

export const setChangeSubscriptionDetailsId = changeSubscriptionDetailsId => ({
  type: types.SET_CHANGE_SUBSCRIPTION_DETAILS_ID,
  changeSubscriptionDetailsId,
})

export const updateSubscriptions = (
  values,
  form,
  confirmCardSetup,
  getStripeElement,
  setStripeBaseError,
  payload = {},
) => ({
  type: types.UPDATE_SUBSCRIPTIONS,
  values,
  form,
  confirmCardSetup,
  getStripeElement,
  setStripeBaseError,
  ...payload,
})

export const updateStoragePlan = makeFormSubmitAction(types.UPDATE_STORAGE_PLAN)

export const switchBillingCycle = billingCycleType => ({
  type: types.SWITCH_BILLING_CYCLE,
  billingCycleType,
})

export const cancelSwitchBillingCycle = () => ({
  type: types.CANCEL_SWITCH_BILLING_CYCLE,
})

export const cancelPlanDowngrade = () => ({
  type: types.CANCEL_PLAN_DOWNGRADE,
})

export const downgradeToFree = makeFormSubmitAction(types.DOWNGRADE_TO_FREE)

export const updateStoragePlanSubscription = makeFormSubmitAction(types.UPDATE_STORAGE_PLAN_SUBSCRIPTION)
