const namespace = 'guestBookings'

export const VALIDATE_RESCHEDULE_BOOKING = `${namespace}/VALIDATE_RESCHEDULE_BOOKING`
export const RESCHEDULE_BOOKING = `${namespace}/RESCHEDULE_BOOKING`
export const ACCEPT_BOOKING = `${namespace}/ACCEPT_BOOKING`
export const DECLINE_BOOKING = `${namespace}/DECLINE_BOOKING`
export const DECLINE_ALL_BOOKINGS = `${namespace}/DECLINE_ALL_BOOKINGS`
export const FETCH_PENDING_FORMS = `${namespace}/FETCH_PENDING_FORMS`
export const VALIDATE_CANCEL_BOOKING = `${namespace}/VALIDATE_CANCEL_BOOKING`
export const CANCEL_BOOKING = `${namespace}/CANCEL_BOOKING`
export const FETCH_BOOKING_RECURRENCE = `${namespace}/FETCH_BOOKING_RECURRENCE`
export const BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST = `${namespace}/BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST`
