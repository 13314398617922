import Axios from 'axios'
import qs from 'qs'

import { API_HOST, WIDGET_API_HOST } from 'lib/constants'

const httpClientParams = {
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  },
}

export const buildCustomBaseUrl = (customUrls = []) => {
  const customBaseURL = `${API_HOST}/api/v1`

  if (customUrls.length === 0) {
    return { customBaseURL }
  }

  return { customBaseURL: `${customBaseURL}/${customUrls.reduce((acc, curr) => `${acc}/${curr}`)}` }
}

const buildHttpClient = (baseURL = buildCustomBaseUrl().customBaseURL) =>
  Axios.create({
    baseURL,
    ...httpClientParams,
  })

const workspacesHttpClient = buildHttpClient(buildCustomBaseUrl(['workspaces']).customBaseURL)

const websiteHttpClient = buildHttpClient(`${WIDGET_API_HOST}/api/v1/workspaces`)

const rootHttpClient = buildHttpClient()

export { default as mountInterceptors } from './mountInterceptors'
export { rootHttpClient, workspacesHttpClient, websiteHttpClient }
export default buildHttpClient
