const teamFeature = {
  'teamFeature.subtitle': '<span>in</span> Team management',
  'teamFeature.description': 'Invite your team members and organize their workloads.',
  'teamFeature.manageFeature': 'Manage team',
  'teamFeature.overview': 'Team overview',
  'teamFeature.subheader': 'Easily manage your team’s work from a single workspace, no matter the size of your team.',
  'teamFeature.manageTeam': 'Manage your team:',
  'teamFeature.manageTeam.invite': 'Invite _(experts) to join your workspace via email.',
  'teamFeature.manageTeam.availability': 'Adjust the team’s availability in different locations.',
  'teamFeature.manageTeam.services':
    'Assign _(experts) to _(services), making sure each person on your team gets a chance to share their expertise with _(clients).',
  'teamFeature.responsibilities': 'Set roles and permissions according to each person’s responsibilities:',
  'teamFeature.responsibilities.crm':
    'Let people use the platform as _(experts) with easy access to their _(appointments) and _(clients).',
  'teamFeature.responsibilities.role':
    'Delegate part of your management responsibilities by giving team members an administrative or role. Administrators can invite new team members and manage their accounts, working hours, _(services), prices, and more.',
  'teamFeature.performance': 'Track your team’s performance:',
  'teamFeature.performance.review':
    'Track your team’s performance by reviewing their _(appointment) histories, video call recordings, _(client) reviews, etc.',
  'teamFeature.performance.support':
    // eslint-disable-next-line expbox/custom-name-validation
    'Provide your team with the necessary support, ensuring your _(clients) get the best service.',
  'teamFeature.summary':
    'With the ExpertBox team management feature, you can streamline your workflow, cutting down your operational costs and getting back the saving time to focus on the things that matter.',
  'teamFeature.learnMore':
    'To learn more, read or help center articles on <inviteLink>how to invite your team members to ExpertBox</inviteLink> and <manageLink>how to manage their roles, accounts, and workloads</manageLink>.',
}

export default teamFeature
