import { combineReducers } from '@reduxjs/toolkit'

import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import { SET_ACTIVE_TAB } from './types'

const activeTab = (state = CLIENT_PROFILE_TABS.overview, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.tabKey
    default:
      return state
  }
}

export default combineReducers({
  activeTab,
})
