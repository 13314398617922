import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const inviteEmployees = makeFormSubmitAction(types.INVITE_EMPLOYEES)
export const changeEmployeeRole = makeFormSubmitAction(types.CHANGE_EMPLOYEE_ROLE)
export const updateProvision = makeFormSubmitAction(types.UPDATE_EMPLOYEE_PROVISION)
export const updateEmployeeProfile = makeFormSubmitAction(types.UPDATE_EMPLOYEE_PROFILE)

export const filterEmployees = filters => ({
  type: types.FILTER_EMPLOYEES,
  filters,
})

export const fetchEmployees = () => ({
  type: types.FETCH_EMPLOYEES,
})

export const setEmployees = employeeIds => ({
  type: types.SET_EMPLOYEES,
  employeeIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_EMPLOYEES_PAGE,
  pageNumber,
})

export const deactivateEmployee = (employeeId, { redirectTo } = {}) => ({
  type: types.DEACTIVATE_EMPLOYEE,
  employeeId,
  redirectTo,
})

export const activateEmployee = employeeId => ({
  type: types.ACTIVATE_EMPLOYEE,
  employeeId,
})

export const removeEmployee = makeFormSubmitAction(types.REMOVE_EMPLOYEE)

export const removeEmployeeInvitation = employeeId => ({
  type: types.REMOVE_EMPLOYEE_INVITATION,
  employeeId,
})

export const resendEmployeeInvitation = employeeId => ({
  type: types.RESEND_EMPLOYEE_INVITATION,
  employeeId,
})

export const setFilterParams = filterParams => ({
  type: types.SET_EMPLOYEES_FILTER_PARAMS,
  filterParams,
})

export const setSortOrder = sortKey => ({
  type: types.SET_EMPLOYEES_SORT_ORDER,
  sortKey,
})

export const fetchProvision = employeeId => ({
  type: types.FETCH_EMPLOYEES_PROVISION,
  employeeId,
})

export const fetchProfile = employeeId => ({
  type: types.FETCH_EMPLOYEES_PROFILE,
  employeeId,
})

export const setBlankState = isBlankState => ({
  type: types.SET_EMPLOYEES_BLANK_STATE,
  isBlankState,
})

export const resetTwoFactorAuth = employee => ({
  type: types.RESET_TWO_FACTOR_AUTH,
  employee,
})

export const fetchEmployeesAvailabilities = ({ date, resetResults, pageParams = {}, excludeEmployees = [] }) => ({
  type: types.FETCH_EMPLOYEES_AVAILABILITIES,
  pageParams,
  date,
  excludeEmployees,
  resetResults,
})
