import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useMemo, useState } from 'react'
import { usePrevious } from 'react-use'
import { path, uniqBy } from 'ramda'
import { noop } from 'lodash'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { shareMyBusinessFile } from 'state/concepts/myBusinessFiles/actions'
import Modal from 'views/shared/Modal'
import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import steps from './steps'

const ManagePermissionsModal = ({ onClose, myBusinessFile, onBack }) => {
  const { businessStoragePermissions, ancestorsPermissions = [], id } = myBusinessFile
  const permissions = useMemo(
    () => uniqBy(path(['userProfile', 'id']), [...ancestorsPermissions, ...businessStoragePermissions]),
    [ancestorsPermissions, businessStoragePermissions],
  )
  const formattedPermissions = useMemo(
    () =>
      permissions.map(businessStoragePermission => ({
        ...businessStoragePermission,
        oldPermission: businessStoragePermission.permission,
      })),
    [permissions],
  )

  const [currentStep, setStep] = useState(SHARE_FILE_STEPS.sharedWith)
  const prevStep = usePrevious(currentStep)
  const StepComponent = steps()[currentStep]

  const handleClose = dirty => () => {
    dirty ? setStep(SHARE_FILE_STEPS.discard) : onClose()
  }
  const handleSubmit = useFormSubmit(shareMyBusinessFile, { onBack })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessStoragePermissions: formattedPermissions,
        newProfileId: undefined,
        newPermissions: [],
        myBusinessFileId: id,
        editedBusinessStorageId: undefined,
      }}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Modal className="main-modal main-modal--md" onClose={handleClose(dirty)}>
          <div className="main-modal__container">
            <StepComponent
              myBusinessFile={myBusinessFile}
              onClose={handleClose(dirty)}
              setStep={setStep}
              prevStep={prevStep}
            />
          </div>
        </Modal>
      )}
    </Formik>
  )
}

ManagePermissionsModal.defaultProps = {
  onBack: noop,
}

ManagePermissionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  myBusinessFile: PropTypes.shape({
    id: PropTypes.string,
    businessStoragePermissions: PropTypes.arrayOf(PropTypes.shape({})),
    ancestorsPermissions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default ManagePermissionsModal
