import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ size, color, className, dataCy }) => (
  <svg data-cy={dataCy} width={`${size}px`} height={`${size}px`} className={className} fill={color} viewBox="0 0 18 14">
    <path d="M2.33329 0.333374C1.41246 0.333374 0.666626 1.07921 0.666626 2.00004V3.66671V6.16671V12C0.666626 12.9209 1.41246 13.6667 2.33329 13.6667H15.6666C16.5875 13.6667 17.3333 12.9209 17.3333 12V6.16671V3.66671V2.00004C17.3333 1.07921 16.5875 0.333374 15.6666 0.333374H2.33329ZM2.33329 2.00004H15.6666V3.66671H2.33329V2.00004ZM2.33329 6.16671H15.6666V12H2.33329V6.16671Z" />
  </svg>
)

Card.defaultProps = {
  size: 24,
  color: '#b1bac9',
  className: '',
  dataCy: 'credit-card-icon',
}

Card.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Card
