import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import { path } from 'ramda'
import Image from 'next/legacy/image'

import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import RateField from 'views/shared/RateField'
import Button from 'views/shared/Button'
import profileName from 'utils/profileName'
import { boldText } from 'utils/locales'
import InfoO from 'views/shared/icons/InfoO'
import Cross from 'views/shared/icons/Cross'
import Spinner from 'views/shared/icons/Spinner'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'

const ReviewAppointmentComponent = ({ handleSubmit, booking, isModalMode, onGoBack, isEdit, isLoading }) => {
  const { logoUrl, name } = useSelector(workspaceSelector)

  if (isLoading !== false || !booking) {
    return <Spinner isAbsolute dataCy="review-appointment-spinner" />
  }

  return (
    <>
      {!isModalMode && (
        <header className="main-header">
          <div className="d-flex align-items-center w-100 justify-content-center">
            {logoUrl && (
              <Image
                src={logoUrl}
                className="border-radius-wrap"
                alt="#"
                width={33}
                height={32}
                data-cy="workspace-logo"
              />
            )}
            <div className="ml-12 in-blue-gray-800 text-subheader font-600">{name}</div>
          </div>
        </header>
      )}

      <div className="review-form-page app-wrap__main full-width">
        {isModalMode && (
          <button type="button" onClick={onGoBack} className="review-form-page__close">
            <Cross dataCy="cross-icon" />
          </button>
        )}

        <div className="container">
          <div className="row">
            <div className="col-lg-7 ml-auto mr-auto">
              <div className="review-form-page__card  main-card" data-cy="review-form">
                <h2 className="font-600 text-headline mb-16" data-cy="review-form-name">
                  {path(['reviewSetting', 'formName'], booking)}
                </h2>
                <p className="text-subheader in-blue-gray-200" data-cy="review-form-title">
                  <FormattedMessage
                    id="clientReview.form.reviewInfo"
                    values={{
                      service: path(['service', 'name'], booking),
                      expert: profileName(booking.userProfile),
                      b: boldText,
                    }}
                  />
                </p>
                <p className="text-subheader in-blue-gray-200" data-cy="review-form-description">
                  {path(['reviewSetting', 'formDescription'], booking)}
                </p>

                <Form>
                  <Field component={RateField} className="mb-20 rate--lg" name="rating" id="rating" />

                  <Field
                    name="description"
                    id="description"
                    component={TextAreaField}
                    icon="alert"
                    label={{ id: 'clientReview.form.descriptionLabel' }}
                    autoSize={{ minRows: 9, maxRows: 8 }}
                    maxLength={3001}
                    wrapperClassName="mb-8"
                    data-cy="review-description-field"
                  />
                  <div className="d-flex align-items-center mt-8">
                    <InfoO className="mr-8" dataCy="exclamation-mark-icon" />
                    <span className="in-blue-gray-200 text-body" data-cy="review-form-notes">
                      <FormattedMessage id="clientReview.form.notes" />
                    </span>
                  </div>
                </Form>

                <div className="d-flex justify-content-flex-end flex-column-revert-xs mt-23">
                  {isModalMode && (
                    <Button
                      className="w-100-xs mr-16 mt-8"
                      text={{ id: 'shared.cancel' }}
                      onClick={onGoBack}
                      kind="flat"
                      dataCy="cancel-button"
                    />
                  )}
                  <SubmitFormButton
                    className="w-100-xs mt-8"
                    text={{ id: isEdit ? 'shared.saveChanges' : 'clientReview.form.submitBtn' }}
                    onClick={handleSubmit}
                    dataCy="submit-review-button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ReviewAppointmentComponent.defaultProps = {
  isLoading: undefined,
  booking: undefined,
  isEdit: false,
}

ReviewAppointmentComponent.propTypes = {
  isLoading: PropTypes.bool,
  booking: PropTypes.shape(),
  isEdit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isModalMode: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
}

export default ReviewAppointmentComponent
