import PropTypes from 'prop-types'

import BookingDateInfo from 'views/shared/AppointmentDetails/BookingDateInfo'
import BookingPrice from 'views/shared/AppointmentDetails/BookingPrice'
import BookingServiceCategory from 'views/shared/AppointmentDetails/BookingServiceCategory'
import InvitedMembers from 'views/shared/AppointmentDetails/InvitedMembers'
import ClientDetails from 'views/shared/AppointmentDetails/ClientDetails'
import ExpertActions from 'views/shared/AppointmentDetails/ExpertActions'
import Members from 'views/shared/AppointmentDetails/Members'
import Recordings from 'views/shared/AppointmentDetails/Recordings'
import StatusBadge from 'views/shared/AppointmentDetails/StatusBadge'
import JoinConference from 'views/shared/JoinConference'
import Link from 'views/shared/Link'
import LocationDetails from 'views/shared/LocationDetails'
import Cross from 'views/shared/icons/Cross'
import External from 'views/shared/icons/External'
import Options from 'views/shared/icons/Options'
import Agreements from './Agreements'
import CancellationReason from './CancellationReason'
import IntakeForms from './IntakeForms'
import RescheduleReason from './RescheduleReason'

const TooltipContentComponent = ({
  onClose,
  onCancel,
  booking,
  isBookingParticipant,
  showAllMembers,
  canJoinConference,
  bookingPagePath,
  currentEmployee,
}) => {
  const videoCallProvider = booking.videoConference?.videoCallProvider || booking.userProfile.videoCallProvider

  return (
    <section className="booking-tooltip" data-cy="booking-tooltip">
      <div className="booking-tooltip__toolbar">
        <StatusBadge booking={booking} />
        <div>
          <Link {...bookingPagePath}>
            <a
              className="main-dropdown-btn mr-24"
              target="_blank"
              data-cy="expand-booking-details-button"
              rel="noreferrer"
            >
              <External className="main-dropdown-btn__icon" size={16} />
            </a>
          </Link>

          <ExpertActions
            booking={booking}
            onCancel={onCancel}
            isBookingParticipant={isBookingParticipant}
            onCloseTooltip={onClose}
            icon={
              <button className="mr-24" type="button" data-cy="booking-actions">
                <Options className="main-dropdown-btn__icon" size={18} />
              </button>
            }
          />

          <button type="button" onClick={onClose} data-cy="close-sidebar-button">
            <Cross size={10} dataCy="cross-icon" className="in-gray-600 va-middle" />
          </button>
        </div>
      </div>

      <div className="booking-tooltip__info mb-24">
        <BookingServiceCategory booking={booking} />
        <div className="d-flex flex-column gap-16 mt-4">
          <div className="d-flex">
            <div className="ml-36">
              <h3 className="booking-tooltip__info-title mb-8" data-cy="booking-service-title">
                {booking.service.name}
              </h3>
              <BookingDateInfo className="gap-4 mb-12" booking={booking} withoutIcon />
            </div>
          </div>
          <RescheduleReason booking={booking} isShort />
          <CancellationReason booking={booking} isShort />
          {canJoinConference ? (
            <JoinConference
              addUserButtonProps={{
                className: 'main-btn--icon-only ml-8 d-none d-sm-block',
              }}
              booking={booking}
              videoCallProvider={videoCallProvider}
              videoConference={booking.videoConference}
              showImage
              showDetails
            />
          ) : (
            <LocationDetails location={booking.location} videoCallProvider={videoCallProvider} />
          )}

          <BookingPrice booking={booking} currentEmployee={currentEmployee} />

          {showAllMembers ? <Members booking={booking} /> : <ClientDetails booking={booking} />}
          <InvitedMembers booking={booking} overlayClassName="ml-32" />

          <Recordings booking={booking} onDelete={onClose} onPreview={onClose} />
        </div>
      </div>
      <div data-cy="forms-section">
        <IntakeForms booking={booking} bookingPagePath={bookingPagePath} />
        <Agreements booking={booking} bookingPagePath={bookingPagePath} />
      </div>
    </section>
  )
}

TooltipContentComponent.defaultProps = {
  currentEmployee: undefined,
}

TooltipContentComponent.propTypes = {
  currentEmployee: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  booking: PropTypes.shape().isRequired,
  isBookingParticipant: PropTypes.bool.isRequired,
  showAllMembers: PropTypes.bool.isRequired,
  canJoinConference: PropTypes.bool.isRequired,
  bookingPagePath: PropTypes.shape().isRequired,
}

export default TooltipContentComponent
