import PropTypes from 'prop-types'

import FileIcon from 'views/shared/FileIcon'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import FolderO from 'views/shared/icons/FolderO'

const FileBadge = ({ file }) => (
  <div className="directories-badge d-inline-flex align-items-center column-gap-6 va-middle pl-8 pr-8">
    <div className="d-flex align-items-center column-gap-4 min-w-0">
      <div className="wh-16-16 d-flex align-items-center justify-content-center">
        {file?.fileMimeType ? (
          <FileIcon size={14} mimeType={file.fileMimeType} dataCy="file-icon" />
        ) : (
          <FolderO size={14} className="directories__folder-icon in-disabled-icon" />
        )}
      </div>
      <div className="min-w-0">
        <p className="text-caption in-blue-gray-800 font-500 mb-0">
          <TruncatedTextByWidthTooltip on={['hover']} overlayClassName="max-w-50" text={file?.name} />
        </p>
      </div>
    </div>
  </div>
)

FileBadge.propTypes = {
  file: PropTypes.shape(),
}

FileBadge.defaultProps = {
  file: null,
}

export default FileBadge
