const namespace = 'chat'

export const CREATE_CHAT = `${namespace}/CREATE_CHAT`
export const CREATE_DIRECT_CHAT = `${namespace}/CREATE_DIRECT_CHAT`
export const UPDATE_CHAT = `${namespace}/UPDATE_CHAT`
export const LEAVE_CHAT = `${namespace}/LEAVE_CHAT`
export const DELETE_CHAT = `${namespace}/DELETE_CHAT`
export const CREATE_MESSAGE = `${namespace}/CREATE_MESSAGE`
export const CREATE_MESSAGE_OPTIMISTIC = `${namespace}/CREATE_MESSAGE_OPTIMISTIC`
export const ADD_SENDING_MESSAGE_ID = `${namespace}/ADD_SENDING_MESSAGE_ID`
export const REMOVE_SENDING_MESSAGE_ID = `${namespace}/REMOVE_SENDING_MESSAGE_ID`
export const ADD_RESENDING_MESSAGE_ID = `${namespace}/ADD_RESENDING_MESSAGE_ID`
export const REMOVE_RESENDING_MESSAGE_ID = `${namespace}/REMOVE_RESENDING_MESSAGE_ID`
export const UPDATE_MESSAGE = `${namespace}/UPDATE_MESSAGE`
export const SET_EDITING_MESSAGE = `${namespace}/SET_EDITING_MESSAGE`
export const REMOVE_MESSAGE = `${namespace}/REMOVE_MESSAGE`
export const REMOVE_MESSAGE_SUCCESS = `${namespace}/REMOVE_MESSAGE_SUCCESS`
export const RECEIVE_MESSAGES = `${namespace}/RECEIVE_MESSAGES`
export const RECEIVE_CHAT_MESSAGES = `${namespace}/RECEIVE_CHAT_MESSAGES`
export const SET_MESSAGES = `${namespace}/SET_MESSAGES`
export const FLUSH_MESSAGES = `${namespace}/FLUSH_MESSAGES`
export const FETCH_MESSAGES = `${namespace}/FETCH_MESSAGES`
export const MARK_MESSAGES_AS_READ = `${namespace}/MARK_MESSAGES_AS_READ`
export const MARK_ALL_MESSAGES_AS_READ = `${namespace}/MARK_ALL_MESSAGES_AS_READ`
export const FETCH_ATTACHMENTS = `${namespace}/FETCH_ATTACHMENTS`
export const ADD_ATTACHMENTS = `${namespace}/ADD_ATTACHMENTS`
export const RESET_ATTACHMENTS = `${namespace}/RESET_ATTACHMENTS`
export const REMOVE_ATTACHMENT = `${namespace}/REMOVE_ATTACHMENT`
export const REMOVE_ATTACHMENT_SUCCESS = `${namespace}/REMOVE_ATTACHMENT_SUCCESS`
export const SET_ONLINE = `${namespace}/SET_ONLINE`
export const SET_OFFLINE = `${namespace}/SET_OFFLINE`
export const SET_UNREAD_INDICATOR_VISIBILITY = `${namespace}/SET_UNREAD_INDICATOR_VISIBILITY`
export const SET_CHAT = `${namespace}/SET_CHAT`
export const GET_CHAT = `${namespace}/GET_CHAT`
export const GET_CHAT_MEMBERS = `${namespace}/GET_CHAT_MEMBERS`
export const ADD_CHAT_MEMBERS = `${namespace}/ADD_CHAT_MEMBERS`
export const SET_CHAT_MEMBER_IDS = `${namespace}/SET_CHAT_MEMBER_IDS`
export const REMOVE_CHAT_MEMBER = `${namespace}/REMOVE_CHAT_MEMBER`
export const RECEIVE_REMOVED_CHAT = `${namespace}/RECEIVE_REMOVED_CHAT`
export const RECEIVE_LEFT_CHAT = `${namespace}/RECEIVE_LEFT_CHAT`
export const RECEIVE_REMOVED_MEMBER = `${namespace}/RECEIVE_REMOVED_MEMBER`
export const RECEIVE_CHAT_UNREAD_MESSAGES_COUNT = `${namespace}/RECEIVE_CHAT_UNREAD_MESSAGES_COUNT`
export const RECEIVE_CHAT_DATA = `${namespace}/RECEIVE_CHAT_DATA`

export const DRAG_FILES = `${namespace}/DRAG_FILES`
export const CREATE_ATTACHMENT_UPLOAD = `${namespace}/CREATE_ATTACHMENT_UPLOAD`
export const UPLOAD_ATTACHMENT = `${namespace}/UPLOAD_ATTACHMENT`
export const UPDATE_ATTACHMENT_UPLOAD = `${namespace}/UPDATE_ATTACHMENT_UPLOAD`
export const CANCEL_ATTACHMENT_UPLOAD = `${namespace}/CANCEL_ATTACHMENT_UPLOAD`
export const SET_UPLOAD_LOADING_STATUS = `${namespace}/SET_UPLOAD_LOADING_STATUS`
export const REMOVE_UPLOAD_LOADING_STATUS = `${namespace}/REMOVE_UPLOAD_LOADING_STATUS`
export const SET_UPLOAD_PROGRESS = `${namespace}/SET_UPLOAD_PROGRESS`
export const REMOVE_UPLOAD_PROGRESS = `${namespace}/REMOVE_UPLOAD_PROGRESS`
export const LINK_UPLOAD_TO_ATTACHMENT = `${namespace}/LINK_UPLOAD_TO_ATTACHMENT`
export const UNLINK_UPLOAD_TO_ATTACHMENT = `${namespace}/UNLINK_UPLOAD_TO_ATTACHMENT`
export const RESET_UPLOAD_TO_ATTACHMENT_LINKS = `${namespace}/RESET_UPLOAD_TO_ATTACHMENT_LINKS`
export const ADD_UPLOAD_ERROR = `${namespace}/ADD_UPLOAD_ERROR`
export const REMOVE_UPLOAD_ERROR = `${namespace}/REMOVE_UPLOAD_ERROR`
export const SET_HAS_VOICE_MESSAGE = `${namespace}/SET_HAS_VOICE_MESSAGE`
export const SEND_MESSAGE = `${namespace}/SEND_MESSAGE`
