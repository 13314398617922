import axios, { CancelToken, isCancel } from 'axios'
import normalize from 'json-api-normalizer'
import { forEach, includes, keys } from 'ramda'
import { createLogic } from 'redux-logic'

import isPresent from 'utils/isPresent'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { fetchFilesSharedWithMe } from 'state/concepts/filesSharedWithMe/actions'
import { sliceFileIntoChunks } from 'utils/file'
import { multipartsCompleteRoute } from 'lib/apiRoutes'
import {
  addUploadFile,
  completeFileLoading,
  failFileLoading,
  fetchMyBusinessFiles,
  removeMyBusinessFileIds,
} from '../actions'
import { uploadFileEndpoint } from '../endpoints'
import { CANCEL_FILE_UPLOAD, UPLOAD_BUSINESS_FILE } from '../types'
import { myBusinessFileByIdSelector } from '../selectors'

const uploadFileOperation = createLogic({
  type: UPLOAD_BUSINESS_FILE,
  latest: false,
  warnTimeout: 0,

  async process({ httpClient, getState, action$, action: { file, meta, replaceOldFile, parentId } }, dispatch, done) {
    const currentEmployee = currentEmployeeSelector(getState())
    const currentFolder = myBusinessFileByIdSelector(getState(), parentId)
    const isOwnFiles = !isPresent(currentFolder) || currentFolder.userProfile.id === currentEmployee.id

    const uploadId = meta.fields?.key.match(/^uploads\/cache\/(.+)/)[1] || meta.key.match(/^uploads\/cache\/(.+)/)[1]
    const { url, endpoint } = uploadFileEndpoint
    const metaFileUrls = meta.urls || [meta.url]
    const fileChunks = meta.url ? [file] : sliceFileIntoChunks(file)

    try {
      const source = CancelToken.source()
      action$.subscribe(newAction => {
        const isCancelAllowed = newAction.type === CANCEL_FILE_UPLOAD && includes(uploadId, newAction.uploadIds)

        if (isCancelAllowed) {
          source.cancel()
        }
      })

      dispatch(addUploadFile(uploadId, file, meta, parentId, replaceOldFile))
      const isMultipartUpload = metaFileUrls.length > 1

      await Promise.all(
        metaFileUrls.map(async (presignUrl, index) => {
          const params = new FormData()
          meta.fields && forEach(key => params.append(key, meta.fields[key]), keys(meta.fields))
          params.append('file', fileChunks[index])
          const requestMethod = isMultipartUpload ? 'put' : 'post'
          await axios[requestMethod](presignUrl, params, {
            cancelToken: source.token,
          })
        }),
      )
      isMultipartUpload &&
        (await httpClient.post(multipartsCompleteRoute, {
          upload_id: meta.upload_id,
          key: meta.key,
          number_of_parts: metaFileUrls.length,
        }))

      const { data, meta: uploadMeta } = await httpClient.post(
        url,
        {
          file: {
            id: uploadId,
            storage: 'cache',
            metadata: {
              size: file.size,
              filename: file.name,
              mime_type: file.type,
            },
          },
          replace_old_file: replaceOldFile,
          parent_id: parentId,
          include: ['ancestors-permissions.user-profile', 'business-storage-permissions.user-profile'],
        },
        { cancelToken: source.token },
      )

      const response = normalize(data, { endpoint })
      const duplicateId = uploadMeta?.duplicate_id

      dispatch(completeFileLoading(uploadId))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        isOwnFiles
          ? fetchMyBusinessFiles(undefined, { append: false })
          : fetchFilesSharedWithMe(undefined, { append: false }),
      )
      if (duplicateId) {
        dispatch(removeMyBusinessFileIds([duplicateId]))
      }
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.youDoNotHavePermissions' },
            kind: 'error',
          }),
        )
      }

      if (!isCancel(error)) {
        dispatch(failFileLoading(uploadId))
        requestErrorHandler({ error, dispatch, excludeErrorArray: [403] })
      }
    }
    done()
  },
})

export default uploadFileOperation
