import { createLogic } from 'redux-logic'
import { omit, pluck } from 'ramda'
import normalize from 'json-api-normalizer'

import { tableOrderDirectionsSelector } from 'state/tableData/selectors'
import generateSortString from 'utils/generateSortString'
import { PAGINATION_ITEMS_PER_PAGE, TABLE_ENTITIES } from 'lib/constants/tableData'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { currentFolderIdSelector, filtersSelector } from '../selectors'
import { fetchMyBusinessFilesEndpoint } from '../endpoints'
import { FETCH_MY_BUSINESS_FILES } from '../types'
import { addMyBusinessFileIds, setBlankState, setMyBusinessFileIds } from '../actions'

const fetchMyBusinessFilesOperation = createLogic({
  type: FETCH_MY_BUSINESS_FILES,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const state = getState()
    const {
      pageParams: { page = 1, size },
      options: { append = true, clientId, parentId, preview = false },
    } = action
    const currentFolderId = currentFolderIdSelector(state)
    const { url, endpoint } = fetchMyBusinessFilesEndpoint
    const { name, employees, types, lastModified } = parentId ? {} : filtersSelector(state)
    const dates = lastModified?.[0]
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.myBusinessFiles))

    const filter = {
      name,
      user_profile_ids: employees,
      types,
      modified_after: dates?.modifiedAfter?.toISO(),
      modified_before: dates?.modifiedBefore?.toISO(),
      parent_id: currentFolderId || parentId || undefined,
      exclude_attached_to_client_id: clientId,
      preview,
    }

    const params = {
      page: { number: page, size: size || PAGINATION_ITEMS_PER_PAGE },
      include: [
        'user-profile',
        'parent.user-profile',
        'business-storage-permissions.user-profile',
        'parent.business-storage-permissions.user-profile',
        'ancestors-permissions.user-profile',
      ],
      filter,
      sort,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const blankStateParams = omit(['parent_id', 'exclude_attached_to_client_id', 'preview'], filter)

      dispatch(setBlankState(isBlankState({ data: data.data, params: blankStateParams })))
      dispatch(dataApiSuccess({ response, endpoint }))

      const ids = pluck('id', data.data)
      append ? dispatch(addMyBusinessFileIds(ids)) : dispatch(setMyBusinessFileIds(ids))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchMyBusinessFilesOperation
