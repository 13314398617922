const serviceCategoriesFeature = {
  'serviceCategoriesFeature.subtitle': '<span>in</span> _(Services)',
  'serviceCategoriesFeature.description':
    'Group _(services) into categories so _(clients) can easily find what they need.',
  'serviceCategoriesFeature.manageFeatures': 'Set categories',
  'serviceCategoriesFeature.overview': '_(Service) categories overview',
  'serviceCategoriesFeature.helpClientsDiscover':
    'Help _(clients) discover your _(services) by putting them into categories:',
  'serviceCategoriesFeature.letClientsEasilyFind':
    'Let _(clients) easily find the _(service) they’re looking for on your booking page.',
  'serviceCategoriesFeature.streamlineYourBookingProcess':
    'Streamline your booking process, making it fast and straightforward.',
  'serviceCategoriesFeature.serviceCategoriesApply':
    '_(Service) categories apply to all booking pages in your workspace.',
  'serviceCategoriesFeature.helpCenter':
    'To learn more, read our help center article on <link>setting up your _(services)</link>.',

  'serviceCategoriesFeature.disableServiceCategoriesManagement': 'Disable _(service) categories feature?',
  'serviceCategoriesFeature.disableServiceCategoriesManagementDescription':
    'Your _(services) won’t be grouped into categories anymore.',
}

export default serviceCategoriesFeature
