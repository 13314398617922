import getBooking from './getBooking'
import getClientBookingAvailability from './getClientBookingAvailability'
import clientRescheduleBooking from './clientRescheduleBooking'
import removeVideoRecord from './removeVideoRecord'
import removeChatHistory from './removeChatHistory'
import scheduleBooking from './scheduleBooking'
import updatePendingBooking from './updatePendingBooking'
import acceptBooking from './acceptBooking'
import declineBooking from './declineBooking'
import declineRecurringBookings from './declineRecurringBookings'
import declineAllRecurringBookings from './declineAllRecurringBookings'
import rescheduleRecurringBookingsOperation from './rescheduleRecurringBookingsOperation'
import receiveAccountFormData from './receiveAccountFormData'
import fetchInCallChat from './fetchInCallChat'

export default [
  getBooking,
  getClientBookingAvailability,
  clientRescheduleBooking,
  removeVideoRecord,
  removeChatHistory,
  scheduleBooking,
  updatePendingBooking,
  acceptBooking,
  declineBooking,
  declineRecurringBookings,
  declineAllRecurringBookings,
  rescheduleRecurringBookingsOperation,
  receiveAccountFormData,
  fetchInCallChat,
]
