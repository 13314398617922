import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isPresent from 'utils/isPresent'
import { sendInvitationToClientPortalEndpoint } from '../endpoints'
import { SEND_INVITATION_TO_CLIENT_PORTAL } from '../types'

const sendInvitationToClientPortalOperation = createLogic({
  type: SEND_INVITATION_TO_CLIENT_PORTAL,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { client } = action
    const { id, sentInvitationAt } = client
    const { url, endpoint } = sendInvitationToClientPortalEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      const response = updateDataHelper(getState().data, 'clientProfile', id, {
        attributes: {
          sentInvitationAt: DateTime.now(),
        },
      })
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: isPresent(sentInvitationAt)
              ? 'notifications.clientInvitationToClientPortalWasResent'
              : 'notifications.clientInvitationToClientPortalWasSent',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default sendInvitationToClientPortalOperation
