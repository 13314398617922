import React from 'react'
import PropTypes from 'prop-types'
import Image from 'next/legacy/image'
import { injectIntl } from 'react-intl'
import { is } from 'ramda'

import FormattedOrRawMessage from '../FormattedOrRawMessage'

const EmptyState = ({
  wrapperClassName,
  wrapperProps,
  extraWrapperClassName,
  extraWrapperProps,
  imgProps,
  textWrapperClassName,
  titleText,
  bodyText,
  titleProps,
  bodyProps,
  children,
  imgWrapperClassName,
  intl,
}) => (
  <div className={wrapperClassName} {...wrapperProps}>
    <div className={extraWrapperClassName} {...extraWrapperProps}>
      <div className={imgWrapperClassName}>
        <Image
          data-cy="empty-state-image"
          className="ml-auto mr-auto"
          {...imgProps}
          alt={is(Object, imgProps.alt) ? intl.formatMessage(imgProps.alt) : imgProps.alt}
        />
      </div>
      <div className={textWrapperClassName}>
        {titleText && (
          <div data-cy="empty-state-title-text" className="text-subheader in-blue-gray-300 mb-4" {...titleProps}>
            <FormattedOrRawMessage message={titleText} />
          </div>
        )}
        {bodyText && (
          <div data-cy="empty-state-body-text" className="text-body in-gray-700" {...bodyProps}>
            <FormattedOrRawMessage message={bodyText} />
          </div>
        )}
      </div>
      {children}
    </div>
  </div>
)

EmptyState.defaultProps = {
  wrapperClassName: 'd-flex align-items-center justify-content-center h-100',
  extraWrapperClassName: 'max-w-308 text-center',
  textWrapperClassName: 'mb-20',
  bodyText: undefined,
  titleProps: {},
  bodyProps: {},
  children: null,
  extraWrapperProps: {},
  wrapperProps: {},
  imgWrapperClassName: '',
  titleText: undefined,
}

EmptyState.propTypes = {
  wrapperClassName: PropTypes.string,
  wrapperProps: PropTypes.shape(),
  extraWrapperClassName: PropTypes.string,
  imgWrapperClassName: PropTypes.string,
  titleProps: PropTypes.shape(),
  bodyProps: PropTypes.shape(),
  textWrapperClassName: PropTypes.string,
  imgProps: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
    alt: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  }).isRequired,
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  children: PropTypes.node,
  extraWrapperProps: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
}

export default injectIntl(EmptyState)
