import classNames from 'clsx'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import {
  SERVICE_NAME_MAX_CHARS,
  SERVICE_DESCRIPTION_MAX_CHARS,
  SERVICE_DESCRIPTION_MIN_ROWS,
  SERVICE_DESCRIPTION_MAX_ROWS,
} from 'lib/constants/scheduleAppointment'
import spanWithClass from 'utils/locales/spanWithClass'
import DropdownServiceField from 'views/shared/DropdownServiceField'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import TextAreaField from 'views/shared/TextAreaField'
import ServiceIcon from 'views/shared/icons/Service'

const ServiceSelectComponent = ({
  userProfileId,
  customService,
  customServiceSelected,
  descriptionPlaceholder,
  isWaitlist,
}) => (
  <div
    className={classNames('booking-tooltip__info', {
      'booking-tooltip__info-divider': customServiceSelected,
    })}
  >
    <div className="d-flex">
      <div className="mr-16 pt-36">
        <ServiceIcon size={20} color="#6e798d" />
      </div>
      <div className="w-100 select-service__rap">
        <div id="select_service" className="w-100 mb-16">
          <Field
            className="booking-tooltip__select-service"
            name="serviceId"
            id="serviceId"
            component={DropdownServiceField}
            statuses={['active']}
            label={{ id: 'scheduleAppointment.service' }}
            placeholder={{ id: 'scheduleAppointment.selectService' }}
            icon="alert"
            userProfileId={userProfileId}
            mountToElement
            customService={customService}
            customServiceSelected={customServiceSelected}
            data-cy="service-select-field"
            disabled={isWaitlist}
          />
        </div>
        {customServiceSelected && (
          <>
            <Field
              name="serviceName"
              id="serviceName"
              icon="alert"
              component={InputWithCounterField}
              label={{ id: 'scheduleAppointment.appointmentName' }}
              showCount
              className="main-input--input-top-count mb-16"
              maxChars={SERVICE_NAME_MAX_CHARS}
            />
            <Field
              name="serviceDescription"
              id="serviceDescription"
              icon="alert"
              component={TextAreaField}
              label={{
                id: 'scheduleAppointment.appointmentDescription',
                values: { span: spanWithClass('ml-4 in-gray-700') },
              }}
              wrapperClassName="mb-0"
              className="main-input--textarea-top-count"
              showCount
              maxLength={SERVICE_DESCRIPTION_MAX_CHARS}
              autoSize={{
                minRows: SERVICE_DESCRIPTION_MIN_ROWS,
                maxRows: SERVICE_DESCRIPTION_MAX_ROWS,
              }}
              placeholder={descriptionPlaceholder}
            />
          </>
        )}
      </div>
    </div>
  </div>
)

ServiceSelectComponent.defaultProps = {
  userProfileId: undefined,
  descriptionPlaceholder: undefined,
}

ServiceSelectComponent.propTypes = {
  customService: PropTypes.shape().isRequired,
  customServiceSelected: PropTypes.bool.isRequired,
  userProfileId: PropTypes.string,
  descriptionPlaceholder: PropTypes.shape(),
  isWaitlist: PropTypes.bool.isRequired,
}

export default ServiceSelectComponent
