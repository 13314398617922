import { createLogic } from 'redux-logic'
import { path } from 'ramda'

import { CHAT_DATA_TYPES } from 'lib/constants/chat'
import { currentChatUserIdSelector } from 'state/concepts/session/selectors'
import {
  receiveMessages,
  receiveRemovedChat,
  receiveLeftChat,
  receiveRemovedMember,
  receiveChatUnreadMessagesCount,
} from 'state/concepts/chat/actions'
import { receiveData } from 'state/concepts/webSockets/actions'
import { RECEIVE_CHAT_DATA } from 'state/concepts/chat/types'
import { dataDelete } from 'state/data/actions'

const receiveChatDataOperation = createLogic({
  type: RECEIVE_CHAT_DATA,
  latest: true,

  async process({ action: { data }, getState }, dispatch, done) {
    const currentChatUserId = currentChatUserIdSelector(getState())
    const chatUsersIgnoreList = path(['meta', 'chat_users_ignore_list'], data)

    if (chatUsersIgnoreList?.includes(currentChatUserId)) {
      dispatch(dataDelete({ kind: 'chat', ids: [path(['data', 'attributes', 'chat-id'], data)] }))
      done()
      return
    }

    switch (path(['meta', 'type'], data)) {
      case CHAT_DATA_TYPES.messagesUpdated:
        dispatch(receiveMessages(data))
        break
      case CHAT_DATA_TYPES.unreadMessagesUpdated:
        dispatch(receiveChatUnreadMessagesCount(data))
        break
      case CHAT_DATA_TYPES.chatRemoved:
        dispatch(receiveRemovedChat(data))
        break
      case CHAT_DATA_TYPES.chatUpdated:
        dispatch(receiveData(data))
        break
      case CHAT_DATA_TYPES.chatMembersLeaves:
        dispatch(receiveLeftChat(data))
        break
      case CHAT_DATA_TYPES.chatMembersRemoved:
        dispatch(receiveRemovedMember(data))
        break
      default:
        break
    }

    done()
  },
})

export default receiveChatDataOperation
