import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setDocumentsIds, addDocumentsIds, setDocumentsBlankState } from '../actions'
import { fetchDocumentsEndpoint } from '../endpoints'
import { documentsPaginationSelector, clientDocumentsFiltersSelector } from '../selectors'
import { FETCH_DOCUMENTS } from '../types'

const fetchDocumentsOperation = createLogic({
  type: FETCH_DOCUMENTS,
  latest: true,

  async process({ httpClient, getState, action: { workspaceCode, filters, options } }, dispatch, done) {
    const { endpoint, url } = fetchDocumentsEndpoint
    const state = getState()
    const filter = clientDocumentsFiltersSelector(state)
    const customBaseUrl = workspaceCode ? buildCustomBaseUrl(['workspaces', workspaceCode]) : {}

    const config = {
      ...customBaseUrl,
      params: {
        include: [
          'client-profile',
          'user-profile',
          'document-permissions',
          'document_signatories.signature',
          'document_signatories.profileable',
        ],
        page: documentsPaginationSelector(state),
        filter: { ...filter, ...filters },
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, config)
      const response = normalize(data, { endpoint })

      dispatch(setDocumentsBlankState(isBlankState({ data: data.data, params: filter })))
      dispatch(dataApiSuccess({ response, endpoint }))
      const documentIds = pluck('id', data.data)
      dispatch(options?.append ? addDocumentsIds(documentIds) : setDocumentsIds(documentIds))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchDocumentsOperation
