import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { disablePaymentCancellationEndpoint } from 'state/concepts/payment/endpoints'
import { DISABLE_PAYMENT_CANCELLATION } from 'state/concepts/payment/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const disablePaymentCancellationOperation = createLogic({
  type: DISABLE_PAYMENT_CANCELLATION,
  latest: true,

  async process({ httpClient, action: { paymentId } }, dispatch, done) {
    const { url, endpoint } = disablePaymentCancellationEndpoint(paymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default disablePaymentCancellationOperation
