import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { deleteWebsite as deleteWebsiteAction } from 'state/concepts/website/actions'
import ConfirmModal from 'views/shared/ConfirmModal'
import { deleteWebsiteLoadingSelector } from 'state/concepts/website/selectors'

const DeleteWebsiteModal = props => {
  const deleteWebsite = useDispatchAction(deleteWebsiteAction)
  const isLoading = useSelector(deleteWebsiteLoadingSelector)

  return (
    <ConfirmModal
      {...props}
      title={{ id: 'website.settings.general.deleteWebsite.modal.title' }}
      bodyText={[{ id: 'website.settings.general.deleteWebsite.modal.description' }]}
      submitText={{ id: 'shared.delete' }}
      onSubmit={deleteWebsite}
      kind="danger"
      isLoading={isLoading}
    />
  )
}

export default DeleteWebsiteModal
