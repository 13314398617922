import { createLogic } from 'redux-logic'
import { pathEq, toString, head, pluck, propEq, length, filter } from 'ramda'

import waitForElement from 'utils/waitForElement'
import { RECEIVE_CHAT_MESSAGES } from 'state/concepts/chat/types'
import { setMessages, removeMessageSuccess } from 'state/concepts/chat/actions'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'

const receiveChatMessagesOperation = createLogic({
  type: RECEIVE_CHAT_MESSAGES,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { chatUserId } = currentUserProfileSelector(getState())
    const { messages } = action
    const messageIds = pluck('id', messages)
    const headMessage = head(messages)

    const isCurrentUserMessage = pathEq(toString(chatUserId), ['chatUser', 'id'], headMessage)
    const isDestroyed = propEq(true, 'destroyed', headMessage)

    dispatch(setMessages(messageIds))

    const destroyedMessageIds = pluck('id', filter(propEq(true, 'destroyed'), messages))
    if (length(destroyedMessageIds)) {
      dispatch(removeMessageSuccess(destroyedMessageIds))
    }

    if (length(messages) === 1 && isCurrentUserMessage && !isDestroyed) {
      const element = await waitForElement(`#message-${headMessage.id}`)

      element?.scrollIntoView()
    }

    done()
  },
})

export default receiveChatMessagesOperation
