import { createLogic } from 'redux-logic'

import { RESET_CLIENT_TRANSACTIONS_FILTER } from 'state/concepts/payments/types'
import { resetFilterParams, setCurrentPage, fetchClientTransactions } from 'state/concepts/payments/actions'

const resetClientTransactionsFilterOperation = createLogic({
  type: RESET_CLIENT_TRANSACTIONS_FILTER,
  latest: true,

  async process(_, dispatch, done) {
    dispatch(resetFilterParams())
    dispatch(setCurrentPage(1))
    dispatch(fetchClientTransactions())
    done()
  },
})

export default resetClientTransactionsFilterOperation
