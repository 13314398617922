import { Views as SharedViews } from 'react-big-calendar'
import { FormattedMessage } from 'react-intl'

import { boldText } from 'utils/locales'

export const BOOKING_STATUSES = {
  scheduled: 'scheduled',
  cancelled: 'cancelled',
  rescheduled: 'rescheduled',
  completed: 'completed',
  pending: 'pending',
  reserved: 'reserved',
}

export const BOOKING_TYPE = {
  schedule: 'schedule',
  booking: 'booking',
}

export const VIDEO_ROOM_TYPE = {
  go: 'go',
  group: 'group',
}

export const BOOKING_STATUSES_FILTERS = [
  {
    value: 'scheduled',
    label: (
      <div className="main-badge main-badge--scheduled">
        <FormattedMessage id="booking.filters.statuses.scheduled" />
      </div>
    ),
    labelPreview: { id: 'booking.filters.statuses.scheduled' },
  },
  {
    value: 'pending',
    label: (
      <div className="main-badge main-badge--requested">
        <FormattedMessage id="booking.filters.statuses.requested" />
      </div>
    ),
    labelPreview: { id: 'booking.filters.statuses.requested' },
  },
  {
    value: 'rescheduled',
    label: (
      <div className="main-badge main-badge--pending">
        <FormattedMessage id="booking.filters.statuses.rescheduled" />
      </div>
    ),
    labelPreview: { id: 'booking.filters.statuses.rescheduled' },
  },
  {
    value: 'completed',
    label: (
      <div className="main-badge main-badge--completed">
        <FormattedMessage id="booking.filters.statuses.completed" />
      </div>
    ),
    labelPreview: { id: 'booking.filters.statuses.completed' },
  },
  {
    value: 'cancelled',
    label: (
      <div className="main-badge main-badge--overdue">
        <FormattedMessage id="booking.filters.statuses.cancelled" />
      </div>
    ),
    labelPreview: { id: 'booking.filters.statuses.cancelled' },
  },
]

export const ACTIVE_BOOKINGS = [BOOKING_STATUSES.scheduled, BOOKING_STATUSES.rescheduled]

export const ARCHIVED_BOOKINGS = [BOOKING_STATUSES.cancelled, BOOKING_STATUSES.completed]

export const AGENDA_LENGTH = 30

export const FREE_PRICE = '0.0'

export const SYNCED_CALENDAR_EVENTS_COLOR = '#b1bac9'
export const BLANK_SERVICE_CATEGORY_COLOR = '#7f6aff'
export const CUSTOM_CALENDAR_EVENTS_CLASS = 'rbc-event--custom'
export const CANCELLED_CALENDAR_EVENTS_CLASS = 'rbc-event--cancelled'
export const RESCHEDULED_CALENDAR_EVENTS_CLASS = 'rbc-event--rescheduled'
export const SCHEDULED_CALENDAR_EVENTS_CLASS = 'rbc-event--scheduled'
export const COMPLETED_CALENDAR_EVENTS_CLASS = 'rbc-event--completed'
export const PENDING_CALENDAR_EVENTS_CLASS = 'rbc-event--pending'
export const RESERVED_CALENDAR_EVENTS_CLASS = 'rbc-event--reserved'
export const EXTERNAL_CALENDAR_EVENTS_CLASS = 'rbc-event--external'
export const SELECTED_CALENDAR_EVENTS_CLASS = 'rbc-event--selected'

export const EVENT_CARD_SM_WIDTH = 180
export const EVENT_CARD_MD_WIDTH = 400
export const EVENT_SM_DURATION = 16
export const EVENT_SM_HEIGHT = 25
export const EVENT_MD_DURATION = 31
export const EVENT_MD_HEIGHT = EVENT_SM_HEIGHT * 2
export const EVENT_LG_DURATION = 46

export const CALENDAR_TYPES_FILTERS = [
  {
    label: { id: 'booking.filters.calendarTypes.expertbox' },
    value: 'expertbox_calendar',
  },
]

export const GOOGLE_CALENDAR_TYPE_FILTERS = [
  {
    label: { id: 'booking.filters.calendarTypes.available' },
    value: 'google_available_calendar',
    parent: { id: 'booking.filters.calendarTypes.googleCalendar' },
  },
  {
    label: { id: 'booking.filters.calendarTypes.unavailable' },
    value: 'google_unavailable_calendar',
    parent: { id: 'booking.filters.calendarTypes.googleCalendar' },
  },
]

export const OUTLOOK_CALENDAR_TYPE_FILTERS = [
  {
    label: { id: 'booking.filters.calendarTypes.available' },
    value: 'outlook_available_calendar',
    parent: { id: 'booking.filters.calendarTypes.outlook' },
  },
  {
    label: { id: 'booking.filters.calendarTypes.unavailable' },
    value: 'outlook_unavailable_calendar',
    parent: { id: 'booking.filters.calendarTypes.outlook' },
  },
]

export const APPLE_CALENDAR_TYPE_FILTERS = [
  {
    label: { id: 'booking.filters.calendarTypes.available' },
    value: 'apple_available_calendar',
    parent: { id: 'booking.filters.calendarTypes.apple' },
  },
  {
    label: { id: 'booking.filters.calendarTypes.unavailable' },
    value: 'apple_unavailable_calendar',
    parent: { id: 'booking.filters.calendarTypes.apple' },
  },
]

export const ALLOWABLE_TIME_IN_MINUTES = 15

export const BOOKING_ACTIONS = {
  accept: 'accept',
  decline: 'decline',
  decline_all: 'decline_all',
  submitForms: 'submit_forms',
  reschedule: 'reschedule',
  cancel: 'cancel',
}

export const BOOKING_DECLINE_OPTIONS = {
  single: 'single',
  all: 'all',
}

export const WEEKLY_INTERVAL_FIELD_OPTIONS = [
  { key: 'weekly', value: 1, label: { id: 'scheduleAppointment.repeatSection.weeklyInterval.optionWeekly' } },
  {
    key: 'everyTwoWeeks',
    value: 2,
    label: { id: 'scheduleAppointment.repeatSection.weeklyInterval.optionEveryTwoWeeks' },
  },
  {
    key: 'everyThreeWeeks',
    value: 3,
    label: { id: 'scheduleAppointment.repeatSection.weeklyInterval.optionEveryThreeWeeks' },
  },
  {
    key: 'everyFourWeeks',
    value: 4,
    label: { id: 'scheduleAppointment.repeatSection.weeklyInterval.optionEveryFourWeeks' },
  },
]

export const ENDS_FIELD_KEY_AND_VALUE = {
  after: 'after',
  until: 'until',
}

export const ENDS_FIELD_OPTIONS = [
  {
    key: ENDS_FIELD_KEY_AND_VALUE.after,
    value: ENDS_FIELD_KEY_AND_VALUE.after,
    label: { id: 'scheduleAppointment.repeatSection.ends.optionAfter' },
  },
  {
    key: ENDS_FIELD_KEY_AND_VALUE.until,
    value: ENDS_FIELD_KEY_AND_VALUE.until,
    label: { id: 'scheduleAppointment.repeatSection.ends.optionOnDate' },
  },
]

export const DAYS_OF_WEEK_VALUE_AND_ID = {
  su: 'SU',
  mo: 'MO',
  tu: 'TU',
  we: 'WE',
  th: 'TH',
  fr: 'FR',
  sa: 'SA',
}

export const DAY_NUMBER_TO_DAY_OF_WEEK = {
  7: DAYS_OF_WEEK_VALUE_AND_ID.su,
  1: DAYS_OF_WEEK_VALUE_AND_ID.mo,
  2: DAYS_OF_WEEK_VALUE_AND_ID.tu,
  3: DAYS_OF_WEEK_VALUE_AND_ID.we,
  4: DAYS_OF_WEEK_VALUE_AND_ID.th,
  5: DAYS_OF_WEEK_VALUE_AND_ID.fr,
  6: DAYS_OF_WEEK_VALUE_AND_ID.sa,
}

export const DAYS_OF_WEEK_FIELD_OPTIONS = [
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekSunday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekSundayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.su,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.su,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekMonday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekMondayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.mo,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.mo,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekTuesday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekTuesdayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.tu,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.tu,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekWednesday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekWednesdayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.we,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.we,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekThursday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekThursdayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.th,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.th,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekFriday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekFridayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.fr,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.fr,
  },
  {
    label: { id: 'scheduleAppointment.repeatSection.dayOfWeekSaturday' },
    tooltipId: 'scheduleAppointment.repeatSection.dayOfWeekSaturdayTooltip',
    value: DAYS_OF_WEEK_VALUE_AND_ID.sa,
    nameAndId: DAYS_OF_WEEK_VALUE_AND_ID.sa,
  },
]

export const RECURRING_BOOKING_OPTIONS = {
  single: 'single',
  all: 'all',
}

export const RECURRING_BOOKING_RADIO_FIELD_OPTIONS = [
  {
    value: RECURRING_BOOKING_OPTIONS.single,
    label: { id: 'cancelRecurringBookingModal.recurringOption.single' },
  },
  {
    value: RECURRING_BOOKING_OPTIONS.all,
    label: { id: 'cancelRecurringBookingModal.recurringOption.all' },
  },
]

export const RECURRING_BOOKING_FIELD_OPTIONS = [
  {
    value: RECURRING_BOOKING_OPTIONS.single,
    label: { id: 'booking.amountAppointment.single' },
  },
  {
    value: RECURRING_BOOKING_OPTIONS.all,
    label: { id: 'booking.amountAppointment.all' },
  },
]

export const EVENT_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
}

export const RESOURCE_KEY = 'resourceId'

export const Views = {
  ...SharedViews,
  TEAM: 'team',
}

export const ViewsHashTable = {
  [Views.DAY]: SharedViews.DAY,
  [Views.WEEK]: SharedViews.WEEK,
  [Views.AGENDA]: SharedViews.AGENDA,
  [Views.TEAM]: SharedViews.DAY,
}

export const calendarSettingsColors = {
  service: 'service',
  teamMember: 'team_member',
}

export const CALENDAR_SETTINGS_OPTIONS = [
  {
    value: calendarSettingsColors.service,
    label: {
      id: `bookings.calendarSettings.modal.option.${calendarSettingsColors.service}`,
      values: { b: boldText },
    },
  },
  {
    value: calendarSettingsColors.teamMember,
    label: {
      id: `bookings.calendarSettings.modal.option.${calendarSettingsColors.teamMember}`,
      values: { b: boldText },
    },
  },
]

export const DECLINE_WAITLIST_BOOKING_VALUES = {
  declineAppointment: 'declineAppointment',
  declineAppointmentAndDeleteWaitlistRequest: 'declineAppointmentAndDeleteWaitlistRequest',
}

export const DECLINE_WAITLIST_BOOKING_OPTIONS = [
  {
    value: DECLINE_WAITLIST_BOOKING_VALUES.declineAppointment,
    label: { id: 'declineWaitlistAppointmentModal.declineOption.declineAppointment' },
  },
  {
    value: DECLINE_WAITLIST_BOOKING_VALUES.declineAppointmentAndDeleteWaitlistRequest,
    label: { id: 'declineWaitlistAppointmentModal.declineOption.declineAppointmentAndDeleteWaitlistRequest' },
  },
]
