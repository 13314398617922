import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  activateServiceCategories as activateServiceCategoriesAction,
  setSelectedServiceCategories as setSelectedServiceCategoriesAction,
} from 'state/concepts/serviceCategories/actions'
import { activateServiceCategoriesLoadingSelector } from 'state/concepts/serviceCategories/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServiceCategoriesBulkActivateModal = ({ serviceCategoriesIds, onClose }) => {
  const isLoading = useSelector(activateServiceCategoriesLoadingSelector)
  const activateServiceCategories = useDispatchAction(activateServiceCategoriesAction, serviceCategoriesIds)
  const setSelectedServiceCategories = useDispatchAction(setSelectedServiceCategoriesAction)

  const handleSubmit = () => {
    activateServiceCategories()
    setSelectedServiceCategories([])
  }

  return (
    <ConfirmModal
      title={{ id: 'serviceCategories.bulkActivate.modal.title' }}
      bodyText={[
        {
          id: 'serviceCategories.bulkActivate.modal.body',
          values: { count: serviceCategoriesIds.length },
        },
      ]}
      submitText={{ id: 'shared.activate' }}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServiceCategoriesBulkActivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategoriesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ServiceCategoriesBulkActivateModal
