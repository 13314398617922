import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import * as types from './types'

const videoConferenceId = (state = null, action) => {
  switch (action.type) {
    case types.SET_VIDEO_CONFERENCE:
      return action.videoConferenceId
    default:
      return state
  }
}

const isRecording = (state = null, action) => {
  switch (action.type) {
    case types.SET_IS_RECORDING:
      return action.isRecording
    default:
      return state
  }
}

const isScreenShared = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_SCREEN_SHARED:
      return action.isScreenShared
    default:
      return state
  }
}

const settingsInitialState = {
  audioDevice: undefined,
  videoDevice: undefined,
  isVideoEnabled: true,
  isAudioEnabled: true,
}
const localSettings = (state = settingsInitialState, action) => {
  switch (action.type) {
    case types.SET_LOCAL_SETTINGS:
      return { ...state, ...action.settings }
    case types.RESET_LOCAL_SETTINGS:
      return settingsInitialState
    default:
      return state
  }
}

const isPreview = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_PREVIEW:
      return action.isPreview
    default:
      return state
  }
}

const chatMessages = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_CHAT_MESSAGE:
      return [...state, action.message]
    case types.CLEAR_CHAT_MESSAGES:
      return []
    default:
      return state
  }
}

const videoRecordEventIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_VIDEO_RECORD_EVENTS:
      return action.videoRecordEventIds
    case types.ADD_VIDEO_RECORD_EVENTS:
      return uniq([...state, action.videoRecordEventId])
    default:
      return state
  }
}

const isSoundTracked = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_SOUND_TRACKED:
      return action.isSoundTracked
    default:
      return state
  }
}

const toggleSidebarOpen = (state = false, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR_OPEN:
      return action.value
    default:
      return state
  }
}

export default combineReducers({
  videoConferenceId,
  isRecording,
  localSettings,
  isPreview,
  isScreenShared,
  chatMessages,
  videoRecordEventIds,
  isSoundTracked,
  toggleSidebarOpen,
})
