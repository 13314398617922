import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { companySettingsSubscriptionRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { SET_BILLING_ACCOUNT } from 'state/concepts/billing/types'
import { setBillingAccountEndpoint } from 'state/concepts/billing/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const setBillingAccount = createLogic({
  type: SET_BILLING_ACCOUNT,
  latest: true,

  async process({ getState, httpClient, action: { form, values } }, dispatch, done) {
    const { endpoint, url } = setBillingAccountEndpoint
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const { extraInformation: newExtraInfo, skipRedirect } = values
    const isRemoved = !newExtraInfo

    const params = {
      extra_information: newExtraInfo,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))

      if (isRemoved) {
        dispatch(showNotification({ messageObject: { id: 'notifications.extraBillingInformationHasBeenRemoved' } }))
      } else {
        dispatch(showNotification({ messageObject: { id: 'notifications.extraBillingInformationHasBeenChanged' } }))
      }
      if (!skipRedirect) {
        redirect({ href: companySettingsSubscriptionRoute, workspace })
      }
    } catch (error) {
      form?.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    done()
  },
})

export default setBillingAccount
