import { createLogic } from 'redux-logic'
import { trim, map, compose, omit, defaultTo } from 'ramda'

import safeTrim from 'lib/safeTrim'
import requestErrorHandler from 'lib/requestErrorHandler'
import { ONBOARDING_STEPS_NAMES } from 'lib/constants/employees'
import { workspaceRoute } from 'lib/apiRoutes'
import { companyAvailabilityRecurringRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import updateDataHelper from 'utils/updateDataHelper'
import { SUBMIT_ONBOARDING_COMPANY_PROFILE } from 'state/concepts/employee/types'
import { updateOnboardingStep } from 'state/concepts/employee/actions'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceIdSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { updateRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'

const submitOnboardingCompanyProfileOperation = createLogic({
  type: SUBMIT_ONBOARDING_COMPANY_PROFILE,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const formData = new FormData()

    try {
      const state = getState()
      const currentWorkspaceId = currentWorkspaceIdSelector(state)

      formData.append('business_name', safeTrim(values.businessName))
      formData.append('industry', safeTrim(values.industry))
      formData.append('country', safeTrim(values.country))
      formData.append('city', safeTrim(values.city))
      formData.append('zip_code', safeTrim(values.zipCode))
      formData.append('address', safeTrim(values.address))
      formData.append('phone', values.phone)
      formData.append('email', safeTrim(values.email))

      if (values.logo === null) {
        formData.append('remove_logo', true)
      } else if (values.logo instanceof Blob) {
        formData.append('logo', values.logo, values.logo.name)
      }

      await httpClient.put(workspaceRoute, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      const attributes = map(compose(trim, defaultTo('')), omit(['logoUrl', 'logo', 'googleAddress'], values))
      const response = updateDataHelper(state.data, 'workspace', currentWorkspaceIdSelector(state), {
        attributes: {
          ...attributes,
          logoUrl: values.logo ? values.logoUrl : null,
        },
      })

      dispatch(
        updateRecentWorkspace({
          id: currentWorkspaceId,
          onboardingStatus: ONBOARDING_STEPS_NAMES.completed,
        }),
      )
      dispatch(dataApiSuccess({ response }))
      dispatch(updateOnboardingStep(ONBOARDING_STEPS_NAMES.completed))
      redirect({ href: companyAvailabilityRecurringRoute, workspace: currentWorkspaceCodeSelector(state) })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default submitOnboardingCompanyProfileOperation
