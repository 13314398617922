import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Copy = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M76.8 102.4c14.139 0 25.6 11.462 25.6 25.6l-.02 332.779 256.021.021c14.139 0 25.6 11.462 25.6 25.6s-11.462 25.6-25.6 25.6H81.921c-16.966 0-30.72-13.754-30.72-30.72V128c0-14.138 11.462-25.6 25.6-25.6zM353.083.988a26.719 26.719 0 0 1 8.378 3.471c2.205 1.379 4.061 2.815 7.864 6.502l78.016 77.981c5.609 5.609 7.346 7.752 9 10.398s2.769 5.337 3.471 8.379c.701 3.041.988 5.785.988 13.717v209.403c0 27.387-2.852 37.319-8.206 47.331s-13.212 17.87-23.225 23.225c-10.012 5.355-19.944 8.206-47.331 8.206H232.364c-27.387 0-37.319-2.852-47.331-8.206s-17.87-13.212-23.225-23.225c-5.355-10.012-8.206-19.944-8.206-47.331V78.764c0-27.388 2.852-37.319 8.206-47.331s13.213-17.87 23.225-23.225C195.045 2.853 204.977.002 232.364.002h107.003c7.932 0 10.676.287 13.717.988zM307.2 51.2h-63.019c-13.694 0-18.66 1.425-23.666 4.103s-8.935 6.606-11.613 11.612c-2.343 4.38-3.727 8.73-4.037 18.922l-.066 4.743v228.438c0 13.694 1.425 18.659 4.103 23.666s6.606 8.935 11.613 11.613c4.381 2.342 8.73 3.727 18.922 4.037l4.743.067h126.037c13.694 0 18.659-1.425 23.666-4.103s8.935-6.606 11.613-11.613c2.342-4.38 3.727-8.73 4.037-18.922l.067-4.743V153.602h-40.96c-33.933 0-61.44-27.507-61.44-61.44v-40.96z" />
  </svg>
)

Copy.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Copy.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Copy
