import React from 'react'
import PropTypes from 'prop-types'

import DropdownComponent from './component'

class Dropdown extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node).isRequired, PropTypes.node]),
  }

  static defaultProps = {
    children: null,
  }

  dropdownRender = menus => {
    const { children } = this.props

    return menus || children
  }

  render = () => <DropdownComponent dropdownRender={this.dropdownRender} {...this.props} />
}

export default Dropdown
