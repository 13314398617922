import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CLIENT_NOTES } from 'state/concepts/companyClientNotes/types'
import { fetchClientNotesEndpoint } from 'state/concepts/companyClientNotes/endpoints'
import { addClientNotesIds } from 'state/concepts/companyClientNotes/actions'

const fetchClientNotesOperation = createLogic({
  type: FETCH_CLIENT_NOTES,
  latest: true,

  async process(
    {
      httpClient,
      action: {
        clientId,
        pageParams: { nextCursor = null },
      },
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = fetchClientNotesEndpoint(clientId)
    const params = {
      page: { after: nextCursor },
      include: 'user-profile',
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addClientNotesIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchClientNotesOperation
