import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_FORM_SUBMISSION } from 'state/concepts/form/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchFormSubmissionEndpoint } from 'state/concepts/form/endpoints'

const fetchFormSubmissionOperation = createLogic({
  type: FETCH_FORM_SUBMISSION,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = fetchFormSubmissionEndpoint(id)
    const params = {
      include: [
        'form',
        'form_answers',
        'form_answers.form_question',
        'form_answers.form_question.form_question_options',
        'form_answers.form_answer_values.form_question_option',
        'client_signature',
        'expert_signature',
        'submitted_by.client_relationship_type',
        'client_signature.signed_by',
        'expert_signature.signed_by',
        'client_profile_form.declined_by.client_relationship_type',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchFormSubmissionOperation
