import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_CHAT } from 'state/concepts/chats/types'
import { getChatEndpoint } from 'state/concepts/chat/endpoints'
import { addChats } from 'state/concepts/chats/actions'

const getChatOperation = createLogic({
  type: GET_CHAT,
  latest: true,

  async process({ httpClient, action: { chatId } }, dispatch, done) {
    const { endpoint, url } = getChatEndpoint(chatId)

    const params = {
      include: ['workspace', 'message'],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addChats([chatId]))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403, 404] })
    }
    done()
  },
})

export default getChatOperation
