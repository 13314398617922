import { omit, pick, pathOr, path, prop, head, isEmpty } from 'ramda'
import camelCase from 'lodash/camelCase'

import formatJsonApiErrors from 'utils/formatJsonApiErrors'

const assignFormErrors = (form, error, statusKeys = ['base']) => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error)
  const formattedErrors = formatJsonApiErrors(errors)

  const metaType = path([0, 'meta', 'type'], errors)
  const baseError = pick(statusKeys, formattedErrors)

  if (metaType) {
    form.setStatus({ [camelCase(metaType)]: prop(head(statusKeys), baseError) })
  } else if (!isEmpty(baseError)) {
    form.setStatus(baseError)
  }

  form.setErrors(omit(statusKeys, formattedErrors))
}

export default assignFormErrors
