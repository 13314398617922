const websitePreview = {
  'websitePreview.manageWebsite': 'Manage website',
  'websitePreview.publishWebsite': 'Publish website',
  'websitePreview.bookAppointment': 'Book _(appointment)',
  'websitePreview.clientPortal': '_(Client) portal',
  'websitePreview.editWebsite': 'Edit website',
  'websitePreview.enableDisableSections': 'Enable/Disable website sections',
  'websitePreview.getSeoSettings': 'Get found on Google (SEO settings)',
  'websitePreview.enableDisableSections.experts.title': '_(Experts)',
  'websitePreview.enableDisableSections.experts.description': 'Show _(experts) assigned to the selected booking page.',
  'websitePreview.enableDisableSections.services.title': '_(Services)',
  'websitePreview.enableDisableSections.services.description':
    'Show _(services) assigned to the selected booking page.',
  'websitePreview.enableDisableSections.locations.title': 'Locations',
  'websitePreview.enableDisableSections.locations.description':
    'Show a list of locations where you provide _(services).',
  'websitePreview.enableDisableSections.reviews.title': 'Reviews',
  'websitePreview.enableDisableSections.reviews.description': 'Show _(client) reviews and ratings.',
  'websitePreview.enableDisableSections.contacts.title': 'Contacts',
  'websitePreview.enableDisableSections.contacts.description':
    'Show your contact details and links to your social media.',
  'websitePreview.googleSeoSection.title.description':
    'Choose how your website will appear in search results by editing your page title and its description.',
  'websitePreview.googleSeoSection.title.label': 'Page title',
  'websitePreview.googleSeoSection.metaDescription.label': 'Meta description',
  'websitePreview.editWelcomeSection': 'Edit section',
  'websitePreview.welcomeSectionSidebar.title': 'Welcome section',
  'websitePreview.welcomeSectionSidebar.textContent': 'Text content',
  'websitePreview.welcomeSectionSidebar.header.label': 'Header',
  'websitePreview.welcomeSectionSidebar.subheader.label': 'Subheader',
  'websitePreview.welcomeSectionSidebar.backgroundImage': 'Background image',
}

export default websitePreview
