// eslint-disable-next-line import/prefer-default-export
export const RESERVED_SUBDOMAINS = [
  'www',
  'app',
  'api',
  'ws',
  'wss',
  'booking',
  'widget',
  'staging',
  'stagingapp',
  'staging2app',
  'stagingapi',
  'stagingbooking',
  'stagingwidget',
  'intg',
  'intgapp',
  'intgapi',
  'intgbooking',
  'intgwidget',
  'intg2',
  'intg2app',
  'intg2api',
  'intg2booking',
  'intg2widget',
  'localhost:4000',
]
