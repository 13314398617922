import { BOOKING_STATUSES } from './bookings'

export const UPCOMING_BOOKINGS = [BOOKING_STATUSES.scheduled, BOOKING_STATUSES.rescheduled]

export const CANCELLED_BOOKINGS = [BOOKING_STATUSES.cancelled]

export const PENDING_BOOKINGS = [BOOKING_STATUSES.pending]

export const PAST_BOOKINGS = [BOOKING_STATUSES.completed]

export const BOOKINGS_TYPES = {
  upcoming: 'upcoming',
  past: 'past',
  cancelled: 'cancelled',
  pending: 'pending',
  waitlist: 'waitlist',
}

export const WIDGET_BOOKING_SUCCESS_EVENT = 'ExpertBox::BOOKING_SUCCESS'
