import endpoint from 'utils/endpoint'
import {
  bookingsCancelRoute,
  recurringBookingsCancelRoute,
  bookingsValidationsCancelRoute,
  bookingsValidationsRescheduleRoute,
  calendarEventsRoute,
  bookingsConflictsRoute,
} from 'lib/apiRoutes'
import {
  CANCEL_BOOKING,
  CANCEL_RECURRING_BOOKINGS,
  VALIDATE_CANCEL_BOOKING,
  VALIDATE_RESCHEDULE_BOOKING,
  FETCH_CALENDAR_EVENTS,
  CHECK_TIME_CONFLICTS,
} from './types'

export const cancelBookingEndpoint = bookingId => endpoint(CANCEL_BOOKING, 'POST', bookingsCancelRoute(bookingId))
export const cancelRecurringBookingsEndpoint = bookingId =>
  endpoint(CANCEL_RECURRING_BOOKINGS, 'POST', recurringBookingsCancelRoute(bookingId))
export const validateCancelBookingEndpoint = bookingId =>
  endpoint(VALIDATE_CANCEL_BOOKING, 'GET', bookingsValidationsCancelRoute(bookingId))
export const validateRescheduleBookingEndpoint = bookingId =>
  endpoint(VALIDATE_RESCHEDULE_BOOKING, 'GET', bookingsValidationsRescheduleRoute(bookingId))
export const fetchCalendarEventsEndpoint = endpoint(FETCH_CALENDAR_EVENTS, 'GET', calendarEventsRoute)
export const checkTimeConflictsEndpoint = endpoint(CHECK_TIME_CONFLICTS, 'GET', bookingsConflictsRoute)
