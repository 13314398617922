import expertboxImg from 'assets/images/logo-mob.svg'
import googleMeetImg from 'assets/images/videoCallsIntegration/google_meet.svg'
import microsoftTeamsImg from 'assets/images/videoCallsIntegration/microsoft_teams.svg'
import zoomImg from 'assets/images/videoCallsIntegration/zoom.svg'

import { VIDEO_CALLS_PROVIDERS } from './videoCalls'

export const LOCATION_STATUS = {
  active: 'active',
  deactivated: 'inactive',
}

export const ONLINE_LOCATION_OPTION = {
  value: 'online',
  label: {
    [VIDEO_CALLS_PROVIDERS.expertbox]: { id: 'scheduleAppointment.locationSelect.expertbox' },
    [VIDEO_CALLS_PROVIDERS.googleMeet]: { id: 'scheduleAppointment.locationSelect.google_meet' },
    [VIDEO_CALLS_PROVIDERS.microsoftTeams]: { id: 'scheduleAppointment.locationSelect.microsoft_teams' },
    [VIDEO_CALLS_PROVIDERS.zoom]: { id: 'scheduleAppointment.locationSelect.zoom' },
  },
}

export const ONLINE_LOCATION_ICONS = {
  [VIDEO_CALLS_PROVIDERS.expertbox]: expertboxImg,
  [VIDEO_CALLS_PROVIDERS.googleMeet]: googleMeetImg,
  [VIDEO_CALLS_PROVIDERS.microsoftTeams]: microsoftTeamsImg,
  [VIDEO_CALLS_PROVIDERS.zoom]: zoomImg,
}

export const MAX_NAME_LENGTH = 70

export const ADDRESS_MAX_LENGTH = 150

export const DEFAULT_MAP_ZOOM = 0

export const MAP_WITH_MARKER_ZOOM = 17

export const LOAD_SCRIPT_LIBRARIES = ['places']

export const ONLINE_KEY = 'online'

export const OFFLINE_KEY = 'offline'

export const DELETE_LOCATION_ACTION_VALUE = {
  remove: 'remove',
  move: 'move',
}

export const DELETE_LOCATION_ACTION_OPTIONS = [
  {
    value: DELETE_LOCATION_ACTION_VALUE.remove,
    label: { id: 'locations.delete.modal.actionField.remove' },
  },
  {
    value: DELETE_LOCATION_ACTION_VALUE.move,
    label: { id: 'locations.delete.modal.actionField.move' },
  },
]
