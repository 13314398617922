const clientProfile = {
  'clientProfile.title': 'Personal profile',
  'clientProfile.firstName': 'First name',
  'clientProfile.lastName': 'Last name',
  'clientProfile.email': 'Email',
  'clientProfile.lastActivityLabel': '_(Client) portal last active',
  'clientProfile.lastActivity.never': 'Never',
  'clientProfile.timezone': 'Timezone',
  'clientProfile.personalDetails': 'Personal details',
  'clientProfile.uploadPhoto': 'Upload photo',
  'clientProfile.updatePhoto': 'Update photo',

  'clientEditProfile.upload': 'Upload photo',
  'clientEditProfile.doYouWantToRemove': 'Do you want to remove your profile image? You can add it later.',

  'clientInformations.customFieldsAdviceTip.text':
    'To collect extra information about your _(clients), add custom fields in <b>{link}</b> section.',
  'clientInformations.customFieldsAdviceTip.link': '_(Client) fields',
  'clientInformations.toggleClientInformationBtnTip': 'Show or hide _(client) information whenever you need it.',
}

export default clientProfile
