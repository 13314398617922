import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const uploadFile = makeFormSubmitAction(types.UPLOAD_FILE)
export const validateClientImport = makeFormSubmitAction(types.VALIDATE_CLIENT_IMPORT)
export const processClientImport = makeFormSubmitAction(types.PROCESS_CLIENT_IMPORT)

export const receiveClientImport = data => ({
  type: types.RECEIVE_CLIENT_IMPORT,
  data,
})

export const setUploadProgress = progress => ({
  type: types.SET_UPLOAD_PROGRESS,
  progress,
})

export const cancelUpload = () => ({
  type: types.CANCEL_UPLOAD_FILE,
})

export const setClientImportId = clientImportId => ({
  type: types.SET_CLIENT_IMPORT_ID,
  clientImportId,
})

export const resetClientImport = () => ({
  type: types.RESET_CLIENT_IMPORT_ID,
})

export const setMatchFormState = values => ({
  type: types.SET_MATCH_FORM_STATE,
  values,
})

export const setErrorsFileUrl = url => ({
  type: types.SET_ERRORS_FILE_URL,
  url,
})

export const resetErrorsFileUrl = () => ({
  type: types.RESET_ERRORS_FILE_URL,
})

export const setIsProcessing = isProcessing => ({
  type: types.SET_IS_PROCESSING,
  isProcessing,
})
