const namespace = 'widget'

export const CREATE_WIDGET = `${namespace}/CREATE_WIDGET`
export const FETCH_WIDGET = `${namespace}/FETCH_WIDGET`
export const UPDATE_WIDGET = `${namespace}/UPDATE_WIDGET`
export const SEND_INSTRUCTIONS = `${namespace}/SEND_INSTRUCTIONS`
export const DELETE_WIDGET = `${namespace}/DELETE_WIDGET`
export const TOGGLE_WIDGET_STATUS = `${namespace}/TOGGLE_WIDGET_STATUS`
export const SET_WIDGET = `${namespace}/SET_WIDGET`
export const FETCH_WIDGET_SERVICES = `${namespace}/FETCH_WIDGET_SERVICES`
export const SET_WIDGET_SERVICES = `${namespace}/SET_WIDGET_SERVICES`
export const UPDATE_WIDGET_SERVICES = `${namespace}/UPDATE_WIDGET_SERVICES`
export const SET_WIDGET_SERVICES_BLANK_STATE = `${namespace}/SET_WIDGET_SERVICES_BLANK_STATE`
export const VALIDATE_WIDGET_SETTINGS = `${namespace}/VALIDATE_WIDGET_SETTINGS`
