import { createLogic } from 'redux-logic'

import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { DISABLE_TWO_FACTOR_SETTINGS } from 'state/concepts/employee/types'
import { dataApiRequest, dataClearRelationship } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { disableTwoFactorSettingsEndpoint } from '../endpoints'

const disableTwoFactorSettingsOperation = createLogic({
  type: DISABLE_TWO_FACTOR_SETTINGS,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const { endpoint, url } = disableTwoFactorSettingsEndpoint
    const { password } = values
    const { id: userProfileId } = currentEmployeeSelector(getState())
    const params = {
      password,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url, { params })

      dispatch(
        dataClearRelationship({
          kind: 'userProfile',
          ids: [userProfileId],
          relationship: 'userProfileTwoFactorSetting',
          isPlural: false,
        }),
      )
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.employeeTwoFactorSettingsDisabled',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default disableTwoFactorSettingsOperation
