import { createLogic } from 'redux-logic'
import { pick } from 'ramda'

import underscorize from 'utils/underscorize'
import requestErrorHandler from 'lib/requestErrorHandler'
import { websiteRoute } from 'lib/apiRoutes'
import { companySettingsWebsiteRoute } from 'lib/routes'
import { WEBSITE_STATUSES } from 'lib/constants/website'
import redirect from 'utils/redirect'
import { hideSidebar } from 'state/sidebar/actions'
import { showNotification } from 'state/notifications/actions'
import { PUBLISH_DRAFT_WEBSITE } from 'state/concepts/website/types'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { resetWebsiteDraft } from '../actions'
import { websiteDraftSelector } from '../selectors'

const publishDraftWebsiteOperation = createLogic({
  type: PUBLISH_DRAFT_WEBSITE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    try {
      const website = websiteDraftSelector(getState())
      const workspace = workspaceSelector(getState()).uniqCode
      const params = {
        ...underscorize(pick(['backgroundImage', 'headerTitle', 'headerSubtitle'], website)),
        website_setting: underscorize(
          pick(
            ['experts', 'services', 'locations', 'reviews', 'contacts', 'pageTitle', 'metaDescription'],
            website.websiteSetting,
          ),
        ),
        status: WEBSITE_STATUSES.published,
      }

      await httpClient.put(websiteRoute, params)

      dispatch(resetWebsiteDraft())
      await redirect({
        href: companySettingsWebsiteRoute,
        workspace,
      })

      dispatch(hideSidebar())
      dispatch(showNotification({ messageObject: { id: 'notifications.websiteWasPublished' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default publishDraftWebsiteOperation
