import { createLogic } from 'redux-logic'
import { findKey } from 'lodash'
import { equals } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { hideSidebar } from 'state/sidebar/actions'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { removeClientBookings } from 'state/concepts/companyClientBookings/actions'
import {
  fetchWaitlistRequestsMetaSelector,
  hasFiltersSelector,
  waitlistRequestSelector,
  waitlistRequestsIdsSelector,
} from 'state/concepts/waitlistRequests/selectors'
import { WAITLIST_STATUSES } from 'lib/constants/waitlistRequests'
import { removeWaitlistRequest, setBlankState } from 'state/concepts/waitlistRequests/actions'
import { isGuestLoggedInSelector } from 'state/concepts/session/selectors'
import redirect from 'utils/redirect'
import { guestLinkExpiredRoute } from 'lib/routes'
import { DELETE_WAITLIST_REQUEST } from '../types'
import { deleteWaitlistRequestEndpoint, fetchWaitlistRequestsEndpoint } from '../endpoints'

const deleteWaitlist = createLogic({
  type: DELETE_WAITLIST_REQUEST,
  latest: true,

  async process({ httpClient, action: { id, feedId }, getState }, dispatch, done) {
    const { url, endpoint } = deleteWaitlistRequestEndpoint(id)
    const state = getState()
    const isGuestLoggedIn = isGuestLoggedInSelector(state)
    const waitlistRequestsEndpoint = fetchWaitlistRequestsEndpoint.endpoint
    const meta = fetchWaitlistRequestsMetaSelector(state)
    const waitlistRequest = waitlistRequestSelector(state, id)
    const waitlistRequestIds = waitlistRequestsIdsSelector(state)
    const hasFilters = hasFiltersSelector(state)

    const status = findKey(WAITLIST_STATUSES, equals(waitlistRequest.status))
    const counterName = `${status}Count`

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(hideSidebar())
      if (feedId) {
        dispatch(removeClientFeedIds([feedId]))
        dispatch(removeClientBookings([feedId]))
      } else {
        dispatch(removeWaitlistRequest([id]))
        const updatedMeta = { totalCount: meta.totalCount - 1, [counterName]: meta[counterName] - 1 }

        dispatch(
          dataApiSuccess({
            endpoint: waitlistRequestsEndpoint,
            response: { meta: { [waitlistRequestsEndpoint]: { meta: updatedMeta } } },
          }),
        )
        if (waitlistRequestIds.length === 1 && !hasFilters) {
          dispatch(setBlankState(true))
        }
      }
      feedId && dispatch(dataDelete({ kind: 'clientFeed', ids: [feedId] }))
      dispatch(dataDelete({ kind: 'waitlistRequest', ids: [id] }))
      !isGuestLoggedIn &&
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.deleteWaitlistSuccess',
            },
          }),
        )
    } catch (error) {
      isGuestLoggedIn ? redirect({ href: guestLinkExpiredRoute }) : requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteWaitlist
