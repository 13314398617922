import endpoint from 'utils/endpoint'
import { clientNotesRoute, clientNoteRoute, clientNoteShareRoute, clientNotePersonalRoute } from 'lib/apiRoutes'
import { FETCH_CLIENT_NOTES, DELETE_CLIENT_NOTE, ADD_NOTE, EDIT_NOTE } from './types'

export const fetchClientNotesEndpoint = clientId => endpoint(FETCH_CLIENT_NOTES, 'GET', clientNotesRoute(clientId))

export const deleteClientNoteEndpoint = (clientId, clientNoteId) =>
  endpoint(DELETE_CLIENT_NOTE, 'DELETE', clientNoteRoute(clientId, clientNoteId))

export const addNoteEndpoint = clientId => endpoint(ADD_NOTE, 'POST', clientNotesRoute(clientId))

export const editNoteEndpoint = (clientId, clientNoteId) =>
  endpoint(EDIT_NOTE, 'PUT', clientNoteRoute(clientId, clientNoteId))

export const makeNotePersonalEndpoint = (clientId, clientNoteId) =>
  endpoint(EDIT_NOTE, 'POST', clientNotePersonalRoute(clientId, clientNoteId))

export const shareNoteEndpoint = (clientId, clientNoteId) =>
  endpoint(EDIT_NOTE, 'POST', clientNoteShareRoute(clientId, clientNoteId))
