import { createLogic } from 'redux-logic'

import { LOGIN_TO_RECENT_WORKSPACE } from 'state/concepts/recentWorkspaces/types'
import { getPortalUrl } from 'utils/clientPortal'
import redirect from 'utils/redirect'

const loginToRecentWorkspaceOperation = createLogic({
  type: LOGIN_TO_RECENT_WORKSPACE,
  latest: true,

  process({ action: { workspace } }, dispatch, done) {
    const { url } = workspace
    const portalUrl = getPortalUrl(url)
    redirect({ href: `${portalUrl}/login`, reload: true })
    done()
  },
})

export default loginToRecentWorkspaceOperation
