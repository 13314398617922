const shared = {
  'shared.expertBox': 'ExpertBox',
  'shared.expertBox.address.firstLine': 'Go Consulted INC 200 Continental Drive,',
  'shared.expertBox.address.secondLine': 'Suite 401, Newark, Delaware, 19713,',
  'shared.expertBox.address.thirdLine': 'United States of America',
  'shared.submit': 'Submit',
  'shared.signAndSubmit': 'Sign and submit',
  'shared.subscribe': 'Subscribe',
  'shared.contactUs': 'Contact us',
  'shared.save': 'Save',
  'shared.saveChanges': 'Save changes',
  'shared.saveAndContinue': 'Save & continue',
  'shared.crop': 'Crop',
  'shared.send': 'Send',
  'shared.discard': 'Discard',
  'shared.email': 'Email',
  'shared.name': 'Name',
  'shared.rename': 'Rename',
  'shared.phoneNumber': 'Phone number',
  'shared.close': 'Close',
  'shared.cancel': 'Cancel',
  'shared.move': 'Move',
  'shared.share': 'Share',
  'shared.ok': 'Ok',
  'shared.search': 'Search',
  'shared.password': 'Password',
  'shared.apply': 'Apply',
  'shared.resetAll': 'Reset all',
  'shared.clearAll': 'Clear all',
  'shared.reset': 'Reset',
  'shared.resetDate': 'Reset date',
  'shared.noSearchResult': 'There are no results for your search request.',
  'shared.next': 'Next',
  'shared.back': 'Back',
  'shared.complete': 'Complete',
  'shared.no': 'No',
  'shared.noName': 'No name',
  'shared.notFound': 'Not Found',
  'shared.noResults': 'No results',
  'shared.noResultsFound': 'No results found',
  'shared.noSearchResultsTitle': 'No results',
  'shared.alt.noResults': 'No results',
  'shared.filters.statuses.title': 'By status',
  'shared.filters.statuses.active': 'Active',
  'shared.filters.statuses.all': 'All',
  'shared.filters.statuses.inactive': 'Deactivated',
  'shared.filters.statuses.pending': 'Pending',
  'shared.filters.labels.experts': 'All _(experts)',
  'shared.filters.labels.services': 'All _(services)',
  'shared.filters.appliedFilters': '{count} filters',
  'shared.filters.defaultLabel': 'All categories',
  'shared.activate': 'Activate',
  'shared.deactivate': 'Deactivate',
  'shared.edit': 'Edit',
  'shared.copy': 'Copy',
  'shared.copyLink': 'Copy link',
  'shared.assign': 'Assign',
  'shared.create': 'Create',
  'shared.remove': 'Remove',
  'shared.delete': 'Delete',
  'shared.preview': 'Preview',
  'shared.discardChanges': 'Discard changes?',
  'shared.cancelText': 'Are you sure you want to discard changes? All data will be lost.',
  'shared.saveChangesTitle': 'Save changes?',
  'shared.saveBeforeContinueText': 'You need to save the changes before you can continue.',
  'shared.update': 'Update',
  'shared.enable': 'Enable',
  'shared.disable': 'Disable',
  'shared.enabled': 'Enabled',
  'shared.disabled': 'Disabled',
  'shared.done': 'Done',
  'shared.dismiss': 'Dismiss',
  'shared.left': 'Left',
  'shared.right': 'Right',
  'shared.top': 'Top',
  'shared.bottom': 'Bottom',
  'shared.retry': 'Retry',
  'shared.areYouSureYouWantToRemove': 'Are you sure you want to remove {name}?',
  'shared.download': 'Download',
  'shared.attachment': 'Attachment',
  'shared.leave': 'Leave',
  'shared.disabledWhileEditing': 'Disabled while editing.',
  'shared.saveOrDiscard': 'Save or discard to proceed navigation.',
  'shared.youAreOfflineNow': 'You are offline now',
  'shared.hide': 'Hide',
  'shared.show': 'Show',
  'shared.showMore': 'Show more',
  'shared.showDetails': 'Show details',
  'shared.hideDetails': 'Hide details',
  'shared.showAll': 'Show all',
  'shared.showLess': 'Show less',
  'shared.gotIt': 'Got it',
  'shared.helpCenter': 'Help Center',
  'shared.learnMore': 'Learn more',
  'shared.skip': 'Skip',
  'shared.finish': 'Finish',
  'shared.free': 'Free',
  'shared.exit': 'Exit',
  'shared.today': 'Today',
  'shared.yesterday': 'Yesterday',
  'shared.tomorrow': 'Tomorrow',
  'shared.weekAgo': 'a week ago',
  'shared.otherDay': '{date}',
  'shared.formatTimeDays': '{count} {count, plural, one {day} other {days}} ago',
  'shared.formatTimeHours': '{count} {count, plural, one {hour} other {hours}} ago',
  'shared.formatTimeMinutes': '{count} min ago',
  'shared.createdAt.today': 'Today at {time}',
  'shared.createdAt.yesterday': 'Yesterday at {time}',
  'shared.createdAt.otherDay': '{date} at {time}',
  'shared.updatedAt.today': 'Updated today at {time}',
  'shared.updatedAt.yesterday': 'Updated yesterday at {time}',
  'shared.updatedAt.otherDay': 'Updated {date} at {time}',
  'shared.decline': 'Decline',
  'shared.accept': 'Accept',
  'shared.unsavedChanges': 'Unsaved changes',
  'shared.confirm': 'Confirm',
  'shared.confirmChanges': 'Confirm changes',
  'shared.login': 'Log in',
  'shared.selectDate': 'Select date',
  'shared.nextStep': 'Next step',
  'shared.change': 'Change',
  'shared.clear': 'Clear',
  'shared.clearField': 'Clear field',
  'shared.https': 'https://',
  'shared.mainSite': '.expertbox.io',
  'shared.select': 'Select',
  'shared.backToPreviousStep': 'Back to previous step',
  'shared.previous': 'Previous',
  'shared.selectColor': 'Select color',
  'shared.selectOption': 'Select option',
  'shared.selectOptions': 'Select options',
  'shared.all': 'All',
  'shared.view': 'View',
  'shared.review': 'Review',
  'shared.reviewResponse': 'Review response',
  'shared.upload': 'Upload',
  'shared.service': '_(Service)',
  'shared.time': 'Time',
  'shared.duplicate': 'Duplicate',
  'shared.makeCopy': 'Make a copy',
  'shared.connect': 'Connect',
  'shared.disconnect': 'Disconnect',
  'shared.searchByName': 'Search by name',
  'shared.tryAdjustingSearch': 'Try adjusting your search or filters',
  'shared.reloadPage': 'Reload page',

  'shared.cancelRequest': 'Cancel request',
  'shared.cancelRequest?': 'Cancel request?',
  'shared.saveContinue': 'Save and continue',

  'tooltips.more': '{count} more...',

  'filters.employees': '{count, plural, one {_(Expert): {value}} other {_(Expert)}}',
  'filters.assignee': '{count, plural, one {Assignee: {value}} other {Assignee}}',
  'filters.linkedClients': '{count, plural, one {Linked _(client): {value}} other {Linked _(clients)}}',
  'filters.services': '{count, plural, one {_(Service): {value}} other {_(Service)}}',
  'filters.clients': '{count, plural, one {_(Client): {value}} other {_(Client)}}',
  'filters.statuses': '{count, plural, one {Status: {value}} other {Status}}',
  'filters.ratings': '{count, plural, one {Rating: {value}} other {Rating}}',
  'filters.dateRange': '{count, plural, =0 {Date range} other {Date range <b>{value}</b>}}',
  'filters.locations': '{count, plural, one {Location: {value}} other {Location}}',
  'filters.clear': 'Clear filters',
  'filters.searchPlaceholder.services': 'Search _(services)',
  'filters.searchPlaceholder.experts': 'Search _(experts)',
  'filters.searchPlaceholder.clients': 'Search _(clients)',
  'filters.forms': '{count, plural, =0 {All forms} one {{value}} other {{count} filters}}',
  'filters.documentTemplates': '{count, plural, one {{value}} other {All templates}}',
  'filters.types': '{count, plural, one {Type: {value}} other {Type}}',
  'filters.lastModified': '{count, plural, one {Last modified: <span>{value}</span>} other {Last modified}}',
  'filters.shared': '{count, plural, one {Shared: <span>{value}</span>} other {Shared}}',
  'filters.trashed': '{count, plural, one {Trashed: <span>{value}</span>} other {Trashed}}',
  'filters.owners': '{count, plural, one {Owner: {value}} other {Owner}}',

  'timeUnits.minutes': 'Minute',
  'timeUnits.hours': 'Hour',
  'timeUnits.days': 'Day',
  'timeUnits.months': 'Month',
  'timeUtils.interval.15': 'Every 15 min',
  'timeUtils.interval.30': 'Every 30 min',
  'timeUtils.interval.45': 'Every 45 min',

  'seo.description': 'ExpertBox: All-in-one online _(client) meeting tool to streamline consulting _(services).',

  'cookies.text': 'This website uses cookies to ensure you get the best experience on our website. ',
  'cookies.button': 'Agree & Dismiss',

  'placeholders.companyUrl': 'company-workspace-url',
  'placeholders.selectCountry': 'Select country',
  'placeholders.selectIndustry': 'Select industry',
  'placeholders.selectTimezone': 'Select timezone',
  'placeholders.emptyTimezone': 'No timezones matching your search',
  'placeholders.selectRole': 'Select role',
  'placeholders.searchUser': 'Search User',
  'placeholders.duration': 'e.g. 30m, 1h 30m',
  'placeholders.selectLocation': 'Select offline locations',
  'placeholders.selectBookingPage': 'Select booking page',

  'pagination.first': 'First',
  'pagination.last': 'Last',

  'provision.freeSession': 'Free session',

  'errorPage.generalNotification': 'Sorry, something went wrong. Try reloading the page.',
  'errorPage.tooManyRequestsNotification': 'Please try again in a few moments.',
  'accessErrorPage.title': 'Access forbidden',
  'accessErrorPage.body': 'The page you are trying to access has restricted access.',
  'accessErrorPage.button': '_(Appointments)',
  'clientErrorPage.title': 'Oops!',
  'clientErrorPage.body': 'It seems we have lost this page but we don’t want to lose you.',
  'clientErrorPage.button': '_(Appointments)',
  'serverErrorPage.title': 'Server Error',
  'serverErrorPage.body': 'Sorry..It’s not you, it’s us. Please try again later',

  'status.active': 'Active',
  'status.inactive': 'Deactivated',
  'status.enabled': 'Enabled',
  'status.disabled': 'Disabled',

  'imageUpload.fileTypes': 'You can upload jpg or png image files.',
  'imageUpload.fileSize': 'Max size of 10Mb.',
  'shared.promocode.discount': 'Discount {number}%',
  'shared.promocode.addedPromocode': 'You’ve added promo code <span>{code}</span>',
  'shared.promocode.enterPromocode': 'Enter a promo code',
  'shared.promocode.haveAnotherPromoCode?': 'Have another promo code?',
  'shared.promocode.havePromoCode?': 'Have a promo code?',
  'shared.clientSuffix': '(_(client))',
  'shared.youSuffix': '(you)',
}

export default shared
