import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { ALERT_KINDS } from 'lib/constants'
import { addMembersEndpoint } from '../endpoints'
import { ADD_MEMBERS } from '../types'

const addMembersOperation = createLogic({
  type: ADD_MEMBERS,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { emails, bookingUniqCode, isVideoConference } = values
    const { url, endpoint } = addMembersEndpoint(bookingUniqCode)
    const params = {
      invited_members: emails.map(email => ({ email })),
      include: ['invited-members'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.invitationsWereSent',
            values: { count: emails.length },
          },
          kind: isVideoConference ? ALERT_KINDS.videocall : ALERT_KINDS.success,
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default addMembersOperation
