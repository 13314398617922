import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck, path } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_TASKS } from 'state/concepts/tasks/types'
import { addTaskIds, setTasksCounts } from 'state/concepts/tasks/actions'
import { fetchTasksEndpoint } from 'state/concepts/tasks/endpoints'
import { filtersSelector, isCompletedSelector, viewSelector } from 'state/concepts/tasks/selectors'
import { getSort } from 'utils/tasks'

const fetchTasksOperation = createLogic({
  type: FETCH_TASKS,
  latest: true,

  async process(
    {
      httpClient,
      action: {
        pageParams: { nextCursor = null },
      },
      getState,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = fetchTasksEndpoint
    const state = getState()
    const { assignee, linkedClients, completed } = filtersSelector(state)
    const view = viewSelector(state)
    const isCompleted = isCompletedSelector(state)

    const params = {
      page: { after: nextCursor },
      sort: getSort(isCompleted, view),
      include: ['user-profile', 'client-profile', 'assignee'],
      filter: {
        client_profile_ids: linkedClients,
        assignee_ids: assignee,
        completed,
      },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const meta = path(['meta', endpoint, 'meta'], response)

      dispatch(setTasksCounts(meta))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addTaskIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchTasksOperation
