import { findKey } from 'lodash'
import { pipe, keys, map, equals, reject, anyPass, isNil } from 'ramda'

import {
  CLIENT_IMPORT_FIRST_NAME_ID,
  CLIENT_IMPORT_LAST_NAME_ID,
  CLIENT_IMPORT_EMAIL_ID,
  CLIENT_IMPORT_SKIP_ID,
  CLIENT_IMPORT_PHONE_NUMBER,
} from 'lib/constants/clientsImport'

const formValuesToParams = values => {
  const firstName = findKey(values, equals(CLIENT_IMPORT_FIRST_NAME_ID))
  const lastName = findKey(values, equals(CLIENT_IMPORT_LAST_NAME_ID))
  const email = findKey(values, equals(CLIENT_IMPORT_EMAIL_ID))
  const phoneNumber = findKey(values, equals(CLIENT_IMPORT_PHONE_NUMBER))

  const profileFields = pipe(
    reject(
      anyPass([
        isNil,
        equals(CLIENT_IMPORT_SKIP_ID),
        equals(CLIENT_IMPORT_FIRST_NAME_ID),
        equals(CLIENT_IMPORT_LAST_NAME_ID),
        equals(CLIENT_IMPORT_EMAIL_ID),
        equals(CLIENT_IMPORT_PHONE_NUMBER),
      ]),
    ),
    keys,
    map(key => ({ id: values[key], title: key })),
  )(values)

  return {
    first_name_title: firstName,
    last_name_title: lastName,
    email_title: email,
    profile_fields: profileFields,
    phone_number_title: phoneNumber,
  }
}

export default formValuesToParams
