import { TIME_UNITS_DAY, TIME_UNITS_HOUR, TIME_UNITS_MINUTE, TIME_UNITS_MONTH } from './timeUnits'

export const MIN_VALUES = {
  [TIME_UNITS_MINUTE]: 15,
  [TIME_UNITS_DAY]: 1,
  [TIME_UNITS_HOUR]: 1,
  [TIME_UNITS_MONTH]: 1,
}

export const REFUND_RESCHEDULE_VALUES = {
  anytime: 'anytime',
  never: 'never',
  upTo: 'up_to_x_before_consultation',
}

export const DEFAULT_VALUES = {
  maxFutureTimeUnit: TIME_UNITS_MONTH,
  maxFutureTime: 6,
  minLeadTimeUnit: TIME_UNITS_HOUR,
  minLeadTime: 1,
  bufferBefore: '5m',
  bufferAfter: '5m',
  refundableLeadTimeUnit: TIME_UNITS_DAY,
  refundableLeadTimeAmount: undefined,
  refundableAnytime: true,
  refundable: false,
  reschedulableLeadTimeUnit: TIME_UNITS_DAY,
  reschedulableLeadTimeAmount: undefined,
  reschedulableAnytime: true,
  reschedulable: false,
}
