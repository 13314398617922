import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiSuccess } from 'state/data/actions'
import { RECEIVE_DATA } from '../types'

const receiveDataOperation = createLogic({
  type: RECEIVE_DATA,
  latest: true,

  process({ action: { data } }, dispatch, done) {
    dispatch(dataApiSuccess({ response: normalize(data) }))
    done()
  },
})

export default receiveDataOperation
