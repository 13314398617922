import { isNil } from 'ramda'

import { bookingStartTime } from 'utils/bookings'
import { durationToSeconds } from 'utils/duration'
import isEmail from 'utils/isEmail'
import recurringBookingFormValuesToParams from 'utils/bookings/recurringBookingFormValuesToParams'
import { DEFAULT_BOOKING_SETTING_PARAMS, FREE_PRICE } from 'lib/constants/scheduleAppointment'
import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'

const scheduleBookingFormToParams = (
  {
    date,
    time,
    clientProfile,
    userProfileId,
    serviceId,
    duration,
    price,
    paymentRequired,
    serviceName,
    serviceDescription,
    weeklyInterval,
    ends,
    repeat,
    byWeekday,
    repeatCount,
    untilDate,
    locationId,
    emails,
    isWaitlist,
    waitlistRequestId,
  },
  timezoneOffset,
) => {
  const isNewClient = isEmail(clientProfile.idOrEmail)
  const clientProfileParams = {
    id: isNewClient ? undefined : clientProfile.idOrEmail,
    email: isNewClient ? clientProfile.idOrEmail : undefined,
    first_name: isNewClient ? clientProfile.firstName : undefined,
    last_name: isNewClient ? clientProfile.lastName : undefined,
  }

  const bookingSettingParams = {
    ...DEFAULT_BOOKING_SETTING_PARAMS,
    payment_required: paymentRequired,
  }
  const serviceParams = {
    name: serviceName,
    description: serviceDescription,
  }

  return {
    user_profile_id: userProfileId,
    client_profile: clientProfileParams,
    ...recurringBookingFormValuesToParams({ repeat, weeklyInterval, ends, byWeekday, repeatCount, untilDate }),
    service_id: serviceId,
    start_time: bookingStartTime(date, time, timezoneOffset).toISO(),
    duration: durationToSeconds(duration),
    price: price || FREE_PRICE,
    booking_setting_attributes: bookingSettingParams,
    service_attributes: isNil(serviceId) ? serviceParams : undefined,
    location_id: locationId === ONLINE_LOCATION_OPTION.value ? '' : locationId,
    invited_members: emails.map(email => ({ email })),
    waitlist_request_id: isWaitlist ? waitlistRequestId : undefined,
  }
}

export default scheduleBookingFormToParams
