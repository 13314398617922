import { propOr, pluck, includes } from 'ramda'

export const isCalendarConnected = (calendarIntegration, calendarId, calendarType) => {
  const { calendarSyncId, calendarSyncType } = calendarIntegration || {}

  return Number(calendarSyncId) === Number(calendarId) && calendarSyncType === calendarType
}

export const splitCalendarSync = calendarSync => calendarSync?.split('::') || []

export const buildCalendarSync = calendarIntegration =>
  calendarIntegration?.calendarSyncId
    ? `${calendarIntegration.calendarSyncId}::${calendarIntegration.calendarSyncType}`
    : undefined

export const isCalendarSyncDisconnected = (connection, calendarIntegration, calendarType) => {
  const listItems = propOr([], `${calendarType}CalendarListItems`, connection)
  const { calendarSyncId, calendarSyncType } = calendarIntegration || {}

  return includes(calendarSyncId, pluck('id', listItems)) && calendarSyncType === calendarType
}
