import nodeCookie from 'cookie'
import browserCookie from 'component-cookie'

import isServer from 'utils/isServer'

const removeCookieIsomorphic = (ctx, name, options = { path: '/' }) => {
  if (ctx && isServer()) {
    if (ctx.res.finished) {
      return
    }
    const setCookieHeader = ctx.res.getHeader('Set-Cookie') || []
    ctx.res.setHeader('Set-Cookie', [
      ...setCookieHeader,
      nodeCookie.serialize(name, '', { expires: new Date(), ...options }),
    ])
  } else {
    browserCookie(name, null, options)
  }
}

export default removeCookieIsomorphic
