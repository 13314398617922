import { createSelector } from '@reduxjs/toolkit'
import build from 'redux-object'

import { dataSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import buildCollection from 'utils/buildCollection'

export const searchedChatUsersSelector = makeSelectSearchResults('chatUsers', 'chatUser')

export const chatUserByIdSelector = createSelector([(_, chatUserId) => chatUserId, dataSelector], (id, data) =>
  id ? build(data, 'chatUser', id) : null,
)

export const chatUsersByIdsSelector = createSelector([(_, ids) => ids, dataSelector], buildCollection('chatUser'))
