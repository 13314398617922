import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Edit = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M745.395 48.738l-108.362 108.362h-528.668v758.537h758.537v-528.666l108.362-108.362v691.21c0 29.925-24.257 54.182-54.182 54.182h-866.9c-29.923 0-54.182-24.257-54.182-54.182v-866.9c0-29.923 24.258-54.182 54.182-54.182h691.212zM947.377 0.001l76.623 76.624-498.056 498.054-76.488 0.13-0.136-76.754 498.056-498.056z" />
  </svg>
)

Edit.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Edit.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Edit
