import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_PAYPAL_CONNECT_LINK } from 'state/concepts/paymentSettings/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { setPayPalConnectLink } from 'state/concepts/paymentSettings/actions'
import { getPayPalConnectLinkEndpoint } from 'state/concepts/paymentSettings/endpoints'

const getPayPalConnectLinkOperation = createLogic({
  type: GET_PAYPAL_CONNECT_LINK,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = getPayPalConnectLinkEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setPayPalConnectLink(data.meta.url))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getPayPalConnectLinkOperation
