import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { getLocationEndpoint } from 'state/concepts/locations/endpoints'
import { GET_LOCATION } from 'state/concepts/locations/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const getLocationOperation = createLogic({
  type: GET_LOCATION,
  latest: true,

  async process({ httpClient, action: { id } }, dispatch, done) {
    const { endpoint, url } = getLocationEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getLocationOperation
