import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import { MIN_RATING, MAX_RATING } from 'lib/constants/reviews'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import yup from 'lib/yupLocalised'
import { getClientReview as getClientReviewAction, updateReview } from 'state/concepts/clientAccountReview/actions'
import {
  clientAccountReviewSelector,
  clientAccountReviewLoadingSelector,
} from 'state/concepts/clientAccountReview/selectors'
import EditReviewAppointmentModalComponent from './component'

class EditReviewAppointmentModal extends React.Component {
  static propTypes = {
    review: PropTypes.shape().isRequired,
    reviewId: PropTypes.string.isRequired,
    getClientReview: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { getClientReview, reviewId } = this.props

    getClientReview({ reviewId })
  }

  render() {
    const {
      onClose,
      review: { booking },
    } = this.props

    return (
      <EditReviewAppointmentModalComponent {...this.props} booking={booking} isModalMode onGoBack={onClose} isEdit />
    )
  }
}

const mapStateToProps = (state, { reviewId }) => ({
  review: clientAccountReviewSelector(state),
  isLoading: clientAccountReviewLoadingSelector(state, reviewId),
})

const mapDispatchToProps = {
  onSubmit: updateReview,
  getClientReview: getClientReviewAction,
}

export { EditReviewAppointmentModal as EditReviewAppointmentModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ review: { rating, text: description } }) => ({ rating, description }),
    validationSchema: yup.object().shape({
      rating: yup.number().min(MIN_RATING).max(MAX_RATING).required(),
      description: yup.string().max(3000).nullable(),
    }),
    handleSubmit: handleSubmitWithProps(['reviewId']),
    enableReinitialize: true,
  }),
)(EditReviewAppointmentModal)
