import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_CUSTOM_NAME } from 'state/concepts/customNames/types'
import { setCustomName } from 'state/concepts/customNames/actions'
import { getCustomNameEndpoint } from 'state/concepts/customNames/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const getCustomNameOperation = createLogic({
  type: GET_CUSTOM_NAME,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = getCustomNameEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(setCustomName(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getCustomNameOperation
