import * as types from './types'

export const fetchServices = () => ({
  type: types.FETCH_SERVICES,
})

export const setServices = serviceIds => ({
  type: types.SET_SERVICES,
  serviceIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_SERVICES_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_SERVICES_PAGE,
  pageNumber,
})

export const setSortOrder = sortKey => ({
  type: types.SET_SERVICES_SORT_ORDER,
  sortKey,
})

export const setNotActivatedServicesCount = count => ({
  type: types.SET_NOT_ACTIVATED_SERVICES_COUNT,
  count,
})

export const resetNotActivatedServicesCount = () => ({
  type: types.RESET_NOT_ACTIVATED_SERVICES_COUNT,
})

export const setFilterParams = filterParams => ({
  type: types.SET_SERVICES_FILTER_PARAMS,
  filterParams,
})

export const filterServices = filters => ({
  type: types.FILTER_SERVICES,
  filters,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const deactivateServices = serviceIds => ({
  type: types.DEACTIVATE_SERVICES,
  serviceIds,
})

export const activateServices = serviceIds => ({
  type: types.ACTIVATE_SERVICES,
  serviceIds,
})

export const setSelectedServices = serviceIds => ({
  type: types.SET_SELECTED_SERVICES,
  serviceIds,
})

export const searchServices = ({ query, exclude, userProfileId, statuses, page, resetResults }) => ({
  type: types.SEARCH_SERVICES,
  query,
  exclude,
  userProfileId,
  statuses,
  page,
  resetResults,
})

export const removeServices = serviceIds => ({
  type: types.REMOVE_SERVICES,
  serviceIds,
})
