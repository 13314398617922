import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, not, path, pipe, prop, values } from 'ramda'
import build from 'redux-object'

import {
  currentPageSelector,
  dataSelector,
  endpointMetaSelector,
  loadingSelector,
  pageCountSelector,
  totalCountSelector,
} from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { sidebarPropsSelector } from 'state/sidebar/selectors'
import {
  fetchWaitlistRequestEndpoint,
  fetchWaitlistRequestsEndpoint,
  resolveWaitlistRequestEndpoint,
} from './endpoints'

export const waitlistRequestsIdsSelector = path(['waitlistRequests', 'ids'])

export const waitlistRequestsSelector = createSelector(
  [waitlistRequestsIdsSelector, dataSelector],
  buildCollection('waitlistRequest'),
)

export const filtersSelector = path(['waitlistRequests', 'filters'])
export const appliedFiltersSelector = (state, name) => path(['waitlistRequests', 'filters', name], state)
export const hasFiltersSelector = createSelector(filtersSelector, compose(any(compose(not, isEmpty)), values))

export const isBlankStateSelector = path(['waitlistRequests', 'isBlankState'])

export const waitlistRequestsTotalCountSelector = state =>
  totalCountSelector(state, fetchWaitlistRequestsEndpoint.endpoint)

export const fetchWaitlistRequestsLoadingSelector = state =>
  loadingSelector(state, fetchWaitlistRequestsEndpoint.endpoint)

export const fetchWaitlistRequestsMetaSelector = state =>
  endpointMetaSelector(state, fetchWaitlistRequestsEndpoint.endpoint)

export const waitlistRequestsCurrentPageSelector = state =>
  currentPageSelector(state, fetchWaitlistRequestsEndpoint.endpoint)

export const waitlistRequestsPageCountSelector = state =>
  pageCountSelector(state, fetchWaitlistRequestsEndpoint.endpoint)

export const waitlistRequestSelector = createSelector(
  [dataSelector, (_, waitlistRequestId) => waitlistRequestId],
  (data, waitlistRequestId) => build(data, 'waitlistRequest', waitlistRequestId) || {},
)

export const fetchWaitlistRequestLoadingSelector = (state, id) =>
  loadingSelector(state, fetchWaitlistRequestEndpoint(id).endpoint)

export const selectedWaitlistRequestIdSelector = pipe(sidebarPropsSelector, prop('waitlistRequestId'))

export const resolveWaitlistRequestMetaSelector = (state, waitlistRequestId) =>
  endpointMetaSelector(state, resolveWaitlistRequestEndpoint(waitlistRequestId).endpoint)
