const costManagement = {
  'costManagement.title': 'Billing details',
  'costManagement.currentSubscriptionPlan.title': 'Current subscription plan',
  'costManagement.currentSubscriptionPlan.teamMembers': 'x{usedTeamSeatsCapacity} team members',
  'costManagement.currentSubscriptionPlan.pricePerMonth': '{pricePerMonth} / month',
  'costManagement.currentSubscriptionPlan.trialPeriod': 'Trial period <spanDot></spanDot> {planName}',
  'costManagement.currentSubscriptionPlan.cancelSubscription': 'Cancel subscription',
  'costManagement.currentSubscriptionPlan.expireTrialPeriod':
    '*Trial period will exprire on {expirationDate} and you will be charged ',
  'costManagement.currentSubscriptionPlan.seats': 'Seats',
  'costManagement.currentSubscriptionPlan.seatsUsed': '{current} of {max} seats used',
  'costManagement.managePlans.button': 'Manage Plans',
  'costManagement.manageSeats.button': 'Manage Seats',
  'costManagement.currentStoragePlan.title': 'Current storage plan',
  'costManagement.currentStoragePlan.size': 'Basic ({size})',
  'costManagement.currentStoragePlan.included': 'Included',
  'costManagement.currentStoragePlan.additionalSize': '+ {size}',
  'costManagement.currentStoragePlan.additionalPrice': '{pricePerMonth} / month',
  'costManagement.currentStoragePlan.button': 'Manage storage plan',
  'costManagement.currentStoragePlan.storage': 'Storage',
  'costManagement.currentStoragePlan.storageUsed': '{current} of {max} used.',
  'costManagement.currentStoragePlan.storageExpired':
    'Storage space is running out. You can expand storage or the system will delete old files to free up some space.',
  'costManagement.videocallHours.title': 'Video call hours',
  'costManagement.videocallHours.extraHoursUsed': '{hours} extra hours used',
  'costManagement.videocallHours.extraVideocallHour': 'Price per extra video call hours',
  'costManagement.videocallHours.progressBarTitle': 'Video call hours',
  'costManagement.videocallHours.progressBarText': '{hoursUsed} hours of {hoursFrom} hours used.',
  'costManagement.videocallHours.hrs': '{hours} hrs',
  'costManagement.videocallHours.pricePerHour': '{pricePerHour} / hour',
  'costManagement.videocallHours.pricePerHourShort': '{pricePerHour} / h',
  'costManagement.videocallHours.button': 'Manage Plans',
  'costManagement.total': 'Total',
  'costManagement.total.amountDue': 'Amount due',
  'costManagement.total.totalAmount': 'Total amount (with VAT)',
  'costManagement.total.nextPayment': 'Next payment is {price} on {date}',
}

export default costManagement
