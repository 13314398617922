import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const EditPencil = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M361.356 79.311 65.704 374.963V446.3h71.337l295.652-295.652-71.337-71.337zm141.021 94.564L173.875 502.379A32.848 32.848 0 0 1 150.647 512H32.853C14.711 512 .001 497.293.001 479.149V361.355a32.848 32.848 0 0 1 9.623-23.228L338.128 9.623c12.829-12.829 33.629-12.829 46.457 0L502.38 127.417c12.828 12.828 12.828 33.629 0 46.458z" />
  </svg>
)

EditPencil.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

EditPencil.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default EditPencil
