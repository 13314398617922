import { createLogic } from 'redux-logic'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { REMIND_WORKSPACES_REQUEST } from 'state/concepts/employee/types'
import { publicRemind } from 'lib/apiRoutes'

const remindWorkspacesRequest = createLogic({
  type: REMIND_WORKSPACES_REQUEST,
  latest: true,

  async process(
    {
      action: {
        values: { email },
        form,
      },
    },
    dispatch,
    done,
  ) {
    try {
      const params = {
        email,
      }

      await workspacesHttpClient.post(publicRemind, params)

      form.setStatus({})
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        form.setStatus({ errorPresent: 'errorPresent' })
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }
    form.setSubmitting(false)
    done()
  },
})

export default remindWorkspacesRequest
