const clientPortal = {
  'clientPortal.enable': 'Enable _(client) portal',
  'clientPortal.description':
    'Let _(clients) log in anytime to manage their _(appointments), payments, documents, and more.',
  'clientPortal.connect': 'Online booking',
  'clientPortal.selectBookingPage':
    'Let _(clients) book _(appointments) right on the _(client) portal with one of your booking pages.',
  'clientPortal.linkDescription':
    'Use this link for direct access to the _(Client) portal: send it to _(clients), or add to your website buttons, menu items, etc.',
  'clientPortal.portalUrl': '_(Client) portal URL',
  'clientPortal.clearBookingPage': 'Clear booking page',
  'clientPortal.bookingPage': 'Booking page',
  'clientPortal.bookingPages.placeholder': 'Select booking page',
  'clientPortal.bookingPages.expertsCount': '{count} {count, plural, one {_(expert)} other {_(experts)}}',
  'clientPortal.bookingPages.servicesCount': '{count} {count, plural, one {_(service)} other {_(services)}}',
  'clientPortal.bookingPages.settingsLink': 'Booking page settings',
  'clientPortal.bookingPages.notFound':
    'You have no active booking pages. To add or activate a booking page, go to {link}.',
  'clientPortal.bookingPageRequired': 'Please select a booking page',
  'clientPortal.linkCopied': 'Link copied to clipboard',
}

export default clientPortal
