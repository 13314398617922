import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const PaymentMessageComponent = ({ paymentRequired, paymentDueDate, manualPaymentEnabled }) => (
  <p className="in-gray-700" data-cy="payment-message">
    {paymentRequired ? (
      <FormattedMessage id="scheduleAppointment.prepayMessage" />
    ) : (
      <FormattedMessage
        id={`scheduleAppointment.postPayMessage.${manualPaymentEnabled ? 'withManualPayment' : 'withoutManualPayment'}`}
        values={{ dueDate: paymentDueDate }}
      />
    )}
  </p>
)

PaymentMessageComponent.propTypes = {
  paymentRequired: PropTypes.bool.isRequired,
  paymentDueDate: PropTypes.string.isRequired,
  manualPaymentEnabled: PropTypes.bool.isRequired,
}

export default PaymentMessageComponent
