const namespace = 'storage'

export const DELETE_STORAGE_FILES = `${namespace}/DELETE_STORAGE_FILES`
export const REMOVE_STORAGE_FILE_IDS = `${namespace}/REMOVE_STORAGE_FILE_IDS`
export const FETCH_STORAGE_FILES = `${namespace}/FETCH_STORAGE_FILES`
export const ADD_STORAGE_FILES_IDS = `${namespace}/ADD_STORAGE_FILES_IDS`
export const SET_STORAGE_FILES_IDS = `${namespace}/SET_STORAGE_FILES_IDS`
export const RESET_STORAGE_FILES_IDS = `${namespace}/RESET_STORAGE_FILES_IDS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const FILTER_STORAGE_FILES = `${namespace}/FILTER_STORAGE_FILES`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const SET_SELECTED_IDS = `${namespace}/SET_SELECTED_IDS`
export const SET_STORAGE_SPACE = `${namespace}/SET_STORAGE_SPACE`
export const SET_AUTO_UPGRADE = `${namespace}/SET_AUTO_UPGRADE`
