import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiSuccess, dataDelete } from 'state/data/actions'
import { RECEIVE_ACCOUNT_FORM_DATA } from 'state/concepts/booking/types'
import { removeClientForm } from 'state/concepts/companyClientForms/actions'

const receiveAccountFormDataOperation = createLogic({
  type: RECEIVE_ACCOUNT_FORM_DATA,
  latest: true,

  async process({ action: { data } }, dispatch, done) {
    const {
      data: {
        attributes: { destroyed },
        id,
      },
    } = data

    if (destroyed) {
      dispatch(dataDelete({ kind: 'clientProfileForm', ids: [id] }))
      dispatch(removeClientForm(id))
    } else {
      dispatch(dataApiSuccess({ response: normalize(data) }))
    }
    done()
  },
})

export default receiveAccountFormDataOperation
