import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import profileName from 'utils/profileName'
import Avatar from 'views/shared/Avatar'

const ProfileIdentityComponent = ({ user, isCurrentUser, withAvatar }) =>
  user.invited ? (
    user.email
  ) : (
    <div className="d-flex align-items-center">
      {withAvatar && (
        <Avatar avatarClassName="mr-8" sizeClassName="main-userpic--xs" imageSize="small" profile={user} />
      )}
      <p className="mb-0 truncate-text" data-cy="client-profile-name">
        {profileName(user)}
      </p>
      {isCurrentUser && (
        <span className="ml-4 current-user">
          <FormattedMessage id="employeeProfile.youWithRoundBrackets" />
        </span>
      )}
    </div>
  )

ProfileIdentityComponent.defaultProps = {
  withAvatar: false,
}

ProfileIdentityComponent.propTypes = {
  user: PropTypes.shape().isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  withAvatar: PropTypes.bool,
}

export default ProfileIdentityComponent
