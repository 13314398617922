import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CircleChecked = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.229-512-512s229.23-512 512-512c282.771 0 512 229.23 512 512s-229.229 512-512 512zM512 704c106.038 0 192-85.962 192-192s-85.962-192-192-192c-106.038 0-192 85.962-192 192s85.962 192 192 192z" />
  </svg>
)

CircleChecked.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CircleChecked.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CircleChecked
