import PropTypes from 'prop-types'

import Plus from 'views/shared/icons/Plus'
import Checked from 'views/shared/icons/Checked'

const OptionCustomService = ({ service: { name } }) => (
  <span className="dropdown-multiselect-item in-blue-600 pt-0 pb-0" role="button">
    <Plus size={14} className="mr-12" color="currentColor" />
    <span className="dropdown-multiselect-item__name va-middle">{name}</span>
    <Checked className="dropdown-multiselect__checked" color="#388Bff" size={15} />
  </span>
)

OptionCustomService.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

export default OptionCustomService
