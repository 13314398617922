import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { CANCEL_BOOKING } from 'state/concepts/guestBookings/types'
import { cancelBookingEndpoint } from 'state/concepts/bookings/endpoints'

const cancelBookingOperation = createLogic({
  type: CANCEL_BOOKING,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess } }, dispatch, done) {
    const { uniqCode, cancellationReason } = values
    const { endpoint, url } = cancelBookingEndpoint(uniqCode)

    const params = {
      cancellation_reason: cancellationReason,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))

      onSuccess()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default cancelBookingOperation
