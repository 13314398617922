import React from 'react'
import PropTypes from 'prop-types'
import cookies from 'component-cookie'

import CookiesCardComponent from './component'

class CookiesCard extends React.Component {
  static propTypes = {
    cookiesCheckState: PropTypes.string.isRequired,
  }

  state = {
    cookiesCheckState: this.props.cookiesCheckState,
  }

  onCookiesAccept = () => {
    cookies('cookiesCheckState', 'accepted', { path: '/', maxage: 315569520000 })
    this.setState({ cookiesCheckState: 'accepted' })
  }

  render() {
    const { cookiesCheckState } = this.state

    return <CookiesCardComponent cookiesCheckState={cookiesCheckState} onCookiesAccept={this.onCookiesAccept} />
  }
}

export default CookiesCard
