import { combineReducers } from '@reduxjs/toolkit'

import { SET_FORM_CATEGORIES_IDS, SET_TOTAL_COUNT, SET_BLANK_STATE, SET_FILTER_PARAMS } from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case SET_FORM_CATEGORIES_IDS:
      return action.ids
    default:
      return state
  }
}

const totalCount = (state = 0, action) => {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return action.count
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const formCategoriesReducer = combineReducers({
  ids,
  totalCount,
  isBlankState,
  filters,
})

export default formCategoriesReducer
