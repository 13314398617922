import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import Link from 'views/shared/Link'
import Spinner from 'views/shared/icons/Spinner'
import { companySettingsLocationsRoute } from 'lib/routes'

const EmptyState = ({ search, isLoading }) => {
  if (isLoading !== false) return <Spinner size={18} />

  return (
    <span className="font-600 in-gray-700">
      {!search ? (
        <>
          <FormattedMessage id="locations.dropdown.emptyState.title" />{' '}
          <Link href={companySettingsLocationsRoute} className="in-blue-600">
            <a className="in-blue-600">
              <FormattedMessage id="locations.dropdown.emptyState.link" />
            </a>
          </Link>
        </>
      ) : (
        <FormattedMessage id="shared.noResultsFound" />
      )}
    </span>
  )
}

EmptyState.defaultProps = {
  isLoading: undefined,
}

EmptyState.propTypes = {
  search: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
}

export default EmptyState
