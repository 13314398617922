import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without, dec, inc } from 'ramda'

import { NOTIFICATION_PANEL } from 'lib/constants/notifications'
import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_PANEL_STATUS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  DEC_UNREAD_NOTIFICATIONS_COUNT,
  INC_UNREAD_NOTIFICATIONS_COUNT,
  REMOVE_NOTIFICATION_FROM_PANEL,
  UNDO_REMOVING_NOTIFICATION_FROM_PANEL,
} from './types'

const notificationPanelStatus = (state = NOTIFICATION_PANEL.close, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_PANEL_STATUS:
      return action.status
    default:
      return state
  }
}

const notificationIds = (state = [], action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return uniq([...state, ...action.notificationIds])
    case REMOVE_NOTIFICATION_FROM_PANEL:
      return without([action.notificationId], state)
    case UNDO_REMOVING_NOTIFICATION_FROM_PANEL:
      return [...state, action.notificationId]
    default:
      return state
  }
}

const unreadNotificationsCount = (state = 0, action) => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATIONS_COUNT:
      return action.unreadCount
    case DEC_UNREAD_NOTIFICATIONS_COUNT:
      return dec(action.unreadCount)
    case INC_UNREAD_NOTIFICATIONS_COUNT:
      return inc(action.unreadCount)
    default:
      return state
  }
}

const notificationsReducer = combineReducers({
  notificationPanelStatus,
  notificationIds,
  unreadNotificationsCount,
})

export default notificationsReducer
