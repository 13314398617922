import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import { prepareEditorValue } from 'utils/reactDraftEditor'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { EDIT_NOTE } from 'state/concepts/companyClientNotes/types'
import { editNoteEndpoint } from 'state/concepts/companyClientNotes/endpoints'
import { setNoteToEdit } from 'state/concepts/companyClientNotes/actions'

const editNoteOperation = createLogic({
  type: EDIT_NOTE,
  latest: true,

  async process(
    {
      getState,
      httpClient,
      action: {
        values: { id, value, clientId },
      },
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = editNoteEndpoint(clientId, id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        value: prepareEditorValue(value),
      }
      await httpClient.put(url, params)

      const response = updateDataHelper(getState().data, 'clientNote', id, {
        attributes: {
          value: params.value,
          updatedAt: DateTime.local().toISO(),
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setNoteToEdit(null))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.noteUpdated' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default editNoteOperation
