import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { REMOVE_CHAT_HISTORY } from 'state/concepts/booking/types'
import { removeChatHistoryEndpoint } from '../endpoints'

const removeChatHistoryOperation = createLogic({
  type: REMOVE_CHAT_HISTORY,
  latest: true,

  async process({ httpClient, getState, action: { videoConferenceId, bookingUniqCode, onSuccess } }, dispatch, done) {
    const { url, endpoint } = removeChatHistoryEndpoint(bookingUniqCode)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      const response = updateDataHelper(getState().data, 'videoConference', videoConferenceId, {
        attributes: {
          chatHistoryUrl: null,
          chatHistoryFilename: null,
          chatHistorySize: null,
        },
      })
      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.chatHistoryWasDeleted' } }))
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeChatHistoryOperation
