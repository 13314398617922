import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, useFormikContext } from 'formik'
import { Select } from 'antd'
import { keys } from 'ramda'
import { nanoid } from 'nanoid'

import Avatar from 'views/shared/Avatar'
import profileName from 'utils/profileName'
import SelectField from 'views/shared/SelectField'
import { PERMISSION_TYPES, SHARE_FILE_STEPS } from 'lib/constants/businessFiles'

const Permission = ({ businessStoragePermission, myBusinessFile, rowIndex, setStep }) => {
  const { userProfile, permission, oldPermission, id, ancestorId } = businessStoragePermission
  const { parent } = myBusinessFile

  const { setFieldValue } = useFormikContext()
  const onPermissionChange = value => {
    if (!parent || !ancestorId || value === oldPermission) return
    setFieldValue('editedBusinessStorageId', id)

    setStep(SHARE_FILE_STEPS.updateAccess)
  }

  return (
    permission && (
      <div className="d-flex align-items-center justify-content-between gap-8 mb-16 mt-16">
        <div className="d-flex align-items-center">
          <Avatar
            avatarClassName="mr-12"
            sizeClassName="main-userpic main-userpic--sm"
            imageSize="small"
            profile={userProfile}
          />
          <p className="mb-0 text-body truncate-text">{profileName(userProfile)}</p>
        </div>
        <Field
          placement="bottomRight"
          optionLabelProp="label"
          name={`businessStoragePermissions.${rowIndex}.permission`}
          className="mb-0 select--text-only"
          popupClassName="max-w-220 min-w-228 w-100 dropdown-with-divider"
          component={SelectField}
          onChange={onPermissionChange}
        >
          <Select.OptGroup>
            {keys(PERMISSION_TYPES).map(permissionType => (
              <Select.Option
                label={<FormattedMessage id={`myBusinessFiles.updatePermission.${permissionType}.label`} />}
                value={PERMISSION_TYPES[permissionType]}
                key={nanoid()}
              >
                <p className="mb-0 truncate-text">
                  <FormattedMessage id={`myBusinessFiles.updatePermission.${permissionType}.label`} />
                </p>
                <p className="text-caption in-blue-gray-300 mb-0 truncate-text">
                  <FormattedMessage id={`myBusinessFiles.updatePermission.${permissionType}.description`} />
                </p>
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup>
            <Select.Option value="" key={nanoid()}>
              <p className="mb-0">
                <FormattedMessage id="myBusinessFiles.updatePermission.removeAccess" />
              </p>
            </Select.Option>
          </Select.OptGroup>
        </Field>
      </div>
    )
  )
}

Permission.propTypes = {
  businessStoragePermission: PropTypes.shape({
    userProfile: PropTypes.shape({
      id: PropTypes.string,
    }),
    ancestorId: PropTypes.number,
    permission: PropTypes.string,
    oldPermission: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  myBusinessFile: PropTypes.shape({
    id: PropTypes.string,
    parent: PropTypes.shape({}),
  }).isRequired,
  rowIndex: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}
export default Permission
