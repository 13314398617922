import { path } from 'ramda'

import {
  IS_ADMIN,
  IS_ADMIN_OR_OWNER,
  IS_ADMIN_OR_OWNER_OR_SCHEDULER,
  IS_OWNER,
  IS_SCHEDULER,
} from 'lib/constants/permissionKeys'
import { FEATURE_KEYS, FEATURE_STATUSES } from 'lib/constants/workspaceFeatures'
import isStripePosEnabled from 'utils/stripe/isStripePosEnabled'
import isPresent from 'utils/isPresent'
import isAdmin from './isAdmin'
import isAdminOrOwner from './isAdminOrOwner'
import isAdminOrOwnerOrScheduler from './isAdminOrOwnerOrScheduler'
import isOwner from './isOwner'
import isScheduler from './isScheduler'

export const rolesByKey = employee => ({
  [IS_ADMIN]: isAdmin(employee),
  [IS_OWNER]: isOwner(employee),
  [IS_ADMIN_OR_OWNER]: isAdminOrOwner(employee),
  [IS_ADMIN_OR_OWNER_OR_SCHEDULER]: isAdminOrOwnerOrScheduler(employee),
  [IS_SCHEDULER]: isScheduler(employee),
})

export const getFeaturesStatus = features =>
  features.reduce((acc, feature) => {
    acc[feature.key] = feature.status !== FEATURE_STATUSES.disabled
    return acc
  }, {})

export const employeeSidebarPermissions = roles => ({
  viewEmployees: roles[IS_ADMIN_OR_OWNER],
  viewServicesAndCategories: roles[IS_ADMIN_OR_OWNER],
  viewCompanySettings: roles[IS_ADMIN_OR_OWNER],
  viewBusinessProfile: roles[IS_ADMIN_OR_OWNER],
  viewWorkspaceSettings: roles[IS_ADMIN_OR_OWNER],
  viewCompanyPaymentSettings: roles[IS_OWNER],
})

const employeePermissions = (employee, features, stripeAccount, storageFiles) => {
  const roles = rolesByKey(employee)
  const featuresStatus = getFeaturesStatus(features)
  const isPosEnabled = isStripePosEnabled(stripeAccount)
  const hasStorageFileAccess = isPresent(path([0, 'fileUrl'], storageFiles))

  return {
    ...employeeSidebarPermissions(roles),
    manageBusinessContactDetails: roles[IS_ADMIN_OR_OWNER],
    // TODO: add scheduler role to manage team after PR #6483
    manageTeam: roles[IS_ADMIN_OR_OWNER],
    manageWidget: roles[IS_ADMIN_OR_OWNER],
    manageServices: roles[IS_ADMIN_OR_OWNER],
    connectPaymentMethod: roles[IS_OWNER] && featuresStatus[FEATURE_KEYS.financialDocuments],
    editPaymentInformation: roles[IS_OWNER],
    retryPaymentOrRenewSubscription: roles[IS_OWNER],
    transferOwnership: roles[IS_OWNER],
    deleteCompany: roles[IS_OWNER],
    customizeReceiptTemplate: roles[IS_OWNER] && featuresStatus[FEATURE_KEYS.financialDocuments],
    seeConsultantsFilter: roles[IS_ADMIN_OR_OWNER],
    applyPromocodes: roles[IS_ADMIN_OR_OWNER],
    manageVideoCallSettings: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.acceptVideoCalls],
    enableCollectReviews: roles[IS_ADMIN_OR_OWNER],
    publishReviewsOnWidget: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.reviews],
    manageReviewSettings: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.reviews],
    manageLocations: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.locations],
    viewCalendarSync: roles[IS_ADMIN_OR_OWNER],
    companyClientsImport: roles[IS_ADMIN_OR_OWNER_OR_SCHEDULER] && featuresStatus[FEATURE_KEYS.crm],
    deleteClient: roles[IS_ADMIN_OR_OWNER],
    createClient: roles[IS_ADMIN_OR_OWNER_OR_SCHEDULER],
    managePaymentMethods: roles[IS_OWNER],
    manageStripe: roles[IS_OWNER],
    manageBookingPolicy: roles[IS_ADMIN_OR_OWNER],
    manageClientPortal: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.clientPortal],
    viewInvoiceAndReceiptSettings: roles[IS_OWNER],
    manageClientAttachment: !roles[IS_SCHEDULER],
    manageClientBusinessAttachments: !roles[IS_SCHEDULER] && featuresStatus[FEATURE_KEYS.businessFiles],
    manageSMSNotifications: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.smsReminders],
    manageFeaturesAndIntegrations: roles[IS_OWNER] || roles[IS_ADMIN],
    manageWebsite: roles[IS_ADMIN_OR_OWNER],
    manageFeatures: roles[IS_ADMIN_OR_OWNER],
    manageCustomNaming: roles[IS_ADMIN_OR_OWNER],
    manageStorage: roles[IS_ADMIN_OR_OWNER],
    previewStorageFile: roles[IS_ADMIN_OR_OWNER] && hasStorageFileAccess,
    manageTasks: featuresStatus[FEATURE_KEYS.tasks],
    manageServiceCategories: featuresStatus[FEATURE_KEYS.serviceCategories],
    manageClientFields: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.crm],
    manageChats: featuresStatus[FEATURE_KEYS.chat],
    manageAcceptPayments: featuresStatus[FEATURE_KEYS.acceptPayments],
    requestCustomPayments: featuresStatus[FEATURE_KEYS.customPayments],
    manageAgreements: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.serviceAgreements],
    manageIntakeForms: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.forms],
    manageClientRelationshipTypes: featuresStatus[FEATURE_KEYS.clientContacts] && roles[IS_ADMIN_OR_OWNER],
    manageAgreementsAndForms:
      roles[IS_ADMIN_OR_OWNER] &&
      (featuresStatus[FEATURE_KEYS.serviceAgreements] || featuresStatus[FEATURE_KEYS.forms]),
    manageCalendarsIntegrations: featuresStatus[FEATURE_KEYS.calendarIntegration] && !roles[IS_SCHEDULER],
    deleteSubmittedForm: roles[IS_ADMIN_OR_OWNER],
    editFormResponses: roles[IS_ADMIN_OR_OWNER],
    fillOutForm: roles[IS_ADMIN_OR_OWNER],
    manageMicrosoftTeamsIntegration: roles[IS_ADMIN_OR_OWNER],
    viewSettings: roles[IS_ADMIN_OR_OWNER],
    manageBookingPage: roles[IS_ADMIN_OR_OWNER],
    deleteAutomationForm: roles[IS_ADMIN_OR_OWNER],
    manageCustomPayments: roles[IS_OWNER],
    manageRefunds: roles[IS_OWNER],
    manageFinancialDocumentsFeature: roles[IS_OWNER],
    manageBilling: !roles[IS_SCHEDULER],
    manageStripePos: roles[IS_OWNER],
    sendForms: featuresStatus[FEATURE_KEYS.forms],
    sendAgreements: featuresStatus[FEATURE_KEYS.serviceAgreements],
    undefinedPermission: true,
    viewBookingPolicy: roles[IS_ADMIN_OR_OWNER],
    manageDocumentTemplates: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.documentTemplates],
    createDocumentFromTemplate: featuresStatus[FEATURE_KEYS.documentTemplates] && !roles[IS_SCHEDULER],
    manageCardReaders: roles[IS_OWNER] && featuresStatus[FEATURE_KEYS.stripePos] && isPosEnabled,
    manageClientContacts: !roles[IS_SCHEDULER] && featuresStatus[FEATURE_KEYS.clientContacts],
    manageSignatureSettings: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.signature],
    manageBookingsTeamView: roles[IS_ADMIN_OR_OWNER],
    manageBusinessFiles: featuresStatus[FEATURE_KEYS.businessFiles],
    viewBookingsTeamView: roles[IS_ADMIN_OR_OWNER_OR_SCHEDULER],
    manageWaitlist: featuresStatus[FEATURE_KEYS.waitlist],
    manageWaitlistSettings: roles[IS_ADMIN_OR_OWNER] && featuresStatus[FEATURE_KEYS.waitlist],
  }
}

export default employeePermissions
