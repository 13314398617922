import React from 'react'
import { withFormik } from 'formik'

import { FIELD_TYPES } from 'lib/constants/customField'
import validationSchema from 'lib/yupLocalised/schemas/clientFields'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import CustomFieldModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class CustomFieldModal extends React.Component {
  render = () => <CustomFieldModalComponent {...this.props} isSubmitDisabled={isSubmitDisabled(this.props)} />
}

export { CustomFieldModal as CustomFieldModalContainer }
export default withFormik({
  mapPropsToValues: ({ id, name = '', customFieldOptions = [], fieldType = FIELD_TYPES.text }) => ({
    id,
    name,
    fieldType,
    fieldOptions: customFieldOptions,
    initialOptions: customFieldOptions,
  }),
  validationSchema,
  handleSubmit: handleSubmitWithProps(['onSuccess']),
})(CustomFieldModal)
