import { createLogic } from 'redux-logic'

import { SET_CLIENT_ATTACHMENTS_FILTER } from 'state/concepts/companyClientAttachments/types'
import {
  fetchClientAttachments,
  resetClientAttachments,
  setFilterParams,
} from 'state/concepts/companyClientAttachments/actions'
import { clientIdSelector } from 'state/concepts/client/selectors'

const filterClientAttachmentsOperation = createLogic({
  type: SET_CLIENT_ATTACHMENTS_FILTER,
  latest: true,

  process({ action: { filters }, getState }, dispatch, done) {
    const clientId = clientIdSelector(getState())

    dispatch(setFilterParams(filters))
    dispatch(resetClientAttachments())
    dispatch(fetchClientAttachments(clientId, { nonClientFiles: true, size: 20, number: 1, append: false }))
    done()
  },
})

export default filterClientAttachmentsOperation
