import PropTypes from 'prop-types'

import profileName from 'utils/profileName'
import Avatar from 'views/shared/Avatar'

const InvitedClientProfile = ({ clientProfile }) => (
  <>
    <Avatar
      avatarClassName="mr-12"
      sizeClassName="main-userpic main-userpic--xs"
      imageSize="small"
      profile={clientProfile}
    />
    <div className="overflow-x-hidden">
      <p className="mb-0 text-body truncate-text" data-cy="client-fullname">
        {profileName(clientProfile)}
      </p>
    </div>
  </>
)

InvitedClientProfile.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
}

export default InvitedClientProfile
