import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Pdf = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M128 0C99.987 0 76.8 23.187 76.8 51.2v128c-28.288 0-51.2 22.912-51.2 51.2v128c0 28.288 22.912 51.2 51.2 51.2v51.2c0 28.014 23.187 51.2 51.2 51.2h307.2c28.014 0 51.2-23.186 51.2-51.2V128L358.4 0H128zm0 51.2h204.8v102.4h102.4v25.6H128v-128zM76.8 230.4h38.4c21.197 0 38.4 17.203 38.4 38.4s-17.203 38.4-38.4 38.4h-12.8v51.2H76.8v-128zm230.4 0H384V256h-51.2v25.6h40.95v25.6H332.8v51.2h-25.6v-128zm-128 .6h41.7c40.499 0 60.7 24.358 60.7 53.9v19.75c0 29.901-20.243 53.75-61.05 53.75H179.2V231zm-76.8 25v25.6h12.8c7.066 0 12.8-5.735 12.8-12.8s-5.734-12.8-12.8-12.8h-12.8zm102.4.599V332.8h15.75c16.179 0 35.45-4.905 35.45-28.15V284.9c0-23.399-19.075-28.301-35.1-28.301h-16.1zM128 409.6h307.2v51.2H128v-51.2z" />
  </svg>
)

Pdf.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Pdf.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Pdf
