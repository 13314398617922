import normalize from 'json-api-normalizer'
import { path } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { employeeOnboardingRoute } from 'lib/routes'
import { isEmailAlertShownSelector } from 'state/concepts/alert/selectors'
import { GET_EMPLOYEE_OWN_PROFILE } from 'state/concepts/employee/types'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isOnboardingCompleted from 'utils/employees/isOnboardingCompleted'
import redirect from 'utils/redirect'
import { getEmployeeOwnProfileEndpoint } from '../endpoints'

const getEmployeeOwnProfileOperation = createLogic({
  type: GET_EMPLOYEE_OWN_PROFILE,
  latest: true,

  async process({ httpClient, action: { params, response, query }, getState }, dispatch, done) {
    const { endpoint, url } = getEmployeeOwnProfileEndpoint
    const isEmailAlertShown = isEmailAlertShownSelector(getState())
    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, {
        params: {
          include: [
            'workspace',
            'user-tutorial',
            'user-onboarding-content',
            'workspace.current-billing-period',
            'workspace.current_subscription_plan',
            'workspace.payment-account',
            'workspace.payment-account.manual-payment',
            'workspace.payment-account.paypal-account',
            'workspace.payment-account.stripe-account',
            'workspace.workspace-two-factor-setting',
            'user-profile-two-factor-setting',
            'pending-business-storage-archive',
          ],
          ...params,
        },
      })
      const normalizedData = normalize(data)

      // two lines below should be in right order to prevent errors
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }))
      dispatch(
        employeeLoginSuccess(data.data.id, !path(['data', 'attributes', 'confirmed-at'], data) && isEmailAlertShown),
      )

      if (!isOnboardingCompleted(data)) {
        redirect({ href: employeeOnboardingRoute, response, query, workspace: query.workspace })
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getEmployeeOwnProfileOperation
