const namespace = 'tasks'

export const FETCH_TASKS = `${namespace}/FETCH_TASKS`
export const ADD_TASK_IDS = `${namespace}/ADD_TASK_IDS`
export const RESET_TASKS = `${namespace}/RESET_TASKS`
export const DELETE_TASK = `${namespace}/DELETE_TASK`
export const REMOVE_TASK_ID = `${namespace}/REMOVE_TASK_ID`
export const SET_TASKS_COUNTS = `${namespace}/SET_TASKS_COUNTS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`
export const FILTER_TASKS = `${namespace}/SET_REVIEWS_FILTER`
export const CREATE_TASK = `${namespace}/CREATE_TASK`
export const ADD_CREATED_TASK_ID = `${namespace}/ADD_CREATED_TASK_ID`
export const SET_VIEW = `${namespace}/SET_VIEW`
export const DECREMENT_TASKS_COUNT = `${namespace}/DECREMENT_TASKS_COUNT`
export const INCREMENT_TASKS_COUNT = `${namespace}/INCREMENT_TASKS_COUNT`
export const UPDATE_TASK_PRIORITY = `${namespace}/UPDATE_TASK_PRIORITY`
export const SET_IS_CREATING_TASK = `${namespace}/SET_IS_CREATING_TASK`
export const MARK_TASK_AS_COMPLETED = `${namespace}/MARK_TASK_AS_COMPLETED`
export const MARK_TASK_AS_TODO = `${namespace}/MARK_TASK_AS_TODO`
export const UPDATE_TASK = `${namespace}/UPDATE_TASK`
export const SET_TASK_TO_EDIT = `${namespace}/SET_TASK_TO_EDIT`
