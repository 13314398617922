import React from 'react'
import PropTypes from 'prop-types'
import { Rate } from 'antd'

class RateField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleChange = number => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props

    setFieldValue(name, number)
  }

  render() {
    const {
      field: { value },
    } = this.props

    return <Rate {...this.props} value={value} onChange={this.handleChange} allowClear={false} />
  }
}

export default RateField
