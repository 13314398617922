import React from 'react'
import PropTypes from 'prop-types'
import className from 'clsx'

import ArrowLeft from 'views/shared/icons/ArrowLeft'
import Link from 'views/shared/Link'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import PageTitle from '../PageTitle'

const PageHeaderComponent = ({
  backLink,
  pageTitleComponent,
  children,
  pageTitle,
  onBackClick,
  classChildren,
  headerClassName,
  headerInnerClassName,
  hasBackLink,
  ...titleProps
}) =>
  (pageTitle || pageTitleComponent) && (
    <>
      {hasBackLink && (
        <Link isFullLink {...backLink}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <a className="main-link-back mr-12 d-sm-none d-block" data-cy="back-link-mobile" onClick={onBackClick}>
            <ArrowLeft className="mr-12" size={10} />
            <FormattedOrRawMessage message={{ id: 'shared.back' }} />
          </a>
        </Link>
      )}
      <div className={`main-page__header column-gap-16 ${headerClassName}`}>
        <div className={className('d-flex align-items-flex-start min-w-0', headerInnerClassName)}>
          {hasBackLink && (
            <Link isFullLink {...backLink}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <a className="main-link-back mr-8 d-sm-flex d-none" data-cy="back-link" onClick={onBackClick}>
                <ArrowLeft className="in-blue-600" size={10} />
              </a>
            </Link>
          )}
          {pageTitleComponent || <PageTitle pageTitle={pageTitle} {...titleProps} />}
        </div>
        <div className={className('main-page__header-btn', classChildren)}>{children}</div>
      </div>
    </>
  )

PageHeaderComponent.defaultProps = {
  pageTitle: null,
  pageTitleComponent: null,
  children: null,
  classChildren: '',
  headerClassName: '',
  headerInnerClassName: '',
  hasBackLink: true,
  backLink: undefined,
  onBackClick: undefined,
}

PageHeaderComponent.propTypes = {
  backLink: PropTypes.shape(),
  pageTitle: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  pageTitleComponent: PropTypes.element,
  children: PropTypes.node,
  onBackClick: PropTypes.func,
  classChildren: PropTypes.string,
  headerClassName: PropTypes.string,
  headerInnerClassName: PropTypes.string,
  hasBackLink: PropTypes.bool,
}

export default PageHeaderComponent
