import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Radio } from 'antd'
import { equals } from 'ramda'

import { formattedTime } from 'utils/dateTime'

const DaySlots = ({ startTime, slots, title, onChangeSlot }) => (
  <div>
    <h3 className="in-gray-700 text-body font-600 mb-8">
      <FormattedMessage {...title} />
    </h3>
    <div className="resch-modal__time">
      {slots.map(slot => (
        <Radio.Button
          key={slot}
          name="startTime"
          className="resch-modal__time-slot"
          value={formattedTime(slot)}
          onChange={onChangeSlot(slot)}
          checked={equals(startTime, slot)}
        >
          {formattedTime(slot)}
        </Radio.Button>
      ))}
    </div>
  </div>
)

DaySlots.propTypes = {
  startTime: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
  onChangeSlot: PropTypes.func.isRequired,
}

export default DaySlots
