import React from 'react'
import PropTypes from 'prop-types'

import ErrorModal from 'views/shared/ErrorModal'
import RescheduleFormModal from './RescheduleFormModal'

const RescheduleModal = ({ rescheduleError, isLoading, ...props }) =>
  isLoading === false &&
  (rescheduleError ? (
    <ErrorModal error={rescheduleError} title={{ id: 'bookings.rescheduleError' }} />
  ) : (
    <RescheduleFormModal {...props} />
  ))

RescheduleModal.defaultProps = {
  rescheduleError: undefined,
  isLoading: undefined,
}

RescheduleModal.propTypes = {
  rescheduleError: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default RescheduleModal
