import normalize from 'json-api-normalizer'
import { path } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import isOnboardingCompleted from 'utils/employees/isOnboardingCompleted'
import requestErrorHandler from 'lib/requestErrorHandler'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { dataApiSuccess } from 'state/data/actions'
import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus'
import handleLoginRedirect from 'utils/session/handleLoginRedirect'
import setEmployeeCookies from 'utils/setEmployeeCookies'
import { usersSessionTwoFARoute } from 'lib/apiRoutes'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { EMPLOYEE_SUBMIT_TWO_FA } from 'state/concepts/session/types'
import { showModal } from 'state/modal/actions'
import isPresent from 'utils/isPresent'
import { employeeLoginMetaSelector } from '../selectors'

const employeeSubmitTwoFaOperation = createLogic({
  type: EMPLOYEE_SUBMIT_TWO_FA,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { jwt } = employeeLoginMetaSelector(getState())

    try {
      const params = {
        otp_code: values.otpCode,
        include: 'workspace,user-tutorial.user-onboarding-content',
      }

      const { data } = await httpClient.post(usersSessionTwoFARoute, params, {
        headers: { Authorization: jwt.access },
        ...buildCustomBaseUrl(['workspaces']),
      })

      const response = normalize(data)
      const currentEmployee = build(response, 'userProfile')[0]
      const tokens = data.meta.jwt
      const backupCodes = data.meta.new_backup_codes
      const uniqCode = path(['workspace', 'uniqCode'], currentEmployee)
      const onSuccess = () => handleLoginRedirect(isOnboardingCompleted(data), uniqCode)

      setEmployeeCookies(currentEmployee, tokens, { path: `/${uniqCode}` })

      dispatch(employeeLoginSuccess(currentEmployee.id, !currentEmployee?.confirmedAt))
      dispatch(dataApiSuccess({ response }))

      if (isPresent(backupCodes)) {
        dispatch(
          showModal({
            modalType: 'FRESH_BACKUP_CODES_MODAL',
            modalProps: {
              backupCodes,
              onSuccess,
            },
          }),
        )
      } else {
        onSuccess()
      }
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }

      form.setSubmitting(false)
    }
    done()
  },
})

export default employeeSubmitTwoFaOperation
