import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'

import Button from 'views/shared/Button'
import isSubmitDisabledFunc from 'utils/form/isSubmitDisabled'

const SubmitFormButton = ({ isSubmitting, isDisabled, allowPristine, ...props }) => {
  const formik = useFormikContext()

  const isFormSubmitting = !isNil(isSubmitting) ? isSubmitting : formik.isSubmitting
  const isSubmitDisabled = !isNil(isDisabled) ? isDisabled : isSubmitDisabledFunc(formik, { allowPristine })

  return (
    <Button
      type="submit"
      dataCy="submit-form-button"
      isLoading={isFormSubmitting}
      disabled={isSubmitDisabled}
      onClick={formik.handleSubmit}
      {...props}
    />
  )
}

SubmitFormButton.propTypes = {
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  allowPristine: PropTypes.bool,
}

SubmitFormButton.defaultProps = {
  isDisabled: null,
  isSubmitting: null,
  allowPristine: false,
}

export default SubmitFormButton
