import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_IN_CALL_CHAT } from 'state/concepts/booking/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setInCallChatId } from 'state/concepts/booking/actions'
import { fetchInCallChatEndpoint } from 'state/concepts/booking/endpoints'

const fetchInCallChatOperation = createLogic({
  type: FETCH_IN_CALL_CHAT,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = fetchInCallChatEndpoint(id)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        include: ['in-call-chat-messages', 'booking', 'service', 'video-conference'],
      }
      const { data } = await httpClient.get(url, { params })
      const normalizedData = normalize(data)

      dispatch(setInCallChatId(data.data.id))
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchInCallChatOperation
