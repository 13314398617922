import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import { hideModal } from 'state/modal/actions'
import { disconnectMicrosoftTeamsProviderEndpoint } from '../endpoints'
import { DISCONNECT_MICROSOFT_TEAMS_PROVIDER } from '../types'

const disconnectMicrosoftTeamsProviderOperation = createLogic({
  type: DISCONNECT_MICROSOFT_TEAMS_PROVIDER,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { id } = currentUserProfileSelector(getState())

    const { url, endpoint } = disconnectMicrosoftTeamsProviderEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)
      const response = updateDataHelper(getState().data, 'userProfile', id, {
        attributes: {
          videoCallProvider: VIDEO_CALLS_PROVIDERS.expertbox,
        },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.microsoftTeamsDisconnected',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default disconnectMicrosoftTeamsProviderOperation
