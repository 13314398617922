import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const TeamsInfo = ({ providerUrl, meetingId }) => (
  <>
    <p className="mb-0 mt-4">
      <FormattedMessage id="consultation.details.videoCallLink" />
      <a href={providerUrl} target="_blank" className="in-blue-600 ml-4 text-underline">
        {providerUrl}
      </a>
    </p>
    <p className="mb-0">
      <FormattedMessage id="consultation.details.microsoft_teams.meetingId" values={{ meetingId }} />
    </p>
  </>
)

TeamsInfo.propTypes = {
  providerUrl: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
}

export default TeamsInfo
