import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hideModal } from 'state/modal/actions'
import FilesPreviewModalComponent from './component'

class FilesPreviewModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    initialFileId: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onRemoveFile: PropTypes.func.isRequired,
    canRemove: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialFileId: undefined,
  }

  state = {
    currentSlide: null,
    currentFiles: this.props.files,
  }

  sliderRef = React.createRef()

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  get initialSlideIndex() {
    const { initialFileId } = this.props
    const { currentFiles } = this.state

    return currentFiles.findIndex(file => file.id === initialFileId)
  }

  get currentSlide() {
    const { currentSlide, currentFiles } = this.state

    return currentSlide || currentFiles[this.initialSlideIndex]
  }

  get currentLength() {
    const { currentFiles } = this.state

    return currentFiles.length - 1
  }

  get currentIndex() {
    const { currentFiles } = this.state

    return currentFiles.findIndex(file => file.id === this.currentSlide.id)
  }

  get newFiles() {
    const { currentFiles } = this.state

    return currentFiles.filter(file => file.id !== this.currentSlide.id)
  }

  get newIndex() {
    return this.currentLength === this.currentIndex ? this.currentIndex - 1 : this.currentIndex
  }

  get canRemove() {
    const { canRemove } = this.props

    return canRemove(this.currentSlide)
  }

  handleSlideRemove = () => {
    const { onClose, onRemoveFile } = this.props

    onRemoveFile(this.currentSlide.id)

    if (this.currentLength > 0) {
      this.setState({
        currentFiles: this.newFiles,
        currentSlide: this.newFiles[this.newIndex],
      })
    } else {
      onClose()
    }
  }

  handleSlideChange = (_, newIndex) => {
    this.setState(state => ({
      currentSlide: state.currentFiles[newIndex],
    }))
  }

  handleKeyDown = event => {
    const { onClose } = this.props
    const slider = this.sliderRef.current

    if (!slider) {
      return
    }

    const keyHandlers = {
      27: onClose,
      37: slider.slickPrev,
      39: slider.slickNext,
    }

    const handler = keyHandlers[event.keyCode]

    if (handler) {
      handler()
    }
  }

  render() {
    return (
      <FilesPreviewModalComponent
        {...this.props}
        {...this.state}
        ref={this.sliderRef}
        onKeyPress={this.handleKeyPress}
        currentSlide={this.currentSlide}
        canRemove={this.canRemove}
        initialSlide={this.initialSlideIndex}
        onSlideChange={this.handleSlideChange}
        onSlideRemove={this.handleSlideRemove}
      />
    )
  }
}

const mapDispatchToProps = {
  onClose: hideModal,
}

export { FilesPreviewModal as FilesPreviewModalContainer }
export default connect(null, mapDispatchToProps)(FilesPreviewModal)
