import { showNotification } from 'state/notifications/actions'

const makeShowNotification = dispatch => () => {
  dispatch(
    showNotification({
      messageObject: { id: 'errorPage.generalNotification' },
      kind: 'error',
    }),
  )
}

export default makeShowNotification
