import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchEmployees, setBlankState, setCurrentPage, setEmployees } from 'state/concepts/employees/actions'
import { fetchEmployeesEndpoint } from 'state/concepts/employees/endpoints'
import { filtersSelector, paginationSelector, sortParamsSelector } from 'state/concepts/employees/selectors'
import { FETCH_EMPLOYEES } from 'state/concepts/employees/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isBlankState from 'utils/isBlankState'
import isPageOverflow from 'utils/isPageOverflow'

const fetchEmployeesOperation = createLogic({
  type: FETCH_EMPLOYEES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchEmployeesEndpoint
    const state = getState()
    const params = {
      page: paginationSelector(state),
      filter: filtersSelector(state),
      sort: sortParamsSelector(state),
      include: ['user-profile-two-factor-setting'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const data = await httpClient.get(url, { params }).then(resp => resp.data)
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setEmployees(data.data.map(item => item.id)))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(1))
        dispatch(fetchEmployees())
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default fetchEmployeesOperation
