import React from 'react'
import PropTypes from 'prop-types'

import PasswordInputFieldComponent from './component'

class PasswordInputField extends React.Component {
  state = {
    visible: false,
  }

  handleChange = event => {
    const { field } = this.props

    field.onChange(event)
  }

  handleVisibilityChange = () => {
    this.setState(({ visible }) => ({ visible: !visible }))
  }

  render = () => (
    <PasswordInputFieldComponent
      onChange={this.handleChange}
      onVisibilityChange={this.handleVisibilityChange}
      {...this.state}
      {...this.props}
    />
  )
}

PasswordInputField.propTypes = {
  checkPasswordStrength: PropTypes.bool,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  messageTextClass: PropTypes.string,
}

PasswordInputField.defaultProps = {
  checkPasswordStrength: false,
  messageTextClass: undefined,
}

export default PasswordInputField
