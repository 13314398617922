import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  validateCancelBooking as validateCancelBookingAction,
  setCancellationError as setCancellationErrorAction,
  resetCancellationRules as resetCancellationRulesAction,
} from 'state/concepts/bookings/actions'
import { cancellationErrorSelector, cancellationValidationLoadingSelector } from 'state/concepts/bookings/selectors'
import CancelModalComponent from './component'

class CancelModal extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
    validateCancelBooking: PropTypes.func.isRequired,
    setCancellationError: PropTypes.func.isRequired,
    resetCancellationRules: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      booking: { uniqCode },
      validateCancelBooking,
    } = this.props

    validateCancelBooking(uniqCode)
  }

  componentWillUnmount() {
    const { setCancellationError, resetCancellationRules } = this.props

    setCancellationError(null)
    resetCancellationRules()
  }

  render() {
    return <CancelModalComponent {...this.props} />
  }
}

const mapStateToProps = (state, { booking }) => ({
  cancellationError: cancellationErrorSelector(state),
  isLoading: cancellationValidationLoadingSelector(state, booking.uniqCode),
})

const mapDispatchToProps = {
  validateCancelBooking: validateCancelBookingAction,
  setCancellationError: setCancellationErrorAction,
  resetCancellationRules: resetCancellationRulesAction,
}

export { CancelModal as CancelModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(CancelModal)
