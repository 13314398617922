import { values } from 'lodash'

import yup from 'lib/yupLocalised'
import {
  SERVICE_MIN_PRICE,
  SERVICE_MAX_PRICE,
  MIN_SERVICE_DURATION,
  MAX_SERVICE_DURATION,
} from 'lib/constants/services'
import {
  SERVICE_NAME_MAX_CHARS,
  SERVICE_DESCRIPTION_MAX_CHARS,
  RECURRING_BOOKING_REPEAT_COUNT_MIN,
  RECURRING_BOOKING_REPEAT_COUNT_MAX,
} from 'lib/constants/scheduleAppointment'
import { DAYS_OF_WEEK_VALUE_AND_ID, ENDS_FIELD_KEY_AND_VALUE } from 'lib/constants/bookings'
import { MAX_INPUT_NAME_LENGTH } from 'lib/constants'
import isEmail from 'utils/isEmail'

export const recurringSchema = yup.object().shape({
  repeat: yup.string().required(),
  weeklyInterval: yup.number().when('repeat', {
    is: 'true',
    then: () => yup.number().required(),
  }),
  byWeekday: yup.array().when('repeat', {
    is: 'true',
    then: () =>
      yup
        .array()
        .of(yup.string().oneOf(values(DAYS_OF_WEEK_VALUE_AND_ID)).required())
        .required(),
  }),
  ends: yup.string().when('repeat', {
    is: 'true',
    then: () => yup.string().oneOf(values(ENDS_FIELD_KEY_AND_VALUE)).required(),
  }),
  repeatCount: yup.number().when(['repeat', 'ends'], {
    is: (repeat, ends) => repeat && ends === ENDS_FIELD_KEY_AND_VALUE.after,
    then: () =>
      yup
        .number()
        .typeError({ id: 'yup.number.typeError' })
        .min(RECURRING_BOOKING_REPEAT_COUNT_MIN)
        .max(RECURRING_BOOKING_REPEAT_COUNT_MAX)
        .required(),
  }),
  untilDate: yup.date().when(['repeat', 'ends'], {
    is: (repeat, ends) => repeat && ends === ENDS_FIELD_KEY_AND_VALUE.until,
    then: () => yup.date().required(),
  }),
})

export const clientProfileSchema = yup.object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  clientProfile: yup.object({
    idOrEmail: yup.string().required(),
    firstName: yup.string().when('idOrEmail', {
      is: idOrEmail => isEmail(idOrEmail),
      then: () => yup.string().max(MAX_INPUT_NAME_LENGTH).required(),
    }),
    lastName: yup.string().when('idOrEmail', {
      is: idOrEmail => isEmail(idOrEmail),
      then: () => yup.string().max(MAX_INPUT_NAME_LENGTH).required(),
    }),
  }),
})

export default yup.object().shape({
  duration: yup
    .string()
    .isDurationString()
    .durationMin(MIN_SERVICE_DURATION)
    .durationMax(MAX_SERVICE_DURATION)
    .required(),
  date: yup.date().required(),
  time: yup.date().required(),
  serviceId: yup.string().nullable().defined(),
  serviceName: yup
    .string()
    .nullable()
    .when('serviceId', {
      is: null,
      then: () => yup.string().max(SERVICE_NAME_MAX_CHARS).required(),
    }),
  serviceDescription: yup.string().nullable().max(SERVICE_DESCRIPTION_MAX_CHARS),
  price: yup
    .number()
    .nullable()
    .when('isFreePrice', {
      is: false,
      then: () =>
        yup
          .number()
          .typeError({ id: 'yup.number.typeError' })
          .nullable()
          .priceMin(SERVICE_MIN_PRICE)
          .max(SERVICE_MAX_PRICE, { id: 'yup.number.servicePrice.max', values: { max: SERVICE_MAX_PRICE } })
          .required(),
    }),
})
