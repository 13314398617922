import { createLogic } from 'redux-logic'

import { fetchWaitlistRequests, resetWaitlistRequestIds, setFilterParams } from '../actions'
import { FILTER_WAITLIST_REQUESTS } from '../types'

const filterWaitlistRequestsOperation = createLogic({
  type: FILTER_WAITLIST_REQUESTS,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(resetWaitlistRequestIds())
    dispatch(setFilterParams(filters))
    dispatch(fetchWaitlistRequests())
    done()
  },
})

export default filterWaitlistRequestsOperation
