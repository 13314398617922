import React from 'react'
import PropTypes from 'prop-types'

import { paymentMethodLocalized } from 'utils/payments'
import ReceiptTemplatePreviewModalComponent from './component'

class ReceiptTemplatePreviewModal extends React.Component {
  static propTypes = {
    transaction: PropTypes.shape({
      paymentMethod: PropTypes.string.isRequired,
      lastDigits: PropTypes.string,
    }).isRequired,
  }

  get paymentMethod() {
    const { transaction } = this.props

    return paymentMethodLocalized(transaction)
  }

  render() {
    return <ReceiptTemplatePreviewModalComponent {...this.props} paymentMethod={this.paymentMethod} />
  }
}

export default ReceiptTemplatePreviewModal
