import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import {
  ADD_CLIENT_NOTES_IDS,
  REMOVE_CLIENT_NOTES_IDS,
  RESET_CLIENT_NOTES,
  SET_IS_ADD_NOTE_FORM_SHOWN,
  ADD_NOTE_SUCCESS,
  SET_NOTE_TO_EDIT,
} from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_NOTES_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_CLIENT_NOTES_IDS:
      return without(action.ids, state)
    case ADD_NOTE_SUCCESS:
      return [action.id, ...state]
    case RESET_CLIENT_NOTES:
      return []
    default:
      return state
  }
}

const isAddNoteFormShown = (state = false, action) => {
  switch (action.type) {
    case SET_IS_ADD_NOTE_FORM_SHOWN:
      return action.isShown
    default:
      return state
  }
}

const noteToEdit = (state = null, action) => {
  switch (action.type) {
    case SET_NOTE_TO_EDIT:
      return action.id
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isAddNoteFormShown,
  noteToEdit,
})
