import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useMount, useUnmount } from 'react-use'

import { HEADER_TITLE_MAX_LENGTH, HEADER_SUBTITLE_MAX_LENGTH } from 'lib/constants/website'
import { headerValidationSchema } from 'lib/yupLocalised/schemas/website'
import { websiteDraftSelector } from 'state/concepts/website/selectors'
import { setWebsiteDraft } from 'state/concepts/website/actions'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import TextAreaField from 'views/shared/TextAreaField'
import DiscardFormButton from 'views/shared/DiscardFormButton'
import BackgroundImageField from './BackgroundImageField'

const WelcomeSectionSidebar = ({ toggleEditState, onClose }) => {
  const website = useSelector(websiteDraftSelector)
  const updateWebsiteDraft = useDispatchAction(setWebsiteDraft)
  const handleSubmit = values => {
    updateWebsiteDraft({ ...website, ...values })
    onClose()
  }

  useMount(toggleEditState)
  useUnmount(toggleEditState)

  return (
    <Formik
      initialValues={{
        headerTitle: website.headerTitle,
        headerSubtitle: website.headerSubtitle,
        backgroundImage: website.backgroundImage,
      }}
      validationSchema={headerValidationSchema}
      onSubmit={handleSubmit}
    >
      <div className="ws-header__editor">
        <div className="ws-header__editor-header" data-cy="section-editor-header">
          <span className="text-subheader font-600">
            <FormattedMessage id="websitePreview.welcomeSectionSidebar.title" />
          </span>
          <DiscardFormButton dataCy="cross-icon-button" isIcon icon="cross" onDiscard={onClose} />
        </div>
        <div className="ws-header__editor-body" data-cy="section-editor-body">
          <p className="font-600" data-cy="text-content-title">
            <FormattedMessage id="websitePreview.welcomeSectionSidebar.textContent" />
          </p>
          <Field
            name="headerTitle"
            id="headerTitle"
            icon="alert"
            component={InputWithCounterField}
            label={{ id: 'websitePreview.welcomeSectionSidebar.header.label' }}
            showCount
            maxChars={HEADER_TITLE_MAX_LENGTH}
            className="main-input--input-top-count"
            dataCy="header-title-input"
          />
          <Field
            name="headerSubtitle"
            id="headerSubtitle"
            icon="alert"
            component={TextAreaField}
            label={{ id: 'websitePreview.welcomeSectionSidebar.subheader.label' }}
            showCount
            className="main-textarea main-input--textarea-top-count"
            maxLength={HEADER_SUBTITLE_MAX_LENGTH}
            data-cy="header-subtitle-input"
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
          <BackgroundImageField
            name="backgroundImage"
            label={{ id: 'websitePreview.welcomeSectionSidebar.backgroundImage' }}
          />
        </div>
        <div className="ws-header__editor-footer" data-cy="section-editor-footer">
          <DiscardFormButton text={{ id: 'shared.close' }} size="small" className="mr-16" onDiscard={onClose} />
          <SubmitFormButton text={{ id: 'shared.apply' }} size="small" />
        </div>
      </div>
    </Formik>
  )
}

WelcomeSectionSidebar.propTypes = {
  toggleEditState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default WelcomeSectionSidebar
