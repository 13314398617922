import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { clientBookingsRoute, clientRoute } from 'lib/routes'
import { restoreAccountEndpoint } from 'state/concepts/client/endpoints'
import { RESTORE_ACCOUNT } from 'state/concepts/client/types'
import { currentUserProfileSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import setClientCookies from 'utils/setClientCookies'

const restoreAccountOperation = createLogic({
  type: RESTORE_ACCOUNT,
  latest: true,

  async process({ httpClient, action: { onSuccess }, getState }, dispatch, done) {
    const { url, endpoint } = restoreAccountEndpoint
    const state = getState()
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const currentUser = currentUserProfileSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)
      const tokens = data.meta?.jwt
      if (tokens) {
        setClientCookies(currentUser, tokens, { path: clientRoute(workspaceCode) })
      }
      dispatch(dataApiSuccess({ endpoint }))

      if (onSuccess) {
        onSuccess()
      } else {
        dispatch(showNotification({ messageObject: { id: 'notifications.accessRestored' } }))
        redirect({
          href: clientBookingsRoute(workspaceCode),
          reload: true,
        })
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default restoreAccountOperation
