/* eslint-disable no-template-curly-in-string */

const booking = {
  'booking.filters.statuses': '{count, plural, one {Status: {value}} other {Status}}',
  'booking.filters.statuses.scheduled': 'Scheduled',
  'booking.filters.statuses.cancelled': 'Cancelled',
  'booking.filters.statuses.requested': 'Requested',
  'booking.filters.statuses.rescheduled': 'Rescheduled',
  'booking.filters.statuses.completed': 'Completed',
  'booking.filters.calendarTypes': '{count, plural, one {Calendar: {value}} other {Calendar}}',
  'booking.filters.calendarTypes.expertbox': 'ExpertBox',
  'booking.filters.calendarTypes.available': 'Available',
  'booking.filters.calendarTypes.unavailable': 'Unavailable',
  'booking.filters.calendarTypes.googleCalendar': 'Google Calendar',
  'booking.filters.calendarTypes.outlook': 'Outlook',
  'booking.filters.calendarTypes.apple': 'iCloud',
  'booking.expertApp': 'ExpertBox app',
  'booking.consultationLink': 'Meeting link',
  'booking.openOnTheSmartphone': 'Open on the smartphone',
  'booking.scanThisQRCode': 'Scan this QR Code with ExpertBox app on your smartphone',
  'booking.orFollowThisLink': 'Or follow this link to open your Meeting page.',
  'booking.openConsultationIn': 'Open in',
  'booking.rateExperience': 'Rate experience',
  'booking.recurrence.till': '{frequency} <span>on</span> {days} <span>till</span> {till}',
  'booking.recurrence.times': '{frequency} <span>on</span> {days}, {count} {count, plural, one {time} other {times}}',
  'booking.rescheduleRecurrenceAppointment': 'Reschedule recurring _(appointment)?',
  'booking.editRecurrenceAppointment': 'Edit recurring _(appointment)?',
  'booking.amountAppointment.single': 'This _(appointment)',
  'booking.amountAppointment.all': 'This and following _(appointments)',
  'booking.filters.owner': '{fullName} <span>(you)</span>',

  'rescheduleBookingModal.reason': 'Reschedule reason',
  'rescheduleBookingModal.reschedule': 'Reschedule',
  'rescheduleBookingModal.morning': 'Morning',
  'rescheduleBookingModal.afternoon': 'Afternoon',
  'rescheduleBookingModal.evening': 'Evening',
  'rescheduleBookingModal.empty.timeSlot': 'There are no available time slots. Please try another date.',

  'cancelBookingModal.cancelAppointment': 'Cancel _(appointment)?',
  'cancelBookingModal.cancelAppointment.submit': 'Cancel _(appointment)',
  'cancelBookingModal.cancelAppointmentError': '_(Appointment) can’t be cancelled',
  'cancelBookingModal.reason': 'Cancelation reason',
  'cancelBookingModal.refund': 'Issue a full refund of ${refundAmount}',

  'cancelRecurringBookingModal.title': 'Cancel recurring _(appointment)?',
  'cancelRecurringBookingModal.recurringOption.single': 'This _(appointment)',
  'cancelRecurringBookingModal.recurringOption.all': 'This and following _(appointments)',
  'cancelRecurringBookingModal.refund':
    'Issue a full refund of ${refundAmount} ({refundCount} {refundCount, plural, one {_(appointment)} other {_(appointments)}})',

  'booking.reviews.title': 'Review',

  'declineWaitlistAppointmentModal.title': 'Decline _(appointment)?',
  'declineWaitlistAppointmentModal.body': "You won't be able to change your response later.",
  'declineWaitlistAppointmentModal.declineOption.declineAppointment': 'Decline this _(appointment)',
  'declineWaitlistAppointmentModal.declineOption.declineAppointmentAndDeleteWaitlistRequest':
    'Decline this _(appointment) and delete the waitlist request',
}

export default booking
