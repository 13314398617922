import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_BOOKING } from 'state/concepts/booking/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setBooking } from 'state/concepts/booking/actions'
import { getBookingEndpoint } from 'state/concepts/booking/endpoints'

const getBookingOperation = createLogic({
  type: GET_BOOKING,
  latest: true,

  async process({ action: { bookingCode }, httpClient }, dispatch, done) {
    const params = {
      include: [
        'client-profile.client-onboarding-content',
        'client-profile',
        'service-category',
        'booking-setting',
        'booking-recurrence',
        'service',
        'timezone',
        'user-profile',
        'workspace',
        'reschedule',
        'video-records',
        'client-profile-forms',
        'client-profile-forms.form',
        'client-profile-forms.workspace',
        'client-profile-forms.user-profile',
        'client-profile-forms.form-submission.client-signature',
        'client-profile-forms.form-submission.expert-signature',
        'review',
        'location',
        'transactions',
        'video-conference',
        'invited-members',
      ],
    }

    const { url, endpoint } = getBookingEndpoint(bookingCode)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const normalizedData = normalize(data)

      dispatch(setBooking(data.data.id))
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getBookingOperation
