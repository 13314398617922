export const CATEGORY_STATUS_ALL = 'all'

export const CATEGORY_STATUSES = {
  all: '',
  active: 'active',
  inactive: 'inactive',
}

export const SERVICE_CATEGORIES_STATUSES_FILTERS = [
  {
    value: CATEGORY_STATUS_ALL,
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: CATEGORY_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: CATEGORY_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
]

export const MAX_NAME_DISPLAY_LENGTH = 50
