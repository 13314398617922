const namespace = 'calendarIntegrations'

export const CONNECT_GOOGLE_CALENDAR = `${namespace}/CONNECT_GOOGLE_CALENDAR`
export const RECONNECT_GOOGLE_CALENDAR = `${namespace}/RECONNECT_GOOGLE_CALENDAR`
export const FETCH_CALENDAR_CONNECTIONS = `${namespace}/FETCH_CALENDAR_CONNECTIONS`
export const UPDATE_GOOGLE_CALENDAR_CONNECTION = `${namespace}/UPDATE_GOOGLE_CALENDAR_CONNECTION`
export const SET_GOOGLE_CALENDAR_CONNECTION_IDS = `${namespace}/SET_GOOGLE_CALENDAR_CONNECTION_IDS`
export const ADD_GOOGLE_CALENDAR_CONNECTION_IDS = `${namespace}/ADD_GOOGLE_CALENDAR_CONNECTION_IDS`
export const REMOVE_GOOGLE_CALENDAR_CONNECTION_IDS = `${namespace}/REMOVE_GOOGLE_CALENDAR_CONNECTION_IDS`
export const DISCONNECT_GOOGLE_CALENDAR = `${namespace}/DISCONNECT_GOOGLE_CALENDAR`
export const SET_GOOGLE_CALENDAR_CONNECT_LINK = `${namespace}/SET_GOOGLE_CALENDAR_CONNECT_LINK`
export const GET_GOOGLE_CALENDAR_CONNECT_LINK = `${namespace}/GET_GOOGLE_CALENDAR_CONNECT_LINK`
export const REMOVE_GOOGLE_CALENDAR_CONNECT_LINK = `${namespace}/REMOVE_GOOGLE_CALENDAR_CONNECT_LINK`
export const RECEIVE_GOOGLE_CALENDAR = `${namespace}/RECEIVE_GOOGLE_CALENDAR`
export const UPDATE_CALENDAR_CONNECTIONS = `${namespace}/UPDATE_CALENDAR_CONNECTIONS`
export const CONNECT_OUTLOOK_CALENDAR = `${namespace}/CONNECT_OUTLOOK_CALENDAR`
export const ADD_OUTLOOK_CALENDAR_CONNECTION_IDS = `${namespace}/ADD_OUTLOOK_CALENDAR_CONNECTION_IDS`
export const GET_OUTLOOK_CALENDAR_CONNECT_LINK = `${namespace}/GET_OUTLOOK_CALENDAR_CONNECT_LINK`
export const SET_OUTLOOK_CALENDAR_CONNECT_LINK = `${namespace}/SET_OUTLOOK_CALENDAR_CONNECT_LINK`
export const REMOVE_OUTLOOK_CALENDAR_CONNECT_LINK = `${namespace}/REMOVE_OUTLOOK_CALENDAR_CONNECT_LINK`
export const UPDATE_OUTLOOK_CALENDAR_CONNECTION = `${namespace}/UPDATE_OUTLOOK_CALENDAR_CONNECTION`
export const RECONNECT_OUTLOOK_CALENDAR = `${namespace}/RECONNECT_OUTLOOK_CALENDAR`
export const DISCONNECT_OUTLOOK_CALENDAR = `${namespace}/DISCONNECT_OUTLOOK_CALENDAR`
export const RECEIVE_OUTLOOK_CALENDAR = `${namespace}/RECEIVE_OUTLOOK_CALENDAR`
export const REMOVE_OUTLOOK_CALENDAR_CONNECTION_IDS = `${namespace}/REMOVE_OUTLOOK_CALENDAR_CONNECTION_IDS`
export const SET_OUTLOOK_CALENDAR_CONNECTION_IDS = `${namespace}/SET_OUTLOOK_CALENDAR_CONNECTION_IDS`
export const SET_CALENDAR_INTEGRATION_ID = `${namespace}/SET_CALENDAR_INTEGRATION_ID`
export const UPDATE_SYNC_CALENDAR = `${namespace}/UPDATE_SYNC_CALENDAR`
export const CONNECT_APPLE_CALENDAR = `${namespace}/CONNECT_APPLE_CALENDAR`
export const ADD_APPLE_CALENDAR_CONNECTION_IDS = `${namespace}/ADD_APPLE_CALENDAR_CONNECTION_IDS`
export const REMOVE_APPLE_CALENDAR_CONNECTION_IDS = `${namespace}/REMOVE_APPLE_CALENDAR_CONNECTION_IDS`
export const SET_APPLE_CALENDAR_CONNECTION_IDS = `${namespace}/SET_APPLE_CALENDAR_CONNECTION_IDS`
export const RECEIVE_APPLE_CALENDAR = `${namespace}/RECEIVE_APPLE_CALENDAR`
export const DISCONNECT_APPLE_CALENDAR = `${namespace}/DISCONNECT_APPLE_CALENDAR`
export const UPDATE_APPLE_CALENDAR_CONNECTION = `${namespace}/UPDATE_APPLE_CALENDAR_CONNECTION`
export const RECONNECT_APPLE_CALENDAR = `${namespace}/RECONNECT_APPLE_CALENDAR`
