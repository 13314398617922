import PropTypes from 'prop-types'
import { keys, last, length, pluck, without } from 'ramda'
import { Select } from 'antd'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import classNames from 'clsx'
import { useMount } from 'react-use'
import { FormattedMessage } from 'react-intl'
import { nanoid } from 'nanoid'

import Tooltip from 'views/shared/Tooltip'
import { searchedEmployeesSelector } from 'state/concepts/filters/selectors'
import isPresent from 'utils/isPresent'
import DropdownUserField from 'views/shared/DropdownUserField'
import SelectField from 'views/shared/SelectField'
import Cross from 'views/shared/icons/Cross'
import Plus from 'views/shared/icons/Plus'
import { DEFAULT_PERMISSION, PERMISSION_TYPES } from 'lib/constants/businessFiles'
import { ALL_EMPLOYEE_ROLES } from 'lib/constants/employees'

const NewPermissionsFields = ({ push, remove, onBack, myBusinessFile, form: { values } }) => {
  const employeeIds = pluck('id', useSelector(searchedEmployeesSelector))
  const { newPermissions, newProfileId } = values

  const { businessStoragePermissions, ancestorsPermissions = [], userProfile: owner } = myBusinessFile
  const sharedWith = pluck('userProfile', [...ancestorsPermissions, ...businessStoragePermissions])
  const selectedUserProfileIds = pluck('userProfileId', newPermissions)
  const excludedUserProfileIds = [...pluck('id', sharedWith), ...selectedUserProfileIds, owner.id]
  const hasAnyEmployees = isPresent(without(excludedUserProfileIds, employeeIds))
  const canAddMore = last(newPermissions)?.userProfileId && hasAnyEmployees

  const onAddMore = () => {
    push({
      userProfileId: undefined,
      permission: DEFAULT_PERMISSION,
    })
  }

  const onRemove = index => () => {
    const isResetAfterRemove = length(newPermissions) === 1
    remove(index)

    if (isResetAfterRemove) onBack()
  }

  useMount(() => {
    if (!isPresent(newPermissions)) {
      push({
        userProfileId: newProfileId,
        permission: DEFAULT_PERMISSION,
      })
    }
  })

  return (
    <>
      {newPermissions.map(({ userProfileId }, index) => (
        <div className="d-flex align-items-center gap-16 mb-16" key={userProfileId}>
          <Field
            component={DropdownUserField}
            withAvatar
            name={`newPermissions.${index}.userProfileId`}
            id="experts"
            statuses={['active']}
            icon="alert"
            excludeEmployees={without([userProfileId], excludedUserProfileIds)}
            className="mb-0 flex-1"
            placeholder={{ id: 'myBusinessFiles.SelectTeamMember.field.placeholder' }}
            roles={ALL_EMPLOYEE_ROLES}
          />
          <Field
            name={`newPermissions.${index}.permission`}
            optionLabelProp="label"
            className="mb-0 w-100 max-w-160"
            popupClassName="max-w-220 w-100"
            component={SelectField}
            placement="bottomRight"
          >
            {keys(PERMISSION_TYPES).map(permissionType => (
              <Select.Option
                label={<FormattedMessage id={`myBusinessFiles.addPermission.${permissionType}.label`} />}
                value={PERMISSION_TYPES[permissionType]}
                key={nanoid()}
              >
                <div>
                  <p className="mb-0 truncate-text">
                    <FormattedMessage id={`myBusinessFiles.addPermission.${permissionType}.label`} />
                  </p>
                  <p className="text-caption in-blue-gray-300 mb-0 truncate-text">
                    <FormattedMessage id={`myBusinessFiles.addPermission.${permissionType}.description`} />
                  </p>
                </div>
              </Select.Option>
            ))}
          </Field>
          <Tooltip
            overlayClassName="main-tooltip main-tooltip--no-pointer"
            icon={
              <button
                type="button"
                className="remove-items in-disabled-gray"
                onClick={onRemove(index)}
                data-cy="remove-button"
              >
                <Cross size={12} color="currentcolor" />
              </button>
            }
            placement="top"
            align={{ offset: [0, -2] }}
          >
            <FormattedMessage id="shared.delete" />
          </Tooltip>
        </div>
      ))}
      <div className="mt-24">
        <button
          className={classNames('main-link font-700', { 'main-link--disabled': !canAddMore })}
          disabled={!canAddMore}
          onClick={onAddMore}
          type="button"
        >
          <Plus size={14} className="mr-8 fill-currentcolor" />
          <span className="va-middle">
            <FormattedMessage id="myBusinessFiles.addPermission.addOneMore" />
          </span>
        </button>
      </div>
    </>
  )
}

NewPermissionsFields.propTypes = {
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  myBusinessFile: PropTypes.shape({
    businessStoragePermissions: PropTypes.arrayOf(PropTypes.shape({})),
    ancestorsPermissions: PropTypes.arrayOf(PropTypes.shape({})),
    userProfile: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      newPermissions: PropTypes.arrayOf(PropTypes.shape({})),
      newProfileId: PropTypes.string,
    }),
  }).isRequired,
  onBack: PropTypes.func.isRequired,
}

export default NewPermissionsFields
