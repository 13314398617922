import { createLogic } from 'redux-logic'
import { mergeAll } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { websiteRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { prepareEditorValue } from 'utils/reactDraftEditor'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_CONTENT_DETAILS } from 'state/concepts/website/types'

const updateContentDetailsOperation = createLogic({
  type: UPDATE_CONTENT_DETAILS,
  latest: true,

  async process({ action: { values, form, websiteId, websiteSettingId }, httpClient, getState }, dispatch, done) {
    try {
      const params = {
        status: values.status,
        website_setting: {
          privacy_policy_enabled: values.privacyPolicyEnabled,
          privacy_policy: prepareEditorValue(values.privacyPolicy),
          terms_and_conditions_enabled: values.termsAndConditionsEnabled,
          terms_and_conditions: prepareEditorValue(values.termsAndConditions),
        },
      }

      await httpClient.put(websiteRoute, params)

      const state = getState()
      const responseUpdates = []

      responseUpdates.push(
        updateDataHelper(state.data, 'website', websiteId, {
          attributes: {
            status: params.status,
          },
        }),
      )

      responseUpdates.push(
        updateDataHelper(state.data, 'websiteSetting', websiteSettingId, {
          attributes: {
            privacyPolicyEnabled: params.website_setting.privacy_policy_enabled,
            privacyPolicy: params.website_setting.privacy_policy,
            termsAndConditionsEnabled: params.website_setting.terms_and_conditions_enabled,
            termsAndConditions: params.website_setting.terms_and_conditions,
          },
        }),
      )

      dispatch(dataApiSuccess({ response: mergeAll(responseUpdates) }))
      dispatch(showNotification({ messageObject: { id: 'notifications.websiteSettingsWereUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateContentDetailsOperation
