import classNames from 'clsx'
import PropTypes from 'prop-types'

const CountIndicator = ({ current, max }) => {
  const isSuccess = current === max

  return (
    <span
      className={classNames('tooltip-doc-counter ml-8 pt-4 pb-4 lh-1', {
        'bg-green-200 in-green-700': isSuccess,
        'in-blue-600 bg-blue-200': !isSuccess,
      })}
      data-cy="count-indicator"
    >
      {current}/{max}
    </span>
  )
}

CountIndicator.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default CountIndicator
