import { Select } from 'antd'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import profileIdentity from 'utils/employees/profileIdentity'
import roleSelectOptions from 'utils/employees/roleSelectOptions'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import SelectField from 'views/shared/SelectField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const EmployeesChangeRoleModalComponent = ({ onClose, handleSubmit, employee }) => {
  const { formatMessage } = useIntl()
  const roleOptions = roleSelectOptions({ withScheduler: true })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="employeesChangeRoleModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <p>
            <FormattedMessage
              id="employeesChangeRoleModal.changeRoleFor"
              values={{
                name: <span className="font-700">{profileIdentity(employee)}</span>,
              }}
            />
          </p>
          <div>
            <Field
              className="mb-16"
              name="roleName"
              id="roleName"
              label={{ id: 'employeeAccount.changeRole.role' }}
              component={SelectField}
              optionLabelProp="label"
            >
              {roleOptions.map(({ label, value, key: optionKey, description }) => (
                <Select.Option
                  className="role-dialog__select-option"
                  label={formatMessage(label)}
                  key={optionKey}
                  value={value}
                >
                  <p className="text-body mb-0">
                    <FormattedMessage {...label} />
                  </p>
                  <p className="text-caption in-blue-gray-300 mb-0">
                    <FormattedMessage {...description} />
                  </p>
                </Select.Option>
              ))}
            </Field>
          </div>
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="main-modal__footer-action"
              text={{ id: 'shared.cancel' }}
              onClick={onClose}
              kind="flat"
            />
            <SubmitFormButton
              className="main-modal__footer-action"
              text={{ id: 'shared.saveChanges' }}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

EmployeesChangeRoleModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default EmployeesChangeRoleModalComponent
