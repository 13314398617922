import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { last } from 'ramda'

import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import Alert from 'views/shared/Alert'
import Cross from 'views/shared/icons/Cross'
import { ALERT_KINDS } from 'lib/constants'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { ETHERPAD_DOCUMENT_PERMISSION_OPTIONS } from 'lib/constants/etherpadDocuments'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { updateEtherpadDocumentClientAccess } from 'state/concepts/etherpadDocuments/actions'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import useExpertActions from 'hooks/etherpadDocuments/useExpertActions'

const UpdateEtherpadDocumentClientAccessModal = ({ onClose, document }) => {
  const handleSubmit = useFormSubmit(updateEtherpadDocumentClientAccess)
  const documentPermission = last(document.documentPermissions)
  const isContactsEnabled = useWorkspaceFeature(FEATURE_KEYS.clientContacts)
  const { onRemoveClientAccess } = useExpertActions({ document })

  return (
    <Modal className="main-modal main-modal--md">
      <Formik
        initialValues={{
          role: documentPermission.role,
          documentId: document.id,
          permissionId: documentPermission.id,
          clientId: document.clientProfile.id,
        }}
        onSubmit={handleSubmit}
      >
        <div className="main-modal__container">
          <div className="main-modal__header">
            <h2 className="main-modal__title">
              <FormattedMessage id="etherpadDocuments.editClientAccess.modal.title" />
            </h2>
            <button type="button" className="main-modal__close" onClick={onClose}>
              <Cross dataCy="cross-icon" />
            </button>
          </div>
          <div className="main-modal__body">
            {isContactsEnabled && (
              <Alert
                type={ALERT_KINDS.info}
                className="align-items-center mb-16 pl-12 pr-12"
                iconSize={20}
                message={{ id: 'etherpadDocuments.editClientAccess.modal.alert' }}
              />
            )}
            <Field
              id="role"
              name="role"
              wrapperClassName="d-flex flex-column row-gap-12"
              mainRadioLabelClassName="font-700 d-block main-radio__text--small"
              mainRadioDescriptionClassName="text-caption"
              className="align-items-center main-radio--black-label mb-0"
              options={ETHERPAD_DOCUMENT_PERMISSION_OPTIONS}
              component={RadioField}
            />
          </div>
          <div className="main-modal__footer">
            <Button
              className="mr-auto"
              kind="danger-secondary"
              text={{ id: 'etherpadDocuments.editClientAccess.modal.removeAccess' }}
              onClick={onRemoveClientAccess}
            />
            <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
            <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.saveChanges' }} />
          </div>
        </div>
      </Formik>
    </Modal>
  )
}

UpdateEtherpadDocumentClientAccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape().isRequired,
}

export default UpdateEtherpadDocumentClientAccessModal
