import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import GoogleInfo from './GoogleInfo'
import ZoomInfo from './ZoomInfo'
import TeamsInfo from './TeamsInfo'

const DETAILS_BY_PROVIDER = {
  [VIDEO_CALLS_PROVIDERS.googleMeet]: GoogleInfo,
  [VIDEO_CALLS_PROVIDERS.zoom]: ZoomInfo,
  [VIDEO_CALLS_PROVIDERS.microsoftTeams]: TeamsInfo,
}

export default DETAILS_BY_PROVIDER
