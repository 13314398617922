import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { RESEND_EMAIL_CONFIRMATION } from 'state/concepts/client/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { resendEmailConfirmationEndpoint } from 'state/concepts/client/endpoints'

const resendEmailConfirmationOperation = createLogic({
  type: RESEND_EMAIL_CONFIRMATION,
  latest: true,

  async process({ httpClient, action: { email } }, dispatch, done) {
    const { url, endpoint } = resendEmailConfirmationEndpoint

    const params = {
      email,
    }

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.emailConfirmationResented' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default resendEmailConfirmationOperation
