import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Paragraph = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 659 512"
    data-cy={dataCy}
  >
    <path d="M0.357 329.143v-36.572h658.285v73.143h-658.285zM0.357 146.286h658.285v73.143h-658.285v-73.143zM0.357 0h658.285v73.143h-658.285v-73.143zM329.5 512h-329.143v-73.143h329.143v73.143z" />
  </svg>
)

Paragraph.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Paragraph.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Paragraph
