import endpoint from 'utils/endpoint'
import {
  agreementsRoute,
  agreementRoute,
  formRoute,
  agreementDownloadRoute,
  formActivationRoute,
  formDeactivationRoute,
} from 'lib/apiRoutes'
import {
  CREATE_AGREEMENT,
  FETCH_AGREEMENT,
  UPDATE_AGREEMENT,
  DELETE_AGREEMENT,
  DOWNLOAD_AGREEMENT,
  UPDATE_AGREEMENT_ACTIVATION,
} from './types'

export const createAgreementEndpoint = endpoint(CREATE_AGREEMENT, 'POST', agreementsRoute)
export const fetchAgreementEndpoint = id => endpoint(FETCH_AGREEMENT, 'GET', formRoute(id))
export const updateAgreementEndpoint = id => endpoint(UPDATE_AGREEMENT, 'PUT', agreementRoute(id))
export const deleteAgreementEndpoint = id => endpoint(DELETE_AGREEMENT, 'DELETE', formRoute(id))
export const downloadAgreementEndpoint = submissionId =>
  endpoint(DOWNLOAD_AGREEMENT, 'GET', agreementDownloadRoute(submissionId))
export const updateAgreementActivationEndpoint = (formId, isEnable) =>
  endpoint(UPDATE_AGREEMENT_ACTIVATION, 'POST', isEnable ? formActivationRoute(formId) : formDeactivationRoute(formId))
