import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import redirect from 'utils/redirect'
import { currentWorkspaceCodeSelector, isClientLoggedInSelector } from 'state/concepts/session/selectors'
import {
  etherpadDocumentDeclineToSignSuccessRoute,
  etherpadDocumentNoPermissionToSignRoute,
  etherpadDocumentSignFailRoute,
  isDocumentErrorEqTo,
} from 'utils/etherpadDocuments'
import { ALERT_KINDS } from 'lib/constants'
import { declineDocumentEndpoint } from '../endpoints'
import { DECLINE_TO_SIGN_ETHERPAD_DOCUMENT } from '../types'

const declineEtherpadDocumentSigningOperation = createLogic({
  type: DECLINE_TO_SIGN_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = declineDocumentEndpoint(values.documentId)
    const state = getState()
    const isClientLoggedIn = isClientLoggedInSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    const params = { decline_reason: values.reason, include: ['declined-by.profileable'] }

    try {
      await httpClient.put(url, params)
      const redirectUrl = etherpadDocumentDeclineToSignSuccessRoute({ workspaceCode, isClientLoggedIn })

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.documentDeclined' } }))
      dispatch(hideModal())
      redirect({
        href: redirectUrl,
      })
    } catch (error) {
      if (isDocumentErrorEqTo(error, 'not_document_signatory')) {
        const redirectUrl = etherpadDocumentSignFailRoute({
          isEmployeeLoggedIn: false,
          workspaceCode,
          isClientLoggedIn,
        })
        dispatch(hideModal())
        isClientLoggedIn &&
          dispatch(
            showNotification({
              messageObject: { id: 'notifications.etherpadDocumentIsNotSigned' },
              kind: ALERT_KINDS.warning,
            }),
          )
        redirect({
          href: redirectUrl,
        })
      }
      if (isDocumentErrorEqTo(error, 'forbidden_for_contact')) {
        const redirectUrl = etherpadDocumentNoPermissionToSignRoute({
          workspaceCode,
          isClientLoggedIn,
        })
        dispatch(hideModal())
        isClientLoggedIn &&
          dispatch(
            showNotification({
              messageObject: { id: 'notifications.etherpadDocumentNoPermission' },
              kind: ALERT_KINDS.error,
            }),
          )
        redirect({
          href: redirectUrl,
        })
      }
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default declineEtherpadDocumentSigningOperation
