import endpoint from 'utils/endpoint'

import {
  servicesRoute,
  servicesBulkActionsDeactivationRoute,
  servicesBulkActionsActivationRoute,
  servicesBulkActionsRemoveRoute,
} from 'lib/apiRoutes'
import { FETCH_SERVICES, DEACTIVATE_SERVICES, ACTIVATE_SERVICES, REMOVE_SERVICES } from './types'

export const fetchServicesEndpoint = endpoint(FETCH_SERVICES, 'GET', servicesRoute)
export const deactivateServiceEndpoint = endpoint(DEACTIVATE_SERVICES, 'POST', servicesBulkActionsDeactivationRoute)
export const activateServiceEndpoint = endpoint(ACTIVATE_SERVICES, 'POST', servicesBulkActionsActivationRoute)
export const removeServicesEndpoint = endpoint(REMOVE_SERVICES, 'POST', servicesBulkActionsRemoveRoute)
