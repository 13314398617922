import { path, pathEq } from 'ramda'
import { createLogic } from 'redux-logic'

import { ALERT_KINDS } from 'lib/constants'
import { showNotification } from 'state/notifications/actions'
import handleDownload from 'utils/handleDownload'
import isPresent from 'utils/isPresent'
import { cancelFilesDownloadSuccess, downloadBusinessFiles, filesDownloadSuccess } from '../actions'
import { pendingFilesDownloadSelector, downloadingFilesSelector } from '../selectors'
import { COMPLETE_FILES_DOWNLOAD } from '../types'

const completeFilesDownloadOperation = createLogic({
  type: COMPLETE_FILES_DOWNLOAD,
  latest: true,

  async process({ getState, action: { data } }, dispatch, done) {
    const pendingFilesDownload = pendingFilesDownloadSelector(getState())
    const downloadingFiles = downloadingFilesSelector(getState())
    const uploadId = path(['data', 'id'], data)
    const fileUrl = path(['data', 'attributes', 'file-url'], data)
    const isArchiveCancelled = pathEq('archive_canceled', ['meta', 'type'], data)

    if (isArchiveCancelled) {
      dispatch(cancelFilesDownloadSuccess(uploadId))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.noPermissionToPerformAction' },
          kind: ALERT_KINDS.error,
        }),
      )
      done()
      return
    }

    if (isPresent(pendingFilesDownload)) {
      dispatch(downloadBusinessFiles(pendingFilesDownload[0]))
    }
    if (downloadingFiles[uploadId]) {
      handleDownload(fileUrl)
      dispatch(filesDownloadSuccess(uploadId))
    }
    done()
  },
})

export default completeFilesDownloadOperation
