import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import { addSearchResults, resetSearchResults } from 'state/searchResults/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchClientRelationshipTypesEndpoint } from '../endpoints'
import { SEARCH_CLIENT_RELATIONSHIP_TYPES } from '../types'

const searchClientRelationshipTypesOperation = createLogic({
  type: SEARCH_CLIENT_RELATIONSHIP_TYPES,
  latest: true,

  async process({ httpClient, action: { resetResults } }, dispatch, done) {
    const { url, endpoint } = fetchClientRelationshipTypesEndpoint
    const params = {
      page: {
        size: 100,
        number: 1,
      },
    }
    dispatch(dataApiRequest({ endpoint }))

    try {
      if (resetResults) {
        dispatch(resetSearchResults(SEARCH_RESULTS_ENTITIES.clientRelationshipTypes))
      }
      const { data } = await httpClient.get(url, { params })
      const ids = pluck('id', data.data)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.clientRelationshipTypes]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default searchClientRelationshipTypesOperation
