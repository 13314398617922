import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchClientRelationshipTypeEndpoint } from '../endpoints'
import { FETCH_CLIENT_RELATIONSHIP_TYPE } from '../types'
import { setClientRelationshipTypeId } from '../actions'

const fetchClientRelationshipTypeOperation = createLogic({
  type: FETCH_CLIENT_RELATIONSHIP_TYPE,
  latest: true,

  async process({ httpClient, action: { id } }, dispatch, done) {
    const { url, endpoint } = fetchClientRelationshipTypeEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setClientRelationshipTypeId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchClientRelationshipTypeOperation
