import { createSelector } from '@reduxjs/toolkit'
import { path, propEq, reject } from 'ramda'
import build from 'redux-object'

import { CALENDAR_CONNECTION_STATUSES } from 'lib/constants'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import isPresent from 'utils/isPresent'
import {
  updateAppleCalendarConnectionEndpoint,
  updateGoogleCalendarConnectionEndpoint,
  updateOutlookCalendarConnectionEndpoint,
} from './endpoints'

export const googleCalendarConnectionIdsSelector = path(['calendarIntegrations', 'googleCalendarConnectionIds'])

export const buildCalendarConnections = calendarType => (ids, data) => {
  if (!isPresent(ids)) return []

  return reject(propEq(CALENDAR_CONNECTION_STATUSES.removing, 'status'), build(data, calendarType, ids) || [])
}

export const googleCalendarConnectionsSelector = createSelector(
  [googleCalendarConnectionIdsSelector, dataSelector],
  buildCalendarConnections('googleCalendarConnection'),
)

export const googleCalendarConnectionSelector = createSelector([(_state, id) => id, dataSelector], (id, data) =>
  build(data, 'googleCalendarConnection', id),
)

export const outlookCalendarConnectionSelector = createSelector([(_state, id) => id, dataSelector], (id, data) =>
  build(data, 'outlookCalendarConnection', id),
)

export const appleCalendarConnectionSelector = createSelector([(_state, id) => id, dataSelector], (id, data) =>
  build(data, 'appleCalendarConnection', id),
)

export const outlookCalendarConnectionIdsSelector = path(['calendarIntegrations', 'outlookCalendarConnectionIds'])

export const outlookCalendarConnectionsSelector = createSelector(
  [outlookCalendarConnectionIdsSelector, dataSelector],
  buildCalendarConnections('outlookCalendarConnection'),
)

export const googleCalendarConnectLinkSelector = (state, id) =>
  path(['calendarIntegrations', 'googleCalendarConnectLink', id], state)

export const updatingGoogleCalendarSelector = (state, id) =>
  loadingSelector(state, updateGoogleCalendarConnectionEndpoint(id).endpoint)

export const updatingOutlookCalendarSelector = (state, id) =>
  loadingSelector(state, updateOutlookCalendarConnectionEndpoint(id).endpoint)

export const outlookCalendarConnectLinkSelector = (state, id) =>
  path(['calendarIntegrations', 'outlookCalendarConnectLink', id], state)

export const appleCalendarConnectionIdsSelector = path(['calendarIntegrations', 'appleCalendarConnectionIds'])

export const appleCalendarConnectionsSelector = createSelector(
  [appleCalendarConnectionIdsSelector, dataSelector],
  buildCalendarConnections('appleCalendarConnection'),
)

export const calendarConnectionsCountSelector = createSelector(
  [googleCalendarConnectionsSelector, outlookCalendarConnectionsSelector, appleCalendarConnectionsSelector],
  (googleCalendarConnections, outlookCalendarConnections, appleCalendarConnections) =>
    (googleCalendarConnections?.length || 0) +
    (outlookCalendarConnections?.length || 0) +
    (appleCalendarConnections.length || 0),
)

export const calendarIntegrationIdSelector = path(['calendarIntegrations', 'calendarIntegrationId'])

export const calendarIntegrationSelector = createSelector(calendarIntegrationIdSelector, dataSelector, (id, data) =>
  id ? build(data, 'calendarIntegration', id) : null,
)

export const updatingAppleCalendarSelector = (state, id) =>
  loadingSelector(state, updateAppleCalendarConnectionEndpoint(id).endpoint)
