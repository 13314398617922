import { useFormikContext } from 'formik'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const SubmitPanel = ({ onClose }) => {
  const { dirty } = useFormikContext()

  return dirty ? (
    <>
      <p className="in-gray-700 align-self-center mb-0 mr-auto">
        <FormattedMessage id="shared.unsavedChanges" />
      </p>
      <div className="d-flex justify-content-end">
        <Button onClick={onClose} className="mr-16" kind="flat" text={{ id: 'shared.discard' }} />
        <SubmitFormButton text={{ id: 'shared.saveChanges' }} />
      </div>
    </>
  ) : (
    <div className="d-flex justify-content-end">
      <Button text={{ id: 'shared.done' }} onClick={onClose} />
    </div>
  )
}

SubmitPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SubmitPanel
