import cookies from 'component-cookie'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import removeClientCookies from './removeClientCookies'
import setSharedCookies from './setSharedCookies'

const setCookies = (currentEmployee, jwt, cookieOptions) => {
  cookies('tokens', JSON.stringify(jwt), cookieOptions)
  cookies('currentEmployeeId', currentEmployee.id, cookieOptions)
  cookies('currentWorkspaceId', currentEmployee.workspace.id, cookieOptions)
  cookies('currentWorkspaceCode', currentEmployee.workspace.uniqCode, cookieOptions)
  cookies('path', cookieOptions.path, cookieOptions)
}

const setEmployeeCookies = (currentEmployee, jwt, cookieOptions = { path: '/' }) => {
  setSharedCookies(cookieOptions)
  setCookies(currentEmployee, jwt, cookieOptions)

  removeClientCookies({ path: COOKIES_PATH })
  setCookies(currentEmployee, jwt, { path: COOKIES_PATH })
}

export default setEmployeeCookies
