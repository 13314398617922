import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import ReactDraftWysiwygPreview from 'views/shared/ReactDraftWysiwygPreview'

const WysiwygModal = ({ onClose, content, title }) => (
  <Modal wrapClassName="ant-modal-wrap--full-mobile" onClose={onClose}>
    <div className="main-modal__container main-modal__container--full-height">
      <div className="client-intake-header">
        <div className="client-intake-header__close">
          <button type="button" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-9">
              <h1 className="text-headline mb-16">
                <FormattedMessage {...title} />
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="main-modal__body">
        <div className="container">
          <ReactDraftWysiwygPreview content={content} />
        </div>
      </div>
    </div>
  </Modal>
)

WysiwygModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
}

export default WysiwygModal
