import * as types from './types'

export const setCurrentPage = (entity, number) => ({
  type: types.SET_TABLE_PAGINATION,
  entity,
  number,
})

export const setOrderDirections = (entity, orderDirections) => ({
  type: types.SET_TABLE_ORDER_DIRECTIONS,
  entity,
  orderDirections,
})
