import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { ADD_CREATED_TASK_ID, REMOVE_TASK_ID } from 'state/concepts/tasks/types'
import { ADD_CLIENT_TASK_IDS, RESET_CLIENT_TASKS } from './types'

const taskIds = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_TASK_IDS:
      return uniq([...state, ...action.ids])
    case ADD_CREATED_TASK_ID:
      return [action.id, ...state]
    case RESET_CLIENT_TASKS:
      return []
    case REMOVE_TASK_ID:
      return without([action.id], state)
    default:
      return state
  }
}

export default combineReducers({
  taskIds,
})
