import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import useFormDiscard from 'hooks/shared/form/useFormDiscard'
import Button from 'views/shared/Button'

const DiscardFormButton = ({ modalProps, onDiscard, component, ...rest }) => {
  const { dirty } = useFormikContext()
  const handleDiscard = useFormDiscard({ modalProps, onDiscard })
  const handleClick = dirty ? handleDiscard : onDiscard
  const ButtonComponent = component || Button

  return <ButtonComponent dataCy="discard-form-button" kind="flat" size="medium" onClick={handleClick} {...rest} />
}

DiscardFormButton.defaultProps = {
  text: { id: 'shared.discard' },
  modalProps: {},
  onDiscard: undefined,
  component: null,
}

DiscardFormButton.propTypes = {
  text: PropTypes.shape(),
  modalProps: PropTypes.shape(),
  onDiscard: PropTypes.func,
  component: PropTypes.elementType,
}

export default DiscardFormButton
