import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { filter, propEq } from 'ramda'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import isPresent from 'utils/isPresent'
import { activateServices } from 'state/concepts/services/actions'
import { activateServicesLoadingSelector } from 'state/concepts/services/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServicesActivateModal = ({ service, onClose }) => {
  const { id, name, locations, online } = service
  const isLoading = useSelector(activateServicesLoadingSelector)
  const handleActivateService = useDispatchAction(activateServices, [id])
  const isActivationDisabled = !isPresent(filter(propEq('active', 'status'), locations)) && !online

  return (
    <ConfirmModal
      title={{ id: 'servicesActivateModal.title' }}
      bodyText={
        isActivationDisabled
          ? [
              {
                id: 'servicesActivateModal.body.alert',
                isAlert: true,
                type: 'error',
                className: 'align-items-center',
                message: { id: 'servicesActivateModal.body.alert' },
              },
            ]
          : [
              {
                id: 'servicesActivateModal.body',
                values: { name: <span className="font-600">{name}</span> },
              },
            ]
      }
      submitText={{ id: 'shared.activate' }}
      onClose={onClose}
      onSubmit={!isActivationDisabled ? handleActivateService : undefined}
      isLoading={isLoading}
    />
  )
}

ServicesActivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    online: PropTypes.bool.isRequired,
  }).isRequired,
}

export default ServicesActivateModal
