import PropTypes from 'prop-types'

import LocationDetails from 'views/shared/LocationDetails'
import isPresent from 'utils/isPresent'

const Locations = ({ locations }) => {
  if (!isPresent(locations)) {
    return null
  }

  if (Array.isArray(locations)) {
    return (
      <>
        {locations.map((location, index) => (
          <LocationDetails
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            hasIcon={index === 0}
            location={location}
          />
        ))}
      </>
    )
  }

  return <LocationDetails hasIcon location={locations} />
}

Locations.propTypes = {
  locations: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]).isRequired,
}

export default Locations
