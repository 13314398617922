import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import redirect from 'utils/redirect'
import { CREATE_WIDGET } from 'state/concepts/widget/types'
import { showNotification } from 'state/notifications/actions'
import { widgetsRoute } from 'lib/apiRoutes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsBookingPagesRoute } from 'lib/routes'

const createWidgetOperation = createLogic({
  type: CREATE_WIDGET,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { form, values, onError } = action
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    try {
      const params = {
        name: values.name,
        url_slug: values.urlSlug,
        color: values.color,
        status: values.status,
        service_ids: values.services,
      }

      await httpClient.post(widgetsRoute, params)

      redirect({ href: companySettingsBookingPagesRoute, workspace: workspaceCode })
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingPageHasBeenCreated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
      onError?.()
    }

    done()
  },
})

export default createWidgetOperation
