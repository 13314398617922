const etherpadDocuments = {
  'etherpadDocuments.created': 'Created:',
  'etherpadDocuments.shared': 'Shared:',
  'etherpadDocuments.signers': 'Signers:',
  'etherpadDocuments.lastEdited': 'Last edited:',
  'etherpadDocuments.signer': '<span>{fullName}</span> ({userRoleOrEmail}) - {status}',
  'etherpadDocuments.signer.status.signed': 'signed',
  'etherpadDocuments.signer.status.sentForSignature': 'sent for signature',
  'etherpadDocuments.signer.status.awaiting': 'awaiting',
  'etherpadDocuments.signer.role.you': 'you',
  'etherpadDocuments.signer.role.expert': '_(expert)',
  'etherpadDocuments.signer.role.client': '_(client)',
  'etherpadDocuments.permission': '<span>{fullName}</span> - {role}',
  'etherpadDocuments.permission.role.viewer': 'viewer',
  'etherpadDocuments.permission.role.commenter': 'commenter',
  'etherpadDocuments.permission.role.editor': 'editor',
  'etherpadDocuments.renameModal.title': 'Rename document',
  'etherpadDocuments.renameModal.name.label': 'Name',
  'etherpadDocuments.voidModal.title': 'Void this document?',
  'etherpadDocuments.voidModal.description':
    'Voiding the document will cancel all pending signatures, and it can’t be reactivated.',
  'etherpadDocuments.voidModal.voidReason.label': 'Reason for voiding:',
  'etherpadDocuments.voidModal.submitText': 'Void document',
  'etherpadDocuments.voidedBy': '{fullName} voided this document.',
  'etherpadDocuments.voidReason': 'Reason for voiding:',
  'etherpadDocuments.declinedBy': '{fullName} declined to sign this document.',
  'etherpadDocuments.declineReason': 'Reason for declining:',
  'etherpadDocuments.signModal.title': 'Sign document',
  'etherpadDocuments.signModal.description': 'Your signature',
  'etherpadDocuments.signModal.name.label': 'Enter your name',
  'etherpadDocuments.signModal.name.placeholder.expert': '_(Expert) Name',
  'etherpadDocuments.signModal.name.placeholder.client': 'Signer Name',
  'etherpadDocuments.signModal.submitText': 'Sign document',
  'etherpadDocuments.document': 'Document',
  'etherpadDocuments.document.status.waitingForSignature': 'Waiting for signatures',
  'etherpadDocuments.document.status.signed': 'Signed',
  'etherpadDocuments.document.status.declined': 'Declined',
  'etherpadDocuments.document.status.voided': 'Voided',
  'etherpadDocuments.document.actions.edit': 'Edit',
  'etherpadDocuments.document.actions.rename': 'Rename',
  'etherpadDocuments.document.actions.downloadPdf': 'Download as PDF',
  'etherpadDocuments.document.actions.editSigners': 'Edit signers',
  'etherpadDocuments.document.actions.remindToSign': 'Remind to sign',
  'etherpadDocuments.document.actions.reviewAndSign': 'Review and sign',
  'etherpadDocuments.document.actions.void': 'Void',
  'etherpadDocuments.document.actions.view': 'View',
  'etherpadDocuments.document.actions.signDocument': 'Sign document',
  'etherpadDocuments.document.alert.alreadySigned':
    'You’ve already signed this document. Review the document below or download it in PDF format.',
  'etherpadDocuments.document.signers': 'Signers',
  'etherpadDocuments.document.defaultSignature': 'Signature',
  'etherpadDocuments.document.signedBy': 'Signed by {fullName}',
  'etherpadDocuments.document.signedBy.date': '{date} UTC',
  'etherpadDocuments.document.signers.client': '(_(client))',
  'etherpadDocuments.document.signers.expert': '(_(expert))',
  'etherpadDocuments.document.signers.you': '(you)',
  'etherpadDocuments.document.signerStatus.awaiting': 'Awaiting',
  'etherpadDocuments.document.signerStatus.sentForSignature': 'Sent for signature',
  'etherpadDocuments.document.signerStatus.signed': 'Signed',
  'etherpadDocuments.document.actions.shareWithClient': 'Share with _(client)',
  'etherpadDocuments.document.actions.editClientAccess': 'Edit _(client) access',
  'etherpadDocuments.document.actions.removeClientAccess': 'Remove _(client) access',
  'etherpadDocuments.emptyState.body': 'This is where you’ll see all the documents related to _(client).',
  'etherpadDocuments.emptyState.title': 'No documents yet',
  'etherpadDocuments.emptyState.alt': 'No documents yet',
  'etherpadDocuments.download': 'Download',
  'etherpadDocuments.download.doc': 'Microsoft Word (.doc)',
  'etherpadDocuments.download.docx': 'Microsoft Word (.docx)',
  'etherpadDocuments.download.odt': 'OpenDocument Format (.odt)',
  'etherpadDocuments.download.rtf': 'Rich Text Format (.rtf)',
  'etherpadDocuments.download.pdf': 'PDF Document (.pdf)',
  'etherpadDocuments.download.txt': 'Plain Text (.txt)',
  'etherpadDocuments.download.html': 'Web Page (.html)',
  'etherpadDocuments.renameDocumentBeforeSharingModal.title': 'Rename document before sharing',
  'etherpadDocuments.renameDocumentBeforeSharingModal.button': 'Save and continue',
  'etherpadDocuments.shareDocumentWithClientModal.title': 'Share document with the _(client)',
  'etherpadDocuments.shareDocumentWithClientModal.viewer.title': 'Viewer',
  'etherpadDocuments.shareDocumentWithClientModal.viewer.description': 'Can only view the shared document.',
  'etherpadDocuments.shareDocumentWithClientModal.permission': 'Permission:',
  'etherpadDocuments.shareDocumentWithClientModal.documentIsSentForSignature.viewer.alert':
    'Once a document is sent for signature, it can only be viewed.',
  'etherpadDocuments.shareDocumentWithClientModal.alert':
    'The document will be shared with the _(client) contacts who have corresponding relationship permissions.',
  'etherpadDocuments.shareDocumentWithClientModal.option.viewer.label': '<span>Viewer</span>',
  'etherpadDocuments.shareDocumentWithClientModal.option.viewer.description':
    '<span>Can only view the shared document.</span>',
  'etherpadDocuments.shareDocumentWithClientModal.option.commenter.label': '<span>Commenter</span>',
  'etherpadDocuments.shareDocumentWithClientModal.option.commenter.description':
    '<span>Can view, comment, and suggest changes to the shared document.</span>',
  'etherpadDocuments.shareDocumentWithClientModal.option.editor.label': '<span>Editor</span>',
  'etherpadDocuments.shareDocumentWithClientModal.option.editor.description':
    '<span>Can make changes to all content within the shared document.</span>',
  'etherpadDocuments.shareDocumentWithClientModal.submit': 'Share document',
  'etherpadDocuments.editClientAccess.modal.title': 'Edit _(client) access',
  'etherpadDocuments.editClientAccess.modal.removeAccess': 'Remove access',
  'etherpadDocuments.editClientAccess.modal.alert':
    'Changes made to _(client) access may affect the access of associated contacts to this document.',

  'etherpadDocuments.removeClientAccess.modal.title': 'Remove _(client) access',
  'etherpadDocuments.removeClientAccess.modal.description': '{clientName} won’t be able to access this document.',
  'etherpadDocuments.open': 'Open',
  'etherpadDocuments.deleteDocument.modal.title': 'Delete document?',
  'etherpadDocuments.deleteDocument.modal.description':
    'The document will be permanently deleted from the _(client) profile for everyone.',
  'etherpadDocuments.deleteDocument.modal.delete': 'Delete document',
  'etherpadDocuments.sendForSignatureAlert': 'Once a document is sent for signature, it <bold>can’t be edited</bold>.',
  'etherpadDocuments.whoWillSign': 'Who will sign the document?',
  'etherpadDocuments.signOptions.meAndOthers.label': 'Me and others',
  'etherpadDocuments.signOptions.meAndOthers.description': 'Sign the document together with others.',
  'etherpadDocuments.signOptions.onlyOther.label': 'Only others',
  'etherpadDocuments.signOptions.onlyOther.description': 'Send the document for others to sign.',
  'etherpadDocuments.signOptions.onlyMe.label': 'Only me',
  'etherpadDocuments.signOptions.onlyMe.description': 'Sign the document yourself.',
  'etherpadDocuments.addSigners': 'Add signers',
  'etherpadDocuments.signers.weWillEmail':
    'We will email new signers a link to view and sign the document. Removed signers will lose their access.',
  'etherpadDocuments.alreadySigned': 'Already signed',
  'etherpadDocuments.signingOrder.description': 'Set the order in which signers receive and sign your document.',
  'etherpadDocuments.addClientAsSigner': 'Add _(client) as signer',
  'etherpadDocuments.addAnotherSigner': 'Add another signer',
  'etherpadDocuments.sendDocument': 'Send document',
  'etherpadDocuments.setSigningOrder': 'Set signing order',
  'etherpadDocuments.requestSignature': 'Request signature',
  'etherpadDocuments.sendForSignature': 'Send for signature',
  'etherpadDocuments.renameDocumentBeforeSendingForSignature': 'Rename document before sending for signature',

  'etherpadDocuments.declineToSign.modal.title': 'Decline to sign this document?',
  'etherpadDocuments.declineToSign.modal.description': `Your decision can’t be changed. We'll let the expert know that you've chosen not to sign this document.`,
  'etherpadDocuments.declineToSign.modal.reason.label': 'Please provide your reason for declining here:',
  'etherpadDocuments.declineToSign.modal.decline': 'Decline to sign',
  'etherpadDocuments.requireSignature': 'Require signature',
  'etherpadDocuments.otherDocuments': 'Other documents',
  'etherpadDocuments.guest.failedToSign': 'This document doesn’t require your signature anymore.',
  'etherpadDocuments.guest.noPermissionToSign.title': `Couldn't find the document`,
  'etherpadDocuments.guest.noPermissionToSign.description':
    'This document is no longer available or you may have no access to it.',
  'etherpadDocuments.guest.declineToSign.title': `You've declined to sign the document.`,
  'etherpadDocuments.guest.declineToSign.description': 'Thank you for taking your time',
  'etherpadDocuments.guest.failedToSign.alt': 'empty',
  'etherpadDocuments.notFound': "Couldn't find the document",
  'etherpadDocuments.notFound.description': 'This document is no longer available or you may have no access to it.',
  'etherpadDocuments.defaultName': 'Untitled document',
}

export default etherpadDocuments
