import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import profileName from 'utils/profileName'
import { boldText } from 'utils/locales'
import { showModal as showModalAction } from 'state/modal/actions'
import { removeChatMember as removeChatMemberAction } from 'state/concepts/chat/actions'
import MemberCardComponent from './component'

class MemberCard extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    removeChatMember: PropTypes.func.isRequired,
    chatMember: PropTypes.shape().isRequired,
    isCurrentUserChatOwner: PropTypes.bool.isRequired,
    manageChatMembers: PropTypes.bool.isRequired,
    chat: PropTypes.shape().isRequired,
  }

  get canBeRemoved() {
    const { isCurrentUserChatOwner, manageChatMembers } = this.props

    if (!manageChatMembers) {
      return false
    }

    if (this.isOwner) {
      return false
    }

    return isCurrentUserChatOwner
  }

  get isOwner() {
    const {
      chatMember: { owner },
    } = this.props

    return owner
  }

  get isDeactivated() {
    const {
      chatMember: { deactivated },
    } = this.props

    return deactivated
  }

  get isDeleted() {
    const {
      chatMember: { deleted },
    } = this.props

    return deleted
  }

  handleConfirmRemoveChatMember = () => {
    const { showModal, chatMember } = this.props

    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: {
          id: 'chat.members.remove.confirmModal.title',
        },
        bodyText: [
          {
            id: 'chat.members.remove.confirmModal.body',
            values: {
              fullName: profileName(chatMember),
              b: boldText,
            },
          },
        ],
        kind: 'danger',
        submitText: { id: 'chat.members.remove' },
        dismissText: { id: 'shared.cancel' },
        onSubmit: this.handleRemoveChatMember,
      },
    })
  }

  handleRemoveChatMember = () => {
    const {
      chat,
      removeChatMember,
      chatMember: { id },
    } = this.props

    removeChatMember(chat, id)
  }

  render = () => (
    <MemberCardComponent
      {...this.props}
      canBeRemoved={this.canBeRemoved}
      isOwner={this.isOwner}
      isDeactivated={this.isDeactivated}
      isDeleted={this.isDeleted}
      onConfirmRemoveChatMember={this.handleConfirmRemoveChatMember}
    />
  )
}

const mapDispatchToProps = {
  showModal: showModalAction,
  removeChatMember: removeChatMemberAction,
}

export { MemberCard as MemberCardContainer }
export default connect(null, mapDispatchToProps)(MemberCard)
