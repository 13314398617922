export const EVENTS = {
  signUpEmailPrompt: '[Sign up] View email prompt',
  signUpSetPassword: '[Sign up] View set password page',
  signUpCompleted: '[Sign up] Completed',
}

export const GROUP_KEYS = {
  workspace: 'Workspace',
}

export const PROPERTY_KEYS = {
  subscriptionPlan: 'Subscription Plan',
  userRole: 'User Role',
  $email: '$email',
  email: 'Email',
  workspace: 'Workspace',
}

export const PROPERTY_VALUES = {
  workspaceOwner: 'Workspace owner',
}
