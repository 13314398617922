import confirmEmployeeOwnEmail from './confirmEmployeeOwnEmail'
import fetchRemindWorkspaces from './fetchRemindWorkspaces'
import getEmployeeOnboarding from './getEmployeeOnboarding'
import getEmployeeOwnProfile from './getEmployeeOwnProfile'
import remindWorkspacesRequest from './remindWorkspacesRequest'
import resendEmailChangeConfirmation from './resendEmailChangeConfirmation'
import resendEmailConfirmation from './resendEmailConfirmation'
import resetPasswordConfirm from './resetPasswordConfirm'
import resetPasswordRequestAccount from './resetPasswordRequestAccount'
import resetPasswordRequestLogin from './resetPasswordRequestLogin'
import signupFromInvitation from './signupFromInvitation'
import submitOnboardingCompanyProfile from './submitOnboardingCompanyProfile'
import submitOnboardingGeneral from './submitOnboardingGeneral'
import updateCalendarViewSetting from './updateCalendarViewSetting'
import updateEmployeeAccountGeneral from './updateEmployeeAccountGeneral'
import updateEmployeeAccountSecurity from './updateEmployeeAccountSecurity'
import updateEmployeePassword from './updateEmployeePassword'
import updateEmployeeProfile from './updateEmployeeProfile'
import updateLogoutInterval from './updateLogoutInterval'
import updateOnboardingStep from './updateOnboardingStep'
import validateAccessToClient from './validateAccessToClient'
import validatePasswordOnTwoFactor from './validatePasswordOnTwoFactor'
import enableTwoFactorSettings from './enableTwoFactorSettings'
import updateTwoFactorSettings from './updateTwoFactorSettings'
import disableTwoFactorSettings from './disableTwoFactorSettings'
import getOtpCodeBySms from './getOtpCodeBySms'
import getAppAuthUri from './getAppAuthUri'
import generateBackupCodes from './generateBackupCodes'
import validateConfirmationTokenOperation from './validateConfirmationToken'
import validateInvitationToken from './validateInvitationToken'
import validateResetPasswordToken from './validateResetPasswordToken'

export default [
  getEmployeeOwnProfile,
  updateEmployeeAccountGeneral,
  updateEmployeeAccountSecurity,
  resetPasswordRequestLogin,
  resetPasswordRequestAccount,
  resetPasswordConfirm,
  validateResetPasswordToken,
  validateInvitationToken,
  signupFromInvitation,
  submitOnboardingGeneral,
  getEmployeeOnboarding,
  confirmEmployeeOwnEmail,
  updateOnboardingStep,
  updateEmployeeProfile,
  submitOnboardingCompanyProfile,
  resendEmailConfirmation,
  resendEmailChangeConfirmation,
  remindWorkspacesRequest,
  fetchRemindWorkspaces,
  validateConfirmationTokenOperation,
  updateEmployeePassword,
  updateLogoutInterval,
  validateAccessToClient,
  validatePasswordOnTwoFactor,
  enableTwoFactorSettings,
  disableTwoFactorSettings,
  getOtpCodeBySms,
  updateTwoFactorSettings,
  getAppAuthUri,
  generateBackupCodes,
  updateCalendarViewSetting,
]
