import Modal from 'views/shared/Modal'
import ReviewAppointmentComponent from 'views/shared/ReviewAppointment'

const EditReviewAppointmentModalComponent = props => (
  <Modal className="main-modal-full-sm">
    <ReviewAppointmentComponent {...props} />
  </Modal>
)

export default EditReviewAppointmentModalComponent
