import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const SquareChecked = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M256 0h512c141.382 0 256 114.615 256 256v512c0 141.382-114.618 256-256 256h-512c-141.385 0-256-114.618-256-256v-512c0-141.385 114.615-256 256-256zM752.71 256l-352.852 353.247-128.57-128.713-79.288 79.377 207.858 208.089 432.142-432.623-79.29-79.377z" />
  </svg>
)

SquareChecked.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

SquareChecked.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default SquareChecked
