import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, path as ramdaPath } from 'ramda'
import build from 'redux-object'

import { dataSelector } from 'state/data/selectors'

const isBlankStateSelector = ramdaPath(['searchResults', 'isBlankState'])
const searchResultsSelector = ramdaPath(['searchResults', 'entities'])

export const makeSelectSearchResults = (kind, path) =>
  createSelector([searchResultsSelector, dataSelector], (searchResults, data) =>
    searchResults[kind] && !isEmpty(searchResults[kind]) && path ? build(data, path, searchResults[kind]) : [],
  )

export const isBlankStateSearchResultsSelector = createSelector(
  [isBlankStateSelector, (_, kind) => kind],
  (blankStates, kind) => kind && blankStates[kind],
)
