import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import { SET_CLIENT_REVIEWS, SET_CLIENT_REVIEWS_PAGE, RESET_CLIENT_REVIEWS } from './types'

const reviewIds = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENT_REVIEWS:
      return uniq([...state, ...action.reviewIds])
    case RESET_CLIENT_REVIEWS:
      return []
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_CLIENT_REVIEWS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

export default combineReducers({
  reviewIds,
  pagination,
})
