import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { incrementDurationHandler, decrementDurationHandler } from 'utils/duration'
import DurationFieldComponent from './component'

class DurationField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    step: PropTypes.number,
  }

  static defaultProps = {
    step: 15,
  }

  onIncrement = () => {
    const { field, step } = this.props

    field.onChange(field.name)(incrementDurationHandler(step, field.value))
  }

  onDecrement = () => {
    const { field, step } = this.props

    field.onChange(field.name)(decrementDurationHandler(step, field.value))
  }

  onKeyDown = e => {
    if (e.keyCode === 38) {
      this.onIncrement()
    }
    if (e.keyCode === 40) {
      this.onDecrement()
    }
  }

  render = () => (
    <DurationFieldComponent
      {...this.props}
      onIncrement={this.onIncrement}
      onDecrement={this.onDecrement}
      onKeyDown={this.onKeyDown}
    />
  )
}

export { DurationField as DurationFieldContainer }
export default injectIntl(DurationField)
