import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { prepareEditorValue } from 'utils/reactDraftEditor'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { ADD_NOTE } from 'state/concepts/companyClientNotes/types'
import { addNoteEndpoint } from 'state/concepts/companyClientNotes/endpoints'
import { addNoteSuccess, setIsAddNoteFormShown } from 'state/concepts/companyClientNotes/actions'

const addNoteOperation = createLogic({
  type: ADD_NOTE,
  latest: true,

  async process(
    {
      httpClient,
      action: {
        values: { clientId, value },
      },
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = addNoteEndpoint(clientId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, { value: prepareEditorValue(value) })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addNoteSuccess(data.data.id))
      dispatch(setIsAddNoteFormShown(false))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.noteAdded' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default addNoteOperation
