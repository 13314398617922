import React from 'react'
import { injectIntl } from 'react-intl'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { take } from 'ramda'

import InputFieldComponent from './component'

class InputField extends React.Component {
  static defaultProps = {
    message: null,
    messageClass: null,
    messageTextClass: null,
    maxChars: undefined,
  }

  static propTypes = {
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape({
      touched: PropTypes.shape().isRequired,
      errors: PropTypes.shape().isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    message: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    messageClass: PropTypes.string,
    messageTextClass: PropTypes.string,
    maxChars: PropTypes.number,
  }

  get fieldErrors() {
    const {
      form: { errors },
      field: { name },
    } = this.props

    return getIn(errors, name)
  }

  get hasErrors() {
    const {
      form: { touched },
      field: { name },
    } = this.props

    return getIn(touched, name) && this.fieldErrors
  }

  get inputMessageData() {
    const { message, messageClass } = this.props

    if (this.hasErrors) {
      return { message: this.fieldErrors, messageClass: 'main-input--has-message-error' }
    }
    if (message) {
      return { message, messageClass }
    }

    return {}
  }

  handleChange = event => {
    const { value } = event.target

    const {
      maxChars,
      field: { name },
      form: { setFieldValue },
    } = this.props

    setFieldValue(name, take(maxChars, value))
  }

  handleClear = () => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props

    setFieldValue(name, '')
  }

  render = () => (
    <InputFieldComponent
      onClear={this.handleClear}
      onChange={this.handleChange}
      {...this.props}
      {...this.inputMessageData}
    />
  )
}

export default injectIntl(InputField)
