import * as types from './types'

export const setIsRecordingInProgress = isRecordingInProgress => ({
  type: types.SET_IS_RECORDING_IN_PROGRESS,
  isRecordingInProgress,
})

export const setCurrentPlaySource = src => ({
  type: types.SET_CURRENT_PLAY_SOURCE,
  src,
})

export const setIsMicrophoneError = isMicrophoneError => ({
  type: types.SET_IS_MICROPHONE_ERROR,
  isMicrophoneError,
})
