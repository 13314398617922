import React from 'react'
import PropTypes from 'prop-types'

class UploadInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  input = React.createRef()

  handleChange = event => {
    this.props.onChange(event)
    this.input.current.value = ''
  }

  render() {
    return <input type="file" {...this.props} onChange={this.handleChange} ref={this.input} />
  }
}

export default UploadInput
