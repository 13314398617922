import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { LEAVE_CHAT } from 'state/concepts/chat/types'
import { leaveChatEndpoint } from '../endpoints'

const leaveChatOperation = createLogic({
  type: LEAVE_CHAT,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { chatId } = action
    const { url } = leaveChatEndpoint(chatId)

    try {
      await httpClient.post(url)
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default leaveChatOperation
