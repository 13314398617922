import { createLogic } from 'redux-logic'
import { omit, pluck } from 'ramda'
import normalize from 'json-api-normalizer'

import generateSortString from 'utils/generateSortString'
import { tableOrderDirectionsSelector } from 'state/tableData/selectors'
import { PAGINATION_ITEMS_PER_PAGE, TABLE_ENTITIES } from 'lib/constants/tableData'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchTrashedFilesEndpoint } from '../endpoints'
import { FETCH_TRASHED_FILES } from '../types'
import { addTrashedFileIds, setBlankState, setTrashedFileIds } from '../actions'
import { filtersSelector } from '../selectors'

const fetchTrashedFilesOperation = createLogic({
  type: FETCH_TRASHED_FILES,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const {
      pageParams: { page = 1, size },
      options: { append = true, preview = false },
    } = action
    const state = getState()
    const { url, endpoint } = fetchTrashedFilesEndpoint
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.trashedFiles))
    const { name, types, trashed } = filtersSelector(state)
    const dates = trashed?.[0]

    const filter = {
      name,
      types,
      trashed_after: dates?.trashedAfter?.toISO(),
      trashed_before: dates?.trashedBefore?.toISO(),
      preview,
    }
    const params = {
      page: { number: page, size: size || PAGINATION_ITEMS_PER_PAGE },
      include: ['user-profile', 'business-storage-permissions'],
      sort,
      filter,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      const blankStateParams = omit(['preview'], filter)

      dispatch(setBlankState(isBlankState({ data: data.data, params: blankStateParams })))
      dispatch(dataApiSuccess({ response, endpoint }))

      const ids = pluck('id', data.data)
      dispatch(append ? addTrashedFileIds(ids) : setTrashedFileIds(ids))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchTrashedFilesOperation
