import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import isBlankState from 'utils/isBlankState'
import { tablePaginationSelector } from 'state/tableData/selectors'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchFormCategoriesEndpoint } from '../endpoints'
import { FETCH_FORM_CATEGORIES } from '../types'
import { setFormCategoryIds, setTotalCount, setBlankState } from '../actions'
import { filtersSelector } from '../selectors'

const fetchFormCategoriesOperation = createLogic({
  type: FETCH_FORM_CATEGORIES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = fetchFormCategoriesEndpoint

    const filter = filtersSelector(getState())
    const params = {
      page: {
        ...tablePaginationSelector(getState(), TABLE_ENTITIES.formCategories),
      },
      filter,
    }
    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ data: data.data, params: filter })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setFormCategoryIds(pluck('id', data.data)))
      dispatch(setTotalCount(data.meta.total))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchFormCategoriesOperation
