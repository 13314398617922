const waitlistRequests = {
  'waitlistRequests.settings': 'Waitlist settings',
  'waitlistRequests.addToWaitlist': 'Add to waitlist',
  'waitlistRequests.requested': '_(Appointment) requested',
  'waitlistRequests.slotsAvailable': 'Time slots available',
  'waitlistRequests.alert.automation':
    '_(Appointments) are auto-scheduled based on _(expert) availability. A _(client) from the top of the waitlist receives _a(appointment) request and can accept or decline it. If declined or expired, the offer goes to the next _(client) in line. To adjust the way the waitlist works, access {link}.',
  'waitlistRequests.alert.manual':
    'You can manually schedule _(appointments) for _(clients) on the waitlist.  Access {link} if you want to enable auto-scheduling or _(client) notifications.',
  'waitlistRequests.alert.notification':
    '_(Clients) get a notification when a new slot matches their waitlist request. Openings are filled on a first-come, first-served basis. To adjust the way the waitlist works, access {link}.',
  'waitlistRequests.waiting': 'Waiting for _(appointment)',
  'waitlistRequests.expired': 'Expired',
  'waitlistRequests.suspended': 'Suspended',
  'waitlistRequests.table.client': '_(Client)',
  'waitlistRequests.table.serviceAndExpert': '_(Service) and _(expert)',
  'waitlistRequests.table.dateAdded': 'Date added',
  'waitlistRequests.table.preferences': 'Availability preferences',
  'waitlistRequests.table.anyExpert': 'Any _(expert)',
  'waitlistRequests.actions.bookAppointment': 'Book _(appointment)',
  'waitlistRequests.actions.sendMessage': 'Send message',
  'waitlistRequests.actions.editRequest': 'Edit request',
  'waitlistRequests.actions.delete.title': 'Delete waitlist request?',
  'waitlistRequests.actions.delete.description':
    'This will delete the request from waitlist with the information that was added to request in waitlist.',
  'waitlistRequests.deleteRequest': 'Delete request',
  'waitlistRequests.anyDate': 'Any date',
  'waitlistRequests.datePreference': '{date}',
  'waitlistRequests.dateTimePreference': '{date}, {time}',
  'waitlistRequests.time.any_time': 'Any time',
  'waitlistRequests.time.before': 'Before {time}',
  'waitlistRequests.time.after': 'After {time}',
  'waitlistRequests.time.between': 'Between {time}',
  'waitlistRequests.empty.title': 'Your waitlist is empty',
  'waitlistRequests.empty.title.alt': 'No waitlist',
  'waitlistRequests.moreTimePreferences': '+{preferencesCount} more',
  'waitlistRequests.empty.description.ownerAdmin':
    'Add a waitlist request to start tracking suitable times for _(client) _(appointments).',
  'waitlistRequests.empty.description.expert': '_(Clients) can join your waitlist through your booking pages',
  'waitlistRequests.client.preferences': 'Availability preference:',
  'waitlistRequests.client.empty': 'You have no waitlist requests yet',
  'waitlistRequests.client.editRequest.setPreferences': 'Set availability preferences',
  'waitlistRequests.client.editRequest.addPreference': 'Add new preference',

  'createWaitlistRequests.addClientInformation': 'Add _(client) information',
  'createWaitlistRequests.existingClients': 'Existing _(clients)',
  'createWaitlistRequests.newClient': 'New _(client)',
  'createWaitlistRequests.selectClient': 'Select _(client)',
  'createWaitlistRequests.selectServiceAndExpert': 'Select _(service) and _(expert)',
  'createWaitlistRequests.selectService': 'Select _(service)',
  'createWaitlistRequests.addToWaitlist': 'Add to waitlist',
  'createWaitlistRequests.addNote.title': 'Add note',
  'createWaitlistRequests.addNote.info': 'This text is not visible to the _(client).',
  'createWaitlistRequests.addNote.label': 'Add relevant information about this waitlist request',
  'createWaitlistRequests.location.title': 'Select location',
  'createWaitlistRequests.timeOption.anyTime': 'Any time',
  'createWaitlistRequests.timeOption.before': 'Before',
  'createWaitlistRequests.timeOption.after': 'After',
  'createWaitlistRequests.timeOption.between': 'Between',
  'createWaitlistRequests.availabilityPreferences.title': 'Set availability preferences',
  'createWaitlistRequests.availabilityPreferences.addToList': 'Add new preference',
  'createWaitlistRequests.availabilityPreferences.endTime': ' End time',
  'createWaitlistRequests.availabilityPreferences.startTime.between': 'Start time',
  'createWaitlistRequests.availabilityPreferences.startTime.before': 'Before time',
  'createWaitlistRequests.availabilityPreferences.startTime.after': 'After time',
  'createWaitlistRequests.availabilityPreferences.date': 'Date',
  'createWaitlistRequests.availabilityPreferences.selectDate': 'Select date',
  'createWaitlistRequests.availabilityPreferences.anyDate': 'Any date',
  'createWaitlistRequests.anyExpert': 'Any available _(expert)',

  'waitlistRequests.sidebar.title': 'Waitlist request',
  'waitlistRequests.sidebar.client': '_(Client)',
  'waitlistRequests.sidebar.email': 'Email',
  'waitlistRequests.sidebar.phoneNumber': 'Phone number',
  'waitlistRequests.sidebar.serviceAndExpert': '_(Service) and _(expert)',
  'waitlistRequests.sidebar.location': 'Location',
  'waitlistRequests.sidebar.availabilityPreferences': 'Availability preferences',
  'waitlistRequests.sidebar.note': 'Note',
  'waitlistRequests.sidebar.createdBy': 'Created by {name} on {date}',
  'editWaitlistRequests.editClientInformation': '_(Client)',

  'waitlistRequests.waitlistRequestForAppointment': 'Waitlist request for _(appointment)',
  'waitlistRequests.addedBy': 'with {profileName}',
  'waitlistRequests.status.appointment_requested': '_(Appointment) requested',
  'waitlistRequests.status.waiting_for_appointment': 'Waiting for _(appointment)',
  'waitlistRequests.status.expired': 'Expired',
  'waitlistRequests.status.suspended': 'Suspended',
  'waitlistRequests.tryAdjustingSearch': 'Try adjusting your filters',

  'waitlistRequests.anyExpertLower': 'any _(expert)',
}

export default waitlistRequests
