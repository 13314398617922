import endpoint from 'utils/endpoint'
import {
  usersAccountUserTutorialRoute,
  usersAccountUserOnboardingContentRoute,
  clientsAccountClientOnboardingContentRoute,
} from 'lib/apiRoutes'
import { SKIP_STEP, UPDATE_ONBOARDING_CONTENT } from './types'

export const updateTutorialStepEndpoint = endpoint(SKIP_STEP, 'PUT', usersAccountUserTutorialRoute)
export const updateUserOnboardingContentEndpoint = endpoint(
  UPDATE_ONBOARDING_CONTENT,
  'PUT',
  usersAccountUserOnboardingContentRoute,
)
export const updateClientOnboardingContentEndpoint = endpoint(
  UPDATE_ONBOARDING_CONTENT,
  'PUT',
  clientsAccountClientOnboardingContentRoute,
)
