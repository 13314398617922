import { createSelector } from '@reduxjs/toolkit'
import { compose, filter, head, includes, path, pipe, prop, propEq, propOr, sortBy } from 'ramda'
import build from 'redux-object'

import { FORM_TYPES, SUBMISSION_STATUSES } from 'lib/constants/forms'
import { employeeSelector } from 'state/concepts/employees/selectors'
import { serviceSelector } from 'state/concepts/service/selectors'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import isPresent from 'utils/isPresent'
import {
  fetchInCallChatEndpoint,
  getBookingEndpoint,
  rescheduleBookingEndpoint,
  rescheduleRecurringBookingsEndpoint,
} from './endpoints'

export const bookingIdSelector = path(['booking', 'bookingId'])
export const bookingDateSelector = path(['booking', 'date'])
export const bookingTimeSlotsSelector = path(['booking', 'timeSlots'])
export const bookingDaySlotsSelector = path(['booking', 'daySlots'])
export const pendingFormIdsSelector = path(['booking', 'pendingFormIds'])
export const inCallChatIdSelector = path(['booking', 'inCallChatId'])

export const bookingSelector = createSelector([bookingIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'booking', id) : null,
)

export const bookingWorkspaceCode = createSelector(bookingSelector, path(['workspace', 'uniqCode']))

export const bookingLoadingSelector = (state, bookingUniqCode) =>
  loadingSelector(state, getBookingEndpoint(bookingUniqCode).endpoint)

export const pendingFormsSelector = createSelector(
  [pendingFormIdsSelector, dataSelector],
  buildCollection('clientProfileForm'),
)

export const pendingAgreementsSelector = createSelector(
  pendingFormsSelector,
  filter(propEq(FORM_TYPES.agreement, 'formType')),
)

export const pendingIntakeFormsSelector = createSelector(
  pendingFormsSelector,
  filter(propEq(FORM_TYPES.intake, 'formType')),
)

export const bookingFormsSelector = createSelector(
  bookingSelector,
  compose(sortBy(prop('name')), propOr([], 'clientProfileForms')),
)

export const bookingAgreementsSelector = createSelector(
  bookingFormsSelector,
  filter(propEq(FORM_TYPES.agreement, 'formType')),
)

export const bookingIntakeFormsSelector = createSelector(
  bookingFormsSelector,
  filter(propEq(FORM_TYPES.intake, 'formType')),
)

export const bookingPendingFormsSelector = createSelector(
  bookingFormsSelector,
  filter(pipe(prop('status'), status => !includes(status, SUBMISSION_STATUSES))),
)

export const pendingFormsMultiSourceSelector = createSelector(
  [pendingFormsSelector, bookingPendingFormsSelector],
  (pendingForms, bookingPendingForms) => (isPresent(bookingPendingForms) ? bookingPendingForms : pendingForms),
)

export const firstPendingFormSelector = createSelector(
  pendingFormsMultiSourceSelector,
  compose(head, sortBy(prop('formType'))),
)

export const expertPriceSelector = createSelector(
  [employeeSelector, (_, _userProfileId, serviceId) => serviceId],
  (userProfile, serviceId) => {
    const userProfileService = propOr({}, 'userProfileService', userProfile)
    return userProfileService.serviceId === Number(serviceId) ? userProfileService.price : null
  },
)

export const servicePriceSelector = createSelector(
  [serviceSelector, (_, _serviceId, userProfileId) => userProfileId],
  (service, userProfileId) => {
    const userProfileService = propOr({}, 'userProfileService', service)
    return userProfileService.userProfileId === Number(userProfileId) ? userProfileService.price : null
  },
)

export const rescheduleBookingLoadingSelector = (state, bookingId) =>
  loadingSelector(state, rescheduleBookingEndpoint(bookingId).endpoint)

export const rescheduleRecurringBookingLoadingSelector = (state, bookingId) =>
  loadingSelector(state, rescheduleRecurringBookingsEndpoint(bookingId).endpoint)

export const inCallChatSelector = createSelector([inCallChatIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'inCallChat', id) : null,
)

export const inCallChatLoadingSelector = (state, id) => loadingSelector(state, fetchInCallChatEndpoint(id).endpoint)
