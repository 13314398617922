import { createLogic } from 'redux-logic'

import { clientsSessionSendCodeRoute } from 'lib/apiRoutes'
import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { setClientEmail, setContactPortalName } from 'state/concepts/login/actions'
import { CLIENT_SEND_CODE } from 'state/concepts/session/types'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'

const clientSendCodeOperation = createLogic({
  type: CLIENT_SEND_CODE,
  latest: true,

  async process({ action: { values, form }, getState, httpClient }, dispatch, done) {
    try {
      const { uniqCode } = workspaceSelector(getState())
      const params = {
        email: values.email,
      }

      const { data } = await httpClient.post(
        clientsSessionSendCodeRoute,
        params,
        buildCustomBaseUrl(['workspaces', uniqCode]),
      )

      dispatch(setClientEmail(values.email))
      dispatch(setContactPortalName(data.meta.contact_portal_name))
    } catch (error) {
      if (form && isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }

    form?.setSubmitting(false)
    done()
  },
})

export default clientSendCodeOperation
