import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { userIdentity } from 'utils/videoConference'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { JOIN_VIDEO_CONFERENCE } from 'state/concepts/videoConference/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { joinVideoConferenceEndpoint } from 'state/concepts/videoConference/endpoints'

const joinVideoConferenceOperation = createLogic({
  type: JOIN_VIDEO_CONFERENCE,
  latest: true,

  async process(
    {
      action: {
        values: { userName },
        form,
        videoConferenceUniqCode,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const { url, endpoint } = joinVideoConferenceEndpoint(videoConferenceUniqCode)
    dispatch(dataApiRequest({ endpoint }))

    const currentProfile = currentUserProfileSelector(getState())
    const identity = userIdentity(currentProfile, userName)

    try {
      const { data } = await httpClient.post(url, { identity }, buildCustomBaseUrl(['workspaces']))
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    done()
  },
})

export default joinVideoConferenceOperation
