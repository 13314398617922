import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const NoAssignedExpert = ({ onAssignExpertsModal }) => (
  <FormattedMessage
    id="scheduleAppointment.assignExpertsToService.noAssignedExperts"
    values={{
      assignExpertsBtn: (
        <button className="main-link" onClick={onAssignExpertsModal}>
          <FormattedMessage id="scheduleAppointment.assignExpertsToService.assignExperts" />
        </button>
      ),
    }}
  />
)

NoAssignedExpert.propTypes = {
  onAssignExpertsModal: PropTypes.func.isRequired,
}

export default NoAssignedExpert
