import PropTypes from 'prop-types'

import RecordingComponent from 'views/shared/AppointmentDetails/Recordings/Recording'
import isPresent from 'utils/isPresent'

const Attachments = ({ attachments }) => {
  if (!isPresent(attachments)) {
    return null
  }

  return (
    <>
      {attachments.map((attachment, index) => (
        <RecordingComponent
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          hasSize={false}
          canRemove={false}
          hasIcon={index === 0}
          clickable={false}
          videoRecord={{
            id: index.toString(),
            filename: attachment.title,
            url: attachment.fileUrl,
          }}
        />
      ))}
    </>
  )
}

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default Attachments
