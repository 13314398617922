const reviewsFeature = {
  'reviewsFeature.title': 'Reviews',
  'reviewsFeature.subtitle': '<span>in</span> Reviews',
  'reviewsFeature.description': 'Share feedback about your _(services) and increase _(clients)’ trust.',
  'reviewsFeature.overview': 'Reviews overview',
  'reviewsFeature.reviewSettings': 'Set up reviews',
  'reviewsFeature.overview.text1':
    'Build trust and provide social proof of your _(services) by publishing ratings and reviews on your booking page.',
  'reviewsFeature.overview.text2': 'Collet reviews easily:',
  'reviewsFeature.overview.text3':
    'Gather authentic reviews by sending _(clients) a review form after their _(appointment) to capture their experience.',
  'reviewsFeature.overview.text4':
    'Evaluate _(client) satisfaction efficiently using a straightforward star rating system.',
  'reviewsFeature.overview.text5':
    'Gain valuable insights through an optional comments section, allowing _(clients) to provide additional feedback.',
  'reviewsFeature.overview.text6': 'Use the power of _(client) reviews:',
  'reviewsFeature.overview.text7': 'Showcase honest feedback from real _(clients), building trust in your _(services).',
  'reviewsFeature.overview.text8':
    'Increase conversion rates by showing the value of your _(services) and helping your prospects make an informed choice.',
  'reviewsFeature.overview.text9': 'Manage and curate reviews:',
  'reviewsFeature.overview.text10': 'Select the most relevant reviews to showcase on your booking page.',
  'reviewsFeature.overview.text11':
    'Build trust and credibility by displaying _(client) reviews that highlight positive experiences.',
  'reviewsFeature.toLearnMores':
    'To learn more, read our help center article on <link>collecting and sharing _(client) feedback</link>.',
  'reviewsFeature.disable.title': 'Disable reviews feature?',
  'reviewsFeature.disable.description':
    'You are about to disable Public reviews. You’ll not be able to create and send agreements to your _(clients).',
}

export default reviewsFeature
