import PropTypes from 'prop-types'
import classNames from 'clsx'
import { InputNumber } from 'antd'
import { is } from 'ramda'
import { getIn } from 'formik'
import { FormattedMessage } from 'react-intl'

import Plus from 'views/shared/icons/Plus'
import Minus from 'views/shared/icons/Minus'
import InputMessage from '../InputMessage'

const NumericField = ({
  id,
  disabled,
  label,
  icon,
  small,
  className,
  placeholder,
  autoComplete,
  intl,
  onChange,
  field,
  dataCy,
  form: { touched, errors, status },
  showButtons,
  onIncrement,
  onDecrement,
  isDecrementDisabled,
  isIncrementDisabled,
  mainInputFieldClassName,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)

  const wrapperClassNames = classNames(
    className,
    showButtons && 'input-numeric--with-btn',
    'main-input input-numeric',
    {
      'main-input--disabled': disabled,
      'main-input--has-message': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
      'main-input--has-message-alert': fieldStatus,
      'main-input--sm': small,
    },
  )

  return (
    <div className={wrapperClassNames} data-cy={dataCy}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <InputNumber
          className={classNames('main-input__field', mainInputFieldClassName)}
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          id={id}
          disabled={disabled}
          {...field}
          {...props}
          onChange={onChange}
          autoComplete={autoComplete}
        />
        {showButtons && (
          <>
            <button
              onClick={onIncrement}
              type="button"
              disabled={disabled || isIncrementDisabled}
              className="input-numeric__button input-numeric__button--increase"
              data-cy="input-numeric-button-increase"
            >
              <Plus className="input-numeric__button-icon" size={12} />
            </button>
            <button
              onClick={onDecrement}
              type="button"
              disabled={disabled || isDecrementDisabled}
              className="input-numeric__button input-numeric__button--decrease"
              data-cy="input-numeric-button-decrease"
            >
              <Minus className="input-numeric__button-icon" size={12} />
            </button>
          </>
        )}
      </div>
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
    </div>
  )
}

NumericField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  small: false,
  placeholder: null,
  className: null,
  mainInputFieldClassName: null,
  autoComplete: 'off',
  dataCy: null,
  showButtons: false,
}

NumericField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  icon: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
  mainInputFieldClassName: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  autoComplete: PropTypes.string,
  dataCy: PropTypes.string,
  showButtons: PropTypes.bool,
  isIncrementDisabled: PropTypes.bool.isRequired,
  isDecrementDisabled: PropTypes.bool.isRequired,
}

export default NumericField
