import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setSearchResults, setBlankState } from 'state/searchResults/actions'
import { GET_SERVICES } from 'state/concepts/filters/types'
import { getServicesEndpoint } from 'state/concepts/filters/endpoints'

const getServicesOperation = createLogic({
  type: GET_SERVICES,
  latest: true,

  async process({ httpClient, action: { searchQuery, statuses } }, dispatch, done) {
    const { endpoint, url } = getServicesEndpoint
    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      filter: {
        name: searchQuery,
        statuses,
      },
      include: 'service-category',
      fields: {
        service: ['name', 'status', 'service_category'],
      },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const data = await httpClient.get(url, { params }).then(resp => resp.data)
      const response = normalize(data)
      const ids = data.data.map(item => item.id)

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.services]: isBlankState({ params: { name: searchQuery }, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setSearchResults({ [SEARCH_RESULTS_ENTITIES.services]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getServicesOperation
