import { createLogic } from 'redux-logic'

import { setFilesFilterParams, setFilesCurrentPage, fetchFiles } from '../actions'
import { FILTER_FILES } from '../types'

const filterFilesOperation = createLogic({
  type: FILTER_FILES,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilesFilterParams(filters))
    dispatch(setFilesCurrentPage(1))
    dispatch(fetchFiles())
    done()
  },
})

export default filterFilesOperation
