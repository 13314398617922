import { createLogic } from 'redux-logic'
import { pluck } from 'ramda'
import normalize from 'json-api-normalizer'

import generateSortString from 'utils/generateSortString'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { tablePaginationSelector, tableOrderDirectionsSelector } from 'state/tableData/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchClientRelationshipTypesEndpoint } from '../endpoints'
import { filtersSelector } from '../selectors'
import { FETCH_CLIENT_RELATIONSHIP_TYPES } from '../types'
import { setClientRelationshipTypesIds } from '../actions'

const fetchClientRelationshipTypesOperation = createLogic({
  type: FETCH_CLIENT_RELATIONSHIP_TYPES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = fetchClientRelationshipTypesEndpoint

    const state = getState()
    const filter = filtersSelector(state)
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.clientRelationshipTypes))

    const params = {
      page: {
        ...tablePaginationSelector(state, TABLE_ENTITIES.clientRelationshipTypes),
      },
      filter,
      sort,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setClientRelationshipTypesIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchClientRelationshipTypesOperation
