import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { getRestoreTrashedFileSuccessNotification } from 'utils/businessFiles'
import { RESTORE_TRASHED_FILES } from '../types'
import { restoreTrashedFilesEndpoint } from '../endpoints'
import { fetchTrashedFiles, removeTrashedFileIds } from '../actions'

const restoreTrashedFilesOperation = createLogic({
  type: RESTORE_TRASHED_FILES,
  latest: true,

  async process({ httpClient, action: { trashedFileIds, onNextSlide }, getState }, dispatch, done) {
    const { url, endpoint } = restoreTrashedFilesEndpoint
    const currentEmployeeId = currentEmployeeIdSelector(getState())

    const params = {
      business_storage_ids: trashedFileIds,
      include: ['parent'],
    }

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(removeTrashedFileIds(trashedFileIds))
      dispatch(fetchTrashedFiles(undefined, { append: false }))

      const notification = getRestoreTrashedFileSuccessNotification(data, currentEmployeeId)

      dispatch(showNotification(notification))
      onNextSlide?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default restoreTrashedFilesOperation
