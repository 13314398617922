import endpoint from 'utils/endpoint'
import {
  videoConferenceVideoRecordingsRoute,
  videoConferenceJoinRoute,
  videoConferenceRoute,
  videoConferenceChatMessagesRoute,
} from 'lib/apiRoutes'
import { FETCH_VIDEO_CONFERENCE_DETAILS, TOGGLE_RECORDING, JOIN_VIDEO_CONFERENCE, CREATE_CHAT_MESSAGE } from './types'

export const fetchVideoConferenceEndpoint = uniqCode =>
  endpoint(FETCH_VIDEO_CONFERENCE_DETAILS, 'GET', videoConferenceRoute(uniqCode))

export const toggleVideoRecordingsEndpoint = uniqCode =>
  endpoint(TOGGLE_RECORDING, 'POST', videoConferenceVideoRecordingsRoute(uniqCode))

export const joinVideoConferenceEndpoint = uniqCode =>
  endpoint(JOIN_VIDEO_CONFERENCE, 'POST', videoConferenceJoinRoute(uniqCode))

export const createVideoConferenceChatMessagesEndpoint = uniqCode =>
  endpoint(CREATE_CHAT_MESSAGE, 'POST', videoConferenceChatMessagesRoute(uniqCode))
