const namespace = 'videoCallsIntegrations'

export const UPDATE_VIDEO_CALL_PROVIDER = `${namespace}/UPDATE_VIDEO_CALL_PROVIDER`
export const CONNECT_GOOGLE_MEET_PROVIDER = `${namespace}/CONNECT_GOOGLE_MEET_PROVIDER`
export const DISCONNECT_GOOGLE_MEET_PROVIDER = `${namespace}/DISCONNECT_GOOGLE_MEET_PROVIDER`
export const GET_MICROSOFT_TEAMS_CONNECT_LINK = `${namespace}/GET_MICROSOFT_TEAMS_CONNECT_LINK`
export const SET_MICROSOFT_TEAMS_CONNECT_LINK = `${namespace}/SET_MICROSOFT_TEAMS_CONNECT_LINK`
export const CONNECT_MICROSOFT_TEAMS_PROVIDER = `${namespace}/CONNECT_MICROSOFT_TEAMS_PROVIDER`
export const DISCONNECT_MICROSOFT_TEAMS_PROVIDER = `${namespace}/DISCONNECT_MICROSOFT_TEAMS_PROVIDER`
export const GET_ZOOM_CONNECT_LINK = `${namespace}/GET_ZOOM_CONNECT_LINK`
export const SET_ZOOM_CONNECT_LINK = `${namespace}/SET_ZOOM_CONNECT_LINK`
export const CONNECT_ZOOM_PROVIDER = `${namespace}/CONNECT_ZOOM_PROVIDER`
export const DISCONNECT_ZOOM_PROVIDER = `${namespace}/DISCONNECT_ZOOM_PROVIDER`
