import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_WIDGET } from 'state/concepts/widget/types'
import { setWidget } from 'state/concepts/widget/actions'
import { fetchWidgetEndpoint } from 'state/concepts/widget/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const fetchWidgetOperation = createLogic({
  type: FETCH_WIDGET,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { widgetId } = action
    const { url, endpoint } = fetchWidgetEndpoint(widgetId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data)

      dispatch(setWidget(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchWidgetOperation
