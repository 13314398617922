import PropTypes from 'prop-types'

import Avatar from 'views/shared/Avatar'
import Close from 'views/shared/icons/Close'
import QuestionO from 'views/shared/icons/QuestionO'
import Tick from 'views/shared/icons/Tick'

const statusMap = {
  0: (
    <div className="userpic-status userpic-status--pending" data-cy="member-status--pending">
      <QuestionO size={8} className="in-orange-800" />
    </div>
  ),
  1: (
    <div className="userpic-status userpic-status--success" data-cy="member-status--success">
      <Tick size={8} className="in-green-600" />
    </div>
  ),
  2: (
    <div className="userpic-status userpic-status--error" data-cy="member-status--error">
      <Close size={8} className="in-red-500" />
    </div>
  ),
}

const Member = ({ member }) => (
  <div className="d-flex align-items-center">
    <div className="main-userpic__wrap">
      <Avatar
        avatarClassName="mr-12"
        sizeClassName="main-userpic main-userpic--xs"
        imageSize="small"
        profile={{ fullName: member.name }}
      />
      {statusMap[member.status]}
    </div>
    <div className="d-flex align-items-center">
      <p className="mb-0 text-body truncate-text" data-cy="expert-fullname">
        {member.name}
      </p>
    </div>
  </div>
)

Member.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
}

export default Member
