import { createLogic } from 'redux-logic'

import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyClientRoute } from 'lib/routes'
import { currentUserProfileSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { updateSignatoriesValuesToParams } from 'utils/etherpadDocuments'
import { clientSelector } from 'state/concepts/client/selectors'
import { updateSignatoriesEndpoint } from '../endpoints'
import { UPDATE_SIGNATORIES } from '../types'

const updateSignatoriesOperation = createLogic({
  type: UPDATE_SIGNATORIES,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateSignatoriesEndpoint(values.documentId)
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const currentUserProfile = currentUserProfileSelector(state)
    const client = clientSelector(state, values.clientId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, updateSignatoriesValuesToParams(values, currentUserProfile, client))

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.signersForDocumentUpdated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint })
      dispatch(
        showNotification({
          messageObject: { id: 'errorPage.generalNotification' },
          kind: 'error',
        }),
      )
    }
    redirect({
      href: companyClientRoute(values.clientId),
      query: { activeTab: CLIENT_PROFILE_TABS.documents },
      workspace,
    })
    done()
  },
})

export default updateSignatoriesOperation
