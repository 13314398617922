import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import spanWithClass from 'utils/locales/spanWithClass'
import MethodsList from './MethodsList'

const SelectMethodStep = ({ setStep, twoFactorSettings, workspaceMethods }) => {
  const currentMethodChecker = prop('methodChecker', twoFactorSettings)

  return (
    <div className="main-modal__body" data-cy="select-method-step">
      <p className="text-body">
        <FormattedMessage id="employeeTwoFactorSettings.selectMethod.howDoYouWant" />
      </p>
      {currentMethodChecker && (
        <p className="text-body">
          <FormattedMessage
            id={`employeeTwoFactorSettings.selectMethod.currentMethod.${currentMethodChecker}`}
            values={{
              span: spanWithClass('font-700'),
            }}
          />
        </p>
      )}

      <MethodsList setStep={setStep} twoFactorSettings={twoFactorSettings} workspaceMethods={workspaceMethods} />
    </div>
  )
}

SelectMethodStep.defaultProps = {
  twoFactorSettings: null,
}

SelectMethodStep.propTypes = {
  setStep: PropTypes.func.isRequired,
  twoFactorSettings: PropTypes.shape({
    methodChecker: PropTypes.string,
  }),
  workspaceMethods: PropTypes.shape({
    email: PropTypes.bool,
    sms: PropTypes.bool,
    app: PropTypes.bool,
  }).isRequired,
}

export default SelectMethodStep
