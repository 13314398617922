import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, not, omit, path, pipe, prop, values } from 'ramda'
import build from 'redux-object'

import {
  fetchClientTransactionsEndpoint,
  fetchReceiptTemplateEndpoint,
  fetchTransactionsEndpoint,
  requestTransactionEndpoint,
  saveAsDraftTransactionEndpoint,
} from 'state/concepts/payments/endpoints'
import { dataSelector, loadingSelector, totalCountSelector } from 'state/data/selectors'
import { updatePaymentEndpoint } from '../payment/endpoints'

export const isBlankStateSelector = path(['payments', 'isBlankState'])
export const transactionIdsSelector = path(['payments', 'transactionIds'])
export const receiptTemplateIdSelector = path(['payments', 'receiptTemplateId'])
export const filtersSelector = path(['payments', 'filters'])
export const appliedFiltersSelector = (state, name) => path(['payments', 'filters', name], state)
export const paginationSelector = path(['payments', 'pagination'])
export const requestPaymentLoadingSelector = state => loadingSelector(state, requestTransactionEndpoint.endpoint)
export const saveAsDraftPaymentLoadingSelector = state =>
  loadingSelector(state, saveAsDraftTransactionEndpoint.endpoint)

export const updatePaymentLoadingSelector = (state, transactionId) =>
  loadingSelector(state, updatePaymentEndpoint(transactionId).endpoint)

export const fetchReceiptTemplateLoadingSelector = state =>
  loadingSelector(state, fetchReceiptTemplateEndpoint.endpoint)

export const transactionsTotalCountSelector = state => totalCountSelector(state, fetchTransactionsEndpoint.endpoint)
export const clientTransactionsTotalCountSelector = state =>
  totalCountSelector(state, fetchClientTransactionsEndpoint.endpoint)

export const transactionsSelector = createSelector([transactionIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'transaction', ids) : [],
)

export const receiptTemplateSelector = createSelector([receiptTemplateIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'receiptTemplate', id) : null,
)

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(compose(not, isEmpty)), values, omit(['action'])),
)

export const tabStatusSelector = pipe(filtersSelector, prop('action'))

export const searchQuerySelector = pipe(filtersSelector, prop('name'))

export const currentPageSelector = pipe(paginationSelector, prop('number'))
