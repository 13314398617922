import { createLogic } from 'redux-logic'

import { setCurrentPage } from 'state/tableData/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { fetchDocumentTemplates, setFilterParams } from '../actions'
import { FILTER_DOCUMENT_TEMPLATES } from '../types'

const filterDocumentTemplatesOperation = createLogic({
  type: FILTER_DOCUMENT_TEMPLATES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(TABLE_ENTITIES.documentTemplates, 1))
    dispatch(fetchDocumentTemplates())
    done()
  },
})

export default filterDocumentTemplatesOperation
