import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { FETCH_WEBSITE } from 'state/concepts/website/types'
import { dataApiFailure, dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setWebsite } from 'state/concepts/website/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchWebsiteEndpoint } from '../endpoints'

const fetchWebsiteOperation = createLogic({
  type: FETCH_WEBSITE,
  latest: true,

  async process({ httpClient, action: { skipErrorHandler } }, dispatch, done) {
    const { url, endpoint } = fetchWebsiteEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const params = {
        include: 'website-setting,widget,social-network,workspace',
      }

      const { data } = await httpClient.get(url, { params })

      dispatch(setWebsite(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))
    } catch (error) {
      if (skipErrorHandler) {
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchWebsiteOperation
