import Image from 'next/image'
import PropTypes from 'prop-types'

import { ONLINE_LOCATION_ICONS } from 'lib/constants/locations'
import canAddParticipants from 'utils/bookings/canAddParticipants'
import CopyLinkButton from 'views/shared/VideoConference/CopyVideoConferenceButton'
import JoinButton from 'views/shared/VideoConference/JoinButton'
import ShowDetails from './ShowDetails'

const JoinConference = ({ videoCallProvider, showImage, booking, joinButtonProps, showDetails, videoConference }) => {
  const userCanAddParticipants = canAddParticipants(booking)
  const videoConferenceCode = booking.videoConference?.uniqCode

  return (
    <>
      <div className="d-flex align-items-center gap-16">
        {showImage && (
          <Image src={ONLINE_LOCATION_ICONS[videoCallProvider]} width="20" height="20" alt="Video call provider" />
        )}
        <div className="d-flex align-items-center">
          <JoinButton
            size="medium"
            text={{ id: 'consultation.joinVideoCall' }}
            videoConferenceCode={videoConferenceCode}
            dataCy="booking-details-join-conference-button"
            {...joinButtonProps}
          />
          {userCanAddParticipants && <CopyLinkButton videoConferenceCode={videoConferenceCode} />}
        </div>
      </div>
      {showDetails && videoConference?.providerInfo && videoConference?.providerUrl && (
        <ShowDetails videoConference={videoConference} />
      )}
    </>
  )
}

JoinConference.propTypes = {
  showImage: PropTypes.bool,
  showDetails: PropTypes.bool,
  videoCallProvider: PropTypes.string.isRequired,
  booking: PropTypes.shape({
    videoConference: PropTypes.shape().isRequired,
  }).isRequired,
  addUserButtonProps: PropTypes.shape(),
  joinButtonProps: PropTypes.shape(),
  videoConference: PropTypes.shape(),
}

JoinConference.defaultProps = {
  showImage: false,
  showDetails: false,
  joinButtonProps: {},
  addUserButtonProps: {},
  videoConference: undefined,
}

export default JoinConference
