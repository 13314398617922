import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import { MAX_TEXTAREA_CONTENT_NORMAL_LENGTH } from 'lib/constants'
import FormattedLinkifyMessage from 'views/shared/FormattedLinkifyMessage'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import Alert from 'views/shared/Alert'
import Cross from 'views/shared/icons/Cross'

const ManualPaymentModalComponent = ({ status, isSubmitDisabled, onClose, actionType }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <Form>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="manual-payment-modal-title">
            <FormattedMessage id={`manualPayment.modal.title.${actionType}`} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body pb-0">
          <p className="mb-24" data-cy="manual-payment-modal-description">
            <FormattedMessage id="manualPayment.modal.body" />
          </p>
          {prop('base', status) && (
            <Alert className="auth__alert">
              <FormattedLinkifyMessage message={prop('base', status)} />
            </Alert>
          )}
          <Field
            className="main-input--textarea main-input--textarea-top-count"
            name="paymentInstructions"
            component={TextAreaField}
            id="paymentInstructions"
            type="text"
            icon="close"
            showCount
            maxLength={MAX_TEXTAREA_CONTENT_NORMAL_LENGTH}
            label={{ id: 'manualPayment.modal.instructionLabel' }}
          />
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="min-w-80 mr-16"
              type="button"
              onClick={onClose}
              text={{ id: 'shared.cancel' }}
              kind="flat"
            />
            <SubmitFormButton className="min-w-80" text={{ id: 'shared.saveChanges' }} disabled={isSubmitDisabled} />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

ManualPaymentModalComponent.defaultProps = {
  status: undefined,
}

ManualPaymentModalComponent.propTypes = {
  status: PropTypes.shape(),
  isSubmitDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
}

export default ManualPaymentModalComponent
