import Router from 'next/router'

import { companyBookingsRoute, employeeOnboardingRoute } from 'lib/routes'
import redirect from 'utils/redirect'

const handleLoginRedirect = (onboardingCompleted, workspaceUniqCode) => {
  const redirectTo = Router.router.query.redirect_to
  if (redirectTo && onboardingCompleted) {
    redirect({ href: redirectTo, reload: true })
  } else {
    redirect({
      href: !onboardingCompleted ? employeeOnboardingRoute : companyBookingsRoute,
      workspace: workspaceUniqCode,
      reload: true,
    })
  }
}

export default handleLoginRedirect
