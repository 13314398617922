import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchClientRelationshipTypes = () => ({
  type: types.FETCH_CLIENT_RELATIONSHIP_TYPES,
})

export const searchClientRelationshipTypes = ({ resetResults } = {}) => ({
  type: types.SEARCH_CLIENT_RELATIONSHIP_TYPES,
  resetResults,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const filterClientRelationshipTypes = (filters = {}) => ({
  type: types.FILTER_CLIENT_RELATIONSHIP_TYPES,
  filters,
})

export const setClientRelationshipTypesIds = ids => ({
  type: types.SET_CLIENT_RELATIONSHIP_TYPES_IDS,
  ids,
})

export const fetchClientRelationshipType = id => ({
  type: types.FETCH_CLIENT_RELATIONSHIP_TYPE,
  id,
})

export const setClientRelationshipTypeId = id => ({
  type: types.SET_CLIENT_RELATIONSHIP_TYPE_ID,
  id,
})

export const resetClientRelationshipTypeId = () => ({
  type: types.RESET_CLIENT_RELATIONSHIP_TYPE_ID,
})

export const createClientRelationshipType = makeFormSubmitAction(types.CREATE_CLIENT_RELATIONSHIP_TYPE)

export const updateClientRelationshipType = makeFormSubmitAction(types.UPDATE_CLIENT_RELATIONSHIP_TYPE)

export const deleteClientRelationshipType = makeFormSubmitAction(types.DELETE_CLIENT_RELATIONSHIP_TYPE)
