import PropTypes from 'prop-types'
import classNames from 'clsx'

import FormattedOrRawMessage from '../FormattedOrRawMessage'
import DropdownButtonIcon from './DropdownButtonIcon'
import Tooltip from '../Tooltip'

const DropdownButton = ({ isClearShown, isOpen, onClear, onClick, withTooltip }) =>
  withTooltip && isClearShown ? (
    <Tooltip
      placement="top"
      on={['hover']}
      overlayClassName="main-tooltip main-tooltip--no-pointer"
      icon={
        <button
          type="button"
          className={classNames('in-gray-700 dropdown-arrow-btn', { 'dropdown-clear-btn': isClearShown })}
          onClick={isClearShown ? onClear : onClick}
        >
          <DropdownButtonIcon isClearShown={isClearShown} isOpen={isOpen} />
        </button>
      }
    >
      <FormattedOrRawMessage message={{ id: 'shared.clear' }} />
    </Tooltip>
  ) : (
    <button
      type="button"
      className={classNames('in-gray-700 dropdown-arrow-btn', { 'dropdown-clear-btn': isClearShown })}
      onClick={isClearShown ? onClear : onClick}
    >
      <DropdownButtonIcon isClearShown={isClearShown} isOpen={isOpen} />
    </button>
  )

DropdownButton.defaultProps = {
  withTooltip: false,
  onClick: undefined,
  onClear: undefined,
}

DropdownButton.propTypes = {
  isClearShown: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  withTooltip: PropTypes.bool,
}

export default DropdownButton
