import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { getCalendarEventEndpoint } from './endpoints'

export const calendarEventIdSelector = path(['calendarEvent', 'calendarEventId'])

export const calendarEventSelector = createSelector([calendarEventIdSelector, dataSelector], (calendarEventId, data) =>
  calendarEventId ? build(data, 'externalCalendarEvent', calendarEventId) : calendarEventId,
)

export const calendarEventByIdSelector = createSelector(
  [dataSelector, (_, calendarEventId) => calendarEventId],
  (data, calendarEventId) => build(data, 'externalCalendarEvent', calendarEventId),
)

export const calendarEventLoadingSelector = (state, calendarEventId) =>
  loadingSelector(state, getCalendarEventEndpoint(calendarEventId).endpoint)
