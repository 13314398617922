import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import redirect from 'utils/redirect'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBookingsRoute } from 'lib/routes'
import { GET_EMPLOYEE_ONBOARDING } from 'state/concepts/employee/types'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isOnboardingCompleted from 'utils/employees/isOnboardingCompleted'
import { getEmployeeOwnProfileEndpoint } from '../endpoints'

const getEmployeeOnboardingOperation = createLogic({
  type: GET_EMPLOYEE_ONBOARDING,
  latest: true,

  async process({ httpClient, action: { response, workspace }, getState }, dispatch, done) {
    const { endpoint, url } = getEmployeeOwnProfileEndpoint
    const currentEmployee = currentEmployeeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        include: ['user-onboarding-content', 'workspace', 'workspace.current-billing-period'],
      }
      const { data } = await httpClient.get(url, { params })
      const normalizedData = normalize(data)

      dispatch(employeeLoginSuccess(data.data.id, !currentEmployee?.confirmedAt))
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }))
      if (isOnboardingCompleted(data)) {
        redirect({ href: companyBookingsRoute, response, workspace })
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getEmployeeOnboardingOperation
