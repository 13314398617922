const bookingFeature = {
  'bookingFeature.subtitle': '<span>in</span> Scheduling',
  'bookingFeature.description': 'Schedule _(appointments) with your _(clients) and update or cancel them as needed.',
  'bookingFeature.manageFeatures': 'Set booking policy',
  'bookingFeature.overview': '_(Appointments) overview',
  'bookingFeature.getControl': 'Get complete control of your schedule with ExpertBox booking management:',
  'bookingFeature.bookAppointments':
    'Book _(appointments) with new and existing _(clients) right from your _(appointment) calendar.',
  'bookingFeature.viewAndManage': 'Easily view and manage all your upcoming, completed, and canceled _(appointments)',
  'bookingFeature.rescheduleOrCancel':
    'Reschedule or cancel _(appointments) with _(clients) in case your plans change, and add a small note to the _(client) if necessary',
  'bookingFeature.manageWorkload': 'If you’re working with a team, you can also manage your team members’ workloads:',
  'bookingFeature.scheduleAppointments': 'Schedule _(appointments) for your team members.',
  'bookingFeature.reviewHistory': 'Review your team’s _(appointment) history.',
  'bookingFeature.stayUpdated': 'Stay updated about your bookings:',
  'bookingFeature.receiveConfirmation':
    'Receive a confirmation whenever _a(client) books _a(appointment) with you or replies to an _(appointment) invitation.',
  'bookingFeature.getNotified':
    'Get notified of any changes related to your _(appointments) so you can adjust your schedule accordingly.',
  'bookingFeature.helpCenter':
    'To learn more, read our help center articles on setting up your <calendarLink>booking calendar</calendarLink> and <appointmentLink>managing your _(appointments)</appointmentLink>.',
}

export default bookingFeature
