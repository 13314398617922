import { map, prop, reduce, indexOf, filter, isEmpty } from 'ramda'
import debounce from 'debounce-promise'

import isEmail from 'utils/isEmail'
import { usersInvitationsStatusRoute as url } from 'lib/apiRoutes'

export default debounce(async ({ invitations }, { getHttpClient }) => {
  const emails = map(prop('email'), invitations)
  const validEmails = filter(isEmail, emails)
  if (isEmpty(validEmails)) {
    return {}
  }

  const httpClient = getHttpClient()

  try {
    const {
      data: { data },
    } = await httpClient.get(url, { params: { emails: validEmails } })

    const errors = reduce(
      (acc, { attributes: { email, status } }) => {
        if (status === 'accepted') {
          acc[indexOf(email, emails)] = { email: { id: 'employees.invitations.errors.alreadyMember' } }
        }

        if (status === 'invited') {
          acc[indexOf(email, emails)] = { email: { id: 'employees.invitations.errors.alreadyInvited' } }
        }

        return acc
      },
      [],
      data,
    )

    if (isEmpty(errors)) {
      return {}
    }

    return Promise.resolve({ invitations: errors })
  } catch {
    return {}
  }
}, 400)
