import {
  employeeAccountSettingsGeneralRoute,
  employeeAccountSettingsSecurityRoute,
  employeeAccountSettingsNotificationsRoute,
} from 'lib/routes'

export const ACCOUNT_SETTINGS_OPTIONS = ['general', 'security', 'notifications']

export const ACCOUNT_SETTINGS_HREFS = {
  general: employeeAccountSettingsGeneralRoute,
  security: employeeAccountSettingsSecurityRoute,
  notifications: employeeAccountSettingsNotificationsRoute,
}

export const NOTIFICATION_SETTINGS_GROUP_NAME = {
  appointment_reminders: 'appointmentReminders',
  communication: 'communication',
  service_updates: 'serviceUpdates',
  availability_updates: 'availabilityUpdates',
  team_appointments: 'teamAppointments',
  my_appointments: 'myAppointments',
  my_team: 'myTeam',
  news: 'news',
  tasks: 'tasks',
  my_forms: 'myForms',
  business_storages: 'businessStorages',
}

export const ACCOUNT_SETTINGS_ACTIONS = {
  delete: 'delete',
}

export const ACCOUNT_SETTINGS_GROUPS = ['web', 'email', 'sms']
