import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const updateVideoCallProvider = makeFormSubmitAction(types.UPDATE_VIDEO_CALL_PROVIDER)

export const connectGoogleMeetProvider = () => ({
  type: types.CONNECT_GOOGLE_MEET_PROVIDER,
})

export const disconnectGoogleMeetProvider = () => ({
  type: types.DISCONNECT_GOOGLE_MEET_PROVIDER,
})

export const getMicrosoftTeamsConnectLink = () => ({
  type: types.GET_MICROSOFT_TEAMS_CONNECT_LINK,
})

export const setMicrosoftTeamsConnectLink = link => ({
  type: types.SET_MICROSOFT_TEAMS_CONNECT_LINK,
  link,
})

export const connectMicrosoftTeamsProvider = (code, state) => ({
  type: types.CONNECT_MICROSOFT_TEAMS_PROVIDER,
  code,
  state,
})

export const disconnectMicrosoftTeamsProvider = () => ({
  type: types.DISCONNECT_MICROSOFT_TEAMS_PROVIDER,
})

export const getZoomConnectLink = () => ({
  type: types.GET_ZOOM_CONNECT_LINK,
})

export const setZoomConnectLink = link => ({
  type: types.SET_ZOOM_CONNECT_LINK,
  link,
})

export const connectZoomProvider = (code, state) => ({
  type: types.CONNECT_ZOOM_PROVIDER,
  code,
  state,
})

export const disconnectZoomProvider = () => ({
  type: types.DISCONNECT_ZOOM_PROVIDER,
})
