const bookingPolicy = {
  'bookingPolicy.anytimeLabel': 'Anytime',
  'bookingPolicy.minLeadTimeLabel': 'Min lead time',
  'bookingPolicy.minLeadTimeAmountPlaceholder': 'e.g., 1',
  'bookingPolicy.rescheduleAndCancellation.title': 'Reschedule and cancellation policy',
  'bookingPolicy.rescheduleAndCancellation.description': 'Changes to the booking policy will only affect new bookings.',
  'bookingPolicy.rescheduleAndCancellation.rescheduleLabel': 'Let _(clients) reschedule _(appointments)',
  'bookingPolicy.minLeadTime.options.minute': 'Min',
  'bookingPolicy.minLeadTime.options.hour': 'Hour',
  'bookingPolicy.minLeadTime.options.day': 'Day',
  'bookingPolicy.rescheduleAndCancellation.refundLabel': 'Refund cancelled _(appointments)',
  'bookingPolicy.clientBookingRules.title': '_(Client) booking rules',
  'bookingPolicy.clientBookingRules.description': 'Changes to the booking policy will only affect new bookings.',
  'bookingPolicy.clientBookingRules.buffer.title': 'Buffer time',
  'bookingPolicy.clientBookingRules.buffer.description':
    'Set time for _a(expert) to prepare before consultation or make necessary notes after it is completed.',
  'bookingPolicy.clientBookingRules.buffer.before': 'Buffer before',
  'bookingPolicy.clientBookingRules.buffer.after': 'Buffer after',
  'bookingPolicy.clientBookingRules.minLeadTime.title': 'Minimum lead time',
  'bookingPolicy.clientBookingRules.minLeadTime.description':
    'How far in advance _(clients) need to make _(appointments)',
  'bookingPolicy.clientBookingRules.maxFutureTime.title': 'Maximum future time',
  'bookingPolicy.clientBookingRules.maxFutureTime.description': 'How far ahead _(clients) can make _(appointments)',
}

export default bookingPolicy
