import { combineReducers } from '@reduxjs/toolkit'

import { SET_CARD_READERS } from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case SET_CARD_READERS:
      return action.cardReadersIds
    default:
      return state
  }
}

export default combineReducers({
  ids,
})
