import { filter, propEq } from 'ramda'

export const CALENDAR_TYPES = {
  google: 'google',
  outlook: 'outlook',
  apple: 'apple',
}

// TODO: swap propEq arguments after merging r4.9
export const calendarListItemsFilter = filter(propEq(false, 'readOnly'))
