const calendar = {
  'calendar.sunday': 'Sunday',
  'calendar.monday': 'Monday',
  'calendar.tuesday': 'Tuesday',
  'calendar.wednesday': 'Wednesday',
  'calendar.thursday': 'Thursday',
  'calendar.friday': 'Friday',
  'calendar.saturday': 'Saturday',

  'calendar.shortDayNames.sunday': 'Sun',
  'calendar.shortDayNames.monday': 'Mon',
  'calendar.shortDayNames.tuesday': 'Tue',
  'calendar.shortDayNames.wednesday': 'Wed',
  'calendar.shortDayNames.thursday': 'Thu',
  'calendar.shortDayNames.friday': 'Fri',
  'calendar.shortDayNames.saturday': 'Sat',

  'weekPicker.week': '{weekStart} - {weekEnd}, {weekYear} (Week {weekNumber})',
  'weekPicker.currentWeek': 'Current week',
}

export default calendar
