import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { Select } from 'antd'
import classNames from 'clsx'

import { CHAT_MEMBER_TYPE } from 'lib/constants/chat'
import { loadingSelector } from 'state/data/selectors'
import { searchChatUsers as searchChatUsersAction } from 'state/concepts/chatUsers/actions'
import { searchedChatUsersSelector } from 'state/concepts/chatUsers/selectors'
import { searchChatUsersEndpoint } from 'state/concepts/chatUsers/endpoints'
import SelectField from 'views/shared/SelectField'
import OptionLabel from './OptionLabel'
import OptionContent from './OptionContent'
import NotFoundContent from './NotFoundContent'
import Loader from './Loader'

const EMPTY_SEARCH_QUERY = ''

class DropdownChatMemberField extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    chatUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    searchChatUsers: PropTypes.func.isRequired,
    form: PropTypes.shape({
      values: PropTypes.shape({
        chat: PropTypes.shape({
          ownerId: PropTypes.number.isRequired,
          chatUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
      }).isRequired,
      setStatus: PropTypes.func.isRequired,
    }).isRequired,
    mode: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isLoading: undefined,
  }

  state = {
    searchQuery: EMPTY_SEARCH_QUERY,
  }

  get chat() {
    const {
      form: {
        values: { chat },
      },
    } = this.props

    return chat
  }

  debouncedSearchChatUsers = debounce(() => {
    const { searchChatUsers } = this.props
    const { searchQuery } = this.state

    searchChatUsers({ chat: this.chat, query: searchQuery, page: 1, resetResults: true })
  }, 250)

  handleSearch = searchQuery => {
    this.handleSelect()
    this.setState({ searchQuery }, this.debouncedSearchChatUsers)
  }

  handleSelect = () => {
    const { searchQuery } = this.state

    if (searchQuery === EMPTY_SEARCH_QUERY) {
      return
    }

    this.setState({ searchQuery: EMPTY_SEARCH_QUERY }, this.debouncedSearchChatUsers)
  }

  isDisabledOption = chatUser => {
    const { id, deactivated, memberType } = chatUser

    if (this.chat) {
      const { ownerId, chatUserIds } = this.chat

      return (
        deactivated ||
        (memberType === CHAT_MEMBER_TYPE.expert && String(ownerId) === id) ||
        chatUserIds.includes(Number(id))
      )
    }
    return deactivated
  }

  render() {
    const { isLoading, chatUsers, mode } = this.props
    const { searchQuery } = this.state
    const chatUserIds = this.chat ? this.chat.chatUserIds : []

    const { chatUsers: _users, searchChatUsers, ...restProps } = this.props

    return (
      <SelectField
        {...restProps}
        onSelect={this.handleSelect}
        searchValue={searchQuery}
        notFoundContent={<NotFoundContent isLoading={isLoading} />}
        onSearch={this.handleSearch}
        optionLabelProp="label"
        autoClearSearchValue={false}
        filterOption={false}
        showSearch
        mountToElement
        open
        autoFocus
      >
        <>
          {chatUsers.map(chatUser => (
            <Select.Option
              label={<OptionLabel chatUser={chatUser} mode={mode} />}
              value={chatUser.id}
              key={chatUser.id}
              disabled={this.isDisabledOption(chatUser)}
            >
              <OptionContent chatUser={chatUser} chatUserIds={chatUserIds} />
            </Select.Option>
          ))}

          {chatUsers.length && (
            <Select.Option
              disabled
              className={classNames({ 'visibility-hidden min-h-a p-0': isLoading === false })}
              key="waypoint"
            >
              <Loader chat={this.chat} searchQuery={searchQuery} isLoading={isLoading} />
            </Select.Option>
          )}
        </>
      </SelectField>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, searchChatUsersEndpoint.endpoint),
  chatUsers: searchedChatUsersSelector(state),
})

const mapDispatchToProps = {
  searchChatUsers: searchChatUsersAction,
}

export { DropdownChatMemberField as DropdownChatMemberFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DropdownChatMemberField)
