const videoCallsRecordingFeature = {
  'videoCallsRecordingFeature.subtitle': '<span>in</span> Video calls',
  'videoCallsRecordingFeature.description': 'Record your video calls to review them and share them with _(clients).',
  'videoCallsRecordingFeature.overview': 'ExpertBox video calls recording overview',
  'videoCallsRecordingFeature.subheader':
    'Record your ExpertBox video calls to share them with _(clients) or review them yourself whenever needed:',
  'videoCallsRecordingFeature.dontWorry':
    'Don’t worry if your internet connection breaks during the call, as ExpertBox will seamlessly stitch your recording.',
  'videoCallsRecordingFeature.getNotified':
    'Get notified when the video recording is ready and get an email with a link to watch it.',
  'videoCallsRecordingFeature.download': 'Download the recording or watch it online.',
  'videoCallsRecordingFeature.secondSubheader':
    'All your communications are secure and protected with an encrypted SSL connection and GDPR compliance.',
  'videoCallsRecordingFeature.toLearnMores':
    'To learn more, read our help center article on <link>recording a video call in ExpertBox</link>.',
  'videoCallsRecordingFeature.disableModalTitle': 'Disable ExpertBox video calls recording feature?',
  'videoCallsRecordingFeature.disableModalAlertText': 'Your past recordings won’t be deleted.',
  'videoCallsRecordingFeature.disableModalWarning': 'You won’t be able to record ExpertBox video calls anymore.',
  'videoCallsRecordingFeature.alertText':
    'To enable ExpertBox video calls recording, you need to start using Accept video calls feature on ExpertBox.',
  'videoCallsRecordingFeature.alertButtonText': 'Enable Accept video call',
}

export default videoCallsRecordingFeature
