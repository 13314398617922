import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { add, compose, isEmpty, keys, last, path, pathOr, pipe, prop, reduce, reverse, sortBy } from 'ramda'
import build from 'redux-object'

import { fetchChatsEndpoint, fetchClientChatsUnreadCountEndpoint } from 'state/concepts/chats/endpoints'
import { dataSelector, endpointSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

export const chatIdsSelector = path(['chats', 'chatIds'])

export const chatsSelector = createSelector(
  [chatIdsSelector, dataSelector],
  compose(reverse, sortBy(compose(DateTime.fromISO, prop('orderTimestamp'))), buildCollection('chat')),
)

export const chatsLoadingSelector = state => loadingSelector(state, fetchChatsEndpoint.endpoint)

export const initialChatsLoadingSelector = state => isEmpty(chatIdsSelector(state)) && chatsLoadingSelector(state)

export const chatSelector = createSelector([(_, chatId) => chatId, dataSelector], (chatId, data) =>
  build(data, 'chat', chatId),
)

export const lastChatMessageSelector = createSelector(chatSelector, chat => prop('message', chat))

export const unreadMessagesCountSelector = pipe(chatSelector, chat => prop('unreadMessagesCount', chat))

export const readCursorSelector = pipe(chatSelector, chat => prop('readCursor', chat))

export const oldestChatIdSelector = createSelector(chatsSelector, compose(prop('id'), last))

export const oldestOrderTimestampSelector = createSelector(chatsSelector, compose(prop('orderTimestamp'), last))

export const unreadCounterSelector = createSelector(dataSelector, data =>
  reduce(
    (acc, chatId) => add(acc, pathOr(0, ['chat', chatId, 'attributes', 'unreadMessagesCount'], data), data),
    0,
    keys(prop('chat', data)),
  ),
)

export const fetchedUnreadCounterSelector = state =>
  endpointSelector(state, fetchClientChatsUnreadCountEndpoint.endpoint)
