import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { CLIENT_RESCHEDULE_BOOKING } from 'state/concepts/booking/types'
import { rescheduleBookingEndpoint } from 'state/concepts/booking/endpoints'

const clientRescheduleBookingOperation = createLogic({
  type: CLIENT_RESCHEDULE_BOOKING,
  latest: true,

  async process({ httpClient, action: { values, form, notificationShown } }, dispatch, done) {
    const { uniqCode, timezone, startTime, rescheduleReason } = values
    const { endpoint, url } = rescheduleBookingEndpoint(uniqCode)

    const params = {
      reschedule_reason: rescheduleReason,
      start_time: DateTime.fromISO(startTime).toISO(),
      client_timezone: timezone,
      include: ['reschedule', 'user-profile', 'video-conference'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(hideModal())
      if (notificationShown) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.bookingRescheduled' },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    done()
  },
})

export default clientRescheduleBookingOperation
