import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'clsx'
import PropTypes from 'prop-types'
import { indexOf } from 'ramda'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import Options from 'views/shared/icons/Options'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import FolderO from 'views/shared/icons/FolderO'
import ArrowRightLong from 'views/shared/icons/ArrowRightLong'
import { isFolder } from 'utils/businessFiles'
import FileIcon from 'views/shared/FileIcon'
import profileName from 'utils/profileName'
import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import spanWithClass from 'utils/locales/spanWithClass'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'

const UpdateAccess = ({ myBusinessFile, setStep }) => {
  const { formatMessage } = useIntl()
  const currentEmployee = useSelector(currentEmployeeSelector)

  const { name, fileMimeType, kind, parent } = myBusinessFile
  const IconComponent = isFolder(kind) ? FolderO : FileIcon
  const {
    values: { businessStoragePermissions, editedBusinessStorageId },
    setFieldValue,
  } = useFormikContext()

  if (!editedBusinessStorageId) return null

  const editedBusinessStoragePermission = businessStoragePermissions.find(({ id }) => id === editedBusinessStorageId)
  const { userProfile, oldPermission, permission, ancestorName, ancestorId } = editedBusinessStoragePermission
  const isNestedParent = parent?.id !== String(ancestorId)
  const userLocale = `myBusinessFiles.updatePermission.${userProfile.id === currentEmployee.id ? 'youHave' : 'profileHas'}`
  const onUpdate = () => {
    setFieldValue('editedBusinessStorageId', undefined)
    setStep(SHARE_FILE_STEPS.sharedWith)
  }
  const onClose = () => {
    const permissionIndex = indexOf(editedBusinessStoragePermission, businessStoragePermissions)
    setFieldValue(`businessStoragePermissions.${permissionIndex}.permission`, oldPermission)
    onUpdate()
  }

  return (
    <>
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage
            id={permission ? 'myBusinessFiles.updatePermission.title' : 'myBusinessFiles.removePermission.title'}
          />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p className="text-body mb-0">
          <FormattedMessage
            id={
              permission ? 'myBusinessFiles.updatePermission.hasAccess' : 'myBusinessFiles.removePermission.hasAccess'
            }
            values={{
              span: spanWithClass('font-600'),
              name,
              parentName: ancestorName,
              userText: formatMessage({ id: userLocale }, { profileName: profileName(userProfile) }),
            }}
          />
        </p>

        <div className="files-tree mt-16">
          <ul className="files-tree__group files-tree__group--root">
            <li className="files-tree__group-element">
              <div className="files-tree__item">
                <div className="d-flex align-items-center column-gap-12">
                  <div className="wh-24-24 d-flex align-items-center justify-content-center">
                    <FolderO size={20} className="in-disabled-icon" />
                  </div>
                  <span className="text-body">{ancestorName}</span>
                </div>
                <div className="d-flex align-items-center in-gray-700 mt-2 column-gap-8 ml-36">
                  <span className="text-body text-line-through">
                    <FormattedMessage id={`myBusinessFiles.updatePermission.${oldPermission}.label`} />
                  </span>
                  <div className="wh-20-20 d-flex align-items-center justify-content-center">
                    <ArrowRightLong size={14} className="in-gray-700" />
                  </div>
                  <span className="text-body">
                    <FormattedMessage
                      id={
                        permission
                          ? `myBusinessFiles.updatePermission.${permission}.label`
                          : 'myBusinessFiles.updatePermission.removeAccess'
                      }
                    />
                  </span>
                </div>
              </div>
              <ul className="files-tree__group">
                <li
                  className={classNames('files-tree__group-element', {
                    'files-tree__group-element--with-three-dots': isNestedParent,
                  })}
                >
                  {isNestedParent && (
                    <div className="files-tree__prent-icon">
                      <Options size={12} className="in-disabled-icon" />
                    </div>
                  )}
                  <div className="files-tree__item">
                    <div className="d-flex align-items-center column-gap-12">
                      <div className="wh-24-24 d-flex align-items-center justify-content-center">
                        <IconComponent mimeType={fileMimeType} size={20} className="in-disabled-icon" />
                      </div>
                      <span className="text-body">{name}</span>
                    </div>
                    <div className="d-flex align-items-center in-gray-700 mt-2 column-gap-8 ml-36">
                      <span className="text-body text-line-through">
                        <FormattedMessage id={`myBusinessFiles.updatePermission.${oldPermission}.label`} />
                      </span>
                      <div className="wh-20-20 d-flex align-items-center justify-content-center">
                        <ArrowRightLong size={14} className="in-gray-700" />
                      </div>
                      <span className="text-body">
                        <FormattedMessage
                          id={
                            permission
                              ? `myBusinessFiles.updatePermission.${permission}.label`
                              : 'myBusinessFiles.updatePermission.removeAccess'
                          }
                        />
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-modal__footer">
        <div className="d-flex justify-content-end column-gap-16">
          <Button text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
          <Button
            text={{
              id: permission ? 'myBusinessFiles.updatePermission.submit' : 'myBusinessFiles.removePermission.submit',
            }}
            onClick={onUpdate}
          />
        </div>
      </div>
    </>
  )
}

UpdateAccess.propTypes = {
  myBusinessFile: PropTypes.shape({
    name: PropTypes.string,
    fileMimeType: PropTypes.string,
    kind: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  setStep: PropTypes.func.isRequired,
}

export default UpdateAccess
