import { createLogic } from 'redux-logic'

import { SET_CLIENT_TRANSACTIONS_FILTER } from 'state/concepts/payments/types'
import { setFilterParams, setCurrentPage, fetchClientTransactions } from 'state/concepts/payments/actions'

const filterClientTransactionsOperation = createLogic({
  type: SET_CLIENT_TRANSACTIONS_FILTER,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(fetchClientTransactions())
    done()
  },
})

export default filterClientTransactionsOperation
