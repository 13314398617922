const clientBusinessFiles = {
  'clientBusinessFiles.tab.shared': 'Shared with me',
  'clientBusinessFiles.tab.myFiles': 'My files',
  'clientBusinessFiles.empty.title': 'No files yet',
  'clientBusinessFiles.empty.body.shared': 'This is where you’ll see all files shared with you.',
  'clientBusinessFiles.empty.body.myFiles': 'This is where you’ll see all uploaded files.',
  'clientBusinessFiles.requireActions': 'Need to review',
  'clientBusinessFiles.completed': 'Reviewed',
  'clientBusinessFiles.reviewFiles': 'Review',
  'clientBusinessFiles.sharedBy': 'Shared by {userName}',
  'clientBusinessFiles.sharedOn': 'Shared on {date}',
  'clientBusinessFiles.uploadedOn': 'Uploaded on {date}',

  'clientBusinessFiles.deleteFile.modal.title': 'Delete file?',
  'clientBusinessFiles.deleteFile.modal.description':
    'This file will be permanently removed from your account. Please note that this action cannot be undone.',
}

export default clientBusinessFiles
