import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const EyeSlashO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M152.148 86.853l-65.296 65.296 105.701 105.701c-123.145 99.017-181.067 227.193-184.796 235.933l-7.757 18.218 7.757 18.217c6.142 14.361 154.596 351.195 504.244 351.195 95.414 0 175.342-25.42 241.887-62.232l117.968 117.968 65.294-65.294-785-785.003zM512 142.589c-55.919 0-106.205 9.264-152.058 23.81l76.028 76.029c24.058-4.571 49.201-7.486 76.030-7.486 249.168 0 377.151 211.863 410.537 276.878-13.576 25.998-43.323 76.464-89.915 127.258l65.834 65.834c77.577-83.949 114.786-167.723 117.787-174.696l7.758-18.217-7.758-18.218c-6.142-14.361-154.595-351.194-504.243-351.194zM258.57 323.868l94.698 94.698c-16.371 27.36-25.974 59.214-25.974 93.435 0 101.866 82.841 184.706 184.706 184.706 34.221 0 66.074-9.6 93.434-25.974l79.636 79.636c-49.58 23.509-107.116 38.691-173.070 38.691-249.168 0-377.152-211.863-410.538-276.88 19.192-36.755 70.69-121.956 157.109-188.314zM521.831 328.287l173.883 173.884c-5.034-93.877-80.005-168.85-173.883-173.884zM423.074 488.372l112.555 112.556c-7.56 2.031-15.414 3.426-23.629 3.426-50.932 0-92.353-41.42-92.353-92.353 0-8.215 1.395-16.067 3.426-23.629z" />
  </svg>
)

EyeSlashO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

EyeSlashO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default EyeSlashO
