import { DateTime } from 'luxon'
import { map, toString, uniq, find, propEq } from 'ramda'

import { FIELD_TYPES } from 'lib/constants/customField'
import isPresent from './isPresent'

export const isText = fieldType => fieldType === FIELD_TYPES.text
export const isDate = fieldType => fieldType === FIELD_TYPES.date
export const isCheckBox = fieldType => fieldType === FIELD_TYPES.checkBox
export const isRadio = fieldType => fieldType === FIELD_TYPES.radioButton
export const isCheckBoxOrRadio = fieldType => isCheckBox(fieldType) || isRadio(fieldType)
export const isCustomField = fieldType => isText(fieldType) || isDate(fieldType) || isCheckBoxOrRadio(fieldType)

export const getDestroyedOptionIds = (selectedOptionIds, initialSelectedOptionIds) =>
  initialSelectedOptionIds.reduce((acc, initialSelectedOptionId) => {
    if (selectedOptionIds.toString().split(',').map(Number).indexOf(initialSelectedOptionId) === -1) {
      acc.push(initialSelectedOptionId)
    }

    return acc
  }, [])

export const buildClientProfileFieldValuesAttributes = (selectedOptionIds, options, initialSelectedOptionsIds) => {
  const destroyedOptionIds = isPresent(initialSelectedOptionsIds)
    ? getDestroyedOptionIds(selectedOptionIds, initialSelectedOptionsIds)
    : []
  const clientProfileFieldValuesAttributes = []

  options.forEach(option => {
    selectedOptionIds
      .toString()
      .split(',')
      .forEach(selectedOptionId => {
        if (option.id === selectedOptionId) {
          const payload = {
            custom_field_option_id: Number(selectedOptionId),
          }
          if (option.clientProfileFieldValueId) payload.id = option.clientProfileFieldValueId

          clientProfileFieldValuesAttributes.push(payload)
        }

        if (destroyedOptionIds.includes(Number(option.id))) {
          const payload = {
            custom_field_option_id: Number(option.id),
            _destroy: true,
          }
          if (option.clientProfileFieldValueId) payload.id = option.clientProfileFieldValueId

          clientProfileFieldValuesAttributes.push(payload)
        }
      })
  })

  return uniq(clientProfileFieldValuesAttributes)
}

export const buildUpdateClientProfileFieldsParams = profileFields =>
  isPresent(profileFields)
    ? profileFields.map(
        ({ value = [], id, fieldType, clientProfileFieldId, customFieldOptions, selectedCustomFieldOptionsIds }) => {
          if (isCheckBoxOrRadio(fieldType)) {
            const payload = {
              custom_field_id: id,
              value: null,
              client_profile_field_values_attributes: buildClientProfileFieldValuesAttributes(
                value,
                customFieldOptions,
                selectedCustomFieldOptionsIds,
              ),
            }

            if (clientProfileFieldId) payload.id = clientProfileFieldId

            return payload
          }

          const payload = {
            custom_field_id: id,
            value,
            client_profile_field_values_attributes: [],
          }

          if (clientProfileFieldId) payload.id = clientProfileFieldId

          return payload
        },
      )
    : []

export const mapOptions = options =>
  options.map(option => ({
    ...option,
    value: option.id,
    label: option.name,
    key: option.id,
  }))

export const mapCustomFields = (customFields, { isBulk } = { isBulk: false }) =>
  customFields.map(field => {
    if (isText(field.fieldType)) {
      return {
        ...field,
        value: field.value || '',
      }
    }
    if (isDate(field.fieldType)) {
      return {
        ...field,
        value: field.value ? DateTime.fromISO(field.value) : null,
      }
    }
    if (isRadio(field.fieldType)) {
      const emptyValue = isBulk ? undefined : ''

      return {
        ...field,
        value: isPresent(field.selectedCustomFieldOptionsIds)
          ? toString(field.selectedCustomFieldOptionsIds[0])
          : emptyValue,
        customFieldOptions: mapOptions(field.customFieldOptions),
      }
    }
    if (isCheckBox(field.fieldType)) {
      return {
        ...field,
        value: isPresent(field.selectedCustomFieldOptionsIds) ? map(toString, field.selectedCustomFieldOptionsIds) : [],
        customFieldOptions: mapOptions(field.customFieldOptions),
      }
    }

    return field
  })

export const mapSelectedOptionsIdsToValues = (options, selectedOptionsIds) =>
  isPresent(selectedOptionsIds)
    ? selectedOptionsIds
        .toString()
        .split(',')
        .reduce((acc, selectedOptionId) => {
          options.forEach(option => (selectedOptionId === option.id ? acc.push(` ${option.name}`) : null))

          return acc
        }, [])
        .toString()
    : ''

export const handleClearField = (e, { name, field, setFieldValue }) => {
  e.stopPropagation()

  switch (field.fieldType) {
    case FIELD_TYPES.date:
      setFieldValue(name, null)
      break
    case FIELD_TYPES.checkBox:
      setFieldValue(name, [])
      break

    default:
      setFieldValue(name, '')
  }
}

export const clearField = ({ clientFields, formFieldsComponent, setFieldValue }) => {
  let onClear
  let isClearFieldShown

  if (isPresent(clientFields)) {
    const {
      props: {
        config: { id, name },
      },
    } = formFieldsComponent

    const field = find(propEq(id, 'id'))(clientFields)
    isClearFieldShown = isPresent(field.value) && isCustomField(field.fieldType)

    onClear = e => handleClearField(e, { name, field, setFieldValue })
  }

  return { onClear, isClearFieldShown }
}
