import * as types from './types'

export const fetchChats = (pageParams = {}) => ({
  type: types.FETCH_CHATS,
  pageParams,
})

export const setChats = chatIds => ({
  type: types.SET_CHATS,
  chatIds,
})

export const addChats = chatIds => ({
  type: types.ADD_CHATS,
  chatIds,
})

export const removeChat = chatId => ({
  type: types.REMOVE_CHAT,
  chatId,
})

export const resetChats = () => ({
  type: types.RESET_CHATS,
})

export const receiveChat = data => ({
  type: types.RECEIVE_CHAT,
  data,
})

export const getChat = chatId => ({
  type: types.GET_CHAT,
  chatId,
})

export const updateLastMessage = messages => ({
  type: types.UPDATE_LAST_MESSAGE,
  messages,
})

export const updateUnreadCounter = messages => ({
  type: types.UPDATE_UNREAD_COUNTER,
  messages,
})

export const fetchUnreadCounter = () => ({
  type: types.FETCH_UNREAD_COUNTER,
})
