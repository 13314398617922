import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Plus = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M455.111 0v455.111h-455.111v113.778h455.111v455.111h113.778v-455.111h455.111v-113.778h-455.111v-455.111h-113.778z" />
  </svg>
)

Plus.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Plus.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Plus
