import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { deactivateServiceCategories } from 'state/concepts/serviceCategories/actions'
import { deactivateServiceCategoriesLoadingSelector } from 'state/concepts/serviceCategories/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const DeactivateModal = ({ serviceCategory: { id, name }, onClose }) => {
  const isLoading = useSelector(deactivateServiceCategoriesLoadingSelector)
  const handleSubmit = useDispatchAction(deactivateServiceCategories, [id])

  return (
    <ConfirmModal
      title={{ id: 'serviceCategories.deactivate.modal.title' }}
      bodyText={[
        {
          id: 'serviceCategories.deactivate.modal.body',
          values: { name: <span className="font-600">{name}</span> },
        },
        {
          id: 'serviceCategories.deactivate.modal.allChildServices',
        },
        {
          id: 'serviceCategories.deactivate.modal.activeBookings',
        },
      ]}
      submitText={{ id: 'shared.deactivate' }}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

DeactivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default DeactivateModal
