import endpoint from 'utils/endpoint'
import { clientContactAccountRoute, clientContactRoute, clientContactsRoute } from 'lib/apiRoutes'
import {
  CREATE_CLIENT_CONTACT,
  FETCH_CLIENT_CONTACT,
  UPDATE_CLIENT_CONTACT,
  FETCH_CLIENT_CONTACTS,
  DELETE_CLIENT_CONTACT,
  DELETE_CLIENT_CONTACT_OWN_ACCOUNT,
} from './types'

export const createClientContactEndpoint = endpoint(CREATE_CLIENT_CONTACT, 'POST', clientContactsRoute)
export const updateClientContactEndpoint = contactId =>
  endpoint(UPDATE_CLIENT_CONTACT, 'PUT', clientContactRoute(contactId))
export const fetchClientContactEndpoint = contactId =>
  endpoint(FETCH_CLIENT_CONTACT, 'GET', clientContactRoute(contactId))
export const fetchClientContactsEndpoint = endpoint(FETCH_CLIENT_CONTACTS, 'GET', clientContactsRoute)
export const deleteClientContactEndpoint = contactId =>
  endpoint(DELETE_CLIENT_CONTACT, 'DELETE', clientContactRoute(contactId))
export const deleteClientContactOwnAccountEndpoint = contactId =>
  endpoint(DELETE_CLIENT_CONTACT_OWN_ACCOUNT, 'DELETE', clientContactAccountRoute(contactId))
