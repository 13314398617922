import { createLogic } from 'redux-logic'

import { SKIP_DUPLICATES_OPTIONS } from 'lib/constants/clientsImport'
import formValuesToParams from 'utils/clientsImport/formValuesToParams'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { PROCESS_CLIENT_IMPORT } from 'state/concepts/companyClientImport/types'
import { processClientImportEndpoint } from 'state/concepts/companyClientImport/endpoints'
import { clientImportIdSelector, matchFormStateSelector } from 'state/concepts/companyClientImport/selectors'
import { setIsProcessing } from 'state/concepts/companyClientImport/actions'

const processClientImportOperation = createLogic({
  type: PROCESS_CLIENT_IMPORT,
  latest: true,

  async process({ action: { values }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const clientImportId = clientImportIdSelector(state)
    const { endpoint, url } = processClientImportEndpoint(clientImportId)
    const matchFormValues = matchFormStateSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    dispatch(setIsProcessing(true))

    const params = {
      ...formValuesToParams(matchFormValues),
      skip_duplicates: values.skipDuplicates === SKIP_DUPLICATES_OPTIONS.skipDuplicates,
    }

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      dispatch(setIsProcessing(false))
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default processClientImportOperation
