import endpoint from 'utils/endpoint'
import { clientRelationshipTypeRoute, clientRelationshipTypesRoute } from 'lib/apiRoutes'
import {
  CREATE_CLIENT_RELATIONSHIP_TYPE,
  FETCH_CLIENT_RELATIONSHIP_TYPES,
  FETCH_CLIENT_RELATIONSHIP_TYPE,
  UPDATE_CLIENT_RELATIONSHIP_TYPE,
  DELETE_CLIENT_RELATIONSHIP_TYPE,
} from './types'

export const fetchClientRelationshipTypesEndpoint = endpoint(
  FETCH_CLIENT_RELATIONSHIP_TYPES,
  'GET',
  clientRelationshipTypesRoute,
)

export const createClientRelationshipTypeEndpoint = endpoint(
  CREATE_CLIENT_RELATIONSHIP_TYPE,
  'POST',
  clientRelationshipTypesRoute,
)

export const fetchClientRelationshipTypeEndpoint = id =>
  endpoint(FETCH_CLIENT_RELATIONSHIP_TYPE, 'GET', clientRelationshipTypeRoute(id))

export const updateClientRelationshipTypeEndpoint = id =>
  endpoint(UPDATE_CLIENT_RELATIONSHIP_TYPE, 'PUT', clientRelationshipTypeRoute(id))

export const deleteClientRelationshipTypeEndpoint = id =>
  endpoint(DELETE_CLIENT_RELATIONSHIP_TYPE, 'DELETE', clientRelationshipTypeRoute(id))
