import { createSelector } from '@reduxjs/toolkit'
import { path, prop } from 'ramda'
import build from 'redux-object'

import buildCollection from 'utils/buildCollection'
import { DOCUMENT_TEMPLATE_STATUSES } from 'lib/constants/documentTemplates'
import { dataSelector, loadingSelector, totalCountSelector } from 'state/data/selectors'
import {
  fetchDocumentTemplatesEndpoint,
  getDocumentTemplateEndpoint,
  previewDocumentTemplateEndpoint,
  publishDocumentTemplateEndpoint,
} from './endpoints'

export const documentTemplateIdsSelector = path(['documentTemplates', 'ids'])

export const documentTemplateIdSelector = path(['documentTemplates', 'id'])

export const filtersSelector = path(['documentTemplates', 'filters'])

export const isBlankStateSelector = path(['documentTemplates', 'isBlankState'])

export const documentTemplatesSelector = createSelector(
  [documentTemplateIdsSelector, dataSelector],
  buildCollection('documentTemplate'),
)

export const documentTemplateSelector = createSelector([documentTemplateIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'documentTemplate', id) : null,
)

export const documentTemplateByIdSelector = createSelector([(_state, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'documentTemplate', id) : null,
)

export const getDocumentTemplateLoadingSelector = (state, id) =>
  loadingSelector(state, getDocumentTemplateEndpoint(id).endpoint)
export const previewDocumentTemplateLoadingSelector = (state, id) =>
  loadingSelector(state, previewDocumentTemplateEndpoint(id).endpoint)
export const publishDocumentTemplateLoadingSelector = (state, id) =>
  loadingSelector(state, publishDocumentTemplateEndpoint(id).endpoint)

export const fetchDocumentTemplatesLoadingSelector = state =>
  loadingSelector(state, fetchDocumentTemplatesEndpoint.endpoint)

export const documentTemplatesTotalCountSelector = state =>
  totalCountSelector(state, fetchDocumentTemplatesEndpoint.endpoint)

export const appliedFiltersSelector = createSelector(filtersSelector, ({ name, status }) => ({
  name,
  status: status === DOCUMENT_TEMPLATE_STATUSES.allStatuses ? '' : status,
}))

export const selectedFilterSelector = createSelector(appliedFiltersSelector, prop('status'))
