import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const processLocales = workspaceCode => ({
  type: types.PROCESS_LOCALES,
  workspaceCode,
})

export const getCustomName = () => ({
  type: types.GET_CUSTOM_NAME,
})

export const setCustomName = customNameId => ({
  type: types.SET_CUSTOM_NAME,
  customNameId,
})

export const updateCustomName = makeFormSubmitAction(types.UPDATE_CUSTOM_NAME)
