import * as types from './types'

export const fetchClientFeed = (clientId, pageParams = {}) => ({
  type: types.FETCH_CLIENT_FEED,
  clientId,
  pageParams,
})

export const addClientFeedIds = ids => ({
  type: types.ADD_CLIENT_FEED_IDS,
  ids,
})

export const removeClientFeedIds = ids => ({
  type: types.REMOVE_CLIENT_FEED_IDS,
  ids,
})

export const resetClientFeed = () => ({
  type: types.RESET_CLIENT_FEED,
})
