import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import { MAX_SERVICE_DURATION, MIN_SERVICE_DURATION } from 'lib/constants/services'
import yup from 'lib/yupLocalised'

const rescheduleRecurringSchema = yup.object().shape({
  serviceId: yup.string().required(),
  duration: yup
    .string()
    .isDurationString()
    .durationMin(MIN_SERVICE_DURATION)
    .durationMax(MAX_SERVICE_DURATION)
    .required(),
  date: yup.date().required(),
  time: yup.date().required(),
  reason: yup.string().max(MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH).required({ id: 'yup.string.rescheduleReason' }),
})

export default rescheduleRecurringSchema
