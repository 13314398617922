import { createLogic } from 'redux-logic'

import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { remindToSignEtherpadDocumentEndpoint } from '../endpoints'
import { REMIND_TO_SIGN_ETHERPAD_DOCUMENT } from '../types'

const remindToSignEtherpadDocumentOperation = createLogic({
  type: REMIND_TO_SIGN_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { documentId }, httpClient }, dispatch, done) {
    const { url, endpoint } = remindToSignEtherpadDocumentEndpoint(documentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentRemindersWereSent',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default remindToSignEtherpadDocumentOperation
