import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import build from 'redux-object'
import Router from 'next/router'

import { clientsContactsSessionRoute } from 'lib/apiRoutes'
import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { clientBookingsRoute, clientRoute } from 'lib/routes'
import { CLIENT_CONTACT_LOGIN } from 'state/concepts/session/types'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import redirect from 'utils/redirect'
import setClientCookies from 'utils/setClientCookies'

const clientContactLoginOperation = createLogic({
  type: CLIENT_CONTACT_LOGIN,
  latest: true,

  async process({ action: { id }, action, getState, httpClient }, dispatch, done) {
    const { uniqCode } = workspaceSelector(getState())
    const redirectTo = Router.router.query.redirect_to || action.redirectTo || clientBookingsRoute(uniqCode)

    try {
      const { data } = await httpClient.post(
        clientsContactsSessionRoute(id),
        { include: 'workspace' },
        buildCustomBaseUrl(['workspaces', uniqCode]),
      )

      const response = normalize(data)
      const currentClient = build(response, 'clientProfile')[0]
      const tokens = data.meta.jwt

      setClientCookies(currentClient, tokens, { path: clientRoute(uniqCode) })

      redirect({
        href: redirectTo,
        reload: true,
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default clientContactLoginOperation
