const bookingPageSettings = {
  'bookingPageSettings.title': 'Booking pages',
  'bookingPageSettings.addBookingPage': 'Add booking page',
  'bookingPageSettings.empty.getBooked': 'Get booked online',
  'bookingPageSettings.empty.createPage': 'Create a booking page and share it with _(clients) to get booked online.',
  'bookingPageSettings.createPage': 'Create booking page',
  'bookingPageSettings.empty.noServices': 'There are no _(services) to book',
  'bookingPageSettings.empty.createService':
    'Before adding a booking page, please create some _(services) for _(clients) to book.',
  'bookingPageSettings.createService': 'Create _(service)',
  'bookingPageSettings.editBookingPage': 'Edit booking page',
  'bookingPageSettings.shareBookingPage': 'Share booking page',
  'bookingPageSettings.bookingPageName': 'Booking page',
  'bookingPageSettings.status': 'Status',
  'bookingPageSettings.actions': 'Actions',
  'bookingPageSettings.services': '{count} {count, plural, one {_(service)} other {_(services)}}',
  'bookingPageSettings.experts': '{count} {count, plural, one {_(expert)} other {_(experts)}}',
  'bookingPageSettings.yourClientsWillNoLonger': 'Your _(clients) will no longer be able to make new meeting',
  'bookingPageSettings.areYouSureYouWantToActivate': 'Are you sure you want to activate the {name}?',
  'bookingPageSettings.areYouSureYouWantToDeactivate': 'Are you sure you want to deactivate the {name}?',
  'bookingPageSettings.activateBookingPage': 'Activate booking page?',
  'bookingPageSettings.deactivateBookingPage': 'Deactivate booking page?',
  'bookingPageSettings.bookingPageIsInstalledBut': 'Booking page is installed, but there are no SSL key',
  'bookingPageSettings.knowMore': 'Know more',
  'bookingPageSettings.inCaseYouCancelBookingTheClient':
    'In case you cancel _(appointment) the _(client) will be refunded with whole cost of consultation',
  'bookingPageSettings.openMenuToAssign': 'Open menu to customize this booking page and embed to your website',
  'bookingPageSettings.welcomeModal.title.settings': 'Welcome to Booking page settings page',
  'bookingPageSettings.welcomeModal.title.integrations': 'Welcome to Integrations page',
  'bookingPageSettings.welcomeModal.title.services': 'Welcome to _(Services) & _(Experts) page',
  'bookingPageSettings.welcomeModal.settings':
    'This page lets you set up your booking page: its unique name, appearance, and status. This done, don’t forget to <b>activate</b> it. Otherwise, _(clients) won’t see it.',
  'bookingPageSettings.welcomeModal.customize':
    'Customize the look of your booking page to increase brand awareness: set the <b>primary color</b> and <b>upload your logo.</b>',
  'bookingPageSettings.welcomeModal.sideBookingPage':
    'This page lets you choose how to share your booking page with _(clients). There are 3 ways to do it:{break} 1 — Add <b>ExpertBox side booking page</b> to your website. This is a floating <b>button</b> that launches a booking form as a popup. Also can be added as a <b>link.</b>',
  'bookingPageSettings.welcomeModal.embedBookingPage':
    '2 — <b>Embed booking page</b> on your website. This way you can place the booking panel anywhere in the body of your website, and make it any size you want.',
  'bookingPageSettings.welcomeModal.shareLink':
    '3 — If you don’t have a website, you can use a standalone <b>booking web page</b>. Then all you need is to share a link!',
  'bookingPageSettings.welcomeModal.services':
    'This page lets you decide which _(services) and _(experts) _(clients) will be able to book on this booking page.',
  'bookingPageSettings.welcomeModal.addRemoveServices': 'Add or remove _(services) from the booking page at any time.',
}

export default bookingPageSettings
