import PropTypes from 'prop-types'

import Checkbox from '../Checkbox'

const CheckboxFieldComponent = ({ field, onChange, ...props }) => (
  <Checkbox checked={field.value} {...field} {...props} onChange={onChange} />
)

CheckboxFieldComponent.propTypes = {
  field: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxFieldComponent
