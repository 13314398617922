import PropTypes from 'prop-types'

import { isCancelled, cancelledByType, cancellationReason } from 'utils/bookings'
import EllipsisWithToggle from 'views/shared/EllipsisWithToggle'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import TextWithBrackets from 'views/shared/icons/TextWithBrackets'

const CancellationReason = ({ formatMessage, booking, isShort }) => {
  if (!isCancelled(booking)) return null

  const reason = cancellationReason(booking)
  const isCustomReason = Boolean(reason?.id)

  return (
    <>
      <div className="d-flex gap-16">
        <div className="w-20 text-center">
          <TextWithBrackets size={18} className="in-blue-gray-200" />
        </div>
        <div className="booking-tooltip__content-warp">
          <div className="in-blue-gray-300 mb-4" data-cy="booking-cancellation-reason-title">
            <FormattedOrRawMessage message={cancelledByType(booking)} />
          </div>
          <EllipsisWithToggle
            text={isCustomReason ? formatMessage(reason) : reason}
            rows={3}
            className="text-body mb-0"
          />
        </div>
      </div>
      {!isShort && <div className="divider divider--light m-0" />}
    </>
  )
}

CancellationReason.defaultProps = {
  isShort: false,
}

CancellationReason.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  booking: PropTypes.shape().isRequired,
  isShort: PropTypes.bool,
}

export default CancellationReason
