import { FormattedMessage } from 'react-intl'

import DateView from 'views/shared/icons/DateView'
import ListView from 'views/shared/icons/ListView'

export const UNASSIGNED_ID = 'unassigned'

export const TASK_STATUS = {
  todo: 'todo',
  completed: 'completed',
}

export const TASK_STATUS_FILTERS = [
  {
    value: TASK_STATUS.todo,
    label: { id: 'tasks.filters.status.todo' },
  },
  {
    value: TASK_STATUS.completed,
    label: { id: 'tasks.filters.status.completed' },
  },
]

export const VIEWS = {
  date: 'date',
  list: 'list',
}

export const TASK_TYPES = {
  overdue: 'overdue',
  upcoming: 'upcoming',
  noDueDate: 'noDue',
}

export const VIEWS_OPTIONS = [
  {
    label: VIEWS.list,
    value: VIEWS.list,
    key: '1',
    content: (
      <>
        <ListView className="mr-12 select-view-icon filter-button__label-icon" size={20} />
        <FormattedMessage id="tasks.listView" />
      </>
    ),
  },
  {
    label: VIEWS.date,
    value: VIEWS.date,
    key: '2',
    content: (
      <>
        <DateView className="mr-12 select-view-icon filter-button__label-icon" size={20} />
        <FormattedMessage id="tasks.dateView" />
      </>
    ),
  },
]
