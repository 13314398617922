import * as types from './types'

const initialState = {
  sidebarType: null,
  sidebarProps: {},
  showOverlay: false,
  animate: true,
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SIDEBAR:
      return {
        sidebarType: action.sidebarType,
        sidebarProps: action.sidebarProps,
        showOverlay: action.showOverlay,
        animate: action.animate,
      }
    case types.HIDE_SIDEBAR:
      return initialState
    case types.UPDATE_SIDEBAR:
      return {
        ...state,
        sidebarProps: {
          ...state.sidebarProps,
          ...action.sidebarProps,
        },
      }
    default:
      return state
  }
}

export default sidebarReducer
