import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

const FormattedCurrency = ({ price, minimumFractionDigits }) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber value={price} style="currency" currency="USD" minimumFractionDigits={minimumFractionDigits} />
)

FormattedCurrency.defaultProps = {
  minimumFractionDigits: 2,
}

FormattedCurrency.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minimumFractionDigits: PropTypes.number,
}

export default FormattedCurrency
