import { useEffect, useState } from 'react'

const useCurrentTime = wavesurfer => {
  const [currentTime, setCurrentTime] = useState(null)

  useEffect(() => {
    if (!wavesurfer) return

    const subscriptions = [wavesurfer.on('timeupdate', seconds => setCurrentTime(seconds))]

    // eslint-disable-next-line consistent-return
    return () => subscriptions.forEach(unsubscribe => unsubscribe())
  }, [wavesurfer])

  return { currentTime }
}

export default useCurrentTime
