import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { includes, without } from 'ramda'
import React from 'react'

import { CHIPS_FINISHED_KEYS } from 'lib/constants/videoConference'
import isEmail from 'utils/isEmail'
import EmailsInputFieldComponent from './component'

class EmailsInputField extends React.Component {
  static propTypes = {
    invitedMembersEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    form: PropTypes.shape({
      setFieldError: PropTypes.func.isRequired,
      errors: PropTypes.shape(),
    }).isRequired,
    isEdit: PropTypes.bool,
  }

  static defaultProps = {
    isEdit: false,
  }

  state = {
    inputValue: '',
  }

  inputFieldRef = React.createRef()

  get fieldErrors() {
    const {
      field,
      form: { errors },
    } = this.props

    return getIn(errors, field.name)
  }

  handleClose = removedTag => () => {
    const { field } = this.props

    field.onChange({
      target: {
        value: without([removedTag], field.value),
        name: field.name,
      },
    })
  }

  handleInputChange = e => {
    const {
      field,
      form: { setFieldError },
    } = this.props

    this.setState({ inputValue: e.target.value })

    if (this.fieldErrors) {
      setFieldError(field.name, undefined)
    }
  }

  handleInputConfirm = () => {
    const {
      field,
      form: { setFieldError },
      invitedMembersEmails,
      isEdit,
    } = this.props
    const { inputValue } = this.state
    const emailValue = inputValue.trim()

    if (!emailValue) {
      return
    }

    const skipValidateAlreadyInvitedEmail = isEdit && !includes(emailValue, field.value)

    if (!isEmail(emailValue)) {
      setFieldError(field.name, { id: 'yup.string.email' })
      return
    }
    if (includes(emailValue, field.value)) {
      setFieldError(field.name, { id: 'inviteMembers.alreadyEntered' })
      return
    }

    if (!skipValidateAlreadyInvitedEmail && includes(emailValue, invitedMembersEmails)) {
      setFieldError(field.name, { id: 'inviteMembers.alreadyInvited' })
      return
    }

    field.onChange({
      target: {
        value: [...field.value, emailValue],
        name: field.name,
      },
    })
    this.setState({ inputValue: '' })
    setFieldError(field.name, undefined)
  }

  handleClick = () => {
    this.inputFieldRef.current.focus()
  }

  handleKeyDown = e => {
    if (includes(e.key, CHIPS_FINISHED_KEYS)) {
      e.preventDefault()
      this.handleInputConfirm()
      this.handleClick()
    }
  }

  render = () => (
    <EmailsInputFieldComponent
      {...this.state}
      {...this.props}
      onClose={this.handleClose}
      onInputChange={this.handleInputChange}
      onInputConfirm={this.handleInputConfirm}
      fieldTouched={this.fieldTouched}
      fieldErrors={this.fieldErrors}
      ref={this.inputFieldRef}
      onClick={this.handleClick}
      onKeyDown={this.handleKeyDown}
    />
  )
}

export default EmailsInputField
