import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Options = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256 312.888c-31.418 0-56.888-25.471-56.888-56.888s25.47-56.888 56.888-56.888 56.888 25.471 56.888 56.888-25.471 56.888-56.888 56.888zm199.112 0c-31.418 0-56.888-25.471-56.888-56.888s25.471-56.888 56.888-56.888C486.53 199.112 512 224.583 512 256s-25.471 56.888-56.888 56.888zm-398.224 0C25.469 312.888 0 287.417 0 256s25.47-56.888 56.888-56.888c31.419 0 56.888 25.471 56.888 56.888s-25.469 56.888-56.888 56.888z" />
  </svg>
)

Options.defaultProps = {
  size: 18,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Options.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Options
