import PropTypes from 'prop-types'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import classNames from 'clsx'

const TotalSectionComponent = ({ title, amount, className, priceClassName }) => (
  <div className="preview-invoice__payment-total">
    <p className={classNames(className, 'mb-0')} data-cy="payment-total-title">
      <FormattedMessage {...title} />
    </p>
    <div className="preview-invoice__payment-total-price-wrap">
      <p className={classNames(priceClassName, 'payment-total-price')} data-cy="payment-total-price-value">
        {/* eslint-disable-next-line react/style-prop-object */}
        <FormattedNumber value={amount} style="currency" currency="USD" />
      </p>
    </div>
  </div>
)

TotalSectionComponent.defaultProps = {
  className: '',
  priceClassName: 'payment-total-price__blue',
}

TotalSectionComponent.propTypes = {
  title: PropTypes.shape().isRequired,
  amount: PropTypes.string.isRequired,
  className: PropTypes.string,
  priceClassName: PropTypes.string,
}

export default TotalSectionComponent
