import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_PAYMENT_METHOD } from 'state/concepts/recurringPayment/types'
import { setPaymentMethodId } from 'state/concepts/recurringPayment/actions'
import { getPaymentMethodEndpoint } from '../endpoints'

const getPaymentMethodOperation = createLogic({
  type: GET_PAYMENT_METHOD,
  latest: true,

  async process({ action: { recurringPaymentId, workspaceCode }, httpClient }, dispatch, done) {
    const { endpoint, url } = getPaymentMethodEndpoint(recurringPaymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, buildCustomBaseUrl(['workspaces', workspaceCode]))
      const response = normalize(data, { endpoint })

      dispatch(setPaymentMethodId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getPaymentMethodOperation
