import { DateTime, Duration } from 'luxon'
import { curry, defaultTo, filter, flatten, ifElse, is, isNil, pipe, toPairs, whereEq } from 'ramda'

import { DURATION_REGEX } from 'lib/constants'
import {
  MINUTES_PER_HOUR,
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
  SECONDS_PER_MONTH,
  TIME_UNITS_DAY,
  TIME_UNITS_HOUR,
  TIME_UNITS_MINUTE,
  TIME_UNITS_MONTH,
} from 'lib/constants/timeUnits'

const SECONDS_PER_TIME_UNIT = {
  [TIME_UNITS_MINUTE]: SECONDS_PER_MINUTE,
  [TIME_UNITS_HOUR]: SECONDS_PER_HOUR,
  [TIME_UNITS_DAY]: SECONDS_PER_DAY,
  [TIME_UNITS_MONTH]: SECONDS_PER_MONTH,
}

const OVERLAP_HOUR = 1

export const createZeroDuration = () => ({ hours: 0, minutes: 0 })

export const parseDuration = durationString => {
  const match = DURATION_REGEX.exec(durationString.trim())
  if (!match) {
    return null
  }

  const [hours, minutes, hoursOnly, minutesOnly] = match.slice(1, 5)

  return {
    hours: parseInt(hoursOnly || hours || 0, 10),
    minutes: parseInt(minutesOnly || minutes || 0, 10),
  }
}

export const formatDuration = (durationObject, { omitZeroes, isLong } = { omitZeroes: true, isLong: false }) => {
  const hideHours = omitZeroes && durationObject.hours === 0
  const hideMinutes = omitZeroes && durationObject.minutes === 0

  let hoursSuffix
  let minutesSuffix

  if (!hideHours) {
    const isMoreThanHour = durationObject.hours > 1
    const longSuffix = isMoreThanHour ? ' hours' : ' hour'
    hoursSuffix = isLong ? longSuffix : 'h'
  }
  if (!hideMinutes) {
    const isMoreThanMinute = durationObject.minutes > 1
    const longSuffix = isMoreThanMinute ? ' minutes' : ' minute'
    minutesSuffix = isLong ? longSuffix : 'm'
  }

  const hours = hideHours ? '' : `${durationObject.hours}${hoursSuffix}`
  const minutes = hideMinutes ? '' : `${durationObject.minutes}${minutesSuffix}`
  const separator = hideHours || hideMinutes ? '' : ' '

  return `${hours}${separator}${minutes}`
}

export const durationToFormatString = (duration, formatOptions) => {
  const hours = Math.floor(duration / SECONDS_PER_HOUR)
  const minutes = Math.floor((duration % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)

  return formatDuration({ hours, minutes }, formatOptions)
}

export const durationToFormatStringByDates = (startDate, endDate) =>
  durationToFormatString(endDate.diff(startDate, 'seconds').get('seconds'))

export const addMinutes = curry((amount, duration) => {
  const result = { ...duration }
  result.minutes += amount
  if (result.minutes >= MINUTES_PER_HOUR) {
    result.hours += Math.trunc(result.minutes / MINUTES_PER_HOUR)
    result.minutes %= MINUTES_PER_HOUR
  }
  return result
})

export const subtractMinutes = curry((amount, duration) => {
  const result = { ...duration }

  result.minutes -= amount
  if (result.minutes < 0) {
    const hoursDelta = -Math.trunc(result.minutes / MINUTES_PER_HOUR) + OVERLAP_HOUR
    result.hours -= hoursDelta
    result.minutes += MINUTES_PER_HOUR * hoursDelta
  }

  if (result.minutes < 0 || result.hours < 0 || whereEq(createZeroDuration(), result)) {
    return { ...duration }
  }

  return result
})

export const durationToSeconds = durationOrString => {
  const duration = is(String, durationOrString) ? parseDuration(durationOrString) : durationOrString

  if (!duration) {
    return 0
  }

  const minutes = duration.hours * MINUTES_PER_HOUR + duration.minutes
  return minutes * SECONDS_PER_MINUTE
}

export const makeDurationChangeHandler = curry((action, params, duration) =>
  pipe(
    parseDuration,
    ifElse(isNil, pipe(defaultTo(createZeroDuration()), addMinutes(params)), action(params)),
    formatDuration,
  )(duration),
)

export const incrementDurationHandler = makeDurationChangeHandler(addMinutes)
export const decrementDurationHandler = makeDurationChangeHandler(subtractMinutes)

export const valueWithUnitToSeconds = (unit, value) => value * SECONDS_PER_TIME_UNIT[unit]

export const secondsToValueWithUnits = seconds => {
  const units = Duration.fromObject({ seconds }).shiftTo('minutes', 'hours', 'days', 'months').toObject()
  const [unit = 'minutes', value = 0] = pipe(
    filter(item => item !== 0),
    toPairs,
    flatten,
  )(units)

  return { value, unit }
}

export const formatDurationFromSeconds = seconds => `${Duration.fromObject({ seconds }).toFormat("h'h' m'm'")}`

export const formatSlotDuration = seconds => `${formatDurationFromSeconds(seconds)}/w`

export const formatDurationDifference = (seconds, showSign) => {
  const sign = seconds >= 0 ? '+' : '-'
  const duration = Duration.fromObject({ seconds: Math.abs(seconds) })
    .toFormat("h'h' m'm'")
    .replace(/(^0h )|( 0m)/, '')

  return `${showSign ? sign : ''}${duration}`
}

export const formatDurationDistance = seconds => Duration.fromObject({ seconds }).toFormat('hh:mm:ss')

export const formatDistance = (time, otherTime) =>
  time.diff(otherTime).toFormat("d'd' h'h' m'm' s's'").replace('0d ', '')

export const daysFromNowToDate = (date, { calculateActualDays = false } = {}) => {
  const targetDate = DateTime.fromISO(date)
  const currentDate = DateTime.now()

  if (calculateActualDays) {
    const difference = targetDate.setZone('UTC').startOf('day').diff(currentDate.setZone('UTC').startOf('day'), 'days')
    return difference.days.toString()
  }

  return targetDate.diff(currentDate).toFormat('d')
}

export const daysFromDateToDate = (endDate, startDate) =>
  DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate)).toFormat('d')

export const formatCountdownTime = (time, otherTime) => time.diff(otherTime).toFormat('mm:ss')
