import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import redirect from 'utils/redirect'
import { guestLinkExpiredRoute } from 'lib/routes'
import { validateCancelBookingEndpoint } from 'state/concepts/bookings/endpoints'
import { VALIDATE_CANCEL_BOOKING } from 'state/concepts/guestBookings/types'
import { setCancellationRules } from 'state/concepts/bookings/actions'

const validateCancelBookingOperation = createLogic({
  type: VALIDATE_CANCEL_BOOKING,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { workspaceCode, uniqCode, res } = action
    const { url } = validateCancelBookingEndpoint(uniqCode)
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    try {
      const response = await httpClient.get(url, config)

      if (response.data && response.data.meta) {
        const {
          data: {
            meta: {
              time,
              cancellation_rules: description,
              booking_refund_amount: bookingRefundAmount,
              refund_amount: refundAmount,
              refund_count: refundCount,
              validate_cancellation_reason: validateCancellationReason,
            },
          },
        } = response

        dispatch(
          setCancellationRules({
            time,
            description,
            bookingRefundAmount,
            refundAmount,
            refundCount,
            validateCancellationReason,
          }),
        )
      }
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }

    done()
  },
})

export default validateCancelBookingOperation
