import React from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import yup from 'lib/yupLocalised'
import { billingDetailSelector } from 'state/concepts/billing/selectors'
import { MAX_PASSWORD_LENGTH } from 'lib/constants'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { removeCompanyProfile } from 'state/concepts/company/actions'
import DeleteCompanyModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class DeleteCompanyModal extends React.Component {
  static propTypes = {
    debtsTotal: PropTypes.number,
  }

  static defaultProps = {
    debtsTotal: 0,
  }

  render = () => <DeleteCompanyModalComponent {...this.props} />
}

const mapStateToProps = state => ({
  debtsTotal: billingDetailSelector(state)?.usedResourcesTotal,
})

const mapDispatchToProps = {
  onSubmit: removeCompanyProfile,
}

export { DeleteCompanyModal as DeleteCompanyModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      password: '',
    }),
    validationSchema: yup.object().shape({
      password: yup.string().max(MAX_PASSWORD_LENGTH).required(),
    }),
    handleSubmit: handleSubmitWithProps(['debtsTotal']),
    validateOnBlur: false,
  }),
)(DeleteCompanyModal)
