import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import { pluck } from 'ramda'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { deleteStorageFilesEndpoint } from '../endpoints'
import { DELETE_STORAGE_FILES } from '../types'
import { fetchStorageFiles, removeStorageFileIds, setStorageSpace } from '../actions'

const deleteStorageFilesOperation = createLogic({
  type: DELETE_STORAGE_FILES,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const { url, endpoint } = deleteStorageFilesEndpoint
    const { files, onSuccess } = action
    const state = getState()
    const workspaceCode = currentWorkspaceCodeSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    const params = {
      storage_files: files.map(({ sourceId, sourceType }) => ({
        source_id: sourceId,
        source_type: sourceType,
      })),
    }
    try {
      const { data } = await httpClient.delete(url, { params }, buildCustomBaseUrl(['workspaces', workspaceCode]))
      const response = normalize(data, { endpoint })
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setStorageSpace(data.meta))
      dispatch(removeStorageFileIds(pluck('id', files)))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.storage.${files.length > 1 ? 'itemsDeleted' : 'fileDeleted'}`,
            values: { count: files.length },
          },
        }),
      )
      onSuccess?.()
      !onSuccess && dispatch(fetchStorageFiles())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteStorageFilesOperation
