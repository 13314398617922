import fetchEtherpadDocuments from './fetchEtherpadDocuments'
import renameEtherpadDocument from './renameEtherpadDocument'
import voidEtherpadDocument from './voidEtherpadDocument'
import signEtherpadDocument from './signEtherpadDocument'
import remindToSignEtherpadDocument from './remindToSignEtherpadDocument'
import createEtherpadDocument from './createEtherpadDocument'
import deleteEtherpadDocument from './deleteEtherpadDocument'
import duplicateEtherpadDocument from './duplicateEtherpadDocument'
import downloadEtherpadDocument from './downloadEtherpadDocument'
import getEtherpadDocument from './getEtherpadDocument'
import removeEtherpadDocumentClientAccess from './removeEtherpadDocumentClientAccess'
import reviewEtherpadDocument from './reviewEtherpadDocument'
import shareEtherpadDocumentWithClient from './shareEtherpadDocumentWithClient'
import updateEtherpadDocumentClientAccess from './updateEtherpadDocumentClientAccess'
import addSignatories from './addSignatories'
import updateSignatories from './updateSignatories'
import touchEtherpadDocument from './touchEtherpadDocument'
import declineEtherpadDocumentSigning from './declineEtherpadDocumentSigning'

export default [
  fetchEtherpadDocuments,
  createEtherpadDocument,
  getEtherpadDocument,
  renameEtherpadDocument,
  voidEtherpadDocument,
  signEtherpadDocument,
  remindToSignEtherpadDocument,
  duplicateEtherpadDocument,
  downloadEtherpadDocument,
  reviewEtherpadDocument,
  shareEtherpadDocumentWithClient,
  updateEtherpadDocumentClientAccess,
  removeEtherpadDocumentClientAccess,
  deleteEtherpadDocument,
  addSignatories,
  updateSignatories,
  touchEtherpadDocument,
  declineEtherpadDocumentSigning,
]
