import PropTypes from 'prop-types'
import { sortableContainer } from 'react-sortable-hoc'

import SortableOption from '../SortableOption'

const FieldOptions = ({ fieldOptions, fieldType, removeRow }) => (
  <div>
    {fieldOptions.map((option, index) => (
      <SortableOption
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        optionIndex={index}
        fieldType={fieldType}
        onRemove={() => removeRow(index)}
        canRemove={fieldOptions.length > 1}
      />
    ))}
  </div>
)

FieldOptions.propTypes = {
  fieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      priority: PropTypes.number.isRequired,
    }),
  ).isRequired,
  removeRow: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
}

export default sortableContainer(FieldOptions)
