import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { camelCase } from 'lodash'

import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { ALERT_KINDS } from 'lib/constants'
import { ETHERPAD_DOCUMENT_PERMISSIONS, ETHERPAD_DOCUMENT_PERMISSION_OPTIONS } from 'lib/constants/etherpadDocuments'
import { shareEtherpadDocumentWithClient } from 'state/concepts/etherpadDocuments/actions'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import Alert from 'views/shared/Alert'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { isSigned, isWaitingForSignature } from 'utils/etherpadDocuments'

const ShareDocumentWithClientModal = ({ onClose, document }) => {
  const isClientContactsEnable = useWorkspaceFeature(FEATURE_KEYS.clientContacts)
  const {
    id: documentId,
    clientProfile: { id: clientId },
  } = document
  const status = camelCase(document.status)
  const handleSubmit = useFormSubmit(shareEtherpadDocumentWithClient)
  const isOnlyViewer = isWaitingForSignature(status) || isSigned(status)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="etherpadDocuments.shareDocumentWithClientModal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            clientId,
            documentId,
            role: ETHERPAD_DOCUMENT_PERMISSIONS.viewer,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <>
            <div className="main-modal__body">
              {isOnlyViewer && (
                <Alert
                  type={ALERT_KINDS.info}
                  className="align-items-center mb-16 pl-12 pr-12"
                  iconSize={20}
                  message={{
                    id: 'etherpadDocuments.shareDocumentWithClientModal.documentIsSentForSignature.viewer.alert',
                  }}
                />
              )}
              {isClientContactsEnable && (
                <Alert
                  type={ALERT_KINDS.info}
                  className="align-items-center mb-16 pl-12 pr-12"
                  iconSize={20}
                  message={{ id: 'etherpadDocuments.shareDocumentWithClientModal.alert' }}
                />
              )}
              {isOnlyViewer ? (
                <>
                  <p className="text-body">
                    <FormattedMessage id="etherpadDocuments.shareDocumentWithClientModal.permission" />
                  </p>
                  <p className="text-body font-700 mb-0">
                    <FormattedMessage id="etherpadDocuments.shareDocumentWithClientModal.viewer.title" />
                  </p>
                  <p className="text-caption mb-0 mt-4">
                    <FormattedMessage id="etherpadDocuments.shareDocumentWithClientModal.viewer.description" />
                  </p>
                </>
              ) : (
                <Field
                  wrapperClassName="d-flex flex-column row-gap-12"
                  className="align-items-center main-radio--black-label mb-0"
                  id="role"
                  name="role"
                  component={RadioField}
                  label={{ id: 'etherpadDocuments.renameModal.name.label' }}
                  options={ETHERPAD_DOCUMENT_PERMISSION_OPTIONS}
                />
              )}
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton
                className="main-modal__footer-action"
                text={{ id: 'etherpadDocuments.shareDocumentWithClientModal.submit' }}
                disabled={false}
              />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

ShareDocumentWithClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    clientProfile: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default ShareDocumentWithClientModal
