import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'
import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { MARK_ALL_MESSAGES_AS_READ } from 'state/concepts/chat/types'
import { markChatMessagesAsReadEndpoint } from 'state/concepts/chat/endpoints'
import { dataApiSuccess } from 'state/data/actions'
import { setUnreadIndicatorVisibility } from 'state/concepts/chat/actions'
import { unreadMessagesSelector } from 'state/concepts/chat/selectors'

const markAllMessagesAsReadOperation = createLogic({
  type: MARK_ALL_MESSAGES_AS_READ,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { chatId } = action
    const unreadMessages = unreadMessagesSelector(state)
    const { endpoint, url } = markChatMessagesAsReadEndpoint(chatId)

    try {
      if (!isEmpty(unreadMessages)) {
        const readCursor = DateTime.utc().toISO()
        const params = { read_cursor: readCursor }

        await httpClient.put(url, params)

        const response = updateDataHelper(getState().data, 'chat', chatId, {
          attributes: { readCursor },
        })

        dispatch(dataApiSuccess({ response, endpoint }))
      }

      dispatch(setUnreadIndicatorVisibility(false))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default markAllMessagesAsReadOperation
