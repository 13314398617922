/* eslint-disable no-template-curly-in-string */
const businessSettings = {
  'businessSettings.title': 'Business information',
  'businessSettings.tab.profile': 'Profile',
  'businessSettings.tab.contactDetails': 'Contact details',
  'businessSettings.profile': 'Business profile',
  'businessSettings.profile.description':
    'This is what people will see on your booking page, financial documents (invoices, receipts, etc), and more.',
  'businessSettings.profile.businessName.label':
    'Business name <span>(this name will appear on your financial documents, policies, etc)</span>',
  'businessSettings.profile.industry.label': 'Industry',
  'businessSettings.contactDetails': 'Contact details',
  'businessSettings.contactDetails.googleAddress.label': 'Address',
  'businessSettings.contactDetails.googleAddress.placeholder': 'Start typing address or postcode',
  'businessSettings.contactDetails.manualAddress.button': 'Enter address manually',
  'businessSettings.contactDetails.addressLine.label': 'Address line',
  'businessSettings.contactDetails.city.label': 'City',
  'businessSettings.contactDetails.country.label': 'Country',
  'businessSettings.contactDetails.zipCode.label': 'Postal/ZIP code',
  'businessSettings.contactDetails.phoneNumber.label': 'Phone number',
  'businessSettings.contactDetails.phoneNumber.placeholder': '(123) 555-5555',
  'businessSettings.contactDetails.email.label': 'Email',
}

export default businessSettings
