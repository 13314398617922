import { createLogic } from 'redux-logic'

import { removeFormId } from '../actions'
import { RECEIVE_GUEST_FORM } from '../types'

const receiveGuestFormOperation = createLogic({
  type: RECEIVE_GUEST_FORM,
  latest: true,

  process({ action: { data } }, dispatch, done) {
    const {
      data: {
        attributes: { 'form-type': formType },
        id,
      },
    } = data

    dispatch(removeFormId(formType, id))
    done()
  },
})

export default receiveGuestFormOperation
