import * as types from './types'

export const getEmployees = ({
  searchQuery,
  page,
  resetResults,
  statuses,
  filters,
  roles = ['workspace_owner', 'administrator', 'user'],
} = {}) => ({
  type: types.GET_EMPLOYEES,
  searchQuery,
  page,
  resetResults,
  statuses,
  filters,
  roles,
})

export const getClients = ({ searchQuery, page, resetResults, active = true }) => ({
  type: types.GET_CLIENTS,
  searchQuery,
  page,
  resetResults,
  active,
})

export const getServices = ({ searchQuery, statuses } = {}) => ({
  type: types.GET_SERVICES,
  searchQuery,
  statuses,
})

export const getClientServices = searchQuery => ({
  type: types.GET_CLIENT_SERVICES,
  searchQuery,
})

export const getClientConsultants = searchQuery => ({
  type: types.GET_CLIENT_CONSULTANTS,
  searchQuery,
})

export const getContactClientProfiles = ({ searchQuery, resetResults, status, clientId, page }) => ({
  type: types.GET_CONTACT_CLIENT_PROFILES,
  searchQuery,
  resetResults,
  status,
  clientId,
  page,
})
