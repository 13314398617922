import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import Modal from 'views/shared/Modal'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import TextAreaField from 'views/shared/TextAreaField'
import Cross from 'views/shared/icons/Cross'
import RescheduleChangeDate from './RescheduleChangeDate'

const RescheduleFormModalComponent = ({
  handleResize,
  hasScroll,
  values: { uniqCode, timezone, startTime },
  setFieldValue,
  onClose,
  handleSubmit,
  scrollContainerRef,
}) => (
  <Modal onClose={onClose} className="resch-modal main-modal main-modal--md main-modal--lg">
    <div className="main-modal__container">
      <div className="main-modal__header main-modal__header--no-shadow">
        <h2 className="main-modal__title">
          <FormattedMessage id="bookings.rescheduleAppointment" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body pb-0 mb-16" ref={scrollContainerRef}>
        <div className="mb-48">
          <RescheduleChangeDate
            uniqCode={uniqCode}
            timezone={timezone}
            startTime={startTime}
            handleResize={handleResize}
            setFieldValue={setFieldValue}
          />
        </div>
        <Field
          className="textarea-reason mb-0 full-width main-input--textarea-top-count"
          name="rescheduleReason"
          component={TextAreaField}
          id="rescheduleReason"
          label={{ id: 'rescheduleBookingModal.reason' }}
          icon="alert"
          showCount
          maxLength={MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH}
        />
      </div>

      <div
        className={classNames('main-modal__footer', {
          'main-modal__footer--shadow': hasScroll,
        })}
      >
        <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
        <SubmitFormButton text={{ id: 'rescheduleBookingModal.reschedule' }} onClick={handleSubmit} />
      </div>
    </div>
  </Modal>
)

RescheduleFormModalComponent.propTypes = {
  scrollContainerRef: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasScroll: PropTypes.bool.isRequired,
  handleResize: PropTypes.func.isRequired,
}

export default RescheduleFormModalComponent
