import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { SET_CUSTOM_FIELDS, ADD_CUSTOM_FIELDS, REMOVE_CUSTOM_FIELDS } from './types'

const customFieldIds = (state = [], action) => {
  switch (action.type) {
    case SET_CUSTOM_FIELDS:
      return action.ids
    case ADD_CUSTOM_FIELDS:
      return uniq([...action.ids, ...state])
    case REMOVE_CUSTOM_FIELDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const customFieldsReducer = combineReducers({
  customFieldIds,
})

export default customFieldsReducer
