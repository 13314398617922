const namespace = 'clientRelationshipTypes'

export const FETCH_CLIENT_RELATIONSHIP_TYPES = `${namespace}/FETCH_CLIENT_RELATIONSHIP_TYPES`
export const SET_CLIENT_RELATIONSHIP_TYPES_IDS = `${namespace}/SET_CLIENT_RELATIONSHIP_TYPES_IDS`
export const FILTER_CLIENT_RELATIONSHIP_TYPES = `${namespace}/FILTER_CLIENT_RELATIONSHIP_TYPES`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const CREATE_CLIENT_RELATIONSHIP_TYPE = `${namespace}/CREATE_CLIENT_RELATIONSHIP_TYPE`
export const FETCH_CLIENT_RELATIONSHIP_TYPE = `${namespace}/FETCH_CLIENT_RELATIONSHIP_TYPE`
export const SET_CLIENT_RELATIONSHIP_TYPE_ID = `${namespace}/SET_CLIENT_RELATIONSHIP_TYPE_ID`
export const UPDATE_CLIENT_RELATIONSHIP_TYPE = `${namespace}/UPDATE_CLIENT_RELATIONSHIP_TYPE`
export const RESET_CLIENT_RELATIONSHIP_TYPE_ID = `${namespace}/RESET_CLIENT_RELATIONSHIP_TYPE_ID`
export const SEARCH_CLIENT_RELATIONSHIP_TYPES = `${namespace}/SEARCH_CLIENT_RELATIONSHIP_TYPES`
export const DELETE_CLIENT_RELATIONSHIP_TYPE = `${namespace}/DELETE_CLIENT_RELATIONSHIP_TYPE`
