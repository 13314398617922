import { createSelector } from '@reduxjs/toolkit'
import { map, path } from 'ramda'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchFormCategoriesEndpoint } from './endpoints'

export const formCategoryIdsSelector = path(['formCategories', 'ids'])

export const formCategoriesLoadingSelector = state => loadingSelector(state, fetchFormCategoriesEndpoint.endpoint)

export const formCategoriesSelector = createSelector(
  [formCategoryIdsSelector, dataSelector],
  buildCollection('formCategory'),
)

export const formCategoriesTotalCountSelector = path(['formCategories', 'totalCount'])

export const isBlankStateSelector = path(['formCategories', 'isBlankState'])

export const filtersSelector = path(['formCategories', 'filters'])

export const formCategoriesForFilterSelector = createSelector(
  makeSelectSearchResults('formCategories', 'formCategory'),
  map(({ id, name }) => ({ key: id, value: id, label: name })),
)
