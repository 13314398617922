import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

const SwitchComponent = ({
  dataTrigger,
  className,
  label,
  disabled,
  isChecked,
  onChange,
  name,
  id,
  dataCy,
  mainSwitchTextClassName,
}) => (
  <label
    data-trigger={dataTrigger}
    className={classNames('main-switch', className, {
      'main-switch--disabled': disabled,
    })}
    data-cy={`${dataCy}-label`}
  >
    <input className="main-switch__input" type="checkbox" checked={isChecked} {...{ id, name, disabled, onChange }} />

    <span className="main-switch__icon" data-cy={dataCy} />

    {label && (
      <span className={classNames('main-switch__text', mainSwitchTextClassName)} data-cy="main-switch-text">
        <FormattedMessage {...label} />
      </span>
    )}
  </label>
)

SwitchComponent.defaultProps = {
  dataTrigger: null,
  className: null,
  disabled: false,
  label: null,
  id: null,
  name: null,
  dataCy: 'main-switch-icon',
  mainSwitchTextClassName: null,
}

SwitchComponent.propTypes = {
  dataTrigger: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  dataCy: PropTypes.string,
  mainSwitchTextClassName: PropTypes.string,
}

export default SwitchComponent
