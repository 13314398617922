import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const currentEmployeeId = (state = null, action) => {
  switch (action.type) {
    case types.EMPLOYEE_LOGIN_SUCCESS:
      return action.currentEmployeeId
    case types.EMPLOYEE_LOGOUT_SUCCESS:
    case types.BULK_EMPLOYEE_LOGOUT_SUCCESS:
      return null
    case types.CLIENT_LOGIN_SUCCESS:
      return null
    default:
      return state
  }
}

const currentClientId = (state = null, action) => {
  switch (action.type) {
    case types.CLIENT_LOGIN_SUCCESS:
      return action.currentClientId
    case types.CLIENT_LOGOUT_SUCCESS:
      return null
    case types.EMPLOYEE_LOGIN_SUCCESS:
      return null
    default:
      return state
  }
}

const currentGuestId = (state = null, action) => {
  switch (action.type) {
    case types.GUEST_LOGIN_SUCCESS:
      return action.currentGuestId
    case types.GUEST_LOGOUT_SUCCESS:
      return null
    default:
      return state
  }
}

const sessionReducer = combineReducers({
  currentEmployeeId,
  currentClientId,
  currentGuestId,
})

export default sessionReducer
