import classNames from 'clsx'
import PropTypes from 'prop-types'

import Time from 'views/shared/icons/Time'

import { formattedDuration, formattedFullDate, formattedTime, showRecurrenceRules } from 'utils/bookings'
import BookingRecurrence from '../BookingRecurrence'

const BookingDateInfo = ({
  booking,
  booking: { bookingRecurrence, rrule, allDayEvent },
  className,
  withoutIcon,
  isShort,
  breakpointDayHide,
  slotStart,
}) => (
  <div className={classNames(className, 'booking-date-info')}>
    <div className="booking-date-info__item d-flex gap-16">
      {!withoutIcon && (
        <div className="w-20 text-center">
          <Time className="booking-date-info__icon in-blue-gray-200" size={18} dataCy="consultation-icon-time" />
        </div>
      )}
      <div className="booking-date-info__content">
        <div className="booking-date-info__content-item">
          <div className={`d-${breakpointDayHide}-none d-inline-block`}>
            <span data-cy="consultation-date">
              {formattedFullDate(allDayEvent ? { startTime: slotStart } : booking)}
            </span>
            <span className="divider-text" />
          </div>
          {!allDayEvent && (
            <>
              <span className="mr-4" data-cy="consultation-start">
                {formattedTime(booking).toLowerCase()}
              </span>
              <span className="in-gray-700 mr-4" data-cy="consultation-duration">
                ({formattedDuration(booking)})
              </span>
            </>
          )}
          {rrule && (
            <span className="mr-4 w-100" data-cy="consultation-start">
              {showRecurrenceRules(rrule)}
            </span>
          )}
          {isShort && bookingRecurrence && (
            <>
              <span className="divider-text d-sm-inline-block d-none" />
              <BookingRecurrence booking={booking} withIcon={false} />
            </>
          )}
        </div>
      </div>
    </div>
    {!isShort && bookingRecurrence && (
      <div className="booking-date-info__item" data-cy="booking-recurrence">
        <BookingRecurrence booking={booking} withIcon={!withoutIcon} />
      </div>
    )}
  </div>
)

BookingDateInfo.defaultProps = {
  className: '',
  withoutIcon: false,
  isShort: false,
  breakpointDayHide: 'xs',
  slotStart: undefined,
}

BookingDateInfo.propTypes = {
  booking: PropTypes.shape().isRequired,
  className: PropTypes.string,
  withoutIcon: PropTypes.bool,
  isShort: PropTypes.bool,
  breakpointDayHide: PropTypes.string,
  slotStart: PropTypes.instanceOf(Date),
}

export default BookingDateInfo
