import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchTransactions = () => ({
  type: types.FETCH_TRANSACTIONS,
})

export const setTransactions = transactionIds => ({
  type: types.SET_TRANSACTIONS,
  transactionIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_TRANSACTIONS_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_TRANSACTIONS_PAGE,
  pageNumber,
})

export const searchTransactions = () => ({
  type: types.SEARCH_TRANSACTIONS,
})

export const filterTransactions = filters => ({
  type: types.SET_TRANSACTIONS_FILTER,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_TRANSACTIONS_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_TRANSACTIONS_FILTER_PARAMS,
})

export const fetchReceiptTemplate = () => ({
  type: types.FETCH_RECEIPT_TEMPLATE,
})

export const setReceiptTemplate = receiptTemplateId => ({
  type: types.SET_RECEIPT_TEMPLATE,
  receiptTemplateId,
})

export const updateReceiptTemplate = makeFormSubmitAction(types.UPDATE_RECEIPT_TEMPLATE)

export const fetchClientTransactions = () => ({
  type: types.FETCH_CLIENT_TRANSACTIONS,
})

export const filterClientTransactions = filters => ({
  type: types.SET_CLIENT_TRANSACTIONS_FILTER,
  filters,
})

export const resetClientTransactionsFilter = () => ({
  type: types.RESET_CLIENT_TRANSACTIONS_FILTER,
})

export const cancelPayment = id => ({
  type: types.CANCEL_PAYMENT,
  id,
})

export const requestPayment = makeFormSubmitAction(types.REQUEST_PAYMENT)
