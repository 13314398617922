const namespace = 'etherpadDocuments'

export const FETCH_ETHERPAD_DOCUMENTS = `${namespace}/FETCH_ETHERPAD_DOCUMENTS`
export const ADD_ETHERPAD_DOCUMENT_IDS = `${namespace}/ADD_ETHERPAD_DOCUMENT_IDS`
export const REMOVE_ETHERPAD_DOCUMENT_IDS = `${namespace}/REMOVE_ETHERPAD_DOCUMENT_IDS`
export const RESET_ETHERPAD_DOCUMENTS = `${namespace}/RESET_ETHERPAD_DOCUMENTS`
export const DUPLICATE_ETHERPAD_DOCUMENT = `${namespace}/DUPLICATE_ETHERPAD_DOCUMENT`
export const DOWNLOAD_ETHERPAD_DOCUMENT = `${namespace}/DOWNLOAD_ETHERPAD_DOCUMENT`
export const RENAME_ETHERPAD_DOCUMENT = `${namespace}/RENAME_ETHERPAD_DOCUMENT`
export const VOID_ETHERPAD_DOCUMENT = `${namespace}/VOID_ETHERPAD_DOCUMENT`
export const SIGN_ETHERPAD_DOCUMENT = `${namespace}/SIGN_ETHERPAD_DOCUMENT`
export const REMIND_TO_SIGN_ETHERPAD_DOCUMENT = `${namespace}/REMIND_TO_SIGN_ETHERPAD_DOCUMENT`
export const CREATE_ETHERPAD_DOCUMENT = `${namespace}/CREATE_ETHERPAD_DOCUMENT`
export const SET_ETHERPAD_DOCUMENT_ID = `${namespace}/SET_ETHERPAD_DOCUMENT_ID`
export const GET_ETHERPAD_DOCUMENT = `${namespace}/GET_ETHERPAD_DOCUMENT`
export const APPEND_ETHERPAD_ID = `${namespace}/APPEND_ETHERPAD_ID`
export const RESET_ETHERPAD_DOCUMENT_ID = `${namespace}/RESET_ETHERPAD_DOCUMENT_ID`
export const REVIEW_ETHERPAD_DOCUMENT = `${namespace}/REVIEW_ETHERPAD_DOCUMENT`
export const SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT = `${namespace}/SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT`
export const UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS = `${namespace}/UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS`
export const REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS = `${namespace}/REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS`
export const DELETE_ETHERPAD_DOCUMENT = `${namespace}/DELETE_ETHERPAD_DOCUMENT`
export const ADD_SIGNATORIES = `${namespace}/ADD_SIGNATORIES`
export const DECLINE_TO_SIGN_ETHERPAD_DOCUMENT = `${namespace}/DECLINE_TO_SIGN_ETHERPAD_DOCUMENT`
export const UPDATE_SIGNATORIES = `${namespace}/UPDATE_SIGNATORIES`
export const TOUCH_ETHERPAD_DOCUMENT = `${namespace}/TOUCH_ETHERPAD_DOCUMENT`
