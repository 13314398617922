const namespace = 'subscriptionPlans'

export const FETCH_SUBSCRIPTION_PLANS = `${namespace}/FETCH_SUBSCRIPTION_PLANS`
export const SET_SUBSCRIPTION_PLANS = `${namespace}/SET_SUBSCRIPTION_PLANS`
export const SET_STORAGE_PLANS = `${namespace}/SET_STORAGE_PLANS`
export const UPDATE_SUBSCRIPTIONS = `${namespace}/UPDATE_SUBSCRIPTIONS`
export const FETCH_CHANGE_SUBSCRIPTION_DETAILS = `${namespace}/FETCH_CHANGE_SUBSCRIPTION_DETAILS`
export const SET_CHANGE_SUBSCRIPTION_DETAILS_ID = `${namespace}/SET_CHANGE_SUBSCRIPTION_DETAILS_ID`
export const UPDATE_STORAGE_PLAN = `${namespace}/UPDATE_STORAGE_PLAN`
export const UPDATE_STORAGE_PLAN_SUBSCRIPTION = `${namespace}/UPDATE_STORAGE_PLAN_SUBSCRIPTION`
export const SWITCH_BILLING_CYCLE = `${namespace}/SWITCH_BILLING_CYCLE`
export const CANCEL_SWITCH_BILLING_CYCLE = `${namespace}/CANCEL_SWITCH_BILLING_CYCLE`
export const CANCEL_PLAN_DOWNGRADE = `${namespace}/CANCEL_PLAN_DOWNGRADE`
export const DOWNGRADE_TO_FREE = `${namespace}/DOWNGRADE_TO_FREE`
