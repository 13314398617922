export const SIGNATURE_TYPES = {
  type: 'type',
  image: 'image',
  draw: 'draw',
}

export const SIGNATURE_TYPE_OPTIONS = [
  {
    value: SIGNATURE_TYPES.type,
    key: SIGNATURE_TYPES.type,
    label: { id: 'eSignature.type' },
  },
  {
    value: SIGNATURE_TYPES.image,
    key: SIGNATURE_TYPES.image,
    label: { id: 'eSignature.image' },
  },
  {
    value: SIGNATURE_TYPES.draw,
    key: SIGNATURE_TYPES.draw,
    label: { id: 'eSignature.draw' },
  },
]
