import classNames from 'clsx'
import { DateTime, FixedOffsetZone, Interval } from 'luxon'

import { distanceBetweenContainerAndElement } from 'lib/calendar/scrollHandling'
import { DATA_SCROLL_CONTAINER } from 'lib/constants'
import { SELECTED_CALENDAR_EVENTS_CLASS, Views } from 'lib/constants/bookings'
import { CALENDAR_WEEK_VIEW_STEP } from 'lib/constants/calendar'
import { HOURS_PER_DAY, MINUTES_PER_DAY, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'
import luxonLocalizer from 'lib/localizer'
import { times } from 'ramda'
import { getClassName, getColor } from 'utils/bookings'
import { getLocalTime } from 'utils/dateTime'
import { durationToSeconds } from 'utils/duration'
import isPresent from './isPresent'

export const localizer = luxonLocalizer(DateTime)

export const eventStyleGetter = selectedSidebarId => event => ({
  className: classNames(getClassName(event, selectedSidebarId), {
    [SELECTED_CALENDAR_EVENTS_CLASS]: selectedSidebarId && selectedSidebarId === event.id,
  }),
  style: {
    color: getColor(event),
  },
})

export const isDayView = view => view === Views.DAY
export const isWeekView = view => view === Views.WEEK
export const isScheduleView = view => view === Views.AGENDA
export const isTeamView = view => view === Views.TEAM

export const startAccessor = event => new Date(event.startTime)

export const endAccessor = event => new Date(event.endTime)

export const allDayAccessor = ({ startTime, endTime, allDayEvent }) => {
  const start = DateTime.fromISO(startTime)
  const end = DateTime.fromISO(endTime)
  const { hours } = Interval.fromDateTimes(start, end).toDuration(['hours']).toObject()

  return allDayEvent || hours >= HOURS_PER_DAY
}

export const formattedTodayDate = timeZoneOffset => {
  const local = getLocalTime(timeZoneOffset)

  return `${local.weekdayLong} ${local.day}, ${local.monthLong}`
}
export const timeGutterFormat = (date, culture, calendarLocalizer) =>
  calendarLocalizer.format(date, 'h a', culture).toLowerCase()

export const dateFromOffset = offset =>
  DateTime.fromObject({}, { zone: FixedOffsetZone.parseSpecifier(`UTC${offset}`) })

// use fake local time as react-big-calendar supports only local machine timezone in native Date
export const makeFakeLocalTZNowJSDateFromOffset = offset => () =>
  new Date(dateFromOffset(offset).toFormat("yyyy-MM-dd'T'HH:mm:ss"))

// use fake local time as react-big-calendar supports only local machine timezone in native Date
export const fakeLocalTZNowFromOffset = offset => dateFromOffset(offset).setZone('local', { keepLocalTime: true })

export const getEventWidth = eventElement =>
  eventElement && eventElement.parentElement.parentElement.getBoundingClientRect().width
export const getEventHeight = eventElement =>
  eventElement && eventElement.parentElement.parentElement.getBoundingClientRect().height

export const scrollContainer = () => {
  /* istanbul ignore if  */
  if (typeof document === 'undefined' || !document) {
    return null
  }

  return document.querySelector(`[data-scroll=${DATA_SCROLL_CONTAINER}]`)
}

const getTimeToScroll = ({ firstEventTime, timezoneOffset, isCurrentDay }) =>
  isCurrentDay ? dateFromOffset(timezoneOffset) : DateTime.fromISO(firstEventTime)

const agendaRowHeight = () => {
  const agendaRow = document.querySelector('[data-calendar=agenda-row]')
  return agendaRow ? agendaRow.getBoundingClientRect().height : 0
}

export const tableContent = view => {
  /* istanbul ignore if  */
  if (typeof document === 'undefined' || !document) {
    return null
  }

  return document.querySelector(view === Views.AGENDA ? '.rbc-agenda-view' : '.rbc-time-content')
}

export const targetScrollHeight = ({
  view,
  tableContainer = tableContent(view),
  minutesShift = MINUTES_PER_HOUR,
  ...options
}) => {
  let distanceFromTableToScrollContainer = distanceBetweenContainerAndElement(tableContainer, scrollContainer())

  if (view === Views.AGENDA) {
    return distanceFromTableToScrollContainer
  }

  options.isCurrentDay && (distanceFromTableToScrollContainer = 0)

  const targetTime = getTimeToScroll(options)
  const timeToScroll = targetTime.hour * MINUTES_PER_HOUR + targetTime.minute - minutesShift
  const distanceFromTimeToScrollToTable =
    timeToScroll > 0 ? Math.ceil((timeToScroll / MINUTES_PER_DAY) * tableContainer.scrollHeight) : 0

  return distanceFromTimeToScrollToTable
}

export const activeScrollHeight = options => {
  if (options.view === Views.AGENDA) {
    const distanceFromTableToScrollContainer = distanceBetweenContainerAndElement(
      options.tableContainer,
      scrollContainer(),
    )

    return distanceFromTableToScrollContainer + agendaRowHeight()
  }

  return targetScrollHeight({ ...options, minutesShift: 0 })
}

export const inRange = ({ startTime, endTime }, start, end) => {
  const eStart = DateTime.fromISO(startTime, { zone: 'UTC' })
  const eEnd = DateTime.fromISO(endTime, { zone: 'UTC' })

  return +eStart <= +end && +eEnd >= +start
}

export const getCalendarEventStartAndEndTime = (date, time, duration) => {
  const startTime = time.set({ year: date.year, month: date.month, day: date.day })
  const endTime = startTime.plus({ seconds: durationToSeconds(duration) })

  return { startTime, endTime }
}

export const schedulingBookingModalBodyContainer = () => document.querySelector('.booking-tooltip__body')

export const isTheSameWeek = (date, previousDate) => {
  let currentWeek = DateTime.fromISO(date)
  currentWeek = currentWeek.weekdayShort === 'Sun' ? currentWeek.plus({ day: 1 }).weekNumber : currentWeek.weekNumber

  let previousWeek = DateTime.fromISO(previousDate)
  previousWeek =
    previousWeek.weekdayShort === 'Sun' ? previousWeek.plus({ day: 1 }).weekNumber : previousWeek.weekNumber
  return previousWeek === currentWeek
}

const timeSlotDivider = CALENDAR_WEEK_VIEW_STEP * SECONDS_PER_MINUTE

export const createAvailabilitiesHashTable = availabilities => {
  if (!isPresent(availabilities)) return undefined

  const result = availabilities.reduce(
    (acc, availability) => {
      const { start, duration, date } = availability

      const end = start + duration
      const startSlot = Math.floor(start / timeSlotDivider)
      const endSlot = Math.ceil(end / timeSlotDivider)

      const dayName = DateTime.fromISO(date).weekdayShort

      const timesCount = endSlot - startSlot
      times(index => {
        acc[dayName][startSlot + index] = true
      }, timesCount)

      return acc
    },
    { Sun: {}, Mon: {}, Tue: {}, Wed: {}, Thu: {}, Fri: {}, Sat: {} },
  )

  return result
}

export const createAvailabilitiesHashTableByResource = resources =>
  resources?.reduce((acc, { employee }) => {
    acc[employee.id] = createAvailabilitiesHashTable(employee.dateAvailability)
    return acc
  }, {})

export const isAvailablePredicate = (date, availabilitiesHashTable) => {
  const weedDay = date.weekdayShort
  const timeSlot = (date.hour * SECONDS_PER_MINUTE + date.minute) / 15
  return !!availabilitiesHashTable?.[weedDay]?.[timeSlot]
}
