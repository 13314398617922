import { createLogic } from 'redux-logic'
import Router from 'next/router'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { ACTIVATE_SERVICES } from 'state/concepts/services/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { activateServiceEndpoint } from 'state/concepts/services/endpoints'
import { setNotActivatedServicesCount } from '../actions'

const activateServicesOperation = createLogic({
  type: ACTIVATE_SERVICES,
  latest: true,

  async process({ action: { serviceIds }, httpClient }, dispatch, done) {
    const { url, endpoint } = activateServiceEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, { service_ids: serviceIds })

      const response = normalize(data)
      const showSuccesNotification =
        !Router.router.asPath.includes(`services/${serviceIds[0]}`) &&
        serviceIds.length > data.meta.not_activated_services

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setNotActivatedServicesCount(data.meta.not_activated_services))

      if (showSuccesNotification) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.servicesActivated',
              values: { count: serviceIds.length },
            },
          }),
        )
      }

      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default activateServicesOperation
