const crmFeature = {
  'crmFeature.title': 'CRM',
  'crmFeature.subtitle': '<span>in</span> CRM',
  'crmFeature.description':
    'Manage all _(client)-related activity and build strong relationships with your _(clients).',
  'crmFeature.overview': 'CRM overview',
  'crmFeature.subheader':
    'Easily access your _(clients)’ information and booking history to better understand _(client) needs and provide the best experience.',
  'crmFeature.keepOrganized': 'Keep your _(client) information organized:',
  'crmFeature.importClients': 'Import your existing _(client) database into ExpertBox.',
  'crmFeature.accessClientInformation':
    'Safely store and easily access your _(clients)’ information, activity, and booking history.',
  'crmFeature.buildStrongRelationships':
    'Use _(client) data to prepare for meetings and build stronger _(client) relationships.',
  'crmFeature.customizeClientProfiles': 'Customize _(client) profiles:',
  'crmFeature.addCustomFields': 'Add custom fields to gather information that is relevant to your business.',
  'crmFeature.takeNotes': 'Take notes:',
  'crmFeature.trackInformation':
    'Keep track of important information such as _(clients)’ progress or questions for an upcoming _(appointment).',
  'crmFeature.takeNotesFor':
    'Take notes for yourself or other administrators before, during, and after _(appointments).',
  'crmFeature.reviewNotes': 'Review notes before the next meeting to make the most of your time together.',
  'crmFeature.organizeFiles': 'Organize _(client) files:',
  'crmFeature.keepFilesOrganized':
    'Keep _(clients)’ files organized and secure by uploading them to _(clients)’ their profiles.',
  'crmFeature.shareFiles': 'Share files with _(clients) in a click.',
  'crmFeature.toLearnMores': 'To learn more, read our help center article on <link>managing your _(clients)</link>.',
  'crmFeature.disableCRM': 'Disable CRM?',
  'crmFeature.disableCRMWarning':
    'You won’t be able to import new _(clients), customize their profiles, take _(client) notes and upload files.',
}

export default crmFeature
