import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { DELETE_INTAKE_FORM } from 'state/concepts/intakeForm/types'
import { fetchIntakeForms } from 'state/concepts/forms/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { deleteIntakeFormEndpoint } from 'state/concepts/intakeForm/endpoints'

const deleteIntakeFormOperation = createLogic({
  type: DELETE_INTAKE_FORM,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = deleteIntakeFormEndpoint(id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(fetchIntakeForms())
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.intakeFormDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteIntakeFormOperation
