import fetchForms from './fetchForms'
import fetchFiles from './fetchFiles'
import fetchDocuments from './fetchDocuments'
import filterDocuments from './filterDocuments'
import viewFiles from './viewFiles'
import receiveGuestForm from './receiveGuestForm'
import receiveAccountForm from './receiveAccountForm'
import filterFiles from './filterFiles'
import fetchMyFiles from './fetchMyFiles'
import filterMyFiles from './filterMyFiles'
import deleteMyFile from './deleteMyFile'

export default [
  fetchForms,
  fetchFiles,
  fetchDocuments,
  filterDocuments,
  viewFiles,
  receiveGuestForm,
  receiveAccountForm,
  filterFiles,
  fetchMyFiles,
  filterMyFiles,
  deleteMyFile,
]
