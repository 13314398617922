import { reduce } from 'ramda'
import min from 'lodash/min'
import max from 'lodash/max'

import {
  CALENDAR_ROW,
  CALENDAR_HEADER_CELL,
  CALENDAR_BODY_FIRST_CELL,
  CALENDAR,
  CALENDAR_BODY,
  CALENDAR_BODY_CELL,
  DEFAULT_AVAILABILITY,
} from 'lib/constants/calendar'
import { SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'

export const isAvailabilityAdded = (selectedCells, availabilityMapping) => {
  // first element in `selectedCells` is initial for selection(buttonDown),
  // last is the last focused(buttonUp), so we can select mode add/remove from its
  // state in mapping
  const { dayIndex, slotIndex } = selectedCells[0].props

  return !availabilityMapping[dayIndex][slotIndex]
}

export const durationDifference = (availabilityMapping, selectedCells, step) => {
  const seconds = selectedCells.length * step * SECONDS_PER_MINUTE

  return isAvailabilityAdded(selectedCells, availabilityMapping) ? seconds : -seconds
}

const getHeaderDimensions = () => {
  const { bottom: headerBottom } = document.querySelector(`.${CALENDAR_HEADER_CELL}`).getBoundingClientRect()

  return { headerBottom }
}

const getGutterWidth = () => document.querySelector(`.${CALENDAR_BODY_FIRST_CELL}`).getBoundingClientRect().width

const getContainerBottom = () => document.querySelector(`.${CALENDAR_BODY}`).getBoundingClientRect().bottom

const getSlotWidth = () => {
  const element = document.querySelector(`.${CALENDAR_BODY_CELL}:not(.${CALENDAR_BODY_FIRST_CELL})`)
  const { width } = element.getBoundingClientRect()
  const marginLeft = parseInt(window.getComputedStyle(element)['margin-left'], 10)

  return width + marginLeft
}

const getBoundarySlotsDimensions = slotIndexes => {
  const firstSlot = document.querySelectorAll(`.${CALENDAR_ROW}`)[min(slotIndexes)]
  const lastSlot = document.querySelectorAll(`.${CALENDAR_ROW}`)[max(slotIndexes)]

  return {
    firstSlotTop: firstSlot.getBoundingClientRect().top,
    lastSlotBottom: lastSlot.getBoundingClientRect().bottom,
  }
}

export const selectionStyles = (slotIndexes, dayIndexes) => {
  const calendarTop = document.querySelector(`.${CALENDAR}`).getBoundingClientRect().top
  const { headerBottom } = getHeaderDimensions()
  const gutterWidth = getGutterWidth()
  const containerBottom = getContainerBottom()
  const slotWidth = getSlotWidth()
  const { firstSlotTop, lastSlotBottom } = getBoundarySlotsDimensions(slotIndexes)

  const startOfSelection = firstSlotTop < headerBottom ? headerBottom - calendarTop : firstSlotTop - calendarTop
  const topOfSelection = startOfSelection
  const bottomOfSelection =
    lastSlotBottom > containerBottom ? containerBottom - calendarTop : lastSlotBottom - calendarTop

  return {
    top: topOfSelection,
    height: bottomOfSelection - startOfSelection,
    left: gutterWidth + min(dayIndexes) * slotWidth,
    width: dayIndexes.length * slotWidth,
  }
}

export const selectedAvailabilities = (selectedCells, availabilityMapping) =>
  reduce(
    (acc, { props: { dayIndex, slotIndex } }) => [
      ...acc,
      availabilityMapping[dayIndex][slotIndex] || DEFAULT_AVAILABILITY,
    ],
    [],
    selectedCells,
  )
