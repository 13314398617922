import { createLogic } from 'redux-logic'
import { prop, head, without } from 'ramda'
import build from 'redux-object'
import normalize from 'json-api-normalizer'

import { sendCommand } from 'state/concepts/webSockets/actions'
import { clientChatsRoute, companyChatsRoute } from 'lib/routes'
import { leaveChatCommand } from 'utils/chat'
import updateDataHelper from 'utils/updateDataHelper'
import redirect from 'utils/redirect'
import { RECEIVE_LEFT_CHAT } from 'state/concepts/chat/types'
import { chatSelector } from 'state/concepts/chat/selectors'
import {
  isEmployeeLoggedInSelector,
  entityWorkspaceUniqCodeSelector,
  currentChatUserIdSelector,
} from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { removeChat } from 'state/concepts/chats/actions'

const receiveLeftChatOperation = createLogic({
  type: RECEIVE_LEFT_CHAT,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { data } = action
    const response = normalize(data)
    const chat = head(build(response, 'chat'))
    const state = getState()
    const chatId = chat.id
    const chatUserId = prop('id', chat.performerChatUser)

    const isEmployee = isEmployeeLoggedInSelector(state)
    const workspaceCode = entityWorkspaceUniqCodeSelector(state, chatId, 'chat')
    const currentChat = chatSelector(state)
    const currentChatUserId = currentChatUserIdSelector(state).toString()
    const isSameChat = currentChat.id === chatId
    const isSameChatUser = currentChatUserId === chatUserId

    if (isSameChat && isSameChatUser) {
      dispatch(sendCommand(leaveChatCommand(chatId)))

      if (isEmployee) {
        redirect({ href: companyChatsRoute, workspace: workspaceCode })
      } else {
        redirect({ href: clientChatsRoute(workspaceCode) })
      }
    }

    if (!isSameChat && isSameChatUser) {
      dispatch(removeChat(chatId))
      dispatch(sendCommand(leaveChatCommand(chatId)))
    }

    if (isSameChat && !isSameChatUser) {
      const updateChatMembers = updateDataHelper(getState().data, 'chat', chatId, {
        attributes: {
          chatUserIds: without([Number(chatUserId)], currentChat.chatUserIds),
        },
      })

      dispatch(dataApiSuccess({ response: updateChatMembers }))
    }

    done()
  },
})

export default receiveLeftChatOperation
