import { isNil } from 'ramda'

import { SERVICE_MIN_PRICE, SERVICE_MAX_PRICE } from 'lib/constants/services'
import yup from 'lib/yupLocalised'

export default yup.object().shape({
  provision: yup
    .array()
    .of(
      yup.object().shape(
        {
          services: yup.array().when('price', {
            is: price => !isNil(price) && price >= 0,
            then: () => yup.array().of(yup.string().max(255)).required().min(1, { id: 'yup.mixed.required' }),
            otherwise: () => yup.array().of(yup.string().max(255)),
          }),
          price: yup.number().when('services', {
            is: services => !isNil(services) && services.length > 0,
            then: () =>
              yup
                .number()
                .typeError({ id: 'yup.number.typeError' })
                .nullable()
                .required()
                .priceMin(SERVICE_MIN_PRICE)
                .max(SERVICE_MAX_PRICE, { id: 'yup.number.servicePrice.max', values: { max: SERVICE_MAX_PRICE } }),
            otherwise: () =>
              yup
                .number()
                .typeError({ id: 'yup.number.typeError' })
                .nullable()
                .priceMin(SERVICE_MIN_PRICE)
                .max(SERVICE_MAX_PRICE, { id: 'yup.number.servicePrice.max', values: { max: SERVICE_MAX_PRICE } }),
          }),
        },
        [['services', 'price']],
      ),
    )
    .required()
    .min(1, { id: 'yup.mixed.required' }),
})
