import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { compose, map, equals, all, propEq } from 'ramda'
import { injectIntl } from 'react-intl'

import validationSchema from 'lib/yupLocalised/schemas/editAvailability'
import { activeLocationsSelector } from 'state/concepts/locations/selectors'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import isPresent from 'utils/isPresent'
import availabilityToFormValues from 'utils/dateAvailabilities/availabilityToFormValues'
import { selectedDays, selectedInterval } from 'utils/dateAvailabilities/interval'
import { selectedAvailabilities } from 'lib/calendar/selectionHandling'
import EditAvailabilitySidebarComponent from './component'

class EditAvailabilitySidebar extends React.Component {
  static propTypes = {
    selectedCells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    daysMapping: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])).isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetCalendar: PropTypes.func.isRequired,
    resetSelection: PropTypes.func.isRequired,
    recurring: PropTypes.bool.isRequired,
    step: PropTypes.number.isRequired,
    intl: PropTypes.shape().isRequired,
    values: PropTypes.shape().isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    availabilityMapping: PropTypes.shape().isRequired,
    updateAvailability: PropTypes.func.isRequired,
    removeAvailability: PropTypes.func.isRequired,
    isVideoCallsEnabled: PropTypes.bool.isRequired,
  }

  get daysInterval() {
    const {
      selectedCells,
      daysMapping,
      intl: { formatMessage },
      recurring,
    } = this.props

    const days = selectedDays(selectedCells, daysMapping, recurring)

    return map(formatMessage, days).join(', ')
  }

  get timeInterval() {
    const { selectedCells, step } = this.props

    return selectedInterval(selectedCells, step)
  }

  get isOnlineDisabled() {
    const {
      values: { locationIds },
    } = this.props

    return !isPresent(locationIds)
  }

  get isOnlineShown() {
    const { isVideoCallsEnabled } = this.props

    return isVideoCallsEnabled
  }

  get isRemoveShown() {
    const { selectedCells, availabilityMapping } = this.props

    const availabilities = selectedAvailabilities(selectedCells, availabilityMapping)

    return !all(propEq([], 'ids'))(availabilities)
  }

  componentDidMount = () => {
    const {
      updateAvailability,
      selectedCells,
      values: { locationIds, online },
    } = this.props

    updateAvailability(selectedCells, online, locationIds)
  }

  componentDidUpdate = ({
    selectedCells: prevSelectedCells,
    availabilityMapping: prevAvailabilityMapping,
    values: { locationIds: prevLocationIds, online: prevOnline },
  }) => {
    const {
      setFieldValue,
      values: { locationIds, online },
      availabilityMapping,
      updateAvailability,
      selectedCells,
      resetForm,
      isVideoCallsEnabled,
    } = this.props

    if (isVideoCallsEnabled) {
      if (!equals(locationIds, prevLocationIds) && !isPresent(locationIds)) {
        setFieldValue('online', true)
      }
    }

    if (!equals(availabilityMapping, prevAvailabilityMapping)) {
      resetForm()
    }

    if (!equals(locationIds, prevLocationIds) || online !== prevOnline || !equals(selectedCells, prevSelectedCells)) {
      updateAvailability(selectedCells, online, locationIds)
    }
  }

  componentWillUnmount = () => {
    const { resetSelection, resetCalendar } = this.props

    resetSelection()
    resetCalendar()
  }

  handleSave = () => {
    const { handleSubmit } = this.props

    handleSubmit()
  }

  handleRemove = () => {
    const { selectedCells, removeAvailability, resetSelection, handleSubmit } = this.props

    removeAvailability(selectedCells)
    resetSelection()
    handleSubmit()
  }

  render = () => (
    <EditAvailabilitySidebarComponent
      {...this.props}
      timeInterval={this.timeInterval}
      daysInterval={this.daysInterval}
      isOnlineDisabled={this.isOnlineDisabled}
      isOnlineShown={this.isOnlineShown}
      isRemoveShown={this.isRemoveShown}
      handleSave={this.handleSave}
      handleRemove={this.handleRemove}
    />
  )
}

const mapStateToProps = state => ({
  activeLocations: activeLocationsSelector(state),
  isVideoCallsEnabled: isEnableWorkspaceFeatureSelector(state, FEATURE_KEYS.acceptVideoCalls),
})

export { EditAvailabilitySidebar as EditAvailabilitySidebarContainer }
export default compose(
  connect(mapStateToProps),
  injectIntl,
  connect(mapStateToProps),
  withFormik({
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: availabilityToFormValues,
    handleSubmit: (_, { props: { handleSave }, ...formikBag }) => handleSave(formikBag),
  }),
)(EditAvailabilitySidebar)
