import { has } from 'ramda'
import { FormattedMessage } from 'react-intl'
import classNames from 'clsx'

import Link from 'views/shared/Link'

const link = (href, text, className) => (
  <Link href={href}>
    <a className={classNames('main-link', className)}>{has('id', text) ? <FormattedMessage {...text} /> : text}</a>
  </Link>
)

export default link
