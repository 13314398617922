import nodeCookie from 'cookie'
import browserCookie from 'component-cookie'

import isServer from 'utils/isServer'

const setCookieIsomorphic = (ctx, name, value, options) => {
  if (ctx && isServer()) {
    ctx.res.setHeader('Set-Cookie', nodeCookie.serialize(name, value, options))
  } else {
    browserCookie(name, value, options)
  }
}

export default setCookieIsomorphic
