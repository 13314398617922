const trashedFiles = {
  'trashedFiles.table.name.title': 'Name',
  'trashedFiles.table.owner.title': 'Owner',
  'trashedFiles.table.trashedAt.title': 'Trashed date',
  'trashedFiles.table.fileSize.title': 'File size',
  'trashedFiles.table.trashedAt': '{date} at {time}',
  'trashedFiles.actions.restore': 'Restore',
  'trashedFiles.actions.restore.modal.single.title': 'Restore the item',
  'trashedFiles.actions.restore.modal.single.description':
    'The parent folder of {name} has been deleted. Once restored, anyone with access to this item will lose it unless it is directly shared with them. The item will be moved to the root directory "{directionName}".',
  'trashedFiles.actions.restore.modal.multiple.title': 'Restore items',
  'trashedFiles.actions.restore.modal.multiple.description':
    'The parent folders of the selected items have been deleted. Once restored, anyone with access to these items will lose it unless they are directly shared with them. The items will be moved to the root directory “{directionName}”.',
  'trashedFiles.actions.deleteForever': 'Delete forever',
  'trashedFiles.alert.title': 'You have 30 days to restore files and folders from the trash.',
  'trashedFiles.alert.buttonText': 'Empty trash',
  'trashedFiles.emptyState.title': 'Nothing in trash',
  'trashedFiles.emptyState.description':
    'Move items you don’t need to trash. Items in trash will be deleted forever after 30 days.',
  'trashedFiles.emptyTrash.modal.title': 'Delete forever?',
  'trashedFiles.emptyTrash.modal.body':
    'All items in the trash will be deleted forever and you won’t be able to restore them.',
  'trashedFiles.emptyTrash.modal.button': 'Delete forever',
  'trashedFiles.noSearchResults': 'Try adjusting your search or filters',
  'trashedFiles.filter.pdf.label': 'PDFs',
  'trashedFiles.filter.imageAndPhoto.label': 'Images and photos',
  'trashedFiles.filter.videoAndAudio.label': 'Video and audio',
  'trashedFiles.filter.folder.label': 'Folders',
  'trashedFiles.filter.otherFile.label': 'Other files',
  'trashedFiles.filter.today.label': 'Today',
  'trashedFiles.filter.yesterday.label': 'Yesterday',
  'trashedFiles.filter.thisWeek.label': 'Earlier this week',
  'trashedFiles.filter.lastWeek.label': 'Last week',
  'trashedFiles.filter.customRange.label': 'Custom date range',
  'trashedFiles.filter.customRange.placeholder': 'Select date',
  'trashedFiles.filter.customRange.after.label': 'After',
  'trashedFiles.filter.customRange.before.label': 'Before',
  'trashedFiles.selectedFilter.today.label': 'Today',
  'trashedFiles.selectedFilter.yesterday.label': 'Yesterday',
  'trashedFiles.selectedFilter.thisWeek.label': 'Earlier this week',
  'trashedFiles.selectedFilter.lastWeek.label': 'Last week',
  'trashedFiles.selectedFilter.customRange.label': 'Custom date range',
  'trashedFiles.searchPlaceholder': 'Search in Trash',
  'trashedFiles.restoreFolderModal.title': 'This folder is in your trash',
  'trashedFiles.restoreFolderModal.description': 'To view this folder, you need to restore it from your trash.',
  'trashedFiles.actions.delete.modal.title': 'Delete forever?',
  'trashedFiles.actions.delete.modal.single.description':
    '<b>{name}</b> will be deleted forever and you won’t be able to restore it.',
  'trashedFiles.actions.delete.modal.multiple.description':
    '{count} items will be deleted forever and you won’t be able to restore them.',
}

export default trashedFiles
