import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CheckboxMixed = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path
      fill="#fff"
      strokeWidth="42.6667"
      d="M31.999 256c0-123.712 100.29-224.001 224.001-224.001h512.002c123.712 0 224.001 100.29 224.001 224.001v512.002c0 123.712-100.29 224.001-224.001 224.001h-512.002c-123.712 0-224.001-100.29-224.001-224.001v-512.002z"
    />
    <path strokeWidth="42.6667" d="M287.999 479.999h448v63.999h-448v-63.999z" />
  </svg>
)

CheckboxMixed.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CheckboxMixed.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CheckboxMixed
