import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, map, not, path, pipe, prop, sum, values } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { fetchRecurringAvailabilityEndpoint, updateRecurringAvailabilityEndpoint } from './endpoints'

const recurringAvailabilityIdsSelector = path(['recurringAvailability', 'recurringAvailabilityIds'])
const timezoneSelector = path(['recurringAvailability', 'timezone'])

export const filtersSelector = path(['recurringAvailability', 'filters'])

export const hasFiltersSelector = createSelector(filtersSelector, compose(any(compose(not, isEmpty)), values))

export const selectedTimezoneSelector = createSelector(
  [dataSelector, timezoneSelector, (_, employeeId) => employeeId],
  (data, timezone, id) => timezone || prop('formattedTimezone', build(data, 'userProfile', id)),
)

export const recurringAvailabilitiesSelector = createSelector(
  [recurringAvailabilityIdsSelector, dataSelector],
  (ids, data) => (ids && !isEmpty(ids) ? build(data, 'recurringAvailability', ids) : []),
)

export const totalSlotsDurationSelector = createSelector(
  recurringAvailabilitiesSelector,
  pipe(map(prop('duration')), sum),
)

export const recurringLoadingSelector = (state, employeeId) =>
  loadingSelector(state, fetchRecurringAvailabilityEndpoint(employeeId).endpoint)

export const recurringUpdatingSelector = (state, employeeId) =>
  loadingSelector(state, updateRecurringAvailabilityEndpoint(employeeId).endpoint)
