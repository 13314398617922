import { createLogic } from 'redux-logic'

import { fetchMyBusinessFiles, resetSelectedIds, setFilterParams } from '../actions'
import { FILTER_MY_BUSINESS_FILES } from '../types'

const filterMyBusinessFilesOperation = createLogic({
  type: FILTER_MY_BUSINESS_FILES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(fetchMyBusinessFiles(undefined, { append: false }))
    dispatch(resetSelectedIds())
    done()
  },
})

export default filterMyBusinessFilesOperation
