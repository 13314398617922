import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_RECURRING_PAYMENT } from 'state/concepts/recurringPayment/types'
import { setRecurringPaymentId } from 'state/concepts/recurringPayment/actions'
import { getRecurringPaymentEndpoint } from 'state/concepts/recurringPayment/endpoints'

const getRecurringPaymentOperation = createLogic({
  type: GET_RECURRING_PAYMENT,
  latest: true,

  async process({ action: { recurringPaymentId }, httpClient }, dispatch, done) {
    const { endpoint, url } = getRecurringPaymentEndpoint(recurringPaymentId)

    dispatch(dataApiRequest({ endpoint }))
    const params = {
      include: ['client-profile', 'recurring-transaction-items', 'recurring-transaction-items.service'],
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setRecurringPaymentId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getRecurringPaymentOperation
