export const WS_STATUSES = {
  opened: 'opened',
  closed: 'closed',
}

export const MESSAGE_TYPES = {
  welcome: 'welcome',
  ping: 'ping',
  confirmSubscription: 'confirm_subscription',
  rejectSubscription: 'reject_subscription',
}

export const COMMANDS = {
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
  message: 'message',
}
