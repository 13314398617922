import { PropTypes } from 'prop-types'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { noop } from 'lodash'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { moveToFolder as moveToFolderAction } from 'state/concepts/filesSharedWithMe/actions'
import { showModal as showModalAction } from 'state/modal/actions'
import isPresent from 'utils/isPresent'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import DirectoryBadge from './DirectoryBadge'

const MoveToFolderWarningModal = ({ myBusinessFile, selectedFolder, onSuccess, backFolder }) => {
  const showModal = useDispatchAction(showModalAction)
  const moveToFolder = useDispatchAction(moveToFolderAction)

  const isLooseAccessBody =
    isPresent(myBusinessFile.ancestorsPermissions) && !isPresent(selectedFolder.businessStoragePermissions)

  const isChangeAccessBody =
    isPresent(myBusinessFile.ancestorsPermissions) && isPresent(selectedFolder.businessStoragePermissions)

  const bodyTextId = useMemo(() => {
    if (isLooseAccessBody) {
      return 'myBusinessFiles.moveToFolderWarningModal.looseAccessBody'
    }
    if (isChangeAccessBody) {
      return 'myBusinessFiles.moveToFolderWarningModal.changeAccessBody'
    }
    return 'myBusinessFiles.moveToFolderWarningModal.giveAccessBody'
  }, [isLooseAccessBody, isChangeAccessBody])

  const onClose = () =>
    showModal({
      modalType: 'SHARED_FILES_MOVE_TO_FOLDER_MODAL',
      modalProps: { myBusinessFile, initialSelectedFolder: backFolder, onSuccess },
    })

  const handleMove = () => {
    moveToFolder(myBusinessFile.id, myBusinessFile.kind, selectedFolder.id, onSuccess)
  }

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="myBusinessFiles.moveToFolderWarningModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <div className="text-body mb-0">
            <FormattedMessage
              id={bodyTextId}
              values={{
                newDir: <DirectoryBadge icon="folder-o" text={selectedFolder.name} />,
                oldDir: <DirectoryBadge icon="folder-o" text={myBusinessFile.parent?.name} />,
              }}
            />
          </div>
        </div>
        <div className="main-modal__footer">
          <div className="d-flex justify-content-end">
            <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
            <Button text={{ id: 'shared.move' }} onClick={handleMove} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

MoveToFolderWarningModal.defaultProps = {
  onSuccess: noop,
}

MoveToFolderWarningModal.propTypes = {
  myBusinessFile: PropTypes.shape().isRequired,
  selectedFolder: PropTypes.shape().isRequired,
  backFolder: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
}

export default MoveToFolderWarningModal
