const employeeSidebar = {
  'employeeSidebar.appointments': '_(Appointments)',
  'employeeSidebar.waitlist': 'Waitlist',
  'employeeSidebar.waitlist.count': '{count} new waitlist{count, plural, one {} other {s}}',
  'employeeSidebar.chats': 'Chats',
  'employeeSidebar.availability': 'Availability',
  'employeeSidebar.regularAvailability': 'Regular availability',
  'employeeSidebar.dateSpecificAvailability': 'Date-specific',
  'employeeSidebar.clients': '_(Clients)',
  'employeeSidebar.businessFiles': 'Business files',
  'employeeSidebar.myFiles': 'My files',
  'employeeSidebar.sharedWithMeFiles': 'Shared with me',
  'employeeSidebar.filesTrash': 'Trash',
  'employeeSidebar.helpCenter': 'Help Center',
  'employeeSidebar.quickLinks': 'Quick links',
  'employeeSidebar.quickLinks.clientPortal': '_(Client) portal',
  'employeeSidebar.quickLinks.bookingLinks': 'Booking links',
}

export default employeeSidebar
