import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pathOr } from 'ramda'

import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import assignServerProviderErrorToForm from 'utils/services/assignServerProviderErrorToForm'
import redirect from 'utils/redirect'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { CREATE_SERVICE } from 'state/concepts/service/types'
import {
  serviceDetailsFormToParams,
  serviceSettingsFormToParams,
  serviceProvidersFormToParams,
} from 'utils/services/serviceFormToParams'
import { servicesRoute } from 'lib/apiRoutes'
import { formsUuidsByIdsSelector } from 'state/concepts/services/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsServicesRoute } from 'lib/routes'
import isPresent from 'utils/isPresent'

const createServiceOperation = createLogic({
  type: CREATE_SERVICE,
  latest: true,

  async process({ action: { values, form, redirectPath, onError }, httpClient, getState }, dispatch, done) {
    const {
      details,
      settings,
      status,
      providers,
      documents: { selectedIntakeFormIds, selectedAgreementIds },
    } = values
    const groupedProvision = providers.provision
    const provision = serviceProvidersFormToParams(groupedProvision)
    const selectedFormIds = [...selectedIntakeFormIds, ...selectedAgreementIds]

    try {
      const state = getState()
      const params = {
        ...serviceDetailsFormToParams(details),
        form_uuids: formsUuidsByIdsSelector(state, selectedFormIds),
        status,
        service_setting: serviceSettingsFormToParams(settings),
        provision,
        include: 'service-setting',
      }

      const { data } = await httpClient.post(servicesRoute, params)
      const response = normalize(data)
      const href = redirectPath || companySettingsServicesRoute

      dispatch(dataApiSuccess({ response }))
      redirect({ href, workspace: currentWorkspaceCodeSelector(state) })
      dispatch(showNotification({ messageObject: { id: 'notifications.serviceCreated' } }))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        error.response.data.errors = assignServerProviderErrorToForm(error, provision, groupedProvision)
      }

      const errors = pathOr(null, ['response', 'data', 'errors'], error)
      const formattedErrors = formatJsonApiErrors(errors)

      requestErrorHandler({ error, dispatch, form })
      if (isPresent(formattedErrors?.name)) {
        onError?.(values)
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.createService.nameIsAlreadyUsed' },
            kind: 'error',
          }),
        )
      }
    }

    form.setSubmitting(false)
    done()
  },
})

export default createServiceOperation
