import { createLogic } from 'redux-logic'

import { fetchFilesSharedWithMe, resetSelectedIds, setFilterParams } from '../actions'
import { FILTER_FILES_SHARED_WITH_ME } from '../types'

const filterFilesSharedWithMe = createLogic({
  type: FILTER_FILES_SHARED_WITH_ME,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(fetchFilesSharedWithMe(undefined, { append: false }))
    dispatch(resetSelectedIds())
    done()
  },
})

export default filterFilesSharedWithMe
