import endpoint from 'utils/endpoint'
import {
  bookingRoute,
  bookingsRoute,
  bookingAvailabilityRoute,
  bookingsRescheduleRoute,
  bookingsVideoRecordRoute,
  bookingAcceptRoute,
  bookingDeclineRoute,
  bookingsRecurrencesRoute,
  declineAllRecurringBookingsRoute,
  rescheduleRecurringBookingsRoute,
  bookingChatHistoryRoute,
  inCallChatRoute,
} from 'lib/apiRoutes'
import {
  GET_BOOKING,
  SET_CLIENT_BOOKING_AVAILABILITY,
  CLIENT_RESCHEDULE_BOOKING,
  REMOVE_VIDEO_RECORD,
  REMOVE_CHAT_HISTORY,
  SCHEDULE_BOOKING,
  ACCEPT_BOOKING,
  DECLINE_BOOKING,
  UPDATE_PENDING_BOOKING,
  DECLINE_ALL_RECURRING_BOOKINGS,
  RESCHEDULE_RECURRING_BOOKINGS,
  FETCH_IN_CALL_CHAT,
} from './types'

export const getBookingEndpoint = bookingId => endpoint(GET_BOOKING, 'GET', bookingRoute(bookingId))

export const createBookingEndpoint = endpoint(SCHEDULE_BOOKING, 'POST', bookingsRoute)

export const createRecurringBookingsEndpoint = endpoint(SCHEDULE_BOOKING, 'POST', bookingsRecurrencesRoute)

export const updateBookingEndpoint = uniqCode => endpoint(UPDATE_PENDING_BOOKING, 'PUT', bookingRoute(uniqCode))

export const clientBookingAvailabilityEndpoint = bookingId =>
  endpoint(SET_CLIENT_BOOKING_AVAILABILITY, 'GET', bookingAvailabilityRoute(bookingId))

export const rescheduleBookingEndpoint = bookingId =>
  endpoint(CLIENT_RESCHEDULE_BOOKING, 'POST', bookingsRescheduleRoute(bookingId))

export const removeVideoRecordEndpoint = videoRecordId =>
  endpoint(REMOVE_VIDEO_RECORD, 'DELETE', bookingsVideoRecordRoute(videoRecordId))

export const removeChatHistoryEndpoint = bookingUniqCode =>
  endpoint(REMOVE_CHAT_HISTORY, 'DELETE', bookingChatHistoryRoute(bookingUniqCode))

export const acceptBookingEndpoint = bookingId => endpoint(ACCEPT_BOOKING, 'POST', bookingAcceptRoute(bookingId))

export const declineBookingEndpoint = bookingId => endpoint(DECLINE_BOOKING, 'POST', bookingDeclineRoute(bookingId))

export const declineAllRecurringBookingsEndpoint = bookingUniqCode =>
  endpoint(DECLINE_ALL_RECURRING_BOOKINGS, 'POST', declineAllRecurringBookingsRoute(bookingUniqCode))

export const rescheduleRecurringBookingsEndpoint = bookingId =>
  endpoint(RESCHEDULE_RECURRING_BOOKINGS, 'POST', rescheduleRecurringBookingsRoute(bookingId))

export const fetchInCallChatEndpoint = id => endpoint(FETCH_IN_CALL_CHAT, 'GET', inCallChatRoute(id))
