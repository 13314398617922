import isPresent from 'utils/isPresent'

export const CLIENT_CONTACTS_TABS = {
  existingClient: 'existingClient',
  existingContact: 'existingContact',
  newContact: 'newContact',
}

export const RESPONSIBLE_FOR_BILLING_PERMISSIONS = ['viewBillings', 'bookAppointment', 'manageAppointments']

export const CLIENT_CONTACT_TYPE = 'clientContact'

export const TOTAL_EXPIRY_DAYS = 7

export const previousComparePredicate = prev => !isPresent(prev)

export const CLIENT_CONTACT_PERMISSIONS = {
  accessClientPortal: 'accessClientPortal',
  bookAppointment: 'bookAppointment',
  leaveReviews: 'leaveReviews',
  manageAppointments: 'manageAppointments',
  manageDocuments: 'manageDocuments',
  receiveAppointmentNotifications: 'receiveAppointmentNotifications',
  viewBillings: 'viewBillings',
  viewDocuments: 'viewDocuments',
}
