import { createLogic } from 'redux-logic'

import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { clientContactAccountDeletionConfirmationCodeRoute } from 'lib/apiRoutes'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { SEND_DELETE_CONFIRMATION_CODE } from '../types'

const sendDeleteConfirmationCodeOperation = createLogic({
  type: SEND_DELETE_CONFIRMATION_CODE,
  latest: true,

  async process({ action: { form, onSuccess, currentContact }, getState, httpClient }, dispatch, done) {
    try {
      const { uniqCode } = workspaceSelector(getState())

      await httpClient.post(
        clientContactAccountDeletionConfirmationCodeRoute(currentContact.id),
        {},
        buildCustomBaseUrl(['workspaces', uniqCode]),
      )

      onSuccess?.()
    } catch (error) {
      if (form && isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }

    form?.setSubmitting(false)
    done()
  },
})

export default sendDeleteConfirmationCodeOperation
