import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import OptionsVertical from 'views/shared/icons/OptionsVertical/component'
import UserAltO from 'views/shared/icons/UserAltO'
import isPresent from 'utils/isPresent'
import Member from '../Member'

const Members = ({ members, moreMembers, toggleMembersTruncate }) => {
  if (!isPresent(members)) {
    return null
  }

  const visibleMembers = moreMembers ? members : members.slice(0, 2)

  return (
    <div className="d-flex gap-16">
      <div className="w-20 text-center">
        <UserAltO size={15} color="#6E798D" />
      </div>
      <div className="flex-1 overflow-x-hidden">
        <p className="in-blue-gray-300 mb-8">
          <FormattedMessage id="calendarEvents.members" values={{ count: members.length }} />
        </p>
        <div className="d-flex flex-column gap-4">
          {visibleMembers.map((member, index) => (
            <Member
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              member={member}
            />
          ))}
          <div>
            {members.length > 2 && (
              <button className="d-flex" onClick={toggleMembersTruncate}>
                <OptionsVertical size={12} className="in-blue-600 mt-2" />
                <span className="ml-8 in-blue-600">
                  {moreMembers ? (
                    <FormattedMessage id="shared.showLess" values={{ count: members.length }} />
                  ) : (
                    <FormattedMessage id="calendarEvents.showAllMembers" values={{ count: members.length }} />
                  )}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Members.defaultProps = {
  moreMembers: false,
  toggleMembersTruncate: undefined,
}

Members.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  moreMembers: PropTypes.bool,
  toggleMembersTruncate: PropTypes.func,
}

export default Members
