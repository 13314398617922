import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { CLEAR_AUTO_SAVE_RESPONSES } from 'state/concepts/form/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setFormSubmissionId } from '../actions'
import { clearSaveResponsesEndpoint } from '../endpoints'

const autoSaveResponsesOperation = createLogic({
  type: CLEAR_AUTO_SAVE_RESPONSES,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = clearSaveResponsesEndpoint

    const params = {
      client_profile_form_id: id,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data) }))
      dispatch(setFormSubmissionId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default autoSaveResponsesOperation
