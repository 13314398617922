import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { path } from 'ramda'

import { removeEmployeeInvitation } from 'state/concepts/employees/actions'
import { loadingSelector } from 'state/data/selectors'
import { removeEmployeeInvitationEndpoint } from 'state/concepts/employees/endpoints'
import EmployeesRemoveInvitationModalComponent from './component'

class EmployeesRemoveInvitationModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    employee: PropTypes.shape().isRequired,
  }

  handleSubmit = () => {
    const {
      onSubmit,
      employee: { id },
    } = this.props
    onSubmit(id)
  }

  render() {
    return <EmployeesRemoveInvitationModalComponent {...this.props} onSubmit={this.handleSubmit} />
  }
}

const makeMapStateToProps = (_initialState, initialProps) => {
  const { endpoint } = removeEmployeeInvitationEndpoint(path(['employee', 'id'], initialProps))

  return state => ({
    isLoading: loadingSelector(state, endpoint),
  })
}

const mapDispatchToProps = {
  onSubmit: removeEmployeeInvitation,
}

export { EmployeesRemoveInvitationModal as EmployeesRemoveInvitationModalContainer }
export default connect(makeMapStateToProps, mapDispatchToProps)(EmployeesRemoveInvitationModal)
