import { combineReducers } from '@reduxjs/toolkit'

import { SET_REVIEW_ID } from './types'

const reviewId = (state = '', action) => {
  switch (action.type) {
    case SET_REVIEW_ID:
      return action.reviewId
    default:
      return state
  }
}

export default combineReducers({
  reviewId,
})
