const endpoint = (feature, method, route, namespace) => {
  const name = namespace ? `/${namespace}` : ''

  return {
    endpoint: `${feature}${name} ${method} ${route}`,
    url: route,
  }
}

export default endpoint
