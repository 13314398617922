import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hideModal as hideModalAction } from 'state/modal/actions'
import ConfirmModal from 'views/shared/ConfirmModal'

class ChatUnavailableModal extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
  }

  handleClose = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render = () => (
    <ConfirmModal
      {...this.props}
      modalClassName="main-modal--md"
      onClose={this.handleClose}
      onSubmit={this.handleClose}
      submitText={{ id: 'shared.ok' }}
      isFooterCloseButton={false}
    />
  )
}

const mapDispatchToProps = {
  hideModal: hideModalAction,
}

export { ChatUnavailableModal as ChatUnavailableModalContainer }
export default connect(null, mapDispatchToProps)(ChatUnavailableModal)
