import React from 'react'
import classNames from 'clsx'
import PropTypes from 'prop-types'
import { RemoveScroll } from 'react-remove-scroll'

import stopPropagation from 'utils/stopPropagation'
import Dropdown from 'views/shared/Dropdown'
import isPresent from 'utils/isPresent'

const DropdownMenuComponent = ({
  onVisibleChange,
  visible,
  menuClassName,
  isHidden,
  items,
  shouldStopPropagation,
  mainDropdownContainerClassName,
  ...restProps
}) => {
  if (isHidden || !isPresent(items)) {
    return null
  }

  return (
    <div
      id="dropdown"
      className={classNames('main-dropdown__container', mainDropdownContainerClassName)}
      role="button"
      data-cy="three-dots-menu"
      onClick={shouldStopPropagation ? stopPropagation : undefined}
    >
      <RemoveScroll enabled={visible}>
        <Dropdown
          onOpenChange={onVisibleChange}
          open={visible}
          menu={{ items, className: menuClassName }}
          {...restProps}
        />
      </RemoveScroll>
    </div>
  )
}

DropdownMenuComponent.defaultProps = {
  menuClassName: null,
  mainDropdownContainerClassName: null,
  isHidden: false,
  shouldStopPropagation: false,
}

DropdownMenuComponent.propTypes = {
  onVisibleChange: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  menuClassName: PropTypes.string,
  mainDropdownContainerClassName: PropTypes.string,
  isHidden: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  shouldStopPropagation: PropTypes.bool,
}

export default DropdownMenuComponent
