import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const PromocodeModal = ({ title, label, onClose, isSubmitDisabled }) => (
  <Modal className="main-modal" onClose={onClose}>
    <Form>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage {...title} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body pb-0">
          <Field
            className="mb-0 mr-12"
            name="code"
            component={InputField}
            id="code"
            type="text"
            icon="close"
            label={label}
          />
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="mr-16"
              type="button"
              onClick={onClose}
              text={{ id: 'billing.promocode.modal.cancel' }}
              kind="flat"
              dataCy="cancel-add-applied-promocode"
            />
            <SubmitFormButton
              text={{ id: 'billing.promocode.modal.apply' }}
              disabled={isSubmitDisabled}
              dataCy="add-applied-promocode"
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

PromocodeModal.propTypes = {
  title: PropTypes.shape().isRequired,
  label: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
}

export default PromocodeModal
