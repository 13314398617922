import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useFormikContext } from 'formik'

import { formattedShortDate, formattedTime } from 'utils/dateTime'
import Button from 'views/shared/Button'
import CrossUnrounded from 'views/shared/icons/CrossUnrounded'
import { getBlobFromDataUrl } from 'utils/file'

const DrawSignature = ({ currentDate, defaultDisplayedName }) => {
  const { setFieldValue, values } = useFormikContext()
  const signatureCanvasRef = useRef(null)

  const handleEnd = async () => {
    const blob = await getBlobFromDataUrl(signatureCanvasRef.current?.toDataURL('image/png'))
    blob.name = 'drawn_signature.png'

    setFieldValue('signatureDraw', blob)
  }
  const isSignatureEmpty = signatureCanvasRef.current?.isEmpty()
  const handleClear = () => {
    signatureCanvasRef.current?.clear()
    setFieldValue('signatureDraw', null)
  }

  return (
    <>
      <div className="signature p-16 mt-16">
        <div className="signature-canvas mb-16">
          <ReactSignatureCanvas
            ref={signatureCanvasRef}
            onEnd={handleEnd}
            penColor="black"
            maxWidth={1.5}
            minWidth={1}
            minDistance={1}
            canvasProps={{
              width: 422,
              height: 122,
              className: 'signature-canvas__canvas',
            }}
          />
          <div className="d-flex align-items-center justify-content-center wh-24-24 signature-canvas__icon">
            <CrossUnrounded size={12} className="in-disabled-icon" />
          </div>
          {isSignatureEmpty && (
            <p className="text-subheader in-gray-500 text-center pe-none full-width mb-0 signature-canvas__placeholder">
              <FormattedMessage id="eSignature.eSignatureDisclosure.drawSignature.signHere" />
            </p>
          )}
        </div>
        <p className="text-body mb-0 in-blue-gray-300">
          <FormattedMessage
            id="etherpadDocuments.document.signedBy"
            values={{
              fullName: values.name || defaultDisplayedName,
            }}
          />
        </p>
        <p className="text-body mb-0 in-blue-gray-300 mt-2">
          <FormattedMessage
            id="etherpadDocuments.document.signedBy.date"
            values={{
              date: `${formattedShortDate(currentDate)} ${formattedTime(currentDate)}`,
            }}
          />
        </p>
      </div>
      <div className="d-flex column-gap-16 mt-16">
        <Button
          kind="secondary"
          size="small"
          text={{ id: 'eSignature.eSignatureDisclosure.drawSignature.clear' }}
          disabled={isSignatureEmpty}
          onClick={handleClear}
        />
      </div>
    </>
  )
}

DrawSignature.propTypes = {
  currentDate: PropTypes.string.isRequired,
  defaultDisplayedName: PropTypes.string.isRequired,
}

export default DrawSignature
