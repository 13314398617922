import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingPaymentsRoute } from 'lib/routes'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { updatePaymentValuesToParams } from 'utils/payments'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { UPDATE_PAYMENT } from '../types'
import { updatePaymentEndpoint } from '../endpoints'

const updatePaymentOperation = createLogic({
  type: UPDATE_PAYMENT,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updatePaymentEndpoint(values.id)
    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, updatePaymentValuesToParams(values))

      dispatch(dataApiSuccess({ endpoint }))
      redirect({
        href: companyBillingPaymentsRoute,
        workspace: currentWorkspaceCodeSelector(getState()),
        skipDiscard: true,
      })
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.updatePayment',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updatePaymentOperation
