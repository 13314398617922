import getEmployees from './getEmployees'
import getClients from './getClients'
import getServices from './getServices'
import getClientServices from './getClientServices'
import getClientConsultants from './getClientConsultants'
import getContactClientProfiles from './getContactClientProfiles'

export default [
  getEmployees,
  getClients,
  getServices,
  getClientServices,
  getClientConsultants,
  getContactClientProfiles,
]
