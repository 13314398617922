import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import { prepareEditorValue } from 'utils/reactDraftEditor'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_AGREEMENT } from 'state/concepts/agreement/types'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { updateAgreementEndpoint } from 'state/concepts/agreement/endpoints'
import { companySettingsAgreementsRoute } from 'lib/routes'

const updateAgreementOperation = createLogic({
  type: UPDATE_AGREEMENT,
  latest: true,

  async process({ action: { form, values, agreementId }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateAgreementEndpoint(agreementId)

    try {
      await httpClient.put(url, {
        name: values.name,
        description: prepareEditorValue(values.description),
        status: values.status,
      })

      dispatch(dataApiSuccess({ endpoint }))
      redirect({ href: companySettingsAgreementsRoute, workspace: currentWorkspaceCodeSelector(getState()) })
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.agreementUpdated',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default updateAgreementOperation
