import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import {
  clientBookingReviewSuccessRoute,
  clientBookingReviewNotAbleToSubmitRoute,
  clientBookingRoute,
} from 'lib/routes'
import { bookingIdSelector } from 'state/concepts/booking/selectors'
import { createClientReviewEndpoint } from 'state/concepts/clientAccountReview/endpoints'
import { CREATE_REVIEW } from 'state/concepts/clientAccountReview/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'

const createReviewOperation = createLogic({
  type: CREATE_REVIEW,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const state = getState()
    const bookingId = bookingIdSelector(state)
    const { workspaceCode, token, values, bookingUniqCode, form } = action
    const { endpoint, url } = createClientReviewEndpoint

    const params = {
      booking_id: bookingId,
      rating: values.rating,
      text: values.description,
    }
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    dispatch(dataApiRequest({ endpoint }))

    try {
      if (token) {
        await httpClient.post(url, params, { headers: { Authorization: token }, ...config })

        dispatch(dataApiSuccess({ endpoint }))

        redirect({ href: clientBookingReviewSuccessRoute(workspaceCode, bookingUniqCode) })
      } else {
        await httpClient.post(url, params, config)

        dispatch(dataApiSuccess({ endpoint }))

        await redirect({ href: clientBookingRoute(workspaceCode, bookingUniqCode) })

        dispatch(
          showNotification({
            messageObject: { id: 'clientReview.form.successNotification' },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)

      if (isErrorStatusUnprocessableEntity(error)) {
        const { base } = formatJsonApiErrors(error.response.data.errors)

        if (base) {
          if (token) {
            redirect({ href: clientBookingReviewNotAbleToSubmitRoute(workspaceCode, bookingUniqCode) })
          } else {
            await redirect({ href: clientBookingRoute(workspaceCode, bookingUniqCode) })

            dispatch(showNotification({ messageObject: base, kind: 'error' }))
          }
        }
      }
    }
    done()
  },
})

export default createReviewOperation
