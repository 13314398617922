import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import { fromFormattedTimezone } from 'utils/timezone'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_RECURRING_AVAILABILITY } from 'state/concepts/recurringAvailability/types'
import { fetchRecurringAvailabilityEndpoint } from 'state/concepts/recurringAvailability/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setRecurringAvailability, setTimezone } from 'state/concepts/recurringAvailability/actions'
import { selectedTimezoneSelector } from 'state/concepts/recurringAvailability/selectors'
import { employeeSelector } from 'state/concepts/employees/selectors'
import { timezonesSelector } from 'state/concepts/timezones/selectors'

const fetchRecurringAvailabilityOperation = createLogic({
  type: FETCH_RECURRING_AVAILABILITY,
  latest: true,

  async process({ httpClient, getState, action: { userId, needToSetEmployeeTimezone } }, dispatch, done) {
    const { endpoint, url } = fetchRecurringAvailabilityEndpoint(userId)
    const state = getState()
    const employee = employeeSelector(state, userId)
    const selectedTimezone = selectedTimezoneSelector(state, userId)
    const timezones = timezonesSelector(state)
    const employeeTimezone = prop('formattedTimezone', employee)

    const formattedTimezone = needToSetEmployeeTimezone ? employeeTimezone : selectedTimezone
    const timezone = fromFormattedTimezone(formattedTimezone, timezones)

    const params = {
      filter: {
        timezone,
      },
      include: 'locations',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setRecurringAvailability(map(prop('id'), data.data)))
      if (needToSetEmployeeTimezone) {
        dispatch(setTimezone(employeeTimezone))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchRecurringAvailabilityOperation
