import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addSearchResults, resetSearchResults, setBlankState } from 'state/searchResults/actions'
import { GET_CONTACT_CLIENT_PROFILES } from 'state/concepts/filters/types'
import { getClientContactsEndpoint, getContactClientProfilesEndpoint } from 'state/concepts/filters/endpoints'

const getContactClientProfilesOperation = createLogic({
  type: GET_CONTACT_CLIENT_PROFILES,

  async process({ httpClient, action: { searchQuery, page, resetResults, status, clientId } }, dispatch, done) {
    const searchResultsKey =
      status === 'active' ? SEARCH_RESULTS_ENTITIES.contactClientProfiles : SEARCH_RESULTS_ENTITIES.clientContacts
    const { endpoint, url } =
      searchResultsKey === SEARCH_RESULTS_ENTITIES.contactClientProfiles
        ? getContactClientProfilesEndpoint
        : getClientContactsEndpoint

    const params = {
      page: {
        size: 20,
        number: page || 1,
      },
      filter: {
        name: searchQuery,
        status,
      },
      client_profile_id: clientId,
    }

    dispatch(dataApiRequest({ endpoint }))
    if (resetResults) {
      dispatch(resetSearchResults(searchResultsKey))
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const ids = data.data.map(item => item.id)

      dispatch(
        setBlankState({
          [searchResultsKey]: isBlankState({
            params: { name: searchQuery },
            data: data.data,
          }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(addSearchResults({ [searchResultsKey]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getContactClientProfilesOperation
