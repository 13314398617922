import { combineReducers } from '@reduxjs/toolkit'
import { uniq, omit, without } from 'ramda'

import {
  SET_GOOGLE_CALENDAR_CONNECT_LINK,
  REMOVE_GOOGLE_CALENDAR_CONNECT_LINK,
  SET_GOOGLE_CALENDAR_CONNECTION_IDS,
  ADD_GOOGLE_CALENDAR_CONNECTION_IDS,
  REMOVE_GOOGLE_CALENDAR_CONNECTION_IDS,
  ADD_OUTLOOK_CALENDAR_CONNECTION_IDS,
  SET_OUTLOOK_CALENDAR_CONNECT_LINK,
  REMOVE_OUTLOOK_CALENDAR_CONNECT_LINK,
  REMOVE_OUTLOOK_CALENDAR_CONNECTION_IDS,
  SET_OUTLOOK_CALENDAR_CONNECTION_IDS,
  SET_CALENDAR_INTEGRATION_ID,
  SET_APPLE_CALENDAR_CONNECTION_IDS,
  ADD_APPLE_CALENDAR_CONNECTION_IDS,
  REMOVE_APPLE_CALENDAR_CONNECTION_IDS,
} from './types'

const googleCalendarConnectLink = (state = null, action) => {
  switch (action.type) {
    case SET_GOOGLE_CALENDAR_CONNECT_LINK:
      return { ...state, [action.id]: action.link }
    case REMOVE_GOOGLE_CALENDAR_CONNECT_LINK:
      return omit([action.id], state)
    default:
      return state
  }
}

const outlookCalendarConnectLink = (state = null, action) => {
  switch (action.type) {
    case SET_OUTLOOK_CALENDAR_CONNECT_LINK:
      return { ...state, [action.id]: action.link }
    case REMOVE_OUTLOOK_CALENDAR_CONNECT_LINK:
      return omit([action.id], state)
    default:
      return state
  }
}

const googleCalendarConnectionIds = (state = [], action) => {
  switch (action.type) {
    case SET_GOOGLE_CALENDAR_CONNECTION_IDS:
      return action.ids
    case ADD_GOOGLE_CALENDAR_CONNECTION_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_GOOGLE_CALENDAR_CONNECTION_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const appleCalendarConnectionIds = (state = [], action) => {
  switch (action.type) {
    case SET_APPLE_CALENDAR_CONNECTION_IDS:
      return action.ids
    case ADD_APPLE_CALENDAR_CONNECTION_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_APPLE_CALENDAR_CONNECTION_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const outlookCalendarConnectionIds = (state = [], action) => {
  switch (action.type) {
    case SET_OUTLOOK_CALENDAR_CONNECTION_IDS:
      return action.ids
    case ADD_OUTLOOK_CALENDAR_CONNECTION_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_OUTLOOK_CALENDAR_CONNECTION_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const calendarIntegrationId = (state = null, action) => {
  switch (action.type) {
    case SET_CALENDAR_INTEGRATION_ID:
      return action.id
    default:
      return state
  }
}

const employeeReducer = combineReducers({
  googleCalendarConnectLink,
  googleCalendarConnectionIds,
  outlookCalendarConnectionIds,
  outlookCalendarConnectLink,
  calendarIntegrationId,
  appleCalendarConnectionIds,
})

export default employeeReducer
