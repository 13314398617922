const recurringPayment = {
  'recurringPayment.header.title': 'Recurring payment',
  'recurringPayment.header.action.sendRequest': 'Send payment',

  'recurringPayment.edit': 'Edit',
  'recurringPayment.cancel': 'Cancel',
  'recurringPayment.delete': 'Delete',

  'recurringPayment.status.active': 'Active',
  'recurringPayment.status.draft': 'Draft',
  'recurringPayment.status.cancelled': 'Cancelled',
  'recurringPayment.status.paid': 'Paid',

  'recurringPayment.details.billingCycle': 'Billing cycle',
  'recurringPayment.details.requestSent': 'Request sent',
  'recurringPayment.details.numberOfSent': '{sentCount} of {totalCount} sent',
  'recurringPayment.details.nextRequest': 'Next request',
  'recurringPayment.details.autoPay': 'Auto-pay',
  'recurringPayment.details.autoPay.enabled': 'Enabled',
  'recurringPayment.details.autoPay.disabled': 'Disabled',

  'recurringPayment.deleteDraft.confirmModal.title': 'Delete draft recurring payment?',
  'recurringPayment.deleteDraft.confirmModal.body': 'Once deleted, it cannot be restored.',
  'recurringPayment.cancel.confirmModal.title': 'Cancel recurring payment?',
  'recurringPayment.cancel.confirmModal.body':
    'Payments will not be issued anymore, and Payments that has status “Pending” and “Overdue” now will be canceled',
  'recurringPayment.cancel.confirmModal.submit': 'Cancel payment',
}

export default recurringPayment
