import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { UPDATE_MESSAGE } from 'state/concepts/chat/types'
import { updateChatMessageEndpoint } from '../endpoints'

const updateMessageOperation = createLogic({
  type: UPDATE_MESSAGE,
  latest: true,

  async process({ httpClient, action: { messageId, text } }, dispatch, done) {
    const { url, endpoint } = updateChatMessageEndpoint(messageId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.put(url, { text })

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default updateMessageOperation
