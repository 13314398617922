import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const LoadInvoice = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M69.818 0c-25.67 0-46.545 20.875-46.545 46.545v372.363c0 25.67 20.875 46.546 46.545 46.546H174.59c-6.074-14.499-9.834-30.185-11.091-46.546H69.817V46.545h279.272v171.137c14.382-5.19 29.696-8.065 45.545-8.182V39.954c.536-7.657-2.185-15.475-7.864-22.363C379.741 9.096 366.148 0 349.089 0H69.817zM208.59 84.954v33.227c-7.889 1.001-52.046 9.296-52.046 62.591 0 72.797 70.516 57.305 79.546 97.59 12.055-7.308 25.241-12.707 39.181-16.454-15.755-46.452-74.749-41.252-74.772-80.909 0-26.228 14.427-27.046 17.546-27.046 5.702 0 19.273 5.001 19.273 36.954h44c0-61.137-41.803-70.671-49.273-72.091V84.952h-23.454zM395.636 256c-34.279 0-64.356 18.781-80.5 46.636-.326-.002-.628-.091-.954-.091-57.563 0-104.728 47.164-104.728 104.728S256.618 512 314.182 512c45.928 0 84.803-30.215 98.818-71.591 42.919-8.224 75.727-46.12 75.727-91.317 0-51.137-41.954-93.091-93.091-93.091zm-246.409 19.91c0 36.469 15.214 54.51 30.318 63.772 5.958-11.8 13.069-22.884 21.773-32.635-4.445-5.283-8.091-14.404-8.091-31.137h-44zm246.409 26.636c25.988 0 46.546 20.557 46.546 46.546 0 18.127-10.128 33.426-25 41.137-5.366-32.219-25.537-59.257-53.274-74.454 8.31-7.94 19.176-13.228 31.728-13.228zm-81.454 46.545c32.409 0 58.182 25.772 58.182 58.182s-25.772 58.182-58.182 58.182c-32.409 0-58.182-25.772-58.182-58.182s25.772-58.182 58.182-58.182z" />
  </svg>
)

LoadInvoice.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'load-invoice-icon',
}

LoadInvoice.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default LoadInvoice
