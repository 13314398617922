import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingRecurringPaymentsRoute } from 'lib/routes'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { RECURRING_PAYMENT_STATUSES } from 'lib/constants/recurringPayments'
import { requestRecurringPaymentValuesToParams } from 'utils/recurringPayments'
import { REQUEST_RECURRING_PAYMENT } from '../types'
import { requestRecurringPaymentEndpoint, saveDraftRecurringPaymentEndpoint } from '../endpoints'

const requestRecurringPaymentOperation = createLogic({
  type: REQUEST_RECURRING_PAYMENT,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const isDraft = values.status === RECURRING_PAYMENT_STATUSES.draft
    const apiEndpoint = isDraft ? saveDraftRecurringPaymentEndpoint : requestRecurringPaymentEndpoint
    const { url, endpoint } = apiEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.post(url, requestRecurringPaymentValuesToParams(values))

      dispatch(dataApiSuccess({ endpoint }))
      redirect({
        href: companyBillingRecurringPaymentsRoute,
        workspace: currentWorkspaceCodeSelector(getState()),
        skipDiscard: true,
      })
      dispatch(
        showNotification({
          messageObject: {
            id: isDraft ? 'notifications.saveAsDraftRecurring' : 'notifications.requestRecurringPayment',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default requestRecurringPaymentOperation
