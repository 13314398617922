import { any, pathOr, propEq } from 'ramda'
import { createLogic } from 'redux-logic'

import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import requestErrorHandler from 'lib/requestErrorHandler'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { connectGoogleMeetProviderEndpoint } from '../endpoints'
import { CONNECT_GOOGLE_MEET_PROVIDER } from '../types'

const connectGoogleMeetProvider = createLogic({
  type: CONNECT_GOOGLE_MEET_PROVIDER,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { id } = currentUserProfileSelector(state)

    const { url, endpoint } = connectGoogleMeetProviderEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)
      const response = updateDataHelper(state.data, 'userProfile', id, {
        attributes: {
          videoCallProvider: VIDEO_CALLS_PROVIDERS.googleMeet,
        },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.googleVideoProviderConnected',
          },
        }),
      )
    } catch (error) {
      let messageObject = { id: 'notifications.googleVideoProviderConnectionFailed' }
      const errors = pathOr([], ['response', 'data', 'errors'], error)

      if (any(propEq({ type: 'no_calendar_sync' }, 'meta'), errors)) {
        messageObject = { id: 'notifications.googleVideoProviderConnectionFailed.noCalendarSync' }
      }

      if (any(propEq({ type: 'not_default_integration_type' }, 'meta'), errors)) {
        messageObject = { id: 'notifications.googleVideoProviderConnectionFailed.notDefaultIntegrationType' }
      }

      requestErrorHandler({ error, dispatch, endpoint })
      dispatch(showNotification({ messageObject, kind: 'error' }))
    }

    done()
  },
})

export default connectGoogleMeetProvider
