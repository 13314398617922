import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck, prop } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import waitForElement from 'utils/waitForElement'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_MESSAGES } from 'state/concepts/chat/types'
import { fetchChatMessageEndpoint } from 'state/concepts/chat/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import {
  lastReadMessageSelector,
  firstUnreadMessageSelector,
  lastMessageIdSelector,
} from 'state/concepts/chat/selectors'
import { setMessages, setUnreadIndicatorVisibility } from 'state/concepts/chat/actions'
import { endpointMetaSelector } from 'state/data/selectors'

const fetchMessageOperation = createLogic({
  type: FETCH_MESSAGES,
  latest: true,

  async process({ action: { chatId, pageParams, isScrollIntoView }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = fetchChatMessageEndpoint(chatId)

    const params = {
      page: {
        ...pageParams,
      },
      include: ['chat-user', 'attachments', 'meta-member', 'meta-performer'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      // Specific order of dispatches below is intentional
      // and used for hacky scroll conditions in MessageList container
      dispatch(setMessages(pluck('id', data.data)))
      dispatch(dataApiSuccess({ response, endpoint }))

      const lastReadMessage = lastReadMessageSelector(getState())
      const firstUnreadMessage = firstUnreadMessageSelector(getState())

      const messageToScroll = lastReadMessage || firstUnreadMessage

      if (messageToScroll && isScrollIntoView) {
        const element = await waitForElement(`#message-${messageToScroll.id}`)

        dispatch(setUnreadIndicatorVisibility(true))
        element?.scrollIntoView()
      }

      const lastMessageId = lastMessageIdSelector(getState())

      if (lastMessageId) {
        const requestMeta = endpointMetaSelector(getState(), endpoint)
        const updateResponse = updateDataHelper(getState().data, 'chat', [chatId], {
          attributes: { unreadMessagesCount: prop('unreadCount', requestMeta) },
          relationships: {
            message: {
              data: { id: lastMessageId, type: 'message' },
            },
          },
        })

        dispatch(dataApiSuccess({ response: updateResponse }))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchMessageOperation
