import * as types from './types'

export const setSearchResults = searchParams => ({
  type: types.SET_SEARCH_RESULTS,
  searchParams,
})

export const addSearchResults = searchParams => ({
  type: types.ADD_SEARCH_RESULTS,
  searchParams,
})

export const resetSearchResults = resetPath => ({
  type: types.RESET_SEARCH_RESULTS,
  resetPath,
})

export const setBlankState = isBlankState => ({
  type: types.SET_SEARCH_RESULTS_BLANK_STATE,
  isBlankState,
})
