import { createLogic } from 'redux-logic'

import { isErrorStatusForbidden, isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { ALERT_KINDS } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import { downloadBusinessFilesEndpoint } from '../endpoints'
import { addFilesDownload, addPendingFilesDownload } from '../actions'
import { DOWNLOAD_BUSINESS_FILES } from '../types'
import { isLoadingDownloadSelector } from '../selectors'

const downloadBusinessFilesOperation = createLogic({
  type: DOWNLOAD_BUSINESS_FILES,
  latest: true,

  async process({ httpClient, getState, action: { fileIds } }, dispatch, done) {
    const { url } = downloadBusinessFilesEndpoint

    const isLoadingDownload = isLoadingDownloadSelector(getState())

    if (isLoadingDownload) {
      dispatch(addPendingFilesDownload(fileIds))
      done()
      return
    }

    try {
      const { data } = await httpClient.post(url, { business_storage_ids: fileIds })
      dispatch(addFilesDownload(data.data.id, fileIds))
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      }
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors
        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
      }
      requestErrorHandler({ error, dispatch, excludeErrorArray: [403, 422] })
    }
    done()
  },
})

export default downloadBusinessFilesOperation
