import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { buildUpdateOptionsParams } from 'utils/customField'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_CUSTOM_FIELD } from 'state/concepts/customFields/types'
import { updateCustomFieldEndpoint } from 'state/concepts/customFields/endpoints'

const updateCustomFieldOperation = createLogic({
  type: UPDATE_CUSTOM_FIELD,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { id } = values
    const { url } = updateCustomFieldEndpoint(id)
    const params = {
      name: values.name,
      custom_field_options_attributes: buildUpdateOptionsParams(values),
      include: 'custom_field_options',
    }

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.customFieldWasUpdated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateCustomFieldOperation
