import { createLogic } from 'redux-logic'

import isPresent from 'utils/isPresent'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import {
  removeClientAttachmentsIds,
  updateClientAttachmentCount,
} from 'state/concepts/companyClientAttachments/actions'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { DELETE_CLIENT_ATTACHMENT } from 'state/concepts/companyClientAttachments/types'
import { deleteClientAttachmentEndpoint } from '../endpoints'
import { clientAttachmentByIdSelector } from '../selectors'

const deleteClientAttachmentOperation = createLogic({
  type: DELETE_CLIENT_ATTACHMENT,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { clientId, attachmentId, feedId } = action
    const { url } = deleteClientAttachmentEndpoint(clientId, attachmentId)
    const attachment = clientAttachmentByIdSelector(state, attachmentId)
    const messageType = isPresent(attachment.businessStorage)
      ? 'clientAttachmentWithBusinessFileWasRemoved'
      : 'clientAttachmentWasDeleted'

    try {
      await httpClient.delete(url)

      dispatch(removeClientAttachmentsIds([attachmentId]))
      dispatch(removeClientFeedIds([feedId]))
      dispatch(updateClientAttachmentCount(clientId))
      dispatch(showNotification({ messageObject: { id: `notifications.${messageType}` } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteClientAttachmentOperation
