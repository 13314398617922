import { createLogic } from 'redux-logic'

import { fetchStorageFiles, setFilterParams } from '../actions'
import { FILTER_STORAGE_FILES } from '../types'

const filterStorageFilesOperation = createLogic({
  type: FILTER_STORAGE_FILES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(fetchStorageFiles(undefined, { append: false }))
    done()
  },
})

export default filterStorageFilesOperation
