import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { removeFileSharedWithMeByIds, setBlankState } from 'state/concepts/filesSharedWithMe/actions'
import isBlankState from 'utils/isBlankState'
import { hideModal } from 'state/modal/actions'
import { fileSharedWithMeIdsSelector, filtersSelector } from 'state/concepts/filesSharedWithMe/selectors'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { ALERT_KINDS } from 'lib/constants'
import { removeMyAccessEndpoint } from '../endpoints'
import { REMOVE_MY_ACCESS } from '../types'

const removeMyAccessOperation = createLogic({
  type: REMOVE_MY_ACCESS,
  latest: true,

  async process({ httpClient, action: { id, onSuccess }, getState }, dispatch, done) {
    const { url, endpoint } = removeMyAccessEndpoint(id)
    const state = getState()
    const filterParams = filtersSelector(state)
    const ids = fileSharedWithMeIdsSelector(state)

    try {
      await httpClient.delete(url)

      if (ids.length === 1) dispatch(setBlankState(isBlankState({ params: filterParams, data: [] })))
      dispatch(removeFileSharedWithMeByIds([id]))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: `notifications.accessWasRemoved` },
        }),
      )
      onSuccess?.()
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default removeMyAccessOperation
