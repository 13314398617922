const namespace = 'customFields'

export const FETCH_CUSTOM_FIELDS = `${namespace}/FETCH_CUSTOM_FIELDS`
export const SET_CUSTOM_FIELDS = `${namespace}/SET_CUSTOM_FIELDS`
export const CREATE_CUSTOM_FIELD = `${namespace}/CREATE_CUSTOM_FIELD`
export const ADD_CUSTOM_FIELDS = `${namespace}/ADD_CUSTOM_FIELDS`
export const REMOVE_CUSTOM_FIELDS = `${namespace}/REMOVE_CUSTOM_FIELDS`
export const UPDATE_CUSTOM_FIELD = `${namespace}/UPDATE_CUSTOM_FIELD`
export const DELETE_CUSTOM_FIELD = `${namespace}/DELETE_CUSTOM_FIELD`
export const UPDATE_CUSTOM_FIELD_PRIORITY = `${namespace}/UPDATE_CUSTOM_FIELD_PRIORITY`
