import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Spinner from 'views/shared/icons/Spinner'

const NotFoundContent = ({ isLoading, noAssignedExpert, serviceId }) => {
  if (isLoading !== false) return <Spinner size={24} />

  if (noAssignedExpert) {
    const NoAssignedExpert = noAssignedExpert

    return <NoAssignedExpert serviceId={serviceId} />
  }

  return <FormattedMessage id="shared.noResultsFound" />
}

NotFoundContent.defaultProps = {
  noAssignedExpert: undefined,
  isLoading: undefined,
  serviceId: undefined,
}

NotFoundContent.propTypes = {
  isLoading: PropTypes.bool,
  noAssignedExpert: PropTypes.shape(),
  serviceId: PropTypes.string,
}

export default NotFoundContent
