import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { pluck } from 'ramda'
import { useLayoutEffect } from 'react'

import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import DropdownUserField from 'views/shared/DropdownUserField'
import Avatar from 'views/shared/Avatar'
import profileName from 'utils/profileName'
import { ALL_EMPLOYEE_ROLES } from 'lib/constants/employees'
import SubmitPanel from './SubmitPanel'
import Permission from './Permission'

const Permissions = ({ myBusinessFile, onClose, setStep }) => {
  const { userProfile: owner, name } = myBusinessFile
  const {
    handleReset,
    values: { businessStoragePermissions },
  } = useFormikContext()
  const sharedWith = pluck('userProfile', businessStoragePermissions)
  const excludedUserProfileIds = pluck('id', [...sharedWith, owner])

  const onUserSelect = () => {
    setStep(SHARE_FILE_STEPS.addPermissions)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(handleReset, [])

  return (
    <>
      <div className="main-modal__header pr-24">
        <h2 className="main-modal__title">
          <FormattedMessage id="myBusinessFiles.shareFileModal.title" values={{ name }} />
        </h2>
        <Field
          component={DropdownUserField}
          name="newProfileId"
          id="newProfileId"
          statuses={['active']}
          excludeEmployees={excludedUserProfileIds}
          icon="alert"
          className="mb-0 mt-16 disabled-select--no-arrow"
          placeholder={{ id: 'myBusinessFiles.shareFileModal.shareWithTeamMembers' }}
          onChange={onUserSelect}
          roles={ALL_EMPLOYEE_ROLES}
        />
      </div>
      <div className="main-modal__body">
        <p className="font-600 text-body">
          <FormattedMessage id="myBusinessFiles.shareFileModal.teamMembersWithAccess" />
        </p>
        <div className="d-flex align-items-center justify-content-between gap-8 mb-16 mt-16">
          <div className="d-flex align-items-center">
            <Avatar
              avatarClassName="mr-12"
              sizeClassName="main-userpic main-userpic--sm"
              imageSize="small"
              profile={owner}
            />
            <p className="mb-0 text-body truncate-text">{profileName(owner)}</p>
          </div>
          <p className="mb-0 in-blue-gray-300">
            <FormattedMessage id="myBusinessFiles.shareFileModal.owner" />
          </p>
        </div>
        {businessStoragePermissions.map((businessStoragePermission, index) => (
          <Permission
            key={businessStoragePermission.id}
            rowIndex={index}
            businessStoragePermission={businessStoragePermission}
            myBusinessFile={myBusinessFile}
            setStep={setStep}
          />
        ))}
      </div>
      <div className="main-modal__footer">
        <SubmitPanel onClose={onClose} />
      </div>
    </>
  )
}

Permissions.propTypes = {
  onClose: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  myBusinessFile: PropTypes.shape({
    id: PropTypes.string,
    parent: PropTypes.shape({}),
    name: PropTypes.string,
    userProfile: PropTypes.shape({}),
    businessStoragePermissions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default Permissions
