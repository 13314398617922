import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import selectedProviders from 'utils/employees/selectedProviders'
import Tooltip from 'views/shared/Tooltip'
import DropdownServiceField from 'views/shared/DropdownServiceField'
import ProvisionPrice from 'views/shared/ProvisionPrice'
import DeleteO from 'views/shared/icons/DeleteO'
import Plus from 'views/shared/icons/Plus'

const ProvisionFieldsComponent = ({
  addRow,
  removeRow,
  form: {
    values,
    values: { provision },
    setFieldValue,
    setFieldTouched,
  },
  handleServiceCreateCallback,
}) => (
  <>
    {provision.map(({ key }, index) => (
      <div className="service-employee" key={key} data-cy="service-employee-section">
        <div className="service-employee__row">
          <div className="service-employee__col flex-grow-1">
            <Field
              className="dropdown-multiselect"
              selectClassName="main-input__field-tags dropdown-tags--blue"
              name={`provision.${index}.services`}
              component={DropdownServiceField}
              removeIconSize={12}
              statuses={['active', 'inactive']}
              handleServiceCreateCallback={handleServiceCreateCallback}
              id={`provision.${index}.services`}
              label={{ id: 'employees.assignServices.services' }}
              icon="alert"
              placeholder={{ id: 'employees.assignServices.addServices' }}
              mode="multiple"
              excludeServices={selectedProviders(values, index)}
            />
          </div>
          <div className="service-employee__col service-employee__col--price">
            <ProvisionPrice index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
          </div>
          <div className="service-employee__col">
            {provision.length > 1 && (
              <Tooltip
                overlayClassName="main-tooltip"
                placement="top"
                icon={
                  <button
                    onClick={removeRow(index)}
                    type="button"
                    className="service-employee__delete-btn"
                    data-cy="service-employee-delete-button"
                  >
                    <DeleteO size={20} className="hover-blue-600" />
                  </button>
                }
              >
                <FormattedMessage id="shared.delete" />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    ))}
    <button className="main-link font-600" onClick={addRow} type="button">
      <Plus className="fill-currentcolor mr-8" size={14} />
      <span>
        <FormattedMessage id="employees.assignServices.addServicesOtherPrice" />
      </span>
    </button>
  </>
)

ProvisionFieldsComponent.displayName = 'ProvisionFieldsComponent'

ProvisionFieldsComponent.propTypes = {
  addRow: PropTypes.func.isRequired,
  handleServiceCreateCallback: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      provision: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
}

export default ProvisionFieldsComponent
