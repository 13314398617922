import yup from 'lib/yupLocalised'

export const verifyPhoneNumberSchema = yup.object().shape({
  phoneNumber: yup.string().required().phoneNumber(),
})

export const otpCodeSchema = yup.object().shape({
  otpCode: yup.string().required(),
})

export const passwordSchema = yup.object().shape({
  password: yup.string().required(),
})
