import { omit, has, set, lensPath, map, assoc } from 'ramda'
import build from 'redux-object'
import { DateTime } from 'luxon'

import { DATA_API_SUCCESS } from 'state/data/types'
import { BULK_EMPLOYEE_LOGOUT_SUCCESS, EMPLOYEE_LOGOUT_SUCCESS } from 'state/concepts/session/types'
import {
  ADD_RECENT_WORKSPACE,
  UPDATE_RECENT_WORKSPACE,
  REMOVE_RECENT_WORKSPACE,
  SET_RECENT_WORKSPACE_TIME,
} from './types'

const recentWorkspaces = (state = {}, action) => {
  switch (action.type) {
    case ADD_RECENT_WORKSPACE:
      return {
        ...state,
        [action.currentEmployee.workspace.id]: {
          ...action.currentEmployee.workspace,
          userProfileId: action.currentEmployee.id,
          onboardingStatus: action.currentEmployee.onboardingStatus,
          userEmail: action.currentEmployee.email,
          tokens: action.tokens,
          lastSession: DateTime.fromISO().ts,
        },
      }
    case UPDATE_RECENT_WORKSPACE:
      return {
        ...state,
        [action.workspace.id]: {
          ...state[action.workspace.id],
          ...action.workspace,
        },
      }
    case DATA_API_SUCCESS:
      if (has('workspace', action.response)) {
        const workspace = build(action.response, 'workspace')[0]
        return {
          ...state,
          [workspace.id]: {
            ...state[workspace.id],
            ...workspace,
          },
        }
      }
      return state
    case SET_RECENT_WORKSPACE_TIME:
      return {
        ...state,
        [action.workspaceId]: {
          ...state[action.workspaceId],
          lastSession: DateTime.fromISO().ts,
        },
      }
    case EMPLOYEE_LOGOUT_SUCCESS:
      return has(action.workspaceId, state) ? set(lensPath([action.workspaceId, 'tokens']), null, state) : state
    case BULK_EMPLOYEE_LOGOUT_SUCCESS:
      return map(assoc('tokens', null))(state)
    case REMOVE_RECENT_WORKSPACE:
      return omit([action.workspaceId], state)
    default:
      return state
  }
}

export default recentWorkspaces
