const documentsFeature = {
  'documentTemplatesFeature.title': 'Document templates',
  'documentTemplatesFeature.subtitle': '<span>in</span> Forms & documents',
  'documentTemplatesFeature.description':
    'Create and use templates like agreements, training programs, progress reports, and more.',
  'documentTemplatesFeature.overview': 'Document templates overview',
  'documentTemplatesFeature.premiumFeature': 'Document templates is a Premium feature.',
  'documentTemplatesFeature.overview.text1':
    'Streamline your document creation process, maintain consistency, and boost productivity with ExpertBox Document templates:',
  'documentTemplatesFeature.overview.text2':
    'Create and use templates like agreements, training programs, progress reports, and more.',
  'documentTemplatesFeature.overview.text3':
    'Generate documents in a few clicks using your templates, saving valuable time and effort.',
  'documentTemplatesFeature.overview.text4':
    'Ensure uniformity and professionalism by using standard documents across your workspace.',
  'documentTemplatesFeature.toLearnMores':
    'To learn more, read our help center articles on <creatingDocumentTemplatesLink>using document templates</creatingDocumentTemplatesLink>.',

  'documentTemplatesFeature.disable.title': 'Disable document templates feature?',
  'documentTemplatesFeature.disable.description':
    'You won’t be able to create documents and share them with _(clients).',
  'documentTemplatesFeature.disable.alert': 'This won’t affect any of the documents created or shared previously.',
  'documentTemplatesFeature.enableDocumentsWarning':
    'To enable document templates, you need to start using documents on ExpertBox.',
  'documentTemplatesFeature.enableDocuments': 'Enable Documents',
  'documentTemplatesFeature.setUpDocumentTemplates': 'Set up document templates',
}

export default documentsFeature
