import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const stripeConnectLink = (state = null, action) => {
  switch (action.type) {
    case types.SET_STRIPE_CONNECT_LINK:
      return action.link
    default:
      return state
  }
}

const payPalConnectLink = (state = null, action) => {
  switch (action.type) {
    case types.SET_PAYPAL_CONNECT_LINK:
      return action.link
    default:
      return state
  }
}

const stripeDisconnectError = (state = null, action) => {
  switch (action.type) {
    case types.SET_STRIPE_DISCONNECT_ERROR:
      return action.error
    case types.RESET_STRIPE_DISCONNECT_ERROR:
      return null
    default:
      return state
  }
}

const isConnectStripePos = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_CONNECT_STRIPE_POS:
      return action.isConnect
    default:
      return state
  }
}

export default combineReducers({
  stripeConnectLink,
  payPalConnectLink,
  stripeDisconnectError,
  isConnectStripePos,
})
