import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { UPDATE_CHAT } from 'state/concepts/chat/types'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { updateChatEndpoint } from '../endpoints'

const updateChatOperation = createLogic({
  type: UPDATE_CHAT,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const formData = new FormData()
    const {
      form,
      values: { id, logo, logoUrl, name },
    } = action
    const { url } = updateChatEndpoint(id)

    try {
      formData.append('name', name.trim())

      if (logo) {
        formData.append('logo', logo, logo.name)
      } else if (!logoUrl) {
        formData.append('logo', '')
      }

      const { data } = await httpClient.put(url, formData, { headers: { 'content-type': 'multipart/form-data' } })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.chatHasBeenUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateChatOperation
