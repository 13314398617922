import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import { hideModal } from 'state/modal/actions'
import { disconnectGoogleMeetProviderEndpoint } from '../endpoints'
import { DISCONNECT_GOOGLE_MEET_PROVIDER } from '../types'

const disconnectGoogleMeetProvider = createLogic({
  type: DISCONNECT_GOOGLE_MEET_PROVIDER,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { id } = currentUserProfileSelector(state)

    const { url, endpoint } = disconnectGoogleMeetProviderEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)
      const response = updateDataHelper(state.data, 'userProfile', id, {
        attributes: {
          videoCallProvider: VIDEO_CALLS_PROVIDERS.expertbox,
        },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.googleVideoProviderDisconnected',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default disconnectGoogleMeetProvider
