import React from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { changeEmployeeRole } from 'state/concepts/employees/actions'
import ChangeRoleModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class EmployeesChangeRoleModal extends React.Component {
  render = () => <ChangeRoleModalComponent {...this.props} />
}

const mapDispatchToProps = {
  onSubmit: changeEmployeeRole,
}

export { EmployeesChangeRoleModal as EmployeesChangeRoleModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ employee }) => ({
      roleName: employee.roleName,
    }),
    handleSubmit: handleSubmitWithProps(['employee']),
  }),
)(EmployeesChangeRoleModal)
