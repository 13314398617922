import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setBlankState, setRecurringPayments } from 'state/concepts/recurringPayments/actions'
import { fetchRecurringPaymentsEndpoint } from 'state/concepts/recurringPayments/endpoints'
import { filtersSelector, paginationSelector } from 'state/concepts/recurringPayments/selectors'
import { FETCH_RECURRING_PAYMENTS } from 'state/concepts/recurringPayments/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isBlankState from 'utils/isBlankState'

const fetchRecurringPaymentsOperation = createLogic({
  type: FETCH_RECURRING_PAYMENTS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchRecurringPaymentsEndpoint
    const state = getState()
    const { statuses, employees, services, clients, name } = filtersSelector(state)

    const params = {
      include: 'client-profile',
      page: paginationSelector(state),
      filter: {
        name,
        statuses,
        service_ids: services,
        user_profile_ids: employees,
        client_profile_ids: clients,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setRecurringPayments(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchRecurringPaymentsOperation
