import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { CHAT_INFO_MODAL_TABS } from 'lib/constants/chat'
import Logo from 'views/Chat/Logo'
import Modal from 'views/shared/Modal'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import Cross from 'views/shared/icons/Cross'
import EditPencil from 'views/shared/icons/EditPencil'
import AttachmentsList from './AttachmentsList'
import MembersList from './MembersList'
import VoiceMessagesList from './VoiceMessagesList'

const ChatInfoModalComponent = ({
  chat,
  activeKey,
  isCurrentUserChatOwner,
  manageChatMembers,
  onOpenChatDetails,
  onOpenAddChatMembers,
  onTabClick,
  onClose,
  isChatActive,
  isDirectChat,
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header modal__header--no-shadow d-flex align-items-center">
        <Logo {...chat} />
        <h2 className="main-modal__title font-16 mr-30">
          <TruncatedTextByWidthTooltip text={chat.name} overlayClassName="ant-modal-tooltip" on={['hover']} />
        </h2>
        {isCurrentUserChatOwner && !isDirectChat && (
          <button className="main-modal__edit" type="button" onClick={onOpenChatDetails} disabled={!isChatActive}>
            <EditPencil />
          </button>
        )}
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body main-modal__body--chat pt-0 pb-0">
        <Tabs
          items={[
            {
              label: <FormattedMessage id="chat.actions.members" />,
              key: CHAT_INFO_MODAL_TABS.members,
              children: (
                <MembersList
                  chat={chat}
                  isCurrentUserChatOwner={isCurrentUserChatOwner}
                  isChatActive={isChatActive}
                  onOpenAddChatMembers={onOpenAddChatMembers}
                  manageChatMembers={manageChatMembers}
                />
              ),
            },
            {
              label: <FormattedMessage id="chat.actions.files" />,
              key: CHAT_INFO_MODAL_TABS.files,
              children: <AttachmentsList chat={chat} />,
            },
            {
              label: <FormattedMessage id="chat.actions.voiceMessages" />,
              key: CHAT_INFO_MODAL_TABS.voice,
              children: <VoiceMessagesList chat={chat} />,
            },
          ]}
          activeKey={activeKey}
          animated={false}
          destroyInactiveTabPane
          onTabClick={onTabClick}
        />
      </div>
    </div>
  </Modal>
)

ChatInfoModalComponent.propTypes = {
  onOpenChatDetails: PropTypes.func.isRequired,
  onOpenAddChatMembers: PropTypes.func.isRequired,
  onTabClick: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
  isCurrentUserChatOwner: PropTypes.bool.isRequired,
  manageChatMembers: PropTypes.bool.isRequired,
  chat: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  isChatActive: PropTypes.bool.isRequired,
  isDirectChat: PropTypes.bool.isRequired,
}

export default ChatInfoModalComponent
