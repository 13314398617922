import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Calendar = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 20 20"
    data-cy={dataCy}
  >
    <path
      fill={color}
      d="M7.49984 1.66675V3.33341H12.4998V1.66675H14.1665V3.33341H17.4998C17.9601 3.33341 18.3332 3.70651 18.3332 4.16675V17.5001C18.3332 17.9603 17.9601 18.3334 17.4998 18.3334H2.49984C2.0396 18.3334 1.6665 17.9603 1.6665 17.5001V4.16675C1.6665 3.70651 2.0396 3.33341 2.49984 3.33341H5.83317V1.66675H7.49984ZM16.6665 10.0001H3.33317V16.6667H16.6665V10.0001ZM9.1665 11.6667V15.0001H4.99984V11.6667H9.1665ZM5.83317 5.00008H3.33317V8.33342H16.6665V5.00008H14.1665V6.66675H12.4998V5.00008H7.49984V6.66675H5.83317V5.00008Z"
    />
  </svg>
)

Calendar.defaultProps = {
  size: 20,
  color: '#b2bac7',
  className: '',
  dataCy: 'calendar-icon',
}

Calendar.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Calendar
