import { createSelector } from '@reduxjs/toolkit'
import { filter, flatten, isEmpty, map, path, propEq, reject } from 'ramda'
import build from 'redux-object'

import { deepCreateSelector } from 'lib/selectors'
import {
  currentPageSelector,
  dataSelector,
  hasMoreSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import profileName from 'utils/profileName'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { getClientContactsEndpoint, getContactClientProfilesEndpoint, getEmployeesEndpoint } from './endpoints'

export const searchedClientsSelector = makeSelectSearchResults('clients', 'clientProfile')
export const searchedEmployeesSelector = makeSelectSearchResults('employees', 'userProfile')

export const employeesSelector = createSelector(
  searchedEmployeesSelector,
  map(employee => ({ key: employee.id, value: employee.id, label: profileName(employee), employee })),
)

export const employeesWithoutCurrentEmployeeSelector = createSelector(
  [searchedEmployeesSelector, currentEmployeeIdSelector],
  (employees, currentEmployeeId) =>
    map(
      employee => ({
        key: employee.id,
        value: employee.id,
        label: profileName(employee),
        employee,
      }),
      reject(employee => employee.id === currentEmployeeId, employees),
    ),
)

export const allActiveEmployeesSelector = createSelector(dataSelector, data =>
  filter(propEq(true, 'active'), build(data, 'userProfile')),
)

export const clientsSelector = createSelector(
  searchedClientsSelector,
  map(client => ({ key: client.id, value: client.id, label: profileName(client) })),
)

export const selectedClientsSelector = deepCreateSelector(
  [dataSelector, (_, clientIds) => (clientIds ? flatten([clientIds]) : [])],
  (data, clientIds) => (isEmpty(clientIds) ? [] : build(data, 'clientProfile', clientIds)),
)

export const servicesSelector = createSelector(
  makeSelectSearchResults('services', 'service'),
  map(service => ({
    key: service.id,
    value: service.id,
    label: service.name,
    parent: path(['serviceCategory', 'name'], service),
  })),
)

export const searchedContactClientProfilesSelector = makeSelectSearchResults('contactClientProfiles', 'clientProfile')
export const selectedClientProfilesSelector = deepCreateSelector(
  [dataSelector, (_, contactClientProfileIds) => (contactClientProfileIds ? flatten([contactClientProfileIds]) : [])],
  (data, contactClientProfileIds) =>
    isEmpty(contactClientProfileIds) ? [] : build(data, 'clientProfile', contactClientProfileIds),
)

export const selectedContactClientProfilesSelector = deepCreateSelector(
  [dataSelector, (_, contactClientProfileIds) => (contactClientProfileIds ? flatten([contactClientProfileIds]) : [])],
  (data, contactClientProfileIds) =>
    isEmpty(contactClientProfileIds) ? [] : build(data, 'contactClientProfile', contactClientProfileIds),
)
export const searchedClientContactsSelector = makeSelectSearchResults('clientContacts', 'clientProfile')

export const employeesNextCursorSelector = state => nextPageSelector(state, getEmployeesEndpoint.endpoint)
export const hasMoreEmployeesSelector = state => hasMoreSelector(state, getEmployeesEndpoint.endpoint)
export const clientContactsPageCountSelector = state => pageCountSelector(state, getClientContactsEndpoint.endpoint)
export const contactClientProfilesPageCountSelector = state =>
  pageCountSelector(state, getContactClientProfilesEndpoint.endpoint)
export const clientContactsCurrentPageSelector = state => currentPageSelector(state, getClientContactsEndpoint.endpoint)
export const contactClientProfilesCurrentPageSelector = state =>
  currentPageSelector(state, getContactClientProfilesEndpoint.endpoint)
