const storage = {
  'storage.table.name.title': 'Name',
  'storage.table.owner.title': 'Owner',
  'storage.table.lastModified.title': 'Last modified',
  'storage.table.fileSize.title': 'Storage used',
  'storage.emptyState.body': 'You can find all the files that impact workspace storage right here.',
  'storage.emptyState.title': 'Workspace storage is empty',
  'storage.usedSize': 'of {size} used',
  'storage.usedSizeTrial': '{size} used',
  'storage.progressBar.businessFiles.title': 'Business files',
  'storage.progressBar.businessFiles.description': 'Files uploaded by team members to Business files.',
  'storage.progressBar.clientFiles.title': '_(Client) files',
  'storage.progressBar.clientFiles.description':
    'Files uploaded by team members in the CRM and _(clients) on the _(Client) Portal.',
  'storage.progressBar.communicationFiles.title': 'Communication files',
  'storage.progressBar.communicationFiles.description':
    'Video call recordings, in-call chats history, voice messages, chat attachments.',
  'storage.searchPlaceholder': 'Search in Storage',
  'storage.noSearchResults': 'Try adjusting your search or filters',
  'storage.actions.delete.modal.title': 'Delete forever?',
  'storage.actions.delete.modal.multiple.description':
    '{count} items will be deleted forever and you won’t be able to restore it.',
  'storage.actions.delete.modal.single.description':
    '<b>{name}</b> will be deleted forever and you won’t be able to restore it.',
  'storage.actions.deleteForever': 'Delete forever',
  'storage.selectedItems': '{count} selected',
  'storage.autoUpgradeSettings.modal.title': 'Auto-upgrade settings',
  'storage.autoUpgradeSettings.modal.description':
    'When auto-upgrade is disabled, older files that exceed the storage limit are hidden. Upgrade within 30 days to restore them, or they’ll be deleted permanently.',
  'storage.autoUpgradeSettings.modal.enable': 'Enable auto-upgrade',
  'storage.autoUpgradeSettings.button': 'Auto-upgrade settings',
  'storage.getMoreStorage.button': 'Get more storage',
  'storage.filters.owner': '{fullName} <span>(you)</span>',
}

export default storage
