import { createLogic } from 'redux-logic'

import { UPDATE_CLIENT_ATTACHMENT_COUNT } from 'state/concepts/companyClientAttachments/types'
import { dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { clientSelector } from 'state/concepts/client/selectors'

const updateClientAttachmentCountOperation = createLogic({
  type: UPDATE_CLIENT_ATTACHMENT_COUNT,
  latest: true,

  async process({ action: { clientId, isAdding }, getState }, dispatch, done) {
    const client = clientSelector(getState(), clientId)
    const count = isAdding ? client.clientAttachmentsCount + 1 : client.clientAttachmentsCount - 1

    const response = updateDataHelper(getState().data, 'clientProfile', clientId, {
      attributes: {
        clientAttachmentsCount: count,
      },
    })
    dispatch(dataApiSuccess({ response }))
    done()
  },
})

export default updateClientAttachmentCountOperation
