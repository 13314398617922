import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck, path } from 'ramda'

import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { FETCH_NOTIFICATIONS } from 'state/concepts/notifications/types'
import { setNotifications, setUnreadNotificationsCount } from 'state/concepts/notifications/actions'
import { fetchNotificationsEndpoint } from 'state/concepts/notifications/endpoints'

const fetchNotificationsOperation = createLogic({
  type: FETCH_NOTIFICATIONS,
  latest: true,

  async process({ httpClient, action: { pageParams } }, dispatch, done) {
    const { endpoint, url } = fetchNotificationsEndpoint
    const params = {
      include: 'user-profile',
      page: {
        ...pageParams,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setNotifications(pluck('id', data.data)))
      dispatch(setUnreadNotificationsCount(path(['meta', 'unread_count'], data)))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default fetchNotificationsOperation
