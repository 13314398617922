import * as types from './types'

export const fetchClientAttachments = (
  clientId,
  options = { size: 20, append: true, number: 1, nonClientFiles: false },
) => ({
  type: types.FETCH_CLIENT_ATTACHMENTS,
  clientId,
  options,
})

export const deleteClientAttachment = ({ clientId, attachmentId, feedId }) => ({
  type: types.DELETE_CLIENT_ATTACHMENT,
  clientId,
  attachmentId,
  feedId,
})

export const removeClientAccess = (attachmentId, sharedFileId) => ({
  type: types.REMOVE_CLIENT_ACCESS,
  sharedFileId,
  attachmentId,
})

export const shareFileWithClient = (sharedFileId, clientId) => ({
  type: types.SHARE_FILE_WITH_CLIENT,
  sharedFileId,
  clientId,
})

export const removeClientAttachmentsIds = ids => ({
  type: types.REMOVE_CLIENT_ATTACHMENTS_IDS,
  ids,
})

export const resetClientAttachments = () => ({
  type: types.RESET_CLIENT_ATTACHMENTS,
})

export const addClientAttachmentsIds = clientAttachmentsIds => ({
  type: types.ADD_CLIENT_ATTACHMENTS_IDS,
  clientAttachmentsIds,
})

export const setClientAttachmentsIds = clientAttachmentsIds => ({
  type: types.SET_CLIENT_ATTACHMENTS_IDS,
  clientAttachmentsIds,
})

export const addClientAttachmentId = clientAttachmentId => ({
  type: types.ADD_CLIENT_ATTACHMENT_ID,
  clientAttachmentId,
})

export const updateClientAttachmentCount = (clientId, isAdding = false) => ({
  type: types.UPDATE_CLIENT_ATTACHMENT_COUNT,
  clientId,
  isAdding,
})

export const filterClientAttachments = filters => ({
  type: types.SET_CLIENT_ATTACHMENTS_FILTER,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_CLIENT_ATTACHMENTS_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const presignClientAttachments = (files, clientId) => ({
  type: types.PRESIGN_CLIENT_ATTACHMENTS,
  files,
  clientId,
})

export const uploadClientAttachments = (file, meta, clientId) => ({
  type: types.UPLOAD_CLIENT_ATTACHMENTS,
  file,
  meta,
  clientId,
})

export const addUploadFile = (uploadId, file, meta) => ({
  type: types.ADD_UPLOAD_FILE,
  uploadId,
  file,
  meta,
})

export const completeFileLoading = uploadId => ({
  type: types.COMPLETE_FILE_LOADING,
  uploadId,
})

export const failFileLoading = uploadId => ({
  type: types.FAIL_FILE_LOADING,
  uploadId,
})

export const removeUploadedFiles = () => ({
  type: types.REMOVE_UPLOADED_FILES,
})

export const cancelFileUpload = uploadIds => ({
  type: types.CANCEL_FILE_UPLOAD,
  uploadIds,
})

export const setIsUploadingSectionOpen = isOpen => ({
  type: types.SET_IS_UPLOADING_SECTION_OPEN,
  isOpen,
})
