import * as types from './types'

export const fetchCardReaders = () => ({
  type: types.FETCH_CARD_READERS,
})

export const setCardReaders = cardReadersIds => ({
  type: types.SET_CARD_READERS,
  cardReadersIds,
})
