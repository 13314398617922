import { combineReducers } from '@reduxjs/toolkit'
import { SET_CLIENT_PORTAL } from 'state/concepts/companyClientPortal/types'

const clientPortalId = (state = null, action) => {
  switch (action.type) {
    case SET_CLIENT_PORTAL:
      return action.clientPortalId
    default:
      return state
  }
}

const clientPortalReducer = combineReducers({
  clientPortalId,
})

export default clientPortalReducer
