import * as types from './types'

export const fetchReviews = () => ({
  type: types.FETCH_REVIEWS,
})

export const setReviews = reviewIds => ({
  type: types.SET_REVIEWS,
  reviewIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_REVIEWS_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_REVIEWS_PAGE,
  pageNumber,
})

export const setSortOrder = sortKey => ({
  type: types.SET_REVIEWS_SORT_ORDER,
  sortKey,
})

export const filterReviews = filters => ({
  type: types.SET_REVIEWS_FILTER,
  filters,
})

export const setFilterParams = (filterParams = {}) => ({
  type: types.SET_REVIEWS_FILTER_PARAMS,
  filterParams,
})

export const resetReviews = () => ({
  type: types.RESET_REVIEWS,
})

export const toggleReviewStatus = (id, status) => ({
  type: types.TOGGLE_REVIEW_STATUS,
  id,
  status,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})
