import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { RECEIVE_CLIENT_IMPORT } from 'state/concepts/companyClientImport/types'
import { dataApiSuccess } from 'state/data/actions'
import { processClientImportEndpoint } from 'state/concepts/companyClientImport/endpoints'
import { setIsProcessing } from 'state/concepts/companyClientImport/actions'

const receiveClientImportOperation = createLogic({
  type: RECEIVE_CLIENT_IMPORT,
  latest: true,

  process({ action }, dispatch, done) {
    const { data } = action
    const { endpoint } = processClientImportEndpoint(data.data.id)
    const response = normalize(data, { endpoint })

    dispatch(dataApiSuccess({ response, endpoint }))
    dispatch(setIsProcessing(false))

    done()
  },
})

export default receiveClientImportOperation
