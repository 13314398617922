import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'
import { FormattedMessage } from 'react-intl'

import validationSchema from 'lib/yupLocalised/schemas/connectAppleCalendar'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import InputField from 'views/shared/InputField'
import Modal from 'views/shared/Modal'
import PasswordInputField from 'views/shared/PasswordInputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const ConnectAppleCalendarModal = ({ onClose, handleSubmit, connection, isReconnect }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title" data-cy="modal-title">
          <FormattedMessage id={`calendarSync.${isReconnect ? 'reconnectToApple' : 'connectToApple'}`} />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <Formik
        initialValues={{
          appleId: propOr('', 'name', connection),
          password: '',
          connectionId: propOr(null, 'id', connection),
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ status }) => (
          <Form>
            <div className="main-modal__body" data-cy="modal-body">
              <p className="mb-16">
                <FormattedMessage id="calendarSync.generatePassword" />
                <span className="main-link">
                  <a href="https://appleid.apple.com/account/manage" target="_blank">
                    https://appleid.apple.com/account/manage
                  </a>
                </span>
                {'. '}
                <FormattedMessage id="calendarSync.credentialsEncrypted" />
              </p>
              {status?.base && <Alert className="mt-16 mb-16" message={status.base} />}
              <Field
                name="appleId"
                id="appleId"
                component={InputField}
                label={{ id: 'calendarSync.appleId' }}
                placeholder={{ id: 'calendarSync.appleId.placeholder' }}
                icon="alert"
                disabled={isReconnect}
              />
              <Field
                name="password"
                id="password"
                component={PasswordInputField}
                label={{ id: 'shared.password' }}
                icon="alert"
                visiblePassword
                autoComplete="new-password"
                className="mb-0"
              />
            </div>
            <div className="main-modal__footer">
              <div className="d-flex justify-content-end">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton text={{ id: isReconnect ? 'calendarSync.reconnect' : 'calendarSync.connect' }} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </Modal>
)

ConnectAppleCalendarModal.defaultProps = {
  connection: null,
  isReconnect: false,
}

ConnectAppleCalendarModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  isReconnect: PropTypes.bool,
}
export default ConnectAppleCalendarModal
