import { createLogic } from 'redux-logic'
import fileDownload from 'js-file-download'
import axios from 'axios'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { downloadEtherpadDocumentEndpoint } from '../endpoints'
import { DOWNLOAD_ETHERPAD_DOCUMENT } from '../types'

const downloadEtherpadDocumentOperation = createLogic({
  type: DOWNLOAD_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { documentId } = action
    const { url, endpoint } = downloadEtherpadDocumentEndpoint(documentId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)
      dispatch(dataApiSuccess({ endpoint }))

      const dataAttributes = data.data.attributes
      const pdfUrl = dataAttributes['pdf-with-trail-url']
      const filename = dataAttributes['pdf-with-trail']?.metadata?.filename
      const blob = await axios.get(pdfUrl, { responseType: 'blob' })

      fileDownload(blob.data, filename)
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default downloadEtherpadDocumentOperation
