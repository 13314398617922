import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import FormattedPriceOrRange from 'views/shared/FormattedPriceOrRange'
import CardCustom from 'views/shared/icons/CardCustom'
import Service from 'views/shared/icons/Service'

const About = ({ color, activeServicesCount, priceMax, priceMin, about }) => (
  <div className="widget-sidebar__info pt-24 pb-24 bg-white">
    <div className="widget-sidebar__container">
      <div className="d-flex align-items-center mb-12">
        <Service size={20} color={color} dataCy="service-icon" />
        <span className="ml-8 expert-info__gray text-subheader" data-cy="active-services">
          <span className="font-600 mr-4">{activeServicesCount}</span>
          <FormattedMessage
            values={{ count: activeServicesCount }}
            id="website.page.providers.providerSidebar.servicesAvailable"
          />
        </span>
      </div>
      <div className="d-flex align-items-center mb-12">
        <CardCustom size={20} color={color} dataCy="card-icon" />
        <span className="ml-8 expert-info__gray text-subheader font-600" data-cy="service-prices">
          <FormattedPriceOrRange priceMin={priceMin} priceMax={priceMax} />
        </span>
      </div>
      {about && (
        <p className="expert-info__gray mb-0 text-subheader text-left" data-cy="expert-about">
          {about}
        </p>
      )}
    </div>
  </div>
)

About.defaultProps = {
  about: '',
}

About.propTypes = {
  activeServicesCount: PropTypes.number.isRequired,
  priceMin: PropTypes.string.isRequired,
  priceMax: PropTypes.string.isRequired,
  about: PropTypes.string,
  color: PropTypes.string.isRequired,
}

export default About
