import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { ALERT_KINDS } from 'lib/constants'
import { passwordSchema } from 'lib/yupLocalised/schemas/userProfileTwoFactorSettings'
import { generateBackupCodes } from 'state/concepts/employee/actions'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import PasswordInputField from 'views/shared/PasswordInputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const GenerateCodesForm = ({ onClose, onSuccess }) => {
  const handleSubmit = useFormSubmit(generateBackupCodes, { onSuccess })

  return (
    <Formik initialValues={{ password: '' }} validationSchema={passwordSchema} onSubmit={handleSubmit}>
      <>
        <div className="main-modal__body">
          <Alert
            className="align-items-center pl-12 pr-12 mb-16"
            iconSize={20}
            type={ALERT_KINDS.info}
            message={{ id: 'employeeTwoFactorSettings.backupCodes.notes' }}
          />
          <p className="text-body">
            <FormattedMessage id="employeeTwoFactorSettings.password.enterPassword" />
          </p>
          <Field
            name="password"
            id="password"
            className="mb-0"
            label={{ id: 'employeeTwoFactorSettings.password.enterPassword.label' }}
            component={PasswordInputField}
            visiblePassword
            autoComplete="on"
            icon="alert"
            data-cy="password-input"
          />
        </div>
        <div className="main-modal__footer">
          <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
          <SubmitFormButton
            className="main-modal__footer-action"
            text={{ id: 'employeeTwoFactorSettings.backupCodes.submit' }}
          />
        </div>
      </>
    </Formik>
  )
}

GenerateCodesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default GenerateCodesForm
