import { createLogic } from 'redux-logic'

import { getDocumentSignatory, isDocumentErrorEqTo } from 'utils/etherpadDocuments'
import { ALERT_KINDS } from 'lib/constants'
import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyClientRoute } from 'lib/routes'
import { currentUserProfileSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { clientSelector } from 'state/concepts/client/selectors'
import { addSignatoriesEndpoint } from '../endpoints'
import { ADD_SIGNATORIES } from '../types'

const addSignatoriesOperation = createLogic({
  type: ADD_SIGNATORIES,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = addSignatoriesEndpoint(values.documentId)
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const currentUserProfile = currentUserProfileSelector(state)
    const client = clientSelector(state, values.clientId)

    dispatch(dataApiRequest({ endpoint }))

    const params = {
      signatories_order: values.signatoriesOrder,
      document_signatories: values.documentSignatories.map(signatory =>
        getDocumentSignatory(signatory, currentUserProfile, client),
      ),
    }

    const redirectToClient = () =>
      redirect({
        href: companyClientRoute(values.clientId),
        query: { activeTab: CLIENT_PROFILE_TABS.documents },
        workspace,
      })

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.documentWasSentForSignatures' } }))
      redirectToClient()
    } catch (error) {
      if (isDocumentErrorEqTo(error, 'document_is_sent_for_signature')) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.etherpadDocumentAlreadySentForSignature' },
            kind: ALERT_KINDS.error,
          }),
        )
        redirectToClient()
      }
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default addSignatoriesOperation
