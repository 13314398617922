import Link from 'views/shared/Link'

const getRenderIntlLink =
  href =>
  // eslint-disable-next-line react/display-name
  (...chunks) => (
    <Link href={href}>
      <a className="main-link text-underline" data-cy="recurring-payment-empty-link">
        {chunks}
      </a>
    </Link>
  )

export default getRenderIntlLink
