import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import { signupRoute } from 'lib/routes'
import { rootHttpClient } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SUBMIT_EMAIL } from 'state/concepts/employeeSignUp/types'
import { createPendingRegistrationEndpoint } from 'state/concepts/employeeSignUp/endpoints'

const submitEmailOperation = createLogic({
  type: SUBMIT_EMAIL,
  latest: true,

  async process(
    {
      action: {
        values: { email, promocode },
        form,
      },
    },
    dispatch,
    done,
  ) {
    const { url } = createPendingRegistrationEndpoint

    try {
      await rootHttpClient.post(url, { email })
      redirect({
        href: signupRoute,
        query: promocode ? { email, promocode } : { email },
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default submitEmailOperation
