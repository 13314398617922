import { combineReducers } from '@reduxjs/toolkit'
import { SET_CALENDAR_EVENT } from './types'

const calendarEventId = (state = '', action) => {
  switch (action.type) {
    case SET_CALENDAR_EVENT:
      return action.calendarEventId
    default:
      return state
  }
}

export default combineReducers({
  calendarEventId,
})
