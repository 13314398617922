const clientFields = {
  'clientFields.title': '_(Client) fields',
  'clientFields.customFields.title': 'Custom fields',
  'clientFields.titleTip':
    'Set up _(client) fields to collect and store relevant information about all the _(clients) in your workspace.',
  'clientFields.customFields.description':
    'Custom fields let you collect additional information about your _(clients). Drag and drop to reorder the fields in the _(client) details card.',
  'clientFields.addCustomField': 'Add custom field',
  'clientFields.addButtonTip': 'Add custom fields to collect additional information about your _(clients).',
  'clientFields.table.header.name': 'Field name',
  'clientFields.table.actions.edit': 'Edit',
  'clientFields.table.actions.delete': 'Delete',
  'clientFields.defaultFields.title': 'Default fields',
  'clientFields.defaultFields.explanation':
    'These fields will be completed automatically once a _(client) books their first _(appointment) via ExpertBox. You can’t edit default fields. ',
  'clientFields.defaultFields.learnMore': 'Learn more about Default fields',
  'clientFields.defaultFields.table.body.firstName': 'First name',
  'clientFields.defaultFields.table.body.lastName': 'Last name',
  'clientFields.defaultFields.table.body.email': 'Email',
  'clientFields.defaultFields.table.body.phoneNumber': 'Phone number',
  'clientFields.modal.title.create': 'Create a custom field',
  'clientFields.modal.body.create':
    'Collect extra information that’s relevant to your business. The field will be added to all _(clients) in your _(client) list.',
  'clientFields.modal.body.nameLabel': 'Field name',
  'clientFields.modal.cancel': 'Cancel',
  'clientFields.modal.create': 'Create',
  'clientFields.modal.update': 'Update',
  'clientFields.modal.title.update': 'Edit custom field',
  'clientFields.modal.body.update': 'The field will be updated for all the _(clients) in your workspace.',
  'clientFields.remove.confirmModal.title': 'Delete this custom field?',
  'clientFields.remove.confirmModal.body':
    'Deleting <b>{name}</b> field will also delete all the information collected in this field. This cannot be undone.',
  'clientFields.fieldType': 'Field type',
  'clientFields.addOption': 'Add option',
  'clientFields.options': 'Options',
  'clientFields.fieldType.text': 'Text',
  'clientFields.fieldType.radioButton': 'Single choice',
  'clientFields.fieldType.checkBox': 'Multiple choice',
  'clientFields.fieldType.date': 'Date',
  'clientFields.fieldType.description.text': 'Up to 2000 characters',
  'clientFields.fieldType.description.radioButton': 'List of options to select one of them',
  'clientFields.fieldType.description.checkBox': 'List of options to select as many as needed',
  'clientFields.fieldType.description.date': 'Date picker',
  'clientFields.newOption': 'Option {number}',
}

export default clientFields
