import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useBoolean } from 'react-use'

import useExpertActions from 'hooks/waitlistRequests/useExpertActions'
import { companyClientRoute } from 'lib/routes'
import { currentUserTimezoneOffsetSelector } from 'state/concepts/session/selectors'
import { fetchWaitlistRequestLoadingSelector, waitlistRequestSelector } from 'state/concepts/waitlistRequests/selectors'
import { dateTimeFromISO, formatInputDate } from 'utils/dateTime'
import { durationToFormatString } from 'utils/duration'
import googleMapsUrlCreator from 'utils/location/googleMapsUrlCreator'
import profileName from 'utils/profileName'
import { getDateText, getTimeText } from 'utils/waitlistRequests/timeFormatter'
import Actions from 'views/company/Waitlist/List/Row/Actions'
import Avatar from 'views/shared/Avatar/component'
import Button from 'views/shared/Button'
import Link from 'views/shared/Link/container'
import Tooltip from 'views/shared/Tooltip'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import Calendar from 'views/shared/icons/Calendar'
import Cross from 'views/shared/icons/Cross'
import Spinner from 'views/shared/icons/Spinner'

const WaitlistRequestSidebar = ({ onClose, waitlistRequestId, isWaitlistPage }) => {
  const waitlistRequest = useSelector(state => waitlistRequestSelector(state, waitlistRequestId))
  const timezoneOffset = useSelector(currentUserTimezoneOffsetSelector)

  const { clientProfile, service, userProfile, location, noteText, availabilityPreferences } = waitlistRequest
  const isLoading = useSelector(state => fetchWaitlistRequestLoadingSelector(state, waitlistRequestId))
  const { onSendMessage, canSendMessage, onBookAppointment, canBookAppointment } = useExpertActions({
    waitlist: waitlistRequest,
    isWaitlistPage,
  })

  const [showMore, handleShowMore] = useBoolean(availabilityPreferences?.length <= 3)

  const paginatedAvailabilityPreferences = showMore ? availabilityPreferences : availabilityPreferences?.slice(0, 3)

  const openShowMore = () => {
    handleShowMore(true)
  }

  return (
    <Tooltip open overlayClassName="booking-tooltip-wrapper waitlist-request">
      <section className="booking-tooltip booking-tooltip-with-header">
        <div className="booking-tooltip__toolbar align-items-center">
          <h3 className="booking-tooltip__info-title in-blue-gray-900" data-cy="tooltip-title">
            <FormattedMessage id="waitlistRequests.sidebar.title" />
          </h3>
          <Actions
            waitlistRequest={waitlistRequest}
            mainDropdownContainerClassName="d-flex align-items-center justify-content-center wh-24-24 ml-auto mr-16"
            isWaitlistPage={isWaitlistPage}
          />
          <button
            className="d-flex align-items-center justify-content-center wh-24-24"
            type="button"
            onClick={onClose}
            data-cy="close-sidebar-button"
          >
            <Cross size={10} dataCy="cross-icon" className="in-gray-600 va-middle" />
          </button>
        </div>

        {isLoading !== false ? (
          <Spinner />
        ) : (
          <div className="booking-tooltip__body d-flex flex-column max-h-100">
            <div className="pl-24 pr-24">
              <h3 className="booking-tooltip__info-title in-blue-gray-900" data-cy="tooltip-title">
                <FormattedMessage id="waitlistRequests.sidebar.client" />
              </h3>

              <div className="d-flex align-items-center column-gap-8 min-w-0 mt-16">
                {waitlistRequest.clientAccess ? (
                  <Link href={companyClientRoute(clientProfile.id)}>
                    <button
                      data-cy="client-link"
                      type="button"
                      className="d-flex align-items-center column-gap-8 min-w-0 waitlist-request__client-avatar"
                    >
                      <Avatar sizeClassName="main-userpic--xs" profile={clientProfile} />
                      <div className="min-w-0">
                        <div className="text-body font-600">
                          <TruncatedTextByWidthTooltip on={['hover']} text={profileName(clientProfile)} />
                        </div>
                      </div>
                    </button>
                  </Link>
                ) : (
                  <div className="d-flex align-items-center column-gap-8 min-w-0 waitlist-request__client-avatar">
                    <Avatar sizeClassName="main-userpic--xs" profile={clientProfile} />
                    <div className="min-w-0">
                      <div className="text-body font-600 in-blue-gray-900">
                        <TruncatedTextByWidthTooltip on={['hover']} text={profileName(clientProfile)} />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex column-gap-8 mt-8">
                <div className="d-flex flex-column min-w-0 flex-grow-1 flex-basis-0">
                  <p className="text-body mb-0 in-blue-gray-200">
                    <FormattedMessage id="waitlistRequests.sidebar.email" />
                  </p>
                  <span className="line-height-20 mb-0 in-blue-gray-900">
                    <TruncatedTextByWidthTooltip
                      text={clientProfile.email}
                      overlayClassName="ant-modal-tooltip"
                      on={['hover']}
                    />
                  </span>
                </div>
                {clientProfile.phoneNumber && (
                  <div className="d-flex flex-column min-w-0 flex-grow-1 flex-basis-0">
                    <p className="text-body mb-0 in-blue-gray-200">
                      <FormattedMessage id="waitlistRequests.sidebar.phoneNumber" />
                    </p>
                    <p className="text-body mb-0 in-blue-gray-900">{clientProfile.phoneNumber}</p>
                  </div>
                )}
              </div>

              {canSendMessage && (
                <Button
                  className="mt-16"
                  isFullWidth
                  size="small"
                  kind="secondary"
                  text={{ id: 'waitlistRequests.actions.sendMessage' }}
                  onClick={onSendMessage}
                />
              )}
            </div>
            <div className="divider divider--light" />
            <div className="pl-24 pr-24">
              <h3 className="booking-tooltip__info-title in-blue-gray-900" data-cy="tooltip-title">
                <FormattedMessage id="waitlistRequests.sidebar.serviceAndExpert" />
              </h3>
              <div className="d-flex justify-content-between align-items-center pt-16 column-gap-16">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column mw-100">
                    <span className="text-body mb-0">
                      <TruncatedTextByWidthTooltip on={['hover']} text={service.name} />
                    </span>
                  </div>
                  {userProfile && <p className="mb-0 in-blue-gray-300 text-body">{profileName(userProfile)}</p>}
                </div>
                <p className="text-body mb-0 in-blue-gray-300">{durationToFormatString(service.duration)}</p>
              </div>
            </div>
            {location && (
              <>
                <div className="divider divider--light" />
                <div className="pl-24 pr-24">
                  <h3 className="booking-tooltip__info-title" data-cy="tooltip-title">
                    <FormattedMessage id="waitlistRequests.sidebar.location" />
                  </h3>
                  <p className="text-body mb-0 pt-16">{location.name}</p>
                  <Link href={googleMapsUrlCreator(location.coordinate.x, location.coordinate.y)} isRoot>
                    <a className="main-link" target="_blank" rel="noreferrer">
                      {location.addressLine}
                    </a>
                  </Link>
                </div>
              </>
            )}
            <div className="divider divider--light" />
            <div className="pl-24 pr-24">
              <h3 className="booking-tooltip__info-title" data-cy="tooltip-title">
                <FormattedMessage id="waitlistRequests.sidebar.availabilityPreferences" />
              </h3>
              <div className="d-flex flex-column row-gap-16 mt-16">
                {paginatedAvailabilityPreferences.map(availabilityPreference => (
                  <div className="d-flex column-gap-12" key={availabilityPreference.id}>
                    <div className="d-flex align-items-center justify-content-center wh-36-36 bg-gray-200 waitlist-request__availability-icon">
                      <Calendar size={20} className="in-blue-gray-200" />
                    </div>
                    <div>
                      <p className="text-body mb-0">
                        <FormattedMessage {...getDateText(availabilityPreference)} />
                      </p>
                      <p className="text-caption mb-0 in-blue-gray-300">
                        <FormattedMessage {...getTimeText({ ...availabilityPreference, timezoneOffset })} />
                      </p>
                    </div>
                  </div>
                ))}
                {!showMore && (
                  <div>
                    <button className="in-blue-600" onClick={openShowMore}>
                      <FormattedMessage id="shared.showMore" />
                    </button>
                  </div>
                )}
              </div>
              {canBookAppointment && (
                <Button
                  className="mt-16"
                  isFullWidth
                  size="small"
                  text={{ id: 'waitlistRequests.actions.bookAppointment' }}
                  onClick={onBookAppointment}
                />
              )}
            </div>
            {noteText && (
              <>
                <div className="divider divider--light" />
                <div className="pl-24 pr-24">
                  <h3 className="booking-tooltip__info-title" data-cy="tooltip-title">
                    <FormattedMessage id="waitlistRequests.sidebar.note" />
                  </h3>
                  <p className="text-body mb-0 pt-16">{noteText}</p>
                </div>
              </>
            )}
            <div className="divider divider--light" />
            <div className="text-body in-gray-700 pl-24 pr-24 pt-16 pb-16 mt-auto">
              <FormattedMessage
                id="waitlistRequests.sidebar.createdBy"
                values={{
                  name: profileName(waitlistRequest.createdBy),
                  date: formatInputDate(dateTimeFromISO(waitlistRequest.createdAt, timezoneOffset)),
                }}
              />
            </div>
          </div>
        )}
      </section>
    </Tooltip>
  )
}

WaitlistRequestSidebar.defaultProps = {
  isWaitlistPage: false,
}

WaitlistRequestSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  waitlistRequestId: PropTypes.string.isRequired,
  isWaitlistPage: PropTypes.bool,
}

export default WaitlistRequestSidebar
