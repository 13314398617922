import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { CREATE_LOCATION } from 'state/concepts/locations/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { createLocationEndpoint } from 'state/concepts/locations/endpoints'
import { companySettingsLocationsRoute } from 'lib/routes'

const createLocationOperation = createLogic({
  type: CREATE_LOCATION,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { url } = createLocationEndpoint

    try {
      const { data } = await httpClient.post(url, {
        name: values.name,
        address_line: values.addressLine,
        status: values.status,
        coordinate: values.coordinate,
      })

      dispatch(dataApiSuccess({ response: normalize(data) }))
      redirect({ href: companySettingsLocationsRoute, workspace: currentWorkspaceCodeSelector(getState()) })
      dispatch(showNotification({ messageObject: { id: 'notifications.locationCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default createLocationOperation
