const namespace = 'forms'

export const FETCH_FORMS = `${namespace}/FETCH_FORMS`
export const SET_FORM_IDS = `${namespace}/SET_FORM_IDS`
export const RESET_FORMS = `${namespace}/RESET_FORMS`
export const SET_FORMS_BLANK_STATE = `${namespace}/SET_FORMS_BLANK_STATE`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const FILTER_INTAKE_FORMS = `${namespace}/FILTER_INTAKE_FORMS`
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`
export const RESET_ALL_FILTERS = `${namespace}/RESET_ALL_FILTERS`
