import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { touchEtherpadDocumentEndpoint } from 'state/concepts/etherpadDocuments/endpoints'
import { TOUCH_ETHERPAD_DOCUMENT } from '../types'

const touchEtherpadDocumentOperation = createLogic({
  type: TOUCH_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { documentId }, httpClient }, dispatch, done) {
    const { url, endpoint } = touchEtherpadDocumentEndpoint(documentId)

    try {
      await httpClient.put(url)
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403, 404] })
    }
    done()
  },
})

export default touchEtherpadDocumentOperation
