import React from 'react'
import PropTypes from 'prop-types'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'

const CheckboxItemFile = ({ text, icon: Icon }) => (
  <div className="d-flex align-items-center column-gap-8 min-w-0">
    <div className="d-flex align-items-center justify-content-center wh-20-20">
      <Icon size={16} className="in-disabled-icon" />
    </div>
    <div className="min-w-0">
      <div className="text-body letter-spacing-14">
        <TruncatedTextByWidthTooltip
          overlayClassName="main-tooltip--ant-custom-checkbox"
          placement="top"
          text={text}
          on={['hover']}
        />
      </div>
    </div>
  </div>
)

CheckboxItemFile.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  icon: PropTypes.func.isRequired,
}

export default CheckboxItemFile
