import classNames from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { clientPaymentRoute, companyBillingPaymentRoute } from 'lib/routes'
import isScheduler from 'utils/employeePermissions/isScheduler'
import FormattedPrice from 'views/shared/FormattedPrice'
import Link from 'views/shared/Link'
import Card from 'views/shared/icons/Card'

const BookingPriceComponent = ({
  transactions,
  booking: { price, workspace },
  className,
  iconWrapperClassName,
  iconSize,
  isClient,
  withPayments,
  currentEmployee,
}) => (
  <div className={classNames(className, 'd-flex gap-16')}>
    <div className={classNames(iconWrapperClassName, 'w-20 text-center')}>
      <Card size={iconSize} color="#6E798D" className="va-middle" />
    </div>
    <div className="flex-grow-1">
      <p className="mb-4 booking-price" data-cy="booking-price">
        <FormattedPrice price={price} />
      </p>
      {withPayments && (
        <div className="d-flex flex-column row-gap-4">
          {transactions.map(({ transactionNumber, id, status, paymentType }) => (
            <p className="d-flex align-items-center mb-0 booking-price" key={transactionNumber}>
              {isClient || !isScheduler(currentEmployee) ? (
                <Link href={isClient ? clientPaymentRoute(workspace.uniqCode, id) : companyBillingPaymentRoute(id)}>
                  <a className="main-link mr-8" data-cy="booking-payment-link">
                    <FormattedMessage id={`payment.number.${paymentType}`} values={{ number: transactionNumber }} />
                  </a>
                </Link>
              ) : (
                <span className="mr-8" data-cy="booking-payment-link">
                  <FormattedMessage id={`payment.number.${paymentType}`} values={{ number: transactionNumber }} />
                </span>
              )}

              <span className={`ml-0 main-badge main-badge--${status}`} data-cy="booking-payment-status">
                <FormattedMessage id={`payments.status.${status}`} />
              </span>
            </p>
          ))}
        </div>
      )}
    </div>
  </div>
)

BookingPriceComponent.defaultProps = {
  className: '',
  iconWrapperClassName: '',
  iconSize: 18,
  isClient: false,
  withPayments: true,
  currentEmployee: undefined,
}

BookingPriceComponent.propTypes = {
  currentEmployee: PropTypes.shape({}),
  booking: PropTypes.shape().isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  iconSize: PropTypes.number,
  isClient: PropTypes.bool,
  withPayments: PropTypes.bool,
}

export default BookingPriceComponent
