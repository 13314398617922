import { useMemo, useState, useCallback } from 'react'
import { useMount, useInterval } from 'react-use'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { prop } from 'ramda'

import { MILLISECONDS_PER_SECOND } from 'lib/constants/timeUnits'
import { CHAT_USER_ACTIVITY_MINUTES } from 'lib/constants/chat'
import { chatUserByIdSelector } from 'state/concepts/chatUsers/selectors'

const useIsOnline = chatUserId => {
  const [isOnline, setIsOnline] = useState(false)
  const chatUser = useSelector(state => chatUserByIdSelector(state, chatUserId))
  const lastActiveAt = useMemo(() => DateTime.fromISO(prop('lastActiveAt', chatUser)), [chatUser])

  const updateIsOnline = useCallback(() => {
    const newIsOnline = DateTime.now().diff(lastActiveAt, 'minutes').minutes < CHAT_USER_ACTIVITY_MINUTES
    if (isOnline !== newIsOnline) {
      setIsOnline(newIsOnline)
    }
  }, [lastActiveAt, isOnline])

  useMount(updateIsOnline)
  useInterval(updateIsOnline, MILLISECONDS_PER_SECOND)

  return isOnline
}

export default useIsOnline
