import React from 'react'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'ramda'

import { BOOKING_DECLINE_OPTIONS } from 'lib/constants/bookings'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { declineRecurringBookings } from 'state/concepts/booking/actions'
import DeclineRecurringAppointmentsModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class DeclineRecurringAppointmentsModal extends React.Component {
  static propTypes = {
    bookingId: PropTypes.string,
    bookingUniqCode: PropTypes.string,
    declineAll: PropTypes.bool,
  }

  static defaultProps = {
    bookingId: null,
    bookingUniqCode: null,
    declineAll: false,
  }

  render() {
    return <DeclineRecurringAppointmentsModalComponent {...this.props} />
  }
}

const mapDispatchToProps = {
  onSubmit: declineRecurringBookings,
}

export { DeclineRecurringAppointmentsModal as DeclineRecurringAppointmentsModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ declineAll }) => ({
      declineRecurring: declineAll ? BOOKING_DECLINE_OPTIONS.all : BOOKING_DECLINE_OPTIONS.single,
    }),
    handleSubmit: handleSubmitWithProps(['bookingId', 'bookingUniqCode']),
  }),
)(DeclineRecurringAppointmentsModal)
