import fetchAttachments from './fetchAttachments'
import deleteAttachment from './deleteAttachment'
import updateClientAttachmentCount from './updateClientAttachmentCount'
import removeClientAccess from './removeClientAccess'
import filterClientAttachments from './filterClientAttachments'
import presignClientAttachments from './presignClientAttachments'
import uploadClientAttachments from './uploadClientAttachments'
import shareFileWithClient from './shareFileWithClient'

export default [
  fetchAttachments,
  deleteAttachment,
  updateClientAttachmentCount,
  removeClientAccess,
  filterClientAttachments,
  presignClientAttachments,
  uploadClientAttachments,
  shareFileWithClient,
]
