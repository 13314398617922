import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import isPresent from 'utils/isPresent'
import { DEFAULT_NAME } from 'lib/constants/etherpadDocuments'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { renameEtherpadDocumentEndpoint } from '../endpoints'
import { RENAME_ETHERPAD_DOCUMENT } from '../types'

const renameEtherpadDocumentOperation = createLogic({
  type: RENAME_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { values, form, onSuccess }, httpClient }, dispatch, done) {
    const { name, documentId } = values
    const { url, endpoint } = renameEtherpadDocumentEndpoint(documentId)
    const trimmedName = name?.trim()
    const params = { name: trimmedName }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(hideModal())

      if (onSuccess) {
        onSuccess()
      } else {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.etherpadDocumentWasRenamed',
            },
          }),
        )
      }

      if (!isPresent(trimmedName)) form.setValues({ ...values, name: DEFAULT_NAME })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default renameEtherpadDocumentOperation
