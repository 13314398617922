import PropTypes from 'prop-types'
import classNames from 'clsx'

const Cross = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M93.94 0l-93.94 93.94 418.060 418.060-418.060 418.057 93.94 93.943 418.060-418.060 418.057 418.060 93.943-93.943-418.060-418.057 418.060-418.060-93.943-93.94-418.057 418.060-418.060-418.060z" />
  </svg>
)

Cross.defaultProps = {
  size: 12,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Cross.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Cross
