import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { REMOVE_SERVICES } from 'state/concepts/services/types'
import { fetchServices } from 'state/concepts/services/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { removeServicesEndpoint } from 'state/concepts/services/endpoints'

const removeServicesOperation = createLogic({
  type: REMOVE_SERVICES,
  latest: true,

  async process({ action: { serviceIds }, httpClient }, dispatch, done) {
    const { url, endpoint } = removeServicesEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, { service_ids: serviceIds })

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(fetchServices())
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.servicesDeleted',
            values: { count: serviceIds.length },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default removeServicesOperation
