import PropTypes from 'prop-types'
import classNames from 'clsx'

const FolderAdd = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M460.801 358.399v153.6h-153.6v102.4h153.6v153.6h102.4v-153.6h153.6v-102.4h-153.6v-153.6h-102.4z" />
    <path d="M0 204.799c0-56.026 46.374-102.4 102.4-102.4h307.2l102.4 102.4h409.6c56.028 0 102.4 46.374 102.4 102.4V819.2c0 56.028-46.372 102.4-102.4 102.4H102.399c-56.026 0-102.4-46.372-102.4-102.4V204.8zm367.202 0H102.401v614.4h819.201V307.198H469.603l-102.401-102.4z" />
  </svg>
)

FolderAdd.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

FolderAdd.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default FolderAdd
