const bookingPage = {
  'bookingPage.addBookingPage': 'Add booking page',
  'bookingPage.editBookingPage': 'Edit booking page',

  'bookingPage.removeBookingPage.title': 'Delete booking page',
  'bookingPage.removeBookingPage.description':
    'Your _(clients) will no longer be able to make new _(appointments) via this booking page. Current _(appointments) will not be removed. Connected _(services) & _(experts) will not be removed.',
  'bookingPage.removeBookingPage.delete': 'Delete booking page',
  'bookingPage.removeBookingPage': 'Delete this booking page?',

  'bookingPage.servicesAndExperts.services': '_(Services)',
  'bookingPage.servicesAndExperts.manageServices': 'Manage _(services)',
  'bookingPage.servicesAndExperts.empty.start': 'There are no available active _(services) with assigned _(experts).',
  'bookingPage.servicesAndExperts.empty.end': 'Please add appropriate _(services).',
  'bookingPage.servicesAndExperts.manageContent':
    'Manage included content to specify what _(services) & consultants will be available for booking. _(Experts) who assigned to selected _(services) will be included automatically.',
  'bookingPage.servicesAndExperts.categoryService': 'Category/_(Service)',
  'bookingPage.servicesAndExperts.experts': '_(Experts)',
  'bookingPage.servicesAndExperts.servicesHaveBeenDeactivated':
    'Some assigned _(services) were deactivated or left without active _(experts).',
  'bookingPage.servicesAndExperts.selectServicesToDisplay':
    'Please select the _(services) you’ll offer on this booking page.',

  'shareBookingPage.title': 'Share booking page - {widgetName}',
  'shareBookingPage.sideWidget': 'Side widget',
  'shareBookingPage.sideWidget.description':
    'A floating button in the corner of your website or a link that launches a booking panel as a pop-up.',
  'shareBookingPage.integrationType.widgetButton': 'Widget button',
  'shareBookingPage.integrationType.widgetButton.hint':
    'Use widget code to add an ExpertBox widget button to your website.',
  'shareBookingPage.integrationType.widgetLink': 'Widget link',
  'shareBookingPage.integrationType.widgetLink.hint':
    'Use widget code and CSS selector to add the widget to your website as a link.',
  'shareBookingPage.integrationType.both': 'Both button & link',
  'shareBookingPage.integrationType.both.hint':
    'Use widget code and CSS selector to add the widget button & link to your website.',
  'shareBookingPage.cssSelector': 'CSS selector',
  'shareBookingPage.cssSelector.description':
    'The CSS selector activates the widget from an element on your website. To link selector to an element by ID, make sure the selector starts with a hash, e.g. #id-go-consulted-widget-trigger. To link it to multiple elements by class, it should start with a dot, e.g. .id-go-consulted-widget-trigger. {link}',
  'shareBookingPage.cssSelector.learnMore': 'Learn more',
  'shareBookingPage.widgetCode': 'Widget code',
  'shareBookingPage.widgetCode.description': 'Copy and paste this code to add the widget to your website.',
  'shareBookingPage.getInstructions': 'Get email instructions',
  'shareBookingPage.getInstructions.description':
    'Send code with installation instructions to your web administrator who can paste it on your website.',
  'shareBookingPage.getInstructions.sendButton': 'Send instructions',
  'shareBookingPage.getInstructions.resendButton': 'Resend in {time}',
  'shareBookingPage.embeddedWidget': 'Embedded widget',
  'shareBookingPage.embeddedWidget.description':
    'A booking panel that you can seamlessly fit into the content on your website.',
  'shareBookingPage.customBookingPage': 'Custom booking page',
  'shareBookingPage.customBookingPage.description': 'A standalone booking web page that you can share as a link.',
  'shareBookingPage.customBookingPage.instructions':
    'Your booking link always starts with {link}, but you can customize the ending to be anything you want.',
  'shareBookingPage.customBookingPage.urlAddress': 'URL address',
  'shareBookingPage.customBookingPage.customize': 'To customize your booking link go to {link}',
  'shareBookingPage.customBookingPage.customize.link': 'Booking page settings.',
  'shareBookingPage.customizeWidgetButton': 'Customize button & position',

  'bookingPage.customizeWidget.position': 'Position',
  'bookingPage.customizeWidget.position.description': 'Choose a position for widget and button.',
  'bookingPage.customizeWidget.position.widget': 'Widget position',
  'bookingPage.customizeWidget.position.button': 'Button position',
  'bookingPage.customizeWidget.buttonAppearance': 'Button appearance',
  'bookingPage.customizeWidget.buttonAppearance.color': 'Button color',
  'bookingPage.customizeWidget.buttonAppearance.iconColor': 'Button icon color',
  'bookingPage.customizeWidget.buttonAppearance.replaceIcon': 'Replace button icon with text title',
  'bookingPage.customizeWidget.buttonAppearance.text': 'Button title',
  'bookingPage.customizeWidget.buttonAppearance.textColor': 'Text color',
  'bookingPage.customizeWidget.moveLeft': 'Move left',
  'bookingPage.customizeWidget.moveRight': 'Move right',

  'createBookingPage.steps.settings': 'Set up booking page',
  'createBookingPage.steps.services': 'Add _(services)',
  'createBookingPage.steps.services.back': 'Back to setting up',
  'createBookingPage.steps.services.form.submit': 'Create booking page',

  'editBookingPage.tabs.settings': 'General settings',
  'editBookingPage.tabs.services': 'Assigned _(services)',
  'editBookingPage.appearance.editLogoAndName': 'Edit company logo and name in {link}',
  'editBookingPage.appearance.businessInformation': 'Business information',

  'bookingPage.bookingPageName': 'Booking page name',
  'bookingPage.bookingPageName.description':
    'This name will be visible for you and other members of your workspace. Your _(clients) won’t see it.',
  'bookingPage.bookingPageName.placeholder': 'e.g., Personal consultations',
  'bookingPage.bookingPageName.validation.required': 'Please enter a page name',
  'bookingPage.bookingPageLink': 'Booking link',
  'bookingPage.bookingPageLink.description':
    'Your booking page link always starts with {customPageUrl}, but you can customize the ending to be anything you want.',
  'bookingPage.bookingPageLink.validation.required': 'Please enter a URL',
  'bookingPage.bookingPageLink.validation.characters': 'Please use only downcased letters, numbers, and dashes',
  'bookingPage.appearance': 'Appearance',
  'bookingPage.appearance.description': 'Choose the primary color of your booking page.',
  'bookingPage.appearance.primaryColor': 'Primary color',
  'bookingPage.appearance.validation.required': 'Please select a widget color',
  'bookingPage.sidebar.status.title': 'Booking page status',
  'bookingPage.sidebar.status.description':
    'Active booking page will be visible for _(clients). You can deactivate it anytime to hide it.',
  'bookingPage.sidebar.status.label.active': 'Active',
  'bookingPage.sidebar.status.label.inactive': 'Deactivated',
  'bookingPage.addServices': 'Add _(services)',
  'bookingPage.services.description': 'Choose _(services) your _(clients) will be able to book via the booking page.',
  'bookingPage.services.tooltip.expand': 'Expand',
  'bookingPage.services.tooltip.collapse': 'Collapse',
  'bookingPage.preview': 'Preview booking page',
}

export default bookingPage
