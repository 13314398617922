import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ArrowUp from 'views/shared/icons/ArrowUp'

const ShowLessButton = ({ toggleTruncate }) => (
  <div className="mt-4">
    <button className="in-blue-600 font-14 font-600" onClick={toggleTruncate} data-cy="show-less-btn">
      <span className="mr-8">
        <FormattedMessage id="shared.showLess" />
      </span>
      <ArrowUp dataCy="arrow-up-icon" color="currentColor" size={12} />
    </button>
  </div>
)

ShowLessButton.propTypes = {
  toggleTruncate: PropTypes.func.isRequired,
}

export default ShowLessButton
