const businessFilesFeature = {
  'businessFilesFeature.title': 'Business files',
  'businessFilesFeature.subtitle': '<span>in</span> Forms & documents',
  'businessFilesFeature.description': 'Store and share important files for your business in your Business files.',
  'businessFilesFeature.overview': 'Business files overview',
  'businessFilesFeature.subheader':
    'Store, organize, and share important files for your business seamlessly with ExpertBox.',
  'businessFilesFeature.organizeFiles': 'Efficiently organize files:',
  'businessFilesFeature.accessFiles': 'Access all your essential files in one secure repository.',
  'businessFilesFeature.categorizeFiles': 'Categorize files into folders for easy navigation and quick retrieval.',
  'businessFilesFeature.locateFiles': 'Quickly locate specific files using powerful search and filtering options.',
  'businessFilesFeature.shareFiles': 'Effortlessly share files:',
  'businessFilesFeature.shareFilesWithTeam':
    'Share files with team members or your _(clients) directly from the repository.',
  'businessFilesFeature.assignPermissions':
    'Assign specific access permissions to different team members, controlling who can view, edit, or delete files.',
  'businessFilesFeature.toLearnMores':
    'To learn more, read our help center article on <link>setting Business files</link>.',
  'businessFilesFeature.disableBusinessFiles': 'Disable business files feature?',
  'businessFilesFeature.disableBusinessFilesWarning': `You won't be able to store business files and share them with your team members.`,
  'businessFilesFeature.disableBusinessFilesAlert': `All the business files will be permanently deleted in <b>30 days</b> for everyone in the workspace. Enable the feature within this time to restore them.`,
  'businessFilesFeature.premiumFeature': 'Business files is a Premium feature.',
  'businessFilesFeature.deletionAlert':
    'All the business files will be permanently deleted in {daysCount} {daysCount, plural, one {day} other {days}} for everyone in the workspace. Enable the feature within this time to restore them.',
  'businessFilesFeature.todayDeletionAlert':
    'All the business files will be permanently deleted today for everyone in the workspace. Enable the feature within this time to restore them.',
}

export default businessFilesFeature
