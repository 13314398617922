import normalize from 'json-api-normalizer'
import { omit, pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import { PAGINATION_ITEMS_PER_PAGE, TABLE_ENTITIES } from 'lib/constants/tableData'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { tableOrderDirectionsSelector } from 'state/tableData/selectors'
import generateSortString from 'utils/generateSortString'
import isBlankState from 'utils/isBlankState'
import { addStorageFilesIds, setAutoUpgrade, setBlankState, setStorageFileIds, setStorageSpace } from '../actions'
import { fetchStorageFilesEndpoint } from '../endpoints'
import { filtersSelector } from '../selectors'
import { FETCH_STORAGE_FILES } from '../types'

const fetchStorageFilesOperation = createLogic({
  type: FETCH_STORAGE_FILES,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const {
      pageParams: { page = 1, size },
      options: { append = true, preview = false },
    } = action

    const { url, endpoint } = fetchStorageFilesEndpoint

    const state = getState()
    const { name, employees, types, lastModified, clients } = filtersSelector(state)
    const dates = lastModified?.[0]
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.storageFiles))

    const filter = {
      name,
      types,
      preview,
      user_profile_ids: employees,
      client_profile_ids: clients,
      modified_after: dates?.modifiedAfter?.toISO(),
      modified_before: dates?.modifiedBefore?.toISO(),
    }

    const params = {
      page: { number: page, size: size || PAGINATION_ITEMS_PER_PAGE },
      include: ['owner'],
      filter,
      sort,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const blankStateParams = omit(['preview'], filter)

      dispatch(setBlankState(isBlankState({ data: data.data, params: blankStateParams })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setStorageSpace(data.meta))
      dispatch(setAutoUpgrade(data.meta.auto_upgrade))

      const ids = pluck('id', data.data)
      append ? dispatch(addStorageFilesIds(ids)) : dispatch(setStorageFileIds(ids))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchStorageFilesOperation
