import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { guestDocumentNotFoundRoute } from 'lib/routes'
import { isGuestLoggedInSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isPresent from 'utils/isPresent'
import redirect from 'utils/redirect'
import setEtherpadSession from 'utils/setEtherpadSession'
import { setEtherpadDocumentId } from '../actions'
import { getEtherpadDocumentEndpoint } from '../endpoints'
import { GET_ETHERPAD_DOCUMENT } from '../types'

const getEtherpadDocumentOperation = createLogic({
  type: GET_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { documentId, workspace, ctx }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = getEtherpadDocumentEndpoint(documentId)
    const isServer = isPresent(ctx?.res)

    dispatch(dataApiRequest({ endpoint }))

    const customBaseUrl = isServer
      ? buildCustomBaseUrl(['workspaces', workspace])
      : { customBaseURL: `/api/${workspace}` }

    const params = {
      include: [
        'user-profile',
        'client-profile',
        'document_permissions',
        'document_signatories.signature',
        'document_signatories.profileable',
        'shared-client-contacts.client-relationship-type',
        'shared-client-contacts.contact-client-profile',
      ].join(','),
    }

    try {
      const { data } = await httpClient.get(url, { params, ...customBaseUrl })

      if (isServer) {
        setEtherpadSession(ctx, data.meta?.etherpad_session)
      }
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setEtherpadDocumentId(documentId))
    } catch (error) {
      if (isGuestLoggedInSelector(getState())) {
        redirect({ href: guestDocumentNotFoundRoute })
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default getEtherpadDocumentOperation
