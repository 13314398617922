import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchClient = (clientId, onSuccess = undefined) => ({
  type: types.FETCH_CLIENT,
  clientId,
  onSuccess,
})

export const createClient = makeFormSubmitAction(types.CREATE_CLIENT)
export const updateClient = makeFormSubmitAction(types.UPDATE_CLIENT)

export const deleteClient = clientId => ({
  type: types.DELETE_CLIENT,
  clientId,
})

export const sendInvitationToClientPortal = client => ({
  type: types.SEND_INVITATION_TO_CLIENT_PORTAL,
  client,
})

export const updateClientOwnProfile = makeFormSubmitAction(types.UPDATE_CLIENT_OWN_PROFILE)

export const uploadClientOwnAvatar = avatar => {
  const formData = new FormData()
  formData.append('avatar', avatar, avatar.name)

  return {
    type: types.UPLOAD_CLIENT_OWN_AVATAR,
    data: formData,
  }
}

export const removeClientOwnAvatar = () => ({
  type: types.REMOVE_CLIENT_OWN_AVATAR,
})

export const setClient = clientId => ({
  type: types.SET_CLIENT,
  clientId,
})

export const getClientOwnProfile = () => ({
  type: types.GET_CLIENT_OWN_PROFILE,
})

export const deleteClientOwnAccount = makeFormSubmitAction(types.DELETE_CLIENT_OWN_ACCOUNT)
export const sendDeleteConfirmationCode = makeFormSubmitAction(types.SEND_DELETE_CONFIRMATION_CODE)
export const resendDeleteConfirmationCode = email => ({
  type: types.SEND_DELETE_CONFIRMATION_CODE,
  values: { email },
})

export const updateClientGeneralSettings = makeFormSubmitAction(types.UPDATE_CLIENT_GENERAL_SETTINGS)

export const confirmClientOwnEmail = token => ({
  type: types.CONFIRM_CLIENT_OWN_EMAIL,
  token,
})

export const validateConfirmationToken = token => ({
  type: types.VALIDATE_CONFIRMATION_TOKEN,
  token,
})

export const resendEmailConfirmation = email => ({
  type: types.RESEND_EMAIL_CONFIRMATION,
  email,
})

export const resendEmailChangeConfirmation = () => ({
  type: types.RESEND_EMAIL_CHANGE_CONFIRMATION,
})

export const fetchNotificationSettings = () => ({
  type: types.FETCH_NOTIFICATION_SETTINGS,
})

export const setNotificationSettingId = notificationSettingId => ({
  type: types.SET_NOTIFICATION_SETTING_ID,
  notificationSettingId,
})

export const updateNotificationSettings = makeFormSubmitAction(types.UPDATE_NOTIFICATION_SETTINGS)

export const updateClientProfileFields = makeFormSubmitAction(types.UPDATE_CLIENT_PROFILE_FIELDS)

export const shareFiles = makeFormSubmitAction(types.SHARE_FILES)

export const sendDocuments = makeFormSubmitAction(types.SEND_DOCUMENTS)

export const setIsClientDeleted = isDeleted => ({
  type: types.SET_IS_CLIENT_DELETED,
  isDeleted,
})

export const restoreAccount = onSuccess => ({
  type: types.RESTORE_ACCOUNT,
  onSuccess,
})

export const assignExperts = makeFormSubmitAction(types.ASSIGN_CLIENT_EXPERTS)

export const restoreContactAccount = (contactId, onSuccess) => ({
  type: types.RESTORE_CONTACT_ACCOUNT,
  onSuccess,
  contactId,
})
