import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import {
  TWO_FACTOR_SETTING_METHODS,
  TWO_FACTOR_SETTING_STEPS,
  SETUP_TWO_FACTOR_SETTING_STEPS,
} from 'lib/constants/twoFactorSettings'

const SelectMethodButton = ({ method, setStep, isSetup }) => {
  const { setFieldValue } = useFormikContext()

  const steps = isSetup ? SETUP_TWO_FACTOR_SETTING_STEPS : TWO_FACTOR_SETTING_STEPS

  const onPhoneNumber = () => {
    setFieldValue('methodChecker', TWO_FACTOR_SETTING_METHODS.sms)
    setStep(steps.verifyNumber)
  }
  const onApp = () => {
    setFieldValue('methodChecker', TWO_FACTOR_SETTING_METHODS.app)
    setStep(steps.enableApp)
  }

  if (method === TWO_FACTOR_SETTING_METHODS.email) {
    return (
      <SubmitFormButton
        kind="secondary"
        className="methods-2fa__action main-btn--icon-only d-flex align-items-center justify-content-center wh-40-40"
        icon="arrow-right"
        iconSize={10}
        iconClassName="in-blue-600"
        isDisabled={false}
        isLoading={false}
        dataCy="set-email-2fa"
      />
    )
  }

  return (
    <Button
      kind="secondary"
      className="methods-2fa__action main-btn--icon-only d-flex align-items-center justify-content-center wh-40-40"
      icon="arrow-right"
      iconSize={10}
      iconClassName="in-blue-600"
      dataCy={`set-${method}-2fa`}
      onClick={method === TWO_FACTOR_SETTING_METHODS.sms ? onPhoneNumber : onApp}
    />
  )
}

SelectMethodButton.propTypes = {
  method: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  isSetup: PropTypes.bool.isRequired,
}

export default SelectMethodButton
