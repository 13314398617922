import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const reviewSettingId = (state = null, action) => {
  switch (action.type) {
    case types.SET_REVIEW_SETTING_ID:
      return action.id
    default:
      return state
  }
}

export default combineReducers({
  reviewSettingId,
})
