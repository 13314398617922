import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const SettingsO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 0l442.182 256v512l-442.182 256-442.182-256v-512l442.182-256zM512 107.567l-349.091 202.105v404.656l349.091 202.105 349.091-202.105v-404.656l-349.091-202.105zM512 698.182c-102.825 0-186.182-83.358-186.182-186.182s83.356-186.182 186.182-186.182c102.824 0 186.182 83.356 186.182 186.182 0 102.824-83.358 186.182-186.182 186.182zM512 605.091c51.414 0 93.091-41.677 93.091-93.091s-41.677-93.091-93.091-93.091c-51.414 0-93.091 41.677-93.091 93.091s41.677 93.091 93.091 93.091z" />
  </svg>
)

SettingsO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

SettingsO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default SettingsO
