import PropTypes from 'prop-types'

import { fileSize } from 'utils/file'
import DownloadButton from 'views/shared/AppointmentDetails/AttachmentActions/DownloadButton'
import RemoveButton from 'views/shared/AppointmentDetails/AttachmentActions/RemoveButton'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import Attachment from 'views/shared/icons/Attachment'
import Options from 'views/shared/icons/Options'
import DropdownMenu from 'views/shared/DropdownMenu'

const RecordingComponent = ({
  isAppointmentDetails,
  onConfirmRemove,
  videoRecord: { filename, size, url, id },
  canRemove,
  canManage,
  onShowPreview,
  hasSize,
  hasIcon,
  clickable,
}) => {
  if (isAppointmentDetails) {
    return (
      <div className="appointment-attachment d-flex align-items-center justify-content-between">
        <div>
          {canManage ? (
            <div className="main-link c-pointer" onClick={() => onShowPreview(id)} role="button">
              <TruncatedTextByWidthTooltip placement="top" text={filename} on={['hover']} />
            </div>
          ) : (
            <span className="main-link">
              <TruncatedTextByWidthTooltip placement="top" text={filename} on={['hover']} />
            </span>
          )}
          <p className="in-gray-700 mb-0">{fileSize(size)}</p>
        </div>
        {canManage && (
          <DropdownMenu
            placement="bottomRight"
            align={{ offset: [0, -2] }}
            className="main-dropdown-btn"
            menuClassName="main-dropdown relative min-w-185"
            icon={
              <button className="wh-24-24 d-flex align-items-center justify-content-center" type="button">
                <Options className="main-dropdown-btn__icon" />
              </button>
            }
            items={[
              {
                itemProps: {
                  text: { id: 'shared.preview' },
                  onClick: () => onShowPreview(id),
                },
              },
              {
                itemProps: {
                  text: { id: 'shared.download' },
                  isLink: true,
                  href: url,
                  target: '_blank',
                },
              },
              {
                isHidden: !canRemove,
                itemProps: {
                  text: { id: 'shared.delete' },
                  onClick: onConfirmRemove(id),
                },
              },
            ]}
          />
        )}
      </div>
    )
  }

  return (
    <div className="d-flex align-items-start">
      <div className="d-flex gap-16">
        <div className="no-shrink w-20 text-center">
          {hasIcon && <Attachment className="in-blue-gray-200" size={18} />}
        </div>
        <div className="min-w-0 flex-1">
          {canManage ? (
            <div
              className="main-link c-pointer w-100"
              onClick={clickable ? () => onShowPreview(id) : null}
              role="button"
              data-cy="preview"
            >
              <TruncatedTextByWidthTooltip placement="top" text={filename} on={['hover']} />
            </div>
          ) : (
            <span className="main-link w-100">
              <TruncatedTextByWidthTooltip placement="top" text={filename} on={['hover']} />
            </span>
          )}
          {hasSize && <div className="in-gray-500">{fileSize(size)}</div>}
        </div>
        {url && <DownloadButton url={url} />}
        {canRemove && <RemoveButton onClick={onConfirmRemove(id)} />}
      </div>
    </div>
  )
}

RecordingComponent.defaultProps = {
  hasSize: true,
  hasIcon: true,
  clickable: true,
  onConfirmRemove: undefined,
  onShowPreview: undefined,
  isAppointmentDetails: false,
}

RecordingComponent.propTypes = {
  onConfirmRemove: PropTypes.func,
  videoRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.number,
  }).isRequired,
  canRemove: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  onShowPreview: PropTypes.func,
  hasSize: PropTypes.bool,
  hasIcon: PropTypes.bool,
  clickable: PropTypes.bool,
  isAppointmentDetails: PropTypes.bool,
}

export default RecordingComponent
