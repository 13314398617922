import PropTypes from 'prop-types'
import React from 'react'

import DatePickerFieldComponent from 'views/shared/DatePicker'

class DatePickerField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.shape(),
      name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape({}),
      touched: PropTypes.shape({}),
      setFieldTouched: PropTypes.func,
    }).isRequired,
  }

  handleChange = date => {
    const { field } = this.props

    field.onChange({ target: { name: field.name, value: date } })
  }

  onChangeOpen = isOpen => {
    const {
      field,
      form: { setFieldTouched },
    } = this.props

    if (!isOpen) setFieldTouched(field.name)
  }

  render() {
    const { field } = this.props

    return (
      <DatePickerFieldComponent
        {...this.props}
        date={field.value}
        onChange={this.handleChange}
        onChangeOpen={this.onChangeOpen}
      />
    )
  }
}

export default DatePickerField
