import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import { CHAT_TYPES } from 'lib/constants/chat'
import isPresent from 'utils/isPresent'
import profileInitials from 'utils/profileInitials'
import GroupFilled from 'views/shared/icons/GroupFilled'

const LogoImage = ({ logoUrl, name, chatType }) => {
  if (isPresent(logoUrl))
    return <Image src={logoUrl} alt={name} className="chat-participants-card__img" layout="fill" />

  return chatType === CHAT_TYPES.direct ? (
    <p className="main-userpic__initials" data-cy="user-initials">
      {profileInitials({ fullName: name })}
    </p>
  ) : (
    <GroupFilled color="white" size={20} dataCy="group-chat-icon" />
  )
}

LogoImage.defaultProps = {
  logoUrl: undefined,
}

LogoImage.propTypes = {
  name: PropTypes.string.isRequired,
  chatType: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
}

export default LogoImage
