import PropTypes from 'prop-types'
import { compose } from 'ramda'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { hasLocationsSelector } from 'state/concepts/session/selectors'
import isEmail from 'utils/isEmail'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import TooltipForm from './TooltipForm'

class ScheduleAppointment extends React.Component {
  static propTypes = {
    values: PropTypes.shape().isRequired,
    intl: PropTypes.shape().isRequired,
    isLocationsEnabled: PropTypes.bool.isRequired,
    hasLocations: PropTypes.bool.isRequired,
    isWaitlist: PropTypes.bool.isRequired,
  }

  get showClientForm() {
    const {
      values: { clientProfile },
    } = this.props

    return isEmail(clientProfile?.idOrEmail)
  }

  get showLocationsSelect() {
    const { hasLocations, isLocationsEnabled, isWaitlist } = this.props

    return (isWaitlist || hasLocations) && isLocationsEnabled
  }

  get customServiceSelected() {
    const {
      values: { serviceId },
    } = this.props

    return serviceId === this.customService.id
  }

  get customService() {
    const {
      intl: { formatMessage },
    } = this.props

    return { id: null, name: formatMessage({ id: 'services.assignExpertDropdown.customService' }) }
  }

  render() {
    return (
      <TooltipForm
        {...this.props}
        customService={this.customService}
        customServiceSelected={this.customServiceSelected}
        showClientForm={this.showClientForm}
        showLocationsSelect={this.showLocationsSelect}
      />
    )
  }
}

const mapStateToProps = state => ({
  hasLocations: hasLocationsSelector(state),
  isLocationsEnabled: isEnableWorkspaceFeatureSelector(state, FEATURE_KEYS.locations),
})

export { ScheduleAppointment as ScheduleAppointmentContainer }
export default compose(connect(mapStateToProps), injectIntl)(ScheduleAppointment)
