import { createLogic } from 'redux-logic'

import { FILTER_CALENDAR_EVENTS } from 'state/concepts/bookings/types'
import { setFilterParams, fetchCalendarEvents } from 'state/concepts/bookings/actions'

const filterCalendarEventsOperation = createLogic({
  type: FILTER_CALENDAR_EVENTS,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(fetchCalendarEvents())
    done()
  },
})

export default filterCalendarEventsOperation
