const namespace = 'form'

export const FETCH_FORM = `${namespace}/FETCH_FORM`
export const SET_FORMS_ID = `${namespace}/SET_FORMS_ID`
export const SUBMIT_FORM = `${namespace}/SUBMIT_FORM`
export const FETCH_FORM_SUBMISSION = `${namespace}/FETCH_FORM_SUBMISSION`
export const CREATE_ATTACHMENT_UPLOAD = `${namespace}/CREATE_ATTACHMENT_UPLOAD`
export const SET_UPLOAD_PROGRESS = `${namespace}/SET_UPLOAD_PROGRESS`
export const RESET_UPLOAD_PROGRESS = `${namespace}/RESET_UPLOAD_PROGRESS`
export const FETCH_CLIENT_PROFILE_FORM = `${namespace}/FETCH_CLIENT_PROFILE_FORM`
export const UPDATE_FORM_SUBMISSION = `${namespace}/UPDATE_FORM_SUBMISSION`
export const AUTO_SAVE_RESPONSES = `${namespace}/AUTO_SAVE_RESPONSES`
export const SET_FORM_SUBMISSION_ID = `${namespace}/SET_FORM_SUBMISSION_ID`
export const SET_IS_AUTO_SAVE_ERROR = `${namespace}/SET_IS_AUTO_SAVE_ERROR`
export const CANCEL_AUTO_SAVE_RESPONSES = `${namespace}/CANCEL_AUTO_SAVE_RESPONSES`
export const SIGN_FORM_SUBMISSION = `${namespace}/SIGN_FORM_SUBMISSION`
export const DECLINE_TO_SIGN_FORM = `${namespace}/DECLINE_TO_SIGN_FORM`
export const CLEAR_AUTO_SAVE_RESPONSES = `${namespace}/CLEAR_AUTO_SAVE_RESPONSES`
