import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { usersInvitationsRoute as url } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { INVITE_EMPLOYEES } from 'state/concepts/employees/types'
import { setCurrentPage, fetchEmployees } from 'state/concepts/employees/actions'
import { hideModal } from 'state/modal/actions'

const inviteEmployeesOperation = createLogic({
  type: INVITE_EMPLOYEES,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    try {
      await httpClient.post(url, values)

      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.membersInvited' } }))
      dispatch(setCurrentPage(1))
      dispatch(fetchEmployees())
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default inviteEmployeesOperation
