import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const ActivateWarningModal = ({ count, onClose }) => (
  <Modal className="main-modal" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="servicesActivateWarningModal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p>
          <FormattedMessage id="servicesActivateWarningModal.body" values={{ count }} />
        </p>
        <p className="mb-0">
          <FormattedMessage id="servicesActivateWarningModal.warning" values={{ count }} />
        </p>
      </div>
      <div className="main-modal__footer">
        <div>
          <Button className="main-modal__footer-action" type="submit" text={{ id: 'shared.ok' }} onClick={onClose} />
        </div>
      </div>
    </div>
  </Modal>
)

ActivateWarningModal.defaultProps = {
  count: 1,
}

ActivateWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  count: PropTypes.number,
}

export default ActivateWarningModal
