import { DateTime } from 'luxon'

import { durationByStartAndEndTime } from 'utils/bookings'

const initialRescheduleRecurringFormValues = (
  { id, startTime, endTime, service, uniqCode, userProfile, invitedMembers },
  isSingle = true,
  isEdit = false,
) => {
  const dateTime = DateTime.fromISO(startTime)
  const duration = durationByStartAndEndTime(startTime, endTime)

  return {
    serviceId: service.id,
    userProfileId: userProfile.id,
    bookingId: id,
    date: dateTime,
    time: dateTime,
    reason: '',
    duration,
    uniqCode,
    isSingle,
    isEdit,
    invitedMembers,
  }
}

export default initialRescheduleRecurringFormValues
