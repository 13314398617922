import { createLogic } from 'redux-logic'
import { path } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { CREATE_SETUP_INTENT } from 'state/concepts/billing/types'
import { setSetupIntentClientSecret, setStripePublishableApiKey } from 'state/concepts/billing/actions'
import { createSetupIntentEndpoint } from 'state/concepts/billing/endpoints'

const createSetupIntentOperation = createLogic({
  type: CREATE_SETUP_INTENT,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = createSetupIntentEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url)
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setSetupIntentClientSecret(path(['meta', 'client_secret'], data)))
      dispatch(setStripePublishableApiKey(path(['meta', 'publishable_api_key'], data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default createSetupIntentOperation
