import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_TRANSACTIONS } from 'state/concepts/payments/types'
import { setTransactions, setBlankState } from 'state/concepts/payments/actions'
import { paginationSelector, filtersSelector } from 'state/concepts/payments/selectors'
import { fetchTransactionsEndpoint } from 'state/concepts/payments/endpoints'

const fetchTransactionsOperation = createLogic({
  type: FETCH_TRANSACTIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchTransactionsEndpoint
    const state = getState()
    const { name, action, statuses, employees, services, clients } = filtersSelector(state)
    const params = {
      include: [
        'user-profile',
        'client-profile',
        'booking',
        'receipt-template',
        'transaction-items',
        'refunded-transaction',
        'recurring-payment',
      ],
      page: paginationSelector(state),
      filter: {
        name,
        action,
        statuses,
        service_ids: services,
        user_profile_ids: employees,
        client_profile_ids: clients,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setTransactions(data.data.map(item => item.id)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchTransactionsOperation
