import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { path } from 'ramda'

import formValuesToParams from 'utils/clientsImport/formValuesToParams'
import mapResponseErrorsToForm from 'utils/clientsImport/mapResponseErrorsToForm'
import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { VALIDATE_CLIENT_IMPORT } from 'state/concepts/companyClientImport/types'
import { validateClientImportEndpoint } from 'state/concepts/companyClientImport/endpoints'
import { clientImportIdSelector } from 'state/concepts/companyClientImport/selectors'
import { setMatchFormState, setErrorsFileUrl } from 'state/concepts/companyClientImport/actions'

const validateClientImportOperation = createLogic({
  type: VALIDATE_CLIENT_IMPORT,
  latest: true,

  async process({ action: { form, values, onNextStep }, httpClient, getState }, dispatch, done) {
    const clientImportId = clientImportIdSelector(getState())
    const { endpoint, url } = validateClientImportEndpoint(clientImportId)

    dispatch(dataApiRequest({ endpoint }))

    const params = formValuesToParams(values)

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })
      const errorsFileUrl = path(['data', 'attributes', 'errors-file-url'], data)

      if (errorsFileUrl) {
        dispatch(setErrorsFileUrl(errorsFileUrl))
        form.setSubmitting(false)
      } else {
        onNextStep()
      }

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setMatchFormState(values))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const formErrors = mapResponseErrorsToForm(values, error)

        form.setErrors(formErrors)
      }

      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default validateClientImportOperation
