import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ArrowRight from 'views/shared/icons/ArrowRight'
import Tooltip from 'views/shared/Tooltip'

const NextArrow = ({ onClick, nextArrowTooltip }) => (
  <Tooltip
    isShown={nextArrowTooltip}
    align={{ offset: [0, -3] }}
    overlayClassName="main-tooltip main-tooltip--no-pointer"
    placement="top"
    icon={
      <button
        type="button"
        onClick={onClick}
        className="files-preview__btn files-preview__btn--next files-preview__btn--modal"
        data-cy="preview-carousel-next-button"
      >
        <ArrowRight dataCy="arrow-right-icon" className="in-white" size={10} />
      </button>
    }
  >
    <FormattedMessage id="shared.next" />
  </Tooltip>
)

NextArrow.defaultProps = {
  onClick: undefined,
  nextArrowTooltip: false,
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
  nextArrowTooltip: PropTypes.bool,
}

export default NextArrow
