import PropTypes from 'prop-types'
import Modal from 'views/shared/Modal'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import profileName from 'utils/profileName'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import CheckboxField from 'views/shared/CheckboxField'
import Cross from 'views/shared/icons/Cross'

const EmployeesDeactivateModalComponent = ({ onClose, employee, handleSubmit }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employeesDeactivateModal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p className="mb-16">
          <FormattedMessage
            id="employeesDeactivateModal.areYouSure"
            values={{
              name: <span className="font-600">{profileName(employee)}</span>,
            }}
          />
        </p>
        <p className="mb-16">
          <FormattedMessage id="employeesDeactivateModal.allTheUpcomingAppointments" />
        </p>
        <p className="mb-16">
          <FormattedMessage id="employeesDeactivateModal.theTeamMemberWillNot" />
        </p>
        <div className="main-modal__item pt-0">
          <Field
            className="mb-0"
            name="cancelAndRefund"
            component={CheckboxField}
            id="cancelAndRefund"
            label={{ id: 'employeesDeactivateModal.checkbox.cancelAndRefund' }}
          />
        </div>
        <div className="main-modal__item pt-0">
          <Field
            className="mb-0"
            name="deactivateAccount"
            component={CheckboxField}
            id="deactivateAccount"
            label={{ id: 'employeesDeactivateModal.checkbox.deactivateTeamMembersAccount' }}
          />
        </div>
      </div>
      <div className="main-modal__footer">
        <div>
          <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
          <SubmitFormButton
            extraClasses="main-modal__footer-action"
            text={{ id: 'employeesDeactivateModal.button' }}
            kind="danger"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  </Modal>
)

EmployeesDeactivateModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default EmployeesDeactivateModalComponent
