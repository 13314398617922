import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, not, omit, path, values } from 'ramda'

import {
  currentPageSelector,
  dataSelector,
  loadingSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchTrashedFilesEndpoint } from './endpoints'

export const trashedFileIdsSelector = path(['trashedFiles', 'ids'])

export const trashedFilesSelector = createSelector(
  [trashedFileIdsSelector, dataSelector],
  buildCollection('businessStorage'),
)

export const trashedFilesByIdsSelector = createSelector(
  [(_, ids) => ids, dataSelector],
  buildCollection('businessStorage'),
)

export const isBlankStateSelector = path(['trashedFiles', 'isBlankState'])

export const filtersSelector = path(['trashedFiles', 'filters'])

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(compose(not, isEmpty)), values, omit(['name'])),
)

export const trashedFilesCurrentPageSelector = state => currentPageSelector(state, fetchTrashedFilesEndpoint.endpoint)
export const trashedFilesNextPageSelector = state => nextPageSelector(state, fetchTrashedFilesEndpoint.endpoint)
export const trashedFilesPageCountSelector = state => pageCountSelector(state, fetchTrashedFilesEndpoint.endpoint)

export const appliedFiltersSelector = (state, name) => path(['trashedFiles', 'filters', name], state)

export const fetchTrashedFilesLoadingSelector = state => loadingSelector(state, fetchTrashedFilesEndpoint.endpoint)

export const selectedIdsSelector = path(['trashedFiles', 'selectedIds'])
