import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_OTP_CODE_BY_SMS } from 'state/concepts/employee/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { getOtpCodeBySmsEndpoint } from '../endpoints'

const getOtpCodeBySmsOperation = createLogic({
  type: GET_OTP_CODE_BY_SMS,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess }, getState }, dispatch, done) {
    const { endpoint, url } = getOtpCodeBySmsEndpoint
    const { phoneNumber } = values
    const params = { phone_number: phoneNumber }

    const workspaceCode = currentWorkspaceCodeSelector(getState())
    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params, buildCustomBaseUrl(['workspaces', workspaceCode]))

      dispatch(dataApiSuccess({ endpoint }))
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form?.setSubmitting(false)
    done()
  },
})

export default getOtpCodeBySmsOperation
