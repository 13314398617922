import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Close = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512zM512 439.593l-144.816-144.816-72.408 72.408 144.816 144.816-144.816 144.814 72.408 72.407 144.816-144.814 144.814 144.814 72.407-72.407-144.814-144.814 144.814-144.816-72.407-72.408-144.814 144.816z" />
  </svg>
)

Close.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Close.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Close
