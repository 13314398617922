import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import { useToggle } from 'react-use'

import { companySettingsPaymentRoute } from 'lib/routes'
import { ALERT_KINDS } from 'lib/constants'
import { PRICE_OPTIONS } from 'lib/constants/scheduleAppointment'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import {
  isWorkspaceOwnerSelector,
  currentWorkspacePaymentMethodConnectedSelector,
} from 'state/concepts/session/selectors'
import CurrencyField from 'views/shared/CurrencyField'
import SelectField from 'views/shared/SelectField'
import SwitchField from 'views/shared/SwitchField'
import CardCustomIcon from 'views/shared/icons/Card'
import Alert from 'views/shared/Alert'
import Link from 'views/shared/Link'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import PaymentMessage from './PaymentMessage'

const PriceComponent = ({
  serviceSelected,
  isFreePrice,
  onIsFreePriceChange,
  manualPaymentEnabled,
  paymentEnabled,
  price,
}) => {
  const isWorkspaceOwner = useSelector(isWorkspaceOwnerSelector)
  const isPaymentsMethodEnabled = useSelector(currentWorkspacePaymentMethodConnectedSelector)
  const isAcceptPaymentsEnabled = useWorkspaceFeature(FEATURE_KEYS.acceptPayments)

  const isAlertShown = isAcceptPaymentsEnabled && !isPaymentsMethodEnabled
  const [isAlertOpened, setIsAlertOpened] = useToggle(isAlertShown)

  return (
    serviceSelected && (
      <div className="booking-tooltip__info booking-tooltip__info-divider">
        <div className="d-flex payment-price-wrap">
          <div className="mr-16 pt-36">
            <CardCustomIcon size={20} color="#6e798d" />
          </div>
          <div className="w-100">
            <div className="d-flex">
              <div className="w-50 mr-8 payment-wrap">
                <Field
                  className="mb-0"
                  type="text"
                  name="isFreePrice"
                  component={SelectField}
                  id="isFreePrice"
                  label={{ id: 'scheduleAppointment.priceSelect.label' }}
                  options={PRICE_OPTIONS}
                  onChange={onIsFreePriceChange}
                  mountToElement
                />
              </div>
              {!isFreePrice && (
                <div className="w-50 ml-8">
                  <Field
                    className="mb-0"
                    name="price"
                    id="price"
                    component={CurrencyField}
                    label={{ id: 'scheduleAppointment.price' }}
                    icon="alert"
                    min={0}
                    placeholder={{ id: 'scheduleAppointment.pricePlaceholder' }}
                    data-cy="booking-price"
                  />
                </div>
              )}
            </div>
            {!isFreePrice && paymentEnabled && (
              <>
                <Field
                  name="paymentRequired"
                  id="paymentRequired"
                  component={SwitchField}
                  label={{ id: 'scheduleAppointment.paymentRequired' }}
                  className="mt-20 mb-0"
                />

                <PaymentMessage manualPaymentEnabled={manualPaymentEnabled} />
              </>
            )}
          </div>
        </div>
        {isAlertOpened && Boolean(Number(price)) && (
          <Alert
            className="mt-16"
            type={ALERT_KINDS.info}
            message={
              !isWorkspaceOwner
                ? { id: 'scheduleAppointment.noPaymentMethodAdminOrExpert' }
                : {
                    id: 'scheduleAppointment.noPaymentMethodOwner',
                    values: {
                      link: (
                        <Link href={companySettingsPaymentRoute}>
                          <a className="font-600 text-underline">
                            <FormattedOrRawMessage
                              message={{ id: 'scheduleAppointment.noPaymentMethodOwner.linkText' }}
                            />
                          </a>
                        </Link>
                      ),
                    },
                  }
            }
            onDiscard={setIsAlertOpened}
            isDiscardable
          />
        )}
      </div>
    )
  )
}

PriceComponent.defaultProps = {
  price: null,
}

PriceComponent.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serviceSelected: PropTypes.bool.isRequired,
  isFreePrice: PropTypes.bool.isRequired,
  onIsFreePriceChange: PropTypes.func.isRequired,
  manualPaymentEnabled: PropTypes.bool.isRequired,
  paymentEnabled: PropTypes.bool.isRequired,
}

export default PriceComponent
