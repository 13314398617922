import { createSelector } from '@reduxjs/toolkit'
import { filter, path, propEq } from 'ramda'

import { FORM_TYPES } from 'lib/constants/forms'
import { fetchFormsEndpoint } from 'state/concepts/forms/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { isEnabled } from 'utils/isEnabled'

export const formIdsSelector = path(['forms', 'ids'])
export const isBlankStateSelector = path(['forms', 'isBlankState'])
export const filtersSelector = path(['forms', 'filters'])

export const formsLoadingSelector = state => loadingSelector(state, fetchFormsEndpoint.endpoint)

export const formsSelector = createSelector(formIdsSelector, dataSelector, buildCollection('form'))

export const agreementsSelector = createSelector(formsSelector, filter(propEq(FORM_TYPES.agreement, 'formType')))

export const intakeFormsSelector = createSelector(formsSelector, filter(propEq(FORM_TYPES.intake, 'formType')))

export const agreementsActiveSelector = createSelector(agreementsSelector, filter(isEnabled))

export const intakeFormsActiveSelector = createSelector(intakeFormsSelector, filter(isEnabled))
