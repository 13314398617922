import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import hasAuthTokens from 'utils/session/hasAuthTokens'
import { setStartTime, removeStartTime, getStartTime } from 'utils/session/sessionExpiringCookies'
import { addBroadcastListener, removeBroadcastListener } from 'lib/broadcastChannel'
import { MILLISECONDS_PER_SECOND } from 'lib/constants/timeUnits'
import { SESSION_EXPIRING_MODAL_INTERVAL } from 'lib/constants/automaticLogout'
import { KEEP_ALIVE_EVENT } from 'lib/constants/broadcastChannel'
import { hideModal as hideModalAction } from 'state/modal/actions'

import SessionExpiringModalComponent from './component'

class SessionExpiringModal extends React.Component {
  constructor(props) {
    super(props)

    this.setStartTime()
    this.state = { timeoutInSeconds: this.secondsLeft }
  }

  componentDidMount = () => {
    // This condition is used to handle switch between tabs on iOS devices
    if (hasAuthTokens()) {
      this.timerHandle = setInterval(this.updateTimer, MILLISECONDS_PER_SECOND)
      addBroadcastListener(this.handleBroadcastChannelEvents)
    } else {
      this.props.onInactive()
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.timerHandle)
    removeBroadcastListener(this.handleBroadcastChannelEvents)
    removeStartTime(this.props.cookiesPath)
  }

  get secondsLeft() {
    return SESSION_EXPIRING_MODAL_INTERVAL + Math.round(this.startTime.diffNow('seconds').seconds)
  }

  setStartTime = () => {
    // This logic is created to handle synchronization between tabs on iOS devices
    const cachedStartTime = getStartTime()

    if (cachedStartTime) {
      this.startTime = cachedStartTime
    } else {
      this.startTime = DateTime.now()
      setStartTime(this.startTime, this.props.cookiesPath)
    }
  }

  handleBroadcastChannelEvents = msg => {
    const { hideModal, namespace } = this.props

    if (msg !== `${namespace}/${KEEP_ALIVE_EVENT}`) return

    clearInterval(this.timerHandle)
    hideModal()
  }

  updateTimer = () => {
    const { onInactive } = this.props

    this.setState(() => {
      if (this.secondsLeft < 0) {
        clearInterval(this.timerHandle)
        onInactive()

        return null
      }

      return { timeoutInSeconds: this.secondsLeft }
    })
  }

  render = () => <SessionExpiringModalComponent {...this.state} onActive={this.props.onActive} />
}

SessionExpiringModal.propTypes = {
  onActive: PropTypes.func.isRequired,
  onInactive: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  cookiesPath: PropTypes.string.isRequired,
}

const mapDispatchToProps = {
  hideModal: hideModalAction,
}

export { SessionExpiringModal as SessionExpiringModalContainer }
export default connect(null, mapDispatchToProps)(SessionExpiringModal)
