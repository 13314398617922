import PropTypes from 'prop-types'

import DropdownMenu from 'views/shared/DropdownMenu'

const ExpertActions = ({
  booking,
  onEdit,
  onCancel,
  onReschedule,
  icon,
  dropdownHidden,
  onMarkAsPaid,
  dropdownPermissions,
  handleChargeWithCardReader,
}) => (
  <DropdownMenu
    className="main-dropdown-btn"
    menuClassName="main-dropdown main-dropdown--many-level min-w-200"
    placement="bottomRight"
    isHidden={dropdownHidden}
    icon={icon}
    items={[
      {
        isHidden: dropdownPermissions.addToCalendarHidden,
        itemProps: {
          text: { id: 'bookings.addToCalendar' },
          nestedItems: [
            {
              isHidden: dropdownPermissions.addToGoogleCalendarHidden,
              itemProps: {
                listItemClassName: 'p-0',
                text: { id: 'bookings.addToGoogleCalendar' },
                isLink: true,
                href: booking.calendarLinks.googleCalendarLink,
                target: '_blank',
              },
            },
            {
              isHidden: dropdownPermissions.addToOutlookHidden,
              itemProps: {
                listItemClassName: 'p-0',
                text: { id: 'bookings.addToOutlook' },
                isLink: true,
                href: booking.calendarLinks.icalendarLink,
              },
            },
          ],
        },
      },
      {
        isHidden: dropdownPermissions.addToCollectPaymentHidden,
        itemProps: {
          text: { id: 'bookings.collectPayment' },
          isLink: true,
          href: booking.calendarLinks.icalendarLink,
          target: '_blank',
          nestedItems: [
            {
              isHidden: dropdownPermissions.isHiddenMarkAsPaid,
              itemProps: {
                listItemClassName: 'p-0',
                onClick: onMarkAsPaid,
                text: { id: 'payments.action.markAsPaid' },
              },
            },
            {
              isHidden: dropdownPermissions.isHiddenChargeWithCardReader,
              itemProps: {
                listItemClassName: 'p-0',
                text: { id: 'bookings.chargeWithCardReader' },
                onClick: handleChargeWithCardReader,
              },
            },
          ],
        },
      },
      {
        type: 'divider',
        isHidden:
          dropdownPermissions.rescheduleBookingHidden &&
          dropdownPermissions.editPendingAppointmentHidden &&
          dropdownPermissions.cancelBookingHidden,
      },
      {
        isHidden: dropdownPermissions.rescheduleBookingHidden,
        itemProps: {
          text: { id: 'bookings.reschedule' },
          onClick: onReschedule,
        },
      },
      {
        isHidden: dropdownPermissions.editPendingAppointmentHidden,
        itemProps: {
          text: { id: 'bookings.editPendingAppointment' },
          onClick: onEdit,
        },
      },
      {
        isHidden: dropdownPermissions.cancelBookingHidden,
        itemProps: {
          text: { id: 'bookings.cancelBooking' },
          onClick: onCancel,
        },
      },
    ]}
  />
)

ExpertActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  handleChargeWithCardReader: PropTypes.func.isRequired,
  booking: PropTypes.shape().isRequired,
  icon: PropTypes.node.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  dropdownHidden: PropTypes.bool.isRequired,
  dropdownPermissions: PropTypes.shape().isRequired,
}

export default ExpertActions
