import { pipe, map, forEach, replace, split, assocPath, mergeDeepWith, is, isNil, identity } from 'ramda'
import camelCase from 'lodash/camelCase'

const formatErrorObject = ({ source: { pointer }, detail }, { rawKeys }) => {
  const attributes = pipe(replace('/data/attributes/', ''), split('/'), rawKeys ? identity : map(camelCase))(pointer)

  return assocPath(attributes, detail, {})
}

const formatJsonApiErrors = (errors, options = {}) => {
  if (isNil(errors)) {
    return { base: 'Unknown error' }
  }

  let result = {}

  forEach(error => {
    const formattedError = formatErrorObject(error, options)
    result = mergeDeepWith((left, right) => (is(String)(left) ? `${left}. ${right}` : left), result, formattedError)
  }, errors)

  return result
}

export default formatJsonApiErrors
