import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { equals } from 'ramda'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import profileName from 'utils/profileName'
import { EMPLOYEE_REMOVE_OPTIONS, EMPLOYEE_REMOVE_VALUE } from 'lib/constants/employees'
import removeEmployeeSchema from 'lib/yupLocalised/schemas/removeEmployee'
import { removeEmployee } from 'state/concepts/employees/actions'
import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import RadioField from 'views/shared/RadioField'
import DropdownUserField from 'views/shared/DropdownUserField'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'

const EmployeesRemoveModal = ({ onClose, employee }) => {
  const { id, uploadedBusinessStoragesCount } = employee
  const fullName = profileName(employee)
  const handleSubmit = useFormSubmit(removeEmployee, { employeeId: id })
  const businessFilesEnabled = useWorkspaceFeature(FEATURE_KEYS.businessFiles)
  const onRemoveOptionChange = setFieldValueFunc => () => setFieldValueFunc('newFileOwnerId', undefined)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="employeesRemoveModal.removeAccount" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            removeType: EMPLOYEE_REMOVE_VALUE.deleteFiles,
            newFileOwnerId: undefined,
          }}
          validationSchema={removeEmployeeSchema}
          onSubmit={handleSubmit}
        >
          {({ values: { removeType }, setFieldValue }) => (
            <Form>
              <div className="main-modal__body">
                <p>
                  <FormattedMessage
                    id="employeesRemoveModal.areYouSure"
                    values={{
                      fullName: <span className="font-600 brake-word">{fullName}</span>,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage id="employeesRemoveModal.actionIsIrreversible" values={{ fullName }} />
                </p>
                {uploadedBusinessStoragesCount > 0 && businessFilesEnabled && (
                  <>
                    <p className="text-body font-600 mb-8">
                      <FormattedMessage id="employeesRemoveModal.businessFiles" />
                    </p>
                    <p className="text-body">
                      <FormattedMessage id="employeesRemoveModal.whatDoYouWantToDo" values={{ fullName }} />
                    </p>
                    <Field
                      id="removeType"
                      name="removeType"
                      radioFieldWrapClassNames="d-flex"
                      wrapperClassName="radio-wrapper d-flex flex-column row-gap-8"
                      className="mb-0"
                      component={RadioField}
                      options={EMPLOYEE_REMOVE_OPTIONS}
                      onChange={onRemoveOptionChange(setFieldValue)}
                    />
                    {equals(removeType, EMPLOYEE_REMOVE_VALUE.newFileOwner) && (
                      <Field
                        id="newFileOwnerId"
                        name="newFileOwnerId"
                        className="mb-0 mt-16 ml-32"
                        component={DropdownUserField}
                        label={{ id: 'employeesRemoveModal.field.newOwner' }}
                        placeholder={{ id: 'employeesRemoveModal.field.placeholder' }}
                        statuses={['active']}
                        icon="alert"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="main-modal__footer">
                <Button
                  className="main-modal__footer-action"
                  text={{ id: 'shared.cancel' }}
                  kind="flat"
                  onClick={onClose}
                />
                <SubmitFormButton
                  className="main-modal__footer-action"
                  text={{ id: 'employeesRemoveModal.removeTeamMember' }}
                  kind="danger"
                  allowPristine={removeType === EMPLOYEE_REMOVE_VALUE.deleteFiles}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

EmployeesRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
}

export default EmployeesRemoveModal
