import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import {
  etherpadDocumentNoPermissionToSignRoute,
  etherpadDocumentSignFailRoute,
  isDocumentErrorEqTo,
} from 'utils/etherpadDocuments'
import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import { ALERT_KINDS } from 'lib/constants'
import redirect from 'utils/redirect'
import {
  currentWorkspaceCodeSelector,
  isClientLoggedInSelector,
  isEmployeeLoggedInSelector,
} from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { SIGNATURE_TYPES } from 'lib/constants/eSignature'
import { signEtherpadDocumentEndpoint } from '../endpoints'
import { SIGN_ETHERPAD_DOCUMENT } from '../types'

const signEtherpadDocumentOperation = createLogic({
  type: SIGN_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const isEmployeeLoggedIn = isEmployeeLoggedInSelector(state)
    const isClientLoggedIn = isClientLoggedInSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const {
      name,
      documentId,
      clientId,
      eSignatureAgree,
      isEsignatureDisclosureShown,
      signatureImage,
      signatureType,
      signatureDraw,
    } = values
    const { url, endpoint } = signEtherpadDocumentEndpoint(documentId)

    const formData = new FormData()
    formData.append('name', name)
    formData.append('include', 'document-signatories,document-signatories.signature')
    if (isEsignatureDisclosureShown) formData.append('signature_disclosure', eSignatureAgree)
    if (signatureType === SIGNATURE_TYPES.image) formData.append('file', signatureImage, signatureImage.name)
    if (signatureType === SIGNATURE_TYPES.draw) formData.append('file', signatureDraw, signatureDraw.name)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentWasSigned',
          },
        }),
      )
    } catch (error) {
      if (isDocumentErrorEqTo(error, 'not_document_signatory')) {
        const shouldShowNotification = isEmployeeLoggedIn || isClientLoggedIn
        const redirectUrl = etherpadDocumentSignFailRoute({
          isEmployeeLoggedIn,
          workspaceCode,
          isClientLoggedIn,
          clientId,
        })
        dispatch(hideModal())
        shouldShowNotification &&
          dispatch(
            showNotification({
              messageObject: { id: 'notifications.etherpadDocumentIsNotSigned' },
              kind: ALERT_KINDS.warning,
            }),
          )
        redirect({
          href: redirectUrl,
          workspace: isEmployeeLoggedIn ? workspaceCode : null,
          query: isEmployeeLoggedIn ? { activeTab: CLIENT_PROFILE_TABS.documents } : null,
        })
      }

      if (isDocumentErrorEqTo(error, 'forbidden_for_contact') && !isEmployeeLoggedIn) {
        const redirectUrl = etherpadDocumentNoPermissionToSignRoute({
          workspaceCode,
          isClientLoggedIn,
        })
        dispatch(hideModal())
        isClientLoggedIn &&
          dispatch(
            showNotification({
              messageObject: { id: 'notifications.etherpadDocumentNoPermission' },
              kind: ALERT_KINDS.error,
            }),
          )
        redirect({
          href: redirectUrl,
        })
      }
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default signEtherpadDocumentOperation
