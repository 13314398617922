/* eslint-disable no-template-curly-in-string */
// eslint-disable-next-line import/prefer-default-export
export const GUEST_REDIRECT_ROUTES = {
  // Appointment actions
  '/client/[workspace]/bookings/[bookingUniqCode]/accept': '/guest/${ workspace }/bookings/${ bookingUniqCode }/accept',
  '/client/[workspace]/bookings/[bookingUniqCode]/decline':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/decline',
  '/client/[workspace]/bookings/[bookingUniqCode]/decline_all':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/decline_all',
  '/client/[workspace]/bookings/[bookingUniqCode]/cancel': '/guest/${ workspace }/bookings/${ bookingUniqCode }/cancel',
  '/client/[workspace]/bookings/[bookingUniqCode]/reschedule':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/reschedule',
  '/client/[workspace]/bookings/[bookingUniqCode]/submit_forms':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/forms',
  '/client/[workspace]/bookings/[bookingUniqCode]/review': '/guest/${ workspace }/bookings/${ bookingUniqCode }/review',
  '/client/[workspace]/bookings/[bookingUniqCode]/review/success':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/review/success',
  '/client/[workspace]/bookings/[bookingUniqCode]/review/not_able_to_submit':
    '/guest/${ workspace }/bookings/${ bookingUniqCode }/review/not_able_to_submit',

  // Documents
  '/client/[workspace]/forms': '/guest/${ workspace }/documents',
  '/client/[workspace]/agreements': '/guest/${ workspace }/documents',
  '/client/[workspace]/files': '/guest/${ workspace }/documents',
  '/client/[workspace]/forms/[clientProfileFormId]/submit':
    '/guest/${ workspace }/documents/${ clientProfileFormId }/submit',
  '/client/[workspace]/documents/[documentId]': '/guest/${ workspace }/etherpad_documents/${ documentId }',
  '/client/[workspace]/documents/[documentId]/review':
    '/guest/${ workspace }/etherpad_documents/${ documentId }/review',

  // Payment actions
  '/client/[workspace]/payments/[paymentId]/checkout': '/guest/${ workspace }/payments/${ paymentId }/checkout',

  // Account actions
  '/client/[workspace]/account_settings/notifications': '/guest/${ workspace }/account_settings/notifications',
  '/client/[workspace]/account_settings/general/delete': '/guest/${ workspace }/account_settings/general/delete',

  // Waitlist requests
  '/client/[workspace]/bookings/remove_waitlist_request':
    '/guest/${ workspace }/waitlist_requests/${ waitlist_request_id }/remove',
}
