import cookies from 'next-cookies'

import { API_HOST } from 'lib/constants'

export default ctx => config => {
  const { currentWorkspaceCode, guestSession } = cookies(ctx)
  const workspaceCode = currentWorkspaceCode || guestSession?.workspace

  if (config.customBaseURL) {
    // eslint-disable-next-line no-param-reassign
    config.baseURL = config.customBaseURL
  } else if (workspaceCode) {
    // eslint-disable-next-line no-param-reassign
    config.baseURL = `${API_HOST}/api/v1/workspaces/${workspaceCode}`
  }

  return config
}
