import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchWaitlistSettingEndpoint } from '../endpoints'
import { FETCH_WAITLIST_SETTING } from '../types'

const fetchWaitlistSettingOperation = createLogic({
  type: FETCH_WAITLIST_SETTING,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = fetchWaitlistSettingEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchWaitlistSettingOperation
