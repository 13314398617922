export const CLIENT_IMPORT_STEP_TYPES = {
  upload: 'upload',
  match: 'match',
  import: 'import',
}

export const CLIENT_IMPORT_STEPS = [
  { type: CLIENT_IMPORT_STEP_TYPES.upload },
  { type: CLIENT_IMPORT_STEP_TYPES.match },
  { type: CLIENT_IMPORT_STEP_TYPES.import },
]

export const CLIENT_IMPORT_FIRST_NAME_ID = 'first_name_title'
export const CLIENT_IMPORT_LAST_NAME_ID = 'last_name_title'
export const CLIENT_IMPORT_EMAIL_ID = 'email_title'
export const CLIENT_IMPORT_SKIP_ID = 'skip'
export const CLIENT_IMPORT_PHONE_NUMBER = 'phone_number_title'

export const DEFAULT_PROFILE_FIELDS = [
  { id: CLIENT_IMPORT_FIRST_NAME_ID, name: { id: 'clients.import.steps.match.profileFields.firstName' } },
  { id: CLIENT_IMPORT_LAST_NAME_ID, name: { id: 'clients.import.steps.match.profileFields.lastName' } },
  { id: CLIENT_IMPORT_EMAIL_ID, name: { id: 'clients.import.steps.match.profileFields.email' } },
  { id: CLIENT_IMPORT_PHONE_NUMBER, name: { id: 'clients.import.steps.match.profileFields.phoneNumber' } },
]

export const SKIP_DUPLICATES_OPTIONS = {
  skipDuplicates: 'skipDuplicates',
  overwriteDuplicates: 'overwriteDuplicates',
}

export const SKIP_DUPLICATES_FIELD_OPTIONS = [
  {
    value: SKIP_DUPLICATES_OPTIONS.skipDuplicates,
    label: { id: 'clients.import.steps.import.options.skipDuplicates.label' },
    description: { id: 'clients.import.steps.import.options.skipDuplicates.description' },
  },
  {
    value: SKIP_DUPLICATES_OPTIONS.overwriteDuplicates,
    label: { id: 'clients.import.steps.import.options.overwriteDuplicates.label' },
    description: { id: 'clients.import.steps.import.options.overwriteDuplicates.description' },
  },
]
