import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import UploadLogo from 'views/shared/UploadLogo'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import { MAX_CHAT_NAME_LENGTH } from 'lib/constants/chat'

const ChatDetailsModalComponent = ({
  logoUrl,
  setChatLogo,
  removeChatLogo,
  onCloseUploadLogo,
  onClose,
  actionType,
  isSubmitDisabled,
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <Form>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="chat.detailsModal.title.chatDetails" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body d-flex">
          <div className="mr-36">
            <UploadLogo
              className="image-uploader--noresponsive"
              id="chatLogo"
              logoUrl={logoUrl}
              title={{ id: 'uploadPhoto.upload' }}
              uploadAction={setChatLogo}
              removeAction={removeChatLogo}
              closeAction={onCloseUploadLogo}
              removeBodyText={[{ id: 'chat.uploadLogo.remove' }]}
              submitText={{ id: 'shared.crop' }}
              isCircularCrop
              skipRemoveConfirmation
            />
          </div>
          <div className="flex-grow-1">
            {/* TODO EBX-10156 here was InputField component and I changed it to InputWithCounterField */}
            <Field
              name="name"
              component={InputWithCounterField}
              id="name"
              type="text"
              icon="close"
              label={{ id: 'chat.detailsModal.label' }}
              showCount
              className="main-input--input-top-count mb-0"
              maxChars={MAX_CHAT_NAME_LENGTH}
            />
          </div>
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="mr-16"
              type="button"
              onClick={onClose}
              text={{ id: 'shared.cancel' }}
              kind="flat"
              dataCy="cancel-create-chat"
            />
            <SubmitFormButton
              text={{ id: `chat.detailsModal.button.${actionType}` }}
              dataCy="create-chat"
              isDisabled={isSubmitDisabled}
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

ChatDetailsModalComponent.propTypes = {
  logoUrl: PropTypes.string,
  setChatLogo: PropTypes.func.isRequired,
  removeChatLogo: PropTypes.func.isRequired,
  onCloseUploadLogo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
}

ChatDetailsModalComponent.defaultProps = {
  logoUrl: null,
}

export default ChatDetailsModalComponent
