import endpoint from 'utils/endpoint'
import {
  addClientBusinessFilesRoute,
  businessStoragesDownloadCancelRoute,
  businessStoragesDownloadRoute,
  businessStoragesMoveMyFoldersRoute,
  businessStoragesMoveRoute,
  businessStoragesPresignRoute,
  businessStoragesTrashRoute,
  businessStoragesUploadRoute,
  duplicateMyBusinessFileRoute,
  myBusinessFilePermissionsRoute,
  myBusinessFileRoute,
  myBusinessFilesFoldersRoute,
  myBusinessFilesRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const fetchMyBusinessFilesEndpoint = endpoint(types.FETCH_MY_BUSINESS_FILES, 'GET', myBusinessFilesRoute)
export const fetchCurrentFolderEndpoint = folderId =>
  endpoint(types.FETCH_CURRENT_FOLDER, 'GET', myBusinessFileRoute(folderId))
export const createFolderEndpoint = endpoint(types.CREATE_FOLDER, 'POST', myBusinessFilesFoldersRoute)
export const duplicateMyBusinessFileEndpoint = myBusinessFileId =>
  endpoint(types.DUPLICATE_MY_BUSINESS_FILE, 'POST', duplicateMyBusinessFileRoute(myBusinessFileId))
export const renameMyBusinessFileEndpoint = myBusinessFileId =>
  endpoint(types.RENAME_MY_BUSINESS_FILE, 'PUT', myBusinessFileRoute(myBusinessFileId))
export const shareMyBusinessFileEndpoint = myBusinessFileId =>
  endpoint(types.SHARE_MY_BUSINESS_FILE, 'PUT', myBusinessFilePermissionsRoute(myBusinessFileId))
export const presignUploadEndpoint = endpoint(types.PRESIGN_BUSINESS_FILE, 'POST', businessStoragesPresignRoute)
export const uploadFileEndpoint = endpoint(types.UPLOAD_BUSINESS_FILE, 'POST', businessStoragesUploadRoute)
export const moveMyBusinessFileToTrashEndpoint = endpoint(
  types.MOVE_MY_BUSINESS_FILE_TO_TRASH,
  'POST',
  businessStoragesTrashRoute,
)

export const downloadBusinessFilesEndpoint = endpoint(
  types.DOWNLOAD_BUSINESS_FILES,
  'POST',
  businessStoragesDownloadRoute,
)

export const cancelFilesDownloadEndpoint = downloadId =>
  endpoint(types.CANCEL_FILES_DOWNLOAD, 'POST', businessStoragesDownloadCancelRoute(downloadId))
export const addClientBusinessFilesEndpoint = clientId =>
  endpoint(types.ADD_CLIENT_BUSINESS_FILES, 'POST', addClientBusinessFilesRoute(clientId))

export const moveToFolderEndpoint = fileId => endpoint(types.MOVE_TO_FOLDER, 'PUT', businessStoragesMoveRoute(fileId))

export const fetchMyFolderToMoveEndpoint = fileId =>
  endpoint(types.FETCH_FOLDERS_TO_MOVE, 'GET', businessStoragesMoveMyFoldersRoute(fileId))
