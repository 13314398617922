import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_APP_AUTH_URI } from 'state/concepts/employee/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { getAppUthUriEndpoint } from '../endpoints'

const getAppAuthUriOperation = createLogic({
  type: GET_APP_AUTH_URI,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = getAppUthUriEndpoint

    const workspaceCode = currentWorkspaceCodeSelector(getState())
    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, buildCustomBaseUrl(['workspaces', workspaceCode]))
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getAppAuthUriOperation
