// Expert routes
export const companyBookingsRoute = '/company/bookings'
export const companyBookingRoute = id => `/company/bookings/${id}`

export const companyAvailabilityRecurringRoute = '/company/availability/recurring'
export const companyAvailabilityDateRoute = `/company/availability/date`

export const companyChatsRoute = '/company/chats'
export const companyChatRoute = id => `/company/chats/${id}`

export const companyClientsRoute = '/company/clients'
export const companyClientsCreateRoute = '/company/clients/create'
export const companyClientsImportRoute = '/company/clients/import'
export const companyClientRoute = id => `/company/clients/${id}`
export const companyClientFilePreviewRoute = id => `/company/clients/${id}/file_preview`
export const companyClientAddContactRoute = id => `/company/clients/${id}/add_contact`
export const companyClientEditContactRoute = (clientId, contactId) =>
  `/company/clients/${clientId}/edit_contact/${contactId}`
export const companyClientShareFilesRoute = id => `/company/clients/${id}/share_files`
export const companyClientShareFormsRoute = id => `/company/clients/${id}/share_forms`
export const companyClientAddFormsRoute = id => `/company/clients/${id}/add_forms`
export const companyClientDocumentTemplatesRoute = id => `/company/clients/${id}/document_templates`
export const companyClientDocumentTemplateRoute = (clientId, documentTemplateId) =>
  `/company/clients/${clientId}/document_templates/${documentTemplateId}`
export const companyClientSendAgreementsRoute = id => `/company/clients/${id}/send_agreements`
export const companyClientsSubmitFormRoute = id => `/company/clients/forms/${id}/submit`
export const companyClientsFormsSubmitSuccessRoute = `/company/clients/forms/submit/success`
export const companyClientsFormsSignSuccessRoute = `/company/clients/forms/sign/success`
export const companyClientsFormsDeclineToSignSuccessRoute = `/company/clients/forms/decline_to_sign/success`
export const companyClientsFormsEditResponsesRoute = (clientProfileFormId, formSubmissionId) =>
  `/company/clients/forms/${clientProfileFormId}/submission_edit/${formSubmissionId}`
export const companyClientsFormsSubmissionSignRoute = clientProfileFormId =>
  `/company/clients/forms/${clientProfileFormId}/sign`
export const companyClientAddBusinessFilesRoute = clientId => `/company/clients/${clientId}/add_business_files`

export const companyBusinessFilesMyFilesRoute = '/company/business_files/my_files'
export const companyBusinessFilesShareRoute = '/company/business_files/shared'
export const companyBusinessFilesSharePreviewRoute = '/company/business_files/shared/preview'
export const companyBusinessFilesShareFolderRoute = folderId => `/company/business_files/shared/${folderId}`
export const companyBusinessFilesTrashRoute = '/company/business_files/trash'
export const companyBusinessFilesTrashPreviewRoute = '/company/business_files/trash/preview'
export const companyBusinessFilesMyFilesFolderRoute = folderId => `/company/business_files/my_files/${folderId}`
export const companyBusinessFilesMyFilesPreviewRoute = '/company/business_files/my_files/preview'

export const companyTasksRoute = '/company/tasks'

export const companyBillingPaymentsRoute = '/company/billing/payments'
export const companyBillingPaymentsRequestRoute = '/company/billing/payments/request'
export const companyBillingPaymentRoute = id => `/company/billing/payments/${id}`
export const companyBillingPaymentEditRoute = id => `/company/billing/payments/${id}/edit`
export const companyBillingRecurringPaymentsRoute = '/company/billing/recurring_payments'
export const companyBillingRecurringPaymentsRequestRoute = '/company/billing/recurring_payments/request'
export const companyBillingRecurringPaymentRoute = id => `/company/billing/recurring_payments/${id}`
export const companyBillingRecurringPaymentEditRoute = id => `/company/billing/recurring_payments/${id}/edit`

export const companyReviewsRoute = '/company/reviews'

export const companyFeaturesRoute = '/company/features'
export const companyFeaturesAvailabilityRoute = '/company/features/availability'
export const companyFeaturesBusinessFilesRoute = '/company/features/business_files'
export const companyFeaturesBookingRoute = '/company/features/booking'
export const companyFeaturesRecurringAppointmentsRoute = '/company/features/recurring_appointments'
export const companyFeaturesClientPortalRoute = '/company/features/client_portal'
export const companyFeaturesClientContactsRoute = '/company/features/client_contacts'
export const companyFeaturesSignatureRoute = '/company/features/signature'
export const companyFeaturesServicesRoute = '/company/features/services'
export const companyFeaturesServiceCategoriesRoute = '/company/features/service_categories'
export const companyFeaturesTasksRoute = '/company/features/tasks'
export const companyFeaturesAcceptVideoCallsRoute = '/company/features/accept_video_calls'
export const companyFeaturesGroupVideoCallsRoute = '/company/features/group_video_calls'
export const companyFeaturesVideoCallsRecordingRoute = '/company/features/video_calls_recording'
export const companyFeaturesBookingPageRoute = '/company/features/booking_page'
export const companyFeaturesBookingWidgetsRoute = '/company/features/booking_widgets'
export const companyFeaturesTeamRoute = '/company/features/team'
export const companyFeaturesWebsiteRoute = '/company/features/website'
export const companyFeaturesLocationsRoute = '/company/features/locations'
export const companyFeaturesClientListRoute = '/company/features/client_list'
export const companyFeaturesCRMRoute = '/company/features/crm'
export const companyFeaturesChatRoute = '/company/features/chat'
export const companyFeaturesFinancialDocumentsRoute = '/company/features/financial_documents'
export const companyFeaturesAcceptPaymentsRoute = '/company/features/accept_payments'
export const companyFeaturesCustomPaymentsRoute = '/company/features/custom_payments'
export const companyFeaturesRefundsRoute = '/company/features/refunds'
export const companyFeaturesSmsRemindersRoute = '/company/features/sms_reminders'
export const companyFeaturesEmailRemindersRoute = '/company/features/email_reminders'
export const companyFeaturesCustomNamingRoute = '/company/features/custom_naming'
export const companyFeaturesServiceAgreementsRoute = '/company/features/service_agreements'
export const companyFeaturesDocumentsRoute = '/company/features/documents'
export const companyFeaturesDocumentTemplatesRoute = '/company/features/document_templates'
export const companyFeaturesIntakeFormsRoute = '/company/features/intake_forms'
export const companyFeaturesReviewsRoute = '/company/features/reviews'
export const companyFeaturesCalendarIntegrationsRoute = '/company/features/calendar_integrations'
export const companyFeaturesVoiceMessagesRoute = '/company/features/voice_messages'
export const companyFeaturesStripePosRoute = '/company/features/stripe_pos'
export const companyFeaturesESignatureRoute = '/company/features' // TODO: 7.3 release E-Signature
export const companyFeaturesWaitlistRoute = '/company/features/waitlist'
export const companyWailistRoute = '/company/waitlist'
export const companyWaitlistRequestCreateRoute = '/company/waitlist_request/create'
export const companyWaitlistRequestEditRoute = waitlistRequestId =>
  `/company/waitlist_request/${waitlistRequestId}/edit`

// Expert Settings routes
export const companySettingsRoute = '/company/settings'

export const companySettingsBookingPolicyRoute = '/company/settings/booking_policy'

export const companySettingsBusinessProfileRoute = '/company/settings/business/profile'
export const companySettingsBusinessContactDetailsRoute = '/company/settings/business/contact_details'

export const companySettingsWorkspaceRoute = '/company/settings/workspace'
export const companySettingsWorkspaceSecurityRoute = '/company/settings/workspace/security'

export const companySettingsEmployeesRoute = '/company/settings/employees'
export const companySettingsEmployeeRoute = employeeId => `/company/settings/employees/${employeeId}`
export const companySettingsEmployeeEditRoute = employeeId => `/company/settings/employees/${employeeId}/edit`
export const companySettingsEmployeeEditServicesRoute = employeeId =>
  `/company/settings/employees/${employeeId}/edit/services`
export const companySettingsEmployeeEditRecurringRoute = employeeId =>
  `/company/settings/employees/${employeeId}/edit/recurring`
export const companySettingsEmployeeEditDateRoute = employeeId => `/company/settings/employees/${employeeId}/edit/date`
export const companySettingsEmployeeEditAccountRoute = employeeId =>
  `/company/settings/employees/${employeeId}/edit/account`

export const companySettingsServicesRoute = '/company/settings/services'
export const companySettingsServicesCreateRoute = '/company/settings/services/create'
export const companySettingsServicesCategoriesRoute = '/company/settings/services/categories'
export const companySettingsServiceRoute = id => `/company/settings/services/${id}`
export const companySettingsServiceSettingsRoute = id => `/company/settings/services/${id}/settings`
export const companySettingsServiceExpertsRoute = id => `/company/settings/services/${id}/providers`
export const companySettingsServiceDocumentsRoute = id => `/company/settings/services/${id}/documents`

export const companySettingsBookingPagesRoute = '/company/settings/booking_pages'
export const companySettingsBookingPagesCreateRoute = '/company/settings/booking_pages/create'
export const companySettingsBookingPageEditRoute = id => `/company/settings/booking_pages/${id}/edit`
export const companySettingsBookingPageEditServicesRoute = id => `/company/settings/booking_pages/${id}/edit/services`
export const companySettingsBookingPageShareRoute = id => `/company/settings/booking_pages/${id}/share`
export const companySettingsBookingPageShareCustomizeWidgetRoute = id =>
  `/company/settings/booking_pages/${id}/share/customize_widget`

export const companySettingsClientFieldsRoute = '/company/settings/client_fields'
export const companySettingsClientRelationshipTypesRoute = '/company/settings/client_relationship_types'
export const companySettingsClientRelationshipTypesCreateRoute = '/company/settings/client_relationship_types/create'
export const companySettingsClientRelationshipTypesEditRoute = id =>
  `/company/settings/client_relationship_types/${id}/edit`

export const companySettingsDocumentTemplatesRoute = '/company/settings/document_templates'
export const companySettingsDocumentTemplateRoute = documentTemplateId =>
  `/company/settings/document_templates/${documentTemplateId}`
export const companySettingsSignatureSettingsRoute = '/company/settings/signature_settings'
export const signatureDisclosureRoute = '/signature_disclosure'

export const companySettingsLocationsRoute = '/company/settings/locations'
export const companySettingsLocationsCreateRoute = '/company/settings/locations/create'
export const companySettingsLocationEditRoute = id => `/company/settings/locations/${id}/edit`

export const companySettingsVideoCallsRoute = '/company/settings/video_calls'

export const companySettingsFormCategoriesRoute = '/company/settings/form_categories'
export const companySettingsIntakeFormsRoute = '/company/settings/intake_forms'
export const companySettingsIntakeFormsCreateRoute = '/company/settings/intake_forms/create'
export const companySettingsIntakeFormsEditRoute = id => `/company/settings/intake_forms/${id}/edit`
export const companySettingsIntakeFormsPreviewRoute = id => `/company/settings/intake_forms/${id}/preview`
export const companySettingsIntakeFormsSubmissionPreviewRoute = formSubmissionId =>
  `/company/clients/forms/submission_preview/${formSubmissionId}`
export const companySettingsIntakeFormsEditQuestionsRoute = id => `/company/settings/intake_forms/${id}/edit/questions`
export const companySettingsIntakeFormsEditSignatureRoute = id => `/company/settings/intake_forms/${id}/edit/signature`
export const companySettingsIntakeFormsEditSharingRoute = id => `/company/settings/intake_forms/${id}/edit/sharing`

export const companySettingsAgreementsRoute = '/company/settings/agreements'
export const companySettingsAgreementsCreateRoute = '/company/settings/agreements/create'
export const companySettingsAgreementEditRoute = id => `/company/settings/agreements/${id}/edit`

export const companySettingsReviewsRoute = '/company/settings/reviews'

export const companySettingsClientPortalRoute = '/company/settings/client_portal'

export const companySettingsCustomNamingRoute = '/company/settings/custom_naming'

export const companySettingsSMSNotificationsRoute = '/company/settings/sms_notifications'

export const companySettingsPaymentRoute = '/company/settings/payment'
export const companySettingsCardReadersRoute = '/company/settings/payment/card_readers'

export const companySettingsSubscriptionRoute = '/company/settings/subscription'
export const companySettingsSubscriptionPaymentHistoryRoute = '/company/settings/subscription/payment_history'
export const companySettingsSubscriptionPlansRoute = '/company/settings/subscription/plans'
export const companySettingsSubscriptionPlanRoute = planId => `/company/settings/subscription/plans/${planId}`
export const companySettingsSubscriptionBillingMethodEditRoute = '/company/settings/subscription/billing_method/edit'
export const companySettingsSubscriptionBillingMethodEditBillingAddressRoute =
  '/company/settings/subscription/billing_method/edit_billing_address'
export const companySettingsSubscriptionBillingAccountRoute = '/company/settings/subscription/billing_account'
export const companySettingsSubscriptionManageSeatsRoute = '/company/settings/subscription/manage_seats'
export const companySettingsSubscriptionManageStorageRoute = '/company/settings/subscription/manage_storage'
export const companySettingsSubscriptionAddPaymentMethodRoute = '/company/settings/subscription/add_payment_method'
export const companySettingsCustomizeReceiptRoute = '/company/settings/customize_receipt'

export const companyCalendarEventRoute = id => `/company/calendar_events/${id}`

export const companySettingsWebsiteRoute = '/company/settings/website'
export const companySettingsWebsitePreviewRoute = '/company/settings/website/preview'
export const companySettingsWebsiteGeneralRoute = '/company/settings/website/general'
export const companySettingsWebsiteContentRoute = '/company/settings/website/content'
export const companySettingsWebsiteCreateRoute = '/company/settings/website/create'
export const companyInCallChatRoute = id => `/company/in_call_chat/${id}`

export const companyClientDocumentRoute = (clientId, documentId) =>
  `/company/clients/${clientId}/documents/${documentId}`
export const companyClientDocumentReviewRoute = (clientId, documentId) =>
  `/company/clients/${clientId}/documents/${documentId}/review`
export const companyClientDocumentAddSignatoriesRoute = (clientId, documentId) =>
  `/company/clients/${clientId}/documents/${documentId}/add_signatories`
export const companyClientDocumentEditSignatoriesRoute = (clientId, documentId) =>
  `/company/clients/${clientId}/documents/${documentId}/edit_signatories`

export const companySettingsStorageRoute = '/company/settings/storage'
export const companySettingsStoragePreviewRoute = '/company/settings/storage/preview'
export const companySettingsWaitlistSettingsRoute = '/company/settings/waitlist_settings'
