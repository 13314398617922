import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { UPDATE_MANUAL_PAYMENT } from 'state/concepts/paymentSettings/types'
import { updateManualPaymentEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { manualPaymentSelector } from 'state/concepts/paymentSettings/selectors'

const updateManualPaymentOperation = createLogic({
  type: UPDATE_MANUAL_PAYMENT,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateManualPaymentEndpoint
    const state = getState()
    const { paymentInstructions } = values

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, {
        payment_instructions: paymentInstructions,
      })

      const response = updateDataHelper(state.data, 'manualPayment', manualPaymentSelector(state).id, {
        attributes: { paymentInstructions },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.manualPaymentWasUpdated',
          },
        }),
      )
      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default updateManualPaymentOperation
