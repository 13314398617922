import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addWaitlistRequestIds, setBlankState } from '../actions'
import { fetchWaitlistRequestsEndpoint } from '../endpoints'
import { FETCH_WAITLIST_REQUESTS } from '../types'
import { filtersSelector } from '../selectors'

const fetchWaitlistRequestsOperation = createLogic({
  type: FETCH_WAITLIST_REQUESTS,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const {
      pageParams: { page = 1 },
      additionalInclude = [],
    } = action
    const state = getState()
    const { employees, services, clients, locations } = filtersSelector(state)
    const filter = {
      service_ids: services,
      user_profile_ids: employees,
      client_profile_ids: clients,
      location_ids: locations,
    }
    const { url, endpoint } = fetchWaitlistRequestsEndpoint
    const params = {
      page: { number: page, size: 20 },
      include: [...additionalInclude, 'user-profile', 'client-profile', 'service', 'first-preference'],
      filter,
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(setBlankState(isBlankState({ data: data.data, params: filter })))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addWaitlistRequestIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchWaitlistRequestsOperation
