import { combineReducers } from '@reduxjs/toolkit'

import { SET_PAYMENT_ID } from './types'

const paymentId = (state = null, action) => {
  switch (action.type) {
    case SET_PAYMENT_ID:
      return action.paymentId
    default:
      return state
  }
}

export default combineReducers({
  paymentId,
})
