import endpoint from 'utils/endpoint'
import { smsNotificationsSettingsRoute } from 'lib/apiRoutes'
import { FETCH_SMS_NOTIFICATIONS_SETTINGS, UPDATE_SMS_NOTIFICATIONS_SETTINGS } from './types'

export const fetchSMSNotificationsSettingsEndpoint = endpoint(
  FETCH_SMS_NOTIFICATIONS_SETTINGS,
  'GET',
  smsNotificationsSettingsRoute,
)
export const updateSMSNotificationsSettingsEndpoint = endpoint(
  UPDATE_SMS_NOTIFICATIONS_SETTINGS,
  'PUT',
  smsNotificationsSettingsRoute,
)
