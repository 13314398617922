import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import employeesProvisionToParams from 'utils/employees/employeesProvisionToParams'
import updateDataHelper from 'utils/updateDataHelper'
import normalize from 'json-api-normalizer'
import { usersProvisionRoute } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { UPDATE_EMPLOYEE_PROVISION } from 'state/concepts/employees/types'
import { hideModal } from 'state/modal/actions'

const updateProvisionOperation = createLogic({
  type: UPDATE_EMPLOYEE_PROVISION,
  latest: true,

  async process(
    {
      action: {
        values: { provision },
        form,
        employee,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    try {
      const state = getState()
      const url = usersProvisionRoute(employee.id)
      const params = {
        provision: employeesProvisionToParams(provision),
        include: 'user-profiles-services.service',
      }

      const { data } = await httpClient.put(url, params)
      const apiResponse = normalize(data)

      const response = updateDataHelper(state.data, 'userProfile', employee.id, {
        attributes: { servicesCount: params.provision.length },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(dataApiSuccess({ response: apiResponse }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.profileHasBeenUpdated',
          },
        }),
      )
      form.resetForm()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateProvisionOperation
