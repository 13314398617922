import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import { isCancel } from 'axios'

import requestErrorHandler from 'lib/requestErrorHandler'
import { AUTO_SAVE_RESPONSES, CANCEL_AUTO_SAVE_RESPONSES } from 'state/concepts/form/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { formSubmissionParams } from 'utils/intakeForms'
import { autoSaveResponsesEndpoint } from '../endpoints'
import { setFormSubmissionId, setIsAutoSaveError } from '../actions'

const autoSaveResponsesOperation = createLogic({
  type: AUTO_SAVE_RESPONSES,
  latest: true,

  async process({ action: { values, onEnd }, httpClient, action$ }, dispatch, done) {
    const { url, endpoint } = autoSaveResponsesEndpoint

    const params = {
      ...formSubmissionParams(values),
      include: [
        'form',
        'form_answers',
        'form_answers.form_question',
        'form_answers.form_question.form_question_options',
        'form_answers.form_answer_values.form_question_option',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const controller = new AbortController()
      action$.subscribe(newAction => {
        if (newAction.type === CANCEL_AUTO_SAVE_RESPONSES) {
          controller.abort()
        }
      })

      const { data } = await httpClient.put(url, params, { signal: controller.signal })

      dispatch(dataApiSuccess({ endpoint, response: normalize(data) }))
      dispatch(setFormSubmissionId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, skipHandlers: isCancel(error) })

      if (isErrorStatusUnprocessableEntity(error)) {
        const { base } = formatJsonApiErrors(error.response.data.errors)
        if (base) {
          dispatch(setIsAutoSaveError(true))
        }
      }
    }

    onEnd?.()
    done()
  },
})

export default autoSaveResponsesOperation
