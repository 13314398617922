import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import redirect from 'utils/redirect'
import { prepareEditorValue } from 'utils/reactDraftEditor'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { CREATE_AGREEMENT } from 'state/concepts/agreement/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsAgreementsRoute } from 'lib/routes'
import { createAgreementEndpoint } from '../endpoints'

const createAgreementOperation = createLogic({
  type: CREATE_AGREEMENT,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { url } = createAgreementEndpoint

    try {
      const { data } = await httpClient.post(url, {
        name: values.name,
        description: prepareEditorValue(values.description),
        status: values.status,
      })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      redirect({ href: companySettingsAgreementsRoute, workspace: currentWorkspaceCodeSelector(getState()) })
      dispatch(showNotification({ messageObject: { id: 'notifications.agreementCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default createAgreementOperation
