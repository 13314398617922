import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchClientFeed } from 'state/concepts/companyClientFeed/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { voidEtherpadDocumentEndpoint } from '../endpoints'
import { VOID_ETHERPAD_DOCUMENT } from '../types'

const voidEtherpadDocumentOperation = createLogic({
  type: VOID_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { voidReason, documentId, feedId, clientId } = values
    const { url, endpoint } = voidEtherpadDocumentEndpoint(documentId)
    const params = { void_reason: voidReason, include: ['voided-by'] }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      const updateDocumentResponse = updateDataHelper(response, 'document', documentId, {
        attributes: { documentKind: 0 },
      })
      dispatch(dataApiSuccess({ response: { ...response, ...updateDocumentResponse }, endpoint }))

      if (feedId) {
        dispatch(fetchClientFeed(clientId))
      }

      dispatch(hideModal())

      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentWasVoided',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default voidEtherpadDocumentOperation
