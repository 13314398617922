import * as types from './types'

export const openConnection = () => ({
  type: types.OPEN_CONNECTION,
})

export const closeConnection = () => ({
  type: types.CLOSE_CONNECTION,
})

export const setConnectionStatus = status => ({
  type: types.SET_CONNECTION_STATUS,
  status,
})

export const addSubscriptionChannels = channels => ({
  type: types.ADD_SUBSCRIPTION_CHANNELS,
  channels,
})

export const removeSubscriptionChannels = channels => ({
  type: types.REMOVE_SUBSCRIPTION_CHANNELS,
  channels,
})

export const resetSubscriptionChannels = () => ({
  type: types.RESET_SUBSCRIPTION_CHANNELS,
})

export const sendCommand = options => ({
  type: types.SEND_COMMAND,
  ...options,
})

export const receiveData = data => ({
  type: types.RECEIVE_DATA,
  data,
})
