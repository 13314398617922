import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import { BOOKING_DECLINE_OPTIONS } from 'lib/constants/bookings'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import RadioField from 'views/shared/RadioField'
import Modal from 'views/shared/Modal'

const DeclineRecurringAppointmentsModalComponent = ({ onClose, handleSubmit }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container d-block" data-cy="decline-modal">
      <div className="main-modal__header">
        <h2 className="main-modal__title" data-cy="decline-modal-title">
          <FormattedMessage id="bookings.clientDeclineRecurring.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>

      <div className="main-modal__body" data-cy="decline-modal-options">
        <Field
          name="declineRecurring"
          component={RadioField}
          options={[
            { value: BOOKING_DECLINE_OPTIONS.single, label: { id: 'bookings.clientDeclineRecurring.options.single' } },
            { value: BOOKING_DECLINE_OPTIONS.all, label: { id: 'bookings.clientDeclineRecurring.options.all' } },
          ]}
        />
        <p className="mb-0" data-cy="decline-modal-message">
          <FormattedMessage id="bookings.clientDeclineRecurring.body" />
        </p>
      </div>

      <div className="main-modal__footer">
        <div className="d-flex justify-content-end">
          <Button
            extraClasses="main-modal__footer-action"
            text={{ id: 'bookings.clientDeclineRecurring.cancel' }}
            kind="flat"
            onClick={onClose}
          />
          <Button
            extraClasses="main-modal__footer-action"
            text={{ id: 'bookings.clientDeclineRecurring.submit' }}
            kind="danger"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  </Modal>
)

DeclineRecurringAppointmentsModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default DeclineRecurringAppointmentsModalComponent
