import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addSearchResults, resetSearchResults, setBlankState } from 'state/searchResults/actions'
import { GET_CLIENTS } from 'state/concepts/filters/types'
import { getClientsEndpoint } from 'state/concepts/filters/endpoints'

const getClientsOperation = createLogic({
  type: GET_CLIENTS,
  latest: true,

  async process({ httpClient, action: { searchQuery, page, resetResults, active } }, dispatch, done) {
    const { endpoint, url } = getClientsEndpoint

    const params = {
      page: {
        number: page || 1,
        size: 20,
      },
      filter: {
        name: searchQuery,
        active,
      },
      fields: {
        'client-profile': ['avatar-urls', 'email', 'first-name', 'last-name'],
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    if (resetResults) {
      dispatch(resetSearchResults('clients'))
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const ids = data.data.map(item => item.id)

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.clients]: isBlankState({ params: { name: searchQuery }, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.clients]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getClientsOperation
