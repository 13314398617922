import {
  clientProfileFormDeclineToSignRoute,
  clientsProfileFormRoute,
  formRoute,
  formSignSubmissionRoute,
  formSubmissionFormAnswerFilesPresignRoute,
  formSubmissionRoute,
  formSubmissionsClearRoute,
  formSubmissionsDraftRoute,
  formSubmissionsRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import {
  AUTO_SAVE_RESPONSES,
  CLEAR_AUTO_SAVE_RESPONSES,
  CREATE_ATTACHMENT_UPLOAD,
  DECLINE_TO_SIGN_FORM,
  FETCH_CLIENT_PROFILE_FORM,
  FETCH_FORM,
  FETCH_FORM_SUBMISSION,
  SIGN_FORM_SUBMISSION,
  SUBMIT_FORM,
  UPDATE_FORM_SUBMISSION,
} from './types'

export const fetchFormEndpoint = id => endpoint(FETCH_FORM, 'GET', formRoute(id))
export const submitFormEndpoint = endpoint(SUBMIT_FORM, 'POST', formSubmissionsRoute)
export const updateFormSubmissionEndpoint = submissionId =>
  endpoint(UPDATE_FORM_SUBMISSION, 'PUT', formSubmissionRoute(submissionId))
export const fetchFormSubmissionEndpoint = id => endpoint(FETCH_FORM_SUBMISSION, 'GET', formSubmissionRoute(id))
export const createAttachmentUploadEndpoint = endpoint(
  CREATE_ATTACHMENT_UPLOAD,
  'POST',
  formSubmissionFormAnswerFilesPresignRoute,
)
export const fetchClientProfileFormEndpoint = id =>
  endpoint(FETCH_CLIENT_PROFILE_FORM, 'GET', clientsProfileFormRoute(id))

export const autoSaveResponsesEndpoint = endpoint(AUTO_SAVE_RESPONSES, 'POST', formSubmissionsDraftRoute)
export const clearSaveResponsesEndpoint = endpoint(CLEAR_AUTO_SAVE_RESPONSES, 'POST', formSubmissionsClearRoute)
export const signFormSubmissionEndpoint = submissionId =>
  endpoint(SIGN_FORM_SUBMISSION, 'POST', formSignSubmissionRoute(submissionId))
export const declineToSignFormEndpoint = clientProfileFormId =>
  endpoint(DECLINE_TO_SIGN_FORM, 'POST', clientProfileFormDeclineToSignRoute(clientProfileFormId))
