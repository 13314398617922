import { createLogic } from 'redux-logic'

import { SET_SERVICE_CATEGORIES_FILTER } from 'state/concepts/serviceCategories/types'
import { setFilterParams, setCurrentPage, searchServiceCategories } from 'state/concepts/serviceCategories/actions'

const filterServiceCategoriesOperation = createLogic({
  type: SET_SERVICE_CATEGORIES_FILTER,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(searchServiceCategories())
    done()
  },
})

export default filterServiceCategoriesOperation
