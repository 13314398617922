import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import updateDataHelper from 'utils/updateDataHelper'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { CONNECT_MICROSOFT_TEAMS_PROVIDER } from '../types'
import { connectMicrosoftTeamsProviderEndpoint } from '../endpoints'

const connectMicrosoftTeamsProviderOperation = createLogic({
  type: CONNECT_MICROSOFT_TEAMS_PROVIDER,
  latest: true,

  async process({ action: { code, state }, httpClient, getState }, dispatch, done) {
    const { id } = currentUserProfileSelector(getState())

    const { url, endpoint } = connectMicrosoftTeamsProviderEndpoint
    try {
      const params = {
        code,
        state,
      }
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, params)
      const response = updateDataHelper(getState().data, 'userProfile', id, {
        attributes: {
          videoCallProvider: VIDEO_CALLS_PROVIDERS.microsoftTeams,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.microsoftTeamsConnectionSuccess' } }))
    } catch (error) {
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.microsoftTeamsConnectionFailed' },
          kind: 'error',
        }),
      )
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default connectMicrosoftTeamsProviderOperation
