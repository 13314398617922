import React from 'react'
import PropTypes from 'prop-types'

import ErrorModal from 'views/shared/ErrorModal'
import CancelFormModal from './CancelFormModal'

const ClientCancelBookingModalComponent = ({ cancellationError, isLoading, ...props }) =>
  isLoading === false &&
  (cancellationError ? (
    <ErrorModal
      error={cancellationError}
      title={{ id: 'cancelBookingModal.cancelAppointmentError' }}
      submitText={{ id: 'shared.dismiss' }}
    />
  ) : (
    <CancelFormModal {...props} />
  ))

ClientCancelBookingModalComponent.defaultProps = {
  cancellationError: undefined,
  isLoading: undefined,
}

ClientCancelBookingModalComponent.propTypes = {
  cancellationError: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default ClientCancelBookingModalComponent
