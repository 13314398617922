import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import SwitchField from 'views/shared/SwitchField'

const Toggle = ({ field }) => (
  <div data-cy={`${field}-menu-item`} className="ws-header__editor-body-item">
    <div>
      <p data-cy="section-title" className="text-subheader mb-4">
        <FormattedMessage id={`websitePreview.enableDisableSections.${field}.title`} />
      </p>
      <p data-cy="section-description" className="in-blue-gray-300 mb-0">
        <FormattedMessage id={`websitePreview.enableDisableSections.${field}.description`} />
      </p>
    </div>
    <Field name={field} id={field} component={SwitchField} className="mb-0" />
  </div>
)

Toggle.propTypes = {
  field: PropTypes.string.isRequired,
}

export default Toggle
