import { Select } from 'antd'
import classNames from 'clsx'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import InputField from 'views/shared/InputField'
import SelectField from 'views/shared/SelectField'
import DeleteO from 'views/shared/icons/DeleteO'
import Plus from 'views/shared/icons/Plus'

const roleSelectOptions = [
  {
    key: 'administrator',
    value: 'administrator',
    label: { id: 'employees.invitations.roleOptions.administrator' },
    description: { id: 'employees.invitations.roleOptions.administrator.description' },
  },
  {
    key: 'scheduler',
    value: 'scheduler',
    label: { id: 'employees.invitations.roleOptions.scheduler' },
    description: { id: 'employees.invitations.roleOptions.scheduler.description' },
  },
  {
    key: 'user',
    value: 'user',
    label: { id: 'employees.invitations.roleOptions.user' },
    description: { id: 'employees.invitations.roleOptions.user.description' },
  },
]

const EmployeesInvitationInputFieldsComponent = ({
  addRow,
  removeRow,
  handleBlur,
  form: {
    values: { invitations },
  },
  disableInviteBtn,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      {invitations.map(({ key }, index) => (
        <div className="ei-dialog__row" key={key}>
          <div className="ei-dialog__col">
            <Field
              name={`invitations.${index}.email`}
              component={InputField}
              id={`invitations.${index}.email`}
              type="email"
              label={{ id: 'employees.invitations.email' }}
              icon="alert"
              onBlur={handleBlur}
            />
          </div>
          <div className="ei-dialog__col">
            <Field
              name={`invitations.${index}.role`}
              component={SelectField}
              id={`invitations.${index}.role`}
              label={{ id: 'employees.invitations.role' }}
              icon="alert"
              placeholder={{ id: 'placeholders.selectRole' }}
              optionLabelProp="label"
            >
              {roleSelectOptions.map(({ label, value, key: optionKey, description }) => (
                <Select.Option
                  className="ei-dialog__select-option"
                  label={formatMessage(label)}
                  key={optionKey}
                  value={value}
                >
                  <p className="text-body mb-0">
                    <FormattedMessage {...label} />
                  </p>
                  <p className="text-caption in-blue-gray-300 mb-0">
                    <FormattedMessage {...description} />
                  </p>
                </Select.Option>
              ))}
            </Field>
          </div>
          {invitations.length > 1 && (
            <div className="ei-dialog__col ei-dialog__col--delete">
              <button
                className="ei-dialog__delete-btn"
                onClick={removeRow(index)}
                type="button"
                data-cy="modal-delete-invitation-button"
              >
                <DeleteO />
              </button>
            </div>
          )}
        </div>
      ))}
      <div>
        <button
          className={classNames('main-link font-700', { 'main-link--disabled': disableInviteBtn })}
          disabled={disableInviteBtn}
          onClick={addRow}
          type="button"
          data-cy="add-invite-row-button"
        >
          <Plus className="mr-8 va-middle" />
          <span>
            <FormattedMessage id="employees.invitations.addMember" />
          </span>
        </button>
      </div>
    </>
  )
}

EmployeesInvitationInputFieldsComponent.propTypes = {
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      invitations: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          role: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }).isRequired,
  disableInviteBtn: PropTypes.bool.isRequired,
}

export default EmployeesInvitationInputFieldsComponent
