const bookings = {
  'bookings.title': '_(Appointments)',
  'bookings.backToAppointmentsBtn': 'List of _(appointments)',
  'bookings.status.scheduled': 'Scheduled',
  'bookings.status.rescheduled': 'Rescheduled',
  'bookings.status.cancelled': 'Cancelled',
  'bookings.status.completed': 'Completed',
  'bookings.status.pending': 'Requested',
  'bookings.status.reserved': 'Awaiting payment',

  'bookings.toolbar.today': 'Today',
  'bookings.toolbar.prevDay': 'Previous day',
  'bookings.toolbar.nextDay': 'Next day',
  'bookings.toolbar.prevWeek': 'Previous week',
  'bookings.toolbar.nextWeek': 'Next week',
  'bookings.toolbar.selectDate': 'Select date',
  'bookings.toolbar.day': 'Day',
  'bookings.toolbar.dayView': 'Day view',
  'bookings.toolbar.week': 'Week',
  'bookings.toolbar.weekView': 'Week view',
  'bookings.toolbar.schedule': 'Schedule',
  'bookings.toolbar.scheduleView': 'Schedule view',
  'bookings.toolbar.team': 'Team',
  'bookings.toolbar.teamView': 'Team view',

  'bookings.expert': '_(Expert)',
  'bookings.client': '_(Client)',
  'bookings.workspace_owner': 'WO',
  'bookings.duration': 'Duration',
  'bookings.rescheduleReason.client': 'Rescheduled by _(Client)',
  'bookings.rescheduleReason.user': 'Rescheduled by _(Expert)',
  'bookings.cancellationReason.default': 'Cancelled automatically',
  'bookings.cancellationReason.defaultDescription':
    'The _(appointment) was cancelled because the _(client) didn’t respond to the invitation.',
  'bookings.cancellationReason.ClientProfile': 'Cancelled by _(Client)',
  'bookings.cancellationReason.UserProfile': 'Cancelled by _(Expert)',
  'bookings.cancellationReason.ClientContact': 'Cancelled by _(Client)',
  'bookings.refunded': 'Refunded',
  'bookings.viewMeetingPage': 'View meeting page',
  'bookings.reschedule': 'Reschedule',
  'bookings.rescheduleAppointment': 'Reschedule _(appointment)',
  'bookings.rescheduleRecurring': 'Reschedule _(appointments)',
  'bookings.rescheduleError': '_(Appointment) can’t be rescheduled',
  'bookings.requestReschedule': 'Request reschedule',
  'bookings.cancelBooking': 'Cancel _(appointment)',
  'bookings.appointmentsTitle': 'Your _(appointments)',
  'bookings.upcomingAppointments': 'Upcoming',
  'bookings.pendingAppointments': 'Requested',
  'bookings.pastAppointments': 'Past',
  'bookings.cancelAppointments': 'Cancelled',
  'bookings.waitlist': 'Waitlist requests',
  'bookings.upcoming.empty': 'You have no upcoming _(appointments).',
  'bookings.past.empty':
    'You have no past _(appointments) yet. As soon as you have your first _(appointment), it will appear here.',
  'bookings.cancelled.empty': 'If any of your _(appointments) gets cancelled, it will appear here.',
  'bookings.pending.empty': 'You have pending _(appointments) yet',
  'bookings.welcomeModal.title': 'Welcome to your _(Appointments) page',
  'bookings.welcomeModal.scheduler.title': 'Welcome to team _(Appointments) page',
  'bookings.welcomeModal.scheduler.viewWithTeamMembers':
    'Here you will see all team _(appointments). You can choose the view convenient for you.',
  'bookings.welcomeModal.viewWithTeamMembers':
    'Here you will see all your _(appointments). You can choose the view convenient for you.',
  'bookings.welcomeModal.usingFilters':
    'You can use filters to easily find _(appointments) for specific _(service), consultant, _(client) or status.',
  'bookings.welcomeModal.youCanPickAnyDate':
    'You can pick any date and view the list of _(appointments) scheduled for that date.',
  'bookings.welcomeModal.clickOnBookingCard': 'Click on the _(appointment) card to open the _(appointment) details.',
  'bookings.welcomeModal.clickOnDotMenu':
    'Click on three-dot menu if you need to cancel _a(appointment) or request reschedule.',
  'bookings.welcomeModal.exploreDetails':
    'Click on _(appointment) in the calendar to explore its details or go to the consultation page.',
  'bookings.welcomeModal.changeView':
    'All your _(appointments) will appear on this screen. You can check any _(appointment) and its details. You can also change view to schedule.',
  'bookings.welcomeModal.joinConsultation':
    'Click on the Join meeting button to join a video chat. This button will be active 15 min before it starts.',
  'bookings.welcomeModal.joinConsultationButton':
    'Join meeting button becomes active 15 minutes before the _(appointment) starts.',
  'bookings.welcomeModal.theCallWillStart':
    'The call will start right within your browser (Chrome, Firefox, Safari, Opera), and you do not need to install any additional programs.',
  'bookings.welcomeModal.beforeJoiningAdjustMicrophone':
    'Before joining the meeting room you can adjust camera and microphone settings.',
  'bookings.empty': 'No _(appointments) yet',
  'bookings.syncEvents.info.timeAvailable': 'Time of this event is available for new bookings',
  'bookings.syncEvents.info.timeUnavailable': 'Time of this event is unavailable for new bookings',
  'bookings.syncEvents.info.dayUnavailable':
    'This all day event is set as unavailable, so you can’t get new _(appointments) for this date.',
  'bookings.syncEvents.info.changeSettings': 'Change settings',
  'bookings.syncEvents.allDayEvent': 'All-day',
  'bookings.members': 'Members',
  'bookings.invitedByEmail': 'Invited by email',
  'bookings.invitedByEmails': 'Invited by emails',
  'bookings.removeMember': 'Remove member',
  'bookings.invitedMembers': 'Invited members',
  'bookings.alsoInvited': 'Also invited',
  'bookings.addToGoogleCalendar': 'Add to Google Calendar',
  'bookings.addToOutlook': 'Add to Outlook / Apple Calendar',
  'bookings.googleCalendar': '{icon} Google Calendar',
  'bookings.microsoftOutlook': '{icon} Microsoft Outlook',
  'bookings.iCloudCalendar': '{icon} iCloud Calendar',
  'bookings.collectPayment': 'Collect payment',
  'bookings.chargeWithCardReader': 'Charge with card reader',
  'bookings.addToCalendar': 'Add to calendar',
  'bookings.videoCallRecordings': 'Video call recordings',
  'bookings.removeRecording.confirmModal.title': 'Delete video call recording',
  'bookings.removeRecording.confirmModal.body':
    'Delete this video call recording? It will be no longer available for other _(experts) or _(clients)',
  'bookings.removeChatHistory.confirmModal.title': 'Delete chat history?',
  'bookings.removeChatHistory.confirmModal.body':
    'Do you want to delete chat history for everyone? This cannot be undone.',
  'bookings.clientDecline.title': 'Decline this _(appointment)?',
  'bookings.clientDecline.body': 'You won’t be able to change your response later.',
  'bookings.editPendingAppointment': 'Edit _(appointment)',
  'bookings.cancelPendingAppointment': 'Cancel _(appointment)',
  'bookings.clientDeclineRecurring.title': 'Decline recurring _(appointment)?',
  'bookings.clientDeclineRecurring.body': 'You won’t be able to change your response later.',
  'bookings.clientDeclineRecurring.options.single': 'This _(appointment)',
  'bookings.clientDeclineRecurring.options.all': 'All pending _(appointments) in the series',
  'bookings.clientDeclineRecurring.cancel': 'Leave',
  'bookings.clientDeclineRecurring.submit': 'Decline _(appointment)',
  'bookings.clientDeclineAllRecurringAppointments.success': 'You declined the _(appointments)',
  'bookings.reschedulePolicy': 'Reschedule policy',
  'bookings.cancelPolicy': 'Cancellation policy',
  'bookings.noServiceCategory': 'Custom _(service)',
  'bookings.online': 'Online',
  'bookings.online.expertbox': 'ExpertBox online _(appointment)',
  'bookings.onlineAppointment': 'Online _(appointment)',
  'bookings.online.google_meet': 'Google Meet online _(appointment)',
  'bookings.online.zoom': 'Zoom online _(appointment)',
  'bookings.online.microsoft_teams': 'Microsoft Teams online _(appointment)',

  'bookings.allDayGutter': 'all-day',

  'bookings.menu.settings': 'Settings',
  'bookings.calendarSettings.modal.title': 'Change _(appointment) color preferences',
  'bookings.calendarSettings.modal.description':
    '_(Appointments) show in different colors so you can easily tell them apart.',
  'bookings.calendarSettings.modal.option.service': 'Color code _(appointments) <b>by different _(services)</b>',
  'bookings.calendarSettings.modal.option.team_member': 'Color code _(appointments) <b>by different team members</b>',

  'bookings.deleteMemberModal.title': 'Remove this member?',
  'bookings.deleteMemberModal.body':
    '<b>{email}</b> will get a cancellation email but will still have access to this _(appointment).',
}

export default bookings
