import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_REVIEW } from 'state/concepts/clientAccountReview/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { updateClientReviewEndpoint } from 'state/concepts/clientAccountReview/endpoints'

const updateClientReview = createLogic({
  type: UPDATE_REVIEW,
  latest: true,

  async process({ action: { form, values, reviewId }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateClientReviewEndpoint(reviewId)
    const { rating, description } = values

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, {
        rating,
        text: description,
      })

      const response = updateDataHelper(getState().data, 'review', reviewId, {
        attributes: {
          rating,
          text: description,
          updatedAt: DateTime.utc().toISO(),
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.reviewWasUpdated',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updateClientReview
