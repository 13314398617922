import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { mergeDeepRight } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { CANCEL_CLIENT_RECURRING_PAYMENT } from 'state/concepts/recurringPayment/types'
import { TRANSACTION_STATUSES } from 'lib/constants/payments'
import { cancelRecurringPaymentEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { entityWorkspaceUniqCodeSelector } from 'state/concepts/session/selectors'
import { buildCustomBaseUrl } from 'lib/httpClient'

const cancelClientRecurringPaymentOperation = createLogic({
  type: CANCEL_CLIENT_RECURRING_PAYMENT,
  latest: true,

  async process({ httpClient, getState, action: { recurringPaymentId, transactionId, onSuccess } }, dispatch, done) {
    const state = getState()
    const { url, endpoint } = cancelRecurringPaymentEndpoint(recurringPaymentId)
    const workspaceCode = entityWorkspaceUniqCodeSelector(state, transactionId, 'transaction')

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, {}, buildCustomBaseUrl(['workspaces', workspaceCode]))

      const apiResponse = normalize(data)
      const updatedResponse = updateDataHelper(state.data, 'transaction', transactionId, {
        attributes: {
          status: TRANSACTION_STATUSES.cancelled,
        },
      })

      dispatch(dataApiSuccess({ endpoint, response: mergeDeepRight(updatedResponse, apiResponse) }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.autoPayWasCancelled' },
        }),
      )
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default cancelClientRecurringPaymentOperation
