import { toUpper } from 'ramda'

import { hasUploadedKey } from 'utils/intakeForms'
import isPresent from 'utils/isPresent'
import { MAX_FILE_SIZE } from 'lib/constants'
import { fileSize as fileSizeHumanized, fileExtension } from 'utils/file'

export function fileSize(maxSize = MAX_FILE_SIZE) {
  return this.test(
    'fileSize',
    {
      id: 'yup.mixed.fileSize',
      values: { size: fileSizeHumanized(maxSize) },
    },
    value => !value || value?.size <= maxSize,
  )
}

export function fileType(extensions = []) {
  return this.test(
    'fileType',
    {
      id: 'yup.mixed.fileType',
      values: { formats: extensions.map(toUpper).join(', ') },
    },
    value => !value || extensions.includes(fileExtension(value)),
  )
}

export function formAttachment() {
  return this.test(
    'formAttachment',
    { id: 'yup.string.requiredField' },
    value => isPresent(value) && hasUploadedKey(value),
  )
}
