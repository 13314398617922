import { always, cond, propEq, T } from 'ramda'
import { capitalize } from 'lodash'

import { DEFAULT_VALUES, REFUND_RESCHEDULE_VALUES } from 'lib/constants/bookingPolicy'
import { durationToFormatString, durationToSeconds, secondsToValueWithUnits, valueWithUnitToSeconds } from './duration'

export const getRefundRescheduleOptionParamValue = (isRefund = false) =>
  cond([
    [isRefund ? propEq(false, 'refundable') : propEq(false, 'reschedulable'), always(REFUND_RESCHEDULE_VALUES.never)],
    [
      isRefund ? propEq(true, 'refundableAnytime') : propEq(true, 'reschedulableAnytime'),
      always(REFUND_RESCHEDULE_VALUES.anytime),
    ],
    [T, always(REFUND_RESCHEDULE_VALUES.upTo)],
  ])

export const bookingPolicyParamsToForm = settings => ({
  reschedulable: settings?.reschedulableOption
    ? settings?.reschedulableOption !== REFUND_RESCHEDULE_VALUES.never
    : DEFAULT_VALUES.reschedulable,
  reschedulableAnytime: settings?.reschedulableOption
    ? settings?.reschedulableOption === REFUND_RESCHEDULE_VALUES.anytime ||
      settings?.reschedulableOption === REFUND_RESCHEDULE_VALUES.never
    : DEFAULT_VALUES.reschedulableAnytime,
  reschedulableAny: capitalize(REFUND_RESCHEDULE_VALUES.anytime),
  reschedulableLeadTimeAmount: settings?.reschedulableUntil
    ? secondsToValueWithUnits(settings?.reschedulableUntil).value
    : DEFAULT_VALUES.reschedulableLeadTimeAmount,
  reschedulableLeadTimeUnit: settings?.reschedulableUntil
    ? secondsToValueWithUnits(settings?.reschedulableUntil).unit
    : DEFAULT_VALUES.reschedulableLeadTimeUnit,
  refundable: settings?.cancellableOption
    ? settings?.cancellableOption !== REFUND_RESCHEDULE_VALUES.never
    : DEFAULT_VALUES.refundable,
  refundableAnytime: settings?.cancellableOption
    ? settings?.cancellableOption === REFUND_RESCHEDULE_VALUES.anytime ||
      settings?.cancellableOption === REFUND_RESCHEDULE_VALUES.never
    : DEFAULT_VALUES.refundableAnytime,
  refundableAny: capitalize(REFUND_RESCHEDULE_VALUES.anytime),
  refundableLeadTimeAmount: settings?.cancellableUntil
    ? secondsToValueWithUnits(settings?.cancellableUntil).value
    : DEFAULT_VALUES.refundableLeadTimeAmount,
  refundableLeadTimeUnit: settings?.cancellableUntil
    ? secondsToValueWithUnits(settings?.cancellableUntil).unit
    : DEFAULT_VALUES.refundableLeadTimeUnit,
  bufferBefore: durationToFormatString(settings?.bufferBefore) || DEFAULT_VALUES.bufferBefore,
  bufferAfter: durationToFormatString(settings?.bufferAfter) || DEFAULT_VALUES.bufferAfter,
  minLeadTime: settings?.minLeadTime
    ? secondsToValueWithUnits(settings?.minLeadTime).value
    : DEFAULT_VALUES.minLeadTime,
  minLeadTimeUnit: settings?.minLeadTime
    ? secondsToValueWithUnits(settings?.minLeadTime).unit
    : DEFAULT_VALUES.minLeadTimeUnit,
  maxFutureTime: settings?.availableFor
    ? secondsToValueWithUnits(settings?.availableFor).value
    : DEFAULT_VALUES.maxFutureTime,
  maxFutureTimeUnit: settings?.availableFor
    ? secondsToValueWithUnits(settings?.availableFor).unit
    : DEFAULT_VALUES.maxFutureTimeUnit,
})

export const bookingPolicyFormToParams = values => ({
  cancellable_option: getRefundRescheduleOptionParamValue(true)(values),
  cancellable_until: valueWithUnitToSeconds(values.refundableLeadTimeUnit, values.refundableLeadTimeAmount),
  reschedulable_option: getRefundRescheduleOptionParamValue()(values),
  reschedulable_until: valueWithUnitToSeconds(values.reschedulableLeadTimeUnit, values.reschedulableLeadTimeAmount),
  buffer_before: durationToSeconds(values.bufferBefore),
  buffer_after: durationToSeconds(values.bufferAfter),
  min_lead_time: valueWithUnitToSeconds(values.minLeadTimeUnit, values.minLeadTime),
  available_for: valueWithUnitToSeconds(values.maxFutureTimeUnit, values.maxFutureTime),
})
