const employeeAccountSettings = {
  'employeeAccountSettings.title': 'Account settings',
  'employeeAccountSettings.tab.general': 'General',
  'employeeAccountSettings.tab.security': 'Security',
  'employeeAccountSettings.tab.notifications': 'Notifications',
  'employeeAccountSettings.notificationsSettings': 'Notification settings',
  'employeeAccountSettings.groupName.appointmentReminders': '_(Appointment) reminders',
  'employeeAccountSettings.groupName.communication': 'Communication',
  'employeeAccountSettings.groupName.serviceUpdates': '_(Service) updates',
  'employeeAccountSettings.groupName.availabilityUpdates': 'Availability updates',
  'employeeAccountSettings.groupName.teamAppointments': 'Team _(appointments)',
  'employeeAccountSettings.groupName.myAppointments': 'My _(appointments)',
  'employeeAccountSettings.groupName.myTeam': 'Team',
  'employeeAccountSettings.groupName.news': 'News',
  'employeeAccountSettings.groupName.tasks': 'Tasks',
  'employeeAccountSettings.groupName.myForms': 'My forms',
  'employeeAccountSettings.groupName.businessStorages': 'Business files updates',

  'employeeAccountSettings.groupDescription.appointmentReminders':
    'Manage and edit auto-reply emails, reminders & notifications sent to you.',
  'employeeAccountSettings.groupDescription.communication': 'When I get a message, somebody joins or leaves a chat.',
  'employeeAccountSettings.groupDescription.serviceUpdates':
    'When I am assigned to _a(service), or new _(services) are created, updated or deleted.',
  'employeeAccountSettings.groupDescription.availabilityUpdates': "When someone changes my or my team's availability.",
  'employeeAccountSettings.groupDescription.teamAppointments':
    'When my team member has a new _(appointment) or any changes to existing ones.',
  'employeeAccountSettings.groupDescription.myAppointments':
    'When I have a new _(appointment), changes to existing ones, and _(appointment) reminders.',
  'employeeAccountSettings.groupDescription.myTeam':
    'When a team member joins or leaves my workspace, changes role or updates profile.',
  'employeeAccountSettings.groupDescription.news': 'News',
  'employeeAccountSettings.groupDescription.tasks': 'Tasks',
  'employeeAccountSettings.groupDescription.myForms': 'When I have a new form to fill out or sign, and form reminders.',
  'employeeAccountSettings.groupDescription.businessStorages': 'When someone shares a file or a folder with me.',
  'employeeAccountSettings.groupDescription.businessStorages.scheduler':
    'When someone shares a file or a folder with me.',

  'employeeAccountSettings.groupDescription.appointmentReminders.scheduler':
    'Manage and edit auto-reply emails, reminders & notifications sent to you.',
  'employeeAccountSettings.groupDescription.communication.scheduler':
    'When I get a message, somebody joins or leaves a chat.',
  'employeeAccountSettings.groupDescription.serviceUpdates.scheduler':
    'When someone is assigned to _a(service), or new _(services) are created, updated or deleted.',
  'employeeAccountSettings.groupDescription.availabilityUpdates.scheduler':
    "When someone changes my team's availability.",
  'employeeAccountSettings.groupDescription.teamAppointments.scheduler':
    'When my team member has a new _(appointment) or any changes to existing ones.',
  'employeeAccountSettings.groupDescription.myAppointments.scheduler':
    'When I have a new _(appointment), changes to existing ones, and _(appointment) reminders.',
  'employeeAccountSettings.groupDescription.myTeam.scheduler':
    'When a team member joins or leaves my workspace, changes role or updates profile.',
  'employeeAccountSettings.groupDescription.news.scheduler': 'News',
  'employeeAccountSettings.groupDescription.tasks.scheduler': 'Tasks',
  'employeeAccountSettings.groupDescription.myForms.scheduler':
    'When I have a new form to fill out or sign, and form reminders.',

  'employeeAccountSettings.inApp': 'In-app',
  'employeeAccountSettings.email': 'Email',
  'employeeAccountSettings.sms': 'Sms',
  'employeeAccountSettings.notification.myInvitationHasBeenAccepted': 'My invitation to join has been accepted',
  'employeeAccountSettings.notification.myOwnershipHasBeenTransferred': 'My ownership has been transferred',
  'employeeAccountSettings.notification.myRoleHasBeenChanged': 'My role has been changed',
  'employeeAccountSettings.notification.myProfileHasBeenUpdated': 'My profile has been updated',
  'employeeAccountSettings.notification.workspaceOwnershipHasBeenTransferred':
    'Workspace ownership has been transferred',
  'employeeAccountSettings.notification.newTeamMemberHasJoined': 'New team member joins the workspace',
  'employeeAccountSettings.notification.teamMemberRoleHasBeenChanged': "Team member's role is changed",
  'employeeAccountSettings.notification.teamMemberHasBeenDeactivated': 'Team member has been deactivated',
  'employeeAccountSettings.notification.teamMemberHasBeenActivated': 'Team member has been activated',
  'employeeAccountSettings.notification.teamMemberHasBeenRemoved': 'Team member has been removed',
  'employeeAccountSettings.notification.teamMemberProfileHasBeenUpdated': "Team member's profile is updated",
  'employeeAccountSettings.notification.teamMemberHasUpdatedHisProfile': 'Team member has updated his profile',
  'employeeAccountSettings.notification.iHaveBeenAssignedToService': 'I have been assigned to the _(service)',
  'employeeAccountSettings.notification.iHaveBeenUnassignedFromService': 'I have been unassigned from the _(service)',
  'employeeAccountSettings.notification.newServiceHasBeenCreated': 'New _(service) has been created',
  'employeeAccountSettings.notification.serviceHasBeenUpdated': 'Workspace _(services) updates',
  'employeeAccountSettings.notification.serviceHasBeenDeactivated': '_(Service) has been deactivated',
  'employeeAccountSettings.notification.serviceHasBeenActivated': '_(Service) has been activated',
  'employeeAccountSettings.notification.newTeamMemberHasBeenAssignedToService':
    'New team member has been assigned to the _(service)',
  'employeeAccountSettings.notification.teamMemberHasBeenUnassignedFromService':
    'Team member has been unassigned from _(service)',
  'employeeAccountSettings.notification.serviceHasBeenDeleted': '_(Service) has been deleted',
  'employeeAccountSettings.notification.myServiceHasBeenUpdated': ' Updates in _(services), I assigned to',
  'employeeAccountSettings.notification.myServiceHasBeenDeactivated': 'My _(service) has been deactivated',
  'employeeAccountSettings.notification.myServiceHasBeenActivated': 'My _(service) has been activated',
  'employeeAccountSettings.notification.myServiceHasBeenDeleted': 'My _(service) has been deleted',
  'employeeAccountSettings.notification.iHaveANewAppointmentBooked': 'I have a new _(appointment) booked',
  'employeeAccountSettings.notification.videoConferenceRoomIsActive': 'Video conference room is active',
  'employeeAccountSettings.notification.clientHasCancelledMyAppointment': 'My _(appointment) is cancelled',
  'employeeAccountSettings.notification.clientHasRescheduledAppointmentWithMe':
    'My _(appointment) has been rescheduled',
  'employeeAccountSettings.notification.iWasAssignedToNewAppointment': 'Team member assigned _(appointment) to me',
  'employeeAccountSettings.notification.appointmentInDay': 'My _(appointment) starts in a day',
  'employeeAccountSettings.notification.appointmentIn30Mins': 'My _(appointment) starts in 30 mins',
  'employeeAccountSettings.notification.appointmentStartsNow': 'My _(appointment) starts now',
  'employeeAccountSettings.notification.teamMemberHasANewAppointment': 'Team member has a new _(appointment)',
  'employeeAccountSettings.notification.teamMemberHasCancelledTheAppointment':
    "Team member's _(appointment) has been cancelled",
  'employeeAccountSettings.notification.clientHasCancelledTheAppointmentWithTeamMember':
    '_(Client) has cancelled the _(appointment) with team member',
  'employeeAccountSettings.notification.clientHasMadeARescheduleWithTeamMember':
    '_(Client) reschedules _a(appointment) with a team member',
  'employeeAccountSettings.notification.teamMemberHasUpdatedHisRegularSchedule':
    'Team member has updated his regular schedule',
  'employeeAccountSettings.notification.teamMemberHasUpdatedHisDaySpecificSchedule':
    'Team member has updated his day-specific schedule',
  'employeeAccountSettings.notification.teamMemberRegularScheduleHasBeenUpdated':
    "Team member's regular schedule has been updated ",
  'employeeAccountSettings.notification.teamMemberDaySpecificScheduleHasBeenUpdated':
    "Team member's day-specific schedule has been updated",
  'employeeAccountSettings.notification.myRegularScheduleHasBeenUpdated': 'My regular schedule has been updated',
  'employeeAccountSettings.notification.myDaySpecificScheduleHasBeenUpdated':
    'My day-specific schedule has been updated',
  'employeeAccountSettings.notification.iHaveNewMessage': 'I have a new message',
  'employeeAccountSettings.notification.newChatMessageUnreadWithin15Mins': 'New chat message unread within 15 mins',
  'employeeAccountSettings.notification.newDocumentReceived': 'New document received',
  'employeeAccountSettings.notification.newDocumentUnreadWithin15Mins': 'New document unread within 15 mins',
  'employeeAccountSettings.notification.appointmentVideoRecordingIsReady': '_(Appointment) video recording is ready',
  'employeeAccountSettings.notification.news': 'News',
  'employeeAccountSettings.notification.myAppointmentHasBeenCancelled': 'My _(appointment) is cancelled',
  'employeeAccountSettings.notification.myAppointmentHasBeenRescheduled': 'My _(appointment) is rescheduled',
  'employeeAccountSettings.notification.myAppointmentHasBeenAcceptedOrDeclinedByClient':
    'My invitation to _(appointment) has been accepted or declined by _(Client)',
  'employeeAccountSettings.notification.clientSubmitsIntakeForm': 'A form is submitted',
  'employeeAccountSettings.notification.clientSubmitsAgreementForm': 'A _(service agreement) is signed',
  'employeeAccountSettings.notification.myAppointmentHasBeenUpdated': 'My _(appointment) is updated',
  'employeeAccountSettings.notification.myAppointmentHasBeenReviewed': '_(Client) reviewed my _(appointment) ',
  'employeeAccountSettings.notification.teamMemberHasAppointmentReviewed':
    "_(Client) reviewed a team member's _(appointment)",
  'employeeAccountSettings.notification.providerAssignedTaskToMe': 'You have new task',
  'employeeAccountSettings.notification.myTaskHasADueDateToday': 'Task is overdue',
  'employeeAccountSettings.doNotSendNotifications': 'Do not send me any emails or web notifications',
  'employeeAccountSettings.doNotSendNotificationsAndSmsReminders':
    'Do not send me any emails, web, or SMS notifications',
  'employeeAccountSettings.doNotSendAnyNotifications': 'Do not send me any notifications',
  'employeeAccountSettings.notSendNote': 'Note: You will always receive transactional emails related to your account.',
  'employeeAccountSettings.notification.teamMemberHasBeenUpdated': 'Team updates',
  'employeeAccountSettings.notification.teamMemberAppointmentIsCancelled': "Team member's _(appointment) is cancelled",
  'employeeAccountSettings.notification.newTeamMemberHasBeenUpdatedToService': 'Someone is assigned to _a(service)',
  'employeeAccountSettings.notification.iHaveANewFormToFillOut': 'I have a new form to fill out or sign',
  'employeeAccountSettings.notification.unfilledFormsReminder': 'Remind about unfilled forms every week',
  'employeeAccountSettings.notification.myFormIsDeclined': '_(Client) declines to sign a form',
  'employeeAccountSettings.notification.teamMemberAvailabilityIsUpdated': "Team members' availability is updated",
  'employeeAccountSettings.notification.iHaveANewSharedFile': 'I have a new shared file or folder',
}

export default employeeAccountSettings
