import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import redirect from 'utils/redirect'
import { currentWorkspaceCodeSelector, currentEmployeeSelector } from 'state/concepts/session/selectors'
import isAdminOrOwner from 'utils/employeePermissions/isAdminOrOwner'
import { isBlankStateSearchResultsSelector } from 'state/searchResults/selectors'
import { companySettingsServicesCreateRoute } from 'lib/routes'
import NotFoundContentComponent from './component'

class NotFoundContent extends React.Component {
  static propTypes = {
    currentEmployee: PropTypes.shape().isRequired,
    isBlankState: PropTypes.bool,
    currentWorkspaceCode: PropTypes.string.isRequired,
    onServiceCreateCallback: PropTypes.func,
  }

  static defaultProps = {
    onServiceCreateCallback: noop,
    isBlankState: false,
  }

  get isOwnerOrAdminDoNotHaveServices() {
    const { isBlankState, currentEmployee } = this.props

    return isBlankState && isAdminOrOwner(currentEmployee)
  }

  get isExpertDoesNotHaveServices() {
    const { isBlankState, currentEmployee } = this.props

    return isBlankState && !isAdminOrOwner(currentEmployee)
  }

  handleRedirectToCreateService = () => {
    const { currentWorkspaceCode, onServiceCreateCallback } = this.props

    onServiceCreateCallback()

    redirect({
      href: companySettingsServicesCreateRoute,
      workspace: currentWorkspaceCode,
    })
  }

  render = () => (
    <NotFoundContentComponent
      {...this.props}
      isOwnerOrAdminDoNotHaveServices={this.isOwnerOrAdminDoNotHaveServices}
      isExpertDoesNotHaveServices={this.isExpertDoesNotHaveServices}
      onRedirectToCreateService={this.handleRedirectToCreateService}
    />
  )
}

const mapStateToProps = state => ({
  currentWorkspaceCode: currentWorkspaceCodeSelector(state),
  currentEmployee: currentEmployeeSelector(state),
  isBlankState: isBlankStateSearchResultsSelector(state, SEARCH_RESULTS_ENTITIES.services),
})

export { NotFoundContent as NotFoundContentContainer }
export default connect(mapStateToProps)(NotFoundContent)
