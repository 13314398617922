import { createLogic } from 'redux-logic'

import { FINISH_TUTORIAL } from 'state/concepts/tutorial/types'
import { dataClearRelationship } from 'state/data/actions'
import { setIsCompleted } from 'state/concepts/tutorial/actions'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'

const finishTutorialOperation = createLogic({
  type: FINISH_TUTORIAL,
  latest: true,

  process({ getState }, dispatch, done) {
    const employeeId = currentEmployeeIdSelector(getState())

    dispatch(
      dataClearRelationship({
        kind: 'userProfile',
        ids: employeeId,
        relationship: 'userTutorial',
      }),
    )
    dispatch(setIsCompleted(false))
    done()
  },
})

export default finishTutorialOperation
