const client = {
  'client.bookings.empty': 'There are no upcoming _(appointments) with this _(client).',
  'client.chats.empty': "This is where you'll see all your chats with _(experts)",
  'client.backToMeetingPage': 'Back to meeting page',

  'client.account.menu.appointments': '_(Appointments)',
  'client.account.menu.appointments.tooltip': '{count} new _(appointment){count, plural, one {} other {s}}',
  'client.account.menu.bookAppointment': 'Book _(appointment)',
  'client.account.menu.chats': 'Chats',
  'client.account.menu.chats.tooltip': '{count} unread message{count, plural, one {} other {s}}',
  'client.account.menu.forms': 'Forms',
  'client.account.menu.forms.tooltip': '{count} new form{count, plural, one {} other {s}}',
  'client.account.menu.agreements': 'Agreements',
  'client.account.menu.agreements.tooltip':
    '{count} new {count, plural, one {_(service agreement)} other {_(service agreements)}}',
  'client.account.menu.documents': 'Documents',
  'client.account.menu.documents.tooltip': '{count} new {count, plural, one {document} other {documents}}',
  'client.account.menu.files': 'Files',
  'client.account.menu.files.tooltip': '{count} new file{count, plural, one {} other {s}}',
  'client.account.menu.payments': 'Payments',
  'client.account.menu.payments.tooltip': '{count} pending {count, plural, one {payment} other {payments}}',
  'client.account.menu.reviews': 'Reviews',
  'client.account.settings.title': 'Account settings',
  'client.account.settings.tab.general': 'General',
  'client.account.settings.tab.notifications': 'Notifications',
  'client.account.settings.tab.general.delete.title': 'Delete account',
  'client.account.settings.tab.general.deleteContact.title': 'Delete my access to {clientName}`s _(client) portal',
  'client.account.settings.tab.general.delete.text':
    'You can still restore your account during a 7 day period. After that, your account and data will be deleted forever.',
  'client.account.settings.tab.general.deleteContact.text':
    'You can still restore your account during a 7 day period. After that, your access and your data related to this _(client) portal will be deleted forever.',
  'client.account.settings.tab.general.delete.button': 'Delete account',
  'client.account.settings.tab.general.deleteContact.button': 'Delete access',
  'client.account.settings.tab.general.delete.modal.title': 'Delete your account?',
  'client.account.settings.tab.general.deleteContact.modal.title': 'Remove access to {clientName}’s _(client) portal?',
  'client.account.settings.tab.general.delete.modal.body':
    'Before you delete your account and personal data, please read this carefully:',
  'client.account.settings.tab.general.deleteContact.modal.body':
    'Before you remove your access and information, related to this _(client) portal, please read this carefully:',
  'client.account.settings.tab.general.delete.modal.body.your_account': 'Your account',
  'client.account.settings.tab.general.delete.modal.body.your_personal_data': 'Your personal data',
  'client.account.settings.tab.general.deleteContact.modal.body.your_personal_data':
    'Your information related to this _(client) portal',
  'client.account.settings.tab.general.delete.modal.body.your_appointments': 'Your _(appointments)',
  'client.account.settings.tab.general.delete.modal.body.accessToOtherClientInformation':
    'Access to other _(client) information',
  'client.account.settings.tab.general.delete.modal.body.accessToOtherClientInformation.text':
    'You’ll will lose any permissions and access to {names}’s information or _(appointments).',
  'client.account.settings.tab.general.delete.modal.body.payments': 'Payments',
  'client.account.settings.tab.general.delete.modal.body.payments.text':
    'You are currently in charge of billing for {names}. Once your access is removed and there are no other billing contacts, they will manage all their payments alone.',
  'client.account.settings.tab.general.deleteContact.modal.body.payments.text':
    'You are currently in charge of billing for {clientName}. Once your access is removed and there are no other billing contacts, {clientName} will manage their payments alone.',
  'client.account.settings.tab.general.delete.modal.body.your_account.text':
    'If you delete your {workspaceName} account, you’ll no longer be able to use {workspaceName} _(services), and your account and data will be lost.',
  'client.account.settings.tab.general.delete.modal.body.your_personal_data.text':
    'We will only keep your data for 7 days before it will be deleted forever. You can reactivate your account and restore all the data within that period.',
  'client.account.settings.tab.general.deleteContact.modal.body.your_personal_data.text':
    'We will only keep your data for 7 days before it will be deleted forever. You can reactivate your access and restore all the data within that period.',
  'client.account.settings.tab.general.delete.modal.body.your_appointments.text':
    'All your upcoming _(appointments) will be canceled automatically and you will get the refund if it’s possible according to the cancellation policy.',
  'client.account.settings.tab.general.delete.modal.password': 'Password',
  'client.account.settings.tab.general.delete.modal.submitButton': 'Delete account',
  'client.account.settings.tab.general.deleteContact.modal.submitButton': 'Delete access',
  'client.account.settings.tab.general.changeEmail.title': 'Contact details',
  'client.account.settings.tab.general.changeEmail.email': 'Email',
  'client.account.settings.tab.general.changeEmail.phoneNumber': 'Phone number',
  'client.account.settings.tab.general.phoneNumber.placeholder': '(123) 555-5555',
  'client.account.settings.tab.general.changeEmail.password': 'Password (if changing email)',
  'client.account.settings.tab.general.changeEmail.emailConfirmed': 'Confirmed',
  'client.account.settings.tab.general.changeEmail.emailNotSaved': 'Not saved',
  'client.account.settings.tab.general.delete.modal.confirmationCodeForm.submitButton': 'Get confirmation code',
  'client.account.settings.tab.general.delete.modal.confirmationCodeForm.info':
    'To keep your account safe, we’ll send a confirmation code to your email.',
  'client.account.settings.tab.general.delete.modal.confirmationCode.text':
    'We just sent you a temporary confirmation code. Please check your inbox.',
  'client.account.settings.tab.general.delete.modal.confirmationCode.label': 'Confirmation code',
  'client.account.settings.tab.general.delete.modal.confirmationCode.resend': 'Resend',
  'client.account.settings.tab.general.delete.modal.confirmationCode.placeholder': 'Enter confirmation code',
  'client.account.settings.tab.general.delete.modal.confirmationCode.didNotGetCode': 'Didn’t get the code? ',
  'client.account.settings.tab.general.delete.modal.incorrectCode': 'Incorrect code',
  'client.account.settings.newEmail':
    "We've sent you an email to confirm your new address: {newEmail}. We will continue to use your old address: {email}, until you confirm the change. {link}",
  'client.account.settings.resendConfirmation': 'Resend confirmation',
  'client.account.notifications.title': 'Notification settings',
  'client.account.notifications.info':
    ' Note! You’ll still receive emails related to your account settings and refunds.',
  'client.account.notifications.getNotification': 'Get email notifications',
  'client.account.reviews.empty.title': 'No reviews yet',
  'client.account.reviews.empty.description': 'This is where you’ll see all your reviews about past _(appointments).',
  'client.account.reviews.forServiceWithProfile':
    '<span>For</span> {serviceName} <span>_(service) with</span> {profileName}',
  'client.account.reviews.deleteModal.title': 'Delete this review?',
  'client.account.reviews.deleteModal.body': 'This action cannot be undone.',
  'client.appointments.acceptAndPay': 'Accept & Pay',

  'client.acceptInvitation.tokenStale': 'This invite link is no longer active',
  'client.acceptInvitation.forbiddenError.title': 'Accept invitation error',
  'client.acceptInvitation.forbiddenError.description':
    'You are currently logged into a different account. Please log out and follow the link from the invitation letter again.',

  'client.account.restoreSuccess': 'Your account is restored.',
  'client.account.restore.title': 'Restore your account?',
  'client.account.restore.body':
    'You asked to remove your account and delete all your data from the {workspaceName} on {time}',
  'client.account.restore.confirm': 'Do you want to restore your account and data?',
  'client.account.restore.button': 'Restore account',

  'client.contactAccount.restoreSuccess': 'Your access is restored.',
  'client.contactAccount.restore.title': 'Restore your access to {name}’s _(client) portal?',
  'client.contactAccount.restore.body':
    'You asked to remove your access and delete all your data from the {workspaceName} on {time}',
  'client.contactAccount.restore.confirm': 'Do you want to restore your access and data?',
  'client.contactAccount.restore.button': 'Restore access',

  'client.account.notifications.emails.title': 'Email notifications',
  'client.account.notifications.emails.enabled.description':
    'You are receiving all emails related to your activity: _(appointments), payments, account settings, etc.',
  'client.account.notifications.emails.disabled.description':
    'You aren’t receiving any emails except those related to your account settings and refunds.',
  'client.account.notifications.sms.title': 'SMS notifications',
  'client.account.notifications.sms.phoneMissing.description':
    'To start getting SMS notifications about your _(appointments) and account. Add your phone number in <link>General settings</link>.',
  'client.account.notifications.sms.disabled.description': 'You are not receiving any SMS notifications.',
  'client.account.notifications.sms.enabled.description':
    'You may receive SMS notifications related to your _(appointments) and access to the account.',
}

export default client
