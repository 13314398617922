import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { trim } from 'ramda'

import SearchListComponent from './component'

class SearchList extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    filterEntities: PropTypes.func.isRequired,
    params: PropTypes.shape(),
    searchValueRef: PropTypes.shape(),
  }

  static defaultProps = {
    defaultValue: '',
    params: {},
    searchValueRef: null,
  }

  state = {
    searchQuery: this.props.defaultValue,
    isFocused: false,
  }

  handleSearch = debounce(searchQuery => {
    const { params } = this.props
    this.props.filterEntities({ ...params, name: searchQuery })
  }, 250)

  componentDidUpdate(prevProps) {
    const { defaultValue, searchValueRef } = this.props

    if (prevProps.defaultValue !== defaultValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchQuery: defaultValue })
      if (searchValueRef) searchValueRef.current = defaultValue
    }
  }

  handleChange = ({ target: { value } }) => {
    const { searchValueRef } = this.props

    if (searchValueRef) searchValueRef.current = value
    this.setState({ searchQuery: value })
    this.handleSearch(trim(value))
  }

  handleClear = () => {
    const { searchValueRef } = this.props

    this.setState({ searchQuery: '' })
    if (searchValueRef) searchValueRef.current = ''
    this.handleSearch('')
  }

  handleFocus = () => {
    this.setState({ isFocused: true })
  }

  handleBlur = () => {
    this.setState({ isFocused: false })
  }

  render() {
    const { searchQuery, isFocused } = this.state
    const { searchValueRef } = this.props

    return (
      <SearchListComponent
        {...this.props}
        value={searchValueRef ? searchValueRef.current : searchQuery}
        isFocused={isFocused}
        onChange={this.handleChange}
        onClear={this.handleClear}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    )
  }
}

export default SearchList
