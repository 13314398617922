import { createLogic } from 'redux-logic'
import { map, trim } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspaceRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_WORKSPACE_NAME } from 'state/concepts/company/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'

const updateWorkspaceNameOperation = createLogic({
  type: UPDATE_WORKSPACE_NAME,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    try {
      const state = getState()
      const params = map(trim, values)

      await httpClient.put(workspaceRoute, params)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.workspaceNameUpdated' },
        }),
      )

      const response = updateDataHelper(state.data, 'workspace', currentWorkspaceIdSelector(state), {
        attributes: params,
      })
      form.setValues(params)

      dispatch(dataApiSuccess({ response }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateWorkspaceNameOperation
