import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Field } from 'formik'

import { getMarkAsPaidSubtitleId, isRefund } from 'utils/payments'
import { TRANSACTION_PAYMENT_METHOD_OPTIONS } from 'lib/constants/payments'
import { formatInputDate } from 'utils/dateTime'
import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import DatePickerField from 'views/shared/DatePickerField'
import SelectField from 'views/shared/SelectField'

const MarkAsPaidModalComponent = ({ onClose, handleSubmit, setTileDisabled, transaction }) => {
  const modalContext = getMarkAsPaidSubtitleId(transaction)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="mark-as-paid-modal-title">
            <FormattedMessage id={`markAsPaid.modal.${modalContext}.title`} />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <div className="d-flex justify-content-between bg-blue-100 p-16">
            <span className="font-600 font-16" data-cy="mark-as-paid-modal-total-title">
              <FormattedMessage id={`markAsPaid.modal.${modalContext}.total`} />
            </span>
            <span
              className={classNames('payment-total-price font-16', {
                'payment-total-price payment-total-price__red': isRefund(transaction),
              })}
              data-cy="mark-as-paid-modal-total-value"
            >
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={transaction.amount} style="currency" currency="USD" />
            </span>
          </div>
          <div className="d-flex justify-content-between gap-24 mt-24">
            <div className="w-100">
              <Field
                name="paymentMethod"
                component={SelectField}
                id="paymentMethod"
                label={{ id: 'markAsPaid.modal.paymentMethod.label' }}
                placeholder={{ id: 'markAsPaid.modal.paymentMethod.placeholder' }}
                options={TRANSACTION_PAYMENT_METHOD_OPTIONS}
                icon="alert"
                data-cy="mark-as-paid-modal-payment-method"
              />
            </div>
            <div className="max-w-146 w-100">
              <div className="main-input__label" data-cy="mark-as-paid-modal-date-label">
                <FormattedMessage id={`markAsPaid.modal.${modalContext}.paidOn.label`} />
              </div>
              <div className="no-icon-calendar text-left bordered-calendar">
                <Field
                  name="paidAt"
                  id="paidAt"
                  component={DatePickerField}
                  tileDisabled={setTileDisabled}
                  hideIcon
                  formatInputDate={formatInputDate}
                  arrowSize={10}
                  overlayClassName="z-index-above"
                  data-cy="mark-as-paid-modal-date-select"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main-modal__footer">
          <Button
            className="mr-16"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onClose}
            dataCy="mark-as-paid-modal-button-cancel"
          />
          <SubmitFormButton
            className="main-modal__footer-action"
            onClick={handleSubmit}
            text={{ id: `markAsPaid.modal.${modalContext}.submit` }}
            dataCy="mark-as-paid-modal-button-submit"
          />
        </div>
      </div>
    </Modal>
  )
}

MarkAsPaidModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setTileDisabled: PropTypes.func.isRequired,
  transaction: PropTypes.shape().isRequired,
}

export default MarkAsPaidModalComponent
