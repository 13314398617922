import { createLogic } from 'redux-logic'

import { dataApiRequest, dataApiSuccess, dataRemoveRelationship } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { removeMemberEndpoint } from '../endpoints'
import { REMOVE_MEMBER } from '../types'

const removeMemberOperation = createLogic({
  type: REMOVE_MEMBER,
  latest: true,

  async process({ httpClient, action: { memberId, bookingId } }, dispatch, done) {
    const { url, endpoint } = removeMemberEndpoint(memberId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(
        dataRemoveRelationship({
          kind: 'booking',
          ownerId: bookingId,
          relationship: 'invitedMembers',
          ids: [memberId],
        }),
      )
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.memberWasRemoved',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeMemberOperation
