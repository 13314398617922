import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  removeServiceCategories as removeServiceCategoriesAction,
  setSelectedServiceCategories as setSelectedServiceCategoriesAction,
  filterServicesByCategories as filterServicesByCategoriesAction,
} from 'state/concepts/serviceCategories/actions'
import { removeServiceCategoriesLoadingSelector } from 'state/concepts/serviceCategories/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServiceCategoryRemoveModal = ({ serviceCategory: { id, name, servicesCount }, onClose }) => {
  const isLoading = useSelector(removeServiceCategoriesLoadingSelector)
  const removeServiceCategories = useDispatchAction(removeServiceCategoriesAction)
  const setSelectedServiceCategories = useDispatchAction(setSelectedServiceCategoriesAction)
  const filterServicesByCategories = useDispatchAction(filterServicesByCategoriesAction)
  const withServicesPresent = servicesCount >= 1
  const submitKind = withServicesPresent
    ? { submitText: { id: 'serviceCategories.bulkRemove.modal.manageServicesButton' } }
    : { submitText: { id: 'shared.remove' }, kind: 'danger' }
  const bodyText = withServicesPresent
    ? [
        [{ id: 'serviceCategories.bulkRemove.modal.body.someCategoryWithService' }],
        [{ id: 'serviceCategories.bulkRemove.modal.body.removeService' }],
      ]
    : [
        {
          id: 'serviceCategories.remove.modal.body',
          values: { name: <span className="font-600">{name}</span> },
        },
      ]

  const handleSubmit = () => {
    if (withServicesPresent) {
      setSelectedServiceCategories([id])
      filterServicesByCategories()
    } else {
      removeServiceCategories([id])
      setSelectedServiceCategories([])
    }
  }

  return (
    <ConfirmModal
      title={{ id: 'serviceCategories.remove.modal.title' }}
      bodyText={bodyText}
      {...submitKind}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServiceCategoryRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    servicesCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default ServiceCategoryRemoveModal
