import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_FORM } from 'state/concepts/form/types'
import { setFormId } from 'state/concepts/form/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchFormEndpoint } from 'state/concepts/form/endpoints'

const fetchFormOperation = createLogic({
  type: FETCH_FORM,
  latest: true,

  async process({ action: { id, options }, httpClient }, dispatch, done) {
    const { url, endpoint } = fetchFormEndpoint(id)
    const params = {
      include: ['form_questions.form_question_options'],
      ...options,
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setFormId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchFormOperation
