import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const QuestionO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.233-512-512 0-282.77 229.23-512 512-512 282.767 0 512 229.23 512 512 0 282.767-229.233 512-512 512zM460.8 665.6v102.4h102.4v-102.4h-102.4zM563.2 581.381c74.020-22.031 128-90.604 128-171.781 0-98.97-80.23-179.2-179.2-179.2-86.938 0-159.418 61.911-175.754 144.049l100.428 20.086c7.004-35.202 38.067-61.735 75.325-61.735 42.414 0 76.8 34.384 76.8 76.8 0 42.414-34.386 76.8-76.8 76.8-28.278 0-51.2 22.922-51.2 51.2v76.8h102.4v-33.019z" />
  </svg>
)

QuestionO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

QuestionO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default QuestionO
