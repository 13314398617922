import { Select } from 'antd'
import { useSelector } from 'react-redux'

import { cardReadersLoadingSelector, cardReadersSelector } from 'state/concepts/cardReaders/selectors'
import SelectField from 'views/shared/SelectField'

const CardReadersSelect = props => {
  const cardReadersList = useSelector(cardReadersSelector)
  const isLoading = useSelector(cardReadersLoadingSelector)

  return (
    <SelectField
      isLoading={isLoading}
      label={{
        id: 'payment.chargeWithCardReader.terminalSelect.label',
      }}
      optionLabelProp="label"
      placeholder={{ id: 'payment.chargeWithCardReader.terminalSelect.placeholder' }}
      {...props}
    >
      {cardReadersList.map(({ id, label, location }) => (
        <Select.Option label={label} key={id} value={id}>
          <div className="d-flex flex-column">
            <p className="text-body mb-0">{label}</p>
            <span className="in-blue-gray-300 text-caption">{location.displayName}</span>
          </div>
        </Select.Option>
      ))}
    </SelectField>
  )
}

export default CardReadersSelect
