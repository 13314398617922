import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

import redirect from 'utils/redirect'
import { companyBookingsRoute, loginRoute } from 'lib/routes'
import ConfirmModal from 'views/shared/ConfirmModal'
import { currentWorkspaceCodeSelector, isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'

class AccessForbiddenModal extends React.Component {
  static propTypes = {
    workspace: PropTypes.string.isRequired,
    isEmployeeLoggedIn: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    const { workspace, isEmployeeLoggedIn, onClose } = this.props

    if (isEmployeeLoggedIn) {
      redirect({ href: companyBookingsRoute, workspace })
    } else {
      redirect({ href: loginRoute })
    }
    onClose()
  }

  render() {
    return (
      <ConfirmModal
        {...this.props}
        title={{ id: 'videoConference.accessForbiddenModal.title' }}
        bodyText={[{ id: 'videoConference.accessForbiddenModal.body' }]}
        isCloseable={false}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = state => ({
  workspace: currentWorkspaceCodeSelector(state),
  isEmployeeLoggedIn: isEmployeeLoggedInSelector(state),
})

export { AccessForbiddenModal as AccessForbiddenModalContainer }
export default connect(mapStateToProps)(AccessForbiddenModal)
