const getHorizontalCrop = (image, offset) => {
  const cropHeight = image.height - offset * 2
  const cropX = (image.width - cropHeight) / 2

  return {
    width: cropHeight,
    height: cropHeight,
    x: cropX,
    y: offset,
    unit: 'px',
  }
}

const getVerticalCrop = (image, offset) => {
  const cropWidth = image.width - offset * 2
  const cropY = (image.height - cropWidth) / 2

  return {
    width: cropWidth,
    height: cropWidth,
    x: offset,
    y: cropY,
    unit: 'px',
  }
}

const getImageCrop = (image, offset = 10) =>
  image.width > image.height ? getHorizontalCrop(image, offset) : getVerticalCrop(image, offset)
export default getImageCrop
