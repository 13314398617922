import { createLogic } from 'redux-logic'
import snakeCase from 'lodash/snakeCase'

import { buildCustomBaseUrl } from 'lib/httpClient'
import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { currentOnboardingContentSelector } from 'state/concepts/session/selectors'
import { UPDATE_CLIENT_ONBOARDING_CONTENT } from 'state/concepts/tutorial/types'
import { updateClientOnboardingContentEndpoint } from 'state/concepts/tutorial/endpoints'

const updateClientOnboardingContentOperation = createLogic({
  type: UPDATE_CLIENT_ONBOARDING_CONTENT,
  latest: true,

  async process({ action: { page }, getState, httpClient }, dispatch, done) {
    const { endpoint, url } = updateClientOnboardingContentEndpoint

    try {
      const state = getState()
      const config = buildCustomBaseUrl(['workspaces'])

      await httpClient.put(url, { page: snakeCase(page) }, config)

      const response = updateDataHelper(
        state.data,
        'clientOnboardingContent',
        currentOnboardingContentSelector(state).id,
        { attributes: { [page]: true } },
      )

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateClientOnboardingContentOperation
