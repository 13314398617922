import { createLogic } from 'redux-logic'

import { SEND_MESSAGE } from 'state/concepts/chat/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { clientSelector } from 'state/concepts/client/selectors'
import { createDirectChat } from 'state/concepts/chat/actions'
import redirect from 'utils/redirect'
import { companyChatRoute } from 'lib/routes'

const sendMessageOperation = createLogic({
  type: SEND_MESSAGE,
  latest: true,

  process({ action: { clientId }, getState }, dispatch, done) {
    const state = getState()

    const currentWorkspaceCode = currentWorkspaceCodeSelector(state)
    const { chatUserId, chatId } = clientSelector(state, clientId)

    if (chatId) {
      redirect({
        href: companyChatRoute(chatId),
        workspace: currentWorkspaceCode,
      })
    } else {
      dispatch(createDirectChat(chatUserId))
    }

    done()
  },
})

export default sendMessageOperation
