import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Image from 'next/legacy/image'

import isPresent from 'utils/isPresent'
import profileLocationWithZipCode from 'utils/profileLocationWithZipCode'

const CompanySectionComponent = ({
  receiptTemplate: {
    taxId,
    workspace: { logoUrl, businessName, address, country, city, zipCode, email, phone },
  },
  children,
}) => (
  <div className="main-card__container">
    <div className="preview-invoice__top">
      {isPresent(logoUrl) && (
        <div className="preview-invoice__image">
          <Image src={logoUrl} alt="Company logo" width={84} height={84} />
        </div>
      )}
      <div>{children}</div>
    </div>
    <p className="preview-invoice__subtitle" data-cy="business-name">
      {businessName}
    </p>
    <div className="in-blue-gray-300">
      {address && (
        <p className="mb-0" data-cy="address">
          {address}
        </p>
      )}
      {(city || country || zipCode) && (
        <p className="mb-0" data-cy="city-country-postcode">
          {profileLocationWithZipCode({ city, country, zipCode })}
        </p>
      )}
      {phone && (
        <p className="mb-0" data-cy="phone">
          {phone}
        </p>
      )}
      {email && (
        <p className="mb-0" data-cy="email">
          {email}
        </p>
      )}
      {taxId && (
        <p className="mb-0" data-cy="tax-id">
          <FormattedMessage id="payments.review.taxId" values={{ taxId }} />
        </p>
      )}
    </div>
  </div>
)

CompanySectionComponent.propTypes = {
  receiptTemplate: PropTypes.shape({
    workspace: PropTypes.shape({
      businessName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      address: PropTypes.string,
      zipCode: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
    taxId: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default CompanySectionComponent
