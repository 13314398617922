import { combineReducers } from '@reduxjs/toolkit'

import { SORT_TYPES } from 'lib/constants'
import { TABLE_ENTITIES } from 'lib/constants/tableData'

import { SET_TABLE_PAGINATION, SET_TABLE_ORDER_DIRECTIONS } from './types'

const pagination = (state = {}, action) => {
  switch (action.type) {
    case SET_TABLE_PAGINATION:
      return {
        ...state,
        [action.entity]: action.number,
      }
    default:
      return state
  }
}

export const orderDirectionsInitialState = {
  [TABLE_ENTITIES.location]: {
    created_at: SORT_TYPES.descend,
  },
}

const orderDirections = (state = orderDirectionsInitialState, action) => {
  switch (action.type) {
    case SET_TABLE_ORDER_DIRECTIONS:
      return {
        ...state,
        [action.entity]: action.orderDirections,
      }
    default:
      return state
  }
}

export default combineReducers({
  pagination,
  orderDirections,
})
