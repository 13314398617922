import classNames from 'clsx'
import { getIn } from 'formik'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { equals, hasIn, identity, isEmpty } from 'ramda'
import React from 'react'
import { injectIntl } from 'react-intl'
import { RemoveScroll } from 'react-remove-scroll'

import { SELECT_MODES } from 'lib/constants/shared'
import { handleInputEvent, isSelectMultiple } from 'utils/inputHelpers'
import isPresent from 'utils/isPresent'
import Checked from 'views/shared/icons/Checked'
import Loader from '../Loader'
import SelectFieldComponent from './component'

class SelectField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    mountToElement: PropTypes.bool,
    mode: PropTypes.string,
    form: PropTypes.shape({
      setFieldTouched: PropTypes.func.isRequired,
      touched: PropTypes.shape().isRequired,
      errors: PropTypes.shape().isRequired,
      status: PropTypes.shape(),
    }).isRequired,
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    showSearchIcon: PropTypes.bool,
    onChange: PropTypes.func,
    dropdownRender: PropTypes.func,
    loaderProps: PropTypes.shape(),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isTouchedOnOpen: PropTypes.bool,
    getPopupContainer: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    mountToElement: false,
    mode: SELECT_MODES.default,
    options: undefined,
    open: undefined,
    isTouchedOnOpen: undefined,
    disabled: false,
    showSearchIcon: false,
    onChange: noop,
    onBlur: undefined,
    dropdownRender: identity,
    loaderProps: {},
    onFocus: noop,
    getPopupContainer: undefined,
  }

  state = {
    isOpen: false,
    isFocused: false,
  }

  selectRef = React.createRef()

  get options() {
    const { options, loaderProps } = this.props

    if (!isPresent(options)) return undefined

    const newOptions = [...options]

    if (isPresent(loaderProps) && (isPresent(options) || loaderProps.isLoading)) {
      newOptions.push({
        key: 'loader',
        value: 'loader',
        content: <Loader {...loaderProps} />,
        className: classNames({ 'visibility-hidden min-h-a p-0': loaderProps.isLoading === false }),
      })
    }

    return newOptions
  }

  get value() {
    const {
      options,
      mode,
      field: { value },
    } = this.props
    return !isEmpty(options) || isSelectMultiple(mode) ? value : undefined
  }

  get removeScrollEnabled() {
    const { open, mountToElement } = this.props
    const { isOpen: isLocalOpenState } = this.state
    // when opened state controlled by parent
    const isOpened = hasIn('onDropdownVisibleChange', this.props) ? open : isLocalOpenState

    return !mountToElement && isOpened
  }

  get wrapperClassNames() {
    const {
      disabled,
      className,
      field,
      form: { touched, errors, status },
      showSearchIcon,
    } = this.props
    const fieldTouched = getIn(touched, field.name)
    const fieldErrors = getIn(errors, field.name)
    const fieldStatus = getIn(status, field.name)

    const wrapperClassNames = classNames('main-input', className, {
      'main-input--disabled': disabled,
      'main-input--has-message': fieldTouched && fieldErrors,
      'main-input--has-message-error': fieldTouched && fieldErrors,
      'main-input--has-message-alert': fieldStatus,
      'main-input--has-search-icon': showSearchIcon,
    })

    return wrapperClassNames
  }

  getPopupContainer = node => (this.props.mountToElement ? node : document.body)

  onDropdownVisibleChange = isOpen => {
    const {
      field,
      form: { setFieldTouched },
      isTouchedOnOpen,
    } = this.props

    if (isTouchedOnOpen && isOpen) setFieldTouched(field.name)
    this.setState({ isOpen })
  }

  handleChange = value => {
    const { field, onChange } = this.props

    this.handleBlur()
    onChange(value, field.value)
    handleInputEvent(field, field.onChange)(value)
  }

  handleSelect = selectedValue => {
    const {
      field: { value },
    } = this.props

    if (value === selectedValue) {
      this.handleBlur()
    }
  }

  handleBlur = () => {
    const {
      mode,
      field: { name },
      form: { setFieldTouched },
      onBlur,
    } = this.props

    this.setState({
      isFocused: false,
    })
    setFieldTouched(name, true, false)
    onBlur?.()

    if (equals(mode, SELECT_MODES.default)) {
      this.selectRef.current.blur()
    }
  }

  handleFocus = () => {
    const { mode, onFocus } = this.props

    this.setState({
      isFocused: true,
    })
    onFocus()

    if (equals(mode, SELECT_MODES.default)) {
      this.selectRef.current.focus()
    }
  }

  render() {
    const { isFocused } = this.state
    return (
      <RemoveScroll enabled={this.removeScrollEnabled} forwardProps>
        <div className={this.wrapperClassNames}>
          <SelectFieldComponent
            onDropdownVisibleChange={this.onDropdownVisibleChange}
            {...this.props}
            isFocused={isFocused}
            getPopupContainer={this.getPopupContainer}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            value={this.value}
            options={this.options}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={this.selectRef}
            menuItemSelectedIcon={<Checked />}
          />
        </div>
      </RemoveScroll>
    )
  }
}

export { SelectField as SelectFieldContainer }
export default injectIntl(SelectField)
