import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import { CHECK_TIME_CONFLICTS } from 'state/concepts/bookings/types'
import { checkTimeConflictsEndpoint } from 'state/concepts/bookings/endpoints'
import { bookingByIdSelector } from 'state/concepts/bookings/selectors'
import recurringBookingFormValuesToParams from 'utils/bookings/recurringBookingFormValuesToParams'
import bookingRecurrenceToParams from 'utils/bookings/bookingRecurrenceToParams'

const checkTimeConflictsOperation = createLogic({
  type: CHECK_TIME_CONFLICTS,
  latest: true,

  async process(
    {
      httpClient,
      getState,
      action: {
        filters: { startTime, duration, bookingId, userProfileId, locationId },
        filters,
        setCheckStatus,
        isRepeat,
      },
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = checkTimeConflictsEndpoint
    const booking = bookingByIdSelector(getState(), bookingId)

    const params = {
      filter: {
        start_time: startTime.toISO(),
        duration,
        exclude_bookings: [bookingId],
        user_profile_id: userProfileId,
        location_id: locationId === ONLINE_LOCATION_OPTION.value ? '' : locationId,
        ...recurringBookingFormValuesToParams(filters),
        ...bookingRecurrenceToParams(booking, isRepeat),
      },
    }

    try {
      const { data } = await httpClient.get(url, { params })

      const calendarEvents = build(normalize(data), 'calendarEvent') || []

      setCheckStatus({
        conflictDates: pluck('startTime', calendarEvents),
        isAvailable: data.meta.has_available,
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default checkTimeConflictsOperation
