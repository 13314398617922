import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_OUTLOOK_CALENDAR_CONNECT_LINK } from 'state/concepts/calendarIntegrations/types'
import { setOutlookCalendarConnectLink } from 'state/concepts/calendarIntegrations/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { getOutlookCalendarConnectLinkEndpoint } from 'state/concepts/calendarIntegrations/endpoints'

const getOutlookCalendarConnectLinkOperation = createLogic({
  type: GET_OUTLOOK_CALENDAR_CONNECT_LINK,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = getOutlookCalendarConnectLinkEndpoint(id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setOutlookCalendarConnectLink(id, data.meta.url))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getOutlookCalendarConnectLinkOperation
