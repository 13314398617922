import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import Close from 'views/shared/icons/Close'
import Input from 'react-input-autosize'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import InputMessage from 'views/shared/InputMessage'

const EmailsInputFieldComponent = forwardRef(
  (
    {
      onClose,
      id,
      label,
      maxItems,
      invitedMembersCount,
      inputValue,
      onInputChange,
      onInputConfirm,
      field: { value },
      fieldErrors,
      onClick,
      onKeyDown,
      mainInputClassName,
    },
    ref,
  ) => (
    <div className="main-input--input-top-count">
      <div
        className={classNames('main-input main-input--tag-list relative', mainInputClassName, {
          'main-input--has-message main-input--has-message-error': fieldErrors,
        })}
        onClick={onClick}
        role="button"
      >
        <label htmlFor={id} className="main-input__label">
          <FormattedMessage {...label} />
        </label>
        <div className="ant-select ant-select-multiple ant-select-allow-clear ant-select-show-search">
          <div className="ant-select-selector pl-8">
            <div className="ant-select-selection-overflow">
              {value.map(tag => (
                <Tag closable onClose={onClose(tag)} key={tag} closeIcon={<Close size={12} color="#FFFFFF" />}>
                  {tag}
                </Tag>
              ))}
              <div className="ant-select-selection-overflow-item ant-select-selection-overflow-item-suffix">
                <div className="ant-select-selection-search">
                  <Input
                    className="ant-select-selection-search-input"
                    value={inputValue}
                    onChange={onInputChange}
                    onBlur={onInputConfirm}
                    onKeyDown={onKeyDown}
                    ref={ref}
                    disabled={invitedMembersCount + value.length === maxItems}
                    placeholder="email@example.com"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {fieldErrors && <InputMessage message={fieldErrors} icon="alert" />}
        {maxItems && (
          <p className="in-blue-gray-300 font-12 text-right input-counter">
            {value ? invitedMembersCount + value.length : invitedMembersCount}
            <span> / </span>
            <span className="in-gray-700">
              <FormattedMessage id="inviteMembers.emailsLimitCounter" values={{ maxItems }} />
            </span>
          </p>
        )}
      </div>
    </div>
  ),
)

EmailsInputFieldComponent.displayName = 'EmailsInputFieldComponent'

EmailsInputFieldComponent.defaultProps = {
  fieldErrors: null,
  maxItems: null,
  invitedMembersCount: 0,
  mainInputClassName: null,
}

EmailsInputFieldComponent.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  maxItems: PropTypes.number,
  invitedMembersCount: PropTypes.number,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputConfirm: PropTypes.func.isRequired,
  fieldErrors: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  mainInputClassName: PropTypes.string,
}

export default EmailsInputFieldComponent
