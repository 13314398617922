import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { currentClientIdSelector } from 'state/concepts/session/selectors'
import { removeMyFileId, setMyFilesBlankState } from 'state/concepts/clientAccountDocuments/actions'
import { deleteClientAttachmentEndpoint } from 'state/concepts/companyClientAttachments/endpoints'
import { DELETE_MY_FILE } from 'state/concepts/clientAccountDocuments/types'
import isBlankState from 'utils/isBlankState'
import { clientMyFilesFiltersSelector, myFilesIdsSelector } from 'state/concepts/clientAccountDocuments/selectors'

const deleteMyFileOperation = createLogic({
  type: DELETE_MY_FILE,
  latest: true,

  async process({ action: { id, onSuccess }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const clientId = currentClientIdSelector(state)
    const ids = myFilesIdsSelector(state)
    const filterParams = clientMyFilesFiltersSelector(state)

    const { url } = deleteClientAttachmentEndpoint(clientId, id)

    try {
      await httpClient.delete(url)

      dispatch(hideModal())
      if (onSuccess) {
        onSuccess()
      } else {
        if (ids.length === 1) dispatch(setMyFilesBlankState(isBlankState({ params: filterParams, data: [] })))
        dispatch(removeMyFileId(id))
      }
      dispatch(showNotification({ messageObject: { id: `notifications.myFileWasDeleted` } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteMyFileOperation
