import PropTypes from 'prop-types'
import classNames from 'clsx'
import { is } from 'ramda'
import { getIn } from 'formik'
import { FormattedMessage } from 'react-intl'

import Plus from 'views/shared/icons/Plus'
import Minus from 'views/shared/icons/Minus'
import InputMessage from '../InputMessage'

const DurationField = ({
  id,
  disabled,
  label,
  icon,
  small,
  className,
  placeholder,
  autoComplete,
  intl,
  onIncrement,
  onDecrement,
  onKeyDown,
  field,
  form: { touched, errors, status },
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)

  const wrapperClassNames = classNames(className, 'main-input input-numeric', {
    'main-input--disabled': disabled,
    'main-input--has-message': fieldTouched && fieldErrors,
    'main-input--has-message-error': fieldTouched && fieldErrors,
    'main-input--has-message-alert': fieldStatus,
    'main-input--sm': small,
  })

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <input
          className="main-input__field"
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          id={id}
          disabled={disabled}
          onKeyDown={onKeyDown}
          {...field}
          {...props}
          autoComplete={autoComplete}
        />
        <button
          onClick={onIncrement}
          type="button"
          className="input-numeric__button input-numeric__button--increase"
          disabled={disabled}
        >
          <Plus className="input-numeric__button-icon" size={12} />
        </button>
        <button
          onClick={onDecrement}
          type="button"
          className="input-numeric__button input-numeric__button--decrease"
          disabled={disabled}
        >
          <Minus className="input-numeric__button-icon" size={12} />
        </button>
      </div>
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
    </div>
  )
}

DurationField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  small: false,
  placeholder: null,
  className: null,
  autoComplete: 'off',
}

DurationField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  icon: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  autoComplete: PropTypes.string,
}

export default DurationField
