import endpoint from 'utils/endpoint'
import { usersAccountNotificationSettingsRoute } from 'lib/apiRoutes'
import * as types from './types'

export const fetchEmployeeAccountNotificationSettingsEndpoint = endpoint(
  types.FETCH_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
  'GET',
  usersAccountNotificationSettingsRoute,
)

export const updateEmployeeAccountNotificationSettingsEndpoint = endpoint(
  types.UPDATE_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
  'PUT',
  usersAccountNotificationSettingsRoute,
)
