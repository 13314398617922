import * as types from './types'

export const fetchWidgets = () => ({
  type: types.FETCH_WIDGETS,
})

export const addWidgets = widgetIds => ({
  type: types.ADD_WIDGETS,
  widgetIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_WIDGETS_PAGE,
  pageNumber,
})

export const resetWidgets = () => ({
  type: types.RESET_WIDGETS,
})

export const setIsBlankState = isBlankState => ({
  type: types.SET_IS_BLANK_STATE,
  isBlankState,
})
