import PropTypes from 'prop-types'
import React from 'react'

import { injectIntl } from 'react-intl'
import TooltipContentComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class CancellationReason extends React.Component {
  static propTypes = {
    intl: PropTypes.shape().isRequired,
  }

  render() {
    const { intl } = this.props

    return <TooltipContentComponent {...this.props} formatMessage={intl.formatMessage} />
  }
}

export { CancellationReason as CancellationReasonContainer }
export default injectIntl(CancellationReason)
