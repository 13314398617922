import normalize from 'json-api-normalizer'
import { path } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import isOnboardingCompleted from 'utils/employees/isOnboardingCompleted'
import isPresent from 'utils/isPresent'
import { workspacesHttpClient } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { addRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { EMPLOYEE_LOGIN } from 'state/concepts/session/types'
import { dataApiSuccess } from 'state/data/actions'
import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus'
import handleLoginRedirect from 'utils/session/handleLoginRedirect'
import setEmployeeCookies from 'utils/setEmployeeCookies'
import { setPassword } from 'state/concepts/login/actions'
import { employeeLoginEndpoint } from '../endpoints'

const employeeLoginOperation = createLogic({
  type: EMPLOYEE_LOGIN,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    try {
      const { url, endpoint } = employeeLoginEndpoint

      const params = {
        email: values.email,
        password: values.password,
        remember_me: values.rememberMe,
        workspace_id: values.workspaceId,
        captcha: values.captcha,
        include: [
          'workspace',
          'user-tutorial.user-onboarding-content',
          'user-profile-two-factor-setting',
          'workspace.workspace-two-factor-setting',
        ],
      }

      const {
        data,
        data: { meta },
      } = await workspacesHttpClient.post(url, params)
      const response = normalize(data, { endpoint })
      const currentEmployee = build(response, 'userProfile')[0]
      const tokens = meta.jwt
      const uniqCode = path(['workspace', 'uniqCode'], currentEmployee)
      const onboardingCompleted = isOnboardingCompleted(data)

      dispatch(employeeLoginSuccess(currentEmployee.id, !currentEmployee?.confirmedAt))
      dispatch(addRecentWorkspace(currentEmployee, tokens))
      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(setPassword(values.password))

      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers.Authorization = tokens.access
      setEmployeeCookies(currentEmployee, tokens, { path: `/${uniqCode}` })

      if (!meta.need_two_factor && isPresent(currentEmployee.userProfileTwoFactorSetting)) {
        handleLoginRedirect(onboardingCompleted, uniqCode)
      }
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }

      form.setSubmitting(false)
    }
    done()
  },
})

export default employeeLoginOperation
