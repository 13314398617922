import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import emptyAttachments from 'assets/images/bookings/no_bookings.svg'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { ATTACHMENT_TYPES } from 'lib/constants/chat'
import { fetchAttachments, resetAttachments } from 'state/concepts/chat/actions'
import { attachmentsLoadingSelector, attachmentsSelector } from 'state/concepts/chat/selectors'
import isPresent from 'utils/isPresent'
import Loader from 'views/Chats/ChatInfoModal/AttachmentsList/Loader'
import Spinner from 'views/shared/icons/Spinner'
import VoiceMessage from './VoiceMessage'

const VoiceMessagesList = ({ chat, chat: { id: chatId } }) => {
  const attachmentsFilter = { attachment_type: ATTACHMENT_TYPES.voiceMessage }
  const fetchVoiceMessages = useDispatchAction(fetchAttachments, chatId, {}, attachmentsFilter)
  const resetVoiceMessages = useDispatchAction(resetAttachments)
  const voiceMessages = useSelector(attachmentsSelector)
  const isLoading = useSelector(state => attachmentsLoadingSelector(state, chatId))
  const isInitialLoading = isLoading !== false && !isPresent(voiceMessages)

  useMount(fetchVoiceMessages)
  useUnmount(resetVoiceMessages)

  if (isInitialLoading !== false) return <Spinner />

  return isPresent(voiceMessages) ? (
    <>
      {voiceMessages.map(voiceMessage => (
        <VoiceMessage key={voiceMessage.id} {...voiceMessage} />
      ))}
      <Loader chat={chat} filter={attachmentsFilter} />
    </>
  ) : (
    <div className="d-flex justify-content-center align-items-center full-height">
      <div className="text-center">
        <div className="chat-msgs__chat-img">
          <Image src={emptyAttachments} alt="No results" data-cy="main-page-empty-image" height={136} width={136} />
        </div>
        <p className="chat-msgs__chat-empty-txt mb-0" data-cy="main-page-empty-text">
          <FormattedMessage id="chat.detailsModal.voiceMessages.empty" />
        </p>
      </div>
    </div>
  )
}

VoiceMessagesList.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default VoiceMessagesList
