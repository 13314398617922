const namespace = 'webSockets'

export const OPEN_CONNECTION = `${namespace}/OPEN_CONNECTION`
export const CLOSE_CONNECTION = `${namespace}/CLOSE_CONNECTION`
export const SET_CONNECTION_STATUS = `${namespace}/SET_CONNECTION_STATUS`
export const ADD_SUBSCRIPTION_CHANNELS = `${namespace}/ADD_SUBSCRIPTION_CHANNELS`
export const REMOVE_SUBSCRIPTION_CHANNELS = `${namespace}/REMOVE_SUBSCRIPTION_CHANNELS`
export const RESET_SUBSCRIPTION_CHANNELS = `${namespace}/RESET_SUBSCRIPTION_CHANNELS`
export const SEND_COMMAND = `${namespace}/SEND_COMMAND`
export const RECEIVE_DATA = `${namespace}/RECEIVE_DATA`
