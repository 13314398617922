import React from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import yup from 'lib/yupLocalised'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { applyPromocode } from 'state/concepts/promocodes/actions'
import PromocodeModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class PromocodeModal extends React.Component {
  render = () => <PromocodeModalComponent {...this.props} isSubmitDisabled={isSubmitDisabled(this.props)} />
}

const mapDispatchToProps = {
  onSubmit: applyPromocode,
}

export { PromocodeModal as PromocodeModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ promotionType }) => ({
      promotion_type: promotionType,
      code: '',
    }),
    validationSchema: yup.object().shape({
      code: yup.string().required(),
    }),
    handleSubmit: handleSubmitWithProps(),
  }),
)(PromocodeModal)
