const clientReview = {
  'clientReview.form.title': 'Please rate your experience',
  'clientReview.form.reviewInfo':
    'You’ve visited <b>{service}</b> with <b>{expert}</b>. Tell us about your experience!',
  'clientReview.form.descriptionLabel': 'Write your review (optional)',
  'clientReview.form.notes': 'Your review could be visible to other users.',
  'clientReview.form.successNotification': 'Your review was submitted.',
  'clientReview.success.title': 'Thanks for your review!',
  'clientReview.success.description': 'We really appreciate it.',
  'clientReview.reviewed.title': 'You’ve already reviewed this _(appointment)',
  'clientReview.reviewed.description': 'Thanks for your review',
  'clientReview.linkExpired.title': 'The link has expired',
  'clientReview.linkExpired.description': 'You can’t review this _(appointment) anymore.',
  'clientReview.form.submitBtn': 'Submit review',
  'clientReview.notAbleToSubmit.title': 'Access forbidden',
  'clientReview.notAbleToSubmit.description': 'You are not able to submit your reviews anymore.',
}

export default clientReview
