import React from 'react'
import PropTypes from 'prop-types'

import OptionContentComponent from './component'

class OptionContent extends React.Component {
  static propTypes = {
    chatUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    chatUser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      memberType: PropTypes.string.isRequired,
    }).isRequired,
  }

  get isAlreadyInChat() {
    const {
      chatUserIds,
      chatUser: { id },
    } = this.props

    return chatUserIds.includes(Number(id))
  }

  render = () => <OptionContentComponent isAlreadyInChat={this.isAlreadyInChat} {...this.props} />
}

export default OptionContent
