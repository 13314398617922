import cookies from 'component-cookie'
import { DateTime } from 'luxon'

import isPresent from 'utils/isPresent'
import { SESSION_EXPIRING_MODAL_INTERVAL, MODAL_START_TIME_COOKIE_KEY } from 'lib/constants/automaticLogout'
import { MILLISECONDS_PER_SECOND } from 'lib/constants/timeUnits'

export const setStartTime = (startTime, cookiesPath) => {
  cookies(MODAL_START_TIME_COOKIE_KEY, JSON.stringify(startTime), {
    path: cookiesPath,
    maxage: SESSION_EXPIRING_MODAL_INTERVAL * MILLISECONDS_PER_SECOND,
  })
}

export const removeStartTime = cookiesPath => {
  cookies(MODAL_START_TIME_COOKIE_KEY, null, { path: cookiesPath })
}

export const getStartTime = () => {
  const startTime = cookies(MODAL_START_TIME_COOKIE_KEY)

  return isPresent(startTime) ? DateTime.fromISO(JSON.parse(startTime)) : null
}
