import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

import requestErrorHandler from 'lib/requestErrorHandler'
import { touchDocumentTemplateEndpoint } from '../endpoints'
import { TOUCH_DOCUMENT_TEMPLATE } from '../types'

const touchDocumentTemplateOperation = createLogic({
  type: TOUCH_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = touchDocumentTemplateEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url)

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403, 404] })
    }
    done()
  },
})

export default touchDocumentTemplateOperation
