import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CircleO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path
      strokeWidth="4"
      d="M256 455.112c109.966 0 199.112-89.146 199.112-199.112S365.967 56.888 256 56.888C146.034 56.888 56.888 146.033 56.888 256S146.033 455.112 256 455.112zM256 512C114.615 512 0 397.385 0 256S114.615 0 256 0s256 114.615 256 256-114.615 256-256 256z"
    />
  </svg>
)

CircleO.defaultProps = {
  size: 16,
  color: '#C8CED8',
  className: '',
  dataCy: undefined,
}

CircleO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CircleO
