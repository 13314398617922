import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBusinessFilesShareRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { fetchCurrentFolderEndpoint } from '../endpoints'
import { FETCH_CURRENT_FOLDER } from '../types'

const fetchCurrentFolderOperation = createLogic({
  type: FETCH_CURRENT_FOLDER,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const { folderId } = action
    const { url, endpoint } = fetchCurrentFolderEndpoint(folderId)
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const params = {
      include: [
        'user-profile',
        'parent.user-profile',
        'business-storage-permissions.user-profile',
        'parent.business-storage-permissions.user-profile',
        'ancestors-permissions.user-profile',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        redirect({
          href: companyBusinessFilesShareRoute,
          workspace,
        })
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchCurrentFolderOperation
