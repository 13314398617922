import { FormattedMessage } from 'react-intl'

export const BADGE_CLASS_BY_STATUS = {
  active: 'pending',
  cancelled: 'cancelled',
  draft: 'draft',
  paid: 'success',
}

export const RECURRING_PAYMENT_STATUSES = {
  active: 'active',
  cancelled: 'cancelled',
  draft: 'draft',
  paid: 'paid',
}

export const RECURRING_PAYMENT_BILLING_CYCLES = {
  weekly: 'Weekly',
  biweekly: 'Bi-weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
}

export const RECURRING_PAYMENT_BILLING_CYCLE_OPTIONS = [
  {
    key: RECURRING_PAYMENT_BILLING_CYCLES.weekly,
    value: RECURRING_PAYMENT_BILLING_CYCLES.weekly,
    label: { id: 'recurringPayments.requestPayment.billingCycle.weekly' },
  },
  {
    key: RECURRING_PAYMENT_BILLING_CYCLES.biweekly,
    value: RECURRING_PAYMENT_BILLING_CYCLES.biweekly,
    label: { id: 'recurringPayments.requestPayment.billingCycle.biweekly' },
  },
  {
    key: RECURRING_PAYMENT_BILLING_CYCLES.monthly,
    value: RECURRING_PAYMENT_BILLING_CYCLES.monthly,
    label: { id: 'recurringPayments.requestPayment.billingCycle.monthly' },
  },
  {
    key: RECURRING_PAYMENT_BILLING_CYCLES.quarterly,
    value: RECURRING_PAYMENT_BILLING_CYCLES.quarterly,
    label: { id: 'recurringPayments.requestPayment.billingCycle.quarterly' },
  },
  {
    key: RECURRING_PAYMENT_BILLING_CYCLES.yearly,
    value: RECURRING_PAYMENT_BILLING_CYCLES.yearly,
    label: { id: 'recurringPayments.requestPayment.billingCycle.yearly' },
  },
]

export const RECURRING_LIMIT_BY_FREQUENCY_VALIDATION = {
  [RECURRING_PAYMENT_BILLING_CYCLES.weekly]: 104,
  [RECURRING_PAYMENT_BILLING_CYCLES.biweekly]: 52,
  [RECURRING_PAYMENT_BILLING_CYCLES.monthly]: 24,
  [RECURRING_PAYMENT_BILLING_CYCLES.quarterly]: 12,
  [RECURRING_PAYMENT_BILLING_CYCLES.yearly]: 4,
}

export const RECURRING_PAYMENT_FILTERS = [
  {
    value: RECURRING_PAYMENT_STATUSES.draft,
    label: (
      <div className="main-badge main-badge--draft">
        <FormattedMessage id="recurringPayment.status.draft" />
      </div>
    ),
  },
  {
    value: RECURRING_PAYMENT_STATUSES.cancelled,
    label: (
      <div className="main-badge main-badge--cancelled">
        <FormattedMessage id="recurringPayment.status.cancelled" />
      </div>
    ),
  },
  {
    value: RECURRING_PAYMENT_STATUSES.active,
    label: (
      <div className="main-badge main-badge--pending">
        <FormattedMessage id="recurringPayment.status.active" />
      </div>
    ),
  },
  {
    value: RECURRING_PAYMENT_STATUSES.paid,
    label: (
      <div className="main-badge main-badge--success">
        <FormattedMessage id="recurringPayment.status.paid" />
      </div>
    ),
  },
]
