const financialDocumentsFeature = {
  'financialDocumentsFeature.alert.text': 'To enable financial documents you have to enable accepting payments first.',
  'financialDocumentsFeature.alert.action': 'Accept payments',
  'financialDocumentsFeature.subtitle': '<span>in</span> Payments',
  'financialDocumentsFeature.description':
    'Automate invoice and receipt generation, then download invoices and receipts as needed.',
  'financialDocumentsFeature.overview': 'Financial documents overview',
  'financialDocumentsFeature.simplifyPayment':
    'Simplify your payment management and focus on providing your _(services). Add your business details once and let ExpertBox handle all your payment documentation while you focus on your work.',
  'financialDocumentsFeature.automated': 'Automated invoicing and receipts:',
  'financialDocumentsFeature.automated.stepOne':
    'Let ExpertBox automatically generate an invoice and attach it to each payment request.',
  'financialDocumentsFeature.automated.stepTwo':
    'After the payment is completed, you and your _(client) will receive a downloadable receipt.',
  'financialDocumentsFeature.reporting': 'Financial reporting and accounting made easy:',
  'financialDocumentsFeature.reporting.stepOne':
    'Keep all financial documents organized and easy to access in your payment history.',
  'financialDocumentsFeature.reporting.stepTwo':
    'Print or bulk-download invoices over specific periods in CSV or PDF format.',
  'financialDocumentsFeature.helpCenter':
    'To learn more, read our help center article on <link>issuing invoices and receipts</link>.',
  'financialDocumentsFeature.manageSettings': 'Set up invoices & receipts',
  'financialDocumentsFeature.upgradePlan': 'Upgrade plan',
  'financialDocumentsFeature.availableOnlyForAdvanced': 'Available only for advanced plans',
  'financialDocumentsFeature.disableFinancialDocuments': 'Disable financial documents feature?',
  'financialDocumentsFeature.disableFinancialDocuments.bodyText':
    'You won’t be able to issue invoices, receipts, or credit notes anymore.',
}

export default financialDocumentsFeature
