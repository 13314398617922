import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingPaymentsRoute } from 'lib/routes'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { requestPaymentValuesToParams } from 'utils/payments'
import { TRANSACTION_STATUSES } from 'lib/constants/payments'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { REQUEST_PAYMENT } from '../types'
import { requestTransactionEndpoint, saveAsDraftTransactionEndpoint } from '../endpoints'

const requestPaymentOperation = createLogic({
  type: REQUEST_PAYMENT,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const isRequest = values.status === TRANSACTION_STATUSES.pending
    const apiEndpoint = isRequest ? requestTransactionEndpoint : saveAsDraftTransactionEndpoint
    const { url, endpoint } = apiEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.post(url, requestPaymentValuesToParams(values))

      dispatch(dataApiSuccess({ endpoint }))
      redirect({
        href: companyBillingPaymentsRoute,
        workspace: currentWorkspaceCodeSelector(getState()),
        skipDiscard: true,
      })
      dispatch(
        showNotification({
          messageObject: {
            id: isRequest ? 'notifications.requestPayment' : 'notifications.saveAsDraft',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default requestPaymentOperation
