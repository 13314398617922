import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import isPresent from 'utils/isPresent'
import requestErrorHandler from 'lib/requestErrorHandler'
import { MARK_MESSAGES_AS_READ } from 'state/concepts/chat/types'
import { markChatMessagesAsReadEndpoint } from 'state/concepts/chat/endpoints'
import { dataApiSuccess } from 'state/data/actions'
import { readCursorSelector } from 'state/concepts/chat/selectors'
import updateDataHelper from 'utils/updateDataHelper'

const markMessagesAsReadOperation = createLogic({
  type: MARK_MESSAGES_AS_READ,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { chatId, readCursor } = action
    const state = getState()
    const { endpoint, url } = markChatMessagesAsReadEndpoint(chatId)
    const currentReadCursor = readCursorSelector(state, chatId)
    const isCursorUpdated = DateTime.fromISO(readCursor) > DateTime.fromISO(currentReadCursor)

    try {
      if (isPresent(readCursor) && isCursorUpdated) {
        const response = updateDataHelper(state.data, 'chat', chatId, {
          attributes: { readCursor },
        })

        dispatch(dataApiSuccess({ response, endpoint }))

        const params = { read_cursor: readCursor }

        await httpClient.put(url, params)
      } else {
        dispatch(dataApiSuccess({ endpoint }))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403] })
    }

    done()
  },
})

export default markMessagesAsReadOperation
