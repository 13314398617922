import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsDocumentTemplateRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'
import { createDocumentTemplateEndpoint } from '../endpoints'
import { CREATE_DOCUMENT_TEMPLATE } from '../types'

const createDocumentTemplateOperation = createLogic({
  type: CREATE_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = createDocumentTemplateEndpoint
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url)
      const documentTemplateId = data.data.id

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))

      redirect({ href: companySettingsDocumentTemplateRoute(documentTemplateId), workspace: workspaceCode })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default createDocumentTemplateOperation
