import { createLogic } from 'redux-logic'

import { API_HOST } from 'lib/constants'
import { KEEP_ALIVE } from 'state/concepts/session/types'
import { keepAliveRoute } from 'lib/apiRoutes'

const keepAliveOperation = createLogic({
  type: KEEP_ALIVE,
  latest: true,

  async process({ httpClient }, _, done) {
    try {
      await httpClient.get(keepAliveRoute, { customBaseURL: `${API_HOST}/api/v1` })
      // eslint-disable-next-line no-empty
    } catch (_e) {}
    done()
  },
})

export default keepAliveOperation
