import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { disconnectOutlookCalendar as disconnectOutlookCalendarAction } from 'state/concepts/calendarIntegrations/actions'
import { loadingSelector } from 'state/data/selectors'
import { disconnectOutlookCalendarEndpoint } from 'state/concepts/calendarIntegrations/endpoints'
import DisconnectModal from '../DisconnectModal'

const OutlookDisconnectModal = ({ connection: { id }, onClose }) => {
  const disconnectOutlookCalendar = useDispatchAction(disconnectOutlookCalendarAction)

  const isLoading = useSelector(state =>
    loadingSelector(state, disconnectOutlookCalendarEndpoint(path(['connection', 'id'], state)).endpoint),
  )

  const handleSubmit = () => {
    disconnectOutlookCalendar(id)
  }

  return <DisconnectModal onClose={onClose} handleSubmit={handleSubmit} isLoading={isLoading} />
}

OutlookDisconnectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default OutlookDisconnectModal
