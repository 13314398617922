import { createLogic } from 'redux-logic'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { onUpdate } from 'utils/attributesForSortable'
import { UPDATE_TASK_PRIORITY } from 'state/concepts/tasks/types'
import { updateTaskPriorityEndpoint } from 'state/concepts/tasks/endpoints'
import { tasksOrderedSelector } from '../selectors'

const updateTaskPriorityOperation = createLogic({
  type: UPDATE_TASK_PRIORITY,
  latest: true,

  async process({ action: { id, priority, orderedFields }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateTaskPriorityEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.put(url, { priority })

      const state = getState()
      const tasks = tasksOrderedSelector(state)
      const taskIds = pluck('id')(orderedFields)
      const response = updateDataHelper(state.data, 'task', taskIds, onUpdate(orderedFields, tasks))

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default updateTaskPriorityOperation
