import { path, any, pathEq } from 'ramda'

const isPageOverflow = error => {
  const responseErrors = path(['response', 'data', 'errors'], error)
  if (!responseErrors) {
    return false
  }

  return any(pathEq('overflow', ['meta', 'type']), responseErrors)
}

export default isPageOverflow
