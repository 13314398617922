import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import AddPermissions from './AddPermissions'
import DiscardChanges from './DiscardChanges'
import Permissions from './Permissions'
import UpdateAccess from './UpdateAccess'

const steps = () => ({
  [SHARE_FILE_STEPS.sharedWith]: Permissions,
  [SHARE_FILE_STEPS.addPermissions]: AddPermissions,
  [SHARE_FILE_STEPS.discard]: DiscardChanges,
  [SHARE_FILE_STEPS.updateAccess]: UpdateAccess,
})

export default steps
