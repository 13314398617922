import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Invite = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M231.619 0c-53.565 0-97.524 43.959-97.524 97.524s43.959 97.524 97.524 97.524 97.524-43.959 97.524-97.524S285.184 0 231.619 0zm0 48.762c27.209 0 48.761 21.552 48.761 48.761s-21.553 48.761-48.762 48.761-48.762-21.552-48.762-48.761 21.553-48.761 48.762-48.761zm170.667 268.19c13.465 0 24.381 10.916 24.381 24.381v48.762h48.762c13.465 0 24.381 10.916 24.381 24.381s-10.916 24.381-24.381 24.381h-48.762v48.762c0 13.465-10.916 24.381-24.381 24.381s-24.381-10.916-24.381-24.381v-48.762h-48.762c-13.465 0-24.381-10.916-24.381-24.381s10.916-24.381 24.381-24.381h48.762v-48.762c0-13.465 10.916-24.381 24.381-24.381zm-170.667-48.761c18.846 0 50.713 5.072 95.525 15.238 9.288 2.16 16.101 10.365 16.101 20.163a20.61 20.61 0 0 1-1.671 8.156l.051-.135-1.389 3.34c-3.793 8.839-12.421 14.919-22.469 14.919-1.993 0-3.93-.239-5.784-.69l.167.034c-34.45-8.168-61.269-12.264-80.53-12.264-80.262 0-170.667 39.765-170.667 60.952v7.315a4.89 4.89 0 0 0 4.876 4.876H219.44a24.286 24.286 0 0 1 15.988 5.974l-.03-.025 19.846 17.14 19.748 17.115a4.876 4.876 0 0 1-3.168 8.558H36.57c-13.465 0-24.381-10.916-24.381-24.381v-36.572c0-72.046 142.848-109.714 219.429-109.714z" />
  </svg>
)

Invite.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Invite.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Invite
