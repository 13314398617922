import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const isRecordingInProgress = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_RECORDING_IN_PROGRESS:
      return action.isRecordingInProgress
    default:
      return state
  }
}

const currentPlaySource = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_PLAY_SOURCE:
      return action.src
    default:
      return state
  }
}

const isMicrophoneError = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_MICROPHONE_ERROR:
      return action.isMicrophoneError
    default:
      return state
  }
}

const audioReducer = combineReducers({
  isRecordingInProgress,
  currentPlaySource,
  isMicrophoneError,
})

export default audioReducer
