import { createLogic } from 'redux-logic'

import { setIsEmailAlertShown } from 'state/concepts/alert/actions'
import { EMPLOYEE_LOGIN_SUCCESS } from 'state/concepts/session/types'

const employeeLoginSuccessOperation = createLogic({
  type: EMPLOYEE_LOGIN_SUCCESS,
  latest: true,

  async process({ action: { isEmailAlertShown } }, dispatch, done) {
    dispatch(setIsEmailAlertShown(isEmailAlertShown))
    done()
  },
})

export default employeeLoginSuccessOperation
