import yup from 'lib/yupLocalised'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants'

export default yup.object().shape({
  invitations: yup.array().of(
    yup.object().shape({
      email: yup.string().email().max(MAX_INPUT_CONTENT_LENGTH).required(),
      role: yup.string().required(),
    }),
  ),
})
