import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  activateServices as activateServicesAction,
  setSelectedServices as setSelectedServicesAction,
} from 'state/concepts/services/actions'
import { activateServicesLoadingSelector } from 'state/concepts/services/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServicesBulkActivateModal = ({ serviceIds, onClose }) => {
  const isLoading = useSelector(activateServicesLoadingSelector)
  const activateServices = useDispatchAction(activateServicesAction, serviceIds)
  const setSelectedServices = useDispatchAction(setSelectedServicesAction)

  const handleSubmit = () => {
    activateServices()
    setSelectedServices([])
  }

  return (
    <ConfirmModal
      title={{ id: 'servicesBulkActivateModal.title' }}
      bodyText={[
        {
          id: 'servicesBulkActivateModal.body',
          values: { count: serviceIds.length },
        },
      ]}
      submitText={{ id: 'servicesActions.activate' }}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServicesBulkActivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ServicesBulkActivateModal
