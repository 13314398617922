import classNames from 'clsx'
import PropTypes from 'prop-types'

import FileIcon from 'views/shared/FileIcon'
import Actions from './Actions'
import File from './File'

const AttachedFileComponent = ({
  id,
  url,
  size,
  createdAt,
  filename,
  mimeType,
  onClick,
  attachmentType,
  isAttachmentsList,
  canRemove,
  chatUser,
  inlineActions,
}) => (
  <div
    data-cy="chat-attachment-item"
    className={classNames('chat-msg-file', {
      'chat-sidebar__attachments-item': isAttachmentsList,
    })}
  >
    <div className="chat-msg-file__icon-container">
      <span className="chat-msg-file__icon">
        <FileIcon mimeType={mimeType} size={24} />
      </span>
    </div>

    <div role="button" onClick={onClick} className={classNames('chat-msg-file__info c-pointer')}>
      <File
        size={size}
        createdAt={createdAt}
        filename={filename}
        chatUser={chatUser}
        isAttachmentsList={isAttachmentsList}
      />
    </div>

    <Actions
      fileId={id}
      url={url}
      attachmentType={attachmentType}
      inlineActions={inlineActions}
      canRemove={canRemove}
    />
  </div>
)

AttachedFileComponent.defaultProps = {
  chatUser: null,
  isAttachmentsList: false,
  inlineActions: false,
}

AttachedFileComponent.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  attachmentType: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  chatUser: PropTypes.shape(),
  isAttachmentsList: PropTypes.bool,
  inlineActions: PropTypes.bool,
}

export default AttachedFileComponent
