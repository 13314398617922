import { createSelector } from '@reduxjs/toolkit'
import { all, ascend, compose, not, omit, path, pathOr, pipe, prop, sort, values } from 'ramda'
import build from 'redux-object'

import { currentClientSelector } from 'state/concepts/session/selectors'
import { workspaceFeaturesSelector } from 'state/concepts/workspaceFeatures/selectors'
import { dataSelector, endpointMetaSelector } from 'state/data/selectors'
import { makeSettingChecked } from 'utils/accountSettings'
import clientPermissions from 'utils/client/permissions'
import { fetchClientEndpoint } from './endpoints'

export const clientIdSelector = path(['client', 'clientId'])

export const isClientDeletedSelector = path(['client', 'isClientDeleted'])

export const clientSelector = createSelector([(_, clientId) => clientId, dataSelector], (clientId, data) =>
  clientId ? build(data, 'clientProfile', clientId) : null,
)

export const clientCustomFieldsSelector = createSelector(
  clientSelector,
  compose(sort(ascend(prop('priority'))), pathOr([], ['customFields'])),
)

export const clientAvatarOriginalSelector = pipe(currentClientSelector, pathOr(null, ['avatarUrls', 'original']))

export const notificationSettingIdSelector = path(['client', 'notificationSettingId'])

export const notificationSettingsSelector = createSelector(
  [dataSelector, notificationSettingIdSelector],
  (data, notificationSettingId) =>
    notificationSettingId ? build(data, 'clientNotificationSetting', notificationSettingId) : null,
)

export const isSmsNotificationsAvailableSelector = createSelector(
  notificationSettingsSelector,
  compose(not, all(makeSettingChecked(false, ['client'])), values, omit(['id']), prop('smsNotificationSetting')),
)

export const currentClientPermissionsSelector = createSelector(
  [currentClientSelector, workspaceFeaturesSelector],
  (client, workspaceFeatures) => clientPermissions({ client: client ?? {}, workspaceFeatures }),
)

export const fetchClientMetaSelector = (state, clienId) =>
  endpointMetaSelector(state, fetchClientEndpoint(clienId).endpoint)
