import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { storageSize } from 'utils/billing'
import { cancelDowngradeStorageEndpoint } from '../endpoints'
import { storagePlanSelector } from '../selectors'
import { CANCEL_DOWNGRADE_STORAGE } from '../types'

const cancelDowngradeStorage = createLogic({
  type: CANCEL_DOWNGRADE_STORAGE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = cancelDowngradeStorageEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url)
      const response = normalize(data)
      const { totalStorage } = storagePlanSelector(getState())

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.billing.cancelDowngradeStorage.success',
            values: {
              size: storageSize(totalStorage),
            },
          },
        }),
      )
      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default cancelDowngradeStorage
