const namespace = 'companyClientAttachments'

export const FETCH_CLIENT_ATTACHMENTS = `${namespace}/FETCH_CLIENT_ATTACHMENTS`
export const DELETE_CLIENT_ATTACHMENT = `${namespace}/DELETE_CLIENT_ATTACHMENT`
export const ADD_CLIENT_ATTACHMENTS_IDS = `${namespace}/ADD_CLIENT_ATTACHMENTS_IDS`
export const SET_CLIENT_ATTACHMENTS_IDS = `${namespace}/SET_CLIENT_ATTACHMENTS_IDS`
export const ADD_CLIENT_ATTACHMENT_ID = `${namespace}/ADD_CLIENT_ATTACHMENT_ID`
export const REMOVE_CLIENT_ATTACHMENTS_IDS = `${namespace}/REMOVE_CLIENT_ATTACHMENTS_IDS`
export const RESET_CLIENT_ATTACHMENTS = `${namespace}/RESET_CLIENT_ATTACHMENTS`
export const UPDATE_CLIENT_ATTACHMENT_COUNT = `${namespace}/UPDATE_CLIENT_ATTACHMENT_COUNT`
export const REMOVE_CLIENT_ACCESS = `${namespace}/REMOVE_CLIENT_ACCESS`
export const SET_CLIENT_ATTACHMENTS_FILTER = `${namespace}/SET_CLIENT_ATTACHMENTS_FILTER`
export const SET_CLIENT_ATTACHMENTS_FILTER_PARAMS = `${namespace}/SET_CLIENT_ATTACHMENTS_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`

export const PRESIGN_CLIENT_ATTACHMENTS = `${namespace}/PRESIGN_CLIENT_ATTACHMENTS`
export const UPLOAD_CLIENT_ATTACHMENTS = `${namespace}/UPLOAD_CLIENT_ATTACHMENTS`
export const CANCEL_FILE_UPLOAD = `${namespace}/CANCEL_FILE_UPLOAD`
export const ADD_UPLOAD_FILE = `${namespace}/ADD_UPLOAD_FILE`
export const COMPLETE_FILE_LOADING = `${namespace}/COMPLETE_FILE_LOADING`
export const FAIL_FILE_LOADING = `${namespace}/FAIL_FILE_LOADING`
export const REMOVE_UPLOADED_FILES = `${namespace}/REMOVE_UPLOADED_FILES`
export const SHARE_FILE_WITH_CLIENT = `${namespace}/SHARE_FILE_WITH_CLIENT`
export const SET_IS_UPLOADING_SECTION_OPEN = `${namespace}/SET_IS_UPLOADING_SECTION_OPEN`
