import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without, omit } from 'ramda'

import {
  SET_ONLINE,
  SET_OFFLINE,
  SET_MESSAGES,
  FLUSH_MESSAGES,
  ADD_ATTACHMENTS,
  RESET_ATTACHMENTS,
  REMOVE_ATTACHMENT_SUCCESS,
  SET_EDITING_MESSAGE,
  REMOVE_MESSAGE_SUCCESS,
  SET_UNREAD_INDICATOR_VISIBILITY,
  SET_UPLOAD_LOADING_STATUS,
  REMOVE_UPLOAD_LOADING_STATUS,
  SET_UPLOAD_PROGRESS,
  REMOVE_UPLOAD_PROGRESS,
  LINK_UPLOAD_TO_ATTACHMENT,
  UNLINK_UPLOAD_TO_ATTACHMENT,
  RESET_UPLOAD_TO_ATTACHMENT_LINKS,
  SET_CHAT,
  DRAG_FILES,
  SET_CHAT_MEMBER_IDS,
  ADD_SENDING_MESSAGE_ID,
  REMOVE_SENDING_MESSAGE_ID,
  ADD_RESENDING_MESSAGE_ID,
  REMOVE_RESENDING_MESSAGE_ID,
  ADD_UPLOAD_ERROR,
  REMOVE_UPLOAD_ERROR,
  SET_HAS_VOICE_MESSAGE,
} from './types'

const messageIds = (state = [], action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return uniq([...state, ...action.messageIds])
    case FLUSH_MESSAGES:
      return []
    case REMOVE_MESSAGE_SUCCESS:
      return without(action.messageId, state)
    default:
      return state
  }
}

const attachmentsIds = (state = [], action) => {
  switch (action.type) {
    case ADD_ATTACHMENTS:
      return uniq([...state, ...action.attachmentsIds])
    case REMOVE_ATTACHMENT_SUCCESS:
      return without(action.attachmentId, state)
    case RESET_ATTACHMENTS:
      return []
    default:
      return state
  }
}

const editingMessage = (state = null, action) => {
  switch (action.type) {
    case SET_EDITING_MESSAGE:
      return action.message
    default:
      return state
  }
}

const hasVoiceMessage = (state = false, action) => {
  switch (action.type) {
    case SET_HAS_VOICE_MESSAGE:
      return action.state
    default:
      return state
  }
}

const isOnline = (state = true, action) => {
  switch (action.type) {
    case SET_ONLINE:
      return true
    case SET_OFFLINE:
      return false
    default:
      return state
  }
}

const isUnreadIndicatorVisible = (state = true, action) => {
  switch (action.type) {
    case SET_UNREAD_INDICATOR_VISIBILITY:
      return action.isUnreadIndicatorVisible
    default:
      return state
  }
}

const uploadStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_UPLOAD_LOADING_STATUS:
      return {
        ...state,
        [action.uploadId]: action.isLoading,
      }
    case REMOVE_UPLOAD_LOADING_STATUS:
      return omit(action.uploadIds, state)
    default:
      return state
  }
}

const uploadProgress = (state = {}, action) => {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.uploadId]: action.progress,
      }
    case REMOVE_UPLOAD_PROGRESS:
      return omit(action.uploadIds, state)
    default:
      return state
  }
}

const uploadLinks = (state = {}, action) => {
  switch (action.type) {
    case LINK_UPLOAD_TO_ATTACHMENT:
      return {
        ...state,
        [action.uploadId]: action.attachmentId,
      }
    case UNLINK_UPLOAD_TO_ATTACHMENT:
      return omit(action.uploadIds, state)
    case RESET_UPLOAD_TO_ATTACHMENT_LINKS:
      return {}
    default:
      return state
  }
}

const uploadWithErrors = (state = [], action) => {
  switch (action.type) {
    case ADD_UPLOAD_ERROR:
      return uniq([...state, action.uploadId])
    case REMOVE_UPLOAD_ERROR:
      return without(action.uploadId, state)
    default:
      return state
  }
}

const chatId = (state = null, action) => {
  switch (action.type) {
    case SET_CHAT:
      return action.chatId
    default:
      return state
  }
}

const draggedFiles = (state = null, action) => {
  switch (action.type) {
    case DRAG_FILES:
      return action.files
    default:
      return state
  }
}

const chatMemberIds = (state = [], action) => {
  switch (action.type) {
    case SET_CHAT_MEMBER_IDS:
      return action.chatMemberIds
    default:
      return state
  }
}

const sendingMessagesIds = (state = [], action) => {
  switch (action.type) {
    case ADD_SENDING_MESSAGE_ID:
      return uniq([...state, action.id])
    case REMOVE_SENDING_MESSAGE_ID:
      return without([action.id], state)
    default:
      return state
  }
}

const resendingMessagesIds = (state = [], action) => {
  switch (action.type) {
    case ADD_RESENDING_MESSAGE_ID:
      return uniq([...state, action.id])
    case REMOVE_RESENDING_MESSAGE_ID:
      return without([action.id], state)
    default:
      return state
  }
}

export default combineReducers({
  messageIds,
  attachmentsIds,
  editingMessage,
  isOnline,
  isUnreadIndicatorVisible,
  uploadStatus,
  uploadProgress,
  uploadLinks,
  uploadWithErrors,
  chatId,
  draggedFiles,
  chatMemberIds,
  sendingMessagesIds,
  resendingMessagesIds,
  hasVoiceMessage,
})
