import Axios from 'axios'
import { mergeRight } from 'ramda'
import cookies from 'next-cookies'

import { API_HOST, COOKIES_DOMAIN } from 'lib/constants'
import setCookieIsomorphic from 'utils/setCookieIsomorphic'
import removeCookieIsomorphic from 'utils/removeCookieIsomorphic'
import redirect from 'utils/redirect'
import { employeeLogoutSuccess } from 'state/concepts/session/actions'
import { updateRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { usersSessionRefreshRoute, clientsSessionRefreshRoute } from 'lib/apiRoutes'
import { clientLoginRoute, guestLinkExpiredRoute, loginRoute } from 'lib/routes'

const refreshInterceptor = ctx => {
  const { dispatch } = ctx.store
  let requestsPool = []
  let isRefreshing = false
  const subscribeToRefresh = listener => requestsPool.push(listener)
  const finishRefresh = accessToken => {
    requestsPool.map(listener => listener(accessToken))
    isRefreshing = false
    requestsPool = []
  }

  return async error => {
    const retryConfig = error.response && error.response.config

    if (retryConfig && error.response.status === 401) {
      const defferedOriginalRequest = new Promise((resolve, reject) => {
        // eslint-disable-next-line consistent-return
        subscribeToRefresh(async accessToken => {
          if (!accessToken) {
            return reject(error)
          }

          retryConfig.headers.Authorization = accessToken

          try {
            const response = await Axios.request(retryConfig)
            resolve(response)
          } catch {
            await redirect({ href: loginRoute, response: ctx.res, skipDiscard: true })
            reject(error)
          }
        })
      })

      if (isRefreshing) {
        return defferedOriginalRequest
      }

      const { tokens, currentEmployeeId, currentWorkspaceId, currentWorkspaceCode, path, guestSession } = cookies(ctx)

      if (!tokens && guestSession) {
        removeCookieIsomorphic(ctx, 'guestSession', { path: '/' })
        await redirect({ href: guestLinkExpiredRoute, response: ctx.res })
        finishRefresh(null)

        return defferedOriginalRequest
      }

      const cookieOptions = { path }

      try {
        isRefreshing = true
        const refreshPath = currentEmployeeId
          ? usersSessionRefreshRoute
          : `/${currentWorkspaceCode}${clientsSessionRefreshRoute}`

        const response = await Axios.post(
          `${API_HOST}/api/v1/workspaces${refreshPath}`,
          {},
          { headers: { 'X-Refresh-Token': tokens.refresh } },
        )
        const newTokens = mergeRight(tokens, response.data.meta.jwt)

        setCookieIsomorphic(ctx, 'tokens', JSON.stringify(newTokens), cookieOptions)
        removeCookieIsomorphic(ctx, 'tokens', { ...cookieOptions, domain: COOKIES_DOMAIN })

        if (currentEmployeeId) {
          dispatch(updateRecentWorkspace({ id: currentWorkspaceId, tokens: newTokens }))
        }

        const newAuthHeader = `Bearer ${response.data.meta.jwt.access}`
        ctx.store.httpClient.defaults.headers.Authorization = newAuthHeader
        finishRefresh(newAuthHeader)
      } catch {
        ctx.store.httpClient.defaults.headers.Authorization = null
        removeCookieIsomorphic(ctx, 'currentEmployeeId', cookieOptions)
        removeCookieIsomorphic(ctx, 'currentWorkspaceId', cookieOptions)
        removeCookieIsomorphic(ctx, 'currentWorkspaceCode', cookieOptions)
        removeCookieIsomorphic(ctx, 'currentClientId', cookieOptions)
        removeCookieIsomorphic(ctx, 'tokens', cookieOptions)
        removeCookieIsomorphic(ctx, 'path', cookieOptions)

        removeCookieIsomorphic(ctx, 'currentEmployeeId', { ...cookieOptions, domain: COOKIES_DOMAIN })
        removeCookieIsomorphic(ctx, 'currentWorkspaceId', { ...cookieOptions, domain: COOKIES_DOMAIN })
        removeCookieIsomorphic(ctx, 'currentWorkspaceCode', { ...cookieOptions, domain: COOKIES_DOMAIN })
        removeCookieIsomorphic(ctx, 'currentClientId', { ...cookieOptions, domain: COOKIES_DOMAIN })
        removeCookieIsomorphic(ctx, 'tokens', { ...cookieOptions, domain: COOKIES_DOMAIN })
        removeCookieIsomorphic(ctx, 'path', { ...cookieOptions, domain: COOKIES_DOMAIN })

        if (currentEmployeeId) {
          await redirect({ href: loginRoute, response: ctx.res, skipDiscard: true })

          dispatch(employeeLogoutSuccess(currentWorkspaceId))
        } else {
          await redirect({ href: clientLoginRoute, response: ctx.res })
        }
        finishRefresh(null)
      }

      return defferedOriginalRequest
    }

    return Promise.reject(error)
  }
}

export default refreshInterceptor
