import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck, prop, isEmpty, path } from 'ramda'

import { isPending } from 'utils/clientAccountBookings'
import { BOOKINGS_TYPES } from 'lib/constants/clientAccountBookings'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import {
  setClientAccountBookingsIds,
  switchClientAccountBookings,
  setPendingBookingsCount,
} from 'state/concepts/clientAccountBookings/actions'
import { FETCH_BOOKINGS } from 'state/concepts/clientAccountBookings/types'
import { currentClientSelector } from 'state/concepts/session/selectors'
import {
  lastClientAccountBookingIdSelector,
  sortParamsSelector,
  statusesSelector,
  pastSelector,
  servicesSelector,
  employeesSelector,
  bookingsTypeSelector,
} from 'state/concepts/clientAccountBookings/selectors'
import { fetchClientAccountBookingsEndpoint } from 'state/concepts/clientAccountBookings/endpoints'

const fetchClientAccountBookingsOperation = createLogic({
  type: FETCH_BOOKINGS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const client = currentClientSelector(state)
    const bookingType = bookingsTypeSelector(state)
    const { endpoint, url } = fetchClientAccountBookingsEndpoint

    const params = {
      include: [
        'user-profile',
        'service',
        'service-category',
        'workspace',
        'reschedule',
        'review',
        'booking-recurrence',
        'location',
        'video-conference',
        'client-pending-form',
        'waitlist-request',
      ],
      page: {
        after: lastClientAccountBookingIdSelector(state),
      },
      sort: sortParamsSelector(state),
      filter: {
        past: pastSelector(state),
        timezone: prop('timezone', client),
        statuses: statusesSelector(state),
        service_ids: servicesSelector(state),
        user_profile_ids: employeesSelector(state),
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const bookingIds = pluck('id', data.data)

      if (isEmpty(bookingIds) && isPending(bookingType)) {
        dispatch(switchClientAccountBookings(BOOKINGS_TYPES.upcoming))
      } else {
        dispatch(setClientAccountBookingsIds(bookingIds))
        dispatch(setPendingBookingsCount(path(['meta', 'pending_bookings_count'], data)))
        dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchClientAccountBookingsOperation
