import PropTypes from 'prop-types'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useWindowSize } from 'react-use'
import { FormattedMessage } from 'react-intl'
import { dec, inc, equals } from 'ramda'

import Button from 'views/shared/Button'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const PreviewPdf = ({ file }) => {
  const [pageCount, setPageCount] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const { height } = useWindowSize()

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages)
  }

  const onPrevPage = () => {
    setPageNumber(dec(pageNumber))
  }

  const onNextPage = () => {
    setPageNumber(inc(pageNumber))
  }

  return (
    <>
      <div className="relative">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={null}>
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            height={height - 72}
            width={854}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderMode="canvas"
            loading={null}
          />
        </Document>
      </div>
      {!equals(pageCount, 1) && (
        <div className="react-pdf__Pagination">
          <Button
            className="wh-48-48 d-flex align-items-center justify-content-center in-white react-pdf__Pagination-button react-pdf__Pagination-button--prev"
            icon="arrow-left"
            iconSize={10}
            isIcon
            disabled={equals(pageNumber, 1)}
            iconClassName="fill-currentcolor"
            dataCy="files-preview-pdf-prev-page"
            onClick={onPrevPage}
          />
          <p className="text-subheader mb-0 in-white react-pdf__Pagination-text">
            <FormattedMessage id="filesPreview.pdf.pageCounter" values={{ pageNumber, pageCount }} />
          </p>
          <Button
            className="wh-48-48 d-flex align-items-center justify-content-center in-white react-pdf__Pagination-button react-pdf__Pagination-button--next"
            icon="arrow-right"
            iconSize={10}
            isIcon
            disabled={equals(pageNumber, pageCount)}
            iconClassName="fill-currentcolor"
            dataCy="files-preview-pdf-next-page"
            onClick={onNextPage}
          />
        </div>
      )}
    </>
  )
}

PreviewPdf.propTypes = {
  file: PropTypes.string.isRequired,
}

export default PreviewPdf
