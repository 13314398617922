import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setFilesIds, addFileIds, setFilesBlankState } from 'state/concepts/clientAccountDocuments/actions'
import { fetchFilesEndpoint } from 'state/concepts/clientAccountDocuments/endpoints'
import { clientFilesFiltersSelector, filesPaginationSelector } from 'state/concepts/clientAccountDocuments/selectors'
import { FETCH_FILES } from 'state/concepts/clientAccountDocuments/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { buildCustomBaseUrl } from 'lib/httpClient'
import isBlankState from 'utils/isBlankState'
import { PAGINATION_ITEMS_PER_PAGE } from 'lib/constants/tableData'
import isPresent from 'utils/isPresent'

const fetchFilesOperation = createLogic({
  type: FETCH_FILES,
  latest: true,

  async process({ httpClient, getState, action: { options = {}, workspaceCode } }, dispatch, done) {
    const { endpoint, url } = fetchFilesEndpoint
    const state = getState()
    const filter = clientFilesFiltersSelector(state)
    const customBaseUrl = workspaceCode ? buildCustomBaseUrl(['workspaces', workspaceCode]) : {}
    const page = isPresent(options.page)
      ? { number: options.page, size: options.size || PAGINATION_ITEMS_PER_PAGE }
      : filesPaginationSelector(state)

    const config = {
      ...customBaseUrl,
      params: {
        include: ['client-attachment', 'user-profile', 'workspace', 'client-attachment.business-storage'],
        page,
        filter: { ...filter },
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, config)
      const response = normalize(data, { endpoint })

      dispatch(setFilesBlankState(isBlankState({ params: config.params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      const fileIds = pluck('id', data.data)
      dispatch(options?.append ? addFileIds(fileIds) : setFilesIds(fileIds))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchFilesOperation
