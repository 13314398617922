import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const DeleteO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M256 102.4v-102.4h512v102.4h256v102.4h-102.4v768c0 28.278-22.922 51.2-51.2 51.2h-716.8c-28.277 0-51.2-22.922-51.2-51.2v-768h-102.4v-102.4h256zM204.8 204.8v716.8h614.4v-716.8h-614.4zM358.4 358.4h102.4v409.6h-102.4v-409.6zM563.2 358.4h102.4v409.6h-102.4v-409.6z" />
  </svg>
)

DeleteO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

DeleteO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DeleteO
