import { createLogic } from 'redux-logic'
import { path } from 'ramda'

import { RESPONDENT_TYPES } from 'lib/constants/forms'
import { DOCUMENT_TABS_BY_FORM_TYPE } from 'lib/constants/clientProfile'
import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { SEND_DOCUMENTS } from 'state/concepts/client/types'
import { sendDocumentsEndpoint } from 'state/concepts/client/endpoints'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { formsUuidsByIdsSelector } from 'state/concepts/services/selectors'
import { companyClientRoute, companyClientsSubmitFormRoute } from 'lib/routes'

const sendDocumentsOperation = createLogic({
  type: SEND_DOCUMENTS,
  latest: true,

  async process(
    {
      action: {
        values: { selectedIntakeFormIds = [], selectedAgreementIds = [], formType, respondent },
        form,
        clientId,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const { url } = sendDocumentsEndpoint(clientId)
    const workspace = currentWorkspaceCodeSelector(getState())
    const selectedFormIds = [...selectedIntakeFormIds, ...selectedAgreementIds]
    const isFormForExpert = respondent === RESPONDENT_TYPES.expert

    try {
      const { data } = await httpClient.post(url, {
        form_uuids: formsUuidsByIdsSelector(getState(), selectedFormIds),
        respondent,
      })

      const nextFormId = path(['meta', 'next_form_id'], data)

      await redirect({
        href: companyClientRoute(clientId),
        workspace,
        query: { activeTab: DOCUMENT_TABS_BY_FORM_TYPE[formType] },
      })

      if (isFormForExpert && nextFormId) {
        window.open(`/${workspace}${companyClientsSubmitFormRoute(nextFormId)}`, '_blank')
      }

      dispatch(
        showNotification({
          messageObject: {
            id: isFormForExpert ? 'notifications.formAddedToClient' : `notifications.${formType}SentToClient`,
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default sendDocumentsOperation
