import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Import = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M232.727 0c-51.433 0-93.091 41.658-93.091 93.091v232.727h-46.545c-51.433 0-93.091 41.658-93.091 93.091v232.727c0 51.433 41.658 93.091 93.091 93.091h46.545v93.091c0 51.433 41.658 93.091 93.091 93.091h380.365l-93.091-93.091h-287.274v-93.091h465.455v-93.091c0-51.433 41.658-93.091 93.091-93.091h93.091v-325.818l-232.727-232.727h-418.909zM232.727 93.091h372.364v186.182h186.182v46.545h-558.544v-232.727zM186.182 418.909c51.433 0 93.091 41.658 93.091 93.091h-46.545c0-25.647-20.899-46.545-46.545-46.545s-46.545 20.899-46.545 46.545v46.545c0 25.647 20.899 46.545 46.545 46.545s46.545-20.899 46.545-46.545h46.545c0 51.433-41.658 93.091-93.091 93.091s-93.091-41.658-93.091-93.091v-46.545c0-51.433 41.658-93.091 93.091-93.091zM402.364 418.909c66.233 1.908 70.273 59.713 70.273 70h-45c0-4.794 0.81-32.454-26-32.454-8.145 0-26.488 3.61-25.091 26.092 1.35 20.62 28.565 30.357 33.546 32.545 10.426 3.817 61.945 26.81 62.364 73.728 0.093 9.961-2.481 62.12-69.182 62.818-72.564 0.791-77.454-61.654-77.454-74.547h45.364c0 6.842 0.579 39.927 32.091 37.367 18.944-1.583 23.076-15.216 23.727-25.367 1.071-17.078-15.233-27.35-32.455-35.635-24.204-11.636-62.484-25.935-63.182-71.27-0.605-40.821 29.388-64.438 71-63.274zM512 418.909h48.998l43.729 169.728 43.999-169.728h49.455l-62.999 232.727h-60.546l-62.636-232.727zM791.273 651.636v186.182h-139.636l186.182 186.182 186.182-186.182h-139.636v-186.182h-93.091z" />
  </svg>
)

Import.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Import.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Import
