import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import profileName from 'utils/profileName'
import Avatar from 'views/shared/Avatar'
import isPresent from 'utils/isPresent'

const OptionContent = ({ chatUser, isAlreadyInChat }) => (
  <div
    className={classNames('main-dropdown__user', { 'c-not-allowed': chatUser.deactivated || isAlreadyInChat })}
    data-cy="member-modal"
  >
    <Avatar
      sizeClassName={classNames('main-userpic--sm', { 'op-03': chatUser.deactivated })}
      imageSize="small"
      profile={chatUser}
      data-cy="member-modal-avatar"
    />
    <div className="members-name__cut-wrap">
      <div className="d-flex align-items-center mb-0">
        <div className="text-ellipsis" data-cy="member-modal-username">
          {profileName(chatUser)}
        </div>
        {isPresent(chatUser.memberRole) && (
          <span className="main-badge main-badge--expert-label ml-12" data-cy="member-modal-role">
            <FormattedMessage id={`chat.members.${chatUser.memberRole}`} />
          </span>
        )}
      </div>
      {chatUser.deactivated && (
        <p className="in-gray-500 mb-0">
          <FormattedMessage id="chat.members.deactivated" />
        </p>
      )}
    </div>
    {isAlreadyInChat && (
      <p className="text-caption in-gray-700 mb-0 ml-auto" data-cy="member-modal-message">
        <FormattedMessage id="chat.members.alreadyInChat" />
      </p>
    )}
  </div>
)

OptionContent.propTypes = {
  chatUser: PropTypes.shape().isRequired,
  isAlreadyInChat: PropTypes.bool.isRequired,
}

export default OptionContent
