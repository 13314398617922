import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import { pathOr } from 'ramda'

import redirect from 'utils/redirect'
import { companySettingsSubscriptionRoute } from 'lib/routes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { setChangeSubscriptionDetailsId } from 'state/concepts/subscriptionPlans/actions'
import { fetchChangeSubscriptionDetailsEndpoint } from 'state/concepts/subscriptionPlans/endpoints'
import { FETCH_CHANGE_SUBSCRIPTION_DETAILS } from 'state/concepts/subscriptionPlans/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setStripePublishableApiKey } from 'state/concepts/billing/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const fetchChangeSubscriptionDetailsOperation = createLogic({
  type: FETCH_CHANGE_SUBSCRIPTION_DETAILS,
  latest: true,

  async process({ httpClient, getState, action: { planId } }, dispatch, done) {
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    const { endpoint, url } = fetchChangeSubscriptionDetailsEndpoint
    const params = {
      subscription_plan_id: planId,
      include: [
        'planned-storage-subscription-plan',
        'storage-plans',
        'current-subscription-plan',
        'current-storage-plan',
        'payment-method',
        'planned-subscription-plan',
        'discount-promocode',
        'current-storage-subscription',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setChangeSubscriptionDetailsId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(setStripePublishableApiKey(pathOr(null, ['meta', 'publishable_api_key'], data)))
    } catch (error) {
      redirect({ href: companySettingsSubscriptionRoute, workspace: workspaceCode })
      requestErrorHandler({ error, dispatch, endpoint, skipHandlers: true })
    }

    done()
  },
})

export default fetchChangeSubscriptionDetailsOperation
