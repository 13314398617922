const documentsFeature = {
  'documentsFeature.title': 'Documents',
  'documentsFeature.subtitle': '<span>in</span> Forms & documents',
  'documentsFeature.description':
    'Create and collaborate on online documents with team members and _(clients) in real-time.',
  'documentsFeature.premiumFeature': 'Documents is a Premium feature.',
  'documentsFeature.toLearnMores':
    'To learn more, read our help center articles on <creatingDocumentsLink>creating</creatingDocumentsLink> and <managingDocumentsLink>managing documents</managingDocumentsLink>.',
  'documentsFeature.overview': 'Documents overview',
  // eslint-disable-next-line expbox/custom-name-validation
  'documentsFeature.overview.text1': 'Save time and provide better services with ExpertBox Documents.',
  'documentsFeature.overview.text2': 'Create and manage documents in your workspace',
  'documentsFeature.overview.text3': 'Create, edit, and manage documents seamlessly within your workspace.',
  'documentsFeature.overview.text4':
    'Link documents to _(client) profiles, providing quick access to essential information.',
  'documentsFeature.overview.text5': 'Enhance collaboration',
  'documentsFeature.overview.text6':
    'Easily share and edit documents with _(clients) in real-time with our collaboration feature.',
  'documentsFeature.overview.text7':
    'Decide who can view, comment, or edit documents, ensuring data security and privacy.',
  'documentsFeature.overview.text8':
    'Add comments directly within documents to provide context, ask questions, and facilitate clear communication.',
  'documentsFeature.overview.text9': 'Seamlessly integrate E-signature',
  'documentsFeature.overview.text10': '{link} for easy document signing by people within or outside your organization.',
  'documentsFeature.overview.text11':
    'Decide the signing order when sending a document to multiple recipients. Keep track of when they receive and sign your documents.',
  'documentsFeature.overview.text12': 'These documents are secure and comply with all HIPAA and GDPR requirements.',
  'documentsFeature.overview.enableESignature': 'Enable e-signature',
  'documentsFeature.disable.title': 'Disable documents feature?',
  'documentsFeature.disable.description': 'You won’t be able to create documents and share them with _(clients).',
  'documentsFeature.disable.alert': 'This won’t affect any of the documents created or shared previously.',
  'documentsFeature.enableCrmWarning': 'To enable documents, you need to start using CRM on ExpertBox.',
  'documentsFeature.enableCrm': 'Enable CRM',
}

export default documentsFeature
