import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CloudArrow = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512.001 128c-99.584 0-183.466 53.333-237.654 128.597C123.094 258.304 0 381.738 0 533.333c0 152.661 124.672 277.334 277.334 277.334H384v-85.333H277.334l-.684.002c-105.661 0-191.317-85.656-191.317-191.317l.002-.721v.037c0-106.581 85.418-192 192-192 1.493 0 4.95.299 10.667.598l26.069 1.28 13.013-22.613c37.347-64.539 106.059-107.264 184.749-107.264h.177-.01.255c105.878 0 193.691 77.348 210.015 178.621l.162 1.22 6.485 40.96 41.173-5.291c9.515-1.28 15.616-2.176 19.243-2.176 82.944 0 149.334 66.389 149.334 149.334s-66.389 149.334-149.334 149.334H639.999v85.333h149.334c129.109 0 234.667-105.558 234.667-234.667 0-127.573-103.381-231.424-230.4-233.813C756.48 219.396 646.485 128.004 512 128.004zm0 256L341.334 554.667h128v341.334h85.333V554.667h128L512 384z" />
  </svg>
)

CloudArrow.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CloudArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CloudArrow
