import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyClientDocumentRoute, companyClientDocumentTemplatesRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { createEtherpadDocumentEndpoint } from '../endpoints'
import { CREATE_ETHERPAD_DOCUMENT } from '../types'

const createEtherpadDocumentOperation = createLogic({
  type: CREATE_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { clientId, documentTemplateId }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = createEtherpadDocumentEndpoint(clientId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    const params = {
      include: ['user-profile', 'client-profile'],
      document_template_id: documentTemplateId,
    }

    try {
      const { data } = await httpClient.post(url, params)
      const documentId = data.data.id

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))

      redirect({ href: companyClientDocumentRoute(clientId, documentId), workspace: workspaceCode })
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error) && documentTemplateId) {
        redirect({ href: companyClientDocumentTemplatesRoute(clientId), workspace: workspaceCode })
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.documentTemplate.noLongerAvailable' },
            kind: 'error',
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default createEtherpadDocumentOperation
