/* eslint-disable @next/next/no-img-element */
import React from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import { FormattedMessage } from 'react-intl'
import Modal from 'views/shared/Modal'

import { IMAGE_SQUARE_ASPECT_RATIO } from 'lib/constants/file'
import Button from 'views/shared/Button'
import UploadInput from 'views/shared/UploadInput'
import Cross from 'views/shared/icons/Cross'

const LogoModal = ({
  src,
  crop,
  onClose,
  onImageSave,
  onCropChange,
  onSelectImage,
  onImageLoaded,
  onCropComplete,
  isCircularCrop,
  submitText,
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="uploadLogo.editImage" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <div className="main-img-crop-field">
          {src && (
            <ReactCrop
              aspect={IMAGE_SQUARE_ASPECT_RATIO}
              crop={crop}
              onComplete={onCropComplete}
              onChange={onCropChange}
              circularCrop={isCircularCrop}
            >
              <img src={src} alt="Crop" onLoad={onImageLoaded} />
            </ReactCrop>
          )}
        </div>
      </div>
      <div className="main-modal__footer main-modal__footer--has-extra-action">
        <div className="main-modal__footer-extra-action">
          <UploadInput
            id="avatar"
            className="d-none"
            name="avatar"
            accept="image/jpeg,image/png"
            onChange={onSelectImage}
          />
          <Button htmlFor="avatar" text={{ id: 'uploadLogo.changeImage' }} isLabel />
        </div>
        <div className="d-flex d-sm-block">
          <Button
            className="main-modal__footer-action flex-grow-1"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onClose}
          />
          <Button
            className="main-modal__footer-action flex-grow-1"
            type="submit"
            text={submitText}
            onClick={onImageSave}
          />
        </div>
      </div>
    </div>
  </Modal>
)

LogoModal.defaultProps = {
  src: null,
  isCircularCrop: false,
  submitText: { id: 'shared.save' },
}

LogoModal.propTypes = {
  src: PropTypes.string,
  isCircularCrop: PropTypes.bool,
  crop: PropTypes.shape().isRequired,
  onImageSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  submitText: PropTypes.shape(),
}

export default LogoModal
