import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import selectedProviders from 'utils/services/selectedProviders'
import isAdminOrOwner from 'utils/employeePermissions/isAdminOrOwner'

import Tooltip from 'views/shared/Tooltip'
import DropdownUserField from 'views/shared/DropdownUserField'
import ProvisionPrice from 'views/shared/ProvisionPrice'
import DeleteO from 'views/shared/icons/DeleteO'
import Plus from 'views/shared/icons/Plus'

const ProviderFields = ({
  addRow,
  removeRow,
  form: {
    values,
    values: { provision },
    setFieldValue,
    setFieldTouched,
  },
  currentEmployee,
  isOneEmployee,
}) => (
  <>
    {provision.map(({ key }, index) => (
      <div className="service-employee" key={key} data-cy="service-employee-section">
        <div className="service-employee__row">
          <div className="service-employee__col flex-grow-1">
            <Field
              className="dropdown-multiselect"
              selectClassName="main-input__field-tags dropdown-tags--blue"
              name={`provision.${index}.employees`}
              component={DropdownUserField}
              removeIconSize={12}
              statuses={['active']}
              id={`provision.${index}.employees`}
              label={{ id: 'createService.experts' }}
              icon="alert"
              placeholder={{ id: 'createService.addEmployees' }}
              mode="multiple"
              excludeEmployees={selectedProviders(values, index)}
              disabled={isOneEmployee}
            />
          </div>
          <div className="service-employee__col service-employee__col--price">
            <ProvisionPrice index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
          </div>
          <div className="service-employee__col">
            {provision.length > 1 && (
              <Tooltip
                overlayClassName="main-tooltip"
                placement="top"
                icon={
                  <button
                    onClick={removeRow(index)}
                    type="button"
                    className="service-employee__delete-btn"
                    data-cy="service-employee-delete-button"
                  >
                    <DeleteO dataCy="icon-delete-o" className="hover-blue-600" size={20} />
                  </button>
                }
              >
                <FormattedMessage id="shared.delete" />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    ))}
    {!isOneEmployee && isAdminOrOwner(currentEmployee) && (
      <button className="main-link font-600" onClick={addRow} type="button">
        <Plus className="fill-currentcolor mr-8" size={14} />
        <span>
          <FormattedMessage id="createService.addExpertOtherPrice" />
        </span>
      </button>
    )}
  </>
)

ProviderFields.propTypes = {
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      provision: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
  currentEmployee: PropTypes.shape().isRequired,
  isOneEmployee: PropTypes.bool.isRequired,
}

export default ProviderFields
