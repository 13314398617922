import { createSelector } from '@reduxjs/toolkit'
import { path, prop } from 'ramda'
import build from 'redux-object'

import {
  processClientImportEndpoint,
  uploadClientImportEndpoint,
  validateClientImportEndpoint,
} from 'state/concepts/companyClientImport/endpoints'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'

export const clientImportIdSelector = path(['companyClientImport', 'clientImportId'])
export const fileUploadProgressSelector = path(['companyClientImport', 'uploadProgress'])
export const errorsFileUrlSelector = path(['companyClientImport', 'errorsFileUrl'])
export const matchFormStateSelector = path(['companyClientImport', 'matchFormState'])
export const isProcessingSelector = path(['companyClientImport', 'isProcessing'])

export const clientImportSelector = createSelector([clientImportIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'clientImport', id) : null,
)

export const clientImportFileColumnsSelector = createSelector(clientImportSelector, prop('mappedFields'))

export const fileUploadLoadingSelector = state => loadingSelector(state, uploadClientImportEndpoint.endpoint)
export const validateClientImportLoadingSelector = (state, id) =>
  loadingSelector(state, validateClientImportEndpoint(id).endpoint)
export const validateClientImportMetaSelector = (state, id) =>
  endpointMetaSelector(state, validateClientImportEndpoint(id).endpoint)
export const processClientImportMetaSelector = (state, id) =>
  endpointMetaSelector(state, processClientImportEndpoint(id).endpoint)
