/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { compose } from 'ramda'
import { withFormik } from 'formik'
import { connect } from 'react-redux'

import yup from 'lib/yupLocalised'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { issueRefund } from 'state/concepts/payment/actions'
import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import IssueRefundModalComponent from './component'

class IssueRefundModal extends React.Component {
  render() {
    return <IssueRefundModalComponent {...this.props} />
  }
}

const mapDispatchToProps = {
  onSubmit: issueRefund,
}

export { IssueRefundModal as IssueRefundModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ transaction: { id } }) => ({ cancellationReason: '', transactionId: id }),
    handleSubmit: handleSubmitWithProps(['fromPayments']),
    validationSchema: yup.object().shape({
      cancellationReason: yup.string().max(MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH).required(),
      transactionId: yup.string().required(),
    }),
  }),
)(IssueRefundModal)
