import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Service = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M358.4 166.399H76.799c-14.139 0-25.601 11.461-25.601 25.6v230.4C51.198 436.538 62.66 448 76.799 448h358.4c14.139 0 25.601-11.461 25.601-25.601v-230.4c0-14.138-11.461-25.601-25.601-25.601h-76.8zm-204.801-51.2v-51.2c0-28.277 22.923-51.2 51.201-51.2h102.401c28.277 0 51.2 22.922 51.2 51.2v51.2h76.8c33.988 0 42.263 2.377 50.608 6.839s14.892 11.01 19.353 19.354c4.463 8.344 6.839 16.62 6.839 50.608v230.4c0 33.988-2.377 42.263-6.839 50.608s-11.01 14.892-19.353 19.353c-8.344 4.463-16.62 6.839-50.608 6.839h-358.4c-33.987 0-42.263-2.377-50.606-6.839s-14.892-11.01-19.355-19.353C2.378 464.664.001 456.388.001 422.4V192c0-33.987 2.376-42.263 6.839-50.607s11.01-14.892 19.355-19.355c8.343-4.462 16.62-6.839 50.606-6.839h76.8zm51.199 0h102.4V89.598c0-14.139-11.461-25.601-25.601-25.601h-51.2c-14.138 0-25.601 11.463-25.601 25.601v25.601zM332.799 294.4h-153.6v25.601c0 14.139-11.463 25.601-25.601 25.601s-25.601-11.461-25.601-25.601V294.4h-76.8v-51.2h409.601v51.2h-76.8v25.601c0 14.139-11.461 25.601-25.601 25.601s-25.601-11.461-25.601-25.601V294.4z" />
  </svg>
)

Service.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'service-icon',
}

Service.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Service
