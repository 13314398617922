import endpoint from 'utils/endpoint'
import {
  userAccountCalendarIntegrationsRoute,
  googleCalendarConnectionRoute,
  googleCalendarConnectionsLinkRoute,
  googleCalendarConnectionReconnectRoute,
  outlookCalendarConnectionsRoute,
  outlookCalendarConnectionsLinkRoute,
  outlookCalendarConnectionRoute,
  outlookCalendarConnectionReconnectRoute,
  googleCalendarConnectionsRoute,
  userAccountCalendarSyncRoute,
  appleCalendarConnectionsRoute,
  appleCalendarConnectionRoute,
  appleCalendarConnectionReconnectRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const connectGoogleCalendarEndpoint = endpoint(
  types.CONNECT_GOOGLE_CALENDAR,
  'POST',
  googleCalendarConnectionsRoute,
)
export const reconnectGoogleCalendarEndpoint = id =>
  endpoint(types.RECONNECT_GOOGLE_CALENDAR, 'POST', googleCalendarConnectionReconnectRoute(id))

export const fetchCalendarConnectionsEndpoint = endpoint(
  types.FETCH_CALENDAR_CONNECTIONS,
  'GET',
  userAccountCalendarIntegrationsRoute,
)
export const updateGoogleCalendarConnectionEndpoint = id =>
  endpoint(types.UPDATE_GOOGLE_CALENDAR_CONNECTION, 'PUT', googleCalendarConnectionRoute(id))

export const disconnectGoogleCalendarEndpoint = id =>
  endpoint(types.DISCONNECT_GOOGLE_CALENDAR, 'DELETE', googleCalendarConnectionRoute(id))

export const getGoogleCalendarConnectionLinkEndpoint = id =>
  endpoint(types.GET_GOOGLE_CALENDAR_CONNECT_LINK, 'POST', googleCalendarConnectionsLinkRoute, id)

export const connectOutlookCalendarEndpoint = endpoint(
  types.CONNECT_OUTLOOK_CALENDAR,
  'POST',
  outlookCalendarConnectionsRoute,
)

export const getOutlookCalendarConnectLinkEndpoint = id =>
  endpoint(types.GET_OUTLOOK_CALENDAR_CONNECT_LINK, 'POST', outlookCalendarConnectionsLinkRoute, id)

export const updateOutlookCalendarConnectionEndpoint = id =>
  endpoint(types.UPDATE_OUTLOOK_CALENDAR_CONNECTION, 'PUT', outlookCalendarConnectionRoute(id))

export const reconnectOutlookCalendarEndpoint = id =>
  endpoint(types.RECONNECT_OUTLOOK_CALENDAR, 'POST', outlookCalendarConnectionReconnectRoute(id))

export const disconnectOutlookCalendarEndpoint = id =>
  endpoint(types.DISCONNECT_OUTLOOK_CALENDAR, 'DELETE', outlookCalendarConnectionRoute(id))

export const updateSyncCalendarEndpoint = endpoint(types.UPDATE_SYNC_CALENDAR, 'POST', userAccountCalendarSyncRoute)

export const connectAppleCalendarEndpoint = endpoint(
  types.CONNECT_APPLE_CALENDAR,
  'POST',
  appleCalendarConnectionsRoute,
)

export const disconnectAppleCalendarEndpoint = id =>
  endpoint(types.DISCONNECT_APPLE_CALENDAR, 'DELETE', appleCalendarConnectionRoute(id))

export const updateAppleCalendarConnectionEndpoint = id =>
  endpoint(types.UPDATE_APPLE_CALENDAR_CONNECTION, 'PUT', appleCalendarConnectionRoute(id))

export const reconnectAppleCalendarEndpoint = id =>
  endpoint(types.RECONNECT_APPLE_CALENDAR, 'POST', appleCalendarConnectionReconnectRoute(id))
