import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const EyeO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 139.636c-372.364 0-512 372.364-512 372.364s139.636 372.364 512 372.364c372.364 0 512-372.364 512-372.364s-139.636-372.364-512-372.364zM512 232.727c245.574 0 369.785 198.614 410 279.091-40.262 79.919-165.404 279.455-410 279.455-245.574 0-369.785-198.614-410-279.091 40.308-79.919 165.403-279.455 410-279.455zM512 325.818c-102.819 0-186.182 83.363-186.182 186.182s83.363 186.182 186.182 186.182c102.819 0 186.182-83.363 186.182-186.182s-83.363-186.182-186.182-186.182zM512 418.909c51.433 0 93.091 41.658 93.091 93.091s-41.658 93.091-93.091 93.091c-51.433 0-93.091-41.658-93.091-93.091s41.658-93.091 93.091-93.091z" />
  </svg>
)

EyeO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

EyeO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default EyeO
