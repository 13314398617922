/* eslint-disable expbox/custom-name-validation */
import acceptPaymentsFeature from './acceptPaymentsFeature'
import acceptVideoCallsFeature from './acceptVideoCallsFeature'
import account from './account'
import automaticLogout from './automaticLogout'
import availability from './availability'
import availabilityFeature from './availabilityFeature'
import backupLogin from './backupLogin'
import billing from './billing'
import booking from './booking'
import bookingCheckout from './bookingCheckout'
import bookingFeature from './bookingFeature'
import bookingPage from './bookingPage'
import bookingPageFeature from './bookingPageFeature'
import bookingPageSettings from './bookingPageSettings'
import bookingPolicy from './bookingPolicy'
import bookingWidgetsFeature from './bookingWidgetsFeature'
import bookings from './bookings'
import businessFilesFeature from './businessFilesFeature'
import businessSettings from './businessSettings'
import calendar from './calendar'
import calendarEvents from './calendarEvents'
import calendarIntegrationsFeature from './calendarIntegrationsFeature'
import calendarSync from './calendarSync'
import cardReader from './cardReader'
import chat from './chat'
import chatFeature from './chatFeature'
import client from './client'
import clientAccountDocuments from './clientAccountDocuments'
import clientBusinessFiles from './clientBusinessFiles'
import clientContacts from './clientContacts'
import clientContactsFeature from './clientContactsFeature'
import clientFields from './clientFields'
import clientListFeature from './clientListFeature'
import clientPayment from './clientPayment'
import clientPayments from './clientPayments'
import clientPortal from './clientPortal'
import clientPortalFeature from './clientPortalFeature'
import clientProfile from './clientProfile'
import clientRelationshipTypes from './clientRelationshipTypes'
import clientReview from './clientReview'
import company from './company'
import companyClients from './companyClients'
import companyClientsCreate from './companyClientsCreate'
import companyClientsImport from './companyClientsImport'
import companyProfile from './companyProfile'
import companySidebar from './companySidebar'
import costManagement from './costManagement'
import createService from './createService'
import createWebsite from './createWebsite'
import crmFeature from './crmFeature'
import customNames from './customNames'
import customNamingFeature from './customNamingFeature'
import customPaymentsFeature from './customPaymentsFeature'
import documentTemplates from './documentTemplates'
import documentTemplatesFeature from './documentTemplatesFeature'
import documentsFeature from './documentsFeature'
import eSignature from './eSignature'
import editService from './editService'
import editWebsite from './editWebsite'
import emailRemindersFeature from './emailRemindersFeature'
import employeeAccount from './employeeAccount'
import employeeAccountSettings from './employeeAccountSettings'
import employeeOnboarding from './employeeOnboarding'
import employeeProfile from './employeeProfile'
import employeeSidebar from './employeeSidebar'
import employeeSignUp from './employeeSignUp'
import employeeTwoFactorSettings from './employeeTwoFactorSettings'
import employees from './employees'
import etherpadDocuments from './etherpadDocuments'
import filesPreview from './filesPreview'
import filesSharedWithMe from './filesSharedWithMe'
import waitlistRequests from './waitlistRequests'
import financialDocumentsFeature from './financialDocumentsFeature'
import formCategories from './formCategories'
import forms from './forms'
import groupVideoCallsFeature from './groupVideoCallsFeature'
import guest from './guest'
import guestAccountSettings from './guestAccountSettings'
import guestBookings from './guestBookings'
import guestPayments from './guestPayments'
import industry from './industry'
import intakeFormsFeature from './intakeFormsFeature'
import inviteMembers from './inviteMembers'
import locations from './locations'
import locationsFeature from './locationsFeature'
import login from './login'
import manageSeats from './manageSeats'
import manageStorage from './manageStorage'
import manageVideoCalls from './manageVideoCalls'
import myBusinessFiles from './myBusinessFiles'
import notificationPanel from './notificationPanel'
import notifications from './notifications'
import pageTitle from './pageTitle'
import payment from './payment'
import paymentSettings from './paymentSettings'
import payments from './payments'
import recurringAppointmentFeature from './recurringAppointmentFeature'
import recurringPayment from './recurringPayment'
import recurringPayments from './recurringPayments'
import refundsFeature from './refundsFeature'
import resetPassword from './resetPassword'
import reviewSettings from './reviewSettings'
import reviewsFeature from './reviewsFeature'
import scheduleAppointment from './scheduleAppointment'
import serviceAgreementsFeature from './serviceAgreementsFeature'
import serviceCategories from './serviceCategories'
import serviceCategoriesFeature from './serviceCategoriesFeature'
import services from './services'
import servicesFeature from './servicesFeature'
import settings from './settings'
import shared from './shared'
import signatureFeature from './signatureFeature'
import signatureSettings from './signatureSettings'
import smsRemindersFeature from './smsRemindersFeature'
import storage from './storage'
import stripePos from './stripePosFeature'
import subscriptionPlans from './subscriptionPlans'
import switchBillingCycle from './switchBillingCycle'
import tasks from './tasks'
import tasksFeature from './tasksFeature'
import teamFeature from './teamFeature'
import trashedFiles from './trashedFiles'
import trialPeriodReminder from './trialPeriodReminder'
import tutorial from './tutorial'
import uploadLogo from './uploadLogo'
import videoCallsIntegrations from './videoCallsIntegrations'
import videoCallsRecordingFeature from './videoCallsRecordingFeature'
import videoConference from './videoConference'
import voiceMessagesFeature from './voiceMessagesFeature'
import waitlistFeature from './waitlistFeature'
import waitlistSettings from './waitlistSettings'
import website from './website'
import websiteFeature from './websiteFeature'
import websitePreview from './websitePreview'
import workspaceFeatures from './workspaceFeatures'
import workspaceSettings from './workspaceSettings'
import yup from './yup'
import guestWaitlistRequests from './guestWaitlistRequests'

const locales = {
  locale: 'en',
  messages: {
    ...account,
    ...automaticLogout,
    ...availability,
    ...billing,
    ...booking,
    ...bookingCheckout,
    ...bookings,
    ...calendar,
    ...chat,
    ...client,
    ...etherpadDocuments,
    ...waitlistRequests,
    ...clientFields,
    ...clientRelationshipTypes,
    ...documentTemplates,
    ...clientContactsFeature,
    ...signatureFeature,
    ...signatureSettings,
    ...clientPayment,
    ...clientPayments,
    ...clientPortal,
    ...clientPortalFeature,
    ...clientProfile,
    ...clientReview,
    ...company,
    ...companyProfile,
    ...companySidebar,
    ...costManagement,
    ...createService,
    ...editService,
    ...employeeAccount,
    ...employeeAccountSettings,
    ...employeeOnboarding,
    ...myBusinessFiles,
    ...trashedFiles,
    ...employeeProfile,
    ...employees,
    ...employeeSidebar,
    ...employeeSignUp,
    ...employeeTwoFactorSettings,
    ...forms,
    ...industry,
    ...login,
    ...backupLogin,
    ...manageSeats,
    ...manageStorage,
    ...notificationPanel,
    ...notifications,
    ...pageTitle,
    ...paymentSettings,
    ...stripePos,
    ...resetPassword,
    ...reviewSettings,
    ...scheduleAppointment,
    ...serviceCategories,
    ...services,
    ...shared,
    ...subscriptionPlans,
    ...tutorial,
    ...uploadLogo,
    ...videoConference,
    ...bookingPage,
    ...bookingPageSettings,
    ...yup,
    ...clientAccountDocuments,
    ...settings,
    ...locations,
    ...cardReader,
    ...companyClients,
    ...companyClientsCreate,
    ...companyClientsImport,
    ...tasks,
    ...payments,
    ...payment,
    ...recurringPayment,
    ...recurringPayments,
    ...bookingPolicy,
    ...recurringPayments,
    ...guestBookings,
    ...guestPayments,
    ...guestAccountSettings,
    ...businessSettings,
    ...workspaceSettings,
    ...calendarSync,
    ...guest,
    ...website,
    ...websitePreview,
    ...createWebsite,
    ...editWebsite,
    ...workspaceFeatures,
    ...availabilityFeature,
    ...bookingFeature,
    ...customNames,
    ...recurringAppointmentFeature,
    ...servicesFeature,
    ...serviceCategoriesFeature,
    ...tasksFeature,
    ...acceptVideoCallsFeature,
    ...groupVideoCallsFeature,
    ...videoCallsRecordingFeature,
    ...bookingPageFeature,
    ...bookingWidgetsFeature,
    ...teamFeature,
    ...websiteFeature,
    ...locationsFeature,
    ...clientListFeature,
    ...crmFeature,
    ...chatFeature,
    ...financialDocumentsFeature,
    ...acceptPaymentsFeature,
    ...customPaymentsFeature,
    ...refundsFeature,
    ...smsRemindersFeature,
    ...emailRemindersFeature,
    ...trialPeriodReminder,
    ...customNamingFeature,
    ...serviceAgreementsFeature,
    ...intakeFormsFeature,
    ...reviewsFeature,
    ...calendarIntegrationsFeature,
    ...calendarEvents,
    ...switchBillingCycle,
    ...manageVideoCalls,
    ...videoCallsIntegrations,
    ...inviteMembers,
    ...voiceMessagesFeature,
    ...formCategories,
    ...clientContacts,
    ...documentsFeature,
    ...waitlistFeature,
    ...waitlistSettings,
    ...documentTemplatesFeature,
    ...eSignature,
    ...businessFilesFeature,
    ...clientBusinessFiles,
    ...filesPreview,
    ...filesSharedWithMe,
    ...storage,
    ...guestWaitlistRequests,
  },
}

export default locales
