import { combineReducers } from '@reduxjs/toolkit'
import { without } from 'ramda'

import {
  CREATE_SETUP_INTENT,
  SET_ACTIVE_SUBSCRIPTION_TRANSACTION_ID,
  SET_BILLING_ACCOUNT_ID,
  SET_BILLING_DETAIL_ID,
  SET_SETUP_INTENT_CLIENT_SECRET,
  SET_STRIPE_PUBLISHABLE_API_KEY,
  SET_SUBSCRIPTION_TRANSACTIONS,
  SET_SUBSCRIPTION_TRANSACTIONS_PAGE,
  RESET_SUBSCRIPTION_TRANSACTIONS,
  REMOVE_SUBSCRIPTION_TRANSACTIONS,
} from './types'

const billingDetailId = (state = null, action) => {
  switch (action.type) {
    case SET_BILLING_DETAIL_ID:
      return action.billingDetailId
    default:
      return state
  }
}

const subscriptionTransactionIds = (state = [], action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_TRANSACTIONS:
      return action.subscriptionTransactionIds
    case RESET_SUBSCRIPTION_TRANSACTIONS:
      return []
    case REMOVE_SUBSCRIPTION_TRANSACTIONS:
      return without(action.ids, state)
    default:
      return state
  }
}

const activeTransactionId = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_SUBSCRIPTION_TRANSACTION_ID:
      return action.subscriptionTransactionId
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_TRANSACTIONS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const billingAccountId = (state = null, action) => {
  switch (action.type) {
    case SET_BILLING_ACCOUNT_ID:
      return action.billingAccountId
    default:
      return state
  }
}

const setupIntentClientSecret = (state = null, action) => {
  switch (action.type) {
    case SET_SETUP_INTENT_CLIENT_SECRET:
      return action.setupIntentClientSecret
    case CREATE_SETUP_INTENT:
      return null
    default:
      return state
  }
}

const stripePublishableApiKey = (state = null, action) => {
  switch (action.type) {
    case SET_STRIPE_PUBLISHABLE_API_KEY:
      return action.stripePublishableApiKey
    default:
      return state
  }
}

const paymentMethod = combineReducers({
  setupIntentClientSecret,
  stripePublishableApiKey,
})

const billingReducer = combineReducers({
  billingDetailId,
  subscriptionTransactionIds,
  activeTransactionId,
  pagination,
  billingAccountId,
  paymentMethod,
})

export default billingReducer
