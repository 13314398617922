import classNames from 'clsx'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import Link from 'views/shared/Link'
import AddUser from 'views/shared/icons/AddUser'
import AlertIcon from 'views/shared/icons/AlertIcon'
import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'
import ArrowDownLong from 'views/shared/icons/ArrowDownLong'
import ArrowLeft from 'views/shared/icons/ArrowLeft'
import ArrowRepeat from 'views/shared/icons/ArrowRepeat'
import ArrowRight from 'views/shared/icons/ArrowRight'
import ArrowRightLong from 'views/shared/icons/ArrowRightLong'
import Attachment from 'views/shared/icons/Attachment'
import CalendarO from 'views/shared/icons/CalendarO'
import CalendarWidget from 'views/shared/icons/CalendarWidget'
import Checked from 'views/shared/icons/Checked'
import Close from 'views/shared/icons/Close'
import Copy from 'views/shared/icons/CopyO'
import Cross from 'views/shared/icons/Cross'
import CrossUnrounded from 'views/shared/icons/CrossUnrounded'
import DeleteO from 'views/shared/icons/DeleteO'
import Download from 'views/shared/icons/Download'
import Edit from 'views/shared/icons/Edit'
import EditPencil from 'views/shared/icons/EditPencil'
import External from 'views/shared/icons/External'
import EyeO from 'views/shared/icons/EyeO'
import Filter from 'views/shared/icons/Filter'
import FolderAdd from 'views/shared/icons/FolderAdd'
import Google from 'views/shared/icons/Google'
import Import from 'views/shared/icons/Import'
import Info from 'views/shared/icons/Info'
import LivePreview from 'views/shared/icons/LivePreview'
import LoadInvoice from 'views/shared/icons/LoadInvoice'
import LoadReceipt from 'views/shared/icons/LoadReceipt'
import LocationO from 'views/shared/icons/LocationO'
import Message from 'views/shared/icons/Message'
import Microphone from 'views/shared/icons/Microphone'
import Note from 'views/shared/icons/Note'
import Options from 'views/shared/icons/Options'
import OptionsVertical from 'views/shared/icons/OptionsVertical'
import Plus from 'views/shared/icons/Plus'
import Question from 'views/shared/icons/Question'
import SettingsO from 'views/shared/icons/SettingsO'
import Spinner from 'views/shared/icons/Spinner'
import StarO from 'views/shared/icons/StarO'
import Template from 'views/shared/icons/Template'
import Tick from 'views/shared/icons/Tick'
import Upload from 'views/shared/icons/Upload'
import Video from 'views/shared/icons/Video'
import VideoO from 'views/shared/icons/VideoO'
import CloudArrow from 'views/shared/icons/CloudArrow'
import ArrowRestore from 'views/shared/icons/ArrowRestore'

const ICON_COMPONENTS = {
  close: Close,
  alert: AlertIcon,
  info: Info,
  tick: Tick,
  plus: Plus,
  copy: Copy,
  filter: Filter,
  'edit-pencil': EditPencil,
  video: Video,
  'video-o': VideoO,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  'live-preview': LivePreview,
  template: Template,
  checked: Checked,
  calendar: CalendarO,
  google: Google,
  edit: Edit,
  'add-user': AddUser,
  question: Question,
  'options-vertical': OptionsVertical,
  external: External,
  attachment: Attachment,
  note: Note,
  options: Options,
  cross: Cross,
  'cross-unrounded': CrossUnrounded,
  upload: Upload,
  'settings-o': SettingsO,
  star: StarO,
  'calendar-widget': CalendarWidget,
  import: Import,
  message: Message,
  delete: DeleteO,
  'arrow-right': ArrowRight,
  'load-invoice': LoadInvoice,
  'load-receipt': LoadReceipt,
  location: LocationO,
  'arrow-repeat': ArrowRepeat,
  'arrow-left': ArrowLeft,
  'arrow-down-long': ArrowDownLong,
  eye: EyeO,
  microphone: Microphone,
  download: Download,
  'arrow-right-long': ArrowRightLong,
  'cloud-arrow': CloudArrow,
  'folder-add': FolderAdd,
  'arrow-restore': ArrowRestore,
}

const Button = forwardRef(
  (
    {
      id,
      className,
      text,
      kind,
      isFullWidth,
      type,
      disabled,
      isLink,
      href,
      target,
      isLabel,
      htmlFor,
      onClick,
      onMouseEnter,
      onMouseLeave,
      icon,
      size,
      dataCy,
      isLoading,
      isIcon,
      iconClassName,
      iconWithTextClassName,
      iconWrapWithTextClassName,
      loadingText,
      iconSize,
      dataAttributes,
      style,
      isRoot,
      query,
      isRawLink,
      mainBtnTxtClassName,
    },
    ref,
  ) => {
    const buttonClassNames = classNames(className, 'main-btn', {
      'main-btn--flat': kind === 'flat',
      'main-btn--secondary': kind === 'secondary',
      'main-btn--text': kind === 'text',
      'main-btn--danger': kind === 'danger',
      'main-btn--danger-secondary': kind === 'danger-secondary',
      'main-btn--full-width': isFullWidth,
      'main-btn--md': size === 'medium',
      'main-btn--sm': size === 'small',
      'main-btn--xs-sm': size === 'tiny-small',
      'main-btn--xs': size === 'tiny',
      'main-btn--loading': isLoading,
    })

    const IconComponent = ICON_COMPONENTS[icon]

    if (isLink) {
      return (
        <Link href={href} isRoot={isRoot} query={query} isRawLink={isRawLink}>
          <a
            id={id}
            role="link"
            className={buttonClassNames}
            onClick={onClick}
            target={target}
            data-cy={dataCy}
            disabled={disabled}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...dataAttributes}
          >
            {IconComponent && (
              <div className="main-btn__icon-wrap">
                <IconComponent className="main-btn__icon" size={iconSize} />
              </div>
            )}
            <span>
              <FormattedOrRawMessage message={text} />
            </span>
          </a>
        </Link>
      )
    }

    if (isLabel) {
      return (
        <label id={id} className={buttonClassNames} htmlFor={htmlFor}>
          <span className={classNames('main-btn__txt', mainBtnTxtClassName)}>
            <FormattedOrRawMessage message={text} />
          </span>
        </label>
      )
    }

    if (isIcon) {
      return (
        <button data-cy={dataCy} onClick={onClick} className={className} disabled={disabled}>
          <IconComponent className={iconClassName} size={iconSize} />
        </button>
      )
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        id={id}
        type={type}
        className={buttonClassNames}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        data-cy={dataCy}
        style={style}
      >
        {isLoading ? (
          <>
            <div className="main-btn__icon-wrap">
              <Spinner size={18} color="currentColor" dataCy="btn-loading-spinner" />
            </div>
            {loadingText && (
              <span className="ml-12">
                <FormattedOrRawMessage message={loadingText} />
              </span>
            )}
          </>
        ) : (
          <>
            {IconComponent && (
              <div className={classNames('main-btn__icon-wrap', iconWrapWithTextClassName)}>
                <IconComponent className={classNames('main-btn__icon', iconWithTextClassName)} size={iconSize} />
              </div>
            )}
            <span className={classNames('main-btn__txt', mainBtnTxtClassName)}>
              <FormattedOrRawMessage message={text} />
            </span>
          </>
        )}
      </button>
    )
  },
)

Button.defaultProps = {
  className: null,
  kind: null,
  isFullWidth: false,
  type: 'button',
  disabled: false,
  isLink: false,
  isIcon: false,
  href: null,
  isLabel: false,
  htmlFor: null,
  onClick: undefined,
  icon: null,
  size: null,
  dataCy: undefined,
  id: undefined,
  isLoading: false,
  loadingText: undefined,
  iconSize: undefined,
  target: undefined,
  text: '',
  dataAttributes: undefined,
  style: undefined,
  iconClassName: undefined,
  iconWithTextClassName: undefined,
  iconWrapWithTextClassName: undefined,
  mainBtnTxtClassName: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  isRoot: false,
  query: undefined,
  isRawLink: false,
}

Button.propTypes = {
  id: PropTypes.string,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconWithTextClassName: PropTypes.string,
  iconWrapWithTextClassName: PropTypes.string,
  mainBtnTxtClassName: PropTypes.string,
  isRawLink: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  kind: PropTypes.oneOf(['flat', 'secondary', 'danger', 'danger-secondary', 'text', 'outline', null]),
  icon: PropTypes.oneOf([
    'close',
    'alert',
    'info',
    'tick',
    'plus',
    'filter',
    'folder-add',
    'edit-pencil',
    'video',
    'video-o',
    'arrow-down',
    'arrow-up',
    'copy',
    'live-preview',
    'template',
    'shared',
    'checked',
    'list-checked',
    'calendar',
    'google',
    'edit',
    'add-user',
    'question',
    'options-vertical',
    'attachment',
    'note',
    'options',
    'cross',
    'cross-unrounded',
    'upload',
    'external',
    'settings-o',
    'star',
    'calendar-widget',
    'import',
    'message',
    'arrow-right',
    'load-invoice',
    'load-receipt',
    'location',
    'arrow-repeat',
    'arrow-left',
    'delete',
    'arrow-down-long',
    'eye',
    'microphone',
    'download',
    'arrow-right-long',
    'cloud-arrow',
    'arrow-restore',
    null,
  ]),
  size: PropTypes.oneOf(['tiny', 'tiny-small', 'small', 'medium', null]),
  isFullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isIcon: PropTypes.bool,
  href: PropTypes.string,
  isLabel: PropTypes.bool,
  htmlFor: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  iconSize: PropTypes.number,
  target: PropTypes.string,
  dataAttributes: PropTypes.shape(),
  style: PropTypes.shape(),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isRoot: PropTypes.bool,
  query: PropTypes.shape(),
}

Button.displayName = 'Button'

export default Button
