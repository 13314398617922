import React from 'react'
import PropTypes from 'prop-types'

import ConfirmModal from 'views/shared/ConfirmModal'

class RemoveModal extends React.Component {
  static propTypes = {
    videoRecordId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    const { videoRecordId, onSubmit } = this.props

    onSubmit(videoRecordId)
  }

  render() {
    return (
      <ConfirmModal
        {...this.props}
        modalClassName="main-modal--md"
        title={{
          id: 'bookings.removeRecording.confirmModal.title',
        }}
        bodyText={[
          {
            id: 'bookings.removeRecording.confirmModal.body',
          },
        ]}
        kind="danger"
        submitText={{ id: 'shared.delete' }}
        dismissText={{ id: 'shared.cancel' }}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default RemoveModal
