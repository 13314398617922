import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Spinner from 'views/shared/icons/Spinner'

const NotFoundContent = ({ isLoading, isBlankState, allowedToInviteClient, withoutInvitation }) => {
  if (isLoading !== false) return <Spinner size={24} />

  if (!allowedToInviteClient || withoutInvitation) {
    return <FormattedMessage id="scheduleAppointment.assignClientDropdown.noMatchesFound" />
  }

  if (isBlankState) {
    return <FormattedMessage id="scheduleAppointment.assignClientDropdown.typeClientsEmailToInvite" />
  }

  return <FormattedMessage id="scheduleAppointment.assignClientDropdown.noMatchesFoundAndInvite" />
}

NotFoundContent.defaultProps = {
  isLoading: undefined,
  isBlankState: false,
}

NotFoundContent.propTypes = {
  allowedToInviteClient: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isBlankState: PropTypes.bool,
  withoutInvitation: PropTypes.bool.isRequired,
}

export default NotFoundContent
