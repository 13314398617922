import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { resetSearchResults } from 'state/searchResults/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { fetchClientRelationshipTypes } from '../actions'
import { DELETE_CLIENT_RELATIONSHIP_TYPE } from '../types'
import { deleteClientRelationshipTypeEndpoint } from '../endpoints'

const deleteClientRelationshipTypeOperation = createLogic({
  type: DELETE_CLIENT_RELATIONSHIP_TYPE,
  latest: true,

  async process({ httpClient, action: { form, values } }, dispatch, done) {
    const { id, newClientRelationshipTypeId } = values
    const { url, endpoint } = deleteClientRelationshipTypeEndpoint(id)
    const params = {
      client_relationship_type_id: newClientRelationshipTypeId,
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url, { params })

      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.clientRelationshipDeleted' } }))
      dispatch(fetchClientRelationshipTypes())
      dispatch(resetSearchResults(TABLE_ENTITIES.clientRelationshipTypes))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default deleteClientRelationshipTypeOperation
