import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { resetTrashedFileIds } from '../actions'
import { emptyTrashEndpoint } from '../endpoints'
import { EMPTY_TRASH } from '../types'

const emptyTrashOperation = createLogic({
  type: EMPTY_TRASH,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = emptyTrashEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(resetTrashedFileIds())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default emptyTrashOperation
