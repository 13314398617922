import PropTypes from 'prop-types'

import Timezone from 'views/shared/icons/Timezone'
import Dropdown from 'views/shared/Dropdown'
import ArrowDown from 'views/shared/icons/ArrowDown'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import TimezoneSelect from './Select'

const ClientTimezoneFieldComponent = ({
  selectedTimezoneLabel,
  isOpen,
  onVisibleChange,
  handleSelect,
  timezones,
  getPopupContainer,
}) => (
  <Dropdown
    className="main-popup dropdown-timezone mb-24"
    open={isOpen}
    onOpenChange={onVisibleChange}
    destroyPopupOnHide
    placement="bottomRight"
    getPopupContainer={getPopupContainer}
    icon={
      <div data-cy="timezone-dropdown" id="client-timezone">
        <div className="d-flex align-items-center column-gap-8 min-w-0">
          <div className="d-flex align-items-center justify-content-center wh-24-24">
            <Timezone className="dropdown-timezone__icon" size={20} />
          </div>
          <div className="min-w-0 text-body dropdown-timezone__txt">
            <TruncatedTextByWidthTooltip on={['hover']} text={selectedTimezoneLabel} />
          </div>
          <button
            type="button"
            className="dropdown-timezone__arrow d-flex align-items-center justify-content-center wh-16-16"
          >
            <ArrowDown size={8} dataCy="arrow-down-icon" />
          </button>
        </div>
      </div>
    }
  >
    <TimezoneSelect options={timezones} handleSelect={handleSelect} />
  </Dropdown>
)

ClientTimezoneFieldComponent.propTypes = {
  selectedTimezoneLabel: PropTypes.string,
  timezones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  getPopupContainer: PropTypes.func.isRequired,
}

ClientTimezoneFieldComponent.defaultProps = {
  selectedTimezoneLabel: null,
}

export default ClientTimezoneFieldComponent
