import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { validateRescheduleBookingEndpoint } from 'state/concepts/bookings/endpoints'
import { VALIDATE_RESCHEDULE_BOOKING } from 'state/concepts/bookings/types'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { setRescheduleError } from 'state/concepts/bookings/actions'

const validateRescheduleBookingOperation = createLogic({
  type: VALIDATE_RESCHEDULE_BOOKING,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { uniqCode } = action

    const { endpoint, url } = validateRescheduleBookingEndpoint(uniqCode)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.get(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setRescheduleError(null))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors
        dispatch(setRescheduleError(detail))
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default validateRescheduleBookingOperation
