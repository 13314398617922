import { createLogic } from 'redux-logic'
import build from 'redux-object'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import { CREATE_MESSAGE_OPTIMISTIC } from 'state/concepts/chat/types'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceIdSelector, currentChatUserIdSelector } from 'state/concepts/session/selectors'
import { receiveChatMessages } from 'state/concepts/chat/actions'

const createMessageOptimisticOperation = createLogic({
  type: CREATE_MESSAGE_OPTIMISTIC,

  async process({ action: { id, chatId, text, attachmentIds }, getState }, dispatch, done) {
    const state = getState()
    const currentWorkspaceId = currentWorkspaceIdSelector(state)
    const currentChatUserId = currentChatUserIdSelector(state)
    const timestamp = DateTime.utc().toISO()

    const message = {
      data: {
        attributes: {
          chatId,
          text,
          editedAt: null,
          destroyed: false,
          meta: '{}',
          createdAt: timestamp,
          updatedAt: null,
        },
        id,
        relationships: {
          chat: { data: { id: chatId, type: 'chat' } },
          chatUser: { data: { id: currentChatUserId, type: 'chatUser' } },
          workspace: { data: { id: currentWorkspaceId, type: 'workspace' } },
          attachments: { data: attachmentIds.map(attachmentId => ({ id: attachmentId, type: 'attachment' })) },
        },
        type: 'message',
      },
    }

    const response = updateDataHelper(state.data, 'message', id, message.data)
    const messages = build(response, 'message')
    dispatch(dataApiSuccess({ response }))
    dispatch(receiveChatMessages(messages))

    done()
  },
})

export default createMessageOptimisticOperation
