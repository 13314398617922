import fetchTransactions from './fetchTransactions'
import filterTransactions from './filterTransactions'
import fetchReceiptTemplate from './fetchReceiptTemplate'
import updateReceiptTemplate from './updateReceiptTemplate'
import fetchClientTransactions from './fetchClientTransactions'
import filterClientTransactions from './filterClientTransactions'
import resetClientTransactionsFilter from './resetClientTransactionsFilter'
import cancelPayment from './cancelPayment'
import requestPayment from './requestPayment'

export default [
  fetchTransactions,
  filterTransactions,
  fetchReceiptTemplate,
  updateReceiptTemplate,
  fetchClientTransactions,
  filterClientTransactions,
  resetClientTransactionsFilter,
  cancelPayment,
  requestPayment,
]
