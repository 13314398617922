import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import { useSelector } from 'react-redux'

import { generateBackupCodesMetaSelector } from 'state/concepts/employee/selectors'
import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import CodesList from './CodesList'
import GenerateCodesForm from './GenerateCodesForm'

const BackupCodesModal = ({ onClose }) => {
  const [isSubmitted, toggleIsSubmitted] = useToggle(false)
  const { backupCodes } = useSelector(generateBackupCodesMetaSelector)

  return (
    <Modal className="main-modal main-modal--md backup-codes">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id={`employeeTwoFactorSettings.backupCodes.${isSubmitted ? 'title' : 'newCodes'}`} />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        {isSubmitted ? (
          <CodesList backupCodes={backupCodes} />
        ) : (
          <GenerateCodesForm onClose={onClose} onSuccess={toggleIsSubmitted} />
        )}
      </div>
    </Modal>
  )
}

BackupCodesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default BackupCodesModal
