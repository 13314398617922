export const ACTIVATION_STATUSES = {
  enabled: 'enabled',
  disabled: 'disabled',
  active: 'active',
  inactive: 'inactive',
}

export const SELECT_MODES = {
  default: 'default',
  multiple: 'multiple',
}
