const filesSharedWithMe = {
  'filesSharedWithMe.noSearchResults': 'Try adjusting your search or filters',
  'filesSharedWithMe.noSharedFiles.alt': 'No shared files',
  'filesSharedWithMe.table.sharedAt.title': 'Share date',
  'filesSharedWithMe.table.sharedBy.title': 'Shared by',
  'filesSharedWithMe.actions.open': 'Open',
  'filesSharedWithMe.actions.rename': 'Rename',
  'filesSharedWithMe.actions.duplicate': 'Make a copy',
  'filesSharedWithMe.actions.shareWithTeam': 'Share with team',
  'filesSharedWithMe.actions.moveToFolder': 'Move to folder',
  'filesSharedWithMe.actions.moveToTrash': 'Move to trash',
  'filesSharedWithMe.actions.removeMyAccess': 'Remove my access',
  'filesSharedWithMe.moveToTrash.modal.title': 'Move to trash?',
  'filesSharedWithMe.moveToTrash.modal.description': 'You’re about to move the {kind} <b>{name}</b> to trash.',
  'filesSharedWithMe.moveToTrash.modal.descriptionWithCounter': 'You’re about to move {count} items to trash.',
  'filesSharedWithMe.moveToTrash.modal.alert': 'You have 30 days to restore files and folders from the trash.',
  'filesSharedWithMe.moveToTrash.modal.button': 'Move to trash',

  'filesSharedWithMe.removeAccess.modal.title':
    'Remove your access to this {kind, select, folder {folder} file {file} other {}}?',
  'filesSharedWithMe.removeAccess.modal.description':
    'If you continue, you won’t be able to access this {kind, select, folder {folder} file {file} other {}} anymore.',
  'filesSharedWithMe.removeAccess.modal.button': 'Remove access',
  'filesSharedWithMe.noSharedFiles.viewer': 'This folder is empty',
  'filesSharedWithMe.noSharedFiles.editor.title': 'Drop files here',
  'filesSharedWithMe.noSharedFiles.editor.description': 'or use “Upload file” button',
}

export default filesSharedWithMe
