import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorTooManyRequests } from 'utils/getErrorStatus'
import { sendInstructionsEndpoint } from 'state/concepts/widget/endpoints'
import { showNotification } from 'state/notifications/actions'
import { SEND_INSTRUCTIONS } from 'state/concepts/widget/types'
import { dataApiSuccess } from 'state/data/actions'
import { INTEGRATION_TYPES } from 'lib/constants/widget'

const sendInstructions = createLogic({
  type: SEND_INSTRUCTIONS,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const {
      form,
      values: { email, captcha },
      widgetId,
      isEmbedded,
      onSuccess,
    } = action

    const { url, endpoint } = sendInstructionsEndpoint(widgetId)

    const integrationType = isEmbedded ? INTEGRATION_TYPES.embedded : INTEGRATION_TYPES.side
    const params = {
      email,
      integration_type: integrationType,
      captcha,
    }

    try {
      await httpClient.post(url, params)

      onSuccess?.()
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.instructionsHaveBeenSent' } }))
      form.resetForm()
    } catch (error) {
      if (isErrorTooManyRequests(error)) {
        dispatch(showNotification({ messageObject: { id: 'errorPage.tooManyRequestsNotification' }, kind: 'error' }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form })
      }
    }

    form.setSubmitting(false)
    done()
  },
})

export default sendInstructions
