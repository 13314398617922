import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'

import { formattedShortDateForTrial } from 'utils/dateTime'
import { appliedPromocodeRoute } from 'lib/apiRoutes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { APPLY_PROMOCODE } from 'state/concepts/promocodes/types'
import { setAppliedPromocodes } from 'state/concepts/promocodes/actions'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { fetchBillingDetail } from 'state/concepts/billing/actions'

const applyPromocodeOperation = createLogic({
  type: APPLY_PROMOCODE,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    try {
      const { data } = await httpClient.post(appliedPromocodeRoute, values)
      const response = normalize(data)
      const appliedPromocode = build(response, 'appliedPromocode')[0]

      dispatch(setAppliedPromocodes(appliedPromocode.id))
      dispatch(dataApiSuccess({ response }))
      dispatch(fetchBillingDetail())
      dispatch(hideModal())

      const notificationParams = appliedPromocode.trialExpireDate
        ? {
            id: 'trialAppliedMessage',
            messageObject: {
              id: 'notifications.trialPromocodeApplied',
              values: {
                date: formattedShortDateForTrial(appliedPromocode.trialExpireDate),
              },
            },
          }
        : {
            id: 'discountAppliedMessage',
            messageObject: {
              id: 'notifications.discountPromocodeApplied',
              values: {
                percentage: appliedPromocode.discountPercentage,
              },
            },
          }

      dispatch(showNotification(notificationParams))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default applyPromocodeOperation
