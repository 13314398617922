import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import { PAGINATION_ITEMS_PER_PAGE } from 'lib/constants/tableData'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { ALERT_KINDS } from 'lib/constants'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { FETCH_FOLDERS_TO_MOVE } from '../types'
import { fetchMyFolderToMoveEndpoint } from '../endpoints'
import { addFolderToMoveIds, setFolderToMoveIds } from '../actions'

const fetchFoldersToMoveOperation = createLogic({
  type: FETCH_FOLDERS_TO_MOVE,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { parentId, fileId, page } = action
    const { url, endpoint } = fetchMyFolderToMoveEndpoint(fileId)

    const params = {
      page: { number: page, size: PAGINATION_ITEMS_PER_PAGE },
      parent_id: parentId,
      include: ['parent.business_storage_permissions', 'business_storage_permissions'],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      const ids = pluck('id', data.data)
      page > 1 ? dispatch(addFolderToMoveIds(ids)) : dispatch(setFolderToMoveIds(ids))
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchFoldersToMoveOperation
