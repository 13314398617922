import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { negate } from 'ramda'

import FormattedCurrency from 'views/shared/FormattedCurrency'
import ArrowRight from 'views/shared/icons/ArrowRight'
import { getDiscount } from 'utils/billing'
import { formattedShortDate } from 'utils/dateTime'

const AnnualToMonthly = ({ subscriptionPlan }) => {
  const { subscriptionPlanPrice, annualPrice, annualEndingAt } = subscriptionPlan
  const annualDiscount = getDiscount(subscriptionPlan)

  return (
    <div className="main-modal__body">
      <div className="d-flex align-items-center justify-content-between mb-16">
        <div className="freemium-modal__billing-cycle-box p-16">
          <h3 className="freemium-modal__price-headline font-16 font-600">
            <FormattedMessage id="switchBillingCycle.annual" />
          </h3>
          <p className="d-flex align-items-center mb-0 mt-4">
            <span className="freemium-modal__discounted-price font-16 text-line-through in-gray-700 mr-8">
              <FormattedCurrency price={subscriptionPlanPrice} />
            </span>
            <span className="freemium-modal__price font-16 font-600">
              <FormattedCurrency price={annualPrice} />
            </span>
            <span className="main-badge main-badge--draft-freemium main-badge--success main-badge--no-dots pl-8 font-400 ml-auto">
              {`${negate(annualDiscount)}%`}
            </span>
          </p>
          <p className="freemium-modal__price-text in-blue-gray-300 mb-0 ">
            <FormattedMessage id="switchBillingCycle.perUserMonth" />
          </p>
        </div>
        <ArrowRight size={10} className="in-blue-600" />
        <div className="freemium-modal__billing-cycle-box p-16">
          <h3 className="freemium-modal__price-headline font-16 font-600">
            <FormattedMessage id="switchBillingCycle.monthly" />
          </h3>
          <p className="d-flex align-items-center mb-0 mt-4">
            <span className="freemium-modal__price font-16 font-600">
              <FormattedCurrency price={subscriptionPlanPrice} />
            </span>
          </p>
          <p className="freemium-modal__price-text in-blue-gray-300 mb-0 ">
            <FormattedMessage id="switchBillingCycle.perUserMonth" />
          </p>
        </div>
      </div>
      <p className="freemium-modal__text mb-20">
        <FormattedMessage
          id="switchBillingCycle.fromAnnual.planIsActiveUntil"
          values={{ annualPaymentDate: formattedShortDate(annualEndingAt) }}
        />
      </p>
      <p className="freemium-modal__text mb-20">
        <FormattedMessage
          id="switchBillingCycle.fromAnnual.youCanCancel"
          values={{ annualPaymentDate: formattedShortDate(annualEndingAt) }}
        />
      </p>
      <p className="freemium-modal__text mb-0">
        <FormattedMessage id="switchBillingCycle.fromAnnual.wantToSave?" values={{ discount: annualDiscount }} />
      </p>
    </div>
  )
}

AnnualToMonthly.propTypes = {
  subscriptionPlan: PropTypes.shape().isRequired,
}

export default AnnualToMonthly
