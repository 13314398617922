import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ArrowRestore = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 0.001c-282.317 0-511.999 229.683-511.999 511.999s229.683 511.999 511.999 511.999c265.065 0 483.731-202.472 509.467-460.872 2.799-28.138-20.391-51.127-48.668-51.127s-50.865 23.038-54.374 51.096c-25.225 201.817-197.885 358.503-406.425 358.503-225.843 0-409.6-183.76-409.6-409.6 0-225.843 183.757-409.6 409.6-409.6 112.946 0 215.176 46.104 289.3 120.3l-77.428 77.43c-21.504 21.503-6.274 58.269 24.132 58.269h241.862c18.848 0 34.133-15.282 34.133-34.133v-241.861c0-30.41-36.768-45.639-58.272-24.137l-92.228 92.231c-92.61-92.835-220.324-150.5-361.499-150.5z" />
  </svg>
)

ArrowRestore.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ArrowRestore.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ArrowRestore
