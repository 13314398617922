import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'

import * as types from './types'

export const deleteClientAccountReview = (reviewId, bookingId) => ({
  type: types.DELETE_REVIEW,
  reviewId,
  bookingId,
})

export const getClientReviewTemplate = ({ token, workspaceCode, bookingUniqCode, response }) => ({
  type: types.GET_REVIEW_TEMPLATE,
  token,
  workspaceCode,
  bookingUniqCode,
  response,
})

export const getClientReview = ({ reviewId }) => ({
  type: types.GET_REVIEW,
  reviewId,
})

export const setClientReviewId = reviewId => ({
  type: types.SET_REVIEW_ID,
  reviewId,
})

export const createReview = makeFormSubmitAction(types.CREATE_REVIEW)
export const updateReview = makeFormSubmitAction(types.UPDATE_REVIEW)
