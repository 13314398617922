import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ImageIcon = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M102.4 0C74.387 0 51.2 23.187 51.2 51.2v409.6c0 28.014 23.187 51.2 51.2 51.2h307.2c28.014 0 51.2-23.186 51.2-51.2V153.6L307.2 0H102.4zm0 51.2h179.2v128h128v281.6H102.4V51.2zm192 238.951l-64 85.351-38.4-51.2-64 85.299h256l-89.6-119.45z" />
  </svg>
)

ImageIcon.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ImageIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ImageIcon
