const namespace = 'formCategories'

export const FETCH_FORM_CATEGORIES = `${namespace}/FETCH_FORM_CATEGORIES`
export const SET_FORM_CATEGORIES_IDS = `${namespace}/SET_FORM_CATEGORIES_IDS`
export const SET_TOTAL_COUNT = `${namespace}/SET_TOTAL_COUNT`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const FILTER_FORM_CATEGORIES = `${namespace}/FILTER_FORM_CATEGORIES`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const CREATE_FORM_CATEGORY = `${namespace}/CREATE_FORM_CATEGORY`
export const UPDATE_FORM_CATEGORY = `${namespace}/UPDATE_FORM_CATEGORY`
export const UPDATE_FORM_CATEGORY_STATUS = `${namespace}/UPDATE_FORM_CATEGORY_STATUS`
export const SEARCH_FORM_CATEGORIES = `${namespace}/SEARCH_FORM_CATEGORIES`
export const DELETE_FORM_CATEGORY = `${namespace}/DELETE_FORM_CATEGORY`
