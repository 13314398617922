import Router from 'next/router'
import qs from 'qs'
import onHeaders from 'on-headers'

const buildQuery = query => (qs.stringify(query) ? `?${qs.stringify(query, { arrayFormat: 'repeat' })}` : '')

const serverRedirect = (response, destination, query) => {
  // eslint-disable-next-line func-names
  onHeaders(response, function () {
    this.setHeader('Location', `${destination}${buildQuery(query)}`)
    this.statusCode = 302
  })
  response.end()
}

const clientRedirect = (href, query, reload, skipDiscard, shallow) =>
  reload
    ? window.location.replace(`${href}${buildQuery(query)}`)
    : Router.push({ pathname: href, query }, null, { skipDiscard, shallow })

const redirect = ({ href, response, workspace, reload, query, skipDiscard, shallow }) => {
  const hrefPath = workspace ? `/${workspace}${href}` : href

  return response
    ? serverRedirect(response, hrefPath, query)
    : clientRedirect(hrefPath, query, reload, skipDiscard, shallow)
}

export default redirect
