import endpoint from 'utils/endpoint'
import {
  chatMessagesRoute,
  chatMessagesMessageRoute,
  chatsMessagesReadRoute,
  chatsAttachmentsRoute,
  chatsAttachmentRoute,
  chatRoute,
  chatLeaveRoute,
  chatMembersRoute,
  chatRemoveMemberRoute,
  chatAttachmentsUploadRoute,
  chatsAttachmentsUploadRoute,
  chatsAttachmentsUploadAttachmentRoute,
} from 'lib/apiRoutes'
import {
  FETCH_MESSAGES,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  REMOVE_MESSAGE,
  MARK_MESSAGES_AS_READ,
  FETCH_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  GET_CHAT,
  UPDATE_CHAT,
  LEAVE_CHAT,
  DELETE_CHAT,
  GET_CHAT_MEMBERS,
  REMOVE_CHAT_MEMBER,
  ADD_CHAT_MEMBERS,
  CREATE_ATTACHMENT_UPLOAD,
  UPDATE_ATTACHMENT_UPLOAD,
  CANCEL_ATTACHMENT_UPLOAD,
} from './types'

export const fetchChatMessageEndpoint = chatId => endpoint(FETCH_MESSAGES, 'GET', chatMessagesRoute(chatId))

export const createChatMessageEndpoint = chatId => endpoint(CREATE_MESSAGE, 'POST', chatMessagesRoute(chatId))

export const markChatMessagesAsReadEndpoint = chatId =>
  endpoint(MARK_MESSAGES_AS_READ, 'PUT', chatsMessagesReadRoute(chatId))

export const updateChatMessageEndpoint = messageId =>
  endpoint(UPDATE_MESSAGE, 'PUT', chatMessagesMessageRoute(messageId))

export const removeChatMessageEndpoint = messageId =>
  endpoint(REMOVE_MESSAGE, 'DELETE', chatMessagesMessageRoute(messageId))

export const fetchChatAttachmentsEndpoint = chatId => endpoint(FETCH_ATTACHMENTS, 'GET', chatsAttachmentsRoute(chatId))

export const removeChatsAttachmentsEndpoint = attachmentId =>
  endpoint(REMOVE_ATTACHMENT, 'DELETE', chatsAttachmentRoute(attachmentId))

export const getChatEndpoint = chatId => endpoint(GET_CHAT, 'GET', chatRoute(chatId))

export const updateChatEndpoint = chatId => endpoint(UPDATE_CHAT, 'PUT', chatRoute(chatId))

export const leaveChatEndpoint = chatId => endpoint(LEAVE_CHAT, 'POST', chatLeaveRoute(chatId))

export const deleteChatEndpoint = chatId => endpoint(DELETE_CHAT, 'DELETE', chatRoute(chatId))

export const getChatMembersEndpoint = chatId => endpoint(GET_CHAT_MEMBERS, 'GET', chatMembersRoute(chatId))

export const removeChatMemberEndpoint = (chatId, memberId) =>
  endpoint(REMOVE_CHAT_MEMBER, 'DELETE', chatRemoveMemberRoute(chatId, memberId))

export const addChatMembersEndpoint = chatId => endpoint(ADD_CHAT_MEMBERS, 'POST', chatMembersRoute(chatId))

export const createAttachmentUploadEndpoint = chatId =>
  endpoint(CREATE_ATTACHMENT_UPLOAD, 'POST', chatAttachmentsUploadRoute(chatId))

export const updateAttachmentUploadEndpoint = attachmentId =>
  endpoint(UPDATE_ATTACHMENT_UPLOAD, 'PUT', chatsAttachmentsUploadAttachmentRoute(attachmentId))

export const removeAttachmentUploadEndpoint = endpoint(CANCEL_ATTACHMENT_UPLOAD, 'DELETE', chatsAttachmentsUploadRoute)
