import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { EDIT_WEBSITE_PREVIEW_STEPS, getWebsiteSettingsToggleFields } from 'lib/constants/website'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { setWebsiteDraft as setWebsiteDraftAction } from 'state/concepts/website/actions'
import { websiteDraftSelector } from 'state/concepts/website/selectors'
import DiscardFormButton from 'views/shared/DiscardFormButton'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import DiscardBackButton from '../DiscardBackButton'
import Toggle from './Toggle'

const EnableSections = ({ onClose, handleSetCurrentStep }) => {
  const website = useSelector(websiteDraftSelector)
  const websiteSetting = propOr({}, 'websiteSetting', website)
  const { experts, services, locations, reviews, contacts } = websiteSetting

  const updateWebsiteDraft = useDispatchAction(setWebsiteDraftAction)
  const isReviewsFeatureEnabled = useWorkspaceFeature(FEATURE_KEYS.reviews)

  const handleSubmit = values => {
    updateWebsiteDraft({ ...website, websiteSetting: { ...website.websiteSetting, ...values } })
    onClose()
  }

  return (
    <Formik initialValues={{ experts, services, locations, reviews, contacts }} onSubmit={handleSubmit}>
      <>
        <div data-cy="edit-sections-header" className="ws-header__editor-header">
          <DiscardFormButton
            text={{ id: 'websitePreview.enableDisableSections' }}
            component={DiscardBackButton}
            onDiscard={handleSetCurrentStep(EDIT_WEBSITE_PREVIEW_STEPS.main)}
          />
          <DiscardFormButton
            dataCy="close-sidebar-btn"
            isIcon
            icon="cross"
            iconSize={10}
            iconClassName="mr-4"
            onDiscard={onClose}
          />
        </div>
        <div className="ws-header__editor-body ws-header__editor-body--no-indent">
          {getWebsiteSettingsToggleFields({ isReviewsFeatureEnabled }).map(field => (
            <Toggle key={field} field={field} />
          ))}
        </div>
        <div className="ws-header__editor-footer">
          <DiscardFormButton text={{ id: 'shared.close' }} size="small" className="mr-16" onDiscard={onClose} />
          <SubmitFormButton text={{ id: 'shared.apply' }} size="small" />
        </div>
      </>
    </Formik>
  )
}

EnableSections.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSetCurrentStep: PropTypes.func.isRequired,
}

export default EnableSections
