import fetchFormCategories from './fetchFormCategories'
import filterFormCategories from './filterFormCategories'
import createFormCategory from './createFormCategory'
import updateFormCategory from './updateFormCategory'
import updateFormCategoryStatus from './updateFormCategoryStatus'
import searchFormCategories from './searchFormCategories'
import deleteFormCategory from './deleteFormCategory'

export default [
  fetchFormCategories,
  filterFormCategories,
  createFormCategory,
  updateFormCategory,
  updateFormCategoryStatus,
  searchFormCategories,
  deleteFormCategory,
]
