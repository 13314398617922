const stripePosFeature = {
  'stripePosFeature.alert.text':
    'To enable Stripe Point of Sale, you need to start accepting payments through ExpertBox.',
  'stripePosFeature.alert.action': 'Accept payments',
  'stripePosFeature.subtitle': 'Payments',
  'stripePosFeature.description': 'Accept in-person payments using Stripe card reader.',
  'stripePosFeature.disableStripePos': 'Disable Stripe Point of Sale?',
  'stripePosFeature.disableStripePos.bodyText':
    'You won’t be able to accept in-person payments with Stripe card readers.',
  'stripePosFeature.manageSettings': 'Set up payments',
  'stripePosFeature.disable.warning':
    '{count, plural, ' +
    '=0 {You won’t be accepting payments anymore, because no other payment method is connected.} ' +
    'one {You’re still accepting {methodOne}.}' +
    '=2 {You’re still accepting {methodOne}, and {methodTwo}.}' +
    'other {You’re still accepting {methodOne}, {methodTwo}, and {methodThree}.}}',
  'stripePosFeature.stripeOnlinePayments': 'Stripe online payments',
  'stripePosFeature.manualPayments': 'Manual payments',
  'stripePosFeature.overview': 'Stripe Point of Sale overview',
  'stripePosFeature.offer': 'Connect a Stripe card reader to offer seamless in-person payments to your _(clients).',
  'stripePosFeature.offer.stepOne':
    'Give your _(clients) more flexibility by accepting in-person card payments, making it easy to pay for your _(services).',
  'stripePosFeature.offer.stepTwo':
    'Get a complete overview of all your payments in one place, both online, offline, and in-person.',
  'stripePosFeature.offer.stepThree': 'Automatically send receipts, invoices, and credit notes to _(clients) online.',
  'stripePosFeature.pleaseNote':
    'Please note that Stripe Point of Sale is currently available in the following countries: Australia, Canada, France, Germany, Ireland, Netherlands, Singapore, United Kingdom, United States (see the <link>full list</link>).',
  'stripePosFeature.helpCenter':
    'To learn more, read or help center article on <link>connecting a Stripe card reader</link>.',
}

export default stripePosFeature
