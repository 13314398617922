import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import { isCancelled, isCompleted, isScheduled, isPending, isRequested, statusBadge, isReserved } from 'utils/bookings'

const StatusBadge = ({ booking, className }) => (
  <p
    data-cy="booking-status"
    className={classNames('main-badge', className, {
      'main-badge--cancelled': isCancelled(booking),
      'main-badge--completed': isCompleted(booking),
      'main-badge--scheduled': isScheduled(booking),
      'main-badge--pending': isPending(booking) || isReserved(booking),
      'main-badge--requested': isRequested(booking),
    })}
  >
    <FormattedMessage id={`bookings.status.${statusBadge(booking)}`} />
  </p>
)

StatusBadge.defaultProps = {
  className: '',
}

StatusBadge.propTypes = {
  booking: PropTypes.shape().isRequired,
  className: PropTypes.string,
}

export default StatusBadge
