import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_SUBSCRIPTION_TRANSACTIONS } from 'state/concepts/billing/types'
import { setSubscriptionTransactions } from 'state/concepts/billing/actions'
import { paginationSelector } from 'state/concepts/billing/selectors'
import { fetchSubscriptionTransactionsEndpoint } from 'state/concepts/billing/endpoints'

const fetchSubscriptionTransactionsOperation = createLogic({
  type: FETCH_SUBSCRIPTION_TRANSACTIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchSubscriptionTransactionsEndpoint
    const state = getState()
    const params = {
      include: 'billing-account',
      page: paginationSelector(state),
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setSubscriptionTransactions(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchSubscriptionTransactionsOperation
