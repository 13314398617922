import endpoint from 'utils/endpoint'
import {
  widgetRoute,
  widgetsServicesRoute,
  widgetsSendInstructionRoute,
  servicesWidgetRoute,
  widgetsValidationsSettingsRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const fetchWidgetEndpoint = id => endpoint(types.FETCH_WIDGET, 'GET', widgetRoute(id))

export const sendInstructionsEndpoint = id => endpoint(types.SEND_INSTRUCTIONS, 'POST', widgetsSendInstructionRoute(id))

export const changeWidgetStatusEndpoint = id => endpoint(types.TOGGLE_WIDGET_STATUS, 'PUT', widgetRoute(id))

export const updateWidgetEndpoint = id => endpoint(types.UPDATE_WIDGET, 'PUT', widgetRoute(id))

export const deleteWidgetEndpoint = id => endpoint(types.DELETE_WIDGET, 'DELETE', widgetRoute(id))

export const fetchWidgetServicesEndpoint = endpoint(types.FETCH_WIDGET_SERVICES, 'GET', servicesWidgetRoute)

export const updateWidgetServicesEndpoint = id =>
  endpoint(types.UPDATE_WIDGET_SERVICES, 'POST', widgetsServicesRoute(id))

export const validateWidgetSettingsEndpoint = endpoint(
  types.VALIDATE_WIDGET_SETTINGS,
  'POST',
  widgetsValidationsSettingsRoute,
)
