import PropTypes from 'prop-types'
import { Form, Field, FieldArray } from 'formik'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import { FIELD_TYPES_OPTIONS } from 'lib/constants/customField'
import { hasOptions, isUpdate } from 'utils/customField'
import SelectField from 'views/shared/SelectField'
import FormattedLinkifyMessage from 'views/shared/FormattedLinkifyMessage'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import InputField from 'views/shared/InputField'
import Alert from 'views/shared/Alert'
import Cross from 'views/shared/icons/Cross'
import FieldOptionFields from './FieldOptionFields'

const CustomFieldModalComponent = ({ status, isSubmitDisabled, onClose, actionType, values: { fieldType } }) => (
  <Modal className="main-modal main-modal--md modal__custom-field" onClose={onClose}>
    <Form>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="custom-field-modal-title">
            <FormattedMessage id={`clientFields.modal.title.${actionType}`} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <p className="mb-16" data-cy="custom-field-modal-body">
            <FormattedMessage id={`clientFields.modal.body.${actionType}`} />
          </p>
          {prop('base', status) && (
            <Alert className="auth__alert">
              <FormattedLinkifyMessage message={prop('base', status)} />
            </Alert>
          )}
          <Field
            name="name"
            component={InputField}
            id="name"
            type="text"
            icon="close"
            label={{ id: 'clientFields.modal.body.nameLabel' }}
          />
          <div>
            <div className="main-input__label">
              <FormattedMessage id="clientFields.fieldType" />
            </div>
            <Field
              name="fieldType"
              id="fieldType"
              selectClassName="main-input__field select-with-description"
              component={SelectField}
              options={FIELD_TYPES_OPTIONS}
              disabled={isUpdate(actionType)}
            />
          </div>
          {hasOptions(fieldType) && <FieldArray name="fieldOptions" component={FieldOptionFields} />}
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="mr-16"
              type="button"
              onClick={onClose}
              text={{ id: 'clientFields.modal.cancel' }}
              kind="flat"
              dataCy="cancel-add-custom-field"
            />
            <SubmitFormButton
              text={{ id: `clientFields.modal.${actionType}` }}
              disabled={isSubmitDisabled}
              dataCy="add-custom-field"
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

CustomFieldModalComponent.defaultProps = {
  status: undefined,
}

CustomFieldModalComponent.propTypes = {
  status: PropTypes.shape(),
  isSubmitDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  values: PropTypes.shape({
    fieldType: PropTypes.string.isRequired,
  }).isRequired,
}

export default CustomFieldModalComponent
