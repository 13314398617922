import { DateTime } from 'luxon'
import { pick, pluck } from 'ramda'

import { FREE_PRICE } from 'lib/constants/scheduleAppointment'
import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import { durationByStartAndEndTime } from 'utils/bookings'

const initialUpdateFormValues = ({
  userProfile: { id: userProfileId },
  service: { id: serviceId, custom, name, description },
  location,
  bookingSetting,
  price,
  startTime,
  endTime,
  invitedMembers,
  ...restProps
}) => {
  const dateTime = DateTime.fromISO(startTime)
  const duration = durationByStartAndEndTime(startTime, endTime)
  const emails = pluck('email', invitedMembers)

  return {
    serviceId: custom ? null : serviceId,
    userProfileId,
    locationId: location ? location.id : ONLINE_LOCATION_OPTION.value,
    serviceName: custom ? name : '',
    serviceDescription: custom ? description : '',
    price,
    isFreePrice: price === FREE_PRICE,
    date: dateTime,
    time: dateTime,
    duration,
    alreadyInvitedMembersEmails: emails,
    emails,
    ...pick(['paymentRequired'], bookingSetting),
    ...pick(['uniqCode', 'date', 'time', 'duration'], restProps),
  }
}

export default initialUpdateFormValues
