import { combineReducers } from '@reduxjs/toolkit'
import * as types from './types'

const email = (state = '', action) => {
  switch (action.type) {
    case types.SET_EMAIL:
      return action.email
    default:
      return state
  }
}

const password = (state = '', action) => {
  switch (action.type) {
    case types.SET_PASSWORD:
      return action.password
    default:
      return state
  }
}

const isLoggedOutDueToInactivity = (state = null, action) => {
  switch (action.type) {
    case types.SET_IS_LOGGED_OUT_DUE_TO_INACTIVITY:
      return action.isLoggedOutDueToInactivity
    default:
      return state
  }
}

const clientEmail = (state = null, action) => {
  switch (action.type) {
    case types.SET_CLIENT_EMAIL:
      return action.email
    default:
      return state
  }
}

const backupCodes = (state = null, action) => {
  switch (action.type) {
    case types.SET_BACKUP_CODES:
      return action.backupCodes
    default:
      return state
  }
}

const contactPortalName = (state = null, action) => {
  switch (action.type) {
    case types.SET_CONTACT_PORTAL_NAME:
      return action.name
    default:
      return state
  }
}

const sessionReducer = combineReducers({
  email,
  password,
  isLoggedOutDueToInactivity,
  clientEmail,
  backupCodes,
  contactPortalName,
})

export default sessionReducer
