import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { dataApiSuccess } from 'state/data/actions'
import { RECEIVE_NOTIFICATION } from 'state/concepts/notifications/types'
import { setNotifications, incUnreadNotificationsCount } from 'state/concepts/notifications/actions'
import { unreadNotificationsCountSelector } from 'state/concepts/notifications/selectors'

const receiveNotificationOperation = createLogic({
  type: RECEIVE_NOTIFICATION,
  latest: true,

  async process({ getState, action: { notification } }, dispatch, done) {
    const state = getState()
    const response = normalize(notification)
    const unreadCount = unreadNotificationsCountSelector(state)

    dispatch(dataApiSuccess({ response }))
    dispatch(setNotifications([notification.data.id]))
    dispatch(incUnreadNotificationsCount(unreadCount))

    done()
  },
})

export default receiveNotificationOperation
