import PropTypes from 'prop-types'

import Avatar from 'views/shared/Avatar'
import ProfileIdentity from 'views/shared/ProfileIdentity'

const OptionContent = ({ client }) => (
  <div className="main-dropdown__user" data-cy="client-profile-select">
    <Avatar sizeClassName="main-userpic--sm" imageSize="small" profile={client} />
    <div className="main-dropdown__user-info">
      <div className="d-flex mb-0">
        <span className="max-w-220 text-ellipsis">
          <ProfileIdentity isClient user={client} />
        </span>
      </div>
      <p className="text-caption in-gray-700 mb-0">{client.email}</p>
    </div>
  </div>
)

OptionContent.propTypes = {
  client: PropTypes.shape().isRequired,
}

export default OptionContent
