import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { CALENDAR_SETTINGS_OPTIONS } from 'lib/constants/bookings'
import { updateCalendarViewSetting } from 'state/concepts/employee/actions'
import { currentCalendarViewSettingSelector } from 'state/concepts/session/selectors'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const CalendarSettingsModal = ({ onClose }) => {
  const calendarViewSetting = useSelector(currentCalendarViewSettingSelector)
  const onSubmit = useDispatchAction(updateCalendarViewSetting)
  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <Formik initialValues={{ view: calendarViewSetting }} onSubmit={onSubmit}>
        <div className="main-modal__container">
          <div className="main-modal__header">
            <h2 className="main-modal__title">
              <FormattedMessage id="bookings.calendarSettings.modal.title" />
            </h2>
            <button type="button" onClick={onClose} className="main-modal__close">
              <Cross dataCy="cross-icon" />
            </button>
          </div>
          <div className="main-modal__body">
            <p>
              <FormattedMessage id="bookings.calendarSettings.modal.description" />
            </p>
            <div>
              <Field
                className="mb-16"
                name="view"
                id="view"
                component={RadioField}
                options={CALENDAR_SETTINGS_OPTIONS}
              />
            </div>
          </div>
          <div className="main-modal__footer">
            <div>
              <Button
                className="main-modal__footer-action"
                text={{ id: 'shared.cancel' }}
                onClick={onClose}
                kind="flat"
              />
              <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.saveChanges' }} />
            </div>
          </div>
        </div>
      </Formik>
    </Modal>
  )
}

CalendarSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CalendarSettingsModal
