import { createLogic } from 'redux-logic'
import fileDownload from 'js-file-download'

import requestErrorHandler from 'lib/requestErrorHandler'
import { CONTENT_DISPOSITION_FILENAME_REGEX } from 'lib/constants'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { DOWNLOAD_AGREEMENT } from 'state/concepts/agreement/types'
import { downloadAgreementEndpoint } from 'state/concepts/agreement/endpoints'

const downloadAgreementOperation = createLogic({
  type: DOWNLOAD_AGREEMENT,
  latest: false,

  async process({ httpClient, action: { submissionId } }, dispatch, done) {
    const { endpoint, url } = downloadAgreementEndpoint(submissionId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data, headers } = await httpClient.get(url, { responseType: 'blob' })
      const filename = headers['content-disposition'].match(CONTENT_DISPOSITION_FILENAME_REGEX)[1]

      fileDownload(data, filename)

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default downloadAgreementOperation
