import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchIntakeForms } from 'state/concepts/forms/actions'
import { DUPLICATE_FORM } from 'state/concepts/intakeForm/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { duplicateFormEndpoint } from '../endpoints'

const duplicateFormOperation = createLogic({
  type: DUPLICATE_FORM,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = duplicateFormEndpoint(id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(fetchIntakeForms())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.intakeFormCopied',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default duplicateFormOperation
