import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { SET_CHATS, ADD_CHATS, REMOVE_CHAT, RESET_CHATS } from './types'

const chatIds = (state = [], action) => {
  switch (action.type) {
    case SET_CHATS:
      return action.chatIds
    case ADD_CHATS:
      return uniq([...action.chatIds, ...state])
    case REMOVE_CHAT:
      return without([action.chatId], state)
    case RESET_CHATS:
      return []
    default:
      return state
  }
}

export default combineReducers({
  chatIds,
})
