/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import InputComponent from './component'

class Input extends React.Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
  }

  static defaultProps = {
    onFocus: undefined,
    onBlur: undefined,
    value: '',
  }

  inputRef = React.createRef()

  state = {
    isFocused: false,
  }

  get value() {
    return this.inputRef.current?.value
  }

  setValue = value => {
    this.inputRef.current.value = value
  }

  clear = () => {
    this.inputRef.current.value = ''
  }

  handleFocus = () => {
    const { onFocus } = this.props

    this.setState({ isFocused: true })
    onFocus?.()
  }

  handleBlur = () => {
    const { onBlur } = this.props

    this.setState({ isFocused: false })
    onBlur?.()
  }

  render = () => (
    <InputComponent
      {...this.props}
      {...this.state}
      handleFocus={this.handleFocus}
      handleBlur={this.handleBlur}
      clear={this.clear}
      ref={this.inputRef}
      value={this.value}
      defaultValue={this.props.value}
    />
  )
}

export { Input as InputContainer }
export default injectIntl(Input, { forwardRef: true })
