import PropTypes from 'prop-types'
import Modal from 'views/shared/Modal'
import { FormattedMessage } from 'react-intl'

import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const EmployeesRemoveInvitationModalComponent = ({ onClose, onSubmit, employee, isLoading }) => (
  <Modal className="main-modal" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employeesRemoveInvitationModal.removeInvitation" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p className="mb-4">
          <FormattedMessage id="employeesRemoveInvitationModal.wantToRemove" />
        </p>
        <p className="font-600 mb-20">{employee.email}?</p>
      </div>
      <div className="main-modal__footer">
        <div>
          <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
          <Button
            className="main-modal__footer-action"
            type="submit"
            text={{ id: 'employeesActions.remove' }}
            onClick={onSubmit}
            disabled={isLoading}
            kind="danger"
          />
        </div>
      </div>
    </div>
  </Modal>
)

EmployeesRemoveInvitationModalComponent.defaultProps = {
  isLoading: undefined,
}

EmployeesRemoveInvitationModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default EmployeesRemoveInvitationModalComponent
