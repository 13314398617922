import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import isPageOverflow from 'utils/isPageOverflow'
import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import {
  setServiceCategories,
  setBlankState,
  setCurrentPage,
  searchServiceCategories,
} from 'state/concepts/serviceCategories/actions'
import { SEARCH_SERVICE_CATEGORIES } from 'state/concepts/serviceCategories/types'
import {
  paginationSelector,
  sortParamsSelector,
  appliedFiltersSelector,
} from 'state/concepts/serviceCategories/selectors'
import { getServiceCategoriesEndpoint } from '../endpoints'

const searchServiceCategoriesOperation = createLogic({
  type: SEARCH_SERVICE_CATEGORIES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = getServiceCategoriesEndpoint
    const state = getState()
    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: appliedFiltersSelector(state),
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setServiceCategories(data.data.map(item => item.id)))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(1))
        dispatch(searchServiceCategories())
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default searchServiceCategoriesOperation
