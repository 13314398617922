import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import formattedNumber from 'utils/formattedNumber'
import { handleInputEvent } from 'utils/inputHelpers'
import CurrencyFieldComponent from './component'

class CurrencyField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    intl: PropTypes.shape().isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: () => {},
  }

  formatter = value => {
    if (!value || Number(value) === 0) {
      return ''
    }

    let number = formattedNumber(value)
    const pos = number.indexOf('.')

    if (pos !== -1) {
      const { length } = String(number).split('.')[1]

      if (pos === 0) {
        number = '0.'
      }
      if (length > 2) {
        number = Number(number).toFixed(2)
      }
    }

    return `$${number}`
  }

  parser = value => value.replace(/\$/g, '')

  handleChange = value => {
    const { field, onChange } = this.props

    onChange(value, field.value)
    handleInputEvent(field, field.onChange)(value)
  }

  render = () => (
    <CurrencyFieldComponent
      {...this.props}
      formatter={this.formatter}
      parser={this.parser}
      onChange={this.handleChange}
    />
  )
}

export { CurrencyField as CurrencyFieldContainer }
export default injectIntl(CurrencyField)
