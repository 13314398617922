import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop, omit } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { REVIEW_STATUSES } from 'lib/constants/reviews'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_REVIEWS } from 'state/concepts/reviews/types'
import { paginationSelector, sortParamsSelector, filtersSelector } from 'state/concepts/reviews/selectors'
import { fetchReviewsEndpoint } from 'state/concepts/reviews/endpoints'
import { setReviews, setBlankState } from 'state/concepts/reviews/actions'

const fetchReviewsOperation = createLogic({
  type: FETCH_REVIEWS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { endpoint, url } = fetchReviewsEndpoint
    const { employees, services, name, statuses, ratings, dateRange } = filtersSelector(state)

    const filterStatuses = statuses === REVIEW_STATUSES.published ? [statuses] : []
    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: {
        name,
        user_profile_ids: employees,
        service_ids: services,
        ratings,
        start_date: dateRange[0],
        end_date: dateRange[1],
        statuses: filterStatuses,
      },
      include: ['service', 'user-profile', 'client-profile', 'booking', 'reviewed-by.contact-client-profile'],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const filterBlankState = omit(['statuses'], params.filter)

      dispatch(setBlankState(isBlankState({ params: filterBlankState, data: data.data })))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setReviews(map(prop('id'), data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchReviewsOperation
