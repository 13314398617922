import createIntakeForm from './createIntakeForm'
import deleteIntakeForm from './deleteIntakeForm'
import downloadForm from './downloadForm'
import duplicateForm from './duplicateForm'
import updateIntakeForm from './updateIntakeForm'
import updateIntakeFormActivation from './updateIntakeFormActivation'
import validateFormDetails from './validateFormDetails'
import resendForm from './resendForm'

export default [
  createIntakeForm,
  updateIntakeForm,
  deleteIntakeForm,
  updateIntakeFormActivation,
  validateFormDetails,
  duplicateForm,
  downloadForm,
  resendForm,
]
