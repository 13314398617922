import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useFormikContext } from 'formik'
import { values } from 'ramda'

import { WEBSITE_BACKGROUND_IMAGE_TYPES } from 'lib/constants/website'
import BackgroundImage from './BackgroundImage'

const BackgroundImageField = ({ name, label }) => {
  const { values: formValues, setFieldValue } = useFormikContext()
  const value = formValues[name]
  const onSelectionChange = type => setFieldValue(name, type)

  return (
    <div className="ws-header__bg-preview" data-cy="header-background-field">
      <p className="font-600 mb-24" data-cy="background-field-title">
        <FormattedMessage {...label} />
      </p>
      <div className="d-flex justify-content-between">
        {values(WEBSITE_BACKGROUND_IMAGE_TYPES).map(type => (
          <BackgroundImage key={type} type={type} value={value} onSelect={onSelectionChange} />
        ))}
      </div>
    </div>
  )
}

BackgroundImageField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.shape().isRequired,
}

export default BackgroundImageField
