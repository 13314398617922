const namespace = 'clients'

export const FETCH_CLIENTS = `${namespace}/FETCH_CLIENTS`
export const SET_CLIENTS = `${namespace}/SET_CLIENTS`
export const REMOVE_CLIENTS = `${namespace}/REMOVE_CLIENTS`
export const SET_CLIENTS_PAGE = `${namespace}/SET_CLIENTS_PAGE`
export const SET_CLIENTS_SORT_ORDER = `${namespace}/SET_CLIENTS_SORT_ORDER`
export const SET_CLIENTS_FILTER = `${namespace}/SET_CLIENTS_FILTER`
export const SET_CLIENTS_FILTER_PARAMS = `${namespace}/SET_CLIENTS_FILTER_PARAMS`
export const SET_CLIENTS_BLANK_STATE = `${namespace}/SET_CLIENTS_BLANK_STATE`
export const INCREMENT_TOTAL_COUNT = `${namespace}/INCREMENT_TOTAL_COUNT`
export const DECREMENT_TOTAL_COUNT = `${namespace}/DECREMENT_TOTAL_COUNT`
export const SET_TOTAL_COUNT = `${namespace}/SET_TOTAL_COUNT`
