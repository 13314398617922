import { createSelector } from '@reduxjs/toolkit'
import { path, pathOr, prop } from 'ramda'
import build from 'redux-object'

import { currentEmployeeSelector, currentWorkspaceStripePaymentSelector } from 'state/concepts/session/selectors'
import { workspaceFeaturesSelector } from 'state/concepts/workspaceFeatures/selectors'
import { dataSelector, endpointMetaSelector } from 'state/data/selectors'
import employeePermissions from 'utils/employeePermissions/permissions'
import isPresent from 'utils/isPresent'
import { storageFilesSelector } from 'state/concepts/storage/selectors'
import { generateBackupCodesEndpoint } from './endpoints'

export const employeeInvitationIdSelector = path(['employee', 'employeeInvitationId'])
export const employeeIdSelector = path(['employee', 'employeeId'])
export const remindWorkspacesSelector = path(['employee', 'remindWorkspaces'])

export const employeeInvitationSelector = createSelector([employeeInvitationIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'userInvitation', id) : null,
)

export const employeeSelector = createSelector(employeeIdSelector, dataSelector, (id, data) =>
  id ? build(data, 'userProfile', id) : null,
)

export const employeeOnboardingStatusSelector = createSelector(currentEmployeeSelector, prop('onboardingStatus'))

export const employeeAvatarSelector = createSelector(currentEmployeeSelector, pathOr(null, ['avatarUrls', 'original']))

export const hasRemindWorkspacesSelector = createSelector(remindWorkspacesSelector, isPresent)

export const currentEmployeePermissionsSelector = createSelector(
  [currentEmployeeSelector, workspaceFeaturesSelector, currentWorkspaceStripePaymentSelector, storageFilesSelector],
  employeePermissions,
)

export const generateBackupCodesMetaSelector = state =>
  endpointMetaSelector(state, generateBackupCodesEndpoint.endpoint)
