export const DOCUMENT_TEMPLATE_STATUSES = {
  allStatuses: 'allStatuses',
  active: 'active',
  inactive: 'inactive',
}

export const DOCUMENT_TEMPLATE_DISCARD_ACTION = 'discard'

export const DOCUMENT_TEMPLATE_STATUS_FILTER_OPTIONS = [
  {
    key: DOCUMENT_TEMPLATE_STATUSES.allStatuses,
    value: DOCUMENT_TEMPLATE_STATUSES.allStatuses,
    label: { id: 'documentTemplates.status.all' },
  },
  {
    key: DOCUMENT_TEMPLATE_STATUSES.active,
    value: DOCUMENT_TEMPLATE_STATUSES.active,
    label: { id: 'documentTemplates.status.active' },
  },
  {
    key: DOCUMENT_TEMPLATE_STATUSES.inactive,
    value: DOCUMENT_TEMPLATE_STATUSES.inactive,
    label: { id: 'documentTemplates.status.inactive' },
  },
]

export const MAX_NAME_DISPLAY_LENGTH = 50
export const MAX_NAME_DISPLAY_LENGTH_WITH_BADGE = 36

export const MAX_NAME_INPUT_LENGTH = 240

export const DEFAULT_NAME = 'Untitled document template'
