import { createLogic } from 'redux-logic'

import isPresent from 'utils/isPresent'
import requestErrorHandler from 'lib/requestErrorHandler'
import { cancelFilesDownloadEndpoint } from '../endpoints'
import { cancelFilesDownloadSuccess, downloadBusinessFiles } from '../actions'
import { CANCEL_FILES_DOWNLOAD } from '../types'
import { pendingFilesDownloadSelector } from '../selectors'

const cancelFilesDownloadOperation = createLogic({
  type: CANCEL_FILES_DOWNLOAD,
  latest: true,

  async process({ httpClient, getState, action: { downloadId, onSuccess } }, dispatch, done) {
    const { url } = cancelFilesDownloadEndpoint(downloadId)
    const pendingFilesDownload = pendingFilesDownloadSelector(getState())

    try {
      await httpClient.post(url)

      if (onSuccess) {
        onSuccess()
      } else {
        dispatch(cancelFilesDownloadSuccess(downloadId))
        if (isPresent(pendingFilesDownload)) {
          dispatch(downloadBusinessFiles(pendingFilesDownload[0]))
        }
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }
    done()
  },
})

export default cancelFilesDownloadOperation
