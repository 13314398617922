import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setEtherpadDocumentId } from '../actions'
import { reviewEtherpadDocumentEndpoint } from '../endpoints'
import { REVIEW_ETHERPAD_DOCUMENT } from '../types'

const reviewEtherpadDocumentOperation = createLogic({
  type: REVIEW_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { documentId, workspace }, httpClient }, dispatch, done) {
    const { url, endpoint } = reviewEtherpadDocumentEndpoint(documentId)

    const customBaseUrl = workspace ? buildCustomBaseUrl(['workspaces', workspace]) : {}

    dispatch(dataApiRequest({ endpoint }))

    const params = {
      include: [
        'document-body',
        'document_permissions',
        'document-signatories.signature',
        'document-signatories.profileable',
        'current-signatory',
        'voided-by',
        'declined-by.profileable',
        'workspace-signature-setting',
        'shared-client-contacts.client-relationship-type',
        'shared-client-contacts.contact-client-profile',
        'workspace-signature-setting.signature-disclosure',
      ],
    }
    try {
      const { data } = await httpClient.get(url, { params, ...customBaseUrl })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setEtherpadDocumentId(documentId))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default reviewEtherpadDocumentOperation
