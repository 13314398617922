import { combineReducers } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { without } from 'ramda'

import {
  SET_BOOKING,
  SET_BOOKING_DATE,
  SET_BOOKING_TIMEZONE,
  SET_BOOKING_TIME_SLOTS,
  SET_PENDING_FORM_IDS,
  REMOVE_PENDING_FORM_IDS,
  SET_IN_CALL_CHAT_ID,
} from './types'

const bookingId = (state = '', action) => {
  switch (action.type) {
    case SET_BOOKING:
      return action.bookingId
    default:
      return state
  }
}

const inCallChatId = (state = '', action) => {
  switch (action.type) {
    case SET_IN_CALL_CHAT_ID:
      return action.id
    default:
      return state
  }
}

const date = (state = DateTime.now().toISO(), action) => {
  switch (action.type) {
    case SET_BOOKING_DATE:
      return action.date
    default:
      return state
  }
}

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_BOOKING_TIMEZONE:
      return action.timezone
    default:
      return state
  }
}

const timeSlots = (state = null, action) => {
  switch (action.type) {
    case SET_BOOKING_TIME_SLOTS:
      return action.timeSlots
    default:
      return state
  }
}

const pendingFormIds = (state = [], action) => {
  switch (action.type) {
    case SET_PENDING_FORM_IDS:
      return action.ids
    case REMOVE_PENDING_FORM_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

export default combineReducers({
  bookingId,
  date,
  timezone,
  timeSlots,
  pendingFormIds,
  inCallChatId,
})
