import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_SERVICE_CATEGORIES,
  SET_SERVICE_CATEGORIES_PAGE,
  SET_SERVICE_CATEGORIES_SORT_ORDER,
  SET_SERVICE_CATEGORIES_FILTER_PARAMS,
  SET_SERVICE_CATEGORIES_SELECTED,
  SET_SERVICE_CATEGORIES_BLANK_STATE,
  RESET_FILTER_PARAMS,
} from './types'

const serviceCategoriesIds = (state = [], action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES:
      return action.serviceCategoriesIds
    default:
      return state
  }
}

const selectedServiceCategories = (state = [], action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES_SELECTED:
      return action.serviceCategoriesIds
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' }
    default:
      return state
  }
}

const filtersInitialState = {
  name: '',
  status: 'all',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_SERVICE_CATEGORIES_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const serviceCategoriesReducer = combineReducers({
  serviceCategoriesIds,
  selectedServiceCategories,
  pagination,
  sort,
  filters,
  isBlankState,
})

export default serviceCategoriesReducer
