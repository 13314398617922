import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { RESEND_EMAIL_CONFIRMATION } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { resendEmailConfirmationEndpoint } from 'state/concepts/employee/endpoints'

const resendEmailConfirmationOperation = createLogic({
  type: RESEND_EMAIL_CONFIRMATION,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = resendEmailConfirmationEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.emailConfirmationResented' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default resendEmailConfirmationOperation
