const trialPeriodReminder = {
  'trialPeriodReminder.upgradeNow': 'Upgrade now',
  'trialPeriodReminder.sidebar.progressBarTitle.daysLeft':
    '<span>{daysLeft} {daysLeft, plural, one {day} other {days}}</span> left on your free trial',
  'trialPeriodReminder.sidebar.progressBarTitle.daysLeft.trialEndsToday': 'Your free trial ends today.',
  'trialPeriodReminder.header.progressBarTitle.daysLeft':
    '<span>{daysLeft} {daysLeft, plural, one {day} other {days}}</span> left on free trial. {link}',
  'trialPeriodReminder.header.progressBarTitle.daysLeft.trialEndsToday': 'Your free trial ends today. {link}',
}

export default trialPeriodReminder
