import copy from 'copy-to-clipboard'
import { Field } from 'formik'
import { QRCodeSVG } from 'qrcode.react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { getAppAuthUri } from 'state/concepts/employee/actions'
import { appAuthUriLoadingSelector, appAuthUriSelector } from 'state/concepts/session/selectors'
import { showNotification as showNotificationAction } from 'state/notifications/actions'
import spanWithClass from 'utils/locales/spanWithClass'
import Button from 'views/shared/Button'
import Input from 'views/shared/Input'
import InputField from 'views/shared/InputField'
import Spinner from 'views/shared/icons/Spinner'

const AppFields = () => {
  const fetchUri = useDispatchAction(getAppAuthUri)
  const showNotification = useDispatchAction(showNotificationAction, {
    messageObject: { id: 'notifications.copyToClipboard' },
  })

  const authUri = useSelector(appAuthUriSelector)
  const isLoading = useSelector(appAuthUriLoadingSelector)

  useMount(fetchUri)

  const secret = authUri ? new URL(authUri).searchParams.get('secret') : ''

  const handleCopy = () => {
    copy(secret)
    showNotification()
  }

  if (isLoading !== false) return <Spinner />

  return (
    <>
      <p className="mb-0 text-body mb-16">
        <FormattedMessage
          id="employeeTwoFactorSettings.setApp.step1"
          values={{
            span: spanWithClass('font-700'),
          }}
        />
      </p>
      <div className="authenticator-code d-flex align-items-center mb-24">
        <div className="authenticator-code__image-wrapper d-flex align-items-center justify-content-center p-16 wh-124-124 flex-shrink-0">
          <QRCodeSVG value={authUri} />
        </div>
        <div className="d-flex flex-column w-100 p-16">
          <p className="text-body">
            <FormattedMessage id="employeeTwoFactorSettings.setApp.step1.scan" />
          </p>
          <div className="d-flex">
            <Input value={secret} disabled className="w-100 mb-0" />
            <Button
              className="main-btn--icon-only in-blue-600 p-0 wh-48-48 ml-16 flex-shrink-0"
              kind="secondary"
              icon="copy"
              iconSize={18}
              onClick={handleCopy}
              dataCy="copy-button"
            />
          </div>
        </div>
      </div>
      <p className="text-body">
        <FormattedMessage
          id="employeeTwoFactorSettings.setApp.step2"
          values={{
            span: spanWithClass('font-700'),
          }}
        />
      </p>
      <Field
        id="otpCode"
        icon="alert"
        name="otpCode"
        component={InputField}
        label={{ id: 'employeeTwoFactorSettings.setApp.otpCode.label' }}
        className="mb-0"
      />
    </>
  )
}

export default AppFields
