import { createLogic } from 'redux-logic'

import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { SEND_DELETE_CONFIRMATION_CODE } from 'state/concepts/client/types'
import { clientsAccountDeletionConfirmationCodeRoute } from 'lib/apiRoutes'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const sendDeleteConfirmationCodeOperation = createLogic({
  type: SEND_DELETE_CONFIRMATION_CODE,
  latest: true,

  async process({ action: { values, form, onSuccess }, getState, httpClient }, dispatch, done) {
    try {
      const { uniqCode } = workspaceSelector(getState())
      const params = {
        email: values.email,
      }

      await httpClient.post(
        clientsAccountDeletionConfirmationCodeRoute,
        params,
        buildCustomBaseUrl(['workspaces', uniqCode]),
      )

      onSuccess?.()
    } catch (error) {
      if (form && isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }

    form?.setSubmitting(false)
    done()
  },
})

export default sendDeleteConfirmationCodeOperation
