const inviteMembers = {
  'inviteMembers.alreadyInvited': 'This person is already invited',
  'inviteMembers.alreadyEntered': 'You’ve already entered this email',
  'inviteMembers.emailsLimitCounter': '{maxItems} emails',
  'inviteMembers.title': 'Invite members',
  'inviteMembers.tooltip': 'You can only invite up to {maxItems} people.',
  'inviteMembers.modal.inviteByExpert':
    'You and your _(client) can invite up to {maxItems} people at no extra charge. Location details or video call link will be emailed to the invitees.',
  'inviteMembers.modal.inviteByClient':
    'You and your _(expert) can invite up to {maxItems} people at no extra charge. Location details or video call link will be emailed to the invitees.',
  'inviteMembers.modal.inputLabel': 'Add emails',
  'inviteMembers.modal.submitButtonText': 'Send invitation',
  'inviteMembers.invitationsWillBeSent':
    'Invitations will be sent once the _(client) accepts the _(appointment). You can invite up to {maxItems} people.',
}

export default inviteMembers
