import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { whereEq, pick } from 'ramda'
import debounce from 'lodash/debounce'

import { durationToSeconds } from 'utils/duration'
import { bookingStartTime } from 'utils/bookings'
import profileName from 'utils/profileName'
import { selectedEmployeesSelector } from 'state/concepts/company/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { checkTimeConflicts as checkTimeConflictsAction } from 'state/concepts/bookings/actions'
import TimeConflictsWarningComponent from './component'

class TimeConflictsWarning extends React.Component {
  static propTypes = {
    values: PropTypes.shape().isRequired,
    checkTimeConflicts: PropTypes.func.isRequired,
    currentEmployee: PropTypes.shape().isRequired,
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape()),
    bookingId: PropTypes.string,
  }

  static defaultProps = {
    bookingId: undefined,
    selectedEmployees: [],
  }

  state = {
    conflictDates: [],
    isAvailable: true,
  }

  get selectedEmployeeName() {
    const { selectedEmployees } = this.props

    return profileName(selectedEmployees[0])
  }

  get isCurrentExpert() {
    const {
      values: { userProfileId },
      currentEmployee: { id },
    } = this.props

    return userProfileId && id === userProfileId
  }

  get isRepeat() {
    const {
      values: { repeat, isSingle },
    } = this.props

    return repeat || isSingle === false
  }

  get warningLocationPart() {
    const {
      values: { locationId },
    } = this.props

    return locationId ? 'WithLocation' : ''
  }

  get warningExpertPart() {
    return this.isCurrentExpert ? 'ForYou' : 'ForExpert'
  }

  get availabilityConflictMessageId() {
    const { isAvailable } = this.state

    if (isAvailable || (!this.isRepeat && this.isCurrentExpert)) {
      return null
    }

    if (this.isRepeat) {
      return `scheduleAppointment.recurringNotAvailable${this.warningExpertPart}${this.warningLocationPart}`
    }

    return `scheduleAppointment.notAvailable${this.warningLocationPart}`
  }

  componentDidMount() {
    this.checkTimeConflicts()
  }

  componentDidUpdate = prevProps => {
    const { values } = this.props

    if (
      !whereEq({
        ...pick(
          [
            'date',
            'time',
            'duration',
            'userProfileId',
            'byWeekday',
            'ends',
            'repeatCount',
            'untilDate',
            'weeklyInterval',
            'repeat',
            'locationId',
          ],
          values,
        ),
      })(prevProps.values)
    ) {
      this.debouncedCheckTimeConflicts()
    }
  }

  setCheckStatus = ({ conflictDates, isAvailable }) => {
    this.setState({ conflictDates, isAvailable })
  }

  checkTimeConflicts = () => {
    const {
      values: {
        date,
        time,
        duration,
        userProfileId,
        byWeekday,
        ends,
        repeatCount,
        untilDate,
        weeklyInterval,
        repeat,
        locationId,
      },
      checkTimeConflicts,
      currentEmployee: { timezoneOffset },
      bookingId,
    } = this.props

    const durationSeconds = durationToSeconds(duration)
    if (!durationSeconds || !userProfileId) {
      return
    }

    const startTime = bookingStartTime(date, time, timezoneOffset)

    const filters = {
      startTime,
      duration: durationSeconds,
      bookingId,
      userProfileId,
      byWeekday,
      ends,
      repeatCount,
      untilDate,
      weeklyInterval,
      repeat,
      locationId,
    }

    checkTimeConflicts(filters, this.setCheckStatus, this.isRepeat)
  }

  debouncedCheckTimeConflicts = debounce(this.checkTimeConflicts, 300)

  render = () => (
    <TimeConflictsWarningComponent
      {...this.props}
      {...this.state}
      isRepeat={this.isRepeat}
      selectedEmployeeName={this.selectedEmployeeName}
      availabilityConflictMessageId={this.availabilityConflictMessageId}
    />
  )
}

const mapStateToProps = (state, { values: { userProfileId } }) => ({
  currentEmployee: currentEmployeeSelector(state),
  selectedEmployees: selectedEmployeesSelector(state, userProfileId),
})

const mapDispatchToProps = {
  checkTimeConflicts: checkTimeConflictsAction,
}

export { TimeConflictsWarning as TimeConflictsWarningContainer }
export default connect(mapStateToProps, mapDispatchToProps)(TimeConflictsWarning)
