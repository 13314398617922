import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Checked = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M384 704.002l554.667-554.667 85.332 85.333-640 639.998-383.999-383.996 85.333-85.332 298.666 298.664z" />
  </svg>
)

Checked.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Checked.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Checked
