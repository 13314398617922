import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'formik'

import { durationToFormatString } from 'utils/duration'
import { serviceSelector } from 'state/concepts/service/selectors'
import DurationField from 'views/shared/DurationField'

class Duration extends React.Component {
  static propTypes = {
    service: PropTypes.shape(),
    setFieldValue: PropTypes.func.isRequired,
  }

  static defaultProps = {
    service: null,
  }

  componentDidUpdate = prevProps => {
    const { service, setFieldValue } = this.props

    if (service && service.id !== prevProps.service?.id) {
      setFieldValue('duration', durationToFormatString(service.duration), false)
    }
  }

  render() {
    return (
      <Field
        className="mb-0"
        name="duration"
        component={DurationField}
        id="duration"
        label={{ id: 'bookings.duration' }}
        icon="alert"
        placeholder={{ id: 'placeholders.duration' }}
        step={15}
      />
    )
  }
}

const mapStateToProps = (state, { serviceId }) => ({
  service: serviceSelector(state, serviceId),
})

export { Duration as DurationContainer }
export default connect(mapStateToProps)(Duration)
