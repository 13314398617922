import getCompany from './getCompany'
import updateCompanyProfile from './updateCompanyProfile'
import changeCompanyOwnership from './changeCompanyOwnership'
import searchEmployees from './searchEmployees'
import removeCompanyProfile from './removeCompanyProfile'
import updateWorkspaceName from './updateWorkspaceName'
import updateWorkspaceTwoFactorSettings from './updateWorkspaceTwoFactorSettings'

export default [
  getCompany,
  updateCompanyProfile,
  removeCompanyProfile,
  changeCompanyOwnership,
  searchEmployees,
  updateWorkspaceName,
  updateWorkspaceTwoFactorSettings,
]
