import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_WIDGET_SERVICES } from 'state/concepts/widget/types'
import { fetchWidgetServicesEndpoint } from 'state/concepts/widget/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setWidgetServices, setBlankState } from '../actions'

const fetchWidgetServicesOperation = createLogic({
  type: FETCH_WIDGET_SERVICES,
  latest: true,

  async process({ httpClient, action: { widgetId } }, dispatch, done) {
    const { url, endpoint } = fetchWidgetServicesEndpoint

    dispatch(dataApiRequest({ endpoint }))

    const params = {
      filter: {
        widget_id: widgetId,
      },
      include: ['service-category', 'user-profiles'],
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setWidgetServices(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchWidgetServicesOperation
