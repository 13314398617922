import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_PAYMENT_SETTINGS } from 'state/concepts/paymentSettings/types'
import { fetchPaymentSettingsEndpoint } from '../endpoints'

const fetchPaymentSettingsOperation = createLogic({
  type: FETCH_PAYMENT_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchPaymentSettingsEndpoint

    const params = {
      include: 'stripe-account,manual-payment,paypal-account',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchPaymentSettingsOperation
