import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import { SELECT_MODES } from 'lib/constants/shared'
import { isChatDirect, isChatGroup } from 'utils/chat'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import Checked from 'views/shared/icons/Checked'
import DropdownChatMemberField from './DropdownChatMemberField'

const InviteMembersModalComponent = ({ handleSubmit, onClose, showSkipButton, chat, chatType }) => (
  <Modal className="main-modal main-modal--invite-chat-members main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title" data-cy="invite-chat-members-modal">
          <FormattedMessage
            id="chat.inviteChatMembers.modal.title"
            values={{ count: isChatGroup({ chatType }) ? 2 : 1 }}
          />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>

      <div className="main-modal__body">
        <Field
          className={classNames('main-input--invite-dropdown', {
            'dropdown-select': isChatDirect({ chatType }),
            'dropdown-multiselect': isChatGroup({ chatType }),
          })}
          selectClassName={classNames('main-input__field-tags', {
            'dropdown-tags--blue': isChatGroup({ chatType }),
          })}
          name="chatUserIds"
          component={DropdownChatMemberField}
          removeIconSize={12}
          id="chatUserIds"
          icon="alert"
          placeholder={{ id: 'chat.inviteChatMembers.modal.placeholder' }}
          mode={isChatGroup({ chatType }) ? SELECT_MODES.multiple : SELECT_MODES.default}
          maxTagCount={isChatGroup({ chatType }) && 12}
          menuItemSelectedIcon={<Checked className="in-green-500" />}
        />
      </div>

      <div className="main-modal__footer">
        <Button
          className="main-modal__footer-action"
          text={{ id: `shared.${showSkipButton ? 'skip' : 'cancel'}` }}
          kind="flat"
          onClick={onClose}
          dataCy="skip-button"
        />
        <SubmitFormButton
          className="main-modal__footer-action"
          onClick={handleSubmit}
          text={{
            id: `chat.inviteChatMembers.modal.${chat || isChatGroup({ chatType }) ? 'selectMembers' : 'create'}`,
          }}
        />
      </div>
    </div>
  </Modal>
)

InviteMembersModalComponent.defaultProps = {
  showSkipButton: false,
  chat: null,
}

InviteMembersModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showSkipButton: PropTypes.bool,
  chat: PropTypes.shape(),
  chatType: PropTypes.string.isRequired,
}

export default InviteMembersModalComponent
