import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { Waypoint } from 'react-waypoint'

import { fetchAttachments as fetchAttachmentsAction } from 'state/concepts/chat/actions'
import {
  attachmentsLoadingSelector,
  fetchAttachmentsMetaSelector,
  nextAttachmentsCursorSelector,
} from 'state/concepts/chat/selectors'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import Spinner from 'views/shared/icons/Spinner'

const Loader = ({ chat: { id }, filter }) => {
  const fetchAttachments = useDispatchAction(fetchAttachmentsAction)
  const isLoading = useSelector(state => attachmentsLoadingSelector(state, id))
  const meta = useSelector(state => fetchAttachmentsMetaSelector(state, id))
  const nextCursor = useSelector(state => nextAttachmentsCursorSelector(state, id))
  const onLoadMore = () => prop('hasMore', meta) && fetchAttachments(id, { nextCursor }, filter)

  return isLoading !== false ? <Spinner size={24} /> : <Waypoint onEnter={onLoadMore} bottomOffset="-100px" />
}

Loader.defaultProps = {
  filter: undefined,
}

Loader.propTypes = {
  chat: PropTypes.shape().isRequired,
  filter: PropTypes.shape(),
}

export default Loader
