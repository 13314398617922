import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const getClientBooking = bookingCode => ({
  type: types.GET_CLIENT_BOOKING,
  bookingCode,
})

export const getEmployeeBooking = bookingId => ({
  type: types.GET_EMPLOYEE_BOOKING,
  bookingId,
})

export const getBooking = bookingCode => ({
  type: types.GET_BOOKING,
  bookingCode,
})

export const getClientServiceAvailability = filter => ({
  type: types.GET_CLIENT_BOOKING_AVAILABILITY,
  filter,
})

export const setBooking = bookingId => ({
  type: types.SET_BOOKING,
  bookingId,
})

export const setBookingDate = date => ({
  type: types.SET_BOOKING_DATE,
  date,
})

export const setBookingTimeSlots = timeSlots => ({
  type: types.SET_BOOKING_TIME_SLOTS,
  timeSlots,
})

export const clientRescheduleBooking = makeFormSubmitAction(types.CLIENT_RESCHEDULE_BOOKING)

export const removeVideoRecord = (videoRecordId, bookingId) => ({
  type: types.REMOVE_VIDEO_RECORD,
  videoRecordId,
  bookingId,
})

export const removeChatHistory = (bookingUniqCode, videoConferenceId, onSuccess) => ({
  type: types.REMOVE_CHAT_HISTORY,
  bookingUniqCode,
  videoConferenceId,
  onSuccess,
})

export const setPendingFormIds = ids => ({
  type: types.SET_PENDING_FORM_IDS,
  ids,
})

export const removePendingFormIds = ids => ({
  type: types.REMOVE_PENDING_FORM_IDS,
  ids,
})

export const scheduleBooking = makeFormSubmitAction(types.SCHEDULE_BOOKING)
export const updatePendingBooking = makeFormSubmitAction(types.UPDATE_PENDING_BOOKING)

export const acceptBooking = uniqCode => ({
  type: types.ACCEPT_BOOKING,
  uniqCode,
})

export const declineBooking = (uniqCode, removeWaitlistRequest = false) => ({
  type: types.DECLINE_BOOKING,
  uniqCode,
  removeWaitlistRequest,
})

export const declineAllRecurringBookings = bookingUniqCode => ({
  type: types.DECLINE_ALL_RECURRING_BOOKINGS,
  bookingUniqCode,
})

export const declineRecurringBookings = makeFormSubmitAction(types.DECLINE_RECURRING_BOOKINGS)

export const rescheduleRecurringBooking = makeFormSubmitAction(types.RESCHEDULE_RECURRING_BOOKINGS)

export const receiveAccountFormData = data => ({
  type: types.RECEIVE_ACCOUNT_FORM_DATA,
  data,
})

export const fetchInCallChat = id => ({
  type: types.FETCH_IN_CALL_CHAT,
  id,
})

export const setInCallChatId = id => ({
  type: types.SET_IN_CALL_CHAT_ID,
  id,
})
