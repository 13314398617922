import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { ALERT_KINDS } from 'lib/constants'
import { DEFAULT_NAME } from 'lib/constants/documentTemplates'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsDocumentTemplatesRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import isPresent from 'utils/isPresent'
import { isErrorStatusNotFound } from 'utils/getErrorStatus'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { renameDocumentTemplateEndpoint } from '../endpoints'
import { RENAME_DOCUMENT_TEMPLATE } from '../types'

const renameDocumentTemplateOperation = createLogic({
  type: RENAME_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ action: { values, form, onSuccess }, httpClient, getState }, dispatch, done) {
    const workspaceCode = currentWorkspaceCodeSelector(getState())
    const { name, documentTemplateId } = values
    const { url, endpoint } = renameDocumentTemplateEndpoint(documentTemplateId)
    const trimmedName = name?.trim()
    const params = { name: trimmedName }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(hideModal())

      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.documentTemplateWasRenamed',
          },
        }),
      )
      onSuccess?.()
      if (!isPresent(trimmedName)) form.setValues({ ...values, name: DEFAULT_NAME })
    } catch (error) {
      if (isErrorStatusNotFound(error)) {
        dispatch(hideModal())
        redirect({ href: companySettingsDocumentTemplatesRoute, workspace: workspaceCode })
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.documentTemplateWasDeletedByAnotherUser',
            },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form })
        form.setSubmitting(false)
      }
    }
    done()
  },
})

export default renameDocumentTemplateOperation
