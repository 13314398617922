import { includes, filter, map } from 'ramda'

class SubscriptionGuarantor {
  constructor(subscribe) {
    this.subscribe = subscribe
    this.pendingSubscriptions = []
  }

  guarantee(subscription) {
    if (!includes(subscription, this.pendingSubscriptions)) {
      this.pendingSubscriptions.push(subscription)
    }
    this.startGuaranteeing()
  }

  forget(identifier) {
    this.pendingSubscriptions = filter(
      subscription => subscription.identifier !== identifier,
      this.pendingSubscriptions,
    )
  }

  forgetAll() {
    this.stopGuaranteeing()
    this.pendingSubscriptions = []
  }

  startGuaranteeing() {
    this.stopGuaranteeing()
    this.retrySubscribing()
  }

  stopGuaranteeing() {
    clearTimeout(this.retryTimeout)
  }

  retrySubscribing() {
    this.retryTimeout = setTimeout(() => {
      map(subscription => this.subscribe(subscription), this.pendingSubscriptions)
    }, 500)
  }
}

export default SubscriptionGuarantor
