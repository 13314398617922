import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { DELETE_LOCATION_ACTION_VALUE, DELETE_LOCATION_ACTION_OPTIONS } from 'lib/constants/locations'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import DropdownLocationField from 'views/shared/DropdownLocationField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const DeleteLocationModal = ({
  isActive,
  onClose,
  values,
  totalCount,
  isSubmitting,
  handleSubmit,
  isLocationDeletionAndActivationDisabled,
}) => {
  const isDeletionDisabled = isActive && isLocationDeletionAndActivationDisabled

  return (
    <Modal className="main-modal main-modal--md">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 data-cy="locations-delete-modal-title" className="main-modal__title">
            <FormattedMessage id="locations.delete.modal.title" />
          </h2>
          <button onClick={onClose} type="button" className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          {isDeletionDisabled ? (
            <Alert type="error" className="align-items-center" message={{ id: 'locations.delete.modal.errorAlert' }} />
          ) : (
            <>
              <Alert type="info" message={{ id: 'locations.delete.modal.alert' }} />
              <p data-cy="locations-delete-modal-subtitle" className="mt-16 text-subtitle">
                <FormattedMessage id="locations.delete.modal.subtitle" />
              </p>
              <p data-cy="locations-delete-modal-text">
                <FormattedMessage id="locations.delete.modal.text" />
              </p>
              <div>
                <Field name="action" id="action" component={RadioField} options={DELETE_LOCATION_ACTION_OPTIONS} />
              </div>
              {values.action === DELETE_LOCATION_ACTION_VALUE.move && (
                <Field
                  className="mb-0 booking-tooltip__select-dropdown"
                  name="locationId"
                  id="locationId"
                  disabled={totalCount === 1}
                  excludeArray={[values.id]}
                  component={DropdownLocationField}
                  label={{ id: 'locations.delete.modal.newLocation' }}
                  placeholder={{ id: 'locations.delete.modal.selectLocation' }}
                />
              )}
            </>
          )}
        </div>
        <div className="main-modal__footer">
          <Button
            dataCy="locations-delete-modal-cancel-button"
            className="mr-0 main-btn--wide-padding"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onClose}
            disabled={isSubmitting}
          />
          {!isDeletionDisabled && (
            <SubmitFormButton
              dataCy="locations-delete-modal-submit-button"
              className="ml-16 min-w-0 main-btn--wide-padding "
              onClick={handleSubmit}
              allowPristine
              kind="danger"
              text={{ id: 'locations.delete.modal.deleteLocation' }}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

DeleteLocationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    action: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  isLocationDeletionAndActivationDisabled: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default DeleteLocationModal
