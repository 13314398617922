const chat = {
  'chats.title': 'Chats',
  'chats.directChat': 'Direct chat',
  'chats.groupChat': 'Group chat',
  'chats.selectChat': 'Please select a chat to start messaging',
  'chats.empty.createChat': 'Create a chat',
  'chats.empty.noChats.title': 'Create your first chat',
  'chats.empty.noChats.subtitle': 'Create a chat to communicate with _(clients) & other _(experts) in your workspace.',

  'chat.title': 'Chat',
  'chat.send': 'Send',
  'chat.save': 'Save',
  'chat.today': 'Today',
  'chat.yesterday': 'Yesterday',
  'chat.inputPlaceholder': 'Type your message here',
  'chat.editingMessage': 'Editing message',
  'chat.deleteMessage.modal.title': 'Delete message',
  'chat.removeMessage.modal.body': 'Are you sure you want to delete this message for everyone?',
  'chat.tag.edited': 'Edited',
  'chat.tag.sending': 'Sending...',
  'chat.tag.couldntSend': 'Couldn’t send.',
  'chat.tag.retry?': 'Retry?',
  'chat.fileUploadError': 'Error uploading file',
  'chat.fileSizeCantBeBigger': 'File size can’t be bigger than {size}.',
  'chat.fileExtensionIsForbidden': 'Looks like some file types are not supported.',
  'chat.fileMultipleErrors': 'Some of those files are not supported or are too big. The limit is {size}.',
  'chat.selectOtherFile': 'Select other file',
  'chat.newMessages': '{count} new {count, plural, one {message} other {messages}}',
  'chat.since': 'since {time} on {date}th',
  'chat.markAsRead': 'Mark as read',
  'chat.shareWithExpert.button': 'Share with _(Expert)',
  'chat.shareWithClient.button': 'Share with _(client)',
  'chat.actions.chatDetails': 'Chat details',
  'chat.actions.addMembers': 'Add chat members',
  'chat.actions.members': 'Members',
  'chat.actions.files': 'Files',
  'chat.actions.voiceMessages': 'Voice',
  'chat.actions.leaveChat': 'Leave chat',
  'chat.actions.deleteChat': 'Delete chat',

  'chat.inviteChatMembers.modal.title': 'Select chat {count, plural, one {member} other {members}}',
  'chat.inviteChatMembers.modal.placeholder': 'Search team members & _(clients) by name or email',
  'chat.inviteChatMembers.modal.noChatUsers': 'There are no _(experts) or _(clients) found on your request.',
  'chat.inviteChatMembers.modal.tryAnotherRequest': 'Please try another request',
  'chat.inviteChatMembers.modal.pleaseSelectMembers': 'Please select members to add.',
  'chat.inviteChatMembers.modal.create': 'Create chat',
  'chat.inviteChatMembers.modal.selectMembers': 'Select members',

  'chat.noChatUsers.modal.title': 'No members to add',
  'chat.noChatUsers.modal.team':
    'There are no _(experts) or _(clients) in your workspace yet. Invite your team members to join ExpertBox or wait until first _(client) books an _(appointment).',
  'chat.noChatUsers.modal.button.inviteTeamMembers': 'Invite team members',

  'chat.members.user': '_(Expert)',
  'chat.members.scheduler': '_(Scheduler)',
  'chat.members.workspace_owner': 'Owner',
  'chat.members.administrator': 'Admin',
  'chat.members.deactivated': 'Deactivated',
  'chat.members.deleted': 'Deleted _(client) account',
  'chat.members.remove': 'Remove',
  'chat.members.remove.confirmModal.title': 'Remove chat member',
  'chat.members.remove.confirmModal.body': 'Remove <b>{fullName}</b> from the chat?',
  'chat.members.alreadyInChat': 'Already in the chat',

  'chat.detailsModal.title.chatDetails': 'Chat details',
  'chat.detailsModal.label': 'Chat name',
  'chat.detailsModal.button.create': 'Create chat',
  'chat.detailsModal.button.update': 'Save changes',
  'chat.detailsModal.cancel': 'Cancel',
  'chat.detailsModal.skip': 'Skip',
  'chat.detailsModal.attachments.empty': 'There are no files for the moment',
  'chat.detailsModal.voiceMessages.empty': 'There are no voice messages for the moment',
  'chat.uploadLogo.remove': 'Do you want to remove chat logo? You can add it later.',

  'chat.leave.confirmModal.title': 'Leave chat',
  'chat.leave.confirmModal.body': 'Leave this chat? You will be able to back by invite.',

  'chat.delete.confirmModal.title': 'Delete chat',
  'chat.delete.confirmModal.body':
    'Delete chat? All messages and files in this chat will be deleted for all chat members forever.',

  'chat.disabled.title': 'Chat has been disabled',
  'chat.disabled.text': 'Access chat history and shared files here, whenever you need them',

  'chat.systemMessages.notImplemented': 'Not implemented',
  'chat.systemMessages.memberAdded': '{performer} added {member} to this chat',
  'chat.systemMessages.memberRemoved': '{performer} has removed {member} from this chat',
  'chat.systemMessages.memberLeftChat': '{member} left this chat',
  'chat.systemMessages.chatLogoUpdated': '{performer} updated chat image',
  'chat.systemMessages.chatLogoRemoved': '{performer} removed chat image',
  'chat.systemMessages.chatNameUpdated': '{performer} updated chat name to "{chatName}"',

  'chat.unavailableModal.noLongerAvailable': 'Chat is no longer available',
  'chat.unavailableModal.hasBeenDeleted': '<b>{chatName}</b> chat has been deleted',
  'chat.unavailableModal.youHaveBeenRemoved': 'You have been removed',
  'chat.unavailableModal.youHaveBeenRemovedByExpert':
    'You have been removed from the chat <b>"{chatName}"</b> by the _(expert)',

  'chat.upload.maxFilesUploadError': 'You can only upload {max} files at a time',
  'chat.dragAndDrop.title': 'Drop file here',
  'chat.dragAndDrop.text': 'to send it as attachment in a chat message',
  'chat.voiceMessage.name': 'ExpertBox Voice {date} at {time}',
  'chat.recorder.start': 'Record voice message',
  'chat.recorder.stop': 'Stop recording',
  'chat.microphonePermissionsError':
    'You haven’t allowed ExpertBox access to your microphone. Please check these settings in your browser.',
  'chat.player.playbackSpeed': 'Playback speed',
  'chat.player.playbackRate': '{rate}x',

  'chat.file.remove.modal.title': 'Delete this file?',
  'chat.file.remove.modal.body': 'Do you want to delete this file for everyone in the chat? This cannot be undone.',
  'chat.voice_message.remove.modal.title': 'Delete this voice message?',
  'chat.voice_message.remove.modal.body': 'Delete this message for everyone in this chat? This cannot be undone.',
  'chat.uploadFile': 'Upload file',
}

export default chat
