import { interFont } from 'utils/fonts'
import { blueGrayColors } from './appColors'

const appTheme = {
  token: {
    fontFamily: interFont.style.fontFamily,
    colorText: blueGrayColors['800'],
  },
}

export default appTheme
