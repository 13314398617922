import {
  difference,
  pluck,
  propEq,
  find,
  any,
  pathOr,
  prop,
  pipe,
  map,
  concat,
  splitEvery,
  splitAt,
  omit,
  sort,
  propOr,
  groupBy,
} from 'ramda'
import { DateTime } from 'luxon'

import DocumentCreate from 'views/shared/icons/DocumentCreate'
import {
  companyClientRoute,
  clientDocumentsRoute,
  etherpadDocumentsFailToSignRoute,
  etherpadDocumentsNoPermissionToSignRoute,
  etherpadDocumentsDeclineToSignRoute,
} from 'lib/routes'
import {
  ETHERPAD_DOCUMENT_ICONS,
  ETHERPAD_DOCUMENT_SIGNER_STATUSES,
  ETHERPAD_DOCUMENT_STATUSES,
  DEFAULT_NAME,
  ETHERPAD_DOCUMENT_SIGNER_TYPES,
  ETHERPAD_DOCUMENT_PERMISSIONS,
  ETHERPAD_DOCUMENT_VISIBLE_SHARED_PERMISSIONS,
} from 'lib/constants/etherpadDocuments'
import profileName from './profileName'
import isPresent from './isPresent'

export const etherpadDocumentSignFailRoute = ({ isEmployeeLoggedIn, workspaceCode, isClientLoggedIn, clientId }) => {
  if (isEmployeeLoggedIn) {
    return companyClientRoute(clientId)
  }

  return isClientLoggedIn ? clientDocumentsRoute(workspaceCode) : etherpadDocumentsFailToSignRoute
}

export const etherpadDocumentNoPermissionToSignRoute = ({ workspaceCode, isClientLoggedIn }) =>
  isClientLoggedIn ? clientDocumentsRoute(workspaceCode) : etherpadDocumentsNoPermissionToSignRoute

export const etherpadDocumentDeclineToSignSuccessRoute = ({ workspaceCode, isClientLoggedIn }) =>
  isClientLoggedIn ? clientDocumentsRoute(workspaceCode) : etherpadDocumentsDeclineToSignRoute

export const isDocumentErrorEqTo = (error, expectedTitle) => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error)

  return isPresent(errors) && any(propEq(expectedTitle, 'title'), errors)
}

export const signerFullName = ({ profileable, fullName }) => fullName || profileName(profileable)
export const signerEmail = ({ profileable, email }) => email || profileable?.email
export const isSignerYou = ({ documentSignatory, currentEmployee, currentClient, currentSignatory }) => {
  const { profileable, profileableType, id } = documentSignatory

  return (
    (profileableType === ETHERPAD_DOCUMENT_SIGNER_TYPES.userProfile && profileable?.id === currentEmployee?.id) ||
    (profileableType === ETHERPAD_DOCUMENT_SIGNER_TYPES.clientProfile && profileable?.id === currentClient?.id) ||
    (profileableType === null && id === currentSignatory?.id)
  )
}
export const isSignerExpert = ({ profileableType }) => profileableType === ETHERPAD_DOCUMENT_SIGNER_TYPES.userProfile
export const isSignerClient = ({ profileableType }) => profileableType === ETHERPAD_DOCUMENT_SIGNER_TYPES.clientProfile

export const statusIcon = status => ETHERPAD_DOCUMENT_ICONS[status] || DocumentCreate

export const isWaitingForSignature = status => status === ETHERPAD_DOCUMENT_STATUSES.waitingForSignature
export const isSigned = status => status === ETHERPAD_DOCUMENT_STATUSES.signed
export const isDeclined = status => status === ETHERPAD_DOCUMENT_STATUSES.declined
export const isVoided = status => status === ETHERPAD_DOCUMENT_STATUSES.voided

export const isSignerSigned = signerStatus => signerStatus === ETHERPAD_DOCUMENT_SIGNER_STATUSES.signed
export const isSignerSentForSignature = signerStatus =>
  signerStatus === ETHERPAD_DOCUMENT_SIGNER_STATUSES.sentForSignature
export const isSignerAwaiting = signerStatus => signerStatus === ETHERPAD_DOCUMENT_SIGNER_STATUSES.awaiting

export const isUntitledDocument = name => name === DEFAULT_NAME

export const isModified = ({ createdAt, updatedAt }) => isPresent(updatedAt) && createdAt !== updatedAt

export const getDocumentSignatory = (signatory, currentUserProfile, client) => {
  const isCurrentUserSigner = currentUserProfile.email === signatory.email
  const isCurrentClientSigner = client.email === signatory.email

  const defaultSignatoryFields = {
    email: signatory.email,
    priority: signatory.priority,
    id: signatory.id,
    _destroy: signatory.destroy,
  }

  if (isCurrentClientSigner) {
    return {
      ...defaultSignatoryFields,
      full_name: profileName(client),
      profileable_type: ETHERPAD_DOCUMENT_SIGNER_TYPES.clientProfile,
      profileable_id: client.id,
    }
  }

  if (isCurrentUserSigner) {
    return {
      ...defaultSignatoryFields,
      full_name: profileName(currentUserProfile),
      profileable_type: ETHERPAD_DOCUMENT_SIGNER_TYPES.userProfile,
      profileable_id: currentUserProfile.id,
    }
  }

  return {
    ...defaultSignatoryFields,
    full_name: signatory.fullName,
    profileable_type: signatory.profileableType,
    profileable_id: signatory.profileable?.id,
  }
}

export const updateSignatoriesValuesToParams = (values, currentUserProfile, client) => {
  const { signatoriesOrder, lockVersion, documentSignatories, initialDocumentSignatories } = values
  const getIds = pluck('id')

  const documentSignatoriesRemoved = difference(getIds(initialDocumentSignatories), getIds(documentSignatories)).map(
    id => ({
      id,
      destroy: true,
      ...find(propEq(id, 'id'))(initialDocumentSignatories),
    }),
  )
  const updatedDocumentSignatories = concat(documentSignatories, documentSignatoriesRemoved)

  return {
    signatories_order: signatoriesOrder,
    lock_version: lockVersion,
    document_signatories: updatedDocumentSignatories
      .filter(({ status }) => !isSignerSigned(status))
      .map(signatory => getDocumentSignatory(signatory, currentUserProfile, client)),
  }
}

export const sortDocumentSignatoriesBySignatureDate = documentSignatories =>
  documentSignatories
    .sort((firstSignatory, secondSignatory) => {
      if (!firstSignatory.signature?.createdAt && secondSignatory.signature?.createdAt) {
        return 1
      }
      if (!secondSignatory.signature?.createdAt && firstSignatory.signature?.createdAt) {
        return -1
      }
      if (!firstSignatory.signature?.createdAt && !secondSignatory.signature?.createdAt) {
        return 0
      }
      return (
        DateTime.fromISO(firstSignatory.signature.createdAt).toMillis() -
        DateTime.fromISO(secondSignatory.signature.createdAt).toMillis()
      )
    })
    .map(signatory => ({
      ...omit(['profileable', 'signature'], signatory),
      profileable: { id: signatory.profileable?.id },
      signature: signatory.signature && {
        name: signatory.signature.name,
        createdAt: signatory.signature.createdAt,
        id: signatory.signature.id,
        ipAddress: signatory.signature.ipAddress,
      },
    }))

const getClientContactPermission = ({ clientRelationshipType: { manageDocuments }, role }) => {
  if (
    role === ETHERPAD_DOCUMENT_PERMISSIONS.viewer ||
    (role === ETHERPAD_DOCUMENT_PERMISSIONS.commenter && manageDocuments)
  )
    return role

  return manageDocuments ? ETHERPAD_DOCUMENT_PERMISSIONS.editor : ETHERPAD_DOCUMENT_PERMISSIONS.viewer
}

export const etherpadDocumentShared = pipe(
  ({ documentPermissions, sharedClientProfiles, sharedClientContacts, status }) => {
    const role = isPresent(status) ? ETHERPAD_DOCUMENT_PERMISSIONS.viewer : prop('role', documentPermissions[0])
    const sharedClients = map(clientProfile => ({ ...clientProfile, role }), sharedClientProfiles)
    const sharedContacts = isPresent(sharedClientContacts)
      ? map(
          ({ contactClientProfile, clientRelationshipType }) => ({
            ...contactClientProfile,
            role: getClientContactPermission({ clientRelationshipType, role }),
          }),
          sharedClientContacts,
        )
      : []

    return concat(sharedClients, sharedContacts)
  },
  splitAt(ETHERPAD_DOCUMENT_VISIBLE_SHARED_PERMISSIONS),
  totalShared => {
    const visibleShared = splitEvery(1, totalShared[0])
    const otherShared = totalShared[1]

    return isPresent(otherShared) ? [...visibleShared, otherShared] : visibleShared
  },
)

export const isAlreadySignedByClient = ({ currentSignatory }) =>
  isSignerSigned(currentSignatory?.status) && !isSignerExpert(currentSignatory)

export const documentSignatoriesSortedByStatus = pipe(
  groupBy(prop('status')),
  map(sort(prop('priority'))),
  sortedByStatus =>
    [].concat(
      propOr([], 'signed', sortedByStatus),
      propOr([], 'sent_for_signature', sortedByStatus),
      propOr([], 'awaiting', sortedByStatus),
    ),
)

export const isSignatoryLooseAccess = document => {
  const { currentSignatory, documentSignatories } = document || {}

  if (!currentSignatory) {
    return false
  }

  const documentSignatory = find(propEq(currentSignatory?.id, 'id'), documentSignatories)

  return !documentSignatory || isSignerAwaiting(documentSignatory.status)
}
