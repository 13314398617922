import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchWaitlistRequests = (pageParams = {}, additionalInclude = []) => ({
  type: types.FETCH_WAITLIST_REQUESTS,
  pageParams,
  additionalInclude,
})

export const fetchWaitlistRequest = id => ({
  type: types.FETCH_WAITLIST_REQUEST,
  id,
})

export const addWaitlistRequestIds = ids => ({
  type: types.ADD_WAITLIST_REQUEST_IDS,
  ids,
})

export const resetWaitlistRequestIds = () => ({
  type: types.RESET_WAITLIST_REQUEST_IDS,
})

export const createWaitlistRequest = makeFormSubmitAction(types.CREATE_WAITLIST_REQUEST)
export const updateWaitlistRequest = makeFormSubmitAction(types.UPDATE_WAITLIST_REQUEST)

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const filterWaitlistRequests = (filters = {}) => ({
  type: types.FILTER_WAITLIST_REQUESTS,
  filters,
})

export const deleteWaitlistRequests = (id, feedId) => ({
  type: types.DELETE_WAITLIST_REQUEST,
  id,
  feedId,
})

export const removeWaitlistRequest = ids => ({
  type: types.REMOVE_WAITLIST_REQUEST,
  ids,
})

export const resolveWaitlistRequest = id => ({
  type: types.RESOLVE_WAITLIST_REQUEST,
  id,
})

export const updateClientWaitlistRequest = makeFormSubmitAction(types.UPDATE_CLIENT_WAITLIST_REQUEST)
