const namespace = 'intakeForm'

export const CREATE_INTAKE_FORM = `${namespace}/CREATE_INTAKE_FORM`
export const UPDATE_INTAKE_FORM = `${namespace}/UPDATE_INTAKE_FORM`
export const DELETE_INTAKE_FORM = `${namespace}/DELETE_INTAKE_FORM`
export const UPDATE_INTAKE_FORM_ACTIVATION = `${namespace}/UPDATE_INTAKE_FORM_ACTIVATION`
export const VALIDATE_FORM_DETAILS = `${namespace}/VALIDATE_FORM_DETAILS`
export const DUPLICATE_FORM = `${namespace}/DUPLICATE_FORM`
export const DOWNLOAD_FORM = `${namespace}/DOWNLOAD_FORM`
export const RESEND_FORM = `${namespace}/RESEND_FORM`
