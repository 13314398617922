import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ArrowDownLong = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M219.427 387.138V36.573c0-20.198 16.375-36.572 36.571-36.572s36.572 16.375 36.572 36.572v350.565l120.425-120.425c14.281-14.281 37.438-14.281 51.72 0s14.281 37.438 0 51.72L281.859 501.289c-14.281 14.282-37.438 14.282-51.72 0L47.283 318.433c-14.282-14.281-14.282-37.438 0-51.72s37.438-14.281 51.72 0L219.43 387.14z" />
  </svg>
)

ArrowDownLong.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ArrowDownLong.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ArrowDownLong
