const companySidebar = {
  'companySidebar.manageTeam': 'Manage team',
  'companySidebar.company': 'Company',
  'companySidebar.companyProfile': 'Company profile',
  'companySidebar.clientFields': '_(Client) fields',
  'companySidebar.services': '_(Services)',
  'companySidebar.allServices': 'All _(services)',
  'companySidebar.categories': 'Categories',
  'companySidebar.billing': 'Billing',
  'companySidebar.paymentMethods': 'Payment methods',
  'companySidebar.subscription': 'Subscription',
  'companySidebar.documents': 'Documents',
  'companySidebar.intakeForms': 'Forms',
  'companySidebar.agreements': '_(Service agreements)',
  'companySidebar.reviews': 'Reviews',
  'companySidebar.features': 'Features',
}

export default companySidebar
