import React from 'react'
import { is, omit } from 'ramda'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { getIn } from 'formik'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import InputMessage from '../InputMessage'
import CircleCancel from '../icons/CircleCancel'
import Copy from '../icons/Copy'

const InputFieldComponent = ({
  id,
  type,
  disabled,
  label,
  icon,
  field,
  placeholder,
  autoComplete,
  intl,
  className,
  form: { status },
  suffix,
  message,
  messageClass,
  messageTextClass,
  onCopy,
  iconColor,
  autoFocus,
  showMessage,
  allowClear,
  onClear,
  dataCy,
  iconSize,
  inputClassName,
  isSuffixClassName,
  innerRef,
  ...props
}) => {
  const fieldStatus = getIn(status, field.name)

  const wrapperClassNames = classNames(
    className,
    'main-input',
    message && ['main-input--has-message relative', messageClass],
    {
      'main-input--disabled': disabled,
      'main-input--has-message-alert': fieldStatus,
    },
  )

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        {onCopy && (
          <button
            className="main-input__icon main-input__icon--right c-pointer font-0"
            data-cy="copy-to-clipboard-icon"
            onClick={onCopy}
          >
            <Copy className="fill-currentcolor" size={20} />
          </button>
        )}
        <div className="d-flex align-items-center">
          <input
            data-cy={dataCy}
            className={classNames(
              inputClassName,
              'main-input__field',
              suffix && isSuffixClassName ? 'mr-8' : '',
              onCopy ? 'main-input__field--right-icon' : '',
            )}
            type={type}
            placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder, placeholder.values) : placeholder}
            autoComplete={autoComplete}
            {...{ disabled, id, autoFocus, ...field, ...omit(['maxChars'], props) }}
            ref={innerRef}
          />
          {suffix}
          {allowClear && isPresent(field.value) && (
            <span role="button" onClick={onClear}>
              <CircleCancel
                className="main-page__search-icon main-page__search-icon--clear"
                dataCy="input-clear-button"
                size={12}
              />
            </span>
          )}
        </div>
      </div>
      {showMessage && message && (
        <InputMessage
          messageTextClass={messageTextClass}
          message={message}
          icon={icon}
          iconColor={iconColor}
          iconSize={iconSize}
        />
      )}
    </div>
  )
}

InputFieldComponent.defaultProps = {
  id: null,
  type: 'text',
  disabled: false,
  autoFocus: false,
  label: null,
  icon: null,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  suffix: null,
  message: null,
  messageClass: null,
  messageTextClass: null,
  onCopy: undefined,
  iconColor: null,
  showMessage: true,
  allowClear: false,
  onClear: noop,
  dataCy: undefined,
  iconSize: 14,
  inputClassName: undefined,
  isSuffixClassName: true,
  innerRef: undefined,
}

InputFieldComponent.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  suffix: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  messageClass: PropTypes.string,
  messageTextClass: PropTypes.string,
  onCopy: PropTypes.func,
  iconColor: PropTypes.oneOf(['blue', null]),
  showMessage: PropTypes.bool,
  allowClear: PropTypes.bool,
  onClear: PropTypes.func,
  dataCy: PropTypes.string,
  iconSize: PropTypes.number,
  inputClassName: PropTypes.string,
  isSuffixClassName: PropTypes.bool,
  innerRef: PropTypes.shape(),
}

export default InputFieldComponent
