import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchCustomFields = () => ({
  type: types.FETCH_CUSTOM_FIELDS,
})

export const setCustomFields = ids => ({
  type: types.SET_CUSTOM_FIELDS,
  ids,
})

export const addCustomFields = ids => ({
  type: types.ADD_CUSTOM_FIELDS,
  ids,
})

export const removeCustomFields = ids => ({
  type: types.REMOVE_CUSTOM_FIELDS,
  ids,
})

export const deleteCustomField = id => ({
  type: types.DELETE_CUSTOM_FIELD,
  id,
})

export const updateCustomFieldPriority = ({ id, priority, orderedFields }) => ({
  type: types.UPDATE_CUSTOM_FIELD_PRIORITY,
  id,
  priority,
  orderedFields,
})

export const createCustomField = makeFormSubmitAction(types.CREATE_CUSTOM_FIELD)
export const updateCustomField = makeFormSubmitAction(types.UPDATE_CUSTOM_FIELD)
