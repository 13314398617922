import { FORM_TYPES } from 'lib/constants/forms'
import * as types from './types'

export const fetchForms = ({ formType = '', filters = {}, options = {}, workspaceCode = null }) => ({
  type: types.FETCH_FORMS,
  formType,
  filters,
  options,
  workspaceCode,
})

export const fetchIntakeForms = (filters = {}, options = {}, workspaceCode = null) =>
  fetchForms({ formType: FORM_TYPES.intake, filters, options, workspaceCode })

export const fetchAgreements = (filters = {}, options = {}, workspaceCode = null) =>
  fetchForms({ formType: FORM_TYPES.agreement, filters, options, workspaceCode })

export const setFormIds = (formType, ids) => ({
  type: types.SET_FORM_IDS,
  ids,
  formType,
})

export const addFormIds = (formType, ids) => ({
  type: types.ADD_FORM_IDS,
  ids,
  formType,
})

export const setFilesIds = ids => ({
  type: types.SET_FILE_IDS,
  ids,
})

export const addFileIds = ids => ({
  type: types.ADD_FILE_IDS,
  ids,
})

export const resetDocuments = () => ({
  type: types.RESET_DOCUMENTS,
})

export const fetchFiles = (options, workspaceCode = null) => ({
  type: types.FETCH_FILES,
  options,
  workspaceCode,
})

export const filterFiles = (filters = {}) => ({
  type: types.FILTER_FILES,
  filters,
})

export const setFilesFilterParams = (filterParams = {}) => ({
  type: types.SET_FILES_FILTER_PARAMS,
  filterParams,
})

export const setFilesBlankState = isBlankState => ({
  type: types.SET_FILES_BLANK_STATE,
  isBlankState,
})

export const viewFiles = sharedFileIds => ({
  type: types.VIEW_FILES,
  sharedFileIds,
})

export const setFormIsBlankState = (formType, isBlankState) => ({
  type: types.SET_FORM_IS_BLANK_STATE,
  formType,
  isBlankState,
})

export const setFormsCurrentPage = (formType, pageNumber) => ({
  type: types.SET_FORM_CURRENT_PAGE,
  formType,
  pageNumber,
})

export const setFilesCurrentPage = pageNumber => ({
  type: types.SET_FILES_CURRENT_PAGE,
  pageNumber,
})

export const receiveGuestForm = data => ({
  type: types.RECEIVE_GUEST_FORM,
  data,
})

export const receiveAccountForm = data => ({
  type: types.RECEIVE_ACCOUNT_FORM,
  data,
})

export const removeFormId = (formType, id) => ({
  type: types.REMOVE_FORM_ID,
  formType,
  id,
})

export const fetchDocuments = (workspaceCode = null, filters = {}, options = {}) => ({
  type: types.FETCH_DOCUMENTS,
  workspaceCode,
  filters,
  options,
})

export const setDocumentsIds = ids => ({
  type: types.SET_DOCUMENT_IDS,
  ids,
})

export const addDocumentsIds = ids => ({
  type: types.ADD_DOCUMENT_IDS,
  ids,
})

export const setDocumentsCurrentPage = pageNumber => ({
  type: types.SET_DOCUMENTS_CURRENT_PAGE,
  pageNumber,
})

export const filterDocuments = filterParams => ({
  type: types.FILTER_DOCUMENTS,
  filterParams,
})

export const setDocumentsFilterParams = filterParams => ({
  type: types.SET_DOCUMENTS_FILTER_PARAMS,
  filterParams,
})

export const setDocumentsBlankState = isBlankState => ({
  type: types.SET_DOCUMENTS_BLANK_STATE,
  isBlankState,
})

export const fetchMyFiles = options => ({
  type: types.FETCH_MY_FILES,
  options,
})

export const filterMyFiles = (filters = {}) => ({
  type: types.FILTER_MY_FILES,
  filters,
})

export const setMyFilesFilterParams = (filterParams = {}) => ({
  type: types.SET_MY_FILES_FILTER_PARAMS,
  filterParams,
})

export const setMyFileIds = ids => ({
  type: types.SET_MY_FILE_IDS,
  ids,
})

export const addMyFileIds = ids => ({
  type: types.ADD_MY_FILE_IDS,
  ids,
})

export const setMyFilesCurrentPage = pageNumber => ({
  type: types.SET_MY_FILES_CURRENT_PAGE,
  pageNumber,
})

export const setMyFilesBlankState = isBlankState => ({
  type: types.SET_MY_FILES_BLANK_STATE,
  isBlankState,
})

export const deleteMyFile = (id, onSuccess) => ({
  type: types.DELETE_MY_FILE,
  id,
  onSuccess,
})

export const removeMyFileId = id => ({
  type: types.REMOVE_MY_FILE_ID,
  id,
})
