const cardReader = {
  'cardReader.connectedStripeCardReaders': 'Connected Stripe card readers',
  'cardReader.stripe_account': 'Stripe account',
  'cardReader.loadMore': 'Load more',
  'cardReader.description':
    'You need an active card reader to accept payments. Manage all your card readers (connect, activate, deactivate) from your',
  'cardReader.table.column.name': 'Name',
  'cardReader.table.column.location': 'Location',
  'cardReader.table.column.deviceCode': 'Device code',
  'cardReader.empty.description':
    'Connect a card reader from your Stripe account to start accepting in-person payments.',
  'cardReader.empty.link': 'Learn how',
  'cardReader.empty.noCardReaders': 'No card readers',
  'cardReader.empty.noTasks': 'No tasks',
}

export default cardReader
