import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ArrowLeft from 'views/shared/icons/ArrowLeft'
import Tooltip from 'views/shared/Tooltip'

const PrevArrow = ({ onClick, prevArrowTooltip }) => (
  <Tooltip
    isShown={prevArrowTooltip}
    align={{ offset: [0, -3] }}
    overlayClassName="main-tooltip main-tooltip--no-pointer"
    placement="top"
    icon={
      <button
        type="button"
        onClick={onClick}
        className="files-preview__btn files-preview__btn--prev files-preview__btn--modal"
        data-cy="preview-carousel-prev-button"
      >
        <ArrowLeft dataCy="arrow-left-icon" className="in-white" size={10} />
      </button>
    }
  >
    <FormattedMessage id="shared.previous" />
  </Tooltip>
)

PrevArrow.defaultProps = {
  onClick: undefined,
  prevArrowTooltip: false,
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  prevArrowTooltip: PropTypes.bool,
}

export default PrevArrow
