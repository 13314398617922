/* eslint-disable expbox/custom-name-validation */
const customNames = {
  'customNames.description':
    'Replace default ExpertBox terms with custom phrases that fit your business. This naming will appear everywhere across your workspace. ',
  'customNames.table.header.defaultName': 'Default name',
  'customNames.table.header.singular': 'Custom name (one)',
  'customNames.table.header.plural': 'Custom name (many)',
  'customNames.table.expert': 'Expert',
  'customNames.table.service': 'Service',
  'customNames.table.client': 'Client',
  'customNames.table.appointment': 'Appointment',
  'customNames.table.intakeForm': 'Form',
  'customNames.table.serviceAgreement': 'Service agreement',
  'customNames.table.scheduler': 'Scheduler',

  'customNames.validation.bothFormsRequired': 'Please write both singular and plural forms.',

  'customNames.table.clientSingular.placeholder.healthcare': 'e.g. Patient',
  'customNames.table.clientPlural.placeholder.healthcare': 'e.g. Patients',
  'customNames.table.expertSingular.placeholder.healthcare': 'e.g. Clinician',
  'customNames.table.expertPlural.placeholder.healthcare': 'e.g. Clinicians',
  'customNames.table.schedulerSingular.placeholder.healthcare': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.healthcare': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.healthcare': 'e.g. Treatment',
  'customNames.table.servicePlural.placeholder.healthcare': 'e.g. Treatment',
  'customNames.table.appointmentSingular.placeholder.healthcare': 'e.g. Appointment',
  'customNames.table.appointmentPlural.placeholder.healthcare': 'e.g. Appointments',
  'customNames.table.serviceAgreementSingular.placeholder.healthcare': 'e.g. Consent form',
  'customNames.table.serviceAgreementPlural.placeholder.healthcare': 'e.g. Consent forms',

  'customNames.table.clientSingular.placeholder.financial': 'e.g. Account',
  'customNames.table.clientPlural.placeholder.financial': 'e.g. Accounts',
  'customNames.table.expertSingular.placeholder.financial': 'e.g. Advisor',
  'customNames.table.expertPlural.placeholder.financial': 'e.g. Advisors',
  'customNames.table.schedulerSingular.placeholder.financial': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.financial': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.financial': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.financial': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.financial': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.financial': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.financial': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.financial': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.sales': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.sales': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.sales': 'e.g. Salesman',
  'customNames.table.expertPlural.placeholder.sales': 'e.g. Salesmen',
  'customNames.table.schedulerSingular.placeholder.sales': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.sales': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.sales': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.sales': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.sales': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.sales': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.sales': 'e.g. Contract',
  'customNames.table.serviceAgreementPlural.placeholder.sales': 'e.g. Contracts',

  'customNames.table.clientSingular.placeholder.training': 'e.g. Applicant',
  'customNames.table.clientPlural.placeholder.training': 'e.g. Applicants',
  'customNames.table.expertSingular.placeholder.training': 'e.g. Coach',
  'customNames.table.expertPlural.placeholder.training': 'e.g. Coaches',
  'customNames.table.schedulerSingular.placeholder.training': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.training': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.training': 'e.g. Practice',
  'customNames.table.servicePlural.placeholder.training': 'e.g. Practices',
  'customNames.table.appointmentSingular.placeholder.training': 'e.g. Session',
  'customNames.table.appointmentPlural.placeholder.training': 'e.g. Sessions',
  'customNames.table.serviceAgreementSingular.placeholder.training': 'e.g. Coaching agreement',
  'customNames.table.serviceAgreementPlural.placeholder.training': 'e.g. Coaching agreements',

  'customNames.table.clientSingular.placeholder.education': 'e.g. Student',
  'customNames.table.clientPlural.placeholder.education': 'e.g. Students',
  'customNames.table.expertSingular.placeholder.education': 'e.g. Tutor',
  'customNames.table.expertPlural.placeholder.education': 'e.g. Tutors',
  'customNames.table.schedulerSingular.placeholder.education': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.education': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.education': 'e.g. Class',
  'customNames.table.servicePlural.placeholder.education': 'e.g. Classes',
  'customNames.table.appointmentSingular.placeholder.education': 'e.g. Lesson',
  'customNames.table.appointmentPlural.placeholder.education': 'e.g. Lessons',
  'customNames.table.serviceAgreementSingular.placeholder.education': 'e.g. Learning agreement',
  'customNames.table.serviceAgreementPlural.placeholder.education': 'e.g. Learning agreements',

  'customNames.table.clientSingular.placeholder.entertainment': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.entertainment': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.entertainment': 'e.g. Specialist',
  'customNames.table.expertPlural.placeholder.entertainment': 'e.g. Specialists',
  'customNames.table.schedulerSingular.placeholder.entertainment': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.entertainment': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.entertainment': 'e.g. Event',
  'customNames.table.servicePlural.placeholder.entertainment': 'e.g. Events',
  'customNames.table.appointmentSingular.placeholder.entertainment': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.entertainment': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.entertainment': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.entertainment': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.marketing': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.marketing': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.marketing': 'e.g. Specialist',
  'customNames.table.expertPlural.placeholder.marketing': 'e.g. Specialists',
  'customNames.table.schedulerSingular.placeholder.marketing': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.marketing': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.marketing': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.marketing': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.marketing': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.marketing': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.marketing': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.marketing': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.consulting': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.consulting': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.consulting': 'e.g. Consultant',
  'customNames.table.expertPlural.placeholder.consulting': 'e.g. Consultants',
  'customNames.table.schedulerSingular.placeholder.consulting': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.consulting': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.consulting': 'e.g. Consulting service',
  'customNames.table.servicePlural.placeholder.consulting': 'e.g. Consulting services',
  'customNames.table.appointmentSingular.placeholder.consulting': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.consulting': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.consulting': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.consulting': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.legal': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.legal': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.legal': 'e.g. Lawyer',
  'customNames.table.expertPlural.placeholder.legal': 'e.g. Lawyers',
  'customNames.table.schedulerSingular.placeholder.legal': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.legal': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.legal': 'e.g. Assistance',
  'customNames.table.servicePlural.placeholder.legal': 'e.g. Assistances',
  'customNames.table.appointmentSingular.placeholder.legal': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.legal': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.legal': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.legal': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.insurance': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.insurance': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.insurance': 'e.g. Agent',
  'customNames.table.expertPlural.placeholder.insurance': 'e.g. Agents',
  'customNames.table.schedulerSingular.placeholder.insurance': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.insurance': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.insurance': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.insurance': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.insurance': 'e.g. Consultation',
  'customNames.table.appointmentPlural.placeholder.insurance': 'e.g. Consultations',
  'customNames.table.serviceAgreementSingular.placeholder.insurance': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.insurance': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.it': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.it': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.it': 'e.g. Specialist',
  'customNames.table.expertPlural.placeholder.it': 'e.g. Specialists',
  'customNames.table.schedulerSingular.placeholder.it': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.it': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.it': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.it': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.it': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.it': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.it': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.it': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.communications': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.communications': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.communications': 'e.g. Professional',
  'customNames.table.expertPlural.placeholder.communications': 'e.g. Professionals',
  'customNames.table.schedulerSingular.placeholder.communications': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.communications': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.communications': 'e.g. PR service',
  'customNames.table.servicePlural.placeholder.communications': 'e.g. PR services',
  'customNames.table.appointmentSingular.placeholder.communications': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.communications': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.communications': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.communications': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.hr': 'e.g. Candidate',
  'customNames.table.clientPlural.placeholder.hr': 'e.g. Candidates',
  'customNames.table.expertSingular.placeholder.hr': 'e.g. Recruiter',
  'customNames.table.expertPlural.placeholder.hr': 'e.g. Recruiters',
  'customNames.table.schedulerSingular.placeholder.hr': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.hr': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.hr': 'e.g. Interview',
  'customNames.table.servicePlural.placeholder.hr': 'e.g. Interviews',
  'customNames.table.appointmentSingular.placeholder.hr': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.hr': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.hr': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.hr': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.design': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.design': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.design': 'e.g. Designer',
  'customNames.table.expertPlural.placeholder.design': 'e.g. Designers',
  'customNames.table.schedulerSingular.placeholder.design': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.design': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.design': 'e.g. Design service',
  'customNames.table.servicePlural.placeholder.design': 'e.g. Design services',
  'customNames.table.appointmentSingular.placeholder.design': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.design': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.design': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.design': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.wellness': 'e.g. Trainee',
  'customNames.table.clientPlural.placeholder.wellness': 'e.g. Trainees',
  'customNames.table.expertSingular.placeholder.wellness': 'e.g. Coach',
  'customNames.table.expertPlural.placeholder.wellness': 'e.g. Coaches',
  'customNames.table.schedulerSingular.placeholder.wellness': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.wellness': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.wellness': 'e.g. Training',
  'customNames.table.servicePlural.placeholder.wellness': 'e.g. Trainings',
  'customNames.table.appointmentSingular.placeholder.wellness': 'e.g. Session',
  'customNames.table.appointmentPlural.placeholder.wellness': 'e.g. Sessions',
  'customNames.table.serviceAgreementSingular.placeholder.wellness': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.wellness': 'e.g. Legal agreements',

  'customNames.table.clientSingular.placeholder.other': 'e.g. Customer',
  'customNames.table.clientPlural.placeholder.other': 'e.g. Customers',
  'customNames.table.expertSingular.placeholder.other': 'e.g. Specialist',
  'customNames.table.expertPlural.placeholder.other': 'e.g. Specialists',
  'customNames.table.schedulerSingular.placeholder.other': 'e.g. Receptionist',
  'customNames.table.schedulerPlural.placeholder.other': 'e.g. Receptionists',
  'customNames.table.serviceSingular.placeholder.other': 'e.g. Product',
  'customNames.table.servicePlural.placeholder.other': 'e.g. Products',
  'customNames.table.appointmentSingular.placeholder.other': 'e.g. Meeting',
  'customNames.table.appointmentPlural.placeholder.other': 'e.g. Meetings',
  'customNames.table.serviceAgreementSingular.placeholder.other': 'e.g. Legal agreement',
  'customNames.table.serviceAgreementPlural.placeholder.other': 'e.g. Legal agreements',
}

export default customNames
