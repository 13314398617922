import { createSelector } from '@reduxjs/toolkit'
import { any, compose, omit, path, pipe, prop, propOr, values } from 'ramda'

import { fetchReviewsEndpoint } from 'state/concepts/reviews/endpoints'
import {
  dataSelector,
  endpointMetaSelector,
  loadingSelector,
  pageCountSelector,
  totalCountSelector,
} from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import isPresent from 'utils/isPresent'

export const isBlankStateSelector = path(['reviews', 'isBlankState'])

export const reviewIdsSelector = path(['reviews', 'reviewIds'])

export const reviewsSelector = createSelector([reviewIdsSelector, dataSelector], buildCollection('review'))

export const paginationSelector = path(['reviews', 'pagination'])

export const currentPageSelector = createSelector(paginationSelector, prop('number'))

export const sortSelector = path(['reviews', 'sort'])

export const sortParamsSelector = createSelector(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const filtersSelector = path(['reviews', 'filters'])

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(isPresent), values, omit(['name', 'statuses'])),
)

export const searchQuerySelector = createSelector(filtersSelector, prop('name'))

export const appliedFiltersSelector = (state, name) => path(['reviews', 'filters', name], state)

export const reviewsTotalCountSelector = state => totalCountSelector(state, fetchReviewsEndpoint.endpoint)

export const reviewsPageCountSelector = state => pageCountSelector(state, fetchReviewsEndpoint.endpoint)

export const reviewsLoadingSelector = state => loadingSelector(state, fetchReviewsEndpoint.endpoint)

export const fetchReviewsMetaSelector = state => endpointMetaSelector(state, fetchReviewsEndpoint.endpoint)

export const collectReviewsSelector = pipe(fetchReviewsMetaSelector, propOr(false, 'collectReviews'))
