import { createSelector } from '@reduxjs/toolkit'
import { compose, last, path, pipe, prop, propOr, includes } from 'ramda'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { filterBookingsForCurrentDay } from 'utils/bookings'
import buildCollection from 'utils/buildCollection'
import { fetchClientAccountBookingsEndpoint } from './endpoints'

const clientAccountBookingsIdsSelector = path(['clientAccountBookings', 'clientAccountBookingsIds'])
export const filtersSelector = path(['clientAccountBookings', 'filters'])
export const sortSelector = path(['clientAccountBookings', 'sort'])

export const statusesSelector = createSelector(filtersSelector, prop('statuses'))

export const pastSelector = createSelector(filtersSelector, prop('past'))

export const bookingsTypeSelector = pipe(filtersSelector, prop('bookingsType'))

export const employeesSelector = createSelector(filtersSelector, prop('employees'))

export const servicesSelector = createSelector(filtersSelector, prop('services'))

export const clientAccountBookingsSelector = createSelector(
  [clientAccountBookingsIdsSelector, dataSelector, statusesSelector],
  (ids, data, statusesFilter) =>
    buildCollection('booking', ids, data).filter(booking => includes(booking.status, statusesFilter)),
)

export const lastClientAccountBookingIdSelector = createSelector(
  clientAccountBookingsSelector,
  compose(propOr(null, 'id'), last),
)

export const clientAccountCurrentDayBookingsSelector = createSelector(
  clientAccountBookingsSelector,
  filterBookingsForCurrentDay,
)

export const clientAccountRestBookingsSelector = createSelector(clientAccountBookingsSelector, bookings =>
  filterBookingsForCurrentDay(bookings, false),
)

export const clientAccountBookingsLoadingSelector = state =>
  loadingSelector(state, fetchClientAccountBookingsEndpoint.endpoint)

export const sortParamsSelector = createSelector(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const pendingCountSelector = path(['clientAccountBookings', 'pendingBookingsCount'])
