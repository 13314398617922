import { uniq, without, pick, keys } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import { VIEWS } from 'lib/constants/tasks'

import {
  ADD_TASK_IDS,
  RESET_TASKS,
  REMOVE_TASK_ID,
  SET_TASKS_COUNTS,
  SET_FILTER_PARAMS,
  RESET_FILTERS,
  ADD_CREATED_TASK_ID,
  SET_VIEW,
  DECREMENT_TASKS_COUNT,
  SET_IS_CREATING_TASK,
  INCREMENT_TASKS_COUNT,
  SET_TASK_TO_EDIT,
} from './types'

const taskIds = (state = [], action) => {
  switch (action.type) {
    case ADD_TASK_IDS:
      return uniq([...state, ...action.taskIds])
    case REMOVE_TASK_ID:
      return without([action.id], state)
    case ADD_CREATED_TASK_ID:
      return [action.id, ...state]
    case RESET_TASKS:
      return []
    default:
      return state
  }
}

const countsInitialState = {
  allTasksCount: 0,
  totalCount: 0,
  overdueTasksCount: 0,
  upcomingTasksCount: 0,
  noDueTasksCount: 0,
}

const counts = (state = countsInitialState, action) => {
  switch (action.type) {
    case SET_TASKS_COUNTS:
      return pick(keys(countsInitialState), action.counts)
    case RESET_TASKS:
      return countsInitialState
    case DECREMENT_TASKS_COUNT:
      return {
        ...state,
        [action.counter]: state[action.counter] - 1,
        totalCount: state.totalCount - 1,
        allTasksCount: action.withoutAllTasksCount ? state.allTasksCount : state.allTasksCount - 1,
      }
    case INCREMENT_TASKS_COUNT:
      return {
        ...state,
        [action.counter]: state[action.counter] + 1,
        totalCount: state.totalCount + 1,
        allTasksCount: action.withoutAllTasksCount ? state.allTasksCount : state.allTasksCount + 1,
      }
    default:
      return state
  }
}

export const filtersInitialState = {
  completed: false,
  assignee: [],
  linkedClients: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return filtersInitialState
    default:
      return state
  }
}

const view = (state = VIEWS.list, action) => {
  switch (action.type) {
    case SET_VIEW:
      return action.view
    default:
      return state
  }
}

const isCreatingTask = (state = false, action) => {
  switch (action.type) {
    case SET_IS_CREATING_TASK:
      return action.isCreating
    default:
      return state
  }
}

const taskToEdit = (state = null, action) => {
  switch (action.type) {
    case SET_TASK_TO_EDIT:
      return action.task
    default:
      return state
  }
}

const tasksReducer = combineReducers({
  taskIds,
  counts,
  filters,
  view,
  isCreatingTask,
  taskToEdit,
})

export default tasksReducer
