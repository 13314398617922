const payments = {
  'payments.title': 'Billing',

  'payments.header.action.paymentSettings': 'Payment settings',
  'payments.header.action.receiptTemplateSettings': 'Invoices & receipts settings ',
  'payments.header.action.requestPayment': 'Request payment',
  'payments.header.action.recordPayment': 'Record payment',
  'payments.header.action.requestRecurringPayment': 'Request recurring payment',

  'payments.table.title.number': 'Payment #',
  'payments.table.title.recurring': 'Recurring',
  'payments.table.title.recurringOf': '{index} of {totalCount}',
  'payments.table.title.services': '_(Services)',
  'payments.table.title.client': '_(Client)',
  'payments.table.title.issueDate': 'Issue date',
  'payments.table.title.dueDate': 'Due date',
  'payments.table.title.status': 'Status',
  'payments.table.title.total': 'Total',
  'payments.table.filters.search.placeholder': 'Search by _(service) name',

  'receipts.transactions.empty': 'There are no transactions yet.',

  'payments.action.view.invoice': 'View invoice',
  'payments.action.view.receipt': 'View receipt',
  'payments.action.view.creditNote': 'View credit note',
  'payments.action.downloadPDF.invoice': 'Download invoice',
  'payments.action.downloadPDF.receipt': 'Download receipt',
  'payments.action.downloadPDF.creditNote': 'Download credit note',
  'payments.action.viewAppointment': 'View _(appointment)',

  'payments.action.sendPayment': 'Send payment',
  'payments.action.resendPayment': 'Resend payment',
  'payments.action.cancelPayment': 'Cancel payment',
  'payments.action.viewPayment': 'View payment',
  'payments.action.viewRecurringPayment': 'View recurring payment',
  'payments.action.editRecurringPayment': 'Edit recurring payment',
  'payments.action.collectPayment': 'Collect payment',
  'payments.action.chargeWithCardReader': 'Charge with card reader',
  'payments.action.markAsPaid': 'Mark as paid',
  'payments.action.markAsRefunded': 'Mark as refunded',
  'payments.action.issueRefund': 'Issue refund',
  'payments.action.editPayment': 'Edit payment',
  'payments.action.deleteDraft': 'Delete draft',
  'payments.cancel.confirmModal.title': 'Cancel payment for _(appointment)?',
  'payments.cancel.confirmModal.submit': 'Cancel payment',
  'payments.cancel.confirmModal.body':
    'The _(client) will get an email that you’ve cancelled your payment request for this _(appointment).',

  'payments.review.invoiceNumber': 'Invoice {transactionNumber}',
  'payments.review.receiptNumber': 'Receipt {transactionNumber}',
  'payments.review.creditNoteNumber': 'Credit note {transactionNumber}',
  'payments.review.dueDate': 'Due date:',
  'payments.review.issueDate': 'Issue date:',
  'payments.review.paidOn': 'Paid on:',
  'payments.review.originalInvoice': 'Original invoice:',
  'payments.review.originalIssueDate': 'Original invoice date:',
  'payments.review.billTo': 'Bill to',
  'payments.review.taxId': 'Tax ID: {taxId}',
  'payments.review.paymentMethod': 'Payment method',
  'payments.review.paymentDetails': 'Payment details',
  'payments.review.refundInformation': 'Refund information',
  'payments.review.refundForPayment': 'Refund for Payment {refundedNumber}',
  'payments.review.table.service': '_(Service)',
  'payments.review.table.quantity': 'Quantity',
  'payments.review.table.price': 'Price',
  'payments.review.table.total': 'Total',
  'payments.review.total.totalAmount': 'Total amount:',
  'payments.review.total.paidAmount': 'Amount paid:',
  'payments.review.total.refundAmount': 'Refund amount:',
  'payments.review.total.refundedAmount': 'Refunded:',

  'receipts.customize.backLink': 'Back to Receipts',
  'receipts.customize.title': 'Invoices & receipts settings',
  'receipts.customize.businessInformation': 'Business information',
  'receipts.customize.businessInformation.alert':
    'Add your business information to start issuing invoices and receipts.',
  'receipts.customize.businessInformation.description': 'This comes from your Business information in Settings.',
  'receipts.customize.businessInformation.edit': 'Edit Business information',
  'receipts.customize.businessInformation.add': 'Add business information',
  'receipts.customize.businessInformation.add.description': 'You haven’t filled out your business information yet.',
  'receipts.customize.label.taxId': 'Tax ID',
  'receipts.customize.label.message': 'Message on receipt',
  'receipts.customize.label.active': 'Activated',
  'receipts.customize.label.inactive': 'Deactivated',
  'receipts.customize.message': 'Footer message on receipt',
  'receipts.customize.message.description':
    'Leave notes for _(clients) that will appear at the bottom of each receipt.',
  'receipts.customize.message.placeholder': 'This text will appear on each receipt',
  'receipts.customize.previewTemplate': 'Preview Receipt Template',
  'receipts.customize.issueDocuments': 'Issue financial documents',
  'receipts.customize.issueDocuments.description':
    'Issue and send invoices and receipts for each payment, and credit notes for refunds.',
  'receipts.customize.previewInvoice': 'Preview invoice',
  'receipts.customize.previewReceipt': 'Preview receipt',
  'receipts.customize.previewCreditNote': 'Preview credit note',

  'payments.status.pending': 'Pending',
  'payments.status.success': 'Paid',
  'payments.status.failure': 'Failed',
  'payments.status.draft': 'Draft',
  'payments.status.cancelled': 'Cancelled',
  'payments.status.overdue': 'Overdue',

  'payments.blank.noPaymentsYet': 'No payments yet',
  'payments.blank.subtitle.paymentEnabled': '{link} to start getting paid for your _(services).',
  'payments.blank.subtitle.paymentEnabled.link': 'Create your first payment request',
  'payments.blank.subtitle.enableAcceptPayments.adminOrOwner':
    'To start getting online payments, enable {link} feature.',
  'payments.blank.subtitle.enableAcceptPayments.link': 'Accept Payments',
  'payments.blank.subtitle.enableAcceptPayments.expert':
    'To start getting online payments, ask your workspace owner to enable Accept payments feature.',
  'payments.blank.subtitle.enableCustomPayments.link': 'Custom payments',
  'payments.blank.subtitle.enableCustomPayments.owner':
    'To start requesting payments from _(clients), you need to enable the {link} feature.',
  'payments.blank.subtitle.enableCustomPayments.adminOrExpert':
    'To start requesting payments from _(clients), ask your workspace owner to enable the Custom payments feature.',
  'payments.blank.subtitle': 'Connect at least one payment method in your {link} to request payments from _(clients).',
  'payments.blank.subtitle.notOwner':
    'To start requesting payments from _(clients), ask your workspace owner to connect at least one payment method.',
  'payments.blank.subtitle.link': 'Payment settings',
  'payments.requestPayment.billTo': 'Bill to',
  'payments.requestPayment.selectServices': 'Select _(services)',
  'payments.paymentForm.table.column.service': '_(Service)',
  'payments.paymentForm.table.column.quantity': 'Quantity',
  'payments.paymentForm.table.column.price': 'Price',
  'payments.paymentForm.table.column.total': 'Total',
  'payments.paymentForm.table.column.service.placeholder': 'Select _(service)',
  'payments.paymentForm.table.footer.totalAmount': 'Total amount: ',
  'payments.paymentForm.table.footer.addService': 'Add _(service)',
  'payments.paymentForm.editPaymentInstructions': 'Edit payment instructions',
  'payments.paymentForm.saveAsDraft': 'Save as draft',
  'payments.cancelledBy.ClientProfile': '_(Client)',
  'payments.cancelledBy.UserProfile': '_(Expert)',

  'payments.tab.label': 'Payments',
}

export default payments
