import { createLogic } from 'redux-logic'
import Router from 'next/router'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { DEACTIVATE_SERVICES } from 'state/concepts/services/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { deactivateServiceEndpoint } from 'state/concepts/services/endpoints'

const deactivateServicesOperation = createLogic({
  type: DEACTIVATE_SERVICES,
  latest: true,

  async process({ action: { serviceIds }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = deactivateServiceEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, { service_ids: serviceIds })
      const state = getState()

      const response = updateDataHelper(state.data, 'service', serviceIds, { attributes: { status: 'inactive' } })

      dispatch(dataApiSuccess({ response, endpoint }))

      if (!Router.router.asPath.includes(`services/${serviceIds[0]}`)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.servicesDeactivated',
              values: { count: serviceIds.length },
            },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deactivateServicesOperation
