import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import {
  compose,
  filter,
  findLastIndex,
  flatten,
  head,
  identity,
  includes,
  last,
  map,
  path,
  pick,
  prop,
  reverse,
  sortBy,
  values,
} from 'ramda'
import build from 'redux-object'

import { WS_STATUSES } from 'lib/constants/webSockets'
import {
  fetchChatAttachmentsEndpoint,
  fetchChatMessageEndpoint,
  getChatEndpoint,
  getChatMembersEndpoint,
} from 'state/concepts/chat/endpoints'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { connectionStatusSelector } from 'state/concepts/webSockets/selectors'
import { dataSelector, endpointMetaSelector, loadingSelector, nextPageSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { isUnreadMessage } from 'utils/chat'
import isPresent from 'utils/isPresent'

const metaSelector = state => state.data.meta

export const chatIdSelector = path(['chat', 'chatId'])
export const messageIdsSelector = path(['chat', 'messageIds'])
export const attachmentsIdsSelector = path(['chat', 'attachmentsIds'])
export const videoRecordsIdsSelector = path(['chat', 'videoRecordsIds'])
export const editingMessageSelector = path(['chat', 'editingMessage'])
export const hasVoiceMessageSelector = path(['chat', 'hasVoiceMessage'])
export const uploadProgressSelector = path(['chat', 'uploadProgress'])
export const uploadStatusSelector = path(['chat', 'uploadStatus'])
export const uploadLinksSelector = path(['chat', 'uploadLinks'])
export const uploadWithErrorsSelector = path(['chat', 'uploadWithErrors'])
export const draggedFilesSelector = path(['chat', 'draggedFiles'])
export const chatMemberIdsSelector = path(['chat', 'chatMemberIds'])
export const sendingMessagesIdsSelector = path(['chat', 'sendingMessagesIds'])
export const resendingMessagesIdsSelector = path(['chat', 'resendingMessagesIds'])

export const messagesSelector = createSelector(
  [messageIdsSelector, dataSelector],
  compose(filter(identity), buildCollection('message')),
)

export const oldestMessageIdSelector = createSelector(
  messagesSelector,
  compose(prop('id'), head, sortBy(compose(DateTime.fromISO, prop('createdAt')))),
)

export const lastMessageIdSelector = createSelector(
  messagesSelector,
  compose(prop('id'), last, sortBy(compose(DateTime.fromISO, prop('createdAt')))),
)

export const chatSelector = createSelector([chatIdSelector, dataSelector], (chatId, data) =>
  chatId ? build(data, 'chat', chatId) : {},
)

export const readCursorSelector = createSelector(chatSelector, chat => prop('readCursor', chat))

export const sortedChatAttachmentsSelector = createSelector(
  messagesSelector,
  compose(
    flatten,
    map(({ attachments }) => sortBy(({ previewUrls }) => (isPresent(previewUrls) ? 1 : 0), attachments)),
    reverse,
  ),
)

export const attachmentsSelector = createSelector(
  [attachmentsIdsSelector, dataSelector],
  compose(filter(identity), buildCollection('attachment')),
)

export const attachmentsLoadingSelector = (state, chatId) =>
  loadingSelector(state, fetchChatAttachmentsEndpoint(chatId).endpoint)

export const nextAttachmentsCursorSelector = (state, id) =>
  nextPageSelector(state, fetchChatAttachmentsEndpoint(id).endpoint) || null

export const fetchAttachmentsMetaSelector = (state, id) =>
  endpointMetaSelector(state, fetchChatAttachmentsEndpoint(id).endpoint)

export const unreadMessagesSelector = createSelector(
  [messagesSelector, currentUserProfileSelector, readCursorSelector],
  (messages, currentUserProfile, readCursor) =>
    messages.filter(message => isUnreadMessage(message, currentUserProfile.chatUserId, readCursor)),
)

export const unreadMessagesCountSelector = createSelector([metaSelector, chatSelector], (meta, chat) => {
  const { endpoint } = fetchChatMessageEndpoint(chat.id)

  return path([endpoint, 'meta', 'unreadCount'], meta)
})

export const lastReadMessageSelector = createSelector(
  [messagesSelector, currentUserProfileSelector, readCursorSelector],
  (messages, currentUserProfile, readCursor) => {
    const firstUnreadMessageIndex = findLastIndex(
      message => isUnreadMessage(message, currentUserProfile.chatUserId, readCursor),
      messages,
    )

    const lastReadMessageIndex = firstUnreadMessageIndex + 1

    return messages[lastReadMessageIndex]
  },
)

export const firstUnreadMessageSelector = createSelector(
  [messagesSelector, currentUserProfileSelector, readCursorSelector],
  (messages, currentUserProfile, readCursor) => {
    const firstUnreadMessageIndex = findLastIndex(
      message => isUnreadMessage(message, currentUserProfile.chatUserId, readCursor),
      messages,
    )

    return messages[firstUnreadMessageIndex]
  },
)

export const isOfflineSelector = createSelector(
  [path(['chat', 'isOnline']), connectionStatusSelector],
  (isOnline, connectionStatus) => !isOnline && connectionStatus === WS_STATUSES.closed,
)

export const isUserChatMemberSelector = createSelector(
  [currentUserProfileSelector, chatSelector],
  (currentProfile, chat) => {
    if (!isPresent(currentProfile) || !isPresent(chat)) {
      return false
    }

    return includes(prop('chatUserId', currentProfile), prop('chatUserIds', chat))
  },
)

export const chatLoadingSelector = (state, id) => loadingSelector(state, getChatEndpoint(id).endpoint)

export const chatMessagesLoadingSelector = (state, id) => loadingSelector(state, fetchChatMessageEndpoint(id).endpoint)

export const chatMembersSelector = createSelector(
  [chatMemberIdsSelector, dataSelector],
  compose(filter(identity), buildCollection('chatUser')),
)

export const chatMembersLoadingSelector = (state, id) => loadingSelector(state, getChatMembersEndpoint(id).endpoint)

export const isSendingInProgressSelector = (state, messageId) => includes(messageId, sendingMessagesIdsSelector(state))

export const isUploadingInProgressSelector = (state, uploadId) => uploadStatusSelector(state)[uploadId]

export const isAnyUploadingInProgressSelector = createSelector(uploadStatusSelector, compose(includes(true), values))

export const attachmentIdsByUploadIdsSelector = createSelector(
  [(_, uploadIds) => uploadIds, uploadLinksSelector],
  compose(values, pick),
)

export const isResendNeededSelector = (state, messageId) => includes(messageId, resendingMessagesIdsSelector(state))
