const namespace = 'chats'

export const FETCH_CHATS = `${namespace}/FETCH_CHATS`
export const SET_CHATS = `${namespace}/SET_CHATS`
export const ADD_CHATS = `${namespace}/ADD_CHATS`
export const REMOVE_CHAT = `${namespace}/REMOVE_CHAT`
export const RESET_CHATS = `${namespace}/RESET_CHATS`
export const RECEIVE_CHAT = `${namespace}/RECEIVE_CHAT`
export const GET_CHAT = `${namespace}/GET_CHAT`
export const UPDATE_LAST_MESSAGE = `${namespace}/UPDATE_LAST_MESSAGE`
export const UPDATE_UNREAD_COUNTER = `${namespace}/UPDATE_UNREAD_COUNTER`
export const FETCH_UNREAD_COUNTER = `${namespace}/FETCH_UNREAD_COUNTER`
