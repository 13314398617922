import * as types from './types'

export const fetchClientForms = (clientId, pageParams = {}) => ({
  type: types.FETCH_CLIENT_FORMS,
  clientId,
  pageParams,
})

export const addClientFormsIds = ids => ({
  type: types.ADD_CLIENT_FORMS_IDS,
  ids,
})

export const resetClientForms = () => ({
  type: types.RESET_CLIENT_FORMS,
})

export const removeClientForm = id => ({
  type: types.REMOVE_CLIENT_FORM,
  id,
})

export const deleteClientForm = (clientFormId, feedId) => ({
  type: types.DELETE_CLIENT_FORM,
  clientFormId,
  feedId,
})
