import {
  recurringPaymentRoute,
  recurringPaymentSendRoute,
  recurringPaymentsRoute,
  recurringPaymentTransactionsRoute,
  recurringPaymentsPaymentMethodRoute,
  recurringPaymentCancelRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const getRecurringPaymentEndpoint = recurringPaymentId =>
  endpoint(types.GET_RECURRING_PAYMENT, 'GET', recurringPaymentRoute(recurringPaymentId))

export const deleteDraftRecurringPaymentEndpoint = recurringPaymentId =>
  endpoint(types.GET_RECURRING_PAYMENT, 'DELETE', recurringPaymentRoute(recurringPaymentId))

export const cancelRecurringPaymentEndpoint = recurringPaymentId =>
  endpoint(types.CANCEL_RECURRING_PAYMENT, 'POST', recurringPaymentCancelRoute(recurringPaymentId))

export const sendRecurringPaymentEndpoint = recurringPaymentId =>
  endpoint(types.SEND_RECURRING_PAYMENT, 'POST', recurringPaymentSendRoute(recurringPaymentId))

export const fetchRecurringPaymentTransactionsEndpoint = recurringPaymentId =>
  endpoint(types.FETCH_TRANSACTIONS, 'GET', recurringPaymentTransactionsRoute(recurringPaymentId))

export const requestRecurringPaymentEndpoint = endpoint(types.REQUEST_RECURRING_PAYMENT, 'POST', recurringPaymentsRoute)

export const saveDraftRecurringPaymentEndpoint = endpoint(
  types.SAVE_DRAFT_RECURRING_PAYMENT,
  'POST',
  recurringPaymentsRoute,
)
export const getPaymentMethodEndpoint = recurringPaymentId =>
  endpoint(types.GET_PAYMENT_METHOD, 'GET', recurringPaymentsPaymentMethodRoute(recurringPaymentId))

export const updatePaymentMethodEndpoint = recurringPaymentId =>
  endpoint(types.UPDATE_PAYMENT_METHOD, 'PUT', recurringPaymentsPaymentMethodRoute(recurringPaymentId))

export const updateRecurringPaymentEndpoint = recurringPaymentId =>
  endpoint(types.UPDATE_RECURRING_PAYMENT, 'PUT', recurringPaymentRoute(recurringPaymentId))
