import { getCountryCallingCode } from 'react-phone-number-input'
import PropTypes from 'prop-types'

const Option = ({ handleSelect, value, label, icon: Icon }) => (
  <div
    className="main-dropdown__item align-items-center justify-content-space-between"
    onClick={handleSelect(value)}
    role="button"
  >
    <span className="d-flex">
      <Icon country={value} label={label} />
      {label}
    </span>
    <span className="in-gray-700">{`+${getCountryCallingCode(value)}`}</span>
  </div>
)

Option.propTypes = {
  icon: PropTypes.elementType.isRequired,
  handleSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Option
