const manageSeats = {
  'manageSeats.title': 'Manage seats',
  'manageSeats.summary': 'Summary',
  'manageSeats.seats': 'Seats',
  'manageSeats.currentSeats': 'Current seats',
  'manageSeats.addSeats': 'Add more seats',
  'manageSeats.removeSeats': 'Remove seats',
  'manageSeats.reviewSeats': 'Review team seats',
  'manageSeats.addRemoveSeats': 'Add/remove seats',
  'manageSeats.cancelChangesToManageSeats': 'Cancel changes to manage seats',
  'manageSeats.currentTeamSize': 'Current team size',
  'manageSeats.updatedSeats': 'Updated team seats',
  'manageSeats.canRemoveFreeOnly': 'You can only remove free team seats',
  'manageSeats.seatsPrice': '{seats, number} {seats, plural, one {seat} other {seats}} x {price}',
  'manageSeats.totalAmount': 'Total amount',
  'manageSeats.youWillBeCharged': 'You will be charged on {date}',
  'manageSeats.youCanCancelUntil': 'You can cancel your request till {date}',
  'manageSeats.youCanRemoveOnly': 'You can only remove free team seats.',
  'manageSeats.planName': '{name} subscription',
  'manageSeats.seatsWillBeRemoved':
    '{count, number} {count, plural, one {seat has} other {seats have}} will be permanently removed from {date}. You can’t invite new team members for these seats.',
}

export default manageSeats
