import endpoint from 'utils/endpoint'
import { reviewRoute, getClientReviewTemplateRoute, clientCreateReviewRoute } from 'lib/apiRoutes'
import { DELETE_REVIEW, GET_REVIEW_TEMPLATE, CREATE_REVIEW, GET_REVIEW, UPDATE_REVIEW } from './types'

export const deleteClientAccountReviewEndpoint = reviewId => endpoint(DELETE_REVIEW, 'DELETE', reviewRoute(reviewId))
export const getClientReviewTemplateEndpoint = bookingUniqCode =>
  endpoint(GET_REVIEW_TEMPLATE, 'GET', getClientReviewTemplateRoute(bookingUniqCode))
export const getClientReviewEndpoint = reviewId => endpoint(GET_REVIEW, 'GET', reviewRoute(reviewId))
export const updateClientReviewEndpoint = reviewId => endpoint(UPDATE_REVIEW, 'PUT', reviewRoute(reviewId))
export const createClientReviewEndpoint = endpoint(CREATE_REVIEW, 'POST', clientCreateReviewRoute)
