import { createLogic } from 'redux-logic'
import build from 'redux-object'
import normalize from 'json-api-normalizer'
import { prop, head } from 'ramda'

import { RECEIVE_CHAT_UNREAD_MESSAGES_COUNT } from 'state/concepts/chat/types'
import updateDataHelper from 'utils/updateDataHelper'
import { currentChatUserIdSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'

const receiveChatUnreadMessagesCountOperation = createLogic({
  type: RECEIVE_CHAT_UNREAD_MESSAGES_COUNT,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const state = getState()
    const { data } = action
    const response = normalize(data)
    const chat = head(build(response, 'chat'))
    const chatUserId = prop('id', chat.performerChatUser)
    const currentChatUserId = currentChatUserIdSelector(state).toString()

    if (chatUserId === currentChatUserId) {
      const unreadMessagesCount = prop('unreadMessagesCount', chat)
      const readCursor = prop('readCursor', chat)
      const updateResponse = updateDataHelper(state.data, 'chat', [chat.id], {
        attributes: { unreadMessagesCount, readCursor },
      })

      dispatch(dataApiSuccess({ response: updateResponse }))
    }

    done()
  },
})

export default receiveChatUnreadMessagesCountOperation
