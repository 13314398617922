import { Field, Formik } from 'formik'
import Script from 'next/script'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactReduxContext } from 'react-redux'
import { useMount } from 'react-use'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { SENTRY_ENV } from 'lib/constants'
import { TEST_PAYMENT_METHODS } from 'lib/constants/payments'
import chargeWithCardReaderSchema from 'lib/yupLocalised/schemas/chargeWithCardReader'
import { fetchCardReaders as fetchCardReadersAction } from 'state/concepts/cardReaders/actions'
import { chargeWithCardReader } from 'state/concepts/payment/actions'
import { fetchConnectionToken } from 'utils/payments'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import FormattedCurrency from 'views/shared/FormattedCurrency'
import InputField from 'views/shared/InputField'
import Modal from 'views/shared/Modal'
import SelectField from 'views/shared/SelectField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import Spinner from 'views/shared/icons/Spinner'
import CardReadersSelect from './CardReadersSelect'

const ChargeWithCardReaderModal = ({ onClose, transaction }) => {
  const [terminal, setTerminal] = useState(null)

  const handleSubmit = useFormSubmit(chargeWithCardReader, { terminal, transaction })
  const fetchCardReaders = useDispatchAction(fetchCardReadersAction)

  const { store } = useContext(ReactReduxContext)

  const createTerminal = async () => {
    const result = window.StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken(store.httpClient),
      onUnexpectedReaderDisconnect: () => {},
    })

    setTerminal(result)
  }

  useMount(() => {
    fetchCardReaders()

    if (window.StripeTerminal) {
      createTerminal()
    }
  })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <Script src="https://js.stripe.com/terminal/v1/sdk.js" onLoad={createTerminal} />
      <Formik
        initialValues={{ cardReaderId: undefined, testCardNumber: '', testPaymentMethod: 'visa' }}
        onSubmit={handleSubmit}
        validationSchema={chargeWithCardReaderSchema}
      >
        {({ status, isSubmitting }) => (
          <div className="main-modal__container">
            <div className="main-modal__header">
              <h2 className="main-modal__title" data-cy="mark-as-paid-modal-title">
                <FormattedMessage id="payment.chargeWithCardReader.modal.title" />
              </h2>
              <button type="button" className="main-modal__close" onClick={onClose} disabled={isSubmitting}>
                <Cross dataCy="cross-icon" />
              </button>
            </div>
            <div className="main-modal__body">
              {status && status.base && <Alert className="mb-24" message={status.base} />}
              <div className="d-flex justify-content-between bg-blue-100 p-16">
                <span className="font-700 font-16" data-cy="mark-as-paid-modal-total-title">
                  <FormattedMessage id="payment.chargeWithCardReader.modal.total" />
                </span>
                <span className="payment-total-price font-16">
                  <FormattedCurrency price={transaction.amount} />
                </span>
              </div>

              {isSubmitting ? (
                <div className="d-flex flex-column align-items-center mt-24">
                  <div className="d-flex align-items-center justify-content-center wh-24-24">
                    <Spinner size={18} dataCy="spiner" />
                  </div>
                  <p className="text-body mb-0 mt-8 in-blue-gray-300">
                    <FormattedMessage id="payment.chargeWithCardReader.modal.processing" />
                  </p>
                </div>
              ) : (
                <>
                  <Field
                    className="main-input--label-offset-16 mt-24 mb-0"
                    component={CardReadersSelect}
                    id="cardReaderId"
                    name="cardReaderId"
                  />

                  {SENTRY_ENV !== 'production' && (
                    <>
                      <Field
                        component={InputField}
                        className="mt-24 mb-0"
                        id="testCardNumber"
                        name="testCardNumber"
                        label={{ id: 'payment.chargeWithCardReader.cardNumber' }}
                        placeholder={{ id: 'payment.chargeWithCardReader.cardNumber.placeholder' }}
                      />

                      <Field
                        name="testPaymentMethod"
                        className="mt-24 mb-0"
                        component={SelectField}
                        id="paymentMethod"
                        label={{ id: 'markAsPaid.modal.paymentMethod.label' }}
                        placeholder={{ id: 'markAsPaid.modal.paymentMethod.placeholder' }}
                        options={TEST_PAYMENT_METHODS}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="main-modal__footer">
              <Button
                className="mr-16"
                text={{ id: 'shared.cancel' }}
                kind="flat"
                onClick={onClose}
                disabled={isSubmitting}
              />
              <SubmitFormButton
                className="main-modal__footer-action"
                text={{ id: 'payment.chargeWithCardReader.modal.chargePayment' }}
                isLoading={false}
              />
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

ChargeWithCardReaderModal.propTypes = {
  transaction: PropTypes.shape({
    amount: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}
export default ChargeWithCardReaderModal
