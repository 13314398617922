const resetPassword = {
  'resetPassword.title': 'Reset your password',
  'resetPassword.info':
    'Please enter the email address you used when creating your account and we will send you a recovery link.',
  'resetPassword.submit': 'Send recovery link',
  'resetPassword.backToLogin': 'Back',
  'resetPassword.resendLink': 'Resend recovery link',
  'resetPassword.checkEmail': 'Check your email',
  'resetPassword.sendRecoveryLink': 'We sent a recovery link to your email address:',
  'resetPassword.requestNewLink': "Didn't receive the email? Check your junk mail or request another one.",
  'resetPassword.newPassword': 'New password',
  'resetPassword.setNewPassword': 'You need to set a new password for your account.',
  'resetPassword.createNewPassword': 'Create new password',
  'resetPassword.linkExpired.title': 'Reset password link has expired',
  'resetPassword.linkExpired.button': 'Log in',
}

export default resetPassword
