const namespace = 'waitlistRequests'

export const FETCH_WAITLIST_REQUESTS = `${namespace}/FETCH_WAITLIST_REQUESTS`
export const FETCH_WAITLIST_REQUEST = `${namespace}/FETCH_WAITLIST_REQUEST`
export const FILTER_WAITLIST_REQUESTS = `${namespace}/FILTER_WAITLIST_REQUESTS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const ADD_WAITLIST_REQUEST_IDS = `${namespace}/ADD_WAITLIST_REQUEST_IDS`
export const RESET_WAITLIST_REQUEST_IDS = `${namespace}/RESET_WAITLIST_REQUEST_IDS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const DELETE_WAITLIST_REQUEST = `${namespace}/DELETE_WAITLIST_REQUEST`
export const REMOVE_WAITLIST_REQUEST = `${namespace}/REMOVE_WAITLIST_REQUEST`
export const UPDATE_CLIENT_WAITLIST_REQUEST = `${namespace}/UPDATE_CLIENT_WAITLIST_REQUEST`
export const CREATE_WAITLIST_REQUEST = `${namespace}/CREATE_WAITLIST_REQUEST`
export const UPDATE_WAITLIST_REQUEST = `${namespace}/UPDATE_WAITLIST_REQUEST`
export const RESOLVE_WAITLIST_REQUEST = `${namespace}/RESOLVE_WAITLIST_REQUEST`
