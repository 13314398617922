import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusGone } from 'utils/getErrorStatus'
import { workspacesHttpClient } from 'lib/httpClient'
import redirect from 'utils/redirect'
import { VALIDATE_RESET_PASSWORD_TOKEN } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { setEmployeeId } from 'state/concepts/employee/actions'
import { validateResetPasswordEndpoint } from '../endpoints'

const validateResetPasswordToken = createLogic({
  type: VALIDATE_RESET_PASSWORD_TOKEN,
  latest: true,

  async process({ action: { token } }, dispatch, done) {
    const { endpoint, url } = validateResetPasswordEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))

      const { data } = await workspacesHttpClient.get(url, { params: { token } })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setEmployeeId(data.data.id))
    } catch (error) {
      if (isErrorStatusGone(error)) {
        redirect({ href: '/reset_password/link_expired' })
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default validateResetPasswordToken
