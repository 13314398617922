import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import setEmployeeCookies from 'utils/setEmployeeCookies'
import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { ENABLE_TWO_FACTOR_SETTINGS } from 'state/concepts/employee/types'
import { currentEmployeeSelector, currentWorkspaceSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal, showModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { setBackupCodes } from 'state/concepts/login/actions'
import { enableTwoFactorSettingsEndpoint } from '../endpoints'

const enableTwoFactorSettingsOperation = createLogic({
  type: ENABLE_TWO_FACTOR_SETTINGS,
  latest: true,

  async process({ httpClient, action: { values, form, setBackupCodesStep }, getState }, dispatch, done) {
    const { endpoint, url } = enableTwoFactorSettingsEndpoint
    const { password, phoneNumber, otpCode, methodChecker } = values
    const state = getState()
    const currentEmployee = currentEmployeeSelector(state)
    const { uniqCode } = currentWorkspaceSelector(state)
    const { id: userProfileId } = currentEmployee

    const params = {
      password,
      phone_number: phoneNumber,
      otp_code: otpCode,
      method_checker: methodChecker,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params, buildCustomBaseUrl(['workspaces', uniqCode]))
      const response = normalize(data, { endpoint })
      const userProfileResponse = updateDataHelper(getState().data, 'userProfile', userProfileId, {
        relationships: {
          userProfileTwoFactorSetting: {
            data: { id: data.data.id, type: 'userProfileTwoFactorSetting' },
          },
        },
      })
      const fullResponse = { ...response, ...userProfileResponse }

      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.employeeTwoFactorSettingsEnabled',
          },
        }),
      )

      const tokens = data.meta?.jwt
      const backupCodes = data.meta?.backup_codes

      if (tokens) {
        setEmployeeCookies(currentEmployee, tokens, { path: `/${uniqCode}` })
      }

      if (setBackupCodesStep) {
        dispatch(setBackupCodes(backupCodes))
        setBackupCodesStep()
      } else {
        dispatch(dataApiSuccess({ response: fullResponse, endpoint }))
        dispatch(hideModal())
        dispatch(
          showModal({
            modalType: 'FRESH_BACKUP_CODES_MODAL',
            modalProps: {
              backupCodes,
              setupMethodChecker: methodChecker,
            },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default enableTwoFactorSettingsOperation
