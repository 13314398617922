import PropTypes from 'prop-types'

import useExpertActions from 'hooks/waitlistRequests/useExpertActions'
import { companyWaitlistRequestEditRoute } from 'lib/routes'
import DropdownMenu from 'views/shared/DropdownMenu'
import Options from 'views/shared/icons/Options'

const Actions = ({ waitlistRequest, mainDropdownContainerClassName, isWaitlistPage }) => {
  const { canEdit, onDelete, canDelete, onSendMessage, canSendMessage, onBookAppointment, canBookAppointment } =
    useExpertActions({
      waitlist: waitlistRequest,
      isWaitlistPage,
    })

  return (
    <DropdownMenu
      shouldStopPropagation
      className="main-dropdown-btn"
      menuClassName="main-dropdown main-dropdown--many-level w-184"
      mainDropdownContainerClassName={mainDropdownContainerClassName}
      icon={
        <button
          className="main-dropdown-btn d-flex align-items-center justify-content-center wh-24-24"
          type="button"
          data-cy="dropdown-open-actions"
        >
          <Options className="main-dropdown-btn__icon" />
        </button>
      }
      items={[
        {
          isHidden: !canBookAppointment,
          itemProps: {
            text: { id: 'waitlistRequests.actions.bookAppointment' },
            onClick: onBookAppointment,
          },
        },
        {
          isHidden: !canSendMessage,
          itemProps: {
            text: { id: 'waitlistRequests.actions.sendMessage' },
            onClick: onSendMessage,
          },
        },
        {
          isHidden: !canEdit,
          itemProps: {
            text: { id: 'waitlistRequests.actions.editRequest' },
            isNextLink: true,
            href: companyWaitlistRequestEditRoute(waitlistRequest?.id),
          },
        },
        {
          isHidden: !canDelete,
          itemProps: {
            text: { id: 'shared.delete' },
            onClick: onDelete,
          },
        },
      ]}
    />
  )
}

Actions.defaultProps = {
  mainDropdownContainerClassName: '',
}

Actions.propTypes = {
  isWaitlistPage: PropTypes.bool.isRequired,
  waitlistRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  mainDropdownContainerClassName: PropTypes.string,
}

export default Actions
