import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { showNotification as showNotificationAction } from 'state/notifications/actions'
import Button from 'views/shared/Button'

const CopyButton = ({ message, ...rest }) => {
  const showNotification = useDispatchAction(showNotificationAction)

  const handleCopyLink = event => {
    event.stopPropagation()
    copy(message)
    showNotification({
      messageObject: { id: 'notifications.copyToClipboard' },
    })
  }

  return <Button text={{ id: 'shared.copyLink' }} onClick={handleCopyLink} {...rest} />
}

CopyButton.propTypes = {
  message: PropTypes.string.isRequired,
}

export default CopyButton
