import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_CUSTOM_FIELDS } from 'state/concepts/customFields/types'
import { setCustomFields } from 'state/concepts/customFields/actions'
import { fetchCustomFieldsEndpoint } from 'state/concepts/customFields/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const fetchCustomFieldsOperation = createLogic({
  type: FETCH_CUSTOM_FIELDS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchCustomFieldsEndpoint
    const params = {
      page: {
        size: 100,
        number: 1,
      },
      include: 'custom_field_options',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setCustomFields(map(prop('id'), data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchCustomFieldsOperation
