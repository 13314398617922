import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { localSettingsSelector } from 'state/concepts/videoConference/selectors'
import { setLocalSettings as setLocalSettingsAction } from 'state/concepts/videoConference/actions'
import { showNotification as showNotificationAction } from 'state/notifications/actions'
import { getDevices } from 'utils/videoConference'
import VideoConferenceSettingsModalComponent from './component'

class VideoConferenceSettingsModal extends React.Component {
  static propTypes = {
    setLocalSettings: PropTypes.func.isRequired,
    values: PropTypes.shape().isRequired,
    changeDevices: PropTypes.func,
    initialValues: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    notificationKind: PropTypes.string.isRequired,
  }

  static defaultProps = {
    changeDevices: () => Promise.resolve(),
  }

  state = {
    audioDevices: [],
    videoDevices: [],
    speakerDevices: [],
  }

  componentDidMount = async () => {
    const {
      initialValues: { audioDevice, videoDevice, speakerDevice },
    } = this.props

    navigator.mediaDevices?.addEventListener('devicechange', this.setDeviceStates)

    this.setDeviceStates(!audioDevice && !videoDevice && !speakerDevice)
  }

  setDeviceStates = async (shouldSetLocalSettings = true) => {
    const { setLocalSettings } = this.props

    const { availableAudioDevices, availableVideoDevices, availableSpeakerDevices } = await getDevices()

    this.setState({
      audioDevices: availableAudioDevices,
      videoDevices: availableVideoDevices,
      speakerDevices: availableSpeakerDevices,
    })

    if (shouldSetLocalSettings) {
      setLocalSettings({
        audioDevice: availableAudioDevices[0],
        videoDevice: availableVideoDevices[0],
        speakerDevice: availableSpeakerDevices[0],
      })
    }
  }

  componentWillUnmount = () => {
    navigator.mediaDevices?.removeEventListener('devicechange', this.setDeviceStates)
  }

  handleSubmit = async () => {
    const {
      values: { audioDevice, videoDevice, speakerDevice },
      changeDevices,
      initialValues,
      setLocalSettings,
      onClose,
      showNotification,
      notificationKind,
    } = this.props

    await changeDevices({
      audioDeviceId: audioDevice?.key,
      videoDeviceId: videoDevice?.key,
      speakerDeviceId: speakerDevice?.key,
      replaceAudio: audioDevice && audioDevice.key !== initialValues.audioDevice.key,
      replaceVideo: videoDevice && videoDevice.key !== initialValues.videoDevice.key,
    })
    setLocalSettings({ audioDevice, videoDevice, speakerDevice })
    onClose()
    showNotification({
      messageObject: { id: 'videoConference.settingsModal.notification' },
      kind: notificationKind,
    })
  }

  render() {
    return (
      <VideoConferenceSettingsModalComponent
        {...this.props}
        {...this.state}
        onSubmit={this.handleSubmit}
        isSubmitDisabled={isSubmitDisabled(this.props)}
      />
    )
  }
}

const mapStateToProps = state => ({
  settings: localSettingsSelector(state),
})

const mapDispatchToProps = {
  setLocalSettings: setLocalSettingsAction,
  showNotification: showNotificationAction,
}

export { VideoConferenceSettingsModal as VideoConferenceSettingsModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ settings: { audioDevice, videoDevice, speakerDevice } }) => ({
      audioDevice,
      videoDevice,
      speakerDevice,
    }),
    enableReinitialize: true,
  }),
)(VideoConferenceSettingsModal)
