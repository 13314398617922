import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { ACTIVATION_STATUSES } from 'lib/constants/formCategories'
import { updateFormCategoryEndpoint } from '../endpoints'
import { UPDATE_FORM_CATEGORY_STATUS } from '../types'

const updateFormCategoryStatusOperation = createLogic({
  type: UPDATE_FORM_CATEGORY_STATUS,
  latest: true,

  async process({ httpClient, action: { id, newStatus }, getState }, dispatch, done) {
    const { url, endpoint } = updateFormCategoryEndpoint(id)
    const params = {
      status: newStatus,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, params)

      const response = updateDataHelper(getState().data, 'formCategory', id, {
        attributes: { status: newStatus },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id:
              newStatus === ACTIVATION_STATUSES.active
                ? 'notifications.formCategoryActivated'
                : 'notifications.formCategoryDeactivated',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default updateFormCategoryStatusOperation
