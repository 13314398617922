import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import isPageOverflow from 'utils/isPageOverflow'
import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { FETCH_SERVICES } from 'state/concepts/services/types'
import { setServices, setBlankState, setCurrentPage, fetchServices } from 'state/concepts/services/actions'
import { fetchServicesEndpoint } from 'state/concepts/services/endpoints'
import { paginationSelector, sortParamsSelector, appliedFilters } from 'state/concepts/services/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const getServicesOperation = createLogic({
  type: FETCH_SERVICES,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchServicesEndpoint
    const state = getState()
    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: appliedFilters(state),
      include: 'service-category',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const data = await httpClient.get(url, { params }).then(resp => resp.data)
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setServices(data.data.map(item => item.id)))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(1))
        dispatch(fetchServices())
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default getServicesOperation
