import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'
import classNames from 'clsx'

import Cross from 'views/shared/icons/Cross'
import Tooltip from 'views/shared/Tooltip'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Time from 'views/shared/icons/Time'
import VideoO from 'views/shared/icons/VideoO'
import LocationO from 'views/shared/icons/LocationO'
import SwitchField from 'views/shared/SwitchField'
import LocationsSelectField from 'views/shared/LocationsSelectField'

const EditAvailabilitySidebarComponent = ({
  onClose,
  timeInterval,
  daysInterval,
  isOnlineDisabled,
  isOnlineShown,
  isRemoveShown,
  handleSave,
  handleRemove,
  isVideoCallsEnabled,
}) => (
  <Tooltip open overlayClassName="booking-tooltip-wrapper">
    <section className="booking-tooltip booking-tooltip-with-header">
      <div className="booking-tooltip__toolbar">
        <h3 className="booking-tooltip__info-title">
          <FormattedMessage id="availability.edit.title" />
        </h3>
        <div>
          <button type="button" onClick={onClose}>
            <Cross size={10} dataCy="cross-icon" className="in-gray-600 va-middle" />
          </button>
        </div>
      </div>
      <div className="booking-tooltip__body">
        <div className="booking-tooltip__info pt-8">
          <div className="d-flex">
            <div className="mr-16">
              <Time size={20} color="#6E798D" />
            </div>
            <div className="w-100">
              <p className="in-blue-gray-300 mb-0">{daysInterval}</p>
              <p className="in-blue-gray-800 mb-0">{timeInterval}</p>
            </div>
          </div>
        </div>

        {isOnlineShown && (
          <div className="booking-tooltip__info pt-24">
            <div className="d-flex">
              <div className="mr-16">
                <VideoO size={20} color="#6E798D" />
              </div>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <span className="main-switch__text ml-0 pt-0">
                  <FormattedMessage id="availability.edit.availableOnline" />
                </span>
                {isOnlineDisabled ? (
                  <Tooltip
                    overlayClassName="main-tooltip pr-4"
                    placement="left"
                    icon={
                      <div className="d-flex">
                        <Field
                          name="online"
                          id="online"
                          component={SwitchField}
                          className={classNames('mb-0 switch-reverse w-100 c-pointer', {
                            'main-swith--checked-disabled': isVideoCallsEnabled,
                          })}
                          disabled={isOnlineDisabled}
                          value="xui"
                        />
                      </div>
                    }
                  >
                    <FormattedMessage id="availability.edit.availableOnline.tooltip" />
                  </Tooltip>
                ) : (
                  <Field
                    name="online"
                    id="online"
                    component={SwitchField}
                    className="mb-0 switch-reverse w-100"
                    disabled={isOnlineDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="booking-tooltip__info pt-18">
          <div className="d-flex">
            <div className="mr-16 pt-12">
              <LocationO size={20} color="#6E798D" />
            </div>
            <div className="w-100">
              <Field
                name="locationIds"
                id="locationIds"
                className="max-w-280"
                component={LocationsSelectField}
                icon="alert"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="booking-tooltip__btns">
        {isRemoveShown && (
          <SubmitFormButton
            className="mr-auto in-red-500 mr-xs-4"
            text={{ id: 'shared.remove' }}
            kind="flat"
            onClick={handleRemove}
            allowPristine
          />
        )}
        <div>
          <Button className="mr-16 mr-xs-4" kind="flat" text={{ id: 'shared.cancel' }} onClick={onClose} />
          <SubmitFormButton text={{ id: 'shared.apply' }} type="submit" onClick={handleSave} allowPristine />
        </div>
      </div>
    </section>
  </Tooltip>
)

EditAvailabilitySidebarComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  timeInterval: PropTypes.string.isRequired,
  daysInterval: PropTypes.string.isRequired,
  isOnlineDisabled: PropTypes.bool.isRequired,
  isOnlineShown: PropTypes.bool.isRequired,
  isRemoveShown: PropTypes.bool.isRequired,
  isVideoCallsEnabled: PropTypes.bool.isRequired,
}

export default EditAvailabilitySidebarComponent
