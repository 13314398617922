const namespace = 'bookings'

export const FETCH_CALENDAR_EVENTS = `${namespace}/FETCH_CALENDAR_EVENTS`
export const SET_CALENDAR_EVENTS = `${namespace}/SET_CALENDAR_EVENTS`
export const ADD_CALENDAR_EVENT = `${namespace}/ADD_CALENDAR_EVENT`
export const FILTER_CALENDAR_EVENTS = `${namespace}/FILTER_CALENDAR_EVENTS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`
export const SET_BOOKINGS_DATE = `${namespace}/SET_BOOKINGS_DATE`
export const SET_TIMEZONE = `${namespace}/SET_TIMEZONE`
export const SET_BOOKINGS_VIEW = `${namespace}/SET_BOOKINGS_VIEW`
export const CANCEL_BOOKING = `${namespace}/CANCEL_BOOKING`
export const CANCEL_RECURRING_BOOKINGS = `${namespace}/CANCEL_RECURRING_BOOKINGS`
export const VALIDATE_CANCEL_BOOKING = `${namespace}/VALIDATE_CANCEL_BOOKING`
export const SET_CANCELLATION_RULES = `${namespace}/SET_CANCELLATION_RULES`
export const SET_CANCELLATION_ERROR = `${namespace}/SET_CANCELLATION_ERROR`
export const VALIDATE_RESCHEDULE_BOOKING = `${namespace}/VALIDATE_RESCHEDULE_BOOKING`
export const SET_RESCHEDULE_ERROR = `${namespace}/SET_RESCHEDULE_ERROR`
export const SET_BOOKINGS_BLANK_STATE = `${namespace}/SET_BOOKINGS_BLANK_STATE`
export const RESET_CANCELLATION_RULES = `${namespace}/RESET_CANCELLATION_RULES`
export const CHECK_TIME_CONFLICTS = `${namespace}/CHECK_TIME_CONFLICTS`
export const SET_BOOKING_FORM = `${namespace}/SET_BOOKING_FORM`
export const RESET_BOOKING_FORM = `${namespace}/RESET_BOOKING_FORM`
