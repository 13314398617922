import { SIGNATURE_TYPES } from 'lib/constants/eSignature'
import yup from 'lib/yupLocalised'

export default yup.object().shape({
  name: yup.string().required(),
  signatureType: yup.string().required(),
  isEsignatureDisclosureShown: yup.bool(),
  eSignatureAgree: yup
    .bool()
    .required()
    .when('isEsignatureDisclosureShown', {
      is: true,
      then: schema => schema.equals([true]),
    }),
  signatureImage: yup.string().when('signatureType', {
    is: SIGNATURE_TYPES.image,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
  signatureDraw: yup.string().when('signatureType', {
    is: SIGNATURE_TYPES.draw,
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
})
