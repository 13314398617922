const recurringPayments = {
  'recurringPayments.requestPayment.recurringSettings': 'How often do you want to charge your _(client)?',
  'recurringPayments.requestPayment.billingCycle': 'Billing cycle',
  'recurringPayments.requestPayment.billingCycle.weekly': 'Weekly',
  'recurringPayments.requestPayment.billingCycle.biweekly': 'Bi-weekly',
  'recurringPayments.requestPayment.billingCycle.monthly': 'Monthly',
  'recurringPayments.requestPayment.billingCycle.quarterly': 'Quarterly',
  'recurringPayments.requestPayment.billingCycle.yearly': 'Yearly',
  'recurringPayments.requestPayment.endsAfter': 'Ends after',
  'recurringPayments.requestPayment.endsAfter.placeholder': 'e.g., 5',
  'recurringPayments.requestPayment.endsAfter.occurences': 'occurrences',
  'recurringPayments.requestPayment.autoPay': 'Auto-pay',
  'recurringPayments.requestPayment.autoPay.description.on':
    '_(Client) can save the card details and get charged automatically at the end of each billing cycle.',
  'recurringPayments.requestPayment.autoPay.description.off':
    '_(Client) will get regular payment requests, and needs to pay each of them separately.',
  'recurringPayments.requestPayment.autoPayOn.alert': `Your _(client) will be charged automatically after their first payment, so you will not be able to record payments manually.`,
  'recurringPayments.requestPayment.alert.setPaymentMethods': 'Set up payment methods',
  'recurringPayments.requestPayment.alert.connectStripePaypal.owner': 'Connect Stripe to allow auto-payments.',
  'recurringPayments.requestPayment.alert.connectStripePaypal.adminOrExpert':
    'To allow auto-payments, ask the owner of your workspace to connect Stripe.',
  'recurringPayments.requestPayment.editActiveRecurring.alert':
    'Your changes will apply only to payment requests that will be issued in the future.',
  'recurringPayments.tab.label': 'Recurring payments',
  'recurringPayments.table.title.service': '_(Service)',
  'recurringPayments.table.title.client': '_(Client)',
  'recurringPayments.table.title.amount': 'Amount',
  'recurringPayments.table.title.status': 'Status',
  'recurringPayments.table.title.nextPayment': 'Next payment',
  'recurringPayments.table.title.billingCycle': 'Billing cycle',
  'recurringPayments.table.service.transactionCount': '{transactionCount} of {limit} sent',
  'recurringPayments.table.billingCycle.autoCharge.true': 'Auto-pay',
  'recurringPayments.table.billingCycle.autoCharge.false': 'Manual payments',

  'recurringPayments.blank.noPaymentsYet': 'No recurring payments yet',
  'recurringPayments.blank.subtitle.hasPaymentMethod':
    '<a>Create a recurring payment request</a> to start getting regular payments, e.g. weekly, or monthly.',
  'recurringPayments.blank.subtitle.noPaymentMethodWithPermission':
    'Connect at least one payment method in your <a>Payment settings</a> to request payments from _(clients).',
  'recurringPayments.blank.subtitle.noPermission':
    'To start requesting payments from _(clients), ask your workspace owner to connect at least one payment method.',
  'recurringPayments.blank.subtitle.enableAcceptPayments.adminOrOwner':
    'To start getting online payments, enable <a>Accept payments</a> feature.',
  'recurringPayments.blank.subtitle.enableAcceptPayments.expert':
    'To start getting online payments, ask your workspace owner to enable Accept payments feature.',
  'recurringPayments.blank.subtitle.enableCustomPayments.owner':
    'To start getting online payments, enable <a>Custom payments</a> feature.',
  'recurringPayments.blank.subtitle.enableCustomPayments.adminOrExpert':
    'To start getting online payments, ask your workspace owner to enable Custom payments feature.',

  'recurringPayments.table.action.view': 'View',
  'recurringPayments.table.action.edit': 'Edit',
  'recurringPayments.table.action.cancel': 'Cancel',
  'recurringPayments.table.action.delete': 'Delete',
  'recurringPayments.table.action.sendRequest': 'Send payment',

  'recurringPayments.cancel.confirmModal.title': 'Cancel recurring payment?',
  'recurringPayments.cancel.confirmModal.submit': 'Cancel recurring payment',
  'recurringPayments.cancel.confirmModal.body': 'Recurring Payment will be canceled and we will not charge you anymore',
  'recurringPayments.table.filters.search.placeholder': 'Search by _(service) name',
}

export default recurringPayments
