import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { resendCodeBySms } from 'state/concepts/employee/actions'
import { formatCountdownSeconds } from 'utils/dateTime'
import { MILLISECONDS_PER_SECOND } from 'lib/constants/timeUnits'
import Button from 'views/shared/Button'

const ResendCode = ({ phoneNumber }) => {
  const resendCode = useDispatchAction(resendCodeBySms, phoneNumber)
  const [leftSeconds, setLeftSeconds] = useState(0)

  const onResendCode = () => {
    resendCode()
    setLeftSeconds(60)
  }

  useEffect(() => {
    let timer
    if (leftSeconds > 0) {
      timer = setInterval(() => setLeftSeconds(prevSeconds => prevSeconds - 1), MILLISECONDS_PER_SECOND)
    }

    return () => clearInterval(timer)
  }, [leftSeconds])

  return (
    <p className="d-flex column-gap-8 text-body mt-16 mb-0" data-cy="resend-code">
      <span className="in-blue-gray-800">
        <FormattedMessage id="employeeTwoFactorSettings.setSms.didNotGetCode" />
      </span>
      {leftSeconds > 0 ? (
        <span className="in-gray-500" data-cy="resend-code-timer">
          <FormattedMessage
            id="employeeTwoFactorSettings.setSms.resendAvailableIn"
            values={{
              remainingTime: formatCountdownSeconds(leftSeconds),
            }}
          />
        </span>
      ) : (
        <Button
          className="main-btn--text-blue"
          kind="text"
          text={{ id: 'employeeTwoFactorSettings.setSms.resendCode' }}
          onClick={onResendCode}
          dataCy="resend-code-button"
        />
      )}
    </p>
  )
}

ResendCode.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
}

export default ResendCode
