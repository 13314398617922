import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ArrowDown from 'views/shared/icons/ArrowDown'

const ShowMoreButton = ({ toggleTruncate }) => (
  <div className="mt-4">
    <button className="in-blue-600 font-14 font-600" onClick={toggleTruncate} data-cy="show-all-btn">
      <span className="mr-8">
        <FormattedMessage id="shared.showAll" />
      </span>
      <ArrowDown size={12} color="currentColor" dataCy="arrow-down-icon" />
    </button>
  </div>
)

ShowMoreButton.propTypes = {
  toggleTruncate: PropTypes.func.isRequired,
}

export default ShowMoreButton
