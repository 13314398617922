import { join } from 'ramda'
import initials from 'utils/initials'

const profileInitials = ({ firstName, lastName, fullName, email }) => {
  if (fullName) {
    return initials(fullName)
  }

  return firstName || lastName ? initials(join(' ', [firstName, lastName])) : initials(email)
}

export default profileInitials
