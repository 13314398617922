import getRecurringPayment from './getRecurringPayment'
import deleteDraftRecurringPayment from './deleteDraftRecurringPayment'
import cancelRecurringPayment from './cancelRecurringPayment'
import sendRecurringPayment from './sendRecurringPayment'
import fetchTransactions from './fetchTransactions'
import requestRecurringPayment from './requestRecurringPayment'
import getPaymentMethod from './getPaymentMethod'
import updatePaymentMethod from './updatePaymentMethod'
import updateRecurringPayment from './updateRecurringPayment'
import cancelClientRecurringPayment from './cancelClientRecurringPayment'

export default [
  getRecurringPayment,
  deleteDraftRecurringPayment,
  cancelRecurringPayment,
  sendRecurringPayment,
  fetchTransactions,
  getPaymentMethod,
  updatePaymentMethod,
  requestRecurringPayment,
  updateRecurringPayment,
  cancelClientRecurringPayment,
]
