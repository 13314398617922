import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { REMOVE_EMPLOYEE_INVITATION } from 'state/concepts/employees/types'
import { fetchEmployees } from 'state/concepts/employees/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { removeEmployeeInvitationEndpoint } from 'state/concepts/employees/endpoints'

const removeEmployeeInvitationOperation = createLogic({
  type: REMOVE_EMPLOYEE_INVITATION,
  latest: true,

  async process({ action: { employeeId }, httpClient }, dispatch, done) {
    const { url, endpoint } = removeEmployeeInvitationEndpoint(employeeId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.employeeInvitationRemoved' } }))
      dispatch(fetchEmployees())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default removeEmployeeInvitationOperation
