import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import UploadInput from 'views/shared/UploadInput'
import Upload from 'views/shared/icons/Upload'

const UploadLogoComponent = ({ id, className, logoUrl, onSelectImage, onRemoveImage, isCircularCrop, title }) => (
  <div
    className={classNames(
      'image-uploader image-uploader__actions-wrap',
      { 'image-uploader--smooth-rounding': !isCircularCrop },
      className,
    )}
  >
    <div className="no-shrink">
      <UploadInput id={id} name={id} className="d-none" accept="image/jpeg,image/png" onChange={onSelectImage} />
      <label className={classNames('image-uploader__placeholder', { 'd-none': logoUrl })} htmlFor={id}>
        <Upload className="image-uploader__placeholder-icon" size={20} />
        <span className="text-caption d-block">
          <FormattedMessage {...title} />
        </span>
      </label>
      <div
        className={classNames('image-uploader__image m-auto', { 'd-none': !logoUrl })}
        style={{ backgroundImage: `${logoUrl ? `url("${logoUrl}")` : null}` }}
      />
    </div>
    <div className="image-uploader__content relative pt-8">
      <div className="image-uploader__image-actions">
        <label className="image-uploader__image-action" htmlFor={id}>
          <span className="d-block text-caption font-600 in-blue-600">
            <FormattedMessage id="uploadLogo.change" />
          </span>
        </label>
        <button type="button" onClick={onRemoveImage} className="in-blue-600 text-caption font-600">
          <FormattedMessage id="shared.delete" />
        </button>
      </div>
      <div className="image-uploader__notice">
        <FormattedMessage id="uploadLogo.notice" />
      </div>
    </div>
  </div>
)

UploadLogoComponent.defaultProps = {
  className: null,
  logoUrl: null,
  isCircularCrop: false,
}

UploadLogoComponent.propTypes = {
  className: PropTypes.string,
  logoUrl: PropTypes.string,
  isCircularCrop: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
}

export default UploadLogoComponent
