import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_REVIEW_SETTINGS } from 'state/concepts/reviewSettings/types'
import { updateReviewSettingsEndpoint } from 'state/concepts/reviewSettings/endpoints'

const updateReviewSettingsOperation = createLogic({
  type: UPDATE_REVIEW_SETTINGS,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url } = updateReviewSettingsEndpoint

    const { collectReviews, formDescription, formName, frequency, sendingDelay } = values

    const params = {
      collect_reviews: collectReviews,
      form_description: formDescription,
      form_name: formName,
      sending_delay: sendingDelay,
      frequency,
    }

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(showNotification({ messageObject: { id: 'notifications.reviewSettingsUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateReviewSettingsOperation
