import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const External = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M398.223 170.666v113.778h-284.444v625.777h625.777v-284.444h113.778v341.333c0 31.42-25.469 56.889-56.889 56.889h-739.555c-31.419 0-56.889-25.469-56.889-56.889v-739.555c0-31.419 25.47-56.889 56.889-56.889h341.333zM1024 0v455.111h-113.778l-0.006-260.949-443.324 443.398-80.453-80.452 443.279-443.329h-260.83v-113.778h455.111z" />
  </svg>
)

External.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

External.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default External
