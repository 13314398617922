import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const GroupFilled = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M384 448H0c0-103.68 74.353-153.6 192-153.6S384 344.32 384 448zM192 243.2c-49.28 0-89.6-40.32-89.6-89.6S142.72 64 192 64c49.28 0 89.6 40.32 89.6 89.6s-40.32 89.6-89.6 89.6zM435.166 448c-.785-42.318-15.142-76.37-41.001-101.555C467.036 352.02 512 385.232 512 448h-76.834zM371.2 294.399c-35.2 0-64-28.8-64-64s28.8-64 64-64 64 28.8 64 64-28.8 64-64 64z" />
  </svg>
)

GroupFilled.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

GroupFilled.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default GroupFilled
