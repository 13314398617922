import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import profileName from 'utils/profileName'
import { deactivateEmployee as deactivateEmployeeAction } from 'state/concepts/employees/actions'
import { loadingSelector } from 'state/data/selectors'
import { deactivateEmployeeEndpoint } from 'state/concepts/employees/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

const EmployeesDeactivateSchedulerModal = ({ employee, onClose }) => {
  const { endpoint } = deactivateEmployeeEndpoint(employee.id)
  const onSubmit = useDispatchAction(deactivateEmployeeAction, employee.id)
  const isLoading = useSelector(state => loadingSelector(state, endpoint))

  return (
    <ConfirmModal
      title={{
        id: 'employeesDeactivateSchedulerModal.title',
      }}
      bodyText={[
        {
          id: 'employeesDeactivateSchedulerModal.description',
          values: { name: <span className="font-600">{profileName(employee)}</span> },
        },
      ]}
      submitText={{ id: 'employeesDeactivateModal.button' }}
      onSubmit={onSubmit}
      onClose={onClose}
      isLoading={isLoading}
    />
  )
}

EmployeesDeactivateSchedulerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
}

export default EmployeesDeactivateSchedulerModal
