import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_RECURRING_PAYMENT_ID,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_BLANK_STATE,
  SET_TRANSACTIONS_PAGE,
  SET_PAYMENT_METHOD_ID,
} from './types'

const recurringPaymentId = (state = null, action) => {
  switch (action.type) {
    case SET_RECURRING_PAYMENT_ID:
      return action.recurringPaymentId
    default:
      return state
  }
}

const transactionIds = (state = [], action) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return action.transactionIds
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const paymentMethodId = (state = null, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_ID:
      return action.paymentMethodId
    default:
      return state
  }
}

export default combineReducers({
  recurringPaymentId,
  transactionIds,
  isBlankState,
  pagination,
  paymentMethodId,
})
