import { Field } from 'formik'
import PropTypes from 'prop-types'

import { schedulingBookingModalBodyContainer } from 'utils/bookingCalendar'
import DropdownClientField from 'views/shared/DropdownClientField'
import InputField from 'views/shared/InputField'
import UserAltOIcon from 'views/shared/icons/UserAltO'

const ClientSelectComponent = ({ isEdit, showClientForm, isWaitlist }) =>
  !isEdit && (
    <div className="booking-tooltip__info booking-tooltip__info-divider">
      <div className="d-flex">
        <div className="mr-16 pt-36">
          <UserAltOIcon size={18} color="#6e798d" />
        </div>
        <div id="select_client" className="max-w-280 w-100">
          <Field
            className="mb-16 booking-tooltip__select-dropdown select-with-clear-wrap select-person-dropdown"
            name="clientProfile.idOrEmail"
            id="clientProfile.idOrEmail"
            component={DropdownClientField}
            label={{ id: 'scheduleAppointment.client' }}
            icon="alert"
            withAvatar
            mountToElement
            data-cy="client-profile-id-or-email"
            actionArgs={{ active: false }}
            getPopupContainer={schedulingBookingModalBodyContainer}
            disabled={isWaitlist}
          />
          {showClientForm && (
            <>
              <Field
                name="clientProfile.firstName"
                id="clientProfile.firstName"
                icon="alert"
                component={InputField}
                label={{ id: 'scheduleAppointment.firstName' }}
                data-cy="client-first-name"
              />
              <Field
                name="clientProfile.lastName"
                id="clientProfile.lastName"
                icon="alert"
                component={InputField}
                label={{ id: 'scheduleAppointment.lastName' }}
                data-cy="client-last-name"
              />
            </>
          )}
        </div>
      </div>
    </div>
  )

ClientSelectComponent.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isWaitlist: PropTypes.bool.isRequired,
  showClientForm: PropTypes.bool.isRequired,
}

export default ClientSelectComponent
