import mixpanel from 'lib/mixpanel'
import { EVENTS, GROUP_KEYS, PROPERTY_KEYS, PROPERTY_VALUES } from 'lib/constants/mixpanel'

const { workspace: workspaceGroupKey } = GROUP_KEYS
const { signUpCompleted, signUpEmailPrompt, signUpSetPassword } = EVENTS
const { userRole, $email, email, workspace: workspacePropKey } = PROPERTY_KEYS
const { workspaceOwner } = PROPERTY_VALUES

export const trackSignUpCompleted = currentEmployee => {
  const {
    email: trackedEmail,
    workspace: { uniqCode },
  } = currentEmployee

  mixpanel.alias(currentEmployee.id)

  mixpanel.add_group(workspaceGroupKey, uniqCode)

  mixpanel.people.set({
    [userRole]: workspaceOwner,
    [$email]: trackedEmail,
  })

  mixpanel.track(signUpCompleted, {
    [workspacePropKey]: uniqCode,
    [email]: trackedEmail,
    [userRole]: workspaceOwner,
  })
}

export const trackSignUpEmailPrompt = () => {
  mixpanel.track(signUpEmailPrompt, {
    [userRole]: workspaceOwner,
  })
}

export const trackSignUpSetPassword = trackedEmail => {
  mixpanel.track(signUpSetPassword, {
    [userRole]: workspaceOwner,
    [email]: trackedEmail,
  })
}
