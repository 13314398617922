import { last, prop } from 'ramda'
import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'

import { promocodesSelector } from 'state/concepts/promocodes/selectors'
import { ADD_PROMOCODE } from 'state/concepts/promocodes/types'
import { setPromocodes } from 'state/concepts/promocodes/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import assignFormErrors from 'utils/form/assignFormErrors'
import requestErrorHandler from 'lib/requestErrorHandler'
import { appliedPromocodeValidationEndpoint } from '../endpoints'

const addPromocodeOperation = createLogic({
  type: ADD_PROMOCODE,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess }, getState }, dispatch, done) {
    const { endpoint, url } = appliedPromocodeValidationEndpoint
    const { code, promotionType } = values
    const previousAppliedCode = prop('code', last(promocodesSelector(getState())))
    const params = {
      code,
      previous_applied_code: previousAppliedCode,
      promotion_type: promotionType,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })
      const promocode = build(response, 'promocode')[0]

      dispatch(setPromocodes(promocode.id))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.promocodeAdded' } }))

      onSuccess?.()
      form.resetForm({ values: { code: '' } })
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, endpoint, dispatch, form })
      }
    }

    form.setSubmitting(false)
    done()
  },
})

export default addPromocodeOperation
