import { createSelector } from '@reduxjs/toolkit'
import { find, path, pipe, prop, propEq } from 'ramda'

import { FEATURE_STATUSES } from 'lib/constants/workspaceFeatures'
import { dataSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

export const workspaceFeaturesIds = path(['workspaceFeatures', 'ids'])

export const workspaceFeaturesSelector = createSelector(
  [workspaceFeaturesIds, dataSelector],
  buildCollection('workspaceFeature'),
)

export const workspaceFeatureByKeySelector = createSelector(
  [workspaceFeaturesSelector, (_state, featureKey) => featureKey],
  (workspaceFeatures, featureKey) => find(propEq(featureKey, 'key'), workspaceFeatures),
)

export const isEnableWorkspaceFeatureSelector = pipe(
  workspaceFeatureByKeySelector,
  feature => feature?.status === FEATURE_STATUSES.enabled,
)

export const updatedAtWorkspaceFeatureSelector = pipe(workspaceFeatureByKeySelector, prop('updatedAt'))
