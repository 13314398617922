const intakeFormsFeature = {
  'intakeFormsFeature.title': 'Forms',
  'intakeFormsFeature.subtitle': '<span>in</span> Forms & documents',
  'intakeFormsFeature.description': 'Collect information from _(experts) and _(clients) to provide better _(services).',
  'intakeFormsFeature.manageFeatures': 'Set up forms',
  'intakeFormsFeature.upgradePlan': 'Upgrade plan',
  'intakeFormsFeature.overview': 'Forms overview',
  'intakeFormsFeature.toLearnMores': 'To learn more, read our help center article on <link>managing your forms</link>.',
  'intakeFormsFeature.overview.text1': 'Save time and provide better _(services) with ExpertBox Forms.',
  'intakeFormsFeature.overview.text2': 'Customize your forms:',
  'intakeFormsFeature.overview.text3':
    'Create custom forms that fit your needs and let you get all the information necessary to provide high-quality _(service).',
  'intakeFormsFeature.overview.text4': 'Personalize the experience with custom thank-you pages.',
  'intakeFormsFeature.overview.text5': 'Streamline data collection:',
  'intakeFormsFeature.overview.text6':
    'Add different field types like text, file uploads, dropdowns, multiple choices, dates, and more.',
  'intakeFormsFeature.overview.text7':
    'Seamlessly integrate CRM fields into forms, ensuring seamless data transfer to _(client) profiles.',
  'intakeFormsFeature.overview.text8': 'Automatically send forms to _(experts) or _(clients):',
  'intakeFormsFeature.overview.text9':
    'Assign forms to _(services) and ask the respondents to complete them before, during, or after their _(appointment).',
  'intakeFormsFeature.overview.text10':
    'Eliminate manual work and send forms when a new _(client) enters your workspace or deletes their account.',
  'intakeFormsFeature.overview.text11': 'Send notifications and reminders:',
  'intakeFormsFeature.overview.text12':
    'Set expiration dates for each form and send automatic reminders until the form is complete.',
  'intakeFormsFeature.overview.text13': 'Get an email and an in-app notification as soon as the form is submitted.',
  'intakeFormsFeature.overview.text14':
    'These forms are secure and comply with all HIPAA, ePHI, and GDPR requirements.',
  'intakeFormsFeature.disable.title': 'Disable forms feature?',
  'intakeFormsFeature.disable.description':
    'This won’t affect any of the forms sent or submitted previously. You won’t be able to create forms or send them to _(clients).',
  'intakeFormsFeature.availableOnlyForAdvanced': 'Forms is a Premium feature.',
  'intakeFormsFeature.setUpForms': 'Set up forms',
}

export default intakeFormsFeature
