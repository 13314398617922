import { combineReducers } from '@reduxjs/toolkit'
import { without } from 'ramda'

import {
  SET_CLIENTS,
  REMOVE_CLIENTS,
  SET_CLIENTS_PAGE,
  SET_CLIENTS_SORT_ORDER,
  SET_CLIENTS_FILTER_PARAMS,
  SET_CLIENTS_BLANK_STATE,
  DECREMENT_TOTAL_COUNT,
  INCREMENT_TOTAL_COUNT,
  SET_TOTAL_COUNT,
} from './types'

const clientIds = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return action.clientIds
    case REMOVE_CLIENTS:
      return without(action.clientIds, state)
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_CLIENTS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_CLIENTS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const sort = (state = { sortKey: 'first_name', direction: 'asc' }, action) => {
  switch (action.type) {
    case SET_CLIENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' }
    default:
      return state
  }
}

const filtersInitialState = {
  name: '',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_CLIENTS_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const totalCount = (state = 0, action) => {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return action.count
    case INCREMENT_TOTAL_COUNT:
      return state + 1
    case DECREMENT_TOTAL_COUNT:
      return state - 1
    default:
      return state
  }
}

export default combineReducers({
  clientIds,
  isBlankState,
  pagination,
  sort,
  filters,
  totalCount,
})
