import { createLogic } from 'redux-logic'
import cookies from 'component-cookie'
import { prop } from 'ramda'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import removeEmployeeCookies from 'utils/removeEmployeeCookies'
import { workspacesHttpClient } from 'lib/httpClient'
import redirect from 'utils/redirect'
import { hideModal } from 'state/modal/actions'
import { BULK_EMPLOYEE_LOGOUT } from 'state/concepts/session/types'
import { recentWorkspacesSelector } from 'state/concepts/recentWorkspaces/selectors'
import { bulkEmployeeLogoutSuccess } from 'state/concepts/session/actions'
import { closeConnection } from 'state/concepts/webSockets/actions'
import { isOpenedConnectionSelector } from 'state/concepts/webSockets/selectors'
import { usersSessionBulkLogout } from 'lib/apiRoutes'
import { loginRoute } from 'lib/routes'
import { EMPLOYEE_LOGOUT_EVENT, EMPLOYEE_NAMESPACE_PREFIX } from 'lib/constants/broadcastChannel'
import { broadcastMessage } from 'lib/broadcastChannel'

const bulkEmployeeLogoutOperation = createLogic({
  type: BULK_EMPLOYEE_LOGOUT,
  latest: true,

  async process({ getState }, dispatch, done) {
    const accessToken = JSON.parse(cookies('tokens')).access
    const state = getState()
    const recentWorkspaces = recentWorkspacesSelector(state)
    const isOpenedConnection = isOpenedConnectionSelector(state)

    const data = recentWorkspaces.map(
      ({ userProfileId, tokens }) =>
        tokens && {
          user_profile_id: userProfileId,
          refresh_token: prop('refresh', tokens),
        },
    )
    const params = {
      auth_data: data,
    }
    const config = {
      headers: {
        Authorization: accessToken,
      },
    }

    await workspacesHttpClient.post(usersSessionBulkLogout, params, config)

    recentWorkspaces.forEach(({ uniqCode, userProfileId }) => {
      removeEmployeeCookies({ path: `/${uniqCode}` })
      removeEmployeeCookies({ path: COOKIES_PATH })
      const namespace = `${EMPLOYEE_NAMESPACE_PREFIX}-${userProfileId}`
      broadcastMessage(`${namespace}/${EMPLOYEE_LOGOUT_EVENT}`)
    })
    dispatch(hideModal())

    await redirect({ href: loginRoute })

    dispatch(bulkEmployeeLogoutSuccess())
    if (isOpenedConnection) {
      dispatch(closeConnection())
    }
    done()
  },
})

export default bulkEmployeeLogoutOperation
