import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CircleChecked = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256 .003C114.43.003.003 114.43.003 256S114.43 511.997 256 511.997 511.997 397.569 511.997 256 397.569.003 256 .003zm120.505 340.276c10.005 10.005 10.005 26.199 0 36.204-5.003 4.981-11.541 7.504-18.102 7.504s-13.099-2.502-18.102-7.504L256 292.182l-84.301 84.301c-5.003 4.981-11.541 7.504-18.102 7.504s-13.1-2.502-18.102-7.504c-10.005-10.005-10.005-26.199 0-36.204l84.301-84.301-84.301-84.301c-10.005-10.006-10.005-26.177 0-36.204s26.198-10.005 36.204 0L256 219.774l84.301-84.301c10.005-10.005 26.199-10.005 36.204 0s10.005 26.198 0 36.204l-84.301 84.301 84.301 84.301z" />
  </svg>
)

CircleChecked.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CircleChecked.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CircleChecked
