const smsRemindersFeature = {
  'smsRemindersFeature.title': 'SMS reminders & notifications',
  'smsRemindersFeature.subtitle': '<span>in</span> Reminders & notifications',
  'smsRemindersFeature.description': 'Reach your _(clients) in a fast and convenient way, wherever they are.',
  'smsRemindersFeature.availableOnlyForAdvanced': 'Available only for advanced plans',
  'smsRemindersFeature.manageFeatures': 'Set SMS notifications',
  'smsRemindersFeature.upgradePlan': 'Upgrade plan',
  'smsRemindersFeature.overview': 'SMS reminders & notifications overview',
  'smsRemindersFeature.overview.text1':
    'SMS notifications are a fast and simple way to ensure you always reach your _(clients), wherever they are.',
  'smsRemindersFeature.overview.text2':
    'Choose the notifications you want to send to your team members and _(clients).',
  'smsRemindersFeature.overview.text3': 'Let _(clients) decide if they want to be reached via SMS.',
  'smsRemindersFeature.overview.text4': "Know that your _(clients) won't miss a thing.",
  'smsRemindersFeature.overview.text5':
    'Our SMS notification system is affordable and flexible, allowing you to deliver high-quality _(service) at your _(clients)’ fingertips.',
  'smsRemindersFeature.toLearnMores':
    'To learn more, read our help center article on <link>managing your SMS notifications</link>.',
  'smsRemindersFeature.disableModal.title': 'Disable SMS reminders & notifications?',
  'smsRemindersFeature.disableModal.alert':
    'You currently have SMS enabled for two-step verification. Disabling SMS will switch team members who use this method to email verification.',
  'smsRemindersFeature.disableModal.text':
    'Your _(clients) and team members won’t be getting SMS reminders & notifications anymore.',
  'smsRemindersFeature.phoneNumberAlert.text':
    'Please fill in the phone number in account settings to receive SMS notifications <link>Account settings</link>',
}

export default smsRemindersFeature
