import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { LOCATION_STATUS, ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { searchLocations as searchLocationsAction } from 'state/concepts/locations/actions'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import { searchedLocationsSelector, selectedLocationsSelector } from 'state/concepts/locations/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import { loadingSelector } from 'state/data/selectors'
import DropdownLocationFieldComponent from './component'

class DropdownLocationField extends React.Component {
  static propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedLocations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    currentEmployee: PropTypes.shape().isRequired,
    searchLocations: PropTypes.func.isRequired,
    excludeArray: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    videoCallProvider: PropTypes.string,
    field: PropTypes.shape().isRequired,
  }

  static defaultProps = {
    isLoading: undefined,
    excludeArray: [],
    videoCallProvider: undefined,
  }

  state = {
    isOpen: false,
  }

  get locationOptions() {
    const { locations, selectedLocations, field } = this.props
    const { isOpen } = this.state

    return isOpen || field.value === ONLINE_LOCATION_OPTION.value ? locations : selectedLocations
  }

  get videoCallProvider() {
    const { videoCallProvider, currentEmployee } = this.props

    return videoCallProvider || currentEmployee.videoCallProvider
  }

  handleDropdownVisibleChange = open => {
    const { searchLocations, excludeArray } = this.props

    if (open) {
      searchLocations({
        resetResults: true,
        filter: { exclude_locations: excludeArray, status: LOCATION_STATUS.active },
      })
    }

    this.setState({ isOpen: open })
  }

  render = () => (
    <DropdownLocationFieldComponent
      {...this.props}
      {...this.state}
      locationOptions={this.locationOptions}
      handleDropdownVisibleChange={this.handleDropdownVisibleChange}
      videoCallProvider={this.videoCallProvider}
    />
  )
}

const mapDispatchToProps = {
  searchLocations: searchLocationsAction,
}

const mapStateToProps = (state, { field }) => ({
  isLoading: loadingSelector(state, fetchLocationsEndpoint.endpoint),
  locations: searchedLocationsSelector(state),
  selectedLocations: selectedLocationsSelector(state, field.value),
  isVideoCallsEnabled: isEnableWorkspaceFeatureSelector(state, FEATURE_KEYS.acceptVideoCalls),
  currentEmployee: currentEmployeeSelector(state),
})

export { DropdownLocationField as DropdownLocationFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DropdownLocationField)
