import cancelDowngradeSeats from './cancelDowngradeSeats'
import cancelDowngradeStorage from './cancelDowngradeStorage'
import createSetupIntent from './createSetupIntent'
import downloadSubscriptionTransaction from './downloadSubscriptionTransaction'
import fetchBillingDetail from './fetchBillingDetail'
import fetchSubscriptionTransactions from './fetchSubscriptionTransactions'
import getBillingAccount from './getBillingAccount'
import renewSubscription from './renewSubscription'
import retryPayment from './retryPayment'
import updatePaymentMethod from './updatePaymentMethod'
import setBillingAccount from './setBillingAccount'
import updateBillingAddress from './updateBillingAddress'
import updateTeamSeats from './updateTeamSeats'

export default [
  fetchBillingDetail,
  fetchSubscriptionTransactions,
  getBillingAccount,
  setBillingAccount,
  createSetupIntent,
  updatePaymentMethod,
  updateTeamSeats,
  cancelDowngradeSeats,
  downloadSubscriptionTransaction,
  retryPayment,
  renewSubscription,
  updateBillingAddress,
  cancelDowngradeStorage,
]
