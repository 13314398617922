import normalize from 'json-api-normalizer'
import { keys } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchEmployeeProfileEndpoint } from 'state/concepts/employees/endpoints'
import { FETCH_EMPLOYEES_PROFILE } from 'state/concepts/employees/types'
import { setRecurringAvailability } from 'state/concepts/recurringAvailability/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const fetchProfileOperation = createLogic({
  type: FETCH_EMPLOYEES_PROFILE,
  latest: true,

  async process({ httpClient, action: { employeeId } }, dispatch, done) {
    const { endpoint, url } = fetchEmployeeProfileEndpoint(employeeId)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        include: [
          'user-profiles-services.service',
          'user-profiles-services.service-category',
          'recurring-availabilities',
          'user-profile-two-factor-setting',
        ].join(','),
      }
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setRecurringAvailability(keys(response.recurringAvailability)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchProfileOperation
