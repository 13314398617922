import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { UPDATE_TASK } from 'state/concepts/tasks/types'
import { updateTaskEndpoint } from 'state/concepts/tasks/endpoints'
import { setTaskToEdit } from 'state/concepts/tasks/actions'
import { UNASSIGNED_ID } from 'lib/constants/tasks'

const updateTaskOperation = createLogic({
  type: UPDATE_TASK,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { dueDate, name, clientProfileId, assigneeId, id } = values
    const { url, endpoint } = updateTaskEndpoint(id)

    const params = {
      due_date: dueDate,
      name,
      client_profile_id: clientProfileId,
      assignee_id: assigneeId !== UNASSIGNED_ID ? assigneeId : undefined,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))
      dispatch(setTaskToEdit(null))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateTaskOperation
