/* eslint-disable @next/next/no-html-link-for-pages */
import PropTypes from 'prop-types'

import Logo from 'views/shared/Logo'

const Error = ({ children }) => (
  <div className="app-wrap">
    <header className="main-header">
      <Logo />
    </header>
    <div className="app-wrap__content">
      <div className="app-wrap__main">
        <div className="app-wrap__viewport p-0">
          <div className="error-page">
            <div className="container">{children}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Error.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Error
