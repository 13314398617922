import { combineReducers } from '@reduxjs/toolkit'
import { filter, uniq, without } from 'ramda'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.ADD_FILES_SHARED_WITH_ME_IDS:
      return uniq([...state, ...action.ids])
    case types.SET_FILES_SHARED_WITH_ME_IDS:
      return action.ids
    case types.REMOVE_FILES_SHARED_WITH_ME_BY_IDS:
      return without(action.ids, state)
    case types.RESET_FILES_SHARED_WITH_ME_IDS:
      return []
    case types.REMOVE_FILE_ID:
      return filter(id => id !== action.fileId, state)
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SELECTED_IDS:
      return action.ids
    case types.RESET_SELECTED_IDS:
    case types.RESET_FILES_SHARED_WITH_ME_IDS:
      return []
    case types.REMOVE_FILES_SHARED_WITH_ME_BY_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const currentFolderId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_FOLDER_ID:
      return action.id
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
  employees: [],
  types: [],
  shared: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.params }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const folderToMoveIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_FOLDER_TO_MOVE_IDS:
      return uniq([...state, ...action.ids])
    case types.SET_FOLDER_TO_MOVE_IDS:
      return action.ids
    case types.RESET_FOLDER_TO_MOVE_IDS:
      return []
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isBlankState,
  selectedIds,
  currentFolderId,
  filters,
  folderToMoveIds,
})
