import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { DefaultPlayer as Video } from 'react-html5video'
import { FormattedMessage, useIntl } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { customNextImageLoader } from 'lib/image'
import { setCurrentPlaySource } from 'state/concepts/audio/actions'
import { isAudio, isPDF, isPicture, isPlayableVideo, isPreviewableFiles } from 'utils/file'
import Button from 'views/shared/Button'
import PreviewPdf from 'views/shared/FilesPreviewPdf'

const Slide = ({ url, mimeType, withImageLoader }) => {
  const { formatMessage } = useIntl()
  const [isImageLoadSuccess, setImageLoadSuccess] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const handlePlayPause = () => setIsPlaying(prev => !prev)
  const setCurrentPlay = useDispatchAction(setCurrentPlaySource, url)

  useEffect(() => {
    if (isPlaying) {
      setCurrentPlay()
    }
  }, [isPlaying, setCurrentPlay])

  return (
    <div className="files-preview__slide" data-cy="preview-carousel-slide">
      {isPicture(mimeType) && isImageLoadSuccess && (
        <Image
          className="files-preview__slide-img"
          src={url}
          loader={withImageLoader ? customNextImageLoader : undefined}
          alt={formatMessage({ id: 'shared.attachment' })}
          layout="fill"
          onError={() => {
            setImageLoadSuccess(false)
          }}
        />
      )}
      {isAudio(mimeType) && (
        <Video
          controls={['PlayPause', 'Seek', 'Time', 'Volume']}
          className="files-preview__audio"
          onPlay={handlePlayPause}
          onPause={handlePlayPause}
          data-cy="files-preview-slide-audio"
        >
          <source src={url} type={mimeType} />
        </Video>
      )}
      {isPDF(mimeType) && <PreviewPdf file={url} data-cy="files-preview-slide-pdf" />}
      {isPlayableVideo(mimeType) && (
        <Video onPlay={handlePlayPause} onPause={handlePlayPause} data-cy="preview-carousel-slide-video">
          <source src={url} type={mimeType} />
        </Video>
      )}
      {(!isPreviewableFiles(mimeType) || !isImageLoadSuccess) && (
        <div data-cy="files-preview-slide-file">
          <div className="files-preview__slide-file-wrap">
            <p className="text-subheader tight-font-family font-600 mb-0">
              <FormattedMessage id="filesPreview.noPreviewAvailable" />
            </p>
            <Button
              className="pl-16 mt-24"
              size="medium"
              iconWrapWithTextClassName="wh-24-24 d-flex align-items-center justify-content-center"
              icon="download"
              iconSize={20}
              text={{ id: 'shared.download' }}
              isLink
              isRawLink
              href={url}
              target="_blank"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Slide.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape()]).isRequired,
  mimeType: PropTypes.string.isRequired,
  withImageLoader: PropTypes.bool.isRequired,
}

export default Slide
