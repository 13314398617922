import { FormattedMessage } from 'react-intl'

import {
  otpCodeSchema,
  verifyPhoneNumberSchema,
  passwordSchema,
} from 'lib/yupLocalised/schemas/userProfileTwoFactorSettings'
import isSmsOrAppEnabled from 'utils/workspace/workspaceTwoFactorSettings'
import {
  disableTwoFactorSettings,
  enableTwoFactorSettings,
  getOtpCodeBySms,
  updateTwoFactorSettings,
  validatePasswordOnTwoFactor,
} from 'state/concepts/employee/actions'
import PasswordStep from './PasswordStep'
import SelectMethodStep from './SelectMethodStep'
import SmsMethodStep from './SmsMethodStep'
import AppStep from './AppStep'

const steps = ({ isDisabling, isUpdating, workspaceMethods }) => {
  const enableTwoFactorSettingsFunc = isUpdating ? updateTwoFactorSettings : enableTwoFactorSettings
  const allowedNotOnlyEmailMethod = isSmsOrAppEnabled(workspaceMethods)
  const titleOnPasswordStep = () => {
    if (isDisabling) return 'employeeTwoFactorSettings.disable.title'
    if (isUpdating) return 'employeeTwoFactorSettings.update.title'

    return 'employeeTwoFactorSettings.enable.title'
  }
  const submitOnPasswordStep = () => {
    if (isDisabling) return disableTwoFactorSettings
    if (allowedNotOnlyEmailMethod) return validatePasswordOnTwoFactor

    return enableTwoFactorSettingsFunc
  }

  return [
    {
      title: <FormattedMessage id={titleOnPasswordStep()} />,
      content: PasswordStep,
      onSubmit: submitOnPasswordStep(),
      validationSchema: passwordSchema,
    },
    {
      title: (
        <FormattedMessage
          id={isUpdating ? 'employeeTwoFactorSettings.update.title' : 'employeeTwoFactorSettings.selectMethod.title'}
        />
      ),
      content: SelectMethodStep,
      onSubmit: enableTwoFactorSettingsFunc,
    },
    {
      title: <FormattedMessage id="employeeTwoFactorSettings.setSms.title" />,
      content: SmsMethodStep,
      onSubmit: getOtpCodeBySms,
      validationSchema: verifyPhoneNumberSchema,
    },
    {
      title: <FormattedMessage id="employeeTwoFactorSettings.setSms.title" />,
      content: SmsMethodStep,
      onSubmit: enableTwoFactorSettingsFunc,
      validationSchema: otpCodeSchema,
    },
    {
      title: <FormattedMessage id="employeeTwoFactorSettings.setApp.title" />,
      content: AppStep,
      onSubmit: enableTwoFactorSettingsFunc,
      validationSchema: otpCodeSchema,
    },
  ]
}

export default steps
