import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const UserO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 18 18"
    data-cy={dataCy}
  >
    <path d="M9 0C6.80271 0 5 1.80271 5 4C5 6.19729 6.80271 8 9 8C11.1973 8 13 6.19729 13 4C13 1.80271 11.1973 0 9 0ZM9 2C10.1164 2 11 2.88359 11 4C11 5.11641 10.1164 6 9 6C7.88359 6 7 5.11641 7 4C7 2.88359 7.88359 2 9 2ZM9 11C7.25505 11 5.18716 11.4098 3.44922 12.0957C2.58025 12.4387 1.7947 12.846 1.16602 13.3691C0.537335 13.8922 0 14.5994 0 15.5V17C0 17.5523 0.447715 18 1 18H17C17.5523 18 18 17.5523 18 17V15.5C18 14.5994 17.4627 13.8922 16.834 13.3691C16.2053 12.846 15.4198 12.4387 14.5508 12.0957C12.8128 11.4098 10.745 11 9 11ZM9 13C10.414 13 12.3461 13.374 13.8184 13.9551C14.5545 14.2456 15.177 14.592 15.5547 14.9062C15.9324 15.2205 16 15.4346 16 15.5V16H2V15.5C2 15.4346 2.06759 15.2205 2.44531 14.9062C2.82304 14.592 3.44549 14.2456 4.18164 13.9551C5.65395 13.374 7.58595 13 9 13Z" />
  </svg>
)

UserO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

UserO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default UserO
