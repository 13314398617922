import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import { boldText } from 'utils/locales'
import FormattedCurrency from 'views/shared/FormattedCurrency'
import Modal from 'views/shared/Modal'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import { ALERT_KINDS } from 'lib/constants'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import PasswordInputField from 'views/shared/PasswordInputField'
import Cross from 'views/shared/icons/Cross'

const DeleteCompanyModalComponent = ({ onClose, handleSubmit, debtsTotal }) => (
  <Modal className="main-modal main-modal--md freemium-modal" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title font-600">
          <FormattedMessage id="workspaceSettings.deleteCompanyModal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close w-24 h-24">
          <Cross size={10} dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body max-h-100">
        {debtsTotal > 0 && (
          <Alert className="pl-12 pr-12" iconSize={20} type={ALERT_KINDS.info}>
            <span className="font-600">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.alert.payment" />
            </span>
            <p className="mb-0">
              <FormattedMessage
                id="workspaceSettings.deleteCompanyModal.alert.feeCharging"
                values={{
                  fee: <FormattedCurrency price={debtsTotal} />,
                  b: boldText,
                }}
              />
            </p>
          </Alert>
        )}
        <p className="text-body mb-0 mt-16">
          <FormattedMessage id="workspaceSettings.deleteCompanyModal.sure?" />
        </p>
        <ul className="bullet-list--col bullet-list">
          <li className="bullet-list__item text-body mt-16">
            <p className="mb-0 font-600">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.allData" />
            </p>
            <p className="mb-0 mt-4">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.allData.description" />
            </p>
          </li>
          <li className="bullet-list__item text-body mt-16">
            <p className="mb-0 font-600">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.appointments" />
            </p>
            <p className="mb-0 mt-4">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.appointments.description" />
            </p>
          </li>
          <li className="bullet-list__item text-body mt-16">
            <p className="mb-0 font-600">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.subscription" />
            </p>
            <p className="mb-0 mt-4">
              <FormattedMessage id="workspaceSettings.deleteCompanyModal.subscription.description" />
            </p>
          </li>
        </ul>
        <div className="divider divider--light" />
        <p className="text-body mb-0">
          <FormattedMessage id="workspaceSettings.deleteCompanyModal.enterPassword" />
        </p>
        <Field
          className="mt-12"
          name="password"
          id="password"
          component={PasswordInputField}
          label={{ id: 'shared.password' }}
          icon="alert"
          visiblePassword
          autoComplete="new-password"
        />
      </div>
      <div className="main-modal__footer gap-16">
        <Button className="mr-0" kind="flat" text={{ id: 'shared.cancel' }} onClick={onClose} />
        <SubmitFormButton text={{ id: 'workspaceSettings.deleteCompany' }} kind="danger" onClick={handleSubmit} />
      </div>
    </div>
  </Modal>
)

DeleteCompanyModalComponent.defaultProps = {
  debtsTotal: 0,
}

DeleteCompanyModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  debtsTotal: PropTypes.number,
}

export default DeleteCompanyModalComponent
