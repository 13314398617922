import { createLogic } from 'redux-logic'

import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { SHARE_FILES } from 'state/concepts/client/types'
import { shareFilesEndpoint } from 'state/concepts/client/endpoints'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companyClientRoute } from 'lib/routes'

const shareFilesOperation = createLogic({
  type: SHARE_FILES,
  latest: true,

  async process(
    {
      action: {
        values: { selectedFilesIds },
        form,
        clientId,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const { url } = shareFilesEndpoint(clientId)
    const workspace = currentWorkspaceCodeSelector(getState())

    try {
      await httpClient.post(url, {
        client_attachment_ids: selectedFilesIds,
      })

      await redirect({
        href: companyClientRoute(clientId),
        workspace,
        query: { activeTab: CLIENT_PROFILE_TABS.files },
      })
      dispatch(showNotification({ messageObject: { id: 'notifications.filesSharedWithClient' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default shareFilesOperation
