const availability = {
  'recurringAvailability.regularAvailability': 'Regular availability',
  'recurringAvailability.timezone': 'Timezone: ',
  'recurringAvailability.timezoneFor': 'Timezone for: ',
  'recurringAvailability.info.addAvailability': 'Click and drag to add or remove weekly regular availability.',
  'recurringAvailability.info.markAvailability': 'Blue blocks mark your permanent availability for all weeks.',
  'recurringAvailability.info.changeAvailability':
    'To change your availability for specific days only, you can use Date-specific availability.',

  'dateAvailability.dateSpecificAvailability': 'Day-specific availability',
  'dateAvailability.welcomeToPage': 'Welcome to Day-specific availability page!',
  'dateAvailability.welcomeMessage': 'Here you can drag or edit your non-regular schedule changes',
  'dateAvailability.scheduler.welcomeMessage': 'Here you can drag or edit _(experts)` non-regular schedule changes',
  'dateAvailability.info.addException': 'Date-specific availability helps to add exceptions in your regular schedule.',
  'dateAvailability.info.addSpecificTime': 'You can add specific time or make changes in your regular availability.',
  'dateAvailability.info.changeSchedule': 'To change your regular schedule on the regular availability screen.',

  'availability.switchTimezoneModal.title': 'Switch timezone',
  'availability.switchTimezoneModal.youAreNot': "Please switch to {fullName}'s timezone to edit their availability",
  'availability.switchTimezoneModal.yes': 'Yes, switch timezone',
  'availability.switchTimezoneModal.no': 'Cancel',
  'availability.manageLocations': 'Manage locations',

  'availability.edit.title': 'Edit availability',
  'availability.edit.availableOnline': 'Available online',
  'availability.edit.availableOnline.tooltip': 'Please select an offline location to turn off online availability.',
  'availability.edit.availableOnline.tooltipForAcceptVideoCallsDisabled':
    'To make _(service) available online - enable {link} feature',
  'availability.edit.availableOnline.tooltipForAcceptVideoCallsDisabled.action': 'Accept video calls',
  'availability.selectExpert': 'Select _(expert)',
}

export default availability
