import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_BILLING_ADDRESS } from 'state/concepts/billing/types'
import { updateBillingAddressEndpoint } from 'state/concepts/billing/endpoints'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsSubscriptionRoute } from 'lib/routes'

const updateBillingAddressOperation = createLogic({
  type: UPDATE_BILLING_ADDRESS,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = updateBillingAddressEndpoint
    const workspace = currentWorkspaceCodeSelector(getState())

    try {
      const params = {
        address: values.address,
        zip_code: values.index,
        country: values.country,
        state: values.state,
        city: values.city,
      }

      dispatch(dataApiRequest({ endpoint }))
      await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      redirect({ href: companySettingsSubscriptionRoute, workspace })
      dispatch(showNotification({ messageObject: { id: 'notifications.billingAddressUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updateBillingAddressOperation
