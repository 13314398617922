import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_DISCONNECT_STRIPE } from 'state/concepts/paymentSettings/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { validateDisconnectStripeEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { setStripeDisconnectError } from 'state/concepts/paymentSettings/actions'
import { showModal } from 'state/modal/actions'

const validateDisconnectStripeOperation = createLogic({
  type: VALIDATE_DISCONNECT_STRIPE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = validateDisconnectStripeEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.get(url)

      dispatch(
        dataApiSuccess({
          endpoint,
        }),
      )
      dispatch(
        showModal({
          modalType: 'DISCONNECT_PAYMENT_SETTINGS_MODAL',
        }),
      )
    } catch (error) {
      if (error.response.status === 422) {
        const [{ detail }] = error.response.data.errors

        dispatch(setStripeDisconnectError(detail))
      }
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default validateDisconnectStripeOperation
