import { createSelector } from '@reduxjs/toolkit'
import { path, pipe, prop } from 'ramda'
import build from 'redux-object'

import { DAYS_ABOUT_TO_EXPIRE } from 'lib/constants/billing'
import { fetchBillingDetailEndpoint, getBillingAccountEndpoint } from 'state/concepts/billing/endpoints'
import { currentBillingPeriodSelector, currentSubscriptionPlanSelector } from 'state/concepts/session/selectors'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { daysFromNowToDate } from 'utils/duration'
import { isFreePlan } from 'utils/billing'

export const paginationSelector = path(['billing', 'pagination'])
export const billingDetailIdSelector = path(['billing', 'billingDetailId'])
export const billingAccountIdSelector = path(['billing', 'billingAccountId'])
export const subscriptionTransactionIdsSelector = path(['billing', 'subscriptionTransactionIds'])

export const billingAccountLoadingSelector = state => loadingSelector(state, getBillingAccountEndpoint.endpoint)

export const billingLoadingSelector = state => loadingSelector(state, fetchBillingDetailEndpoint.endpoint)

export const billingAccountInfoSelector = createSelector([dataSelector, billingAccountIdSelector], (data, id) =>
  id ? build(data, 'billingAccount', id) : {},
)

export const billingDetailSelector = createSelector([billingDetailIdSelector, dataSelector], (id, data) =>
  build(data, 'billingDetail', id),
)

export const billingDetailMetaSelector = state => endpointMetaSelector(state, fetchBillingDetailEndpoint.endpoint)

export const plannedSubscriptionCycleTypeSelector = createSelector(
  billingDetailSelector,
  prop('plannedSubscriptionCycleType'),
)

export const plannedSubscriptionPlanSelector = createSelector(billingDetailSelector, prop('plannedSubscriptionPlan'))

export const subscriptionPlanSelector = createSelector(billingDetailSelector, prop('subscriptionPlan'))

export const storagePlanSelector = createSelector(billingDetailSelector, prop('storage'))

export const smsPlanSelector = createSelector(billingDetailSelector, prop('sms'))

export const videoCallsSelector = createSelector(billingDetailSelector, prop('videoCalls'))

export const paymentMethodSelector = createSelector(billingDetailSelector, prop('paymentMethod'))

export const billingAccountSelector = createSelector(billingDetailSelector, prop('billingAccount'))

export const billingTotalPriceSelector = pipe(billingDetailSelector, prop('totalPrice'))

export const subscriptionTransactionsSelector = createSelector(
  [subscriptionTransactionIdsSelector, dataSelector],
  buildCollection('subscriptionTransaction'),
)

export const subscriptionTransactionsByIdSelector = createSelector(
  [(_state, id) => id, dataSelector],
  buildCollection('subscriptionTransaction'),
)

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const isTrialSelector = pipe(currentBillingPeriodSelector, prop('trial'))

export const isTrialNotExpiredSelector = createSelector(
  [isTrialSelector, currentBillingPeriodSelector],
  (isTrial, currentPeriod) => isTrial && !prop('expired', currentPeriod),
)

export const isTrialExtendedSelector = pipe(currentBillingPeriodSelector, prop('trialExtended'))

export const isTrialAboutToExpireSelector = createSelector(
  [isTrialSelector, currentBillingPeriodSelector],
  (isTrial, currentPeriod) => isTrial && daysFromNowToDate(prop('endingAt', currentPeriod)) <= DAYS_ABOUT_TO_EXPIRE,
)

export const stripePublishableApiKeySelector = path(['billing', 'paymentMethod', 'stripePublishableApiKey'])

export const setupIntentClientSecretSelector = path(['billing', 'paymentMethod', 'setupIntentClientSecret'])

export const lastTransactionSelector = createSelector(billingDetailSelector, prop('lastTransaction'))

export const isFreeOrTrialSelector = createSelector(
  [currentSubscriptionPlanSelector, isTrialSelector],
  (currentPlan, isTrial) => isTrial || isFreePlan(currentPlan),
)
