import { equals } from 'ramda'

import { SCHEDULE_TYPES, WAITLIST_STATUSES } from 'lib/constants/waitlistRequests'

export const isAutomaticallyPredicate = equals(SCHEDULE_TYPES.automatically)
export const isNotificationOnlyPredicate = equals(SCHEDULE_TYPES.notificationOnly)

export const isExpiredPredicate = equals(WAITLIST_STATUSES.expired)
export const isRequestedPredicate = equals(WAITLIST_STATUSES.appointmentRequested)
export const isWaitingPredicate = equals(WAITLIST_STATUSES.waitingForAppointment)
export const isSuspendedPredicate = equals(WAITLIST_STATUSES.suspended)
