import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { ALERT_KINDS } from 'lib/constants'
import { moveToFolderEndpoint } from '../endpoints'
import { removeFileId } from '../actions'
import { MOVE_TO_FOLDER } from '../types'

const moveToFolderOperation = createLogic({
  type: MOVE_TO_FOLDER,
  latest: true,

  async process({ httpClient, action: { fileId, parentId, kind, onSuccess } }, dispatch, done) {
    const { url, endpoint } = moveToFolderEndpoint(fileId)

    const params = {
      parent_id: parentId,
    }

    try {
      await httpClient.put(url, params)

      dispatch(hideModal())
      dispatch(removeFileId(fileId))
      dispatch(showNotification({ messageObject: { id: 'notifications.businessStorageMoved', values: { kind } } }))
      onSuccess?.()
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default moveToFolderOperation
