import { createLogic } from 'redux-logic'
import { last, pathOr } from 'ramda'

import { resetPromocodes } from 'state/concepts/promocodes/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsEmployeesRoute, companySettingsSubscriptionRoute } from 'lib/routes'
import { changeSubscriptionDetailsSelector } from 'state/concepts/subscriptionPlans/selectors'
import { promocodesSelector } from 'state/concepts/promocodes/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import link from 'utils/locales/link'
import redirect from 'utils/redirect'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import isPresent from 'utils/isPresent'
import { updateTeamSeatsEndpoint } from '../endpoints'
import { UPDATE_TEAM_SEATS } from '../types'

const updateTeamSeatsOperation = createLogic({
  type: UPDATE_TEAM_SEATS,
  latest: true,

  async process(
    {
      action: {
        form,
        values: { teamSeatsCapacityChange },
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const isUpgrade = teamSeatsCapacityChange > 0
    const { teamSeatsCapacity } = changeSubscriptionDetailsSelector(state)
    const appliedPromocodeData = last(promocodesSelector(state))
    const { endpoint, url } = updateTeamSeatsEndpoint
    const newSeats = teamSeatsCapacity + teamSeatsCapacityChange
    const code = appliedPromocodeData?.code

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, { team_seats_capacity_change: teamSeatsCapacityChange, code })

      dispatch(dataApiSuccess({ endpoint }))
      redirect({ href: companySettingsSubscriptionRoute, workspace: currentWorkspaceCodeSelector(state) })
      dispatch(resetPromocodes())
      dispatch(
        showNotification({
          messageObject: {
            id: isUpgrade ? 'notifications.seatsHasBeenIncreased' : 'notifications.seatsHasBeenDecreased',
            values: {
              newSeats,
              link: link(companySettingsEmployeesRoute, { id: 'notifications.seatsHasBeenIncreased.link' }),
            },
          },
        }),
      )
    } catch (error) {
      const errors = pathOr(null, ['response', 'data', 'errors'], error)
      const formattedErrors = formatJsonApiErrors(errors)
      form.setSubmitting(false)
      if (isPresent(formattedErrors?.code)) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.applyDiscount.isNotApplied',
            },
            kind: 'error',
          }),
        )
      }
      requestErrorHandler({ error, dispatch, endpoint, form })
    }

    done()
  },
})

export default updateTeamSeatsOperation
