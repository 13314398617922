import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setBillingDetailId } from 'state/concepts/billing/actions'
import { fetchBillingDetailEndpoint } from 'state/concepts/billing/endpoints'
import { FETCH_BILLING_DETAIL } from 'state/concepts/billing/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const fetchBillingDetailOperation = createLogic({
  type: FETCH_BILLING_DETAIL,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchBillingDetailEndpoint
    const params = {
      include: [
        'payment-method.billing-address',
        'subscription-plan',
        'storage',
        'video-calls',
        'billing-account',
        'last-transaction',
        'workspace',
        'workspace.current-billing-period',
        'video_recordings',
        'sms',
        'planned-subscription-plan',
        'planned-storage-plan',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBillingDetailId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchBillingDetailOperation
