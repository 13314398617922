const clientContacts = {
  'clientContacts.addContact.title': 'Add contact information',
  'clientContacts.addContact.contactTab.existingClient': 'Existing _(client)',
  'clientContacts.addContact.contactTab.existingContact': 'Existing contact',
  'clientContacts.addContact.contactTab.newContact': 'New contact',
  'clientContacts.addContact.selectClient': 'Select _(client)',
  'clientContacts.addContact.selectContact': 'Select contact',
  'clientContacts.deleteContact.modal.title': 'Delete contact?',
  'clientContacts.deleteContact.modal.submitText': 'Delete contact',
  'clientContacts.deleteContact.modal.alert.billingInfo':
    'This contact is currently in charge of billing. If you delete them and there are no other billing contacts, {clientFullName} will need to manage their own bills alone.',
  'clientContacts.deleteContact.modal.text.loseAccess':
    'This contact will lose any permissions and access to {clientFullName}’s information and _(appointments).',
  'clientContacts.deleteContact.modal.text.deletePermanently':
    'This will permanently delete this contact from your workspace. They will lose any permissions and access to {clientFullName}’s information and _(appointments). Any chats, documents, or forms they completed won’t be deleted.',
  'clientContacts.addContact.relationshipType.title': 'Set relationship to _(client)',
  'clientContacts.addContact.relationshipType.label': 'Relationship type <span>(required)</span>',
  'clientContacts.addContact.relationshipType.placeholder': 'Select relationship type',
  'clientContacts.addContact.relationshipType.permissions.title': 'Assigned permissions',
  'clientContacts.addContact.relationshipType.permissions.clientPortal': 'Access _(Client) portal',
  'clientContacts.addContact.relationshipType.permissions.appointments': 'Book _(client) _(appointments)',
  'clientContacts.addContact.relationshipType.permissions.joinRescheduleAcceptAppointments':
    'Accept, reschedule, and cancel _(client) _(appointments)',
  'clientContacts.addContact.relationshipType.permissions.receiveNotifications':
    'Receive email and SMS _(appointment) notifications and reminders',
  'clientContacts.addContact.relationshipType.permissions.viewDownloadForms':
    'View and download _(client) forms, documents, _(service) agreements, and files',
  'clientContacts.addContact.relationshipType.permissions.fillOutSignForms':
    'Fill out and sign _(client) forms, documents, and _(service) agreements',
  'clientContacts.addContact.relationshipType.permissions.viewDownloadInvoices':
    'View and download _(client) invoices and receipts',
  'clientContacts.addContact.relationshipType.permissions.leaveReviews': 'Leave reviews',
  'clientContacts.addContact.emergencyContact': 'Emergency contact',
  'clientContacts.addContact.billingContact': 'Billing contact',
  'clientContacts.addContact.billingContact.alert.contact':
    'From now on, this contact will have permission to handle all billing related to the _(client).',
  'clientContacts.addContact.billingContact.alert.client':
    'From now on, this contact will no longer have permission to handle all billing related to the _(client).',
  'clientContacts.addContact.addNote.title': 'Add note',
  'clientContacts.addContact.addNote.label': 'Add relevant information about this contact',
  'clientContacts.addContact.addNote.placeholder': 'Add your note here',
  'clientContacts.addContact.createContact': 'Create contact',
  'clientContacts.addContact.editContact': 'Edit contact',
}

export default clientContacts
