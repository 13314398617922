import PropTypes from 'prop-types'
import classNames from 'clsx'
import React from 'react'
import { getIn } from 'formik'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import Checked from 'views/shared/icons/Checked'
import Cross from 'views/shared/icons/Cross'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'lib/constants'
import isPresent from 'utils/isPresent'
import InputMessage from 'views/shared/InputMessage'
import EyeO from '../icons/EyeO'
import EyeSlashO from '../icons/EyeSlashO'

const PasswordInputFieldComponent = ({
  id,
  disabled,
  label,
  icon,
  visiblePassword,
  field,
  checkPasswordStrength,
  form: { touched, errors, setFieldTouched, status },
  form,
  visible,
  onVisibilityChange,
  className,
  messageTextClass,
  validationRowsClass,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const validations = [
    {
      refused: isPresent(getIn(errors, 'passwordOneLowercaseLetter')),
      id: 'passwordOneLowercaseLetter',
    },
    {
      refused: isPresent(getIn(errors, 'passwordOneUppercaseLetter')),
      id: 'passwordOneUppercaseLetter',
    },
    {
      refused: isPresent(getIn(errors, 'passwordOneNumberCharacter')),
      id: 'passwordOneNumberCharacter',
    },
    {
      refused: isPresent(getIn(errors, 'passwordOneSpecialCharacter')),
      id: 'passwordOneSpecialCharacter',
    },
    {
      refused: isPresent(getIn(errors, 'passwordMinLength')),
      id: 'min',
      values: { min: MIN_PASSWORD_LENGTH },
    },
    {
      refused: isPresent(getIn(errors, 'passwordNameInclusion')),
      id: 'passwordNameInclusion',
    },
  ]

  const isTouchedWithErrors = fieldTouched && fieldErrors

  const wrapperClassNames = classNames(className, 'main-input', {
    'main-input--disabled': disabled,
    'main-input--has-message': checkPasswordStrength || isTouchedWithErrors,
    'main-input--has-message-error': isTouchedWithErrors || prop('base', status),
  })
  const validationRowClassNames = refused =>
    classNames('mb-4 d-flex align-items-center', {
      'in-green-700': !refused,
      'in-red-500': refused,
    })

  const IconComponent = !visible ? EyeO : EyeSlashO

  const inputType = visiblePassword && visible ? 'text' : 'password'

  const onClick = () => setFieldTouched(field.name, true)

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <input
          className="main-input__field main-input__field--right-icon"
          data-cy="password-input"
          type={inputType}
          maxLength={MAX_PASSWORD_LENGTH}
          onClick={onClick}
          {...{ disabled, id, ...field, ...props }}
        />
        {visiblePassword && (
          <span role="button" onClick={onVisibilityChange} className="main-input__icon c-pointer">
            <IconComponent size={20} />
          </span>
        )}
      </div>

      {fieldTouched && !fieldErrors && isPresent(field.value) && checkPasswordStrength && (
        <ul className={classNames(validationRowsClass, 'mb-4 mt-8')}>
          {validations.map(validation => (
            <li className={validationRowClassNames(validation.refused)} key={validation.id}>
              {validation.refused ? (
                <Cross className="mr-8 in-red-500" size={12} />
              ) : (
                <Checked className="mr-8 in-green-700" size={16} />
              )}
              <FormattedMessage id={`yup.string.password.${validation.id}`} values={validation.values} />
            </li>
          ))}
        </ul>
      )}

      {fieldTouched && fieldErrors && (
        <InputMessage messageTextClass={messageTextClass} message={fieldErrors} icon={icon} />
      )}
    </div>
  )
}

PasswordInputFieldComponent.defaultProps = {
  id: null,
  visiblePassword: false,
  disabled: false,
  label: null,
  icon: null,
  className: null,
  messageTextClass: undefined,
  validationRowsClass: null,
  checkPasswordStrength: false,
}

PasswordInputFieldComponent.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    setFieldTouched: PropTypes.func,
    status: PropTypes.shape(),
  }).isRequired,
  visiblePassword: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  checkPasswordStrength: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  validationRowsClass: PropTypes.string,
  messageTextClass: PropTypes.string,
}

export default PasswordInputFieldComponent
