import { createLogic } from 'redux-logic'

import { rootHttpClient } from 'lib/httpClient'
import { createPendingRegistrationEndpoint } from 'state/concepts/employeeSignUp/endpoints'
import { SAVE_PENDING_REGISTRATION } from 'state/concepts/employeeSignUp/types'

const savePendingRegistrationOperation = createLogic({
  type: SAVE_PENDING_REGISTRATION,
  latest: true,

  async process({ action: { email } }, dispatch, done) {
    const { url } = createPendingRegistrationEndpoint

    try {
      await rootHttpClient.post(url, { email })
      // eslint-disable-next-line no-empty
    } catch (_error) {}

    done()
  },
})

export default savePendingRegistrationOperation
