import { split } from 'ramda'

import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'

export const isProviderExpertbox = videoCallProvider => videoCallProvider === VIDEO_CALLS_PROVIDERS.expertbox
export const isProviderZoom = videoCallProvider => videoCallProvider === VIDEO_CALLS_PROVIDERS.zoom
export const isProviderGoogle = videoCallProvider => videoCallProvider === VIDEO_CALLS_PROVIDERS.googleMeet
export const isProviderTeams = videoCallProvider => videoCallProvider === VIDEO_CALLS_PROVIDERS.microsoftTeams

export const isNotDefaultProviderAlertShown = (workspaceProvider, userProvider) =>
  [VIDEO_CALLS_PROVIDERS.zoom, VIDEO_CALLS_PROVIDERS.googleMeet].includes(workspaceProvider) &&
  workspaceProvider !== userProvider

export const parseProviderDetails = ({ videoCallProvider, providerInfo }) => {
  const parsedDetails = JSON.parse(providerInfo)
  if (isProviderGoogle(videoCallProvider)) {
    const phone =
      parsedDetails.phone && `(${parsedDetails.phone.region_code}) ${split(':', parsedDetails.phone.uri)[1]}`
    const pin = parsedDetails.more && `${parsedDetails.more.pin}#`
    const morePhoneNumbers = parsedDetails.more?.uri
    return {
      phone,
      pin,
      morePhoneNumbers,
    }
  }

  if (isProviderTeams(videoCallProvider)) {
    return {
      meetingId: parsedDetails.meeting_id,
    }
  }

  return {
    meetingId: parsedDetails.meeting_id,
    passcode: parsedDetails.passcode,
  }
}
