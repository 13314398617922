import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import classNames from 'clsx'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { APPOINTMENT_INVITED_MEMBERS_LIMIT } from 'lib/constants/videoCalls'
import { DEFAULT_CUSTOMIZATION_COLORS } from 'lib/constants/widgets'
import { removeMember } from 'state/concepts/inviteAppointmentMembers/actions'
import { showModal } from 'state/modal/actions'
import { boldText } from 'utils/locales'
import Avatar from 'views/shared/Avatar'
import Tooltip from 'views/shared/Tooltip'
import Close from 'views/shared/icons/Close'

const InvitedMember = ({ member, alsoInvited, noHover, highlighted, bookingId, hideRemoveMemberButton }) => {
  const backgroundColor =
    DEFAULT_CUSTOMIZATION_COLORS[
      member.id < APPOINTMENT_INVITED_MEMBERS_LIMIT
        ? member.id
        : Math.floor(member.id / APPOINTMENT_INVITED_MEMBERS_LIMIT)
    ]

  const onDeleteMember = useDispatchAction(removeMember, member.id, bookingId)

  const showConfirmDeleteModal = useDispatchAction(showModal, {
    modalType: 'CONFIRM_MODAL',
    modalProps: {
      title: { id: 'bookings.deleteMemberModal.title' },
      bodyText: [
        {
          id: 'bookings.deleteMemberModal.body',
          values: {
            email: member.email,
            b: boldText,
          },
        },
      ],
      submitText: { id: 'shared.remove' },
      dismissText: { id: 'shared.cancel' },
      submitClassName: 'main-btn--danger',
      onSubmit: onDeleteMember,
    },
  })

  return (
    <div
      className={classNames(`align-items-center invited-members__member mw-100`, {
        'd-inline-flex': !alsoInvited,
        'd-flex': alsoInvited,
        'invited-members__member--no-hover': noHover || hideRemoveMemberButton,
        'invited-members__member--highlighted': highlighted,
      })}
    >
      <Avatar
        avatarClassName="mr-12"
        sizeClassName={`main-userpic main-userpic--${alsoInvited ? 'sm' : 'xs'}`}
        imageSize="small"
        profile={member}
        style={{ backgroundColor }}
      />
      <div className="d-flex align-items-center min-w-0">
        <p className="mb-0 text-body truncate-text" data-cy="expert-fullname">
          {member.email}
        </p>
      </div>
      {!hideRemoveMemberButton && (
        <Tooltip
          overlayClassName="main-tooltip main-tooltip--no-pointer"
          placement="topRight"
          align={{ offset: [0, -2] }}
          icon={
            <button
              className={classNames(
                'invited-members__delete-button d-flex align-items-center justify-content-center',
                { 'wh-20-20 ml-12': !alsoInvited },
                { 'wh-24-24 ml-auto': alsoInvited },
              )}
              type="button"
              onClick={showConfirmDeleteModal}
              data-cy="remove-member-button"
            >
              <Close className="in-disabled-gray invited-members__delete-button-icon" size={14} />
            </button>
          }
        >
          <p className="mb-0">
            <FormattedMessage id="bookings.removeMember" />
          </p>
        </Tooltip>
      )}
    </div>
  )
}

InvitedMember.defaultProps = {
  hideRemoveMemberButton: false,
}

InvitedMember.propTypes = {
  alsoInvited: PropTypes.bool.isRequired,
  noHover: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool.isRequired,
  hideRemoveMemberButton: PropTypes.bool,
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  bookingId: PropTypes.string.isRequired,
}

export default InvitedMember
