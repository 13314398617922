const eSignature = {
  'eSignature.type': 'Type',
  'eSignature.image': 'Upload image',
  'eSignature.draw': 'Draw',
  'eSignature.eSignatureDisclosure.read': 'Please read the',
  'eSignature.eSignatureDisclosure.agree': 'I agree to use electronic records and signatures.',
  'eSignature.eSignatureDisclosure.fileUpload.alert': 'Please use jpg or png image smaller than 10Mb.',
  'eSignature.eSignatureDisclosure.fileUpload.update': 'Update signature',
  'eSignature.eSignatureDisclosure.fileUpload.upload': 'Upload signature',
  'eSignature.eSignatureDisclosure.fileUpload.dropHere': 'Drop a photo of your signature here to upload.',
  'eSignature.eSignatureDisclosure.fileUpload.drop': 'Drop the files here...',
  'eSignature.eSignatureDisclosure.fileUpload.validationText':
    'You can upload jpg or png image files. Max size of 10Mb.',
  'eSignature.eSignatureDisclosure.drawSignature.clear': 'Clear signature',
  'eSignature.eSignatureDisclosure.drawSignature.signHere': 'Sign here',
}

export default eSignature
