import { combineReducers } from '@reduxjs/toolkit'

import { SET_SMS_NOTIFICATIONS_SETTINGS_ID } from './types'

const smsNotificationsSettingsId = (state = null, action) => {
  switch (action.type) {
    case SET_SMS_NOTIFICATIONS_SETTINGS_ID:
      return action.smsNotificationsSettingsId
    default:
      return state
  }
}

const smsNotificationsSettingsReducer = combineReducers({
  smsNotificationsSettingsId,
})

export default smsNotificationsSettingsReducer
