import connectGoogleCalendar from './connectGoogleCalendar'
import reconnectGoogleCalendar from './reconnectGoogleCalendar'
import fetchCalendarConnections from './fetchCalendarConnections'
import updateGoogleCalendarConnection from './updateGoogleCalendarConnection'
import disconnectGoogleCalendar from './disconnectGoogleCalendar'
import getGoogleCalendarConnectLink from './getGoogleCalendarConnectLink'
import receiveGoogleCalendar from './receiveGoogleCalendar'
import updateCalendarConnections from './updateCalendarConnections'
import connectOutlookCalendar from './connectOutlookCalendar'
import getOutlookCalendarConnectLink from './getOutlookCalendarConnectLink'
import updateOutlookCalendarConnection from './updateOutlookCalendarConnection'
import disconnectOutlookCalendar from './disconnectOutlookCalendar'
import receiveOutlookCalendar from './receiveOutlookCalendar'
import reconnectOutlookCalendar from './reconnectOutlookCalendar'
import updateSyncCalendar from './updateSyncCalendar'
import connectAppleCalendar from './connectAppleCalendar'
import receiveAppleCalendar from './receiveAppleCalendar'
import disconnectAppleCalendar from './disconnectAppleCalendar'
import reconnectAppleCalendar from './reconnectAppleCalendar'
import updateAppleCalendarConnection from './updateAppleCalendarConnection'

export default [
  connectGoogleCalendar,
  reconnectGoogleCalendar,
  fetchCalendarConnections,
  updateGoogleCalendarConnection,
  disconnectGoogleCalendar,
  getGoogleCalendarConnectLink,
  receiveGoogleCalendar,
  updateCalendarConnections,
  connectOutlookCalendar,
  getOutlookCalendarConnectLink,
  updateOutlookCalendarConnection,
  disconnectOutlookCalendar,
  receiveOutlookCalendar,
  reconnectOutlookCalendar,
  updateSyncCalendar,
  connectAppleCalendar,
  receiveAppleCalendar,
  disconnectAppleCalendar,
  reconnectAppleCalendar,
  updateAppleCalendarConnection,
]
