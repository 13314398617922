import { createLogic } from 'redux-logic'

import { isErrorStatusGone } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { VALIDATE_CONFIRMATION_TOKEN } from 'state/concepts/client/types'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { clientLoginRoute } from 'lib/routes'
import { validateConfirmationTokenEndpoint } from '../endpoints'

const validateConfirmationTokenOperation = createLogic({
  type: VALIDATE_CONFIRMATION_TOKEN,
  latest: true,

  async process({ action: { token }, httpClient }, dispatch, done) {
    const { url, endpoint } = validateConfirmationTokenEndpoint

    try {
      await httpClient.post(url, { token })

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.emailHasBeenConfirmed' },
        }),
      )
      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      if (isErrorStatusGone(error)) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.confirmationLinkHasBeenExpired' },
            kind: 'error',
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    redirect({ href: clientLoginRoute })
    done()
  },
})

export default validateConfirmationTokenOperation
