import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { pluck } from 'ramda'
import { useSelector } from 'react-redux'

import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { APPOINTMENT_INVITED_MEMBERS_LIMIT, VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { FormattedMessage } from 'react-intl'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import isPresent from 'utils/isPresent'
import EmailsInputField from 'views/shared/EmailsInputField'
import InfoO from 'views/shared/icons/InfoO'

const InviteMembers = ({ isEdit, invitedMembers }) => {
  const groupVideoCallsEnabled = useWorkspaceFeature(FEATURE_KEYS.groupVideoCalls)
  const { videoCallProvider } = useSelector(workspaceSelector)
  const invitedMembersEmails = pluck('email', invitedMembers)
  const { values } = useFormikContext()

  const canInviteMembers =
    groupVideoCallsEnabled || isPresent(values.locationId) || videoCallProvider !== VIDEO_CALLS_PROVIDERS.expertbox

  return (
    canInviteMembers && (
      <div className="booking-tooltip__info booking-tooltip__info-divider">
        <div className="d-flex">
          <div className="max-w-280 w-100 ml-32">
            <Field
              component={EmailsInputField}
              maxItems={APPOINTMENT_INVITED_MEMBERS_LIMIT}
              invitedMembersCount={0}
              invitedMembersEmails={invitedMembersEmails}
              name="emails"
              id="emails"
              label={{ id: 'inviteMembers.title' }}
              isEdit={isEdit}
            />
            <div className="d-flex mt-8 max-w-280 w-100">
              <div className="wh-20-20 d-flex align-items-center justify-content-center flex-shrink-0">
                <InfoO className="in-disabled-gray" />
              </div>
              <p className="mb-0 ml-8 text-body in-blue-gray-300">
                <FormattedMessage
                  id="inviteMembers.invitationsWillBeSent"
                  values={{
                    maxItems: APPOINTMENT_INVITED_MEMBERS_LIMIT,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

InviteMembers.defaultProps = {
  isEdit: false,
  invitedMembers: [],
}

InviteMembers.propTypes = {
  isEdit: PropTypes.bool,
  invitedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    }),
  ),
}

export default InviteMembers
