import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import CodesList from '../BackupCodesModal/CodesList'

const FreshBackupCodesModal = ({ onClose, backupCodes, onSuccess, setupMethodChecker }) => {
  const handleClose = () => {
    onClose()
    onSuccess?.()
  }

  return (
    <Modal className="main-modal main-modal--md backup-codes">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            {setupMethodChecker ? (
              <FormattedMessage id={`employeeTwoFactorSettings.setupWith.${setupMethodChecker}`} />
            ) : (
              <FormattedMessage id="backupLogin.modal.title" />
            )}
          </h2>
          <button type="button" className="main-modal__close" onClick={handleClose} data-cy="close-button">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <CodesList backupCodes={backupCodes} oldIsExpired={!setupMethodChecker} />
      </div>
    </Modal>
  )
}

FreshBackupCodesModal.defaultProps = {
  onSuccess: noop,
  setupMethodChecker: null,
}

FreshBackupCodesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  backupCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSuccess: PropTypes.func,
  setupMethodChecker: PropTypes.string,
}

export default FreshBackupCodesModal
