import React from 'react'
import PropTypes from 'prop-types'

import getImageCrop from 'utils/getImageCrop'
import LogoModalComponent from './component'

class LogoModal extends React.Component {
  state = {
    croppedSrc: null,
    crop: {},
  }

  static defaultProps = {
    closeAction: null,
  }

  static propTypes = {
    onSelectImage: PropTypes.func.isRequired,
    uploadAction: PropTypes.func.isRequired,
    closeAction: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    mimeType: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  }

  onImageLoaded = ({ target }) => {
    this.imageRef = target
    const crop = getImageCrop(target)

    this.setState({ crop })
    this.onCropComplete(crop)
    return false
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  getCroppedImg = (image, crop, fileName) => {
    const { mimeType } = this.props

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    return new Promise(resolve => {
      canvas.toBlob(res => {
        const blob = res
        blob.name = fileName
        resolve(blob)
      }, mimeType)
    })
  }

  onImageSave = () => {
    const { croppedSrc } = this.state
    const { onClose, uploadAction } = this.props

    this.setState({ croppedSrc: null, crop: {} })

    onClose()
    uploadAction(croppedSrc)
  }

  onCropComplete = async crop => {
    const { fileName } = this.props

    if (this.imageRef && crop.width && crop.height) {
      const croppedSrc = await this.getCroppedImg(this.imageRef, crop, fileName)

      this.setState({ croppedSrc })
    }
  }

  handleClose = () => {
    const { onClose, closeAction } = this.props

    onClose()
    closeAction && closeAction()
  }

  render = () => {
    const { onSelectImage } = this.props
    const { crop } = this.state

    return (
      <LogoModalComponent
        crop={crop}
        onImageLoaded={this.onImageLoaded}
        onCropComplete={this.onCropComplete}
        onCropChange={this.onCropChange}
        onImageSave={this.onImageSave}
        onSelectImage={onSelectImage}
        {...this.props}
        onClose={this.handleClose}
      />
    )
  }
}

export default LogoModal
