import React from 'react'
import PropTypes from 'prop-types'

import { equals, findIndex } from 'ramda'
import DaysCheckboxGroupFieldComponent from './component'

class DaysCheckboxGroupField extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
  }

  isChecked = value => this.props.form.values.byWeekday.includes(value)

  handleChange = value => () => {
    const {
      push,
      remove,
      form: {
        values: { byWeekday },
      },
    } = this.props

    if (byWeekday.includes(value) && byWeekday.length === 1) {
      return
    }

    byWeekday.includes(value) ? remove(findIndex(equals(value), byWeekday)) : push(value)
  }

  render() {
    return <DaysCheckboxGroupFieldComponent {...this.props} isChecked={this.isChecked} onChange={this.handleChange} />
  }
}

export default DaysCheckboxGroupField
