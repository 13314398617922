import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { declineAllRecurringBookingsEndpoint } from 'state/concepts/booking/endpoints'
import { DECLINE_ALL_BOOKINGS } from 'state/concepts/guestBookings/types'
import redirect from 'utils/redirect'
import { guestLinkExpiredRoute } from 'lib/routes'

const declineAllBookingsOperation = createLogic({
  type: DECLINE_ALL_BOOKINGS,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { workspaceCode, uniqCode, res } = action
    const { url } = declineAllRecurringBookingsEndpoint(uniqCode)
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    try {
      await httpClient.post(url, {}, config)
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }

    done()
  },
})

export default declineAllBookingsOperation
