import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { removeMessage } from 'state/concepts/chat/actions'
import { loadingSelector } from 'state/data/selectors'
import { removeChatMessageEndpoint } from 'state/concepts/chat/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

class ChatMessageRemoveModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.shape().isRequired,
  }

  handleSubmit = () => {
    const {
      onSubmit,
      message: { id },
    } = this.props
    onSubmit(id)
  }

  render = () => (
    <ConfirmModal
      {...this.props}
      title={{ id: 'chat.deleteMessage.modal.title' }}
      bodyText={[{ id: 'chat.removeMessage.modal.body' }]}
      submitText={{ id: 'shared.delete' }}
      onSubmit={this.handleSubmit}
      kind="danger"
    />
  )
}

const mapStateToProps = (state, { messageId }) => ({
  isLoading: loadingSelector(state, removeChatMessageEndpoint(messageId).endpoint),
})

const mapDispatchToProps = {
  onSubmit: removeMessage,
}

export { ChatMessageRemoveModal as ChatMessageRemoveModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageRemoveModal)
