import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setClient, setIsClientDeleted } from 'state/concepts/client/actions'
import { FETCH_CLIENT } from 'state/concepts/client/types'
import { fetchClientEndpoint } from 'state/concepts/client/endpoints'

const fetchClientOperation = createLogic({
  type: FETCH_CLIENT,
  latest: true,

  async process({ httpClient, action: { clientId, onSuccess } }, dispatch, done) {
    const { endpoint, url } = fetchClientEndpoint(clientId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const params = {
        include: ['custom-fields', 'custom-fields.custom_field_options', 'user-profiles'],
      }
      const { data } = await httpClient.get(url, { params })
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      if (onSuccess) {
        onSuccess?.()
      } else {
        dispatch(setClient(clientId))
        dispatch(setIsClientDeleted(false))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [404] })
      dispatch(setIsClientDeleted(error.response.status === 404))
    }
    done()
  },
})

export default fetchClientOperation
