import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Timezone = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.233-512-512 0-282.77 229.23-512 512-512 282.767 0 512 229.23 512 512 0 282.767-229.233 512-512 512zM394.753 904.571c-49.285-104.525-79.498-219.812-86.155-341.371h-203.030c20.281 162.637 135.91 295.665 289.184 341.371zM411.172 563.2c7.7 124.867 43.372 242.161 100.828 345.702 57.457-103.542 93.128-220.836 100.828-345.702h-201.656zM918.431 563.2h-203.028c-6.656 121.559-36.869 236.846-86.154 341.371 153.272-45.706 268.902-178.734 289.183-341.371zM105.569 460.8h203.030c6.657-121.561 36.87-236.848 86.155-341.373-153.274 45.71-268.903 178.736-289.184 341.373zM411.172 460.8h201.656c-7.7-124.866-43.372-242.163-100.828-345.703-57.457 103.54-93.128 220.837-100.828 345.703zM629.248 119.427c49.285 104.525 79.498 219.812 86.154 341.373h203.028c-20.28-162.637-135.91-295.663-289.183-341.373z" />
  </svg>
)

Timezone.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Timezone.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Timezone
