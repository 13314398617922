export const blueGrayColors = {
  100: '#8593aa',
  200: '#697a94',
  300: '#586a86',
  400: '#485a78',
  500: '#3a4c6a',
  600: '#293c5b',
  700: '#1e3255',
  800: '#13284c',
  900: '#0c1e3e',
}

export const grayColors = {
  10: '#fafbfb',
  20: '#f3f4f5',
  30: '#f0f0f0',
  50: '#f1f4f7',
  70: '#6e798d',
  100: '#f8f9fc',
  150: '#ebeff2',
  200: '#f5f7fa',
  250: '#f3f5f9',
  300: '#e8ebf0',
  400: '#dde2e8',
  500: '#c8ced8',
  600: '#b1bac9',
  700: '#a3acbd',
  800: '#95a0b3',
  900: '#8b97ac',
}

export const blueColors = {
  100: '#f3f8ff',
  200: '#e7f1ff',
  300: '#cae0ff',
  350: '#abceff',
  400: '#99c4ff',
  450: '#5396ff',
  500: '#65a5ff',
  600: '#388bff',
  700: '#2970ff',
  800: '#1d56c8',
  900: '#184193',
  1000: '#294bff',
  1100: '#002d9c',
}

export const greenColors = {
  100: '#eafcf7',
  200: '#cff8eb',
  300: '#92ecd4',
  400: '#6ddfc2',
  500: '#3ed3ae',
  600: '#22c9a4',
  700: '#0bb08b',
  800: '#008669',
  900: '#006258',
}

export const secondaryGreenColors = {
  400: '#7eec9a',
  500: '#54e279',
  600: '#3ed3a3',
}

export const redColors = {
  100: '#fff8f8',
  200: '#ffeaea',
  300: '#ffc2c2',
  400: '#ff8d8d',
  450: '#fa7b7b',
  500: '#ff5d5d',
  550: '#fa5555',
  600: '#f03d3d',
  700: '#dd2727',
  750: '#da1e28',
  800: '#bd0303',
  850: '#b81922',
  900: '#a50000',
  1000: '#8f0008',
}

export const orangeColors = {
  100: '#fffcf5',
  200: '#fff6e4',
  300: '#ffecc7',
  400: '#ffdc99',
  500: '#ffca65',
  600: '#ffbb38',
  700: '#ffad0d',
  800: '#ff8f00',
  900: '#f07300',
}

export const yellowColors = {
  500: '#f9da36',
}

export const violetColors = {
  200: '#ecddff',
  500: '#a29bfe',
  700: '#7f6aff',
}

export const complementaryColors = {
  mainBtnActiveColor: '#2f76d9',
  malibuBlue: '#71abfc',
  calendarPickedAreaBg: '#fff',
  dsaCalendarPickedAreaBg: '#b9f5c8',
  shadowColor: '#526173',
  vcIconColor: '#6c798f',
  vcStopBgcHover: '#fc8a8a',
  vcGradientStart: '#172237',
  vcGradientEnd: '#0f192b',
  vcIconFill: '#101b2d',
  vcDisabledBg: '#ecedf0',
  vcDisabledIcon: '#b2bac7',
  checkboxDefaultBroder: '#b2bac7',
  dividerColor: '#f1f2f4',
  chatMsgFileLinkBg: '#f1f2f4',
  chatMsgFileDocBg: '#f1f2f4',
  chatNmiHoverBg: '#0c66ff',
  headerBorderColor: '#f1f2f4',
  agendaTableBorderColor: '#ddd',
  disabledBtn: '#ecedf0',
  mgSiteStructureWrap: '#f1f2f4',
  mgSiteNewSection: '#c9ced6',
  mgSiteNewSectionMultDivider: '#aab3c5',
  scrollbarColor: '#526173',
}
