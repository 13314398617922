import { Popover } from 'antd'
import classNames from 'clsx'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'

import { toJSDate } from 'utils/dateTime'
import ArrowLeft from 'views/shared/icons/ArrowLeft'
import ArrowRight from 'views/shared/icons/ArrowRight'
import Button from '../Button'
import FormattedOrRawMessage from '../FormattedOrRawMessage'
import InputMessage from '../InputMessage'
import DatePickerInput from './Input'

const DatePicker = ({
  date,
  onChange,
  isOpen,
  handleOpenChange,
  onCalendarChange,
  onMonthClick,
  onYearClick,
  onDecadeClick,
  formatInputDate,
  intl: { locale },
  transitionName,
  label,
  labelClassName,
  wrapperClassName,
  placeholder,
  hideIcon,
  iconName,
  hideDropdownIcon,
  hideSubmit,
  id,
  isClearShown,
  onClear,
  overlayClassName,
  getPopupContainer,
  align,
  withTooltip,
  form: { errors, touched },
  field,
  mainInputClassName,
  placement,
  ...props
}) => {
  const fieldErrors = getIn(errors, field?.name)
  const fieldTouched = field?.name ? getIn(touched, field?.name) : false
  const wrapperClassNames = classNames('main-input', mainInputClassName, {
    'main-input--has-message': fieldTouched && fieldErrors,
    'main-input--has-message-error': fieldTouched && fieldErrors,
  })

  return (
    <div className={wrapperClassNames}>
      <Popover
        className="popover-datepicker"
        transitionName={transitionName}
        onOpenChange={handleOpenChange}
        content={
          <>
            <Calendar
              {...props}
              id={id}
              value={toJSDate(date) || ''}
              calendarType="gregory"
              locale={locale}
              onChange={onCalendarChange}
              className="main-datepicker main-datepicker--booking"
              nextLabel={<ArrowRight dataCy="arrow-right-icon" size={12} />}
              prevLabel={<ArrowLeft dataCy="arrow-left-icon" size={12} />}
              onClickMonth={onMonthClick}
              onClickYear={onYearClick}
              onClickDecade={onDecadeClick}
            />
            {!hideSubmit && (
              <>
                <div className="divider divider--light" />
                <div className="d-flex justify-content-end main-datepicker-actions">
                  {isClearShown && (
                    <Button
                      className="mr-8"
                      text={{ id: 'shared.resetDate' }}
                      kind="flat"
                      size="small"
                      onClick={onClear}
                    />
                  )}
                  <Button text={{ id: 'shared.submit' }} size="small" onClick={handleOpenChange} />
                </div>
              </>
            )}
          </>
        }
        trigger="click"
        open={isOpen}
        placement={placement}
        align={align}
        getPopupContainer={getPopupContainer}
        overlayClassName={classNames('main-datepicker-wrap', overlayClassName)}
      >
        {label && (
          <label className={labelClassName} htmlFor={id}>
            <FormattedOrRawMessage message={label} />
          </label>
        )}
        <DatePickerInput
          date={date}
          formatDate={formatInputDate}
          isOpen={isOpen}
          hideIcon={hideIcon}
          iconName={iconName}
          hideDropdownIcon={hideDropdownIcon}
          className={wrapperClassName}
          placeholder={placeholder}
          isClearShown={isClearShown}
          onClear={onClear}
          withTooltip={withTooltip}
        />
      </Popover>
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon="alert" />}
    </div>
  )
}

DatePicker.propTypes = {
  date: PropTypes.shape({
    toJSDate: PropTypes.func.isRequired,
  }),
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpenChange: PropTypes.func.isRequired,
  onCalendarChange: PropTypes.func.isRequired,
  onMonthClick: PropTypes.func.isRequired,
  onYearClick: PropTypes.func.isRequired,
  onDecadeClick: PropTypes.func.isRequired,
  formatInputDate: PropTypes.func,
  onClear: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  transitionName: PropTypes.string,
  hideIcon: PropTypes.bool,
  iconName: PropTypes.string,
  hideDropdownIcon: PropTypes.bool,
  hideSubmit: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  isClearShown: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string,
  getPopupContainer: PropTypes.func,
  align: PropTypes.shape({
    offset: PropTypes.arrayOf(PropTypes.number),
  }),
  withTooltip: PropTypes.bool,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    touched: PropTypes.shape(),
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  mainInputClassName: PropTypes.string,
  placement: PropTypes.string,
}

DatePicker.defaultProps = {
  transitionName: '',
  formatInputDate: undefined,
  hideIcon: false,
  iconName: 'calendar-check',
  hideDropdownIcon: false,
  hideSubmit: false,
  label: null,
  labelClassName: '',
  wrapperClassName: '',
  id: null,
  date: null,
  placeholder: '',
  overlayClassName: '',
  align: undefined,
  getPopupContainer: undefined,
  withTooltip: false,
  form: { errors: {}, touched: {} },
  field: {},
  mainInputClassName: '',
  placement: 'bottom',
}

export default DatePicker
