import { createLogic } from 'redux-logic'

import { companyClientRoute } from 'lib/routes'
import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import redirect from 'utils/redirect'
import { ADD_CLIENT_BUSINESS_FILES } from '../types'
import { addClientBusinessFilesEndpoint } from '../endpoints'

const addClientBusinessFilesOperation = createLogic({
  type: ADD_CLIENT_BUSINESS_FILES,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const {
      values: { fileIds, clientId },
      form,
    } = action
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const { url, endpoint } = addClientBusinessFilesEndpoint(clientId)
    const params = {
      business_storage_ids: fileIds,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.businessFilesWereAddedToClient' },
        }),
      )
      redirect({
        href: companyClientRoute(clientId),
        query: { activeTab: CLIENT_PROFILE_TABS.files },
        workspace,
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default addClientBusinessFilesOperation
