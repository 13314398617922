import { createLogic } from 'redux-logic'

import { BOOKING_DECLINE_OPTIONS } from 'lib/constants/bookings'
import { DECLINE_RECURRING_BOOKINGS } from 'state/concepts/booking/types'
import { declineBooking, declineAllRecurringBookings } from 'state/concepts/booking/actions'

const declineRecurringBookingsOperation = createLogic({
  type: DECLINE_RECURRING_BOOKINGS,
  latest: true,

  process({ action }, dispatch, done) {
    const {
      values: { declineRecurring },
      bookingUniqCode,
    } = action

    if (declineRecurring === BOOKING_DECLINE_OPTIONS.single) {
      dispatch(declineBooking(bookingUniqCode))
    } else {
      dispatch(declineAllRecurringBookings(bookingUniqCode))
    }

    done()
  },
})

export default declineRecurringBookingsOperation
