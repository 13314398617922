import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { removeGoogleCalendarConnectionIds } from 'state/concepts/calendarIntegrations/actions'
import { dataApiSuccess } from 'state/data/actions'
import { RECEIVE_GOOGLE_CALENDAR } from '../types'

const receiveGoogleCalendarOperation = createLogic({
  type: RECEIVE_GOOGLE_CALENDAR,
  latest: true,

  process({ action: { data } }, dispatch, done) {
    const {
      data: {
        attributes: { destroyed },
        id,
      },
    } = data

    if (destroyed) {
      dispatch(removeGoogleCalendarConnectionIds(id))
    } else {
      dispatch(dataApiSuccess({ response: normalize(data) }))
    }
    done()
  },
})

export default receiveGoogleCalendarOperation
