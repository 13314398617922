import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import profileName from 'utils/profileName'
import isScheduler from 'utils/employeePermissions/isScheduler'
import { activateEmployee as activateEmployeeAction } from 'state/concepts/employees/actions'
import { loadingSelector } from 'state/data/selectors'
import { activateEmployeeEndpoint } from 'state/concepts/employees/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

const EmployeesActivateSchedulerModal = ({ employee, onClose }) => {
  const { endpoint } = activateEmployeeEndpoint(employee.id)
  const onSubmit = useDispatchAction(activateEmployeeAction, employee.id)
  const isLoading = useSelector(state => loadingSelector(state, endpoint))

  return (
    <ConfirmModal
      title={{
        id: isScheduler(employee) ? 'employeesActivateSchedulerModal.title' : 'employeesActivateModal.activateAccount',
      }}
      bodyText={[
        {
          id: isScheduler(employee)
            ? 'employeesActivateSchedulerModal.description'
            : 'employeesActivateModal.areYouSure',
          values: { name: <span className="font-600">{profileName(employee)}</span> },
        },
      ]}
      submitText={{ id: 'employeesActions.activate' }}
      onSubmit={onSubmit}
      onClose={onClose}
      isLoading={isLoading}
    />
  )
}

EmployeesActivateSchedulerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
}

export default EmployeesActivateSchedulerModal
