const namespace = 'session'

export const KEEP_ALIVE = `${namespace}/KEEP_ALIVE`
export const EMPLOYEE_LOGIN = `${namespace}/EMPLOYEE_LOGIN`
export const EMPLOYEE_LOGIN_SUCCESS = `${namespace}/EMPLOYEE_LOGIN_SUCCESS`
export const EMPLOYEE_LOGOUT = `${namespace}/EMPLOYEE_LOGOUT`
export const EMPLOYEE_LOGOUT_SUCCESS = `${namespace}/EMPLOYEE_LOGOUT_SUCCESS`
export const EMPLOYEE_POST_LOGOUT = `${namespace}/EMPLOYEE_POST_LOGOUT`
export const BULK_EMPLOYEE_LOGOUT = `${namespace}/BULK_EMPLOYEE_LOGOUT`
export const BULK_EMPLOYEE_LOGOUT_SUCCESS = `${namespace}/BULK_EMPLOYEE_LOGOUT_SUCCESS`
export const CLIENT_LOGIN = `${namespace}/CLIENT_LOGIN`
export const CLIENT_SEND_CODE = `${namespace}/CLIENT_SEND_CODE`
export const CLIENT_LOGIN_SUCCESS = `${namespace}/CLIENT_LOGIN_SUCCESS`
export const CLIENT_LOGOUT = `${namespace}/CLIENT_LOGOUT`
export const CLIENT_POST_LOGOUT = `${namespace}/CLIENT_POST_LOGOUT`
export const CLIENT_LOGOUT_SUCCESS = `${namespace}/CLIENT_LOGOUT_SUCCESS`
export const GUEST_LOGIN_SUCCESS = `${namespace}/GUEST_LOGIN_SUCCESS`
export const GUEST_LOGOUT_SUCCESS = `${namespace}/GUEST_LOGOUT_SUCCESS`
export const EMPLOYEE_SUBMIT_TWO_FA = `${namespace}/EMPLOYEE_SUBMIT_TWO_FA`
export const RESEND_OTP_CODE_ON_LOGIN = `${namespace}/RESEND_OTP_CODE_ON_LOGIN`
export const CLIENT_CONTACT_LOGIN = `${namespace}/CLIENT_CONTACT_LOGIN`
