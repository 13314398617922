import * as types from './types'

// eslint-disable-next-line import/prefer-default-export
export const searchChatUsers = ({ chat, query, page, resetResults }) => ({
  type: types.SEARCH_CHAT_USERS,
  chat,
  query,
  page,
  resetResults,
})
