import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import Modal from 'views/shared/Modal'
import validationSchema from 'lib/yupLocalised/schemas/declineEtherpadDocumentSigning'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import TextAreaField from 'views/shared/TextAreaField'
import Cross from 'views/shared/icons/Cross'
import { declineEtherpadDocumentSigning } from 'state/concepts/etherpadDocuments/actions'

const DeclineSignatureModal = ({ onClose, document }) => {
  const handleSubmit = useFormSubmit(declineEtherpadDocumentSigning)

  return (
    <Modal className="main-modal main-modal--md">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="etherpadDocuments.declineToSign.modal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{ reason: '', documentId: document.id }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <>
            <div className="main-modal__body">
              <p className="text-body">
                <FormattedMessage id="etherpadDocuments.declineToSign.modal.description" />
              </p>
              <Field
                name="reason"
                id="reason"
                component={TextAreaField}
                className="textarea-reason main-input--textarea-top-count"
                label={{ id: 'etherpadDocuments.declineToSign.modal.reason.label' }}
                maxLength={1000}
                autoSize={{ minRows: 4, maxRows: 4 }}
                showCount
                icon="alert"
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton kind="danger" text={{ id: 'etherpadDocuments.declineToSign.modal.decline' }} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

DeclineSignatureModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape().isRequired,
}

export default DeclineSignatureModal
