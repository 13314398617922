import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { RESEND_EMPLOYEE_INVITATION } from 'state/concepts/employees/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { resendEmployeeInvitationEndpoint } from 'state/concepts/employees/endpoints'

const resendEmployeeInvitationOperation = createLogic({
  type: RESEND_EMPLOYEE_INVITATION,
  latest: true,

  async process({ action: { employeeId }, httpClient }, dispatch, done) {
    const { url, endpoint } = resendEmployeeInvitationEndpoint(employeeId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.employeeInvitationResented' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default resendEmployeeInvitationOperation
