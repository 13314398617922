import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { isNaN } from 'lodash'

import NumericFieldComponent from './component'

class NumericField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    step: 1,
    min: undefined,
    max: undefined,
    disabled: false,
  }

  get isDecrementDisabled() {
    const {
      disabled,
      field: { value },
      min,
    } = this.props

    return disabled || value === min
  }

  get isIncrementDisabled() {
    const {
      disabled,
      field: { value },
      max,
    } = this.props
    return disabled || value === max
  }

  onChange = value => {
    const {
      form: { setFieldValue },
      field,
    } = this.props

    setFieldValue(field.name, value)
  }

  onIncrement = () => {
    const {
      field: { value },
      step,
      max,
    } = this.props
    const newValue = +value + step

    if (isNaN(newValue) || newValue > max) {
      return
    }

    this.onChange(newValue)
  }

  onDecrement = () => {
    const {
      field: { value },
      step,
      min,
    } = this.props
    const newValue = +value - step

    if (isNaN(newValue) || newValue < min) {
      return
    }

    this.onChange(newValue)
  }

  render = () => (
    <NumericFieldComponent
      {...this.props}
      isDecrementDisabled={this.isDecrementDisabled}
      isIncrementDisabled={this.isIncrementDisabled}
      onChange={this.onChange}
      onIncrement={this.onIncrement}
      onDecrement={this.onDecrement}
    />
  )
}

export { NumericField as NumericFieldContainer }
export default injectIntl(NumericField)
