import { createLogic } from 'redux-logic'
import { prop } from 'ramda'

import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { REMOVE_NOTIFICATION } from 'state/concepts/notifications/types'
import { removeNotificationEndpoint } from 'state/concepts/notifications/endpoints'
import { removeNotificationFromPanel, decUnreadNotificationsCount } from 'state/concepts/notifications/actions'
import { notificationSelector, unreadNotificationsCountSelector } from 'state/concepts/notifications/selectors'

const removeNotificationOperation = createLogic({
  type: REMOVE_NOTIFICATION,
  latest: true,

  async process({ httpClient, getState, action }, dispatch, done) {
    const { notificationId } = action
    const { endpoint, url } = removeNotificationEndpoint(notificationId)
    const state = getState()
    const readAt = prop('readAt', notificationSelector(state, notificationId))
    const unreadCount = unreadNotificationsCountSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeNotificationFromPanel(notificationId))
      if (readAt === null) {
        dispatch(decUnreadNotificationsCount(unreadCount))
      }
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default removeNotificationOperation
