import React from 'react'
import PropTypes from 'prop-types'

const ExpertRounded = ({ size, color, className }) => (
  <svg
    preserveAspectRatio="none"
    width={`${size}px`}
    height={`${size}px`}
    className={className}
    fill={color}
    viewBox="0 0 512 512"
  >
    <path d="M256 0C114.611 0 0 114.611 0 256s114.611 256 256 256 256-114.611 256-256S397.389 0 256 0zm0 51.2c113.101 0 204.8 91.699 204.8 204.8 0 40.891-12.15 78.876-32.798 110.851l-19.702-15.25C368.569 320.958 295.348 307.2 256 307.2s-112.594 13.757-152.3 44.401L84 366.899C63.335 334.919 51.2 296.906 51.2 256c0-113.101 91.699-204.8 204.8-204.8zm0 44.8c-45.875 0-83.2 37.325-83.2 83.2s37.325 83.2 83.2 83.2 83.2-37.325 83.2-83.2S301.875 96 256 96zm0 51.2c17.639 0 32 14.362 32 32s-14.361 32-32 32c-17.638 0-32-14.362-32-32s14.362-32 32-32zm0 211.2c79.378 0 122.063 29.913 142.049 44.9-36.834 35.53-86.832 57.5-142.049 57.5-55.215 0-105.215-21.97-142.05-57.5 19.987-14.986 62.672-44.9 142.05-44.9zm-152.2 34.299c3.097 3.413 6.21 6.812 9.5 10.002-3.294-3.205-6.427-6.582-9.5-10.002zm303.55.901c-2.808 3.085-5.611 6.19-8.599 9.101 2.98-2.893 5.767-6.029 8.599-9.101z" />
  </svg>
)

ExpertRounded.defaultProps = {
  size: 24,
  color: '#b1bac9',
  className: '',
}

ExpertRounded.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default ExpertRounded
