import { propEq, prop, none } from 'ramda'

import isPresent from 'utils/isPresent'

const isWidgetServiceDisabled = service =>
  propEq('inactive', 'status', service) ||
  !isPresent(prop('userProfiles', service)) ||
  none(prop('active'))(prop('userProfiles', service))

export default isWidgetServiceDisabled
