import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { removeEtherpadDocumentIds } from '../actions'
import { deleteEtherpadDocumentEndpoint } from '../endpoints'
import { DELETE_ETHERPAD_DOCUMENT } from '../types'

const deleteEtherpadDocument = createLogic({
  type: DELETE_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action: { documentId, feedId, redirectTo }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = deleteEtherpadDocumentEndpoint(documentId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)

      redirectTo && redirect({ href: redirectTo, workspace: currentWorkspaceCodeSelector(getState()) })

      dispatch(removeEtherpadDocumentIds([documentId]))
      dispatch(dataDelete({ kind: 'document', ids: [documentId] }))
      feedId && dispatch(dataDelete({ kind: 'clientFeed', ids: [feedId] }))

      dispatch(hideModal())
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.etherpadDocumentWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteEtherpadDocument
