const namespace = 'filesSharedWithMe'

export const FETCH_FILES_SHARED_WITH_ME = `${namespace}/FETCH_FILES_SHARED_WITH_ME`
export const ADD_FILES_SHARED_WITH_ME_IDS = `${namespace}/ADD_FILES_SHARED_WITH_ME_IDS`
export const SET_FILES_SHARED_WITH_ME_IDS = `${namespace}/SET_FILES_SHARED_WITH_ME_IDS`
export const RESET_FILES_SHARED_WITH_ME_IDS = `${namespace}/RESET_FILES_SHARED_WITH_ME_IDS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const SET_CURRENT_FOLDER_ID = `${namespace}/SET_CURRENT_FOLDER_ID`
export const SET_SELECTED_IDS = `${namespace}/SET_SELECTED_IDS`
export const RESET_SELECTED_IDS = `${namespace}/RESET_SELECTED_IDS`
export const FILTER_FILES_SHARED_WITH_ME = `${namespace}/FILTER_FILES_SHARED_WITH_ME`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const MOVE_TO_FOLDER = `${namespace}/MOVE_TO_FOLDER`
export const FETCH_FOLDERS_TO_MOVE = `${namespace}/FETCH_FOLDERS_TO_MOVE`
export const ADD_FOLDER_TO_MOVE_IDS = `${namespace}/ADD_FOLDER_TO_MOVE_IDS`
export const RESET_FOLDER_TO_MOVE_IDS = `${namespace}/RESET_FOLDER_TO_MOVE_IDS`
export const SET_FOLDER_TO_MOVE_IDS = `${namespace}/SET_FOLDER_TO_MOVE_IDS`
export const REMOVE_FILE_ID = `${namespace}/REMOVE_FILE_ID`
export const MOVE_FILES_SHARED_WITH_ME_TO_TRASH = `${namespace}/MOVE_FILES_SHARED_WITH_ME_TO_TRASH`
export const REMOVE_FILES_SHARED_WITH_ME_BY_IDS = `${namespace}/REMOVE_FILES_SHARED_WITH_ME_BY_IDS`
export const REMOVE_MY_ACCESS = `${namespace}/REMOVE_MY_ACCESS`
export const RECEIVE_BUSINESS_STORAGE = `${namespace}/RECEIVE_BUSINESS_STORAGE`
export const RECEIVE_BUSINESS_STORAGE_PERMISSIONS = `${namespace}/RECEIVE_BUSINESS_STORAGE_PERMISSIONS`
