import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsSubscriptionRoute } from 'lib/routes'
import { isTrialSelector } from 'state/concepts/billing/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { DOWNGRADE_TO_FREE } from 'state/concepts/subscriptionPlans/types'
import { showNotification } from 'state/notifications/actions'
import { getUpdateSubscriptionsUrl } from 'utils/billing'
import { formattedFullDate } from 'utils/dateTime'
import redirect from 'utils/redirect'
import { changeSubscriptionDetailsSelector, plannedSubscriptionPlanSelector } from '../selectors'

const downgradeToFree = createLogic({
  type: DOWNGRADE_TO_FREE,
  latest: true,

  async process({ httpClient, getState, action: { values, form, onError } }, dispatch, done) {
    const state = getState()
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const isTrial = isTrialSelector(state)
    const {
      subscriptionCycleType,
      downgradeDate,
      currentSubscriptionPlan: { name: currentPlanName },
    } = changeSubscriptionDetailsSelector(state)
    const { name: planName } = plannedSubscriptionPlanSelector(state, values.planId)

    const url = getUpdateSubscriptionsUrl({
      isTrial,
      isUpgrade: false,
      isCurrentPlanFree: false,
      subscriptionCycleType,
    })

    try {
      await httpClient.post(url, {
        cancel_appointments: values.cancelAppointments,
        subscription_plan_id: values.planId,
        billing_cycle_type: values.billingCycle,
        auto_upgrade: false,
      })

      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.${isTrial ? 'subscriptionDowngradedToFreeInTrial' : 'subscriptionDowngraded'}`,
            values: {
              planName,
              currentPlanName,
              paymentDate: formattedFullDate(downgradeDate),
            },
          },
        }),
      )
      redirect({ href: companySettingsSubscriptionRoute, workspace: workspaceCode })
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
      onError?.()
    }

    done()
  },
})

export default downgradeToFree
