import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'

import {
  EDIT_WEBSITE_PREVIEW_STEPS,
  WEBSITE_META_DESCRIPTION_MAX_LENGTH,
  WEBSITE_PAGE_TITLE_MAX_LENGTH,
} from 'lib/constants/website'
import { websiteDraftSelector } from 'state/concepts/website/selectors'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { setWebsiteDraft as setWebsiteDraftAction } from 'state/concepts/website/actions'
import DiscardFormButton from 'views/shared/DiscardFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import { googleMetaValidationSchema } from 'lib/yupLocalised/schemas/website'
import DiscardBackButton from '../DiscardBackButton'

const GoogleSeo = ({ onClose, handleSetCurrentStep }) => {
  const website = useSelector(websiteDraftSelector)
  const websiteSetting = propOr({}, 'websiteSetting', website)
  const { pageTitle, metaDescription } = websiteSetting

  const updateWebsiteDraft = useDispatchAction(setWebsiteDraftAction)

  const handleSubmit = values => {
    updateWebsiteDraft({ ...website, websiteSetting: { ...website.websiteSetting, ...values } })
    onClose()
  }

  return (
    <Formik
      validationSchema={googleMetaValidationSchema}
      initialValues={{ pageTitle: pageTitle || '', metaDescription: metaDescription || '' }}
      onSubmit={handleSubmit}
    >
      <>
        <div data-cy="edit-seo-info-header" className="ws-header__editor-header">
          <DiscardFormButton
            text={{ id: 'websitePreview.getSeoSettings' }}
            component={DiscardBackButton}
            onDiscard={handleSetCurrentStep(EDIT_WEBSITE_PREVIEW_STEPS.main)}
          />
          <DiscardFormButton isIcon icon="cross" iconClassName="mr-4" iconSize={10} onDiscard={onClose} />
        </div>
        <div className="ws-header__editor-body" data-cy="google-section-body">
          <p data-cy="edit-seo-info-description">
            <FormattedMessage id="websitePreview.googleSeoSection.title.description" />
          </p>
          <Field
            id="pageTitle"
            name="pageTitle"
            component={TextAreaField}
            className="main-textarea main-input--textarea-top-count"
            label={{ id: 'websitePreview.googleSeoSection.title.label' }}
            maxLength={WEBSITE_PAGE_TITLE_MAX_LENGTH}
            showCount
            autoSize={{ minRows: 1, maxRows: 2 }}
            data-cy="edit-seo-info-page-title"
          />
          <Field
            id="metaDescription"
            name="metaDescription"
            component={TextAreaField}
            className="main-textarea main-input--textarea-top-count"
            label={{ id: 'websitePreview.googleSeoSection.metaDescription.label' }}
            maxLength={WEBSITE_META_DESCRIPTION_MAX_LENGTH}
            showCount
            autoSize={{ minRows: 1, maxRows: 4 }}
            data-cy="edit-seo-info-meta"
          />
        </div>
        <div className="ws-header__editor-footer">
          <DiscardFormButton text={{ id: 'shared.close' }} size="small" className="mr-16" onDiscard={onClose} />
          <SubmitFormButton text={{ id: 'shared.apply' }} size="small" />
        </div>
      </>
    </Formik>
  )
}

GoogleSeo.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSetCurrentStep: PropTypes.func.isRequired,
}

export default GoogleSeo
