import React from 'react'
import PropTypes from 'prop-types'

import useLoseChangesWarning from 'hooks/shared/form/useLoseChangesWarning'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import getDisplayName from 'utils/getDisplayName'
import * as modalActions from 'state/modal/actions'

/**
 * @deprecated use `useLoseChangesWarning` hook instead
 */

const withLoseChangesWarning = modalProps => WrappedComponent => {
  const WithLoseChangesWarning = ({ dirty, isSubmitting, ...props }) => {
    const showModal = useDispatchAction(modalActions.showModal)
    const hideModal = useDispatchAction(modalActions.hideModal)
    const modalActs = { showModal, hideModal }

    useLoseChangesWarning({ modalProps, formikProps: { dirty, isSubmitting } })

    return <WrappedComponent {...props} isSubmitting={isSubmitting} dirty={dirty} {...modalActs} />
  }

  WithLoseChangesWarning.displayName = `withLoseChangesWarning(${getDisplayName(WrappedComponent)})`
  WithLoseChangesWarning.pageTitle = WrappedComponent.pageTitle
  WithLoseChangesWarning.getInitialProps = context =>
    WrappedComponent.getInitialProps ? WrappedComponent.getInitialProps(context) : {}

  WithLoseChangesWarning.propTypes = {
    dirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }

  return WithLoseChangesWarning
}

export default withLoseChangesWarning
