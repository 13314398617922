import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setSearchResults, setBlankState } from 'state/searchResults/actions'
import { GET_CLIENT_CONSULTANTS } from 'state/concepts/filters/types'
import { getClientConsultantsEndpoint } from 'state/concepts/filters/endpoints'

const getClientConsultantsOperation = createLogic({
  type: GET_CLIENT_CONSULTANTS,
  latest: true,

  async process({ httpClient, action: { searchQuery } }, dispatch, done) {
    const { endpoint, url } = getClientConsultantsEndpoint
    const params = {
      page: {
        number: 1,
        size: 1000,
      },
      filter: {
        name: searchQuery,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const ids = pluck('id', data.data)

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.employees]: isBlankState({ params: { name: searchQuery }, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setSearchResults({ [SEARCH_RESULTS_ENTITIES.employees]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getClientConsultantsOperation
