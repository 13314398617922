import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Time = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.233-512-512 0-282.77 229.23-512 512-512 282.767 0 512 229.23 512 512 0 282.767-229.233 512-512 512zM512 921.6c226.217 0 409.6-183.383 409.6-409.6s-183.383-409.6-409.6-409.6c-226.216 0-409.6 183.384-409.6 409.6s183.384 409.6 409.6 409.6zM563.2 512h204.8v102.4h-307.2v-358.4h102.4v256z" />
  </svg>
)

Time.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Time.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Time
