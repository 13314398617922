import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'

import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { MAX_NAME_INPUT_LENGTH } from 'lib/constants/etherpadDocuments'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { renameEtherpadDocument } from 'state/concepts/etherpadDocuments/actions'
import etherpadDocument from 'lib/yupLocalised/schemas/etherpadDocument'

const RenameDocumentModal = ({ onClose, document, submitText, title, onSuccess }) => {
  const { name, id: documentId } = document
  const handleSubmit = useFormSubmit(renameEtherpadDocument, { onSuccess })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage {...title} />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            name,
            documentId,
          }}
          onSubmit={handleSubmit}
          validationSchema={etherpadDocument}
          enableReinitialize
        >
          <>
            <div className="main-modal__body">
              <Field
                className="mb-0 main-input--input-top-count"
                name="name"
                component={InputWithCounterField}
                id="name"
                icon="alert"
                label={{ id: 'etherpadDocuments.renameModal.name.label' }}
                showCount
                maxChars={MAX_NAME_INPUT_LENGTH}
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton className="main-modal__footer-action" text={submitText} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

RenameDocumentModal.defaultProps = {
  title: { id: 'etherpadDocuments.renameModal.title' },
  submitText: { id: 'shared.saveChanges' },
  onSuccess: null,
}

RenameDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    clientProfile: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string,
  }),
  submitText: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
}

export default RenameDocumentModal
