import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { ALERT_KINDS, MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import DateTime from 'views/shared/ScheduleAppointment/DateTime'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import Tooltip from 'views/shared/Tooltip'
import CrossIcon from 'views/shared/icons/Cross'

const RescheduleAppointmentComponent = ({
  onClose,
  values: { bookingId },
  values,
  setFieldValue,
  status,
  handleSubmit,
  isSubmitting,
  isSingle,
  invitedMembers,
}) => (
  <Tooltip open overlayClassName="booking-tooltip-wrapper">
    <section className="booking-tooltip booking-tooltip-with-header">
      <div className="booking-tooltip__toolbar">
        <h3 className="booking-tooltip__info-title" data-cy="reschedule-sidebar-title">
          <FormattedMessage id={isSingle ? 'bookings.rescheduleAppointment' : 'bookings.rescheduleRecurring'} />
        </h3>
        <div>
          <button className="in-gray-600" type="button" onClick={onClose}>
            <CrossIcon size={10} className="va-middle" />
          </button>
        </div>
      </div>

      <div className="booking-tooltip__body">
        <Alert
          className="auth__alert ml-24 mr-24"
          type={ALERT_KINDS.info}
          message={{
            id: invitedMembers?.length
              ? 'scheduleAppointment.reschedule.info.withInviteMembers'
              : 'scheduleAppointment.edit.info',
          }}
        />
        <DateTime
          bookingId={bookingId}
          status={status}
          values={values}
          setFieldValue={setFieldValue}
          isTooltip
          withDivider={false}
        />
        <div className="booking-tooltip__info mt-16">
          <Field
            className="textarea-reason mb-24 full-width main-input--textarea-top-count"
            name="reason"
            component={TextAreaField}
            id="reason"
            label={{ id: 'rescheduleBookingModal.reason' }}
            icon="alert"
            data-cy="reschedule-tooltip-reason-field"
            showCount
            maxLength={MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH}
          />
        </div>
      </div>

      <div className="booking-tooltip__btns">
        <Button className="mr-16 mr-xs-4" kind="flat" text={{ id: 'shared.cancel' }} onClick={onClose} />
        <SubmitFormButton
          text={{ id: 'shared.saveChanges' }}
          type="submit"
          onClick={handleSubmit}
          isSubmitting={isSubmitting}
          disabled={false}
        />
      </div>
    </section>
  </Tooltip>
)

RescheduleAppointmentComponent.defaultProps = {
  status: undefined,
}

RescheduleAppointmentComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  invitedMembers: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  status: PropTypes.shape(),
  isSubmitting: PropTypes.bool.isRequired,
  isSingle: PropTypes.bool.isRequired,
}

export default RescheduleAppointmentComponent
