export const TIME_UNITS_MINUTE = 'minutes'
export const TIME_UNITS_HOUR = 'hours'
export const TIME_UNITS_DAY = 'days'
export const TIME_UNITS_MONTH = 'months'

export const TIME_INTERVAL_15 = '15'
export const TIME_INTERVAL_30 = '30'
export const TIME_INTERVAL_45 = '45'

export const TIME_UNIT_MINUTE = {
  label: { id: `timeUnits.${TIME_UNITS_MINUTE}` },
  value: TIME_UNITS_MINUTE,
  key: TIME_UNITS_MINUTE,
}
export const TIME_UNIT_HOUR = {
  label: { id: `timeUnits.${TIME_UNITS_HOUR}` },
  value: TIME_UNITS_HOUR,
  key: TIME_UNITS_HOUR,
}
export const TIME_UNIT_DAY = {
  label: { id: `timeUnits.${TIME_UNITS_DAY}` },
  value: TIME_UNITS_DAY,
  key: TIME_UNITS_DAY,
}
export const TIME_UNIT_MONTH = {
  label: { id: `timeUnits.${TIME_UNITS_MONTH}` },
  value: TIME_UNITS_MONTH,
  key: TIME_UNITS_MONTH,
}

export const TIME_UNITS_OPTIONS = [TIME_UNIT_MINUTE, TIME_UNIT_HOUR, TIME_UNIT_DAY]

export const TIME_UNITS_INTERVAL_OPTIONS = [
  { label: { id: `timeUtils.interval.${TIME_INTERVAL_15}` }, value: TIME_INTERVAL_15, key: TIME_INTERVAL_15 },
  { label: { id: `timeUtils.interval.${TIME_INTERVAL_30}` }, value: TIME_INTERVAL_30, key: TIME_INTERVAL_30 },
  { label: { id: `timeUtils.interval.${TIME_INTERVAL_45}` }, value: TIME_INTERVAL_45, key: TIME_INTERVAL_45 },
]

export const MILLISECONDS_PER_SECOND = 1000
export const SECONDS_PER_HOUR = 3600
export const SECONDS_PER_MINUTE = 60
export const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE
export const MINUTES_PER_HOUR = 60
export const MINUTES_PER_DAY = 1440
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24
export const SECONDS_PER_MONTH = 2629746
export const HOURS_PER_DAY = 24
export const DAYS_PER_WEEK = 7
