import { createLogic } from 'redux-logic'
import { trim } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { currentClientIdSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { formattedTimezone } from 'state/concepts/timezones/selectors'
import { UPDATE_CLIENT_OWN_PROFILE } from 'state/concepts/client/types'
import { updateClientOwnProfileEndpoint } from '../endpoints'

const updateClientOwnProfileOperation = createLogic({
  type: UPDATE_CLIENT_OWN_PROFILE,
  latest: true,

  async process({ getState, action: { form, values }, httpClient }, dispatch, done) {
    const { url } = updateClientOwnProfileEndpoint
    const state = getState()

    const params = {
      first_name: trim(values.firstName),
      last_name: trim(values.lastName),
      timezone: values.timezone,
    }

    try {
      await httpClient.put(url, params)

      const attributes = {
        ...values,
        firstName: trim(values.firstName),
        lastName: trim(values.lastName),
        formattedTimezone: formattedTimezone(values.timezone)(state),
      }

      const response = updateDataHelper(state.data, 'clientProfile', currentClientIdSelector(state), { attributes })

      form.setValues(attributes)

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.clientProfileUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateClientOwnProfileOperation
