import React from 'react'
import { Rate } from 'antd'

export const REVIEW_STATUSES = {
  all: 'allReviews',
  published: 'published',
  unpublished: 'unpublished',
}

export const RATING_SCALE = [5, 4, 3, 2, 1]
export const MIN_RATING = 1
export const MAX_RATING = 5
export const ROUND_PRECISION = 1

export const REVIEW_STATUSES_FILTERS = [
  {
    value: REVIEW_STATUSES.all,
    label: { id: 'company.reviews.statusFilter.allReviews' },
  },
  {
    value: REVIEW_STATUSES.published,
    label: { id: 'company.reviews.statusFilter.published' },
  },
]

export const REVIEWS_RATINGS_FILTER_OPTIONS = [
  { value: '5', label: <Rate defaultValue={5} className="ml-12" disabled /> },
  { value: '4', label: <Rate defaultValue={4} className="ml-12" disabled /> },
  { value: '3', label: <Rate defaultValue={3} className="ml-12" disabled /> },
  { value: '2', label: <Rate defaultValue={2} className="ml-12" disabled /> },
  { value: '1', label: <Rate defaultValue={1} className="ml-12" disabled /> },
]
