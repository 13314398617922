import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingPaymentsRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { fetchTransactions } from 'state/concepts/payments/actions'
import { DELETE_DRAFT_PAYMENT } from 'state/concepts/payment/types'
import { deleteDraftPaymentEndpoint } from 'state/concepts/payment/endpoints'
import { hideModal } from 'state/modal/actions'

const deleteDraftPaymentOperation = createLogic({
  type: DELETE_DRAFT_PAYMENT,
  latest: true,

  async process({ httpClient, getState, action: { paymentId, fromPayments } }, dispatch, done) {
    const { url, endpoint } = deleteDraftPaymentEndpoint(paymentId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      if (fromPayments) {
        dispatch(fetchTransactions())
      } else {
        await redirect({ href: companyBillingPaymentsRoute, workspace: workspaceCode })
      }
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.paymentDraftDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteDraftPaymentOperation
