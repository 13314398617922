import endpoint from 'utils/endpoint'
import {
  clientAttachmentsRoute,
  clientAttachmentRoute,
  clientsSharedFileRoute,
  clientAttachmentsPresignRoute,
  clientAttachmentsUploadRoute,
  clientShareFilesRoute,
} from 'lib/apiRoutes'
import {
  FETCH_CLIENT_ATTACHMENTS,
  DELETE_CLIENT_ATTACHMENT,
  REMOVE_CLIENT_ACCESS,
  PRESIGN_CLIENT_ATTACHMENTS,
  UPLOAD_CLIENT_ATTACHMENTS,
  SHARE_FILE_WITH_CLIENT,
} from './types'

export const fetchClientAttachmentsEndpoint = clientId =>
  endpoint(FETCH_CLIENT_ATTACHMENTS, 'GET', clientAttachmentsRoute(clientId))
export const deleteClientAttachmentEndpoint = (clientId, clientAttachmentId) =>
  endpoint(DELETE_CLIENT_ATTACHMENT, 'DELETE', clientAttachmentRoute(clientId, clientAttachmentId))
export const deleteClientAccessEndpoint = sharedFileId =>
  endpoint(REMOVE_CLIENT_ACCESS, 'DELETE', clientsSharedFileRoute(sharedFileId))
export const clientAttachmentsPresignEndpoint = clientId =>
  endpoint(PRESIGN_CLIENT_ATTACHMENTS, 'POST', clientAttachmentsPresignRoute(clientId))
export const clientAttachmentsUploadEndpoint = clientId =>
  endpoint(UPLOAD_CLIENT_ATTACHMENTS, 'POST', clientAttachmentsUploadRoute(clientId))
export const shareFilesWithClientEndpoint = clientId =>
  endpoint(SHARE_FILE_WITH_CLIENT, 'POST', clientShareFilesRoute(clientId))
