export const WEEKDAYS_MESSAGES = {
  sunday: { id: 'calendar.sunday' },
  monday: { id: 'calendar.monday' },
  tuesday: { id: 'calendar.tuesday' },
  wednesday: { id: 'calendar.wednesday' },
  thursday: { id: 'calendar.thursday' },
  friday: { id: 'calendar.friday' },
  saturday: { id: 'calendar.saturday' },
}

export const WEEKDAYS_SHORT_MESSAGES = {
  sunday: { id: 'calendar.shortDayNames.sunday' },
  monday: { id: 'calendar.shortDayNames.monday' },
  tuesday: { id: 'calendar.shortDayNames.tuesday' },
  wednesday: { id: 'calendar.shortDayNames.wednesday' },
  thursday: { id: 'calendar.shortDayNames.thursday' },
  friday: { id: 'calendar.shortDayNames.friday' },
  saturday: { id: 'calendar.shortDayNames.saturday' },
}

export const WEEKDAYS_MAPPING = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const SUNDAY_SHORT = 'Sun'

export const CALENDAR_WEEK_VIEW_STEP = 15

export const DEFAULT_SCROLL_TO_TIME = 9

export const CALENDAR = 'main-calendar'
export const CALENDAR_BODY = 'availability-table-body'
export const CALENDAR_BODY_CELL = 'main-calendar__body-cell'
export const CALENDAR_BODY_FIRST_CELL = 'main-calendar__first-col'
export const CALENDAR_HEADER = 'main-calendar__header'
export const CALENDAR_HEADER_CELL = 'main-calendar__header-cell'
export const CALENDAR_ROW = 'main-calendar__row'

export const DEFAULT_AVAILABILITY = { ids: [], online: true, locationIds: [] }

export const BASE_CALENDAR_OFFSET = { offset: [0, 0] }
