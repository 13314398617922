import createFolderOperation from './createFolder'
import fetchMyBusinessFiles from './fetchMyBusinessFiles'
import fetchCurrentFolder from './fetchCurrentFolder'
import filterMyBusinessFiles from './filterMyBusinessFiles'
import duplicateMyBusinessFile from './duplicateMyBusinessFile'
import renameMyBusinessFile from './renameMyBusinessFile'
import shareMyBusinessFile from './shareMyBusinessFile'
import uploadFile from './uploadFile'
import presignFiles from './presignFiles'
import moveMyBusinessFileToTrash from './moveMyBusinessFileToTrash'
import cancelFilesDownload from './cancelFilesDownload'
import downloadBusinessFiles from './downloadBusinessFiles'
import completeFilesDownload from './completeFilesDownload'
import addClientBusinessFiles from './addClientBusinessFiles'
import moveToFolder from './moveToFolder'
import fetchFoldersToMove from './fetchFoldersToMove'
import receivedBusinessStorage from './receivedBusinessStorage'

export default [
  fetchMyBusinessFiles,
  fetchCurrentFolder,
  filterMyBusinessFiles,
  createFolderOperation,
  duplicateMyBusinessFile,
  renameMyBusinessFile,
  shareMyBusinessFile,
  uploadFile,
  presignFiles,
  moveMyBusinessFileToTrash,
  cancelFilesDownload,
  downloadBusinessFiles,
  completeFilesDownload,
  addClientBusinessFiles,
  moveToFolder,
  fetchFoldersToMove,
  receivedBusinessStorage,
]
