import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withFormik } from 'formik'
import { withRouter } from 'next/router'

import validationSchema from 'lib/yupLocalised/schemas/rescheduleBookingSchema'
import hasScroll from 'utils/hasScroll'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { clientRescheduleBooking } from 'state/concepts/booking/actions'
import { currentUserTimezoneSelector } from 'state/concepts/session/selectors'
import RescheduleFormModalComponent from './component'

class RescheduleFormModal extends React.Component {
  scrollContainerRef = React.createRef()

  state = {
    hasScroll: false,
  }

  handleResize = () => {
    this.setState({ hasScroll: hasScroll(this.scrollContainerRef.current) })
  }

  render = () => (
    <RescheduleFormModalComponent
      scrollContainerRef={this.scrollContainerRef}
      {...this.props}
      {...this.state}
      handleResize={this.handleResize}
    />
  )
}

const mapStateToProps = state => ({
  timezone: currentUserTimezoneSelector(state),
})

const mapDispatchToProps = {
  onSubmit: clientRescheduleBooking,
}

export { RescheduleFormModal as RescheduleFormModalContainer }
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({
      router: {
        query: { bookingUniqCode },
      },
      timezone,
      booking,
    }) => ({
      uniqCode: bookingUniqCode || booking.uniqCode,
      timezone,
      startTime: '',
      rescheduleReason: '',
    }),
    validationSchema,
    handleSubmit: handleSubmitWithProps(['notificationShown']),
  }),
)(RescheduleFormModal)
