const serviceAgreementsFeature = {
  'serviceAgreementsFeature.title': '_(Service agreements)',
  'serviceAgreementsFeature.subtitle': '<span>in</span> Forms & documents',
  'serviceAgreementsFeature.description': 'Ask _(clients) to sign legal documents before providing _(services).',
  'serviceAgreementsFeature.manageFeatures': 'Set up agreements',
  'serviceAgreementsFeature.upgradePlan': 'Upgrade plan',
  'serviceAgreementsFeature.overview': '_(Service agreements) overview',
  'serviceAgreementsFeature.toLearnMores':
    'To learn more, read our help center article on <link>managing _(service agreements)</link>.',
  'serviceAgreementsFeature.overview.text1':
    'ExpertBox _(service agreements) let you eliminate paperwork and set clear expectations for your _(clients) while protecting your business.',
  'serviceAgreementsFeature.overview.text2': 'Customize your _(service agreements):',
  'serviceAgreementsFeature.overview.text3': 'Create your own set of documents that meet industry requirements.',
  'serviceAgreementsFeature.overview.text4': 'Collect informed consent from _(clients):',
  'serviceAgreementsFeature.overview.text5':
    'Assign _(service agreements) to _(services) so that _(clients) are automatically asked to sign them when they book _a(appointment).',
  'serviceAgreementsFeature.overview.text6': 'Send agreements to _(clients) anytime as needed.',
  'serviceAgreementsFeature.overview.text7': 'Easily manage your documents:',
  'serviceAgreementsFeature.overview.text8': 'Get an email and in-app notification as soon as an agreement is signed.',
  'serviceAgreementsFeature.overview.text9':
    'Easily access shared documents in _a(client)’s profile or on the _(appointment) page.',
  'serviceAgreementsFeature.overview.text10':
    'All documents and communications are secure and protected with an encrypted SSL connection and GDPR compliance.',
  'serviceAgreementsFeature.disable.title': 'Disable _(service agreements) feature?',
  'serviceAgreementsFeature.disable.description':
    'This will delete all _(service agreements) in your workspace, both for you and your _(clients), even those already signed.',
}

export default serviceAgreementsFeature
