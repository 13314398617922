import * as types from './types'

export const fetchClientAccountBookings = () => ({
  type: types.FETCH_BOOKINGS,
})

export const filterClientAccountBookings = filters => ({
  type: types.FILTER_BOOKINGS,
  filters,
})

export const switchClientAccountBookings = bookingsType => ({
  type: types.SWITCH_BOOKINGS,
  bookingsType,
})

export const setClientAccountBookingsIds = clientAccountBookingsIds => ({
  type: types.SET_BOOKINGS,
  clientAccountBookingsIds,
})

export const resetClientAccountBookingsIds = () => ({
  type: types.RESET_BOOKINGS,
})

export const setSortOrder = sortOrder => ({
  type: types.SET_SORT_ORDER,
  sortOrder,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const removeClientAccountBookings = bookingIds => ({
  type: types.REMOVE_BOOKINGS,
  bookingIds,
})

export const setPendingBookingsCount = count => ({
  type: types.SET_PENDING_BOOKINGS_COUNT,
  count,
})

export const decrementPendingBookingsCount = (amount = 1) => ({
  type: types.DECREMENT_PENDING_BOOKINGS_COUNT,
  amount,
})
