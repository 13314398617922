import endpoint from 'utils/endpoint'
import { clientsBookingRecurrenceRoute, waitlistRequestsBookingRoute } from 'lib/apiRoutes'
import { FETCH_BOOKING_RECURRENCE, BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST } from './types'

export const fetchBookingRecurrenceEndpoint = bookingRecurrenceId =>
  endpoint(FETCH_BOOKING_RECURRENCE, 'GET', clientsBookingRecurrenceRoute(bookingRecurrenceId))

export const bookAppointmentFromWaitlistRequestEndpoint = endpoint(
  BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST,
  'POST',
  waitlistRequestsBookingRoute,
)
