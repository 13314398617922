import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_CHAT_MEMBERS } from 'state/concepts/chat/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setChatMemberIds } from 'state/concepts/chat/actions'
import { getChatMembersEndpoint } from '../endpoints'

const getChatMembersOperation = createLogic({
  type: GET_CHAT_MEMBERS,
  latest: true,

  async process({ action: { chatId }, httpClient }, dispatch, done) {
    const { endpoint, url } = getChatMembersEndpoint(chatId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(setChatMemberIds(pluck('id', data.data)))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getChatMembersOperation
