import { createLogic } from 'redux-logic'

import isPresent from 'utils/isPresent'
import { removeUploadLoadingStatus, removeUploadProgress, unlinkUploadToAttachment } from 'state/concepts/chat/actions'
import { attachmentIdsByUploadIdsSelector } from 'state/concepts/chat/selectors'
import { CANCEL_ATTACHMENT_UPLOAD } from 'state/concepts/chat/types'
import { removeAttachmentUploadEndpoint } from 'state/concepts/chat/endpoints'

const cancelAttachmentUploadOperation = createLogic({
  type: CANCEL_ATTACHMENT_UPLOAD,
  latest: false,

  async process({ httpClient, getState, action: { uploadIds } }, dispatch, done) {
    const attachmentIds = attachmentIdsByUploadIdsSelector(getState(), uploadIds)

    const params = {
      attachment_ids: attachmentIds,
    }

    const { url } = removeAttachmentUploadEndpoint

    try {
      if (isPresent(attachmentIds)) {
        await httpClient.delete(url, { params })
      }

      dispatch(removeUploadLoadingStatus(uploadIds))
      dispatch(removeUploadProgress(uploadIds))
      dispatch(unlinkUploadToAttachment(uploadIds))
      // eslint-disable-next-line no-empty
    } catch (_e) {}

    done()
  },
})

export default cancelAttachmentUploadOperation
