import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_STRIPE_CONNECT_LINK } from 'state/concepts/paymentSettings/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { setStripeConnectLink } from 'state/concepts/paymentSettings/actions'
import { getStripeConnectLinkEndpoint } from 'state/concepts/paymentSettings/endpoints'

const getStripeConnectLinkOperation = createLogic({
  type: GET_STRIPE_CONNECT_LINK,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = getStripeConnectLinkEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setStripeConnectLink(data.meta.url))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getStripeConnectLinkOperation
