import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'

import { BLANK_SERVICE_CATEGORY_COLOR } from 'lib/constants/bookings'

const BookingServiceCategory = ({ booking: { serviceCategory } }) => (
  <div className="d-flex align-items-center">
    <span
      className="main-card__category"
      style={{ backgroundColor: prop('color', serviceCategory) || BLANK_SERVICE_CATEGORY_COLOR }}
    />
    <p
      className="in-blue-gray-300 font-600 d-inline-block text-caption text-uppercase mb-0"
      data-cy="booking-service-category"
    >
      {serviceCategory ? prop('name', serviceCategory) : <FormattedMessage id="bookings.noServiceCategory" />}
    </p>
  </div>
)

BookingServiceCategory.propTypes = {
  booking: PropTypes.shape().isRequired,
}

export default BookingServiceCategory
