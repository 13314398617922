import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Drag = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M182.857 438.857c20.197 0 36.571 16.374 36.571 36.572S203.054 512 182.856 512c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zm146.285 0c20.197 0 36.572 16.374 36.572 36.572S349.34 512 329.142 512c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zM182.857 292.572c20.197 0 36.571 16.374 36.571 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zm146.285 0c20.197 0 36.572 16.374 36.572 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zM182.857 146.286c20.197 0 36.571 16.374 36.571 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zm146.285 0c20.197 0 36.572 16.374 36.572 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572s16.374-36.572 36.572-36.572zM182.857.001c20.197 0 36.571 16.374 36.571 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572S162.658.001 182.856.001zm146.285 0c20.197 0 36.572 16.374 36.572 36.572s-16.374 36.572-36.572 36.572c-20.197 0-36.572-16.374-36.572-36.572S308.944.001 329.142.001z" />
  </svg>
)

Drag.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Drag.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Drag
