import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import parseToJSONAPIFormat from 'utils/parseToJsonApiFormat'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { UPDATE_SMS_NOTIFICATIONS_SETTINGS } from 'state/concepts/smsNotificationsSettings/types'
import { updateSMSNotificationsSettingsEndpoint } from 'state/concepts/smsNotificationsSettings/endpoints'

const updateSMSNotificationsSettingsOperation = createLogic({
  type: UPDATE_SMS_NOTIFICATIONS_SETTINGS,
  latest: true,

  async process({ getState, action: { form, values }, httpClient }, dispatch, done) {
    const { endpoint, url } = updateSMSNotificationsSettingsEndpoint

    const params = parseToJSONAPIFormat(values)

    try {
      await httpClient.put(url, params)

      const response = updateDataHelper(getState().data, 'smsNotificationSetting', values.id, {
        attributes: values,
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.sms.updated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updateSMSNotificationsSettingsOperation
