import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'
import build from 'redux-object'

import { getClientReviewEndpoint, getClientReviewTemplateEndpoint } from 'state/concepts/clientAccountReview/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'

export const clientAccountReviewIdSelector = path(['clientAccountReview', 'reviewId'])

export const clientAccountReviewSelector = createSelector([clientAccountReviewIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'review', id) : {},
)

export const clientReviewTemplateLoadingSelector = (state, bookingUniqCode) =>
  loadingSelector(state, getClientReviewTemplateEndpoint(bookingUniqCode).endpoint)

export const clientAccountReviewLoadingSelector = (state, reviewId) =>
  loadingSelector(state, getClientReviewEndpoint(reviewId).endpoint)
