import { path, propOr } from 'ramda'

import { FEATURE_KEYS, FEATURE_STATUSES } from 'lib/constants/workspaceFeatures'

export const getFeaturesStatus = features =>
  features.reduce((acc, feature) => {
    acc[feature.key] = feature.status !== FEATURE_STATUSES.disabled
    return acc
  }, {})

export const clientPaymentsPermissions = (client, featuresStatus) =>
  client.transactionsCount > 0 || featuresStatus[FEATURE_KEYS.acceptPayments]

const clientPermissions = ({ client, workspaceFeatures }) => {
  const featuresStatus = getFeaturesStatus(workspaceFeatures)
  const currentContactPermissions = path(['currentContact', 'clientRelationshipType'], client)
  const responsibleForBilling = path(['currentContact', 'responsibleForBilling'], client)
  const canManageDocuments = propOr(true, 'manageDocuments', currentContactPermissions)
  const canViewDocuments = propOr(true, 'viewDocuments', currentContactPermissions)
  const canViewPayments = propOr(true, 'viewBillings', currentContactPermissions) || responsibleForBilling
  const canInteractWithDocuments = canManageDocuments || canViewDocuments

  return {
    viewChats: featuresStatus[FEATURE_KEYS.chat],
    viewDocuments: canViewDocuments && (!!client.sharedDocumentsCount || featuresStatus[FEATURE_KEYS.textDocuments]),
    viewPayments: canViewPayments && clientPaymentsPermissions(client, featuresStatus),
    viewForms: canInteractWithDocuments && (!!client.assignedFormsCount || featuresStatus[FEATURE_KEYS.forms]),
    viewAgreements:
      canInteractWithDocuments && (!!client.assignedAgreementsCount || featuresStatus[FEATURE_KEYS.serviceAgreements]),
    viewFiles: canViewDocuments,
  }
}

export default clientPermissions
