export default {
  'voiceMessagesFeature.title': 'Voice Messages',
  'voiceMessagesFeature.subtitle': '<span>in</span> Messenger',
  'voiceMessagesFeature.overview': 'Voice messages overview',
  'voiceMessagesFeature.manage': 'Go to chats',
  'voiceMessagesFeature.disable.title': 'Disable Voice messages feature?',
  'voiceMessagesFeature.alert.chatFeatureTitle':
    'To enable voice messages, you need to start using chat feature on ExpertBox.',
  'voiceMessagesFeature.alert.chatFeatureText': 'Enable Chat',
  'voiceMessagesFeature.disable.description':
    'Sending voice messages in this workspace will no longer be available, but you can still listen to your old messages.',
  'voiceMessagesFeature.description': 'Connect with team and _(clients) in a quick and easy way.',
  'voiceMessagesFeature.text.1': 'Efficiently communicate with _(clients) using voice messages!',
  'voiceMessagesFeature.text.2': 'Communicate efficiently:',
  'voiceMessagesFeature.text.3': 'Send and receive voice messages to team members and _(clients) with ease.',
  'voiceMessagesFeature.text.4':
    'Provide quick and personalized responses, making communication more engaging and efficient.',
  'voiceMessagesFeature.text.5': 'Provide constant support to _(clients):',
  'voiceMessagesFeature.text.6':
    'Quickly address your _(clients)’ needs without composing lengthy messages or scheduling additional _(appointments).',
  'voiceMessagesFeature.text.7': 'Add a personal touch by sharing emotions with your voice.',
  'voiceMessagesFeature.text.8': 'Protect your privacy:',
  'voiceMessagesFeature.text.9': 'Comply with GDPR and HIPAA requirements.',
  'voiceMessagesFeature.text.10': 'Use encrypted SSL connections for secure communication.',
  'voiceMessagesFeature.toLearnMores':
    'To learn more, read our help center articles on <howWorkChatLink>how ExpertBox chats work</howWorkChatLink> and on <createChatLink>creating chats</createChatLink> and <joinChatLink>joining chats</joinChatLink>.',
}
