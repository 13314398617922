import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import isStripePaymentError from 'utils/stripe/isStripePaymentError'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { RETRY_PAYMENT } from 'state/concepts/billing/types'
import { lastTransactionSelector } from 'state/concepts/billing/selectors'
import { removeSubscriptionTransactions } from 'state/concepts/billing/actions'
import { retryPaymentEndpoint } from 'state/concepts/billing/endpoints'

const retryPaymentOperation = createLogic({
  type: RETRY_PAYMENT,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = retryPaymentEndpoint
    const state = getState()
    const { id } = lastTransactionSelector(state)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const params = {
        include: [
          'payment-method.billing-address',
          'subscription-plan',
          'storage',
          'video-calls',
          'billing-account',
          'last-transaction',
          'workspace',
          'workspace.current-billing-period',
          'video_recordings',
          'sms',
          'planned-subscription-plan',
          'planned-storage-plan',
        ],
      }

      const { data } = await httpClient.post(url, params)
      const response = normalize(data)

      dispatch(dataDelete({ kind: 'subscriptionTransaction', ids: [id] }))
      dispatch(removeSubscriptionTransactions([id]))
      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.retryPayment.paymentWasSuccessful' } }))
    } catch (error) {
      if (isStripePaymentError(error)) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.retryPayment.paymentWasFailed',
            },
            kind: 'error',
          }),
        )
      }
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default retryPaymentOperation
