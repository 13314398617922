import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { includes } from 'ramda'
import { CancelToken, isCancel } from 'axios'

import isNetworkError from 'utils/isNetworkError'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import {
  setUploadLoadingStatus,
  linkUploadToAttachment,
  uploadAttachment,
  addUploadError,
} from 'state/concepts/chat/actions'
import { CREATE_ATTACHMENT_UPLOAD, CANCEL_ATTACHMENT_UPLOAD } from 'state/concepts/chat/types'
import { createAttachmentUploadEndpoint } from 'state/concepts/chat/endpoints'

const createAttachmentUploadOperation = createLogic({
  type: CREATE_ATTACHMENT_UPLOAD,
  latest: false,

  async process({ httpClient, action, action$ }, dispatch, done) {
    const {
      chatId,
      fileUpload: { id: uploadId, type, file },
    } = action
    const { name: filename } = file
    const { endpoint, url } = createAttachmentUploadEndpoint(chatId)

    const source = CancelToken.source()
    action$.subscribe(newAction => {
      const isCancelAllowed = newAction.type === CANCEL_ATTACHMENT_UPLOAD && includes(uploadId, newAction.uploadIds)

      if (isCancelAllowed) {
        source.cancel()
      }
    })

    dispatch(dataApiRequest({ endpoint }))
    dispatch(setUploadLoadingStatus(uploadId, true))

    try {
      const {
        data,
        data: { meta },
      } = await httpClient.post(
        url,
        { filename, attachment_type: type },
        {
          cancelToken: source.token,
        },
      )
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(linkUploadToAttachment(uploadId, data.data.id))
      dispatch(uploadAttachment(uploadId, file, meta))
    } catch (error) {
      if (isNetworkError(error)) {
        dispatch(addUploadError(uploadId))
      } else if (!isCancel(error)) {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default createAttachmentUploadOperation
