const namespace = 'promocodes'

export const ADD_PROMOCODE = `${namespace}/ADD_PROMOCODE`
export const SET_PROMOCODES = `${namespace}/SET_PROMOCODES`
export const VALIDATE_PROMOCODES = `${namespace}/VALIDATE_PROMOCODES`
export const SET_TRIAL_CODE = `${namespace}/SET_TRIAL_CODE`
export const SET_DISCOUNT_CODE = `${namespace}/SET_DISCOUNT_CODE`
export const APPLY_PROMOCODE = `${namespace}/APPLY_PROMOCODE`
export const SET_APPLIED_PROMOCODES = `${namespace}/SET_APPLIED_PROMOCODES`
export const RESET_PROMOCODES = `${namespace}/RESET_PROMOCODES`
