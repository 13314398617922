import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const DocumentCreate = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path
      fill="#b2bac7"
      d="M170.672 42.672c-46.933 0-85.333 38.4-85.333 85.333v682.667c0 46.933 38.4 85.333 85.333 85.333h476.668l-85.333-85.333h-391.334v-682.667h298.667v213.333h213.333v178.001l85.333 85.333v-306.001l-256-256h-341.333z"
    />
    <path
      fill="#388bff"
      d="M554.672 597.339v85.333l219.584 219.584 85.333-85.333-219.584-219.584h-85.333zM889.755 847.088l-85.333 85.333 42.667 42.667c8.32 8.32 21.845 8.32 30.165 0l55.168-55.168c8.32-8.363 8.32-21.845 0-30.165l-42.667-42.667z"
    />
  </svg>
)

DocumentCreate.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

DocumentCreate.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DocumentCreate
