import { SECONDS_PER_MINUTE } from './timeUnits'

export const LOGOUT_INTERVALS = ['300', '600', '900', '1800']

export const LOGOUT_INTERVAL_OPTIONS = LOGOUT_INTERVALS.map(interval => ({
  key: interval,
  value: interval,
  label: {
    id: 'automaticLogout.select.option',
    values: { interval: interval / SECONDS_PER_MINUTE },
  },
}))

// This interval in seconds is used to prevent an issue when backend logs out user before frontend
export const SYNC_SAFETY_GAP = 3
export const SESSION_EXPIRING_MODAL_INTERVAL = SECONDS_PER_MINUTE
export const MODAL_START_TIME_COOKIE_KEY = 'sessionExpiringModalStartTime'
