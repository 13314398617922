import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { disconnectAppleCalendar as disconnectAppleCalendarAction } from 'state/concepts/calendarIntegrations/actions'
import { loadingSelector } from 'state/data/selectors'
import { disconnectAppleCalendarEndpoint } from 'state/concepts/calendarIntegrations/endpoints'
import DisconnectModal from '../DisconnectModal'

const AppleDisconnectModal = ({ connection: { id }, onClose }) => {
  const disconnectAppleCalendar = useDispatchAction(disconnectAppleCalendarAction)

  const isLoading = useSelector(state =>
    loadingSelector(state, disconnectAppleCalendarEndpoint(path(['connection', 'id'], state)).endpoint),
  )

  const handleSubmit = () => {
    disconnectAppleCalendar(id)
  }

  return <DisconnectModal onClose={onClose} handleSubmit={handleSubmit} isLoading={isLoading} />
}

AppleDisconnectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default AppleDisconnectModal
