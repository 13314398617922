export function textMaxLength(max) {
  return this.test(
    'textMaxLength',
    {
      id: 'yup.string.max',
      values: { max },
    },
    value => {
      const currentContent = value.getCurrentContent()

      return currentContent.getPlainText().length <= max
    },
  )
}

export function hasText() {
  return this.test(
    'hasText',
    {
      id: 'yup.mixed.required',
    },
    value => {
      const currentContent = value.getCurrentContent()

      return currentContent.hasText()
    },
  )
}
