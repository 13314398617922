const companyClients = {
  'clients.title': '_(Clients)',
  'clients.header.new': 'New _(client)',
  'clients.header.import': 'Import',
  'clients.empty': 'No _(clients) have been added yet.',
  'clients.empty.newClients': 'New _(clients) are added automatically once they book _a(appointment). \n',
  'clients.table.client': '_(Client)',
  'clients.table.upcomingAppointments': 'Upcoming _(appointments)',
  'clients.table.allAppointments': 'All _(appointments)',
  'clients.table.actions.delete': 'Delete',
  'client.table.actions.sendInvitation': 'Invite to _(Client) portal',
  'client.table.actions.resendInvitation': 'Resend _(client) portal invitation',
  'clients.table.actions.delete.confirmModal.title': 'Delete _(client) profile?',
  'clients.table.actions.delete.confirmModal.body':
    'This will delete all the information from the _(client) profile, such as _(client) notes or files. _(Appointments), chats or invoices with this _(client) won’t be deleted.',
  'clients.table.actions.delete.confirmModal.clientIsAContact':
    'Also, so that you know, this _(client) is a contact to {associatedClientContactsCount} {associatedClientContactsCount, plural, one {_(client)} other {_(clients)}} in your {workspaceName}. By deleting this profile, {fullName} will lose any permissions and access to their information and _(appointments).',
  'clients.table.welcomeToPage': 'Welcome to your _(clients) page',
  'clients.table.welcomeMessage':
    '_(Clients) will appear here after they book _a(appointment). Then you’ll be able to check and edit their data, manage _(appointments) and history.',
  'clients.welcomeMessage':
    '_(Clients) will appear here after they book _a(appointment). Then you’ll be able to check and edit their data, manage _(appointments) and history.',
  'clients.welcomeToPage': 'Welcome to your _(clients) page!',
}

export default companyClients
