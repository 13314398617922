import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Microphone = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M511.999.001c-89.304 0-161.684 72.381-161.684 161.684v323.367c0 89.304 72.38 161.684 161.684 161.684s161.684-72.381 161.684-161.684V161.685C673.683 72.381 601.302.001 511.999.001zm-318.63 485.052c-32.66 0-58.966 28.98-53.684 61.262 26.402 161.668 155.72 288.46 318.42 311.791v111.999c0 29.75 24.145 53.895 53.895 53.895s53.895-24.145 53.895-53.895V858.106c162.703-23.331 292.017-150.124 318.42-311.791 5.282-32.282-21.024-61.262-53.685-61.262-26.624 0-48.581 19.594-53.054 45.895C755.749 657.872 645.173 754.527 512 754.527s-243.751-96.655-265.578-223.579c-4.473-26.301-26.374-45.895-53.052-45.895z" />
  </svg>
)

Microphone.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Microphone.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Microphone
