import { createLogic } from 'redux-logic'
import isPresent from 'utils/isPresent'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_SYNC_CALENDAR } from 'state/concepts/calendarIntegrations/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { updateSyncCalendarEndpoint } from '../endpoints'

const updateSyncCalendarOperation = createLogic({
  type: UPDATE_SYNC_CALENDAR,
  latest: true,

  async process(
    { action: { calendarSyncId, calendarSyncType, calendarIntegrationId }, httpClient, getState },
    dispatch,
    done,
  ) {
    const state = getState()
    const { endpoint, url } = updateSyncCalendarEndpoint

    try {
      const params = {
        calendar_sync_id: calendarSyncId,
        calendar_sync_type: calendarSyncType,
      }

      const filteredParams = Object.fromEntries(Object.entries(params).filter(([, value]) => isPresent(value)))

      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, filteredParams)

      const response = updateDataHelper(state.data, 'calendarIntegration', calendarIntegrationId, {
        attributes: {
          calendarSyncId,
          calendarSyncType,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateSyncCalendarOperation
