const namespace = 'locations'

export const FETCH_LOCATIONS = `${namespace}/FETCH_LOCATIONS`
export const SET_LOCATIONS = `${namespace}/SET_LOCATIONS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const SET_TOTAL_COUNT = `${namespace}/SET_TOTAL_COUNT`
export const SET_CURRENT_PAGE = `${namespace}/SET_CURRENT_PAGE`
export const RESET_LOCATIONS = `${namespace}/RESET_LOCATIONS`
export const FILTER_LOCATIONS = `${namespace}/FILTER_LOCATIONS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const SEARCH_LOCATIONS = `${namespace}/SEARCH_LOCATIONS`
export const CREATE_LOCATION = `${namespace}/CREATE_LOCATION`
export const TOGGLE_LOCATION_STATUS = `${namespace}/TOGGLE_LOCATION_STATUS`
export const EDIT_LOCATION = `${namespace}/EDIT_LOCATION`
export const GET_LOCATION = `${namespace}/GET_LOCATION`
export const DELETE_LOCATION = `${namespace}/DELETE_LOCATION`
export const REMOVE_LOCATION = `${namespace}/REMOVE_LOCATION`
