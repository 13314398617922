import PropTypes from 'prop-types'

import Button from 'views/shared/Button'

const ShowLessButton = ({ toggleTruncate, style }) => {
  const handleClick = event => {
    event.stopPropagation()
    toggleTruncate()
  }

  return (
    <Button
      style={style}
      text={{ id: 'website.page.showLess' }}
      className="text-headline mt-8 custom-btn--text"
      onClick={handleClick}
    />
  )
}

ShowLessButton.propTypes = {
  toggleTruncate: PropTypes.func.isRequired,
  style: PropTypes.shape().isRequired,
}

export default ShowLessButton
