import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  removeServices as removeServicesAction,
  setSelectedServices as setSelectedServicesAction,
} from 'state/concepts/services/actions'
import { removeServicesLoadingSelector } from 'state/concepts/services/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServicesBulkRemoveModal = ({ serviceIds, onClose }) => {
  const isLoading = useSelector(removeServicesLoadingSelector)
  const removeServices = useDispatchAction(removeServicesAction, serviceIds)
  const setSelectedServices = useDispatchAction(setSelectedServicesAction)

  const handleSubmit = () => {
    removeServices()
    setSelectedServices([])
  }

  return (
    <ConfirmModal
      title={{ id: 'servicesBulkRemoveModal.title' }}
      bodyText={[
        [
          {
            id: 'servicesBulkRemoveModal.body.start',
          },
          {
            id: 'servicesBulkRemoveModal.body.boldText',
            values: { count: serviceIds.length },
            isBold: true,
          },
          {
            id: 'servicesBulkRemoveModal.body.final',
          },
        ],
      ]}
      submitText={{ id: 'servicesActions.remove' }}
      kind="danger"
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServicesBulkRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ServicesBulkRemoveModal
