const clientPayments = {
  'clientPayments.title': 'Payments',
  'clientPayments.table.empty.title': 'No payments yet',
  'clientPayments.table.empty.description': 'This is where you’ll see your payment requests and  history.',
  'clientPayments.table.title.number': 'Payment #',
  'clientPayments.table.title.services': '_(Services)',
  'clientPayments.table.title.expert': '_(Expert)',
  'clientPayments.table.title.issueDate': 'Issue date',
  'clientPayments.table.title.dueDate': 'Due date',
  'clientPayments.table.title.status': 'Status',
  'clientPayments.table.title.total': 'Total',

  'clientPayments.status.pending': 'Pending',
  'clientPayments.status.success': 'Paid',
  'clientPayments.status.failure': 'Failed',
  'clientPayments.status.draft': 'Draft',
  'clientPayments.status.cancelled': 'Cancelled',
  'clientPayments.status.overdue': 'Overdue',

  'clientPayments.action.view.invoice': 'View invoice',
  'clientPayments.action.view.receipt': 'View receipt',
  'clientPayments.action.view.creditNote': 'View credit note',
  'clientPayments.action.downloadPDF.invoice': 'Download invoice',
  'clientPayments.action.downloadPDF.receipt': 'Download receipt',
  'clientPayments.action.downloadPDF.creditNote': 'Download credit note',
  'clientPayments.actions.payOnline': 'Pay online',
  'clientPayments.actions.viewAppointment': 'View _(appointment)',
}

export default clientPayments
