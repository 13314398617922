import { withFormik } from 'formik'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import yup from 'lib/yupLocalised'
import { loginRoute } from 'lib/routes'
import { deactivateEmployee as deactivateEmployeeAction } from 'state/concepts/employees/actions'
import EmployeeDeactivateComponent from './component'

const mapDispatchToProps = {
  deactivateEmployee: deactivateEmployeeAction,
}

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      cancelAndRefund: false,
      deactivateAccount: false,
    }),
    validationSchema: yup.object().shape({
      cancelAndRefund: yup.boolean().oneOf([true]).required(),
      deactivateAccount: yup.boolean().oneOf([true]).required(),
    }),
    handleSubmit: (
      _,
      {
        props: {
          deactivateEmployee,
          employee: { id },
        },
      },
    ) => {
      deactivateEmployee(id, { redirectTo: loginRoute })
    },
  }),
)(EmployeeDeactivateComponent)
