import PropTypes from 'prop-types'

import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import Invoice from './Invoice'
import Receipt from './Receipt'
import CreditNote from './CreditNote'

const PREVIEW_COMPONENTS = {
  invoice: Invoice,
  receipt: Receipt,
  creditNote: CreditNote,
}

const ReceiptTemplatePreviewModalComponent = ({ onClose, transaction, paymentMethod, previewType }) => {
  const Component = PREVIEW_COMPONENTS[previewType]

  if (!Component) {
    return null
  }

  return (
    <Modal className="main-modal main-modal--invoice" onClose={onClose}>
      <button className="main-modal__close-fixed" type="button" onClick={onClose}>
        <Cross dataCy="cross-icon" />
      </button>
      <div className="main-card mb-0">
        <Component {...transaction} paymentMethod={paymentMethod} />
      </div>
    </Modal>
  )
}

ReceiptTemplatePreviewModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.shape().isRequired,
  paymentMethod: PropTypes.shape().isRequired,
  previewType: PropTypes.string.isRequired,
}

export default ReceiptTemplatePreviewModalComponent
