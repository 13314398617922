import { CONCEPT_TYPES } from 'lib/constants/businessFiles'
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchMyBusinessFiles = (pageParams = {}, options = {}) => ({
  type: types.FETCH_MY_BUSINESS_FILES,
  pageParams,
  options,
})

export const fetchCurrentFolder = folderId => ({
  type: types.FETCH_CURRENT_FOLDER,
  folderId,
})

export const filterMyBusinessFiles = (filters = {}) => ({
  type: types.FILTER_MY_BUSINESS_FILES,
  filters,
})

export const addMyBusinessFileIds = ids => ({
  type: types.ADD_MY_BUSINESS_FILE_IDS,
  ids,
})

export const setMyBusinessFileIds = ids => ({
  type: types.SET_MY_BUSINESS_FILE_IDS,
  ids,
})

export const insertBeforeMyBusinessFileId = (newId, beforeId) => ({
  type: types.INSERT_BEFORE_MY_BUSINESS_FILE_ID,
  payload: { newId, beforeId },
})

export const resetMyBusinessFileIds = () => ({
  type: types.RESET_MY_BUSINESS_FILE_IDS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const setSelectedIds = myBusinessFileIds => ({
  type: types.SET_SELECTED_IDS,
  myBusinessFileIds,
})

export const resetSelectedIds = () => ({
  type: types.RESET_SELECTED_IDS,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const createFolder = makeFormSubmitAction(types.CREATE_FOLDER)
export const setCurrentFolderId = folderId => ({
  type: types.SET_CURRENT_FOLDER_ID,
  folderId,
})

export const duplicateMyBusinessFile = (myBusinessFileId, conceptType = CONCEPT_TYPES.myFiles) => ({
  type: types.DUPLICATE_MY_BUSINESS_FILE,
  myBusinessFileId,
  conceptType,
})

export const renameMyBusinessFile = makeFormSubmitAction(types.RENAME_MY_BUSINESS_FILE)

export const shareMyBusinessFile = makeFormSubmitAction(types.SHARE_MY_BUSINESS_FILE)

export const uploadFile = (file, meta, parentId, replaceOldFile = false) => ({
  type: types.UPLOAD_BUSINESS_FILE,
  file,
  meta,
  parentId,
  replaceOldFile,
})

export const presignFiles = (files, parentId) => ({
  type: types.PRESIGN_BUSINESS_FILE,
  files,
  parentId,
})

export const addUploadFile = (uploadId, file, meta, parentId, replaceOldFile) => ({
  type: types.ADD_UPLOAD_FILE,
  uploadId,
  file,
  meta,
  parentId,
  replaceOldFile,
})

export const completeFileLoading = uploadId => ({
  type: types.COMPLETE_FILE_LOADING,
  uploadId,
})

export const failFileLoading = uploadId => ({
  type: types.FAIL_FILE_LOADING,
  uploadId,
})

export const cancelFileUpload = uploadIds => ({
  type: types.CANCEL_FILE_UPLOAD,
  uploadIds,
})

export const removeUploadedFiles = () => ({
  type: types.REMOVE_UPLOADED_FILES,
})

export const moveMyBusinessFileToTrash = (myBusinessFileIds, onNextSlide, onBack) => ({
  type: types.MOVE_MY_BUSINESS_FILE_TO_TRASH,
  myBusinessFileIds,
  onNextSlide,
  onBack,
})

export const removeMyBusinessFileIds = myBusinessFileIds => ({
  type: types.REMOVE_MY_BUSINESS_FILE_IDS,
  myBusinessFileIds,
})

export const downloadBusinessFiles = fileIds => ({
  type: types.DOWNLOAD_BUSINESS_FILES,
  fileIds,
})

export const cancelFilesDownload = (downloadId, onSuccess) => ({
  type: types.CANCEL_FILES_DOWNLOAD,
  downloadId,
  onSuccess,
})

export const cancelFilesDownloadSuccess = downloadId => ({
  type: types.CANCEL_FILES_DOWNLOAD_SUCCESS,
  downloadId,
})

export const addFilesDownload = (downloadId, fileIds) => ({
  type: types.ADD_FILES_DOWNLOAD,
  downloadId,
  fileIds,
})

export const removeFilesDownload = () => ({
  type: types.REMOVE_FILES_DOWNLOAD,
})

export const filesDownloadSuccess = downloadId => ({
  type: types.FILES_DOWNLOAD_SUCCESS,
  downloadId,
})

export const completeFilesDownload = data => ({
  type: types.COMPLETE_FILES_DOWNLOAD,
  data,
})

export const addPendingFilesDownload = fileIds => ({
  type: types.ADD_PENDING_FILES_DOWNLOAD,
  fileIds,
})

export const addClientBusinessFiles = makeFormSubmitAction(types.ADD_CLIENT_BUSINESS_FILES)

export const moveToFolder = (fileId, kind, parentId = null, onSuccess) => ({
  type: types.MOVE_TO_FOLDER,
  fileId,
  parentId,
  kind,
  onSuccess,
})

export const fetchFoldersToMove = (fileId, parentId, page = 1) => ({
  type: types.FETCH_FOLDERS_TO_MOVE,
  parentId,
  fileId,
  page,
})

export const addFolderToMoveIds = ids => ({
  type: types.ADD_FOLDER_TO_MOVE_IDS,
  ids,
})

export const setFolderToMoveIds = ids => ({
  type: types.SET_FOLDER_TO_MOVE_IDS,
  ids,
})

export const resetFolderToMoveIds = () => ({
  type: types.RESET_FOLDER_TO_MOVE_IDS,
})

export const receivedBusinessStorage = data => ({
  type: types.RECEIVE_BUSINESS_STORAGE,
  data,
})

export const setIsDownloadingSectionOpen = isOpen => ({
  type: types.SET_IS_DOWNLOADING_SECTION_OPEN,
  isOpen,
})

export const setIsUploadingSectionOpen = isOpen => ({
  type: types.SET_IS_UPLOADING_SECTION_OPEN,
  isOpen,
})
