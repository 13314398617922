import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const SessionExpiringModal = ({ onActive, timeoutInSeconds }) => (
  <Modal className="main-modal main-modal--md" onClose={onActive}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title in-blue-gray-800">
          <FormattedMessage id="automaticLogout.modal.title" />
        </h2>
        <button type="button" onClick={onActive} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body text-center mt-40 mb-16">
        <div className="text-jumbo font-600" data-cy="automatic-logout-timeout-in-seconds">
          {timeoutInSeconds}
        </div>
        <p className="mb-0" data-cy="automatic-logout-modal-seconds-text">
          <FormattedMessage id="automaticLogout.modal.seconds" />
        </p>
      </div>
      <div className="main-modal__footer">
        <Button
          className="main-modal__footer-action"
          text={{ id: 'automaticLogout.modal.stayLoggedIn' }}
          onClick={onActive}
          dataCy="stay-logged-in-button"
        />
      </div>
    </div>
  </Modal>
)

SessionExpiringModal.propTypes = {
  onActive: PropTypes.func.isRequired,
  timeoutInSeconds: PropTypes.number.isRequired,
}

export default SessionExpiringModal
