import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Puzzle = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M204.8 204.8c0-113.108 91.692-204.8 204.8-204.8 113.106 0 204.8 91.692 204.8 204.8h153.6c28.278 0 51.2 22.923 51.2 51.2v153.6c113.106 0 204.8 91.694 204.8 204.8s-91.694 204.8-204.8 204.8v153.6c0 28.278-22.922 51.2-51.2 51.2h-716.8c-28.277 0-51.2-22.922-51.2-51.2v-716.8c0-28.277 22.923-51.2 51.2-51.2h153.6zM409.6 102.4c-56.554 0-102.4 45.846-102.4 102.4 0 12.060 2.063 23.527 5.814 34.138 5.538 15.671 3.126 33.055-6.47 46.625s-25.183 21.638-41.803 21.638h-162.34v614.4h614.4v-162.34c0-16.62 8.069-32.205 21.637-41.805 13.573-9.595 30.956-12.006 46.623-6.467 10.614 3.748 22.083 5.811 34.14 5.811 56.556 0 102.4-45.844 102.4-102.4s-45.844-102.4-102.4-102.4c-12.058 0-23.526 2.063-34.14 5.811-15.667 5.54-33.050 3.128-46.623-6.467-13.568-9.6-21.637-25.185-21.637-41.805v-162.34h-162.34c-16.62 0-32.205-8.068-41.805-21.638-9.595-13.57-12.006-30.954-6.467-46.625 3.748-10.61 5.811-22.077 5.811-34.138 0-56.554-45.844-102.4-102.4-102.4z" />
  </svg>
)

Puzzle.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Puzzle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Puzzle
