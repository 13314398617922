import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Tooltip from 'views/shared/Tooltip'
import DeleteO from 'views/shared/icons/DeleteO'

const RemoveButton = ({ onClick }) => (
  <Tooltip
    placement="top"
    on={['hover']}
    overlayClassName="main-tooltip main-tooltip--no-pointer"
    icon={
      <button onClick={onClick} className="main-icon-btn no-shrink ml-12 mr-12" type="button">
        <DeleteO className="main-icon-btn__icon hover-blue-600" size={20} />
      </button>
    }
  >
    <FormattedMessage id="shared.delete" />
  </Tooltip>
)

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default RemoveButton
