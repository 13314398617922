import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { setCurrentPlaySource } from 'state/concepts/audio/actions'
import { currentPlaySourceSelector, isRecordingInProgressSelector } from 'state/concepts/audio/selectors'

const usePlayer = wavesurfer => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [duration, setDuration] = useState(null)
  const mediaSource = useMemo(() => wavesurfer?.media?.currentSrc, [wavesurfer?.media?.currentSrc])
  const isRecording = useSelector(isRecordingInProgressSelector)
  const currentPlaySource = useSelector(currentPlaySourceSelector)
  const setCurrentPlay = useDispatchAction(setCurrentPlaySource, mediaSource)

  const handlePlay = useCallback(() => {
    if (!wavesurfer || isRecording) return

    isPlaying ? wavesurfer.pause() : wavesurfer.play()
  }, [isPlaying, isRecording, wavesurfer])

  const handlePlaybackRate = useCallback(
    value => {
      if (!wavesurfer) return

      wavesurfer.setPlaybackRate(value, true)
      setPlaybackRate(value)
    },
    [wavesurfer],
  )

  useEffect(() => {
    if (!wavesurfer) return

    const initialDuration = wavesurfer.getDuration()

    if (initialDuration) {
      setDuration(initialDuration)
    }

    const subscriptions = [
      wavesurfer.on('play', () => {
        setCurrentPlay()
        setIsPlaying(true)
      }),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('ready', seconds => setDuration(seconds)),
    ]

    // eslint-disable-next-line consistent-return
    return () => subscriptions.forEach(unsubscribe => unsubscribe())
  }, [setCurrentPlay, wavesurfer])

  useEffect(() => {
    if (!wavesurfer) return

    if (isRecording && isPlaying) {
      wavesurfer.pause()
    }
  }, [handlePlay, isPlaying, isRecording, wavesurfer])

  useEffect(() => {
    if (!wavesurfer) return

    if (isPlaying && currentPlaySource && currentPlaySource !== mediaSource) {
      wavesurfer.pause()
    }
  }, [currentPlaySource, isPlaying, mediaSource, wavesurfer])

  return { isPlaying, handlePlay, duration, playbackRate, handlePlaybackRate }
}

export default usePlayer
