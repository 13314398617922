import { Field } from 'formik'
import PropTypes from 'prop-types'

import ExpertIcon from 'views/shared/icons/ExpertRounded'
import DropdownUserField from 'views/shared/DropdownUserField'
import isAdminOrOwnerOrScheduler from 'utils/employeePermissions/isAdminOrOwnerOrScheduler'
import isScheduler from 'utils/employeePermissions/isScheduler'
import NoAssignedExpert from './NoAssignedExpert'

const ExpertSelectComponent = ({ currentEmployee, serviceId, isNoAssignedExperts }) =>
  isAdminOrOwnerOrScheduler(currentEmployee) && (
    <div className="booking-tooltip__info booking-tooltip__info-divider">
      <div className="d-flex">
        <div className="mr-16 pt-36">
          <ExpertIcon size={20} color="#6e798d" />
        </div>
        <div id="select_expert" className="max-w-280 w-100">
          <Field
            className="mb-0 booking-tooltip__select-dropdown select-person-dropdown"
            name="userProfileId"
            id="userProfileId"
            component={DropdownUserField}
            hideEmail
            statuses={['active']}
            noAssignedExpert={isNoAssignedExperts ? NoAssignedExpert : null}
            label={{ id: 'scheduleAppointment.expert' }}
            placeholder={{ id: 'scheduleAppointment.selectExpert' }}
            icon="alert"
            serviceId={serviceId}
            withAvatar
            mountToElement
            excludeEmployees={isScheduler(currentEmployee) ? [currentEmployee.id] : []}
            data-cy="expert-select-field"
          />
        </div>
      </div>
    </div>
  )

ExpertSelectComponent.defaultProps = {
  serviceId: null,
}

ExpertSelectComponent.propTypes = {
  currentEmployee: PropTypes.shape().isRequired,
  isNoAssignedExperts: PropTypes.bool.isRequired,
  serviceId: PropTypes.string,
}

export default ExpertSelectComponent
