import classNames from 'clsx'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { sidebarTypeSelector } from 'state/sidebar/selectors'
import { isSidebarOpenSelector } from 'state/concepts/videoConference/selectors'
import NOTIFICATIONS_COMPONENTS from './notificationComponents'

const NotificationsDock = ({ hideNotification, notifications, className }) => {
  const isOpenSidebar = !!useSelector(sidebarTypeSelector)
  const isSidebarOpen = !!useSelector(isSidebarOpenSelector)
  return (
    <div
      className={classNames(
        'notification-list',
        className,
        { 'notification-list--sidebar-open': isOpenSidebar },
        { 'notification-list--video-call-sidebar-open': isSidebarOpen },
      )}
    >
      {Object.keys(notifications).map(key => {
        const NotificationComponent = NOTIFICATIONS_COMPONENTS[notifications[key].notificationType]
        const props = {
          hideNotification,
          ...notifications[key],
        }
        return <NotificationComponent key={key} {...props} />
      })}
    </div>
  )
}

NotificationsDock.defaultProps = {
  className: '',
}

NotificationsDock.propTypes = {
  hideNotification: PropTypes.func.isRequired,
  notifications: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
}

export default NotificationsDock
