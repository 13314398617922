import normalize from 'json-api-normalizer'
import { startsWith } from 'lodash'
import { includes, pathEq, pick } from 'ramda'
import build from 'redux-object'

import { publicSearchRoute } from 'lib/apiRoutes'
import { APP_HOST } from 'lib/constants'
import { CLIENT_PORTAL_STATUS } from 'lib/constants/clientPortal'
import { RESERVED_SUBDOMAINS } from 'lib/constants/subdomains'
import { workspacesHttpClient } from 'lib/httpClient'
import { clientLoginRoute } from 'lib/routes'
import { resetSubdomain, setSubdomain } from 'state/concepts/subdomain/actions'
import {
  isClientPortalEnabledSelector,
  isCurrentContactPortalEnabledSelector,
  subdomainSelector,
} from 'state/concepts/subdomain/selectors'
import { dataApiSuccess } from 'state/data/actions'
import isRestoreRoute from 'utils/guest/isRestoreRoute'
import isSubscribeRoute from 'utils/guest/isSubscribeRoute'
import redirectRoute from 'utils/guest/redirectRoute'
import isPresent from 'utils/isPresent'
import isIpAddress from 'utils/isIpAddress'
import isFormsRoute from 'utils/guest/isFormsRoute'
import isBookWaitlistRequestRoute from 'utils/guest/isBookWaitlistRequestRoute'

const processSubdomain = async ctx => {
  const {
    req,
    pathname,
    store,
    query: { token },
    query,
  } = ctx
  let workspace

  try {
    const state = store.getState()
    const subdomain = req.headers.host.split('.')[0]
    const currentSubdomain = subdomainSelector(state)
    let isClientPortalEnabled = isClientPortalEnabledSelector(state)
    let isCurrentContactPortalEnabled = isCurrentContactPortalEnabledSelector(state)

    if (includes(subdomain, RESERVED_SUBDOMAINS) || isIpAddress(req.headers.host)) {
      store.dispatch(resetSubdomain())

      return {}
    }

    if (isPresent(subdomain) && subdomain !== currentSubdomain) {
      const params = {
        url: subdomain,
        include: 'client-portal',
        token,
      }
      const { data } = await workspacesHttpClient.get(publicSearchRoute, { params })
      const response = normalize(data)
      workspace = build(response, 'workspace', data.data.id)

      isClientPortalEnabled = pathEq(CLIENT_PORTAL_STATUS.enabled, ['clientPortal', 'status'], workspace)
      isCurrentContactPortalEnabled = workspace.currentContactPortalEnabled !== false

      store.dispatch(dataApiSuccess({ response }))
      store.dispatch(setSubdomain(subdomain, data.data.id))
    }

    if ((!isClientPortalEnabled || !isCurrentContactPortalEnabled) && startsWith(pathname, '/client/[workspace]'))
      return {
        redirectTo: redirectRoute(ctx),
        query: pick(['token', 'type', 'remove_waitlist_request'], query),
        workspace,
      }

    if (
      isClientPortalEnabled &&
      isCurrentContactPortalEnabled &&
      startsWith(pathname, '/guest') &&
      !isRestoreRoute(pathname) &&
      !isSubscribeRoute(pathname) &&
      !isFormsRoute(pathname) &&
      !isBookWaitlistRequestRoute(pathname)
    ) {
      return { redirectTo: clientLoginRoute, workspace }
    }
  } catch (_error) {
    return { redirectTo: `${APP_HOST}/404` }
  }

  return { workspace }
}

export default processSubdomain
