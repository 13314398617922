import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_TRANSACTIONS } from 'state/concepts/recurringPayment/types'
import { setTransactions, setBlankState } from 'state/concepts/recurringPayment/actions'
import { fetchRecurringPaymentTransactionsEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { paginationSelector } from 'state/concepts/recurringPayment/selectors'

const fetchTransactionsOperation = createLogic({
  type: FETCH_TRANSACTIONS,
  latest: true,

  async process({ action: { recurringPaymentId }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchRecurringPaymentTransactionsEndpoint(recurringPaymentId)

    const params = {
      include: [
        'user-profile',
        'client-profile',
        'booking',
        'receipt-template',
        'transaction-items',
        'refunded-transaction',
        'recurring-payment',
      ],
      page: paginationSelector(getState()),
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setTransactions(data.data.map(item => item.id)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchTransactionsOperation
