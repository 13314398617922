import { combineReducers } from '@reduxjs/toolkit'

import {
  RESET_FILTER_PARAM,
  SET_RECURRING_PAYMENTS,
  SET_RECURRING_PAYMENTS_BLANK_STATE,
  SET_FILTER_PARAMS,
  SET_RECURRING_PAYMENTS_PAGE,
} from './types'

const recurringPaymentIds = (state = [], action) => {
  switch (action.type) {
    case SET_RECURRING_PAYMENTS:
      return action.recurringPaymentIds
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_RECURRING_PAYMENTS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_RECURRING_PAYMENTS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const filtersInitialState = {
  name: '',
  statuses: [],
  employees: [],
  clients: [],
  services: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTER_PARAM:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const recurringPaymentsReducer = combineReducers({
  recurringPaymentIds,
  isBlankState,
  pagination,
  filters,
})

export default recurringPaymentsReducer
