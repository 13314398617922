import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Link from 'views/shared/Link'
import AgreementIcon from 'views/shared/icons/Agreements'
import CountIndicator from '../CountIndicator'

const Agreements = ({ booking: { agreementFormsCount, submittedAgreementFormsCount }, bookingPagePath }) =>
  agreementFormsCount ? (
    <Link {...bookingPagePath}>
      <a
        className="booking-tooltip__intake in-blue-gray-800"
        target="_blank"
        rel="noreferrer"
        data-cy="forms-section-agreements"
      >
        <div className="d-flex no-shrink mr-16" data-cy="forms-section-icon">
          <AgreementIcon size={16} className="booking-tooltip__intake-svg in-blue-gray-200 " />
        </div>
        <p className="mb-0 truncate-text">
          <span className="font-600" data-cy="forms-section-title">
            <FormattedMessage id="agreements.title" />
          </span>
        </p>
        <CountIndicator current={submittedAgreementFormsCount} max={agreementFormsCount} />
      </a>
    </Link>
  ) : null

Agreements.propTypes = {
  booking: PropTypes.shape({
    agreementFormsCount: PropTypes.number.isRequired,
    submittedAgreementFormsCount: PropTypes.number.isRequired,
  }).isRequired,
  bookingPagePath: PropTypes.shape().isRequired,
}

export default Agreements
