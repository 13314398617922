import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchPaymentSettings = () => ({
  type: types.FETCH_PAYMENT_SETTINGS,
})

export const disconnectStripe = () => ({
  type: types.DISCONNECT_STRIPE,
})

export const disconnectPaypal = () => ({
  type: types.DISCONNECT_PAYPAL,
})

export const getStripeConnectLink = () => ({
  type: types.GET_STRIPE_CONNECT_LINK,
})

export const setStripeConnectLink = link => ({
  type: types.SET_STRIPE_CONNECT_LINK,
  link,
})

export const connectStripe = (code, state) => ({
  type: types.CONNECT_STRIPE,
  code,
  state,
})

export const setPayPalConnectLink = link => ({
  type: types.SET_PAYPAL_CONNECT_LINK,
  link,
})

export const getPayPalConnectLink = () => ({
  type: types.GET_PAYPAL_CONNECT_LINK,
})

export const receivePayPalAccount = data => ({
  type: types.RECEIVE_PAYPAL_ACCOUNT,
  data,
})

export const disconnectManualPayment = () => ({
  type: types.DISCONNECT_MANUAL_PAYMENT,
})

export const updateManualPayment = makeFormSubmitAction(types.UPDATE_MANUAL_PAYMENT)
export const createManualPayment = makeFormSubmitAction(types.CREATE_MANUAL_PAYMENT)

export const validateDisconnectStripe = () => ({
  type: types.VALIDATE_DISCONNECT_STRIPE,
})

export const setStripeDisconnectError = error => ({
  type: types.SET_STRIPE_DISCONNECT_ERROR,
  error,
})

export const resetStripeDisconnectError = () => ({
  type: types.RESET_STRIPE_DISCONNECT_ERROR,
})

export const updateStripePos = status => ({
  type: types.UPDATE_STRIPE_POS,
  status,
})

export const setIsConnectStripePos = isConnect => ({
  type: types.SET_IS_CONNECT_STRIPE_POS,
  isConnect,
})
