import { createLogic } from 'redux-logic'
import { includes } from 'ramda'
import normalize from 'json-api-normalizer'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { UPDATE_WIDGET_SERVICES } from 'state/concepts/widget/types'
import { widgetIdSelector, widgetServicesIdsSelector } from 'state/concepts/widget/selectors'
import { showNotification } from 'state/notifications/actions'
import { updateWidgetServicesEndpoint } from 'state/concepts/widget/endpoints'

const updateWidgetServicesOperation = createLogic({
  type: UPDATE_WIDGET_SERVICES,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const { form, values } = action

    const state = getState()
    const id = widgetIdSelector(state)
    const widgetServicesIds = widgetServicesIdsSelector(state)

    const { url, endpoint } = updateWidgetServicesEndpoint(id)

    try {
      const params = { service_ids: values.services, status: values.status }

      const { data } = await httpClient.post(url, params)
      const response = normalize(data)

      const updateWidgetServices = updateDataHelper(state.data, 'service', widgetServicesIds, serviceId => ({
        attributes: { connectedToWidget: includes(serviceId, values.services) },
      }))

      dispatch(dataApiSuccess({ response: { ...response, ...updateWidgetServices } }))
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingPageHasBeenUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateWidgetServicesOperation
