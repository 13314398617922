import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchBillingDetail = () => ({
  type: types.FETCH_BILLING_DETAIL,
})

export const setBillingDetailId = billingDetailId => ({
  type: types.SET_BILLING_DETAIL_ID,
  billingDetailId,
})

export const fetchSubscriptionTransactions = () => ({
  type: types.FETCH_SUBSCRIPTION_TRANSACTIONS,
})

export const setSubscriptionTransactions = subscriptionTransactionIds => ({
  type: types.SET_SUBSCRIPTION_TRANSACTIONS,
  subscriptionTransactionIds,
})

export const resetSubscriptionTransactions = () => ({
  type: types.RESET_SUBSCRIPTION_TRANSACTIONS,
})

export const removeSubscriptionTransactions = ids => ({
  type: types.REMOVE_SUBSCRIPTION_TRANSACTIONS,
  ids,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_SUBSCRIPTION_TRANSACTIONS_PAGE,
  pageNumber,
})

export const setBillingAccountId = billingAccountId => ({
  type: types.SET_BILLING_ACCOUNT_ID,
  billingAccountId,
})

export const getBillingAccount = () => ({
  type: types.GET_BILLING_ACCOUNT,
})

export const setBillingAccountInfo = makeFormSubmitAction(types.SET_BILLING_ACCOUNT)

export const createSetupIntent = () => ({
  type: types.CREATE_SETUP_INTENT,
})

export const setSetupIntentClientSecret = setupIntentClientSecret => ({
  type: types.SET_SETUP_INTENT_CLIENT_SECRET,
  setupIntentClientSecret,
})

export const setStripePublishableApiKey = stripePublishableApiKey => ({
  type: types.SET_STRIPE_PUBLISHABLE_API_KEY,
  stripePublishableApiKey,
})

export const updatePaymentMethod = makeFormSubmitAction(types.UPDATE_PAYMENT_METHOD)

export const updateTeamSeats = makeFormSubmitAction(types.UPDATE_TEAM_SEATS)

export const cancelDowngradeSeats = () => ({
  type: types.CANCEL_DOWNGRADE_SEATS,
})

export const downloadSubscriptionTransaction = (id, isDownload = true) => ({
  type: types.DOWNLOAD_SUBSCRIPTION_TRANSACTION,
  id,
  isDownload,
})

export const retryPayment = () => ({
  type: types.RETRY_PAYMENT,
})

export const renewSubscription = () => ({
  type: types.RENEW_SUBSCRIPTION,
})

export const updateBillingAddress = makeFormSubmitAction(types.UPDATE_BILLING_ADDRESS)

export const cancelDowngradeStorage = () => ({
  type: types.CANCEL_DOWNGRADE_STORAGE,
})
