import PropTypes from 'prop-types'
import { Formik } from 'formik'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import yup from 'lib/yupLocalised'
import colors from 'lib/constants/categoriesColors'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { createServiceCategory as createServiceCategoryAction } from 'state/concepts/serviceCategories/actions'
import PersistServiceCategoriesModal from '../PersistModal'

const ServiceCategoryCreateModal = ({ onClose }) => {
  const handleCreateServiceCategory = useDispatchAction(createServiceCategoryAction)

  return (
    <Formik
      initialValues={{
        name: '',
        color: colors[0],
      }}
      validationSchema={yup.object().shape({
        name: yup.string().max(MAX_INPUT_CONTENT_LENGTH).required(),
      })}
      onSubmit={handleCreateServiceCategory}
    >
      {props => (
        <PersistServiceCategoriesModal
          title={{ id: 'serviceCategories.createCategory.modal.title' }}
          submitText={{ id: 'shared.create' }}
          onClose={onClose}
          isSubmitDisabled={isSubmitDisabled(props)}
        />
      )}
    </Formik>
  )
}

ServiceCategoryCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ServiceCategoryCreateModal
