import PropTypes from 'prop-types'
import { path, pluck } from 'ramda'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { CALENDAR_TYPES } from 'lib/constants/calendarIntegrations'
import { disconnectGoogleCalendar as disconnectGoogleCalendarAction } from 'state/concepts/calendarIntegrations/actions'
import { disconnectGoogleCalendarEndpoint } from 'state/concepts/calendarIntegrations/endpoints'
import { calendarIntegrationSelector } from 'state/concepts/calendarIntegrations/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { loadingSelector } from 'state/data/selectors'
import { isProviderGoogle } from 'utils/videoCalls'
import DisconnectModal from '../DisconnectModal'

const GoogleDisconnectModal = ({ connection: { id, googleCalendarListItems }, onClose }) => {
  const { videoCallProvider } = useSelector(currentEmployeeSelector)
  const calendarIntegration = useSelector(calendarIntegrationSelector)
  const disconnectGoogleCalendar = useDispatchAction(disconnectGoogleCalendarAction)

  const isLoading = useSelector(state =>
    loadingSelector(state, disconnectGoogleCalendarEndpoint(path(['connection', 'id'], state)).endpoint),
  )

  const alertMessage = useMemo(() => {
    const showAlert =
      isProviderGoogle(videoCallProvider) &&
      calendarIntegration?.calendarSyncType === CALENDAR_TYPES.google &&
      pluck('id', googleCalendarListItems).includes(String(calendarIntegration?.calendarSyncId))

    return showAlert ? { id: 'googleCalendarConnectionRemoveModal.googleMeetAlert' } : null
  }, [
    calendarIntegration?.calendarSyncId,
    calendarIntegration?.calendarSyncType,
    googleCalendarListItems,
    videoCallProvider,
  ])

  const handleSubmit = () => {
    disconnectGoogleCalendar(id)
  }

  return (
    <DisconnectModal onClose={onClose} handleSubmit={handleSubmit} isLoading={isLoading} alertMessage={alertMessage} />
  )
}

GoogleDisconnectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    googleCalendarListItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
}

export default GoogleDisconnectModal
