import { Field } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { ALERT_KINDS } from 'lib/constants'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { getPreferencesText } from 'utils/waitlistRequests/timeFormatter'
import { currentUserTimezoneOffsetSelector } from 'state/concepts/session/selectors'
import Alert from 'views/shared/Alert'
import TimePickerField from 'views/shared/TimePickerField'
import TimeIcon from 'views/shared/icons/Time'
import DatePicker from './DatePicker'
import Duration from './Duration'
import RepeatSection from './RepeatSection'
import TimeConflictsWarning from './TimeConflictsWarning'

const DateTimeComponent = ({
  values: { serviceId, date, repeat, ends },
  values,
  bookingId,
  status,
  setFieldValue,
  isTooltip,
  showRepeat,
  isWaitlist,
  availabilityPreferences,
}) => {
  const timezoneOffset = useSelector(currentUserTimezoneOffsetSelector)
  const isEnableRecurringAppointments = useWorkspaceFeature(FEATURE_KEYS.recurringAppointments)
  const availabilityPreferencesMessage = useMemo(
    () =>
      availabilityPreferences?.map((preference, index) => {
        const { dateText, timeText } = getPreferencesText({ ...preference, timezoneOffset })
        return (
          <Fragment key={`waitlist-prefference-${preference.id}`}>
            <p className="mb-0 font-400">
              <FormattedMessage
                id="waitlistRequests.dateTimePreference"
                values={{
                  date: <FormattedMessage {...dateText} />,
                  time: <FormattedMessage {...timeText} />,
                }}
              />
              {index === availabilityPreferences.length - 1 ? '.' : ';'}
            </p>
          </Fragment>
        )
      }),
    [availabilityPreferences, timezoneOffset],
  )

  return (
    <div className="booking-tooltip__info booking-tooltip__info-divider">
      {prop('base', status) && <Alert className="auth__alert" iconSize={20} message={prop('base', status)} />}
      {isWaitlist && availabilityPreferencesMessage && (
        <Alert
          className="auth__alert font-600"
          type={ALERT_KINDS.info}
          message={{
            id: 'scheduleAppointment.availabilityPreferences.info',
            values: { availability: availabilityPreferencesMessage },
          }}
        />
      )}
      <TimeConflictsWarning className="mb-16" bookingId={bookingId} values={values} />
      <div className="d-flex">
        <div className="mr-16 pt-36">
          <TimeIcon size={20} color="#6e798d" />
        </div>
        <div className="w-100 calendar-duration-wrap">
          <div className="booking-calendar__day">
            <div className="main-input__label">
              <FormattedMessage id="scheduleAppointment.startTime" />
            </div>
            <div className="d-flex align-items-center">
              <div className="w-100 max-w-139 mr-16" data-cy="date-picker">
                <div className="no-icon-calendar bordered-calendar ant-picker__calendar-picker-wrapper">
                  <DatePicker date={date} isTooltip={isTooltip} />
                </div>
              </div>
              <Field
                mainWrapperClass="ant-picker__time-wrapper"
                popupClassName="ant-picker__time-picker-wrapper"
                name="time"
                id="time"
                component={TimePickerField}
                icon="alert"
              />
            </div>
          </div>
          <div className="w-100 pt-8 duration-wrap">
            <div className="max-w-139 no-max-width-sm">
              <div className="full-width-field" data-cy="duration-picker">
                <Duration setFieldValue={setFieldValue} serviceId={serviceId} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRepeat && isEnableRecurringAppointments && <RepeatSection repeat={repeat} ends={ends} />}
    </div>
  )
}

DateTimeComponent.defaultProps = {
  status: undefined,
  bookingId: undefined,
  isTooltip: false,
  showRepeat: false,
  isWaitlist: false,
  availabilityPreferences: undefined,
}

DateTimeComponent.propTypes = {
  values: PropTypes.shape({
    serviceId: PropTypes.string,
    date: PropTypes.shape(),
    repeat: PropTypes.bool,
    ends: PropTypes.string,
  }).isRequired,
  bookingId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  status: PropTypes.shape(),
  isTooltip: PropTypes.bool,
  showRepeat: PropTypes.bool,
  isWaitlist: PropTypes.bool,
  availabilityPreferences: PropTypes.arrayOf(PropTypes.shape()),
}

export default DateTimeComponent
