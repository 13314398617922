import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, path, pipe, prop } from 'ramda'
import build from 'redux-object'

import { fetchClientsEndpoint } from 'state/concepts/clients/endpoints'
import { dataSelector, loadingSelector, pageCountSelector } from 'state/data/selectors'

export const isBlankStateSelector = path(['clients', 'isBlankState'])

export const clientsIdsSelector = path(['clients', 'clientIds'])

export const clientsTotalCountSelector = path(['clients', 'totalCount'])

export const clientsSelector = createSelector([clientsIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'clientProfile', ids) : [],
)

export const paginationSelector = path(['clients', 'pagination'])

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const sortSelector = path(['clients', 'sort'])

export const sortParamsSelector = pipe(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const filtersSelector = path(['clients', 'filters'])

export const searchQuerySelector = pipe(filtersSelector, prop('name'))

export const appliedFiltersSelector = pipe(filtersSelector, ({ name }) => ({ name }))

export const clientsPageCountSelector = state => pageCountSelector(state, fetchClientsEndpoint.endpoint)

export const clientsLoadingSelector = state => loadingSelector(state, fetchClientsEndpoint.endpoint)
