import { props, values } from 'ramda'

const roleSelectOptions = ({ withScheduler }) => {
  const roleOptions = {
    administrator: {
      key: 'administrator',
      value: 'administrator',
      label: { id: 'employeeAccount.changeRole.roleOptions.administrator' },
      description: { id: 'employeeAccount.changeRole.roleOptions.administrator.description' },
    },
    scheduler: {
      key: 'scheduler',
      value: 'scheduler',
      label: { id: 'employeeAccount.changeRole.roleOptions.scheduler' },
      description: { id: 'employeeAccount.changeRole.roleOptions.scheduler.description' },
    },
    user: {
      key: 'user',
      value: 'user',
      label: { id: 'employeeAccount.changeRole.roleOptions.user' },
      description: { id: 'employeeAccount.changeRole.roleOptions.user.description' },
    },
  }

  return withScheduler ? values(roleOptions) : props(['administrator', 'user'], roleOptions)
}

export default roleSelectOptions
