import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const TextWithBrackets = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1252 1024"
    data-cy={dataCy}
  >
    <path d="M114 0c-62.183 0-113.778 51.595-113.778 113.778v796.444c0 62.185 51.595 113.778 113.778 113.778h113.778v-113.778h-113.778v-796.444h113.778v-113.778h-113.778zM1024.222 0v113.778h113.778v796.444h-113.778v113.778h113.778c62.185 0 113.778-51.593 113.778-113.778v-796.444c0-62.183-51.593-113.778-113.778-113.778h-113.778zM227.778 227.556v113.778h796.444v-113.778h-796.444zM227.778 455.111v113.778h625.778v-113.778h-625.778zM227.778 682.667v113.778h796.444v-113.778h-796.444z" />
  </svg>
)

TextWithBrackets.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

TextWithBrackets.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default TextWithBrackets
