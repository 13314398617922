import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { ALERT_KINDS } from 'lib/constants'
import updateDataHelper from 'utils/updateDataHelper'
import { isErrorStatusNotFound } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { fetchEmployees } from 'state/concepts/employees/actions'
import { employeeLogout } from 'state/concepts/session/actions'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { DEACTIVATE_EMPLOYEE } from 'state/concepts/employees/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { deactivateEmployeeEndpoint } from 'state/concepts/employees/endpoints'
import redirect from 'utils/redirect'

const deactivateEmployeeOperation = createLogic({
  type: DEACTIVATE_EMPLOYEE,
  latest: true,

  async process({ action: { employeeId, redirectTo }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = deactivateEmployeeEndpoint(employeeId)
    const state = getState()
    const currentEmployee = currentUserProfileSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.post(url)

      const response = updateDataHelper(state.data, 'userProfile', employeeId, { attributes: { active: false } })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.memberDeactivated' } }))
      if (currentEmployee.id === employeeId) {
        dispatch(employeeLogout())
      }
      if (redirectTo) {
        redirect({ href: redirectTo })
      }
    } catch (error) {
      if (isErrorStatusNotFound(error)) {
        dispatch(hideModal())
        dispatch(fetchEmployees())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.membeIsNoLongerAvailable' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default deactivateEmployeeOperation
