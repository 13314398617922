const formCategories = {
  'formCategories.status.active': 'Active',
  'formCategories.status.inactive': 'Deactivated',
  'formCategories.addCategory': 'Add category',
  'formCategories.searchPlaceholder': 'Search by name',
  'formCategories.empty.title': 'No categories yet',
  'formCategories.empty.description': 'Start organizing your forms by grouping them into categories.',
  'formCategories.empty.createCategory': 'Create category',
  'formCategories.noSearchResults': 'Try adjusting your search',
  'formCategories.createModal.namePlaceholder': 'e.g., Intake forms',
  'formCategories.createModal.nameLabel': 'Category name',
  'formCategories.createModal.colorLabel': 'Select category color',
  'formCategories.createModal.title': 'Add category',
  'formCategories.updateModal.title': 'Edit category',
  'formCategories.deactivateModal.title': 'Deactivate category?',
  'formCategories.deactivateModal.description':
    'This will deactivate all forms under this category, so you won’t be able to send them to _(experts) or _(clients).',
  'formCategories.deactivateModal.alert': 'This won’t affect the forms sent previously.',
  'formCategories.activateModal.title': 'Activate category?',
  'formCategories.activateModal.description':
    'This will activate all forms in this category, and you’ll be able to send them to _(experts) and _(clients).',
  'formCategories.removeModal.title': 'Delete this category?',
  'formCategories.removeModal.noForms': 'No forms assigned to this category. This action will delete the category.',
  'formCategories.removeModal.formsAssigned':
    '<b>{formsCount} {formsCount, plural, one {form} other {forms}}</b> assigned to this category',
  'formCategories.removeModal.selectNewCategory':
    'Before you delete this category, please select new category for assigned forms.',
  'formCategories.removeModal.newCategoryLabel': 'New category',
  'formCategories.removeModal.newCategoryPlaceholder': 'Select category',
  'formCategories.removeModal.submitText': 'Delete category',
  'formCategories.removeModal.notAllowed.title': 'Can’t perform action',
  'formCategories.removeModal.notAllowed.description':
    'Before delete this category you need to create at least one new category and reassign all forms.',
}

export default formCategories
