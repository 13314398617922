import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { DISCONNECT_STRIPE } from 'state/concepts/paymentSettings/types'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { paymentAccountSelector, stripeAccountSelector } from 'state/concepts/paymentSettings/selectors'
import { disconnectStripeEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { STRIPE_POS_STATUSES } from 'lib/constants/stripePos'
import updateDataHelper from 'utils/updateDataHelper'

const disconnectStripeOperation = createLogic({
  type: DISCONNECT_STRIPE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = disconnectStripeEndpoint
    const state = getState()
    const { id } = paymentAccountSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      const stripeAccount = stripeAccountSelector(state)
      dispatch(dataDelete({ kind: 'stripeAccount', ids: [stripeAccount.id] }))

      const response = updateDataHelper(getState().data, 'paymentAccount', id, {
        relationships: {
          stripeAccount: { data: null },
        },
      })
      dispatch(dataApiSuccess({ endpoint, response }))

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.paymentSettingsDisconnected' },
        }),
      )
      if (stripeAccount.posStatus === STRIPE_POS_STATUSES.enabled) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.stripePosWasDisconnected' },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default disconnectStripeOperation
