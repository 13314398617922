import PropTypes from 'prop-types'

import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'
import Close from 'views/shared/icons/Close'

const DropdownButtonIcon = ({ isClearShown, isOpen }) => {
  if (isClearShown) return <Close size={14} color="#388BFF" />
  return isOpen ? (
    <ArrowUp className="pe-none" dataCy="arrow-up-icon" color="#388BFF" size={10} />
  ) : (
    <ArrowDown className="pe-none" dataCy="arrow-down-icon" color="#388BFF" size={10} />
  )
}

DropdownButtonIcon.propTypes = {
  isClearShown: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default DropdownButtonIcon
