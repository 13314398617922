import { createLogic } from 'redux-logic'

import { REVIEW_STATUSES } from 'lib/constants/reviews'
import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { TOGGLE_REVIEW_STATUS } from 'state/concepts/reviews/types'
import { updateReviewStatusEndpoint } from 'state/concepts/reviews/endpoints'

const toggleReviewStatusOperation = createLogic({
  type: TOGGLE_REVIEW_STATUS,
  latest: true,

  async process({ httpClient, getState, action: { id, status } }, dispatch, done) {
    const { url, endpoint } = updateReviewStatusEndpoint(id)
    const newStatus = status === REVIEW_STATUSES.published ? REVIEW_STATUSES.unpublished : REVIEW_STATUSES.published

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, { status: newStatus })

      const response = updateDataHelper(getState().data, 'review', id, { attributes: { status: newStatus } })
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default toggleReviewStatusOperation
