import { bookingStartTime } from 'utils/bookings'
import { durationToSeconds } from 'utils/duration'

const updateRescheduleRecurringFormToParams = ({ date, time, duration, reason }, timezoneOffset) => ({
  start_time: bookingStartTime(date, time, timezoneOffset).toISO(),
  duration: durationToSeconds(duration),
  reschedule_reason: reason,
})

export default updateRescheduleRecurringFormToParams
