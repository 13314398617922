import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, path, prop } from 'ramda'
import build from 'redux-object'

import {
  fetchRecurringPaymentTransactionsEndpoint,
  getPaymentMethodEndpoint,
  getRecurringPaymentEndpoint,
  requestRecurringPaymentEndpoint,
  saveDraftRecurringPaymentEndpoint,
  sendRecurringPaymentEndpoint,
  updateRecurringPaymentEndpoint,
} from 'state/concepts/recurringPayment/endpoints'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'

export const recurringPaymentIdSelector = path(['recurringPayment', 'recurringPaymentId'])
export const transactionIdsSelector = path(['recurringPayment', 'transactionIds'])
export const isBlankStateSelector = path(['recurringPayment', 'isBlankState'])
export const paginationSelector = path(['recurringPayment', 'pagination'])

export const recurringPaymentSelector = createSelector([recurringPaymentIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'recurringPayment', id) : null,
)

export const transactionsSelector = createSelector([transactionIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'transaction', ids) : [],
)

export const getRecurringPaymentLoadingSelector = (state, id) =>
  loadingSelector(state, getRecurringPaymentEndpoint(id).endpoint)

export const fetchTransactionsLoadingSelector = (state, id) =>
  loadingSelector(state, fetchRecurringPaymentTransactionsEndpoint(id).endpoint)

export const sendRecurringPaymentLoadingSelector = (state, id) =>
  loadingSelector(state, sendRecurringPaymentEndpoint(id).endpoint)

export const currentPageSelector = createSelector(paginationSelector, prop('number'))
export const requestRecurringPaymentLoadingSelector = state =>
  loadingSelector(state, requestRecurringPaymentEndpoint.endpoint)

export const saveDraftRecurringPaymentLoadingSelector = state =>
  loadingSelector(state, saveDraftRecurringPaymentEndpoint.endpoint)

export const updateRecurringPaymentLoadingSelector = (state, recurringPaymentId) =>
  loadingSelector(state, updateRecurringPaymentEndpoint(recurringPaymentId).endpoint)

export const paymentMethodIdSelector = path(['recurringPayment', 'paymentMethodId'])

export const getPaymentMethodMetaSelector = (state, recurringPaymentId) =>
  endpointMetaSelector(state, getPaymentMethodEndpoint(recurringPaymentId).endpoint)

export const isPaymentMethodLoading = (state, recurringPaymentId) =>
  loadingSelector(state, getPaymentMethodEndpoint(recurringPaymentId).endpoint)

export const paymentMethodSelector = createSelector([paymentMethodIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'clientPaymentMethod', id) : null,
)
