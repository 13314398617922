import { createLogic } from 'redux-logic'

import { UPDATE_ONBOARDING_CONTENT } from 'state/concepts/tutorial/types'
import { updateUserOnboardingContent, updateClientOnboardingContent } from 'state/concepts/tutorial/actions'
import { isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'

const updateOnboardingContentOperation = createLogic({
  type: UPDATE_ONBOARDING_CONTENT,
  latest: true,

  process({ action: { page }, getState }, dispatch, done) {
    isEmployeeLoggedInSelector(getState())
      ? dispatch(updateUserOnboardingContent(page))
      : dispatch(updateClientOnboardingContent(page))
    done()
  },
})

export default updateOnboardingContentOperation
