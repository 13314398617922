import { createLogic } from 'redux-logic'

import { SET_TRANSACTIONS_FILTER } from 'state/concepts/payments/types'
import { setFilterParams, setCurrentPage, fetchTransactions } from 'state/concepts/payments/actions'

const filterTransactionsOperation = createLogic({
  type: SET_TRANSACTIONS_FILTER,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(1))
    dispatch(fetchTransactions())
    done()
  },
})

export default filterTransactionsOperation
