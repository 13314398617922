import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_REVIEW } from 'state/concepts/clientAccountReview/types'
import { setClientReviewId } from 'state/concepts/clientAccountReview/actions'
import { getClientReviewEndpoint } from 'state/concepts/clientAccountReview/endpoints'

const getClientReviewTemplateOperation = createLogic({
  type: GET_REVIEW,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { reviewId } = action
    const { endpoint, url } = getClientReviewEndpoint(reviewId)
    const params = {
      include: ['booking', 'booking.service', 'booking.user-profile', 'booking.review_setting'],
    }

    try {
      dispatch(dataApiRequest({ endpoint }))

      const { data } = await httpClient.get(url, { params })

      dispatch(setClientReviewId(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getClientReviewTemplateOperation
