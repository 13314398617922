import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const getRecurringPayment = recurringPaymentId => ({
  type: types.GET_RECURRING_PAYMENT,
  recurringPaymentId,
})

export const deleteDraftRecurringPayment = (recurringPaymentId, refetchRecurringPayments = false) => ({
  type: types.DELETE_DRAFT_RECURRING_PAYMENT,
  recurringPaymentId,
  refetchRecurringPayments,
})

export const cancelRecurringPayment = (recurringPaymentId, onSuccess) => ({
  type: types.CANCEL_RECURRING_PAYMENT,
  recurringPaymentId,
  onSuccess,
})

export const fetchTransactions = recurringPaymentId => ({
  type: types.FETCH_TRANSACTIONS,
  recurringPaymentId,
})

export const setTransactions = transactionIds => ({
  type: types.SET_TRANSACTIONS,
  transactionIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_TRANSACTIONS_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_TRANSACTIONS_PAGE,
  pageNumber,
})

export const setRecurringPaymentId = recurringPaymentId => ({
  type: types.SET_RECURRING_PAYMENT_ID,
  recurringPaymentId,
})

export const requestRecurringPayment = makeFormSubmitAction(types.REQUEST_RECURRING_PAYMENT)
export const updateRecurringPayment = makeFormSubmitAction(types.UPDATE_RECURRING_PAYMENT)
export const sendRecurringPayment = (recurringPaymentId, refetchTransactions = false, hideNotification = false) => ({
  type: types.SEND_RECURRING_PAYMENT,
  recurringPaymentId,
  refetchTransactions,
  hideNotification,
})

export const setPaymentMethodId = paymentMethodId => ({
  type: types.SET_PAYMENT_METHOD_ID,
  paymentMethodId,
})

export const getPaymentMethod = (recurringPaymentId, workspaceCode) => ({
  type: types.GET_PAYMENT_METHOD,
  recurringPaymentId,
  workspaceCode,
})

export const updatePaymentMethod = makeFormSubmitAction(types.UPDATE_PAYMENT_METHOD)

export const cancelClientRecurringPayment = ({ transactionId, recurringPaymentId, onSuccess }) => ({
  type: types.CANCEL_CLIENT_RECURRING_PAYMENT,
  onSuccess,
  transactionId,
  recurringPaymentId,
})
