const clientPortalFeature = {
  'clientPortalFeature.advancedFeature': 'Advanced feature',
  'clientPortalFeature.title': '_(Client) portal',
  'clientPortalFeature.subtitle': '<span>in</span> _(Client) portal',
  'clientPortalFeature.description':
    'Let _(clients) manage all their activity: _(appointments), communication, payments, and more.',
  'clientPortalFeature.availableOnlyForAdvanced': 'Available only for advanced plans',
  'clientPortalFeature.upgradePlan': 'Upgrade plan',
  'clientPortalFeature.manageSettings': 'Set up _(client) portal',
  'clientPortalFeature.overview': '_(Client) portal overview',
  'clientPortalFeature.secondDescription':
    'The _(client) portal is a single place for your _(clients) to securely interact with your business and manage all their information and activity. Depending on the features in your workspace, the _(client) portal allows your _(clients) to:',
  'clientPortalFeature.manage': 'Manage all their activity in one place:',
  'clientPortalFeature.manageBookings': 'Book new _(appointments) as well as reschedule or cancel bookings.',
  'clientPortalFeature.manageUpcomingAndPastBookings': 'See all upcoming _(appointments) and past booking history.',
  'clientPortalFeature.manageFeedbacks': 'Send feedback about past _(appointments).',
  'clientPortalFeature.control': 'Control payments:',
  'clientPortalFeature.controlAccept': 'Accept _(appointments) and pay for them securely.',
  'clientPortalFeature.controlAccess': 'Easily access all payment history, along with invoices and receipts.',
  'clientPortalFeature.create': 'Create a channel for direct communication with your business:',
  'clientPortalFeature.createInstant': 'Get instant support from _(experts) with a secure chat.',
  'clientPortalFeature.createSafely':
    'Safely send and receive downloadable files, providing more context and extra materials.',
  'clientPortalFeature.save': 'Save time with fast and paperless document exchange:',
  'clientPortalFeature.saveEasily': 'Easily review and complete any shared forms or agreements.',
  'clientPortalFeature.saveReview': 'Review signed documents at any time.',
  'clientPortalFeature.thirdDescription':
    'The _(client) portal is a secure place for all _(client) information and communication. Safe access with a one-time authorization password, compliance with GDPR and HIPAA requirements, and SSL encrypted connections ensure that your _(clients)’ data is always protected.',
  'clientPortalFeature.toLearnMore':
    'To learn more, read our help center article on <link>setting up and managing your _(client) portal</link>.',
  'clientPortalFeature.relatedFeatures': 'Related features',
  'clientPortalFeature.disableModalTitle': 'Disable _(client) portal feature?',
  'clientPortalFeature.disableModalWarning': `_(Clients) won’t be able to log into the _(client) portal anymore. They still can interact with the workspace via email: access their _(appointments), get payment requests, complete forms, etc.`,
}

export default clientPortalFeature
