import { createLogic } from 'redux-logic'

import { COOKIES_DOMAIN } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import removeClientCookies from 'utils/removeClientCookies'
import { deleteClientOwnAccountEndpoint } from 'state/concepts/client/endpoints'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { DELETE_CLIENT_OWN_ACCOUNT } from '../types'

const deleteClientOwnAccountOperation = createLogic({
  type: DELETE_CLIENT_OWN_ACCOUNT,
  latest: true,

  async process({ action: { values, form, onSuccess }, httpClient, getState }, dispatch, done) {
    const { url } = deleteClientOwnAccountEndpoint
    const { uniqCode } = workspaceSelector(getState())
    const params = {
      code: values.confirmationCode,
    }

    try {
      await httpClient.delete(url, { params })

      removeClientCookies({ path: `/client/${uniqCode}` })
      removeClientCookies({ domain: COOKIES_DOMAIN, path: `/client/${uniqCode}` })
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default deleteClientOwnAccountOperation
