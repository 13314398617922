import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import spanWithClass from 'utils/locales/spanWithClass'
import terminal from 'assets/images/freemium/terminal.svg'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import Checked from 'views/shared/icons/Checked'
import Cross from 'views/shared/icons/Cross'

const SwitchToPremiumModal = ({ onClose, onPremiumSelect, onPremiumPlusSelect }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <button
        type="button"
        onClick={onClose}
        className="main-modal__close main-modal__close--without-header d-flex align-items-center justify-content-center"
      >
        <Cross dataCy="cross-icon" size={10} />
      </button>
      <div className="main-modal__body">
        <div className="ml-auto mr-auto d-flex justify-content-center">
          <Image height={220} width={220} src={terminal} alt="" />
        </div>
        <p className="text-headline mb-0 mt-24 in-blue-gray-800 text-center">
          <FormattedMessage id="billing.switchToPremiumModal.title" values={{ span: spanWithClass('in-violet-700') }} />
        </p>
        <p className="text-body in-blue-gray-800 mb-0 mt-24">
          <FormattedMessage id="billing.switchToPremiumModal.description" />
        </p>
        <ul className="mt-18">
          <li className="d-flex align-items-center text-body in-blue-gray-800">
            <Checked size={16} className="in-blue-600 flex-shrink-0 mr-12" />
            <FormattedMessage id="billing.switchToPremiumModal.acceptPaymentOnline" />
          </li>
          <li className="d-flex align-items-center mt-12 text-body in-blue-gray-800">
            <Checked size={16} className="in-blue-600 flex-shrink-0 mr-12" />
            <FormattedMessage id="billing.switchToPremiumModal.sendInvoices" />
          </li>
          <li className="d-flex align-items-center mt-12 text-body in-blue-gray-800">
            <Checked size={16} className="in-blue-600 flex-shrink-0 mr-12" />
            <FormattedMessage id="billing.switchToPremiumModal.automateRefunds" />
          </li>
          <li className="d-flex align-items-center mt-12 text-body in-blue-gray-800">
            <Checked size={16} className="in-blue-600 flex-shrink-0 mr-12" />
            <FormattedMessage id="billing.switchToPremiumModal.trackTransactions" />
          </li>
        </ul>
      </div>
      <div className="main-modal__footer gap-16">
        <Button
          className="mr-0"
          kind="flat"
          text={{ id: 'billing.switchToPremiumModal.cancel' }}
          onClick={onPremiumSelect}
        />
        <Button
          className="main-btn--violet"
          text={{ id: 'billing.switchToPremiumModal.confirm' }}
          onClick={onPremiumPlusSelect}
        />
      </div>
    </div>
  </Modal>
)

SwitchToPremiumModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPremiumSelect: PropTypes.func.isRequired,
  onPremiumPlusSelect: PropTypes.func.isRequired,
}

export default SwitchToPremiumModal
