import * as types from './types'

export const fetchFilesSharedWithMe = (pageParams = {}, options = {}) => ({
  type: types.FETCH_FILES_SHARED_WITH_ME,
  pageParams,
  options,
})

export const addFileSharedWithMeIds = ids => ({
  type: types.ADD_FILES_SHARED_WITH_ME_IDS,
  ids,
})

export const setFileSharedWithMeIds = ids => ({
  type: types.SET_FILES_SHARED_WITH_ME_IDS,
  ids,
})

export const resetFileSharedWithMeIds = () => ({
  type: types.RESET_FILES_SHARED_WITH_ME_IDS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const setCurrentFolderId = id => ({
  type: types.SET_CURRENT_FOLDER_ID,
  id,
})

export const setSelectedIds = ids => ({
  type: types.SET_SELECTED_IDS,
  ids,
})

export const resetSelectedIds = () => ({
  type: types.RESET_SELECTED_IDS,
})

export const filterFilesSharedWithMe = (filters = {}) => ({
  type: types.FILTER_FILES_SHARED_WITH_ME,
  filters,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const setFilterParams = params => ({
  type: types.SET_FILTER_PARAMS,
  params,
})

export const moveToFolder = (fileId, kind, parentId, onSuccess) => ({
  type: types.MOVE_TO_FOLDER,
  fileId,
  parentId,
  kind,
  onSuccess,
})

export const fetchFoldersToMove = (fileId, parentId, page = 1) => ({
  type: types.FETCH_FOLDERS_TO_MOVE,
  parentId,
  fileId,
  page,
})

export const addFolderToMoveIds = ids => ({
  type: types.ADD_FOLDER_TO_MOVE_IDS,
  ids,
})

export const setFolderToMoveIds = ids => ({
  type: types.SET_FOLDER_TO_MOVE_IDS,
  ids,
})

export const resetFolderToMoveIds = () => ({
  type: types.RESET_FOLDER_TO_MOVE_IDS,
})

export const removeFileId = fileId => ({
  type: types.REMOVE_FILE_ID,
  fileId,
})

export const moveFilesSharedWithMeToTrash = (sharedFileIds, onNextSlide, onBack) => ({
  type: types.MOVE_FILES_SHARED_WITH_ME_TO_TRASH,
  sharedFileIds,
  onNextSlide,
  onBack,
})

export const removeFileSharedWithMeByIds = ids => ({
  type: types.REMOVE_FILES_SHARED_WITH_ME_BY_IDS,
  ids,
})

export const removeMyAccess = (id, onSuccess) => ({
  type: types.REMOVE_MY_ACCESS,
  id,
  onSuccess,
})

export const receivedBusinessStorage = data => ({
  type: types.RECEIVE_BUSINESS_STORAGE,
  data,
})

export const receiveBusinessStoragePermission = data => ({
  type: types.RECEIVE_BUSINESS_STORAGE_PERMISSIONS,
  data,
})
