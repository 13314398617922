import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { UPDATE_BOOKING_SETTINGS } from 'state/concepts/workspaceBookingSettings/types'
import { updateBookingSettingsEndpoint } from 'state/concepts/workspaceBookingSettings/endpoints'
import { bookingSettingsIdSelector } from 'state/concepts/workspaceBookingSettings/selectors'
import { bookingPolicyFormToParams } from 'utils/bookingPolicy'
import { showNotification } from 'state/notifications/actions'

const updateBookingSettingsOperation = createLogic({
  type: UPDATE_BOOKING_SETTINGS,
  latest: true,

  async process({ action: { values, form }, getState, httpClient }, dispatch, done) {
    const { url } = updateBookingSettingsEndpoint

    try {
      const params = bookingPolicyFormToParams(values)
      const id = bookingSettingsIdSelector(getState())

      await httpClient.put(url, params)

      const response = updateDataHelper(getState().data, 'workspaceBookingSetting', id, {
        attributes: {
          cancellableOption: params.cancellable_option,
          cancellableUntil: params.cancellable_until,
          minLeadTime: params.min_lead_time,
          availableFor: params.available_for,
          bufferBefore: params.buffer_before,
          bufferAfter: params.buffer_after,
          reschedulableOption: params.reschedulable_option,
          reschedulableUntil: params.reschedulable_until,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.updateBookingPolicy',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateBookingSettingsOperation
