import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_PASSWORD_ON_TWO_FACTOR } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { validatePasswordOnTwoFactorEndpoint } from '../endpoints'

const validatePasswordOnTwoFactorOperation = createLogic({
  type: VALIDATE_PASSWORD_ON_TWO_FACTOR,
  latest: true,

  async process({ httpClient, action: { values, form, onSuccess } }, dispatch, done) {
    const { endpoint, url } = validatePasswordOnTwoFactorEndpoint
    const { password } = values
    const params = { password }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default validatePasswordOnTwoFactorOperation
