import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { searchServiceCategories } from 'state/concepts/serviceCategories/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { REMOVE_SERVICE_CATEGORIES } from 'state/concepts/serviceCategories/types'
import { removeServiceCategoriesEndpoint } from '../endpoints'

const removeServiceCategoriesOperation = createLogic({
  type: REMOVE_SERVICE_CATEGORIES,
  latest: true,

  async process({ httpClient, action: { serviceCategoriesIds } }, dispatch, done) {
    const { endpoint, url } = removeServiceCategoriesEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, { service_category_ids: serviceCategoriesIds })

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.serviceCategoriesRemoved',
            values: { count: serviceCategoriesIds.length },
          },
        }),
      )
      dispatch(searchServiceCategories())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeServiceCategoriesOperation
