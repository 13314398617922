import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { validateCancelBookingEndpoint } from 'state/concepts/bookings/endpoints'
import { VALIDATE_CANCEL_BOOKING } from 'state/concepts/bookings/types'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { setCancellationRules, setCancellationError } from 'state/concepts/bookings/actions'

const validateCancelBookingOperation = createLogic({
  type: VALIDATE_CANCEL_BOOKING,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { uniqCode } = action
    const { endpoint, url } = validateCancelBookingEndpoint(uniqCode)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const response = await httpClient.get(url)

      if (response.data && response.data.meta) {
        const {
          data: {
            meta: {
              time,
              cancellation_rules: description,
              booking_refund_amount: bookingRefundAmount,
              refund_amount: refundAmount,
              refund_count: refundCount,
              validate_cancellation_reason: validateCancellationReason,
            },
          },
        } = response

        dispatch(
          setCancellationRules({
            time,
            description,
            bookingRefundAmount,
            refundAmount,
            refundCount,
            validateCancellationReason,
          }),
        )
      }

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setCancellationError(null))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors
        dispatch(setCancellationError(detail))
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default validateCancelBookingOperation
