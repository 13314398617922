import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchReviewSettings = () => ({
  type: types.FETCH_REVIEW_SETTINGS,
})

export const updateReviewSettings = makeFormSubmitAction(types.UPDATE_REVIEW_SETTINGS)

export const setReviewSettingId = id => ({
  type: types.SET_REVIEW_SETTING_ID,
  id,
})
