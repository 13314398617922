import { createLogic } from 'redux-logic'

import { setCurrentPage } from 'state/tableData/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { FORM_TYPES } from 'lib/constants/forms'
import { fetchIntakeForms, setFilterParams } from '../actions'
import { FILTER_INTAKE_FORMS } from '../types'

const filterIntakeFormsOperation = createLogic({
  type: FILTER_INTAKE_FORMS,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(setCurrentPage(TABLE_ENTITIES[FORM_TYPES.intake], 1))
    dispatch(fetchIntakeForms())
    done()
  },
})

export default filterIntakeFormsOperation
