import { FormattedMessage } from 'react-intl'
import { useToggle } from 'react-use'
import PropTypes from 'prop-types'

import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'
import { parseProviderDetails } from 'utils/videoCalls'
import DETAILS_BY_PROVIDER from './details'

const ShowDetails = ({ videoConference }) => {
  const [isDetailsShown, toggleIsDetailsShown] = useToggle(false)
  const details = parseProviderDetails(videoConference)
  const Details = DETAILS_BY_PROVIDER[videoConference.videoCallProvider]

  return (
    <>
      {isDetailsShown && (
        <div className="ml-36">
          <p className="font-600 mb-0">
            <FormattedMessage id={`consultation.details.title.${videoConference.videoCallProvider}`} />
          </p>
          <Details {...details} providerUrl={videoConference.providerUrl} />
        </div>
      )}
      <button className="in-blue-600 text-left ml-36" onClick={toggleIsDetailsShown}>
        <FormattedMessage id={`shared.${isDetailsShown ? 'hideDetails' : 'showDetails'}`} />
        {isDetailsShown ? (
          <ArrowUp dataCy="arrow-up" className="in-blue-600 ml-8" size={8} />
        ) : (
          <ArrowDown dataCy="arrow-down" className="in-blue-600 ml-8" size={8} />
        )}
      </button>
    </>
  )
}

ShowDetails.propTypes = {
  videoConference: PropTypes.shape().isRequired,
}

export default ShowDetails
