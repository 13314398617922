import endpoint from 'utils/endpoint'

import {
  usersRoute,
  usersDeactivationRoute,
  usersActivationRoute,
  userRoute,
  userInvitationsRoute,
  usersInvitationsResendRoute,
  usersTwoFactorAuthRoute,
  usersAvailabilitiesRoute,
} from 'lib/apiRoutes'
import {
  FETCH_EMPLOYEES,
  DEACTIVATE_EMPLOYEE,
  ACTIVATE_EMPLOYEE,
  REMOVE_EMPLOYEE,
  REMOVE_EMPLOYEE_INVITATION,
  RESEND_EMPLOYEE_INVITATION,
  FETCH_EMPLOYEES_PROVISION,
  FETCH_EMPLOYEES_PROFILE,
  RESET_TWO_FACTOR_AUTH,
  FETCH_EMPLOYEES_AVAILABILITIES,
} from './types'

export const fetchEmployeesEndpoint = endpoint(FETCH_EMPLOYEES, 'GET', usersRoute)
export const deactivateEmployeeEndpoint = id => endpoint(DEACTIVATE_EMPLOYEE, 'POST', usersDeactivationRoute(id))
export const activateEmployeeEndpoint = id => endpoint(ACTIVATE_EMPLOYEE, 'POST', usersActivationRoute(id))
export const removeEmployeeEndpoint = id => endpoint(REMOVE_EMPLOYEE, 'DELETE', userRoute(id))
export const removeEmployeeInvitationEndpoint = id =>
  endpoint(REMOVE_EMPLOYEE_INVITATION, 'DELETE', userInvitationsRoute(id))
export const resendEmployeeInvitationEndpoint = id =>
  endpoint(RESEND_EMPLOYEE_INVITATION, 'POST', usersInvitationsResendRoute(id))
export const fetchEmployeeProvisionEndpoint = id => endpoint(FETCH_EMPLOYEES_PROVISION, 'GET', userRoute(id))
export const fetchEmployeeProfileEndpoint = id => endpoint(FETCH_EMPLOYEES_PROFILE, 'GET', userRoute(id))
export const resetTwoFactorAuthEndpoint = id => endpoint(RESET_TWO_FACTOR_AUTH, 'DELETE', usersTwoFactorAuthRoute(id))
export const fetchEmployeesAvailabilitiesEndpoint = endpoint(
  FETCH_EMPLOYEES_AVAILABILITIES,
  'GET',
  usersAvailabilitiesRoute,
)
