import { createSelector } from '@reduxjs/toolkit'
import { path, pathOr, pipe, prop } from 'ramda'

import isPresent from 'utils/isPresent'

export const dataSelector = prop('data')

const metaSelector = state => state.data.meta
const endpointArgSelector = (_, endpoint) => endpoint

export const loadingSelector = createSelector(
  [metaSelector, endpointArgSelector],
  (meta, endpoint) => meta[endpoint] && meta[endpoint].loading,
)

export const linksSelector = createSelector([metaSelector, endpointArgSelector], (meta, endpoint) =>
  path([endpoint, 'links'], meta),
)

export const endpointSelector = createSelector([metaSelector, endpointArgSelector], (meta, endpoint) =>
  prop(endpoint, meta),
)

export const endpointMetaSelector = createSelector([metaSelector, endpointArgSelector], (meta, endpoint) =>
  pathOr({}, [endpoint, 'meta'], meta),
)

export const hasMoreSelector = createSelector(endpointMetaSelector, prop('hasMore'))
export const totalCountSelector = createSelector(endpointMetaSelector, meta =>
  isPresent(prop('total', meta)) ? prop('total', meta) : prop('totalCount', meta),
)

export const pageCountSelector = pipe(linksSelector, prop('last'))

export const currentPageSelector = pipe(linksSelector, prop('self'))

export const nextPageSelector = pipe(linksSelector, prop('next'))
