import * as types from './types'

export const fetchStorageFiles = (pageParams = {}, options = {}) => ({
  type: types.FETCH_STORAGE_FILES,
  pageParams,
  options,
})

export const addStorageFilesIds = ids => ({
  type: types.ADD_STORAGE_FILES_IDS,
  ids,
})

export const setStorageFileIds = ids => ({
  type: types.SET_STORAGE_FILES_IDS,
  ids,
})

export const resetStorageFilesIds = () => ({
  type: types.RESET_STORAGE_FILES_IDS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const filterStorageFiles = (filters = {}) => ({
  type: types.FILTER_STORAGE_FILES,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAMS,
})

export const deleteStorageFiles = (files, onSuccess) => ({
  type: types.DELETE_STORAGE_FILES,
  files,
  onSuccess,
})

export const removeStorageFileIds = ids => ({
  type: types.REMOVE_STORAGE_FILE_IDS,
  ids,
})

export const setSelectedIds = ids => ({
  type: types.SET_SELECTED_IDS,
  ids,
})

export const setStorageSpace = storageSpace => ({
  type: types.SET_STORAGE_SPACE,
  storageSpace,
})

export const setAutoUpgrade = autoUpgrade => ({
  type: types.SET_AUTO_UPGRADE,
  autoUpgrade,
})
