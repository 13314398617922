import { TimePicker } from 'antd'
import classNames from 'clsx'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'
import InputMessage from '../InputMessage'

const TimePickerFieldComponent = ({
  field,
  mainWrapperClass,
  defaultValue,
  onChange,
  onOpenChange,
  isOpen,
  form: { touched, errors },
  placeholder,
  intl,
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const wrapperClassNames = classNames(mainWrapperClass, {
    'main-input--has-message': fieldTouched && fieldErrors,
    'main-input--has-message-error': fieldTouched && fieldErrors,
  })

  return (
    <div className={wrapperClassNames}>
      <TimePicker
        value={defaultValue}
        onChange={onChange}
        use12Hours
        format="h:mm a"
        minuteStep={15}
        inputReadOnly
        allowClear={false}
        showNow={false}
        onOpenChange={onOpenChange}
        transitionName=""
        placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
        suffixIcon={
          isOpen ? (
            <ArrowUp dataCy="arrow-down-icon" className="in-gray-700" size={10} />
          ) : (
            <ArrowDown dataCy="arrow-down-icon" className="in-gray-700" size={10} />
          )
        }
        {...props}
      />
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon="alert" />}
    </div>
  )
}

TimePickerFieldComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  mainWrapperClass: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
}

TimePickerFieldComponent.defaultProps = {
  mainWrapperClass: null,
  defaultValue: undefined,
  placeholder: null,
}

export default TimePickerFieldComponent
