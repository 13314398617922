import fetchTasks from './fetchTasks'
import deleteTask from './deleteTask'
import filterTasks from './filterTasks'
import createTask from './createTask'
import updateTaskPriority from './updateTaskPriority'
import markTaskAsCompleted from './markTaskAsCompleted'
import markTaskAsTodo from './markTaskAsTodo'
import updateTask from './updateTask'

export default [
  fetchTasks,
  deleteTask,
  filterTasks,
  createTask,
  updateTaskPriority,
  markTaskAsCompleted,
  markTaskAsTodo,
  updateTask,
]
