import { Select } from 'antd'
import classNames from 'clsx'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { ONLINE_LOCATION_ICONS, ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import SelectField from 'views/shared/SelectField'
import Loader from './Loader'
import NotFoundContent from './NotFoundContent'
import OptionContent from './OptionContent'

const DropdownLocationFieldComponent = ({
  isLoading,
  handleDropdownVisibleChange,
  isOpen,
  locationOptions,
  isEmptyWhenNoOptions,
  isVideoCallsEnabled,
  videoCallProvider,
  ...restProps
}) => (
  <SelectField
    className="max-w-420"
    popupClassName="dropdown-with-divider pb-4"
    optionLabelProp="label"
    {...restProps}
    notFoundContent={<NotFoundContent isLoading={isLoading} />}
    onDropdownVisibleChange={handleDropdownVisibleChange}
    open={isOpen}
  >
    {!isLoading && !isEmptyWhenNoOptions && isVideoCallsEnabled && (
      <Select.OptGroup
        label={
          <span className="d-block pt-8 pb-4 pl-16 pr-16 font-400">
            <FormattedMessage id="scheduleAppointment.locationSelect.onlineLocation" />
          </span>
        }
      >
        <Select.Option
          label={<FormattedMessage {...ONLINE_LOCATION_OPTION.label[videoCallProvider]} />}
          value={ONLINE_LOCATION_OPTION.value}
          className="mb-4"
        >
          <div className="d-flex align-items-center">
            <div className="mr-8 d-flex align-items-center">
              <Image
                src={ONLINE_LOCATION_ICONS[videoCallProvider]}
                width={24}
                height={24}
                alt="Video call provider logo"
              />
            </div>
            <FormattedMessage {...ONLINE_LOCATION_OPTION.label[videoCallProvider]} />
          </div>
        </Select.Option>
      </Select.OptGroup>
    )}
    {locationOptions.length && (
      <>
        <Select.OptGroup
          label={
            <span className="d-block pt-8 pb-4 pl-16 pr-16 font-400">
              <FormattedMessage id="scheduleAppointment.locationSelect.offlineLocations" />
            </span>
          }
        >
          {locationOptions.map(location => (
            <Select.Option label={location.name} title={location.name} value={location.id} key={location.id}>
              <OptionContent location={location} />
            </Select.Option>
          ))}
        </Select.OptGroup>
        <Select.Option
          disabled
          className={classNames({ 'visibility-hidden min-h-a p-0': isLoading === false })}
          key="waypoint"
        >
          <Loader isLoading={isLoading} />
        </Select.Option>
      </>
    )}
  </SelectField>
)

DropdownLocationFieldComponent.propTypes = {
  handleDropdownVisibleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locationOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
  isEmptyWhenNoOptions: PropTypes.bool,
  isVideoCallsEnabled: PropTypes.bool.isRequired,
  videoCallProvider: PropTypes.string.isRequired,
}

DropdownLocationFieldComponent.defaultProps = {
  isLoading: undefined,
  isEmptyWhenNoOptions: false,
}

export default DropdownLocationFieldComponent
