import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const DiscardChanges = ({ setStep, prevStep }) => {
  const { handleReset } = useFormikContext()
  const onDiscard = () => {
    handleReset()
    setStep(SHARE_FILE_STEPS.sharedWith)
  }
  const onCancel = () => setStep(prevStep)

  return (
    <>
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="shared.discardChanges" />
        </h2>
        <button type="button" className="main-modal__close" onClick={onCancel} data-cy="close-button">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p className="main-modal__body-text">
          <FormattedMessage id="shared.cancelText" />
        </p>
      </div>
      <div className="main-modal__footer">
        <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onCancel} kind="flat" />
        <Button
          className="main-modal__footer-action main-btn--danger"
          type="submit"
          text={{ id: 'shared.discard' }}
          onClick={onDiscard}
        />
      </div>
    </>
  )
}

DiscardChanges.propTypes = {
  setStep: PropTypes.func.isRequired,
  prevStep: PropTypes.string.isRequired,
}

export default DiscardChanges
