const namespace = 'reviews'

export const FETCH_REVIEWS = `${namespace}/FETCH_REVIEWS`
export const SET_REVIEWS = `${namespace}/SET_REVIEWS`
export const SET_REVIEWS_PAGE = `${namespace}/SET_REVIEWS_PAGE`
export const SET_REVIEWS_SORT_ORDER = `${namespace}/SET_REVIEWS_SORT_ORDER`
export const SET_REVIEWS_FILTER = `${namespace}/SET_REVIEWS_FILTER`
export const SET_REVIEWS_FILTER_PARAMS = `${namespace}/SET_REVIEWS_FILTER_PARAMS`
export const SET_REVIEWS_BLANK_STATE = `${namespace}/SET_REVIEWS_BLANK_STATE`
export const RESET_REVIEWS = `${namespace}/RESET_REVIEWS`
export const TOGGLE_REVIEW_STATUS = `${namespace}/TOGGLE_REVIEW_STATUS`
export const RESET_FILTERS = `${namespace}/RESET_FILTERS`
