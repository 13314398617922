import { createLogic } from 'redux-logic'
import { map } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { servicesDetailsRoute } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_SERVICE_DETAILS } from 'state/concepts/service/types'
import { serviceDetailsFormToParams } from 'utils/services/serviceFormToParams'

const updateServiceDetailsOperation = createLogic({
  type: UPDATE_SERVICE_DETAILS,
  latest: true,

  async process(
    {
      action: {
        values,
        form,
        componentInitialProps: { serviceId },
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    try {
      const state = getState()
      const params = serviceDetailsFormToParams(values)
      const url = servicesDetailsRoute(serviceId)

      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'service', serviceId, {
        attributes: {
          name: params.name,
          duration: params.duration,
          online: params.online,
          description: params.description,
        },
        relationships: {
          serviceCategory: {
            data: { id: params.service_category_id },
          },
          locations: {
            data: map(val => ({ id: val, type: 'location' }), values.locationIds),
          },
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.serviceUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)

    done()
  },
})

export default updateServiceDetailsOperation
