import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import ProviderSidebar from 'views/Website/Providers/Provider/ProviderSidebar'
import ServiceSidebar from 'views/Website/Services/Service/ServiceSidebar'
import BookingTooltip from 'views/company/Bookings/BookingTooltip'
import EditScheduleAppointment from 'views/company/Bookings/EditScheduleAppointment'
import RescheduleAppointment from 'views/company/Bookings/RescheduleAppointment'
import ScheduleAppointment from 'views/company/Bookings/ScheduleAppointment'
import EditWebsiteSidebar from 'views/company/EditWebsite/Preview/EditWebsiteSidebar'
import WelcomeSectionSidebar from 'views/company/EditWebsite/Preview/WelcomeSectionSidebar'
import EditAvailabilitySidebar from 'views/shared/AvailabilityCalendar/EditAvailabilitySidebar'
import ExternalEventSidebar from 'views/shared/BookingCalendar/DayEvent/ExternalEventSidebar'
import WaitlistRequestSidebar from 'views/shared/Waitlist/WaitlistRequestSidebar'

export const SIDEBAR_COMPONENTS = {
  [SIDEBAR_COMPONENTS_TYPES.editAvailabilitySidebar]: EditAvailabilitySidebar,
  [SIDEBAR_COMPONENTS_TYPES.createBookingAppointment]: ScheduleAppointment,
  [SIDEBAR_COMPONENTS_TYPES.editBookingAppointment]: EditScheduleAppointment,
  [SIDEBAR_COMPONENTS_TYPES.rescheduleBookingAppointment]: RescheduleAppointment,
  [SIDEBAR_COMPONENTS_TYPES.bookingDetails]: BookingTooltip,
  [SIDEBAR_COMPONENTS_TYPES.websiteEdit]: EditWebsiteSidebar,
  [SIDEBAR_COMPONENTS_TYPES.websiteWelcomeSection]: WelcomeSectionSidebar,
  [SIDEBAR_COMPONENTS_TYPES.websiteViewService]: ServiceSidebar,
  [SIDEBAR_COMPONENTS_TYPES.websiteViewProvider]: ProviderSidebar,
  [SIDEBAR_COMPONENTS_TYPES.externalCalendarEventDetails]: ExternalEventSidebar,
  [SIDEBAR_COMPONENTS_TYPES.waitlistRequestDetails]: WaitlistRequestSidebar,
}

export default SIDEBAR_COMPONENTS
