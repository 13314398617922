const companyClientsCreate = {
  'clients.create.newClient': 'Create new _(client)',
  'clients.create.generalInformation': 'General information',
  'clients.create.additionalInformation': 'Additional information',
  'clients.create.addCustomFields':
    'Add custom fields in {link} to collect additional information about your _(clients).',
  'clients.create.fieldsSettings': 'Fields settings',
  'client.create.fields.firstName': 'First name <span>(required)</span>',
  'client.create.fields.lastName': 'Last name <span>(required)</span>',
  'client.create.fields.email': 'Email address <span>(required)</span>',
  'client.create.fields.sendInvitation': 'Send invitation to the _(Client) portal',
  'client.create.noCustomFields':
    'Add custom fields in {link} to collect additional information about your _(clients).',
  'client.create.noCustomFields.linkText': 'Fields settings',
}

export default companyClientsCreate
