import { VIDEO_ROOM_TYPE } from 'lib/constants/bookings'
import { VIDEO_CALLS_PROVIDERS } from 'lib/constants/videoCalls'

import isPresent from 'utils/isPresent'

const canAddParticipants = ({ videoConference, location, userProfile }) => {
  const videoCallProvider = videoConference?.videoCallProvider || userProfile.videoCallProvider

  return (
    videoConference?.roomType === VIDEO_ROOM_TYPE.group ||
    isPresent(location) ||
    videoCallProvider !== VIDEO_CALLS_PROVIDERS.expertbox
  )
}

export default canAddParticipants
