import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { currentEmployeeSelector, currentWorkspaceSelector } from 'state/concepts/session/selectors'
import { RESET_PASSWORD_REQUEST_ACCOUNT } from 'state/concepts/employee/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { resetPasswordAccountEndpoint } from '../endpoints'

const resetPasswordRequestAccount = createLogic({
  type: RESET_PASSWORD_REQUEST_ACCOUNT,
  latest: true,

  async process({ getState }, dispatch, done) {
    const { endpoint, url } = resetPasswordAccountEndpoint

    const state = getState()
    const workspace = currentWorkspaceSelector(state)
    const params = {
      email: currentEmployeeSelector(state).email,
      workspace_id: workspace.id,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await workspacesHttpClient.post(url, params)

      dispatch(dataApiSuccess({ response: {}, endpoint }))
      dispatch(
        showNotification({
          messageObject: { id: 'resetPassword.checkEmail' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default resetPasswordRequestAccount
