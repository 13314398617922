import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { fetchEmployeesEndpoint } from 'state/concepts/employees/endpoints'
import { endpointMetaSelector } from 'state/data/selectors'
import checkDuplicates from 'utils/invitations/checkDuplicates'
import EmployeesInvitationInputFieldsComponent from './component'

const { endpoint } = fetchEmployeesEndpoint

class EmployeesInvitationInputFields extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
    employeesMeta: PropTypes.shape({
      teamSize: PropTypes.number,
      teamSeatsCapacity: PropTypes.number,
    }).isRequired,
  }

  get disableInviteBtn() {
    const { form, employeesMeta } = this.props
    const {
      values: { invitations },
    } = form
    const { teamSize, teamSeatsCapacity } = employeesMeta

    const disableInviteBtn = invitations.length >= teamSeatsCapacity - teamSize

    return disableInviteBtn
  }

  addRow = () => this.props.push({ email: '', role: 'user', key: Math.random() })

  removeRow = index => () => {
    const { remove, form } = this.props

    checkDuplicates(form, { without: index })
    remove(index)
  }

  handleBlur = event => {
    const { form } = this.props

    form.handleBlur(event)
    checkDuplicates(form)
  }

  render() {
    return (
      <EmployeesInvitationInputFieldsComponent
        {...this.props}
        disableInviteBtn={this.disableInviteBtn}
        addRow={this.addRow}
        removeRow={this.removeRow}
        handleBlur={this.handleBlur}
      />
    )
  }
}

const mapStateToProps = state => ({
  employeesMeta: endpointMetaSelector(state, endpoint),
})

export { EmployeesInvitationInputFields as EmployeesInvitationInputFieldsContainer }
export default compose(injectIntl, connect(mapStateToProps))(EmployeesInvitationInputFields)
