import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { updateWorkspaceFeatureActivation } from 'state/concepts/workspaceFeatures/actions'
import Alert from 'views/shared/Alert'
import { ALERT_KINDS } from 'lib/constants'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { DISABLE_FEATURE_OPTIONS, DISABLE_FEATURE_VALUES } from 'lib/constants/locationsFeature'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'

const DisableLocationsFeatureModal = ({ onClose }) => {
  const handleDeactivateFeature = useDispatchAction(updateWorkspaceFeatureActivation)
  const handleSubmit = ({ action }) => {
    handleDeactivateFeature(FEATURE_KEYS.locations, false, {
      move_to_online: action === DISABLE_FEATURE_VALUES.moveToOnline,
    })
  }

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <Formik onSubmit={handleSubmit} initialValues={{ action: DISABLE_FEATURE_VALUES.remove }}>
        <div className="main-modal__container d-block">
          <div className="main-modal__header">
            <h2 className="main-modal__title" data-cy="modal-title">
              <FormattedMessage id="locationsFeature.disableLocations" />
            </h2>
            <button data-cy="close-button" type="button" onClick={onClose} className="main-modal__close">
              <Cross dataCy="cross-icon" />
            </button>
          </div>
          <div className="main-modal__body">
            <Alert type={ALERT_KINDS.info} message={{ id: 'locationsFeature.disableLocations.alert' }} />
            <p className="text-body font-600 mt-16 mb-8" data-cy="modal-subtitle">
              <FormattedMessage id="locationsFeature.disableLocations.bodySubtitle" />
            </p>
            <p className="text-body" data-cy="modal-body">
              <FormattedMessage id="locationsFeature.disableLocations.bodyText" />
            </p>
            <Field id="action" name="action" component={RadioField} options={DISABLE_FEATURE_OPTIONS} />
          </div>
          <div className="main-modal__footer">
            <div>
              <Button
                className="main-modal__footer-action"
                text={{ id: 'shared.cancel' }}
                onClick={onClose}
                kind="flat"
                dataCy="cancel-button"
              />
              <SubmitFormButton
                className="main-modal__footer-action main-btn--danger"
                text={{ id: 'features.disableFeature' }}
                allowPristine
              />
            </div>
          </div>
        </div>
      </Formik>
    </Modal>
  )
}

DisableLocationsFeatureModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default DisableLocationsFeatureModal
