import { DateTime } from 'luxon'
import isPresent from 'utils/isPresent'

const isExpired = tokens => {
  if (!isPresent(tokens)) {
    return true
  }

  let refreshExpiresAt
  if (typeof tokens === 'string') {
    refreshExpiresAt = JSON.parse(tokens).refresh_expires_at
  } else {
    refreshExpiresAt = tokens.refresh_expires_at
  }

  return DateTime.fromISO(refreshExpiresAt) < DateTime.now()
}

export default isExpired
