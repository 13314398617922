import { isNil } from 'ramda'

// eslint-disable-next-line import/prefer-default-export
export function priceMin(min) {
  return this.test({
    name: 'priceMin',
    message: { id: 'yup.number.servicePrice.min', values: { min } },
    params: { min },
    test: value => {
      if (isNil(value) || value === 0) {
        return true
      }

      return value >= min
    },
  })
}
