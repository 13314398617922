import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const DocumentPending = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path
      fill="#b2bac7"
      d="M170.667 42.667c-46.739 0-85.333 38.594-85.333 85.333v682.667c0 46.741 38.594 85.333 85.333 85.333h256v-85.333h-256v-682.667h298.667v213.333h213.333v128h85.333v-170.667l-256-256h-341.333z"
    />
    <path
      fill="#ff8f00"
      d="M725.334 554.667c-117.316 0-213.333 96.017-213.333 213.333s96.017 213.333 213.333 213.333c117.316 0 213.333-96.017 213.333-213.333s-96.017-213.333-213.333-213.333zM725.334 640.001v128h128c0 71.198-56.802 128-128 128s-128-56.802-128-128c0-71.198 56.802-128 128-128z"
    />
  </svg>
)

DocumentPending.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

DocumentPending.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DocumentPending
