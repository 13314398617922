import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const createAgreement = makeFormSubmitAction(types.CREATE_AGREEMENT)
export const updateAgreement = makeFormSubmitAction(types.UPDATE_AGREEMENT)

export const fetchAgreement = id => ({
  type: types.FETCH_AGREEMENT,
  id,
})

export const setAgreementId = id => ({
  type: types.SET_AGREEMENT_ID,
  id,
})

export const deleteAgreement = id => ({
  type: types.DELETE_AGREEMENT,
  id,
})

export const downloadAgreement = submissionId => ({
  type: types.DOWNLOAD_AGREEMENT,
  submissionId,
})

export const updateAgreementActivation = (id, isEnable) => ({
  type: types.UPDATE_AGREEMENT_ACTIVATION,
  id,
  isEnable,
})
