import { reduce } from 'ramda'

import { SERVICE_INTERVAL_OPTIONS } from 'lib/constants/services'
import { durationToSeconds } from 'utils/duration'

export const serviceDetailsFormToParams = details => ({
  name: details.name,
  description: details.description,
  duration: details.duration && durationToSeconds(details.duration),
  service_category_id: details.category,
  location_ids: details.locationIds,
  online: details.online,
})

const schedulingIntervalOption = ({ schedulingRule, schedulingInterval }) =>
  schedulingRule === SERVICE_INTERVAL_OPTIONS.fixedInterval ? schedulingInterval : undefined

export const serviceSettingsFormToParams = settings => ({
  scheduling_rule: settings.schedulingRule,
  scheduling_interval: schedulingIntervalOption(settings),
  consultant_can_record_video: settings.consultantCanRecordVideo,
  payment_required: settings.paymentRequired,
})

export const serviceProvidersFormToParams = reduce(
  (acc, { employees, price }) => [
    ...acc,
    ...reduce((memo, providerId) => [...memo, { user_profile_id: providerId, price }], [], employees),
  ],
  [],
)
