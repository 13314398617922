export const SERVICE_NAME_MAX_CHARS = 255
export const SERVICE_DESCRIPTION_MAX_CHARS = 2000
export const SERVICE_DESCRIPTION_MIN_ROWS = 1
export const SERVICE_DESCRIPTION_MAX_ROWS = 2

export const FREE_PRICE = '0.0'
export const PRICE_OPTIONS = [
  { key: 'free', value: true, label: { id: 'scheduleAppointment.priceSelect.free' } },
  { key: 'paid', value: false, label: { id: 'scheduleAppointment.priceSelect.paid' } },
]

export const DEFAULT_BOOKING_SETTING_PARAMS = {
  payment_required: false,
}

export const RECURRING_BOOKING_REPEAT_COUNT_MIN = 1
export const RECURRING_BOOKING_REPEAT_COUNT_MAX = 50
