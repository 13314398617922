import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.SET_TRASHED_FILE_IDS:
      return action.ids
    case types.ADD_TRASHED_FILE_IDS:
      return uniq([...state, ...action.ids])
    case types.RESET_TRASHED_FILE_IDS:
      return []
    case types.REMOVE_TRASHED_FILE_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_BLANK_STATE:
      return action.isBlankState
    case types.RESET_TRASHED_FILE_IDS:
      return true
    default:
      return state
  }
}

const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SELECTED_IDS:
      return action.trashedFileIds
    case types.RESET_TRASHED_FILE_IDS:
      return []
    case types.REMOVE_TRASHED_FILE_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
  types: [],
  trashed: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isBlankState,
  selectedIds,
  filters,
})
