import * as types from './types'

export const setSubdomain = (subdomain, workspaceId) => ({
  type: types.SET_SUBDOMAIN,
  subdomain,
  workspaceId,
})

export const resetSubdomain = () => ({
  type: types.RESET_SUBDOMAIN,
})
