import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { servicesSettingsRoute } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_SERVICE_SETTINGS } from 'state/concepts/service/types'
import { serviceSettingsFormToParams } from 'utils/services/serviceFormToParams'

const updateServiceSettingsOperation = createLogic({
  type: UPDATE_SERVICE_SETTINGS,
  latest: true,

  async process(
    {
      action: {
        values,
        form,
        componentInitialProps: { serviceId },
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    try {
      const state = getState()
      const params = serviceSettingsFormToParams(values)
      const url = servicesSettingsRoute(serviceId)

      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'serviceSetting', values.id, {
        attributes: {
          schedulingRule: params.scheduling_rule,
          schedulingInterval: params.scheduling_interval,
          paymentOption: params.payment_option,
          consultantCanRecordVideo: params.consultant_can_record_video,
          paymentRequired: params.payment_required,
          group: params.group,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.serviceUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)

    done()
  },
})

export default updateServiceSettingsOperation
