const services = {
  'servicesFilters.categories.title': 'By category ({count})',
  'servicesFilters.categories': '{option}',

  'services.title': '_(Services)',
  'services.bulkActivationAlertError': 'Some _(services) couldn’t be activated.',
  'services.notServicesYet': 'There are no _(services) yet.',
  'services.addService': 'Add _(service)',
  'services.selectedCount': '{count, number} {count, plural, one {service is} other {services are}} selected',
  'services.allServices': 'All _(services)',
  'services.assignExpert.modal.title': 'Assign _(Expert)',
  'services.assignExperts.modal.subtitle': 'Select _(experts) to assign to:',
  'services.openMenuToAssign': 'Open this menu to assign team members to this _(service)',
  'services.manageCategories': 'Manage categories',

  'services.assignExpertDropdown.customService': 'Custom _(service)',
  'services.assignExpertDropdown.ownerOrAdmin.thereAreNo':
    'There are no _(services) in your workspace. {addServiceLink}',
  'services.assignExpertDropdown.ownerOrAdmin.addService': '+ Add _(service)',
  'services.assignExpertDropdown.expert':
    'You have no assigned _(services). Please contact the workspace administrator to help',

  'servicesTable.service': '_(Service)',
  'servicesTable.category': 'Category',
  'servicesTable.expert': '_(Expert)',
  'servicesTable.status': 'Status',

  'servicesActions.deactivate': 'Deactivate',
  'servicesActions.activate': 'Activate',
  'servicesActions.assignEmployee': 'Assign _(Expert)',
  'servicesActions.remove': 'Remove',
  'servicesActions.assignEmployees': 'Assign _(Experts)',

  'servicesActivateModal.title': 'Activate _(service)',
  'servicesActivateModal.body': 'Are you sure you want to activate the {name}?',
  'servicesActivateModal.body.alert':
    'Make the _(service) available online, or assign active offline location, to activate it.',

  'servicesActivateWarningModal.title': 'Activate _(service)',
  'servicesActivateWarningModal.body':
    '{count, plural, one {This _(service) belongs to the deactivated category.} other {{count, number} _(services) belong to the deactivated category(ies).}}',
  'servicesActivateWarningModal.warning':
    '{count, plural, one {Change its category or activate the category first.} other {Change or activate the category(ies) first.}}',

  'servicesDeactivateModal.title': 'Deactivate _(service)',
  'servicesDeactivateModal.body.start': 'Are you sure you want to deactivate the ',
  'servicesDeactivateModal.body.boldText': '{name}',
  'servicesDeactivateModal.body.final': '?',
  'servicesDeactivateModal.warning':
    'Active _(appointments) will remain but _(clients) will not be able to book this _(service).',
  'servicesRemoveModal.title': 'Remove _(service)',
  'servicesRemoveModal.body.start': 'Are you sure you want to remove ',
  'servicesRemoveModal.body.boldText': '{name}',
  'servicesRemoveModal.body.final': '?',
  'servicesRemoveModal.warning':
    'Scheduled _(appointments) will remain but _(clients) will not be able to book this _(service)',
  'servicesBulkRemoveModal.title': 'Remove _(services)',
  'servicesBulkRemoveModal.body.start': 'Are you sure you want to remove ',
  'servicesBulkRemoveModal.body.boldText': '{count, number} {count, plural, one {_(service)} other {_(services)}}',
  'servicesBulkRemoveModal.body.final': '?',

  'servicesBulkActivateModal.title': 'Activate _(services)',
  'servicesBulkActivateModal.body':
    'Are you sure you want to activate {count, number} {count, plural, one {_(service)} other {_(services)}}?',
  'servicesBulkDeactivateModal.title': 'Deactivate _(services)',
  'servicesBulkDeactivateModal.body.start': 'Are you sure you want to deactivate ',
  'servicesBulkDeactivateModal.body.boldText': '{count, number} {count, plural, one {_(service)} other {_(services)}}',
  'servicesBulkDeactivateModal.body.final': '?',
  'servicesBulkDeactivateModal.warning':
    'Active _(appointments) will remain but _(clients) will not be able to book {count, plural, one {this _(service)} other {these services}}.',
}

export default services
