import { createSelector } from '@reduxjs/toolkit'
import { any, filter, isEmpty, map, path, pipe, prop, propEq } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector, totalCountSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import {
  getServiceCategoriesEndpoint,
  activateServiceCategoriesEndpoint,
  deactivateServiceCategoriesEndpoint,
  removeServiceCategoriesEndpoint,
} from 'state/concepts/serviceCategories/endpoints'

export const isBlankStateSelector = path(['serviceCategories', 'isBlankState'])

export const serviceCategoriesForFilterSelector = createSelector(
  makeSelectSearchResults('serviceCategories', 'serviceCategory'),
  map(({ id, name }) => ({ key: id, value: id, label: name })),
)

export const serviceCategoriesIdsSelector = path(['serviceCategories', 'serviceCategoriesIds'])

export const serviceCategoriesSelector = createSelector([serviceCategoriesIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'serviceCategory', ids) : [],
)

export const paginationSelector = path(['serviceCategories', 'pagination'])

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const sortSelector = path(['serviceCategories', 'sort'])

export const sortParamsSelector = createSelector(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const filtersSelector = path(['serviceCategories', 'filters'])

export const searchQuerySelector = pipe(filtersSelector, prop('name'))

export const appliedFiltersSelector = createSelector(filtersSelector, ({ name, status }) => ({
  name,
  status: status === 'all' ? '' : status,
}))

export const selectedFilterSelector = createSelector(appliedFiltersSelector, prop('status'))

export const selectedSelector = path(['serviceCategories', 'selectedServiceCategories'])

export const selectedServiceCategoriesSelector = createSelector([selectedSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'serviceCategory', ids) : [],
)

export const selectedServiceCategoriesWithServicesSelector = createSelector(
  selectedServiceCategoriesSelector,
  serviceCategories => filter(category => category.servicesCount > 0, serviceCategories),
)

export const isSpecificServiceCategoriesSelected = createSelector(
  [selectedServiceCategoriesSelector, (_, key) => key],
  (serviceCategories, key) => any(propEq(key, 'status'), serviceCategories),
)

export const serviceCategoriesLoadingSelector = state => loadingSelector(state, getServiceCategoriesEndpoint.endpoint)

export const serviceCategoriesTotalCountSelector = state =>
  totalCountSelector(state, getServiceCategoriesEndpoint.endpoint)

export const activateServiceCategoriesLoadingSelector = state =>
  loadingSelector(state, activateServiceCategoriesEndpoint.endpoint)
export const deactivateServiceCategoriesLoadingSelector = state =>
  loadingSelector(state, deactivateServiceCategoriesEndpoint.endpoint)
export const removeServiceCategoriesLoadingSelector = state =>
  loadingSelector(state, removeServiceCategoriesEndpoint.endpoint)
