import { always, cond, equals, anyPass, T } from 'ramda'

import {
  UPCOMING_BOOKINGS,
  CANCELLED_BOOKINGS,
  PAST_BOOKINGS,
  BOOKINGS_TYPES,
  PENDING_BOOKINGS,
} from '../lib/constants/clientAccountBookings'

export const isUpcoming = equals(BOOKINGS_TYPES.upcoming)

export const isPending = equals(BOOKINGS_TYPES.pending)

export const isPast = equals(BOOKINGS_TYPES.past)

export const isCancelled = equals(BOOKINGS_TYPES.cancelled)

export const isWaitlist = equals(BOOKINGS_TYPES.waitlist)

export const bookingsTypeTranslation = cond([
  [isUpcoming, always(BOOKINGS_TYPES.upcoming)],
  [isPast, always(BOOKINGS_TYPES.past)],
  [isCancelled, always(BOOKINGS_TYPES.cancelled)],
  [isPending, always(BOOKINGS_TYPES.pending)],
])

export const filtersByType = cond([
  [
    isPending,
    always({
      past: undefined,
      statuses: PENDING_BOOKINGS,
      bookingsType: BOOKINGS_TYPES.pending,
    }),
  ],
  [
    isUpcoming,
    always({
      past: false,
      statuses: UPCOMING_BOOKINGS,
      bookingsType: BOOKINGS_TYPES.upcoming,
    }),
  ],
  [
    isPast,
    always({
      past: true,
      statuses: PAST_BOOKINGS,
      bookingsType: BOOKINGS_TYPES.past,
    }),
  ],
  [
    isCancelled,
    always({
      past: undefined,
      statuses: CANCELLED_BOOKINGS,
      bookingsType: BOOKINGS_TYPES.cancelled,
    }),
  ],
  [T, always({})],
])

export const sortOrderByType = cond([
  [anyPass([isUpcoming, isPending]), always({ sortKey: 'start_time', direction: 'asc' })],
  [anyPass([isPast, isCancelled]), always({ sortKey: 'start_time', direction: 'desc' })],
  [T, always({})],
])
