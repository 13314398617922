import * as types from './types'

export const setIsExpanded = isExpanded => ({
  type: types.SET_IS_EXPANDED,
  isExpanded,
})

export const setIsCompleted = isCompleted => ({
  type: types.SET_IS_COMPLETED,
  isCompleted,
})

export const skipStep = step => ({
  type: types.SKIP_STEP,
  step,
})

export const finishTutorial = () => ({
  type: types.FINISH_TUTORIAL,
})

export const updateOnboardingContent = page => ({
  type: types.UPDATE_ONBOARDING_CONTENT,
  page,
})

export const updateClientOnboardingContent = page => ({
  type: types.UPDATE_CLIENT_ONBOARDING_CONTENT,
  page,
})

export const updateUserOnboardingContent = page => ({
  type: types.UPDATE_USER_ONBOARDING_CONTENT,
  page,
})
