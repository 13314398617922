import { createLogic } from 'redux-logic'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import { COOKIES_DOMAIN } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import { clientLoginRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import removeClientCookies from 'utils/removeClientCookies'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { DELETE_CLIENT_OWN_ACCOUNT } from 'state/concepts/client/types'
import { deleteClientOwnAccountEndpoint } from 'state/concepts/client/endpoints'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const deleteClientOwnAccountOperation = createLogic({
  type: DELETE_CLIENT_OWN_ACCOUNT,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const { url } = deleteClientOwnAccountEndpoint
    const { uniqCode } = workspaceSelector(getState())
    const params = {
      code: values.confirmationCode,
    }

    try {
      await httpClient.delete(url, { params })

      dispatch(hideModal())

      removeClientCookies({ path: `/client/${uniqCode}` })
      removeClientCookies({ domain: COOKIES_DOMAIN, path: `/client/${uniqCode}` })
      removeClientCookies({ path: COOKIES_PATH })

      await redirect({ href: clientLoginRoute })

      dispatch(showNotification({ messageObject: { id: 'notifications.clientAccountDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default deleteClientOwnAccountOperation
