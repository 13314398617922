import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import Alert from 'views/shared/Alert'

const BodyText = ({ isBold, isAlert, ...rest }) => {
  if (isAlert) return <Alert {...rest} />

  return (
    <span className={isBold ? 'font-600' : undefined} data-cy="modal-text">
      <FormattedMessage {...rest} />
    </span>
  )
}

BodyText.defaultProps = {
  isBold: false,
  isAlert: false,
}

BodyText.propTypes = {
  isBold: PropTypes.bool,
  isAlert: PropTypes.bool,
}

export default BodyText
