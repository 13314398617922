import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import redirect from 'utils/redirect'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { guestLinkExpiredRoute, guestPaymentCheckoutRoute, guestWaitlistAcceptSuccessRoute } from 'lib/routes'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { bookAppointmentFromWaitlistRequestEndpoint } from '../endpoints'
import { BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST } from '../types'

const bookAppointmentFromWaitlistRequestOperation = createLogic({
  type: BOOK_APPOINTMENT_FROM_WAITLIST_REQUEST,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const { url, endpoint } = bookAppointmentFromWaitlistRequestEndpoint
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, null, config)
      const response = normalize(data)
      const paymentId = data.data.attributes['payment-id']
      const paymentRequired = data.data.attributes['payment-required']

      redirect({
        href: paymentRequired
          ? guestPaymentCheckoutRoute(workspaceCode, paymentId)
          : guestWaitlistAcceptSuccessRoute(workspaceCode),
        query: {
          redirectTo: paymentRequired ? guestWaitlistAcceptSuccessRoute(workspaceCode) : undefined,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute })
    }
    done()
  },
})

export default bookAppointmentFromWaitlistRequestOperation
