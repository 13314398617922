import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import { WEBSITE_BACKGROUND_IMAGES } from 'lib/constants/website'
import Tick from 'views/shared/icons/Tick'

const BackgroundImage = ({ value, type, onSelect }) => {
  const isActive = type === value
  const handleSelectImage = () => onSelect(type)

  return (
    <button
      className={isActive ? 'ws-header__bg-preview-item-active' : 'ws-header__bg-preview-item'}
      onClick={handleSelectImage}
      data-cy={`background-image-button-${type}`}
    >
      {isActive && <Tick className="ws-header__bg-preview-item-icon" />}
      <div className="ws-header__bg-preview-item">
        <Image src={WEBSITE_BACKGROUND_IMAGES[type]} alt={`Background image ${type}`} width={90} height={90} />
      </div>
    </button>
  )
}

BackgroundImage.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default BackgroundImage
