import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_ACCESS_TO_CLIENT } from 'state/concepts/employee/types'
import assignFormErrors from 'utils/form/assignFormErrors'
import { validateAccessToClientEndpoint } from '../endpoints'

const validateAccessToClientOperation = createLogic({
  type: VALIDATE_ACCESS_TO_CLIENT,
  latest: true,

  async process({ action: { userProfileId, clientProfileId, form }, httpClient }, dispatch, done) {
    const { endpoint, url } = validateAccessToClientEndpoint

    try {
      const params = {
        user_profile_id: userProfileId,
        client_profile_id: clientProfileId,
      }
      await httpClient.get(url, { params })
      form.setStatus(null)
    } catch (error) {
      assignFormErrors(form, error, ['base'])
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default validateAccessToClientOperation
