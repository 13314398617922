import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { removeClientForm } from '../actions'
import { deleteClientFormEndpoint } from '../endpoints'
import { DELETE_CLIENT_FORM } from '../types'

const deleteClientForm = createLogic({
  type: DELETE_CLIENT_FORM,
  latest: true,

  async process({ action: { clientFormId, feedId }, httpClient }, dispatch, done) {
    const { url, endpoint } = deleteClientFormEndpoint(clientFormId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeClientForm(clientFormId))
      feedId && dispatch(removeClientFeedIds([feedId]))
      dispatch(dataDelete({ kind: 'clientProfileForm', ids: [clientFormId] }))

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.intakeFormDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteClientForm
