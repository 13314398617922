import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { FormattedMessage } from 'react-intl'

import ProfileIdentity from 'views/shared/ProfileIdentity'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import arrayHelpers from './arrayHelpers'

const AssignServicesModalComponent = ({ onClose, handleSubmit, employee }) => (
  <Modal className="main-modal main-modal--lg" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employees.assignServices.modal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p data-cy="employees-assign-services-modal-subtitle">
          <FormattedMessage id="employees.assignServices.modal.subtitle" />
        </p>
        <div className="mb-16">
          <div className="main-tag main-tag--light">
            <span className="mr-4 va-middle" data-cy="assign-expert-fullname">
              <ProfileIdentity user={employee} />
            </span>
          </div>
        </div>
        <FieldArray name="provision">{arrayHelpers(onClose)}</FieldArray>
      </div>
      <div className="main-modal__footer">
        <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
        <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.assign' }} onClick={handleSubmit} />
      </div>
    </div>
  </Modal>
)

AssignServicesModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
}

export default AssignServicesModalComponent
