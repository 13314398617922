import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { mergeDeepRight, pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { CREATE_TASK } from 'state/concepts/tasks/types'
import { addCreatedTaskId, setIsCreatingTask, incrementTasksCount } from 'state/concepts/tasks/actions'
import { createTaskEndpoint } from 'state/concepts/tasks/endpoints'
import { getTaskCounter } from 'utils/tasks'
import { onCreate } from 'utils/attributesForSortable'
import updateDataHelper from 'utils/updateDataHelper'
import { UNASSIGNED_ID } from 'lib/constants/tasks'
import { tasksOrderedSelector } from '../selectors'

const createTaskOperation = createLogic({
  type: CREATE_TASK,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const state = getState()
    const tasks = tasksOrderedSelector(state)
    const { endpoint, url } = createTaskEndpoint
    const { dueDate, name, clientProfileId, assigneeId } = values
    const params = {
      include: ['user-profile', 'client-profile', 'assignee'],
      due_date: dueDate,
      name,
      client_profile_id: clientProfileId,
      assignee_id: assigneeId !== UNASSIGNED_ID ? assigneeId : undefined,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)

      const apiResponse = normalize(data, { endpoint })

      const taskIds = pluck('id', tasks)
      const updatedResponse = updateDataHelper(state.data, 'task', taskIds, onCreate(tasks))

      dispatch(dataApiSuccess({ response: mergeDeepRight(updatedResponse, apiResponse), endpoint }))
      dispatch(incrementTasksCount(getTaskCounter({ dueDate })))
      dispatch(addCreatedTaskId(data.data.id))
      dispatch(setIsCreatingTask(false))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default createTaskOperation
