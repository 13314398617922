const chatFeature = {
  'chatFeature.title': 'Chat',
  'chatFeature.subtitle': '<span>in</span> Messenger',
  'chatFeature.description': 'Stay in touch with your team and _(clients).',
  'chatFeature.manage': 'Go to chats',
  'chatFeature.overview': 'Chat overview',
  'chatFeature.subheader': 'Create chats and send messages and files to your team members and _(clients) at any time.',
  'chatFeature.provideSupport': 'Provide constant support to _(clients):',
  'chatFeature.reachYourClients': 'Instantly reach your _(clients) and address their concerns.',
  'chatFeature.shareInformation': 'Share important information and files at any time.',
  'chatFeature.communicateEfficiently': 'Communicate efficiently:',
  'chatFeature.communicationInOnePlace': 'Keep all work communication in one place.',
  'chatFeature.useVoiceMessaging':
    'Use voice messaging to quickly respond to your _(clients)’ needs instead of writing a lengthy message or having yet another _(appointment).',
  'chatFeature.protectYourPrivacy': 'Protect your privacy:',
  'chatFeature.GDPR': 'Comply with GDPR and HIPAA requirements.',
  'chatFeature.SSL': 'Use encrypted SSL connections for secure communication.',
  'chatFeature.toLearnMores':
    'To learn more, read our help center articles on <howChatsWorksLink>how ExpertBox chats work</howChatsWorksLink> and on <creatingChatsLink>creating chats</creatingChatsLink> and <joiningChatsLink>joining chats</joiningChatsLink>.',
  'chatFeature.disableChat': 'Disable Chat feature?',
  'chatFeature.disableChatWarning':
    'All the chats will be deleted forever for everybody in the workspace. You won’t be able to send messages to your team members and _(clients) anymore.',
}

export default chatFeature
