import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'

import { REVIEW_STATUSES } from 'lib/constants/reviews'

import {
  SET_REVIEWS,
  SET_REVIEWS_PAGE,
  SET_REVIEWS_SORT_ORDER,
  SET_REVIEWS_FILTER_PARAMS,
  SET_REVIEWS_BLANK_STATE,
  RESET_REVIEWS,
  RESET_FILTERS,
} from './types'

const reviewIds = (state = [], action) => {
  switch (action.type) {
    case SET_REVIEWS:
      return uniq([...state, ...action.reviewIds])
    case RESET_REVIEWS:
      return []
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_REVIEWS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_REVIEWS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const sort = (state = { sortKey: 'updated_at', direction: 'desc' }, action) => {
  switch (action.type) {
    case SET_REVIEWS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' }
    default:
      return state
  }
}

const filtersInitialState = {
  name: '',
  statuses: REVIEW_STATUSES.all,
  employees: [],
  services: [],
  ratings: [],
  dateRange: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_REVIEWS_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return {
        ...filtersInitialState,
        name: state.name,
        statuses: state.statuses,
      }
    default:
      return state
  }
}

export default combineReducers({
  reviewIds,
  isBlankState,
  pagination,
  sort,
  filters,
})
