import { FormattedMessage } from 'react-intl'

import compact from 'utils/compact'
import CalendarO from 'views/shared/icons/CalendarO'
import CheckedSquare from 'views/shared/icons/CheckedSquare'
import CircleChecked from 'views/shared/icons/CircleChecked'
import DropdownO from 'views/shared/icons/DropdownO'
import Duration from 'views/shared/icons/Duration'
import Paragraph from 'views/shared/icons/Paragraph'
import Text from 'views/shared/icons/Text'
import Time from 'views/shared/icons/Time'
import Title from 'views/shared/icons/Title'
import Upload from 'views/shared/icons/Upload'
import UserAltO from 'views/shared/icons/UserAltO'
import { DECLINED_BY_TYPES, FORM_STATUSES, RESPONDENT_TYPES } from './forms'

export const MAX_INTAKE_FORMS_COUNT = 100
export const MAX_QUESTION_OPTIONS_COUNT = 100
export const MAX_SERVICE_DOCUMENTS_COUNT = 200
export const NAME_MAX_LENGTH = 100
export const DESCRIPTION_MAX_LENGTH = 1000
export const QUESTION_MAX_LENGTH = 1000
export const MAX_QUESTIONS_COUNT = 100
export const OPTION_MAX_LENGTH = 250
export const EXPIRY_PERIOD_MIN = 1
export const EXPIRY_PERIOD_MAX = 999

export const FORM_AUTOMATION_FREQUENCIES = {
  perClient: 'per_client',
  perAppointment: 'per_appointment',
}
export const FORM_FREQUENCY_OPTIONS = [
  {
    value: FORM_AUTOMATION_FREQUENCIES.perClient,
    label: { id: 'company.forms.frequency.oncePerClient' },
    description: { id: 'company.forms.frequency.oncePerClient.description' },
  },
  {
    value: FORM_AUTOMATION_FREQUENCIES.perAppointment,
    label: { id: 'company.forms.frequency.oncePerAppointment' },
    description: { id: 'company.forms.frequency.oncePerAppointment.description' },
  },
]
export const AGREEMENT_MAX_LENGTH = 50000
export const QUESTION_TYPES = {
  text: 'text',
  agreement: 'agreement',
  subtitle: 'subtitle',
  paragraph: 'paragraph',
  radioButton: 'radio_button',
  checkBox: 'check_box',
  date: 'date',
  file: 'file',
  dropdown: 'dropdown',
  duration: 'duration',
  time: 'time',
  crm: 'crm',
}

export const QUESTIONS_MAX_LENGTH = {
  [QUESTION_TYPES.text]: 1000,
  [QUESTION_TYPES.crm]: 1000,
  [QUESTION_TYPES.subtitle]: 255,
  [QUESTION_TYPES.paragraph]: 10000,
  [QUESTION_TYPES.radioButton]: 1000,
  [QUESTION_TYPES.checkBox]: 1000,
  [QUESTION_TYPES.date]: 1000,
  [QUESTION_TYPES.file]: 1000,
  [QUESTION_TYPES.dropdown]: 1000,
  [QUESTION_TYPES.time]: 1000,
  [QUESTION_TYPES.duration]: 1000,
}

export const QUESTION_TYPES_ICONS = {
  [QUESTION_TYPES.text]: Text,
  [QUESTION_TYPES.radioButton]: CircleChecked,
  [QUESTION_TYPES.checkBox]: CheckedSquare,
  [QUESTION_TYPES.date]: CalendarO,
  [QUESTION_TYPES.file]: Upload,
  [QUESTION_TYPES.dropdown]: DropdownO,
  [QUESTION_TYPES.time]: Time,
  [QUESTION_TYPES.duration]: Duration,
}

export const getQuestionTypeOptions = respondent =>
  compact([
    {
      value: QUESTION_TYPES.text,
      key: QUESTION_TYPES.text,
      content: (
        <div className="d-flex align-items-center">
          <Text size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.text.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.file,
      key: QUESTION_TYPES.file,
      content: (
        <div className="d-flex align-items-center">
          <Upload size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.file.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.date,
      key: QUESTION_TYPES.date,
      content: (
        <div className="d-flex align-items-center">
          <CalendarO size={20} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.date.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.time,
      key: QUESTION_TYPES.time,
      content: (
        <div className="d-flex align-items-center">
          <Time size={20} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.time.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.duration,
      key: QUESTION_TYPES.duration,
      content: (
        <div className="d-flex align-items-center">
          <Duration size={20} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.duration.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.radioButton,
      key: QUESTION_TYPES.radioButton,
      content: (
        <div className="d-flex align-items-center">
          <CircleChecked size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.radioButton.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.checkBox,
      key: QUESTION_TYPES.checkBox,
      content: (
        <div className="d-flex align-items-center">
          <CheckedSquare size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.checkBox.name" />
        </div>
      ),
    },
    {
      value: QUESTION_TYPES.dropdown,
      key: QUESTION_TYPES.dropdown,
      content: (
        <div className="d-flex align-items-center">
          <DropdownO size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.dropdown.name" />
        </div>
      ),
    },
    respondent === RESPONDENT_TYPES.client
      ? {
          value: QUESTION_TYPES.crm,
          key: QUESTION_TYPES.crm,
          content: (
            <div className="d-flex align-items-center">
              <UserAltO size={18} className="mr-12 icon in-blue-600" />
              <FormattedMessage id="company.forms.questionTypes.crm.name" />
            </div>
          ),
        }
      : null,
    {
      value: QUESTION_TYPES.subtitle,
      key: QUESTION_TYPES.subtitle,
      className: 'relative mt-8',
      content: (
        <>
          <div className="divider--in-option" />
          <div className="d-flex align-items-center">
            <Title size={18} className="mr-12 icon in-blue-600" />
            <FormattedMessage id="company.forms.questionTypes.subtitle.name" />
          </div>
        </>
      ),
    },
    {
      value: QUESTION_TYPES.paragraph,
      key: QUESTION_TYPES.paragraph,
      content: (
        <div className="d-flex align-items-center">
          <Paragraph size={18} className="mr-12 icon in-blue-600" />
          <FormattedMessage id="company.forms.questionTypes.paragraph.name" />
        </div>
      ),
    },
  ])

export const MAX_FILE_SIZE = 10 * 1024 * 1024

export const MAX_FILES_TO_UPLOAD = 10

export const CLIENT_IMPORT_FIRST_NAME_ID = 'first_name_title'
export const CLIENT_IMPORT_LAST_NAME_ID = 'last_name_title'
export const CLIENT_IMPORT_EMAIL_ID = 'email_title'

export const DEFAULT_PROFILE_FIELDS = [
  { id: CLIENT_IMPORT_FIRST_NAME_ID, name: { id: 'clients.import.steps.match.profileFields.firstName' } },
  { id: CLIENT_IMPORT_LAST_NAME_ID, name: { id: 'clients.import.steps.match.profileFields.lastName' } },
  { id: CLIENT_IMPORT_EMAIL_ID, name: { id: 'clients.import.steps.match.profileFields.email' } },
]

export const STATUS_LOCALE_MAP = {
  [FORM_STATUSES.pending]: {
    [RESPONDENT_TYPES.expert]: 'waitingExpertResponse',
    [RESPONDENT_TYPES.client]: 'waitingClientResponse',
  },
  [FORM_STATUSES.declined]: {
    [DECLINED_BY_TYPES.expert]: 'declinedByExpert',
    [DECLINED_BY_TYPES.client]: 'declinedByClient',
    [DECLINED_BY_TYPES.clientContact]: 'declinedByClient',
  },
}
