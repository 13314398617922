const bookingPageFeature = {
  'bookingPageFeature.subtitle': '<span>in</span> Booking',
  'bookingPageFeature.description': 'Get a booking link to share with _(clients) and get booked online.',
  'bookingPageFeature.manageFeatures': 'Set up booking pages',
  'bookingPageFeature.overview': 'Booking page overview',
  'bookingPageFeature.subheader':
    'With an ExpertBox booking page, you can start filling your schedule in a few clicks.',
  'bookingPageFeature.easilySetUp':
    'Easily set up your booking page to let _(clients) book _(appointments) with you 24/7:',
  'bookingPageFeature.chooseServices': 'Choose the _(services) and _(experts) you want to promote.',
  'bookingPageFeature.tailorBookingPanel':
    'Tailor your booking panel to reflect your style by setting its primary color.',
  'bookingPageFeature.customizeLink': 'Customize your booking link.',
  'bookingPageFeature.shareBookingPage': 'Share your booking page easily:',
  'bookingPageFeature.letClientsToBook':
    'Let _(clients) book _(appointments) even if you don’t have your own website with our <b>booking.expertbox.io</b> domain.',
  'bookingPageFeature.copyLink':
    'Copy and share a booking link with _(clients) in any convenient way: email, social media, website, etc.',
  'bookingPageFeature.streamline': 'Streamline the booking experience:',
  'bookingPageFeature.showcaseYourExpertise':
    'Showcase your expertise by adding information about _(experts) and _(services).',
  'bookingPageFeature.letClientsSelectMeeting':
    'Let _(clients) select a meeting time based on your team’s availability and booking rules.',
  'bookingPageFeature.transparentPrices': 'Be transparent about your prices.',
  'bookingPageFeature.getPaid': 'Get paid instantly with online payments.',
  'bookingPageFeature.getNotified': 'Get notified about every new booking.',
  'bookingPageFeature.summary': 'Get more bookings with less effort with ExpertBox booking pages.',
  'bookingPageFeature.toLearnMores':
    'To learn more, read our help center articles on how to <setUpLink>set up online bookings</setUpLink> and <shareLink>share a booking page link with your _(clients)</shareLink>.',
}

export default bookingPageFeature
