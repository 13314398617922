import PropTypes from 'prop-types'
import { Select } from 'antd'
import { keys } from 'ramda'
import noop from 'lodash/noop'
import classNames from 'clsx'

import isPresent from 'utils/isPresent'
import SelectField from 'views/shared/SelectField'
import NotFoundContent from './NotFoundContent'
import OptionLabel from './OptionLabel'
import OptionContent from './OptionContent'
import OptionCustomService from './OptionCustomService'
import Loader from './Loader'

const DropdownServiceFieldComponent = ({
  isLoading,
  services,
  servicesOptions,
  mode,
  handleSearch,
  handleDropdownVisibleChange,
  handleServiceCreateCallback,
  isOpen,
  customService,
  customServiceSelected,
  searchQuery,
  excludeServices,
  userProfileId,
  statuses,
  ...restProps
}) => (
  <SelectField
    {...restProps}
    onSearch={handleSearch}
    optionLabelProp="label"
    showSearch
    allowClear
    filterOption={false}
    notFoundContent={<NotFoundContent isLoading={isLoading} onServiceCreateCallback={handleServiceCreateCallback} />}
    onDropdownVisibleChange={handleDropdownVisibleChange}
    open={isOpen}
    mode={mode}
  >
    {!isLoading && customService && (
      <Select.Option
        className={classNames({ 'ant-select-item-option-selected': customServiceSelected })}
        label={<OptionLabel service={customService} mode={mode} />}
        title={customService.name}
        value={customService.id}
        key={customService.id}
      >
        <OptionCustomService service={customService} />
      </Select.Option>
    )}
    {keys(servicesOptions).map(categoryName => (
      <Select.OptGroup label={categoryName} key={categoryName}>
        {servicesOptions[categoryName].map(service => (
          <Select.Option
            label={<OptionLabel service={service} mode={mode} />}
            title={service.name}
            value={service.id}
            key={service.id}
          >
            <OptionContent service={service} />
          </Select.Option>
        ))}
      </Select.OptGroup>
    ))}

    {isPresent(services) && (
      <Select.Option
        disabled
        className={classNames({ 'visibility-hidden min-h-a p-0': isLoading === false })}
        key="waypoint"
      >
        <Loader
          searchQuery={searchQuery}
          excludeServices={excludeServices}
          statuses={statuses}
          userProfileId={userProfileId}
          isLoading={isLoading}
        />
      </Select.Option>
    )}
  </SelectField>
)

DropdownServiceFieldComponent.defaultProps = {
  isLoading: undefined,
  mode: 'default',
  handleServiceCreateCallback: noop,
  customService: undefined,
  customServiceSelected: undefined,
  excludeServices: null,
  userProfileId: undefined,
  statuses: [],
  searchQuery: '',
}

DropdownServiceFieldComponent.propTypes = {
  servicesOptions: PropTypes.shape().isRequired,
  services: PropTypes.shape().isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDropdownVisibleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
  handleServiceCreateCallback: PropTypes.func,
  customService: PropTypes.shape(),
  customServiceSelected: PropTypes.bool,
  searchQuery: PropTypes.string,
  excludeServices: PropTypes.arrayOf(PropTypes.string),
  userProfileId: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
}

export default DropdownServiceFieldComponent
