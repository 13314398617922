import { is } from 'ramda'

import { HEX_COLOR_REGEX, SELECTOR_REGEX } from 'lib/constants'

export function isHexColor() {
  return this.test('isHexColor', { id: 'yup.string.isHexColor' }, value => {
    if (!is(String, value)) return true

    return HEX_COLOR_REGEX.test(value)
  })
}

export function isSelector() {
  return this.test('isSelector', { id: 'yup.string.isSelector' }, value => {
    if (!is(String, value)) return true

    return SELECTOR_REGEX.test(value)
  })
}
