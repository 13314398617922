import { createSelector } from '@reduxjs/toolkit'
import compact from 'lodash/compact'
import {
  any,
  compose,
  filter,
  flatten,
  groupBy,
  isEmpty,
  length,
  map,
  omit,
  path,
  pathEq,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  values,
} from 'ramda'
import build from 'redux-object'

import { FORM_TYPES } from 'lib/constants/forms'
import { CATEGORY_STATUSES } from 'lib/constants/serviceCategories'
import { clientBookingAvailabilityEndpoint } from 'state/concepts/booking/endpoints'
import {
  activateServiceEndpoint,
  deactivateServiceEndpoint,
  removeServicesEndpoint,
} from 'state/concepts/services/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import buildCollection from 'utils/buildCollection'
import groupServiceByCategory from 'utils/services/groupServiceByCategory'

export const serviceIdsSelector = path(['services', 'serviceIds'])

export const isBlankStateSelector = path(['services', 'isBlankState'])

export const servicesSelector = createSelector([serviceIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'service', ids) : [],
)

export const paginationSelector = path(['services', 'pagination'])

export const notActivatedCountSelector = path(['services', 'notActivatedServicesCount'])

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const sortSelector = path(['services', 'sort'])

export const sortParamsSelector = createSelector(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const filtersSelector = path(['services', 'filters'])

export const searchQuerySelector = pipe(filtersSelector, prop('name'))

export const selectedSelector = path(['services', 'selectedServices'])

export const appliedFilters = createSelector(filtersSelector, ({ name, status, categories }) => ({
  name,
  statuses: status === 'all' ? [] : [status],
  service_category_ids: categories,
}))

export const selectedFiltersSelector = createSelector(appliedFilters, pipe(omit(['name']), values, flatten))

export const serviceDetailsSelector = createSelector([dataSelector, (_, serviceId) => serviceId], (data, serviceId) =>
  build(data, 'service', serviceId),
)

export const serviceStatusSelector = createSelector(serviceDetailsSelector, prop('status'))

export const serviceSettingsSelector = createSelector(serviceDetailsSelector, prop('serviceSetting'))

export const formsUuidsByIdsSelector = createSelector(
  [(_, ids) => ids, dataSelector],
  pipe(buildCollection('form'), pluck('formUuid')),
)

export const serviceFormsSelector = pipe(serviceDetailsSelector, propOr([], 'forms'))

export const serviceAgreementIdsSelector = createSelector(
  serviceFormsSelector,
  pipe(filter(propEq(FORM_TYPES.agreement, 'formType')), pluck('id')),
)

export const serviceIntakeFormIdsSelector = createSelector(
  serviceFormsSelector,
  pipe(filter(propEq(FORM_TYPES.intake, 'formType')), pluck('id')),
)

export const serviceProvisionSelector = createSelector(
  serviceDetailsSelector,
  compose(
    values,
    map(employeeProfiles => {
      const employees = map(path(['userProfile', 'id']), employeeProfiles)
      const price = Number(employeeProfiles[0].price)

      return {
        price,
        key: employees.join(''),
        employees,
        isFreePrice: price === 0,
      }
    }),
    groupBy(prop('price')),
    propOr([], 'userProfilesServices'),
  ),
)

export const selectedServicesSelector = createSelector([selectedSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'service', ids) : [],
)

export const isSpecificServicesSelected = createSelector([selectedServicesSelector, (_, key) => key], (services, key) =>
  any(propEq(key, 'status'), services),
)

export const withDeactivatedCategoryCountSelector = createSelector(
  selectedServicesSelector,
  compose(length, filter(pathEq(CATEGORY_STATUSES.inactive, ['serviceCategory', 'status']))),
)

export const searchedServicesSelector = createSelector(
  makeSelectSearchResults('services', 'service'),
  groupServiceByCategory,
)

export const selectedProvidedServicesSelector = createSelector(
  [dataSelector, (_, serviceIds) => serviceIds],
  (data, serviceIds) => {
    const ids = compact(flatten([serviceIds]))
    if (isEmpty(ids)) {
      return {}
    }

    return groupServiceByCategory(compact(build(data, 'service', ids)))
  },
)

export const clientServiceAvailabilityLoadingSelector = (state, bookingId) =>
  loadingSelector(state, clientBookingAvailabilityEndpoint(bookingId).endpoint)

export const activateServicesLoadingSelector = state => loadingSelector(state, activateServiceEndpoint.endpoint)

export const deactivateServicesLoadingSelector = state => loadingSelector(state, deactivateServiceEndpoint.endpoint)

export const removeServicesLoadingSelector = state => loadingSelector(state, removeServicesEndpoint.endpoint)
