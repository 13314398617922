import { pathOr } from 'ramda'

import { captureException } from 'lib/sentry'

const UNCAPTURED_REQUEST_STATUSES = [401, 403, 410, 422]

const uncapturedStatuses = method => [...UNCAPTURED_REQUEST_STATUSES, ...(method === 'get' ? [404] : [])]

const sentryInterceptor = ctx => async error => {
  const method = pathOr(null, ['response', 'config', 'method'], error)
  const status = pathOr(null, ['response', 'status'], error)

  if (!uncapturedStatuses(method).includes(status)) {
    captureException(error, ctx.isServer ? ctx : null)
  }

  return Promise.reject(error)
}

export default sentryInterceptor
