import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { deactivateServices } from 'state/concepts/services/actions'
import { deactivateServicesLoadingSelector } from 'state/concepts/services/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServicesDeactivateModal = ({ service: { id, name }, onClose }) => {
  const isLoading = useSelector(deactivateServicesLoadingSelector)
  const handleDeactivateService = useDispatchAction(deactivateServices, [id])

  return (
    <ConfirmModal
      title={{ id: 'servicesDeactivateModal.title' }}
      bodyText={[
        [
          { id: 'servicesDeactivateModal.body.start' },
          {
            id: 'servicesDeactivateModal.body.boldText',
            values: { name },
            isBold: true,
          },
          { id: 'servicesDeactivateModal.body.final' },
        ],
        {
          id: 'servicesDeactivateModal.warning',
        },
      ]}
      submitText={{ id: 'servicesActions.deactivate' }}
      onClose={onClose}
      onSubmit={handleDeactivateService}
      isLoading={isLoading}
    />
  )
}

ServicesDeactivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ServicesDeactivateModal
