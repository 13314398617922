/* eslint-disable expbox/custom-name-validation */
const industry = {
  'industry.healthcare': 'Healthcare',
  'industry.financial': 'Financial',
  'industry.sales': 'Sales & Customer Support',
  'industry.training': 'Training & Coaching',
  'industry.education': 'Education',
  'industry.entertainment': 'Entertainment',
  'industry.marketing': 'Marketing',
  'industry.consulting': 'Consulting',
  'industry.legal': 'Legal',
  'industry.insurance': 'Insurance',
  'industry.it': 'Information Technology & Services',
  'industry.communications': 'Public Relations and Communications',
  'industry.hr': 'Human Resources',
  'industry.design': 'Design, Art & Creative',
  'industry.wellness': 'Wellness and Fitness',
  'industry.other': 'Other',
}

export default industry
