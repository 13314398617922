import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { downloadSubscriptionTransaction } from 'state/concepts/billing/actions'
import { subscriptionTransactionsByIdSelector } from 'state/concepts/billing/selectors'
import Modal from 'views/shared/Modal'
import PreviewPdf from 'views/shared/PreviewPdf'
import Cross from 'views/shared/icons/Cross'
import Spinner from 'views/shared/icons/Spinner'

const SubscriptionTransactionPreviewModal = ({ onClose, transactionId }) => {
  const transaction = useSelector(state => subscriptionTransactionsByIdSelector(state, transactionId))

  const downloadInvoice = useDispatchAction(downloadSubscriptionTransaction, transactionId, false)

  useMount(() => {
    if (!transaction?.invoicePdfUrl) downloadInvoice()
  })

  return (
    <Modal className="main-modal main-modal--xl" onClose={onClose}>
      <button className="main-modal__close-fixed" data-cy="button--close" type="button" onClick={onClose}>
        <Cross dataCy="cross-icon" />
      </button>
      {transaction?.invoicePdfUrl ? (
        <PreviewPdf width={720} file={transaction.invoicePdfUrl} />
      ) : (
        <div style={{ height: 100 }}>
          <Spinner isAbsolute />
        </div>
      )}
    </Modal>
  )
}

SubscriptionTransactionPreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
}

export default SubscriptionTransactionPreviewModal
