const taskFeature = {
  'tasksFeature.title': 'Tasks',
  'tasksFeature.subtitle': '<span>in</span> Tasks management',
  'tasksFeature.description': 'Add tasks to manage your work and keep track of the deadlines.',
  'tasksFeature.goToTasks': 'Go to tasks',
  'tasksFeature.overview': 'Tasks overview',
  'tasksFeature.subheader':
    'Improve your workflow, effectively manage your team’s workload, and provide a better experience for your _(clients) with Tasks in ExpertBox.',
  'tasksFeature.manageWorkload': 'Manage your workload:',
  'tasksFeature.createTasks': 'Create and manage tasks to make sure you get things done on time.',
  'tasksFeature.assignTasks': 'Assign tasks to team members and track their progress and status.',
  'tasksFeature.getNotified': 'Get notified when a task is assigned to you or when the due date comes.',
  'tasksFeature.filterTasks':
    'Filter tasks by team member, linked _(client), due date, or status to stay on top of your workload.',
  'tasksFeature.clientSatisfaction': 'Improve _(client) satisfaction:',
  'tasksFeature.linkTasks':
    'Link tasks to specific _(clients) and track related activity directly from _(client) profiles.',
  'tasksFeature.setDeadlines':
    'Make sure you always deliver on your promises to _(clients) by setting deadlines and getting automated reminders on the due date.',
  'tasksFeature.toLearnMores': 'To learn more, read our help center article on <link>managing tasks</link>.',
  'tasksFeature.disableTasks': 'Disable tasks feature?',
  'tasksFeature.disableTasksWarning':
    'You won’t be able to create new tasks anymore. This will also delete all the current tasks in your workspace.',
}

export default taskFeature
