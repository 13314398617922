import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { is } from 'ramda'
import { useIntl } from 'react-intl'

const TextArea = forwardRef(({ placeholder, ...props }, ref) => {
  const intl = useIntl()

  return (
    <Input.TextArea
      {...props}
      ref={ref}
      placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
    />
  )
})

TextArea.displayName = 'TextArea'

TextArea.defaultProps = {
  placeholder: '',
}

TextArea.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
}

export default TextArea
