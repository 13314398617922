import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'views/shared/Modal'
import Spinner from 'views/shared/icons/Spinner'

const ModalSpinner = ({ color, size }) => (
  <Modal className="main-modal" wrapClassName="with-modal-spinner">
    <Spinner isAbsolute color={color} size={size} />
  </Modal>
)

ModalSpinner.defaultProps = {
  size: 48,
  color: 'white',
}

ModalSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

export default ModalSpinner
