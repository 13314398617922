import { pathOr, keys, prop, reduce, equals } from 'ramda'
import { findKey } from 'lodash'

import formatJsonApiErrors from 'utils/formatJsonApiErrors'

const mapResponseErrorsToForm = (values, error) => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error)
  const formattedErrors = formatJsonApiErrors(errors, { rawKeys: true })
  const invalidProfileFields = prop('profile-fields', formattedErrors)

  return reduce(
    (acc, profileFieldId) => {
      const formKey = findKey(values, equals(profileFieldId.toString()))

      return formKey ? { ...acc, [formKey]: invalidProfileFields[profileFieldId].id } : acc
    },
    {},
    keys(invalidProfileFields),
  )
}

export default mapResponseErrorsToForm
