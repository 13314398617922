import * as types from './types'

export const fetchRecurringPayments = () => ({
  type: types.FETCH_RECURRING_PAYMENTS,
})

export const setRecurringPayments = recurringPaymentIds => ({
  type: types.SET_RECURRING_PAYMENTS,
  recurringPaymentIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_RECURRING_PAYMENTS_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_RECURRING_PAYMENTS_PAGE,
  pageNumber,
})

export const filterRecurringPayments = filters => ({
  type: types.FILTER_RECURRING_PAYMENTS,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilterParams = () => ({
  type: types.RESET_FILTER_PARAM,
})
