import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Field } from 'formik'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import RadioField from 'views/shared/RadioField'
import { RECURRING_BOOKING_FIELD_OPTIONS } from 'lib/constants/bookings'

const RescheduleRecurringBookingModalComponent = ({ onClose, headerTitle, handleSubmit }) => (
  <Modal className="main-modal main-modal--md">
    <Form>
      <div className="main-modal__container" data-cy="reschedule-recurring-booking-modal">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage {...headerTitle} />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>

        <div className="main-modal__body" data-cy="reschedule-recurring-booking-modal-body">
          <Field name="amount" component={RadioField} options={RECURRING_BOOKING_FIELD_OPTIONS} />
        </div>

        <div className="main-modal__footer">
          <div>
            <Button
              className="main-modal__footer-action mr-16"
              text={{ id: 'shared.cancel' }}
              onClick={onClose}
              kind="flat"
            />
            <SubmitFormButton
              className="main-modal__footer-action"
              text={{ id: 'shared.confirm' }}
              onClick={handleSubmit}
              allowPristine
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

RescheduleRecurringBookingModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  headerTitle: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default RescheduleRecurringBookingModalComponent
