import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import { FILTER_SERVICES_BY_CATEGORIES } from 'state/concepts/serviceCategories/types'
import { selectedSelector } from 'state/concepts/serviceCategories/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { addSearchResults } from 'state/searchResults/actions'
import { setFilterParams, setCurrentPage } from 'state/concepts/services/actions'
import { setSelectedServiceCategories } from 'state/concepts/serviceCategories/actions'
import { hideModal } from 'state/modal/actions'
import { companySettingsServicesRoute } from 'lib/routes'

const filterServicesByCategoriesOperation = createLogic({
  type: FILTER_SERVICES_BY_CATEGORIES,
  latest: true,

  process({ getState }, dispatch, done) {
    const state = getState()
    const serviceCategoriesIds = selectedSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)

    dispatch(addSearchResults({ serviceCategories: serviceCategoriesIds }))
    dispatch(setFilterParams({ status: 'all', categories: serviceCategoriesIds }))
    dispatch(setCurrentPage(1))
    redirect({
      href: companySettingsServicesRoute,
      workspace: workspaceCode,
    })
    dispatch(setSelectedServiceCategories([]))
    dispatch(hideModal())

    done()
  },
})

export default filterServicesByCategoriesOperation
