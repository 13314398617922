import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { clientAccountSettingsGeneralRoute } from 'lib/routes'
import { CONFIRM_CLIENT_OWN_EMAIL } from 'state/concepts/client/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { currentClientSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import updateDataHelper from 'utils/updateDataHelper'
import redirect from 'utils/redirect'
import { confirmClientOwnEmailEndpoint } from '../endpoints'

const confirmClientOwnEmailOperation = createLogic({
  type: CONFIRM_CLIENT_OWN_EMAIL,
  latest: true,

  async process({ action: { token }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const client = currentClientSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const { endpoint, url } = confirmClientOwnEmailEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, { token })

      const response = updateDataHelper(state.data, 'clientProfile', client.id, {
        attributes: {
          email: client.newEmail,
          newEmail: null,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.emailHasBeenConfirmed' } }))
    } catch (error) {
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.invalidLink' },
          kind: 'error',
        }),
      )

      requestErrorHandler({
        error,
        dispatch,
        endpoint,
        skipHandlers: true,
      })
    }

    redirect({
      href: clientAccountSettingsGeneralRoute(workspaceCode),
    })
    done()
  },
})

export default confirmClientOwnEmailOperation
