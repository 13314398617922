import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CLIENT_PORTAL } from 'state/concepts/companyClientPortal/types'
import { fetchClientPortalEndpoint } from 'state/concepts/companyClientPortal/endpoints'
import { setClientPortalId } from 'state/concepts/companyClientPortal/actions'

const fetchClientPortalOperation = createLogic({
  type: FETCH_CLIENT_PORTAL,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchClientPortalEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params: { include: 'widget' } })
      const response = normalize(data)

      dispatch(setClientPortalId(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchClientPortalOperation
