import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Duration = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 0c-282.317 0-512 229.683-512 512s229.683 512 512 512c282.317 0 512-229.683 512-512h-102.4c0 225.843-183.757 409.6-409.6 409.6s-409.6-183.757-409.6-409.6c0-225.843 183.757-409.6 409.6-409.6 125.972 0 238.336 57.577 313.498 147.3l-108.698 108.7h307.2v-307.2l-126.5 126.5c-93.85-108.29-231.306-177.7-385.5-177.7zM460.8 204.8v286l-138.6 138.601 72.4 72.397 168.6-168.596v-328.402h-102.4z" />
  </svg>
)

Duration.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Duration.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Duration
