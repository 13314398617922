import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addEtherpadDocumentIds } from '../actions'
import { fetchEtherpadDocumentsEndpoint } from '../endpoints'
import { FETCH_ETHERPAD_DOCUMENTS } from '../types'

const fetchEtherpadDocumentsOperation = createLogic({
  type: FETCH_ETHERPAD_DOCUMENTS,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const {
      clientId,
      pageParams: { page = 1 },
    } = action
    const { url, endpoint } = fetchEtherpadDocumentsEndpoint(clientId)
    const params = {
      page: { number: page, size: 20 },
      include: [
        'user_profile',
        'client_profile',
        'document_permissions',
        'document-signatories',
        'document-signatories.signature',
        'document-signatories.profileable',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addEtherpadDocumentIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchEtherpadDocumentsOperation
