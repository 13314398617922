import { prop, propEq } from 'ramda'

import { RECURRING_PAYMENT_STATUSES } from 'lib/constants/recurringPayments'
import {
  companyBillingRecurringPaymentsRequestRoute,
  companyFeaturesAcceptPaymentsRoute,
  companyFeaturesCustomPaymentsRoute,
  companySettingsPaymentRoute,
} from 'lib/routes'
import getRenderIntlLink from 'utils/getRenderIntlLink'

export const isDraft = propEq(RECURRING_PAYMENT_STATUSES.draft, 'status')
export const isActive = propEq(RECURRING_PAYMENT_STATUSES.active, 'status')

export const requestRecurringPaymentValuesToParams = values => ({
  frequency: values.frequency,
  limit: values.limit,
  auto_charge: values.autoPay,
  client_profile_id: values.clientId,
  status: values.status,
  payment_instructions: values.paymentInstructions,
  transaction_items_attributes: values.services.map(({ service, quantity, price }) => ({
    service_id: service,
    quantity,
    price,
  })),
})

export const isEditable = recurringPayment =>
  isDraft(recurringPayment) ||
  (isActive(recurringPayment) && prop('limit', recurringPayment) !== prop('transactionCount', recurringPayment))

export const isCancellable = recurringPayment => isActive(recurringPayment)

export const getBlankStateSubtitleProps = (
  isPaymentMethodEnabled,
  isWorkspaceOwner,
  isAdmin,
  isAcceptPaymentsFeatureEnabled,
  isCustomPaymentsFeatureEnabled,
) => {
  if (!isAcceptPaymentsFeatureEnabled) {
    if (isAdmin || isWorkspaceOwner) {
      return {
        id: 'recurringPayments.blank.subtitle.enableAcceptPayments.adminOrOwner',
        values: { a: getRenderIntlLink(companyFeaturesAcceptPaymentsRoute) },
      }
    }

    return {
      id: 'recurringPayments.blank.subtitle.enableAcceptPayments.expert',
    }
  }

  if (!isCustomPaymentsFeatureEnabled) {
    if (isWorkspaceOwner) {
      return {
        id: 'recurringPayments.blank.subtitle.enableCustomPayments.owner',
        values: { a: getRenderIntlLink(companyFeaturesCustomPaymentsRoute) },
      }
    }

    return {
      id: 'recurringPayments.blank.subtitle.enableCustomPayments.adminOrExpert',
    }
  }

  if (isPaymentMethodEnabled) {
    return {
      id: 'recurringPayments.blank.subtitle.hasPaymentMethod',
      values: { a: getRenderIntlLink(companyBillingRecurringPaymentsRequestRoute) },
    }
  }

  if (!isWorkspaceOwner) {
    return { id: 'recurringPayments.blank.subtitle.noPermission' }
  }

  return {
    id: 'recurringPayments.blank.subtitle.noPaymentMethodWithPermission',
    values: { a: getRenderIntlLink(companySettingsPaymentRoute) },
  }
}
