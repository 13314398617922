import qs from 'qs'
import * as Sentry from '@sentry/nextjs'
import cookies from 'next-cookies'
import clientCookies from 'component-cookie'
import { keys, prop, split, omit } from 'ramda'

import { IS_SENTRY_DISABLED, SENTRY_DSN, SENTRY_ENV, RELEASE_TAG } from 'lib/constants'

const userType = (currentEmployeeId, currentClientId) => {
  if (!currentEmployeeId && !currentClientId) return null

  return currentEmployeeId ? 'User' : 'Client'
}

const setCommonContext = (sentryScope, error) => {
  if (prop('message', error)) {
    // De-duplication currently doesn't work correctly for SSR / browser errors
    // so we force de-duplication by error message if it is present
    sentryScope.setFingerprint([prop('message', error)])
  }

  if (prop('statusCode', error)) {
    sentryScope.setExtra('statusCode', prop('statusCode', error))
  }

  sentryScope.setTag('isAxiosError', !!error.isAxiosError)

  if (error.isAxiosError) {
    const { config, response } = error

    if (config) {
      sentryScope.setTag('axios.url', `${config.baseURL}${config.url}`)
      sentryScope.setExtra('axios.method', config.method)
      sentryScope.setExtra('axios.request', config.data)
    }

    sentryScope.setExtra('axios.response', omit(['config', 'headers', 'request'], response))
  }
}

const setServerContext = (sentryScope, ctx) => {
  const { req, errorInfo } = ctx
  const { currentEmployeeId, currentWorkspaceId, currentClientId } = cookies(ctx)

  const [pathname, ...queryParams] = split('?', req.url)
  const search = queryParams.join('?')

  sentryScope.setTag('ssr', true)
  sentryScope.setTag('workspaceId', currentWorkspaceId)
  sentryScope.setExtra('method', req.method)
  sentryScope.setExtra('pathname', pathname)
  sentryScope.setExtra('query', qs.parse(search))
  sentryScope.setUser({ id: currentEmployeeId || currentClientId })
  sentryScope.setTag('userType', userType(currentEmployeeId, currentClientId))

  keys(errorInfo).forEach(key => sentryScope.setExtra(key, prop(key, errorInfo)))
}

const setBrowserContext = sentryScope => {
  const { currentEmployeeId, currentWorkspaceId, currentClientId } = clientCookies()
  const { pathname, search } = window.location
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  sentryScope.setTag('ssr', false)
  sentryScope.setTag('workspaceId', currentWorkspaceId)
  sentryScope.setExtra('pathname', pathname)
  sentryScope.setExtra('query', query)
  sentryScope.setUser({ id: currentEmployeeId || currentClientId })
  sentryScope.setTag('userType', userType(currentEmployeeId, currentClientId))
}

const captureException = (err, ctx) => {
  Sentry.configureScope(scope => {
    setCommonContext(scope, err)

    if (ctx) {
      setServerContext(scope, ctx)
    } else if (typeof window !== 'undefined') {
      setBrowserContext(scope)
    }
  })

  return Sentry.captureException(err)
}

const getSentryOptions = () => {
  const sentryOptions = {
    dsn: SENTRY_DSN,
    release: RELEASE_TAG,
    environment: SENTRY_ENV,
    autoSessionTracking: false,
    ignoreErrors: [
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      /^ResizeObserver loop.*/,
    ],
  }

  /* istanbul ignore next */
  if (IS_SENTRY_DISABLED) {
    // Don't actually send the errors to Sentry in development env
    sentryOptions.beforeSend = () => null
  }

  return sentryOptions
}

export { getSentryOptions, captureException }
