import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

// eslint-disable-next-line import/no-named-as-default
import SIDEBAR_COMPONENTS from './sidebarComponents'

const SidebarRoot = ({ sidebarType, sidebarProps, onClose, showOverlay, animate }) => {
  const SpecificSidebar = SIDEBAR_COMPONENTS[sidebarType]
  const className = animate ? classNames('sidebar-animate', { 'sidebar-animate-open': sidebarType }) : undefined
  const sidebarTypeClassName = sidebarType?.toLowerCase()

  return (
    <div className={className}>
      {showOverlay && <div className="overlay" />}
      <div className={`sidebar-wrap sidebar-wrap--${sidebarTypeClassName}`}>
        {sidebarType ? <SpecificSidebar onClose={onClose} {...sidebarProps} /> : null}
      </div>
    </div>
  )
}

SidebarRoot.defaultProps = {
  sidebarType: null,
  sidebarProps: {},
  showOverlay: false,
  animate: true,
}

SidebarRoot.propTypes = {
  sidebarType: PropTypes.string,
  sidebarProps: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
  animate: PropTypes.bool,
}

export default SidebarRoot
