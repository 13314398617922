// eslint-disable-next-line import/prefer-default-export
export const FORM_TYPES = {
  all: '',
  intake: 'intake',
  agreement: 'agreement',
}

export const RESPONDENT_TYPES = {
  expert: 'expert',
  client: 'client',
}

export const DECLINED_BY_TYPES = {
  [RESPONDENT_TYPES.expert]: 'UserProfile',
  [RESPONDENT_TYPES.client]: 'ClientProfile',
  clientContact: 'ClientContact',
}

export const SIGNER_TYPES = {
  none: 'none',
  both: 'both',
  expert: 'expert',
  client: 'client',
}

export const STATUS_TYPES = {
  active: 'active',
  inactive: 'inactive',
}

export const ALL_FILTER_OPTIONS = {
  allStatuses: 'allStatuses',
  allRespondents: 'allRespondents',
}

export const RESPONDENT_OPTIONS = [
  {
    key: RESPONDENT_TYPES.expert,
    value: RESPONDENT_TYPES.expert,
    label: { id: 'company.forms.respondent.expert' },
  },
  {
    key: RESPONDENT_TYPES.client,
    value: RESPONDENT_TYPES.client,
    label: { id: 'company.forms.respondent.client' },
  },
]

export const RESPONDENT_FILTER_OPTIONS = [
  {
    key: ALL_FILTER_OPTIONS.allRespondents,
    value: ALL_FILTER_OPTIONS.allRespondents,
    label: { id: 'shared.all' },
  },
  {
    key: RESPONDENT_TYPES.client,
    value: RESPONDENT_TYPES.client,
    label: { id: 'company.forms.respondent.client' },
  },
  {
    key: RESPONDENT_TYPES.expert,
    value: RESPONDENT_TYPES.expert,
    label: { id: 'company.forms.respondent.expert' },
  },
]

export const STATUS_FILTER_OPTIONS = [
  {
    key: ALL_FILTER_OPTIONS.allStatuses,
    value: ALL_FILTER_OPTIONS.allStatuses,
    label: { id: 'shared.all' },
  },
  {
    key: STATUS_TYPES.active,
    value: STATUS_TYPES.active,
    label: { id: 'company.forms.status.active' },
  },
  {
    key: STATUS_TYPES.inactive,
    value: STATUS_TYPES.inactive,
    label: { id: 'company.forms.status.deactivated' },
  },
]

export const GUEST_FORMS_FILTERS = {
  submitted: false,
}

export const AUTOMATIC_SHARING = {
  noSharing: 'i_dont_need_automatic_sharing',
  clientInWorkspace: 'a_new_client_in_your_workspace',
  clientDeletesAccount: 'client_deletes_their_account',
  newBooking: 'a_new_appointment_is_scheduled',
  bookingStarts: 'appointment_starts',
  bookingEnds: 'appointment_finishes',
}

export const AUTOMATIC_SHARING_OPTIONS = [
  {
    key: AUTOMATIC_SHARING.noSharing,
    value: AUTOMATIC_SHARING.noSharing,
    label: { id: 'company.forms.noSharing' },
    group: 'default',
  },
  {
    key: AUTOMATIC_SHARING.newBooking,
    value: AUTOMATIC_SHARING.newBooking,
    label: { id: 'company.forms.newBooking' },
    group: 'booking',
  },
  {
    key: AUTOMATIC_SHARING.bookingStarts,
    value: AUTOMATIC_SHARING.bookingStarts,
    label: { id: 'company.forms.bookingStarts' },
    group: 'booking',
  },
  {
    key: AUTOMATIC_SHARING.bookingEnds,
    value: AUTOMATIC_SHARING.bookingEnds,
    label: { id: 'company.forms.bookingEnds' },
    group: 'booking',
  },
  {
    key: AUTOMATIC_SHARING.clientInWorkspace,
    value: AUTOMATIC_SHARING.clientInWorkspace,
    label: { id: 'company.forms.clientInWorkspace' },
    group: 'client',
  },
  {
    key: AUTOMATIC_SHARING.clientDeletesAccount,
    value: AUTOMATIC_SHARING.clientDeletesAccount,
    label: { id: 'company.forms.clientDeletesAccount' },
    group: 'client',
  },
]

export const SIGNATURE_FIELD_NAMES = {
  client: 'clientSignature',
  expert: 'expertSignature',
}

export const FORM_STATUSES = {
  pending: 'pending',
  waitingExpertSignature: 'waiting_expert_signature',
  waitingClientSignature: 'waiting_client_signature',
  declined: 'declined',
  submitted: 'submitted',
  submittedAndSigned: 'submitted_and_signed',
}

export const WAITING_SIGNATURE_STATUSES = {
  waitingExpertSignature: 'waiting_expert_signature',
  waitingClientSignature: 'waiting_client_signature',
}

export const SUBMISSION_STATUSES = [FORM_STATUSES.submitted, FORM_STATUSES.submittedAndSigned, FORM_STATUSES.declined]

export const CLIENT_SUBMISSION_STATUSES = [
  FORM_STATUSES.submitted,
  FORM_STATUSES.submittedAndSigned,
  FORM_STATUSES.waitingExpertSignature,
  FORM_STATUSES.declined,
]

export const MAX_FORM_SIGNATURE_LENGTH = 100
export const MAX_MINUTES_PER_GROUP = 5
