import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Note = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M1024 682.667l-341.333 341.106-625.652 0.228c-31.489 0-57.015-25.31-57.015-56.513v-910.973c0-31.212 25.313-56.513 56.513-56.513h910.973c31.209 0 56.513 25.928 56.513 57.029v625.637zM910.222 113.778h-796.444v796.444h455.111v-284.444c0-29.173 21.959-53.22 50.256-56.508l6.633-0.381 284.444-0.057v-455.054zM863.061 682.61l-180.395 0.057v180.281l180.395-180.338z" />
  </svg>
)

Note.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Note.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Note
