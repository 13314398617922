import PropTypes from 'prop-types'
import classNames from 'clsx'

import InfoO from 'views/shared/icons/InfoO'
import Tick from 'views/shared/icons/TickO'
import Close from 'views/shared/icons/Close'
import CloseO from 'views/shared/icons/CloseO'
import AlertO from 'views/shared/icons/AlertO'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'

const ICON_COMPONENTS = {
  close: CloseO,
  alert: Close,
  info: InfoO,
  tick: Tick,
  alertO: AlertO,
}

const InputMessage = ({ icon, message, iconColor, messageTextClass, iconClassName, iconSize }) => {
  const IconComponent = ICON_COMPONENTS[icon]

  const iconClassNames = classNames('main-input__message-icon', iconClassName, {
    'main-input__message-icon--blue': iconColor === 'blue',
  })

  return (
    <p className={classNames('main-input__message', messageTextClass)} data-cy="input-message">
      {icon && (
        <span className={iconClassNames}>
          <IconComponent className="main-input__message-icon-shape fill-currentcolor" size={iconSize} />
        </span>
      )}
      <span className="main-input__message-text">
        <FormattedOrRawMessage message={message} />
      </span>
    </p>
  )
}

InputMessage.defaultProps = {
  icon: null,
  iconColor: null,
  messageTextClass: null,
  iconClassName: null,
  iconSize: 14,
}

InputMessage.propTypes = {
  icon: PropTypes.oneOf(['close', 'alert', 'info', 'tick', 'alertO', null]),
  iconColor: PropTypes.oneOf(['blue', null]),
  message: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
    PropTypes.string,
  ]).isRequired,
  messageTextClass: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.number,
}

export default InputMessage
