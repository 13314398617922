const employeeOnboarding = {
  'employeeOnboarding.setUpYourAccount': 'Help us set up your account',
  'employeeOnboarding.generalInformation': 'General Information',
  'employeeOnboarding.generalInformation.schedulerDescription': 'Help people get to know you.',
  'employeeOnboarding.generalInformation.description':
    'Help people get to know you. _(Clients) will see this information when booking _a(appointment), so be sure to include your photo and information about yourself.',
  'employeeOnboarding.firstName': 'First name',
  'employeeOnboarding.lastName': 'Last name',
  'employeeOnboarding.position': 'Position',
  'employeeOnboarding.position.placeholder': 'i.e. Manager',
  'employeeOnboarding.about': 'About <span>(optional)</span>',
  'employeeOnboarding.about.placeholder': 'Describe your bio and experience',
  'employeeOnboarding.timezone': 'Timezone',
  'employeeOnboarding.timezone.description':
    'Set your timezone to let people from all over the world book _(appointments) with you. You can change it later.',
  'employeeOnboarding.phone': 'Phone number',
  'employeeOnboarding.phone.description':
    'We will only use it for SMS notifications related to your bookings. Your _(clients) won’t see it.',
  'employeeOnboarding.phone.placeholder': '(123) 555-5555',
  'employeeOnboarding.finish': 'Finish',
  'employeeOnboarding.businessProfile': 'Business profile <span>(optional)</span>',
  'employeeOnboarding.businessProfile.description':
    'This is what people will see on your booking page, financial documents (invoices, receipts, etc), and more.',
  'employeeOnboarding.businessName':
    'Business name <span>(this name will appear on your financial documents, policies, etc)</span>',
  'employeeOnboarding.businessName.placeholder': 'e.g., ExpertBox Inc',
  'employeeOnboarding.contactDetails': 'Contact details <span>(optional)</span>',
  'employeeOnboarding.addressSearch': 'Address',
  'employeeOnboarding.addressSearch.placeholder': 'Start typing address or postcode',
  'employeeOnboarding.enterAddressManually': 'Enter address manually',
  'employeeOnboarding.addressLine': 'Address line',
  'employeeOnboarding.city': 'City',
  'employeeOnboarding.postcode': 'Postcode',
  'employeeOnboarding.country': 'Country',
  'employeeOnboarding.email': 'Email',
}

export default employeeOnboarding
