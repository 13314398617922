import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { prop } from 'ramda'
import React from 'react'

import { ALERT_KINDS } from 'lib/constants'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { sendDeleteConfirmationCode as sendClientDeleteConfirmationCode } from 'state/concepts/client/actions'
import { sendDeleteConfirmationCode as sendClientContactDeleteConfirmationCode } from 'state/concepts/clientContacts/actions'
import { currentClientSelector } from 'state/concepts/session/selectors'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const ConfirmationCodeForm = ({ onClose, onDeleteFormToggle, currentContact }) => {
  const email = prop('email', useSelector(currentClientSelector))
  const handleSubmit = useFormSubmit(
    currentContact ? sendClientContactDeleteConfirmationCode : sendClientDeleteConfirmationCode,
    { onSuccess: onDeleteFormToggle, currentContact },
  )

  return (
    <Formik initialValues={{ email }} onSubmit={handleSubmit}>
      {({ status, isSubmitting }) => (
        <>
          <Alert
            className="mb-16 text-left"
            type={ALERT_KINDS.info}
            message={{ id: 'client.account.settings.tab.general.delete.modal.confirmationCodeForm.info' }}
          />

          {prop('base', status) && (
            <Alert type={ALERT_KINDS.error} className="auth__alert" message={prop('base', status)} />
          )}

          <Button
            className="main-modal__footer-action"
            text={{ id: 'shared.leave' }}
            onClick={onClose}
            kind="flat"
            dataCy="cancel-delete-account-modal-button"
          />
          <SubmitFormButton
            text={{ id: 'client.account.settings.tab.general.delete.modal.confirmationCodeForm.submitButton' }}
            className="main-modal__footer-action"
            kind="danger"
            disabled={isSubmitting}
            dataCy="submit-request-confirmaiton-code"
          />
        </>
      )}
    </Formik>
  )
}

ConfirmationCodeForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDeleteFormToggle: PropTypes.func.isRequired,
  currentContact: PropTypes.shape(),
}

ConfirmationCodeForm.defaultProps = {
  currentContact: null,
}

export default ConfirmationCodeForm
