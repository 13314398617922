import updateVideoCallProvider from './updateVideoCallProvider'
import connectGoogleMeetProvider from './connectGoogleMeetProvider'
import disconnectGoogleMeetProvider from './disconnectGoogleMeetProvider'
import getMicrosoftTeamsConnectLink from './getMicrosoftTeamsConnectLink'
import connectMicrosoftTeamsProvider from './connectMicrosoftTeamsProvider'
import disconnectMicrosoftTeamsProvider from './disconnectMicrosoftTeamsProvider'
import getZoomConnectLink from './getZoomConnectLink'
import connectZoomProvider from './connectZoomProvider'
import disconnectZoomProvider from './disconnectZoomProvider'

export default [
  updateVideoCallProvider,
  connectGoogleMeetProvider,
  disconnectGoogleMeetProvider,
  getMicrosoftTeamsConnectLink,
  connectMicrosoftTeamsProvider,
  disconnectMicrosoftTeamsProvider,
  getZoomConnectLink,
  connectZoomProvider,
  disconnectZoomProvider,
]
