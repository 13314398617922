import { combineReducers } from '@reduxjs/toolkit'
import { uniq } from 'ramda'
import * as types from './types'

const promocodeIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_PROMOCODES:
      return uniq([...state, action.promocodeId])
    case types.RESET_PROMOCODES:
      return []
    default:
      return state
  }
}

const appliedPromocodeIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_APPLIED_PROMOCODES:
      return uniq([...state, action.appliedPromocodeId])
    default:
      return state
  }
}

const trialCode = (state = null, action) => {
  switch (action.type) {
    case types.SET_TRIAL_CODE:
      return action.code
    default:
      return state
  }
}

const discountCode = (state = null, action) => {
  switch (action.type) {
    case types.SET_DISCOUNT_CODE:
      return action.code
    default:
      return state
  }
}

export default combineReducers({
  promocodeIds,
  appliedPromocodeIds,
  trialCode,
  discountCode,
})
