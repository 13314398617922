import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_SUBSCRIPTION_PLANS } from 'state/concepts/subscriptionPlans/types'
import { setSubscriptionPlans, setStoragePlans } from 'state/concepts/subscriptionPlans/actions'
import { fetchSubscriptionPlansEndpoint } from 'state/concepts/subscriptionPlans/endpoints'
import { workspacesHttpClient } from 'lib/httpClient'

const fetchSubscriptionPlansOperation = createLogic({
  type: FETCH_SUBSCRIPTION_PLANS,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchSubscriptionPlansEndpoint
    const params = {
      include: 'subscription-plans,storage-plans',
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await workspacesHttpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setSubscriptionPlans(pluck('id', data.data.relationships['subscription-plans'].data)))
      dispatch(setStoragePlans(pluck('id', data.data.relationships['storage-plans'].data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchSubscriptionPlansOperation
