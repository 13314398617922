import skipStep from './skipStep'
import finishTutorial from './finishTutorial'
import updateOnboardingContent from './updateOnboardingContent'
import updateClientOnboardingContent from './updateClientOnboardingContent'
import updateUserOnboardingContent from './updateUserOnboardingContent'

export default [
  skipStep,
  finishTutorial,
  updateOnboardingContent,
  updateClientOnboardingContent,
  updateUserOnboardingContent,
]
