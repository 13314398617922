import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CHATS } from 'state/concepts/chats/types'
import { fetchChatsEndpoint } from 'state/concepts/chats/endpoints'
import { addChats } from 'state/concepts/chats/actions'

const fetchChatsOperation = createLogic({
  type: FETCH_CHATS,
  latest: true,

  async process(
    {
      action: {
        pageParams: { nextCursor = null },
      },
      httpClient,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = fetchChatsEndpoint
    const params = {
      page: { after: nextCursor },
      include: [
        'workspace',
        'message',
        'message.chat-user',
        'message.attachments',
        'message.meta-member',
        'message.meta-performer',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      const chatIds = pluck('id', data.data)
      dispatch(addChats(chatIds))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchChatsOperation
