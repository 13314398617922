import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'

import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { declineBooking } from 'state/concepts/booking/actions'
import { DECLINE_WAITLIST_BOOKING_OPTIONS, DECLINE_WAITLIST_BOOKING_VALUES } from 'lib/constants/bookings'
import useDispatchAction from 'hooks/shared/useDispatchAction'

const DeclineWaitlistAppointmentModal = ({ onClose, bookingUniqCode }) => {
  const handleDeclineBooking = useDispatchAction(declineBooking)

  const handleSubmit = ({ action }) => {
    handleDeclineBooking(
      bookingUniqCode,
      action === DECLINE_WAITLIST_BOOKING_VALUES.declineAppointmentAndDeleteWaitlistRequest,
    )
  }

  return (
    <Modal className="main-modal main-modal--md">
      <Formik onSubmit={handleSubmit} initialValues={{ action: DECLINE_WAITLIST_BOOKING_VALUES.declineAppointment }}>
        {({ isSubmitting }) => (
          <div className="main-modal__container">
            <div className="main-modal__header">
              <h2 className="main-modal__title">
                <FormattedMessage id="declineWaitlistAppointmentModal.title" />
              </h2>
              <button data-cy="close-button" type="button" className="main-modal__close" onClick={onClose}>
                <Cross dataCy="cross-icon" />
              </button>
            </div>
            <div className="main-modal__body">
              <Field
                wrapperClassName="d-flex flex-column row-gap-12"
                radioFieldWrapClassName="d-flex"
                className="align-items-center mb-0"
                name="action"
                component={RadioField}
                options={DECLINE_WAITLIST_BOOKING_OPTIONS}
              />
              <p className="text-body mb-0 mt-16">
                <FormattedMessage id="declineWaitlistAppointmentModal.body" />
              </p>
            </div>
            <div className="main-modal__footer">
              <Button
                className="mr-16"
                text={{ id: 'shared.leave' }}
                kind="flat"
                onClick={onClose}
                dataCy="leave-button"
              />
              <SubmitFormButton text={{ id: 'shared.confirm' }} kind="danger" isDisabled={isSubmitting} />
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

DeclineWaitlistAppointmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  bookingUniqCode: PropTypes.string.isRequired,
}

export default DeclineWaitlistAppointmentModal
