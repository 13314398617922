import { FormattedMessage } from 'react-intl'
import Image from 'next/legacy/image'

import emptySearchImg from 'assets/images/auth/empty_search.svg'

const ClientBookingTimeSlotsEmpty = () => (
  <div className="main-page__empty resch-modal__content-empty">
    <div className="main-page__empty-content" data-cy="main-page-empty-content">
      <div className="main-page__empty-image">
        <Image src={emptySearchImg} alt="No time slots" data-cy="main-page-empty-image" />
      </div>
      <p className="main-page__empty-text" data-cy="empty-timeS-slot-text">
        <FormattedMessage id="rescheduleBookingModal.empty.timeSlot" />
      </p>
    </div>
  </div>
)

export default ClientBookingTimeSlotsEmpty
