import * as apiRoutes from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const fetchBillingDetailEndpoint = endpoint(types.FETCH_BILLING_DETAIL, 'GET', apiRoutes.billingDetailRoute)

export const fetchSubscriptionTransactionsEndpoint = endpoint(
  types.FETCH_SUBSCRIPTION_TRANSACTIONS,
  'GET',
  apiRoutes.subscriptionTransactionsRoute,
)

export const getBillingAccountEndpoint = endpoint(types.GET_BILLING_ACCOUNT, 'GET', apiRoutes.billingAccountRoute)

export const setBillingAccountEndpoint = endpoint(types.SET_BILLING_ACCOUNT, 'PUT', apiRoutes.billingAccountRoute)

export const createSetupIntentEndpoint = endpoint(
  types.CREATE_SETUP_INTENT,
  'POST',
  apiRoutes.paymentMethodSetupIntentRoute,
)

export const updatePaymentMethodEndpoint = endpoint(types.UPDATE_PAYMENT_METHOD, 'PUT', apiRoutes.paymentMethodRoute)

export const updateTeamSeatsEndpoint = endpoint(
  types.UPDATE_TEAM_SEATS,
  'POST',
  apiRoutes.teamSeatsSubscriptionChangeRoute,
)

export const cancelDowngradeSeatsEndpoint = endpoint(
  types.CANCEL_DOWNGRADE_SEATS,
  'POST',
  apiRoutes.teamSeatsSubscriptionDowngradeCancelRoute,
)

export const downloadSubscriptionTransactionEndpoint = transactionId =>
  endpoint(
    types.DOWNLOAD_SUBSCRIPTION_TRANSACTION,
    'GET',
    apiRoutes.subscriptionTransactionDownloadRoute(transactionId),
  )

export const retryPaymentEndpoint = endpoint(types.RETRY_PAYMENT, 'POST', apiRoutes.paymentRetryRoute)

export const renewSubscriptionEndpoint = endpoint(types.RENEW_SUBSCRIPTION, 'POST', apiRoutes.subscriptionsRenewRoute)

export const updateBillingAddressEndpoint = endpoint(
  types.UPDATE_BILLING_ADDRESS,
  'PUT',
  apiRoutes.paymentMethodBillingAddressRoute,
)

export const cancelDowngradeStorageEndpoint = endpoint(
  types.CANCEL_DOWNGRADE_STORAGE,
  'POST',
  apiRoutes.subscriptionsDowngradeStoragePlanRoute,
)
