import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchSMSNotificationsSettings = () => ({
  type: types.FETCH_SMS_NOTIFICATIONS_SETTINGS,
})

export const setSMSNotificationsSettings = smsNotificationsSettingsId => ({
  type: types.SET_SMS_NOTIFICATIONS_SETTINGS_ID,
  smsNotificationsSettingsId,
})

export const updateSMSNotificationsSettings = makeFormSubmitAction(types.UPDATE_SMS_NOTIFICATIONS_SETTINGS)
