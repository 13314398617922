import { combineReducers } from '@reduxjs/toolkit'

import { SET_STATUS_CODE } from './types'

const statusCode = (state = null, action) => {
  switch (action.type) {
    case SET_STATUS_CODE:
      return action.statusCode
    default:
      return state
  }
}

export default combineReducers({
  statusCode,
})
