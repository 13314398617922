import { createSelector } from '@reduxjs/toolkit'
import { compose, flatten, groupBy, isEmpty, map, omit, path, pathOr, pipe, prop, propOr, values } from 'ramda'
import build from 'redux-object'

import { dataSelector, hasMoreSelector, nextPageSelector } from 'state/data/selectors'
import { fetchEmployeesAvailabilitiesEndpoint } from './endpoints'

const employeeIdsSelector = path(['employees', 'employeeIds'])

export const isBlankStateSelector = path(['employees', 'isBlankState'])

export const employeesSelector = createSelector([employeeIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'userProfile', ids) : [],
)

export const employeeSelector = createSelector([(_, employeeId) => employeeId, dataSelector], (employeeId, data) =>
  employeeId ? build(data, 'userProfile', employeeId) : null,
)

export const employeeAvatarSelector = pipe(employeeSelector, pathOr(null, ['avatarUrls', 'original']))

export const employeeTimezoneSelector = createSelector(employeeSelector, propOr(null, 'formattedTimezone'))

export const paginationSelector = path(['employees', 'pagination'])

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const filtersSelector = path(['employees', 'filters'])

export const searchQuerySelector = pipe(filtersSelector, prop('name'))

export const sortSelector = path(['employees', 'sort'])

export const sortParamsSelector = pipe(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const appliedFilters = createSelector(filtersSelector, pipe(omit(['name']), values, flatten))

export const provisionSelector = createSelector(
  employeeSelector,
  compose(
    values,
    map(profilesServices => {
      const services = map(path(['service', 'id']), profilesServices)
      const price = Number(profilesServices[0].price)

      return {
        price,
        key: services.join(''),
        services,
        isFreePrice: price === 0,
      }
    }),
    groupBy(prop('price')),
    propOr([], 'userProfilesServices'),
  ),
)

export const employeesAvailabilitiesNextCursorSelector = state =>
  nextPageSelector(state, fetchEmployeesAvailabilitiesEndpoint.endpoint)
export const hasMoreEmployeesAvailabilitiesSelector = state =>
  hasMoreSelector(state, fetchEmployeesAvailabilitiesEndpoint.endpoint)
