export const SERVICE_STATUSES = {
  all: 'all',
  active: 'active',
  inactive: 'inactive',
}

export const SERVICE_INTERVAL_OPTIONS = {
  durationBased: 'duration_based',
  fixedInterval: 'fixed_interval',
}

export const CREATE_SERVICE_STEPS = {
  1: 'details',
  2: 'settings',
  3: 'providers',
  4: 'documents',
}

export const SERVICE_STATUSES_FILTERS = [
  {
    value: SERVICE_STATUSES.all,
    label: { id: 'shared.filters.statuses.all' },
  },
  {
    value: SERVICE_STATUSES.active,
    label: { id: 'shared.filters.statuses.active' },
  },
  {
    value: SERVICE_STATUSES.inactive,
    label: { id: 'shared.filters.statuses.inactive' },
  },
]

export const SERVICE_MIN_PRICE = 1.0
export const SERVICE_MAX_PRICE = 10000.0

export const SERVICE_SETTINGS_TIME_FIELDS = {
  minLeadTime: 'minLeadTime',
  availableFor: 'availableFor',
  cancellableUntil: 'cancellableUntil',
  reschedulableUntil: 'reschedulableUntil',
}

export const DEFAULT_SERVICE_DURATION = '15m'
export const MIN_SERVICE_DURATION = '5m'
export const MAX_SERVICE_DURATION = '8h'

export const MAX_CATEGORY_DISPLAY_LENGTH = 21
