const forms = {
  'forms.submitted': 'Submitted',
  'forms.pending': 'Pending',
  'forms.status.waitingExpertResponse': 'Waiting for _(expert)’s response',
  'forms.status.waitingClientResponse': 'Waiting for _(client)’s response',
  'forms.status.waitingExpertSignature': 'Waiting for _(expert)’s signature',
  'forms.status.waitingClientSignature': 'Waiting for _(client)’s signature',
  'forms.status.declinedByExpert': 'Declined by _(expert)',
  'forms.status.declinedByClient': 'Declined by _(client)',
  'forms.status.submitted': 'Submitted',
  'forms.status.submittedAndSigned': 'Submitted and signed',
  'forms.editResponses': 'Edit responses',
  'forms.downloadForm': 'Download form',
  'forms.assignedForms': 'Assigned forms',
  'forms.expertPending': '{count} waiting for _(expert)',
  'forms.title': 'Forms',
  'forms.fillOutForm': 'Fill out',
  'forms.reviewAndSign': 'Review and sign',
  'forms.formsToComplete': '{count} {count, plural, one {form} other {forms}} to complete',
  'forms.pleaseFillOutForms': 'Please fill out the forms before the _(appointment) to get effective consultation.',
  'forms.fillOutForms': 'Fill out forms',
  'forms.fillOutNewForm': 'Fill out a new form',
  'forms.sendNewForm': 'Send a new form',
  'forms.duplicateAndEdit': 'Duplicate and edit',
  'forms.sendCopyForEditing': 'Send a copy for editing',
  'forms.fillOutDocuments': 'Fill out documents',
  'forms.signAgreements': 'Sign {count, plural, one {_(service agreement)} other {_(service agreements)}}',
  'forms.agreementsToComplete':
    '{count} {count, plural, one {_(service agreement)} other {_(service agreements)}} to complete',
  'forms.agreementsAndFormsToComplete':
    '{agreementsCount} {agreementsCount, plural, one {_(service agreement)} other {_(service agreements)}} and {intakeFormsCount} {intakeFormsCount, plural, one {form} other {forms}} to complete',
  'forms.manageCategories': 'Manage categories',
  'forms.formReminders': 'Form reminders',
  'forms.filter.byCategory': 'By category ({formCategoriesCount})',
  'forms.expiresOn': 'Expires on',
  'forms.respondentInline.expert': 'Respondent: _(Expert)',
  'forms.respondentInline.client': 'Respondent: _(Client)',
  'forms.ExpiresAtInline': 'Expires on: <span>{expiresAt}</span>',
  'documents.formIsNoAvailable': 'Form is no longer available',

  'formSubmission.submitForm': 'Submit',
  'formSubmission.yourAnswer': 'Your answer',
  'formSubmission.requiredFields': '* Required fields',
  'formSubmission.submittedBy': 'Submitted by {name}',
  'formSubmission.submittedByContact': 'Submitted by {name} ({relationshipName})',
  'formSubmission.submittedBy.date': '{date} UTC',
  'formSubmission.signature.expert': '_(Expert)’s signature',
  'formSubmission.signature.client': '_(Client)’s signature',
  'formSubmission.signature.signedBy': 'Signed by {name}',
  'formSubmission.signature.ipAddress': 'IP address: {ipAddress}',
  'formSubmission.signature.expertRequired': 'This form requires _(expert)’s signature.',
  'formSubmission.signature.clientRequired': 'This form requires _(client)’s signature.',
  'formSubmission.signAgreement': 'I agree and sign this document',
  'formSubmission.confirmModal.submit': 'I agree',
  'formSubmission.confirmModal.title': 'Sign _(service agreement)?',
  'formSubmission.confirmModal.text': 'Are you sure you want to accept and sign this _(service agreement)?',
  'formSubmission.selectDate': 'Select date',
  'formSubmission.selectTime': 'Select time',
  'formSubmission.selectDuration': 'Select duration',
  'formSubmission.fileUploadError': 'Error uploading file',
  'formSubmission.fileSizeCantBeBigger': 'File size can’t be bigger than {size}',
  'formSubmission.maxFilesUploadError': 'You can only upload {max} files at a time',
  'formSubmission.uploadFiles': 'Upload your files',
  'formSubmission.uploadLimits': 'Maximum number 10, max size 10 Mb',
  'formSubmission.autosaveModal.title': 'Autosave your work',
  'formSubmission.autosaveModal.body':
    'Your work is automatically saved for 30 days, so you can switch devices or take a break anytime.',
  'formSubmission.draftSaved': 'Draft saved',
  'formSubmission.saving': 'Saving...',
  'formSubmission.clearForm': 'Clear form',
  'formSubmission.clearForm.title': 'Clear form?',
  'formSubmission.clearForm.bodyText': 'This will delete your answers from all questions, and cannot be undone.',
  'formSubmission.formIsFillingOutNow':
    "Someone else is filling out the form now, so you can't edit it. Please try again later.",

  'agreements.submitted': 'Accepted',
  'agreements.pending': 'Waiting for _(client)',
  'agreements.title': '_(Service agreements)',
  'agreements.formsToComplete':
    '{count} {count, plural, one {_(service agreement)} other {_(service agreements)}} to complete',
  'agreements.pleaseReviewAndSign': 'Please review and sign these _(service agreements) before the _(appointment).',
  'agreements.signAgreement': 'Sign <span>agreement</span>',
  'agreements.previewAgreement': 'Preview agreement',
  'agreements.viewAgreement': 'View <span>agreement</span>',
  'agreements.accepted.thanksForTime': 'Thank you for taking your time',
  'agreements.accepted.success': 'The _(service agreement) was accepted',
  'formSubmission.downloadFile': 'Download file',

  'formSigning.yourSignature.clientSignature': `Your signature <span>(_(Client))</span>`,
  'formSigning.yourSignature.expertSignature': `Your signature <span>(_(Expert))</span>`,
  'formSigning.enterName': 'Enter your name',
  'formSigning.enterName.clientSignature': '_(Client) name',
  'formSigning.enterName.expertSignature': '_(Expert) name',
  'formSigning.signedBy.clientSignature': 'Signed by _(Client) name',
  'formSigning.signedBy.expertSignature': 'Signed by _(Expert) name',
  'formSigning.signedBy': 'Signed by {name}',
  'formSigning.signedBy.date': '{date} UTC',
  'formSigning.success.title': 'The form is signed',
  'formSigning.success.body': 'Thank you for taking your time',
  'formSigning.alert': `Once you submit your response, we'll send this form to the {signer} for their signature.`,
  'formSigning.declineToSubmit': 'Decline to sign',
  'formDeclining.success': 'Thank you for taking your time',
  'formDeclining.declined': 'You’ve declined to sign the form.',
  'formDeclining.title': 'Decline to sign this form?',
  'formDeclining.description.client':
    'Your decision can’t be changed. We’ll let the _(client) know that you’ve chosen not to sign this form.',
  'formDeclining.description.expert':
    'Your decision can’t be changed. We’ll let the _(expert) know that you’ve chosen not to sign this form.',
  'formDeclining.declineReason.label': 'Please provide your reason for declining here:',
  'formDeclining.submit': 'Decline to sign',
  'formDeclinedAlert.client': 'The _(client) declined to sign this form.',
  'formDeclinedAlert.clientContact': 'Declined by contact: {name} ({relationship})',
  'formDeclinedAlert.expert': 'The _(expert) declined to sign this form.',
  'formDeclinedAlert.reason': 'Reason for declining:',

  'formSigning.name.placeholder.expert': '_(Expert) Name',
  'formSigning.name.placeholder.client': '_(Client) Name',
}

export default forms
