const pageTitle = {
  'pageTitle.expertBox': 'ExpertBox',
  'pageTitle.appointments': '_(Appointments)',
  'pageTitle.appointmentsDetails': '_(Appointments) details',
  'pageTitle.team': 'Team',
  'pageTitle.manageSeats': 'Manage seats',
  'pageTitle.manageStorage': 'Manage storage plan',
  'pageTitle.editProfile': 'Edit profile',
  'pageTitle.editTeamMember': 'Edit team member',
  'pageTitle.regularAvailability': 'Regular availability',
  'pageTitle.addBillingMethod': 'Add billing method',
  'pageTitle.updateBillingMethod': 'Update billing method',
  'pageTitle.dateAvailability': 'Day-specific availability',
  'pageTitle.services': '_(Services)',
  'pageTitle.serviceCategories': 'Categories',
  'pageTitle.clients': '_(Clients)',
  'pageTitle.businessInformation': 'Business information',
  'pageTitle.myBusinessFiles': 'My files',
  'pageTitle.sharedFiles': 'Shared files',
  'pageTitle.addBusinessFiles': 'Add business files',
  'pageTitle.trashedFiles': 'Trash',
  'pageTitle.myBusinessFilesPreview': 'My preview files',
  'pageTitle.filesSharedWithMePreview': 'Shared file preview',
  'pageTitle.trashedFilesPreview': 'Trashed files preview',
  'pageTitle.storageFilesPreview': 'Storage files preview',
  'pageTitle.client.uploadedFilesPreview': 'Uploaded files preview',
  'pageTitle.client.sharedFilesPreview': 'Shared files preview',
  'pageTitle.bookingPages': 'Booking pages',
  'pageTitle.cardReaders': 'Card Readers',
  'pageTitle.paymentMethods': 'Payment settings',
  'pageTitle.payments': 'Payments',
  'pageTitle.previewPayment': 'Preview payment',
  'pageTitle.subscription': 'Subscription',
  'pageTitle.addPaymentMethod': 'Add payment method',
  'pageTitle.costManagement': 'Cost management',
  'pageTitle.customizeReceipt': 'Invoices & receipts settings',
  'pageTitle.subscriptionPlans': 'Subscription plans',
  'pageTitle.invoiceDetails': 'Invoice details',
  'pageTitle.billingMethod': 'Billing method',
  'pageTitle.bookingDetails': '_(Appointment) details',
  'pageTitle.scheduleAppointment': 'Schedule _a(appointment)',
  'pageTitle.videoConference': 'Video conference',
  'pageTitle.clientProfile': '_(Client) profile',
  'pageTitle.editClient': 'Edit _(client)',
  'pageTitle.teamMemberProfile': 'Team member profile',
  'pageTitle.editTeamMemberAccount': 'Edit team member account',
  'pageTitle.editDateAvailability': 'Edit date availability',
  'pageTitle.editTeamMemberProfile': 'Edit team member profile',
  'pageTitle.editRegularAvailability': 'Edit regular availability',
  'pageTitle.editTeamMemberServices': 'Edit team member _(services)',
  'pageTitle.editServiceDetails': 'Edit _(service) details',
  'pageTitle.createService': 'Create _(service)',
  'pageTitle.editServiceProviders': 'Edit _(service) providers',
  'pageTitle.editServiceDocuments': 'Edit _(service) documents',
  'pageTitle.editServiceSettings': 'Edit _(service) settings',
  'pageTitle.createBookingPage': 'Create booking page',
  'pageTitle.editBookingPage': 'Edit booking page',
  'pageTitle.shareBookingPage': 'Share booking page',
  'pageTitle.customizeWidget': 'Customize side widget',
  'pageTitle.teamMemberOnboarding': 'Team member onboarding',
  'pageTitle.personalProfile': 'Personal profile',
  'pageTitle.clientAppointment': '_(Client) _(appointment)',
  'pageTitle.clientVideoConference': '_(Client) video conference',
  'pageTitle.yourWorkspaces': 'Your workspaces',
  'pageTitle.remindWorkspacesRequest': 'Remind workspaces request',
  'pageTitle.resetPasswordConfirm': 'Reset password confirm',
  'pageTitle.resetPasswordRequest': 'Reset password request',
  'pageTitle.resetPasswordLinkExpired': 'Reset password link expired',
  'pageTitle.signUpSubscriptionPlans': 'Sign up subscription plans',
  'pageTitle.accountSettingsGeneral': 'General Settings',
  'pageTitle.accountSettingsNotifications': 'Notification Settings',
  'pageTitle.accountSettingsSecurity': 'Security Settings',
  'pageTitle.accountSettingsCalendarSync': 'Calendar Sync',
  'pageTitle.login': 'Login',
  'pageTitle.teamMemberInvitationSignup': 'Team member invitation signup',
  'pageTitle.signUp': 'Sign up',
  'pageTitle.joinConference': 'Join Conference',
  'pageTitle.chat': 'Chat',
  'pageTitle.chats': 'Chats',
  'pageTitle.intakeForms': 'Forms',
  'pageTitle.agreement': '_(Service agreements)',
  'pageTitle.createIntakeForm': 'Create form',
  'pageTitle.createAgreement': 'Create _(service agreement)',
  'pageTitle.editAgreement': 'Edit _(service agreement)',
  'pageTitle.editIntakeForm': 'Edit form',
  'pageTitle.clientFields': '_(Client) fields',
  'pageTitle.clientRelationshipTypes': '_(Client) relationship types',
  'pageTitle.signatureSettings': 'E-signature',
  'pageTitle.createClientRelationshipType': 'Add relationship type',
  'pageTitle.editClientRelationshipType': 'Edit relationship type',
  'pageTitle.reviews': 'Reviews',
  'pageTitle.clientLinkExpired': 'Expired link',
  'pageTitle.documentNotFound': "Couldn't find the document",
  'pageTitle.reviewSettings': 'Review settings',
  'pageTitle.shareFiles': 'Share files',
  'pageTitle.sendDocuments': 'Send documents',
  'pageTitle.sendAgreements': 'Share _(service agreements)',
  'pageTitle.shareForm': 'Share form',
  'pageTitle.addForm': 'Add form',
  'pageTitle.addContact': 'Add contact',
  'pageTitle.editContact': 'Edit contact',
  'pageTitle.settings': 'Settings',
  'pageTitle.tasks': 'Tasks',
  'pageTitle.createClient': 'Create new _(client)',
  'pageTitle.importClients': 'Import _(clients)',
  'pageTitle.documents': 'Documents & Files',
  'pageTitle.locations': 'Locations',
  'pageTitle.videoCallSettings': 'Video call settings',
  'pageTitle.calendarSync': 'Calendar sync',
  'pageTitle.createLocation': 'Create location',
  'pageTitle.editLocation': 'Edit location',
  'pageTitle.paymentDetails': 'Preview payment',
  'pageTitle.refundDetails': 'Preview refund',
  'pageTitle.recurringPaymentDetails': 'Recurring payment',
  'pageTitle.recurringPayments': 'Recurring payments',
  'pageTitle.billing': 'Billing',
  'pageTitle.features': 'Features',
  'pageTitle.website': 'Website',
  'pageTitle.createWebsite': 'Create website',
  'pageTitle.websiteSettings': 'Website settings',
  'pageTitle.customNaming': 'Custom naming',
  'pageTitle.waitlist': 'Waitlist',
  'pageTitle.addWaitlistRequest': 'Add to waitlist',
  'pageTitle.waitlistSettings': 'Waitlist settings',
  'pageTitle.editWaitlistRequest': 'Edit waitlist request',

  'pageTitle.client.login': 'Log in to ExpertBox account',
  'pageTitle.client.accountSettings': 'Account settings',
  'pageTitle.client.accountSettingsNotifications': 'Notification Settings',
  'pageTitle.client.appointments': '_(Appointments)',
  'pageTitle.client.chats': 'Chats',
  'pageTitle.client.reviews': 'Reviews',
  'pageTitle.client.chat': 'Chat',
  'pageTitle.client.personalProfile': 'Personal profile',
  'pageTitle.client.payments': 'Payments',
  'pageTitle.client.paymentDetails': 'Payment details',
  'pageTitle.client.refundDetails': 'Preview refund',
  'pageTitle.client.bookingsLogin': 'Booked _(appointment)',
  'pageTitle.client.rateExperience': 'Rate your experience',
  'pageTitle.client.forms': 'Forms',
  'pageTitle.client.agreements': '_(Service agreements)',
  'pageTitle.client.documents': 'Documents',
  'pageTitle.client.acceptWaitlist': 'Accept waitlist request',
  'pageTitle.client.files': 'Files',
  'pageTitle.client.appointmentDetails': '_(Appointment) details',
  'pageTitle.client.makePayment': 'Make payment',
  'pageTitle.client.completeBooking': 'Complete booking',
  'pageTitle.client.editWaitlist': 'Edit waitlist request',
  'pageTitle.client.removeWaitlist': 'Remove waitlist request',

  'pageTitle.documentTemplates': 'Document templates',
  'pageTitle.selectTemplate': 'Select template',
  'pageTitle.previewTemplate': 'Preview template',

  'pageTitle.etherpadDocumentFailToSign': 'Fail to sign',
  'pageTitle.etherpadDocumentNoPermissionToSign': 'No permission to sign',
  'pageTitle.etherpadDocumentDeclineToSign': 'Decline to sign',
  'pageTitle.guest.bookingReschedule': 'Reschedule _(appointment)',
  'pageTitle.guest.bookingAccept': 'Confirm _(appointment)',
  'pageTitle.guest.bookingDecline': 'Decline _(appointment)',
  'pageTitle.guest.bookingCancel': 'Cancel _(appointment)',
  'pageTitle.guest.declineRecurringAppointments': 'Decline recurring _(appointments)',
  'pageTitle.guest.documents': 'Require actions',
  'pageTitle.guest.restoreAccount': 'Restore your account',
  'pageTitle.guest.notifications': 'Notifications',

  'pageTitle.publicVideoConference': 'Video conference',
  'pageTitle.appointmentDetails': '_(Appointment) details',
  'pageTitle.completeBooking': 'Complete booking',
  'pageTitle.makePayment': 'Make payment',
  'pageTitle.requestPayment': 'Request payment',
  'pageTitle.requestRecurringPayment': 'Request recurring payment',
  'pageTitle.editPayment': 'Edit payment',
  'pageTitle.bookingPolicy': 'Booking policy',
  'pageTitle.client.updatePaymentMethod': 'Update payment method',
  'pageTitle.editRecurringPayment': 'Edit recurring payment',
  'pageTitle.featuresAndIntegrations': 'Features',
  'pageTitle.features.availability': 'Availability',
  'pageTitle.features.booking': '_(Appointments)',
  'pageTitle.features.services': '_(Services)',
  'pageTitle.features.customNaming': 'Custom naming',
  'pageTitle.features.serviceCategories': '_(Service) categories',
  'pageTitle.features.clientPortal': '_(Client) portal',
  'pageTitle.features.clientContacts': '_(Client) contacts',
  'pageTitle.features.signature': 'E-signature',
  'pageTitle.features.tasks': 'Tasks',
  'pageTitle.features.groupVideoCalls': 'ExpertBox group video calls',
  'pageTitle.features.videoCallsRecording': 'ExpertBox video calls recording',
  'pageTitle.features.acceptVideoCalls': 'Accept video calls',
  'pageTitle.features.bookingPage': 'Booking page',
  'pageTitle.features.bookingWidgets': 'Booking widgets',
  'pageTitle.features.team': 'Team',
  'pageTitle.features.clientList': '_(Client) list',
  'pageTitle.features.crm': 'CRM',
  'pageTitle.clientPortal': '_(Client) portal',
  'pageTitle.client.recurringAppointment': 'Requested recurring _(appointments)',
  'pageTitle.features.website': 'Website',
  'pageTitle.features.locations': 'Offline locations',
  'pageTitle.features.chat': 'Chat',
  'pageTitle.features.financialDocuments': 'Financial documents',
  'pageTitle.features.acceptPayments': 'Accept payments',
  'pageTitle.features.customPayments': 'Custom payments',
  'pageTitle.features.refunds': 'Refunds',
  'pageTitle.features.smsReminders': 'SMS reminders & notifications',
  'pageTitle.features.emailReminders': 'Email reminders & notifications',
  'pageTitle.smsNotifications': 'SMS notifications',
  'pageTitle.features.recurringAppointments': 'Recurring _(appointments)',
  'pageTitle.features.serviceAgreements': '_(Service agreements)',
  'pageTitle.features.intakeForms': 'Forms',
  'pageTitle.features.documents': 'Documents',
  'pageTitle.features.documentTemplates': 'Document templates',
  'pageTitle.features.reviews': 'Reviews',
  'pageTitle.features.calendarIntegrations': 'Calendar Integrations',
  'pageTitle.features.waitlist': 'Waitlist',
  'pageTitle.viewForm': 'View form',
  'pageTitle.previewForm': 'Preview form',
  'pageTitle.signForm': 'Sign form',
  'pageTitle.editFormResponses': 'Edit form responses',
  'pageTitle.submitForm': 'Submit form',
  'pageTitle.acceptAgreement': 'Accept agreement',
  'pageTitle.submitFormSuccess': 'Form submitted',
  'pageTitle.signFormSuccess': 'Form signed',
  'pageTitle.declineFormSuccess': 'Form declined',
  'pageTitle.formCategories': 'Categories',
  'pageTitle.subscriptions.downgradeToFree': 'Downgrade plan to Free',
  'pageTitle.paymentMethod.editBillingAddress': 'Edit billing address',
  'pageTitle.features.voiceMessages': 'Voice Messages',
  'pageTitle.features.stripePos': 'Stripe Point of Sale',
  'pageTitle.features.businessFiles': 'Business files',
  'pageTitle.agreementAccepted': 'Agreement accepted',
  'pageTitle.inCallChatPreview': 'In call chat preview',
  'pageTitle.sendForSignature': 'Send for signature',
  'pageTitle.sendForSignatureWithDocument': 'Send for signature "{document}"',
  'pageTitle.editSigners': 'Edit signers',
  'pageTitle.editSignersWithDocument': 'Edit signers "{document}"',
  'pageTitle.signatureDisclosure': 'Signature disclosure',
  'pageTitle.storage': 'Storage',
}

export default pageTitle
