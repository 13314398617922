import * as types from './types'

export const fetchClientReviews = clientId => ({
  type: types.FETCH_CLIENT_REVIEWS,
  clientId,
})

export const setClientReviews = reviewIds => ({
  type: types.SET_CLIENT_REVIEWS,
  reviewIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_CLIENT_REVIEWS_PAGE,
  pageNumber,
})

export const resetClientReviews = () => ({
  type: types.RESET_CLIENT_REVIEWS,
})
