import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { DELETE_LOCATION } from 'state/concepts/locations/types'
import { deleteLocationEndpoint } from 'state/concepts/locations/endpoints'
import { hideModal } from 'state/modal/actions'
import { DELETE_LOCATION_ACTION_VALUE, ONLINE_LOCATION_OPTION } from 'lib/constants/locations'
import { currentWorkspaceIdSelector, currentWorkspaceLocationsCountSelector } from 'state/concepts/session/selectors'
import updateDataHelper from 'utils/updateDataHelper'
import { removeLocation, fetchLocations } from '../actions'

const deleteLocationOperation = createLogic({
  type: DELETE_LOCATION,
  latest: true,

  async process(
    {
      action: {
        form,
        values: { id, locationId, action },
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const { url, endpoint } = deleteLocationEndpoint(id)
    const state = getState()
    const workspaceId = currentWorkspaceIdSelector(state)
    const workspaceLocationsCount = currentWorkspaceLocationsCountSelector(state)

    const params = {
      location_id: locationId === ONLINE_LOCATION_OPTION.value ? undefined : locationId,
      move_to: action === DELETE_LOCATION_ACTION_VALUE.move,
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url, { params })

      const response = updateDataHelper(state.data, 'workspace', workspaceId, {
        attributes: { locationsCount: workspaceLocationsCount - 1 },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(removeLocation(id))
      dispatch(hideModal())
      dispatch(fetchLocations())
      dispatch(showNotification({ messageObject: { id: 'notifications.locationWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default deleteLocationOperation
