import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { getMarkAsPaidSubtitleId } from 'utils/payments'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { MARK_AS_PAID } from 'state/concepts/payment/types'
import { markAsPaidPaymentEndpoint } from 'state/concepts/payment/endpoints'
import { hideModal } from 'state/modal/actions'

const markAsPaidOperation = createLogic({
  type: MARK_AS_PAID,
  latest: true,

  async process({ httpClient, action: { transaction, values, form } }, dispatch, done) {
    const { id } = transaction
    const { paymentMethod, paidAt } = values
    const { url, endpoint } = markAsPaidPaymentEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, { payment_method: paymentMethod, paid_at: paidAt })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: `notifications.${getMarkAsPaidSubtitleId(transaction)}.paymentMarkedAsPaid` },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default markAsPaidOperation
