const employeeAccount = {
  'employee.verifyEmail': 'Verify your email address',
  'employee.followConfirmationLink':
    'Please, verify your email address. We’ve sent you a confirmation link to {email} {link}',

  'employeeAccount.updateEmail': 'Update email',
  'employeeAccount.email': 'Email',
  'employeeAccount.password': 'Password',
  'employeeAccount.changePassword': 'Change password',
  'employeeAccount.feelFreeToUpdateYourPassword': 'Feel free to update your password so your account stays secure.',
  'employeeAccount.forgotPassword': 'Forgot password?',
  'employeeAccount.currentPassword': 'Current password',
  'employeeAccount.newPassword': 'New password',
  'employeeAccount.newEmail':
    "We've sent you an email to confirm your new address: {newEmail}. We will continue to use your old address: {email}, until you confirm the change. {link}",
  'employeeAccount.resendConfirmation': 'Resend confirmation',
  'employeeAccount.confirmed': 'Confirmed',
  'employeeAccount.emailNotSaved': 'Not saved',
  'employeeAccount.doYouWantToRemove': 'Do you want to remove your profile image? You can add it later.',
  'employeeAccount.upload': 'Upload photo',
  'employeeAccount.deactivateAccount.title': 'Deactivate account',
  'employeeAccount.deactivateOwnerAccount.description':
    'To be able to deactivate your account, you need to transfer the ownership of your workspace first.{link}',
  'employeeAccount.deactivateOwnerAccount.workspaceSettings': 'Go to Workspace settings',
  'employeeAccount.deactivateAccount.description':
    'Deactivating your account won’t delete any of your data, messages, or files. Please note that it will be deactivated immediately, and you’ll be logged out of the workspace. You won’t be able to log in until an administrator or workspace owner reactivates your account.',
  'employeeAccount.deactivateAccount.button': 'Deactivate account',
  'employeeAccount.deleteAccount': 'Delete account',
  'employeeAccount.phoneForm.title': 'Phone number',
  'employeeAccount.phoneForm.phoneNumber.label': 'Phone number <inGray700>(optional)</inGray700>',
  'employeeAccount.phoneForm.phoneNumber.placeholder': '+1 484 444 4444',
  'employeeAccount.phoneForm.hint':
    'We will only use it for SMS notifications related to your bookings. Your _(clients) won’t see it.',
  'employeeAccount.errors.emailFormat': 'Please enter email in the format: name@example.com',
  'employeeAccount.errors.emailRequired': 'Please enter your email',
  'employeeAccount.errors.passwordRequired': 'Please enter your password',
}

export default employeeAccount
