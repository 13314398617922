import * as types from './types'

export const setIsEmailAlertShown = isShow => ({
  type: types.SET_IS_EMAIL_ALERT_SHOWN,
  isShow,
})

export const setIsConnectPayPal = isShow => ({
  type: types.SET_IS_CONNECT_PAYPAL,
  isShow,
})

export const setIsWaitlistScheduleType = isShow => ({
  type: types.SET_IS_WAITLIST_SCHEDULE_TYPE,
  isShow,
})
