class ConnectionMonitor {
  constructor(onDisconnect, connectionTimeout = 6) {
    this.onDisconnect = onDisconnect
    this.connectionTimeout = connectionTimeout
  }

  start() {
    this.recordPing()
    this.interval = setInterval(this.checkConnectionState, 1000)
  }

  checkConnectionState = () => {
    if (this.secondSinceLastPing() > this.connectionTimeout) {
      this.onDisconnect()
      this.stop()
    }
  }

  stop() {
    clearInterval(this.interval)
  }

  recordPing() {
    this.pingAt = Date.now()
  }

  secondSinceLastPing() {
    return (Date.now() - this.pingAt) / 1000
  }
}

export default ConnectionMonitor
