import { createLogic } from 'redux-logic'

import { COOKIES_DOMAIN } from 'lib/constants'
import { COOKIES_PATH } from 'lib/constants/videoConference'
import { CLIENT_LOGOUT_EVENT } from 'lib/constants/broadcastChannel'
import removeClientCookies from 'utils/removeClientCookies'
import { broadcastMessage } from 'lib/broadcastChannel'
import redirect from 'utils/redirect'
import { CLIENT_POST_LOGOUT } from 'state/concepts/session/types'
import { clientLogoutSuccess } from 'state/concepts/session/actions'
import { setIsLoggedOutDueToInactivity } from 'state/concepts/login/actions'
import { broadcastChannelNamespaceSelector } from 'state/concepts/session/selectors'
import { closeConnection } from 'state/concepts/webSockets/actions'
import { isOpenedConnectionSelector } from 'state/concepts/webSockets/selectors'
import { clientLoginRoute } from 'lib/routes'
import { hideModal } from 'state/modal/actions'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const clientPostLogoutOperation = createLogic({
  type: CLIENT_POST_LOGOUT,
  latest: true,

  async process({ action: { isInactive, withoutBroadcast }, getState }, dispatch, done) {
    const state = getState()
    const isOpenedConnection = isOpenedConnectionSelector(state)
    const { uniqCode } = workspaceSelector(state)
    const namespace = broadcastChannelNamespaceSelector(state)

    removeClientCookies({ path: `/client/${uniqCode}` })
    removeClientCookies({ domain: COOKIES_DOMAIN, path: `/client/${uniqCode}` })
    removeClientCookies({ path: COOKIES_PATH })
    if (isInactive) {
      dispatch(setIsLoggedOutDueToInactivity(true))
    }

    await redirect({ href: clientLoginRoute, skipDiscard: true })

    dispatch(clientLogoutSuccess())
    dispatch(hideModal())
    if (isOpenedConnection) {
      dispatch(closeConnection())
    }
    if (!withoutBroadcast) {
      broadcastMessage(`${namespace}/${CLIENT_LOGOUT_EVENT}`)
    }

    done()
  },
})

export default clientPostLogoutOperation
