import { createLogic } from 'redux-logic'
import { CardNumberElement } from '@stripe/react-stripe-js'

import redirect from 'utils/redirect'
import { showNotification } from 'state/notifications/actions'
import profileName from 'utils/profileName'
import { clientPaymentsRoute } from 'lib/routes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { currentClientSelector } from 'state/concepts/session/selectors'
import { UPDATE_PAYMENT_METHOD } from 'state/concepts/recurringPayment/types'
import { updatePaymentMethodEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { getPaymentMethodMetaSelector } from 'state/concepts/recurringPayment/selectors'

const updatePaymentMethodOperation = createLogic({
  type: UPDATE_PAYMENT_METHOD,
  latest: true,

  async process(
    {
      action: {
        values,
        form,
        confirmCardSetup,
        getStripeElement,
        setStripeBaseError,
        recurringPaymentId,
        workspaceCode,
      },
      getState,
      httpClient,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = updatePaymentMethodEndpoint(recurringPaymentId)

    try {
      const state = getState()
      const { clientSecret } = getPaymentMethodMetaSelector(state, recurringPaymentId)
      const currentClient = currentClientSelector(state)

      const { setupIntent, error } = await confirmCardSetup(clientSecret, {
        payment_method: {
          card: getStripeElement(CardNumberElement),
          billing_details: {
            name: profileName(currentClient),
            email: currentClient.email,
            address: {
              city: values.city,
              state: values.state,
              line1: values.address,
              country: values.country,
              postal_code: values.index,
            },
          },
        },
      })
      if (error) {
        setStripeBaseError(error.message)
        // eslint-disable-next-line no-throw-literal
        throw { ...error, isStripe: true }
      }

      dispatch(dataApiRequest({ endpoint }))

      const params = {
        stripe_payment_method_id: setupIntent.payment_method,
      }

      await httpClient.put(url, params, buildCustomBaseUrl(['workspaces', workspaceCode]))

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.paymentMethodWasSaved' } }))
      redirect({ href: clientPaymentsRoute(workspaceCode) })
    } catch (error) {
      const { isStripe } = error

      if (!isStripe) {
        requestErrorHandler({ error, dispatch, endpoint, form })
      }
      form.setSubmitting(false)
    }

    done()
  },
})

export default updatePaymentMethodOperation
