import { ALLOWABLE_TIME_IN_MINUTES } from 'lib/constants/bookings'

const videoConference = {
  'videoConference.waitingToJoin': 'Waiting for the participant to join',
  'videoConference.newShare': 'New share',
  'videoConference.stopShare': 'Stop share',
  'videoConference.yourMicrophoneIsOnMute': 'Your microphone is on mute',
  'videoConference.microphoneTurnedOff': 'Another participant has turned off microphone',
  'videoConference.checkConnection': 'Check your Internet connection',
  'videoConference.lostConnection': 'Internet connection has been lost',
  'videoConference.youLostConnection': 'You lost your internet connection. Trying to reconnect...',
  'videoConference.tryingToReconnect': 'Trying to reconnect ...',
  'videoConference.reconnecting': 'Reconnecting…',
  'videoConference.recordingStarted': 'Recording…',
  'videoConference.expertRecording': '_(Expert) is recording…',
  'videoConference.recordingPaused': 'Recording paused',
  'videoConference.grid': 'Grid',
  'videoConference.spotlight': 'Spotlight',
  'videoConference.joinConference': 'Join Conference',
  'videoConference.preview': 'Preview',
  'videoConference.you': '(you)',
  'videoConference.cameraOff': 'Camera is off',
  'videoConference.isTooEarly': `You can join the video call ${ALLOWABLE_TIME_IN_MINUTES} minutes before it starts`,
  'videoConference.isTooLate': 'The call was ended',
  'videoConference.isTooManyParticipants':
    'Sorry, there are too many people on this call. Please try to join again later.',

  'videoConference.settingsModal.microphone': 'Microphone',
  'videoConference.settingsModal.camera': 'Camera',
  'videoConference.settingsModal.speakers': 'Speakers',
  'videoConference.settingsModal.settings': 'Settings',
  'videoConference.settingsModal.notification': 'Settings were updated',

  'videoConference.welcomeModal.title': 'Welcome to video conference page',
  'videoConference.welcomeModal.startScreenSharing':
    'Use this button to start a video sharing, you can share the whole screen, separate app or browser tab.',
  'videoConference.welcomeModal.recordVideo':
    'Click this button to record your video consultation. This video will be later available on your consultation page.',
  'videoConference.welcomeModal.leaveConference':
    'User red button to exit from video consultation. You can re-join during the consultation time.',
  'videoConference.welcomeModal.youCanSwapScreens': 'You can swap screens during the video call. ',
  'videoConference.welcomeModal.youCanShareScreen':
    'Click this button to share your screen. You can share the entire screen or a separate browser tab.',
  'videoConference.welcomeModal.recordCall':
    'Click this button  if you want the meeting to be recorded. You will be able to access  the recording after the consultation.',
  'videoConference.welcomeModal.sendingMessages': 'You can send messages in chat during consultation.',
  'videoConference.welcomeModal.leaveMeeting':
    'Click this button if you need to leave the meeting. You can rejoin the meeting room any time during consultation time.',

  'videoConference.alreadyJoinedModal.title': 'Other device is active',
  'videoConference.alreadyJoinedModal.bodyLine1':
    'This consultation is active on the other device. You have to exit before opening it on this device.',
  'videoConference.accessForbiddenModal.title': 'Access Forbidden',
  'videoConference.accessForbiddenModal.body': 'Sorry, you have no permission to enter this consultation',
  'videoConference.meetingEndedModal.title': 'Meeting is ended',
  'videoConference.meetingEndedModal.body': 'You will be redirected to the meeting page',
  'videoConference.enterYourName': 'Enter your name',
  'videoConference.shareInvitationLink': 'Share invitation link',
  'videoConference.getInvitationLink': 'Get invitation link',
  'videoConference.shareLinkByEmail': 'Share invitation link by email',
  'videoConference.sendInvitationLink': 'Send invitation link',
  'videoConference.callSizeWarning': 'Up to 12 members can be in the call at a time',
  'videoConference.copiedToBuffer': 'Copied to buffer',
  'videoConference.expert': '_(Expert)',
  'videoConference.addMembers': 'Invite members',
  'videoConference.members': 'Members',
  'videoConference.formsAndAgreements': 'Forms and Agreements',
  'videoConference.forms': 'Forms',
  'videoConference.agreements': '_(Service agreements)',
  'videoConference.forms.respondent.client': 'Respondent: _(Client)',
  'videoConference.forms.respondent.expert': 'Respondent: _(Expert)',
  'videoConference.agreements.status.pending': 'Pending',
  'videoConference.agreements.status.submitted': 'Accepted',
  'videoConference.forms.expiresAt': 'Expires on:<span>{date}</span>',

  'videoConference.microphoneWarningModal.title': 'Can’t find your microphone',
  'videoConference.microphoneWarningModal.body':
    'Please make sure that your microphone is available. Check your settings and refresh the page.',
  'videoConference.cameraWarningModal.title': 'Can’t find your camera',
  'videoConference.cameraWarningModal.body':
    'Please make sure that your camera is available. Check your settings and refresh the page.',
  'videoConference.browserNotSupported':
    'Your browser in not supported. Please use the latest version of Chrome, Firefox, Edge or Safari.',

  'videoConference.control.tooltip.invite': 'Share invitation link',
  'videoConference.control.tooltip.members': 'Video call members',
  'videoConference.control.tooltip.forms': 'Forms&Documents',
  'videoConference.control.tooltip.turnOffAudio': 'Turn off microphone',
  'videoConference.control.tooltip.turnOnAudio': 'Turn on microphone',
  'videoConference.control.tooltip.turnOffVideo': 'Turn off camera',
  'videoConference.control.tooltip.turnOnVideo': 'Turn on camera',
  'videoConference.control.tooltip.turnOffScreenShare': 'Stop sharing',
  'videoConference.control.tooltip.turnOnScreenShare': 'Share screen',
  'videoConference.control.tooltip.settings': 'Settings',
  'videoConference.control.tooltip.leaveCall': 'Leave call',
  'videoConference.control.tooltip.openChat': 'Open in-call chat',
  'videoConference.control.tooltip.closeChat': 'Hide in-call chat',
  'videoConference.control.tooltip.recordingDisabled': 'Recording for this _(service) is disabled',
  'videoConference.control.tooltip.turnOffRecording': 'Pause recording',
  'videoConference.control.tooltip.turnOnRecording': 'Start recording',
  'videoConference.control.tooltip.pin': 'Pin to main screen',
  'videoConference.control.tooltip.unpin': 'Unpin from main screen',
  'videoConference.control.tooltip.block': 'Block',
  'videoConference.control.tooltip.mute': 'Mute',
  'videoConference.control.tooltip.youCantUnmute': 'You can’t unmute another member.',
  'videoConference.control.tooltip.muteMember': 'Mute member',
  'videoConference.control.tooltip.unmuteMember': 'Unmute member',
  'videoConference.control.tooltip.removeMember': 'Remove from the call',
  'videoConference.cameraIsStarting': 'Camera is starting',
  'videoConference.shareLink': 'Share link',
  'videoConference.removeMemberModal.title': 'Remove {userName} from the call?',
  'videoConference.removeMemberModal.body': 'They still will be able to rejoin.',

  'videoConference.muteMemberModal.title': 'Mute member',
  'videoConference.muteMemberModal.body': 'Mute {userName} for everyone? Only {userName} can unmute themselves.',
  'videoConference.muteMemberModal.mute': 'Mute',

  'videoConference.duplicatedIdentityModal.title': 'You were disconnected',
  'videoConference.duplicatedIdentityModal.bodyText': 'You joined this call on another device or tab.',
  'videoConference.userSharedScreen': '{userName} shared his screen',

  'videoConference.chat.title': 'In-call chat',
  'videoConference.chat.empty': 'No messages in the chat',

  'videoPreview.poweredBy': 'Powered by ',
  'videoPreview.copyright': ' Copyright © 2020',

  'consultation.joinMeeting': 'Join meeting',
  'consultation.leave': 'Leave',
  'consultation.join': 'Join video call',
  'consultation.join.expertbox': 'Join with ExpertBox video',
  'consultation.join.google_meet': 'Join with Google Meet',
  'consultation.join.zoom': 'Join with Zoom',
  'consultation.join.microsoft_teams': 'Join with Microsoft Teams',
  'consultation.joinVideoCall': 'Join video call',
  'consultation.joining': 'Joining...',
  'consultation.joinIn': 'Join in {timeToConsultation}',
  'consultation.details.title.google_meet': 'Google Meet joining info',
  'consultation.details.title.zoom': 'Zoom joining info',
  'consultation.details.videoCallLink': 'Video call link:',
  'consultation.details.google_meet.dial': 'Or dial: {phone}',
  'consultation.details.google_meet.pin': 'PIN: {pin}',
  'consultation.details.google_meet.moreNumbers': 'More phone numbers: {link}',
  'consultation.details.title.microsoft_teams': 'Microsoft Teams joining info',
  'consultation.details.microsoft_teams.meetingId': 'Meeting ID: {meetingId}',
  'consultation.details.zoom.meetingId': 'Meeting ID: {meetingId}',
  'consultation.details.zoom.passcode': 'Passcode: {passcode}',

  'videoConference.participants.empty': 'No one else in this call',
  'videoConference.participants.noMoreParticipants': '{count, plural, one {is} other {are}} in this call',
  'videoConference.participants.moreParticipants': 'and {count} more are in this call',
}

export default videoConference
