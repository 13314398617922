import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { VALIDATE_INVITATION_TOKEN } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { setEmployeeInvitationId } from '../actions'
import { validateInvitationTokenEndpoint } from '../endpoints'

const validateInvitationTokenOperation = createLogic({
  type: VALIDATE_INVITATION_TOKEN,
  latest: true,

  async process({ action: { token } }, dispatch, done) {
    const { endpoint, url } = validateInvitationTokenEndpoint
    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await workspacesHttpClient.get(url, { params: { token } })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setEmployeeInvitationId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [404] })
    }
    done()
  },
})

export default validateInvitationTokenOperation
