import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import validationSchema from 'lib/yupLocalised/schemas/cancelRecurringBookings'
import { ACTIVE_BOOKINGS, FREE_PRICE, RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'
import { handleSubmit } from 'utils/form/handleSubmit'
import { cancelBooking } from 'state/concepts/bookings/actions'
import { cancellationRulesSelector } from 'state/concepts/bookings/selectors'
import CancelFormModalComponent from './component'

class CancelFormModal extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
    cancellationRules: PropTypes.shape({
      refundCount: PropTypes.number.isRequired,
      refundAmount: PropTypes.string.isRequired,
      bookingRefundAmount: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    values: PropTypes.shape({
      recurringOption: PropTypes.string.isRequired,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }

  get isSingleOption() {
    const {
      values: { recurringOption },
    } = this.props

    return recurringOption === RECURRING_BOOKING_OPTIONS.single
  }

  get isRefundShown() {
    return this.refundAmount !== FREE_PRICE
  }

  get refundAmount() {
    const {
      cancellationRules: { refundAmount, bookingRefundAmount },
    } = this.props

    return this.isSingleOption ? bookingRefundAmount : refundAmount
  }

  get refundCount() {
    const {
      cancellationRules: { refundCount },
    } = this.props

    return this.isSingleOption ? 1 : refundCount
  }

  componentDidUpdate = ({ values: prevValues }) => {
    const {
      values: { recurringOption },
      booking: { status },
      cancellationRules: { bookingRefundAmount },
      setFieldValue,
    } = this.props

    if (this.isSingleOption && recurringOption !== prevValues.recurringOption) {
      setFieldValue('refundNeeded', bookingRefundAmount !== FREE_PRICE && ACTIVE_BOOKINGS.includes(status))
    }
  }

  render = () => (
    <CancelFormModalComponent
      {...this.props}
      isCancellationRuleShown={this.isCancellationRuleShown}
      isRefundShown={this.isRefundShown}
      refundAmount={this.refundAmount}
      refundCount={this.refundCount}
    />
  )
}

const mapStateToProps = state => ({
  cancellationRules: cancellationRulesSelector(state),
})

const mapDispatchToProps = {
  onSubmit: cancelBooking,
}

export { CancelFormModal as CancelFormModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ booking: { id, status, uniqCode }, cancellationRules: { bookingRefundAmount } }) => ({
      id,
      uniqCode,
      cancellationReason: '',
      refundNeeded: bookingRefundAmount !== FREE_PRICE && ACTIVE_BOOKINGS.includes(status),
      recurringOption: RECURRING_BOOKING_OPTIONS.single,
    }),
    validateOnMount: true,
    validationSchema,
    handleSubmit,
  }),
)(CancelFormModal)
