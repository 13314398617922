const videoCallsIntegrations = {
  'videoCallsIntegrations.pageTitle': 'Integrations',
  'videoCallsIntegrations.videoCall': 'Video call',
  'videoCallsIntegrations.connectCalendarSync.info.alert':
    'You are using ExpertBox video to meet your _(clients) online. To start having online _(appointments) via Google Meet, connect your Google Calendar and allow ExpertBox to create events there.',
  'videoCallsIntegrations.connectCalendarSync.warning.alert.text':
    'To use Google Meet, connect your Google Calendar and allow ExpertBox to sync _(appointments).',
  'videoCallsIntegrations.connectCalendarSync.warning.alert.action': 'Connect Google Calendar',
  'videoCallsIntegrations.googleMeet': 'Google Meet',
  'videoCallsIntegrations.googleMeet.disconnect.modal.title': 'Disconnect Google Meet?',
  'videoCallsIntegrations.googleMeet.disconnect.modal.body.textOne': `Google Meet invite links won’t be added to new online appointments. You can still meet your clients online via
  ExpertBox video calls.`,
  'videoCallsIntegrations.googleMeet.disconnect.modal.body.textTwo':
    'Note that any _(appointments) that already have links to Google Meet won’t be updated automatically.',
  'videoCallsIntegrations.googleMeet.disconnect.modal.alert':
    'Google Meet is a default video call provider at your workspace.',
  'videoCallsIntegrations.notConnected': 'Not connected',
  'videoCallsIntegrations.connected': 'Connected',
  'videoCallsIntegrations.googleMeet.createAndJoin':
    'Automatically create and join meetings at Google Meet from your ExpertBox _(appointments).',
  'videoCallsIntegrations.microsoftTeams': 'Microsoft Teams (requires business/education account)',
  'videoCallsIntegrations.microsoftTeams.alert':
    'You are using ExpertBox video to meet your _(clients) online. To start having online _(appointments) at Microsoft Teams, connect your work/school Teams account for everybody in this workspace.',
  'videoCallsIntegrations.microsoftTeams.createAndJoin':
    'Automatically create and join meetings at Microsoft Teams from your ExpertBox _(appointments). Please note that to join a meeting, you need to be a member of your work/school Team.',
  'videoCallsIntegrations.microsoftTeams.disconnect.modal.body.textOne':
    'Microsoft Teams invite links won’t be added to any new online _(appointments)  in the workspace. You can still meet your _(clients) online via ExpertBox video calls.',
  'videoCallsIntegrations.microsoftTeams.disconnect.modal.body.textTwo':
    'Note that any _(appointments) that already have links to Microsoft Teams won’t be updated automatically.',
  'videoCallsIntegrations.microsoftTeams.disconnect.modal.title': 'Disconnect Microsoft Teams?',
  'videoCallsIntegrations.zoom': 'Zoom',
  'videoCallsIntegrations.zoom.alert':
    'You are using ExpertBox video to meet your _(clients) online. To start having online _(appointments) at Zoom, connect your Zoom account.',
  'videoCallsIntegrations.zoom.createAndJoin':
    'Automatically create and join Zoom meetings from your ExpertBox _(appointments).',
  'videoCallsIntegrations.zoom.disconnect.modal.title': 'Disconnect Zoom?',
  'videoCallsIntegrations.zoom.disconnect.modal.body.textOne':
    'Zoom invite links won’t be added to new online _(appointments). You can still meet your _(clients) online via ExpertBox video calls.',
  'videoCallsIntegrations.zoom.disconnect.modal.body.textTwo':
    'Note that any _(appointments) that already have Zoom links won’t be updated automatically.',
}

export default videoCallsIntegrations
