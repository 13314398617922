import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const UserAltO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256-.001c-62.493 0-113.778 51.285-113.778 113.778S193.508 227.555 256 227.555s113.778-51.286 113.778-113.778S318.492-.001 256-.001zm0 56.89c31.744 0 56.889 25.145 56.889 56.889s-25.144 56.889-56.888 56.889-56.888-25.144-56.888-56.888 25.144-56.889 56.888-56.889zm0 255.999c-49.636 0-108.459 11.662-157.867 31.175-24.746 9.757-47.075 21.334-64.968 36.21S-.001 415.289-.001 440.888v42.666c0 15.709 12.736 28.445 28.445 28.445h455.111c15.709 0 28.445-12.736 28.445-28.445v-42.666c0-25.6-15.274-45.738-33.166-60.587-17.892-14.904-40.221-26.482-64.939-36.238-49.437-19.512-108.259-31.175-157.895-31.175zm0 56.889c40.221 0 95.175 10.638 137.045 27.164 20.964 8.248 38.656 18.12 49.408 27.05 10.724 8.96 12.658 15.048 12.658 16.896v8.534a5.689 5.689 0 0 1-5.688 5.688H62.579a5.689 5.689 0 0 1-5.688-5.688v-8.534c0-1.849 1.934-7.964 12.658-16.896 10.752-8.932 28.445-18.774 49.408-27.022 41.87-16.584 96.825-27.192 137.045-27.192z" />
  </svg>
)

UserAltO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

UserAltO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default UserAltO
