import { DateTime } from 'luxon'
import { T, always, compose, cond, equals, includes, not, path, prop, propEq, toString } from 'ramda'
import { rrulestr } from 'rrule'

import {
  BLANK_SERVICE_CATEGORY_COLOR,
  BOOKING_ACTIONS,
  BOOKING_STATUSES,
  CANCELLED_CALENDAR_EVENTS_CLASS,
  COMPLETED_CALENDAR_EVENTS_CLASS,
  CUSTOM_CALENDAR_EVENTS_CLASS,
  EVENT_LG_DURATION,
  EVENT_MD_DURATION,
  EVENT_MD_HEIGHT,
  EVENT_SIZES,
  EVENT_SM_DURATION,
  EVENT_SM_HEIGHT,
  EXTERNAL_CALENDAR_EVENTS_CLASS,
  RESCHEDULED_CALENDAR_EVENTS_CLASS,
  RESERVED_CALENDAR_EVENTS_CLASS,
  SCHEDULED_CALENDAR_EVENTS_CLASS,
  SYNCED_CALENDAR_EVENTS_COLOR,
} from 'lib/constants/bookings'
import { MILLISECONDS_PER_SECOND, SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'
import isPresent from 'utils/isPresent'
import { durationToFormatString } from './duration'

export const isSyncedCalendarEvent = propEq('externalCalendarEvent', 'type')
export const isCustomColor = prop('customColor')
export const isServiceCategory = compose(isPresent, prop('serviceCategory'))

export const isCancelled = propEq(BOOKING_STATUSES.cancelled, 'status')
export const isCompleted = propEq(BOOKING_STATUSES.completed, 'status')
export const isScheduled = propEq(BOOKING_STATUSES.scheduled, 'status')
export const isRescheduled = propEq(BOOKING_STATUSES.rescheduled, 'status')
export const isRequested = propEq(BOOKING_STATUSES.pending, 'status')
export const isPending = propEq(BOOKING_STATUSES.rescheduled, 'status')
export const isReserved = propEq(BOOKING_STATUSES.reserved, 'status')

export const isRescheduleReason = ({ status }) =>
  includes(status, [BOOKING_STATUSES.cancelled, BOOKING_STATUSES.completed, BOOKING_STATUSES.pending])

export const isUnavailableReason = ({ status }) =>
  includes(status, [BOOKING_STATUSES.cancelled, BOOKING_STATUSES.completed, BOOKING_STATUSES.pending])

export const isAvailable = compose(not, isUnavailableReason)

export const statusBadge = prop('status')
export const showBadge = ({ status }) =>
  !includes(status, [BOOKING_STATUSES.scheduled, BOOKING_STATUSES.completed, BOOKING_STATUSES.pending])

export const showDocuments = ({ status }) => !includes(status, [BOOKING_STATUSES.pending, BOOKING_STATUSES.cancelled])
export const showPolicies = ({ status }) => includes(status, [BOOKING_STATUSES.pending])
export const filterBookingsForCurrentDay = (bookings, isForCurrentDay = true) => {
  const hasSame = ({ startTime }) => DateTime.now().hasSame(DateTime.fromISO(startTime), 'day')

  if (isForCurrentDay) {
    return bookings.filter(hasSame)
  }

  return bookings.filter(({ startTime }) => not(hasSame({ startTime })))
}

export const formattedStartTimeWithOffset = ({ startTime }, timezoneOffset) =>
  DateTime.fromISO(startTime).setZone(`UTC${timezoneOffset}`).toFormat('t')
export const formattedEndTimeWithOffset = ({ endTime }, timezoneOffset) =>
  DateTime.fromISO(endTime).setZone(`UTC${timezoneOffset}`).toFormat('t')
export const formattedTimeWithOffset = (event, timezoneOffset) =>
  `${formattedStartTimeWithOffset(event, timezoneOffset)} - ${formattedEndTimeWithOffset(event, timezoneOffset)}`
export const formattedStartTime = ({ startTime }) => DateTime.fromISO(startTime).toFormat('t')
export const formattedEndTime = ({ endTime }) => DateTime.fromISO(endTime).toFormat('t')
export const formattedTime = event =>
  `${formattedStartTime(event).replace(/ (PM|AM)/, '')}-${formattedEndTime(event)}`.replaceAll(':00', '').toLowerCase()
export const formattedWeekday = ({ startTime }) => DateTime.fromISO(startTime).toFormat('DDDD')
export const formattedShortWeekday = ({ startTime }) => DateTime.fromISO(startTime).toFormat('ccc')
export const formattedFullDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('ccc, MMM d, yyyy')
export const formattedDateTime = event => `${formattedTime(event)}, ${formattedWeekday(event)}`
export const formattedShortDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('EEE, DDD')
export const formattedShortestDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('cccc, LLL d')
export const formattedShortestDateWithTime = (time, timezoneOffset) =>
  DateTime.fromISO(time).setZone(`UTC${timezoneOffset}`).toFormat('cccc, LLL d, t')
export const formattedStartShortDate = ({ startTime }) => DateTime.fromISO(startTime).toFormat('d LLL')
export const formattedTimeWithDuration = (newStartTime, { startTime, endTime }) =>
  DateTime.fromISO(newStartTime).plus(DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime), ['hours', 'minutes']))
export const duration = ({ startTime, endTime }) => DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime))
export const formattedDuration = event =>
  duration(event).toFormat("h'hr' m'm'").replace('0hr', '').replace(' 0m', '').trim()

export const formattedSlotTime = slot => DateTime.fromJSDate(slot).toISODate()

export const durationInSelectedDate = (event, selectedDate) => {
  const startTime = DateTime.fromISO(event.startTime)
  const endTime = DateTime.fromISO(event.endTime)

  const intervalStart = startTime.hasSame(selectedDate, 'day') ? startTime : selectedDate.startOf('day')
  const intervalEnd = endTime.hasSame(selectedDate, 'day') ? endTime : selectedDate.endOf('day')

  return intervalEnd.diff(intervalStart)
}

export const isLessThan = (event, minutes, selectedDate) =>
  durationInSelectedDate(event, selectedDate).milliseconds / MILLISECONDS_PER_SECOND / SECONDS_PER_MINUTE < minutes

const getSizeByHeight = height => {
  if (height < EVENT_SM_HEIGHT) return EVENT_SIZES.small
  if (height < EVENT_MD_HEIGHT) return EVENT_SIZES.medium
  return undefined
}

const getSizeByDuration = (startTime, endTime) => {
  const start = DateTime.fromISO(startTime)
  const end = DateTime.fromISO(endTime)
  const eventMinutes = end.diff(start, 'minutes').toObject().minutes

  if (eventMinutes < EVENT_SM_DURATION) return EVENT_SIZES.small
  if (eventMinutes < EVENT_MD_DURATION) return EVENT_SIZES.medium
  if (eventMinutes < EVENT_LG_DURATION) return EVENT_SIZES.large
  return EVENT_SIZES.xlarge
}

export const getSize = ({ height, startTime, endTime }) => {
  const sizeByHeight = getSizeByHeight(height)
  if (sizeByHeight) return sizeByHeight
  return getSizeByDuration(startTime, endTime)
}

export const getColor = cond([
  [isCustomColor, isCustomColor],
  [isSyncedCalendarEvent, always(SYNCED_CALENDAR_EVENTS_COLOR)],
  [isServiceCategory, path(['serviceCategory', 'color'])],
  [T, always(BLANK_SERVICE_CATEGORY_COLOR)],
])

export const getClassName = cond([
  [isRequested, always(CUSTOM_CALENDAR_EVENTS_CLASS)],
  [isCancelled, always(CANCELLED_CALENDAR_EVENTS_CLASS)],
  [isRescheduled, always(RESCHEDULED_CALENDAR_EVENTS_CLASS)],
  [isScheduled, always(SCHEDULED_CALENDAR_EVENTS_CLASS)],
  [isCompleted, always(COMPLETED_CALENDAR_EVENTS_CLASS)],
  [isReserved, always(RESERVED_CALENDAR_EVENTS_CLASS)],
  [isSyncedCalendarEvent, always(EXTERNAL_CALENDAR_EVENTS_CLASS)],
  [T, always('')],
])

export const currentClosestDate = () => {
  const localDate = DateTime.local()

  return localDate.plus({ hour: localDate.minute > 30 ? 1 : 0 }).set({ minute: localDate.minute <= 30 ? 30 : 0 })
}

export const bookingStartTime = (date, time, timezoneOffset) =>
  date.set({ hour: time.hour, minute: time.minute, second: 0 }).setZone(`UTC${timezoneOffset}`, { keepLocalTime: true })

export const isSubmitFormsAction = equals(BOOKING_ACTIONS.submitForms)
export const isAcceptAction = equals(BOOKING_ACTIONS.accept)
export const isDeclineAction = equals(BOOKING_ACTIONS.decline)
export const isDeclineAllAction = equals(BOOKING_ACTIONS.decline_all)
export const isRescheduleAction = equals(BOOKING_ACTIONS.reschedule)
export const isCancelAction = equals(BOOKING_ACTIONS.cancel)

export const bookingDuration = ({ endTime, startTime }) =>
  DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime), 'seconds').seconds

export const durationByStartAndEndTime = (startTime, endTime) =>
  durationToFormatString(bookingDuration({ startTime, endTime }))

export const cancelledByType = booking =>
  isCancelled(booking)
    ? {
        id: `bookings.cancellationReason.${booking.cancelledByType || 'default'}`,
      }
    : null

export const hasPerformer = booking => isPresent(booking.cancelledByType)

export const cancellationReason = booking => {
  if (!isCancelled(booking)) {
    return null
  }

  return hasPerformer(booking) ? booking.cancellationReason : { id: 'bookings.cancellationReason.defaultDescription' }
}

export const isRecurring = booking => isPresent(booking.bookingRecurrence)

export const isLastRecurring = ({ bookingRecurrence, id }) => toString(bookingRecurrence.lastBookingId) === id

export const showRecurrenceRules = rruleString => rrulestr(rruleString).toText()

export const sortBookingRecurrence = ({ days }) => {
  const sorter = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }
  return days
    .split(', ')
    .sort((dayOne, dayTwo) => sorter[dayOne.toLowerCase()] - sorter[dayTwo.toLowerCase()])
    .join(', ')
}

export const hasAttachments = booking =>
  isPresent(prop('chatHistoryUrl', booking?.videoConference)) || isPresent(booking?.videoRecords)
