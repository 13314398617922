import { createLogic } from 'redux-logic'
import cookies from 'component-cookie'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_EMPLOYEE_PASSWORD } from 'state/concepts/employee/types'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceSelector } from 'state/concepts/session/selectors'
import { usersAccountPasswordRoute } from 'lib/apiRoutes'
import { updateRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'

const updateEmployeePasswordOperation = createLogic({
  type: UPDATE_EMPLOYEE_PASSWORD,
  latest: true,

  async process({ action: { oldPassword, password, form }, httpClient, getState }, dispatch, done) {
    const params = {
      old_password: oldPassword,
      password,
      password_confirmation: password,
    }
    const { id, uniqCode } = currentWorkspaceSelector(getState())
    try {
      const { data } = await httpClient.put(usersAccountPasswordRoute, params)
      const tokens = data.meta.jwt

      cookies('tokens', JSON.stringify(tokens), { path: `/${uniqCode}` })
      cookies('tokens', JSON.stringify(tokens), { path: COOKIES_PATH })

      dispatch(updateRecentWorkspace({ id, uniqCode, tokens }))
      dispatch(showNotification({ messageObject: { id: 'notifications.employeeAccount.passwordUpdated' } }))
      form.resetForm()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    done()
  },
})

export default updateEmployeePasswordOperation
