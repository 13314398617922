import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { shareEtherpadDocumentWithClientEndpoint } from '../endpoints'
import { SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT } from '../types'

const shareEtherpadDocumentWithClientOperation = createLogic({
  type: SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    const { clientId, documentId, role } = values
    const { url, endpoint } = shareEtherpadDocumentWithClientEndpoint(clientId, documentId)
    const include = 'document.shared-client-contacts,document.shared-client-profiles'
    const params = { role, include }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentWasSharedWithClient',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default shareEtherpadDocumentWithClientOperation
