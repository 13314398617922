import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBookingsRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import updateDataHelper from 'utils/updateDataHelper'
import { CHANGE_WORKSPACE_OWNERSHIP } from 'state/concepts/company/types'
import { showNotification } from 'state/notifications/actions'
import { resetSearchResults } from 'state/searchResults/actions'
import { currentEmployeeIdSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { hideModal } from 'state/modal/actions'
import { dataApiSuccess } from 'state/data/actions'
import { ownershipRoute } from 'lib/apiRoutes'

const changeCompanyOwnershipOperation = createLogic({
  type: CHANGE_WORKSPACE_OWNERSHIP,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    try {
      const state = getState()
      const params = {
        user_id: values.employeeId,
        password: values.password,
        keep_administrator_role: values.keepAdministrator,
      }

      await httpClient.put(ownershipRoute, params)

      const response = updateDataHelper(state.data, 'userProfile', currentEmployeeIdSelector(state), {
        attributes: {
          roleName: values.keepAdministrator ? 'administrator' : 'user',
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(resetSearchResults('employees'))
      dispatch(hideModal())

      redirect({ href: companyBookingsRoute, workspace: currentWorkspaceCodeSelector(state) })

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.ownershipTransferred' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default changeCompanyOwnershipOperation
