import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { any, compose, head, isEmpty, map, not, path, pipe, prop, sortBy, values } from 'ramda'
import build from 'redux-object'

import { AGENDA_LENGTH, RESOURCE_KEY, Views } from 'lib/constants/bookings'
import {
  fetchCalendarEventsEndpoint,
  validateCancelBookingEndpoint,
  validateRescheduleBookingEndpoint,
} from 'state/concepts/bookings/endpoints'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'
import { startAccessor } from 'utils/bookingCalendar'

export const calendarEventsIdsSelector = path(['bookings', 'calendarEventsIds'])

export const anyGoogleCalendarConnectedSelector = pipe(endpointMetaSelector, prop('anyGoogleCalendarConnected'))

export const anyOutlookCalendarConnectedSelector = pipe(endpointMetaSelector, prop('anyOutlookCalendarConnected'))

export const anyAppleCalendarConnectedSelector = pipe(endpointMetaSelector, prop('anyAppleCalendarConnected'))

export const isBlankStateSelector = path(['bookings', 'isBlankState'])

export const calendarEventsSelector = createSelector([calendarEventsIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'calendarEvent', ids, { includeType: true }) : [],
)

export const calendarEventsSourcesSelector = createSelector(
  calendarEventsSelector,
  map(({ source }) => ({ ...source, [RESOURCE_KEY]: source.userProfile.id })),
)

export const filtersSelector = path(['bookings', 'filters'])

export const hasFiltersSelector = pipe(filtersSelector, compose(any(compose(not, isEmpty)), values))

export const appliedFiltersSelector = (state, name) => path(['bookings', 'filters', name], state)

export const timezoneSelector = path(['bookings', 'timezone'])

export const selectedTimezoneSelector = createSelector(
  [timezoneSelector, currentEmployeeSelector],
  (timezone, currentEmployee) => timezone || prop('timezone', currentEmployee),
)

export const timezoneOffsetSelector = createSelector([selectedTimezoneSelector, dataSelector], (timezone, data) =>
  path(['timezone', timezone, 'attributes', 'offset'], data),
)

export const bookingsViewSelector = path(['persistedBookings', 'view'])

export const bookingsDateSelector = path(['bookings', 'date'])

export const datesFilterSelector = createSelector([bookingsViewSelector, bookingsDateSelector], (view, date) => {
  let startDate = DateTime.fromISO(date, { zone: 'UTC' })
  let endDate = startDate
  if (view === Views.WEEK) {
    if (startDate.weekdayShort !== 'Sun') {
      startDate = startDate.startOf('week').minus({ day: 1 })
    }
    endDate = startDate.plus({ days: 6 })
  }
  if (view === Views.AGENDA) {
    endDate = startDate.plus({ days: AGENDA_LENGTH })
  }

  return { startDate, endDate }
})

export const agendaDatesRangeSelector = createSelector(bookingsDateSelector, startDate => {
  const date = DateTime.fromISO(startDate, { zone: 'UTC' })

  let current = date
  const daysArray = []

  for (let i = 0; i <= AGENDA_LENGTH; i += 1) {
    daysArray.push(current)
    current = current.plus({ days: 1 })
  }

  return daysArray
})

export const firstEventTimeSelector = createSelector(
  calendarEventsSourcesSelector,
  compose(prop('startTime'), head, sortBy(startAccessor)),
)

export const calendarEventsLoadingSelector = state => loadingSelector(state, fetchCalendarEventsEndpoint.endpoint)

export const cancellationRulesSelector = path(['bookings', 'cancellationRules'])

export const cancellationValidationLoadingSelector = (state, bookingId) =>
  loadingSelector(state, validateCancelBookingEndpoint(bookingId).endpoint)

export const cancellationErrorSelector = path(['bookings', 'cancellationError'])

export const rescheduleValidationLoadingSelector = (state, bookingId) =>
  loadingSelector(state, validateRescheduleBookingEndpoint(bookingId).endpoint)

export const rescheduleErrorSelector = path(['bookings', 'rescheduleError'])

export const bookingByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'booking', id) : null,
)

export const selectedDateSelector = createSelector(bookingsDateSelector, DateTime.fromISO)

export const isWeekBookingsViewSelector = createSelector(bookingsViewSelector, view => view === Views.WEEK)
