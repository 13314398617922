import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { changeWidgetStatusEndpoint } from 'state/concepts/widget/endpoints'
import { TOGGLE_WIDGET_STATUS } from 'state/concepts/widget/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { widgetSelector } from 'state/concepts/widget/selectors'

const toggleWidgetStatusOperation = createLogic({
  type: TOGGLE_WIDGET_STATUS,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const { widgetId } = action
    const { url, endpoint } = changeWidgetStatusEndpoint(widgetId)

    try {
      const state = getState()
      const { status } = widgetSelector(state, widgetId)
      const isActive = status === 'active'

      const params = {
        status: isActive ? 'inactive' : 'active',
      }

      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'widget', widgetId, {
        attributes: {
          status: params.status,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingPageWasUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default toggleWidgetStatusOperation
