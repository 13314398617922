import { createLogic } from 'redux-logic'
import cookies from 'next-cookies'

import isExpired from 'utils/session/isExpired'
import { CABLE_HOST } from 'lib/constants'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { WS_STATUSES, MESSAGE_TYPES, COMMANDS } from 'lib/constants/webSockets'
import { webSocketsAuthenticateRoute, webSocketsPublicAuthenticateRoute } from 'lib/apiRoutes'
import { toCommand, toIdentifier, parseData, parseIdentifier } from 'utils/webSockets'
import ConnectionMonitor from 'lib/ConnectionMonitor'
import SubscriptionGuarantor from 'lib/SubscriptionGuarantor'
import { SET_OFFLINE } from 'state/concepts/chat/types'
import { setOffline } from 'state/concepts/chat/actions'
import { setConnectionStatus, resetSubscriptionChannels } from '../actions'
import { OPEN_CONNECTION, CLOSE_CONNECTION, SEND_COMMAND } from '../types'

const openConnection = createLogic({
  type: OPEN_CONNECTION,
  cancelType: CLOSE_CONNECTION,
  latest: true,
  warnTimeout: 0,

  async process({ httpClient, action$, cancelled$ }, dispatch, done) {
    let socket

    try {
      const { tokens, guestSession } = cookies({})
      const isAuthenticated = isExpired(tokens) && !guestSession?.token

      const url = isAuthenticated ? webSocketsPublicAuthenticateRoute : webSocketsAuthenticateRoute

      const {
        data: {
          meta: { token },
        },
      } = await httpClient.post(url, {}, buildCustomBaseUrl())

      socket = new WebSocket(`${CABLE_HOST}?token=${token}`)
      const responseHandlers = {}

      const connectionMonitor = new ConnectionMonitor(() => {
        dispatch(setOffline())
      })

      const guarantor = new SubscriptionGuarantor(({ command }) => {
        socket.send(command)
      })

      socket.onopen = () => {
        dispatch(setConnectionStatus(WS_STATUSES.opened))
      }

      socket.onclose = () => {
        dispatch(setConnectionStatus(WS_STATUSES.closed))
        dispatch(resetSubscriptionChannels())
        connectionMonitor.stop()
        guarantor.forgetAll()
        done()
      }

      socket.onmessage = ({ data }) => {
        const identifier = parseIdentifier(data)
        const onReceived = responseHandlers[identifier]
        const { type, message } = parseData(data)

        connectionMonitor.recordPing()

        if (type === MESSAGE_TYPES.welcome) {
          connectionMonitor.start()
        }

        if ([MESSAGE_TYPES.confirmSubscription, MESSAGE_TYPES.rejectSubscription].includes(type)) {
          guarantor.forget(identifier)
        }

        if (onReceived && message) {
          onReceived(message)
        }
      }

      action$.subscribe(action => {
        if (action.type === SEND_COMMAND && socket.readyState === 1) {
          const command = toCommand(action)
          const identifier = toIdentifier(action)

          if (action.onReceived) {
            responseHandlers[identifier] = action.onReceived
          }

          socket.send(command)

          if (action.command === COMMANDS.subscribe) {
            guarantor.guarantee({ identifier, command })
          }

          if (action.command === COMMANDS.unsubscribe) {
            guarantor.forget(identifier)
          }
        }

        if (action.type === SET_OFFLINE) {
          socket.close()
          dispatch(setConnectionStatus(WS_STATUSES.closed))
          dispatch(resetSubscriptionChannels())
          done()
        }
      })

      cancelled$.subscribe(() => {
        socket.close()
        dispatch(setConnectionStatus(WS_STATUSES.closed))
        dispatch(resetSubscriptionChannels())
      })
    } catch {
      socket?.close()
      dispatch(setConnectionStatus(WS_STATUSES.closed))
      dispatch(resetSubscriptionChannels())
      done()
    }
  },
})

export default openConnection
