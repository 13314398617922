import PropTypes from 'prop-types'
import classNames from 'clsx'
import { path, isNil } from 'ramda'

import profileInitials from 'utils/profileInitials'

import Time from 'views/shared/icons/Time'
import User from 'views/shared/icons/User'

const AvatarComponent = ({ avatarClassName, sizeClassName, profile, additionalProfile, imageSize, ...rest }) => {
  if (!profile) {
    return null
  }

  const { invited } = profile
  const avatar = path(['avatarUrls', imageSize], profile)
  const initials = profileInitials(profile)
  const avatarClassNames = classNames(avatarClassName, 'main-userpic', sizeClassName, {
    'main-userpic--initials': !avatar && !invited,
    'main-userpic--additional': additionalProfile,
    relative: !avatar,
  })

  if (!isNil(avatar)) {
    return <div {...rest} className={avatarClassNames} style={{ backgroundImage: `url(${avatar})` }} />
  }

  return (
    <div {...rest} className={avatarClassNames}>
      {invited ? (
        <span>
          <User />
          <Time className="main-userpic__status" />
        </span>
      ) : (
        <p className="main-userpic__initials" data-cy="user-initials">
          {initials}
        </p>
      )}
    </div>
  )
}

AvatarComponent.defaultProps = {
  avatarClassName: null,
  sizeClassName: null,
  imageSize: 'original',
  profile: null,
  additionalProfile: false,
}

AvatarComponent.propTypes = {
  profile: PropTypes.shape(),
  avatarClassName: PropTypes.string,
  sizeClassName: PropTypes.string,
  imageSize: PropTypes.string,
  additionalProfile: PropTypes.bool,
}

export default AvatarComponent
