import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { CONCEPT_TYPES } from 'lib/constants/businessFiles'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { insertBeforeMyBusinessFileId } from '../actions'
import { duplicateMyBusinessFileEndpoint } from '../endpoints'
import { DUPLICATE_MY_BUSINESS_FILE } from '../types'

const duplicateMyBusinessFileOperation = createLogic({
  type: DUPLICATE_MY_BUSINESS_FILE,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { myBusinessFileId, conceptType } = action
    const { url, endpoint } = duplicateMyBusinessFileEndpoint(myBusinessFileId)
    const params = { include: ['ancestors-permissions.user-profile', 'business-storage-permissions.user-profile'] }
    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(insertBeforeMyBusinessFileId(data.data.id, myBusinessFileId))
      dispatch(
        showNotification({
          messageObject: {
            id:
              conceptType === CONCEPT_TYPES.myFiles
                ? 'notifications.myBusinessFileWasCopied'
                : 'notifications.fileSharedWithMeWasCopied',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default duplicateMyBusinessFileOperation
