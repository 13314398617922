import {
  publicWidgetProvidersRoute,
  websiteRoute,
  publicWidgetServicesRoute,
  publicWebsiteReviewsRoute,
  publicWidgetLocationsRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import {
  FETCH_PUBLIC_PROVIDERS,
  FETCH_WEBSITE,
  DELETE_WEBSITE,
  FETCH_PUBLIC_SERVICES,
  FETCH_PUBLIC_WEBSITE_REVIEWS,
  FETCH_PUBLIC_LOCATIONS,
} from './types'

export const fetchWebsiteEndpoint = endpoint(FETCH_WEBSITE, 'GET', websiteRoute)

export const fetchPublicProvidersEndpoint = (workspaceCode, widgetId) =>
  endpoint(FETCH_PUBLIC_PROVIDERS, 'GET', publicWidgetProvidersRoute(workspaceCode, widgetId))

export const fetchPublicServicesEndpoint = (workspaceCode, widgetId) =>
  endpoint(FETCH_PUBLIC_SERVICES, 'GET', publicWidgetServicesRoute(workspaceCode, widgetId))

export const deleteWebsiteEndpoint = endpoint(DELETE_WEBSITE, 'DELETE', websiteRoute)
export const fetchPublicWebsiteReviewsEndpoint = (workspaceCode, widgetId) =>
  endpoint(FETCH_PUBLIC_WEBSITE_REVIEWS, 'GET', publicWebsiteReviewsRoute(workspaceCode, widgetId))
export const fetchPublicLocationsEndpoint = (workspaceCode, widgetId) =>
  endpoint(FETCH_PUBLIC_LOCATIONS, 'GET', publicWidgetLocationsRoute(workspaceCode, widgetId))
