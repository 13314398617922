import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { DEACTIVATE_SERVICE_CATEGORIES } from 'state/concepts/serviceCategories/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { deactivateServiceCategoriesEndpoint } from 'state/concepts/serviceCategories/endpoints'

const deactivateServiceCategoriesOperation = createLogic({
  type: DEACTIVATE_SERVICE_CATEGORIES,
  latest: true,

  async process({ httpClient, getState, action: { serviceCategoriesIds } }, dispatch, done) {
    const { url, endpoint } = deactivateServiceCategoriesEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url, { service_category_ids: serviceCategoriesIds })
      const state = getState()

      const response = updateDataHelper(state.data, 'serviceCategory', serviceCategoriesIds, {
        attributes: { status: 'inactive' },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.serviceCategoriesDeactivated',
            values: { count: serviceCategoriesIds.length },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deactivateServiceCategoriesOperation
