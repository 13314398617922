import { createLogic } from 'redux-logic'

import { fetchTrashedFiles, setFilterParams } from '../actions'
import { FILTER_TRASHED_FILES } from '../types'

const filterTrashedFilesOperation = createLogic({
  type: FILTER_TRASHED_FILES,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(fetchTrashedFiles(undefined, { append: false }))
    done()
  },
})

export default filterTrashedFilesOperation
