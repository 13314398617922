const serviceCategories = {
  'serviceCategories.title': 'Categories',
  'serviceCategories.empty': 'There are no categories yet.',
  'serviceCategories.table.category': 'Category',
  'serviceCategories.table.service': '_(Service)',
  'serviceCategories.table.status': 'Status',
  'serviceCategories.addCategory': 'Add category',
  'serviceCategories.activate.modal.title': 'Activate category',
  'serviceCategories.activate.modal.body': 'Are you sure you want to activate {name}?',
  'serviceCategories.deactivate.modal.title': 'Deactivate category',
  'serviceCategories.deactivate.modal.body': 'Are you sure you want to deactivate {name}?',
  'serviceCategories.deactivate.modal.allChildServices': 'All child _(services) will also be deactivated.',
  'serviceCategories.deactivate.modal.activeBookings':
    'Active _(appointments) will remain but _(clients) will not be able to book _(services) of this category.',
  'serviceCategories.createCategory.modal.title': 'Add category',
  'serviceCategories.editCategory.modal.title': 'Edit category',
  'serviceCategories.selectColors.modal.label': 'Select category color:',
  'serviceCategories.selectColors.modal.placeholder': 'Add category name',
  'serviceCategories.remove.modal.body': 'Are you sure you want to remove the {name} _(service) category?',
  'serviceCategories.remove.modal.title': 'Remove category',
  'serviceCategories.selectedCount':
    '{count, number} {count, plural, one {category is} other {categories are}} selected',
  'serviceCategories.bulkActivate.modal.title': 'Activate categories',
  'serviceCategories.bulkActivate.modal.body':
    'Are you sure you want to activate {count, number} {count, plural, one {category} other {categories}}?',
  'serviceCategories.bulkDeactivate.modal.title': 'Deactivate categories',
  'serviceCategories.bulkDeactivate.modal.body.start': 'Are you sure you want to deactivate ',
  'serviceCategories.bulkDeactivate.modal.body.boldText':
    '{count, number} {count, plural, one {category} other {categories}}',
  'serviceCategories.bulkDeactivate.modal.body.final': '?',
  'serviceCategories.bulkDeactivate.modal.allChildServices': 'All child _(services) will also be deactivated.',
  'serviceCategories.bulkDeactivate.modal.activeBookings':
    'Active _(appointments) will remain but _(clients) will not be able to book _(services) of these categories.',
  'serviceCategories.bulkRemove.modal.title': 'Remove categories',
  'serviceCategories.bulkRemove.modal.body.start': 'Are you sure you want to remove ',
  'serviceCategories.bulkRemove.modal.body.categoriesWithService':
    'There are some _(services) which belong to selected categories.',
  'serviceCategories.bulkRemove.modal.body.someCategoryWithService':
    'There are some _(services) which belong to this category.',
  'serviceCategories.bulkRemove.modal.body.someCategoriesWithServices': 'There are some _(services) which belong to ',
  'serviceCategories.bulkRemove.modal.body.removeService':
    'Please remove the _(services) or change their category first.',
  'serviceCategories.bulkRemove.modal.body.removeCategory': 'Are you sure you want to remove this category?',
  'serviceCategories.bulkRemove.modal.body.categoriesWithServices':
    '{count, number} {count, plural, one {category} other {categories}}.',
  'serviceCategories.bulkRemove.modal.body.categoriesWithoutServices':
    '{count, number} {count, plural, one {category} other {categories}}?',
  'serviceCategories.bulkRemove.modal.manageServicesButton': 'Manage _(services)',
}

export default serviceCategories
