import { DOCUMENT_TEMPLATE_STATUS_FILTER_OPTIONS, DOCUMENT_TEMPLATE_STATUSES } from 'lib/constants/documentTemplates'

export const filterLabel = status =>
  DOCUMENT_TEMPLATE_STATUS_FILTER_OPTIONS.find(option => option.value === status).label

export const isActive = status => status === DOCUMENT_TEMPLATE_STATUSES.active
export const isInactive = status => status === DOCUMENT_TEMPLATE_STATUSES.inactive

export const documentTemplateFiltersToParams = filters => ({
  name: filters.name,
  status: filters.status === DOCUMENT_TEMPLATE_STATUSES.allStatuses ? undefined : filters.status,
})
