import { createLogic } from 'redux-logic'
import { identity, ifElse, isNil } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_LOGOUT_INTERVAL } from 'state/concepts/employee/types'
import { showNotification } from 'state/notifications/actions'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { dataApiFailure, dataApiRequest, dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { usersAccountLogoutIntervalEndpoint } from '../endpoints'

const updateLogoutIntervalOperation = createLogic({
  type: UPDATE_LOGOUT_INTERVAL,
  latest: true,

  async process({ action: { logoutInterval, isLogoutEnabled, form }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = usersAccountLogoutIntervalEndpoint

    const params = { logout_interval: logoutInterval }

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.put(url, params)
      const response = updateDataHelper(getState().data, 'userProfile', currentEmployeeIdSelector(getState()), {
        attributes: {
          logoutInterval: ifElse(isNil, identity, Number)(logoutInterval),
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: { id: `notifications.automaticLogout${isLogoutEnabled ? 'Enabled' : 'Disabled'}` },
        }),
      )
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }))
      requestErrorHandler({ error, dispatch, form })
    }
    done()
  },
})

export default updateLogoutIntervalOperation
