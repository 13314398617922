import classNames from 'clsx'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import isPresent from 'utils/isPresent'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'

const PageTitleComponent = ({ pageTitle, pageSubTitle, badgeTitle, badgeClassName }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <h1 className="font-600 mb-0 text-headline d-flex align-items-center">
        <TruncatedTextByWidthTooltip
          overlayClassName="main-tooltip main-tooltip--no-pointer max-w-75"
          placement="topLeft"
          text={pageTitle?.id ? formatMessage(pageTitle, pageTitle?.values) : pageTitle}
          on={['hover']}
          align={{ offset: [0, 0] }}
        />

        {isPresent(pageSubTitle) && (
          <span className="in-gray-700 ml-12" data-cy="page-header-items-count">
            <FormattedOrRawMessage message={pageSubTitle} />
          </span>
        )}
      </h1>
      {badgeTitle && (
        <span className={classNames('main-badge', badgeClassName)} data-cy="payment-status">
          <FormattedOrRawMessage message={badgeTitle} />
        </span>
      )}
    </>
  )
}

PageTitleComponent.defaultProps = {
  pageTitle: null,
  pageSubTitle: null,
  badgeTitle: null,
  badgeClassName: '',
}

PageTitleComponent.propTypes = {
  pageTitle: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  pageSubTitle: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.number]),
  badgeTitle: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  badgeClassName: PropTypes.string,
}

export default PageTitleComponent
