const reviewSettings = {
  'reviewSettings.buttonText': 'Review settings',
  'reviewSettings.sendingDelay.right': 'Right',
  'reviewSettings.sendingDelay.oneHour': '1 hour',
  'reviewSettings.sendingDelay.threeHours': '3 hours',
  'reviewSettings.sendingDelay.oneDay': '1 day',
  'reviewSettings.frequency.perAppointment': 'After each _(appointment)',
  'reviewSettings.frequency.perAppointment.descr':
    'Ask _(client) to leave a review after each completed _(appointment)',
  'reviewSettings.frequency.perClient': 'After the first _(appointment)',
  'reviewSettings.frequency.perClient.descr':
    'Ask _(client) to leave a review after first _(appointment) with a new _(expert) and _(service)',
  'reviewSettings.updateSettings': 'Update review settings',
  'reviewSettings.collectReviews': 'Collecting reviews',
  'reviewSettings.collectReviewsLabel': 'Send an email after _a(appointment) to ask for a review',
  'reviewSettings.setFrequency': 'Set how frequent _(client) should receive the review form',
  'reviewSettings.sendingDelay': 'Set time of sending review request to _(client)',
  'reviewSettings.formName': 'Form name',
  'reviewSettings.formDescription': 'Description (optional)',
  'reviewSettings.formDescription.placeholder': 'This text will appear on each receipt',
  'reviewSettings.afterAppointment': 'after the _(appointment).',
  'reviewSettings.reviewTemplate': 'Review template',
  'reviewSettings.reviewTemplate.description':
    'Manage and edit auto-reply emails, reminders & notifications sent to your customers.',
  'reviewSettings.reviewSettingsOnboarding':
    'Decide how often you’ll ask _(clients) to leave a review and customize your message.',
  'reviewSettings.titleReviewsOnboarding':
    'Reviews help you keep track of _(client) satisfaction and increase trust of prospective customers.',
  'reviewSettings.toggleReviewsOnboarding': 'Increase customer trust by publishing reviews on your booking page.',
}

export default reviewSettings
