import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import CompanySection from '../CompanySection'
import BillSection from '../BillSection'
import TableSection from '../TableSection'
import TotalSection from '../TotalSection'

const CreditNoteComponent = ({
  amount,
  transactionNumber,
  issueDate,
  receiptTemplate,
  clientProfile,
  transactionItems,
  paymentMethod,
  refundedTransaction: { transactionNumber: refundedNumber, issueDate: refundedIssueDate },
}) => (
  <>
    <CompanySection receiptTemplate={receiptTemplate}>
      <p className="preview-invoice__title">
        <FormattedMessage id="payments.review.creditNoteNumber" values={{ transactionNumber }} />
      </p>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="payment-issue-date-title">
          <FormattedMessage id="payments.review.issueDate" />
        </span>
        <span data-cy="payment-issue-date">{issueDate}</span>
      </div>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="original-invoice-title">
          <FormattedMessage id="payments.review.originalInvoice" />
        </span>
        <span data-cy="original-invoice-number">{refundedNumber}</span>
      </div>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="original-invoice-date-title">
          <FormattedMessage id="payments.review.originalIssueDate" />
        </span>
        <span data-cy="original-invoice-date">{refundedIssueDate}</span>
      </div>
    </CompanySection>
    <div className="divider mb-24 mt-16" />
    <BillSection clientProfile={clientProfile} paymentMethod={paymentMethod} />
    <TableSection transactionItems={transactionItems} />
    <div className="preview-invoice__payment-total-wrap">
      <TotalSection
        priceClassName="payment-total-price__red"
        title={{ id: 'payments.review.total.refundAmount' }}
        amount={amount}
      />
      <TotalSection
        className="font-600"
        priceClassName="payment-total-price__red"
        title={{ id: 'payments.review.total.refundedAmount' }}
        amount={amount}
      />
    </div>
    <div className="divider mt-24 mb-24" />
    <div className="main-card__container">
      <p className="preview-invoice__subtitle" data-cy="refund-information-title">
        <FormattedMessage id="payments.review.refundInformation" />
      </p>
      <p className="mb-0" data-cy="refund-information-message">
        <FormattedMessage id="payments.review.refundForPayment" values={{ refundedNumber }} />
      </p>
    </div>
  </>
)

CreditNoteComponent.propTypes = {
  refundedTransaction: PropTypes.shape().isRequired,
  clientProfile: PropTypes.shape().isRequired,
  receiptTemplate: PropTypes.shape().isRequired,
  transactionItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amount: PropTypes.string.isRequired,
  issueDate: PropTypes.string.isRequired,
  transactionNumber: PropTypes.string.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
}

export default CreditNoteComponent
