import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_RECURRING_AVAILABILITY } from 'state/concepts/recurringAvailability/types'
import { showNotification } from 'state/notifications/actions'
import { updateRecurringAvailabilityEndpoint } from 'state/concepts/recurringAvailability/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideSidebar } from 'state/sidebar/actions'
import { setRecurringAvailability } from 'state/concepts/recurringAvailability/actions'

const updateRecurringAvailabilityOperation = createLogic({
  type: UPDATE_RECURRING_AVAILABILITY,
  latest: true,

  async process({ action: { availabilities, userId, form }, httpClient }, dispatch, done) {
    const { endpoint, url } = updateRecurringAvailabilityEndpoint(userId)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        recurring_availabilities: availabilities,
        include: 'locations',
      }
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(hideSidebar())
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setRecurringAvailability(map(prop('id'), data.data)))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.regularAvailabilityUpdated' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form?.setSubmitting(false)
    }
    done()
  },
})

export default updateRecurringAvailabilityOperation
