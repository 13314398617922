import endpoint from 'utils/endpoint'

import {
  serviceCategoriesRoute,
  serviceCategoryRoute,
  serviceCategoriesBulkActionsDeactivationRoute,
  serviceCategoriesBulkActionsActivationRoute,
  serviceCategoriesBulkActionsRemoveRoute,
} from 'lib/apiRoutes'
import {
  GET_SERVICE_CATEGORIES,
  ACTIVATE_SERVICE_CATEGORIES,
  DEACTIVATE_SERVICE_CATEGORIES,
  REMOVE_SERVICE_CATEGORIES,
  CREATE_SERVICE_CATEGORY,
  EDIT_SERVICE_CATEGORY,
} from './types'

export const getServiceCategoriesEndpoint = endpoint(GET_SERVICE_CATEGORIES, 'GET', serviceCategoriesRoute)
export const activateServiceCategoriesEndpoint = endpoint(
  ACTIVATE_SERVICE_CATEGORIES,
  'POST',
  serviceCategoriesBulkActionsActivationRoute,
)
export const deactivateServiceCategoriesEndpoint = endpoint(
  DEACTIVATE_SERVICE_CATEGORIES,
  'POST',
  serviceCategoriesBulkActionsDeactivationRoute,
)
export const removeServiceCategoriesEndpoint = endpoint(
  REMOVE_SERVICE_CATEGORIES,
  'POST',
  serviceCategoriesBulkActionsRemoveRoute,
)
export const createServiceCategoryEndpoint = endpoint(CREATE_SERVICE_CATEGORY, 'POST', serviceCategoriesRoute)
export const editServiceCategoryEndpoint = categoryId =>
  endpoint(EDIT_SERVICE_CATEGORY, 'PUT', serviceCategoryRoute(categoryId))
