import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { companyBookingRoute } from 'lib/routes'
import { currentEmployeeSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { isAvailable } from 'utils/bookings'
import isPresent from 'utils/isPresent'
import isAdminOrOwnerOrScheduler from 'utils/employeePermissions/isAdminOrOwnerOrScheduler'
import TooltipContentComponent from './component'

class TooltipContent extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    booking: PropTypes.shape({
      location: PropTypes.shape(),
      id: PropTypes.string.isRequired,
      uniqCode: PropTypes.string.isRequired,
      userProfile: PropTypes.shape().isRequired,
      invitedMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      service: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    currentEmployee: PropTypes.shape().isRequired,
    workspaceCode: PropTypes.string.isRequired,
  }

  get isBookingParticipant() {
    const { currentEmployee, booking } = this.props

    return currentEmployee.id === booking.userProfile.id
  }

  get showAllMembers() {
    const { currentEmployee } = this.props

    return isAdminOrOwnerOrScheduler(currentEmployee)
  }

  get canJoinConference() {
    const { booking } = this.props

    return isAvailable(booking) && this.isBookingParticipant && !isPresent(booking.location)
  }

  get bookingPagePath() {
    const { booking } = this.props

    return {
      href: companyBookingRoute(booking.uniqCode),
    }
  }

  render() {
    return (
      <TooltipContentComponent
        {...this.props}
        isBookingParticipant={this.isBookingParticipant}
        showAllMembers={this.showAllMembers}
        canJoinConference={this.canJoinConference}
        bookingPagePath={this.bookingPagePath}
      />
    )
  }
}

const mapStateToProps = state => ({
  currentEmployee: currentEmployeeSelector(state),
  workspaceCode: currentWorkspaceCodeSelector(state),
})

export { TooltipContent as TooltipContentContainer }
export default connect(mapStateToProps)(TooltipContent)
