import {
  clientDocumentPermissionsRoute,
  clientDocumentsRoute,
  clientsDocumentDownloadRoute,
  clientsDocumentDuplicateRoute,
  clientsDocumentPermissionRoute,
  clientsDocumentReviewRoute,
  clientsDocumentRoute,
  clientsDocumentVoidRoute,
  clientsDocumentSignatoriesRoute,
  clientsDocumentRemindToSignRoute,
  clientsDocumentSignRoute,
  clientsDocumentTouchRoute,
  clientsDocumentDeclineRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const fetchEtherpadDocumentsEndpoint = clientId =>
  endpoint(types.FETCH_ETHERPAD_DOCUMENTS, 'GET', clientDocumentsRoute(clientId))

export const duplicateEtherpadDocumentEndpoint = documentId =>
  endpoint(types.DUPLICATE_ETHERPAD_DOCUMENT, 'POST', clientsDocumentDuplicateRoute(documentId))

export const downloadEtherpadDocumentEndpoint = documentId =>
  endpoint(types.DOWNLOAD_ETHERPAD_DOCUMENT, 'GET', clientsDocumentDownloadRoute(documentId))

export const renameEtherpadDocumentEndpoint = documentId =>
  endpoint(types.RENAME_ETHERPAD_DOCUMENT, 'PUT', clientsDocumentRoute(documentId))

export const voidEtherpadDocumentEndpoint = documentId =>
  endpoint(types.VOID_ETHERPAD_DOCUMENT, 'PUT', clientsDocumentVoidRoute(documentId))

export const signEtherpadDocumentEndpoint = documentId =>
  endpoint(types.SIGN_ETHERPAD_DOCUMENT, 'POST', clientsDocumentSignRoute(documentId))

export const createEtherpadDocumentEndpoint = clientId =>
  endpoint(types.CREATE_ETHERPAD_DOCUMENT, 'POST', clientDocumentsRoute(clientId))

export const remindToSignEtherpadDocumentEndpoint = documentId =>
  endpoint(types.REMIND_TO_SIGN_ETHERPAD_DOCUMENT, 'POST', clientsDocumentRemindToSignRoute(documentId))

export const getEtherpadDocumentEndpoint = documentId =>
  endpoint(types.GET_ETHERPAD_DOCUMENT, 'GET', clientsDocumentRoute(documentId))

export const reviewEtherpadDocumentEndpoint = documentId =>
  endpoint(types.REVIEW_ETHERPAD_DOCUMENT, 'GET', clientsDocumentReviewRoute(documentId))

export const shareEtherpadDocumentWithClientEndpoint = (clientId, documentId) =>
  endpoint(types.SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT, 'POST', clientDocumentPermissionsRoute(clientId, documentId))
export const updateEtherpadDocumentClientAccessEndpoint = ({ clientId, documentId, permissionId }) =>
  endpoint(
    types.UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS,
    'PUT',
    clientsDocumentPermissionRoute({ clientId, documentId, permissionId }),
  )

export const removeEtherpadDocumentClientAccessEndpoint = ({ clientId, documentId, permissionId }) =>
  endpoint(
    types.REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS,
    'DELETE',
    clientsDocumentPermissionRoute({ clientId, documentId, permissionId }),
  )
export const deleteEtherpadDocumentEndpoint = documentId =>
  endpoint(types.DELETE_ETHERPAD_DOCUMENT, 'DELETE', clientsDocumentRoute(documentId))
export const addSignatoriesEndpoint = documentId =>
  endpoint(types.ADD_SIGNATORIES, 'POST', clientsDocumentSignatoriesRoute(documentId))

export const updateSignatoriesEndpoint = documentId =>
  endpoint(types.UPDATE_SIGNATORIES, 'PUT', clientsDocumentSignatoriesRoute(documentId))

export const touchEtherpadDocumentEndpoint = documentId =>
  endpoint(types.TOUCH_ETHERPAD_DOCUMENT, 'PUT', clientsDocumentTouchRoute(documentId))
export const declineDocumentEndpoint = documentId =>
  endpoint(types.DECLINE_TO_SIGN_ETHERPAD_DOCUMENT, 'PUT', clientsDocumentDeclineRoute(documentId))
