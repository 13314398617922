const websiteFeature = {
  'websiteFeature.subtitle': '<span>in</span> Website',
  'websiteFeature.description': 'Set up a professional-looking booking website.',
  'websiteFeature.manageFeature': 'Set up website',
  'websiteFeature.overview': 'Website overview',
  'websiteFeature.secondDescription':
    'Start establishing your online presence and set up a professional-looking website in no time — no programming skills required. Your booking website will be highly customizable and offer everything you need to introduce your company, _(services), and _(experts) as well as to accept online bookings 24/7.',
  'websiteFeature.setUp': 'Set up your booking website with minimal effort:',
  'websiteFeature.chooseStyle': 'Choose the style of your website with one of our custom backgrounds.',
  'websiteFeature.editWebsite': 'Edit your website content, including the header and subheader.',
  'websiteFeature.decideWhatToShow':
    'Decide which information you want to display: team, _(services), locations, _(client) reviews, and more.',
  'websiteFeature.policy': 'Add privacy policy and legal terms, adapting our templates to your business needs.',
  'websiteFeature.improveVisibility': 'Improve your site’s visibility:',
  'websiteFeature.linkWebsite':
    'Let your potential _(clients) easily find your website using your workspace subdomain.',
  'websiteFeature.appearInSearch':
    'Choose how your website will appear in search results by editing your page title and meta description.',
  'websiteFeature.thirdDescription':
    'Once you’ve finished setting up your website, preview it to make sure you’re happy with the results, publish it, and start getting new _(clients)!',
  'websiteFeature.toLearnMores':
    'To learn more, read our help center articles on <setupLink>how to set up your website</setupLink> and <customizeLink>customize your website</customizeLink>.',
}

export default websiteFeature
