import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const PaperClip = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 16 20"
    data-cy={dataCy}
  >
    <path d="M12.75 0.75C11.6415 0.75 10.5523 1.1801 9.71385 2.01854L2.6826 9.04979C0.355475 11.3769 0.355475 15.1622 2.6826 17.4893C5.00972 19.8164 8.79497 19.8164 11.1221 17.4893L15.9864 12.625L14.5781 11.2168L9.71385 16.081C8.15621 17.6387 5.64848 17.6387 4.09084 16.081C2.5332 14.5234 2.5332 12.0157 4.09084 10.458L11.1221 3.42678C12.0323 2.5166 13.4912 2.5166 14.4013 3.42678C15.3115 4.33696 15.3115 5.79585 14.4013 6.70604L7.3701 13.7373C7.10738 14 6.69731 14 6.43459 13.7373C6.17187 13.4746 6.17187 13.0645 6.43459 12.8018L13.0567 6.17969L11.6484 4.77145L5.02635 11.3935C3.99415 12.4257 3.99415 14.1133 5.02635 15.1455C6.05855 16.1777 7.74614 16.1777 8.77834 15.1455L15.8096 8.11428C17.4893 6.43461 17.4893 3.6982 15.8096 2.01854C14.9701 1.17904 13.857 0.75 12.75 0.75Z" />
  </svg>
)

PaperClip.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

PaperClip.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default PaperClip
