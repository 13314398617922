import { combineReducers } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import * as conceptsReducers from '../concepts'
import dataReducer from '../data/reducer'
import intlReducer from '../intl/reducer'
import notificationsReducer from '../notifications/reducer'
import modalReducer from '../modal'
import sidebarReducer from '../sidebar'
import searchResultsReducer from '../searchResults'
import tableDataReducer from '../tableData'
import navigationReducer from '../navigation'
import { RESET_STORE } from './types'

const appReducer = combineReducers({
  ...conceptsReducers,
  data: dataReducer,
  intl: intlReducer,
  notifications: notificationsReducer,
  modal: modalReducer,
  sidebar: sidebarReducer,
  searchResults: searchResultsReducer,
  tableData: tableDataReducer,
  navigation: navigationReducer,
  isHydrated: (state = false) => state,
})

const mainReducer = (state, action) => {
  switch (action.type) {
    case RESET_STORE:
      return appReducer(undefined, action)
    case HYDRATE:
      // https://github.com/kirill-konshin/next-redux-wrapper/issues/280
      if (state.isHydrated) {
        return state
      }

      return { ...state, ...action.payload, isHydrated: true }
    default:
      return appReducer(state, action)
  }
}

export default mainReducer
