import { path } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { SUBMIT_FORM } from 'state/concepts/form/types'
import {
  currentWorkspaceCodeSelector,
  isEmployeeLoggedInSelector,
  isGuestLoggedInSelector,
} from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import { formsSignRoute, formsSubmitSuccessRoute } from 'utils/forms'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { formSubmissionParams } from 'utils/intakeForms'
import redirect from 'utils/redirect'
import { cancelAutoSaveResponses } from '../actions'
import { submitFormEndpoint } from '../endpoints'

const submitFormOperation = createLogic({
  type: SUBMIT_FORM,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { url } = submitFormEndpoint
    const state = getState()

    const workspaceCode = currentWorkspaceCodeSelector(state)
    const isEmployeeLoggedIn = isEmployeeLoggedInSelector(state)
    const isGuestLoggedIn = isGuestLoggedInSelector(state)
    const { formType } = values

    try {
      dispatch(cancelAutoSaveResponses())
      const { data } = await httpClient.post(url, formSubmissionParams(values))

      const nextFormId = path(['meta', 'next_form_id'], data)
      const nextFormStatus = path(['meta', 'next_form_status'], data)

      redirect({
        href: values.isSignatureNeeded
          ? formsSignRoute({ isEmployeeLoggedIn, isGuestLoggedIn, workspaceCode, clientProfileFormId: values.id })
          : formsSubmitSuccessRoute({ isEmployeeLoggedIn, isGuestLoggedIn, workspaceCode }),
        workspace: isEmployeeLoggedIn ? workspaceCode : null,
        query: { bookingId: values.bookingId, nextFormId, nextFormStatus, formType },
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)

      if (isErrorStatusUnprocessableEntity(error)) {
        const { base } = formatJsonApiErrors(error.response.data.errors)

        if (base) {
          dispatch(showNotification({ messageObject: base, kind: 'error' }))
        }
      }
    }

    done()
  },
})

export default submitFormOperation
