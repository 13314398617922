import { combineReducers } from '@reduxjs/toolkit'

import { RESET_DATE_AVAILABILITY, RESET_FILTERS, SET_DATE_AVAILABILITY, SET_FILTER_PARAMS, SET_TIMEZONE } from './types'

const dateAvailabilityIds = (state = [], action) => {
  switch (action.type) {
    case SET_DATE_AVAILABILITY:
      return action.dateAvailabilityIds
    case RESET_DATE_AVAILABILITY:
      return []
    default:
      return state
  }
}

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezone
    default:
      return state
  }
}

const filtersInitialState = {
  locations: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return filtersInitialState
    default:
      return state
  }
}

const dateAvailabilityReducer = combineReducers({
  dateAvailabilityIds,
  timezone,
  filters,
})

export default dateAvailabilityReducer
