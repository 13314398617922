import { createLogic } from 'redux-logic'
import { keys, omit } from 'ramda'
import { snakeCase } from 'lodash'

import safeTrim from 'lib/safeTrim'
import requestErrorHandler from 'lib/requestErrorHandler'
import { workspaceRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_COMPANY_PROFILE } from 'state/concepts/company/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'

const updateCompanyProfileOperation = createLogic({
  type: UPDATE_COMPANY_PROFILE,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()

    const formData = new FormData()
    const attributes = {}

    if (values.logo === null) {
      formData.append('remove_logo', true)
      attributes.logoUrl = null
    } else if (values.logo instanceof Blob) {
      formData.append('logo', values.logo, values.logo.name)
      attributes.logoUrl = values.logoUrl
    }

    const valueKeys = keys(omit(['logoUrl', 'logo', 'invoicingEnabled', 'googleAddress'], values))
    valueKeys.forEach(key => {
      formData.append(snakeCase(key), safeTrim(values[key]))
      attributes[key] = safeTrim(values[key])
    })

    try {
      await httpClient.put(workspaceRoute, formData)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.companyProfileUpdated' },
        }),
      )

      const response = updateDataHelper(state.data, 'workspace', currentWorkspaceIdSelector(state), { attributes })

      dispatch(dataApiSuccess({ response }))
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateCompanyProfileOperation
