const acceptVideoCallsFeature = {
  'acceptVideoCallsFeature.subtitle': '<span>in</span> Video calls',
  'acceptVideoCallsFeature.description': 'Have high-quality video meetings with _(clients) inside your workspace.',
  'acceptVideoCallsFeature.manageFeatures': 'Set up video calls',
  'acceptVideoCallsFeature.overview': 'Accept video calls overview',
  'acceptVideoCallsFeature.talkWithYourClients':
    'Talk with your _(clients) face to face with high-quality video and perfect sound!',
  'acceptVideoCallsFeature.onceAClientBooks':
    'Once _a(client) books _a(appointment), both you and your _(client) will get a link to the video call.',
  'acceptVideoCallsFeature.ifYouChangeAnAppointment':
    'If you change _a(appointment), the video call link will be updated automatically.',
  'acceptVideoCallsFeature.afterYouJoined':
    'After you’ve joined the call, you can enrich the meeting experience by sharing your screen or sending instant messages in the in-call chat.',
  'acceptVideoCallsFeature.helpCenter':
    'To learn more, read our help center guide on <link>holding an ExpertBox video call</link>.',
  'acceptVideoCallsFeature.disableModalTitle': 'Disable Accept video calls feature?',
  'acceptVideoCallsFeature.disableModalAlertText': 'This won’t affect any of the _(appointments) booked previously.',
  'acceptVideoCallsFeature.disableModalActionsText': 'Availability and _(service) actions',
  'acceptVideoCallsFeature.disableModalQuestion': `What do you want to do with availability and services provided at the online locations?`,
  'acceptVideoCallsFeature.disableModalOption.stopOnline': 'Remove availability and stop providing online _(services)',
  'acceptVideoCallsFeature.disableModalOption.moveToOffline': 'Move to offline location',
  'acceptVideoCallsFeature.disableModalLocation': 'New location',
  'acceptVideoCallsFeature.alertText': 'To disable Accept video calls, you need to enable offline locations feature',
  'acceptVideoCallsFeature.alertButtonText': 'Offline locations',
  'acceptVideoCallsFeature.secondAlertText':
    'You can’t disable Accept Video calls because there are not any active offline locations',
  'acceptVideoCallsFeature.secondAlertButtonText': 'Create location',
}

export default acceptVideoCallsFeature
