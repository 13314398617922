import { DateTime } from 'luxon'

import { DEFAULT_SERVICE_DURATION } from 'lib/constants/services'
import { FREE_PRICE } from 'lib/constants/scheduleAppointment'
import { currentClosestDate } from 'utils/bookings'
import { DAY_NUMBER_TO_DAY_OF_WEEK, ENDS_FIELD_OPTIONS, WEEKLY_INTERVAL_FIELD_OPTIONS } from 'lib/constants/bookings'
import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'

const initialCreateFormValues = isAcceptVideoCallsEnabled => {
  const localTime = currentClosestDate()

  return {
    duration: DEFAULT_SERVICE_DURATION,
    date: localTime,
    time: localTime,
    price: FREE_PRICE,
    isFreePrice: true,
    serviceName: '',
    serviceDescription: '',
    paymentRequired: true,
    repeat: false,
    untilDate: DateTime.now().toUTC(),
    repeatCount: '3',
    weeklyInterval: WEEKLY_INTERVAL_FIELD_OPTIONS[0].value,
    ends: ENDS_FIELD_OPTIONS[0].value,
    byWeekday: [DAY_NUMBER_TO_DAY_OF_WEEK[localTime.weekday]],
    locationId: isAcceptVideoCallsEnabled ? ONLINE_LOCATION_OPTION.value : undefined,
    clientProfile: {
      idOrEmail: undefined,
      firstName: '',
      lastName: '',
    },
    emails: [],
  }
}

export default initialCreateFormValues
