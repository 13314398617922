import { uniq, without } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.ADD_ETHERPAD_DOCUMENT_IDS:
      return uniq([...state, ...action.ids])
    case types.REMOVE_ETHERPAD_DOCUMENT_IDS:
      return without(action.ids, state)
    case types.APPEND_ETHERPAD_ID:
      return uniq([action.id, ...state])
    case types.RESET_ETHERPAD_DOCUMENTS:
      return []
    default:
      return state
  }
}

const documentId = (state = null, action) => {
  switch (action.type) {
    case types.SET_ETHERPAD_DOCUMENT_ID:
      return action.documentId
    case types.RESET_ETHERPAD_DOCUMENT_ID:
      return null
    default:
      return state
  }
}

export default combineReducers({
  ids,
  documentId,
})
