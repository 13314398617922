import { createLogic } from 'redux-logic'
import { prop, head } from 'ramda'
import build from 'redux-object'
import normalize from 'json-api-normalizer'

import { leaveChatCommand } from 'utils/chat'
import redirect from 'utils/redirect'
import { boldText } from 'utils/locales'
import { companyChatsRoute, clientChatsRoute } from 'lib/routes'
import { sendCommand } from 'state/concepts/webSockets/actions'
import { RECEIVE_REMOVED_MEMBER } from 'state/concepts/chat/types'
import { chatSelector } from 'state/concepts/chat/selectors'
import {
  isEmployeeLoggedInSelector,
  entityWorkspaceUniqCodeSelector,
  currentChatUserIdSelector,
} from 'state/concepts/session/selectors'
import { showModal } from 'state/modal/actions'
import { removeChat } from 'state/concepts/chats/actions'

const receiveRemovedMemberOperation = createLogic({
  type: RECEIVE_REMOVED_MEMBER,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { data } = action
    const response = normalize(data)
    const chat = head(build(response, 'chat'))
    const state = getState()
    const chatId = chat.id
    const chatUserId = prop('id', chat.chatUser)

    const isEmployee = isEmployeeLoggedInSelector(state)
    const workspaceCode = entityWorkspaceUniqCodeSelector(state, chatId, 'chat')
    const currentChat = chatSelector(state)
    const currentChatUserId = currentChatUserIdSelector(state).toString()

    if (currentChat.id === chatId && currentChatUserId === chatUserId) {
      dispatch(sendCommand(leaveChatCommand(chatId)))

      if (isEmployee) {
        await redirect({ href: companyChatsRoute, workspace: workspaceCode })
      } else {
        await redirect({ href: clientChatsRoute(workspaceCode) })
      }

      dispatch(
        showModal({
          modalType: 'CHAT_UNAVAILABLE_MODAL',
          modalProps: {
            title: { id: 'chat.unavailableModal.youHaveBeenRemoved' },
            bodyText: [
              {
                id: 'chat.unavailableModal.youHaveBeenRemovedByExpert',
                values: {
                  chatName: prop('name', currentChat),
                  b: boldText,
                },
              },
            ],
          },
        }),
      )
    } else if (currentChatUserId === chatUserId) {
      dispatch(removeChat(chatId))
      dispatch(sendCommand(leaveChatCommand(chatId)))
    }

    done()
  },
})

export default receiveRemovedMemberOperation
