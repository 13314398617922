import createClientContact from './createClientContact'
import fetchClientContact from './fetchClientContact'
import updateClientContact from './updateClientContact'
import deleteClientContact from './deleteClientContact'
import fetchClientContacts from './fetchClientContacts'
import sendDeleteConfirmationCode from './sendDeleteConfirmationCode'
import deleteClientContactOwnAccount from './deleteClientContactOwnAccount'

export default [
  createClientContact,
  updateClientContact,
  fetchClientContact,
  fetchClientContacts,
  deleteClientContact,
  sendDeleteConfirmationCode,
  deleteClientContactOwnAccount,
]
