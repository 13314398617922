import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { path, pathOr, map } from 'ramda'
import { toString } from 'lodash'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { CANCEL_BOOKING } from 'state/concepts/bookings/types'
import { cancelBookingEndpoint, cancelRecurringBookingsEndpoint } from 'state/concepts/bookings/endpoints'
import { removeClientAccountBookings, setPendingBookingsCount } from '../../clientAccountBookings/actions'

const cancelBookingOperation = createLogic({
  type: CANCEL_BOOKING,
  latest: true,

  async process({ httpClient, action: { values, form } }, dispatch, done) {
    const { id, uniqCode, recurringOption, cancellationReason, refundNeeded } = values
    const isCancelAllFuture = recurringOption === RECURRING_BOOKING_OPTIONS.all
    const { endpoint, url } = isCancelAllFuture
      ? cancelRecurringBookingsEndpoint(uniqCode)
      : cancelBookingEndpoint(uniqCode)

    const params = {
      cancellation_reason: cancellationReason,
      refund_needed: refundNeeded,
      include: 'transactions',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, params)

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(removeClientAccountBookings(map(toString, pathOr([id], ['meta', 'cancelled_bookings_ids'], data))))

      const pendingBookingsCount = path(['meta', 'pending_bookings_count'], data)
      if (pendingBookingsCount) {
        dispatch(setPendingBookingsCount(pendingBookingsCount))
      }

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.${isCancelAllFuture ? 'recurringBookingsCancelled' : 'bookingCancelled'}`,
          },
        }),
      )
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors

        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
      }

      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    done()
  },
})

export default cancelBookingOperation
