import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { CHAT_DETAILS_MODAL_ACTIONS, CHAT_INFO_MODAL_TABS, CHAT_TYPES } from 'lib/constants/chat'
import { updateChat as updateChatAction } from 'state/concepts/chat/actions'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { showModal as showModalAction } from 'state/modal/actions'
import { isChatActive, isChatDirect } from 'utils/chat'
import ChatInfoModalComponent from './component'

class ChatInfoModal extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    chat: PropTypes.shape().isRequired,
    tab: PropTypes.string,
    currentUser: PropTypes.shape(),
  }

  static defaultProps = {
    tab: CHAT_INFO_MODAL_TABS.members,
    currentUser: undefined,
  }

  state = {
    tab: null,
  }

  get activeKey() {
    const { tab: passedTab } = this.props
    const { tab } = this.state

    return tab || passedTab
  }

  get isCurrentUserChatOwner() {
    const {
      currentUser,
      chat: { ownerId },
    } = this.props
    const chatUserId = currentUser?.chatUserId

    return chatUserId === ownerId
  }

  get manageChatMembers() {
    const { chat } = this.props

    return (
      this.isCurrentUserChatOwner &&
      this.activeKey === CHAT_INFO_MODAL_TABS.members &&
      chat.chatType === CHAT_TYPES.room
    )
  }

  get isChatActive() {
    const { chat } = this.props

    return isChatActive(chat)
  }

  get isDirectChat() {
    const { chat } = this.props

    return isChatDirect(chat)
  }

  handleOpenChatDetails = () => {
    const { showModal, updateChat, chat } = this.props

    showModal({
      modalType: 'CHAT_DETAILS_MODAL',
      modalProps: {
        ...chat,
        actionType: CHAT_DETAILS_MODAL_ACTIONS.update,
        onSubmit: updateChat,
      },
    })
  }

  handleOpenAddChatMembers = () => {
    const { showModal, chat } = this.props

    showModal({
      modalType: 'ADD_CHAT_MEMBERS_MODAL',
      modalProps: {
        chat,
        chatType: CHAT_TYPES.room,
      },
    })
  }

  handleTabClick = key => {
    this.setState({ tab: key })
  }

  render = () => (
    <ChatInfoModalComponent
      {...this.props}
      onTabClick={this.handleTabClick}
      onOpenChatDetails={this.handleOpenChatDetails}
      onOpenAddChatMembers={this.handleOpenAddChatMembers}
      activeKey={this.activeKey}
      isCurrentUserChatOwner={this.isCurrentUserChatOwner}
      manageChatMembers={this.manageChatMembers}
      isChatActive={this.isChatActive}
      isDirectChat={this.isDirectChat}
    />
  )
}

const mapStateToProps = state => ({
  currentUser: currentUserProfileSelector(state),
})

const mapDispatchToProps = {
  showModal: showModalAction,
  updateChat: updateChatAction,
}

export { ChatInfoModal as ChatInfoModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(ChatInfoModal)
