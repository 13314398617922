import React from 'react'
import PropTypes from 'prop-types'

import ErrorModal from 'views/shared/ErrorModal'
import CancelFormModal from './CancelFormModal'

const CancelRecurringBookingModalComponent = ({ isLoading, cancellationError, ...props }) =>
  isLoading === false &&
  (cancellationError ? (
    <ErrorModal
      title={{ id: 'cancelBookingModal.cancelAppointmentError' }}
      error={cancellationError}
      submitText={{ id: 'shared.dismiss' }}
    />
  ) : (
    <CancelFormModal {...props} />
  ))

CancelRecurringBookingModalComponent.defaultProps = {
  isLoading: undefined,
  cancellationError: undefined,
}

CancelRecurringBookingModalComponent.propTypes = {
  isLoading: PropTypes.bool,
  cancellationError: PropTypes.string,
}

export default CancelRecurringBookingModalComponent
