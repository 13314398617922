const signatureFeature = {
  'signatureFeature.toEnableAlert':
    'To enable eSignature, you need to start using either documents or forms on ExpertBox.',
  'signatureFeature.toEnableAlert.enableDocuments': 'Enable Documents',
  'signatureFeature.toEnableAlert.enableForms': 'Enable Forms',
  'signatureFeature.title': 'E-signature',
  'signatureFeature.subtitle': '<span>in</span> Forms & documents',
  'signatureFeature.manageSignature': 'Set up e-signature',
  'signatureFeature.description': 'Streamline form and document signing and ensure legally binding signatures.',
  'signatureFeature.overview': 'E-signature overview',
  'signatureFeature.overviewDetails':
    'Streamline document signing and boost efficiency. This feature lets _(clients) and _(experts) sign documents and forms, ensuring legally binding signatures.',
  'signatureFeature.overview1.title': 'Simplify document signing:',
  'signatureFeature.overview1.point1': 'Invite collaborators from outside your workspace to sign critical documents.',
  'signatureFeature.overview1.point2':
    'Define the order in which individuals are prompted to sign documents, reducing manual efforts.',
  'signatureFeature.overview1.point3':
    'Gain a comprehensive audit trail for each document, keeping track of document views and signing activities, including location and API addresses.',
  'signatureFeature.overview2.title': 'Ensure form accuracy:',
  'signatureFeature.overview2.point1':
    'Streamline data collection and automatically send completed forms for signature. We automate this process without the need for printing or email exchanges, enhancing convenience.',
  'signatureFeature.overview2.point2':
    'Guarantee that all participants affirm the accuracy of information within forms, ensuring data integrity and compliance.',
  'signatureFeature.toLearnMore':
    'To learn more, read our help center articles on <link>signing forms and documents.</link>',
  'signatureFeature.disableModalTitle': 'Disable e-signature feature?',
  'signatureFeature.disableModalDescription': 'You won’t be able to request signatures for any forms or documents.',
  'signatureFeature.disableModalAlert':
    'This won’t affect documents and forms that are already signed or awaiting signatures.',
}

export default signatureFeature
