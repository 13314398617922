import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { boldText } from 'utils/locales'
import { companySettingsSubscriptionRoute } from 'lib/routes/company'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Link from 'views/shared/Link'
import Cross from 'views/shared/icons/Cross'
import FormattedCurrency from 'views/shared/FormattedCurrency'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { hideModal as hideModalAction } from 'state/modal/actions'

const CompanyIsNotDeletedModal = ({ onClose, debtsTotal }) => {
  const hideModal = useDispatchAction(hideModalAction)

  return (
    <Modal className="main-modal main-modal--md freemium-modal" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title font-600">
            <FormattedMessage id="workspaceSettings.companyIsNotDeletedModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close w-24 h-24">
            <Cross size={10} dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          <p className="freemium-modal__text mb-0" data-cy="debt-info">
            <FormattedMessage
              id="workspaceSettings.companyIsNotDeletedModal.needToPay"
              values={{
                fee: <FormattedCurrency price={debtsTotal} />,
                b: boldText,
              }}
            />
          </p>
          <p className="freemium-modal__text mb-0 mt-16" data-cy="billing-info">
            <FormattedMessage
              id="workspaceSettings.companyIsNotDeletedModal.checkBilling"
              values={{
                link: (
                  <Link href={companySettingsSubscriptionRoute}>
                    <a className="main-link text-underline ml-4 mr-4" role="link" onClick={hideModal}>
                      <FormattedMessage id="workspaceSettings.companyIsNotDeletedModal.billingInfo" />
                    </a>
                  </Link>
                ),
              }}
            />
          </p>
        </div>
        <div className="main-modal__footer gap-16">
          <Button text={{ id: 'shared.dismiss' }} onClick={onClose} />
        </div>
      </div>
    </Modal>
  )
}

CompanyIsNotDeletedModal.defaultProps = {
  debtsTotal: 0,
}

CompanyIsNotDeletedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  debtsTotal: PropTypes.number,
}

export default CompanyIsNotDeletedModal
