import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Alert from 'views/shared/Alert'
import Cross from 'views/shared/icons/Cross'
import User from 'views/shared/icons/User'

import InvitationInputFields from './InvitationInputFields'

const EmployeesInvitationModalComponent = ({
  handleSubmit,
  status,
  isSubmitDisabled,
  onClose,
  isSubmitting,
  employeesMeta: { teamSize, teamSeatsCapacity },
}) => (
  <Modal className="main-modal main-modal--lg" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employees.invitations.modal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p>
          <FormattedMessage id="employees.invitations.modal.subtitle" />
        </p>
        {status && status.invitations && (
          <Alert className="mb-16" message={{ id: 'employees.invitations.duplicateEmails' }} type="warning" />
        )}

        {status && status.base && <Alert className="mb-16" message={status.base} />}

        <FieldArray name="invitations" component={InvitationInputFields} />
      </div>
      <div className="main-modal__footer justify-content-space-between align-items-center">
        <p className="d-flex align-items-center mb-0">
          <User size={15} className="in-blue-600 mr-10" />
          <span
            className="d-inline-flex align-items-baseline column-gap-4 text-body in-blue-gray-300"
            data-cy="number-of-used-seats"
          >
            <FormattedMessage
              id="employees.invitations.usedSeats"
              values={{
                teamSize: (
                  <span className="ei-dialog__seats-used d-flex align-items-center">
                    <span className="ei-dialog__seats-used-count">{teamSize}</span>
                  </span>
                ),
                teamSeatsCapacity,
              }}
            />
          </span>
        </p>
        <div>
          <Button
            className="main-modal__footer-action"
            text={{ id: 'employees.invitations.cancel' }}
            kind="flat"
            onClick={onClose}
          />
          <SubmitFormButton
            className="main-modal__footer-action"
            text={{ id: 'employees.invitations.submit' }}
            onClick={handleSubmit}
            kind={isSubmitDisabled ? 'outline' : null}
            isDisabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  </Modal>
)

EmployeesInvitationModalComponent.defaultProps = {
  status: undefined,
}

EmployeesInvitationModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape(),
  isSubmitDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  employeesMeta: PropTypes.shape({
    teamSize: PropTypes.number,
    teamSeatsCapacity: PropTypes.number,
  }).isRequired,
}

export default EmployeesInvitationModalComponent
