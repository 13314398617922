import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { CREATE_CHAT_MESSAGE } from 'state/concepts/videoConference/types'
import { receiveChatMessage } from 'state/concepts/videoConference/actions'
import { createVideoConferenceChatMessagesEndpoint } from 'state/concepts/videoConference/endpoints'

const createChatMessageOperation = createLogic({
  type: CREATE_CHAT_MESSAGE,
  latest: true,

  async process({ action: { uniqCode, message }, httpClient }, dispatch, done) {
    const identity = JSON.parse(message.author)
    const params = {
      text: message.text,
      identity,
    }
    const { url } = createVideoConferenceChatMessagesEndpoint(uniqCode)
    dispatch(receiveChatMessage(message))

    try {
      await httpClient.post(url, params, buildCustomBaseUrl(['workspaces']))
      // eslint-disable-next-line no-empty
    } catch (_error) {}

    done()
  },
})

export default createChatMessageOperation
