import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import Router from 'next/router'

import { soleAssociatedContact } from 'utils/clientContacts'
import isPresent from 'utils/isPresent'
import assignFormErrors from 'utils/form/assignFormErrors'
import { isErrorStatusUnauthorized } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { buildCustomBaseUrl } from 'lib/httpClient'
import {
  clientRoute,
  clientBookingsRoute,
  clientAccountSettingsRestoreRoute,
  clientLoginAccountsRoute,
} from 'lib/routes'
import redirect from 'utils/redirect'
import setClientCookies from 'utils/setClientCookies'
import { CLIENT_LOGIN } from 'state/concepts/session/types'
import { clientsSessionRoute } from 'lib/apiRoutes'
import { dataApiSuccess } from 'state/data/actions'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { clientLoginSuccess, clientContactLogin } from 'state/concepts/session/actions'

const clientLoginOperation = createLogic({
  type: CLIENT_LOGIN,
  latest: true,

  async process({ action: { values, form }, getState, httpClient }, dispatch, done) {
    try {
      const state = getState()
      const { uniqCode } = workspaceSelector(state)
      const params = {
        email: values.email,
        login_code: values.loginCode,
        captcha: values.captcha,
        include: 'workspace,associated-client-contacts.client_profile',
      }

      const { data } = await httpClient.post(clientsSessionRoute, params, buildCustomBaseUrl(['workspaces', uniqCode]))
      const response = normalize(data)
      const currentClient = build(response, 'clientProfile', data.data.id)
      const tokens = data.meta.jwt

      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers.Authorization = tokens.access

      setClientCookies(currentClient, tokens, { path: clientRoute(uniqCode) })

      dispatch(clientLoginSuccess(currentClient.id))
      dispatch(dataApiSuccess({ response }))

      const associatedContact = soleAssociatedContact(currentClient)

      if (associatedContact && !associatedContact.destroyRequestedAt) {
        dispatch(clientContactLogin(associatedContact.id))
        done()
        return
      }

      if (associatedContact && associatedContact.destroyRequestedAt) {
        redirect({
          href: clientAccountSettingsRestoreRoute(uniqCode),
          query: { contactId: associatedContact.id },
          reload: true,
        })
        done()
        return
      }

      const redirectTo = Router.router.query.redirect_to || values.redirectTo || clientBookingsRoute(uniqCode)

      if (isPresent(currentClient.associatedClientContacts)) {
        redirect({ href: clientLoginAccountsRoute, query: { redirect_to: redirectTo } })
      } else {
        redirect({
          href: currentClient.discardedAt ? clientAccountSettingsRestoreRoute(uniqCode) : redirectTo,
          reload: true,
        })
      }
    } catch (error) {
      if (isErrorStatusUnauthorized(error)) {
        assignFormErrors(form, error)
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }

    form.setSubmitting(false)
    done()
  },
})

export default clientLoginOperation
