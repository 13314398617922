const namespace = 'services'

export const FETCH_SERVICES = `${namespace}/FETCH_SERVICES`
export const SET_SERVICES = `${namespace}/SET_SERVICES`
export const SET_SERVICES_PAGE = `${namespace}/SET_SERVICES_PAGE`
export const SET_SERVICES_SORT_ORDER = `${namespace}/SET_SERVICES_SORT_ORDER`
export const SET_SERVICES_FILTER_PARAMS = `${namespace}/SET_SERVICES_FILTER_PARAMS`
export const SET_SERVICES_BLANK_STATE = `${namespace}/SET_SERVICES_BLANK_STATE`
export const SET_NOT_ACTIVATED_SERVICES_COUNT = `${namespace}/SET_NOT_ACTIVATED_SERVICES_COUNT`
export const RESET_NOT_ACTIVATED_SERVICES_COUNT = `${namespace}/RESET_NOT_ACTIVATED_SERVICES_COUNT`
export const FILTER_SERVICES = `${namespace}/FILTER_SERVICES`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const DEACTIVATE_SERVICES = `${namespace}/DEACTIVATE_SERVICES`
export const ACTIVATE_SERVICES = `${namespace}/ACTIVATE_SERVICES`
export const SET_SELECTED_SERVICES = `${namespace}/SET_SELECTED_SERVICES`
export const SEARCH_SERVICES = `${namespace}/SEARCH_SERVICES`
export const REMOVE_SERVICES = `${namespace}/REMOVE_SERVICES`
