import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { serviceSelector } from 'state/concepts/service/selectors'
import { showModal as showModalAction } from 'state/modal/actions'
import NoAssignedExpertComponent from './component'

class NoAssignedExpert extends React.Component {
  static propTypes = {
    service: PropTypes.shape(),
    serviceId: PropTypes.string,
    showModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    service: null,
    serviceId: null,
  }

  handleAssignExpertsModal = () => {
    const { showModal, service, serviceId } = this.props

    showModal({
      modalType: 'ASSIGN_EMPLOYEES_MODAL',
      modalProps: { service, serviceId },
    })
  }

  render = () => <NoAssignedExpertComponent {...this.props} onAssignExpertsModal={this.handleAssignExpertsModal} />
}

const mapStateToProps = (state, { serviceId }) => ({
  service: serviceSelector(state, serviceId),
})

const mapDispatchToProps = {
  showModal: showModalAction,
}

export { NoAssignedExpert as NoAssignedExpertContainer }
export default connect(mapStateToProps, mapDispatchToProps)(NoAssignedExpert)
