import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'

import * as types from './types'

export const getCompany = () => ({
  type: types.GET_COMPANY,
})

export const updateCompanyProfile = makeFormSubmitAction(types.UPDATE_COMPANY_PROFILE)
export const updateWorkspaceName = makeFormSubmitAction(types.UPDATE_WORKSPACE_NAME)
export const removeCompanyProfile = makeFormSubmitAction(types.REMOVE_COMPANY_PROFILE)
export const changeCompanyOwnership = makeFormSubmitAction(types.CHANGE_WORKSPACE_OWNERSHIP)

export const searchEmployees = ({
  query,
  exclude,
  statuses,
  serviceId,
  page,
  resetResults,
  roles = ['workspace_owner', 'administrator', 'user'],
}) => ({
  type: types.SEARCH_EMPLOYEES,
  query,
  exclude,
  statuses,
  serviceId,
  page,
  resetResults,
  roles,
})

export const updateWorkspaceTwoFactorSettings = makeFormSubmitAction(types.UPDATE_WORKSPACE_TWO_FACTOR_SETTINGS)
