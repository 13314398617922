import { is, reduce } from 'ramda'

import compact from 'utils/compact'
import { MIN_PASSWORD_LENGTH, WORKSPACE_URL_REGEX } from 'lib/constants'
import splitEmail from 'utils/splitEmail'

export function isWorkspaceUrl() {
  return this.test('isWorkspaceUrl', { id: 'yup.string.isWorkspaceUrl' }, value => {
    if (!is(String, value)) {
      return true
    }

    return WORKSPACE_URL_REGEX.test(value)
  })
}

export function passwordValidation(regexp, message) {
  return this.test({
    name: 'passwordValidation',
    message,
    test: (value, context) => {
      const { password } = context.parent

      if (!is(String, password)) {
        return false
      }

      return regexp.test(password)
    },
  })
}

export function passwordMinLength(message = { id: 'yup.string.password.min', values: { min: MIN_PASSWORD_LENGTH } }) {
  return this.test('passwordMinLength', message, (value, context) => {
    const { password } = context.parent

    if (!is(String, password)) {
      return false
    }

    return password.length >= MIN_PASSWORD_LENGTH
  })
}

export function passwordNameInclusion(message = { id: 'yup.string.password.passwordNameInclusion' }) {
  return this.test('passwordNameInclusion', message, (value, context) => {
    const password = context.parent.password?.toLowerCase()

    if (!is(String, password)) {
      return false
    }

    const names = compact([
      ...(splitEmail(context.parent.email) || []),
      context.parent.workspaceName,
      context.parent.workspaceUrl,
      context.parent.firstName,
      context.parent.lastName,
    ])

    return reduce((acc, term) => (password.indexOf(term.toLowerCase()) !== -1 ? false : acc), true, names)
  })
}
