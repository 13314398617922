import { WIDGET_HOST } from 'lib/constants'

export const MAX_WIDGET_NAME_DISPLAY_LENGTH = 24
export const MAX_BUTTON_TEXT_LENGTH = 15
export const MIN_WIDGET_NAME_LENGTH = 3
export const MAX_WIDGET_NAME_LENGTH = 255
export const MAX_EMAIL_LENGTH = 255
export const MIN_SLUG_LENGTH = 3
export const MAX_SLUG_LENGTH = 63
export const SLUG_VALIDATION_REGEX = /^[0-9a-z-]+$/

export const SEND_INSTRUCTIONS_THROTTLE_IN_SECONDS = 30

export const DEFAULT_CUSTOMIZATION_COLORS = [
  '#B2BEC3',
  '#FDCB6E',
  '#A9F87E',
  '#7EF89D',
  '#55EFC4',
  '#00B894',
  '#FDA679',
  '#81ECEC',
  '#7EDCF8',
  '#74B9FF',
  '#7E9EF8',
  '#5396FF',
  '#F885FE',
  '#B97EF8',
  '#FF7675',
  '#FD79A8',
  '#A29BFE',
  '#847EF8',
  '#F4EB4F',
  '#CDF051',
]

export const WIDGET_POSITION_OPTIONS = [
  {
    value: 'left',
    label: { id: 'shared.left' },
    icon: 'left-arrow',
  },
  {
    value: 'right',
    label: { id: 'shared.right' },
    icon: 'right-arrow',
  },
]

export const BUTTON_POSITION_OPTIONS = [
  {
    value: 'top',
    label: { id: 'shared.top' },
    icon: 'up-arrow',
  },
  {
    value: 'bottom',
    label: { id: 'shared.bottom' },
    icon: 'down-arrow',
  },
]

export const DEFAULT_APP_ID = 'APP_ID'

export const MOUNT_TO = 'expertbox-widget'

export const WIDGET_SCRIPT = 'expertbox-widget-script'

export const WIDGET_SRC = `${WIDGET_HOST}/dist/@rubygarage/go-consulted-booking-widget.min.js`
export const WIDGET_PREVIEW_SRC = `${WIDGET_HOST}/dist/widget-preview/bundle.min.js`

export const WIDGET_STATUSES = {
  active: 'active',
  inactive: 'inactive',
}

export const DEFAULT_WIDGET_SETTINGS = {
  name: '',
  urlSlug: '',
  position: 'right',
  color: '#3ED3A3',
  status: WIDGET_STATUSES.active,
  installationType: 'button',
  buttonPosition: 'bottom',
  buttonColor: '#3ED3A3',
  buttonIconColor: '#FFFFFF',
  buttonAsText: false,
  buttonText: 'Button title',
  buttonTextColor: '#FFFFFF',
  selector: '#default-expertbox-widget-trigger',
  services: [],
}

export const CUSTOM_PAGE_HOST = process.env.CUSTOM_PAGE_HOST || 'https://stagingbooking.expertbox.io/'
export const BOOKING_LINK_PATH = 'booking.expertbox.io/'
