import UAParser from 'ua-parser-js'

import isServer from 'utils/isServer'

const getOrientation = () => {
  if (isServer()) {
    return undefined
  }

  return window.orientation
}

const isMobileOrTablet = () => {
  const parser = new UAParser()
  const { type: deviseType } = parser.getDevice()
  const { name: osName } = parser.getOS()

  // Workaround for iPadOS which is currently not detectable via UA
  const iPadOS = getOrientation() !== undefined && osName === 'Mac OS'

  return deviseType !== undefined || iPadOS
}

export default isMobileOrTablet
