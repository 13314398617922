import { createLogic } from 'redux-logic'
import { length } from 'ramda'

import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { isFolder as isFolderFunc } from 'utils/businessFiles'
import { hideModal } from 'state/modal/actions'
import { MOVE_MY_BUSINESS_FILE_TO_TRASH } from '../types'
import { moveMyBusinessFileToTrashEndpoint } from '../endpoints'
import { fetchMyBusinessFiles, removeMyBusinessFileIds } from '../actions'
import { currentFolderIdSelector, myBusinessFileByIdSelector } from '../selectors'

const moveMyBusinessFileToTrashOperation = createLogic({
  type: MOVE_MY_BUSINESS_FILE_TO_TRASH,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const { myBusinessFileIds, onNextSlide, onBack } = action
    const myBusinessFileId = myBusinessFileIds[0]
    const selectedIdsCount = length(myBusinessFileIds)
    const state = getState()
    const currentFolderId = currentFolderIdSelector(state)
    const { kind } = myBusinessFileByIdSelector(state, myBusinessFileId)
    const isFolder = isFolderFunc(kind)
    const { url, endpoint } = moveMyBusinessFileToTrashEndpoint
    const params = { business_storage_ids: myBusinessFileIds }
    const notificationId =
      selectedIdsCount > 1
        ? 'notifications.itemsWereMovedToTrash'
        : `notifications.${isFolder ? 'folderWasMovedToTrash' : 'fileWasMovedToTrash'}`

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, params)
      const trashedCount = data.meta.trashed_items_count

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeMyBusinessFileIds(myBusinessFileIds))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: notificationId, values: { count: trashedCount } },
        }),
      )
      onNextSlide?.() || onBack?.()
      if (!onBack && !onNextSlide)
        dispatch(fetchMyBusinessFiles(undefined, { append: true, parentId: currentFolderId }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default moveMyBusinessFileToTrashOperation
