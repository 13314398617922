import PropTypes from 'prop-types'
import { Formik, FieldArray } from 'formik'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'ramda'

import useMountAction from 'hooks/shared/useMountAction'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import validationSchema from 'lib/yupLocalised/schemas/serviceProvision'
import { showServiceDetails, updateServiceProvision } from 'state/concepts/service/actions'
import { serviceProvisionSelector } from 'state/concepts/services/selectors'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'
import ProviderFields from '../../CreateService/Providers/ProviderFields'

const AssignEmployeesModal = props => {
  const {
    service: { name },
    serviceId,
    onClose,
  } = props
  const provision = useSelector(state => serviceProvisionSelector(state, serviceId))
  const handleSubmit = useFormSubmit(updateServiceProvision)

  useMountAction(showServiceDetails, serviceId)

  return (
    <Modal className="main-modal main-modal--lg" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="services.assignExpert.modal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            serviceId,
            provision: !isEmpty(provision)
              ? provision
              : [{ employees: [], price: undefined, key: 0, isFreePrice: false }],
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              <p>
                <FormattedMessage id="services.assignExperts.modal.subtitle" />
              </p>
              <div className="mb-16">
                <p className="main-tag main-tag--light">
                  <span className="mr-4 va-middle">{name}</span>
                </p>
              </div>
              <FieldArray name="provision" component={ProviderFields} />
            </div>
            <div className="main-modal__footer">
              <Button
                className="main-modal__footer-action"
                text={{ id: 'shared.cancel' }}
                kind="flat"
                onClick={onClose}
              />
              <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.assign' }} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

AssignEmployeesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  serviceId: PropTypes.string.isRequired,
}

export default AssignEmployeesModal
