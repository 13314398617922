const clientContactsFeature = {
  'clientContactsFeature.title': '_(Client) contacts',
  'clientContactsFeature.subtitle': '<span>in</span> CRM',
  'clientContactsFeature.description':
    'Streamline _(client) relationships by adding and managing their related contacts.',
  'clientContactsFeature.overview': '_(Client) contacts overview',
  'clientContactsFeature.manage': 'Add and manage related contacts for your _(clients) to streamline their experience.',
  'clientContactsFeature.context': 'Add context to _(client) profiles:',
  'clientContactsFeature.attach': 'Attach related contacts to _(clients), defining their relationship.',
  'clientContactsFeature.detailedNotes':
    'Keep detailed notes on each contact, adding more context for providing _(client) _(services).',
  'clientContactsFeature.emergencyContacts':
    'Assign emergency contacts and those responsible for billing to streamline coordination.',
  'clientContactsFeature.customizeAccess': 'Customize access and permissions:',
  'clientContactsFeature.createCustomRoles':
    'Create custom roles and set permissions, ensuring each contact has the right level of access and control.',
  'clientContactsFeature.contactsAccess':
    'Let contacts access the _(client) portal, manage _(appointments), and receive relevant reminders.',
  'clientContactsFeature.handleDocuments':
    'Give contacts the tools to handle documentation, billing, and communication with _(experts), supporting them in their roles.',
  'clientContactsFeature.relationshipManagement': 'Flexible relationship management:',
  'clientContactsFeature.createContacts':
    'Conveniently add contacts by creating new ones or associating existing _(clients) within your workspace.',
  'clientContactsFeature.convertContacts':
    'Convert related contacts to individual _(clients) as their relationships with your business evolve.',
  'clientContactsFeature.automateFinance': 'Automate financial operations:',
  'clientContactsFeature.simplifyFinance':
    'Simplify the financial workflow by directing billing communications to the contact responsible for payments.',
  'clientContactsFeature.ensureAll':
    'Ensure all relevant payment information is accessible to designated contacts, regardless of financial responsibility.',
  'clientContactsFeature.manageClientRelationships': 'Set relationship types',
  'clientContactsFeature.disableModalTitle': 'Disable _(Client) contacts?',
  'clientContactsFeature.disableModalDescription':
    'You won’t be able to add related contacts to _(clients) anymore. However, existing contacts won’t be deleted.',
  'clientContactsFeature.toLearnMore':
    'To learn more, read our help center article on <link>managing _(client)</link> contacts and setting relationship types.',
  'clientContactsFeature.crmAlert.text': 'To enable _(client) contacts, you need to start using CRM on ExpertBox.',
  'clientContactsFeature.crmAlert.action': 'Enable CRM',
}

export default clientContactsFeature
