import { createSelector } from '@reduxjs/toolkit'
import { any, compose, groupWith, isEmpty, not, path, values } from 'ramda'
import build from 'redux-object'

import {
  currentPageSelector,
  dataSelector,
  loadingSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import bunchItemsByTime from 'utils/bunchItemsByTime'
import { fetchClientAttachmentsEndpoint } from './endpoints'

export const clientAttachmentsIdsSelector = path(['companyClientAttachments', 'clientAttachmentsIds'])
export const isUploadingSectionOpenSelector = path(['companyClientAttachments', 'isUploadingSectionOpen'])

export const filtersSelector = path(['companyClientAttachments', 'filters'])

export const clientAttachmentsSelector = createSelector([clientAttachmentsIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'clientAttachment', ids) : [],
)

export const clientAttachmentByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'clientAttachment', id) : {},
)

export const clientAttachmentsGroupSelector = createSelector(clientAttachmentsSelector, groupWith(bunchItemsByTime))

export const clientAttachmentsCurrentPageSelector = (state, clientId) =>
  currentPageSelector(state, fetchClientAttachmentsEndpoint(clientId).endpoint)
export const clientAttachmentsNextPageSelector = (state, clientId) =>
  nextPageSelector(state, fetchClientAttachmentsEndpoint(clientId).endpoint)
export const clientAttachmentsPageCountSelector = (state, clientId) =>
  pageCountSelector(state, fetchClientAttachmentsEndpoint(clientId).endpoint)
export const fetchClientAttachmentsLoadingSelector = (state, clientId) =>
  loadingSelector(state, fetchClientAttachmentsEndpoint(clientId).endpoint)

export const hasFiltersSelector = createSelector(filtersSelector, compose(any(compose(not, isEmpty)), values))

export const uploadFilesSelector = path(['companyClientAttachments', 'uploadFiles'])
