const namespace = 'website'

export const FETCH_PUBLIC_WEBSITE = `${namespace}/FETCH_PUBLIC_WEBSITE`
export const FETCH_PUBLIC_WEBSITE_REVIEWS = `${namespace}/FETCH_PUBLIC_WEBSITE_REVIEWS`
export const ADD_REVIEW_IDS = `${namespace}/ADD_REVIEW_IDS`
export const FETCH_WEBSITE = `${namespace}/FETCH_WEBSITE`
export const SET_WEBSITE = `${namespace}/SET_WEBSITE`
export const CREATE_WEBSITE = `${namespace}/CREATE_WEBSITE`
export const UPDATE_GENERAL_DETAILS = `${namespace}/UPDATE_GENERAL_DETAILS`
export const UPDATE_CONTENT_DETAILS = `${namespace}/UPDATE_CONTENT_DETAILS`
export const FETCH_PUBLIC_PROVIDERS = `${namespace}/FETCH_PUBLIC_PROVIDERS`
export const FETCH_PUBLIC_LOCATIONS = `${namespace}/FETCH_PUBLIC_LOCATIONS`
export const FETCH_PUBLIC_SERVICES = `${namespace}/FETCH_PUBLIC_SERVICES`
export const ADD_SERVICES = `${namespace}/ADD_SERVICES`
export const ADD_LOCATIONS = `${namespace}/ADD_LOCATIONS`
export const ADD_PROVIDERS = `${namespace}/ADD_PROVIDERS`
export const DELETE_WEBSITE = `${namespace}/DELETE_WEBSITE`
export const SET_WEBSITE_DRAFT = `${namespace}/SET_WEBSITE_DRAFT`
export const RESET_WEBSITE_DRAFT = `${namespace}/RESET_WEBSITE_DRAFT`
export const PUBLISH_DRAFT_WEBSITE = `${namespace}/PUBLISH_DRAFT_WEBSITE`
