import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { ALERT_KINDS } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import { currentFolderIdSelector as currentFolderIdSharedSelector } from 'state/concepts/filesSharedWithMe/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import redirect from 'utils/redirect'
import { createFolderEndpoint } from '../endpoints'
import { currentFolderIdSelector } from '../selectors'
import { CREATE_FOLDER } from '../types'

const createFolderOperation = createLogic({
  type: CREATE_FOLDER,
  latest: true,

  async process(
    {
      httpClient,
      action: {
        values: { name, redirectToRoute },
      },
      getState,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const { url, endpoint } = createFolderEndpoint

    const currentFolderIdMyFiles = currentFolderIdSelector(state)
    const currentFolderIdShared = currentFolderIdSharedSelector(state)
    const folderId = currentFolderIdMyFiles || currentFolderIdShared

    const workspaceCode = currentWorkspaceCodeSelector(state)

    const params = {
      name,
      parent_id: folderId,
      include: ['ancestors-permissions.user-profile'],
    }

    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.businessFilesFolderWasCreated' } }))
      redirect({
        workspace: workspaceCode,
        href: redirectToRoute(data.data.id),
      })
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default createFolderOperation
