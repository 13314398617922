export const PERMISSION_DENIED = 'Permission denied'

export const TWILIO_DUPLICATE_IDENTITY_ERROR_CODE = 53205

export const LAYOUTS = {
  grid: 'grid',
  spotlight: 'spotlight',
}

export const CONNECTION_STATES = {
  disconnected: 'disconnected',
  reconnecting: 'reconnecting',
  connected: 'connected',
}

export const COOKIES_PATH = '/video_conference'

export const MAX_USER_NAME_LENGTH = 100

export const USER_TYPES = {
  expert: 'u',
  client: 'c',
}

export const TWILIO_MAX_PARTICIPANTS_BY_ROOM_TYPE = {
  group: 12,
  go: 2,
}

export const PREVIEW_MAX_SHOWN_PARTICIPANTS = 3

export const DEFAULT_SPEAKER_DEVICE = { key: 'default', label: 'Default speaker', value: 'default' }

export const events = {
  MESSAGE_CREATED: 'messageCreated',
  MUTE_PARTICIPANT: 'muteParticipant',
  REMOVE_PARTICIPANT: 'removeParticipant',
}

export const CHIPS_FINISHED_KEYS = [' ', 'Enter', ',']

export const VIDEO_PRIORITY = {
  low: 'low',
  standard: 'standard',
  high: 'high',
}

export const FORMS_VIEW = {
  general: 'general',
  forms: 'forms',
  agreements: 'agreements',
}
