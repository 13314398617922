import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import build from 'redux-object'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_CALENDAR_CONNECTIONS } from 'state/concepts/calendarIntegrations/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import {
  setGoogleCalendarConnectionIds,
  setOutlookCalendarConnectionIds,
  setCalendarIntegrationId,
  setAppleCalendarConnectionIds,
} from '../actions'
import { fetchCalendarConnectionsEndpoint } from '../endpoints'

const fetchCalendarConnectionsOperation = createLogic({
  type: FETCH_CALENDAR_CONNECTIONS,
  latest: true,

  async process({ httpClient, action: { include } }, dispatch, done) {
    const { endpoint, url } = fetchCalendarConnectionsEndpoint

    try {
      const params = {
        include,
      }
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const { googleCalendarConnections, outlookCalendarConnections, appleCalendarConnections, id } = build(
        response,
        'calendarIntegration',
      )[0]

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(setGoogleCalendarConnectionIds(pluck('id', googleCalendarConnections)))
      dispatch(setOutlookCalendarConnectionIds(pluck('id', outlookCalendarConnections)))
      dispatch(setAppleCalendarConnectionIds(pluck('id', appleCalendarConnections)))
      dispatch(setCalendarIntegrationId(id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchCalendarConnectionsOperation
