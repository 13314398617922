import { createLogic } from 'redux-logic'

import { getTaskCounter } from 'utils/tasks'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataDelete } from 'state/data/actions'
import { DELETE_TASK } from 'state/concepts/tasks/types'
import { deleteTaskEndpoint } from 'state/concepts/tasks/endpoints'
import { removeTaskId, decrementTasksCount } from 'state/concepts/tasks/actions'
import { removeClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { showNotification } from 'state/notifications/actions'

const deleteTaskOperation = createLogic({
  type: DELETE_TASK,
  latest: true,

  async process({ httpClient, action: { task, feedId } }, dispatch, done) {
    const { endpoint, url } = deleteTaskEndpoint(task.id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeTaskId(task.id))
      feedId && dispatch(removeClientFeedIds([feedId]))
      dispatch(dataDelete({ kind: 'task', ids: [task.id] }))
      dispatch(decrementTasksCount(getTaskCounter(task)))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.taskWasDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteTaskOperation
