import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import { durationToSeconds } from 'utils/duration'
import PaymentMessageComponent from './component'

class PaymentMessage extends React.Component {
  static propTypes = {
    formik: PropTypes.shape({
      values: PropTypes.shape({
        paymentRequired: PropTypes.bool.isRequired,
        date: PropTypes.shape().isRequired,
        time: PropTypes.shape().isRequired,
        duration: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  get paymentDueDate() {
    const {
      formik: {
        values: { date, duration, time },
      },
    } = this.props

    return date
      .set({ hour: time.hour, minute: time.minute, second: 0 })
      .plus({ seconds: durationToSeconds(duration) })
      .toFormat('LLL d, yyyy')
  }

  render() {
    const {
      formik: {
        values: { paymentRequired },
      },
    } = this.props

    return (
      <PaymentMessageComponent {...this.props} paymentRequired={paymentRequired} paymentDueDate={this.paymentDueDate} />
    )
  }
}

export default connect(PaymentMessage)
