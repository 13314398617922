const scheduleAppointment = {
  'scheduleAppointment.backLink': '_(appointment) calendar',
  'scheduleAppointment.title': 'Schedule _a(appointment)',
  'scheduleAppointment.editTitle': 'Edit _(appointment)',
  'scheduleAppointment.selectService': 'Select _(service)',
  'scheduleAppointment.service': '_(Service)',
  'scheduleAppointment.selectExpert': 'Select _(expert)',
  'scheduleAppointment.expert': '_(Expert)',
  'scheduleAppointment.price': 'Price',
  'scheduleAppointment.priceSelect.label': 'Payment',
  'scheduleAppointment.priceSelect.free': 'Free',
  'scheduleAppointment.priceSelect.paid': 'Paid',
  'scheduleAppointment.selectOrInviteClient': 'Find _(client) or invite by email',
  'scheduleAppointment.selectClient': 'Find _(client)',
  'scheduleAppointment.client': '_(Client)',
  'scheduleAppointment.location': 'Location',
  'scheduleAppointment.location.placeholder': 'Select location',
  'scheduleAppointment.firstName': 'First name',
  'scheduleAppointment.lastName': 'Last name',
  'scheduleAppointment.repeatSection.repeatLabel': 'Repeat',
  'scheduleAppointment.repeatSection.weeklyIntervalLabel': 'Repeat',
  'scheduleAppointment.repeatSection.weeklyInterval.optionWeekly': 'Weekly',
  'scheduleAppointment.repeatSection.weeklyInterval.optionEveryTwoWeeks': 'Every 2 weeks',
  'scheduleAppointment.repeatSection.weeklyInterval.optionEveryThreeWeeks': 'Every 3 weeks',
  'scheduleAppointment.repeatSection.weeklyInterval.optionEveryFourWeeks': 'Every 4 weeks',
  'scheduleAppointment.repeatSection.weeklyIntervalPlaceholder': 'Date frequency',
  'scheduleAppointment.repeatSection.repeatCountLabel': '_(Appointments)',
  'scheduleAppointment.repeatSection.endsLabel': 'Ends',
  'scheduleAppointment.repeatSection.endsPlaceholder': 'Ending',
  'scheduleAppointment.repeatSection.ends.optionAfter': 'After',
  'scheduleAppointment.repeatSection.ends.optionOnDate': 'On date',
  'scheduleAppointment.repeatSection.dayOfWeekMonday': 'M',
  'scheduleAppointment.repeatSection.dayOfWeekTuesday': 'T',
  'scheduleAppointment.repeatSection.dayOfWeekWednesday': 'W',
  'scheduleAppointment.repeatSection.dayOfWeekThursday': 'T',
  'scheduleAppointment.repeatSection.dayOfWeekFriday': 'F',
  'scheduleAppointment.repeatSection.dayOfWeekSaturday': 'S',
  'scheduleAppointment.repeatSection.dayOfWeekSunday': 'S',
  'scheduleAppointment.repeatSection.dayOfWeekMondayTooltip': 'Monday',
  'scheduleAppointment.repeatSection.dayOfWeekTuesdayTooltip': 'Tuesday',
  'scheduleAppointment.repeatSection.dayOfWeekWednesdayTooltip': 'Wednesday',
  'scheduleAppointment.repeatSection.dayOfWeekThursdayTooltip': 'Thursday',
  'scheduleAppointment.repeatSection.dayOfWeekFridayTooltip': 'Friday',
  'scheduleAppointment.repeatSection.dayOfWeekSaturdayTooltip': 'Saturday',
  'scheduleAppointment.repeatSection.dayOfWeekSundayTooltip': 'Sunday',
  'scheduleAppointment.repeatSection.untilDateLabel': 'Date',
  'scheduleAppointment.pricePlaceholder': '$',
  'scheduleAppointment.paymentRequired': 'Require prepayment',
  'scheduleAppointment.noPaymentMethodOwner': '{link} to send payment requests to _(clients).',
  'scheduleAppointment.noPaymentMethodOwner.linkText': 'Connect a payment method',
  'scheduleAppointment.noPaymentMethodAdminOrExpert':
    'Ask the workspace owner to connect a payment method to send payment requests to _(client).',
  'scheduleAppointment.rescheduleAndCancel': 'Reschedule and cancellation policy',
  'scheduleAppointment.rescheduleAndCancel.isAbleToReschedule': 'Let _(clients) reschedule _(appointments)',
  'scheduleAppointment.rescheduleAndCancel.isAbleToCancel': 'Refund cancelled _(appointments)',
  'scheduleAppointment.startTime': 'Start time',
  'scheduleAppointment.saveAndAddNew': 'Save & Add new',
  'scheduleAppointment.addAppointment': 'Add _(appointment)',
  'scheduleAppointment.edit.info': 'The _(client) will get an email with new _(appointment) details.',
  'scheduleAppointment.edit.info.withInviteMembers':
    'All members will get an email with the updated _(appointment) details.',
  'scheduleAppointment.availabilityPreferences.info': 'Availability preference: {availability}',
  'scheduleAppointment.reschedule.info.withInviteMembers':
    'All invited members will get an email with new _(appointment) details.',
  'scheduleAppointment.conflicts': 'Conflicts with another _(appointment). Please select another time',
  'scheduleAppointment.recurringConflicts':
    'Conflicts with another _(appointment) in your calendar on the following days: {dates}',
  'scheduleAppointment.notAvailable': '_(Expert) is not available at this time. Please select another time',
  'scheduleAppointment.notAvailableWithLocation':
    'The _(expert) is not available at this location. Please select another time or location',
  'scheduleAppointment.recurringNotAvailableForExpert':
    "Some _(appointments) are outside {selectedEmployeeName}'s availability",
  'scheduleAppointment.recurringNotAvailableForYou': 'Some _(appointments) are outside your availability',
  'scheduleAppointment.recurringNotAvailableForExpertWithLocation':
    "Some _(appointments) are outside {selectedEmployeeName}'s availability in this location",
  'scheduleAppointment.recurringNotAvailableForYouWithLocation':
    'Some _(appointments) are outside your availability in this location',
  'scheduleAppointment.inviteNewClientWithEmail': 'Invite a new _(client) with email',
  'scheduleAppointment.assignExpertsToService.noAssignedExperts':
    'The selected _(service) has no assigned _(experts). {assignExpertsBtn}',
  'scheduleAppointment.assignExpertsToService.assignExperts': 'Assign _(experts) to _(service)',
  'scheduleAppointment.assignClientDropdown.noMatchesFound': 'No matches found',
  'scheduleAppointment.assignClientDropdown.noMatchesFoundAndInvite':
    'No matches found. To invite a new _(client), type their email.',
  'scheduleAppointment.assignClientDropdown.typeClientsEmailToInvite':
    'Type your _(client)’s email to invite them to the _(appointment).',
  'scheduleAppointment.appointmentName': '_(Appointment) name',
  'scheduleAppointment.appointmentDescription': 'Description <span>(optional)</span>',
  'scheduleAppointment.appointmentDescription.placeholder': 'Add a short introduction for _(clients) here',
  'scheduleAppointment.additionalSettings.title': 'Additional settings',
  'scheduleAppointment.additionalSettings.description': 'Set reschedule and cancellation policy',

  'scheduleAppointment.locationSelect.expertbox': 'ExpertBox video call',
  'scheduleAppointment.locationSelect.google_meet': 'Google Meet',
  'scheduleAppointment.locationSelect.microsoft_teams': 'Microsoft Teams',
  'scheduleAppointment.locationSelect.zoom': 'Zoom',
  'scheduleAppointment.locationSelect.offlineLocations': 'Offline locations',
  'scheduleAppointment.locationSelect.onlineLocation': 'Online location',

  'scheduleAppointment.clientProfile.addAppointment': 'Add _(appointment)',
  'scheduleAppointment.prepayMessage':
    'The _(client) will pay the full price online when accepting the _(appointment) invitation.',
  'scheduleAppointment.postPayMessage.withManualPayment':
    'The _(client) can choose to pay online or offline according to your instructions. The payment is due {dueDate} at 11:59 PM.',
  'scheduleAppointment.postPayMessage.withoutManualPayment':
    'The _(client) should pay online until {dueDate} at 11:59 PM',
}

export default scheduleAppointment
