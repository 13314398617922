import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'

import { ALERT_KINDS } from 'lib/constants'
import confirmationCodeSchema from 'lib/yupLocalised/schemas/deleteClient'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  deleteClientOwnAccount,
  resendDeleteConfirmationCode as resendClientDeleteConfirmationCode,
} from 'state/concepts/client/actions'
import { currentClientSelector } from 'state/concepts/session/selectors'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import {
  deleteClientContactOwnAccount,
  resendDeleteConfirmationCode as resendClientContactDeleteConfirmationCode,
} from 'state/concepts/clientContacts/actions'

const DeleteForm = ({ onClose, currentContact }) => {
  const email = prop('email', useSelector(currentClientSelector))
  const handleSubmit = useFormSubmit(currentContact ? deleteClientContactOwnAccount : deleteClientOwnAccount)
  const handleResend = useDispatchAction(
    currentContact ? resendClientContactDeleteConfirmationCode : resendClientDeleteConfirmationCode,
    currentContact || email,
  )

  return (
    <Formik
      initialValues={{ email, confirmationCode: '', contactId: currentContact?.id }}
      validationSchema={confirmationCodeSchema}
      onSubmit={handleSubmit}
    >
      {({ status, isSubmitting }) => (
        <>
          <div className="w-100 text-left">
            <p>
              <FormattedMessage id="client.account.settings.tab.general.delete.modal.confirmationCode.text" />
            </p>
            {prop('base', status) && (
              <Alert type={ALERT_KINDS.error} className="auth__alert" message={prop('base', status)} />
            )}
            <Field
              name="confirmationCode"
              component={InputField}
              id="confirmationCode"
              label={{ id: 'client.account.settings.tab.general.delete.modal.confirmationCode.label' }}
              placeholder={{ id: 'client.account.settings.tab.general.delete.modal.confirmationCode.placeholder' }}
              icon="alert"
              autoComplete="off"
              className="mb-16 max-w-328"
              data-cy="confirmation-code-input"
            />
            <p>
              <span>
                <FormattedMessage id="client.account.settings.tab.general.delete.modal.confirmationCode.didNotGetCode" />
              </span>
              <span className="main-link font-600">
                <button className="main-link font-600" onClick={handleResend}>
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.confirmationCode.resend" />
                </button>
              </span>
            </p>
          </div>
          <div className="main-card__footer pt-8">
            <Button className="main-modal__footer-action" text={{ id: 'shared.leave' }} onClick={onClose} kind="flat" />
            <SubmitFormButton
              text={{
                id: `client.account.settings.tab.general.${
                  currentContact ? 'deleteContact' : 'delete'
                }.modal.submitButton`,
              }}
              className="main-modal__footer-action"
              kind="danger"
              disabled={isSubmitting}
              dataCy="submit-delete-client-account"
            />
          </div>
        </>
      )}
    </Formik>
  )
}

DeleteForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentContact: PropTypes.shape(),
}

DeleteForm.defaultProps = {
  currentContact: null,
}

export default DeleteForm
