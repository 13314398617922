import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NextLink from 'next/link'

import { currentWorkspaceCodeSelector, isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'

class Link extends React.Component {
  static propTypes = {
    workspaceCode: PropTypes.string,
    isEmployeeLoggedIn: PropTypes.bool.isRequired,
    href: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    isRoot: PropTypes.bool,
    query: PropTypes.shape(),
    shallow: PropTypes.bool,
    isFullLink: PropTypes.bool,
    passHref: PropTypes.bool,
    hash: PropTypes.string,
    className: PropTypes.string,
    dataCy: PropTypes.string,
    legacyBehavior: PropTypes.bool,
    target: PropTypes.string,
    rel: PropTypes.string,
    isRawLink: PropTypes.bool,
  }

  static defaultProps = {
    as: undefined,
    href: undefined,
    isRoot: false,
    workspaceCode: undefined,
    query: undefined,
    shallow: false,
    isFullLink: false,
    passHref: false,
    hash: undefined,
    className: undefined,
    dataCy: undefined,
    legacyBehavior: true,
    target: undefined,
    rel: undefined,
    isRawLink: false,
  }

  get isWorkspaceLink() {
    const { workspaceCode, isRoot, isEmployeeLoggedIn } = this.props

    return !isRoot && workspaceCode && isEmployeeLoggedIn
  }

  get href() {
    const { workspaceCode, href, as, query, isFullLink, hash, isRawLink } = this.props

    if (isRawLink) {
      return href
    }

    return {
      pathname: this.isWorkspaceLink && !isFullLink ? `/${workspaceCode}${as || href}` : as || href,
      query,
      hash,
    }
  }

  render() {
    const { children, shallow, passHref, className, dataCy, legacyBehavior, target, rel } = this.props

    return (
      <NextLink
        href={this.href}
        shallow={shallow}
        passHref={passHref}
        legacyBehavior={legacyBehavior}
        className={className}
        data-cy={dataCy}
        target={target}
        rel={rel}
      >
        {children}
      </NextLink>
    )
  }
}

const mapStateToProps = state => ({
  workspaceCode: currentWorkspaceCodeSelector(state),
  isEmployeeLoggedIn: isEmployeeLoggedInSelector(state),
})

export { Link as LinkContainer }
export default connect(mapStateToProps)(Link)
