import { combineReducers } from '@reduxjs/toolkit'
import { dissoc, mergeWith, compose, uniq, concat } from 'ramda'

import { SET_SEARCH_RESULTS, RESET_SEARCH_RESULTS, ADD_SEARCH_RESULTS, SET_SEARCH_RESULTS_BLANK_STATE } from './types'

const entities = (state = {}, action) => {
  switch (action.type) {
    case ADD_SEARCH_RESULTS:
      return mergeWith(compose(uniq, concat), state, action.searchParams)
    case SET_SEARCH_RESULTS:
      return { ...state, ...action.searchParams }
    case RESET_SEARCH_RESULTS:
      return dissoc(action.resetPath, state)
    default:
      return state
  }
}

const isBlankState = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS_BLANK_STATE:
      return {
        ...state,
        ...action.isBlankState,
      }
    default:
      return state
  }
}

export default combineReducers({
  entities,
  isBlankState,
})
