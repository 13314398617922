import React from 'react'
import PropTypes from 'prop-types'

import ProvisionFieldsComponent from './component'

class ProvisionFields extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
  }

  addRow = () =>
    this.props.push({
      services: [],
      price: undefined,
      key: Math.random(),
      isFreePrice: false,
    })

  removeRow = index => () => {
    const { remove, form } = this.props
    if (form.values.provision.length < 2) {
      return
    }

    remove(index)
  }

  render() {
    return <ProvisionFieldsComponent {...this.props} addRow={this.addRow} removeRow={this.removeRow} />
  }
}

export default ProvisionFields
