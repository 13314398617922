import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const ArrowRightLong = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M387.138 292.572H36.572C16.374 292.572 0 276.198 0 256s16.374-36.572 36.572-36.572h350.566L266.712 99.002c-14.281-14.281-14.281-37.438 0-51.72s37.438-14.282 51.72 0l182.857 182.857c14.282 14.281 14.282 37.438 0 51.72L318.432 464.716c-14.281 14.281-37.438 14.281-51.72 0s-14.281-37.438 0-51.72L387.138 292.57z" />
  </svg>
)

ArrowRightLong.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

ArrowRightLong.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default ArrowRightLong
