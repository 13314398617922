import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Filter = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M32 32h448c17.673 0 32 14.327 32 32s-14.327 32-32 32H32C14.327 96 0 81.673 0 64s14.327-32 32-32zm192 384h64c17.673 0 32 14.327 32 32s-14.327 32-32 32h-64c-17.673 0-32-14.327-32-32s14.327-32 32-32zm-96-192h256c17.673 0 32 14.327 32 32s-14.327 32-32 32H128c-17.673 0-32-14.327-32-32s14.327-32 32-32z" />
  </svg>
)

Filter.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Filter.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Filter
