import { createSelector } from '@reduxjs/toolkit'
import { equals, filter, path, pathEq, pipe } from 'ramda'
import build from 'redux-object'

import { WEBSITE_STATUSES } from 'lib/constants/website'
import { dataSelector, endpointMetaSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { deleteWebsiteEndpoint, fetchPublicWebsiteReviewsEndpoint, fetchWebsiteEndpoint } from './endpoints'

export const websiteIdSelector = path(['website', 'websiteId'])
export const serviceIdsSelector = path(['website', 'serviceIds'])
export const providerIdsSelector = path(['website', 'providerIds'])
export const reviewIdsSelector = path(['website', 'reviewIds'])
export const locationIdsSelector = path(['website', 'locationIds'])

export const websiteDraftSelector = path(['website', 'websiteDraft'])
export const websiteSelector = createSelector([websiteIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'website', id) : null,
)

export const websiteOrDraftSelector = createSelector(
  [websiteDraftSelector, websiteSelector],
  (websiteDraft, website) => websiteDraft || website,
)

export const isWebsiteDraftChangedSelector = createSelector(
  [websiteDraftSelector, websiteSelector],
  (websiteDraft, website) => !equals(websiteDraft, website),
)

export const providersSelector = createSelector([providerIdsSelector, dataSelector], buildCollection('provider'))

export const servicesSelector = createSelector([serviceIdsSelector, dataSelector], buildCollection('service'))
export const reviewsSelector = createSelector([reviewIdsSelector, dataSelector], buildCollection('review'))
export const reviewsByProviderIdSelector = createSelector(
  [reviewsSelector, (_, providerId) => providerId],
  (reviews, providerId) => filter(pathEq(providerId, ['userProfile', 'id']), reviews),
)
export const locationsSelector = createSelector([locationIdsSelector, dataSelector], buildCollection('location'))

export const fetchPublicWebsiteReviewsMetaSelector = (state, workspaceCode, widgetId) =>
  endpointMetaSelector(state, fetchPublicWebsiteReviewsEndpoint(workspaceCode, widgetId).endpoint)

export const websiteLoadingSelector = state => loadingSelector(state, fetchWebsiteEndpoint.endpoint)
export const deleteWebsiteLoadingSelector = state => loadingSelector(state, deleteWebsiteEndpoint.endpoint)

export const isWebsitePublishedSelector = pipe(websiteSelector, website =>
  equals(website.status, WEBSITE_STATUSES.published),
)
