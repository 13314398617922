import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingRecurringPaymentsRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { fetchRecurringPayments } from 'state/concepts/recurringPayments/actions'
import { DELETE_DRAFT_RECURRING_PAYMENT } from 'state/concepts/recurringPayment/types'
import { deleteDraftRecurringPaymentEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { hideModal } from 'state/modal/actions'

const deleteDraftRecurringPaymentOperation = createLogic({
  type: DELETE_DRAFT_RECURRING_PAYMENT,
  latest: true,

  async process({ httpClient, getState, action: { recurringPaymentId, refetchRecurringPayments } }, dispatch, done) {
    const { url, endpoint } = deleteDraftRecurringPaymentEndpoint(recurringPaymentId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      if (refetchRecurringPayments) {
        dispatch(fetchRecurringPayments())
      } else {
        await redirect({ href: companyBillingRecurringPaymentsRoute, workspace: workspaceCode })
      }
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.recurringPaymentDraftDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteDraftRecurringPaymentOperation
