import { createSelector } from '@reduxjs/toolkit'
import { compose, filter, head, map, pipe, prop, propEq, values } from 'ramda'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import findTimezone from 'utils/findTimezone'
import { getTimezonesEndpoint } from './endpoints'

const timezonesDataSelector = createSelector(dataSelector, compose(values, prop('timezone')))

export const timezonesSelector = createSelector(
  timezonesDataSelector,
  map(({ id, attributes: { name } }) => ({ key: id, value: id, label: name })),
)

export const browserTimezoneSelector = createSelector(timezonesDataSelector, compose(prop('id'), findTimezone))

export const formattedTimezone = timezone =>
  createSelector(timezonesSelector, timezones =>
    pipe(filter(propEq(timezone, 'value')), head, prop('label'))(timezones),
  )

export const timezoneLoadingSelector = state => loadingSelector(state, getTimezonesEndpoint.endpoint)
