import normalize from 'json-api-normalizer'
import { camelCase } from 'lodash'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { updateWorkspaceFeatureActivationEndpoint } from 'state/concepts/workspaceFeatures/endpoints'
import { UPDATE_WORKSPACE_FEATURE_ACTIVATION } from 'state/concepts/workspaceFeatures/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import isPresent from 'utils/isPresent'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'

const updateFeatureActivation = createLogic({
  type: UPDATE_WORKSPACE_FEATURE_ACTIVATION,
  latest: true,

  async process(
    { httpClient, action: { featureKey, isEnable, params, form, closeModal, setErrorStatusUnprocessableEntity } },
    dispatch,
    done,
  ) {
    const { endpoint, url } = updateWorkspaceFeatureActivationEndpoint(featureKey, isEnable)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const args = [url]
      if (isPresent(params)) {
        args.push(params)
      }
      const { data } = await httpClient.post(...args)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      if (isEnable) {
        dispatch(
          showNotification({
            messageObject: {
              id: `notifications.${camelCase(featureKey)}Enabled`,
            },
          }),
        )
      } else {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: {
              id: `notifications.${camelCase(featureKey)}Disabled`,
            },
          }),
        )
        form?.setSubmitting(false)
        closeModal?.()
      }
    } catch (error) {
      form?.setSubmitting(false)

      if (isErrorStatusUnprocessableEntity(error)) {
        dispatch(hideModal())
        setErrorStatusUnprocessableEntity?.(true)
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default updateFeatureActivation
