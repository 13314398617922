import classNames from 'clsx'
import PropTypes from 'prop-types'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import CalendarCheck from 'views/shared/icons/CalendarCheck'
import CalendarO from 'views/shared/icons/CalendarO'
import DropdownButton from 'views/shared/DropdownButton'
import Tooltip from 'views/shared/Tooltip'
import { formatDate as formateDateUtil } from 'utils/dateTime'
import InputText from '../InputText'

const ICON_COMPONENTS = {
  'calendar-check': CalendarCheck,
  'calendar-o': CalendarO,
}

const DatePickerInput = ({
  date,
  onClick,
  formatDate,
  isOpen,
  hideIcon,
  iconName,
  className,
  placeholder,
  isClearShown,
  onClear,
  withTooltip,
  hideDropdownIcon,
}) => {
  const Icon = ICON_COMPONENTS[iconName]

  return (
    <div
      onClick={onClick}
      role="button"
      data-cy="date-picker-btn"
      className={classNames('calendar-btn', className, {
        open: isOpen,
      })}
    >
      {!hideIcon && <Icon className="in-gray-600 mr-8 calendar-icon" size={16} />}
      {withTooltip ? (
        <Tooltip
          placement="top"
          on={['hover']}
          overlayClassName="main-tooltip main-tooltip--no-pointer"
          icon={
            <span>
              <InputText formatDate={formatDate} placeholder={placeholder} date={date} />
            </span>
          }
        >
          <FormattedOrRawMessage message={{ id: 'shared.change' }} />
        </Tooltip>
      ) : (
        <InputText formatDate={formatDate} placeholder={placeholder} date={date} />
      )}
      {!hideDropdownIcon && (
        <DropdownButton
          withTooltip={withTooltip}
          isClearShown={isClearShown}
          isOpen={isOpen}
          onClear={onClear}
          onClick={onClick}
        />
      )}
    </div>
  )
}

DatePickerInput.defaultProps = {
  onClick: undefined,
  formatDate: formateDateUtil,
  isOpen: false,
  hideIcon: false,
  iconName: 'calendar-check',
  hideDropdownIcon: false,
  className: '',
  date: null,
  withTooltip: false,
  onClear: undefined,
}

DatePickerInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]),
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  formatDate: PropTypes.func,
  onClear: PropTypes.func,
  isOpen: PropTypes.bool,
  hideIcon: PropTypes.bool,
  iconName: PropTypes.string,
  hideDropdownIcon: PropTypes.bool,
  className: PropTypes.string,
  isClearShown: PropTypes.bool.isRequired,
  withTooltip: PropTypes.bool,
}

export default DatePickerInput
