import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import UserAltO from 'views/shared/icons/UserAltO'
import { companyClientRoute } from 'lib/routes'
import Link from 'views/shared/Link'
import InvitedClientProfile from './InvitedClientProfile'
import PendingClientProfile from './PendingClientProfile'
import InviteMembersToAppointment from '../InviteMembersToAppointment'

const ClientDetails = ({ booking, booking: { clientProfile } }) => (
  <div className="d-flex gap-16" data-cy="consultation-client">
    <div className="w-20 text-center">
      <UserAltO className="in-blue-gray-200" size={15} />
    </div>
    <div className="flex-1 w-100">
      <p className="in-blue-gray-800 mb-8" data-cy="client-title">
        <FormattedMessage id="bookings.client" />
      </p>
      <div className="d-flex align-items-center">
        <Link href={companyClientRoute(clientProfile.id)}>
          <a className="member-link">
            <span className="member-link__info">
              {clientProfile.firstName ? (
                <InvitedClientProfile clientProfile={clientProfile} />
              ) : (
                <PendingClientProfile clientProfile={clientProfile} />
              )}
            </span>
          </a>
        </Link>
      </div>
      <InviteMembersToAppointment booking={booking} />
    </div>
  </div>
)

ClientDetails.propTypes = {
  booking: PropTypes.shape({
    clientProfile: PropTypes.shape().isRequired,
  }).isRequired,
}

export default ClientDetails
