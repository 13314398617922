import { createLogic } from 'redux-logic'
import { add } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { subscriptionPlanSelector } from 'state/concepts/billing/selectors'
import { showNotification } from 'state/notifications/actions'
import { CANCEL_DOWNGRADE_SEATS } from '../types'
import { cancelDowngradeSeatsEndpoint } from '../endpoints'

const cancelDowngradeSeatsOperation = createLogic({
  type: CANCEL_DOWNGRADE_SEATS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { id, teamSeatsDowngradedQuantity, teamSeatsCapacity } = subscriptionPlanSelector(state)
    const { endpoint, url } = cancelDowngradeSeatsEndpoint

    const newTeamSeatsCapacity = add(teamSeatsCapacity, teamSeatsDowngradedQuantity)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      const response = updateDataHelper(getState().data, 'subscriptionPlan', id, {
        attributes: {
          teamSeatsCapacity: newTeamSeatsCapacity,
          teamSeatsDowngradedQuantity: 0,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.cancelTeamSeatsDowngrade.downgradeIsCancelled',
            values: {
              totalQuantity: newTeamSeatsCapacity,
            },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default cancelDowngradeSeatsOperation
