import { useFormikContext } from 'formik'
import { useCallback } from 'react'

import { hideModal as hideModalAction, showModal as showModalAction } from 'state/modal/actions'
import useDispatchAction from '../useDispatchAction'

const useFormDiscard = (options = {}) => {
  const { modalProps, onDiscard } = options
  const { handleReset } = useFormikContext()
  const hideModal = useDispatchAction(hideModalAction)
  const showModal = useDispatchAction(showModalAction)

  const handleSubmitDiscard = useCallback(() => {
    handleReset()
    hideModal()
    onDiscard?.()
  }, [handleReset, hideModal, onDiscard])

  const handleDiscard = useCallback(() => {
    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: { id: 'shared.discardChanges' },
        bodyText: [{ id: 'shared.cancelText' }],
        submitText: { id: 'shared.discard' },
        submitClassName: 'main-btn--danger',
        dismissText: { id: 'shared.cancel' },
        onSubmit: handleSubmitDiscard,
        ...modalProps,
      },
    })
  }, [handleSubmitDiscard, showModal, modalProps])

  return handleDiscard
}

export default useFormDiscard
