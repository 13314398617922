import {
  formActivationRoute,
  formDeactivationRoute,
  formDuplicationRoute,
  formEmptyDownloadRoute,
  formRoute,
  formSubmittedDownloadRoute,
  formValidationsSettingsRoute,
  formsRoute,
  clientProfileFormResendRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import {
  CREATE_INTAKE_FORM,
  DELETE_INTAKE_FORM,
  DOWNLOAD_FORM,
  DUPLICATE_FORM,
  UPDATE_INTAKE_FORM,
  UPDATE_INTAKE_FORM_ACTIVATION,
  VALIDATE_FORM_DETAILS,
  RESEND_FORM,
} from './types'

export const createIntakeFormEndpoint = endpoint(CREATE_INTAKE_FORM, 'POST', formsRoute)
export const updateIntakeFormEndpoint = id => endpoint(UPDATE_INTAKE_FORM, 'PUT', formRoute(id))
export const deleteIntakeFormEndpoint = id => endpoint(DELETE_INTAKE_FORM, 'DELETE', formRoute(id))
export const updateIntakeFormActivationEndpoint = (formId, isEnable) =>
  endpoint(
    UPDATE_INTAKE_FORM_ACTIVATION,
    'POST',
    isEnable ? formActivationRoute(formId) : formDeactivationRoute(formId),
  )
export const validateFormDetailsEndpoint = endpoint(VALIDATE_FORM_DETAILS, 'POST', formValidationsSettingsRoute)
export const duplicateFormEndpoint = id => endpoint(DUPLICATE_FORM, 'POST', formDuplicationRoute(id))
export const downloadEmptyFormEndpoint = id => endpoint(DOWNLOAD_FORM, 'GET', formEmptyDownloadRoute(id))
export const downloadSubmittedFormEndpoint = id => endpoint(DOWNLOAD_FORM, 'GET', formSubmittedDownloadRoute(id))
export const resendFormEndpoint = id => endpoint(RESEND_FORM, 'POST', clientProfileFormResendRoute(id))
