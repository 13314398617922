import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { switchBillingCycleEndpoint } from 'state/concepts/subscriptionPlans/endpoints'
import { SWITCH_BILLING_CYCLE } from 'state/concepts/subscriptionPlans/types'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { CYCLE_TYPES } from 'lib/constants/billing'

const switchBillingCycleOperation = createLogic({
  type: SWITCH_BILLING_CYCLE,
  latest: true,

  async process({ httpClient, action: { billingCycleType } }, dispatch, done) {
    const { url, endpoint } = switchBillingCycleEndpoint
    const newBillingCycleType = billingCycleType === CYCLE_TYPES.monthly ? CYCLE_TYPES.annual : CYCLE_TYPES.monthly

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, { billing_cycle_type: newBillingCycleType })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.switchBillingCycle.switched',
            values: { newBillingCycleType },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default switchBillingCycleOperation
