const locationsFeature = {
  'locationsFeature.alert.text': 'To disable offline locations, you need to enable video calling feature.',
  'locationsFeature.alert.action': 'Accept video calls',
  'locationsFeature.subtitle': '<span>in</span> Locations',
  'locationsFeature.description': 'Add your physical business locations to offer _(services) offline.',
  'locationsFeature.overview': 'Offline locations overview',
  'locationsFeature.setLocations': 'Set locations',
  'locationsFeature.meetClients':
    'Do you prefer to meet your _(clients) in person? Now you can automate your scheduling across multiple offline and online locations.',
  'locationsFeature.manageTeam': 'Manage your and your team’s availability at each location.',
  'locationsFeature.selectServices': 'Select _(services) you provide at each physical location.',
  'locationsFeature.seeLocations':
    'Let _(clients) see your locations on a map and choose a location for _a(appointment) right from your booking page.',
  'locationsFeature.helpCenter': 'Learn more at our help center article on <link>providing _(services) offline</link>.',
  'locationsFeature.disableLocations': 'Disable offline locations feature?',
  'locationsFeature.disableLocations.alert': 'This won’t affect any of the _(appointments) booked previously.',
  'locationsFeature.disableLocations.bodySubtitle': 'Availability and _(service) actions',
  'locationsFeature.disableLocations.bodyText':
    'What do you want to do with availability and _(services) provided at the offline locations?',
  'locationsFeature.disableLocations.remove': 'Remove availability and stop providing _(services) at these locations',
  'locationsFeature.disableLocations.moveToOnline': 'Change to online',
}

export default locationsFeature
