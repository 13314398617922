import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import CheckboxField from 'views/shared/CheckboxField'
import Cross from 'views/shared/icons/Cross'

const EmployeeDeactivateComponent = ({ onClose, handleSubmit }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employeeDeactivateYourselfModal.title" />
        </h2>
        <button dataCy="cross-icon" type="button" onClick={onClose} className="main-modal__close">
          <Cross />
        </button>
      </div>

      <div className="main-modal__body">
        <p className="mb-16">
          <FormattedMessage id="employeeDeactivateYourselfModal.description" />
        </p>
        <p className="mb-16">
          <FormattedMessage id="employeeDeactivateYourselfModal.description_2" />
        </p>
        <p className="mb-16">
          <FormattedMessage id="employeeDeactivateYourselfModal.note" />
        </p>
        <div className="main-modal__item pt-0">
          <Field
            className="mb-0"
            name="cancelAndRefund"
            component={CheckboxField}
            id="cancelAndRefund"
            label={{ id: 'employeesDeactivateModal.checkbox.cancelAndRefund' }}
          />
        </div>
        <div className="main-modal__item pt-0">
          <Field
            className="mb-0"
            name="deactivateAccount"
            component={CheckboxField}
            id="deactivateAccount"
            label={{ id: 'employeesDeactivateModal.checkbox.deactivateTeamMembersAccount' }}
          />
        </div>
      </div>

      <div className="main-modal__footer">
        <div className="d-flex justify-content-end">
          <Button
            extraClasses="main-modal__footer-action"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onClose}
          />
          <SubmitFormButton
            extraClasses="main-modal__footer-action"
            text={{ id: 'employeesDeactivateModal.button' }}
            kind="danger"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  </Modal>
)

EmployeeDeactivateComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default EmployeeDeactivateComponent
