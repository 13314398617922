import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { pick } from 'ramda'
import { useCallback, useState } from 'react'

import { currentWorkspaceTwoFactorSettingsSelector } from 'state/concepts/session/selectors'
import { TWO_FACTOR_SETTING_METHODS, TWO_FACTOR_SETTING_STEPS } from 'lib/constants/twoFactorSettings'
import isPresent from 'utils/isPresent'
import Cross from 'views/shared/icons/Cross'
import Modal from 'views/shared/Modal'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import steps from './steps'

const TwoFactorSettingsModal = ({ onClose, twoFactorSettings, isDisabling }) => {
  const workspaceTwoFactorSettings = useSelector(currentWorkspaceTwoFactorSettingsSelector)
  const workspaceMethods = pick(['email', 'sms', 'app'], workspaceTwoFactorSettings)
  const isUpdating = isPresent(twoFactorSettings)
  const [currentStep, setStep] = useState(TWO_FACTOR_SETTING_STEPS.password)
  const handleNextStep = useCallback(() => setStep(currentStep + 1), [currentStep, setStep])
  const handleSelectMethodStep = useCallback(() => setStep(TWO_FACTOR_SETTING_STEPS.selectMethod), [setStep])
  const stepData = steps({ isDisabling, isUpdating, workspaceMethods })[currentStep]
  const { title, content: StepComponent, onSubmit, validationSchema } = stepData
  const handleSubmit = useFormSubmit(onSubmit, { onSuccess: handleNextStep })

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">{title}</h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            password: '',
            methodChecker: TWO_FACTOR_SETTING_METHODS.email,
            otpCode: '',
            phoneNumber: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <StepComponent
            onClose={onClose}
            isDisabling={isDisabling}
            isUpdating={isUpdating}
            onBack={handleSelectMethodStep}
            setStep={setStep}
            currentStep={currentStep}
            twoFactorSettings={twoFactorSettings}
            workspaceMethods={workspaceMethods}
          />
        </Formik>
      </div>
    </Modal>
  )
}

TwoFactorSettingsModal.defaultProps = {
  twoFactorSettings: null,
  isDisabling: false,
}

TwoFactorSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  twoFactorSettings: PropTypes.shape({
    methodChecker: PropTypes.string,
  }),
  isDisabling: PropTypes.bool,
}

export default TwoFactorSettingsModal
