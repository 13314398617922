import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import classNames from 'clsx'

import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'

const { Paragraph } = Typography

const EllipsisWithToggle = ({
  text,
  rows,
  isEllipsis,
  isShowMore,
  onToggleEllipsis,
  onToggleShowMore,
  isClientRendered,
  className,
}) =>
  text ? (
    <div>
      {isClientRendered ? (
        <Paragraph
          className={classNames('ellipsis-with-toggle__content', className)}
          data-cy="booking-cancellation-reason"
          ellipsis={
            isEllipsis
              ? {
                  rows,
                  expandable: false,
                  onEllipsis: onToggleShowMore,
                }
              : false
          }
        >
          {text}
        </Paragraph>
      ) : (
        <div
          className={classNames('ellipsis-with-toggle__content', className, `ellipsis-with-toggle__content--${rows}`)}
        >
          {text}
        </div>
      )}

      {isShowMore && (
        <button className="in-blue-600 font-600 mt-4" onClick={onToggleEllipsis}>
          <span className="mr-8">
            <FormattedMessage id={`shared.${isEllipsis ? 'showAll' : 'showLess'}`} />
          </span>
          {isEllipsis ? (
            <ArrowDown size={12} color="currentColor" dataCy="arrow-down-icon" />
          ) : (
            <ArrowUp size={12} color="currentColor" dataCy="arrow-up-icon" />
          )}
        </button>
      )}
    </div>
  ) : null

EllipsisWithToggle.defaultProps = {
  text: undefined,
  className: '',
}

EllipsisWithToggle.propTypes = {
  text: PropTypes.node,
  className: PropTypes.string,
  rows: PropTypes.number.isRequired,
  isEllipsis: PropTypes.bool.isRequired,
  isShowMore: PropTypes.bool.isRequired,
  onToggleEllipsis: PropTypes.func.isRequired,
  onToggleShowMore: PropTypes.func.isRequired,
  isClientRendered: PropTypes.bool.isRequired,
}

export default EllipsisWithToggle
