import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { resetSearchResults } from 'state/searchResults/actions'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { fetchFormCategories } from '../actions'
import { DELETE_FORM_CATEGORY } from '../types'
import { deleteFormCategoryEndpoint } from '../endpoints'

const deleteFormCategoryOperation = createLogic({
  type: DELETE_FORM_CATEGORY,
  latest: true,

  async process({ httpClient, action: { form, values } }, dispatch, done) {
    const { id, newFormCategoryId } = values
    const { url, endpoint } = deleteFormCategoryEndpoint(id)
    const params = {
      form_category_id: newFormCategoryId,
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url, { params })

      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.formCategoryDeleted' } }))
      dispatch(fetchFormCategories())
      dispatch(resetSearchResults(TABLE_ENTITIES.formCategories))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default deleteFormCategoryOperation
