import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import isPresent from 'utils/isPresent'
import setEtherpadSession from 'utils/setEtherpadSession'
import { setDocumentTemplateId } from '../actions'
import { getDocumentTemplateEndpoint } from '../endpoints'
import { GET_DOCUMENT_TEMPLATE } from '../types'

const getDocumentTemplateOperation = createLogic({
  type: GET_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ action: { documentTemplateId, workspace, ctx }, httpClient }, dispatch, done) {
    const { url, endpoint } = getDocumentTemplateEndpoint(documentTemplateId)
    const isServer = isPresent(ctx?.res)

    dispatch(dataApiRequest({ endpoint }))

    const customBaseUrl = isServer
      ? buildCustomBaseUrl(['workspaces', workspace])
      : { customBaseURL: `/api/${workspace}` }

    try {
      const { data } = await httpClient.get(url, { ...customBaseUrl })

      if (isServer) {
        setEtherpadSession(ctx, data.meta?.etherpad_session)
      }
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setDocumentTemplateId(documentTemplateId))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getDocumentTemplateOperation
