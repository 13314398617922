import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { addAppleCalendarConnectionIds } from '../actions'
import { connectAppleCalendarEndpoint } from '../endpoints'
import { CONNECT_APPLE_CALENDAR } from '../types'

const connectAppleCalendar = createLogic({
  type: CONNECT_APPLE_CALENDAR,
  latest: true,

  async process(
    {
      action: {
        values: { appleId, password },
        form,
      },
      httpClient,
    },
    dispatch,
    done,
  ) {
    const { url, endpoint } = connectAppleCalendarEndpoint
    try {
      const params = {
        apple_id: appleId,
        password,
        include: 'apple-calendar-list-items',
      }
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addAppleCalendarConnectionIds([data.data.id]))

      dispatch(showNotification({ messageObject: { id: 'notifications.calendarAdded' } }))
      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default connectAppleCalendar
