import fetchNotifications from './fetchNotifications'
import receiveNotification from './receiveNotification'
import removeNotification from './removeNotification'
import markNotificationAsRead from './markNotificationAsRead'
import markNotificationAsUnread from './markNotificationAsUnread'
import markNotificationsAsRead from './markNotificationsAsRead'

export default [
  fetchNotifications,
  receiveNotification,
  removeNotification,
  markNotificationAsRead,
  markNotificationAsUnread,
  markNotificationsAsRead,
]
