import { createSelector } from '@reduxjs/toolkit'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { showServicesDetailsEndpoint } from './endpoints'

export const serviceSelector = createSelector([(_, serviceId) => serviceId, dataSelector], (serviceId, data) =>
  serviceId ? build(data, 'service', serviceId) : null,
)

export const serviceLoadingSelector = (state, serviceId) =>
  loadingSelector(state, showServicesDetailsEndpoint(serviceId).endpoint)
