import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import SubmitFormButton from 'views/shared/SubmitFormButton'
import SelectField from 'views/shared/SelectField'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import { clientRelationshipTypesForFilterSelector } from 'state/concepts/clientRelationshipTypes/selectors'
import spanWithClass from 'utils/locales/spanWithClass'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { deleteClientRelationshipType } from 'state/concepts/clientRelationshipTypes/actions'

const RemoveClientRelationshipTypeModal = ({ onClose, clientRelationshipType }) => {
  const { id, clientContactsCount, name } = clientRelationshipType
  const hasClientContacts = clientContactsCount > 0
  const clientRelationshipTypes = useSelector(clientRelationshipTypesForFilterSelector)
  const filteredClientRelationshipTypes = clientRelationshipTypes.filter(item => item.value !== id)
  const handleSubmit = useFormSubmit(deleteClientRelationshipType)

  return (
    <Modal className="main-modal main-modal--md modal__custom-field" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="clientRelationshipTypes.removeModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            id,
            newClientRelationshipTypeId: undefined,
          }}
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              {!hasClientContacts && (
                <p className="mb-16">
                  <FormattedMessage id="clientRelationshipTypes.removeModal.noClientContacts" values={{ name }} />
                </p>
              )}
              {hasClientContacts && (
                <>
                  <p className="mb-16">
                    <FormattedMessage
                      id="clientRelationshipTypes.removeModal.clientContactsAssigned"
                      values={{
                        clientContactsCount,
                        span: spanWithClass('font-700'),
                        name,
                      }}
                    />
                  </p>
                  <div>
                    <Field
                      id="newClientRelationshipTypeId"
                      name="newClientRelationshipTypeId"
                      label={{ id: 'clientRelationshipTypes.removeModal.selectNewRelationLabel' }}
                      showSearch
                      placeholder={{ id: 'clientRelationshipTypes.removeModal.selectNewRelationPlaceholder' }}
                      options={filteredClientRelationshipTypes}
                      component={SelectField}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text="Cancel" kind="flat" onClick={onClose} />
              <SubmitFormButton
                allowPristine={!hasClientContacts}
                text={{ id: 'clientRelationshipTypes.removeModal.submitText' }}
                kind="danger"
              />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

RemoveClientRelationshipTypeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  clientRelationshipType: PropTypes.shape({
    id: PropTypes.string,
    clientContactsCount: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
}

export default RemoveClientRelationshipTypeModal
