import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const getPayment = transactionId => ({
  type: types.GET_PAYMENT,
  transactionId,
})

export const acceptAndPay = makeFormSubmitAction(types.ACCEPT_AND_PAY)
export const markAsPaid = makeFormSubmitAction(types.MARK_AS_PAID)
export const issueRefund = makeFormSubmitAction(types.ISSUE_REFUND)

export const getPaymentCheckout = (paymentId, response) => ({
  type: types.GET_PAYMENT_CHECKOUT,
  paymentId,
  response,
})

export const setPaymentId = paymentId => ({
  type: types.SET_PAYMENT_ID,
  paymentId,
})

export const deleteDraftPayment = (paymentId, fromPayments) => ({
  type: types.DELETE_DRAFT_PAYMENT,
  paymentId,
  fromPayments,
})

export const sendPayment = (paymentId, isResend = false) => ({
  type: types.SEND_PAYMENT,
  paymentId,
  isResend,
})

export const updatePayment = makeFormSubmitAction(types.UPDATE_PAYMENT)

export const disablePaymentCancellation = paymentId => ({
  type: types.DISABLE_PAYMENT_CANCELLATION,
  paymentId,
})

export const chargeWithCardReader = makeFormSubmitAction(types.CHARGE_WITH_CARD_READER)
