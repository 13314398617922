import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const submitForm = makeFormSubmitAction(types.SUBMIT_FORM)

export const fetchForm = (id, options = {}) => ({
  type: types.FETCH_FORM,
  id,
  options,
})

export const setFormId = id => ({
  type: types.SET_FORMS_ID,
  id,
})

export const fetchFormSubmission = id => ({
  type: types.FETCH_FORM_SUBMISSION,
  id,
})

export const setUploadProgress = (uploadId, progress) => ({
  type: types.SET_UPLOAD_PROGRESS,
  uploadId,
  progress,
})

export const createAttachmentUpload = (id, fileUpload, onSuccess) => ({
  type: types.CREATE_ATTACHMENT_UPLOAD,
  fileUpload,
  id,
  onSuccess,
})

export const resetUploadProgress = () => ({
  type: types.RESET_UPLOAD_PROGRESS,
})

export const fetchClientProfileForm = id => ({
  type: types.FETCH_CLIENT_PROFILE_FORM,
  id,
})

export const updateFormSubmission = makeFormSubmitAction(types.UPDATE_FORM_SUBMISSION)

export const autoSaveResponses = (values, onEnd) => ({
  type: types.AUTO_SAVE_RESPONSES,
  values,
  onEnd,
})

export const setFormSubmissionId = id => ({
  type: types.SET_FORM_SUBMISSION_ID,
  id,
})

export const setIsAutoSaveError = isAutoSaveError => ({
  type: types.SET_IS_AUTO_SAVE_ERROR,
  isAutoSaveError,
})

export const cancelAutoSaveResponses = () => ({
  type: types.CANCEL_AUTO_SAVE_RESPONSES,
})

export const clearAutoSaveResponses = id => ({
  type: types.CLEAR_AUTO_SAVE_RESPONSES,
  id,
})

export const signFormSubmission = makeFormSubmitAction(types.SIGN_FORM_SUBMISSION)

export const declineToSignForm = makeFormSubmitAction(types.DECLINE_TO_SIGN_FORM)
