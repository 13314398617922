import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchFormCategories = () => ({
  type: types.FETCH_FORM_CATEGORIES,
})

export const searchFormCategories = ({ resetResults, filter } = {}) => ({
  type: types.SEARCH_FORM_CATEGORIES,
  resetResults,
  filter,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const filterFormCategories = (filters = {}) => ({
  type: types.FILTER_FORM_CATEGORIES,
  filters,
})

export const setFormCategoryIds = ids => ({
  type: types.SET_FORM_CATEGORIES_IDS,
  ids,
})

export const setTotalCount = count => ({
  type: types.SET_TOTAL_COUNT,
  count,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BLANK_STATE,
  isBlankState,
})

export const updateFormCategoryStatus = (id, newStatus) => ({
  type: types.UPDATE_FORM_CATEGORY_STATUS,
  id,
  newStatus,
})

export const createFormCategory = makeFormSubmitAction(types.CREATE_FORM_CATEGORY)

export const updateFormCategory = makeFormSubmitAction(types.UPDATE_FORM_CATEGORY)

export const deleteFormCategory = makeFormSubmitAction(types.DELETE_FORM_CATEGORY)
