import { omit } from 'ramda'
import { createLogic } from 'redux-logic'

import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchCalendarEvents } from 'state/concepts/bookings/actions'
import { showNotification } from 'state/notifications/actions'
import { hideSidebar, showSidebar } from 'state/sidebar/actions'
import initialCreateFormValues from 'utils/bookings/initialCreateFormValues'
import scheduleBookingFormToParams from 'utils/bookings/scheduleBookingFormToParams'
import { timezoneOffsetSelector } from 'state/concepts/bookings/selectors'
import { createBookingEndpoint, createRecurringBookingsEndpoint } from '../endpoints'
import { SCHEDULE_BOOKING } from '../types'

const scheduleBookingOperation = createLogic({
  type: SCHEDULE_BOOKING,
  latest: true,

  async process(
    {
      httpClient,
      getState,
      action: {
        values: { scheduleNewAppointment, repeat },
        values,
        form,
      },
    },
    dispatch,
    done,
  ) {
    const { url } = repeat ? createRecurringBookingsEndpoint : createBookingEndpoint
    const state = getState()
    const timezoneOffset = timezoneOffsetSelector(state)
    const params = scheduleBookingFormToParams(values, timezoneOffset)

    try {
      await httpClient.post(url, params)

      dispatch(fetchCalendarEvents())
      dispatch(hideSidebar())

      dispatch(showNotification({ messageObject: { id: 'notifications.appointmentWasScheduled' } }))

      if (scheduleNewAppointment) {
        form.setSubmitting(false)
        dispatch(
          showSidebar({
            sidebarType: SIDEBAR_COMPONENTS_TYPES.createBookingAppointment,
            sidebarProps: {
              ...initialCreateFormValues(),
              ...omit(
                [
                  'clientProfile',
                  'date',
                  'time',
                  'scheduleNewAppointment',
                  'repeat',
                  'untilDate',
                  'repeatCount',
                  'weeklyInterval',
                  'ends',
                  'byWeekday',
                ],
                values,
              ),
            },
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default scheduleBookingOperation
