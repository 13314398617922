import { createLogic } from 'redux-logic'

import { CALENDAR_CONNECTION_STATUSES } from 'lib/constants'
import { CALENDAR_TYPES } from 'lib/constants/calendarIntegrations'
import requestErrorHandler from 'lib/requestErrorHandler'
import {
  appleCalendarConnectionSelector,
  calendarIntegrationSelector,
} from 'state/concepts/calendarIntegrations/selectors'
import { DISCONNECT_APPLE_CALENDAR } from 'state/concepts/calendarIntegrations/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { isCalendarSyncDisconnected } from 'utils/calendarIntegrations'
import updateDataHelper from 'utils/updateDataHelper'
import { disconnectAppleCalendarEndpoint } from '../endpoints'

const disconnectAppleCalendarOperation = createLogic({
  type: DISCONNECT_APPLE_CALENDAR,
  latest: true,

  async process({ action: { id }, httpClient, getState }, dispatch, done) {
    const { endpoint, url } = disconnectAppleCalendarEndpoint(id)
    const state = getState()
    const calendarIntegration = calendarIntegrationSelector(state)
    const connection = appleCalendarConnectionSelector(state, id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      const response = updateDataHelper(state.data, 'appleCalendarConnection', id, {
        attributes: { status: CALENDAR_CONNECTION_STATUSES.removing },
      })

      const calendarIntegrationResponse = isCalendarSyncDisconnected(
        connection,
        calendarIntegration,
        CALENDAR_TYPES.apple,
      )
        ? updateDataHelper(state.data, 'calendarIntegration', calendarIntegration?.id, {
            attributes: {
              calendarSyncId: null,
              calendarSyncType: null,
            },
          })
        : {}

      dispatch(dataApiSuccess({ response: { ...response, ...calendarIntegrationResponse }, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.calendarRemoved' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default disconnectAppleCalendarOperation
