import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { currentOnboardingContentSelector } from 'state/concepts/session/selectors'
import { updateOnboardingContent as updateOnboardingContentAction } from 'state/concepts/tutorial/actions'
import WelcomeModalComponent from './component'

class WelcomeModal extends React.Component {
  static defaultProps = {
    title: undefined,
  }

  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    title: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    page: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    updateOnboardingContent: PropTypes.func.isRequired,
  }

  sliderRef = React.createRef()

  state = {
    currentSlideIndex: 0,
  }

  get isLastSlide() {
    const { currentSlideIndex } = this.state
    const { steps } = this.props

    return currentSlideIndex + 1 === steps.length
  }

  handleSlideChange = (_, newIndex) => {
    this.setState({ currentSlideIndex: newIndex })
  }

  handleNext = () => {
    this.sliderRef.current.slickNext()
  }

  handleClose = () => {
    const { page, updateOnboardingContent, onClose } = this.props

    onClose()
    updateOnboardingContent(page)
  }

  render() {
    return (
      <WelcomeModalComponent
        {...this.props}
        {...this.state}
        sliderRef={this.sliderRef}
        onSlideChange={this.handleSlideChange}
        isLastSlide={this.isLastSlide}
        onNext={this.handleNext}
        onClose={this.handleClose}
      />
    )
  }
}

const mapStateToProps = state => ({
  onboardingContent: currentOnboardingContentSelector(state),
})

const mapDispatchToProps = {
  updateOnboardingContent: updateOnboardingContentAction,
}

export { WelcomeModal as WelcomeModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal)
