import { createLogic } from 'redux-logic'
import { omit, pluck } from 'ramda'
import normalize from 'json-api-normalizer'

import isPageOverflow from 'utils/isPageOverflow'
import { setCurrentPage } from 'state/tableData/actions'
import isBlankState from 'utils/isBlankState'
import { documentTemplateFiltersToParams } from 'utils/documentTemplates'
import generateSortString from 'utils/generateSortString'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { tablePaginationSelector, tableOrderDirectionsSelector } from 'state/tableData/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { fetchDocumentTemplatesEndpoint } from '../endpoints'
import { filtersSelector } from '../selectors'
import { FETCH_DOCUMENT_TEMPLATES } from '../types'
import { setDocumentTemplatesIds, setBlankState, fetchDocumentTemplates, addDocumentTemplatesIds } from '../actions'

const fetchDocumentTemplatesOperation = createLogic({
  type: FETCH_DOCUMENT_TEMPLATES,
  latest: true,

  async process(
    {
      httpClient,
      getState,
      action: {
        options = {
          omittedBlankStateFilters: [],
          filters: {},
        },
      },
    },
    dispatch,
    done,
  ) {
    const { url, endpoint } = fetchDocumentTemplatesEndpoint

    const state = getState()
    const filters = documentTemplateFiltersToParams(filtersSelector(state))
    const sort = generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.documentTemplates))

    const params = {
      page: {
        ...tablePaginationSelector(state, TABLE_ENTITIES.documentTemplates),
      },
      filter: {
        ...filters,
        ...options.filters,
      },
      sort,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      dispatch(
        setBlankState(isBlankState({ data: data.data, params: omit(options.omittedBlankStateFilters, filters) })),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      const ids = pluck('id', data.data)
      options.append ? dispatch(addDocumentTemplatesIds(ids)) : dispatch(setDocumentTemplatesIds(ids))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(TABLE_ENTITIES.documentTemplates, 1))
        dispatch(fetchDocumentTemplates())
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchDocumentTemplatesOperation
