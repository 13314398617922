import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { downloadSubscriptionTransactionEndpoint } from 'state/concepts/billing/endpoints'
import { DOWNLOAD_SUBSCRIPTION_TRANSACTION } from 'state/concepts/billing/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const downloadSubscriptionTransactionOperation = createLogic({
  type: DOWNLOAD_SUBSCRIPTION_TRANSACTION,
  latest: true,

  async process({ httpClient, action: { id, isDownload } }, dispatch, done) {
    const { endpoint, url } = downloadSubscriptionTransactionEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data, { endpoint }) }))

      if (isDownload) {
        const invoiceUrl = data.data.attributes['invoice-pdf-url']
        const a = document.createElement('a')
        a.target = '_blank'
        a.rel = 'noopener noreferrer'
        a.href = invoiceUrl

        a.click()
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default downloadSubscriptionTransactionOperation
