const namespace = 'videoConference'

export const FETCH_VIDEO_CONFERENCE_DETAILS = `${namespace}/FETCH_VIDEO_CONFERENCE_DETAILS`
export const SET_VIDEO_CONFERENCE = `${namespace}/SET_VIDEO_CONFERENCE`
export const SET_IS_RECORDING = `${namespace}/SET_IS_RECORDING`
export const TOGGLE_RECORDING = `${namespace}/TOGGLE_RECORDING`
export const SET_LOCAL_SETTINGS = `${namespace}/SET_LOCAL_SETTINGS`
export const RESET_LOCAL_SETTINGS = `${namespace}/RESET_LOCAL_SETTINGS`
export const SET_IS_PREVIEW = `${namespace}/SET_IS_PREVIEW`
export const SET_IS_SCREEN_SHARED = `${namespace}/SET_IS_SCREEN_SHARED`
export const JOIN_VIDEO_CONFERENCE = `${namespace}/JOIN_VIDEO_CONFERENCE`
export const RECEIVE_CHAT_MESSAGE = `${namespace}/RECEIVE_CHAT_MESSAGE`
export const CLEAR_CHAT_MESSAGES = `${namespace}/CLEAR_CHAT_MESSAGES`
export const SET_VIDEO_RECORD_EVENTS = `${namespace}/SET_VIDEO_RECORD_EVENTS`
export const ADD_VIDEO_RECORD_EVENTS = `${namespace}/ADD_VIDEO_RECORD_EVENTS`
export const CREATE_CHAT_MESSAGE = `${namespace}/CREATE_CHAT_MESSAGE`
export const SET_IS_SOUND_TRACKED = `${namespace}/SET_IS_SOUND_TRACKED`
export const TOGGLE_SIDEBAR_OPEN = `${namespace}/TOGGLE_SIDEBAR_OPEN`
export const IS_SIDEBAR_OPEN = `${namespace}/IS_SIDEBAR_OPEN`
