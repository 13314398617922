import { createSelector } from '@reduxjs/toolkit'
import { path, prop } from 'ramda'

import { fetchCompanyClientReviewsEndpoint } from 'state/concepts/companyClientReviews/endpoints'
import { dataSelector, loadingSelector, pageCountSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

export const clientReviewIdsSelector = path(['companyClientReviews', 'reviewIds'])

export const clientReviewsSelector = createSelector([clientReviewIdsSelector, dataSelector], buildCollection('review'))

export const paginationSelector = path(['companyClientReviews', 'pagination'])

export const currentPageSelector = createSelector(paginationSelector, prop('number'))

export const clientReviewsPageCountSelector = state =>
  pageCountSelector(state, fetchCompanyClientReviewsEndpoint.endpoint)

export const clientReviewsLoadingSelector = state => loadingSelector(state, fetchCompanyClientReviewsEndpoint.endpoint)
