import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'
import { flatten } from 'ramda'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import BodyText from './BodyText'

const ConfirmModal = ({
  onClose,
  onSubmit,
  title,
  bodyText,
  submitText,
  submitClassName,
  dismissText,
  isLoading,
  kind,
  modalClassName,
  isCloseable,
  isFooterCloseButton,
  dismissKind,
}) => (
  <Modal
    className={classNames('main-modal main-modal--md', modalClassName)}
    onClose={isCloseable ? onClose : undefined}
  >
    <div className="main-modal__container d-block" data-cy="confirm-modal">
      <div className="main-modal__header">
        <h2 className="main-modal__title" data-cy="modal-title">
          <FormattedMessage {...title} />
        </h2>
        {isCloseable && (
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        )}
      </div>
      <div className="main-modal__body">
        {bodyText.map((paragraph, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <p className={classNames('main-modal__body-text', { 'mb-0': bodyText.length === index + 1 })} key={index}>
            {flatten([paragraph]).map(text => (
              <BodyText key={text.id} {...text} />
            ))}
          </p>
        ))}
      </div>
      <div className="main-modal__footer">
        <div>
          {isCloseable && isFooterCloseButton && (
            <Button
              className="main-modal__footer-action"
              text={dismissText}
              onClick={onClose}
              kind={dismissKind || 'flat'}
              dataCy="modal-cancel"
            />
          )}
          {onSubmit && (
            <Button
              className={classNames('main-modal__footer-action', submitClassName)}
              type="submit"
              text={submitText}
              onClick={onSubmit}
              disabled={isLoading}
              isLoading={isLoading}
              kind={kind}
              dataCy="modal-submit"
            />
          )}
        </div>
      </div>
    </div>
  </Modal>
)

ConfirmModal.defaultProps = {
  isLoading: undefined,
  kind: null,
  dismissText: { id: 'shared.cancel' },
  submitText: { id: 'shared.ok' },
  submitClassName: null,
  modalClassName: null,
  isCloseable: true,
  isFooterCloseButton: true,
  onSubmit: undefined,
  dismissKind: null,
}

ConfirmModal.propTypes = {
  kind: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }).isRequired,
  bodyText: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node])).isRequired,
  submitText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  dismissText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  submitClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  isCloseable: PropTypes.bool,
  isFooterCloseButton: PropTypes.bool,
  dismissKind: PropTypes.string,
}

export default ConfirmModal
