const signatureSettings = {
  'signatureSettings.auditTrailEnabled.title': 'Display audit trail for completed documents',
  'signatureSettings.auditTrailEnabled.description':
    'Once all signatories have signed the document, an audit trail will automatically be added to each downloaded PDF.',
  'signatureSettings.disclosureEnabled.title': 'Enable eSignature disclosure',
  'signatureSettings.disclosureEnabled.description': 'Require an explicit opt-in with e-Sign disclosure.',
  'signatureSettings.disclosureEnabled.name.label': 'Agreement name',
  'signatureSettings.disclosureEnabled.content.label': 'Agreement content',
  'signatureSettings.disclosureEnabled.content.placeholder': 'Agreement content',
  'signatureSettings.disclosureEnabled.content.h1': 'Heading 1',
  'signatureSettings.disclosureEnabled.content.h2': 'Heading 2',
  'signatureSettings.disclosureEnabled.content.h3': 'Heading 3',
  'signatureSettings.disclosureEnabled.content.h4': 'Heading 4',
  'signatureSettings.disclosureEnabled.content.h5': 'Heading 5',
  'signatureSettings.disclosureEnabled.content.h6': 'Heading 6',
  'signatureSettings.disclosureEnabled.content.normal': 'Normal text',
  'signatureSettings.disclosureEnabled.content.blocktype': 'Block Type',
}

export default signatureSettings
