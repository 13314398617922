import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FREE_PRICE } from 'lib/constants/scheduleAppointment'
import { servicePriceSelector, expertPriceSelector } from 'state/concepts/booking/selectors'
import {
  currentWorkspaceManualPaymentEnabledSelector,
  currentWorkspacePaymentEnabledSelector,
} from 'state/concepts/session/selectors'
import PriceComponent from './component'

class Price extends React.Component {
  static propTypes = {
    expertPrice: PropTypes.string,
    servicePrice: PropTypes.string,
    serviceId: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isFreePrice: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    paymentEnabled: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    expertPrice: null,
    servicePrice: null,
    serviceId: undefined,
    price: null,
    isFreePrice: false,
  }

  get appointmentPrice() {
    const { expertPrice, servicePrice } = this.props

    return servicePrice || expertPrice
  }

  get serviceSelected() {
    const { serviceId } = this.props

    return serviceId !== undefined
  }

  componentDidMount = () => {
    const { setFieldValue, price, paymentEnabled } = this.props

    setFieldValue('isFreePrice', price === FREE_PRICE)

    if (!paymentEnabled) {
      setFieldValue('paymentRequired', false)
    }
  }

  componentDidUpdate = prevProps => {
    const { expertPrice, servicePrice } = this.props

    if (prevProps.expertPrice !== expertPrice || prevProps.servicePrice !== servicePrice) {
      this.handleAppointmentPriceChange()
    }
  }

  handleAppointmentPriceChange = () => {
    const { setFieldValue } = this.props
    const price = this.appointmentPrice || FREE_PRICE

    setFieldValue('price', price, false)
    setFieldValue('isFreePrice', price === FREE_PRICE, false)
  }

  handleIsFreePriceChange = isFreePrice => {
    const { setFieldValue } = this.props
    const price = isFreePrice ? FREE_PRICE : null

    setFieldValue('price', price)
  }

  render = () => (
    <PriceComponent
      {...this.props}
      serviceSelected={this.serviceSelected}
      onIsFreePriceChange={this.handleIsFreePriceChange}
    />
  )
}

const mapStateToProps = (state, { serviceId, userProfileId }) => ({
  expertPrice: expertPriceSelector(state, userProfileId, serviceId),
  servicePrice: servicePriceSelector(state, serviceId, userProfileId),
  manualPaymentEnabled: currentWorkspaceManualPaymentEnabledSelector(state),
  paymentEnabled: currentWorkspacePaymentEnabledSelector(state),
})

export { Price as PriceContainer }
export default connect(mapStateToProps)(Price)
