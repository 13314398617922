import PropTypes from 'prop-types'
import classNames from 'clsx'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import spanWithClass from 'utils/locales/spanWithClass'
import { formattedShortDate } from 'utils/dateTime'
import ArrowRepeat from 'views/shared/icons/ArrowRepeat'
import { sortBookingRecurrence } from 'utils/bookings'

const BookingRecurrence = ({ booking: { bookingRecurrence }, className, withIcon }) =>
  bookingRecurrence && (
    <div className={classNames(className, 'd-flex')}>
      {withIcon && <ArrowRepeat className="mr-12 in-blue-gray-200" size={20} />}
      <div className="d-flex flex-1 gap-10">
        <div className="d-flex align-items-center" data-cy="recurrence">
          <p className="mb-0">
            {prop('till', bookingRecurrence) ? (
              <FormattedMessage
                id="booking.recurrence.till"
                values={{
                  frequency: prop('frequency', bookingRecurrence),
                  days: sortBookingRecurrence(bookingRecurrence),
                  till: formattedShortDate(prop('till', bookingRecurrence)),
                  span: spanWithClass('in-gray-700'),
                }}
              />
            ) : (
              <FormattedMessage
                id="booking.recurrence.times"
                values={{
                  frequency: prop('frequency', bookingRecurrence),
                  days: sortBookingRecurrence(bookingRecurrence),
                  count: prop('times', bookingRecurrence),
                  span: spanWithClass('in-gray-700'),
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  )

BookingRecurrence.defaultProps = {
  className: '',
  withIcon: true,
}

BookingRecurrence.propTypes = {
  booking: PropTypes.shape().isRequired,
  className: PropTypes.string,
  withIcon: PropTypes.bool,
}

export default BookingRecurrence
