import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchEtherpadDocuments = (clientId, pageParams = {}) => ({
  type: types.FETCH_ETHERPAD_DOCUMENTS,
  clientId,
  pageParams,
})

export const addEtherpadDocumentIds = ids => ({
  type: types.ADD_ETHERPAD_DOCUMENT_IDS,
  ids,
})

export const removeEtherpadDocumentIds = ids => ({
  type: types.REMOVE_ETHERPAD_DOCUMENT_IDS,
  ids,
})

export const resetEtherpadDocuments = () => ({
  type: types.RESET_ETHERPAD_DOCUMENTS,
})

export const duplicateEtherpadDocument = ({ documentId, clientId, feedId }) => ({
  type: types.DUPLICATE_ETHERPAD_DOCUMENT,
  documentId,
  clientId,
  feedId,
})

export const downloadEtherpadDocument = ({ documentId, clientId }) => ({
  type: types.DOWNLOAD_ETHERPAD_DOCUMENT,
  documentId,
  clientId,
})

export const appendEtherpadId = id => ({
  type: types.APPEND_ETHERPAD_ID,
  id,
})

export const renameEtherpadDocument = makeFormSubmitAction(types.RENAME_ETHERPAD_DOCUMENT)

export const voidEtherpadDocument = makeFormSubmitAction(types.VOID_ETHERPAD_DOCUMENT)

export const signEtherpadDocument = makeFormSubmitAction(types.SIGN_ETHERPAD_DOCUMENT)

export const remindToSignEtherpadDocument = documentId => ({
  type: types.REMIND_TO_SIGN_ETHERPAD_DOCUMENT,
  documentId,
})

export const createEtherpadDocument = (clientId, documentTemplateId) => ({
  type: types.CREATE_ETHERPAD_DOCUMENT,
  clientId,
  documentTemplateId,
})

export const setEtherpadDocumentId = documentId => ({
  type: types.SET_ETHERPAD_DOCUMENT_ID,
  documentId,
})

export const resetEtherpadDocumentId = () => ({
  type: types.RESET_ETHERPAD_DOCUMENT_ID,
})

export const getEtherpadDocument = (documentId, workspace, ctx) => ({
  type: types.GET_ETHERPAD_DOCUMENT,
  documentId,
  workspace,
  ctx,
})

export const reviewEtherpadDocument = (documentId, workspace) => ({
  type: types.REVIEW_ETHERPAD_DOCUMENT,
  documentId,
  workspace,
})

export const shareEtherpadDocumentWithClient = makeFormSubmitAction(types.SHARE_ETHERPAD_DOCUMENT_WITH_CLIENT)
export const updateEtherpadDocumentClientAccess = makeFormSubmitAction(types.UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS)
export const removeEtherpadDocumentClientAccess = document => ({
  type: types.REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS,
  document,
})

export const deleteEtherpadDocument = ({ documentId, feedId, redirectTo }) => ({
  type: types.DELETE_ETHERPAD_DOCUMENT,
  documentId,
  feedId,
  redirectTo,
})

export const addSignatories = makeFormSubmitAction(types.ADD_SIGNATORIES)
export const updateSignatories = makeFormSubmitAction(types.UPDATE_SIGNATORIES)

export const declineEtherpadDocumentSigning = makeFormSubmitAction(types.DECLINE_TO_SIGN_ETHERPAD_DOCUMENT)
export const touchEtherpadDocument = documentId => ({
  type: types.TOUCH_ETHERPAD_DOCUMENT,
  documentId,
})
