import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusGone } from 'utils/getErrorStatus'
import { dataApiSuccess } from 'state/data/actions'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import updateDataHelper from 'utils/updateDataHelper'
import { VALIDATE_CONFIRMATION_TOKEN } from 'state/concepts/employee/types'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { validateConfirmationTokenEndpoint } from '../endpoints'

const validateConfirmationTokenOperation = createLogic({
  type: VALIDATE_CONFIRMATION_TOKEN,
  latest: true,

  async process({ getState, action: { token, workspace, pathname }, httpClient }, dispatch, done) {
    const { url, endpoint } = validateConfirmationTokenEndpoint
    const href = pathname.replace('/[workspace]/', '/')
    const state = getState()

    try {
      const response = updateDataHelper(state.data, 'userProfile', currentEmployeeIdSelector(state), {
        attributes: {
          confirmedAt: DateTime.now().toUTC(),
        },
      })

      await httpClient.post(url, { token })

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.emailHasBeenConfirmed' },
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      if (isErrorStatusGone(error)) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.confirmationLinkHasBeenExpired' },
            kind: 'error',
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    redirect({ href, workspace })
    done()
  },
})

export default validateConfirmationTokenOperation
