import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const GoogleInfo = ({ phone, pin, morePhoneNumbers, providerUrl }) => (
  <>
    <p className="mb-0 mt-4">
      <FormattedMessage id="consultation.details.videoCallLink" />
      <a href={providerUrl} target="_blank" className="in-blue-600 ml-4 text-underline">
        {providerUrl}
      </a>
    </p>
    {phone && (
      <p className="mb-0" data-cy="phone">
        <FormattedMessage id="consultation.details.google_meet.dial" values={{ phone }} />
      </p>
    )}
    {pin && (
      <p className="mb-0" data-cy="pin">
        <FormattedMessage id="consultation.details.google_meet.pin" values={{ pin }} />
      </p>
    )}
    {morePhoneNumbers && (
      <p className="mb-0" data-cy="more-phones">
        <FormattedMessage id="consultation.details.google_meet.moreNumbers" values={{ link: morePhoneNumbers }} />
      </p>
    )}
  </>
)

GoogleInfo.defaultProps = {
  phone: undefined,
  pin: undefined,
  morePhoneNumbers: undefined,
}

GoogleInfo.propTypes = {
  phone: PropTypes.string,
  pin: PropTypes.string,
  morePhoneNumbers: PropTypes.string,
  providerUrl: PropTypes.string.isRequired,
}

export default GoogleInfo
