import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadingSelector } from 'state/data/selectors'
import { hideModal as hideModalAction } from 'state/modal/actions'
import { removeAttachment as removeAttachmentAction } from 'state/concepts/chat/actions'
import { removeChatsAttachmentsEndpoint } from 'state/concepts/chat/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

class RemoveFileModal extends React.Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    removeAttachment: PropTypes.func.isRequired,
    fileId: PropTypes.string.isRequired,
    attachmentType: PropTypes.string.isRequired,
  }

  handleSubmit = () => {
    const { hideModal, removeAttachment, fileId } = this.props

    removeAttachment(fileId)
    hideModal()
  }

  render() {
    const { attachmentType } = this.props

    return (
      <ConfirmModal
        {...this.props}
        onSubmit={this.handleSubmit}
        title={{ id: `chat.${attachmentType}.remove.modal.title` }}
        bodyText={[{ id: `chat.${attachmentType}.remove.modal.body` }]}
        submitText={{ id: 'shared.delete' }}
        kind="danger"
      />
    )
  }
}

const mapStateToProps = (state, { fileId }) => ({
  isLoading: loadingSelector(state, removeChatsAttachmentsEndpoint(fileId).endpoint),
})

const mapDispatchToProps = {
  hideModal: hideModalAction,
  removeAttachment: removeAttachmentAction,
}

export { RemoveFileModal as RemoveFileModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(RemoveFileModal)
