import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { ATTACHMENT_TYPES } from 'lib/constants/chat'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_ATTACHMENTS } from 'state/concepts/chat/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addAttachments } from 'state/concepts/chat/actions'
import { fetchChatAttachmentsEndpoint } from '../endpoints'

const fetchAttachmentsOperation = createLogic({
  type: FETCH_ATTACHMENTS,
  latest: true,

  async process(
    {
      action: {
        chatId,
        pageParams: { nextCursor = null },
        filter = {
          attachment_type: ATTACHMENT_TYPES.file,
        },
      },
      httpClient,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = fetchChatAttachmentsEndpoint(chatId)

    const params = {
      page: { after: nextCursor },
      include: 'chat-user',
      filter,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(addAttachments(pluck('id', data.data)))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchAttachmentsOperation
