import { createLogic } from 'redux-logic'

import { FILTER_BOOKINGS } from 'state/concepts/clientAccountBookings/types'
import {
  setFilterParams,
  resetClientAccountBookingsIds,
  fetchClientAccountBookings,
} from 'state/concepts/clientAccountBookings/actions'

const filterClientAccountBookingsOperation = createLogic({
  type: FILTER_BOOKINGS,
  latest: true,

  async process({ action: { filters } }, dispatch, done) {
    dispatch(resetClientAccountBookingsIds())
    dispatch(setFilterParams(filters))
    dispatch(fetchClientAccountBookings())
    done()
  },
})

export default filterClientAccountBookingsOperation
