const waitForElement = selector =>
  new Promise(resolve => {
    let timer = null

    const element = document.querySelector(selector)
    if (element) {
      resolve(element)
      return
    }

    const observer = new MutationObserver((mutationsList, callbackObserver) => {
      const targetElement = document.querySelector(selector)
      if (targetElement) {
        clearTimeout(timer)
        callbackObserver.disconnect()
        resolve(targetElement)
      }
    })

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    })

    timer = setTimeout(() => {
      resolve(null)
      observer.disconnect()
    }, 2000)
  })

export default waitForElement
