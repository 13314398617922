import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_EMPLOYEE_ACCOUNT_GENERAL } from 'state/concepts/employee/types'
import { showNotification } from 'state/notifications/actions'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { usersAccountProfileRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'

const updateEmployeeAccountGeneralOperation = createLogic({
  type: UPDATE_EMPLOYEE_ACCOUNT_GENERAL,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    try {
      const state = getState()
      const { password, newEmail, phoneNumber, isEmailChanged } = values

      const params = {
        new_email: isEmailChanged || password ? newEmail : null,
        password,
        phone_number: phoneNumber,
      }

      await httpClient.put(usersAccountProfileRoute, params)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.accountSettingsWereUpdated' },
        }),
      )

      if (isEmailChanged) {
        const response = updateDataHelper(state.data, 'userProfile', currentEmployeeIdSelector(state), {
          attributes: {
            newEmail,
          },
        })

        dispatch(dataApiSuccess({ response }))
      }

      form.resetForm({ values: { ...values, password: '' } })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateEmployeeAccountGeneralOperation
