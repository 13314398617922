import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage } from 'react-intl'

import Avatar from 'views/shared/Avatar'
import ProfileIdentity from 'views/shared/ProfileIdentity'

const OptionContent = ({ employee, hideEmail }) => (
  <div className="main-dropdown__user" data-cy="user-profile-select">
    <Avatar
      sizeClassName={classNames('main-userpic--sm', { 'op-03': !employee.active })}
      imageSize="small"
      profile={employee}
    />
    <div className="main-dropdown__user-info pr-0">
      <div className="d-flex mb-0">
        {!employee.invited && (
          <span className="max-w-220 text-ellipsis">
            <ProfileIdentity user={employee} />
          </span>
        )}
        {!employee.active && !employee.invited && (
          <span className="ml-12 op-03">
            <FormattedMessage id="status.inactive" />
          </span>
        )}
      </div>
      {!hideEmail && <p className="text-caption in-gray-700 mb-0">{employee.email}</p>}
    </div>
  </div>
)

OptionContent.propTypes = {
  employee: PropTypes.shape().isRequired,
  hideEmail: PropTypes.bool.isRequired,
}

export default OptionContent
