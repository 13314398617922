const emailRemindersFeature = {
  'emailRemindersFeature.title': 'Email reminders & notifications',
  'emailRemindersFeature.subtitle': '<span>in</span> Reminders & notifications',
  'emailRemindersFeature.description':
    'Stay informed about all the important things that are happening in your workspace.',
  'emailRemindersFeature.manageFeatures': 'Set my notifications',
  'emailRemindersFeature.overview': 'Email reminders & notifications overview',
  'emailRemindersFeature.overview.text1':
    'ExpertBox email notifications let you stay in the loop about all the important things happening in your workspace.',
  'emailRemindersFeature.overview.text2':
    'Get notified about updates related to your _(appointments), _(services), team, or _(client) activity:',
  'emailRemindersFeature.overview.text3': 'Never miss _a(appointment) with automated reminders.',
  'emailRemindersFeature.overview.text4':
    'Send _(appointment) requests to your _(clients) and get notified about their replies.',
  'emailRemindersFeature.overview.text5':
    'Make sure your team members are aware of the _(appointments) and _(services) they’ve been assigned to.',
  'emailRemindersFeature.overview.text6': 'Be the first to learn about _(client) feedback, submitted documents, etc.',
  'emailRemindersFeature.overview.text7': 'Personalize your notifications:',
  'emailRemindersFeature.overview.text8':
    'Choose which notifications you receive to ensure you stay up to date on what matters most.',
  'emailRemindersFeature.overview.text9': 'Unsubscribe from notifications that aren’t relevant to your workflow.',
  'emailRemindersFeature.toLearnMores':
    'To learn more, read our help center article on <link>managing email notifications</link>.',
}

export default emailRemindersFeature
