import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const microsoftTeamsConnectLink = (state = null, action) => {
  switch (action.type) {
    case types.SET_MICROSOFT_TEAMS_CONNECT_LINK:
      return action.link
    default:
      return state
  }
}

const zoomConnectLink = (state = null, action) => {
  switch (action.type) {
    case types.SET_ZOOM_CONNECT_LINK:
      return action.link
    default:
      return state
  }
}

const videoCallsIntegrationsReducer = combineReducers({
  microsoftTeamsConnectLink,
  zoomConnectLink,
})

export default videoCallsIntegrationsReducer
