import { Breadcrumb } from 'antd'
import classNames from 'clsx'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import ArrowRight from 'views/shared/icons/ArrowRight'
import Link from 'views/shared/Link'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import PageHeader from './PageHeader'

const BreadcrumbsComponent = ({
  crumbs,
  lastCrumbId,
  className,
  dataScroll,
  lastWorkerCrumbs,
  onBackClick,
  breadcrumbClassName,
  breadcrumbLinkClassName,
  breadcrumbTextClassName,
  ...headerProps
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames('main-page__header-wrap', className)} data-scroll={dataScroll}>
      <Breadcrumb
        className={classNames('d-sm-block d-none breadcrumb-wrapper', breadcrumbClassName)}
        separator={<ArrowRight className="in-gray-500" size={10} />}
        items={crumbs.map(({ label, href, id, menu, isText = false, isDisabled = false, ...crumbProps }) => {
          const isHaveDropdown = !!menu?.items
          return {
            key: id,
            'data-cy': 'breadcrumbs-item',
            className: isDisabled ? 'breadcrumbs-item--disabled' : undefined,
            title:
              id !== lastCrumbId && !isHaveDropdown && !isText ? (
                <Link isFullLink href={href}>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <a
                    className={classNames('breadcrumbs-item__element', breadcrumbLinkClassName, {
                      'c-initial pe-none': isDisabled,
                    })}
                    onClick={onBackClick(id)}
                  >
                    <TruncatedTextByWidthTooltip
                      overlayClassName="main-tooltip main-tooltip--no-pointer breadcrumbs-item__element-tooltip"
                      placement="top"
                      text={label?.id ? formatMessage(label, label?.values) : label}
                      on={['hover']}
                      align={{ offset: [0, 0] }}
                    />
                  </a>
                </Link>
              ) : (
                <span
                  className={`breadcrumbs-item__element max-w-initial min-w-0 ${breadcrumbTextClassName}`}
                  {...crumbProps}
                >
                  <TruncatedTextByWidthTooltip
                    overlayClassName="main-tooltip main-tooltip--no-pointer breadcrumbs-item__element-tooltip breadcrumbs-item__element-tooltip--wide"
                    placement="top"
                    text={label?.id ? formatMessage(label, label?.values) : label}
                    on={['hover']}
                    align={{ offset: [0, 0] }}
                  />
                </span>
              ),
            dropdownProps: isHaveDropdown
              ? {
                  overlayClassName: 'w-184',
                  className: 'main-dropdown-btn breadcrumb-wrapper__dropdown',
                  align: { offset: [0, 2] },
                  placement: 'bottomLeft',
                  trigger: ['click'],
                }
              : undefined,
            menu: isHaveDropdown
              ? {
                  items: menu.items.map(({ label: menuLabel, href: menuHref, id: menuId, ...menuItemProps }) => ({
                    key: menuId,
                    'data-cy': 'breadcrumbs-menu-item',
                    title:
                      menuId !== lastCrumbId ? (
                        <Link isFullLink href={menuHref}>
                          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                          <a className={`${breadcrumbLinkClassName} main-dropdown__item`} onClick={onBackClick(menuId)}>
                            <div className="truncate-text">
                              <FormattedOrRawMessage message={menuLabel} />
                            </div>
                          </a>
                        </Link>
                      ) : (
                        <span className={`${breadcrumbTextClassName} main-dropdown__item`} {...menuItemProps}>
                          <div className="truncate-text">
                            <FormattedOrRawMessage message={menuLabel} />
                          </div>
                        </span>
                      ),
                  })),
                  className: 'main-dropdown main-dropdown--many-level breadcrumb-wrapper__main-dropdown',
                }
              : undefined,
          }
        })}
      />
      <PageHeader onBackClick={onBackClick(lastWorkerCrumbs?.id)} {...headerProps} />
    </div>
  )
}

BreadcrumbsComponent.defaultProps = {
  className: '',
  breadcrumbClassName: '',
  dataScroll: '',
  breadcrumbLinkClassName: '',
  breadcrumbTextClassName: '',
  onBackClick: noop,
  lastWorkerCrumbs: undefined,
  lastCrumbId: undefined,
}

BreadcrumbsComponent.propTypes = {
  className: PropTypes.string,
  breadcrumbClassName: PropTypes.string,
  breadcrumbLinkClassName: PropTypes.string,
  breadcrumbTextClassName: PropTypes.string,
  crumbs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lastCrumbId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataScroll: PropTypes.string,
  lastWorkerCrumbs: PropTypes.shape(),
  onBackClick: PropTypes.func,
}

export default BreadcrumbsComponent
