import endpoint from 'utils/endpoint'
import { workspaceSignatureSettingRoute, signatureDisclosureRoute } from 'lib/apiRoutes'
import * as types from './types'

export const fetchSignatureSettingEndpoint = endpoint(
  types.FETCH_SIGNATURE_SETTING,
  'GET',
  workspaceSignatureSettingRoute,
)
export const updateSignatureSettingEndpoint = endpoint(
  types.UPDATE_SIGNATURE_SETTING,
  'PUT',
  workspaceSignatureSettingRoute,
)
export const getSignatureDisclosureEndpoint = endpoint(types.GET_SIGNATURE_DISCLOSURE, 'GET', signatureDisclosureRoute)
