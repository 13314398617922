/* eslint-disable no-template-curly-in-string */
const bookingCheckout = {
  'bookingCheckout.backToAppointmentDetails': '_(Appointment) details',
  'bookingCheckout.title': 'Complete booking',
  'bookingCheckout.sidebar.reschedulePolicy': 'Reschedule policy',
  'bookingCheckout.sidebar.cancellationPolicy': 'Cancellation policy',
  'bookingCheckout.form.title': 'Payment details',
  'bookingCheckout.form.cardNumber': 'Card number',
  'bookingCheckout.form.cardNumber.placeholder': '0000 0000 0000 0000',
  'bookingCheckout.form.expireDate': 'Expire date',
  'bookingCheckout.form.expireDate.placeholder': 'MM/YY',
  'bookingCheckout.form.cvv': 'CVV / CVC',
  'bookingCheckout.form.cvv.placeholder': '***',
  'bookingCheckout.form.billingAddress': 'Billing address',
  'bookingCheckout.form.city': 'City',
  'bookingCheckout.form.zipCode': 'Postal / ZIP code',
  'bookingCheckout.form.state': 'State',
  'bookingCheckout.form.country': 'Country',
  'bookingCheckout.form.autoCharge': 'Allow automatic payments from my card',
  'bookingCheckout.form.autoCharge.description':
    'Your card will be auto-charged with {amount} {frequency}, as per the terms mentioned in the Payment.',
  'bookingCheckout.submitPanel.price': 'Price:',
  'bookingCheckout.submitPanel.submit': 'Pay {price}',
  'bookingCheckout.errorPage.title': 'The link has expired',
  'bookingCheckout.errorPage.body': 'It seems we have lost this page but we don’t want to lose you.',
}

export default bookingCheckout
