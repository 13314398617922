import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const addPromocode = makeFormSubmitAction(types.ADD_PROMOCODE)

export const applyPromocode = makeFormSubmitAction(types.APPLY_PROMOCODE)

export const setPromocodes = promocodeId => ({
  type: types.SET_PROMOCODES,
  promocodeId,
})

export const setAppliedPromocodes = appliedPromocodeId => ({
  type: types.SET_APPLIED_PROMOCODES,
  appliedPromocodeId,
})

export const setTrialCode = code => ({
  type: types.SET_TRIAL_CODE,
  code,
})

export const setDiscountCode = code => ({
  type: types.SET_DISCOUNT_CODE,
  code,
})

export const validatePromocodes = form => ({
  type: types.VALIDATE_PROMOCODES,
  form,
})

export const resetPromocodes = () => ({
  type: types.RESET_PROMOCODES,
})
