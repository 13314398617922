import { createLogic } from 'redux-logic'
import { path } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { RESET_PASSWORD_REQUEST_LOGIN } from 'state/concepts/employee/types'
import { usersResetPasswordRoute } from 'lib/apiRoutes'

const resetPasswordRequestLogin = createLogic({
  type: RESET_PASSWORD_REQUEST_LOGIN,
  latest: true,

  async process({ action: { values, form }, getState }, dispatch, done) {
    try {
      const workspaceId = path(['subdomain', 'workspaceId'], getState())
      const params = {
        ...values,
        workspace_id: workspaceId,
      }

      await workspacesHttpClient.post(usersResetPasswordRoute, params)

      form.setStatus({})
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default resetPasswordRequestLogin
