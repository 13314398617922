const manageVideoCalls = {
  'manageVideoCalls.defaultProvider': 'Default video call provider',
  'manageVideoCalls.chooseProvider': 'Choose the way your _(clients) will connect to online _(appointments).',
  'manageVideoCalls.form.expertbox.label': 'ExpertBox video',
  'manageVideoCalls.form.expertbox.description':
    'Select to have one-to-one video calls via ExpertBox. To have group video calls, enable "ExpertBox group video calls" feature.',
  'manageVideoCalls.form.googleMeet.label': 'Google Meet',
  'manageVideoCalls.form.googleMeet.description': `Automatically create and join meetings at Google Meet from your ExpertBox online appointments. Every team member will need to connect their Google account.`,
  'manageVideoCalls.form.googleMeet.disabledText': 'To select Google Meet as a default video call provider,',
  'manageVideoCalls.form.googleMeet.disabledLinkText': 'enable feature Calendar integrations',
  'manageVideoCalls.form.zoom.label': 'Zoom',
  'manageVideoCalls.form.zoom.description': `Automatically create and join Zoom from your ExpertBox online appointments. Every team member will need to connect their Zoom account.`,
  'manageVideoCalls.form.microsoftTeams.label': 'Microsoft Teams (requires business/education account)',
  'manageVideoCalls.form.microsoftTeams.description':
    'Automatically create and join meetings at Microsoft Teams from your ExpertBox online _(appointments). Please note that to join a meeting, _(experts) need to be members of your work/school Team.',
  'manageVideoCalls.alert.zoom':
    'To start having online _(appointments) at Zoom, a team member needs to connect their Zoom account. Until then, they are going to connect via ExpertBox video call.',
  'manageVideoCalls.alert.google_meet':
    'To start having online _(appointments) at Google Meet, a team member needs to connect their Google Meet account. Until then, they are going to connect via ExpertBox video call.',
  'manageVideoCalls.alert.microsoft_teams':
    'To start having online _(appointments) at Microsoft Teams, connect your work/school Microsoft Teams account for everybody in this workspace. Until then, they are going to connect via ExpertBox video call.',
  'manageVideoCalls.setupIntegration': 'Set up video call integrations',
}

export default manageVideoCalls
