const namespace = 'recurringPayment'

export const GET_RECURRING_PAYMENT = `${namespace}/GET_RECURRING_PAYMENT`
export const SET_RECURRING_PAYMENT_ID = `${namespace}/SET_RECURRING_PAYMENT_ID`
export const FETCH_TRANSACTIONS = `${namespace}/FETCH_TRANSACTIONS`
export const SET_TRANSACTIONS = `${namespace}/SET_TRANSACTIONS`
export const SET_TRANSACTIONS_PAGE = `${namespace}/SET_TRANSACTIONS_PAGE`
export const SET_TRANSACTIONS_BLANK_STATE = `${namespace}/SET_TRANSACTIONS_BLANK_STATE`
export const REQUEST_RECURRING_PAYMENT = `${namespace}/REQUEST_RECURRING_PAYMENT`
export const UPDATE_RECURRING_PAYMENT = `${namespace}/UPDATE_RECURRING_PAYMENT`
export const SAVE_DRAFT_RECURRING_PAYMENT = `${namespace}/SAVE_DRAFT_RECURRING_PAYMENT`
export const SEND_RECURRING_PAYMENT = `${namespace}/SEND_RECURRING_PAYMENT`
export const DELETE_DRAFT_RECURRING_PAYMENT = `${namespace}/DELETE_DRAFT_RECURRING_PAYMENT`
export const GET_PAYMENT_METHOD = `${namespace}/GET_PAYMENT_METHOD`
export const SET_PAYMENT_METHOD_ID = `${namespace}/SET_PAYMENT_METHOD_ID`
export const UPDATE_PAYMENT_METHOD = `${namespace}/UPDATE_PAYMENT_METHOD`
export const CANCEL_RECURRING_PAYMENT = `${namespace}/CANCEL_RECURRING_PAYMENT`
export const CANCEL_CLIENT_RECURRING_PAYMENT = `${namespace}/CANCEL_CLIENT_RECURRING_PAYMENT`
