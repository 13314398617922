import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { mergeDeepRight } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingPaymentRoute } from 'lib/routes'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { ISSUE_REFUND } from 'state/concepts/payment/types'
import { issueRefundEndpoint } from 'state/concepts/payment/endpoints'
import { hideModal } from 'state/modal/actions'
import link from 'utils/locales/link'
import updateDataHelper from 'utils/updateDataHelper'
import { fetchTransactions } from 'state/concepts/payments/actions'

const issueRefundOperation = createLogic({
  type: ISSUE_REFUND,
  latest: true,

  async process({ httpClient, action: { values, form, fromPayments }, getState }, dispatch, done) {
    const { cancellationReason, transactionId } = values
    const { url, endpoint } = issueRefundEndpoint(transactionId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url, { cancellation_reason: cancellationReason })
      const apiResponse = normalize(data, { endpoint })
      const updatedResponse = updateDataHelper(getState().data, 'transaction', transactionId, {
        relationships: {
          refund: {
            data: {
              id: data.data.id,
              type: 'transaction',
            },
          },
        },
      })

      dispatch(dataApiSuccess({ endpoint, response: mergeDeepRight(updatedResponse, apiResponse) }))
      dispatch(hideModal())

      if (fromPayments) {
        dispatch(fetchTransactions())
      }
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.paymentIssueRefund',
            values: {
              link: link(companyBillingPaymentRoute(data.data.id), {
                id: 'notifications.paymentIssueRefund.viewRefund',
              }),
            },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default issueRefundOperation
