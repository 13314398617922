import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { FORM_TYPES } from 'lib/constants/forms'
import {
  ADD_DOCUMENT_IDS,
  ADD_FILE_IDS,
  ADD_FORM_IDS,
  ADD_MY_FILE_IDS,
  REMOVE_FORM_ID,
  REMOVE_MY_FILE_ID,
  RESET_DOCUMENTS,
  SET_DOCUMENT_IDS,
  SET_DOCUMENTS_BLANK_STATE,
  SET_DOCUMENTS_CURRENT_PAGE,
  SET_DOCUMENTS_FILTER_PARAMS,
  SET_FILE_IDS,
  SET_FILES_BLANK_STATE,
  SET_FILES_CURRENT_PAGE,
  SET_FILES_FILTER_PARAMS,
  SET_FORM_CURRENT_PAGE,
  SET_FORM_IDS,
  SET_FORM_IS_BLANK_STATE,
  SET_MY_FILE_IDS,
  SET_MY_FILES_BLANK_STATE,
  SET_MY_FILES_CURRENT_PAGE,
  SET_MY_FILES_FILTER_PARAMS,
} from './types'

const formIdsInitialState = {
  intake: [],
  agreement: [],
}
const formIds = (state = formIdsInitialState, action) => {
  switch (action.type) {
    case SET_FORM_IDS:
      return {
        ...state,
        [action.formType]: action.ids,
      }
    case ADD_FORM_IDS:
      return {
        ...state,
        [action.formType]: [...state[action.formType], ...action.ids],
      }
    case REMOVE_FORM_ID:
      return {
        ...state,
        [action.formType]: state[action.formType].filter(id => id !== action.id),
      }
    case RESET_DOCUMENTS:
      return formIdsInitialState
    default:
      return state
  }
}

const fileIds = (state = [], action) => {
  switch (action.type) {
    case SET_FILE_IDS:
      return action.ids
    case ADD_FILE_IDS:
      return uniq([...state, ...action.ids])
    case RESET_DOCUMENTS:
      return []
    default:
      return state
  }
}

const myFileIds = (state = [], action) => {
  switch (action.type) {
    case SET_MY_FILE_IDS:
      return action.ids
    case ADD_MY_FILE_IDS:
      return uniq([...state, ...action.ids])
    case REMOVE_MY_FILE_ID:
      return without([action.id], state)
    default:
      return state
  }
}

const documentIds = (state = [], action) => {
  switch (action.type) {
    case SET_DOCUMENT_IDS:
      return uniq(action.ids)
    case ADD_DOCUMENT_IDS:
      return uniq([...state, ...action.ids])
    case RESET_DOCUMENTS:
      return []
    default:
      return state
  }
}

const isBlankStateInitialState = {
  intakeForms: true,
  agreementForms: true,
}
const isBlankState = (state = isBlankStateInitialState, action) => {
  switch (action.type) {
    case SET_FORM_IS_BLANK_STATE:
      return { ...state, [`${action.formType}Forms`]: action.isBlankState }
    case RESET_DOCUMENTS:
      return isBlankStateInitialState
    default:
      return state
  }
}

const formsPaginationInitialState = {
  [FORM_TYPES.intake]: { number: 1, size: 20 },
  [FORM_TYPES.agreement]: { number: 1, size: 20 },
}
const formsPagination = (state = formsPaginationInitialState, action) => {
  switch (action.type) {
    case SET_FORM_CURRENT_PAGE:
      return {
        ...state,
        [action.formType]: { ...state[action.formType], number: action.pageNumber },
      }
    default:
      return state
  }
}

const filesPagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_FILES_CURRENT_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const documentsPagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_DOCUMENTS_CURRENT_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const initialFilterState = { name: '' }

const documentsFilters = (state = initialFilterState, action) => {
  switch (action.type) {
    case SET_DOCUMENTS_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_DOCUMENTS:
      return initialFilterState
    default:
      return state
  }
}

const isDocumentsBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_DOCUMENTS_BLANK_STATE:
      return action.isBlankState
    case RESET_DOCUMENTS:
      return true
    default:
      return state
  }
}

const initialFilesFilterState = { name: '' }

const filesFilters = (state = initialFilesFilterState, action) => {
  switch (action.type) {
    case SET_FILES_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const initialMyFilesFilterState = { name: '' }

const myFilesFilters = (state = initialMyFilesFilterState, action) => {
  switch (action.type) {
    case SET_MY_FILES_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const myFilesPagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_MY_FILES_CURRENT_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const isFilesBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_FILES_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const isMyFilesBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_MY_FILES_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const formsReducer = combineReducers({
  formIds,
  fileIds,
  documentIds,
  isBlankState,
  formsPagination,
  filesPagination,
  documentsPagination,
  documentsFilters,
  isDocumentsBlankState,
  filesFilters,
  myFilesFilters,
  myFileIds,
  myFilesPagination,
  isFilesBlankState,
  isMyFilesBlankState,
})

export default formsReducer
