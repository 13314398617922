import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Field } from 'formik'
import { keys, isEmpty } from 'ramda'
import Calendar from 'react-calendar'
import Spinner from 'views/shared/icons/Spinner'
import ArrowLeft from 'views/shared/icons/ArrowLeft'
import ArrowRight from 'views/shared/icons/ArrowRight'

import isPresent from 'utils/isPresent'
import TimezoneField from '../TimezoneField'
import ClientBookingTimeSlotsEmpty from '../TimeSlotsEmpty'
import DaySlots from './DaySlots'

const ClientBookingRescheduleChangeDateComponent = ({
  startTime,
  date,
  timeSlots,
  setSlotsDate,
  setTileDisable,
  onClickMonth,
  onPrevNextClick,
  onChangeSlot,
  isLoading,
  intl: { locale },
}) => (
  <div className="resch-modal__content" data-cy="reschedule-datepicker">
    <div className="resch-modal__calendar-wrap">
      <Calendar
        value={date.toJSDate()}
        minDate={DateTime.now().toJSDate()}
        minDetail="year"
        locale={locale}
        calendarType="gregory"
        onChange={setSlotsDate}
        tileDisabled={setTileDisable}
        onClickMonth={onClickMonth}
        showNeighboringMonth={false}
        className="main-datepicker main-datepicker--booking"
        nextLabel={<ArrowRight size={12} />}
        prevLabel={<ArrowLeft size={12} dataCy="arrow-left-icon" />}
        prev2Label={null}
        next2Label={null}
        onActiveStartDateChange={onPrevNextClick}
      />
    </div>
    <div className="divider full-width d-block d-sm-none" />
    {isLoading !== false && (
      <div className="d-flex flex-grow-1 align-self-center">
        <Spinner size={48} />
      </div>
    )}
    {isLoading === false && (
      <div className="flex-grow-1 full-width">
        {isPresent(timeSlots) ? (
          <>
            <Field id="timezone" name="timezone" component={TimezoneField} />
            {keys(timeSlots).map(part =>
              !isEmpty(timeSlots[part]) ? (
                <DaySlots
                  key={part}
                  startTime={startTime}
                  slots={timeSlots[part]}
                  title={{ id: `rescheduleBookingModal.${part}` }}
                  onChangeSlot={onChangeSlot}
                />
              ) : null,
            )}
          </>
        ) : (
          <ClientBookingTimeSlotsEmpty />
        )}
      </div>
    )}
  </div>
)

ClientBookingRescheduleChangeDateComponent.defaultProps = {
  timeSlots: null,
  isLoading: undefined,
}

ClientBookingRescheduleChangeDateComponent.propTypes = {
  timeSlots: PropTypes.shape(),
  startTime: PropTypes.string.isRequired,
  date: PropTypes.shape().isRequired,
  setSlotsDate: PropTypes.func.isRequired,
  setTileDisable: PropTypes.func.isRequired,
  onClickMonth: PropTypes.func.isRequired,
  onPrevNextClick: PropTypes.func.isRequired,
  onChangeSlot: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ClientBookingRescheduleChangeDateComponent
