import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import TextAreaField from 'views/shared/TextAreaField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { voidEtherpadDocument } from 'state/concepts/etherpadDocuments/actions'
import { VOID_REASON_MAX_LENGTH } from 'lib/constants/etherpadDocuments'
import { clientIdSelector } from 'state/concepts/client/selectors'

const VoidDocumentModal = ({ onClose, document, feedId }) => {
  const handleSubmit = useFormSubmit(voidEtherpadDocument)
  const clientId = useSelector(clientIdSelector)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="etherpadDocuments.voidModal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            documentId: document.id,
            voidReason: '',
            feedId,
            clientId,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <>
            <div className="main-modal__body">
              <p>
                <FormattedMessage id="etherpadDocuments.voidModal.description" />
              </p>
              <Field
                id="voidReason"
                name="voidReason"
                wrapperClassName="mb-0"
                label={{ id: 'etherpadDocuments.voidModal.voidReason.label' }}
                className="main-input--textarea-top-count"
                showCount
                maxLength={VOID_REASON_MAX_LENGTH}
                autoSize={{ minRows: 3, maxRows: 6 }}
                component={TextAreaField}
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton text={{ id: 'etherpadDocuments.voidModal.submitText' }} kind="danger" />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

VoidDocumentModal.defaultProps = {
  feedId: null,
}

VoidDocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  feedId: PropTypes.string,
}

export default VoidDocumentModal
