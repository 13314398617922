import cookies from 'component-cookie'

const removeClientCookies = (cookieOptions = { path: '/client' }) => {
  cookies('tokens', null, cookieOptions)
  cookies('currentClientId', null, cookieOptions)
  cookies('currentWorkspaceId', null, cookieOptions)
  cookies('currentWorkspaceCode', null, cookieOptions)
  cookies('path', null, cookieOptions)
}

export default removeClientCookies
