import { combineReducers } from '@reduxjs/toolkit'

import { SELECT_HREF } from './types'

const selectedHref = (state = '', action) => {
  switch (action.type) {
    case SELECT_HREF:
      return action.href || ''
    default:
      return state
  }
}

const breadcrumbsReducer = combineReducers({
  selectedHref,
})

export default breadcrumbsReducer
