import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'

import Cross from 'views/shared/icons/Cross'

import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import documentTemplateSchema from 'lib/yupLocalised/schemas/documentTemplate'
import { MAX_NAME_INPUT_LENGTH } from 'lib/constants/documentTemplates'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import {
  fetchDocumentTemplates as fetchDocumentTemplatesAction,
  renameDocumentTemplate,
} from 'state/concepts/documentTemplates/actions'
import useDispatchAction from 'hooks/shared/useDispatchAction'

const RenameDocumentTemplateModal = ({ onClose, documentTemplate }) => {
  const { name, id: documentTemplateId } = documentTemplate
  const fetchDocumentTemplates = useDispatchAction(fetchDocumentTemplatesAction)
  const handleSubmit = useFormSubmit(renameDocumentTemplate, { onSuccess: fetchDocumentTemplates })

  return (
    <Modal className="main-modal main-modal--md">
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="documentTemplates.renameModal.title" />
          </h2>
          <button type="button" className="main-modal__close" onClick={onClose}>
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            name,
            documentTemplateId,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={documentTemplateSchema}
        >
          <>
            <div className="main-modal__body">
              <Field
                id="name"
                className="mb-0 main-input--input-top-count"
                name="name"
                component={InputWithCounterField}
                icon="alert"
                label={{ id: 'documentTemplates.renameModal.name.label' }}
                showCount
                maxChars={MAX_NAME_INPUT_LENGTH}
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.saveChanges' }} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

RenameDocumentTemplateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  documentTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

export default RenameDocumentTemplateModal
