import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setReceiptTemplate } from 'state/concepts/payments/actions'
import { FETCH_RECEIPT_TEMPLATE } from 'state/concepts/payments/types'
import { fetchReceiptTemplateEndpoint } from 'state/concepts/payments/endpoints'

const fetchReceiptTemplateOperation = createLogic({
  type: FETCH_RECEIPT_TEMPLATE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchReceiptTemplateEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(setReceiptTemplate(data.data.id))
      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchReceiptTemplateOperation
