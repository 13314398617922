import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { compose, pick, whereEq } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import withLoseChangesWarning from 'lib/withLoseChangesWarning'
import yup from 'lib/yupLocalised'
import validationSchema, { clientProfileSchema, recurringSchema } from 'lib/yupLocalised/schemas/scheduleBooking'
import { scheduleBooking } from 'state/concepts/booking/actions'
import { showSidebar as showSidebarAction } from 'state/sidebar/actions'
import { sidebarPropsSelector } from 'state/sidebar/selectors'
import initialCreateFormValues from 'utils/bookings/initialCreateFormValues'
import { handleSubmit } from 'utils/form/handleSubmit'
import ScheduleAppointmentComponent from 'views/shared/ScheduleAppointment'

class ScheduleAppointment extends React.Component {
  static propTypes = {
    showSidebar: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    values: PropTypes.shape().isRequired,
    setErrors: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    bookingToSchedule: PropTypes.shape().isRequired,
    date: PropTypes.shape().isRequired,
    time: PropTypes.shape().isRequired,
    duration: PropTypes.string.isRequired,
    userProfileId: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    const { date, time, duration, setValues, showSidebar, bookingToSchedule, values, userProfileId } = this.props

    if (!whereEq({ date, time, duration, userProfileId })(prevProps)) {
      setValues({ ...values, date, time, duration, userProfileId })
    }

    const currentValues = pick(['date', 'time', 'duration'], values)
    if (!whereEq(currentValues)(prevProps.values)) {
      showSidebar({
        sidebarType: SIDEBAR_COMPONENTS_TYPES.createBookingAppointment,
        sidebarProps: {
          ...initialCreateFormValues(),
          ...bookingToSchedule,
          ...currentValues,
        },
      })
    }
  }

  handleSubmitAndAddNew = async () => {
    const { handleSubmit: formikHandleSubmit, setFieldValue } = this.props

    await setFieldValue('scheduleNewAppointment', true, false)
    formikHandleSubmit()
  }

  render() {
    return <ScheduleAppointmentComponent {...this.props} handleSubmitAndAddNew={this.handleSubmitAndAddNew} />
  }
}

const mapStateToProps = state => ({
  bookingToSchedule: sidebarPropsSelector(state),
})

const mapDispatchToProps = {
  showSidebar: showSidebarAction,
  onSubmit: scheduleBooking,
}

export { ScheduleAppointment as ScheduleAppointmentContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ bookingToSchedule }) => bookingToSchedule,
    handleSubmit,
    validationSchema: validationSchema.concat(recurringSchema).concat(clientProfileSchema).shape({
      userProfileId: yup.string().required(),
    }),
  }),
  withLoseChangesWarning(),
)(ScheduleAppointment)
