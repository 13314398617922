import { createLogic } from 'redux-logic'

import { companyClientRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { createContactValuesToParams } from 'utils/clientContacts'
import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import { createClientContactEndpoint } from '../endpoints'
import { CREATE_CLIENT_CONTACT } from '../types'

const createClientContactOperation = createLogic({
  type: CREATE_CLIENT_CONTACT,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const { url, endpoint } = createClientContactEndpoint
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)
    const { clientId } = values

    const params = createContactValuesToParams(values)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.contactWasCreated',
          },
        }),
      )
      redirect({ href: companyClientRoute(clientId), workspace, query: { activeTab: CLIENT_PROFILE_TABS.contacts } })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default createClientContactOperation
