import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'
import { path } from 'ramda'

import isPresent from 'utils/isPresent'
import requestErrorHandler from 'lib/requestErrorHandler'
import { workspacesHttpClient } from 'lib/httpClient'
import { employeeOnboardingRoute } from 'lib/routes'
import setEmployeeCookies from 'utils/setEmployeeCookies'
import redirect from 'utils/redirect'
import { dataApiSuccess } from 'state/data/actions'
import { SIGNUP_FROM_INVITATION } from 'state/concepts/employee/types'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { addRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { usersInvitationsRegistrationRoute } from 'lib/apiRoutes'
import { setPassword } from 'state/concepts/login/actions'

const signupFromInvitationOperation = createLogic({
  type: SIGNUP_FROM_INVITATION,
  latest: true,

  async process({ action: { values, form }, httpClient }, dispatch, done) {
    try {
      const params = {
        token: values.token,
        password: values.password,
        include: ['workspace', 'user-profile-two-factor-setting', 'workspace.workspace-two-factor-setting'],
      }

      const {
        data,
        data: { meta },
      } = await workspacesHttpClient.post(usersInvitationsRegistrationRoute, params)
      const response = normalize(data)
      const currentEmployee = build(response, 'userProfile')[0]
      const uniqCode = path(['workspace', 'uniqCode'], currentEmployee)
      const tokens = meta.jwt

      dispatch(employeeLoginSuccess(currentEmployee.id, !currentEmployee?.confirmedAt))
      dispatch(addRecentWorkspace(currentEmployee, tokens))
      dispatch(dataApiSuccess({ response }))
      dispatch(setPassword(values.password))

      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers.Authorization = tokens.access
      setEmployeeCookies(currentEmployee, tokens, { path: `/${uniqCode}` })

      if (!meta.need_two_factor && isPresent(currentEmployee.userProfileTwoFactorSetting)) {
        redirect({
          href: employeeOnboardingRoute,
          workspace: uniqCode,
          reload: true,
        })
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default signupFromInvitationOperation
