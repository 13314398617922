import yup from 'lib/yupLocalised'
import { CHAT_TYPES } from 'lib/constants/chat'

export default yup.object().shape({
  chat: yup.object(),
  chatType: yup.string().required(),
  chatUserIds: yup.array().when('chatType', {
    is: CHAT_TYPES.room,
    then: () => yup.array().of(yup.string()),
    otherwise: () => yup.string().required(),
  }),
})
