import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { adjust, assoc } from 'ramda'

import { endpointMetaSelector } from 'state/data/selectors'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { fetchFilesSharedWithMeEndpoint } from 'state/concepts/filesSharedWithMe/endpoints'
import { currentFolderIdSelector as currentSharedFolderIdSelector } from 'state/concepts/filesSharedWithMe/selectors'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { isFolder } from 'utils/businessFiles'
import { hideModal } from 'state/modal/actions'
import { RENAME_MY_BUSINESS_FILE } from '../types'
import { fetchMyBusinessFilesEndpoint, renameMyBusinessFileEndpoint } from '../endpoints'
import { currentFolderIdSelector, myBusinessFileByIdSelector } from '../selectors'

const renameMyBusinessFileOperation = createLogic({
  type: RENAME_MY_BUSINESS_FILE,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const {
      values: { myBusinessFileId, name, extension },
      form: { setSubmitting },
    } = action
    const state = getState()
    const { url, endpoint } = renameMyBusinessFileEndpoint(myBusinessFileId)
    const {
      kind,
      userProfile: { id: userProfileId },
    } = myBusinessFileByIdSelector(state, myBusinessFileId)
    const currentEmployeeId = currentEmployeeIdSelector(state)
    const isOwner = userProfileId === currentEmployeeId
    const currentFolderId = isOwner ? currentFolderIdSelector(state) : currentSharedFolderIdSelector(state)

    try {
      const { data } = await httpClient.put(url, { name: extension ? `${name}.${extension}` : name })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      if (currentFolderId === myBusinessFileId) {
        const { endpoint: metaEndpoint } = isOwner ? fetchMyBusinessFilesEndpoint : fetchFilesSharedWithMeEndpoint
        const { path } = endpointMetaSelector(state, metaEndpoint)
        const newPath = adjust(-1, assoc(1, name), path)
        const metaResponse = { meta: { [metaEndpoint]: { meta: { path: newPath } } } }

        dispatch(dataApiSuccess({ response: metaResponse, endpoint: metaEndpoint }))
      }
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: `notifications.${isFolder(kind) ? 'folderWasUpdated' : 'fileWasUpdated'}` },
        }),
      )
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(
          showNotification({ messageObject: { id: 'notifications.noPermissionToPerformAction' }, kind: 'error' }),
        )
        dispatch(hideModal())
      }
      requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403] })
      setSubmitting(false)
    }

    done()
  },
})

export default renameMyBusinessFileOperation
