import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const DropdownO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M85.334 256c-46.638 0-85.334 38.696-85.334 85.334v341.331c0 46.638 38.696 85.335 85.334 85.335h853.331c46.638 0 85.335-38.697 85.335-85.335v-341.331c0-46.638-38.697-85.334-85.335-85.334h-853.331zM85.334 341.334h512.002v341.331h-512.002v-341.331zM682.665 341.334h256v341.331h-256v-341.331zM725.335 469.334l85.33 85.331 85.335-85.331h-170.665z" />
  </svg>
)

DropdownO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

DropdownO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default DropdownO
