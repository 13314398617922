import { prop, map, compose, sortBy, filter, propEq, any, propOr, pluck, path } from 'ramda'
import { DateTime } from 'luxon'
import build from 'redux-object'
import { nanoid } from 'nanoid'

import isMobileOrTablet from 'lib/isMobileOrTablet'
import { USER_TYPES, DEFAULT_SPEAKER_DEVICE, VIDEO_PRIORITY } from 'lib/constants/videoConference'
import profileName from 'utils/profileName'
import isPresent from 'utils/isPresent'
import updateDataHelper from 'utils/updateDataHelper'
import getMediaDevices from 'lib/webrtcClient/utils/getMediaDevices'

const buildDevicesOptions = map(({ label, deviceId }) => ({
  key: deviceId,
  label: label || deviceId,
  value: deviceId,
}))

const getAudioDevices = compose(buildDevicesOptions, filter(propEq('audioinput', 'kind')))
const getVideoDevices = compose(buildDevicesOptions, filter(propEq('videoinput', 'kind')))
const getSpeakerDevices = compose(buildDevicesOptions, filter(propEq('audiooutput', 'kind')))

const getAvailableDevices = filter(prop('value'))

export const getDevices = async () => {
  const devices = await getMediaDevices()

  const audioDevices = getAudioDevices(devices)
  const videoDevices = getVideoDevices(devices)
  const speakerDevices = getSpeakerDevices(devices)

  const availableAudioDevices = getAvailableDevices(audioDevices)
  const availableVideoDevices = getAvailableDevices(videoDevices)
  const availableSpeakerDevices = getAvailableDevices(speakerDevices)

  const hasAudioInputDevices = isPresent(audioDevices)
  const hasVideoInputDevices = isPresent(videoDevices)

  return {
    audioDevices,
    videoDevices,
    speakerDevices,
    availableAudioDevices,
    availableVideoDevices,
    availableSpeakerDevices: isPresent(availableSpeakerDevices) ? availableSpeakerDevices : [DEFAULT_SPEAKER_DEVICE],
    hasAudioInputDevices,
    hasVideoInputDevices,
  }
}

export const userIdentity = (currentUser, userName) => {
  if (!currentUser) {
    return JSON.stringify({ name: userName, key: nanoid(5) })
  }

  return JSON.stringify({
    name: userName,
    id: currentUser.id,
    type: currentUser.type[0],
  })
}

export const participantName = (entity, clientHost) => {
  const identity = JSON.parse(entity.identity || entity.author)
  const { name } = identity

  if (identity.type === USER_TYPES.client && identity.id === clientHost.id) {
    return profileName(clientHost)
  }

  return name
}

export const sortMessages = sortBy(compose(DateTime.fromISO, prop('createdAt')))

export const getConnectOptions = () => {
  const options = {
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        dominantSpeakerPriority: 'standard',
      },
    },
    maxAudioBitrate: 16000,
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: { local: 1, remote: 1 },
  }

  if (isMobileOrTablet()) {
    options.bandwidthProfile.video.maxSubscriptionBitrate = 2500000
  }
  return options
}

const STANDARD_PRIORITY_SIZE_LIMIT = 500
const LOW_PRIORITY_SIZE_LIMIT = 300

export const getVideoPriority = inlineSize => {
  if (inlineSize < LOW_PRIORITY_SIZE_LIMIT) {
    return VIDEO_PRIORITY.low
  }
  if (inlineSize < STANDARD_PRIORITY_SIZE_LIMIT) {
    return VIDEO_PRIORITY.standard
  }

  return VIDEO_PRIORITY.high
}

export const getVideoConstraints = () =>
  isMobileOrTablet() ? { height: 480, frameRate: 24, width: 640 } : { height: 720, frameRate: 24, width: 1280 }

export const isScreenSharingSupported = () => !!navigator.mediaDevices?.getDisplayMedia

export const detachTrack = (track, container) => {
  const element = track.detach(container)
  element.srcObject = null
}

export const isScreenShared = any(propEq('screen', 'trackName'))

export const workspacifyClientProfile = (currentWorkspaceId, response) => {
  const clientProfiles = build(response, 'clientProfile')

  if (!isPresent(clientProfiles) || !isPresent(currentWorkspaceId)) {
    return response
  }

  const updatedClientProfile = updateDataHelper(response, 'clientProfile', pluck('id', clientProfiles), id => {
    const {
      attributes,
      relationships: { workspaceClientProfile },
    } = response.clientProfile[id]
    const workspaceClientProfileId = path(['data', 'id'], workspaceClientProfile)
    const workspaceAttributes = path(['workspaceClientProfile', workspaceClientProfileId, 'attributes'], response)
    const { firstName, lastName } = attributes

    if (workspaceAttributes.workspaceId !== Number(currentWorkspaceId)) {
      return {
        attributes: {
          firstName,
          lastName,
        },
      }
    }

    return {
      attributes: {
        firstName: propOr(firstName, 'firstName', workspaceAttributes),
        lastName: propOr(lastName, 'lastName', workspaceAttributes),
      },
    }
  })

  return { ...response, ...updatedClientProfile }
}
