export const CLIENT_PROFILE_TABS = {
  overview: 'overview',
  documents: 'documents',
  tasks: 'tasks',
  appointments: 'appointments',
  notes: 'notes',
  files: 'files',
  reviews: 'reviews',
  forms: 'forms',
  agreements: 'agreements',
  contacts: 'contacts',
}

export const DOCUMENT_TABS_BY_FORM_TYPE = {
  agreement: CLIENT_PROFILE_TABS.agreements,
  intake: CLIENT_PROFILE_TABS.forms,
}

export const MAX_NOTE_LENGTH = 10000

export const MAX_FILES_TO_UPLOAD = 10

export const CLIENT_FEED_TYPES = {
  booking: 'booking',
  clientNote: 'clientNote',
  clientAttachment: 'clientAttachment',
  clientProfileForm: 'clientProfileForm',
  review: 'review',
  task: 'task',
  document: 'document',
  clientContact: 'clientContact',
  waitlist: 'waitlistRequest',
}

export const PREVIEW_EXPERTS_COUNT = 3
