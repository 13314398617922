import React from 'react'
import { withFormik } from 'formik'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import yup from 'lib/yupLocalised'
import { MAX_PASSWORD_LENGTH } from 'lib/constants'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { handleSubmit } from 'utils/form/handleSubmit'
import { changeCompanyOwnership } from 'state/concepts/company/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import TransferOwnershipModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class TransferOwnershipModal extends React.Component {
  render = () => <TransferOwnershipModalComponent {...this.props} isSubmitDisabled={isSubmitDisabled(this.props)} />
}

const mapDispatchToProps = {
  onSubmit: changeCompanyOwnership,
}

const mapStateToProps = state => ({
  currentEmployee: currentEmployeeSelector(state),
})

export { TransferOwnershipModal as TransferOwnershipModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      employeeId: undefined,
      password: '',
      keepAdministrator: true,
    }),
    validationSchema: yup.object().shape({
      employeeId: yup.string().required({ id: 'yup.selectAccountOwner' }),
      password: yup.string().max(MAX_PASSWORD_LENGTH).required(),
    }),
    handleSubmit,
    validateOnBlur: false,
  }),
)(TransferOwnershipModal)
