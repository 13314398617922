import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CALENDAR_EVENTS } from 'state/concepts/bookings/types'
import { filtersSelector, selectedTimezoneSelector, datesFilterSelector } from 'state/concepts/bookings/selectors'
import { fetchCalendarEventsEndpoint } from 'state/concepts/bookings/endpoints'
import { setCalendarEvents, setBlankState } from 'state/concepts/bookings/actions'
import isBlankState from 'utils/isBlankState'

const fetchCalendarEventsOperation = createLogic({
  type: FETCH_CALENDAR_EVENTS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { endpoint, url } = fetchCalendarEventsEndpoint
    const { startDate, endDate } = datesFilterSelector(state)
    const timezone = selectedTimezoneSelector(state)
    const { statuses, employees, services, clients, name, calendarTypes, locations } = filtersSelector(state)

    const params = {
      include: [
        'source.client-profile',
        'source.reschedule',
        'source.service-category',
        'source.booking-recurrence',
        'source.booking-setting',
        'source.transactions',
        'source.service',
        'source.user-profile',
        'source.video_records',
        'source.location',
        'source.video_conference',
        'source.invited-members',
      ],
      filter: {
        start_date: startDate.toISODate(),
        end_date: endDate.toISODate(),
        timezone,
        user_profile_ids: employees,
        service_ids: services,
        client_profile_ids: clients,
        calendar_types: calendarTypes,
        location_ids: locations,
        statuses,
        name,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(setBlankState(isBlankState({ data: data.data })))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setCalendarEvents(map(prop('id'), data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchCalendarEventsOperation
