import acceptVideoCallsImg from 'assets/images/module_system/accept-video-calls.svg'
import acceptPaymentsImg from 'assets/images/module_system/accept_payments.svg'
import availabilityImg from 'assets/images/module_system/availability-dark.svg'
import bookingPageImg from 'assets/images/module_system/booking-page.svg'
import bookingWidgetsImg from 'assets/images/module_system/booking-widgets.svg'
import bookingImg from 'assets/images/module_system/booking.svg'
import calendarIntegrationsImg from 'assets/images/module_system/calendar-integrations.svg'
import calendarImg from 'assets/images/module_system/calendar.svg'
import chatImg from 'assets/images/module_system/chat.svg'
import clientListImg from 'assets/images/module_system/client-list.svg'
import clientPortalImg from 'assets/images/module_system/client-portal.svg'
import contactsImg from 'assets/images/module_system/contacts.svg'
import crmImg from 'assets/images/module_system/crm.svg'
import customNamingImg from 'assets/images/module_system/custom-naming.svg'
import customPaymentsImg from 'assets/images/module_system/custom_payments.svg'
import documentsImg from 'assets/images/module_system/documents.svg'
import emailRemindersImg from 'assets/images/module_system/email-reminders.svg'
import expertBoxImg from 'assets/images/module_system/expert-box.svg'
import financialDocumentsImg from 'assets/images/module_system/financial-documents.svg'
import intakeFormsImg from 'assets/images/module_system/intake-forms.svg'
import locationsImg from 'assets/images/module_system/locations.svg'
import videoCallRecordingImg from 'assets/images/module_system/record.svg'
import refundsImg from 'assets/images/module_system/refunds.svg'
import reviewsImg from 'assets/images/module_system/reviews.svg'
import serviceAgreementsImg from 'assets/images/module_system/service_agreements.svg'
import serviceCategoriesImg from 'assets/images/module_system/service_categories.svg'
import servicesImg from 'assets/images/module_system/services.svg'
import smsRemindersImg from 'assets/images/module_system/sms-reminders.svg'
import stripePosImg from 'assets/images/module_system/stripe_pos.svg'
import tasksImg from 'assets/images/module_system/tasks.svg'
import teamPageImg from 'assets/images/module_system/team-management.svg'
import voiceMessagesImg from 'assets/images/module_system/voice_messages.svg'
import waitlistImg from 'assets/images/module_system/waitlist.svg'
import websiteImg from 'assets/images/module_system/website.svg'
import templatesImg from 'assets/images/module_system/templates.svg'
import signatureImg from 'assets/images/module_system/signature.svg'
import fileImg from 'assets/images/module_system/business_files.svg'

import * as routes from 'lib/routes'
import { PLAN_TYPES } from './billing'

export const FEATURE_STATUSES = {
  enabled: 'enabled',
  disabled: 'disabled',
}

export const featureStatusesMap = {
  [FEATURE_STATUSES.enabled]: true,
  [FEATURE_STATUSES.disabled]: false,
}

export const FEATURE_KEYS = {
  voiceMessages: 'voice_message',
  recurringAppointments: 'recurring_appointments',
  appointments: 'appointments',
  availability: 'availability',
  clientPortal: 'client_portal',
  services: 'services',
  serviceCategories: 'service_categories',
  tasks: 'tasks',
  acceptVideoCalls: 'accept_video_calls',
  groupVideoCalls: 'group_video_calls',
  videoCallRecording: 'video_call_recording',
  bookingPage: 'booking_page',
  bookingWidgets: 'booking_widgets',
  website: 'website',
  team: 'team',
  locations: 'multiple_locations',
  clientsList: 'clients_list',
  crm: 'crm',
  chat: 'chat',
  financialDocuments: 'financial_documents',
  acceptPayments: 'accept_payments',
  stripePos: 'stripe_pos',
  customPayments: 'custom_payments',
  refunds: 'refunds',
  emailReminders: 'email',
  smsReminders: 'sms',
  customNaming: 'custom_naming',
  forms: 'forms',
  serviceAgreements: 'agreements',
  textDocuments: 'text_documents',
  reviews: 'reviews',
  clientContacts: 'client_contacts',
  calendarIntegration: 'calendar_integration',
  documents: 'text_documents',
  waitlist: 'waitlist',
  documentTemplates: 'document_templates',
  signature: 'signature',
  businessFiles: 'business_files',
}

export const FEATURE_CATEGORIES = {
  booking: 'booking',
  clients: 'clients',
  services: 'services',
  communication: 'communication',
  teamManagement: 'teamManagement',
  website: 'website',
  payments: 'payments',
  whiteLabel: 'whiteLabel',
  remindersAndNotifications: 'remindersAndNotifications',
  formsAndDocuments: 'formsAndDocuments',
}

export const FEATURE_MODULES = {
  scheduling: 'scheduling',
  booking: 'booking',
  clientPortal: 'clientPortal',
  services: 'services',
  taskManagement: 'taskManagement',
  videoCalls: 'videoCalls',
  teamManagement: 'teamManagement',
  website: 'website',
  crm: 'crm',
  messenger: 'messenger',
  payments: 'payments',
  locations: 'locations',
  remindersAndNotifications: 'remindersAndNotifications',
  whiteLabel: 'whiteLabel',
  formsAndDocuments: 'formsAndDocuments',
  reviews: 'reviews',
}

export const ORDER_KEYS = {
  ...FEATURE_KEYS,
  services: 1,
  serviceCategories: 2,
  documentTemplates: 4,
  serviceAgreements: 3,
  signature: 4,
  clientsList: 1,
  crm: 2,
  clientContacts: 3,
}

export const FEATURES_CARDS = [
  {
    key: FEATURE_KEYS.availability,
    orderKey: ORDER_KEYS.availability,
    title: { id: 'features.availability.title' },
    description: { id: 'features.availability.description' },
    imageSrc: calendarImg,
    dataCy: 'features-availability-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesAvailabilityRoute,
    module: FEATURE_MODULES.scheduling,
    moduleTitle: { id: 'features.module.scheduling' },
  },
  {
    key: FEATURE_KEYS.appointments,
    orderKey: ORDER_KEYS.appointments,
    title: { id: 'features.booking.title' },
    description: { id: 'features.booking.description' },
    imageSrc: bookingImg,
    dataCy: 'features-booking-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesBookingRoute,
    module: FEATURE_MODULES.scheduling,
    moduleTitle: { id: 'features.module.booking' },
  },
  {
    key: FEATURE_KEYS.recurringAppointments,
    orderKey: ORDER_KEYS.recurringAppointments,
    title: { id: 'features.recurringAppointments.title' },
    description: { id: 'features.recurringAppointments.description' },
    imageSrc: availabilityImg,
    dataCy: 'features-recurringAppointments-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesRecurringAppointmentsRoute,
    module: FEATURE_MODULES.scheduling,
    moduleTitle: { id: 'features.module.scheduling' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.clientPortal,
    orderKey: ORDER_KEYS.clientPortal,
    title: { id: 'features.clientPortal.title' },
    description: { id: 'features.clientPortal.description' },
    imageSrc: clientPortalImg,
    dataCy: 'features-clientPortal-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesClientPortalRoute,
    module: FEATURE_MODULES.clientPortal,
    moduleTitle: { id: 'features.module.clientPortal' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.clientContacts,
    orderKey: ORDER_KEYS.clientContacts,
    title: { id: 'features.clientContacts.title' },
    description: { id: 'features.clientContacts.description' },
    imageSrc: contactsImg,
    dataCy: 'features-clientContacts-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesClientContactsRoute,
    module: FEATURE_MODULES.crm,
    moduleTitle: { id: 'features.module.clientPortal' },
    isAdvanced: true,
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.services,
    orderKey: ORDER_KEYS.services,
    title: { id: 'features.services.title' },
    description: { id: 'features.services.description' },
    imageSrc: servicesImg,
    dataCy: 'features-services-card',
    category: FEATURE_CATEGORIES.services,
    href: routes.companyFeaturesServicesRoute,
    module: FEATURE_MODULES.services,
    moduleTitle: { id: 'features.module.services' },
  },
  {
    key: FEATURE_KEYS.serviceCategories,
    orderKey: ORDER_KEYS.serviceCategories,
    title: { id: 'features.serviceCategories.title' },
    description: { id: 'features.serviceCategories.description' },
    imageSrc: serviceCategoriesImg,
    dataCy: 'features-serviceCategories-card',
    category: FEATURE_CATEGORIES.services,
    href: routes.companyFeaturesServiceCategoriesRoute,
    module: FEATURE_MODULES.services,
    moduleTitle: { id: 'features.module.services' },
  },
  {
    key: FEATURE_KEYS.tasks,
    orderKey: ORDER_KEYS.tasks,
    title: { id: 'features.tasks.title' },
    description: { id: 'features.tasks.description' },
    imageSrc: tasksImg,
    dataCy: 'features-tasks-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesTasksRoute,
    module: FEATURE_MODULES.taskManagement,
    moduleTitle: { id: 'features.module.taskManagement' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.acceptVideoCalls,
    orderKey: ORDER_KEYS.acceptVideoCalls,
    title: { id: 'features.acceptVideoCalls.title' },
    description: { id: 'features.acceptVideoCalls.description' },
    imageSrc: acceptVideoCallsImg,
    dataCy: 'features-acceptVideoCalls-card',
    category: FEATURE_CATEGORIES.communication,
    href: routes.companyFeaturesAcceptVideoCallsRoute,
    module: FEATURE_MODULES.videoCalls,
    moduleTitle: { id: 'features.module.videoCalls' },
  },
  {
    key: FEATURE_KEYS.groupVideoCalls,
    orderKey: ORDER_KEYS.groupVideoCalls,
    title: { id: 'features.groupVideoCalls.title' },
    description: { id: 'features.groupVideoCalls.description' },
    imageSrc: expertBoxImg,
    dataCy: 'features-group-video-calls-card',
    category: FEATURE_CATEGORIES.communication,
    href: routes.companyFeaturesGroupVideoCallsRoute,
    module: FEATURE_MODULES.videoCalls,
    moduleTitle: { id: 'features.module.videoCalls' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.videoCallRecording,
    orderKey: ORDER_KEYS.videoCallRecording,
    title: { id: 'features.videoCallsRecording.title' },
    description: { id: 'features.videoCallsRecording.description' },
    imageSrc: videoCallRecordingImg,
    dataCy: 'features-video-calls-recording-card',
    category: FEATURE_CATEGORIES.communication,
    href: routes.companyFeaturesVideoCallsRecordingRoute,
    module: FEATURE_MODULES.videoCalls,
    moduleTitle: { id: 'features.module.videoCalls' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.bookingPage,
    orderKey: ORDER_KEYS.bookingPage,
    title: { id: 'features.bookingPage.title' },
    description: { id: 'features.bookingPage.description' },
    imageSrc: bookingPageImg,
    dataCy: 'booking-page-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesBookingPageRoute,
    module: FEATURE_MODULES.booking,
    moduleTitle: { id: 'features.module.booking' },
  },
  {
    key: FEATURE_KEYS.bookingWidgets,
    orderKey: ORDER_KEYS.bookingWidgets,
    title: { id: 'features.bookingWidgets.title' },
    description: { id: 'features.bookingWidgets.description' },
    imageSrc: bookingWidgetsImg,
    dataCy: 'booking-widgets-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesBookingWidgetsRoute,
    module: FEATURE_MODULES.booking,
    moduleTitle: { id: 'features.module.booking' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.team,
    orderKey: ORDER_KEYS.team,
    title: { id: 'features.team.title' },
    description: { id: 'features.team.description' },
    imageSrc: teamPageImg,
    dataCy: 'features-team-card',
    category: FEATURE_CATEGORIES.teamManagement,
    href: routes.companyFeaturesTeamRoute,
    module: FEATURE_MODULES.teamManagement,
    moduleTitle: { id: 'features.module.teamManagement' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.website,
    orderKey: ORDER_KEYS.website,
    title: { id: 'features.website.title' },
    description: { id: 'features.website.description' },
    imageSrc: websiteImg,
    dataCy: 'features-website-card',
    category: FEATURE_CATEGORIES.website,
    href: routes.companyFeaturesWebsiteRoute,
    module: FEATURE_MODULES.website,
    moduleTitle: { id: 'features.module.website' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.locations,
    orderKey: ORDER_KEYS.locations,
    title: { id: 'features.locations.title' },
    description: { id: 'features.locations.description' },
    imageSrc: locationsImg,
    dataCy: 'features-locations-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesLocationsRoute,
    module: FEATURE_MODULES.locations,
    moduleTitle: { id: 'features.module.locations' },
  },
  {
    key: FEATURE_KEYS.clientsList,
    orderKey: ORDER_KEYS.clientsList,
    title: { id: 'features.clientList.title' },
    description: { id: 'features.clientList.description' },
    imageSrc: clientListImg,
    dataCy: 'features-client-list-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesClientListRoute,
    module: FEATURE_MODULES.crm,
    moduleTitle: { id: 'features.module.crm' },
  },
  {
    key: FEATURE_KEYS.crm,
    orderKey: ORDER_KEYS.crm,
    title: { id: 'features.crm.title' },
    description: { id: 'features.crm.description' },
    imageSrc: crmImg,
    dataCy: 'features-crm-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesCRMRoute,
    module: FEATURE_MODULES.crm,
    moduleTitle: { id: 'features.module.crm' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.chat,
    orderKey: ORDER_KEYS.chat,
    title: { id: 'features.chat.title' },
    description: { id: 'features.chat.description' },
    imageSrc: chatImg,
    dataCy: 'features-chat-card',
    category: FEATURE_CATEGORIES.communication,
    href: routes.companyFeaturesChatRoute,
    module: FEATURE_MODULES.messenger,
    moduleTitle: { id: 'features.module.messenger' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.financialDocuments,
    orderKey: ORDER_KEYS.financialDocuments,
    title: { id: 'features.financialDocuments.title' },
    description: { id: 'features.financialDocuments.description' },
    imageSrc: financialDocumentsImg,
    dataCy: 'features-financial-documents-card',
    category: FEATURE_CATEGORIES.payments,
    href: routes.companyFeaturesFinancialDocumentsRoute,
    module: FEATURE_MODULES.payments,
    moduleTitle: { id: 'features.module.payments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premiumPlus,
  },
  {
    key: FEATURE_KEYS.stripePos,
    orderKey: ORDER_KEYS.stripePos,
    title: { id: 'features.stripePos.title' },
    description: { id: 'features.stripePos.description' },
    imageSrc: stripePosImg,
    dataCy: 'features-stripe-pos-card',
    category: FEATURE_CATEGORIES.payments,
    href: routes.companyFeaturesStripePosRoute,
    module: FEATURE_MODULES.payments,
    moduleTitle: { id: 'features.module.payments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premiumPlus,
  },
  {
    key: FEATURE_KEYS.acceptPayments,
    orderKey: ORDER_KEYS.acceptPayments,
    title: { id: 'features.acceptPayments.title' },
    description: { id: 'features.acceptPayments.description' },
    imageSrc: acceptPaymentsImg,
    dataCy: 'features-accept-payments-card',
    category: FEATURE_CATEGORIES.payments,
    href: routes.companyFeaturesAcceptPaymentsRoute,
    module: FEATURE_MODULES.payments,
    moduleTitle: { id: 'features.module.payments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premiumPlus,
  },
  {
    key: FEATURE_KEYS.customPayments,
    orderKey: ORDER_KEYS.customPayments,
    title: { id: 'features.customPayments.title' },
    description: { id: 'features.customPayments.description' },
    imageSrc: customPaymentsImg,
    dataCy: 'features-custom-payments-card',
    category: FEATURE_CATEGORIES.payments,
    href: routes.companyFeaturesCustomPaymentsRoute,
    module: FEATURE_MODULES.payments,
    moduleTitle: { id: 'features.module.payments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premiumPlus,
  },
  {
    key: FEATURE_KEYS.refunds,
    orderKey: ORDER_KEYS.refunds,
    title: { id: 'features.refunds.title' },
    description: { id: 'features.refunds.description' },
    imageSrc: refundsImg,
    dataCy: 'features-refunds-card',
    category: FEATURE_CATEGORIES.payments,
    href: routes.companyFeaturesRefundsRoute,
    module: FEATURE_MODULES.payments,
    moduleTitle: { id: 'features.module.payments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premiumPlus,
  },
  {
    key: FEATURE_KEYS.emailReminders,
    title: { id: 'features.emailReminders.title' },
    description: { id: 'features.emailReminders.description' },
    imageSrc: emailRemindersImg,
    dataCy: 'features-emailReminders-card',
    category: FEATURE_CATEGORIES.remindersAndNotifications,
    href: routes.companyFeaturesEmailRemindersRoute,
    module: FEATURE_MODULES.remindersAndNotifications,
    moduleTitle: { id: 'features.module.remindersAndNotifications' },
  },
  {
    key: FEATURE_KEYS.smsReminders,
    title: { id: 'features.smsReminders.title' },
    description: { id: 'features.smsReminders.description' },
    imageSrc: smsRemindersImg,
    dataCy: 'features-smsReminders-card',
    category: FEATURE_CATEGORIES.remindersAndNotifications,
    href: routes.companyFeaturesSmsRemindersRoute,
    module: FEATURE_MODULES.remindersAndNotifications,
    moduleTitle: { id: 'features.module.remindersAndNotifications' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.customNaming,
    orderKey: ORDER_KEYS.customNaming,
    title: { id: 'features.customNaming.title' },
    description: { id: 'features.customNaming.description' },
    imageSrc: customNamingImg,
    dataCy: 'features-custom-naming-card',
    category: FEATURE_CATEGORIES.whiteLabel,
    href: routes.companyFeaturesCustomNamingRoute,
    module: FEATURE_MODULES.whiteLabel,
    moduleTitle: { id: 'features.module.whiteLabel' },
  },
  {
    key: FEATURE_KEYS.forms,
    orderKey: ORDER_KEYS.forms,
    title: { id: 'features.forms.title' },
    description: { id: 'features.forms.description' },
    imageSrc: intakeFormsImg,
    dataCy: 'features-intake-forms-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesIntakeFormsRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.serviceAgreements,
    orderKey: ORDER_KEYS.serviceAgreements,
    title: { id: 'features.serviceAgreements.title' },
    description: { id: 'features.serviceAgreements.description' },
    imageSrc: serviceAgreementsImg,
    dataCy: 'features-service-agreements-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesServiceAgreementsRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.textDocuments,
    orderKey: ORDER_KEYS.textDocuments,
    title: { id: 'features.documents.title' },
    description: { id: 'features.documents.description' },
    imageSrc: documentsImg,
    dataCy: 'features-service-documents-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesDocumentsRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.documentTemplates,
    orderKey: ORDER_KEYS.documentTemplates,
    title: { id: 'features.documentTemplates.title' },
    description: { id: 'features.documentTemplates.description' },
    imageSrc: templatesImg,
    dataCy: 'features-document-templates-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesDocumentTemplatesRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.signature,
    orderKey: ORDER_KEYS.signature,
    title: { id: 'features.signature.title' },
    description: { id: 'features.signature.description' },
    imageSrc: signatureImg,
    dataCy: 'features-signature-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesSignatureRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.reviews,
    orderKey: ORDER_KEYS.reviews,
    title: { id: 'features.reviews.title' },
    description: { id: 'features.reviews.description' },
    imageSrc: reviewsImg,
    dataCy: 'features-service-agreements-card',
    category: FEATURE_CATEGORIES.clients,
    href: routes.companyFeaturesReviewsRoute,
    module: FEATURE_MODULES.reviews,
    moduleTitle: { id: 'features.module.reviews' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.calendarIntegration,
    title: { id: 'features.calendarIntegrations.title' },
    description: { id: 'features.calendarIntegrations.description' },
    imageSrc: calendarIntegrationsImg,
    dataCy: 'features-calendarIntegrations-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesCalendarIntegrationsRoute,
    module: FEATURE_MODULES.scheduling,
    moduleTitle: { id: 'features.module.schedulingAndBooking' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.voiceMessages,
    title: { id: 'features.voiceMessages.title' },
    description: { id: 'features.voiceMessages.description' },
    imageSrc: voiceMessagesImg,
    dataCy: 'features-voiceMessages-card',
    category: FEATURE_CATEGORIES.communication,
    href: routes.companyFeaturesVoiceMessagesRoute,
    module: FEATURE_MODULES.messenger,
    moduleTitle: { id: 'features.module.messenger' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.businessFiles,
    title: { id: 'features.businessFiles.title' },
    description: { id: 'features.businessFiles.description' },
    imageSrc: fileImg,
    dataCy: 'features-businessFiles-card',
    category: FEATURE_CATEGORIES.formsAndDocuments,
    href: routes.companyFeaturesBusinessFilesRoute,
    module: FEATURE_MODULES.formsAndDocuments,
    moduleTitle: { id: 'features.module.formsAndDocuments' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
  {
    key: FEATURE_KEYS.waitlist,
    title: { id: 'features.waitlist.title' },
    description: { id: 'features.waitlist.description' },
    imageSrc: waitlistImg,
    dataCy: 'features-waitlist-card',
    category: FEATURE_CATEGORIES.booking,
    href: routes.companyFeaturesWaitlistRoute,
    module: FEATURE_MODULES.booking,
    moduleTitle: { id: 'features.module.schedulingAndBooking' },
    permissionForSubscriptionPlan: PLAN_TYPES.premium,
  },
]
