import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find, propEq, prop } from 'ramda'

import { timezonesSelector } from 'state/concepts/timezones/selectors'
import ClientBookingTimezoneDropdownComponent from './component'

class ClientTimezoneField extends React.Component {
  static propTypes = {
    timezones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }

  state = {
    isOpen: false,
  }

  get selectedTimezoneLabel() {
    const {
      timezones,
      field: { value },
    } = this.props

    return prop('label', find(propEq(value, 'value'), timezones))
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleVisibleChange = visible => (visible ? this.handleOpen() : this.handleClose())

  handleSelect = timezone => () => {
    const { field } = this.props

    field.onChange(field.name)(timezone)
    this.handleClose()
  }

  getPopupContainer = () => document.getElementById('client-timezone')

  render() {
    return (
      <ClientBookingTimezoneDropdownComponent
        {...this.props}
        {...this.state}
        handleSelect={this.handleSelect}
        onVisibleChange={this.handleVisibleChange}
        selectedTimezoneLabel={this.selectedTimezoneLabel}
        filterTimezones={this.filterTimezones}
        getPopupContainer={this.getPopupContainer}
      />
    )
  }
}

const mapStateToProps = state => ({
  timezones: timezonesSelector(state),
})

export { ClientTimezoneField as ClientTimezoneFieldContainer }
export default connect(mapStateToProps)(ClientTimezoneField)
