import endpoint from 'utils/endpoint'
import {
  clientsProfileForms,
  clientsAccountSharedFiles,
  clientsAccountSharedDocuments,
  clientsAccountViewSharedFiles,
  clientsAccountUploadedFiles,
} from 'lib/apiRoutes'
import { FETCH_FORMS, FETCH_FILES, FETCH_DOCUMENTS, FETCH_MY_FILES, VIEW_FILES } from './types'

export const fetchFormsEndpoint = formType => endpoint(FETCH_FORMS, 'GET', clientsProfileForms, formType)
export const fetchFilesEndpoint = endpoint(FETCH_FILES, 'GET', clientsAccountSharedFiles)
export const fetchMyFilesEndpoint = endpoint(FETCH_MY_FILES, 'GET', clientsAccountUploadedFiles)
export const fetchDocumentsEndpoint = endpoint(FETCH_DOCUMENTS, 'GET', clientsAccountSharedDocuments)
export const viewFilesEndpoint = endpoint(VIEW_FILES, 'POST', clientsAccountViewSharedFiles)
