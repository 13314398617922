import React from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { prop } from 'ramda'

import { RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'

import RescheduleRecurringBookingModalComponent from './component'

class RescheduleRecurringBookingModal extends React.Component {
  static propTypes = {
    values: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    const { values, onClose, onSubmit } = this.props

    onClose()
    onSubmit(prop('amount', values))
  }

  render = () => <RescheduleRecurringBookingModalComponent {...this.props} handleSubmit={this.handleSubmit} />
}

export { RescheduleRecurringBookingModal as RescheduleRecurringBookingModalContainer }
export default withFormik({
  mapPropsToValues: () => ({
    amount: RECURRING_BOOKING_OPTIONS.single,
  }),
})(RescheduleRecurringBookingModal)
