import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { resolveWaitlistRequestEndpoint } from '../endpoints'
import { RESOLVE_WAITLIST_REQUEST } from '../types'

const resolveWaitlistRequestOperation = createLogic({
  type: RESOLVE_WAITLIST_REQUEST,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { url, endpoint } = resolveWaitlistRequestEndpoint(action.id)
    const params = {
      include: [
        'user-profile',
        'client-profile',
        'location',
        'service',
        'first-preference',
        'availability-preferences',
        'created-by',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default resolveWaitlistRequestOperation
