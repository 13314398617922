import endpoint from 'utils/endpoint'
import { transactionsRoute, receiptTemplateRoute, clientTransactionsRoute, transactionCancelRoute } from 'lib/apiRoutes'
import {
  FETCH_TRANSACTIONS,
  FETCH_RECEIPT_TEMPLATE,
  UPDATE_RECEIPT_TEMPLATE,
  FETCH_CLIENT_TRANSACTIONS,
  CANCEL_PAYMENT,
  REQUEST_PAYMENT,
  SAVE_AS_DRAFT,
} from './types'

export const fetchTransactionsEndpoint = endpoint(FETCH_TRANSACTIONS, 'GET', transactionsRoute)
export const fetchClientTransactionsEndpoint = endpoint(FETCH_CLIENT_TRANSACTIONS, 'GET', clientTransactionsRoute)

export const fetchReceiptTemplateEndpoint = endpoint(FETCH_RECEIPT_TEMPLATE, 'GET', receiptTemplateRoute)
export const updateReceiptTemplateEndpoint = endpoint(UPDATE_RECEIPT_TEMPLATE, 'PUT', receiptTemplateRoute)

export const cancelTransactionEndpoint = transactionId =>
  endpoint(CANCEL_PAYMENT, 'POST', transactionCancelRoute(transactionId))

export const requestTransactionEndpoint = endpoint(REQUEST_PAYMENT, 'POST', transactionsRoute)
export const saveAsDraftTransactionEndpoint = endpoint(SAVE_AS_DRAFT, 'POST', transactionsRoute)
