import { createLogic } from 'redux-logic'

import { setDocumentsFilterParams, fetchDocuments, setDocumentsCurrentPage } from '../actions'
import { FILTER_DOCUMENTS } from '../types'

const filterDocumentsOperation = createLogic({
  type: FILTER_DOCUMENTS,
  latest: true,

  async process({ action: { filterParams } }, dispatch, done) {
    dispatch(setDocumentsFilterParams(filterParams))
    dispatch(setDocumentsCurrentPage(1))
    dispatch(fetchDocuments())
    done()
  },
})

export default filterDocumentsOperation
