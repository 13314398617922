import { times } from 'lodash'
import PropTypes from 'prop-types'
import { Document, Page, pdfjs } from 'react-pdf'

import Spinner from '../icons/Spinner'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const PreviewPdfComponent = ({ file, width, onLoadSuccess, pageCount }) => (
  <Document file={file} onLoadSuccess={onLoadSuccess} loading={<Spinner size={18} color="#388bff" />}>
    {times(pageCount, index => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        width={width}
        renderMode="canvas"
      />
    ))}
  </Document>
)

PreviewPdfComponent.defaultProps = {
  width: undefined,
}

PreviewPdfComponent.propTypes = {
  file: PropTypes.string.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  width: PropTypes.number,
}

export default PreviewPdfComponent
