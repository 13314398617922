import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const getEmployeeOwnProfile = (response, params = {}, query) => ({
  type: types.GET_EMPLOYEE_OWN_PROFILE,
  response,
  params,
  query,
})

export const updateEmployeeAccountGeneral = makeFormSubmitAction(types.UPDATE_EMPLOYEE_ACCOUNT_GENERAL)

export const updateEmployeeAccountSecurity = makeFormSubmitAction(types.UPDATE_EMPLOYEE_ACCOUNT_SECURITY)
export const updateLogoutInterval = (logoutInterval, isLogoutEnabled, form) => ({
  type: types.UPDATE_LOGOUT_INTERVAL,
  logoutInterval,
  isLogoutEnabled,
  form,
})
export const updateEmployeePassword = (oldPassword, password, form) => ({
  type: types.UPDATE_EMPLOYEE_PASSWORD,
  oldPassword,
  password,
  form,
})

export const resetPasswordRequestLogin = makeFormSubmitAction(types.RESET_PASSWORD_REQUEST_LOGIN)

export const resetPasswordRequestAccount = makeFormSubmitAction(types.RESET_PASSWORD_REQUEST_ACCOUNT)

export const resetPasswordConfirm = makeFormSubmitAction(types.RESET_PASSWORD_CONFIRM)

export const updateEmployeeProfile = makeFormSubmitAction(types.UPDATE_EMPLOYEE_PROFILE)

export const validateResetPasswordToken = token => ({
  type: types.VALIDATE_RESET_PASSWORD_TOKEN,
  token,
})

export const validateInvitationToken = token => ({
  type: types.VALIDATE_INVITATION_TOKEN,
  token,
})

export const setEmployeeInvitationId = id => ({
  type: types.SET_EMPLOYEE_INVITATION_ID,
  id,
})

export const signupFromInvitation = makeFormSubmitAction(types.SIGNUP_FROM_INVITATION)

export const submitOnboardingGeneral = makeFormSubmitAction(types.SUBMIT_ONBOARDING_GENERAL)

export const submitOnboardingCompanyProfile = makeFormSubmitAction(types.SUBMIT_ONBOARDING_COMPANY_PROFILE)

export const getEmployeeOnboarding = (response, workspace) => ({
  type: types.GET_EMPLOYEE_ONBOARDING,
  response,
  workspace,
})

export const confirmEmployeeEmail = token => ({
  type: types.CONFIRM_EMPLOYEE_EMAIL,
  token,
})

export const updateOnboardingStep = step => ({
  type: types.UPDATE_ONBOARDING_STEP,
  step,
})

export const resendEmailConfirmation = () => ({
  type: types.RESEND_EMAIL_CONFIRMATION,
})

export const resendEmailChangeConfirmation = () => ({
  type: types.RESEND_EMAIL_CHANGE_CONFIRMATION,
})

export const remindWorkspacesRequest = makeFormSubmitAction(types.REMIND_WORKSPACES_REQUEST)

export const setRemindWorkspaces = workspaces => ({
  type: types.SET_REMIND_WORKSPACES,
  workspaces,
})

export const fetchRemindWorkspaces = token => ({
  type: types.FETCH_REMIND_WORKSPACES,
  token,
})

export const validateConfirmationToken = (token, workspace, pathname) => ({
  type: types.VALIDATE_CONFIRMATION_TOKEN,
  token,
  workspace,
  pathname,
})

export const validateAccessToClient = (userProfileId, clientProfileId, form) => ({
  type: types.VALIDATE_ACCESS_TO_CLIENT,
  userProfileId,
  clientProfileId,
  form,
})

export const validatePasswordOnTwoFactor = makeFormSubmitAction(types.VALIDATE_PASSWORD_ON_TWO_FACTOR)
export const enableTwoFactorSettings = makeFormSubmitAction(types.ENABLE_TWO_FACTOR_SETTINGS)
export const disableTwoFactorSettings = makeFormSubmitAction(types.DISABLE_TWO_FACTOR_SETTINGS)
export const updateTwoFactorSettings = makeFormSubmitAction(types.UPDATE_TWO_FACTOR_SETTINGS)
export const getOtpCodeBySms = makeFormSubmitAction(types.GET_OTP_CODE_BY_SMS)
export const generateBackupCodes = makeFormSubmitAction(types.GENERATE_BACKUP_CODES)

export const resendCodeBySms = phoneNumber => ({
  type: types.GET_OTP_CODE_BY_SMS,
  values: { phoneNumber },
})

export const getAppAuthUri = () => ({
  type: types.GET_APP_AUTH_URI,
})

export const updateCalendarViewSetting = ({ view }) => ({
  type: types.UPDATE_CALENDAR_VIEW_SETTING,
  view,
})

export const setEmployeeId = id => ({
  type: types.SET_EMPLOYEE_ID,
  id,
})
