const waitlistSettings = {
  'waitlistSettings.waitlistEnabled.title': 'Enable waitlist on your booking pages',
  'waitlistSettings.waitlistEnabled.description':
    'Let _(clients) join your waitlist through your online booking pages.',
  'waitlistSettings.availableSlots.title': 'How do you want to fill available slots?',
  'waitlistSettings.scheduleType.automatically.label': 'Automatically schedule _(appointments)',
  'waitlistSettings.scheduleType.automatically.description':
    'Automatically schedule _(appointments) with _(clients) from the top of the waitlist based on their waitlist requests and _(expert) availability. _(Clients) can choose to accept or decline those _(appointment) requests.',
  'waitlistSettings.scheduleType.automatically.setTimeToRespond': 'Set time to respond',
  'waitlistSettings.scheduleType.automatically.setTimeToRespond.description':
    'This is the duration for your _(client) to respond before the _(appointment) is offered to the next _(client) on the waitlist.',
  'waitlistSettings.scheduleType.notificationOnly.label': 'Send _(clients) a notification when an opening occurs',
  'waitlistSettings.scheduleType.notificationOnly.description':
    '_(Clients) get an email when a new slot matches their waitlist request. SMS notifications can also be enabled. Openings are filled on a first-come, first-served basis.',
  'waitlistSettings.scheduleType.notificationOnly.smsNotifications':
    'Enable SMS notifications for those _(clients) who have shared their phone numbers.',
  'waitlistSettings.scheduleType.manually.label': 'Book _(appointments) manually',
  'waitlistSettings.scheduleType.manually.description':
    'You can only schedule _(appointments) manually for _(clients) who are on the waitlist.',
}

export default waitlistSettings
