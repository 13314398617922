import PropTypes from 'prop-types'

import ProfileIdentity from 'views/shared/ProfileIdentity'

const OptionLabel = ({ mode, client, withAvatar }) =>
  mode === 'default' ? (
    <ProfileIdentity isClient user={client} withAvatar={withAvatar} />
  ) : (
    <span className="dropdown-multiselect-choise">
      <span className="dropdown-multiselect-choise__txt">
        <ProfileIdentity isClient user={client} withAvatar={withAvatar} />
      </span>
    </span>
  )

OptionLabel.propTypes = {
  mode: PropTypes.string.isRequired,
  client: PropTypes.shape().isRequired,
  withAvatar: PropTypes.bool.isRequired,
}

export default OptionLabel
