import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_ONBOARDING_STEP } from 'state/concepts/employee/types'
import { dataApiSuccess } from 'state/data/actions'
import { currentEmployeeIdSelector } from 'state/concepts/session/selectors'

const updateOnboardingStepOperation = createLogic({
  type: UPDATE_ONBOARDING_STEP,
  latest: true,

  process({ getState, action: { step } }, dispatch, done) {
    const state = getState()

    const response = updateDataHelper(state.data, 'userProfile', currentEmployeeIdSelector(state), {
      attributes: {
        onboardingStatus: step,
      },
    })

    dispatch(dataApiSuccess({ response }))

    done()
  },
})

export default updateOnboardingStepOperation
