import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchClientChatsUnreadCountEndpoint } from '../endpoints'
import { FETCH_UNREAD_COUNTER } from '../types'

const fetchUnreadCounter = createLogic({
  type: FETCH_UNREAD_COUNTER,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchClientChatsUnreadCountEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchUnreadCounter
