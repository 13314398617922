import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { updateAgreementActivationEndpoint } from 'state/concepts/agreement/endpoints'
import { UPDATE_AGREEMENT_ACTIVATION } from 'state/concepts/agreement/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'

const updateAgreementActivation = createLogic({
  type: UPDATE_AGREEMENT_ACTIVATION,
  latest: true,

  async process({ httpClient, action: { id, isEnable } }, dispatch, done) {
    const { endpoint, url } = updateAgreementActivationEndpoint(id, isEnable)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.agreement${isEnable ? 'Activated' : 'Deactivated'}`,
          },
        }),
      )
      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateAgreementActivation
