import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const currentSubdomain = (state = null, action) => {
  switch (action.type) {
    case types.SET_SUBDOMAIN:
      return action.subdomain
    case types.RESET_SUBDOMAIN:
      return null
    default:
      return state
  }
}

const workspaceId = (state = null, action) => {
  switch (action.type) {
    case types.SET_SUBDOMAIN:
      return action.workspaceId
    case types.RESET_SUBDOMAIN:
      return null
    default:
      return state
  }
}

const subdomainReducer = combineReducers({
  currentSubdomain,
  workspaceId,
})

export default subdomainReducer
