const clientRelationshipTypes = {
  'clientRelationshipTypes.addNewType': 'Add relationship type',
  'clientRelationshipTypes.searchPlaceholder': 'Search by name',
  'clientRelationshipTypes.noSearchResults': 'Try adjusting your search',
  'clientRelationshipTypes.table.name': 'Name',
  'clientRelationshipTypes.table.contactsCount': 'Contacts',
  'clientRelationshipTypes.form.typeInfo': 'Relationship type information',
  'clientRelationshipTypes.form.name.label': 'Relationship type name',
  'clientRelationshipTypes.form.permissions': 'Relationship permissions',
  'clientRelationshipTypes.form.customizePermissions':
    'Customize permissions according to the contactʼs relationship with the _(client).',
  'clientRelationshipTypes.form.clientPortal': '_(Client) portal',
  'clientRelationshipTypes.form.accessClientPortal.label': 'Access _(Client) portal',
  'clientRelationshipTypes.form.accessClientPortal.placeholder': 'e.g., Family member, Spouse',
  'clientRelationshipTypes.form.appointmentsManagement': '_(Appointments) management',
  'clientRelationshipTypes.form.bookAppointment.label': 'Book _(client) _(appointments)',
  'clientRelationshipTypes.form.bookAppointment.labelWarning': ' (requires _(Client) portal access)',
  'clientRelationshipTypes.form.manageAppointments.label': 'Accept, reschedule, and cancel _(client) _(appointments)',
  'clientRelationshipTypes.form.receiveAppointmentNotifications.label':
    'Receive email and SMS _(appointment) notifications and reminders',
  'clientRelationshipTypes.form.clientDocumentation': '_(Client) documentation',
  'clientRelationshipTypes.form.viewDocuments.label': 'View and download {list}',
  'clientRelationshipTypes.form.manageDocuments.label': 'Fill out and sign {list}',
  'clientRelationshipTypes.form.manageDocuments.labelWarning':
    '(requires view access for documents, forms, agreements, and files)',
  'clientRelationshipTypes.form.clientPaymentHistory': '_(Client) payment history',
  'clientRelationshipTypes.form.viewBillings.label': 'View and download _(client) invoices and receipts',
  'clientRelationshipTypes.form.clientFeedback': '_(Client) feedback',
  'clientRelationshipTypes.form.leaveReviews.label': 'Leave reviews',
  'clientRelationshipTypes.form.submitCreate': 'Create type',
  'clientRelationshipTypes.removeModal.title': 'Delete relationship type?',
  'clientRelationshipTypes.removeModal.noClientContacts':
    'There are no contacts marked as {name}, so youʼre good to go.',
  'clientRelationshipTypes.removeModal.clientContactsAssigned':
    'There {clientContactsCount, plural, one {is} other {are}} <span>{clientContactsCount} {clientContactsCount, plural, one {{name} contact} other {{name} contacts}}</span> in your workspace. To continue, please change their relationship type:',
  'clientRelationshipTypes.removeModal.selectNewRelationLabel': 'New relationship type',
  'clientRelationshipTypes.removeModal.selectNewRelationPlaceholder': 'New _(client) relationship type',
  'clientRelationshipTypes.removeModal.submitText': 'Delete relationship type',
  'clientRelationshipTypes.removeModal.notAllowed.title': 'Can’t delete relationship type',
  'clientRelationshipTypes.removeModal.notAllowed.description':
    'To keep adding and managing _(client) contacts, you need to have at least one relationship type in your workspace.',
  'clientRelationshipTypes.clientForms': '_(client) forms',
  'clientRelationshipTypes.documents': 'documents',
  'clientRelationshipTypes.serviceAgreements': '_(service agreements)',
  'clientRelationshipTypes.files': 'files',
}

export default clientRelationshipTypes
