import PropTypes from 'prop-types'
import { either, equals, isNil, reject } from 'ramda'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { currentWorkspaceManualPaymentSelector } from 'state/concepts/session/selectors'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { warningMessageArgs } from 'lib/constants/stripePos'
import { disconnectStripe } from 'state/concepts/paymentSettings/actions'
import { validateDisconnectStripeEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { loadingSelector } from 'state/data/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'

// eslint-disable-next-line react/prefer-stateless-function
class DisconnectPaymentSettingsModal extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isCustomPaymentsEnabled: PropTypes.bool.isRequired,
    manualPayment: PropTypes.shape(),
  }

  static defaultProps = {
    manualPayment: null,
  }

  get paymentMethodsMap() {
    const { isCustomPaymentsEnabled, manualPayment } = this.props

    return reject(either(isNil, equals(false)))([
      manualPayment?.id && 'manualPayments',
      isCustomPaymentsEnabled && 'customPayments',
    ])
  }

  render() {
    return (
      <ConfirmModal
        {...this.props}
        title={{ id: 'paymentSettings.disconnectModal.title' }}
        bodyText={[
          { id: 'paymentSettings.disconnectModal.body' },
          {
            id: `paymentSettings.disconnectStripePosModal.warning`,
            isAlert: true,
            type: 'warning',
            className: 'align-items-center',
            message: {
              id: `paymentSettings.disconnectStripePosModal.warning`,
              values: {
                count: this.paymentMethodsMap.length,
                ...Object.fromEntries(
                  this.paymentMethodsMap.map((value, index) => [
                    warningMessageArgs[index],
                    // eslint-disable-next-line react/no-array-index-key
                    <strong key={`${value}-${index}`} className="font-700">
                      <FormattedMessage id={`paymentSettings.disconnectStripePosModal.${value}`} />
                    </strong>,
                  ]),
                ),
              },
            },
          },
        ]}
        submitText={{ id: 'paymentSettings.disconnect' }}
        kind="danger"
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, validateDisconnectStripeEndpoint.endpoint),
  isCustomPaymentsEnabled: isEnableWorkspaceFeatureSelector(state, FEATURE_KEYS.customPayments),
  manualPayment: currentWorkspaceManualPaymentSelector(state),
})

const mapDispatchToProps = {
  onSubmit: disconnectStripe,
}

export { DisconnectPaymentSettingsModal as DisconnectPaymentSettingsModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DisconnectPaymentSettingsModal)
