import {
  transactionCheckoutRoute,
  transactionMarkAsPaidRoute,
  transactionRoute,
  transactionSendRoute,
  transactionRefundRoute,
  transactionDisableCancellationRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const getPaymentEndpoint = transactionId => endpoint(types.GET_PAYMENT, 'GET', transactionRoute(transactionId))

export const getPaymentCheckoutEndpoint = transactionId =>
  endpoint(types.GET_PAYMENT_CHECKOUT, 'GET', transactionCheckoutRoute(transactionId))

export const markAsPaidPaymentEndpoint = transactionId =>
  endpoint(types.MARK_AS_PAID, 'POST', transactionMarkAsPaidRoute(transactionId))

export const deleteDraftPaymentEndpoint = transactionId =>
  endpoint(types.DELETE_DRAFT_PAYMENT, 'DELETE', transactionRoute(transactionId))

export const sendPaymentEndpoint = transactionId =>
  endpoint(types.SEND_PAYMENT, 'POST', transactionSendRoute(transactionId))

export const updatePaymentEndpoint = transactionId =>
  endpoint(types.UPDATE_PAYMENT, 'PUT', transactionRoute(transactionId))

export const issueRefundEndpoint = transactionId =>
  endpoint(types.ISSUE_REFUND, 'POST', transactionRefundRoute(transactionId))

export const disablePaymentCancellationEndpoint = transactionId =>
  endpoint(types.SEND_PAYMENT, 'POST', transactionDisableCancellationRoute(transactionId))
