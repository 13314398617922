import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const User = ({ size, color, className, dataCy }) => (
  <svg
    preserveAspectRatio="none"
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M512 497.778H0c0-134.4 99.138-199.111 256-199.111s256 64.712 256 199.111zm-256-256c-62.578 0-113.778-51.2-113.778-113.778S193.422 14.222 256 14.222 369.778 65.422 369.778 128 318.578 241.778 256 241.778z" />
  </svg>
)

User.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

User.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default User
