import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_STRIPE_POS } from 'state/concepts/paymentSettings/types'
import { dataApiSuccess } from 'state/data/actions'
import { updateStripePosEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { STRIPE_POS_STATUSES } from 'lib/constants/stripePos'

const updateStripePosOperation = createLogic({
  type: UPDATE_STRIPE_POS,
  latest: true,

  async process({ httpClient, action: { status } }, dispatch, done) {
    const { url, endpoint } = updateStripePosEndpoint

    try {
      const { data } = await httpClient.put(url, { pos_status: status })

      dispatch(dataApiSuccess({ response: normalize(data) }))
      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.stripePosWas${status === STRIPE_POS_STATUSES.enabled ? 'Connected' : 'Disconnected'}`,
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default updateStripePosOperation
