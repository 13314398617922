import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchWorkspace = makeFormSubmitAction(types.FETCH_WORKSPACE)

export const setIsLoggedOutDueToInactivity = isLoggedOutDueToInactivity => ({
  type: types.SET_IS_LOGGED_OUT_DUE_TO_INACTIVITY,
  isLoggedOutDueToInactivity,
})

export const setEmail = email => ({
  type: types.SET_EMAIL,
  email,
})

export const setPassword = password => ({
  type: types.SET_PASSWORD,
  password,
})

export const setClientEmail = email => ({
  type: types.SET_CLIENT_EMAIL,
  email,
})

export const setBackupCodes = backupCodes => ({
  type: types.SET_BACKUP_CODES,
  backupCodes,
})

export const setContactPortalName = name => ({
  type: types.SET_CONTACT_PORTAL_NAME,
  name,
})
