import { equals } from 'ramda'

import { SELECT_MODES } from 'lib/constants/shared'

export const handleDropdownEvent =
  handlerFunc =>
  (e, { name, value }) =>
    handlerFunc({ target: { name, value } })

export const handleInputEvent = (field, handlerFunc, defaultValue) => value =>
  handlerFunc({ target: { name: field.name, value: value !== undefined ? value : defaultValue } })

export const isSelectMultiple = mode => equals(mode, SELECT_MODES.multiple)
