import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Envelope = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 945 768"
    data-cy={dataCy}
  >
    <path d="M874.223 0c39.15 0 70.892 32.236 70.892 72v623.997c0 39.765-31.742 72.003-70.892 72.003H70.777C31.624 768-.115 735.762-.115 695.997V72c0-39.764 31.739-72 70.892-72h803.446zm-23.637 185.664L498.715 423.938a46.657 46.657 0 01-52.432 0L94.401 185.712 94.406 672H850.59l-.006-486.336zM812.588 96H132.354L472.5 326.312 812.588 96z" />
  </svg>
)

Envelope.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Envelope.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Envelope
