import autoSaveResponses from './autoSaveResponses'
import clearSaveResponses from './clearSaveResponses'
import createAttachmentUpload from './createAttachmentUpload'
import declineToSignForm from './declineToSignForm'
import fetchClientProfileForm from './fetchClientProfileForm'
import fetchForm from './fetchForm'
import fetchFormSubmission from './fetchFormSubmission'
import signFormSubmission from './signFormSubmission'
import submitForm from './submitForm'
import updateFormSubmission from './updateFormSubmission'

export default [
  fetchForm,
  submitForm,
  fetchFormSubmission,
  createAttachmentUpload,
  fetchClientProfileForm,
  updateFormSubmission,
  autoSaveResponses,
  signFormSubmission,
  declineToSignForm,
  clearSaveResponses,
]
