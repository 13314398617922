import React from 'react'
import { injectIntl } from 'react-intl'

import TruncatedTextWithTooltipComponent from './component'

class TruncatedTextByWidthTooltip extends React.Component {
  state = {
    isTruncated: false,
  }

  handleTruncate = isTruncated => {
    this.setState({ isTruncated })
  }

  render = () => <TruncatedTextWithTooltipComponent {...this.state} {...this.props} onTruncate={this.handleTruncate} />
}

export { TruncatedTextByWidthTooltip as TruncatedTextByWidthTooltipContainer }
export default injectIntl(TruncatedTextByWidthTooltip)
