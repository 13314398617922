import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import { dataRemoveRelationship } from 'state/data/actions'
import * as types from './types'

export const fetchVideoConferenceDetails = uniqCode => ({
  type: types.FETCH_VIDEO_CONFERENCE_DETAILS,
  uniqCode,
})

export const joinVideoConference = makeFormSubmitAction(types.JOIN_VIDEO_CONFERENCE)

export const leaveVideoConference = (videoConferenceId, participantId) =>
  dataRemoveRelationship({
    kind: 'videoConference',
    ownerId: videoConferenceId,
    relationship: 'participants',
    ids: [participantId],
  })

export const setVideoConference = videoConferenceId => ({
  type: types.SET_VIDEO_CONFERENCE,
  videoConferenceId,
})

export const setIsRecording = isRecording => ({
  type: types.SET_IS_RECORDING,
  isRecording,
})

export const toggleRecording = isRecording => ({
  type: types.TOGGLE_RECORDING,
  isRecording,
})

export const setLocalSettings = settings => ({
  type: types.SET_LOCAL_SETTINGS,
  settings,
})

export const resetLocalSettings = () => ({
  type: types.RESET_LOCAL_SETTINGS,
})

export const setIsPreview = isPreview => ({
  type: types.SET_IS_PREVIEW,
  isPreview,
})

export const setIsScreenShared = isScreenShared => ({
  type: types.SET_IS_SCREEN_SHARED,
  isScreenShared,
})

export const receiveChatMessage = message => ({
  type: types.RECEIVE_CHAT_MESSAGE,
  message,
})

export const clearChatMessages = () => ({
  type: types.CLEAR_CHAT_MESSAGES,
})

export const setVideoRecordEvents = videoRecordEventIds => ({
  type: types.SET_VIDEO_RECORD_EVENTS,
  videoRecordEventIds,
})

export const addVideoRecordEvents = videoRecordEventId => ({
  type: types.ADD_VIDEO_RECORD_EVENTS,
  videoRecordEventId,
})

export const createChatMessage = (uniqCode, message) => ({
  type: types.CREATE_CHAT_MESSAGE,
  uniqCode,
  message,
})

export const setIsSoundTracked = isSoundTracked => ({
  type: types.SET_IS_SOUND_TRACKED,
  isSoundTracked,
})

export const toggleSidebarOpen = value => ({
  type: types.TOGGLE_SIDEBAR_OPEN,
  value,
})

export const isSidebarOpen = () => ({
  type: types.IS_SIDEBAR_OPEN,
})
