import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_BOOKING_SETTINGS } from 'state/concepts/workspaceBookingSettings/types'
import { fetchBookingSettingsEndpoint } from 'state/concepts/workspaceBookingSettings/endpoints'
import { setBookingSettingsId } from '../actions'

const fetchBookingSettingsOperation = createLogic({
  type: FETCH_BOOKING_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchBookingSettingsEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setBookingSettingsId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchBookingSettingsOperation
