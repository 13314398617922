import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { compose, equals, head, not, path, pipe, prop, sortBy } from 'ramda'
import build from 'redux-object'

import { NOTIFICATION_PANEL } from 'lib/constants/notifications'
import { dataSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

export const notificationPanelStatusSelector = path(['notificationPanel', 'notificationPanelStatus'])
export const notificationIdsSelector = path(['notificationPanel', 'notificationIds'])
export const unreadNotificationsCountSelector = path(['notificationPanel', 'unreadNotificationsCount'])

export const isNotificationPanelOpenedSelector = createSelector(
  notificationPanelStatusSelector,
  equals(NOTIFICATION_PANEL.open),
)

export const notificationSelector = createSelector(
  [dataSelector, (_, notificationId) => notificationId],
  (data, notificationId) => build(data, 'notification', notificationId),
)

export const notificationsSelector = createSelector(
  [notificationIdsSelector, dataSelector],
  buildCollection('notification'),
)

export const isUnreadNotificationsSelector = pipe(unreadNotificationsCountSelector, pipe(equals(0), not))

export const lastNotificationIdSelector = createSelector(
  notificationsSelector,
  compose(prop('id'), head, sortBy(compose(DateTime.fromISO, prop('createdAt')))),
)
