const availabilityFeature = {
  'availabilityFeature.subtitle': '<span>in</span> Scheduling',
  'availabilityFeature.description': 'Set your working hours to let _(clients) know your schedule.',
  'availabilityFeature.manageFeatures': 'Set availability',
  'availabilityFeature.overview': 'Availability overview',
  'availabilityFeature.subheader':
    'Let your _(clients) know when you and your team members are available and never worry about getting booked at an inconvenient time.',
  'availabilityFeature.automateScheduling': 'Automate your scheduling:',
  'availabilityFeature.setRegularWorkingHours':
    'Set regular working hours for you and your team members to let _(clients) book available time slots.',
  'availabilityFeature.setSpecificDays':
    'Stay flexible by editing your schedule on specific days: add or remove a few hours of availability, or schedule a day off.',
  'availabilityFeature.manageWorkload': 'Manage your team’s workload:',
  'availabilityFeature.optimizeWorkload':
    'Optimize your team’s workload with easy adjustments to your team members’ schedules.',
  'availabilityFeature.streamlineScheduling': 'Streamline your scheduling and get more _(clients) with less effort.',
  'availabilityFeature.helpCenter':
    'To learn more, read our help center article on <link>setting your working hours</link>.',
  'availabilityFeature.relatedFeatures.title': 'Related features',
  'availabilityFeature.schedulingCards.booking': 'Booking',
  'availabilityFeature.schedulingCards.scheduleAppointments':
    'Schedule _(appointments) with your _(clients), and update or cancel them as needed.',
  'availabilityFeature.schedulingCards.enabled': 'Enabled',
  'availabilityFeature.schedulingCards.disabled': 'Disabled',
  'availabilityFeature.schedulingCards.recurringAppointments': 'Recurring bookings',
  'availabilityFeature.schedulingCards.setRecurringSchedule':
    'Set recurring schedule for your _(clients)’ _(appointments)',
  'availabilityFeature.schedulingCards.upgradePlan': 'Upgrade plan',
  'availabilityFeature.advancedFeature': 'Advanced feature',
}

export default availabilityFeature
