import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_CALENDAR_EVENT } from 'state/concepts/calendarEvent/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setCalendarEvent } from 'state/concepts/calendarEvent/actions'
import { getCalendarEventEndpoint } from 'state/concepts/calendarEvent/endpoints'

const getCalendarEventOperation = createLogic({
  type: GET_CALENDAR_EVENT,
  latest: true,

  async process({ action: { calendarEventId }, httpClient }, dispatch, done) {
    const { url, endpoint } = getCalendarEventEndpoint(calendarEventId)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const normalizedData = normalize(data)

      dispatch(setCalendarEvent(data.data.id))
      dispatch(dataApiSuccess({ response: normalizedData, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getCalendarEventOperation
