import { combineReducers } from '@reduxjs/toolkit'

import {
  RESET_FILTERS,
  RESET_RECURRING_AVAILABILITY,
  SET_FILTER_PARAMS,
  SET_RECURRING_AVAILABILITY,
  SET_TIMEZONE,
} from './types'

const recurringAvailabilityIds = (state = [], action) => {
  switch (action.type) {
    case SET_RECURRING_AVAILABILITY:
      return action.recurringAvailabilityIds
    case RESET_RECURRING_AVAILABILITY:
      return []
    default:
      return state
  }
}

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezone
    default:
      return state
  }
}

const filtersInitialState = {
  locations: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return filtersInitialState
    default:
      return state
  }
}

const recurringAvailabilityReducer = combineReducers({
  recurringAvailabilityIds,
  timezone,
  filters,
})

export default recurringAvailabilityReducer
