const automaticLogout = {
  'automaticLogout.title': 'Automatic log out',
  'automaticLogout.switch.label': 'Automatic log out',
  'automaticLogout.select.label': 'Automatically log me out after',
  'automaticLogout.select.option': '{interval} minutes of inactivity',
  'automaticLogout.description':
    'Log out automatically disconnects you after a period of inactivity. This option helps to prevent unauthorized access by others through open login.',
  'automaticLogout.modal.title': 'Current session will expire in:',
  'automaticLogout.modal.seconds': 'seconds',
  'automaticLogout.modal.stayLoggedIn': 'Stay Logged In',
}

export default automaticLogout
