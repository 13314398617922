import endpoint from 'utils/endpoint'

import { customFieldsRoute, customFieldRoute } from 'lib/apiRoutes'
import { FETCH_CUSTOM_FIELDS, CREATE_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD_PRIORITY } from './types'

export const fetchCustomFieldsEndpoint = endpoint(FETCH_CUSTOM_FIELDS, 'GET', customFieldsRoute)
export const createCustomFieldEndpoint = endpoint(CREATE_CUSTOM_FIELD, 'POST', customFieldsRoute)

export const updateCustomFieldEndpoint = customFieldId =>
  endpoint(UPDATE_CUSTOM_FIELD, 'PUT', customFieldRoute(customFieldId))

export const deleteCustomFieldEndpoint = customFieldId =>
  endpoint(UPDATE_CUSTOM_FIELD, 'DELETE', customFieldRoute(customFieldId))

export const updateCustomFieldPriorityEndpoint = customFieldId =>
  endpoint(UPDATE_CUSTOM_FIELD_PRIORITY, 'PUT', customFieldRoute(customFieldId))
