import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const TickO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0zm0 51.2c-113.108 0-204.799 91.692-204.799 204.799S142.893 460.798 256 460.798c113.108 0 204.799-91.692 204.799-204.799S369.107 51.2 256 51.2zM148.874 248.591c10.764-11.202 28.218-11.202 38.983 0l38.964 40.543 97.323-101.533c10.764-11.202 28.218-11.202 38.983 0s10.764 29.362 0 40.563L246.331 349.998c-10.764 11.202-28.218 11.202-38.983 0l-58.475-60.845c-10.764-11.202-10.764-29.362 0-40.563z" />
  </svg>
)

TickO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'tick-icon',
}

TickO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default TickO
