import classNames from 'clsx'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { is, omit } from 'ramda'
import { forwardRef } from 'react'

import Select from 'views/shared/Select'
import Tooltip from 'views/shared/Tooltip'
import Search from 'views/shared/icons/Search'
import InfoO from 'views/shared/icons/InfoO'
import FormattedOrRawMessage from '../FormattedOrRawMessage'
import InputMessage from '../InputMessage'

const SelectField = forwardRef(
  (
    {
      id,
      disabled,
      label,
      labelWithTooltip,
      labelTooltipText,
      icon,
      field,
      placeholder,
      intl,
      form: { touched, errors },
      getPopupContainer,
      dropdownRender,
      selectClassName,
      onChange,
      showSearchIcon,
      isFocused,
      ...props
    },
    ref,
  ) => {
    const fieldTouched = getIn(touched, field.name)
    const fieldErrors = getIn(errors, field.name)

    return (
      <>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="main-input__label" htmlFor={id}>
            <FormattedOrRawMessage message={label} />
          </label>
        )}
        {labelWithTooltip && (
          <div className="d-flex align-items-center column-gap-4 mb-4">
            <label className="main-input__label mb-0" htmlFor={id}>
              <FormattedOrRawMessage message={labelWithTooltip} />
            </label>
            <Tooltip
              overlayClassName="main-tooltip assigned-experts-list__title-tooltip"
              placement="right"
              align={{ offset: [7, 0] }}
              icon={
                <div className="d-flex align-items-center justify-content-center wh-20-20">
                  <InfoO size={16} className="in-blue-600" />
                </div>
              }
            >
              <FormattedOrRawMessage message={labelTooltipText} />
            </Tooltip>
          </div>
        )}
        <div className="relative">
          {showSearchIcon && (
            <Search
              className={classNames('main-page__search-icon', {
                focused: isFocused,
              })}
              size={14}
            />
          )}
          <Select
            ref={ref}
            id={id}
            name={field.name}
            className={selectClassName}
            placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
            value={field.value}
            disabled={disabled}
            onChange={onChange}
            getPopupContainer={getPopupContainer}
            optionFilterProp="label"
            dropdownRender={dropdownRender}
            {...omit(
              ['loaderProps', 'excludeArray', 'isTouchedOnOpen', 'className', 'actionArgs', 'additionalOptions'],
              props,
            )}
          />
        </div>
        {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
      </>
    )
  },
)

SelectField.displayName = 'SelectField'

SelectField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  labelWithTooltip: null,
  labelTooltipText: null,
  icon: null,
  placeholder: null,
  selectClassName: '',
  showSearchIcon: false,
}

SelectField.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
    PropTypes.string,
  ]),
  labelWithTooltip: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
    PropTypes.string,
  ]),
  labelTooltipText: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  getPopupContainer: PropTypes.func.isRequired,
  dropdownRender: PropTypes.func.isRequired,
  selectClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showSearchIcon: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
}

export default SelectField
