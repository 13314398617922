import { Select as AntdSelect } from 'antd'
import classNames from 'clsx'
import PropTypes from 'prop-types'
import { has, omit } from 'ramda'
import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import CircleCancel from 'views/shared/icons/CircleCancel'
import ArrowDown from 'views/shared/icons/ArrowDown'

const Select = forwardRef(
  (
    {
      className,
      options,
      children,
      transitionName,
      emptyText,
      defaultClassName,
      removeIconSize,
      removeIconClassName,
      ...props
    },
    ref,
  ) => {
    const { formatMessage } = useIntl()
    // To prevent "React does not recognize the `propName` prop on a DOM element" warning
    const restProps = omit(
      [
        'excludeServices',
        'selectedServices',
        'searchServices',
        'mountToElement',
        'withAvatar',
        'excludeEmployees',
        'selectedEmployees',
        'searchEmployees',
        'isLoading',
        'userProfileId',
        'serviceId',
        'selectedClients',
        'getClients',
        'noAssignedExpert',
        'handleServiceCreateCallback',
        'fetchServices',
        'statuses',
        'hideEmail',
        'isBlankState',
        'searchLocations',
        'selectedLocations',
        'onBlur',
        'currentEmployee',
      ],
      props,
    )

    return (
      <AntdSelect
        virtual={false}
        transitionName={transitionName}
        ref={ref}
        className={classNames(defaultClassName, className)}
        optionFilterProp="label"
        notFoundContent={formatMessage(emptyText)}
        removeIcon={
          <div className="d-flex align-items-center justify-content-center wh-20-20">
            <CircleCancel size={removeIconSize} color="#fff" className={removeIconClassName} />
          </div>
        }
        suffixIcon={<ArrowDown size={10} />}
        {...restProps}
      >
        {children ||
          options.map(({ label, value, key, content, className: optionLabelClassname, title }) => {
            const formattedLabel = has('id', label) ? formatMessage(label, label.values) : label

            return (
              <AntdSelect.Option
                title={title || formattedLabel}
                label={formattedLabel}
                value={value}
                key={key}
                className={optionLabelClassname}
              >
                {content || formattedLabel}
              </AntdSelect.Option>
            )
          })}
      </AntdSelect>
    )
  },
)

Select.displayName = 'Select'

Select.defaultProps = {
  options: [],
  children: null,
  className: '',
  transitionName: '',
  emptyText: { id: 'shared.noSearchResult' },
  defaultClassName: 'main-input__field',
  removeIconSize: 16,
  removeIconClassName: '',
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.shape(),
        PropTypes.bool,
        PropTypes.number,
      ]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node).isRequired, PropTypes.node]),
  className: PropTypes.string,
  transitionName: PropTypes.string,
  emptyText: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  defaultClassName: PropTypes.string,
  removeIconSize: PropTypes.number,
  removeIconClassName: PropTypes.string,
}

export default Select
