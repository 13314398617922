import * as types from './types'

export const fetchClients = () => ({
  type: types.FETCH_CLIENTS,
})

export const setClients = clientIds => ({
  type: types.SET_CLIENTS,
  clientIds,
})

export const removeClients = clientIds => ({
  type: types.REMOVE_CLIENTS,
  clientIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_CLIENTS_BLANK_STATE,
  isBlankState,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_CLIENTS_PAGE,
  pageNumber,
})

export const setSortOrder = sortKey => ({
  type: types.SET_CLIENTS_SORT_ORDER,
  sortKey,
})

export const filterClients = filters => ({
  type: types.SET_CLIENTS_FILTER,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_CLIENTS_FILTER_PARAMS,
  filterParams,
})

export const incrementTotalCount = () => ({
  type: types.INCREMENT_TOTAL_COUNT,
})

export const decrementTotalCount = () => ({
  type: types.DECREMENT_TOTAL_COUNT,
})

export const setTotalCount = count => ({
  type: types.SET_TOTAL_COUNT,
  count,
})
