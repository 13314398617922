import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  deactivateServiceCategories as deactivateServiceCategoriesAction,
  setSelectedServiceCategories as setSelectedServiceCategoriesAction,
} from 'state/concepts/serviceCategories/actions'
import { deactivateServiceCategoriesLoadingSelector } from 'state/concepts/serviceCategories/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServiceCategoriesBulkDeactivateModal = ({ serviceCategoriesIds, onClose }) => {
  const isLoading = useSelector(deactivateServiceCategoriesLoadingSelector)
  const deactivateServiceCategories = useDispatchAction(deactivateServiceCategoriesAction, serviceCategoriesIds)
  const setSelectedServiceCategories = useDispatchAction(setSelectedServiceCategoriesAction)

  const handleSubmit = () => {
    deactivateServiceCategories()
    setSelectedServiceCategories([])
  }

  return (
    <ConfirmModal
      title={{ id: 'serviceCategories.bulkDeactivate.modal.title' }}
      bodyText={[
        [
          {
            id: 'serviceCategories.bulkDeactivate.modal.body.start',
          },
          {
            id: 'serviceCategories.bulkDeactivate.modal.body.boldText',
            values: { count: serviceCategoriesIds.length },
            isBold: true,
          },
          {
            id: 'serviceCategories.bulkDeactivate.modal.body.final',
          },
        ],
        {
          id: 'serviceCategories.bulkDeactivate.modal.allChildServices',
        },
        {
          id: 'serviceCategories.bulkDeactivate.modal.activeBookings',
        },
      ]}
      submitText={{ id: 'shared.deactivate' }}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServiceCategoriesBulkDeactivateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategoriesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ServiceCategoriesBulkDeactivateModal
