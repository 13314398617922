import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CLIENT_FEED } from 'state/concepts/companyClientFeed/types'
import { fetchClientFeedEndpoint } from 'state/concepts/companyClientFeed/endpoints'
import { addClientFeedIds } from 'state/concepts/companyClientFeed/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'

const fetchClientFeedOperation = createLogic({
  type: FETCH_CLIENT_FEED,
  latest: true,

  async process(
    {
      httpClient,
      getState,
      action: {
        clientId,
        pageParams: { page = 1 },
      },
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const { timezone } = currentEmployeeSelector(state)
    const { endpoint, url } = fetchClientFeedEndpoint(clientId)

    const params = {
      page: { number: page, size: 20 },
      include: [
        'source.service',
        'source.booking-setting',
        'source.booking-recurrence',
        'source.service-category',
        'source.client-profile',
        'source.client-relationship-type',
        'source.contact-client-profile',
        'source.user-profile',
        'source.reschedule',
        'source.video-records',
        'source.workspace',
        'source.review',
        'source.assignee',
        'source.form',
        'source.form-submission.submitted-by.client-relationship-type',
        'source.form-submission.submitted-by.contact-client-profile',
        'source.reviewed-by.client-relationship-type',
        'source.reviewed-by.contact-client-profile',
        'source.form-submission.client-signature.signed-by.contact-client-profile',
        'source.form-submission.client-signature.signed-by.client-relationship-type',
        'source.declined-by.client-relationship-type',
        'source.declined-by.contact-client-profile',
        'source.document_permissions',
        'source.document-signatories',
        'source.document-signatories.signature',
        'source.document-signatories.profileable',
        'source.waitlist-request',
        'source.availability-preferences',
        'source.business-storage',
      ],
      filter: { timezone },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addClientFeedIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchClientFeedOperation
