import ceil from 'lodash/ceil'
import { last, split, prop, compose, toLower } from 'ramda'

import {
  DURATION_PARSABLE_MIME_TYPES,
  MAX_FILE_CHUNK_SIZE,
  MIME_TYPES,
  PLAYABLE_VIDEO_MIME_TYPES,
} from 'lib/constants/file'

export const isPDF = mimeType => MIME_TYPES.pdf.includes(mimeType)
export const isVideo = mimeType => MIME_TYPES.video.includes(mimeType)
export const isAudio = mimeType => MIME_TYPES.audio.includes(mimeType)
export const isPicture = mimeType => MIME_TYPES.image.includes(mimeType)
export const isPlayableVideo = mimeType => PLAYABLE_VIDEO_MIME_TYPES.includes(mimeType)
export const isPreviewable = mimeType => isPicture(mimeType) || isPlayableVideo(mimeType)
export const isPreviewableFiles = mimeType =>
  isPicture(mimeType) || isPlayableVideo(mimeType) || isAudio(mimeType) || isPDF(mimeType)
export const isDurationParsable = mimeType => DURATION_PARSABLE_MIME_TYPES.audio.includes(mimeType)

export const fileSize = bytes => {
  const kilobytes = bytes / 1024
  const megabytes = bytes / 1024 / 1024
  const gigabytes = bytes / 1024 / 1024 / 1024

  if (gigabytes >= 1) {
    return `${ceil(gigabytes, 2)} GB`
  }
  if (megabytes >= 1) {
    return `${ceil(megabytes, 2)} MB`
  }
  if (kilobytes >= 1) {
    return `${ceil(kilobytes, 2)} KB`
  }

  return `${ceil(bytes, 2)} B`
}

export const fileExtension = compose(toLower, last, split('.'), prop('name'))
export const getBlobFromDataUrl = async dataUrl => {
  const response = await fetch(dataUrl)
  const blob = await response.blob()
  return blob
}

export const getFileUploadInfo = (file, maxChunkSize = MAX_FILE_CHUNK_SIZE) => ({
  name: file.name,
  number_of_parts: Math.ceil(file.size / maxChunkSize),
})

export const sliceFileIntoChunks = (file, maxChunkSize = MAX_FILE_CHUNK_SIZE) => {
  const fileChunks = []
  let start = 0

  if (file.size <= maxChunkSize) return [file]

  while (start < file.size) {
    const end = Math.min(start + maxChunkSize, file.size)
    const chunk = file.slice(start, end)
    fileChunks.push(chunk)
    start = end
  }

  return fileChunks
}
