const namespace = 'clientAccountDocuments'

export const FETCH_FORMS = `${namespace}/FETCH_FORMS`
export const SET_FORM_IDS = `${namespace}/SET_FORM_IDS`
export const RESET_DOCUMENTS = `${namespace}/RESET_DOCUMENTS`
export const FETCH_FILES = `${namespace}/FETCH_FILES`
export const FILTER_FILES = `${namespace}/FILTER_FILES`
export const SET_FILES_FILTER_PARAMS = `${namespace}/SET_FILES_FILTER_PARAMS`
export const VIEW_FILES = `${namespace}/VIEW_FILES`
export const SET_FILE_IDS = `${namespace}/SET_FILE_IDS`
export const ADD_FILE_IDS = `${namespace}/ADD_FILE_IDS`
export const SET_FORM_IS_BLANK_STATE = `${namespace}/SET_FORM_IS_BLANK_STATE`
export const SET_FORM_CURRENT_PAGE = `${namespace}/SET_FORM_CURRENT_PAGE`
export const SET_FILES_CURRENT_PAGE = `${namespace}/SET_FILES_CURRENT_PAGE`
export const SET_DOCUMENTS_CURRENT_PAGE = `${namespace}/SET_DOCUMENTS_CURRENT_PAGE`
export const ADD_FORM_IDS = `${namespace}/ADD_FORM_IDS`
export const RECEIVE_GUEST_FORM = `${namespace}/RECEIVE_GUEST_FORM`
export const REMOVE_FORM_ID = `${namespace}/REMOVE_FORM_ID`
export const RECEIVE_ACCOUNT_FORM = `${namespace}/RECEIVE_ACCOUNT_FORM`
export const FETCH_DOCUMENTS = `${namespace}/FETCH_DOCUMENTS`
export const SET_DOCUMENT_IDS = `${namespace}/SET_DOCUMENT_IDS`
export const FILTER_DOCUMENTS = `${namespace}/FILTER_DOCUMENTS`
export const SET_DOCUMENTS_FILTER_PARAMS = `${namespace}/SET_DOCUMENTS_FILTER_PARAMS`
export const SET_DOCUMENTS_BLANK_STATE = `${namespace}/SET_DOCUMENTS_BLANK_STATE`
export const ADD_DOCUMENT_IDS = `${namespace}/ADD_DOCUMENT_IDS`
export const FETCH_MY_FILES = `${namespace}/FETCH_MY_FILES`
export const FILTER_MY_FILES = `${namespace}/FILTER_MY_FILES`
export const SET_MY_FILES_FILTER_PARAMS = `${namespace}/SET_MY_FILES_FILTER_PARAMS`
export const SET_MY_FILE_IDS = `${namespace}/SET_MY_FILE_IDS`
export const ADD_MY_FILE_IDS = `${namespace}/ADD_MY_FILE_IDS`
export const REMOVE_MY_FILE_ID = `${namespace}/REMOVE_MY_FILE_ID`
export const DELETE_MY_FILE = `${namespace}/DELETE_MY_FILE`
export const SET_MY_FILES_CURRENT_PAGE = `${namespace}/SET_MY_FILES_CURRENT_PAGE`
export const SET_FILES_BLANK_STATE = `${namespace}/SET_FILES_BLANK_STATE`
export const SET_MY_FILES_BLANK_STATE = `${namespace}/SET_MY_FILES_BLANK_STATE`
