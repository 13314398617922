import { createLogic } from 'redux-logic'

import { companySettingsClientRelationshipTypesRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { updateClientRelationshipTypeEndpoint } from '../endpoints'
import { UPDATE_CLIENT_RELATIONSHIP_TYPE } from '../types'

const updateClientRelationshipTypeOperation = createLogic({
  type: UPDATE_CLIENT_RELATIONSHIP_TYPE,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const {
      id,
      name,
      accessClientPortal,
      bookAppointment,
      manageAppointments,
      receiveAppointmentNotifications,
      viewDocuments,
      manageDocuments,
      viewBillings,
      leaveReviews,
    } = values
    const { url, endpoint } = updateClientRelationshipTypeEndpoint(id)
    const state = getState()
    const workspace = currentWorkspaceCodeSelector(state)

    const params = {
      name,
      access_client_portal: accessClientPortal,
      book_appointment: bookAppointment,
      manage_appointments: manageAppointments,
      receive_appointment_notifications: receiveAppointmentNotifications,
      view_documents: viewDocuments,
      manage_documents: manageDocuments,
      view_billings: viewBillings,
      leave_reviews: leaveReviews,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, params)

      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.clientRelationshipUpdated',
          },
        }),
      )
      redirect({ href: companySettingsClientRelationshipTypesRoute, workspace })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updateClientRelationshipTypeOperation
