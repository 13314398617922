import React from 'react'

import RepeatSectionComponent from './component'

class RepeatSection extends React.Component {
  datePickerCellDisabled = ({ date }) => new Date().toDateString() !== date.toDateString() && new Date() - date > 0

  render() {
    return <RepeatSectionComponent {...this.props} datePickerCellDisabled={this.datePickerCellDisabled} />
  }
}

export default RepeatSection
