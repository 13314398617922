import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { ACTIVATION_STATUSES } from 'lib/constants/formCategories'
import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import { addSearchResults, resetSearchResults, setBlankState } from 'state/searchResults/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchFormCategoriesEndpoint } from '../endpoints'
import { SEARCH_FORM_CATEGORIES } from '../types'

const searchFormCategoriesOperation = createLogic({
  type: SEARCH_FORM_CATEGORIES,
  latest: true,

  async process({ httpClient, action: { resetResults, filter } }, dispatch, done) {
    const { url, endpoint } = fetchFormCategoriesEndpoint
    const defaultFilter = { status: ACTIVATION_STATUSES.active }
    const params = {
      page: {
        size: 100,
        number: 1,
      },
      filter: filter || defaultFilter,
    }
    dispatch(dataApiRequest({ endpoint }))

    try {
      if (resetResults) {
        dispatch(resetSearchResults(SEARCH_RESULTS_ENTITIES.formCategories))
      }
      const { data } = await httpClient.get(url, { params })
      const ids = data.data.map(item => item.id)
      const response = normalize(data, { endpoint })

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.formCategories]: isBlankState({ params: {}, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.formCategories]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default searchFormCategoriesOperation
