import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Modal from 'views/shared/Modal'

import { ALERT_KINDS } from 'lib/constants'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const CalendarDisconnectModal = ({ handleSubmit, onClose, isLoading, alertMessage }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="googleCalendarConnectionRemoveModal.removeConnection" />
        </h2>
        <button data-cy="cross-icon" type="button" onClick={onClose} className="main-modal__close">
          <Cross />
        </button>
      </div>
      <div className="main-modal__body">
        <p>
          <FormattedMessage id="googleCalendarConnectionRemoveModal.areYouSure" />
        </p>
        {alertMessage && (
          <Alert className="align-items-center mt-16" type={ALERT_KINDS.warning} message={alertMessage} iconSize={22} />
        )}
      </div>
      <div className="main-modal__footer">
        <div>
          <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
          <Button
            className="main-modal__footer-action"
            type="submit"
            text={{ id: 'calendarSync.disconnect' }}
            onClick={handleSubmit}
            disabled={isLoading}
            kind="danger"
          />
        </div>
      </div>
    </div>
  </Modal>
)

CalendarDisconnectModal.defaultProps = {
  isLoading: undefined,
  alertMessage: undefined,
}

CalendarDisconnectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  alertMessage: PropTypes.shape(),
}

export default CalendarDisconnectModal
