import * as types from './types'

export const removeRecentWorkspace = workspaceId => ({
  type: types.REMOVE_RECENT_WORKSPACE,
  workspaceId,
})

export const loginToRecentWorkspace = workspace => ({
  type: types.LOGIN_TO_RECENT_WORKSPACE,
  workspace,
})

export const updateRecentWorkspace = workspace => ({
  type: types.UPDATE_RECENT_WORKSPACE,
  workspace,
})

export const addRecentWorkspace = (currentEmployee, tokens) => ({
  type: types.ADD_RECENT_WORKSPACE,
  currentEmployee,
  tokens,
})

export const fetchWorkspace = url => ({
  type: types.FETCH_WORKSPACE,
  url,
})

export const setRecentWorkspaceTime = workspaceId => ({
  type: types.SET_RECENT_WORKSPACE_TIME,
  workspaceId,
})
