import { createLogic } from 'redux-logic'
import { v4 as uuidv4 } from 'uuid'
import { values } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import isNetworkError from 'utils/isNetworkError'
import { CREATE_MESSAGE } from 'state/concepts/chat/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import {
  createMessageOptimistic,
  addSendingMessage,
  removeSendingMessage,
  addResendingMessage,
  removeResendingMessage,
  markAllMessagesAsRead,
  setOffline,
  resetUploadToAttachmentLinks,
} from 'state/concepts/chat/actions'
import { uploadLinksSelector } from 'state/concepts/chat/selectors'
import { createChatMessageEndpoint } from 'state/concepts/chat/endpoints'

const createMessageOperation = createLogic({
  type: CREATE_MESSAGE,

  async process({ action: { chatId, text, messageUuid }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = createChatMessageEndpoint(chatId)
    const id = messageUuid || uuidv4()
    const attachmentIds = values(uploadLinksSelector(getState()))
    const params = {
      id,
      text,
      attachment_ids: attachmentIds,
    }

    dispatch(addSendingMessage(id))
    dispatch(createMessageOptimistic({ id, chatId, text, attachmentIds }))
    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(markAllMessagesAsRead(chatId))
      dispatch(resetUploadToAttachmentLinks())
      dispatch(removeSendingMessage(id))
      dispatch(removeResendingMessage(id))
    } catch (error) {
      if (isNetworkError(error)) {
        dispatch(setOffline())
        dispatch(addResendingMessage(id))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default createMessageOperation
