import { createLogic } from 'redux-logic'

import isStripePaymentError from 'utils/stripe/isStripePaymentError'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { removeRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { hideModal, showModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { employeePostLogout } from 'state/concepts/session/actions'
import { removeCompanyProfileEndpoint } from 'state/concepts/company/endpoints'
import { currentWorkspaceIdSelector } from 'state/concepts/session/selectors'
import { REMOVE_COMPANY_PROFILE } from 'state/concepts/company/types'

const removeCompanyProfileOperation = createLogic({
  type: REMOVE_COMPANY_PROFILE,
  latest: true,

  async process({ httpClient, getState, action: { values, form, debtsTotal } }, dispatch, done) {
    const { url, endpoint } = removeCompanyProfileEndpoint
    const currentWorkspaceId = currentWorkspaceIdSelector(getState())
    const params = {
      password: values.password,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.delete(url, { params })

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.workspaceHasBeenDeleted' } }))
      dispatch(hideModal())
      dispatch(employeePostLogout())
      dispatch(removeRecentWorkspace(currentWorkspaceId))
    } catch (error) {
      if (isStripePaymentError(error)) {
        dispatch(hideModal())
        dispatch(
          showModal({
            modalType: 'COMPANY_IS_NOT_DELETED_MODAL',
            modalProps: { debtsTotal },
          }),
        )
      }
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default removeCompanyProfileOperation
