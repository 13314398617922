import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Video = ({ color, className, dataCy, height, width, size }) => (
  <svg
    width={`${width || size}px`}
    height={`${height || size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M112.154 162.133c-55.124 0-99.957 44.835-99.957 99.957v499.803c0 55.124 44.835 99.957 99.957 99.957h549.785c55.124 0 99.957-44.835 99.957-99.957v-145.84l168.688 134.906c9.044 7.193 20.096 10.933 31.243 10.933 7.347 0 14.773-1.683 21.67-4.975 17.339-8.292 28.313-25.813 28.313-45.003v-399.845c0-19.19-10.96-36.758-28.313-45.104-17.297-8.243-37.917-5.942-52.909 6.056l-168.688 134.907v-145.84c0-55.124-44.835-99.957-99.957-99.957h-549.785z" />
  </svg>
)

Video.defaultProps = {
  size: 16,
  height: undefined,
  width: undefined,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Video.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Video
