import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Modal from 'views/shared/Modal'

import { hideModal } from 'state/modal/actions'
import Button from 'views/shared/Button'
import AlertO from 'views/shared/icons/AlertO'
import Cross from 'views/shared/icons/Cross'

const ErrorUploadModal = ({ onClose, onSelectImage }) => (
  <Modal className="main-modal" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="uploadLogo.uploadError" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <div className="text-center">
          <AlertO className="mb-16 main-modal__alert-icon" size={72} />
          <p className="mb-0">
            <FormattedMessage id="uploadLogo.formatSizeError" />
          </p>
        </div>
      </div>
      <div className="main-modal__footer">
        <Button className="main-modal__footer-action" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
        <input
          id="avatar"
          className="d-none"
          type="file"
          name="avatar"
          accept="image/jpeg,image/png"
          onChange={onSelectImage}
        />
        <Button
          className="main-modal__footer-action"
          htmlFor="avatar"
          text={{ id: 'uploadLogo.selectImage' }}
          isLabel
        />
      </div>
    </div>
  </Modal>
)

ErrorUploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  onClose: hideModal,
}

export { ErrorUploadModal as ErrorUploadModalContainer }
export default connect(null, mapDispatchToProps)(ErrorUploadModal)
