import { indexOf, isEmpty, map, min, prop, reduce } from 'ramda'

import { DATA_SCROLL_CONTAINER, DATA_SCROLL_ELEMENT } from 'lib/constants'
import { CALENDAR_HEADER, CALENDAR_ROW, DEFAULT_SCROLL_TO_TIME } from 'lib/constants/calendar'
import { MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'

const scrollToIndex = (availabilities, step) => {
  const earliestSlot = reduce(min, Infinity, map(prop('start'), availabilities))

  return earliestSlot / step / SECONDS_PER_MINUTE
}

export const isScrollActive = (availabilities, step) => {
  const slotElement = document.querySelectorAll(`.${CALENDAR_ROW}`)[scrollToIndex(availabilities, step)]
  const headerElement = document.querySelector(`.${CALENDAR_HEADER}`)

  const slotTop = slotElement.getBoundingClientRect().top
  const headerTop = headerElement.getBoundingClientRect().bottom

  return { scrollBackActive: slotTop < headerTop }
}

export const getStickyContainer = () => document.querySelector(`[data-scroll=${DATA_SCROLL_CONTAINER}]`)

export const getStickyElements = () => {
  const stickyElements = document.querySelectorAll(`[data-scroll=${DATA_SCROLL_ELEMENT}]`)
  let totalStickyHeight = 0
  let expandedStickyElements = []

  if (stickyElements) {
    expandedStickyElements = reduce(
      (acc, el) => {
        const elByIndex = stickyElements[el.dataset.scrollHeightElementIndex]
        const top = elByIndex ? elByIndex.getBoundingClientRect().height : totalStickyHeight

        acc[indexOf(el, stickyElements)] = {
          node: el,
          top,
        }

        if (!elByIndex) {
          totalStickyHeight += el.getBoundingClientRect().height
        }

        return acc
      },
      [],
      stickyElements,
    )
  }

  return { totalStickyHeight, stickyElements: expandedStickyElements }
}

export const distanceBetweenContainerAndElement = (element, scrollContainer) => {
  if (!element) {
    return 0
  }

  const { totalStickyHeight } = getStickyElements()
  const scrollContainerTop = scrollContainer.getBoundingClientRect().top
  const scrollToNodeTop = element.getBoundingClientRect().top
  const visibleDistance = scrollToNodeTop - scrollContainerTop
  const totalDistance = scrollContainer.scrollTop + visibleDistance

  return totalDistance - totalStickyHeight
}

export const scrollToElement = (availabilities, step) => {
  const scrollContainer = getStickyContainer()

  if (!scrollContainer) return

  let scrollToNode = null
  const slotsPerHour = MINUTES_PER_HOUR / step
  const defaultScrollTo = slotsPerHour * DEFAULT_SCROLL_TO_TIME

  if (isEmpty(availabilities)) {
    scrollToNode = document.querySelectorAll(`.${CALENDAR_ROW}`)[defaultScrollTo]

    scrollContainer.scrollTop = distanceBetweenContainerAndElement(scrollToNode, scrollContainer)

    return
  }

  const scrollIndex = scrollToIndex(availabilities, step)
  const rowElements = document.querySelectorAll(`.${CALENDAR_ROW}`)

  if (scrollIndex < slotsPerHour) {
    scrollToNode = rowElements[scrollIndex]
  } else {
    scrollToNode = rowElements[scrollIndex - slotsPerHour]
  }

  scrollContainer.scrollTop = distanceBetweenContainerAndElement(scrollToNode, scrollContainer)
}
