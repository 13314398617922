import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { CancelToken, isCancel } from 'axios'

import progressCallback from 'utils/progressCallback'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setUploadProgress, setClientImportId } from 'state/concepts/companyClientImport/actions'
import { UPLOAD_FILE, CANCEL_UPLOAD_FILE } from 'state/concepts/companyClientImport/types'
import { uploadClientImportEndpoint } from 'state/concepts/companyClientImport/endpoints'

const uploadFileOperation = createLogic({
  type: UPLOAD_FILE,
  latest: true,
  warnTimeout: 0,

  async process(
    {
      action: {
        form,
        values: { file },
      },
      httpClient,
      action$,
    },
    dispatch,
    done,
  ) {
    const { endpoint, url } = uploadClientImportEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = new FormData()
      params.append('file', file)

      const source = CancelToken.source()
      action$.subscribe(newAction => {
        if (newAction.type === CANCEL_UPLOAD_FILE) {
          source.cancel()
        }
      })

      const { data } = await httpClient.post(url, params, {
        onUploadProgress: progressCallback(progress => dispatch(setUploadProgress(progress))),
        cancelToken: source.token,
      })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setClientImportId(data.data.id))
    } catch (error) {
      requestErrorHandler({ form, error, dispatch, endpoint, skipHandlers: isCancel(error) })
    }

    form.setSubmitting(false)
    dispatch(setUploadProgress(null))
    done()
  },
})

export default uploadFileOperation
