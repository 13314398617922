import PropTypes from 'prop-types'
import classNames from 'clsx'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'

const InputText = ({ date, formatDate, placeholder }) => (
  <span className={classNames('mr-8 calendar-date', { 'in-gray-700': !date })}>
    <FormattedOrRawMessage message={date ? formatDate(date) : placeholder} />
  </span>
)

InputText.defaultProps = {
  formatDate: date => date.toFormat('MMMM d, yyyy'),
  date: null,
}

InputText.propTypes = {
  date: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]),
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  formatDate: PropTypes.func,
}

export default InputText
