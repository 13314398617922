import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Authentication = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512.003 0C347.968 0 219.428 128.539 219.428 292.575v48.762c-53.638 0-97.521 43.884-97.521 97.521v487.621c0 53.638 43.884 97.521 97.521 97.521h585.144c53.638 0 97.521-43.884 97.521-97.521V438.858c0-53.638-43.884-97.521-97.521-97.521v-48.762C804.572 128.54 676.033 0 511.997 0zm0 97.523c109.373 0 195.046 85.672 195.046 195.046v48.762H316.95v-48.762c0-109.373 85.672-195.046 195.046-195.046zM219.428 438.861h585.144v487.621H219.428V438.861zm97.53 195.042a48.768 48.768 0 0 0-48.761 48.761c0 12.931 5.139 25.337 14.283 34.478s21.545 14.283 34.478 14.283a48.768 48.768 0 0 0 48.761-48.761c0-12.931-5.139-25.337-14.283-34.478s-21.545-14.283-34.478-14.283zm195.045 0a48.766 48.766 0 0 0-48.761 48.761 48.766 48.766 0 0 0 48.761 48.761 48.766 48.766 0 0 0 48.761-48.761 48.766 48.766 0 0 0-48.761-48.761zm195.046 0a48.766 48.766 0 0 0-48.761 48.761 48.766 48.766 0 0 0 48.761 48.761 48.766 48.766 0 0 0 48.761-48.761 48.766 48.766 0 0 0-48.761-48.761z" />
  </svg>
)

Authentication.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Authentication.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Authentication
