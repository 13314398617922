import React from 'react'

import { MAIN_SITE_LINK } from 'lib/constants'
import currentWorkspaceCode from 'utils/workspace/currentWorkspaceCode'
import checkIsClientLoggedIn from 'utils/client/isClientLoggedIn'
import { companyBookingsRoute, clientBookingsRoute } from 'lib/routes'
import LogoComponent from './component'

class Logo extends React.Component {
  state = {
    workspaceCode: null,
    isClientLoggedIn: null,
  }

  componentDidMount() {
    this.setState({
      workspaceCode: currentWorkspaceCode(),
      isClientLoggedIn: checkIsClientLoggedIn(),
    })
  }

  get getUrlOptions() {
    const { isClientLoggedIn, workspaceCode } = this.state

    if (isClientLoggedIn) {
      return { href: clientBookingsRoute(workspaceCode) }
    }

    if (workspaceCode) {
      return { href: `/${workspaceCode}${companyBookingsRoute}` }
    }

    return { target: '_blank', rel: 'noopener noreferrer', href: MAIN_SITE_LINK, passHref: true }
  }

  render() {
    return <LogoComponent urlOptions={this.getUrlOptions} {...this.props} />
  }
}

export default Logo
