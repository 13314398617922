import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Spinner from 'views/shared/icons/Spinner'

const NotFoundContent = ({
  isLoading,
  isOwnerOrAdminDoNotHaveServices,
  isExpertDoesNotHaveServices,
  onRedirectToCreateService,
}) => {
  if (isLoading !== false) return <Spinner size={24} />

  if (isExpertDoesNotHaveServices) return <FormattedMessage id="services.assignExpertDropdown.expert" />

  if (isOwnerOrAdminDoNotHaveServices) {
    return (
      <FormattedMessage
        id="services.assignExpertDropdown.ownerOrAdmin.thereAreNo"
        values={{
          addServiceLink: (
            <button className="main-link" onClick={onRedirectToCreateService}>
              <FormattedMessage id="services.assignExpertDropdown.ownerOrAdmin.addService" />
            </button>
          ),
        }}
      />
    )
  }

  return <FormattedMessage id="shared.noResultsFound" />
}

NotFoundContent.propTypes = {
  isLoading: PropTypes.bool,
  isOwnerOrAdminDoNotHaveServices: PropTypes.bool.isRequired,
  isExpertDoesNotHaveServices: PropTypes.bool.isRequired,
  onRedirectToCreateService: PropTypes.func.isRequired,
}

NotFoundContent.defaultProps = {
  isLoading: undefined,
}

export default NotFoundContent
