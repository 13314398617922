import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { isEmpty } from 'ramda'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { GET_SERVICE_CATEGORIES } from 'state/concepts/serviceCategories/types'
import { setSearchResults, setBlankState } from 'state/searchResults/actions'
import { getServiceCategoriesEndpoint } from '../endpoints'

const getServiceCategoriesOperation = createLogic({
  type: GET_SERVICE_CATEGORIES,
  latest: true,

  async process({ httpClient, action: { status } }, dispatch, done) {
    const params = {
      page: { size: 100, number: 1 },
      filter: {
        status,
      },
      sort: 'name',
    }
    const { endpoint, url } = getServiceCategoriesEndpoint

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const ids = data.data.map(item => item.id)

      dispatch(setBlankState({ [SEARCH_RESULTS_ENTITIES.serviceCategories]: isEmpty(ids) }))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setSearchResults({ [SEARCH_RESULTS_ENTITIES.serviceCategories]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default getServiceCategoriesOperation
