import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import { DAYS_OF_WEEK_FIELD_OPTIONS } from 'lib/constants/bookings'
import Tooltip from 'views/shared/Tooltip'
import DaysCheckboxField from './DaysCheckboxField'

const DaysCheckboxGroupFieldComponent = ({ isChecked, onChange, ...rest }) => (
  <>
    {DAYS_OF_WEEK_FIELD_OPTIONS.map(({ value, label, tooltipId }, index) => (
      <Tooltip
        key={value}
        overlayClassName="main-tooltip"
        placement="top"
        mouseEnterDelay={0.3}
        on={['hover']}
        align={{
          offset: [0, 14],
        }}
        icon={
          <div className="select-days" data-cy="weekday-selector">
            <Field
              {...rest}
              id={`byWeekday.${index}`}
              name={`byWeekday.${index}`}
              labelClassName="select-days__label"
              label={label}
              checked={isChecked(value)}
              onChange={onChange(value)}
              component={DaysCheckboxField}
            />
          </div>
        }
      >
        <FormattedMessage id={tooltipId} />
      </Tooltip>
    ))}
  </>
)

DaysCheckboxGroupFieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
}

export default DaysCheckboxGroupFieldComponent
