import PropTypes from 'prop-types'
import Modal from 'views/shared/Modal'
import { FormattedMessage } from 'react-intl'

import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const EmployeesErrorModal = ({ onClose, error }) => (
  <Modal className="main-modal" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="employeesErrorModal.removeError" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body">
        <p className="mb-0">{error}</p>
      </div>
      <div className="main-modal__footer">
        <Button className="main-modal__footer-action" type="submit" text={{ id: 'shared.ok' }} onClick={onClose} />
      </div>
    </div>
  </Modal>
)

EmployeesErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
}

export default EmployeesErrorModal
