import { createLogic } from 'redux-logic'
import { CardNumberElement } from '@stripe/react-stripe-js'

import { transactionCheckoutPaymentIntentRoute } from 'lib/apiRoutes'
import profileName from 'utils/profileName'
import requestErrorHandler from 'lib/requestErrorHandler'
import {
  currentClientSelector,
  entityWorkspaceUniqCodeSelector,
  currentGuestSelector,
} from 'state/concepts/session/selectors'
import { paymentSelector } from 'state/concepts/payment/selectors'
import { ACCEPT_AND_PAY } from 'state/concepts/payment/types'
import { buildCustomBaseUrl } from 'lib/httpClient'

const acceptAndPayOperation = createLogic({
  type: ACCEPT_AND_PAY,
  warnTimeout: 0,

  async process(
    {
      action: { values, form, confirmCardPayment, getStripeElement, setStripeBaseError, onError },
      getState,
      httpClient,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const { id: paymentId, amount } = paymentSelector(state)
    const profile = currentGuestSelector(state) || currentClientSelector(state)
    const workspaceCode = entityWorkspaceUniqCodeSelector(state, paymentId, 'transaction')

    try {
      const { data } = await httpClient.post(
        transactionCheckoutPaymentIntentRoute(paymentId),
        { amount, auto_charge: values.autoCharge },
        buildCustomBaseUrl(['workspaces', workspaceCode]),
      )

      const { error } = await confirmCardPayment(data.meta.payment_intent_client_secret, {
        payment_method: {
          card: getStripeElement(CardNumberElement),
          billing_details: {
            name: profileName(profile),
            email: profile.email,
            address: {
              city: values.city,
              state: values.state,
              line1: values.address,
              country: values.country,
              postal_code: values.index,
            },
          },
        },
      })

      if (error) {
        setStripeBaseError(error.message)
        // eslint-disable-next-line no-throw-literal
        throw { ...error, isStripe: true }
      }
    } catch (error) {
      const { isStripe } = error

      if (!isStripe) {
        requestErrorHandler({ error, dispatch, form })
        onError()
      }
      form.setSubmitting(false)
    }

    done()
  },
})

export default acceptAndPayOperation
