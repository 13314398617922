const namespace = 'myBusinessFiles'

export const FETCH_CURRENT_FOLDER = `${namespace}/FETCH_CURRENT_FOLDER`
export const FETCH_MY_BUSINESS_FILES = `${namespace}/FETCH_MY_BUSINESS_FILES`
export const FILTER_MY_BUSINESS_FILES = `${namespace}/FILTER_MY_BUSINESS_FILES`
export const ADD_MY_BUSINESS_FILE_IDS = `${namespace}/ADD_MY_BUSINESS_FILE_IDS`
export const SET_MY_BUSINESS_FILE_IDS = `${namespace}/SET_MY_BUSINESS_FILE_IDS`
export const RESET_MY_BUSINESS_FILE_IDS = `${namespace}/RESET_MY_BUSINESS_FILE_IDS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const SET_SELECTED_IDS = `${namespace}/SET_SELECTED_IDS`
export const RESET_SELECTED_IDS = `${namespace}/RESET_SELECTED_IDS`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const CREATE_FOLDER = `${namespace}/CREATE_FOLDER`
export const SET_CURRENT_FOLDER_ID = `${namespace}/SET_CURRENT_FOLDER_ID`
export const DUPLICATE_MY_BUSINESS_FILE = `${namespace}/DUPLICATE_MY_BUSINESS_FILE`
export const INSERT_BEFORE_MY_BUSINESS_FILE_ID = `${namespace}/INSERT_BEFORE_MY_BUSINESS_FILE_ID`
export const RENAME_MY_BUSINESS_FILE = `${namespace}/RENAME_MY_BUSINESS_FILE`
export const SHARE_MY_BUSINESS_FILE = `${namespace}/SHARE_MY_BUSINESS_FILE`
export const UPLOAD_BUSINESS_FILE = `${namespace}/UPLOAD_BUSINESS_FILE`
export const PRESIGN_BUSINESS_FILE = `{namespace}/PRESIGN_BUSINESS_FILE`
export const COMPLETE_FILE_LOADING = `${namespace}/COMPLETE_FILE_LOADING`
export const ADD_UPLOAD_FILE = `${namespace}/ADD_UPLOAD_FILE`
export const FAIL_FILE_LOADING = `${namespace}/FAIL_FILE_LOADING`
export const CANCEL_FILE_UPLOAD = `${namespace}/CANCEL_FILE_UPLOAD`
export const REMOVE_UPLOADED_FILES = `${namespace}/REMOVE_UPLOADED_FILES`
export const MOVE_MY_BUSINESS_FILE_TO_TRASH = `${namespace}/MOVE_MY_BUSINESS_FILE_TO_TRASH`
export const REMOVE_MY_BUSINESS_FILE_IDS = `${namespace}/REMOVE_MY_BUSINESS_FILE_IDS`
export const DOWNLOAD_BUSINESS_FILES = `${namespace}/DOWNLOAD_BUSINESS_FILES`
export const ADD_FILES_DOWNLOAD = `${namespace}/ADD_FILES_DOWNLOAD`
export const COMPLETE_FILES_DOWNLOAD = `${namespace}/COMPLETE_FILES_DOWNLOAD`
export const CANCEL_FILES_DOWNLOAD = `${namespace}/CANCEL_FILE_DOWNLOAD`
export const REMOVE_FILES_DOWNLOAD = `${namespace}/REMOVE_FILE_DOWNLOAD`
export const CANCEL_FILES_DOWNLOAD_SUCCESS = `${namespace}/CANCEL_FILES_DOWNLOAD_SUCCESS`
export const FILES_DOWNLOAD_SUCCESS = `${namespace}/FILES_DOWNLOAD_SUCCESS`
export const ADD_PENDING_FILES_DOWNLOAD = `${namespace}/ADD_PENDING_FILES_DOWNLOAD`
export const ADD_CLIENT_BUSINESS_FILES = `${namespace}/ADD_CLIENT_BUSINESS_FILES`
export const MOVE_TO_FOLDER = `${namespace}/MOVE_TO_FOLDER`
export const FETCH_FOLDERS_TO_MOVE = `${namespace}/FETCH_FOLDERS_TO_MOVE`
export const ADD_FOLDER_TO_MOVE_IDS = `${namespace}/ADD_FOLDER_TO_MOVE_IDS`
export const RESET_FOLDER_TO_MOVE_IDS = `${namespace}/RESET_FOLDER_TO_MOVE_IDS`
export const SET_FOLDER_TO_MOVE_IDS = `${namespace}/SET_FOLDER_TO_MOVE_IDS`
export const RECEIVE_BUSINESS_STORAGE = `${namespace}/RECEIVE_BUSINESS_STORAGE`
export const SET_IS_DOWNLOADING_SECTION_OPEN = `${namespace}/SET_IS_DOWNLOADING_SECTION_OPEN`
export const SET_IS_UPLOADING_SECTION_OPEN = `${namespace}/SET_IS_UPLOADING_SECTION_OPEN`
