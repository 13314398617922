import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setDateAvailability, setTimezone } from 'state/concepts/dateAvailability/actions'
import { fetchDateAvailabilityEndpoint } from 'state/concepts/dateAvailability/endpoints'
import { selectedTimezoneSelector } from 'state/concepts/dateAvailability/selectors'
import { FETCH_DATE_AVAILABILITY } from 'state/concepts/dateAvailability/types'
import { employeeSelector } from 'state/concepts/employees/selectors'
import { timezonesSelector } from 'state/concepts/timezones/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fromFormattedTimezone } from 'utils/timezone'

const fetchDateAvailabilityOperation = createLogic({
  type: FETCH_DATE_AVAILABILITY,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { startDate, endDate, userId, needToSetEmployeeTimezone, timezone: forcedTimezone } = action
    const { endpoint, url } = fetchDateAvailabilityEndpoint(userId)
    const state = getState()
    const employee = employeeSelector(state, userId)
    const selectedTimezone = selectedTimezoneSelector(state, userId)
    const employeeTimezone = prop('formattedTimezone', employee)
    const timezones = timezonesSelector(state)
    const computedTimezone = needToSetEmployeeTimezone ? employeeTimezone : forcedTimezone || selectedTimezone
    const isFormattedTimezone = computedTimezone.includes('UTC')
    const timezone = isFormattedTimezone ? fromFormattedTimezone(computedTimezone, timezones) : computedTimezone

    const params = {
      filter: {
        start_date: startDate,
        end_date: endDate,
        timezone,
      },
      include: 'locations',
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setDateAvailability(map(prop('id'), data.data)))
      if (needToSetEmployeeTimezone) {
        dispatch(setTimezone(employeeTimezone))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchDateAvailabilityOperation
