import { createSelector } from '@reduxjs/toolkit'
import { any, isEmpty, path, pipe, prop, propEq } from 'ramda'
import build from 'redux-object'

import { dataSelector, endpointMetaSelector, totalCountSelector } from 'state/data/selectors'
import { fetchWidgetsEndpoint } from './endpoints'

const widgetIdsSelector = path(['widgets', 'widgetIds'])
export const isBlankStateSelector = path(['widgets', 'isBlankState'])
export const paginationSelector = path(['widgets', 'pagination'])
export const sortSelector = path(['widgets', 'sort'])

export const widgetsSelector = createSelector([widgetIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'widget', ids) : [],
)

export const hasActiveWidgetsSelector = createSelector(widgetsSelector, any(propEq('active', 'status')))

export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const sortParamsSelector = pipe(sortSelector, ({ sortKey, direction }) =>
  direction === 'asc' ? sortKey : `-${sortKey}`,
)

export const hasWidgetActiveServicesSelector = state =>
  !propEq(0, 'activeServicesCount', endpointMetaSelector(state, fetchWidgetsEndpoint.endpoint))

export const widgetsTotalCountSelector = state => totalCountSelector(state, fetchWidgetsEndpoint.endpoint)
