// eslint-disable-next-line import/prefer-default-export
export const SIDEBAR_COMPONENTS_TYPES = {
  editAvailabilitySidebar: 'EDIT_AVAILABILITY_SIDEBAR',
  createBookingAppointment: 'CREATE_BOOKING_APPOINTMENT',
  editBookingAppointment: 'EDIT_BOOKING_APPOINTMENT',
  rescheduleBookingAppointment: 'RESCHEDULE_BOOKING_APPOINTMENT',
  bookingDetails: 'BOOKING_DETAILS',
  externalCalendarEventDetails: 'EXTERNAL_CALENDAR_EVENT_DETAILS',
  websiteEdit: 'WEBSITE_EDIT',
  websiteWelcomeSection: 'WEBSITE_WELCOME_SECTION',
  websiteViewService: 'WEBSITE_VIEW_SERVICE',
  websiteViewProvider: 'WEBSITE_VIEW_PROVIDER',
  waitlistRequestDetails: 'WAITLIST_REQUEST_DETAILS',
}
