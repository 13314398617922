import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'
import { useMemo } from 'react'

import Button from 'views/shared/Button'
import PasswordInputField from 'views/shared/PasswordInputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const PasswordStep = ({ isDisabling, onClose, isUpdating }) => {
  const submitText = useMemo(() => {
    if (isDisabling) return 'shared.disable'
    if (isUpdating) return 'employeeTwoFactorSettings.password.continue'

    return 'employeeTwoFactorSettings.password.submit'
  }, [isDisabling, isUpdating])

  return (
    <>
      <div className="main-modal__body">
        <p className="text-body">
          <FormattedMessage id="employeeTwoFactorSettings.password.enterPassword" />
        </p>
        <Field
          name="password"
          id="password"
          className="mb-0"
          label={{ id: 'employeeTwoFactorSettings.password.enterPassword.label' }}
          component={PasswordInputField}
          visiblePassword
          autoComplete="on"
          icon="alert"
        />
      </div>
      <div className="main-modal__footer">
        <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
        <SubmitFormButton
          className="main-modal__footer-action"
          text={{ id: submitText }}
          kind={isDisabling ? 'danger' : null}
        />
      </div>
    </>
  )
}

PasswordStep.propTypes = {
  isDisabling: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
}

export default PasswordStep
