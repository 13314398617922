import zeroBg from 'assets/images/website/bg1-high.png'
import oneBg from 'assets/images/website/bg2-high.png'
import twoBg from 'assets/images/website/bg3-high.png'

export const WEBSITE_STATUSES = {
  published: 'published',
  unpublished: 'unpublished',
}

export const WEBSITE_BACKGROUND_IMAGES = {
  zero: zeroBg,
  one: oneBg,
  two: twoBg,
}
export const WEBSITE_BACKGROUND_IMAGE_TYPES = {
  zero: 'zero',
  one: 'one',
  two: 'two',
}
export const HEADER_TITLE_MAX_LENGTH = 75
export const WEBSITE_PAGE_TITLE_MAX_LENGTH = 75
export const WEBSITE_META_DESCRIPTION_MAX_LENGTH = 240
export const HEADER_SUBTITLE_MAX_LENGTH = 350
export const TERMS_AND_CONDITIONS_MAX_LENGTH = 10000
export const PRIVACY_POLICY_MAX_LENGTH = 10000

export const DEFAULT_ENTITIES_PAGE_SIZE = 4
export const MAX_TEXT_LENGTH = 120
export const EDIT_WEBSITE_PREVIEW_STEPS = {
  main: 'main',
  enableSections: 'enableSections',
  googleSeo: 'googleSeo',
}
export const getWebsiteSettingsToggleFields = ({ isReviewsFeatureEnabled }) =>
  ['experts', 'services', 'locations', 'reviews', 'contacts'].filter(
    field => field !== 'reviews' || isReviewsFeatureEnabled,
  )

export const WEBSITE_PROVIDER_TABS = {
  about: 'about',
  reviews: 'reviews',
}
