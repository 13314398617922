import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { compose, pick, whereEq } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import withLoseChangesWarning from 'lib/withLoseChangesWarning'
import yup from 'lib/yupLocalised'
import validationSchema from 'lib/yupLocalised/schemas/scheduleBooking'
import { updatePendingBooking } from 'state/concepts/booking/actions'
import { showSidebar as showSidebarAction } from 'state/sidebar/actions'
import { sidebarPropsSelector } from 'state/sidebar/selectors'
import getBookingToEditProps from 'utils/bookings/getBookingToEdit'
import initialUpdateFormValues from 'utils/bookings/initialUpdateFormValues'
import { handleSubmit } from 'utils/form/handleSubmit'
import ScheduleAppointmentComponent from 'views/shared/ScheduleAppointment'

class EditScheduleAppointment extends React.Component {
  static propTypes = {
    setValues: PropTypes.func.isRequired,
    showSidebar: PropTypes.func.isRequired,
    values: PropTypes.shape().isRequired,
    sidebarProps: PropTypes.shape().isRequired,
    date: PropTypes.shape().isRequired,
    time: PropTypes.shape().isRequired,
    duration: PropTypes.string.isRequired,
  }

  componentDidUpdate(prevProps) {
    const { date, time, duration, setValues, showSidebar, sidebarProps, values } = this.props

    if (!whereEq({ date, time, duration })(prevProps)) {
      setValues({ ...values, date, time, duration })
    }

    const currentValues = pick(['date', 'time', 'duration'], values)
    if (!whereEq(currentValues)(prevProps.values)) {
      showSidebar({
        sidebarType: SIDEBAR_COMPONENTS_TYPES.editBookingAppointment,
        sidebarProps: getBookingToEditProps({ ...sidebarProps, ...currentValues }),
      })
    }
  }

  render() {
    return <ScheduleAppointmentComponent {...this.props} isEdit />
  }
}

const mapStateToProps = state => ({
  sidebarProps: sidebarPropsSelector(state),
})

const mapDispatchToProps = {
  onSubmit: updatePendingBooking,
  showSidebar: showSidebarAction,
}

export { EditScheduleAppointment as EditScheduleAppointmentContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ sidebarProps }) => initialUpdateFormValues(sidebarProps),
    handleSubmit,
    validationSchema: validationSchema.shape({
      userProfileId: yup.string(),
    }),
  }),
  withLoseChangesWarning(),
)(EditScheduleAppointment)
