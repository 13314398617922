import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'

import { dataSelector } from 'state/data/selectors'
import buildWorkspaceObject from 'utils/buildWorkspaceObject'
import { namespace } from './types'

export const paymentAccountSelector = createSelector(dataSelector, buildWorkspaceObject('paymentAccount'))

export const stripeAccountSelector = createSelector(dataSelector, buildWorkspaceObject('stripeAccount'))

export const payPalAccountSelector = createSelector(dataSelector, buildWorkspaceObject('paypalAccount'))

export const manualPaymentSelector = createSelector(dataSelector, buildWorkspaceObject('manualPayment'))

export const stripeConnectLinkSelector = path(['paymentSettings', 'stripeConnectLink'])

export const payPalConnectLinkSelector = path(['paymentSettings', 'payPalConnectLink'])

export const stripeDisconnectErrorSelector = path(['paymentSettings', 'stripeDisconnectError'])

export const isConnectStripePosSelector = path([namespace, 'isConnectStripePos'])
