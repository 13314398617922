import mergeWith from 'lodash/mergeWith'
import isFunction from 'lodash/isFunction'
import { flatten, is } from 'ramda'

const updateDataHelper = (stateData, type, ids, data) => {
  const objects = {}
  flatten([ids]).forEach(id => {
    objects[id] = mergeWith(
      {},
      stateData[type][id],
      { ...(isFunction(data) ? data(id) : data) },
      (objValue, srcValue) => (is(Array)(objValue) ? srcValue : undefined),
    )
  })

  return { [type]: objects }
}

export default updateDataHelper
