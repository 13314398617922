import { getIn } from 'formik'
import PropTypes from 'prop-types'
import { take } from 'ramda'
import React from 'react'

import InputWithCounterFieldComponent from './component'

class InputWithCounterField extends React.Component {
  static propTypes = {
    maxChars: PropTypes.number.isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape(),
      touched: PropTypes.shape().isRequired,
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
  }

  handleChange = event => {
    const { onChange } = this.props
    const { value } = event.target

    const {
      maxChars,
      field: { name },
      form: { setFieldValue },
    } = this.props
    const newValue = take(maxChars, value)
    setFieldValue(name, newValue)
    onChange?.(newValue)
  }

  render() {
    const {
      field: { name },
      form: { errors, touched },
    } = this.props

    return (
      <InputWithCounterFieldComponent
        {...this.props}
        onChange={this.handleChange}
        fieldErrors={getIn(errors, name)}
        fieldTouched={getIn(touched, name)}
      />
    )
  }
}

InputWithCounterField.defaultProps = {
  onChange: undefined,
}

export default InputWithCounterField
