import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import { CYCLE_TYPES } from 'lib/constants/billing'
import requestErrorHandler from 'lib/requestErrorHandler'
import { cancelSwitchBillingCycleEndpoint } from 'state/concepts/subscriptionPlans/endpoints'
import { CANCEL_SWITCH_BILLING_CYCLE } from 'state/concepts/subscriptionPlans/types'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'

const cancelSwitchBillingCycleOperation = createLogic({
  type: CANCEL_SWITCH_BILLING_CYCLE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = cancelSwitchBillingCycleEndpoint

    try {
      const { data } = await httpClient.post(url, { include: 'planned-subscription-plan' })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.switchBillingCycle.switched',
            values: { newBillingCycleType: CYCLE_TYPES.annual },
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default cancelSwitchBillingCycleOperation
