import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { pageCountSelector, currentPageSelector } from 'state/data/selectors'
import { searchEmployees as searchEmployeesAction } from 'state/concepts/company/actions'
import { fetchEmployeesEndpoint } from 'state/concepts/employees/endpoints'
import Spinner from 'views/shared/icons/Spinner'

class Loader extends React.Component {
  static propTypes = {
    searchEmployees: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    excludeEmployees: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    serviceId: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
    excludeEmployees: null,
    statuses: [],
    serviceId: undefined,
    roles: ['workspace_owner', 'administrator', 'user'],
  }

  handleLoadMore = () => {
    const { searchEmployees, currentPage, pageCount, searchQuery, excludeEmployees, statuses, serviceId, roles } =
      this.props

    if (pageCount >= currentPage + 1) {
      searchEmployees({
        query: searchQuery,
        exclude: excludeEmployees,
        statuses,
        serviceId,
        page: currentPage + 1,
        roles,
      })
    }
  }

  render() {
    const { isLoading } = this.props

    if (isLoading !== false) {
      return <Spinner size={18} />
    }

    return <Waypoint onEnter={this.handleLoadMore} />
  }
}

const mapStateToProps = state => {
  const { endpoint } = fetchEmployeesEndpoint

  return {
    pageCount: pageCountSelector(state, endpoint),
    currentPage: currentPageSelector(state, endpoint),
  }
}

const mapDispatchToProps = {
  searchEmployees: searchEmployeesAction,
}

export { Loader as LoaderContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Loader)
