import { createSelector } from '@reduxjs/toolkit'
import { isEmpty, path, pipe, prop } from 'ramda'
import build from 'redux-object'

import { fetchClientBookingsEndpoint } from 'state/concepts/companyClientBookings/endpoints'
import { dataSelector, loadingSelector, pageCountSelector } from 'state/data/selectors'

export const paginationSelector = path(['companyClientBookings', 'pagination'])

export const currentPageSelector = pipe(paginationSelector, prop('number'))

const clientBookingsIdsSelector = path(['companyClientBookings', 'clientBookingsIds'])

export const clientBookingsSelector = createSelector([clientBookingsIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'clientFeed', ids, { includeType: true }) : [],
)

export const clientBookingsPageCountSelector = (state, id) =>
  pageCountSelector(state, fetchClientBookingsEndpoint(id).endpoint)

export const clientBookingsLoadingSelector = (state, id) =>
  loadingSelector(state, fetchClientBookingsEndpoint(id).endpoint)
