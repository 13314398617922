import PropTypes from 'prop-types'
import classNames from 'clsx'

import profileName from 'utils/profileName'
import { isSelectMultiple } from 'utils/inputHelpers'

const OptionLabel = ({ mode, chatUser }) => (
  <span
    className={classNames({
      'dropdown-multiselect-choise dropdown-multiselect-choise--deactiv':
        isSelectMultiple(mode) && chatUser.deactivated,
    })}
  >
    <span className={classNames({ 'dropdown-multiselect-choise__txt': isSelectMultiple(mode) })}>
      {profileName(chatUser)}
    </span>
  </span>
)

OptionLabel.propTypes = {
  mode: PropTypes.string.isRequired,
  chatUser: PropTypes.shape().isRequired,
}

export default OptionLabel
