import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { SHOW_SERVICE_DETAILS } from 'state/concepts/service/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showServicesDetailsEndpoint } from 'state/concepts/service/endpoints'

const showServiceDetailsOperation = createLogic({
  type: SHOW_SERVICE_DETAILS,
  latest: true,

  async process({ action: { serviceId }, httpClient }, dispatch, done) {
    const params = {
      include: ['service-category', 'service-setting', 'user-profiles-services.user-profile', 'forms', 'locations'],
    }
    const { url, endpoint } = showServicesDetailsEndpoint(serviceId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default showServiceDetailsOperation
