import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import { TABLE_ENTITIES } from 'lib/constants/tableData'
import requestErrorHandler from 'lib/requestErrorHandler'
import { setBlankState, setLocations, setTotalCount } from 'state/concepts/locations/actions'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import { FETCH_LOCATIONS } from 'state/concepts/locations/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { tableOrderDirectionsSelector, tablePaginationSelector } from 'state/tableData/selectors'
import isBlankState from 'utils/isBlankState'
import generateSortString from 'utils/generateSortString'
import { filtersSelector } from '../selectors'

const fetchLocationsOperation = createLogic({
  type: FETCH_LOCATIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchLocationsEndpoint
    const state = getState()
    const filter = filtersSelector(state)

    const params = {
      page: {
        ...tablePaginationSelector(state, TABLE_ENTITIES.location),
      },
      sort: generateSortString(tableOrderDirectionsSelector(state, TABLE_ENTITIES.location)),
      filter,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      dispatch(setBlankState(isBlankState({ data: data.data, params: filter })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setLocations(pluck('id', data.data)))
      dispatch(setTotalCount(data.meta.total_count))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchLocationsOperation
