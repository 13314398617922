import keepAlive from './keepAlive'
import employeeLogin from './employeeLogin'
import employeeLogout from './employeeLogout'
import employeePostLogout from './employeePostLogout'
import bulkEmployeeLogout from './bulkEmployeeLogout'
import clientSendCode from './clientSendCode'
import clientLogin from './clientLogin'
import clientLogout from './clientLogout'
import clientPostLogout from './clientPostLogout'
import employeeLoginSuccess from './employeeLoginSuccess'
import employeeSubmitTwoFa from './employeeSubmitTwoFa'
import resendOtpCodeOnLogin from './resendOtpCodeOnLogin'
import clientContactLogin from './clientContactLogin'

export default [
  keepAlive,
  employeeLogin,
  employeeLogout,
  employeePostLogout,
  bulkEmployeeLogout,
  clientSendCode,
  clientLogin,
  clientLogout,
  clientPostLogout,
  employeeLoginSuccess,
  employeeSubmitTwoFa,
  resendOtpCodeOnLogin,
  clientContactLogin,
]
