import {
  publicRemind,
  updateCalendarViewSettingRoute,
  usersAccountConfirmationResendRoute,
  usersAccountConfirmationRoute,
  usersAccountEmailResendChangeRequestRoute,
  usersAccountEmailRoute,
  usersAccountLogoutIntervalRoute,
  usersAccountProfileRoute,
  usersInvitationsRegistrationRoute,
  usersResetPasswordRoute,
  usersValidationsClientRoute,
  usersAccountTwoFactorSettingsMatchPasswordRoute,
  usersAccountTwoFactorSettingsRoute,
  usersAccountTwoFactorSettingsVerifyPhoneNumberRoute,
  usersAccountTwoFactorSettingsAppProvisionUriRoute,
  usersAccountTwoFactorSettingsBackupCodesRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const getEmployeeOwnProfileEndpoint = endpoint(types.GET_EMPLOYEE_OWN_PROFILE, 'GET', usersAccountProfileRoute)
export const validateResetPasswordEndpoint = endpoint(
  types.VALIDATE_RESET_PASSWORD_TOKEN,
  'GET',
  usersResetPasswordRoute,
)
export const resetPasswordAccountEndpoint = endpoint(
  types.RESET_PASSWORD_REQUEST_ACCOUNT,
  'POST',
  usersResetPasswordRoute,
)
export const validateInvitationTokenEndpoint = endpoint(
  types.VALIDATE_INVITATION_TOKEN,
  'GET',
  usersInvitationsRegistrationRoute,
)
export const confirmEmployeeOwnEmailEndpoint = endpoint(types.CONFIRM_EMPLOYEE_EMAIL, 'PUT', usersAccountEmailRoute)
export const resendEmailConfirmationEndpoint = endpoint(
  types.RESEND_EMAIL_CONFIRMATION,
  'POST',
  usersAccountConfirmationResendRoute,
)
export const resendEmailChangeConfirmationEndpoint = endpoint(
  types.RESEND_EMAIL_CHANGE_CONFIRMATION,
  'POST',
  usersAccountEmailResendChangeRequestRoute,
)
export const fetchRemindWorkspacesEndpoint = endpoint(types.FETCH_REMIND_WORKSPACES, 'GET', publicRemind)
export const validateConfirmationTokenEndpoint = endpoint(
  types.VALIDATE_CONFIRMATION_TOKEN,
  'POST',
  usersAccountConfirmationRoute,
)
export const usersAccountLogoutIntervalEndpoint = endpoint(
  types.UPDATE_LOGOUT_INTERVAL,
  'PUT',
  usersAccountLogoutIntervalRoute,
)

export const validateAccessToClientEndpoint = endpoint(
  types.VALIDATE_ACCESS_TO_CLIENT,
  'GET',
  usersValidationsClientRoute,
)

export const validatePasswordOnTwoFactorEndpoint = endpoint(
  types.VALIDATE_PASSWORD_ON_TWO_FACTOR,
  'POST',
  usersAccountTwoFactorSettingsMatchPasswordRoute,
)

export const enableTwoFactorSettingsEndpoint = endpoint(
  types.ENABLE_TWO_FACTOR_SETTINGS,
  'POST',
  usersAccountTwoFactorSettingsRoute,
)

export const updateTwoFactorSettingsEndpoint = endpoint(
  types.UPDATE_TWO_FACTOR_SETTINGS,
  'PUT',
  usersAccountTwoFactorSettingsRoute,
)

export const disableTwoFactorSettingsEndpoint = endpoint(
  types.DISABLE_TWO_FACTOR_SETTINGS,
  'DELETE',
  usersAccountTwoFactorSettingsRoute,
)

export const getOtpCodeBySmsEndpoint = endpoint(
  types.GET_OTP_CODE_BY_SMS,
  'POST',
  usersAccountTwoFactorSettingsVerifyPhoneNumberRoute,
)

export const getAppUthUriEndpoint = endpoint(
  types.GET_APP_AUTH_URI,
  'GET',
  usersAccountTwoFactorSettingsAppProvisionUriRoute,
)

export const generateBackupCodesEndpoint = endpoint(
  types.GENERATE_BACKUP_CODES,
  'POST',
  usersAccountTwoFactorSettingsBackupCodesRoute,
)

export const updateCalendarViewSettingEndpoint = endpoint(
  types.UPDATE_CALENDAR_VIEW_SETTING,
  'PUT',
  updateCalendarViewSettingRoute,
)
