const customPaymentsFeature = {
  'customPaymentsFeature.alert.text':
    'To enable custom payments, you need to start accepting payments through ExpertBox.',
  'customPaymentsFeature.alert.action': 'Accept payments',
  'customPaymentsFeature.subtitle': '<span>in</span> Payments',
  'customPaymentsFeature.description': 'Send payment requests for any products or _(services) you provide.',
  'customPaymentsFeature.overview': 'Custom payments overview',
  'customPaymentsFeature.makeItEasy':
    'ExpertBox custom payments make it easy to bill _(clients) for any product or _(service), even if it’s not related to _a(appointment) within your workspace.',
  'customPaymentsFeature.customRequest': 'Create custom payment requests:',
  'customPaymentsFeature.customRequest.stepOne':
    'Create payment requests for any product or _(service) you’ve provided.',
  'customPaymentsFeature.customRequest.stepTwo':
    'Add multiple billable items to your request so _(clients) can see the breakdown of what they’re paying for.',
  'customPaymentsFeature.sendRequest': 'Send payment requests to _(clients):',
  'customPaymentsFeature.sendRequest.stepOne': 'Send payment requests directly from the ExpertBox platform.',
  'customPaymentsFeature.sendRequest.stepTwo': 'Save payment requests as drafts and send them later.',
  'customPaymentsFeature.sendRequest.stepThree': 'Send payment reminders to _(clients) to ensure timely payments.',
  'customPaymentsFeature.billClients': 'Bill your _(clients) regularly:',
  'customPaymentsFeature.billClients.stepOne':
    'Set a custom billing cycle and the number of payment requests to send to _a(client).',
  'customPaymentsFeature.billClients.stepTwo':
    'Decide whether you want to charge your _(clients) automatically (available only for online payments). This will let _(clients) save their card details and automate payments.',
  'customPaymentsFeature.trackPayments': 'Track payments:',
  'customPaymentsFeature.trackPayments.stepOne':
    'Get an email and in-app notification as soon as a payment is completed or when a payment becomes overdue.',
  'customPaymentsFeature.trackPayments.stepTwo':
    'Track all your payment requests and their statuses so you can stay organized and keep your financial records up to date.',
  'customPaymentsFeature.paymentMethod':
    'Depending on the payment method, your _(clients) will be able to pay online or in person. If you prefer in-person payments, you can mark those payment requests as paid once you have received the money, keeping your financial records up to date.',
  'customPaymentsFeature.helpCenter':
    'To learn more, read our help center article on <link>sending custom payment requests to _(clients)</link>.',
  'customPaymentsFeature.manageSettings': 'Go to billing',
  'customPaymentsFeature.upgradePlan': 'Upgrade plan',
  'customPaymentsFeature.availableOnlyForAdvanced': 'Custom payments is a Premium feature.',
  'customPaymentsFeature.disableCustomPayments': 'Disable custom payments feature?',
  'customPaymentsFeature.disableCustomPayments.bodyText':
    'You won’t be able to send custom payment requests to _(clients) anymore. This won’t cancel any of the payment requests issued before, and your _(clients) will still be able to pay according to the connected payment method.',
}

export default customPaymentsFeature
