import { combineReducers } from '@reduxjs/toolkit'
import { uniq, without } from 'ramda'

import { SET_CLIENT_BOOKINGS, SET_CLIENT_BOOKINGS_PAGE, RESET_CLIENT_BOOKINGS, REMOVE_CLIENT_BOOKINGS } from './types'

const clientBookingsIds = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENT_BOOKINGS:
      return uniq([...state, ...action.clientBookingsIds])
    case REMOVE_CLIENT_BOOKINGS:
      return without(action.ids, state)
    case RESET_CLIENT_BOOKINGS:
      return []
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_CLIENT_BOOKINGS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

export default combineReducers({
  clientBookingsIds,
  pagination,
})
