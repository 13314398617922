import getServiceCategories from './getServiceCategories'
import searchServiceCategories from './searchServiceCategories'
import filterServiceCategories from './filterServiceCategories'
import activateServiceCategories from './activateServiceCategories'
import deactivateServiceCategories from './deactivateServiceCategories'
import removeServiceCategories from './removeServiceCategories'
import createServiceCategory from './createServiceCategory'
import editServiceCategory from './editServiceCategory'
import filterServicesByCategoriesOperation from './filterServicesByCategories'

export default [
  getServiceCategories,
  searchServiceCategories,
  filterServiceCategories,
  filterServicesByCategoriesOperation,
  activateServiceCategories,
  deactivateServiceCategories,
  removeServiceCategories,
  createServiceCategory,
  editServiceCategory,
]
