import { equals, path, pipe } from 'ramda'

import { WS_STATUSES } from 'lib/constants/webSockets'

export const connectionStatusSelector = path(['webSockets', 'status'])

export const subscriptionChannelsSelector = path(['webSockets', 'channels'])

export const isOpenedConnectionSelector = pipe(connectionStatusSelector, equals(WS_STATUSES.opened))
export const isClosedConnectionSelector = pipe(connectionStatusSelector, equals(WS_STATUSES.closed))
