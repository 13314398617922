import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const File = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M102.4 0C74.387 0 51.2 23.187 51.2 51.2v409.6c0 28.014 23.187 51.2 51.2 51.2h307.2c28.014 0 51.2-23.186 51.2-51.2V153.6L307.2 0H102.4zm0 51.2h179.2v128h128v281.6H102.4V51.2zM179.2 256c-14.138 0-25.6 11.461-25.6 25.6s11.462 25.6 25.6 25.6h153.6c14.139 0 25.6-11.461 25.6-25.6S346.939 256 332.8 256H179.2zm0 102.4c-14.138 0-25.6 11.461-25.6 25.6s11.462 25.6 25.6 25.6h153.6c14.139 0 25.6-11.461 25.6-25.6s-11.461-25.6-25.6-25.6H179.2z" />
  </svg>
)

File.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

File.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default File
