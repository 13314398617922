const namespace = 'booking'

export const GET_CLIENT_BOOKING = `${namespace}/GET_CLIENT_BOOKING`
export const GET_EMPLOYEE_BOOKING = `${namespace}/GET_EMPLOYEE_BOOKING`
export const GET_BOOKING = `${namespace}/GET_BOOKING`
export const GET_CLIENT_BOOKING_AVAILABILITY = `${namespace}/GET_CLIENT_BOOKING_AVAILABILITY`
export const SET_CLIENT_BOOKING_AVAILABILITY = `${namespace}/SET_CLIENT_BOOKING_AVAILABILITY`
export const SET_BOOKING = `${namespace}/SET_BOOKING`
export const SET_BOOKING_DATE = `${namespace}/SET_BOOKING_DATE`
export const SET_BOOKING_TIMEZONE = `${namespace}/SET_BOOKING_TIMEZONE`
export const SET_BOOKING_TIME_SLOTS = `${namespace}/SET_BOOKING_TIME_SLOTS`
export const CLIENT_RESCHEDULE_BOOKING = `${namespace}/CLIENT_RESCHEDULE_BOOKING`
export const REMOVE_VIDEO_RECORD = `${namespace}/REMOVE_VIDEO_RECORD`
export const REMOVE_CHAT_HISTORY = `${namespace}/REMOVE_CHAT_HISTORY`
export const SET_PENDING_FORM_IDS = `${namespace}/SET_PENDING_FORM_IDS`
export const REMOVE_PENDING_FORM_IDS = `${namespace}/REMOVE_PENDING_FORM_IDS`
export const SCHEDULE_BOOKING = `${namespace}/SCHEDULE_BOOKING`
export const UPDATE_PENDING_BOOKING = `${namespace}/UPDATE_PENDING_BOOKING`
export const ACCEPT_BOOKING = `${namespace}/ACCEPT_BOOKING`
export const DECLINE_BOOKING = `${namespace}/DECLINE_BOOKING`
export const DECLINE_RECURRING_BOOKINGS = `${namespace}/DECLINE_RECURRING_BOOKINGS`
export const DECLINE_ALL_RECURRING_BOOKINGS = `${namespace}/DECLINE_ALL_RECURRING_BOOKINGS`
export const RESCHEDULE_RECURRING_BOOKINGS = `${namespace}/RESCHEDULE_RECURRING_BOOKINGS`
export const RECEIVE_ACCOUNT_FORM_DATA = `${namespace}/RECEIVE_ACCOUNT_FORM_DATA`
export const FETCH_IN_CALL_CHAT = `${namespace}/FETCH_IN_CALL_CHAT`
export const SET_IN_CALL_CHAT_ID = `${namespace}/SET_IN_CALL_CHAT_ID`
