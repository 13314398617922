import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { UPDATE_DATE_AVAILABILITY } from 'state/concepts/dateAvailability/types'
import { showNotification } from 'state/notifications/actions'
import { updateDateAvailabilityEndpoint } from 'state/concepts/dateAvailability/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideSidebar } from 'state/sidebar/actions'
import { setDateAvailability } from 'state/concepts/dateAvailability/actions'

const updateDateAvailabilityOperation = createLogic({
  type: UPDATE_DATE_AVAILABILITY,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { availabilities, startDate, endDate, userId, form } = action
    const { endpoint, url } = updateDateAvailabilityEndpoint(userId)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        date_availabilities: availabilities,
        filter: {
          start_date: startDate,
          end_date: endDate,
        },
        include: 'locations',
      }
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      dispatch(hideSidebar())
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setDateAvailability(map(prop('id'), data.data)))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.dateAvailabilityUpdated' },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
      form?.setSubmitting(false)
    }
    done()
  },
})

export default updateDateAvailabilityOperation
