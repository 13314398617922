import { combineReducers } from '@reduxjs/toolkit'
import { assocPath, pipe, reduce, uniq, without } from 'ramda'

import * as types from './types'

const clientAttachmentsIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_CLIENT_ATTACHMENTS_IDS:
      return uniq([...state, ...action.clientAttachmentsIds])
    case types.SET_CLIENT_ATTACHMENTS_IDS:
      return action.clientAttachmentsIds
    case types.ADD_CLIENT_ATTACHMENT_ID:
      return uniq([action.clientAttachmentId, ...state])
    case types.REMOVE_CLIENT_ATTACHMENTS_IDS:
      return without(action.ids, state)
    case types.RESET_CLIENT_ATTACHMENTS:
      return []
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_CLIENT_ATTACHMENTS_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const uploadFiles = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_UPLOAD_FILE:
      return {
        ...state,
        [action.uploadId]: {
          id: action.uploadId,
          file: action.file,
          meta: action.meta,
          isLoading: true,
        },
      }
    case types.COMPLETE_FILE_LOADING:
      return assocPath([action.uploadId], { ...state[action.uploadId], isLoading: false }, state)
    case types.FAIL_FILE_LOADING:
      return assocPath([action.uploadId], { ...state[action.uploadId], isFailed: true, isLoading: false }, state)
    case types.CANCEL_FILE_UPLOAD:
      return pipe(
        reduce(
          (acc, uploadId) => assocPath([uploadId], { ...acc[uploadId], isCancelled: true, isLoading: false }, acc),
          state,
        ),
      )(action.uploadIds)
    case types.REMOVE_UPLOADED_FILES:
      return {}
    default:
      return state
  }
}

const isUploadingSectionOpen = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_UPLOADING_SECTION_OPEN:
      return action.isOpen
    default:
      return state
  }
}

export default combineReducers({
  clientAttachmentsIds,
  filters,
  uploadFiles,
  isUploadingSectionOpen,
})
