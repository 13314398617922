const myBusinessFiles = {
  'myBusinessFiles.table.name.title': 'Name',
  'myBusinessFiles.table.sharedWith.title': 'Shared with',
  'myBusinessFiles.table.lastModified.title': 'Last modified',
  'myBusinessFiles.table.fileSize.title': 'File size',
  'myBusinessFiles.table.lastModified': '{date} at {time}',
  'myBusinessFiles.selectAll': 'Select all',
  'myBusinessFiles.selectedCount': '{count} selected',
  'myBusinessFiles.createFolder': 'Create folder',
  'myBusinessFiles.createFolder.tooltip': 'Folder nesting limit: 10 levels.',
  'myBusinessFiles.uploadFile': 'Upload file',
  'myBusinessFiles.actions.moveToTrash': 'Move to trash',
  'myBusinessFiles.actions.preview': 'Preview',
  'myBusinessFiles.actions.open': 'Open',
  'myBusinessFiles.actions.rename': 'Rename',
  'myBusinessFiles.actions.duplicate': 'Make a copy',
  'myBusinessFiles.actions.shareWithTeam': 'Share with team',
  'myBusinessFiles.actions.moveToFolder': 'Move to folder',
  'myBusinessFiles.noSearchResults': 'Try adjusting your search or filters',
  'myBusinessFiles.searchPlaceholder': 'Search in My files',
  'myBusinessFiles.shared.searchPlaceholder': 'Search in Shared with me',
  'myBusinessFiles.createFolder.modal.title': 'Create a new folder',
  'myBusinessFiles.renameMyBusinessFile.modal.title.folder': 'Rename folder',
  'myBusinessFiles.renameMyBusinessFile.modal.title.file': 'Rename file',
  'myBusinessFiles.createFolder.modal.defaultValue': 'Untitled folder',
  'myBusinessFiles.createFolder.modal.label': 'Name',
  'myBusinessFiles.filter.pdf.label': 'PDFs',
  'myBusinessFiles.filter.imageAndPhoto.label': 'Images and photos',
  'myBusinessFiles.filter.videoAndAudio.label': 'Video and audio',
  'myBusinessFiles.filter.folder.label': 'Folders',
  'myBusinessFiles.filter.otherFile.label': 'Other files',
  'myBusinessFiles.filter.today.label': 'Today',
  'myBusinessFiles.filter.lastWeek.label': 'Last 7 days',
  'myBusinessFiles.filter.lastMonth.label': 'Last 30 days',
  'myBusinessFiles.filter.thisYear.label': 'This year ({thisYear})',
  'myBusinessFiles.filter.lastYear.label': 'Last year ({lastYear})',
  'myBusinessFiles.filter.customRange.label': 'Custom date range',
  'myBusinessFiles.filter.customRange.placeholder': 'Select date',
  'myBusinessFiles.filter.customRange.after.label': 'After',
  'myBusinessFiles.filter.customRange.before.label': 'Before',
  'myBusinessFiles.selectedFilter.today.label': 'Today',
  'myBusinessFiles.selectedFilter.lastWeek.label': 'Last 7 days',
  'myBusinessFiles.selectedFilter.lastMonth.label': 'Last 30 days',
  'myBusinessFiles.selectedFilter.thisYear.label': 'This year',
  'myBusinessFiles.selectedFilter.lastYear.label': 'Last year',
  'myBusinessFiles.selectedFilter.customRange.label': 'Custom date range',
  'myBusinessFiles.shareFileModal.title': 'Share “{name}”',
  'myBusinessFiles.shareFileModal.share': 'Share',
  'myBusinessFiles.shareFileModal.shareWithTeamMembers': 'Share with team members',
  'myBusinessFiles.shareFileModal.teamMembersWithAccess': 'Team members with access',
  'myBusinessFiles.shareFileModal.owner': 'Owner',
  'myBusinessFiles.addPermission.addOneMore': 'Add one more',
  'myBusinessFiles.addPermission.reader.label': 'Viewer',
  'myBusinessFiles.addPermission.reader.description': 'View and download files',
  'myBusinessFiles.addPermission.writer.label': 'Editor',
  'myBusinessFiles.addPermission.writer.description': 'Organize, add and edit files',
  'myBusinessFiles.updatePermission.reader.label': 'Viewer',
  'myBusinessFiles.updatePermission.reader.description': 'View and download files',
  'myBusinessFiles.updatePermission.writer.label': 'Editor',
  'myBusinessFiles.updatePermission.writer.description': 'Organize, add and edit files',
  'myBusinessFiles.updatePermission.removeAccess': 'Remove access',
  'myBusinessFiles.removePermission.title': 'Remove access from parent folder?',
  'myBusinessFiles.removePermission.submit': 'Remove from parent',
  'myBusinessFiles.updatePermission.title': 'Update access for parent folder?',
  'myBusinessFiles.updatePermission.submit': 'Update parent',
  'myBusinessFiles.updatePermission.youHave': 'You have',
  'myBusinessFiles.updatePermission.profileHas': '{profileName} has',
  'myBusinessFiles.updatePermission.hasAccess':
    '{userText} access to <span>{name}</span> via a parent folder <span>{parentName}</span>. Changing the access on this file will change the access for the parent folder too.',
  'myBusinessFiles.removePermission.hasAccess':
    '{userText} access to <span>{name}</span> via a parent folder <span>{parentName}</span>. Removing access from this file/folder will remove access from the parent folder too.',

  'myBusinessFiles.dropFilesHere': 'Drop files here',
  'myBusinessFiles.useUploadButton': 'or use “Upload file” button',
  'myBusinessFiles.uploadOptions.replaceAll': 'Replace existing items',
  'myBusinessFiles.uploadOptions.keepAll': 'Keep all items',
  'myBusinessFiles.uploadOptionsModal.title': 'Upload options',
  'myBusinessFiles.uploadOptionsModal.body':
    "One or more items already exist in this location. Do you want to replace the existing items with a new version or keep both items? Replacing the items won't change sharing settings.",
  'myBusinessFiles.uploadProgress.retry': 'Retry',
  'myBusinessFiles.uploadProgress.minimize': 'Minimize',
  'myBusinessFiles.uploadProgress.maximize': 'Maximize',

  'myBusinessFiles.uploadItem.cancelled': 'Cancelled',
  'myBusinessFiles.uploadItem.cancelUpload': 'Cancel upload',
  'myBusinessFiles.uploadItem.uploadFailed': 'Upload failed',

  'myBusinessFiles.uploadProgress.uploadingCount': 'Uploading {count} {count, plural, one {file} other {files}}',
  'myBusinessFiles.uploadProgress.uploadsComplete': '{count} {count, plural, one {upload} other {uploads}} complete',
  'myBusinessFiles.uploadProgress.uploadFailed': '{count} {count, plural, one {download} other {downloads}} failed',
  'myBusinessFiles.uploadProgress.completedWithFailed': 'Upload complete ({count} failed)',
  'myBusinessFiles.uploadProgress.uploadCancelled': 'Upload cancelled',

  'myBusinessFiles.uploadSizeExceeded': 'Upload failed. Limit for file size is 1GB.',
  'myBusinessFiles.uploadSizeExceeded.uploadingInProgress': 'Uploading in progress...',

  'myBusinessFiles.cancelModal.title': 'Cancel upload?',
  'myBusinessFiles.cancelModal.body': 'Your upload is not complete. Would you like to cancel the upload?',
  'myBusinessFiles.cancelModal.submit': 'Cancel upload',
  'myBusinessFiles.cancelModal.dismiss': 'Continue upload',
  'myBusinessFiles.actions.moveToTrash.modal.title': 'Move to trash?',
  'myBusinessFiles.actions.moveToTrash.modal.description': `You’re about to move the {kind} <b>{name}</b> to trash`,
  'myBusinessFiles.actions.moveToTrash.modal.descriptionWithCounter': `You’re about to move {count} items to trash.`,
  'myBusinessFiles.actions.moveToTrash.modal.alert': 'You have 30 days to restore files and folders from the trash.',
  'myBusinessFiles.addBusinessFiles.noSearchResults': 'Try adjusting your search',
  'myBusinessFiles.addBusinessFiles.expand': 'Expand',
  'myBusinessFiles.addBusinessFiles.submitText': 'Add business files',
  'myBusinessFiles.addBusinessFiles.all': 'Business files',
  'myBusinessFiles.addBusinessFiles.emptyState.title': 'No uploaded business files yet',
  'myBusinessFiles.addBusinessFiles.emptyState.description':
    'To add business files to the _(client) profile, you must first upload them to business file storage.',
  'myBusinessFiles.addBusinessFiles.emptyState.buttonText': 'Manage business files',

  'myBusinessFiles.title': 'Business files',
  'myBusinessFiles.myFiles': 'My files',
  'myBusinessFiles.sharedFiles': 'Shared with me',
  'myBusinessFiles.trash': 'Trash',

  'myBusinessFiles.downloadProgress.zippingFiles': 'Zipping {count} {count, plural, one {file} other {files}}',
  'myBusinessFiles.downloadProgress.filesZipped': '{count} {count, plural, one {file} other {files}} zipped',
  'myBusinessFiles.downloadProgress.zippingAwaiting': '{count} {count, plural, one {file} other {files}}',
  'myBusinessFiles.downloadProgress.awaiting': 'Awaiting',

  'myBusinessFiles.downloadProgress.preparingDownload': 'Preparing download',
  'myBusinessFiles.downloadProgress.cancelled': 'Zip cancelled',
  'myBusinessFiles.downloadProgress.downloadCancelled': 'Download cancelled',
  'myBusinessFiles.downloadProgress.downloadReady': 'Download ready',
  'myBusinessFiles.downloadProgress.cancelUpload': 'Cancel download',

  'myBusinessFiles.downloadCancelModal.title': 'Cancel download?',
  'myBusinessFiles.downloadCancelModal.body': 'Your download is not complete. Would you like to cancel the download?',
  'myBusinessFiles.downloadCancelModal.submit': 'Cancel download',
  'myBusinessFiles.downloadCancelModal.dismiss': 'Continue download',

  'myBusinessFiles.moveItemTo': 'Move 1 {kind} to...',
  'myBusinessFiles.noAvailableFolders': 'No available folders',

  'myBusinessFiles.moveToFolderWarningModal.title': 'Change who has access?',
  'myBusinessFiles.moveToFolderWarningModal.changeAccessBody':
    'This item will be visible to everyone who can see {newDir}. Everyone who can see {oldDir} will lose  access to this item unless this item is shared directly with them',
  'myBusinessFiles.moveToFolderWarningModal.giveAccessBody':
    'This item will be visible to everyone who can see {newDir}.',
  'myBusinessFiles.moveToFolderWarningModal.looseAccessBody':
    'Everyone who can see {oldDir} will lose access to this item unless this item is shared directly with them.',
  'myBusinessFiles.SelectTeamMember.field.placeholder': 'Select team member',
}

export default myBusinessFiles
