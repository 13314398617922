import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { removeVideoRecord as removeVideoRecordAction } from 'state/concepts/booking/actions'
import { currentEmployeePermissionsSelector } from 'state/concepts/employee/selectors'
import { isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'
import * as modalActions from 'state/modal/actions'
import RecordingsComponent from './component'

class Recordings extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    booking: PropTypes.shape({
      id: PropTypes.string.isRequired,
      videoRecords: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    isEmployeeLoggedIn: PropTypes.bool.isRequired,
    permissions: PropTypes.shape().isRequired,
    removeVideoRecord: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onPreview: PropTypes.func,
  }

  static defaultProps = {
    onDelete: noop,
    onPreview: noop,
  }

  canRemove = () => {
    const { isEmployeeLoggedIn, permissions } = this.props

    return isEmployeeLoggedIn && permissions.manageClientAttachment
  }

  canManage = () => {
    const { permissions } = this.props

    return permissions.manageClientAttachment
  }

  handleRemove = videoRecordId => {
    const { removeVideoRecord, booking } = this.props

    removeVideoRecord(videoRecordId, booking.id)
  }

  handleConfirmRemove = videoRecordId => () => {
    const { showModal, onDelete } = this.props

    showModal({
      modalType: 'RECORDING_REMOVE_MODAL',
      modalProps: {
        videoRecordId,
        onSubmit: this.handleRemove,
      },
    })
    onDelete()
  }

  handleShowPreview = initialFileId => {
    const {
      showModal,
      booking: { videoRecords },
      onPreview,
    } = this.props

    showModal({
      modalType: 'FILES_PREVIEW_MODAL',
      modalProps: {
        files: videoRecords,
        initialFileId,
        canRemove: this.canRemove,
        onRemoveFile: this.handleRemove,
      },
    })
    onPreview()
  }

  render = () => (
    <RecordingsComponent
      {...this.props}
      onShowPreview={this.handleShowPreview}
      canRemove={this.canRemove()}
      canManage={this.canManage()}
      onConfirmRemove={this.handleConfirmRemove}
    />
  )
}

const mapStateToProps = state => ({
  isEmployeeLoggedIn: isEmployeeLoggedInSelector(state),
  permissions: currentEmployeePermissionsSelector(state),
})

const mapDispatchToProps = {
  ...modalActions,
  removeVideoRecord: removeVideoRecordAction,
}

export { Recordings as RecordingsContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Recordings)
