/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedNumber } from 'react-intl'

const TableSectionComponent = ({ transactionItems }) => (
  <div className="preview-invoice__table">
    <div className="preview-invoice__table-header">
      <div className="preview-invoice__table-cell preview-invoice__table-cell--header preview-invoice__table-cell--first">
        <p className="text-truncate mb-0" data-cy="transaction-items-service-title">
          <FormattedMessage id="payments.review.table.service" />
        </p>
      </div>
      <div className="preview-invoice__table-cell preview-invoice__table-cell--header d-none d-sm-block">
        <p className="text-truncate mb-0" data-cy="transaction-items-quantity-title">
          <FormattedMessage id="payments.review.table.quantity" />
        </p>
      </div>
      <div className="preview-invoice__table-cell preview-invoice__table-cell--header d-none d-sm-block">
        <p className="text-truncate mb-0" data-cy="transaction-items-price-title">
          <FormattedMessage id="payments.review.table.price" />
        </p>
      </div>
      <div className="preview-invoice__table-cell preview-invoice__table-cell--header d-none d-sm-block">
        <p className="text-truncate mb-0" data-cy="transaction-items-total-title">
          <FormattedMessage id="payments.review.table.total" />
        </p>
      </div>
    </div>
    {transactionItems.map(({ id, name, quantity, price, total }) => (
      <div key={id} className="preview-invoice__table-row">
        <div className="preview-invoice__table-cell preview-invoice__table-cell--first">
          <p className="preview-invoice__table-cell-title text-truncate" data-cy="transaction-items-service-name">
            {name}
          </p>
          <div className="preview-invoice__table-mobile-row">
            <div className="preview-invoice__table-mobile-cell">
              <p className="in-gray-700 mb-4">
                <FormattedMessage id="payments.review.table.quantity" />
              </p>
              <p className="mb-0">{quantity}</p>
            </div>
            <div className="preview-invoice__table-mobile-cell">
              <p className="in-gray-700 mb-4">
                <FormattedMessage id="payments.review.table.price" />
              </p>
              <p className="mb-0">{price}</p>
            </div>
            <div className="preview-invoice__table-mobile-cell">
              <p className="in-gray-700 mb-4">
                <FormattedMessage id="payments.review.table.total" />
              </p>
              <p className="mb-0">{total}</p>
            </div>
          </div>
        </div>
        <div className="preview-invoice__table-cell d-none d-sm-block">
          <p className="text-truncate mb-0" data-cy="transaction-items-quantity">
            {quantity}
          </p>
        </div>
        <div className="preview-invoice__table-cell d-none d-sm-block">
          <p className="text-truncate mb-0" data-cy="transaction-items-price-value">
            <FormattedNumber value={price} style="currency" currency="USD" />
          </p>
        </div>
        <div className="preview-invoice__table-cell d-none d-sm-block">
          <p className="text-truncate mb-0" data-cy="transaction-items-total-value">
            <FormattedNumber value={total} style="currency" currency="USD" />
          </p>
        </div>
      </div>
    ))}
  </div>
)

TableSectionComponent.propTypes = {
  transactionItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default TableSectionComponent
