import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchBookingSettings = () => ({
  type: types.FETCH_BOOKING_SETTINGS,
})

export const setBookingSettingsId = id => ({
  type: types.SET_BOOKING_SETTINGS_ID,
  id,
})

export const updateBookingSettings = makeFormSubmitAction(types.UPDATE_BOOKING_SETTINGS)
