import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CLIENT_TRANSACTIONS } from 'state/concepts/payments/types'
import { setTransactions, setBlankState } from 'state/concepts/payments/actions'
import { paginationSelector, filtersSelector } from 'state/concepts/payments/selectors'
import { fetchClientTransactionsEndpoint } from 'state/concepts/payments/endpoints'

const fetchClientTransactionsOperation = createLogic({
  type: FETCH_CLIENT_TRANSACTIONS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchClientTransactionsEndpoint
    const state = getState()
    const { services, employees, statuses } = filtersSelector(state)
    const params = {
      include: [
        'user-profile',
        'workspace',
        'booking',
        'receipt-template',
        'transaction-items',
        'refunded-transaction',
        'recurring-payment',
      ],
      page: paginationSelector(state),
      filter: {
        service_ids: services,
        user_profile_ids: employees,
        statuses,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setTransactions(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchClientTransactionsOperation
