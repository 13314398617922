import { createLogic } from 'redux-logic'
import { forEach, keys, includes } from 'ramda'
import axios, { CancelToken, isCancel } from 'axios'

import isNetworkError from 'utils/isNetworkError'
import progressCallback from 'utils/progressCallback'
import requestErrorHandler from 'lib/requestErrorHandler'
import { setUploadProgress, updateAttachmentUpload, addUploadError } from 'state/concepts/chat/actions'
import { UPLOAD_ATTACHMENT, CANCEL_ATTACHMENT_UPLOAD } from 'state/concepts/chat/types'

const uploadAttachmentOperation = createLogic({
  type: UPLOAD_ATTACHMENT,
  latest: false,
  warnTimeout: 0,

  async process({ action, action$ }, dispatch, done) {
    const { uploadId, file, meta } = action

    try {
      const params = new FormData()
      forEach(key => params.append(key, meta.fields[key]), keys(meta.fields))
      params.append('file', file)

      const source = CancelToken.source()
      action$.subscribe(newAction => {
        const isCancelAllowed = newAction.type === CANCEL_ATTACHMENT_UPLOAD && includes(uploadId, newAction.uploadIds)

        if (isCancelAllowed) {
          source.cancel()
        }
      })

      await axios.post(meta.url, params, {
        onUploadProgress: progressCallback(progress => dispatch(setUploadProgress(uploadId, progress))),
        cancelToken: source.token,
      })

      dispatch(updateAttachmentUpload(uploadId, file, meta))
    } catch (error) {
      if (isNetworkError(error)) {
        dispatch(addUploadError(uploadId))
      } else if (!isCancel(error)) {
        requestErrorHandler({ error, dispatch })
      }
    }

    done()
  },
})

export default uploadAttachmentOperation
