import endpoint from 'utils/endpoint'
import {
  paymentAccountRoute,
  stripeConnectLinkRoute,
  stripeConnectRoute,
  manualPaymentRoute,
  payPalConnectLinkRoute,
  payPalConnectRoute,
  validateDisconnectPaymentMethodRoute,
  stripeAccountPosStatusRoute,
} from 'lib/apiRoutes'

import * as types from './types'

export const fetchPaymentSettingsEndpoint = endpoint(types.FETCH_PAYMENT_SETTINGS, 'GET', paymentAccountRoute)
export const getStripeConnectLinkEndpoint = endpoint(types.GET_STRIPE_CONNECT_LINK, 'POST', stripeConnectLinkRoute)
export const getPayPalConnectLinkEndpoint = endpoint(types.GET_PAYPAL_CONNECT_LINK, 'POST', payPalConnectLinkRoute)
export const disconnectStripeEndpoint = endpoint(types.DISCONNECT_STRIPE, 'DELETE', stripeConnectRoute)
export const disconnectPaypalEndpoint = endpoint(types.DISCONNECT_PAYPAL, 'DELETE', payPalConnectRoute)
export const connectStripeEndpoint = endpoint(types.CONNECT_STRIPE, 'POST', stripeConnectRoute)
export const updateManualPaymentEndpoint = endpoint(types.UPDATE_MANUAL_PAYMENT, 'PUT', manualPaymentRoute)
export const disconnectManualPaymentEndpoint = endpoint(types.DISCONNECT_MANUAL_PAYMENT, 'DELETE', manualPaymentRoute)
export const createManualPaymentEndpoint = endpoint(types.CREATE_MANUAL_PAYMENT, 'POST', manualPaymentRoute)
export const validateDisconnectStripeEndpoint = endpoint(
  types.VALIDATE_DISCONNECT_STRIPE,
  'GET',
  validateDisconnectPaymentMethodRoute,
)
export const updateStripePosEndpoint = endpoint(types.UPDATE_STRIPE_POS, 'PUT', stripeAccountPosStatusRoute)
