import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { getIn } from 'formik'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'
import TextArea from 'views/shared/TextArea'
import InputMessage from '../InputMessage'

const TextAreaField = ({
  id,
  type,
  disabled,
  label,
  icon,
  field,
  placeholder,
  className,
  wrapperClassName,
  form: { touched, errors },
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)

  const wrapperClassNames = classNames('main-input main-input--textarea', wrapperClassName, {
    'main-input--disabled': disabled,
    'main-input--has-message': fieldTouched && fieldErrors,
    'main-input--has-message-error': fieldTouched && fieldErrors,
  })

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedOrRawMessage message={label} />
        </label>
      )}
      <div className="relative">
        <TextArea
          className={classNames('main-input__field', className)}
          type={type}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          {...field}
          {...props}
        />
      </div>
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
    </div>
  )
}

TextAreaField.defaultProps = {
  id: null,
  type: 'text',
  disabled: false,
  label: null,
  icon: null,
  placeholder: null,
  className: null,
  wrapperClassName: '',
}

TextAreaField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape().isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.shape(),
    }),
    PropTypes.string,
  ]),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
}

export default TextAreaField
