import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusGone } from 'utils/getErrorStatus'
import { employeeAccountSettingsGeneralRoute } from 'lib/routes'
import { CONFIRM_EMPLOYEE_EMAIL } from 'state/concepts/employee/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { currentEmployeeSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import updateDataHelper from 'utils/updateDataHelper'
import redirect from 'utils/redirect'

import { confirmEmployeeOwnEmailEndpoint } from '../endpoints'

const confirmEmployeeOwnEmailOperation = createLogic({
  type: CONFIRM_EMPLOYEE_EMAIL,
  latest: true,

  async process({ action: { token }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const employee = currentEmployeeSelector(state)
    const { endpoint, url } = confirmEmployeeOwnEmailEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, { token })

      const response = updateDataHelper(state.data, 'userProfile', employee.id, {
        attributes: {
          email: employee.newEmail,
          newEmail: null,
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.emailHasBeenConfirmed' },
        }),
      )
    } catch (error) {
      if (isErrorStatusGone(error)) {
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.invalidLink' },
            kind: 'error',
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint, excludeErrorArray: [403, 404] })
      }
    }

    redirect({ href: employeeAccountSettingsGeneralRoute, workspace: currentWorkspaceCodeSelector(state) })
    done()
  },
})

export default confirmEmployeeOwnEmailOperation
