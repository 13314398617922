import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { workspaceTwoFactorSettingRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { UPDATE_WORKSPACE_TWO_FACTOR_SETTINGS } from 'state/concepts/company/types'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceSelector } from 'state/concepts/session/selectors'

const updateWorkspaceTwoFactorSettingsOperation = createLogic({
  type: UPDATE_WORKSPACE_TWO_FACTOR_SETTINGS,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const currentWorkspace = currentWorkspaceSelector(state)

    try {
      const params = {
        required: values.twoFaEnabled,
        app: values.app,
        sms: values.sms,
        remember_me: values.rememberMe,
        frequency: values.rememberMe ? values.frequency : undefined,
      }

      await httpClient.put(workspaceTwoFactorSettingRoute, params)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.workspaceTwoFactorSettingsUpdated' },
        }),
      )

      const response = updateDataHelper(
        state.data,
        'workspaceTwoFactorSetting',
        currentWorkspace.workspaceTwoFactorSetting.id,
        {
          attributes: {
            required: values.twoFaEnabled,
            app: values.app,
            sms: values.sms,
            rememberMe: values.rememberMe,
            frequency: values.rememberMe ? values.frequency : currentWorkspace.workspaceTwoFactorSetting.frequency,
          },
        },
      )

      dispatch(dataApiSuccess({ response }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateWorkspaceTwoFactorSettingsOperation
