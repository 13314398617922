import { propOr } from 'ramda'

export const parseData = data => JSON.parse(data)

export const parseIdentifier = data => {
  const parsedData = parseData(data)
  return propOr('{}', 'identifier', parsedData)
}

export const toIdentifier = ({ channel, params }) => JSON.stringify({ channel, ...params })

export const toCommand = ({ command, channel, action, params, data }) =>
  JSON.stringify({
    command,
    data: JSON.stringify({ action, data }),
    identifier: toIdentifier({ channel, params }),
  })
