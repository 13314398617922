import { BroadcastChannel, enforceOptions } from 'broadcast-channel'

if (process.env.NODE_ENV === 'test') {
  enforceOptions({ type: 'simulate' })
}

const broadcastChannel = new BroadcastChannel('broadcast-channel-main')

export const addBroadcastListener = handler => broadcastChannel.addEventListener('message', handler)
export const removeBroadcastListener = handler => broadcastChannel.removeEventListener('message', handler)
export const broadcastMessage = message => broadcastChannel.postMessage(message)

export default broadcastChannel
