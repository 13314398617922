import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { VALIDATE_CREATE_SERVICE_STEP } from 'state/concepts/service/types'
import { CREATE_SERVICE_STEPS } from 'lib/constants/services'
import { serviceDetailsFormToParams, serviceSettingsFormToParams } from 'utils/services/serviceFormToParams'
import { servicesValidationsDetailsRoute, servicesValidationsSettingsRoute } from 'lib/apiRoutes'

const validateCreateServiceStepOperation = createLogic({
  type: VALIDATE_CREATE_SERVICE_STEP,
  latest: true,

  async process({ action: { values, step, finishStep, form }, httpClient }, dispatch, done) {
    try {
      const routeAndParams = {
        [CREATE_SERVICE_STEPS[1]]: [servicesValidationsDetailsRoute, serviceDetailsFormToParams],
        [CREATE_SERVICE_STEPS[2]]: [servicesValidationsSettingsRoute, serviceSettingsFormToParams],
      }[step]

      if (routeAndParams) {
        const [url, mapValues] = routeAndParams

        const params = mapValues(values)

        await httpClient.post(url, params)
      }

      finishStep()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default validateCreateServiceStepOperation
