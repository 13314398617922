import fetchPublicWebsite from './fetchPublicWebsite'
import fetchPublicWebsiteReviews from './fetchPublicWebsiteReviews'
import fetchWebsite from './fetchWebsite'
import createWebsite from './createWebsite'
import updateGeneralDetails from './updateGeneralDetails'
import updateContentDetails from './updateContentDetails'
import fetchPublicProviders from './fetchPublicProviders'
import fetchPublicServices from './fetchPublicServices'
import fetchPublicLocations from './fetchPublicLocations'
import deleteWebsite from './deleteWebsite'
import publishDraftWebsite from './publishDraftWebsite'

export default [
  fetchPublicWebsite,
  fetchPublicWebsiteReviews,
  fetchWebsite,
  createWebsite,
  updateGeneralDetails,
  updateContentDetails,
  fetchPublicProviders,
  fetchPublicServices,
  fetchPublicLocations,
  deleteWebsite,
  publishDraftWebsite,
]
