const namespace = 'companyClientImport'

export const SET_CLIENT_IMPORT_ID = `${namespace}/SET_CLIENT_IMPORT_ID`
export const RESET_CLIENT_IMPORT_ID = `${namespace}/RESET_CLIENT_IMPORT_ID`
export const SET_UPLOAD_PROGRESS = `${namespace}/SET_UPLOAD_PROGRESS`
export const UPLOAD_FILE = `${namespace}/UPLOAD_FILE`
export const CANCEL_UPLOAD_FILE = `${namespace}/CANCEL_UPLOAD_FILE`
export const VALIDATE_CLIENT_IMPORT = `${namespace}/VALIDATE_CLIENT_IMPORT`
export const PROCESS_CLIENT_IMPORT = `${namespace}/PROCESS_CLIENT_IMPORT`
export const RECEIVE_CLIENT_IMPORT = `${namespace}/RECEIVE_CLIENT_IMPORT`
export const SET_MATCH_FORM_STATE = `${namespace}/SET_MATCH_FORM_STATE`
export const SET_ERRORS_FILE_URL = `${namespace}/SET_ERRORS_FILE_URL`
export const RESET_ERRORS_FILE_URL = `${namespace}/RESET_ERRORS_FILE_URL`
export const SET_IS_PROCESSING = `${namespace}/SET_IS_PROCESSING`
