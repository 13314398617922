import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CARD_READERS } from 'state/concepts/cardReaders/types'
import { fetchCardReadersEndpoint } from 'state/concepts/cardReaders/endpoints'
import { setCardReaders } from 'state/concepts/cardReaders/actions'

const fetchCardReadersOperation = createLogic({
  type: FETCH_CARD_READERS,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchCardReadersEndpoint
    const params = { include: 'location' }
    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setCardReaders(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchCardReadersOperation
