import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { UPLOAD_OPTIONS } from 'lib/constants/businessFiles'
import { uploadFile as uploadFileAction } from 'state/concepts/myBusinessFiles/actions'
import { hideModal as hideModalAction } from 'state/modal/actions'
import Button from 'views/shared/Button'
import Modal from 'views/shared/Modal'
import RadioField from 'views/shared/RadioField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Cross from 'views/shared/icons/Cross'

const UploadOptionsModal = ({ onClose, filesMeta, files, parentId }) => {
  const hideModal = useDispatchAction(hideModalAction)
  const uploadFile = useDispatchAction(uploadFileAction)

  const onSubmit = values => {
    filesMeta.forEach((fileMeta, index) => {
      uploadFile(files[index], fileMeta, parentId, values.replaceOldFile === 'true')
    })

    hideModal()
  }

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage id="myBusinessFiles.uploadOptionsModal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik onSubmit={onSubmit} initialValues={{ replaceOldFile: 'true' }}>
          <>
            <div className="main-modal__body">
              <div className="mb-18">
                <FormattedMessage id="myBusinessFiles.uploadOptionsModal.body" />
              </div>
              <Field
                className="mb-12"
                id="replaceOldFile"
                name="replaceOldFile"
                component={RadioField}
                options={UPLOAD_OPTIONS}
              />
            </div>
            <div className="main-modal__footer">
              <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
              <SubmitFormButton text={{ id: 'shared.upload' }} isSubmitting={false} allowPristine />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

UploadOptionsModal.defaultProps = {
  parentId: null,
}

UploadOptionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  filesMeta: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  parentId: PropTypes.string,
}

export default UploadOptionsModal
