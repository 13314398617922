import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { addBroadcastListener, removeBroadcastListener } from 'lib/broadcastChannel'
import { CLIENT_LOGOUT_EVENT, EMPLOYEE_LOGOUT_EVENT } from 'lib/constants/broadcastChannel'
import { broadcastChannelNamespaceSelector } from 'state/concepts/session/selectors'
import {
  employeePostLogout as employeePostLogoutAction,
  clientPostLogout as clientPostLogoutAction,
} from 'state/concepts/session/actions'

class BroadcastChannelConnection extends React.Component {
  static propTypes = {
    namespace: PropTypes.string,
    employeePostLogout: PropTypes.func.isRequired,
    clientPostLogout: PropTypes.func.isRequired,
  }

  static defaultProps = {
    namespace: null,
  }

  componentDidMount() {
    addBroadcastListener(this.handleBroadcastChannelEvents)
  }

  componentWillUnmount() {
    removeBroadcastListener(this.handleBroadcastChannelEvents)
  }

  get clientLogoutEvent() {
    const { namespace } = this.props

    return `${namespace}/${CLIENT_LOGOUT_EVENT}`
  }

  get employeeLogoutEvent() {
    const { namespace } = this.props

    return `${namespace}/${EMPLOYEE_LOGOUT_EVENT}`
  }

  handleBroadcastChannelEvents = msg => {
    const { clientPostLogout, employeePostLogout } = this.props

    if (msg === this.clientLogoutEvent) {
      clientPostLogout({ withoutBroadcast: true })
    }
    if (msg === this.employeeLogoutEvent) {
      employeePostLogout({ withoutBroadcast: true })
    }
  }

  render = () => null
}

const mapStateToProps = state => ({
  namespace: broadcastChannelNamespaceSelector(state),
})

const mapDispatchToProps = {
  employeePostLogout: employeePostLogoutAction,
  clientPostLogout: clientPostLogoutAction,
}

export { BroadcastChannelConnection as BroadcastChannelConnectionContainer }
export default connect(mapStateToProps, mapDispatchToProps)(BroadcastChannelConnection)
