import { createLogic } from 'redux-logic'
import { prop, head, propEq } from 'ramda'
import { DateTime } from 'luxon'

import { UPDATE_LAST_MESSAGE } from 'state/concepts/chats/types'
import updateDataHelper from 'utils/updateDataHelper'
import { dataApiSuccess } from 'state/data/actions'
import { lastChatMessageSelector } from 'state/concepts/chats/selectors'

const updateLastMessageOperation = createLogic({
  type: UPDATE_LAST_MESSAGE,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const state = getState()
    const { messages } = action
    const receivedMessage = head(messages)
    const chatId = prop('chatId', receivedMessage)
    const isDestroyed = propEq(true, 'destroyed', receivedMessage)
    const lastChatMessage = lastChatMessageSelector(state, chatId)
    let data = lastChatMessage ? { id: lastChatMessage.id, type: 'message' } : null

    if (isDestroyed) {
      const message = prop('lastChatMessage', receivedMessage)
      data = message ? { id: message.id, type: 'message' } : null
    } else if (!lastChatMessage) {
      data = { id: receivedMessage.id, type: 'message' }
    } else {
      const createdAt = DateTime.fromISO(prop('createdAt', receivedMessage))
      const lastChatMessageCreatedAt = DateTime.fromISO(prop('createdAt', lastChatMessage))

      if (receivedMessage.id === lastChatMessage.id || createdAt > lastChatMessageCreatedAt) {
        data = { id: receivedMessage.id, type: 'message' }
      }
    }

    const response = updateDataHelper(state.data, 'chat', [chatId], {
      relationships: {
        message: { data },
      },
    })

    dispatch(dataApiSuccess({ response }))

    done()
  },
})

export default updateLastMessageOperation
