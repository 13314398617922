import { combineReducers } from '@reduxjs/toolkit'
import { camelCase } from 'lodash'
import { adjust, fromPairs, map, pipe, toPairs, uniq, without } from 'ramda'

import * as types from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case types.ADD_STORAGE_FILES_IDS:
      return uniq([...state, ...action.ids])
    case types.REMOVE_STORAGE_FILE_IDS:
      return without(action.ids, state)
    case types.SET_STORAGE_FILES_IDS:
      return action.ids
    case types.RESET_STORAGE_FILES_IDS:
      return []
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const autoUpgrade = (state = false, action) => {
  switch (action.type) {
    case types.SET_AUTO_UPGRADE:
      return action.autoUpgrade
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
  employees: [],
  clients: [],
  types: [],
  lastModified: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case types.RESET_FILTER_PARAMS:
      return { ...filtersInitialState }
    default:
      return state
  }
}

const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_SELECTED_IDS:
      return action.ids
    case types.RESET_STORAGE_FILES_IDS:
      return []
    case types.REMOVE_STORAGE_FILE_IDS:
      return without(action.ids, state)
    default:
      return state
  }
}

const storageSpace = (state = {}, action) => {
  switch (action.type) {
    case types.SET_STORAGE_SPACE:
      return {
        ...state,
        ...pipe(toPairs, map(adjust(0, camelCase)), fromPairs)(action.storageSpace),
      }
    default:
      return state
  }
}

export default combineReducers({
  ids,
  isBlankState,
  filters,
  selectedIds,
  storageSpace,
  autoUpgrade,
})
