import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const StarO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 20 20"
    data-cy={dataCy}
  >
    <path d="M19.8603 7.61914C19.7976 7.4375 19.6836 7.27796 19.532 7.15987C19.3804 7.04177 19.1978 6.97019 19.0064 6.95381L13.3561 6.12961L10.8239 0.995717C10.7426 0.827827 10.6156 0.686236 10.4576 0.587164C10.2995 0.488092 10.1167 0.435547 9.93018 0.435547C9.74364 0.435547 9.56087 0.488092 9.4028 0.587164C9.24474 0.686236 9.11778 0.827827 9.03647 0.995717L6.50428 6.11968L0.854016 6.95381C0.670231 6.97994 0.497448 7.05705 0.355274 7.17641C0.2131 7.29576 0.107228 7.45258 0.0496727 7.62907C-0.00301177 7.80153 -0.00773958 7.98508 0.0359967 8.16002C0.0797329 8.33497 0.170282 8.4947 0.297927 8.62208L4.39908 12.5941L3.40607 18.2345C3.36598 18.4216 3.3808 18.6163 3.44875 18.7952C3.51671 18.9741 3.6349 19.1295 3.7891 19.2428C3.94331 19.3561 4.12697 19.4224 4.31798 19.4338C4.509 19.4452 4.69923 19.4011 4.8658 19.3069L9.93018 16.6556L14.9946 19.3069C15.1339 19.3856 15.2913 19.4266 15.4514 19.4261C15.6617 19.4268 15.8669 19.3608 16.0372 19.2374C16.1913 19.1271 16.3106 18.9749 16.3809 18.799C16.4513 18.623 16.4699 18.4306 16.4344 18.2444L15.4414 12.6041L19.5426 8.63201C19.686 8.51054 19.7919 8.35089 19.8482 8.1716C19.9044 7.99231 19.9086 7.80073 19.8603 7.61914ZM13.7533 11.5912C13.6384 11.7028 13.5521 11.8406 13.502 11.9928C13.4518 12.1449 13.4393 12.307 13.4653 12.4651L14.1803 16.6357L10.4466 14.6497C10.3014 14.5779 10.1417 14.5406 9.97983 14.5406C9.81794 14.5406 9.65823 14.5779 9.51312 14.6497L5.77938 16.6357L6.49435 12.4651C6.5204 12.307 6.50782 12.1449 6.45767 11.9928C6.40753 11.8406 6.32131 11.7028 6.20637 11.5912L3.22732 8.61215L7.40792 8.00641C7.56879 7.98404 7.72171 7.92254 7.85329 7.82732C7.98487 7.7321 8.09108 7.60607 8.16261 7.46025L9.93018 3.67686L11.7971 7.47018C11.8686 7.616 11.9748 7.74203 12.1064 7.83725C12.238 7.93247 12.3909 7.99397 12.5517 8.01634L16.7323 8.62208L13.7533 11.5912Z" />
  </svg>
)

StarO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'star-icon',
}

StarO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default StarO
