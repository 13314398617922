import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { usersRoleRoute } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { CHANGE_EMPLOYEE_ROLE } from 'state/concepts/employees/types'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'

const changeEmployeeRoleOperation = createLogic({
  type: CHANGE_EMPLOYEE_ROLE,
  latest: true,

  async process(
    {
      action: {
        values: { roleName, isProfileUpdate },
        form,
        employee,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const url = usersRoleRoute(employee.id)
    const params = { role: roleName }

    try {
      await httpClient.put(url, params)

      const response = updateDataHelper(state.data, 'userProfile', employee.id, { attributes: { roleName } })

      dispatch(dataApiSuccess({ response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: isProfileUpdate ? 'notifications.employeeProfileChanged' : 'notifications.employeeRoleChanged',
          },
        }),
      )
      form.resetForm({ values: { roleName, isProfileUpdate } })
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    form.setSubmitting(false)
    done()
  },
})

export default changeEmployeeRoleOperation
