import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { mergeDeepRight } from 'ramda'

import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { CREATE_MANUAL_PAYMENT } from 'state/concepts/paymentSettings/types'
import { createManualPaymentEndpoint } from 'state/concepts/paymentSettings/endpoints'
import updateDataHelper from 'utils/updateDataHelper'
import { paymentAccountSelector } from '../selectors'

const createManualPaymentOperation = createLogic({
  type: CREATE_MANUAL_PAYMENT,
  latest: true,

  async process({ action: { form, values }, getState, httpClient }, dispatch, done) {
    const { url, endpoint } = createManualPaymentEndpoint
    const state = getState()
    const { id } = paymentAccountSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))

      const { data } = await httpClient.post(url, {
        payment_instructions: values.paymentInstructions,
      })

      const apiResponse = normalize(data)

      const response = updateDataHelper(state.data, 'paymentAccount', id, {
        relationships: {
          manualPayment: {
            data: {
              type: 'manualPayment',
              id: data.data.id,
            },
          },
        },
      })

      dispatch(dataApiSuccess({ endpoint, response: mergeDeepRight(response, apiResponse) }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.manualPaymentWasConnected',
          },
        }),
      )
      dispatch(hideModal())
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default createManualPaymentOperation
