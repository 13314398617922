import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import ArrowRight from 'views/shared/icons/ArrowRight'
import { EDIT_WEBSITE_PREVIEW_STEPS } from 'lib/constants/website'
import Cross from 'views/shared/icons/Cross'

const MainMenu = ({ onClose, handleSetCurrentStep }) => (
  <>
    <div className="ws-header__editor-header">
      <span data-cy="edit-website-header" className="text-subheader font-600">
        <FormattedMessage id="websitePreview.editWebsite" />
      </span>
      <button data-cy="close-sidebar-btn" type="button" onClick={onClose}>
        <Cross size={10} className="mr-4" />
      </button>
    </div>
    <div className="ws-header__editor-body ws-header__editor-body--no-indent">
      <div
        className="ws-header__editor-body-item ws-header__editor-body-item--md"
        role="button"
        onClick={handleSetCurrentStep(EDIT_WEBSITE_PREVIEW_STEPS.enableSections)}
      >
        <p data-cy="edit-sections-item" className="text-subheader mb-0">
          <FormattedMessage id="websitePreview.enableDisableSections" />
        </p>
        <ArrowRight size={12} className="mt-4 mr-4" />
      </div>
      <div
        className="ws-header__editor-body-item ws-header__editor-body-item--md"
        role="button"
        onClick={handleSetCurrentStep(EDIT_WEBSITE_PREVIEW_STEPS.googleSeo)}
        data-cy="google-seo-button"
      >
        <p data-cy="edit-seo-info-item" className="text-subheader mb-0">
          <FormattedMessage id="websitePreview.getSeoSettings" />
        </p>
        <ArrowRight size={12} className="mt-4 mr-4" />
      </div>
    </div>
  </>
)

MainMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSetCurrentStep: PropTypes.func.isRequired,
}

export default MainMenu
