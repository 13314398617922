import { Component } from 'react'

import ShowMoreTextComponent from './component'

class ShowMoreText extends Component {
  state = {
    shouldTruncate: true,
    isTruncated: false,
  }

  toggleTruncate = () => {
    this.setState(({ shouldTruncate }) => ({ shouldTruncate: !shouldTruncate }))
  }

  handleTruncate = isTruncated => {
    this.setState({ isTruncated })
  }

  render = () => (
    <ShowMoreTextComponent
      {...this.state}
      {...this.props}
      toggleTruncate={this.toggleTruncate}
      handleTruncate={this.handleTruncate}
    />
  )
}

export default ShowMoreText
