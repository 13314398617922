import endpoint from 'utils/endpoint'
import {
  clientRoute,
  clientsRoute,
  clientsAccountProfileRoute,
  clientsAccountEmailRoute,
  clientsAccountAvatarRoute,
  clientsAccountConfirmationRoute,
  clientsAccountConfirmationResendRoute,
  clientsAccountEmailResendChangeRequestRoute,
  clientsAccountLogoutIntervalRoute,
  clientsAccountClientNotificationSettingsRoute,
  clientShareFilesRoute,
  clientSendDocumentsRoute,
  clientsAccountRoute,
  clientsAccountRestoreRoute,
  clientsUsersAssignRoute,
  sendInvitationToClientPortalRoute,
  clientContactsAccountRestoreRoute,
} from 'lib/apiRoutes'
import {
  FETCH_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_OWN_PROFILE,
  DELETE_CLIENT_OWN_ACCOUNT,
  CONFIRM_CLIENT_OWN_EMAIL,
  UPDATE_CLIENT_OWN_PROFILE,
  UPLOAD_CLIENT_OWN_AVATAR,
  REMOVE_CLIENT_OWN_AVATAR,
  VALIDATE_CONFIRMATION_TOKEN,
  RESEND_EMAIL_CONFIRMATION,
  RESEND_EMAIL_CHANGE_CONFIRMATION,
  UPDATE_LOGOUT_INTERVAL,
  FETCH_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_CLIENT_PROFILE_FIELDS,
  SHARE_FILES,
  SEND_DOCUMENTS,
  UPDATE_CLIENT_CONTACT_DETAILS,
  RESTORE_ACCOUNT,
  ASSIGN_CLIENT_EXPERTS,
  SEND_INVITATION_TO_CLIENT_PORTAL,
  RESTORE_CONTACT_ACCOUNT,
} from './types'

export const fetchClientEndpoint = clientId => endpoint(FETCH_CLIENT, 'GET', clientRoute(clientId))
export const createClientEndpoint = endpoint(CREATE_CLIENT, 'POST', clientsRoute)
export const updateClientEndpoint = clientId => endpoint(UPDATE_CLIENT, 'PUT', clientRoute(clientId))
export const deleteClientEndpoint = clientId => endpoint(DELETE_CLIENT, 'DELETE', clientRoute(clientId))
export const sendInvitationToClientPortalEndpoint = clientId =>
  endpoint(SEND_INVITATION_TO_CLIENT_PORTAL, 'POST', sendInvitationToClientPortalRoute(clientId))
export const updateClientProfileFieldsEndpoint = clientId =>
  endpoint(UPDATE_CLIENT_PROFILE_FIELDS, 'PUT', clientRoute(clientId))
export const getClientOwnProfileEndpoint = endpoint(GET_CLIENT_OWN_PROFILE, 'GET', clientsAccountProfileRoute)
export const deleteClientOwnAccountEndpoint = endpoint(DELETE_CLIENT_OWN_ACCOUNT, 'DELETE', clientsAccountProfileRoute)
export const updateClientContactDetailsEndpoint = endpoint(UPDATE_CLIENT_CONTACT_DETAILS, 'PUT', clientsAccountRoute)
export const confirmClientOwnEmailEndpoint = endpoint(CONFIRM_CLIENT_OWN_EMAIL, 'PUT', clientsAccountEmailRoute)
export const updateClientOwnProfileEndpoint = endpoint(UPDATE_CLIENT_OWN_PROFILE, 'PUT', clientsAccountProfileRoute)
export const uploadClientOwnAvatarEndpoint = endpoint(UPLOAD_CLIENT_OWN_AVATAR, 'PUT', clientsAccountAvatarRoute)
export const removeClientOwnAvatarEndpoint = endpoint(REMOVE_CLIENT_OWN_AVATAR, 'DELETE', clientsAccountAvatarRoute)
export const validateConfirmationTokenEndpoint = endpoint(
  VALIDATE_CONFIRMATION_TOKEN,
  'POST',
  clientsAccountConfirmationRoute,
)
export const resendEmailConfirmationEndpoint = endpoint(
  RESEND_EMAIL_CONFIRMATION,
  'POST',
  clientsAccountConfirmationResendRoute,
)
export const resendEmailChangeConfirmationEndpoint = endpoint(
  RESEND_EMAIL_CHANGE_CONFIRMATION,
  'POST',
  clientsAccountEmailResendChangeRequestRoute,
)
export const clientsAccountLogoutIntervalEndpoint = endpoint(
  UPDATE_LOGOUT_INTERVAL,
  'PUT',
  clientsAccountLogoutIntervalRoute,
)
export const fetchNotificationSettingsEndpoint = endpoint(
  FETCH_NOTIFICATION_SETTINGS,
  'GET',
  clientsAccountClientNotificationSettingsRoute,
)
export const updateNotificationSettingsEndpoint = endpoint(
  UPDATE_NOTIFICATION_SETTINGS,
  'PUT',
  clientsAccountClientNotificationSettingsRoute,
)
export const shareFilesEndpoint = clientId => endpoint(SHARE_FILES, 'POST', clientShareFilesRoute(clientId))
export const sendDocumentsEndpoint = clientId => endpoint(SEND_DOCUMENTS, 'POST', clientSendDocumentsRoute(clientId))
export const restoreAccountEndpoint = endpoint(RESTORE_ACCOUNT, 'POST', clientsAccountRestoreRoute)
export const restoreContactAccountEndpoint = contactId =>
  endpoint(RESTORE_CONTACT_ACCOUNT, 'POST', clientContactsAccountRestoreRoute(contactId))
export const assignExpertsEndpoint = clientId =>
  endpoint(ASSIGN_CLIENT_EXPERTS, 'PUT', clientsUsersAssignRoute(clientId))
