const company = {
  'company.client.profile.title': '_(Client) profile',
  'company.client.profile.alert.accountDeactivated': 'This account is deactivated by _(client).',
  'company.client.profile.emptyState.title': 'We couldn’t find this _(client)',
  'company.client.profile.emptyState.adminDescription':
    'Looks like this _(client) has been deleted. Try creating a new one.',
  'company.client.profile.emptyState.expertDescription': 'Looks like this _(client) has been deleted.',
  'company.client.profile.emptyState.createNewClient': 'Create new _(client)',
  'company.client.profile.showInformation': 'Show _(client) information',
  'company.client.profile.hideInformation': 'Hide _(client) information',
  'company.client.profile.tabs.overview': 'Overview',
  'company.client.profile.tabs.tasks': 'Tasks',
  'company.client.profile.tabs.appointments': '_(Appointments)',
  'company.client.profile.tabs.notes': 'Notes',
  'company.client.profile.tabs.files': 'Files',
  'company.client.profile.tabs.contacts': 'Contacts',
  'company.client.profile.tabs.intakeForms': 'Forms',
  'company.client.profile.tabs.agreements': '_(Service agreements)',
  'company.client.profile.tabs.reviews': 'Reviews',
  'company.client.profile.tabs.documents': 'Documents',
  'company.client.profile.tabs.forms': 'Forms',
  'company.client.profile.menu.sendMessage': 'Send message',
  'company.client.profile.menu.request': 'Request',
  'company.client.profile.menu.request.payment': 'Payment',
  'company.client.profile.menu.request.recurringPayment': 'Recurring payment',
  'company.client.profile.menu.addNote': 'Add note',
  'company.client.profile.menu.createTask': 'Create task',
  'company.client.profile.menu.addForm': 'Add form',
  'company.client.profile.menu.addFiles': 'Add files',
  'company.client.profile.menu.uploadFiles': 'Upload files',
  'company.client.profile.menu.addBusinessFiles': 'Add business files',
  'company.client.profile.menu.share': 'Share',
  'company.client.profile.menu.share.form': 'Form',
  'company.client.profile.menu.share.serviceAgreement': '_(Service agreement)',
  'company.client.profile.menu.share.file': 'File',
  'company.client.profile.menu.editInfo': 'Edit information',
  'company.client.profile.menu.delete': 'Delete',
  'company.client.profile.menu.shareWithClient': 'Share with _(client)',
  'company.client.profile.menu.addDocument': 'Add document',
  'company.client.profile.menu.blankDocument': 'Blank document',
  'company.client.profile.menu.fromTemplate': 'From a template',
  'company.client.profile.backToListOfClients': 'List of _(clients)',
  'company.client.profile.editInfo': 'Edit _(client) information',
  'company.client.profile.addFiles': 'Upload files',
  'company.client.profile.addContact': 'Add contact',
  'company.client.profile.addNote': 'Add a note',
  'company.client.profile.createTask': 'Create task',
  'company.client.profile.createTask.name.placeholder': 'Write your notes here',
  'company.client.profile.shareFiles': 'Share files',
  'company.client.profile.shareFiles.table.header.name': '_(Client) files',
  'company.client.profile.shareFiles.table.header.nameWithSelected':
    '{count} _(client) {count, plural, one {file} other {files}} selected',
  'company.client.profile.shareFilesWith': 'Share files with {clientName}',
  'company.client.emptyState.title': 'No files for sharing yet',
  'company.client.emptyState.description':
    'To share files with the _(client), you must first upload them to the _(client) profile.',
  'company.client.profile.shareFiles.submitTitle':
    'Share {count} {count, plural, one {file} other {files}} with {clientName}',
  'company.client.profile.shareFiles.submit': 'Share files',
  'company.client.profile.delete': 'Delete _(client)',
  'company.client.profile.clearFields': 'Clear fields',
  'company.client.profile.assignedExperts': 'Assigned _(experts)',
  'company.client.profile.assignedExperts.tooltip':
    'Assign _(experts) to _(clients) to provide them access to view and manage _(client) information, schedule _(appointments), and chat with them. By default, the workspace owner and admins have access to all _(clients).',
  'company.client.profile.unassigned': 'Unassigned',

  'company.client.profile.sendDocuments': 'Send documents',
  'company.client.profile.sendDocumentsTo': 'Send documents to {clientName}',

  'company.client.profile.shareFormWith': 'Share form with {clientName}',
  'company.client.profile.addFormTo': 'Add form to {clientName}',

  'company.client.profile.sendDocuments.title': 'Share form with {clientName}',
  'company.client.profile.addForms.title': 'Add form to {clientName}',

  'company.client.profile.shareAgreementsWith': 'Share _(service agreements) with {clientName}',
  'company.client.profile.sendDocuments.noFormsToShare': 'No forms for sharing yet',
  'company.client.profile.sendDocuments.noAgreementsToShare': 'No _(service agreements) for sharing yet',
  'company.client.profile.sendDocuments.createAgreement': 'Create _(service agreement)',
  'company.client.profile.sendDocuments.createIntakeForm': 'Create form',
  'company.client.profile.sendDocuments.agreement.header': 'Select _(service agreements)',
  'company.client.profile.sendDocuments.agreement.description':
    'Ask your _(client) to accept one or more _(service agreements).',
  'company.client.profile.sendDocuments.agreement.table.row.previewTooltipText': 'Preview _(service agreement)',
  'company.client.profile.sendDocuments.agreement.table.row.statusDeactivated': 'Deactivated',

  'company.client.profile.sendDocuments.intake.header': 'Select forms',
  'company.client.profile.sendDocuments.intake.description':
    'Ask your _(client) to complete one or more forms. This section shows only forms for _(clients).',
  'company.client.profile.sendDocuments.intake.table.row.previewTooltipText': 'Preview form',
  'company.client.profile.sendDocuments.intake.table.row.statusDeactivated': 'Deactivated',
  'company.client.profile.sendDocuments.submitTitle':
    'Send {count} {count, plural, one {document} other {documents}} to {clientName}',

  'company.client.profile.addForms.intake.header': 'Select forms',
  'company.client.profile.addForms.intake.description':
    'Select the forms to fill out and attach to the _(client) profile. Add up to 20 forms to the _(client) profile at once and complete them.',
  'company.client.profile.addForms.intake.table.row.previewTooltipText': 'Preview form',
  'company.client.profile.addForms.intake.table.row.statusDeactivated': 'Deactivated',
  'company.client.profile.addForms.submit': 'Select {count, plural, one {form} other {forms}}',

  'company.client.profile.sendDocuments.submit': 'Share {count, plural, one {form} other {forms}}',
  'company.client.profile.shareAgreements.submit':
    'Share {count, plural, one {_(service agreement)} other {_(service agreements)}}',
  'company.client.profile.expertDeactivated': ' (deactivated)',
  'company.client.overview.planned': 'Planned',
  'company.client.overview.past': 'Past',
  'company.client.overview.noItems': 'Nothing here yet',
  'company.client.overview.addItemsInfo':
    'This is where you’ll see all the _(client)’s _(appointments), notes and files.',

  'company.client.appointments.appointment': '_(Appointment)',
  'company.client.appointments.appointmentWith': ' with {profileName}',
  'company.client.appointments.noItems': 'No _(appointments) yet',
  'company.client.appointments.addItemsInfo': 'This is where you’ll see all _(appointments) with your _(client).',

  'company.client.notes.noNotes': 'No notes yet',
  'company.client.notes.addNotesInfo':
    'Add notes for yourself and workspace administrators. Your _(clients) won’t see them.',
  'company.client.notes.addNewNote': 'Add a new note',
  'company.client.notes.addNote': 'Add note',
  'company.client.notes.addNote.placeholder': 'Write your notes here',
  'company.client.notes.actions.delete': 'Delete',
  'company.client.notes.actions.edit': 'Edit',
  'company.client.notes.actions.makePrivate': 'Make private',
  'company.client.notes.actions.makePublic': 'Share with _(client)',
  'company.client.notes.remove.confirmModal.title': 'Delete note',
  'company.client.notes.remove.confirmModal.body': 'Are you sure you want to delete this note? This cannot be undone.',
  'company.client.notes.note': 'Note',
  'company.client.notes.addedBy': ' added by {profileName}',
  'company.client.notes.personal': 'Private',
  'company.client.notes.shared': ' Shared',

  'company.client.tasks.noTasks': 'No tasks yet',
  'company.client.tasks.addTasksInfo': 'Add tasks to this _(client), e.g., “Send invoice”.',
  'company.client.tasks.createTask': 'Create a task',
  'company.client.tasks.title.assignedTo': '<span>Task</span> assigned to {profileName}',
  'company.client.tasks.title.by': ' by {profileName}',
  'company.client.tasks.title.addedBy': 'Task added by {profileName}',
  'company.client.tasks.dueDate': 'Due date:',
  'company.client.tasks.editTask': 'Edit task',
  'company.client.tasks.deleteTask': 'Delete task',

  'company.client.attachments.empty.title': 'No files yet',
  'company.client.attachments.empty.description':
    'Upload _(client) files here. Files are private and your _(clients) will not see them.',
  'company.client.attachments.empty.btn': 'Upload files',
  'company.client.attachments.empty.btnAdd': 'Add business files',
  'company.client.attachments.empty.btnAddFiles': 'Add files',
  'company.client.attachments.remove.confirmModal.title': 'Delete this file?',
  'company.client.attachments.remove.confirmModal.body': 'It will be deleted from _(client)’s profile.',
  'company.client.attachments.remove.confirmModal.businessStorage.body':
    'This file will be removed from the _(client) profile, but it will still be available in your business file storage.',
  'company.client.attachments.successUploadFile': 'The file {filename} was uploaded.',
  'company.client.attachments.maxFilesUploadError': 'You can only upload {max} files at a time',
  'company.client.attachments.tooLargeFileError':
    '{filename} file is too large and couldn’t be uploaded. The limit is {maxSize}.',
  'company.client.attachments.file': 'File',
  'company.client.attachments.uploadedBy': ' uploaded by {profileName}',
  'company.client.attachments.addedFrom': ' added from business files by {profileName}',
  'company.client.attachments.actions.shareWithClient': 'Share with _(client)',
  'company.client.attachments.actions.removeAccess': 'Remove _(client) access',
  'company.client.attachments.badge': 'Shared with _(client)',

  'company.client.attachments.removeAccess.confirmModal.title': 'Remove _(client) access for this file?',
  'company.client.attachments.removeAccess.confirmModal.body':
    'The file will no longer be accessible to the _(client).',
  'company.client.attachments.removeAccess.confirmModal.submit': 'Remove access',

  'company.client.intake.empty.title': 'No forms yet',
  'company.client.intake.empty.description': 'This is where you’ll see all the forms related to _(client).',

  'company.client.agreement.empty.title': 'No _(service agreements) yet',
  'company.client.agreement.empty.description':
    'This is where you’ll see all the _(service agreements) related to _(client).',

  'company.client.form.submissionLabel.pending': 'Needs to be filled out',
  'company.client.form.submissionLabel.submitted': 'Submitted',
  'company.client.forms.empty.title': 'No forms yet',
  'company.client.forms.empty.description': 'This is where you’ll see all the _(client)’s forms.',
  'company.client.forms.status.pending': 'Pending',
  'company.client.forms.status.submitted': 'Submitted',
  'company.client.forms.actions.previewForm': 'Preview',
  'company.client.forms.actions.editResponses': 'Edit responses',
  'company.client.forms.submittedOn': 'Submitted on ',
  'company.client.forms.submittedByContact':
    '<span>Submitted by contact:</span> {name} <span>({relationshipName})</span>',
  'company.client.forms.submittedAndSignedByContact':
    '<span>Submitted and signed by contact:</span> {name} <span>({relationshipName})</span>',
  'company.client.forms.declinedByContact':
    '<span>Declined by contact:</span> {name} <span>({relationshipName})</span>',

  'company.client.forms.serviceName': '<inGray700>for</inGray700> {serviceName} <inGray700>_(appointment)</inGray700>',
  'company.client.forms.intakeForm': 'Form',

  'company.client.agreements.serviceName':
    '<inGray700>for</inGray700> {serviceName} <inGray700>_(appointment)</inGray700>',

  'company.client.agreements.sentBy': '<inGray700>sent by</inGray700> {userName}',
  'company.client.form.sharedBy': '<inGray700>shared by</inGray700> {userName}',
  'company.client.form.formRespondent': '<inGray700>for</inGray700> {respondent}',
  'company.client.form.expiryDate': 'Expiry date: {date}',
  'company.client.form.expiresOn': 'Expires on: {date}',
  'company.client.agreements.agreement': '_(Service agreement)',
  'company.client.agreements.empty.title': 'No _(service agreements) yet',
  'company.client.agreements.empty.description': 'This is where you’ll see all the _(client)’s _(service agreements).',
  'company.client.agreements.signedOn': 'Signed on ',
  'company.client.agreements.acceptedByContact':
    '<span>Accepted by contact:</span> {name} <span>({relationshipName})</span>',

  'company.client.reviews.published': 'Publish on booking page',
  'company.client.reviews.forServiceWithProfile':
    '<span>Review</span> for {serviceName} _(appointment) with {profileName}',
  'company.client.reviews.empty.title': 'No reviews yet',
  'company.client.reviews.empty.description': 'This is where you’ll see all the _(client)’s reviews.',
  'company.client.reviews.submittedByContact':
    '<span>Submitted by contact:</span> {name} <span>({relationshipName})</span>',

  'company.reviews.title': 'Reviews',
  'company.reviews.forServiceWithProfile': '<span>For</span> {serviceName} <span>_(service) with</span> {profileName}',
  'company.reviews.publishedByTheContact': 'Published by the contact of {profileName}',
  'company.reviews.adminOrOwner.search.placeholder': 'Search by _(service), _(expert), or keyword',
  'company.reviews.expert.search.placeholder': 'Search by _(service) or keyword',
  'company.reviews.emptySearch.title': 'No results',
  'company.reviews.emptySearch.description': 'Try adjusting your search or filters',
  'company.reviews.empty.enableInSettings.title': 'To start collecting reviews, enable this feature',
  'company.reviews.empty.enableInSettings.description':
    'To ask _(clients) about their experience, allow collecting reviews in your review settings.',
  'company.reviews.empty.enableInSettings.button': 'Go to review settings',
  'company.reviews.empty.title': 'No reviews yet',
  'company.reviews.empty.description': 'This is where you’ll see _(client) reviews about\n their experience.',
  'company.reviews.empty.askEnableInSettings.description':
    'To start collecting reviews from _(clients), ask your administrator to enable this feature in review settings.',
  'company.reviews.timeStamp.updatedToday': 'Updated today',
  'company.reviews.timeStamp.updatedYesterday': 'Updated yesterday',
  'company.reviews.timeStamp.updatedFormatTimeDays': 'Updated {count} {count, plural, one {day} other {days}} ago',
  'company.reviews.timeStamp.updatedWeekAgo': 'Updated a week ago',
  'company.reviews.timeStamp.updatedOtherDay': 'Updated on {date}',
  'company.reviews.sidebar.reviews': '{total} {total, plural, one {review} other {reviews}}',
  'company.reviews.statusFilter.allReviews': 'All reviews',
  'company.reviews.statusFilter.published': 'Published on booking page',

  'company.forms.title': 'Forms',
  'company.forms.search.placeholder': 'Search by name',
  'company.forms.allForms': 'All forms',
  'company.forms.title.create': 'Create form',
  'company.forms.title.edit': 'Edit form',
  'company.forms.filter.byStatus': 'By status',
  'company.forms.filter.byRespondent': 'By respondent',
  'company.forms.addIntakeFormBtn': 'Add form',
  'company.forms.empty.title': 'No forms yet',
  'company.forms.empty.description': 'Create forms, to gather information from _(clients) and _(experts).',
  'company.forms.empty.btn': 'Create form',
  'company.forms.table.header.name': 'Name',
  'company.forms.table.header.formCategory': 'Category',
  'company.forms.table.header.respondent': 'Respondent',
  'company.forms.table.header.status': 'Status',
  'company.forms.deleteConfirmation.title': 'Delete this form?',
  'company.forms.deleteConfirmation.body': 'Are you sure you want to delete this form? This cannot be undone.',
  'company.forms.activationConfirmation.title': 'Activate form?',
  'company.forms.activationConfirmation.body': 'Activate the form to start sending it to _(experts) and _(clients).',
  'company.forms.deactivationConfirmation.title': 'Deactivate form?',
  'company.forms.deactivationConfirmation.body': 'You won’t be able to send this form to _(experts) or _(clients).',
  'company.forms.status.title': 'Form status',
  'company.forms.status.description': 'Activate the form to assign it to _(services) or send to respondents.',
  'company.forms.status.active': 'Active',
  'company.forms.status.deactivated': 'Deactivated',
  'company.forms.learnMore': 'Learn more about forms',
  'company.forms.listOfForms': 'List of forms',
  'company.forms.formName': 'Form name',
  'company.forms.welcomeMessage': 'Welcome message (optional)',
  'company.forms.welcomeMessagePlaceholder': 'Add a short introduction for _(clients) here',
  'company.forms.frequency': 'How often do you want to send this form to _(client)?',
  'company.forms.frequency.oncePerClient': 'Once per _(client)',
  'company.forms.frequency.oncePerClient.description': 'Send this form if a _(client) hasn’t completed it before.',
  'company.forms.frequency.oncePerAppointment': 'Once per _(appointment)',
  'company.forms.frequency.oncePerAppointment.description': 'Send this form before every _(appointment).',
  'company.forms.formQuestionsAttributes.error': 'Please add at least one question to your form.',
  'company.forms.formQuestionsAttributes.questionLabel': 'Question',
  'company.forms.formQuestionsAttributes.textLabel': 'Text field',
  'company.forms.formQuestionsAttributes.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.addQuestion': 'Add new question',
  'company.forms.formQuestionsAttributes.maximumReached': 'You have reached the limit of 100 questions.',
  'company.forms.formQuestionsAttributes.required': 'Required',
  'company.forms.formQuestionsAttributes.text.description': 'Respondents will be able to answer with a plain text.',
  'company.forms.formQuestionsAttributes.radio_button.description':
    'Give your _(clients) options, and ask to choose one of them.',
  'company.forms.formQuestionsAttributes.check_box.description':
    'Give your _(clients) options, and let them choose as many as they like.',
  'company.forms.formQuestionsAttributes.file.description':
    'Ask your _(clients) to upload files of any type, up to 10MB each.',
  'company.forms.formQuestionsAttributes.date.description': 'Ask your _(clients) to pick a date from the calendar. ',
  'company.forms.formQuestionsAttributes.subtitle.description': 'Add a subtitle to organize your form into sections.',
  'company.forms.formQuestionsAttributes.paragraph.description':
    'Say something to _(clients) without asking to reply: a piece of information, a quote, or an inspiring phrase.',
  'company.forms.formQuestionsAttributes.dropdown.description':
    'Ask respondents to choose one of options from the dropdown menu.',
  'company.forms.formQuestionsAttributes.time.description': 'Ask respondents to select a time.',
  'company.forms.formQuestionsAttributes.time.name': 'Time',
  'company.forms.formQuestionsAttributes.duration.description': 'Ask respondents to select a duration.',
  'company.forms.formQuestionsAttributes.duration.name': 'Duration',
  'company.forms.formQuestionsAttributes.text.placeholder': 'Type your question here.',
  'company.forms.formQuestionsAttributes.text.name': 'Text',
  'company.forms.formQuestionsAttributes.radio_button.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.radio_button.name': 'Single choice',
  'company.forms.formQuestionsAttributes.check_box.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.check_box.name': 'Multiple choice',
  'company.forms.formQuestionsAttributes.file.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.file.name': 'File',
  'company.forms.formQuestionsAttributes.date.placeholder': 'Type your question here ',
  'company.forms.formQuestionsAttributes.date.name': 'Date',
  'company.forms.formQuestionsAttributes.subtitle.placeholder': 'Section title',
  'company.forms.formQuestionsAttributes.paragraph.placeholder': 'Some additional text here for your _(clients)',
  'company.forms.formQuestionsAttributes.dropdown.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.dropdown.name': 'Dropdown',
  'company.forms.formQuestionsAttributes.time.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.duration.placeholder': 'Type your question here',
  'company.forms.formQuestionsAttributes.crm.description':
    'Select field from the _(client) profile, to which answers from this field will be imported.',
  'company.forms.questionTypes.file.name': 'File upload',
  'company.forms.questionTypes.checkBox.name': 'Multiple choice',
  'company.forms.questionTypes.text.name': 'Text',
  'company.forms.questionTypes.radioButton.name': 'Single choice',
  'company.forms.questionTypes.date.name': 'Date',
  'company.forms.questionTypes.subtitle.name': 'Subtitle',
  'company.forms.questionTypes.paragraph.name': 'Paragraph',
  'company.forms.questionTypes.crm.name': 'CRM fields',
  'company.forms.questionTypes.dropdown.name': 'Dropdown',
  'company.forms.questionTypes.time.name': 'Time',
  'company.forms.questionTypes.duration.name': 'Duration',
  'company.forms.crmField.label': 'CRM field',
  'company.forms.fieldType.label': 'Field type',
  'company.forms.addOption': 'Add option',
  'company.forms.questionOptions.placeholder': 'Option {count}',
  'company.forms.steps.general': 'General',
  'company.forms.steps.questions': 'Questions',
  'company.forms.steps.sharing': 'Sharing',
  'company.forms.steps.sharing.title': 'Set up automatic sharing',
  'company.forms.steps.sharing.bookingGroup': 'Before/after providing _(services)',
  'company.forms.steps.sharing.clientGroup': 'After _(client) actions',
  'company.forms.steps.sharing.description':
    'Send out the form automatically, even when you’re away. You can also send it manually from the _(client) profile.',
  'company.forms.steps.sharing.whenSharing': 'When do you want to send this form?',
  'company.forms.steps.sharing.backToSignature': 'Back to signature',
  'company.forms.steps.sharing.categoryOrService': 'Category/_(Service)',
  'company.forms.steps.sharing.assignFormToServices': 'Assign form to _(services)',
  'company.forms.steps.sharing.assignFormToServices.label': 'For which _(services) will you send this form?',
  'company.forms.steps.sharing.emptyServices.title': 'No _(services)',
  'company.forms.steps.sharing.emptyServices.body': 'Create and activate _(services) to assign the form.',
  'company.forms.steps.sharing.emptyServices.manage': 'Manage _(services)',

  'company.forms.steps.signature': 'Signature',
  'company.forms.backToGeneral': 'Back to general',
  'company.forms.backToQuestions': 'Back to questions',
  'company.forms.createForm': 'Create form',
  'company.forms.respondent.client': '_(Client)',
  'company.forms.respondent.expert': '_(Expert)',
  'company.forms.clientRespondentInfo': '_(Experts) can still edit their _(client) responses.',
  'company.forms.cmrFieldWarning':
    '<bold>CRM field</bold> questions will no longer be imported to _(client) profiles. This won’t affect any forms completed previously.',
  'company.forms.sharingOptionWarning.clientInWorkspace':
    '<bold>Sharing a form</bold> with _(experts) when there is a new _(client) in your workspace is not supported. Please go to the Sharing tab to set up automatic sharing.',
  'company.forms.sharingOptionWarning.clientDeletesAccount':
    '<bold>Sharing a form</bold> with _(experts) when _(client) deletes their account is not supported. Please go to the Sharing tab to set up automatic sharing.',
  'company.forms.respondent.label': 'Who will respond?',
  'company.forms.respondent.placeholder': 'Select respondent',
  'company.forms.formCategory.label': 'Form category',
  'company.forms.formCategory.placeholder': 'Select category',
  'company.forms.isExpiryPeriod.title': 'Expiration date',
  'company.forms.isExpiryPeriod.descriptions':
    "Set an expiration date to disable this form for respondents if it's not submitted or signed within the specified period.",
  'company.forms.isExpiryPeriod.label': 'Expiration date',
  'company.forms.isExpiryPeriod.affix': 'days after receiving the form',
  'company.forms.formNameRequired': 'Please enter a Form name',
  'company.forms.signature.askToSignForm': 'Ask to sign this form?',
  'company.forms.signature.askToSignForm.description': 'Respondents will be asked to sign a completed form.',
  'company.forms.signature.errorMessage': 'Please select a respondent.',
  'company.forms.signature.client': '_(Client)',
  'company.forms.signature.expert': '_(Expert)',
  'company.forms.signerTitle': 'Who will sign?',

  'company.editIntakeForm.tabs.general': 'General',
  'company.editIntakeForm.tabs.questions': 'Questions',
  'company.editIntakeForm.tabs.sharing': 'Sharing',
  'company.editIntakeForm.tabs.signature': 'Signature',
  'company.forms.preview': 'Preview',
  'company.forms.goToEditCrmFields': 'To edit CRM field type or options, go to ',
  'company.forms.goToEditCrmFieldsLink': '_(Client) fields settings.',
  'company.forms.selectField': 'Select field',
  'company.forms.defaultFields': 'Default fields',
  'company.forms.customFields': 'Custom fields',

  'company.agreements.listOfAgreements': 'List of _(service agreements)',
  'company.agreements.description':
    'Create _(service agreements) and assign them to _(services) to ask _(clients) for their consent before _(appointments).',
  'company.agreements.agreementName': '_(Service agreement) name',
  'company.agreements.agreementText': '_(Service agreement) text',
  'company.agreements.agreementTextPlaceholder': 'Type your text here',
  'company.agreements.title': '_(Service agreements)',
  'company.agreements.title.create': 'Create _(service agreement)',
  'company.agreements.title.edit': 'Edit _(service agreement)',
  'company.agreements.empty.title': 'No _(service agreements) yet',
  'company.agreements.empty.description':
    'Create _(service agreements) to ask _(clients) for their consent before _(appointments).',
  'company.agreements.table.header.name': 'Name',
  'company.agreements.table.header.status': 'Status',
  'company.agreements.table.header.lastEdited': 'Last modified',
  'company.agreements.deleteConfirmation.title': 'Delete this _(service agreement)?',
  'company.agreements.deleteConfirmation.body':
    'You’re about to delete <b>{formName}</b> forever. This won’t delete the _(service agreements) already sent to or signed by _(clients).',
  'company.agreements.activationConfirmation.title': 'Do you want to activate this _(service agreement)?',
  'company.agreements.activationConfirmation.body':
    'Activate the _(service agreement) and make sure it is assigned to _a(service) to start collecting answers from  _(clients).',
  'company.agreements.deactivationConfirmation.title': 'Do you want to deactivate this _(service agreement)?',
  'company.agreements.deactivationConfirmation.body':
    '_(Clients) won’t be able to sign any pending <b>{formName}</b> _(service agreements). Also, they won’t be asked to sign it before future _(appointments).',
  'company.agreements.status.title': '_(Service agreements) status',
  'company.agreements.status.description':
    'Activate the _(service agreement) and make sure it is assigned to _a(service) to ask _(clients) for their consent before _(appointments).',
  'company.agreements.status.active': 'Active',
  'company.agreements.status.deactivated': 'Deactivated',
  'company.forms.bookingStarts': 'When _(appointment) starts',
  'company.forms.newBooking': 'When a new _(appointment) is scheduled',
  'company.forms.bookingEnds': 'When _(appointment) finishes',
  'company.forms.noSharing': `I don't need automatic sharing`,
  'company.forms.clientInWorkspace': 'When there is a new _(client) in your workspace',
  'company.forms.clientDeletesAccount': 'When _(client) deletes their account',

  'company.client.contacts': 'Contact',
  'company.client.contacts.status.billingContact': 'Billing contact',
  'company.client.contacts.dropdown.addAsClient': 'Add as a _(client)',
  'company.client.contacts.dropdown.editContact': 'Edit contact',
  'company.client.contacts.dropdown.sendMessage': 'Send message',
  'company.client.contacts.relationshipType': 'Relationship type',
  'company.client.contacts.phoneNumber': 'Phone number',
  'company.client.contacts.emergencyContact': 'Emergency contact',
  'company.client.contacts.emergencyContact.yes': 'Yes',
  'company.client.contacts.emergencyContact.no': 'No',
  'company.client.contacts.note': 'Note',
  'company.client.contacts.alert':
    'This contact has removed their access to {fullName}’s information. It will be deleted in {destroyContactDay} {destroyContactDay, plural, one {day} other {days}}.',
  'company.client.contacts.alert.deletionToday':
    'This contact has removed their access to {fullName}’s information. It will be deleted today.',

  'company.client.contacts.empty.title': 'No contacts yet',
  'company.client.contacts.empty.description':
    'Add related contacts, like family, emergency contacts, or those responsible for finances, to grant them access to _(client) information.',
  'company.client.contacts.empty.button': 'Add contact',

  'company.client.contacts.waitlist.availabilityPreference': 'Availability preference:',
}

export default company
