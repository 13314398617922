const namespace = 'serviceCategories'

export const GET_SERVICE_CATEGORIES = `${namespace}/GET_SERVICE_CATEGORIES`
export const SEARCH_SERVICE_CATEGORIES = `${namespace}/SEARCH_SERVICE_CATEGORIES`
export const SET_SERVICE_CATEGORIES = `${namespace}/SET_SERVICE_CATEGORIES`
export const SET_SERVICE_CATEGORIES_PAGE = `${namespace}/SET_SERVICE_CATEGORIES_PAGE`
export const SET_SERVICE_CATEGORIES_SORT_ORDER = `${namespace}/SET_SERVICE_CATEGORIES_SORT_ORDER`
export const SET_SERVICE_CATEGORIES_FILTER = `${namespace}/SET_SERVICE_CATEGORIES_FILTER`
export const SET_SERVICE_CATEGORIES_FILTER_PARAMS = `${namespace}/SET_SERVICE_CATEGORIES_FILTER_PARAMS`
export const SET_SERVICE_CATEGORIES_SELECTED = `${namespace}/SET_SERVICE_CATEGORIES_SELECTED`
export const SET_SERVICE_CATEGORIES_BLANK_STATE = `${namespace}/SET_SERVICE_CATEGORIES_BLANK_STATE`
export const ACTIVATE_SERVICE_CATEGORIES = `${namespace}/ACTIVATE_SERVICE_CATEGORIES`
export const DEACTIVATE_SERVICE_CATEGORIES = `${namespace}/DEACTIVATE_SERVICE_CATEGORIES`
export const REMOVE_SERVICE_CATEGORIES = `${namespace}/REMOVE_SERVICE_CATEGORIES`
export const CREATE_SERVICE_CATEGORY = `${namespace}/CREATE_SERVICE_CATEGORY`
export const EDIT_SERVICE_CATEGORY = `${namespace}/EDIT_SERVICE_CATEGORY`
export const FILTER_SERVICES_BY_CATEGORIES = `${namespace}/FILTER_SERVICES_BY_CATEGORIES`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
