import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Link from 'views/shared/Link'
import File from 'views/shared/icons/File'
import CountIndicator from '../CountIndicator'

const IntakeForms = ({
  booking: { intakeFormsCount, submittedIntakeFormsCount, pendingExpertIntakeFormsCount },
  bookingPagePath,
}) =>
  intakeFormsCount ? (
    <Link {...bookingPagePath}>
      <a
        className="booking-tooltip__intake in-blue-gray-800"
        target="_blank"
        rel="noreferrer"
        data-cy="forms-section-intake-forms"
      >
        <div className="d-flex no-shrink mr-16" data-cy="forms-section-icon">
          <File size={16} className="booking-tooltip__intake-svg in-blue-gray-200 " />
        </div>
        <p className="mb-0 truncate-text">
          <span className="font-600" data-cy="forms-section-title">
            <FormattedMessage id="forms.title" />
          </span>
        </p>
        <CountIndicator current={submittedIntakeFormsCount} max={intakeFormsCount} />
        {pendingExpertIntakeFormsCount > 0 && (
          <span className="bg-orange-800 in-white font-500 ml-auto mr-0 tooltip-doc-counter">
            <FormattedMessage id="forms.expertPending" values={{ count: pendingExpertIntakeFormsCount }} />
          </span>
        )}
      </a>
    </Link>
  ) : null

IntakeForms.propTypes = {
  booking: PropTypes.shape({
    intakeFormsCount: PropTypes.number.isRequired,
    submittedIntakeFormsCount: PropTypes.number.isRequired,
    pendingExpertIntakeFormsCount: PropTypes.number.isRequired,
  }).isRequired,
  bookingPagePath: PropTypes.shape().isRequired,
}

export default IntakeForms
