import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, map, not, path, pipe, prop, sum, values } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector } from 'state/data/selectors'
import { fetchDateAvailabilityEndpoint, updateDateAvailabilityEndpoint } from './endpoints'

const dateAvailabilityIdsSelector = path(['dateAvailability', 'dateAvailabilityIds'])
const timezoneSelector = path(['dateAvailability', 'timezone'])

export const filtersSelector = path(['dateAvailability', 'filters'])

export const hasFiltersSelector = createSelector(filtersSelector, compose(any(compose(not, isEmpty)), values))

export const selectedTimezoneSelector = createSelector(
  [dataSelector, timezoneSelector, (_, employeeId) => employeeId],
  (data, timezone, id) => timezone || prop('formattedTimezone', build(data, 'userProfile', id)),
)

export const dateAvailabilitiesSelector = createSelector([dateAvailabilityIdsSelector, dataSelector], (ids, data) =>
  ids && !isEmpty(ids) ? build(data, 'dateAvailability', ids) : [],
)

export const totalSlotsDurationSelector = createSelector(dateAvailabilitiesSelector, pipe(map(prop('duration')), sum))

export const dateLoadingSelector = (state, employeeId) =>
  loadingSelector(state, fetchDateAvailabilityEndpoint(employeeId).endpoint)

export const dateUpdatingSelector = (state, employeeId) =>
  loadingSelector(state, updateDateAvailabilityEndpoint(employeeId).endpoint)
