import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { DELETE_AGREEMENT } from 'state/concepts/agreement/types'
import { fetchAgreements } from 'state/concepts/forms/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { deleteAgreementEndpoint } from 'state/concepts/agreement/endpoints'

const deleteAgreementOperation = createLogic({
  type: DELETE_AGREEMENT,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = deleteAgreementEndpoint(id)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(fetchAgreements())
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.agreementDeleted',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteAgreementOperation
