import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { fetchDocumentTemplates } from '../actions'
import { deleteDocumentTemplateEndpoint } from '../endpoints'
import { DELETE_DOCUMENT_TEMPLATE } from '../types'

const deleteDocumentTemplateOperation = createLogic({
  type: DELETE_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ action: { documentTemplateId, reloadAfterDelete }, httpClient }, dispatch, done) {
    const { url, endpoint } = deleteDocumentTemplateEndpoint(documentTemplateId)

    try {
      await httpClient.delete(url)

      dispatch(hideModal())

      if (reloadAfterDelete) {
        dispatch(fetchDocumentTemplates())
        dispatch(showNotification({ messageObject: { id: 'notifications.documentTemplateWasDeleted' } }))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default deleteDocumentTemplateOperation
