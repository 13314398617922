import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { websiteHttpClient } from 'lib/httpClient'
import { FETCH_PUBLIC_PROVIDERS } from 'state/concepts/website/types'
import { dataApiFailure, dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { fetchPublicProvidersEndpoint } from '../endpoints'
import { websiteSelector } from '../selectors'
import { addProviders } from '../actions'

const fetchPublicProvidersOperation = createLogic({
  type: FETCH_PUBLIC_PROVIDERS,
  latest: true,

  async process({ action: { pageParams }, getState }, dispatch, done) {
    const state = getState()
    const { uniqCode } = workspaceSelector(state)
    const {
      widget: { id },
    } = websiteSelector(state)
    const { url, endpoint } = fetchPublicProvidersEndpoint(uniqCode, id)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = {
        page: {
          ...pageParams,
          size: 4,
        },
        filter: {
          timezone: 'UTC',
        },
        include: 'service,user-profile-service',
      }

      const { data } = await websiteHttpClient.get(url, { params })
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(addProviders(pluck('id', data.data)))
    } catch (_error) {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default fetchPublicProvidersOperation
