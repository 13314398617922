import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { isEmpty } from 'ramda'

import isPageOverflow from 'utils/isPageOverflow'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_WIDGETS } from 'state/concepts/widgets/types'
import { fetchWidgetsEndpoint } from 'state/concepts/widgets/endpoints'
import { setWidgets, setBlankState, fetchWidgets, setCurrentPage } from 'state/concepts/widgets/actions'
import { paginationSelector, sortParamsSelector } from 'state/concepts/widgets/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const getWidgetsOperation = createLogic({
  type: FETCH_WIDGETS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchWidgetsEndpoint
    const state = getState()

    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const data = await httpClient.get(url, { params }).then(resp => resp.data)
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isEmpty(data.data)))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setWidgets(data.data.map(item => item.id)))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(1))
        dispatch(fetchWidgets())
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default getWidgetsOperation
