import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const createService = makeFormSubmitAction(types.CREATE_SERVICE)

export const updateServiceDetails = makeFormSubmitAction(types.UPDATE_SERVICE_DETAILS)

export const updateServiceSettings = makeFormSubmitAction(types.UPDATE_SERVICE_SETTINGS)

export const updateServiceProvision = makeFormSubmitAction(types.UPDATE_SERVICE_PROVISION)

export const updateServiceAssignedForms = makeFormSubmitAction(types.UPDATE_SERVICE_ASSIGNED_FORMS)

export const validateCreateServiceStep = (
  values,
  step,
  finishStep,
  { setErrors, setSubmitting, setStatus, resetForm },
) => ({
  type: types.VALIDATE_CREATE_SERVICE_STEP,
  values,
  step,
  finishStep,
  form: {
    setErrors,
    setSubmitting,
    setStatus,
    resetForm,
  },
})

export const showServiceDetails = serviceId => ({
  type: types.SHOW_SERVICE_DETAILS,
  serviceId,
})
