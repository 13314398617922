import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { PRIVACY_POLICY_LINK } from 'lib/constants'
import Button from 'views/shared/Button'

const CookiesCard = ({ cookiesCheckState, onCookiesAccept }) =>
  !cookiesCheckState ? (
    <div className="cookies" data-cy="cookies-section">
      <div className="cookies__container">
        <p className="mb-0 text-center" data-cy="cookies-text">
          <FormattedMessage id="cookies.text" />
          <a
            className="main-link"
            href={PRIVACY_POLICY_LINK}
            rel="noopener noreferrer"
            target="_blank"
            data-cy="privacy-policy-link"
          >
            <FormattedMessage id="shared.learnMore" />
          </a>
        </p>
        <Button
          onClick={onCookiesAccept}
          text={{ id: 'cookies.button' }}
          size="small"
          dataCy="agree-and-dismiss-button"
        />
      </div>
    </div>
  ) : null

CookiesCard.propTypes = {
  cookiesCheckState: PropTypes.string.isRequired,
  onCookiesAccept: PropTypes.func.isRequired,
}

export default CookiesCard
