import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { compose, pick } from 'ramda'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import withLoseChangesWarning from 'lib/withLoseChangesWarning'
import validationSchema from 'lib/yupLocalised/schemas/rescheduleRecurringSchema'
import { rescheduleRecurringBooking } from 'state/concepts/booking/actions'
import {
  rescheduleBookingLoadingSelector,
  rescheduleRecurringBookingLoadingSelector,
} from 'state/concepts/booking/selectors'
import { sidebarPropsSelector } from 'state/sidebar/selectors'
import { handleSubmit } from 'utils/form/handleSubmit'
import RescheduleAppointmentComponent from './component'

class RescheduleAppointment extends React.Component {
  static propTypes = {
    rescheduleRecurringBookingLoading: PropTypes.bool,
    rescheduleBookingLoading: PropTypes.bool,
  }

  static defaultProps = {
    rescheduleRecurringBookingLoading: undefined,
    rescheduleBookingLoading: undefined,
  }

  get isSubmitting() {
    const { rescheduleRecurringBookingLoading, rescheduleBookingLoading } = this.props

    return rescheduleRecurringBookingLoading === true || rescheduleBookingLoading === true
  }

  render = () => <RescheduleAppointmentComponent {...this.props} isSubmitting={this.isSubmitting} />
}

const mapStateToProps = (state, { uniqCode }) => ({
  bookingToSchedule: sidebarPropsSelector(state),
  rescheduleRecurringBookingLoading: rescheduleRecurringBookingLoadingSelector(state, uniqCode),
  rescheduleBookingLoading: rescheduleBookingLoadingSelector(state, uniqCode),
})

const mapDispatchToProps = {
  onSubmit: rescheduleRecurringBooking,
}

export { RescheduleAppointment as RescheduleAppointmentContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ bookingToSchedule }) =>
      pick(['duration', 'time', 'reason', 'date', 'bookingId', 'serviceId', 'userProfileId', 'uniqCode', 'isSingle'])(
        bookingToSchedule,
      ),
    validationSchema,
    handleSubmit,
  }),
  withLoseChangesWarning(),
  injectIntl,
)(RescheduleAppointment)
