import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'

const NoMembersToAddModalComponent = ({ onClose, onManageTeam, showSkipButton }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="chat.noChatUsers.modal.title" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>

      <div className="main-modal__body">
        <FormattedMessage id="chat.noChatUsers.modal.team" />
      </div>

      <div className="main-modal__footer">
        {showSkipButton && (
          <Button className="main-modal__footer-action" text={{ id: 'shared.skip' }} kind="flat" onClick={onClose} />
        )}
        <Button
          className="main-modal__footer-action"
          text={{ id: 'chat.noChatUsers.modal.button.inviteTeamMembers' }}
          onClick={onManageTeam}
        />
      </div>
    </div>
  </Modal>
)

NoMembersToAddModalComponent.defaultProps = {
  showSkipButton: false,
}

NoMembersToAddModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onManageTeam: PropTypes.func.isRequired,
  showSkipButton: PropTypes.bool,
}

export default NoMembersToAddModalComponent
