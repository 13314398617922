import * as types from './types'

export const fetchNotifications = (pageParams = {}) => ({
  type: types.FETCH_NOTIFICATIONS,
  pageParams,
})

export const receiveNotification = notification => ({
  type: types.RECEIVE_NOTIFICATION,
  notification,
})

export const setNotifications = notificationIds => ({
  type: types.SET_NOTIFICATIONS,
  notificationIds,
})

export const setNotificationPanelStatus = status => ({
  type: types.SET_NOTIFICATION_PANEL_STATUS,
  status,
})

export const setUnreadNotificationsCount = unreadCount => ({
  type: types.SET_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
})

export const decUnreadNotificationsCount = unreadCount => ({
  type: types.DEC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
})

export const incUnreadNotificationsCount = unreadCount => ({
  type: types.INC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
})

export const removeNotification = notificationId => ({
  type: types.REMOVE_NOTIFICATION,
  notificationId,
})

export const removeNotificationFromPanel = notificationId => ({
  type: types.REMOVE_NOTIFICATION_FROM_PANEL,
  notificationId,
})

export const undoRemovingNotificationFromPanel = notificationId => ({
  type: types.UNDO_REMOVING_NOTIFICATION_FROM_PANEL,
  notificationId,
})

export const markNotificationAsRead = notificationId => ({
  type: types.MARK_NOTIFICATION_AS_READ,
  notificationId,
})

export const markNotificationAsUnread = notificationId => ({
  type: types.MARK_NOTIFICATION_AS_UNREAD,
  notificationId,
})

export const markNotificationsAsRead = () => ({
  type: types.MARK_NOTIFICATIONS_AS_READ,
})
