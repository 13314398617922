import { Select } from 'antd'
import classNames from 'clsx'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import isPresent from 'utils/isPresent'
import { searchEmployees as searchEmployeesAction } from 'state/concepts/company/actions'
import { selectedEmployeesSelector } from 'state/concepts/company/selectors'
import { fetchEmployeesEndpoint } from 'state/concepts/employees/endpoints'
import { searchedEmployeesSelector } from 'state/concepts/filters/selectors'
import { loadingSelector } from 'state/data/selectors'
import profileIdentity from 'utils/employees/profileIdentity'
import SelectField from 'views/shared/SelectField'
import Loader from './Loader'
import NotFoundContent from './NotFoundContent'
import OptionContent from './OptionContent'
import OptionLabel from './OptionLabel'

class DropdownUserField extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    withAvatar: PropTypes.bool,
    isLoading: PropTypes.bool,
    employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    searchEmployees: PropTypes.func.isRequired,
    excludeEmployees: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    serviceId: PropTypes.string,
    noAssignedExpert: PropTypes.shape(),
    hideEmail: PropTypes.bool,
    additionalOptions: PropTypes.shape(),
    roles: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    withAvatar: false,
    excludeEmployees: null,
    isLoading: undefined,
    mode: 'default',
    statuses: [],
    serviceId: undefined,
    noAssignedExpert: undefined,
    additionalOptions: undefined,
    hideEmail: false,
    roles: ['workspace_owner', 'administrator', 'user'],
  }

  state = {
    isOpen: false,
    searchQuery: '',
  }

  debouncedSearchEmployees = debounce(() => {
    const { searchEmployees, excludeEmployees, statuses, serviceId, roles } = this.props
    const { searchQuery } = this.state

    searchEmployees({ query: searchQuery, exclude: excludeEmployees, statuses, serviceId, roles, resetResults: true })
  }, 250)

  handleSearch = searchQuery => {
    this.setState(
      {
        isOpen: true,
        searchQuery,
      },
      this.debouncedSearchEmployees,
    )
  }

  handleDropdownVisibleChange = open => {
    const { searchEmployees, excludeEmployees, statuses, serviceId, roles } = this.props

    if (open) {
      searchEmployees({ exclude: excludeEmployees, statuses, serviceId, roles, resetResults: true })
    }
    this.setState({ isOpen: open })
  }

  render() {
    const {
      mode,
      withAvatar,
      isLoading,
      employees,
      selectedEmployees,
      noAssignedExpert,
      serviceId,
      hideEmail,
      excludeEmployees,
      statuses,
      additionalOptions,
      roles,
    } = this.props
    const { isOpen, searchQuery } = this.state
    const employeesOptions = isOpen ? employees : selectedEmployees

    return (
      <SelectField
        {...this.props}
        onSearch={this.handleSearch}
        optionLabelProp="label"
        showSearch
        filterOption={false}
        notFoundContent={
          <NotFoundContent isLoading={isLoading} noAssignedExpert={noAssignedExpert} serviceId={serviceId} />
        }
        onDropdownVisibleChange={this.handleDropdownVisibleChange}
        open={isOpen}
      >
        {!isLoading && (isPresent(employeesOptions) || !isOpen) && additionalOptions}
        {employeesOptions.map(employee => (
          <Select.Option
            className="availability__search-dropdown-item"
            label={<OptionLabel mode={mode} employee={employee} withAvatar={withAvatar} />}
            title={profileIdentity(employee)}
            value={employee.id}
            key={employee.id}
          >
            <OptionContent employee={employee} hideEmail={hideEmail} />
          </Select.Option>
        ))}

        {employees.length && (
          <Select.Option
            disabled
            className={classNames({ 'visibility-hidden min-h-a p-0': isLoading === false })}
            key="waypoint"
          >
            <Loader
              searchQuery={searchQuery}
              excludeEmployees={excludeEmployees}
              statuses={statuses}
              serviceId={serviceId}
              isLoading={isLoading}
              roles={roles}
            />
          </Select.Option>
        )}
      </SelectField>
    )
  }
}

const mapStateToProps = (state, { field }) => ({
  isLoading: loadingSelector(state, fetchEmployeesEndpoint.endpoint),
  employees: searchedEmployeesSelector(state),
  selectedEmployees: selectedEmployeesSelector(state, field.value),
})

const mapDispatchToProps = {
  searchEmployees: searchEmployeesAction,
}

export { DropdownUserField as DropdownUserFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DropdownUserField)
