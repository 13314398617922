import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Tick = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M256 512C114.616 512 0 397.385 0 256S114.615 0 256 0c141.384 0 256 114.615 256 256S397.385 512 256 512zm84.842-338.102L205.344 309.396l-46.442-46.442c-9.997-9.997-26.207-9.997-36.204 0s-9.997 26.207 0 36.204l64.543 64.543c9.997 9.997 26.207 9.997 36.204 0l153.6-153.6c9.997-9.997 9.997-26.207 0-36.204s-26.207-9.997-36.204 0z" />
  </svg>
)

Tick.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Tick.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Tick
