import { createLogic } from 'redux-logic'
import { length } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { ALERT_KINDS } from 'lib/constants'
import { isErrorStatusForbidden, isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { isFolder as isFolderFunc } from 'utils/businessFiles'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { removeFileSharedWithMeByIds, fetchFilesSharedWithMe } from '../actions'
import { fileSharedWithMeByIdSelector } from '../selectors'
import { moveFilesSharedWithMeToTrashEndpoint } from '../endpoints'
import { MOVE_FILES_SHARED_WITH_ME_TO_TRASH } from '../types'

const moveFilesSharedWithMeToTrashOperation = createLogic({
  type: MOVE_FILES_SHARED_WITH_ME_TO_TRASH,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const { sharedFileIds, onNextSlide, onBack } = action
    const sharedFileId = sharedFileIds[0]
    const selectedIdsCount = length(sharedFileIds)
    const state = getState()
    const { kind } = fileSharedWithMeByIdSelector(state, sharedFileId)
    const isFolder = isFolderFunc(kind)
    const { url, endpoint } = moveFilesSharedWithMeToTrashEndpoint
    const params = { business_storage_ids: sharedFileIds }
    const notificationId =
      selectedIdsCount > 1
        ? 'notifications.itemsWereMovedToTrash'
        : `notifications.${isFolder ? 'folderWasMovedToTrash' : 'fileWasMovedToTrash'}`

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, params)
      const trashedCount = data.meta.trashed_items_count

      dispatch(dataApiSuccess({ endpoint }))

      dispatch(removeFileSharedWithMeByIds(sharedFileIds))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: notificationId, values: { count: trashedCount } },
        }),
      )

      onNextSlide?.() || onBack?.()
      if (!onNextSlide && !onBack) {
        dispatch(fetchFilesSharedWithMe(undefined, { append: true }))
      }
    } catch (error) {
      if (isErrorStatusForbidden(error) || isErrorStatusUnprocessableEntity(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
        onBack?.()
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default moveFilesSharedWithMeToTrashOperation
