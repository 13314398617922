import * as types from './types'

export const fetchClientTasks = (clientId, pageParams = {}) => ({
  type: types.FETCH_CLIENT_TASKS,
  clientId,
  pageParams,
})

export const addClientTasksIds = ids => ({
  type: types.ADD_CLIENT_TASK_IDS,
  ids,
})

export const resetClientTasks = () => ({
  type: types.RESET_CLIENT_TASKS,
})
