import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setFormIds, addFormIds, setFormIsBlankState } from 'state/concepts/clientAccountDocuments/actions'
import { fetchFormsEndpoint } from 'state/concepts/clientAccountDocuments/endpoints'
import { formsPaginationSelector } from 'state/concepts/clientAccountDocuments/selectors'
import { FETCH_FORMS } from 'state/concepts/clientAccountDocuments/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { buildCustomBaseUrl } from 'lib/httpClient'

const fetchFormsOperation = createLogic({
  type: FETCH_FORMS,
  latest: false,

  async process({ httpClient, getState, action: { formType, filters, options, workspaceCode } }, dispatch, done) {
    const { endpoint, url } = fetchFormsEndpoint(formType)
    const state = getState()
    const customBaseUrl = workspaceCode ? buildCustomBaseUrl(['workspaces', workspaceCode]) : {}
    const config = {
      ...customBaseUrl,
      params: {
        filter: {
          form_type: formType,
          ...filters,
        },
        page: formsPaginationSelector(state, formType),
        include: ['user-profile', 'workspace'],
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, config)
      const response = normalize(data, { endpoint })
      const formIds = pluck('id', data.data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setFormIsBlankState(formType, data.meta.total_count === 0))
      dispatch(options?.append ? addFormIds(formType, formIds) : setFormIds(formType, formIds))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchFormsOperation
