import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isPresent from 'utils/isPresent'
import { bookingByIdSelector } from 'state/concepts/bookings/selectors'
import BookingCalendarTooltipComponent from './component'

class BookingCalendarTooltip extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    booking: PropTypes.shape(),
  }

  static defaultProps = {
    booking: null,
  }

  get visible() {
    const { booking } = this.props

    return isPresent(booking)
  }

  render() {
    return <BookingCalendarTooltipComponent {...this.props} visible={this.visible} />
  }
}

const mapStateToProps = (state, props) => ({
  booking: bookingByIdSelector(state, props.id),
})

export { BookingCalendarTooltip as BookingCalendarTooltipContainer }
export default connect(mapStateToProps)(BookingCalendarTooltip)
