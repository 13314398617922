import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBillingRecurringPaymentsRoute } from 'lib/routes'
import { showNotification } from 'state/notifications/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { requestRecurringPaymentValuesToParams } from 'utils/recurringPayments'
import { RECURRING_PAYMENT_STATUSES } from 'lib/constants/recurringPayments'
import { UPDATE_RECURRING_PAYMENT } from '../types'
import { sendRecurringPaymentEndpoint, updateRecurringPaymentEndpoint } from '../endpoints'
import { recurringPaymentIdSelector } from '../selectors'

const updateRecurringPaymentOperation = createLogic({
  type: UPDATE_RECURRING_PAYMENT,
  latest: true,

  async process(
    {
      action: {
        form,
        values: { status, isEditingDraft },
        values,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const isActive = status === RECURRING_PAYMENT_STATUSES.active
    const recurringPaymentId = recurringPaymentIdSelector(state)
    const { url, endpoint } = updateRecurringPaymentEndpoint(recurringPaymentId)
    const { url: sendUrl } = sendRecurringPaymentEndpoint(recurringPaymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, requestRecurringPaymentValuesToParams(values))

      if (isActive && isEditingDraft) {
        await httpClient.post(sendUrl)
      }
      dispatch(dataApiSuccess({ endpoint }))

      redirect({
        href: companyBillingRecurringPaymentsRoute,
        workspace: currentWorkspaceCodeSelector(state),
        skipDiscard: true,
      })
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.updateRecurringPayment',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default updateRecurringPaymentOperation
