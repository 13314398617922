import * as types from './types'

export const fetchRecurringAvailability = (userId, needToSetEmployeeTimezone = false) => ({
  type: types.FETCH_RECURRING_AVAILABILITY,
  userId,
  needToSetEmployeeTimezone,
})

export const updateRecurringAvailability = (availabilities, userId, form) => ({
  type: types.UPDATE_RECURRING_AVAILABILITY,
  availabilities,
  userId,
  form,
})

export const setRecurringAvailability = recurringAvailabilityIds => ({
  type: types.SET_RECURRING_AVAILABILITY,
  recurringAvailabilityIds,
})

export const resetRecurringAvailability = () => ({
  type: types.RESET_RECURRING_AVAILABILITY,
})

export const setTimezone = timezone => ({
  type: types.SET_TIMEZONE,
  timezone,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})
