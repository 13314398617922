import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const updateClientPortal = makeFormSubmitAction(types.UPDATE_CLIENT_PORTAL)

export const setClientPortalId = clientPortalId => ({
  type: types.SET_CLIENT_PORTAL,
  clientPortalId,
})

export const fetchClientPortal = () => ({
  type: types.FETCH_CLIENT_PORTAL,
})
