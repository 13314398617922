import { agreementOperations } from './agreement'
import { billingOperations } from './billing'
import { bookingOperations } from './booking'
import { bookingsOperations } from './bookings'
import { calendarEventOperations } from './calendarEvent'
import { calendarIntegrationsOperations } from './calendarIntegrations'
import { cardReadersOperations } from './cardReaders'
import { chatOperations } from './chat'
import { chatUsersOperations } from './chatUsers'
import { chatsOperations } from './chats'
import { clientOperations } from './client'
import { clientAccountBookingsOperations } from './clientAccountBookings'
import { clientAccountDocumentsOperations } from './clientAccountDocuments'
import { clientAccountReviewOperations } from './clientAccountReview'
import { clientContactsOperations } from './clientContacts'
import { clientRelationshipTypesOperations } from './clientRelationshipTypes'
import { clientsOperations } from './clients'
import { companyOperations } from './company'
import { companyClientAttachmentsOperations } from './companyClientAttachments'
import { companyClientBookingsOperations } from './companyClientBookings'
import { companyClientFeedOperations } from './companyClientFeed'
import { companyClientFormsOperations } from './companyClientForms'
import { companyClientImportOperations } from './companyClientImport'
import { clientNotesOperations } from './companyClientNotes'
import { companyClientPortalOperations } from './companyClientPortal'
import { companyClientReviewsOperations } from './companyClientReviews'
import { companyClientTasksOperations } from './companyClientTasks'
import { customFieldsOperations } from './customFields'
import { customNamesOperations } from './customNames'
import { dateAvailabilityOperations } from './dateAvailability'
import { documentTemplatesOperations } from './documentTemplates'
import { employeeOperations } from './employee'
import { employeeSignUpOperations } from './employeeSignUp'
import { employeesOperations } from './employees'
import { etherpadDocumentsOperations } from './etherpadDocuments'
import { filesSharedWithMeOperations } from './filesSharedWithMe'
import { filtersOperations } from './filters'
import { formOperations } from './form'
import { formCategoriesOperations } from './formCategories'
import { formsOperations } from './forms'
import { guestOperations } from './guest'
import { guestAccountSettingsOperations } from './guestAccountSettings'
import { guestBookingsOperations } from './guestBookings'
import { guestPaymentsOperations } from './guestPayments'
import { intakeFormOperations } from './intakeForm'
import { inviteAppointmentMembersOperations } from './inviteAppointmentMembers'
import { locationsOperations } from './locations'
import { loginOperations } from './login'
import { myBusinessFilesOperations } from './myBusinessFiles'
import { notificationSettingsOperations } from './notificationSettings'
import { notificationsOperations } from './notifications'
import { paymentOperations } from './payment'
import { paymentSettingsOperations } from './paymentSettings'
import { paymentsOperations } from './payments'
import { promocodesOperations } from './promocodes'
import { recentWorkspacesOperations } from './recentWorkspaces'
import { recurringAvailabilityOperations } from './recurringAvailability'
import { recurringPaymentOperations } from './recurringPayment'
import { recurringPaymentsOperations } from './recurringPayments'
import { reviewSettingsOperations } from './reviewSettings'
import { reviewsOperations } from './reviews'
import { serviceOperations } from './service'
import { serviceCategoriesOperations } from './serviceCategories'
import { servicesOperations } from './services'
import { sessionOperations } from './session'
import { sidebarWidgetsOperations } from './sidebarWidgets'
import { signatureSettingsOperations } from './signatureSettings'
import { smsNotificationsSettingsOperations } from './smsNotificationsSettings'
import { subscriptionPlansOperations } from './subscriptionPlans'
import { waitlistRequestsOperations } from './waitlistRequests'
import { waitlistSettingsOperations } from './waitlistSettings'
import { tasksOperations } from './tasks'
import { timezonesOperations } from './timezones'
import { trashedFilesOperations } from './trashedFiles'
import { tutorialOperations } from './tutorial'
import { videoCallsIntegrationsOperations } from './videoCallsIntegrations'
import { videoConferenceOperations } from './videoConference'
import { webSocketsOperations } from './webSockets'
import { websiteOperations } from './website'
import { widgetOperations } from './widget'
import { widgetsOperations } from './widgets'
import { bookingSettingsOperations } from './workspaceBookingSettings'
import { workspaceFeaturesOperations } from './workspaceFeatures'
import { storageOperations } from './storage'

export default [
  ...serviceOperations,
  ...servicesOperations,
  ...sessionOperations,
  ...employeeOperations,
  ...employeesOperations,
  ...companyOperations,
  ...timezonesOperations,
  ...serviceCategoriesOperations,
  ...recurringAvailabilityOperations,
  ...dateAvailabilityOperations,
  ...billingOperations,
  ...bookingsOperations,
  ...bookingOperations,
  ...clientsOperations,
  ...clientOperations,
  ...clientAccountBookingsOperations,
  ...videoConferenceOperations,
  ...chatOperations,
  ...chatsOperations,
  ...chatUsersOperations,
  ...widgetsOperations,
  ...widgetOperations,
  ...loginOperations,
  ...paymentsOperations,
  ...subscriptionPlansOperations,
  ...myBusinessFilesOperations,
  ...trashedFilesOperations,
  ...webSocketsOperations,
  ...tutorialOperations,
  ...filtersOperations,
  ...etherpadDocumentsOperations,
  ...signatureSettingsOperations,
  ...waitlistRequestsOperations,
  ...waitlistSettingsOperations,
  ...recentWorkspacesOperations,
  ...paymentSettingsOperations,
  ...notificationSettingsOperations,
  ...notificationsOperations,
  ...employeeSignUpOperations,
  ...promocodesOperations,
  ...customFieldsOperations,
  ...companyClientBookingsOperations,
  ...companyClientAttachmentsOperations,
  ...clientNotesOperations,
  ...companyClientFormsOperations,
  ...companyClientFeedOperations,
  ...companyClientReviewsOperations,
  ...companyClientTasksOperations,
  ...companyClientImportOperations,
  ...formsOperations,
  ...intakeFormOperations,
  ...agreementOperations,
  ...formOperations,
  ...reviewsOperations,
  ...clientAccountReviewOperations,
  ...reviewSettingsOperations,
  ...clientAccountDocumentsOperations,
  ...sidebarWidgetsOperations,
  ...locationsOperations,
  ...tasksOperations,
  ...paymentOperations,
  ...recurringPaymentOperations,
  ...bookingSettingsOperations,
  ...recurringPaymentsOperations,
  ...workspaceFeaturesOperations,
  ...guestBookingsOperations,
  ...companyClientPortalOperations,
  ...guestPaymentsOperations,
  ...guestAccountSettingsOperations,
  ...guestOperations,
  ...smsNotificationsSettingsOperations,
  ...websiteOperations,
  ...customNamesOperations,
  ...calendarIntegrationsOperations,
  ...clientRelationshipTypesOperations,
  ...documentTemplatesOperations,
  ...calendarEventOperations,
  ...videoCallsIntegrationsOperations,
  ...inviteAppointmentMembersOperations,
  ...cardReadersOperations,
  ...formCategoriesOperations,
  ...clientContactsOperations,
  ...filesSharedWithMeOperations,
  ...storageOperations,
]
