import { Component } from 'react'

import EllipsisWithToggleComponent from './component'

class EllipsisWithToggle extends Component {
  state = {
    isEllipsis: true,
    isShowMore: false,
    isClientRendered: false,
  }

  toggleEllipsis = () => {
    this.setState(({ isEllipsis }) => ({ isEllipsis: !isEllipsis }))
  }

  toggleShowMore = () => {
    this.setState(({ isShowMore }) => ({ isShowMore: !isShowMore }))
  }

  componentDidMount() {
    this.setState({ isClientRendered: true })
  }

  render = () => (
    <EllipsisWithToggleComponent
      {...this.state}
      {...this.props}
      onToggleEllipsis={this.toggleEllipsis}
      onToggleShowMore={this.toggleShowMore}
    />
  )
}

export default EllipsisWithToggle
