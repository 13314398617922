const guestBookings = {
  'guestBookings.shared.thankYou': 'Thank you for your response',
  'guestBookings.rescheduleAppointment.title': 'Reschedule _(appointment)',
  'guestBookings.rescheduleAppointment.submit': 'Reschedule _(appointment)',
  'guestBookings.rescheduleAppointment.success': 'Your _(appointment) is rescheduled',
  'guestBookings.acceptAppointment.success': 'Your _(appointment) is confirmed!',
  'gustBookings.declineRecurringAppointments.title': 'Decline recurring _(appointments)',
  'guestBookings.declineRecurringAppointments.success': 'All _(appointments) in the series are declined',
  'guestBookings.declineAppointment.success': 'Your _(appointment) is declined',
  'guestBookings.documents.title': 'Require actions',
  'guestBookings.documents.intakeForm.title': 'Forms',
  'guestBookings.documents.serviceAgreement.title': '_(Service agreements)',
  'guestBookings.documents.etherpadDocuments.title': 'Documents',
  'guestBookings.documents.files.title': 'Files',
  'guestBookings.documents.success.title': 'All forms are submitted',
  'guestBookings.documents.formIsSubmitted': 'The form is submitted',
  'guestBookings.documents.success.subtitle': 'Thank you for taking your time',
  'guestBookings.documents.success.nextForm': 'Open next',
  'guestBookings.cancelAppointment.title': 'Cancel _(appointment)',
  'guestBookings.cancelAppointment.submit': 'Cancel _(appointment)',
  'guestBookings.cancelAppointment.success': 'Your _(appointment) is cancelled',
}

export default guestBookings
