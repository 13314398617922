import yup from 'lib/yupLocalised'
import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import { BOOKING_STATUSES } from 'lib/constants/bookings'

export default ({ booking }) =>
  yup.object().shape({
    cancellationReason: yup
      .string()
      .max(MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH)
      .when('id', {
        is: () => booking.status !== BOOKING_STATUSES.pending,
        then: schema => schema.required(),
      }),
  })
