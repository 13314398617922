import PropTypes from 'prop-types'

import { ALERT_KINDS } from 'lib/constants'
import isPresent from 'utils/isPresent'
import { formattedShortestDateWithTime } from 'utils/bookings'
import Alert from 'views/shared/Alert'

const TimeConflictsWarningComponent = ({
  values: { userProfileId },
  isRepeat,
  conflictDates,
  selectedEmployeeName,
  availabilityConflictMessageId,
  currentEmployee: { timezoneOffset },
}) =>
  isPresent(userProfileId) && (
    <>
      {isPresent(conflictDates) && (
        <Alert
          className="mb-4"
          message={{
            id: `scheduleAppointment.${isRepeat ? 'recurringConflicts' : 'conflicts'}`,
            values: {
              dates: conflictDates.map(date => (
                <div key={date}>{formattedShortestDateWithTime(date, timezoneOffset)}</div>
              )),
            },
          }}
          type={ALERT_KINDS.warning}
        />
      )}

      {availabilityConflictMessageId && (
        <Alert
          className="mb-4"
          message={{
            id: availabilityConflictMessageId,
            values: { selectedEmployeeName },
          }}
          type={ALERT_KINDS.warning}
        />
      )}
    </>
  )

TimeConflictsWarningComponent.defaultProps = {
  availabilityConflictMessageId: null,
}

TimeConflictsWarningComponent.propTypes = {
  values: PropTypes.shape({
    userProfileId: PropTypes.string,
    repeat: PropTypes.bool,
  }).isRequired,
  conflictDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedEmployeeName: PropTypes.string.isRequired,
  currentEmployee: PropTypes.shape().isRequired,
  availabilityConflictMessageId: PropTypes.string,
  isRepeat: PropTypes.bool.isRequired,
}

export default TimeConflictsWarningComponent
