import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyClientRoute } from 'lib/routes'
import { UPDATE_FORM_SUBMISSION } from 'state/concepts/form/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { updateFormSubmissionParams } from 'utils/intakeForms'
import redirect from 'utils/redirect'
import { CLIENT_PROFILE_TABS } from 'lib/constants/clientProfile'
import { updateFormSubmissionEndpoint } from '../endpoints'
import { cancelAutoSaveResponses } from '../actions'

const updateFormSubmissionOperation = createLogic({
  type: UPDATE_FORM_SUBMISSION,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const { formSubmissionId, clientId } = values
    const { url } = updateFormSubmissionEndpoint(formSubmissionId)
    const state = getState()

    const workspaceCode = currentWorkspaceCodeSelector(state)

    try {
      dispatch(cancelAutoSaveResponses())
      await httpClient.put(url, updateFormSubmissionParams(values))

      redirect({
        href: companyClientRoute(clientId),
        workspace: workspaceCode,
        query: {
          activeTab: CLIENT_PROFILE_TABS.forms,
        },
      })
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.formResponsesUpdated',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)

      if (isErrorStatusUnprocessableEntity(error)) {
        const { base } = formatJsonApiErrors(error.response.data.errors)

        if (base) {
          dispatch(showNotification({ messageObject: base, kind: 'error' }))
        }
      }
    }

    done()
  },
})

export default updateFormSubmissionOperation
