import { createLogic } from 'redux-logic'

import { sendCommand } from 'state/concepts/webSockets/actions'
import { joinChatCommand } from 'utils/chat'
import { CREATE_DIRECT_CHAT } from 'state/concepts/chat/types'
import requestErrorHandler from 'lib/requestErrorHandler'
import redirect from 'utils/redirect'
import { showNotification } from 'state/notifications/actions'
import { chatsRoute } from 'lib/apiRoutes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companyChatRoute } from 'lib/routes'
import { CHAT_TYPES } from 'lib/constants/chat'
import { hideModal } from 'state/modal/actions'

const createDirectChatOperation = createLogic({
  type: CREATE_DIRECT_CHAT,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { chatUserId } = action
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    try {
      const params = {
        chat_type: CHAT_TYPES.direct,
        chat_user_ids: [chatUserId],
      }

      const { data } = await httpClient.post(chatsRoute, params)
      const { id } = data.data

      dispatch(sendCommand(joinChatCommand(id)))
      dispatch(hideModal())
      redirect({
        href: companyChatRoute(id),
        workspace: workspaceCode,
      })
      dispatch(showNotification({ messageObject: { id: 'notifications.chatHasBeenCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default createDirectChatOperation
