import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import fileDownload from 'js-file-download'
import { DateTime } from 'luxon'
import classNames from 'clsx'

import Button from 'views/shared/Button'
import CopyButton from 'views/shared/CopyButton'

const CodesList = ({ backupCodes, oldIsExpired, isSetup }) => {
  const onDownload = () => {
    const data = new Blob([backupCodes.join('\n')], { type: 'text/plain' })
    const timeStamp = DateTime.now().toFormat('yyyy-MM-dd-HH-mm')

    fileDownload(data, `backup_code_expertbox_${timeStamp}.txt`)
  }

  return (
    <div className={classNames({ 'main-modal__body': !isSetup })}>
      {oldIsExpired && (
        <p className="text-body">
          <FormattedMessage id="employeeTwoFactorSettings.backupCodes.expired" />
        </p>
      )}
      <p className="text-body">
        <FormattedMessage id="employeeTwoFactorSettings.backupCodes.saveCodes" />
      </p>
      <div className="backup-codes__container">
        <div
          className={classNames({
            'backup-codes__list p-16': !isSetup,
            'backup-codes__list backup-codes__list--two-column p-16': isSetup,
          })}
        >
          {Array.isArray(backupCodes) &&
            backupCodes.map(code => (
              <p key={code} className="mb-0 font-700">
                {code}
              </p>
            ))}
        </div>
        <div className="d-flex column-gap-16 justify-content-end p-16 backup-codes__actions">
          <CopyButton
            icon="copy"
            iconSize={18}
            iconWrapWithTextClassName="wh-24-24 d-flex align-items-center justify-content-center"
            size="medium"
            kind="secondary"
            text={{ id: 'shared.copy' }}
            message={backupCodes.join(' ')}
          />
          <Button
            icon="download"
            iconSize={20}
            iconWrapWithTextClassName="wh-24-24 d-flex align-items-center justify-content-center"
            size="medium"
            kind="secondary"
            text={{ id: 'shared.download' }}
            onClick={onDownload}
          />
        </div>
      </div>
    </div>
  )
}

CodesList.defaultProps = {
  oldIsExpired: false,
  isSetup: false,
  backupCodes: [],
}

CodesList.propTypes = {
  backupCodes: PropTypes.arrayOf(PropTypes.string),
  oldIsExpired: PropTypes.bool,
  isSetup: PropTypes.bool,
}

export default CodesList
