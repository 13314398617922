import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { filtersSelector } from 'state/concepts/companyClientAttachments/selectors'
import { FETCH_CLIENT_ATTACHMENTS } from 'state/concepts/companyClientAttachments/types'
import { fetchClientAttachmentsEndpoint } from 'state/concepts/companyClientAttachments/endpoints'
import { addClientAttachmentsIds, setClientAttachmentsIds } from 'state/concepts/companyClientAttachments/actions'

const fetchAttachmentsOperation = createLogic({
  type: FETCH_CLIENT_ATTACHMENTS,
  latest: true,

  async process(
    {
      httpClient,
      getState,
      action: {
        clientId,
        options: { size, append, number, nonClientFiles },
      },
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const employee = currentEmployeeSelector(state)
    const { endpoint, url } = fetchClientAttachmentsEndpoint(clientId)

    const params = {
      include: ['user-profile', 'business-storage', 'shared-file', 'client-profile'],
      sort: '-created_at',
      page: {
        number,
        size,
      },
      filter: {
        timezone: employee.timezone,
        ...filtersSelector(state),
        non_client_files: nonClientFiles,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      const ids = map(prop('id'), data.data)
      dispatch(append ? addClientAttachmentsIds(ids) : setClientAttachmentsIds(ids))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchAttachmentsOperation
