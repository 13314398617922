import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { all, identity, values } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

import { RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'
import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import { companyBookingsRoute } from 'lib/routes'
import { currentUserProfileSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showModal as showModalAction } from 'state/modal/actions'
import { showSidebar as showSidebarAction } from 'state/sidebar/actions'
import { isCancelled, isCompleted, isRequested, isUnavailableReason, isLastRecurring } from 'utils/bookings'
import getBookingToEditProps from 'utils/bookings/getBookingToEdit'
import initialRescheduleRecurringFormValues from 'utils/bookings/initialRescheduleRecurringFormValues'
import isPresent from 'utils/isPresent'
import redirect from 'utils/redirect'
import { couldBeChargeWithCardReader, couldBeMarkedAsPaid } from 'utils/payments'
import isScheduler from 'utils/employeePermissions/isScheduler'
import ExpertActionsComponent from './component'

class ExpertActions extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    booking: PropTypes.shape().isRequired,
    currentUser: PropTypes.shape().isRequired,
    showSidebar: PropTypes.func.isRequired,
    isBookingParticipant: PropTypes.bool.isRequired,
    onCloseTooltip: PropTypes.func,
    onCancel: PropTypes.func,
    currentWorkspaceCode: PropTypes.string.isRequired,
  }

  static defaultProps = {
    onCloseTooltip: noop,
    onCancel: noop,
  }

  get isRequested() {
    const { booking } = this.props

    return isRequested(booking)
  }

  get isUnavailable() {
    const { booking } = this.props

    return isUnavailableReason(booking)
  }

  get toGoogleCalendarHidden() {
    const { isBookingParticipant } = this.props

    return this.isUnavailable || !isBookingParticipant
  }

  get toOutlookHidden() {
    const { isBookingParticipant } = this.props

    return this.isUnavailable || !isBookingParticipant
  }

  get addToCalendarHidden() {
    return this.toGoogleCalendarHidden && this.toOutlookHidden
  }

  get isHiddenMarkAsPaid() {
    const {
      booking: { transactions },
    } = this.props

    return !isPresent(transactions) || !couldBeMarkedAsPaid(transactions[0])
  }

  get isHiddenChargeWithCardReader() {
    const {
      booking: { transactions },
    } = this.props

    return !isPresent(transactions) || !couldBeChargeWithCardReader(transactions[0])
  }

  get dropdownPermissions() {
    const { booking, currentUser } = this.props

    return {
      addToCalendarHidden: this.addToCalendarHidden,
      addToGoogleCalendarHidden: this.toGoogleCalendarHidden,
      addToOutlookHidden: this.toOutlookHidden,
      rescheduleBookingHidden: this.isUnavailable,
      editPendingAppointmentHidden: !this.isRequested,
      cancelBookingHidden: isCompleted(booking) || isCancelled(booking),
      addToCollectPaymentHidden:
        isScheduler(currentUser) || (this.isHiddenChargeWithCardReader && this.isHiddenMarkAsPaid),
      isHiddenChargeWithCardReader: this.isHiddenChargeWithCardReader,
      isHiddenMarkAsPaid: this.isHiddenMarkAsPaid,
    }
  }

  get dropdownHidden() {
    return all(identity, values(this.dropdownPermissions))
  }

  redirectToBookings = () => {
    const { currentWorkspaceCode } = this.props

    redirect({
      href: companyBookingsRoute,
      workspace: currentWorkspaceCode,
    })
  }

  handleRescheduleByType = type => {
    const { booking, showSidebar } = this.props
    const isSingle = type === RECURRING_BOOKING_OPTIONS.single

    this.redirectToBookings()

    showSidebar({
      sidebarType: SIDEBAR_COMPONENTS_TYPES.rescheduleBookingAppointment,
      sidebarProps: initialRescheduleRecurringFormValues(booking, isSingle),
    })
  }

  handleChargeWithCardReader = () => {
    const { showModal, booking } = this.props
    showModal({
      modalType: 'CHARGE_WITH_CARD_READER_MODAL',
      modalProps: { transaction: booking.transactions[0] },
    })
  }

  handleMarkAsPaid = () => {
    const { showModal, booking } = this.props

    showModal({
      modalType: 'MARK_AS_PAID_MODAL',
      modalProps: { transaction: booking.transactions[0] },
    })
  }

  onReschedule = () => {
    const {
      showModal,
      booking,
      booking: { bookingRecurrence },
    } = this.props

    if (isPresent(bookingRecurrence) && !isLastRecurring(booking)) {
      showModal({
        modalType: 'RESCHEDULE_RECURRING_BOOKING_MODAL',
        modalProps: {
          headerTitle: { id: 'booking.rescheduleRecurrenceAppointment' },
          onSubmit: this.handleRescheduleByType,
        },
      })
    } else {
      this.handleRescheduleByType(RECURRING_BOOKING_OPTIONS.single)
    }
  }

  handleEditSubmit = amount => {
    const { booking, showSidebar } = this.props

    this.redirectToBookings()

    if (amount === RECURRING_BOOKING_OPTIONS.single) {
      showSidebar({
        sidebarType: SIDEBAR_COMPONENTS_TYPES.editBookingAppointment,
        sidebarProps: getBookingToEditProps(booking),
      })
    } else {
      showSidebar({
        sidebarType: SIDEBAR_COMPONENTS_TYPES.rescheduleBookingAppointment,
        sidebarProps: initialRescheduleRecurringFormValues(booking, false),
      })
    }
  }

  handleEdit = event => {
    const {
      showModal,
      booking,
      booking: { bookingRecurrence },
    } = this.props
    event.preventDefault()

    if (isPresent(bookingRecurrence) && !isLastRecurring(booking)) {
      showModal({
        modalType: 'RESCHEDULE_RECURRING_BOOKING_MODAL',
        modalProps: {
          headerTitle: { id: 'booking.editRecurrenceAppointment' },
          onSubmit: this.handleEditSubmit,
        },
      })
    } else {
      this.handleEditSubmit(RECURRING_BOOKING_OPTIONS.single)
    }
  }

  onCancel = () => {
    const {
      showModal,
      booking,
      booking: { bookingRecurrence },
      onCancel,
    } = this.props

    showModal({
      modalType:
        bookingRecurrence && !isLastRecurring(booking) ? 'CANCEL_RECURRING_BOOKING_MODAL' : 'CANCEL_BOOKING_MODAL',
      modalProps: { booking },
    })

    onCancel()
  }

  render = () => (
    <ExpertActionsComponent
      {...this.props}
      onReschedule={this.onReschedule}
      handleChargeWithCardReader={this.handleChargeWithCardReader}
      onMarkAsPaid={this.handleMarkAsPaid}
      onEdit={this.handleEdit}
      onCancel={this.onCancel}
      dropdownPermissions={this.dropdownPermissions}
      dropdownHidden={this.dropdownHidden}
    />
  )
}

const mapDispatchToProps = {
  showModal: showModalAction,
  showSidebar: showSidebarAction,
}

const mapStateToProps = state => ({
  currentWorkspaceCode: currentWorkspaceCodeSelector(state),
  currentUser: currentUserProfileSelector(state),
})

export { ExpertActions as ExpertActionsContainer }
export default connect(mapStateToProps, mapDispatchToProps)(ExpertActions)
