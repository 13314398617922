import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchTasks = (pageParams = {}) => ({
  type: types.FETCH_TASKS,
  pageParams,
})

export const addTaskIds = taskIds => ({
  type: types.ADD_TASK_IDS,
  taskIds,
})

export const resetTasks = () => ({
  type: types.RESET_TASKS,
})

export const deleteTask = (task, feedId) => ({
  type: types.DELETE_TASK,
  task,
  feedId,
})

export const removeTaskId = id => ({
  type: types.REMOVE_TASK_ID,
  id,
})

export const setTasksCounts = counts => ({
  type: types.SET_TASKS_COUNTS,
  counts,
})

export const filterTasks = filters => ({
  type: types.FILTER_TASKS,
  filters,
})

export const setFilterParams = (filterParams = {}) => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})

export const createTask = makeFormSubmitAction(types.CREATE_TASK)
export const updateTask = makeFormSubmitAction(types.UPDATE_TASK)

export const addCreatedTaskId = id => ({
  type: types.ADD_CREATED_TASK_ID,
  id,
})

export const setView = view => ({
  type: types.SET_VIEW,
  view,
})

export const decrementTasksCount = (counter, withoutAllTasksCount = false) => ({
  type: types.DECREMENT_TASKS_COUNT,
  counter,
  withoutAllTasksCount,
})

export const incrementTasksCount = (counter, withoutAllTasksCount = false) => ({
  type: types.INCREMENT_TASKS_COUNT,
  counter,
  withoutAllTasksCount,
})

export const updateTaskPriority = ({ id, priority, orderedFields }) => ({
  type: types.UPDATE_TASK_PRIORITY,
  id,
  priority,
  orderedFields,
})

export const setIsCreatingTask = isCreating => ({
  type: types.SET_IS_CREATING_TASK,
  isCreating,
})

export const markTaskCompleted = ({ task, feedId, skipRemoveFromList }) => ({
  type: types.MARK_TASK_AS_COMPLETED,
  task,
  feedId,
  skipRemoveFromList,
})

export const markTaskAsToDo = ({ task, feedId, skipRemoveFromList }) => ({
  type: types.MARK_TASK_AS_TODO,
  task,
  feedId,
  skipRemoveFromList,
})

export const setTaskToEdit = task => ({
  type: types.SET_TASK_TO_EDIT,
  task,
})
