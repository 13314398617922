import yup from 'lib/yupLocalised'

import {
  HEADER_TITLE_MAX_LENGTH,
  HEADER_SUBTITLE_MAX_LENGTH,
  TERMS_AND_CONDITIONS_MAX_LENGTH,
  PRIVACY_POLICY_MAX_LENGTH,
  WEBSITE_PAGE_TITLE_MAX_LENGTH,
  WEBSITE_META_DESCRIPTION_MAX_LENGTH,
} from 'lib/constants/website'

export const widgetValidationSchema = yup.object().shape({
  widgetId: yup.string().required(),
})

export const legalPagesValidationSchema = yup.object().shape({
  termsAndConditionsEnabled: yup.boolean(),
  termsAndConditions: yup.object().textMaxLength(TERMS_AND_CONDITIONS_MAX_LENGTH),
  privacyPolicyEnabled: yup.boolean(),
  privacyPolicy: yup.object().textMaxLength(PRIVACY_POLICY_MAX_LENGTH),
})

export const googleMetaValidationSchema = yup.object().shape({
  title: yup.string().max(WEBSITE_PAGE_TITLE_MAX_LENGTH),
  metaDescription: yup.string().max(WEBSITE_META_DESCRIPTION_MAX_LENGTH),
})
export const headerValidationSchema = yup.object().shape({
  headerTitle: yup.string().max(HEADER_TITLE_MAX_LENGTH).required(),
  headerSubtitle: yup.string().max(HEADER_SUBTITLE_MAX_LENGTH),
})

export const contentValidationSchema = headerValidationSchema.concat(legalPagesValidationSchema)
