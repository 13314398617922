import { divide, includes } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fileExtension } from 'utils/file'
import { showModal as showModalAction } from 'state/modal/actions'
import UploadLogoComponent from './component'

class UploadLogo extends React.Component {
  static defaultProps = {
    isCircularCrop: false,
    skipRemoveConfirmation: false,
  }

  static propTypes = {
    isCircularCrop: PropTypes.bool,
    skipRemoveConfirmation: PropTypes.bool,
    showModal: PropTypes.func.isRequired,
    uploadAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    removeBodyText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  handleSelectImage = event => {
    const { files } = event.target
    const { showModal, uploadAction, isCircularCrop } = this.props

    if (files.length) {
      const file = files[0]
      // file size in MB.
      const fileSize = divide(file.size, 1048576)
      const fileType = file.type

      const isValidType = includes(fileType, ['image/jpeg', 'image/jpg', 'image/png'])
      const isValidExtension = includes(fileExtension(file), ['jpg', 'jpeg', 'png'])

      if (fileSize > 10 || !isValidType || !isValidExtension) {
        showModal({
          modalType: 'UPLOAD_ERROR_MODAL',
          modalProps: {
            onSelectImage: this.handleSelectImage,
          },
        })

        return
      }

      showModal({
        modalType: 'LOGO_MODAL',
        modalProps: {
          src: URL.createObjectURL(file),
          mimeType: file.type,
          fileName: file.name,
          onSelectImage: this.handleSelectImage,
          uploadAction,
          isCircularCrop,
          ...this.props,
        },
      })
    }
  }

  handleRemoveImage = () => {
    const { showModal, removeAction, removeBodyText, skipRemoveConfirmation } = this.props

    if (!skipRemoveConfirmation) {
      showModal({
        modalType: 'LOGO_REMOVE_MODAL',
        modalProps: { removeAction, removeBodyText, ...this.props },
      })
    } else {
      removeAction()
    }
  }

  render = () => (
    <UploadLogoComponent
      {...this.props}
      onSelectImage={this.handleSelectImage}
      onRemoveImage={this.handleRemoveImage}
    />
  )
}

const mapDispatchToProps = {
  showModal: showModalAction,
}

export { UploadLogo as UploadLogoContainer }
export default connect(null, mapDispatchToProps)(UploadLogo)
