import PropTypes from 'prop-types'
import classNames from 'clsx'
import { InputNumber } from 'antd'
import { is } from 'ramda'
import { getIn } from 'formik'
import { FormattedMessage } from 'react-intl'

import InputMessage from '../InputMessage'

const CurrencyField = ({
  id,
  disabled,
  label,
  icon,
  small,
  className,
  placeholder,
  autoComplete,
  intl,
  formatter,
  parser,
  step,
  field,
  onChange,
  form: { touched, errors, status },
  ...props
}) => {
  const fieldTouched = getIn(touched, field.name)
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)

  const wrapperClassNames = classNames(className, 'main-input input-numeric', {
    'main-input--disabled': disabled,
    'main-input--has-message': fieldTouched && fieldErrors,
    'main-input--has-message-error': fieldTouched && fieldErrors,
    'main-input--has-message-alert': fieldStatus,
    'main-input--sm': small,
  })

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <InputNumber
          className="main-input__field"
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          id={id}
          disabled={disabled}
          {...field}
          {...props}
          onChange={onChange}
          formatter={formatter}
          parser={parser}
          autoComplete={autoComplete}
          step={step}
        />
      </div>
      {fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon={icon} />}
    </div>
  )
}

CurrencyField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  small: false,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  step: 0.01,
}

CurrencyField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  icon: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  formatter: PropTypes.func.isRequired,
  parser: PropTypes.func.isRequired,
  step: PropTypes.number,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CurrencyField
