import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { FieldArray, useFormikContext } from 'formik'

import { SHARE_FILE_STEPS } from 'lib/constants/businessFiles'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Button from 'views/shared/Button'
import ArrowLeft from 'views/shared/icons/ArrowLeft'
import NewPermissionsFields from './NewPermissionsFields'

const AddPermissions = ({ myBusinessFile, setStep }) => {
  const { name } = myBusinessFile
  const { handleReset } = useFormikContext()
  const onBack = () => {
    handleReset()
    setStep(SHARE_FILE_STEPS.sharedWith)
  }

  return (
    <>
      <div className="main-modal__header d-flex align-items-center main-modal__header--no-shadow">
        <button type="button" data-cy="back-button" onClick={onBack}>
          <ArrowLeft className="mr-12 in-disabled-icon" size={12} />
        </button>
        <h2 className="main-modal__title brake-word">
          <FormattedMessage id="myBusinessFiles.shareFileModal.title" values={{ name }} />
        </h2>
      </div>
      <div className="main-modal__body pt-0">
        <FieldArray name="newPermissions">
          {arrayHelpers => <NewPermissionsFields {...arrayHelpers} myBusinessFile={myBusinessFile} onBack={onBack} />}
        </FieldArray>
      </div>
      <div className="main-modal__footer">
        <div className="d-flex justify-content-end">
          <Button
            className="mr-16"
            text={{ id: 'shared.cancel' }}
            kind="flat"
            onClick={onBack}
            dataCy="cancel-button"
          />
          <SubmitFormButton text={{ id: 'myBusinessFiles.shareFileModal.share' }} />
        </div>
      </div>
    </>
  )
}

AddPermissions.propTypes = {
  myBusinessFile: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  setStep: PropTypes.func.isRequired,
}

export default AddPermissions
