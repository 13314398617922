import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import SearchList from 'views/shared/SearchList'

const ClientTimezoneSelectComponent = ({ options, handleSelect, filterTimezones }) => (
  <div className="main-dropdown main-dropdown--right min-w-320">
    <SearchList filterEntities={filterTimezones} className="main-dropdown__search" />
    <div className="main-dropdown__body main-dropdown__body--scrolled">
      <div className="main-dropdown__section">
        {options.length > 0 ? (
          options.map(({ key, value, label }) => (
            <div className="main-dropdown__item" key={key} onClick={handleSelect(value)} role="button">
              {label}
            </div>
          ))
        ) : (
          <p className="main-dropdown__item-empty">
            <FormattedMessage id="placeholders.emptyTimezone" />
          </p>
        )}
      </div>
    </div>
  </div>
)

ClientTimezoneSelectComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSelect: PropTypes.func.isRequired,
  filterTimezones: PropTypes.func.isRequired,
}

export default ClientTimezoneSelectComponent
