import { createLogic } from 'redux-logic'
import { without } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { REMOVE_CHAT_MEMBER } from 'state/concepts/chat/types'
import { removeChatMemberEndpoint } from '../endpoints'

const removeChatMember = createLogic({
  type: REMOVE_CHAT_MEMBER,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const {
      chat: { id, chatUserIds },
      memberId,
    } = action
    const { endpoint, url } = removeChatMemberEndpoint(id, memberId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      const response = updateDataHelper(getState().data, 'chat', id, {
        attributes: {
          chatUserIds: without([Number(memberId)], chatUserIds),
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.chatMemberHasBeenRemoved' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default removeChatMember
