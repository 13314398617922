import { combineReducers } from '@reduxjs/toolkit'

import { SET_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS_ID } from './types'

const notificationSettingsId = (state = null, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS_ID:
      return action.notificationSettingsId
    default:
      return state
  }
}

const notificationSettingsReducer = combineReducers({
  notificationSettingsId,
})

export default notificationSettingsReducer
