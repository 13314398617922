import fetchWaitlistRequests from './fetchWaitlistRequests'
import fetchWaitlistRequest from './fetchWaitlistRequest'
import createWaitlistRequest from './createWaitlistRequest'
import filterWaitlistRequests from './filterWaitlistRequests'
import deleteWaitlist from './deleteWaitlist'
import updateClientWaitlistRequest from './updateClientWaitlistRequest'
import updateWaitlistRequest from './updateWaitlistRequest'
import resolveWaitlistRequest from './resolveWaitlistRequest'

export default [
  fetchWaitlistRequests,
  createWaitlistRequest,
  filterWaitlistRequests,
  fetchWaitlistRequest,
  deleteWaitlist,
  updateClientWaitlistRequest,
  updateWaitlistRequest,
  resolveWaitlistRequest,
]
