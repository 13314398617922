import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCallback, useMemo } from 'react'
import { useMount } from 'react-use'
import { sortBy, prop } from 'ramda'

import { updateOptionsPriority } from 'utils/customField'
import { MAX_FIELD_OPTIONS_COUNT } from 'lib/constants/customField'
import arrayMove from 'utils/arrayMove'
import Button from 'views/shared/Button'
import FieldOptions from './FieldOptions'

const FieldOptionFields = ({
  push,
  remove,
  form: {
    setValues,
    values,
    values: { fieldOptions, fieldType },
  },
}) => {
  const { formatMessage } = useIntl()

  const sortedOptions = useMemo(() => sortBy(prop('priority'), fieldOptions), [fieldOptions])

  const addRow = useCallback(() => {
    push({
      name: formatMessage({ id: 'clientFields.newOption' }, { number: fieldOptions.length + 1 }),
      priority: fieldOptions.length,
    })
  }, [fieldOptions.length, push, formatMessage])

  useMount(() => {
    if (!fieldOptions.length) {
      addRow()
    }
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const movedOptions = arrayMove(fieldOptions, oldIndex, newIndex)

      setValues(
        {
          ...values,
          fieldOptions: updateOptionsPriority(movedOptions),
        },
        false,
      )
    }
  }

  return (
    <div className="main-modal__full-wrap mt-16">
      <p className="text-body mb-8 in-blue-gray-300 pl-16 pr-16">
        <FormattedMessage id="clientFields.options" />
      </p>

      <FieldOptions
        fieldOptions={sortedOptions}
        fieldType={fieldType}
        onSortEnd={onSortEnd}
        removeRow={remove}
        helperClass="question-option-dragging"
        useDragHandle
      />
      {fieldOptions.length < MAX_FIELD_OPTIONS_COUNT && (
        <Button
          onClick={addRow}
          className="ml-24 main-btn--text-blue"
          text={{ id: 'clientFields.addOption' }}
          kind="text"
          icon="plus"
          iconSize={14}
        />
      )}
    </div>
  )
}

FieldOptionFields.propTypes = {
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setValues: PropTypes.func.isRequired,
    values: PropTypes.shape({
      fieldType: PropTypes.string.isRequired,
      fieldOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          priority: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default FieldOptionFields
