import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

import { TWO_FACTOR_SETTING_METHODS, TWO_FACTOR_SETTING_STEPS } from 'lib/constants/twoFactorSettings'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import SmsMethodFields from './SmsMethodFields'

const SmsMethodStep = ({ onClose, onBack, currentStep, setStep }) => {
  const isStepWithOtp = currentStep === TWO_FACTOR_SETTING_STEPS.enableSms
  const {
    setFieldValue,
    values: { phoneNumber, otpCode },
  } = useFormikContext()

  const resetSmsValues = () => {
    setFieldValue('phoneNumber', '')
    setFieldValue('otpCode', '')
  }

  const handleBackClick = () => {
    setFieldValue('methodChecker', TWO_FACTOR_SETTING_METHODS.email)
    resetSmsValues()
    onBack()
  }

  return (
    <>
      <div className="main-modal__body overflow-y-visible">
        <SmsMethodFields
          currentStep={currentStep}
          setStep={setStep}
          verifyNumberStep={TWO_FACTOR_SETTING_STEPS.verifyNumber}
          isStepWithOtp={currentStep === TWO_FACTOR_SETTING_STEPS.enableSms}
        />
      </div>
      <div className="main-modal__footer">
        <Button
          kind="flat"
          className="mr-auto main-btn--icon-gray pl-0 pr-16 min-w-auto"
          text={{ id: 'shared.back' }}
          icon="arrow-left"
          iconSize={10}
          iconWrapWithTextClassName="wh-24-24 d-flex align-items-center justify-content-center"
          onClick={handleBackClick}
        />
        <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
        <SubmitFormButton
          className="main-modal__footer-action"
          text={{
            id: isStepWithOtp
              ? 'employeeTwoFactorSettings.setSms.enable'
              : 'employeeTwoFactorSettings.setSms.verifyPhoneNumber',
          }}
          disabled={isStepWithOtp ? !otpCode : !phoneNumber}
        />
      </div>
    </>
  )
}

SmsMethodStep.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default SmsMethodStep
