import normalize from 'json-api-normalizer'
import { DateTime } from 'luxon'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setBookingTimeSlots } from 'state/concepts/booking/actions'
import { clientBookingAvailabilityEndpoint } from 'state/concepts/booking/endpoints'
import { GET_CLIENT_BOOKING_AVAILABILITY } from 'state/concepts/booking/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { firstDateOfMonthISO, lastDateOfMonthISO } from 'utils/dateTime'

const getClientBookingAvailabilityOperation = createLogic({
  type: GET_CLIENT_BOOKING_AVAILABILITY,
  latest: true,

  async process({ httpClient, action: { filter } }, dispatch, done) {
    const { date, uniqCode, timezone } = filter
    const { endpoint, url } = clientBookingAvailabilityEndpoint(uniqCode)

    const minDate = DateTime.now()
    const startDate = date.hasSame(DateTime.now(), 'month') ? minDate : date

    const params = {
      filter: {
        start_date: firstDateOfMonthISO(startDate),
        end_date: lastDateOfMonthISO(startDate),
        timezone,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      const response = normalize(data, { endpoint, camelizeKeys: false })
      const { 'users-time-slots': timeSlots } = build(response, 'booking', data.data.id)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setBookingTimeSlots(timeSlots))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getClientBookingAvailabilityOperation
