import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { CANCEL_RECURRING_PAYMENT } from 'state/concepts/recurringPayment/types'
import { cancelRecurringPaymentEndpoint } from 'state/concepts/recurringPayment/endpoints'
import { hideModal } from 'state/modal/actions'

const cancelRecurringPaymentOperation = createLogic({
  type: CANCEL_RECURRING_PAYMENT,
  latest: true,

  async process({ httpClient, action: { recurringPaymentId, onSuccess } }, dispatch, done) {
    const { url, endpoint } = cancelRecurringPaymentEndpoint(recurringPaymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.post(url)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.recurringPaymentCancelled',
          },
        }),
      )
      onSuccess?.()
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors

        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default cancelRecurringPaymentOperation
