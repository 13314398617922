import systemUserLogo from 'assets/images/system_user_logo.svg'

export const SYSTEM_MESSAGES = {
  memberAdded: 'chat.system_messages.new_chat_member',
  memberRemoved: 'chat.system_messages.member_removed',
  memberLeftChat: 'chat.system_messages.member_left_chat',
  chatLogoUpdated: 'chat.system_messages.logo_updated',
  chatLogoRemoved: 'chat.system_messages.logo_removed',
  chatNameUpdated: 'chat.system_messages.name_updated',
}

export const CHAT_STATUS = {
  active: 'active',
  inactive: 'inactive',
}

export const MARK_AS_READ_DELAY = 1000

export const USER_NETWORK_STATUSES = {
  online: 'online',
  offline: 'offline',
}

export const NEW_MESSAGES_INDICATOR_TIMEOUT = 5000

export const MAX_UPLOAD_FILE_LENGTH = 60

export const SYSTEM_CHAT_USER = {
  firstName: 'ExpertBox',
  lastName: '',
  avatarUrls: {
    small: systemUserLogo,
  },
}

export const MAX_CHAT_NAME_LENGTH = 160

export const CHAT_DETAILS_MODAL_ACTIONS = {
  create: 'create',
  update: 'update',
}

export const CHAT_INFO_MODAL_TABS = {
  members: 'members',
  files: 'files',
  voice: 'voice',
}

export const CHAT_MEMBER_TYPE = {
  expert: 'UserProfile',
  client: 'ClientProfile',
  scheduler: 'SchedulerProfile',
}

export const CHAT_MEMBER_ROLE = {
  scheduler: 'scheduler',
  expert: 'user',
  owner: 'workspace_owner',
  admin: 'administrator',
}

export const MAX_CHAT_UNREAD_MESSAGES = 99

export const CHAT_DATA_TYPES = {
  messagesUpdated: 'messages_updated',
  unreadMessagesUpdated: 'unread_messages_updated',
  chatRemoved: 'chat_removed',
  chatUpdated: 'chat_updated',
  chatMembersLeaves: 'chat_members_leaves',
  chatMembersRemoved: 'chat_members_removed',
}

export const ATTACHMENT_TYPES = {
  file: 'file',
  voiceMessage: 'voice_message',
}

export const CHAT_TYPES = {
  room: 'room',
  direct: 'direct',
}

export const KEEP_ONLINE_COMMAND = {
  command: 'message',
  channel: 'ChatAppearanceChannel',
  action: 'keep_online',
}

export const RECEIVE_APPEARANCE_COMMAND = {
  command: 'message',
  channel: 'ChatAppearanceChannel',
  action: 'receive_appearance',
}

export const CHAT_USER_ACTIVITY_MINUTES = 5

export const FORBIDDEN_FILE_EXTENSIONS = [
  'ade',
  'adp',
  'apk',
  'bat',
  'cab',
  'chm',
  'cmd',
  'com',
  'cpl',
  'dll',
  'dmg',
  'exe',
  'hta',
  'ins',
  'isp',
  'jar',
  'js',
  'jse',
  'lib',
  'lnk',
  'mde',
  'msc',
  'msi',
  'msp',
  'mst',
  'nsh',
  'pif',
  'scr',
  'sct',
  'shb',
  'sys',
  'vb',
  'vbe',
  'vbs',
  'vxd',
  'wsc',
  'wsf',
  'wsh',
  'action',
  'app',
  'bin',
  'command',
  'csh',
  'gadget',
  'inf1',
  'inx',
  'ipa',
  'isu',
  'job',
  'ksh',
  'osx',
  'out',
  'paf',
  'prg',
  'ps1',
  'reg',
  'rgs',
  'run',
  'sct',
  'shb',
  'shs',
  'u3p',
  'vbs',
  'vbscript',
  'workflow',
  'ws',
]

export const MAX_MESSAGE_CONTENT_HEIGHT = 74
