import { CardNumberElement } from '@stripe/react-stripe-js'
import { last, pathOr } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsSubscriptionRoute } from 'lib/routes'
import { createSetupIntentEndpoint } from 'state/concepts/billing/endpoints'
import { resetPromocodes } from 'state/concepts/promocodes/actions'
import { promocodesSelector } from 'state/concepts/promocodes/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { UPDATE_SUBSCRIPTIONS } from 'state/concepts/subscriptionPlans/types'
import { showNotification } from 'state/notifications/actions'
import { getUpdateSubscriptionsUrl, isFreePlan } from 'utils/billing'
import { formattedFullDate } from 'utils/dateTime'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import isPresent from 'utils/isPresent'
import redirect from 'utils/redirect'
import { changeSubscriptionDetailsSelector, plannedSubscriptionPlanSelector } from '../selectors'

const updateSubscriptionsOperation = createLogic({
  type: UPDATE_SUBSCRIPTIONS,
  latest: true,

  async process(
    { action: { values, form, confirmCardSetup, getStripeElement, setStripeBaseError, onError }, getState, httpClient },
    dispatch,
    done,
  ) {
    const state = getState()
    const {
      subscriptionCycleType,
      paymentDate,
      downgradeDate,
      paymentMethod,
      currentSubscriptionPlan,
      teamSeatsDowngradedQuantity,
    } = changeSubscriptionDetailsSelector(state)
    const { name: currentPlanName } = currentSubscriptionPlan
    const isCurrentPlanFree = isFreePlan(currentSubscriptionPlan)
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const { name: planName } = plannedSubscriptionPlanSelector(state, values.planId)
    const { isTrial, isUpgrade } = values
    const url = getUpdateSubscriptionsUrl({ isTrial, isUpgrade, isCurrentPlanFree, subscriptionCycleType })
    const appliedPromocodeData = last(promocodesSelector(state))
    const { url: createSetupIntentUrl } = createSetupIntentEndpoint

    let paymentMethodId

    try {
      if (!paymentMethod) {
        const { data } = await httpClient.post(createSetupIntentUrl)
        const { setupIntent, error } = await confirmCardSetup(data.meta.client_secret, {
          payment_method: {
            card: getStripeElement(CardNumberElement),
            billing_details: {
              address: {
                line1: values.address,
                postal_code: values.index,
                country: values.country,
                state: values.state,
                city: values.city,
              },
            },
          },
        })
        paymentMethodId = setupIntent?.payment_method
        if (error) {
          setStripeBaseError(error.message)
          // eslint-disable-next-line no-throw-literal
          throw { ...error, isStripe: true }
        }
      }
      const teamSeatsCapacityChange =
        teamSeatsDowngradedQuantity > 0 && !values.cancelSeatsDowngrade ? undefined : values.teamSeatsCapacityChange

      const params = {
        stripe_payment_method_id: paymentMethodId,
        subscription_plan_id: values.planId,
        storage_plan_id: values.storagePlanId,
        team_seats_capacity_change: teamSeatsCapacityChange,
        billing_cycle_type: values.billingCycle,
        cancel_team_seats_downgrade: values.cancelSeatsDowngrade,
        code: appliedPromocodeData?.code,
        auto_upgrade: values.autoUpgrade,
      }

      await httpClient.post(url, params)

      dispatch(resetPromocodes())

      await redirect({ href: companySettingsSubscriptionRoute, workspace: workspaceCode })

      dispatch(
        showNotification({
          messageObject: {
            id: `notifications.subscription${isUpgrade ? 'Upgraded' : 'Downgraded'}`,
            values: {
              planName,
              currentPlanName,
              paymentDate: formattedFullDate(isUpgrade ? paymentDate : downgradeDate),
            },
          },
        }),
      )
    } catch (error) {
      const { isStripe } = error
      const errors = pathOr(null, ['response', 'data', 'errors'], error)
      const formattedErrors = formatJsonApiErrors(errors)
      if (isPresent(formattedErrors?.code)) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.applyDiscount.isNotApplied',
            },
            kind: 'error',
          }),
        )
      }
      if (!isStripe) {
        requestErrorHandler({ error, dispatch, form, formStatusKeys: ['base', 'subscriptionPlanId'] })
      }
      form.setSubmitting(false)
      onError?.()
    }
    done()
  },
})

export default updateSubscriptionsOperation
