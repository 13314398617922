import { createLogic } from 'redux-logic'

import { prepareQuestionAttributes, buildFormParams } from 'utils/intakeForms'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_INTAKE_FORM } from 'state/concepts/intakeForm/types'
import { dataApiSuccess } from 'state/data/actions'
import { updateIntakeFormEndpoint } from 'state/concepts/intakeForm/endpoints'

const updateIntakeFormOperation = createLogic({
  type: UPDATE_INTAKE_FORM,
  latest: true,

  async process({ action: { form, values, intakeFormId }, httpClient }, dispatch, done) {
    const { url, endpoint } = updateIntakeFormEndpoint(intakeFormId)

    const questions = prepareQuestionAttributes(values.formQuestionsAttributes)
    if (!questions.length) {
      form.setStatus({ base: { id: 'company.forms.formQuestionsAttributes.error' } })
      form.setSubmitting(false)
      done()
      return
    }
    try {
      await httpClient.put(url, buildFormParams(values, questions))

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.intakeFormUpdated',
          },
        }),
      )
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateIntakeFormOperation
