const namespace = 'companyClientNotes'

export const ADD_CLIENT_NOTES_IDS = `${namespace}/ADD_CLIENT_NOTES_IDS`
export const REMOVE_CLIENT_NOTES_IDS = `${namespace}/REMOVE_CLIENT_NOTES_IDS`
export const FETCH_CLIENT_NOTES = `${namespace}/FETCH_CLIENT_NOTES`
export const RESET_CLIENT_NOTES = `${namespace}/RESET_CLIENT_NOTES`
export const DELETE_CLIENT_NOTE = `${namespace}/DELETE_CLIENT_NOTE`
export const SET_IS_ADD_NOTE_FORM_SHOWN = `${namespace}/SET_IS_ADD_NOTE_FORM_SHOWN`
export const ADD_NOTE = `${namespace}/ADD_NOTE`
export const ADD_NOTE_SUCCESS = `${namespace}/ADD_NOTE_SUCCESS`
export const SET_NOTE_TO_EDIT = `${namespace}/SET_NOTE_TO_EDIT`
export const EDIT_NOTE = `${namespace}/EDIT_NOTE`
export const EDIT_NOTE_ACCESSIBILITY = `${namespace}/EDIT_NOTE_ACCESSIBILITY`
