const servicesFeature = {
  'servicesFeature.subtitle': '<span>in</span> _(Services)',
  'servicesFeature.description': 'Create and edit _(services), booking rules, and more.',
  'servicesFeature.manageFeatures': 'Set _(services)',
  'servicesFeature.overview': '_(Services) overview',
  'servicesFeature.setupYourServices': 'Set up your _(services) to start getting _(appointments) via ExpertBox.',
  'servicesFeature.shortDescription':
    'Add a short description of your _(services) so _(clients) know exactly what they’ll get when they book _a(appointment) with you.',
  'servicesFeature.tailorAppointments':
    'Tailor _(appointments) to fit your schedule: set their duration, create short breaks between sessions, and set booking intervals.',
  'servicesFeature.assignServices':
    'Assign _(services) to team members, making sure that each member gets to share their expertise.',
  'servicesFeature.setupFlexiblePricing':
    'Set up a flexible pricing system, charging different rates for different team members.',
  'servicesFeature.setupYourServicesAndPrices':
    'Once you’ve set up your _(services) and prices, you’re just a few clicks away from getting _(appointment) bookings. Add _a(service) on any of your booking pages and see the _(appointments) fill up your schedule.',
  'servicesFeature.helpCenter':
    'To learn more, read our help center article on <link>setting up your _(services)</link>.',
}

export default servicesFeature
