import PropTypes from 'prop-types'
import classNames from 'clsx'

import SquareO from 'views/shared/icons/SquareO'
import SquareChecked from 'views/shared/icons/SquareChecked'
import CheckboxMixed from 'views/shared/icons/CheckboxMixed'
import FormattedOrRawMessage from '../FormattedOrRawMessage'

const Checkbox = ({
  label,
  disabled,
  className,
  iconSize,
  labelClassName,
  isFieldHidden,
  isIndeterminate,
  checked,
  dataCy,
  labelElement,
  mainCheckboxIconWrapperClassName,
  ...props
}) => (
  <label
    className={classNames('main-checkbox', className, {
      'main-checkbox--disabled': disabled,
    })}
  >
    {!isFieldHidden && (
      <input
        className="main-checkbox__input"
        type="checkbox"
        disabled={disabled}
        data-cy="indeterminate-checkbox"
        data-indeterminate={isIndeterminate}
        checked={checked}
        {...props}
      />
    )}

    {isIndeterminate && (
      <div className={classNames('main-checkbox__icon-wrapper', mainCheckboxIconWrapperClassName)}>
        <CheckboxMixed
          dataCy="mixed-checkbox"
          size={iconSize}
          color="#dde2e8"
          className={classNames('main-checkbox__icon', { 'main-checkbox__icon-checked': !disabled })}
        />
      </div>
    )}

    {!isIndeterminate && checked && (
      <div className={classNames('main-checkbox__icon-wrapper', mainCheckboxIconWrapperClassName)}>
        <SquareChecked
          dataCy="checked-checkbox"
          size={iconSize}
          color="#dde2e8"
          className={classNames('main-checkbox__icon', { 'main-checkbox__icon-checked': !disabled })}
        />
      </div>
    )}

    {!isIndeterminate && !checked && (
      <div className={classNames('main-checkbox__icon-wrapper', mainCheckboxIconWrapperClassName)}>
        <SquareO
          dataCy="unchecked-checkbox"
          size={iconSize}
          color="#dde2e8"
          className="main-checkbox__icon fill-currentcolor"
        />
      </div>
    )}

    {labelElement}

    {label && (
      <span className={classNames(labelClassName, 'main-checkbox__text')} data-cy={dataCy}>
        <FormattedOrRawMessage message={label} />
      </span>
    )}
  </label>
)

Checkbox.defaultProps = {
  disabled: false,
  isFieldHidden: false,
  isIndeterminate: false,
  checked: false,
  className: null,
  labelClassName: null,
  label: null,
  dataCy: undefined,
  labelElement: null,
  mainCheckboxIconWrapperClassName: null,
  iconSize: 18,
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  isFieldHidden: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  dataCy: PropTypes.string,
  labelElement: PropTypes.shape(),
  mainCheckboxIconWrapperClassName: PropTypes.string,
  iconSize: PropTypes.number,
}

export default Checkbox
