import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const isEmailAlertShown = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_EMAIL_ALERT_SHOWN:
      return action.isShow
    default:
      return state
  }
}

const isConnectPayPal = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_CONNECT_PAYPAL:
      return action.isShow
    default:
      return state
  }
}

const isWaitlistScheduleType = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_WAITLIST_SCHEDULE_TYPE:
      return action.isShow
    default:
      return state
  }
}

const alertReducer = combineReducers({
  isEmailAlertShown,
  isConnectPayPal,
  isWaitlistScheduleType,
})

export default alertReducer
