const calendarIntegrationsFeature = {
  'calendarIntegrationsFeature.title': 'Calendar integrations',
  'calendarIntegrationsFeature.subtitle': '<span>in</span> Scheduling',
  'calendarIntegrationsFeature.description':
    'Sync calendars to avoid double-bookings and manage schedule across platforms.',
  'calendarIntegrationsFeature.availableOnlyForAdvanced': 'Available only for advanced plans',
  'calendarIntegrationsFeature.upgradePlan': 'Upgrade plan',
  'calendarIntegrationsFeature.overview': 'Calendar integrations overview',
  'calendarIntegrationsFeature.overview.text1':
    'Effortlessly manage your schedule and prevent double-bookings by syncing your personal or work calendar with ExpertBox. Connect a single calendar with ExpertBox Free, and or upgrade now to sync up to 6 calendars with Premium.  ',
  'calendarIntegrationsFeature.overview.text2': 'Manage your schedule efficiently:',
  'calendarIntegrationsFeature.overview.text3':
    'Sync your availability seamlessly across platforms, such as Google, iCloud, or Microsoft Outlook, ensuring accurate and up-to-date scheduling information.',
  'calendarIntegrationsFeature.overview.text4':
    'Benefit from a two-way integration with a selected calendar, allowing _(appointments) booked through ExpertBox to automatically appear as events on your preferred calendar.',
  'calendarIntegrationsFeature.overview.text5':
    'Eliminate the need for manual calendar updates and save valuable time.',
  'calendarIntegrationsFeature.overview.text6':
    'Take control of your schedule by blocking availability on specific calendars to prevent overlapping _(appointments).',
  'calendarIntegrationsFeature.overview.text7': 'Provide better _(client) experience:',
  'calendarIntegrationsFeature.overview.text8':
    'Provide _(clients) with up-to-date and accurate availability information, ensuring transparency and reliability.',
  'calendarIntegrationsFeature.overview.text9':
    'Build trust and credibility by minimizing scheduling errors and delivering a seamless booking experience.',
  'calendarIntegrationsFeature.toLearnMores':
    'Learn more in our help center article <link>on syncing your calendars</link>.',
  'calendarIntegrationsFeature.disable.title': 'Disable calendar integrations feature?',
  'calendarIntegrationsFeature.disable.description':
    'This will disconnect your and your team’s synced calendars from ExpertBox.',
  'calendarIntegrationsFeature.disable.alert.message':
    'Disabling this feature will disconnect Google Meet video integration.',
  'calendarIntegrationsFeature.syncCalendars': 'Sync calendars',
}

export default calendarIntegrationsFeature
