import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SEARCH_SERVICES } from 'state/concepts/services/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchServicesEndpoint } from 'state/concepts/services/endpoints'
import { addSearchResults, resetSearchResults, setBlankState } from 'state/searchResults/actions'

const searchServicesOperation = createLogic({
  type: SEARCH_SERVICES,
  latest: true,

  async process(
    { action: { query, exclude, userProfileId, statuses, page, resetResults }, httpClient },
    dispatch,
    done,
  ) {
    const { endpoint, url } = fetchServicesEndpoint
    const params = {
      page: {
        size: 20,
        number: page || 1,
      },
      filter: {
        name: query,
        exclude_services: exclude,
        user_profile_id: userProfileId,
        statuses,
      },
      sort: 'service_categories.name',
      include: 'service-category,user-profile-service',
    }

    try {
      dispatch(dataApiRequest({ endpoint }))

      if (resetResults) {
        dispatch(resetSearchResults(SEARCH_RESULTS_ENTITIES.services))
      }

      const { data } = await httpClient.get(url, { params })
      const ids = data.data.map(item => item.id)
      const response = normalize(data, { endpoint })

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.services]: isBlankState({ params: { name: query }, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.services]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default searchServicesOperation
