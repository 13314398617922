import { Field } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { setBookingsDate as setBookingsDateAction } from 'state/concepts/bookings/actions'
import { selectedDateSelector } from 'state/concepts/bookings/selectors'
import { formatInputDate } from 'utils/dateTime'
import DatePickerField from 'views/shared/DatePickerField'

class DatePicker extends React.Component {
  static propTypes = {
    date: PropTypes.shape().isRequired,
    selectedDate: PropTypes.shape().isRequired,
    setBookingsDate: PropTypes.func.isRequired,
    isTooltip: PropTypes.bool,
  }

  static defaultProps = {
    isTooltip: false,
  }

  componentDidMount() {
    const { isTooltip, date, setBookingsDate, selectedDate } = this.props

    if (isTooltip && date && date !== selectedDate) {
      setBookingsDate(date.toISODate())
    }
  }

  componentDidUpdate = prevProps => {
    const { isTooltip, date, setBookingsDate, selectedDate } = this.props

    if (isTooltip && prevProps.date !== date && date !== selectedDate) {
      setBookingsDate(date.toISODate())
    }
  }

  render() {
    return (
      <Field
        name="date"
        id="date"
        component={DatePickerField}
        icon="alert"
        className="sca"
        formatInputDate={formatInputDate}
        hideIcon={false}
        hideSubmit
      />
    )
  }
}

const mapStateToProps = state => ({
  selectedDate: selectedDateSelector(state),
})

const mapDispatchToProps = {
  setBookingsDate: setBookingsDateAction,
}

export { DatePicker as DatePickerContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DatePicker)
