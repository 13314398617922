import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { processLocales } from 'state/concepts/customNames/actions'
import { UPDATE_CUSTOM_NAME } from 'state/concepts/customNames/types'
import { updateCustomNameEndpoint } from 'state/concepts/customNames/endpoints'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const updateCustomNameOperation = createLogic({
  type: UPDATE_CUSTOM_NAME,
  latest: true,

  async process({ action: { form, id, values }, httpClient, getState }, dispatch, done) {
    const workspaceCode = currentWorkspaceCodeSelector(getState())
    const { url } = updateCustomNameEndpoint
    const params = {
      expert_singular: values.expertSingular || null,
      expert_plural: values.expertPlural || null,
      scheduler_singular: values.schedulerSingular || null,
      scheduler_plural: values.schedulerPlural || null,
      client_singular: values.clientSingular || null,
      client_plural: values.clientPlural || null,
      service_singular: values.serviceSingular || null,
      service_plural: values.servicePlural || null,
      appointment_singular: values.appointmentSingular || null,
      appointment_plural: values.appointmentPlural || null,
      service_agreement_singular: values.serviceAgreementSingular || null,
      service_agreement_plural: values.serviceAgreementPlural || null,
    }

    try {
      await httpClient.put(url, params)

      const state = getState()
      const response = updateDataHelper(state.data, 'customName', id, {
        attributes: values,
      })

      dispatch(processLocales(workspaceCode))
      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.customNameWasUpdated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateCustomNameOperation
