import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const addMembers = makeFormSubmitAction(types.ADD_MEMBERS)

export const removeMember = (memberId, bookingId) => ({
  type: types.REMOVE_MEMBER,
  memberId,
  bookingId,
})
