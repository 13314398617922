import PropTypes from 'prop-types'

import { draftJsToMarkup } from 'utils/reactDraftEditor'

const ReactDraftWysiwygPreview = ({ content }) => (
  <div className="content-wrap" data-cy="fillout-agreement-description">
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={draftJsToMarkup(content)}
    />
  </div>
)

ReactDraftWysiwygPreview.defaultProps = {
  content: undefined,
}
ReactDraftWysiwygPreview.propTypes = {
  content: PropTypes.string,
}

export default ReactDraftWysiwygPreview
