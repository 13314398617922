import { createSelector } from '@reduxjs/toolkit'
import { equals, flatten, isEmpty, pipe, propOr } from 'ramda'
import build from 'redux-object'

import { deepCreateSelector } from 'lib/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataSelector } from 'state/data/selectors'

export const companySelector = createSelector(currentEmployeeSelector, propOr(null, 'workspace'))

export const companyLogoSelector = createSelector(companySelector, company =>
  company && company.logoUrl ? company.logoUrl : null,
)

export const selectedEmployeesSelector = deepCreateSelector(
  [dataSelector, (_, employeeIds) => (employeeIds ? flatten([employeeIds]) : [])],
  (data, employeeIds) => (isEmpty(employeeIds) ? [] : build(data, 'userProfile', employeeIds)),
)

export const isOneEmployeeSelector = pipe(companySelector, company => equals(company.employeesCount, 1))
