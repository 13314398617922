import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { path } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { shareFilesWithClientEndpoint } from 'state/concepts/companyClientAttachments/endpoints'
import { SHARE_FILE_WITH_CLIENT } from 'state/concepts/companyClientAttachments/types'

const shareFileWithClientOperation = createLogic({
  type: SHARE_FILE_WITH_CLIENT,
  latest: true,

  async process({ httpClient, action: { sharedFileId, clientId }, getState }, dispatch, done) {
    const { endpoint, url } = shareFilesWithClientEndpoint(clientId)
    const params = {
      client_attachment_ids: [sharedFileId],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, params)
      const response = normalize(data, { endpoint })

      const updateDataResponse = updateDataHelper(getState().data, 'clientAttachment', sharedFileId, {
        relationships: {
          sharedFile: {
            data: { id: path(['data', 0, 'id'], data), type: 'sharedFile' },
          },
        },
      })

      dispatch(dataApiSuccess({ endpoint, response: { ...response, ...updateDataResponse } }))
      dispatch(showNotification({ messageObject: { id: 'notifications.filesSharedWithClient' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default shareFileWithClientOperation
