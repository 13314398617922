import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { REMOVE_CLIENT_OWN_AVATAR } from 'state/concepts/client/types'
import { currentClientIdSelector } from 'state/concepts/session/selectors'
import { clientsAccountAvatarRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { removeClientOwnAvatarEndpoint } from '../endpoints'

const removeClientOwnAvatarOperation = createLogic({
  type: REMOVE_CLIENT_OWN_AVATAR,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint } = removeClientOwnAvatarEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const state = getState()

      await httpClient.delete(clientsAccountAvatarRoute)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.yourProfileImageRemoved' },
        }),
      )

      const response = updateDataHelper(state.data, 'clientProfile', currentClientIdSelector(state), {
        attributes: {
          avatarUrls: null,
        },
      })

      dispatch(dataApiSuccess({ response }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeClientOwnAvatarOperation
