import { combineReducers } from '@reduxjs/toolkit'

import * as types from './types'

const widgetIds = (state = [], action) => {
  switch (action.type) {
    case types.SET_WIDGETS:
      return action.widgetIds
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case types.SET_WIDGETS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case types.SET_WIDGETS_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

const sort = (state = { sortKey: 'name', direction: 'asc' }, action) => {
  switch (action.type) {
    case types.SET_WIDGETS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? { ...state, direction: state.direction === 'asc' ? 'desc' : 'asc' }
        : { sortKey: action.sortKey, direction: 'asc' }
    default:
      return state
  }
}

const widgetsReducer = combineReducers({
  widgetIds,
  isBlankState,
  pagination,
  sort,
})

export default widgetsReducer
