import { createLogic } from 'redux-logic'

import { companySettingsWebsiteRoute } from 'lib/routes'
import requestErrorHandler from 'lib/requestErrorHandler'
import redirect from 'utils/redirect'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { DELETE_WEBSITE } from 'state/concepts/website/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { deleteWebsiteEndpoint } from '../endpoints'
import { setWebsite } from '../actions'

const deleteWebsiteOperation = createLogic({
  type: DELETE_WEBSITE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = deleteWebsiteEndpoint
    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)
      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      await redirect({ href: companySettingsWebsiteRoute, workspace: currentWorkspaceCodeSelector(getState()) })
      dispatch(setWebsite(null))
      dispatch(showNotification({ messageObject: { id: 'notifications.websiteWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteWebsiteOperation
