import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import SwitchField from 'views/shared/SwitchField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { updateStoragePlanSubscription as updateStoragePlanSubscriptionAction } from 'state/concepts/subscriptionPlans/actions'
import { autoUpgradeSelector } from 'state/concepts/storage/selectors'
import isPresent from 'utils/isPresent'
import { billingDetailSelector } from 'state/concepts/billing/selectors'
import useMountAction from 'hooks/shared/useMountAction'
import { fetchBillingDetail } from 'state/concepts/billing/actions'

const AutoUpgradeSettingsModal = ({ onClose }) => {
  const autoUpgrade = useSelector(autoUpgradeSelector)
  const billingDetail = useSelector(billingDetailSelector)
  const plannedStoragePlan = prop('plannedStoragePlan', billingDetail)
  const onSubmit = useFormSubmit(updateStoragePlanSubscriptionAction)

  useMountAction(fetchBillingDetail)

  return (
    <Modal className="main-modal main-modal--md freemium-modal" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title font-600">
            <FormattedMessage id="storage.autoUpgradeSettings.modal.title" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close w-24 h-24">
            <Cross size={10} dataCy="cross-icon" />
          </button>
        </div>
        <Formik onSubmit={onSubmit} initialValues={{ autoUpgrade }}>
          <>
            <div className="main-modal__body">
              <p>
                <FormattedMessage id="storage.autoUpgradeSettings.modal.description" />
              </p>
              <Field
                id="autoUpgrade"
                name="autoUpgrade"
                component={SwitchField}
                className="mb-0 d-flex align-items-center"
                label={{ id: 'storage.autoUpgradeSettings.modal.enable' }}
                disabled={isPresent(plannedStoragePlan)}
              />
            </div>
            <div className="main-modal__footer gap-16">
              <Button text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} dataCy="cancel-button" />
              <SubmitFormButton text={{ id: 'shared.saveChanges' }} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

AutoUpgradeSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AutoUpgradeSettingsModal
