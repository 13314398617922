import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const LockAlt = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M511.999 0c-162.075 0-292.571 130.496-292.571 292.571v48.762c-53.3 0-97.524 44.224-97.524 97.524v487.619c0 53.302 44.224 97.524 97.524 97.524h585.143c53.302 0 97.524-44.222 97.524-97.524v-487.619c0-53.3-44.222-97.524-97.524-97.524v-48.762c0-162.075-130.497-292.571-292.571-292.571zM511.999 97.524c110.992 0 195.048 84.056 195.048 195.048v48.762h-390.095v-48.762c0-110.991 84.056-195.048 195.048-195.048zM219.428 438.857h585.143v487.619h-585.143v-487.619zM511.999 585.143c-53.638 0-97.524 43.886-97.524 97.524s43.886 97.524 97.524 97.524c53.638 0 97.524-43.886 97.524-97.524s-43.886-97.524-97.524-97.524z" />
  </svg>
)

LockAlt.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

LockAlt.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default LockAlt
