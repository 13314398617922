import acceptAndPay from './acceptAndPay'
import deleteDraftPayment from './deleteDraftPayment'
import getPayment from './getPayment'
import getPaymentCheckout from './getPaymentCheckout'
import markAsPaid from './markAsPaid'
import sendPayment from './sendPayment'
import updatePayment from './updatePayment'
import issueRefund from './issueRefund'
import disablePaymentCancellation from './disablePaymentCancellation'
import chargeWithCardReader from './chargeWithCardReader'

export default [
  getPayment,
  acceptAndPay,
  getPaymentCheckout,
  markAsPaid,
  deleteDraftPayment,
  updatePayment,
  sendPayment,
  issueRefund,
  disablePaymentCancellation,
  chargeWithCardReader,
]
