const clientListFeature = {
  'clientListFeature.title': '_(Client) list',
  'clientListFeature.subtitle': '<span>in</span> CRM',
  'clientListFeature.description': 'Keep your _(client) information organized and access it anytime.',
  'clientListFeature.manageFeature': 'Go to _(clients)',
  'clientListFeature.overview': '_(Client) list overview',
  'clientListFeature.subheader':
    // eslint-disable-next-line expbox/custom-name-validation
    'Keep your _(client) information organized and easy to access. With your ExpertBox _(client) list, you can make sure every _(client) gets the best service.',

  'clientListFeature.viewClients': 'View your _(client) list:',
  'clientListFeature.viewClients.list': 'Access your _(client) list in a single place.',
  'clientListFeature.viewClients.filter': 'Quickly filter and sort through _(clients) by name or email.',

  'clientListFeature.clientInformation': 'Keep your _(client) information up to date:',
  'clientListFeature.clientInformation.appointments':
    'Once your _(client) books _a(appointment) with you, their information will automatically be added to your _(client) base.',
  'clientListFeature.clientInformation.create': 'Create _(client) profiles manually.',
  'clientListFeature.clientInformation.update': 'Update _(client) information as needed.',
  'clientListFeature.clientInformation.delete':
    'Delete _(client) profiles that are no longer relevant to keep your _(client) list up to date.',

  'clientListFeature.learnMore':
    'To learn more, read our help center articles on <manageClientsLink>managing your _(clients)</manageClientsLink>.',
}

export default clientListFeature
