import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { removeAttachmentSuccess } from 'state/concepts/chat/actions'
import { REMOVE_ATTACHMENT } from 'state/concepts/chat/types'
import { removeChatsAttachmentsEndpoint } from '../endpoints'

const removeAttachmentOperation = createLogic({
  type: REMOVE_ATTACHMENT,
  latest: true,

  async process({ httpClient, action: { attachmentId } }, dispatch, done) {
    const { url, endpoint } = removeChatsAttachmentsEndpoint(attachmentId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(removeAttachmentSuccess(attachmentId))
      dispatch(showNotification({ messageObject: { id: 'notifications.fileHasBeenDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default removeAttachmentOperation
