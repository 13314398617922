import { concat, difference, isNil } from 'ramda'

import { bookingStartTime } from 'utils/bookings'
import { durationToSeconds } from 'utils/duration'
import { FREE_PRICE } from 'lib/constants/scheduleAppointment'
import { ONLINE_LOCATION_OPTION } from 'lib/constants/locations'

const updateScheduleBookingFormToParams = (
  {
    date,
    time,
    userProfileId,
    serviceId,
    duration,
    price,
    paymentRequired,
    serviceName,
    serviceDescription,
    locationId,
    emails = [],
    alreadyInvitedMembersEmails = [],
  },
  timezoneOffset,
) => {
  const bookingSettingParams = {
    payment_required: paymentRequired,
  }
  const serviceParams = {
    name: serviceName,
    description: serviceDescription,
  }
  const emailsToDestroy = difference(alreadyInvitedMembersEmails, emails)
  const formattedEmailsToDestroy = emailsToDestroy.map(email => ({ email, _destroy: true }))
  const formattedEmailsToAdd = emails.map(email => ({ email, _destroy: false }))

  return {
    user_profile_id: userProfileId,
    service_id: serviceId,
    start_time: bookingStartTime(date, time, timezoneOffset).toISO(),
    duration: durationToSeconds(duration),
    price: price || FREE_PRICE,
    booking_setting_attributes: bookingSettingParams,
    service_attributes: isNil(serviceId) ? serviceParams : undefined,
    location_id: locationId === ONLINE_LOCATION_OPTION.value ? '' : locationId,
    invited_members: concat(formattedEmailsToDestroy, formattedEmailsToAdd),
  }
}

export default updateScheduleBookingFormToParams
