import {
  waitlistRequestsRoute,
  waitlistRequestRoute,
  waitlistRequestResolveRoute,
  clientsWaitlistRequestRoute,
} from 'lib/apiRoutes'
import endpoint from 'utils/endpoint'
import * as types from './types'

export const fetchWaitlistRequestsEndpoint = endpoint(types.FETCH_WAITLIST_REQUESTS, 'GET', waitlistRequestsRoute)

export const deleteWaitlistRequestEndpoint = id =>
  endpoint(types.DELETE_WAITLIST_REQUEST, 'DELETE', waitlistRequestRoute(id))

export const fetchWaitlistRequestEndpoint = id =>
  endpoint(types.FETCH_WAITLIST_REQUEST, 'GET', waitlistRequestRoute(id))
export const createWaitlistRequestEndpoint = endpoint(types.CREATE_WAITLIST_REQUEST, 'POST', waitlistRequestsRoute)
export const updateClientWaitlistRequestEndpoint = id =>
  endpoint(types.UPDATE_CLIENT_WAITLIST_REQUEST, 'PUT', clientsWaitlistRequestRoute(id))
export const updateWaitlistRequestEndpoint = waitlistRequestId =>
  endpoint(types.UPDATE_WAITLIST_REQUEST, 'PUT', waitlistRequestRoute(waitlistRequestId))
export const resolveWaitlistRequestEndpoint = waitlistRequestId =>
  endpoint(types.RESOLVE_WAITLIST_REQUEST, 'GET', waitlistRequestResolveRoute(waitlistRequestId))
