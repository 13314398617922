import endpoint from 'utils/endpoint'
import {
  usersRoute,
  clientsRoute,
  servicesRoute,
  clientServicesRoute,
  clientConsultantsRoute,
  contactClientProfilesRoute,
} from 'lib/apiRoutes'
import {
  GET_CLIENTS,
  GET_SERVICES,
  GET_EMPLOYEES,
  GET_CLIENT_SERVICES,
  GET_CLIENT_CONSULTANTS,
  GET_CONTACT_CLIENT_PROFILES,
  GET_CLIENT_CONTACTS,
} from './types'

export const getClientsEndpoint = endpoint(GET_CLIENTS, 'GET', clientsRoute)
export const getContactClientProfilesEndpoint = endpoint(GET_CONTACT_CLIENT_PROFILES, 'GET', contactClientProfilesRoute)
export const getClientContactsEndpoint = endpoint(GET_CLIENT_CONTACTS, 'GET', contactClientProfilesRoute)
export const getServicesEndpoint = endpoint(GET_SERVICES, 'GET', servicesRoute)
export const getEmployeesEndpoint = endpoint(GET_EMPLOYEES, 'GET', usersRoute)
export const getClientServicesEndpoint = endpoint(GET_CLIENT_SERVICES, 'GET', clientServicesRoute)
export const getClientConsultantsEndpoint = endpoint(GET_CLIENT_CONSULTANTS, 'GET', clientConsultantsRoute)
