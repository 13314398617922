import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { pageCountSelector, currentPageSelector } from 'state/data/selectors'
import { searchLocations as searchLocationsAction } from 'state/concepts/locations/actions'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import Spinner from 'views/shared/icons/Spinner'

class Loader extends React.Component {
  static propTypes = {
    searchLocations: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
  }

  handleLoadMore = () => {
    const { searchLocations, currentPage, pageCount } = this.props

    if (pageCount >= currentPage + 1) {
      searchLocations({ page: currentPage + 1 })
    }
  }

  render() {
    const { isLoading } = this.props

    if (isLoading !== false) {
      return <Spinner size={18} isAbsolute />
    }

    return <Waypoint onEnter={this.handleLoadMore} bottomOffset="-100px" />
  }
}

const mapStateToProps = state => {
  const { endpoint } = fetchLocationsEndpoint

  return {
    pageCount: pageCountSelector(state, endpoint),
    currentPage: currentPageSelector(state, endpoint),
  }
}

const mapDispatchToProps = {
  searchLocations: searchLocationsAction,
}

export { Loader as LoaderContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Loader)
