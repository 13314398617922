const namespace = 'documentTemplates'

export const FETCH_DOCUMENT_TEMPLATES = `${namespace}/FETCH_DOCUMENT_TEMPLATES`
export const SET_DOCUMENT_TEMPLATES_IDS = `${namespace}/SET_DOCUMENT_TEMPLATES_IDS`
export const ADD_DOCUMENT_TEMPLATES_IDS = `${namespace}/ADD_DOCUMENT_TEMPLATES_IDS`
export const RESET_DOCUMENT_TEMPLATES_IDS = `${namespace}/RESET_DOCUMENT_TEMPLATES_IDS`
export const RENAME_DOCUMENT_TEMPLATE = `${namespace}/RENAME_DOCUMENT_TEMPLATE`
export const DELETE_DOCUMENT_TEMPLATE = `${namespace}/DELETE_DOCUMENT_TEMPLATE`
export const FILTER_DOCUMENT_TEMPLATES = `${namespace}/FILTER_DOCUMENT_TEMPLATES`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const CREATE_DOCUMENT_TEMPLATE = `${namespace}/CREATE_DOCUMENT_TEMPLATE`
export const GET_DOCUMENT_TEMPLATE = `${namespace}/GET_DOCUMENT_TEMPLATE`
export const SET_DOCUMENT_TEMPLATE_ID = `${namespace}/SET_DOCUMENT_TEMPLATE_ID`
export const RESET_DOCUMENT_TEMPLATE_ID = `${namespace}/RESET_DOCUMENT_TEMPLATE_ID`
export const PREVIEW_DOCUMENT_TEMPLATE = `${namespace}/PREVIEW_DOCUMENT_TEMPLATE`
export const DEACTIVATE_DOCUMENT_TEMPLATE = `${namespace}/DEACTIVATE_DOCUMENT_TEMPLATE`
export const ACTIVATE_DOCUMENT_TEMPLATE = `${namespace}/ACTIVATE_DOCUMENT_TEMPLATE`
export const DISCARD_DOCUMENT_TEMPLATE = `${namespace}/DISCARD_DOCUMENT_TEMPLATE`
export const TOUCH_DOCUMENT_TEMPLATE = `${namespace}/TOUCH_DOCUMENT_TEMPLATE`
export const PUBLISH_DOCUMENT_TEMPLATE = `${namespace}/PUBLISH_DOCUMENT_TEMPLATE`
