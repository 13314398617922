import { createSelector } from '@reduxjs/toolkit'
import { path, pathEq, pipe } from 'ramda'
import build from 'redux-object'

import { CLIENT_PORTAL_STATUS } from 'lib/constants/clientPortal'
import { dataSelector } from 'state/data/selectors'

export const subdomainSelector = path(['subdomain', 'currentSubdomain'])
export const workspaceIdSelector = path(['subdomain', 'workspaceId'])

export const workspaceSelector = createSelector([workspaceIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'workspace', id) : null,
)

export const isClientPortalEnabledSelector = pipe(workspaceSelector, workspace =>
  pathEq(CLIENT_PORTAL_STATUS.enabled, ['clientPortal', 'status'], workspace),
)

export const isCurrentContactPortalEnabledSelector = pipe(
  workspaceSelector,
  workspace => workspace?.currentContactPortalEnabled !== false,
)
