import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { sendCommand } from 'state/concepts/webSockets/actions'
import { joinChatCommand } from 'utils/chat'
import { CREATE_CHAT } from 'state/concepts/chat/types'
import requestErrorHandler from 'lib/requestErrorHandler'
import redirect from 'utils/redirect'
import { dataApiSuccess } from 'state/data/actions'
import { addChats } from 'state/concepts/chats/actions'
import { showNotification } from 'state/notifications/actions'
import { setChat } from 'state/concepts/chat/actions'
import { chatsRoute } from 'lib/apiRoutes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companyChatRoute } from 'lib/routes'
import { hideModal } from 'state/modal/actions'

const createChatOperation = createLogic({
  type: CREATE_CHAT,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const formData = new FormData()
    const {
      form,
      values: { logo, name },
      chatType,
      chatUserIds,
    } = action
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    try {
      formData.append('chat_type', chatType)
      chatUserIds.forEach(id => formData.append('chat_user_ids[]', id))

      if (name) {
        formData.append('name', name.trim())
      }

      if (logo) {
        formData.append('logo', logo, logo.name)
      }

      const { data } = await httpClient.post(chatsRoute, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      const response = normalize(data)
      const { id } = data.data

      dispatch(dataApiSuccess({ response }))
      dispatch(addChats([id]))
      dispatch(setChat(id))
      dispatch(sendCommand(joinChatCommand(id)))
      dispatch(hideModal())
      redirect({
        href: companyChatRoute(id),
        workspace: workspaceCode,
      })
      dispatch(showNotification({ messageObject: { id: 'notifications.chatHasBeenCreated' } }))
    } catch (error) {
      const chatId = error.response?.data.errors?.[0].meta?.chat_id
      if (chatId) {
        redirect({ href: companyChatRoute(chatId), workspace: workspaceCode })
        dispatch(hideModal())
      }
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default createChatOperation
