import PropTypes from 'prop-types'
import { equals, identity, includes, toLower } from 'ramda'
import { useMemo, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import isPresent from 'utils/isPresent'
import Dropdown from 'views/shared/Dropdown'
import SearchList from 'views/shared/SearchList'
import ArrowDown from 'views/shared/icons/ArrowDown'
import ArrowUp from 'views/shared/icons/ArrowUp'
import Option from './Option'

const CountrySelect = ({ options: initialOptions, iconComponent: Icon, value, onChange, disabled }) => {
  const { label } = initialOptions.find(option => option.value === value) || {}
  const [options, setOptions] = useState(initialOptions)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    setOptions(initialOptions)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleVisibleChange = visible => (visible ? handleOpen() : handleClose())

  const handleSelect = newValue => event => {
    event.stopPropagation()
    onChange(newValue)
    handleClose()
  }

  const handleSearch = ({ name }) => {
    const includesNameIn = includes(toLower(name))

    if (!name) {
      setOptions(initialOptions)
      return
    }
    setOptions(
      initialOptions.filter(option => includesNameIn(toLower(option.value)) || includesNameIn(toLower(option.label))),
    )
  }

  const { lessPriorityOptions, biggerPriorityOptions } = useMemo(() => {
    const lessPrio = []
    const biggerPrio = []
    options.forEach(option => {
      if (equals(option.value, 'US') || equals(option.value, 'GB')) {
        biggerPrio.push(option)
        return
      }
      lessPrio.push(option)
    })
    return {
      biggerPriorityOptions: biggerPrio,
      lessPriorityOptions: lessPrio,
    }
  }, [options])

  return (
    <Dropdown
      data-cy="country-dropdown"
      className="main-popup country-dropdown"
      open={isOpen}
      onOpenChange={handleVisibleChange}
      destroyPopupOnHide
      getPopupContainer={identity}
      disabled={disabled}
      icon={
        <div className="d-flex c-pointer">
          <div className="d-flex">
            <Icon country={value} label={label} />
          </div>
          <button type="button" data-cy="dropdown-button">
            {isOpen ? <ArrowUp size={8} dataCy="arrow-up-icon" /> : <ArrowDown size={8} dataCy="arrow-down-icon" />}
          </button>
        </div>
      }
    >
      <div className="main-dropdown main-dropdown--right min-w-320">
        <SearchList filterEntities={handleSearch} className="main-dropdown__search" />
        <div className="main-dropdown__body main-dropdown__body--scrolled">
          <div className="main-dropdown__section">
            {biggerPriorityOptions.map(option => (
              <Option {...option} key={option.value} handleSelect={handleSelect} icon={Icon} />
            ))}
            {isPresent(lessPriorityOptions) && isPresent(biggerPriorityOptions) && (
              <div data-cy="divider" className="divider mt-8 mb-8" />
            )}
            {lessPriorityOptions.map(option => (
              <Option {...option} key={option.value} handleSelect={handleSelect} icon={Icon} />
            ))}

            {!isPresent(lessPriorityOptions) && !isPresent(biggerPriorityOptions) && (
              <p className="text-grey mt-8 ml-16 mb-0 in-gray-600">
                <FormattedMessage id="shared.noResultsFound" />
              </p>
            )}
          </div>
        </div>
      </div>
    </Dropdown>
  )
}

CountrySelect.defaultProps = {
  value: 'US',
  disabled: false,
}

CountrySelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  iconComponent: PropTypes.elementType.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CountrySelect
