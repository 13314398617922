import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Spinner = ({ size, color, isAbsolute, dataCy }) => (
  <div
    data-cy="loading-spinner"
    className={classNames('main-spinner-wrap', { 'main-spinner-wrap--absolute': isAbsolute })}
  >
    <svg
      width={`${size}px`}
      height={`${size}px`}
      className="main-spinner"
      fill={color}
      viewBox="0 0 512 512"
      data-cy={dataCy}
    >
      <path d="M.001 256.002c0-15.709 12.736-28.445 28.445-28.445s28.444 12.736 28.444 28.445c0 53.552 21.2 103.675 58.318 140.792s87.242 58.318 140.793 58.318c109.966 0 199.111-89.145 199.111-199.111S365.967 56.889 256 56.889c-26.523 0-52.276 5.173-76.207 15.096-14.512 6.017-31.154-.868-37.171-15.38s.869-31.154 15.38-37.17C188.81 6.66 221.965.001 255.998.001c141.385 0 256 114.615 256 256s-114.615 256-256 256c-68.794 0-133.338-27.3-181.019-74.981S-.002 324.795-.002 256.001z" />
    </svg>
  </div>
)

Spinner.defaultProps = {
  size: 24,
  color: '#388bff',
  isAbsolute: false,
  dataCy: undefined,
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  isAbsolute: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default Spinner
