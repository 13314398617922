import normalize from 'json-api-normalizer'
import { toString } from 'lodash'
import { length, map, path } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { declineAllRecurringBookingsEndpoint } from 'state/concepts/booking/endpoints'
import { DECLINE_ALL_RECURRING_BOOKINGS } from 'state/concepts/booking/types'
import {
  decrementPendingBookingsCount,
  removeClientAccountBookings,
} from 'state/concepts/clientAccountBookings/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'

const declineAllRecurringBookingsOperation = createLogic({
  type: DECLINE_ALL_RECURRING_BOOKINGS,
  latest: true,

  async process({ httpClient, action: { bookingUniqCode } }, dispatch, done) {
    const { endpoint, url } = declineAllRecurringBookingsEndpoint(bookingUniqCode)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url)
      const response = normalize(data)

      const declinedBookingsIds = map(toString, path(['meta', 'declined_bookings_ids'], data))

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(removeClientAccountBookings(declinedBookingsIds))
      dispatch(decrementPendingBookingsCount(length(declinedBookingsIds)))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'bookings.clientDeclineAllRecurringAppointments.success' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default declineAllRecurringBookingsOperation
