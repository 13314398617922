import fetchEmployees from './fetchEmployees'
import inviteEmployees from './inviteEmployees'
import deactivateEmployee from './deactivateEmployee'
import activateEmployee from './activateEmployee'
import removeEmployee from './removeEmployee'
import removeEmployeeInvitation from './removeEmployeeInvitation'
import resendEmployeeInvitation from './resendEmployeeInvitation'
import changeEmployeeRole from './changeEmployeeRole'
import filterEmployees from './filterEmployees'
import updateProvision from './updateProvision'
import fetchProvision from './fetchProvision'
import fetchProfile from './fetchProfile'
import updateProfile from './updateProfile'
import resetTwoFactorAuth from './resetTwoFactorAuth'
import fetchEmployeesAvailabilities from './fetchEmployeesAvailabilities'

export default [
  fetchEmployees,
  inviteEmployees,
  deactivateEmployee,
  activateEmployee,
  removeEmployee,
  removeEmployeeInvitation,
  resendEmployeeInvitation,
  changeEmployeeRole,
  filterEmployees,
  updateProvision,
  fetchProvision,
  fetchProfile,
  updateProfile,
  resetTwoFactorAuth,
  fetchEmployeesAvailabilities,
]
