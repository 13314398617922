import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import Linkify from 'linkify-react'
import { useIntl } from 'react-intl'

const FormattedLinkifyMessage = ({ message, options }) => {
  const { formatMessage } = useIntl()
  let content

  if (is(String, message)) {
    content = message
  }

  if (is(Object, message) && is(String, message.id)) {
    content = formatMessage({ ...message }, message.values)
  }
  return <Linkify options={options}>{content}</Linkify>
}

FormattedLinkifyMessage.defaultProps = {
  message: undefined,
  options: {
    className: 'linkified',
    attributes: { target: '_blank' },
  },
}

FormattedLinkifyMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.shape(),
}

export default FormattedLinkifyMessage
