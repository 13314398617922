import mixpanel from 'mixpanel-browser'

import { MIXPANEL_PROJECT_TOKEN } from 'lib/constants'

const isEnabled = !!MIXPANEL_PROJECT_TOKEN

const mixpanelAdapter = {
  init: () => {
    if (isEnabled) {
      mixpanel.init(MIXPANEL_PROJECT_TOKEN)
    }
  },
  identify: id => {
    if (isEnabled) {
      mixpanel.identify(id)
    }
  },
  alias: id => {
    if (isEnabled) {
      mixpanel.alias(id)
    }
  },
  track: (name, props) => {
    if (isEnabled) {
      mixpanel.track(name, props)
    }
  },
  people: {
    set: props => {
      if (isEnabled) {
        mixpanel.people.set(props)
      }
    },
  },
  add_group: (groupKey, groupId) => {
    if (isEnabled) {
      mixpanel.add_group(groupKey, groupId)
    }
  },
  get_group: (groupKey, groupId) => {
    if (isEnabled) {
      return mixpanel.get_group(groupKey, groupId)
    }

    return { set: () => {} }
  },
}

export default mixpanelAdapter
