import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { websiteRoute } from 'lib/apiRoutes'
import updateDataHelper from 'utils/updateDataHelper'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_GENERAL_DETAILS } from 'state/concepts/website/types'

const updateGeneralDetailsOperation = createLogic({
  type: UPDATE_GENERAL_DETAILS,
  latest: true,

  async process({ action: { values, form, websiteId }, httpClient, getState }, dispatch, done) {
    try {
      const params = {
        widget_id: values.widgetId,
        status: values.status,
      }

      await httpClient.put(websiteRoute, params)

      const response = updateDataHelper(getState().data, 'website', websiteId, {
        attributes: {
          status: params.status,
        },
        relationships: {
          widget: {
            data: { id: params.widget_id },
          },
        },
      })

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.websiteSettingsWereUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateGeneralDetailsOperation
