import { combineReducers } from '@reduxjs/toolkit'

import { SET_BOOKING_SETTINGS_ID } from './types'

const bookingSettingsId = (state = null, action) => {
  switch (action.type) {
    case SET_BOOKING_SETTINGS_ID:
      return action.id
    default:
      return state
  }
}

const bookingSettingsReducer = combineReducers({
  bookingSettingsId,
})

export default bookingSettingsReducer
