import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import Router from 'next/router'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'
import { companyClientDocumentReviewRoute, companyClientDocumentRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import isPresent from 'utils/isPresent'
import { fetchClientFeed } from 'state/concepts/companyClientFeed/actions'
import { duplicateEtherpadDocumentEndpoint } from '../endpoints'
import { DUPLICATE_ETHERPAD_DOCUMENT } from '../types'
import { appendEtherpadId } from '../actions'

const duplicateEtherpadDocumentOperation = createLogic({
  type: DUPLICATE_ETHERPAD_DOCUMENT,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { documentId, clientId, feedId } = action
    const { url, endpoint } = duplicateEtherpadDocumentEndpoint(documentId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url)
      const newDocumentId = data.data.id
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))

      const getFullRoute = route => `/${workspaceCode}${route}`

      if (
        Router.router.state.asPath === getFullRoute(companyClientDocumentRoute(clientId, documentId)) ||
        Router.router.state.asPath === getFullRoute(companyClientDocumentReviewRoute(clientId, documentId))
      ) {
        redirect({ href: companyClientDocumentRoute(clientId, newDocumentId), workspace: workspaceCode, reload: true })
      } else {
        isPresent(feedId) ? dispatch(fetchClientFeed(clientId)) : dispatch(appendEtherpadId(newDocumentId))
      }
      dispatch(showNotification({ messageObject: { id: 'notifications.documentCopyWasCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default duplicateEtherpadDocumentOperation
