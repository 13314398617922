import PropTypes from 'prop-types'
import TruncateMarkup from 'react-truncate-markup'
import classNames from 'clsx'
import parseHtml from 'html-react-parser'

import isPresent from 'utils/isPresent'
import { splitMessage } from 'utils/chat'
import { contentToHtml } from 'utils/reactDraftEditor'
import ShowMoreButton from './ShowMoreButton'
import ShowLessButton from './ShowLessButton'

const ShowMoreTextComponent = ({
  text,
  lines,
  lineHeight,
  className,
  shouldTruncate,
  toggleTruncate,
  handleTruncate,
  isTruncated,
  showMoreComponent,
  showLessComponent,
  style,
  isRichtext,
}) => {
  if (!isPresent(text)) {
    return null
  }

  const ShowMoreComponent = showMoreComponent || ShowMoreButton
  const ShowLessComponent = showLessComponent || ShowLessButton

  return (
    <div data-cy="text">
      {shouldTruncate ? (
        <TruncateMarkup
          lines={lines}
          lineHeight={lineHeight}
          onTruncate={handleTruncate}
          ellipsis={<ShowMoreComponent style={style} toggleTruncate={toggleTruncate} />}
        >
          <div>
            {isRichtext
              ? parseHtml(contentToHtml(text))
              : splitMessage(text).map((paragraph, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={index} className={classNames(className, { 'truncated-text__ellipsis': isTruncated })}>
                    {paragraph}
                  </p>
                ))}
          </div>
        </TruncateMarkup>
      ) : (
        <div>
          {isRichtext
            ? parseHtml(contentToHtml(text))
            : splitMessage(text).map((paragraph, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p className={className} key={index}>
                  {paragraph}
                </p>
              ))}
          <ShowLessComponent style={style} toggleTruncate={toggleTruncate} />
        </div>
      )}
    </div>
  )
}

ShowMoreTextComponent.defaultProps = {
  text: '',
  className: '',
  lines: 4,
  lineHeight: 30,
  showMoreComponent: undefined,
  showLessComponent: undefined,
  style: {},
  isRichtext: false,
}

ShowMoreTextComponent.propTypes = {
  text: PropTypes.string,
  lines: PropTypes.number,
  lineHeight: PropTypes.number,
  className: PropTypes.string,
  shouldTruncate: PropTypes.bool.isRequired,
  toggleTruncate: PropTypes.func.isRequired,
  handleTruncate: PropTypes.func.isRequired,
  isTruncated: PropTypes.bool.isRequired,
  showMoreComponent: PropTypes.elementType,
  showLessComponent: PropTypes.elementType,
  style: PropTypes.shape(),
  isRichtext: PropTypes.bool,
}

export default ShowMoreTextComponent
