import { snakeCase } from 'lodash'
import { map, mergeAll, pipe, toPairs } from 'ramda'

const createNotificationParams = (notificationSettings, isSubscribe) =>
  pipe(
    toPairs,
    map(([key, values]) => ({
      [snakeCase(key)]: { ...values, web: isSubscribe, email: isSubscribe },
    })),
    mergeAll,
  )(notificationSettings)

export default createNotificationParams
