/* eslint-disable react/prop-types */
import { ConfigProvider } from 'antd'
import 'assets/styles/style.scss'
import cookies from 'next-cookies'
import Router from 'next/router'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-calendar/dist/Calendar.css'
import 'react-html5video/dist/styles.css'
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux'
import { useLifecycles } from 'react-use'
// https://github.com/reduxjs/redux-toolkit/issues/2008#issuecomment-1453328258
// eslint-disable-next-line
import 'symbol-observable'

import { GA_TRACKING_ID } from 'lib/constants'
import appTheme from 'lib/constants/appTheme'
import { gtmPageView } from 'lib/gtag'
import { mountInterceptors } from 'lib/httpClient'
import mixpanel from 'lib/mixpanel'
import WebSocketConnection from 'lib/webSocketConnection'
import { processLocales } from 'state/concepts/customNames/actions'
import { processSubdomain } from 'state/concepts/subdomain/services'
import reduxWrapper from 'state/store'
import awaitStoreMiddlewares from 'utils/awaitStoreMiddlewares'
import { interFont, interTightFont } from 'utils/fonts'
import isServer from 'utils/isServer'
import redirect from 'utils/redirect'
import BroadcastChannelConnection from 'views/BroadcastChannelConnection'
import ErrorHandler from 'views/Errors/ErrorHandler'
import Meta from 'views/layouts/Meta'
import ModalRoot from 'views/ModalRoot'
import NotificationsDock from 'views/NotificationsDock'
import CookiesCard from 'views/shared/CookiesCard'
import SidebarRoot from 'views/SidebarRoot'

mixpanel.init()

const Application = ({ Component, pageProps, cookiesCheckState, ...rest }) => {
  const { store } = reduxWrapper.useWrappedStore({ ...rest, pageProps, cookiesCheckState })

  useLifecycles(
    () => {
      // preparing axios instance on client (mount effect runs only on client)
      mountInterceptors({ store })

      // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js#L6
      if (GA_TRACKING_ID) {
        Router.events.on('routeChangeComplete', url => gtmPageView(url))
      }
    },
    () => {
      if (GA_TRACKING_ID) {
        Router.events.off('routeChangeComplete', url => gtmPageView(url))
      }
    },
  )

  const getLayout = Component.getLayout ?? (page => page)
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${interFont.style.fontFamily};
          --font-inter-tight: ${interTightFont.style.fontFamily};
        }
      `}</style>

      <Provider store={store}>
        <IntlProvider timeZone={timeZone}>
          <ConfigProvider theme={appTheme}>
            <Meta pageTitle={Component.pageTitle} />
            <ErrorHandler>{getLayout(<Component {...pageProps} />)}</ErrorHandler>
            <CookiesCard cookiesCheckState={cookiesCheckState} />
            <WebSocketConnection />
            <BroadcastChannelConnection />
            <ModalRoot />
            <SidebarRoot />
            <NotificationsDock />
          </ConfigProvider>
        </IntlProvider>
      </Provider>
    </>
  )
}

Application.getInitialProps = reduxWrapper.getInitialAppProps(store => async ({ ctx, Component }) => {
  const cookiesProps = { cookiesCheckState: cookies(ctx).cookiesCheckState || '' }
  const context = { ...ctx, store }

  // preparing axios instance on server
  if (isServer()) {
    mountInterceptors(context)

    const { redirectTo, query, workspace } = await processSubdomain(context)

    if (redirectTo) {
      await redirect({ href: redirectTo, response: ctx.res, query })
      return cookiesProps
    }

    store.dispatch(processLocales(workspace?.uniqCode))
  }

  // run getInitialProps for page component
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(context) : {}

  // wait for all logics to finish when doing SSR
  if (isServer()) {
    await awaitStoreMiddlewares(store)
  }

  return { pageProps, ...cookiesProps }
})

export default Application
