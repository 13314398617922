import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import { isLastRecurring, isRecurring } from 'utils/bookings'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import validationSchema from 'lib/yupLocalised/schemas/clientCancelBooking'
import { FREE_PRICE, RECURRING_BOOKING_OPTIONS } from 'lib/constants/bookings'
import { handleSubmit } from 'utils/form/handleSubmit'
import { cancelBooking } from 'state/concepts/bookings/actions'
import { cancellationRulesSelector } from 'state/concepts/bookings/selectors'
import CancelFormModalComponent from './component'

class CancelFormModal extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
  }

  get isSubmitDisabled() {
    return isSubmitDisabled(this.props, { allowPristine: true })
  }

  get isFree() {
    const {
      booking: { price },
    } = this.props

    return price === FREE_PRICE
  }

  get isRecurring() {
    const { booking } = this.props

    return isRecurring(booking) && !isLastRecurring(booking)
  }

  render() {
    return (
      <CancelFormModalComponent
        {...this.props}
        isSubmitDisabled={this.isSubmitDisabled}
        isFree={this.isFree}
        isRecurring={this.isRecurring}
      />
    )
  }
}

const mapStateToProps = state => ({
  cancellationRules: cancellationRulesSelector(state),
})

const mapDispatchToProps = {
  onSubmit: cancelBooking,
}

export { CancelFormModal as CancelFormModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    isInitialValid: true,
    mapPropsToValues: ({ booking: { id, uniqCode } }) => ({
      id,
      uniqCode,
      cancellationReason: '',
      recurringOption: RECURRING_BOOKING_OPTIONS.single,
    }),
    validationSchema,
    handleSubmit,
  }),
)(CancelFormModal)
