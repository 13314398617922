import { template } from 'lodash'

import { GUEST_REDIRECT_ROUTES } from 'lib/constants/guest'

const redirectRoute = ({ pathname, query: { action }, query }) => {
  const currentPath = action ? `${pathname}/${action}` : pathname
  const redirectToTemplate = GUEST_REDIRECT_ROUTES[currentPath] || '/404'
  return template(redirectToTemplate)(query)
}

export default redirectRoute
