// SOURCES:
// - https://github.com/vercel/next.js/tree/master/examples/with-google-analytics
// - https://dev.to/ornio/add-google-analytics-through-gtm-google-tag-manager-on-next-js-5e4f

export const GTAG_SIGN_UP_EVENT = 'sign_up'
export const GTAG_PAGEVIEW_EVENT = 'pageview'

export const dataLayerPush = event => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(event)
  }
}

export const gtmPageView = url => {
  const pageEvent = {
    event: GTAG_PAGEVIEW_EVENT,
    page: url,
  }

  dataLayerPush(pageEvent)

  return pageEvent
}
