import { createLogic } from 'redux-logic'

import { filtersByType, sortOrderByType } from 'utils/clientAccountBookings'
import { SWITCH_BOOKINGS } from 'state/concepts/clientAccountBookings/types'
import { setSortOrder, filterClientAccountBookings } from 'state/concepts/clientAccountBookings/actions'

const switchClientAccountBookingsOperation = createLogic({
  type: SWITCH_BOOKINGS,
  latest: true,

  async process({ action: { bookingsType } }, dispatch, done) {
    const filters = filtersByType(bookingsType)
    const sortOrder = sortOrderByType(bookingsType)

    dispatch(setSortOrder(sortOrder))
    dispatch(filterClientAccountBookings(filters))
    done()
  },
})

export default switchClientAccountBookingsOperation
