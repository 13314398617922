const namespace = 'trashedFiles'

export const FETCH_TRASHED_FILES = `${namespace}/FETCH_TRASHED_FILES`
export const FILTER_TRASHED_FILES = `${namespace}/FILTER_TRASHED_FILES`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const RESET_FILTER_PARAMS = `${namespace}/RESET_FILTER_PARAMS`
export const ADD_TRASHED_FILE_IDS = `${namespace}/ADD_TRASHED_FILE_IDS`
export const SET_TRASHED_FILE_IDS = `${namespace}/SET_TRASHED_FILE_IDS`
export const RESET_TRASHED_FILE_IDS = `${namespace}/RESET_TRASHED_FILE_IDS`
export const SET_BLANK_STATE = `${namespace}/SET_BLANK_STATE`
export const SET_SELECTED_IDS = `${namespace}/SET_SELECTED_IDS`
export const RESTORE_TRASHED_FILES = `${namespace}/RESTORE_TRASHED_FILES`
export const EMPTY_TRASH = `${namespace}/EMPTY_TRASH`
export const DELETE_TRASHED_FILES = `${namespace}/DELETE_TRASHED_FILES`
export const REMOVE_TRASHED_FILE_IDS = `${namespace}/REMOVE_TRASHED_FILE_IDS`
export const RECEIVE_RESTORED_FILES = `${namespace}/RECEIVE_RESTORED_FILES`
