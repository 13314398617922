import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_WIDGETS } from 'state/concepts/sidebarWidgets/types'
import { fetchWidgetsEndpoint } from 'state/concepts/sidebarWidgets/endpoints'
import { addWidgets, setIsBlankState } from 'state/concepts/sidebarWidgets/actions'
import { paginationSelector } from 'state/concepts/sidebarWidgets/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const fetchWidgetsOperation = createLogic({
  type: FETCH_WIDGETS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = fetchWidgetsEndpoint
    const state = getState()

    const params = {
      page: paginationSelector(state),
      filter: { statuses: ['active'] },
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addWidgets(pluck('id', data.data)))
      dispatch(setIsBlankState(isBlankState({ data: data.data })))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchWidgetsOperation
