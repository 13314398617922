import { createLogic } from 'redux-logic'
import { equals, filter, find, propEq } from 'ramda'

import { UPDATE_CALENDAR_CONNECTIONS } from 'state/concepts/calendarIntegrations/types'
import { isCalendarConnected, splitCalendarSync } from 'utils/calendarIntegrations'
import {
  updateGoogleCalendarConnection,
  updateOutlookCalendarConnection,
  updateAppleCalendarConnection,
  updateSyncCalendar,
} from 'state/concepts/calendarIntegrations/actions'
import {
  googleCalendarConnectionsSelector,
  outlookCalendarConnectionsSelector,
  calendarIntegrationSelector,
  appleCalendarConnectionsSelector,
} from 'state/concepts/calendarIntegrations/selectors'
import { showNotification } from 'state/notifications/actions'

const updateCalendarConnectionsOperation = createLogic({
  type: UPDATE_CALENDAR_CONNECTIONS,
  latest: true,

  async process(
    {
      action: {
        values: { googleCalendars, outlookCalendars, appleCalendars, calendarSync },
        form,
      },
      getState,
    },
    dispatch,
    done,
  ) {
    const state = getState()

    const calendarIntegration = calendarIntegrationSelector(state)
    const initialGoogleCalendars = googleCalendarConnectionsSelector(state)
    const initialOutlookCalendars = outlookCalendarConnectionsSelector(state)
    const initialAppleCalendars = appleCalendarConnectionsSelector(state)

    const [calendarSyncId, calendarSyncType] = splitCalendarSync(calendarSync)

    const googleCalendarsToUpdate = filter(
      ({ id, googleCalendarListItems }) =>
        !equals(googleCalendarListItems, find(propEq(id, 'id'), initialGoogleCalendars).googleCalendarListItems),
      googleCalendars,
    )

    const outlookCalendarsToUpdate = filter(
      ({ id, outlookCalendarListItems }) =>
        !equals(outlookCalendarListItems, find(propEq(id, 'id'), initialOutlookCalendars).outlookCalendarListItems),
      outlookCalendars,
    )

    const appleCalendarsToUpdate = filter(
      ({ id, appleCalendarListItems }) =>
        !equals(appleCalendarListItems, find(propEq(id, 'id'), initialAppleCalendars).appleCalendarListItems),
      appleCalendars,
    )

    googleCalendarsToUpdate.forEach(calendar => {
      dispatch(updateGoogleCalendarConnection({ calendar }))
    })

    outlookCalendarsToUpdate.forEach(calendar => {
      dispatch(updateOutlookCalendarConnection({ calendar }))
    })

    appleCalendarsToUpdate.forEach(calendar => {
      dispatch(updateAppleCalendarConnection({ calendar }))
    })

    if (
      (calendarSyncId === '' && calendarSyncType === '') ||
      (!isCalendarConnected(calendarIntegration, calendarSyncId, calendarSyncType) && calendarSyncId)
    ) {
      dispatch(updateSyncCalendar({ calendarSyncId, calendarSyncType, calendarIntegrationId: calendarIntegration.id }))
    }

    dispatch(showNotification({ messageObject: { id: 'calendarSync.changesSaved' } }))

    form.setSubmitting(false)
    done()
  },
})

export default updateCalendarConnectionsOperation
