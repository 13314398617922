import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'
import isEmpty from 'lodash/isEmpty'

import yup from 'lib/yupLocalised'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { showModal as showModalAction } from 'state/modal/actions'
import { CHAT_TYPES, MAX_CHAT_NAME_LENGTH } from 'lib/constants/chat'
import { chatUsersByIdsSelector } from 'state/concepts/chatUsers/selectors'
import { formatChatName } from 'utils/chat'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import ChatDetailsComponent from './component'

class ChatDetailsModal extends React.Component {
  static propTypes = {
    chat: PropTypes.shape(),
    chatType: PropTypes.string,
    values: PropTypes.shape().isRequired,
    showModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actionType: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    chatUserIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  }

  static defaultProps = {
    logoUrl: null,
    chat: undefined,
    chatType: CHAT_TYPES.room,
    chatUserIds: [],
  }

  get isSubmitDisabled() {
    const {
      chat,
      values: { logoUrl, name },
    } = this.props

    if (chat) {
      return chat.logoUrl === logoUrl && chat.name === name && isSubmitDisabled(this.props)
    }

    return isEmpty(name) && isSubmitDisabled(this.props)
  }

  handleSetChatLogo = file => {
    const { values } = this.props

    this.showChatDetails({ ...values, logo: file, logoUrl: URL.createObjectURL(file) })
  }

  handleRemoveChatLogo = () => {
    const { values } = this.props

    this.showChatDetails({ ...values, logo: null, logoUrl: null })
  }

  handleCloseUploadLogo = () => {
    const { values } = this.props

    this.showChatDetails(values)
  }

  showChatDetails = values => {
    const { showModal, onSubmit, actionType, chat, chatType, chatUserIds } = this.props
    const { id, name, logo, logoUrl } = values

    showModal({
      modalType: 'CHAT_DETAILS_MODAL',
      modalProps: {
        chat,
        chatType,
        id,
        logoUrl,
        logo,
        name,
        actionType,
        onSubmit,
        chatUserIds,
      },
    })
  }

  render = () => (
    <ChatDetailsComponent
      {...this.props}
      onCloseUploadLogo={this.handleCloseUploadLogo}
      logoUrl={this.props.logoUrl}
      setChatLogo={this.handleSetChatLogo}
      removeChatLogo={this.handleRemoveChatLogo}
      isSubmitDisabled={this.isSubmitDisabled}
    />
  )
}
const mapStateToProps = (state, { chatUserIds }) => ({
  chatUsers: chatUsersByIdsSelector(state, chatUserIds),
  currentUser: currentUserProfileSelector(state),
})

const mapDispatchToProps = {
  showModal: showModalAction,
}

export { ChatDetailsModal as ChatDetailsModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ id, name, chatUsers, logo, logoUrl, currentUser }) => ({
      id,
      name: name || formatChatName([currentUser, ...chatUsers]),
      logo,
      logoUrl,
    }),
    validationSchema: yup.object().shape({
      name: yup.string().required().max(MAX_CHAT_NAME_LENGTH),
    }),
    handleSubmit: handleSubmitWithProps(['chatUserIds', 'chatType']),
  }),
)(ChatDetailsModal)
