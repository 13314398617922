import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Question = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M512 1024c-282.77 0-512-229.233-512-512 0-282.77 229.23-512 512-512 282.767 0 512 229.23 512 512 0 282.767-229.233 512-512 512zM512 921.6c226.217 0 409.6-183.383 409.6-409.6s-183.383-409.6-409.6-409.6c-226.216 0-409.6 183.384-409.6 409.6s183.384 409.6 409.6 409.6zM460.8 665.6h102.4v102.4h-102.4v-102.4zM563.2 581.381v33.019h-102.4v-76.8c0-28.278 22.922-51.2 51.2-51.2 42.414 0 76.8-34.386 76.8-76.8 0-42.416-34.386-76.8-76.8-76.8-37.258 0-68.321 26.533-75.325 61.735l-100.428-20.086c16.336-82.139 88.816-144.049 175.754-144.049 98.97 0 179.2 80.23 179.2 179.2 0 81.178-53.98 149.75-128 171.781z" />
  </svg>
)

Question.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Question.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Question
