import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Play = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M465.544 281.207 75.355 508.095c-13.824 8.038-31.501 3.268-39.483-10.656A29.267 29.267 0 0 1 32 482.884V29.109C32 13.032 44.94-.001 60.903-.001a28.745 28.745 0 0 1 14.452 3.9l390.189 226.888c13.824 8.038 18.56 25.842 10.579 39.764a29.012 29.012 0 0 1-10.579 10.656z" />
  </svg>
)

Play.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Play.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Play
