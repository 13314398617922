import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPLOAD_CLIENT_OWN_AVATAR } from 'state/concepts/client/types'
import { clientsAccountAvatarRoute } from 'lib/apiRoutes'
import { uploadClientOwnAvatarEndpoint } from '../endpoints'

const uploadClientOwnAvatarOperation = createLogic({
  type: UPLOAD_CLIENT_OWN_AVATAR,
  latest: true,

  async process({ action: { data }, httpClient }, dispatch, done) {
    const { endpoint } = uploadClientOwnAvatarEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data: responseData } = await httpClient.put(clientsAccountAvatarRoute, data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      const response = normalize(responseData)

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.yourProfileImageUpdated' },
        }),
      )

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default uploadClientOwnAvatarOperation
