import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { EDIT_SERVICE_CATEGORY } from 'state/concepts/serviceCategories/types'
import { searchServiceCategories } from 'state/concepts/serviceCategories/actions'
import { hideModal } from 'state/modal/actions'
import { editServiceCategoryEndpoint } from '../endpoints'

const editSeviceCategoryOperation = createLogic({
  type: EDIT_SERVICE_CATEGORY,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url } = editServiceCategoryEndpoint(values.id)

    try {
      await httpClient.put(url, values)
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.serviceCategoriesUpdated' } }))
      dispatch(searchServiceCategories())
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default editSeviceCategoryOperation
