import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchWidget = widgetId => ({
  type: types.FETCH_WIDGET,
  widgetId,
})

export const createWidget = makeFormSubmitAction(types.CREATE_WIDGET)
export const updateWidget = makeFormSubmitAction(types.UPDATE_WIDGET)
export const sendInstructions = makeFormSubmitAction(types.SEND_INSTRUCTIONS)
export const updateWidgetServices = makeFormSubmitAction(types.UPDATE_WIDGET_SERVICES)
export const validateWidgetSettings = makeFormSubmitAction(types.VALIDATE_WIDGET_SETTINGS)

export const deleteWidget = widgetId => ({
  type: types.DELETE_WIDGET,
  widgetId,
})

export const toggleWidgetStatus = widgetId => ({
  type: types.TOGGLE_WIDGET_STATUS,
  widgetId,
})

export const setWidget = widgetId => ({
  type: types.SET_WIDGET,
  widgetId,
})

export const fetchWidgetServices = widgetId => ({
  type: types.FETCH_WIDGET_SERVICES,
  widgetId,
})

export const setWidgetServices = widgetServicesIds => ({
  type: types.SET_WIDGET_SERVICES,
  widgetServicesIds,
})

export const setBlankState = isBlankState => ({
  type: types.SET_WIDGET_SERVICES_BLANK_STATE,
  isBlankState,
})
