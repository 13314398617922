import { createLogic } from 'redux-logic'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { onUpdate } from 'utils/attributesForSortable'
import { UPDATE_CUSTOM_FIELD_PRIORITY } from 'state/concepts/customFields/types'
import { updateCustomFieldPriorityEndpoint } from 'state/concepts/customFields/endpoints'
import { customFieldsOrderByPrioritySelector } from '../selectors'

const updateCustomFieldPriorityOperation = createLogic({
  type: UPDATE_CUSTOM_FIELD_PRIORITY,
  latest: true,

  async process({ action: { id, priority, orderedFields }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = updateCustomFieldPriorityEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, { priority })

      const state = getState()
      const customFields = customFieldsOrderByPrioritySelector(state)
      const customFieldIds = pluck('id')(orderedFields)
      const response = updateDataHelper(
        state.data,
        'customField',
        customFieldIds,
        onUpdate(orderedFields, customFields),
      )

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default updateCustomFieldPriorityOperation
