import { FORM_TYPES } from 'lib/constants/forms'
import * as types from './types'

export const fetchForms = (formType = '', page = {}, filters = {}) => ({
  type: types.FETCH_FORMS,
  page,
  formType,
  filters,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const filterIntakeForms = (filters = {}) => ({
  type: types.FILTER_INTAKE_FORMS,
  filters,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})

export const resetAllFilters = () => ({
  type: types.RESET_ALL_FILTERS,
})

export const fetchIntakeForms = (...args) => fetchForms(FORM_TYPES.intake, ...args)

export const fetchAgreements = (...args) => fetchForms(FORM_TYPES.agreement, ...args)

export const setFormIds = ids => ({
  type: types.SET_FORM_IDS,
  ids,
})

export const resetForms = () => ({
  type: types.RESET_FORMS,
})

export const setBlankState = isBlankState => ({
  type: types.SET_FORMS_BLANK_STATE,
  isBlankState,
})
