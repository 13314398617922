import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { updateVideoCallProviderEndpoint } from '../endpoints'
import { UPDATE_VIDEO_CALL_PROVIDER } from '../types'

const updateVideoCallProvider = createLogic({
  type: UPDATE_VIDEO_CALL_PROVIDER,
  latest: true,

  async process({ httpClient, action: { values, form }, getState }, dispatch, done) {
    const state = getState()
    const { url, endpoint } = updateVideoCallProviderEndpoint
    const { id } = workspaceSelector(state)
    const { videoCallProvider } = values
    const params = {
      video_call_provider: videoCallProvider,
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.put(url, params)
      const response = updateDataHelper(getState().data, 'workspace', id, {
        attributes: {
          videoCallProvider,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.videoCallSettingsUpdated',
          },
        }),
      )
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint, form })
    }
    done()
  },
})

export default updateVideoCallProvider
