import fetchClient from './fetchClient'
import updateClient from './updateClient'
import deleteClient from './deleteClient'
import getClientOwnProfile from './getClientOwnProfile'
import deleteClientOwnAccount from './deleteClientOwnAccount'
import updateClientGeneralSettings from './updateClientGeneralSettings'
import sendDeleteConfirmationCode from './sendDeleteConfirmationCode'
import confirmClientOwnEmail from './confirmClientOwnEmail'
import updateClientOwnProfile from './updateClientOwnProfile'
import uploadClientOwnAvatarOperation from './uploadClientOwnAvatar'
import removeClientOwnAvatarOperation from './removeClientOwnAvatar'
import validateConfirmationToken from './validateConfirmationToken'
import resendEmailConfirmation from './resendEmailConfirmation'
import resendEmailChangeConfirmation from './resendEmailChangeConfirmation'
import fetchNotificationSettings from './fetchNotificationSettings'
import updateNotificationSettings from './updateNotificationSettings'
import updateClientProfileFields from './updateClientProfileFields'
import shareFiles from './shareFiles'
import sendDocuments from './sendDocuments'
import createClient from './createClient'
import restoreAccount from './restoreAccount'
import assignExperts from './assignExperts'
import sendInvitationToClientPortal from './sendInvitationToClientPortal'
import restoreContactAccount from './restoreContactAccount'

export default [
  fetchClient,
  updateClient,
  deleteClient,
  getClientOwnProfile,
  deleteClientOwnAccount,
  updateClientGeneralSettings,
  sendDeleteConfirmationCode,
  confirmClientOwnEmail,
  updateClientOwnProfile,
  uploadClientOwnAvatarOperation,
  removeClientOwnAvatarOperation,
  validateConfirmationToken,
  resendEmailConfirmation,
  resendEmailChangeConfirmation,
  fetchNotificationSettings,
  updateNotificationSettings,
  updateClientProfileFields,
  shareFiles,
  sendDocuments,
  createClient,
  restoreAccount,
  assignExperts,
  sendInvitationToClientPortal,
  restoreContactAccount,
]
