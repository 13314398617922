import { createLogic } from 'redux-logic'
import { last, pathOr } from 'ramda'

import { resetPromocodes } from 'state/concepts/promocodes/actions'
import { subscriptionsChangeStoragePlanRoute } from 'lib/apiRoutes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsSubscriptionRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { changeSubscriptionDetailsSelector } from 'state/concepts/subscriptionPlans/selectors'
import { promocodesSelector } from 'state/concepts/promocodes/selectors'
import { UPDATE_STORAGE_PLAN } from 'state/concepts/subscriptionPlans/types'
import { showNotification } from 'state/notifications/actions'
import { storageSize } from 'utils/billing'
import { formattedShortestDate } from 'utils/dateTime'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import isPresent from 'utils/isPresent'
import redirect from 'utils/redirect'

const updateStoragePlanOperation = createLogic({
  type: UPDATE_STORAGE_PLAN,
  latest: true,

  async process(
    {
      action: {
        values: { storagePlanId, autoUpgrade },
        form,
      },
      getState,
      httpClient,
    },
    dispatch,
    done,
  ) {
    const state = getState()
    const appliedPromocodeData = last(promocodesSelector(state))
    const params = {
      storage_plan_id: storagePlanId,
      code: appliedPromocodeData?.code,
      auto_upgrade: autoUpgrade,
    }
    const workspace = currentWorkspaceCodeSelector(state)
    const { storagePlans, currentStoragePlan, paymentDate } = changeSubscriptionDetailsSelector(state)
    const selectedStoragePlan = storagePlans.find(({ id }) => id === storagePlanId)
    const date = formattedShortestDate(paymentDate)

    try {
      await httpClient.post(subscriptionsChangeStoragePlanRoute, params)

      const getMessageId = () => {
        if (!selectedStoragePlan) {
          return autoUpgrade
            ? 'notifications.storageSubscriptionAutoUpgradeEnabled'
            : 'notifications.storageSubscriptionAutoUpgradeDisabled'
        }

        if (selectedStoragePlan.size === 0) {
          return 'notifications.storageSubscriptionCancelled'
        }

        return selectedStoragePlan.size > currentStoragePlan.size
          ? 'notifications.storageSubscriptionUpgraded'
          : 'notifications.storageSubscriptionDowngraded'
      }
      const messageObject = {
        id: getMessageId(),
        values: selectedStoragePlan && { date, size: storageSize(selectedStoragePlan.size) },
      }
      await redirect({ href: companySettingsSubscriptionRoute, workspace })
      dispatch(resetPromocodes())
      dispatch(showNotification({ messageObject }))
    } catch (error) {
      const errors = pathOr(null, ['response', 'data', 'errors'], error)
      const formattedErrors = formatJsonApiErrors(errors)
      form.setSubmitting(false)
      if (isPresent(formattedErrors?.code)) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.applyDiscount.isNotApplied',
            },
            kind: 'error',
          }),
        )
      }
      requestErrorHandler({
        error,
        dispatch,
        form,
      })
    }

    done()
  },
})

export default updateStoragePlanOperation
