import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { connect } from 'react-redux'
import { forEach, toPairs, compose, pick } from 'ramda'
import cookies from 'component-cookie'

import { isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'
import { COOKIES_PATH } from 'lib/constants/videoConference'
import Button from 'views/shared/Button'

class JoinButton extends React.Component {
  static propTypes = {
    videoConferenceCode: PropTypes.string.isRequired,
    className: PropTypes.string,
    stopEventPropagation: PropTypes.bool,
    isEmployeeLoggedIn: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: '',
    stopEventPropagation: false,
  }

  get conferencePath() {
    const { videoConferenceCode } = this.props

    return `/video_conference/${videoConferenceCode}`
  }

  handleClick = event => {
    const { stopEventPropagation, isEmployeeLoggedIn } = this.props

    if (stopEventPropagation) {
      event.stopPropagation()
    }

    isEmployeeLoggedIn
      ? cookies('currentClientId', null, { path: COOKIES_PATH })
      : cookies('currentEmployeeId', null, { path: COOKIES_PATH })

    const authCookies = pick(
      ['path', 'tokens', 'currentClientId', 'currentEmployeeId', 'currentWorkspaceId', 'currentWorkspaceCode'],
      cookies(),
    )

    forEach(([name, value]) => {
      if (name === 'path') {
        cookies('path', COOKIES_PATH, { path: COOKIES_PATH })
      } else {
        cookies(name, value, { path: COOKIES_PATH })
      }
    }, toPairs(authCookies))
  }

  render() {
    const { className } = this.props

    return (
      <Button
        iconSize={16}
        text={{ id: 'consultation.join' }}
        onClick={this.handleClick}
        href={this.conferencePath}
        target="_blank"
        isLink
        isRoot
        {...this.props}
        className={classNames('main-btn--md-fixed', className)}
      />
    )
  }
}

const mapStateToProps = state => ({
  isEmployeeLoggedIn: isEmployeeLoggedInSelector(state),
})

export { JoinButton as JoinButtonContainer }
export default compose(connect(mapStateToProps))(JoinButton)
