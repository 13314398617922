import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { updateClientPortalEndpoint } from 'state/concepts/companyClientPortal/endpoints'
import { UPDATE_CLIENT_PORTAL } from 'state/concepts/companyClientPortal/types'

const updateClientPortalOperation = createLogic({
  type: UPDATE_CLIENT_PORTAL,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url, endpoint } = updateClientPortalEndpoint
    const { status, widgetId } = values

    try {
      const { data } = await httpClient.put(url, {
        status,
        widget_id: widgetId || null,
        include: 'widget',
      })

      dispatch(dataApiSuccess({ response: normalize(data), endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.clientPortalWasUpdated',
          },
        }),
      )
      form.resetForm({ values })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateClientPortalOperation
