import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Message = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M270.908 963.799l-270.908 60.201 60.201-270.909c-38.419-71.844-60.201-153.923-60.201-241.091 0-282.77 229.23-512 512-512 282.767 0 512 229.23 512 512 0 282.767-229.233 512-512 512-87.168 0-169.247-21.78-241.092-60.201zM285.734 855.603l33.461 17.894c58.788 31.437 124.478 48.102 192.805 48.102 226.217 0 409.6-183.383 409.6-409.6s-183.383-409.6-409.6-409.6c-226.216 0-409.6 183.384-409.6 409.6 0 68.326 16.665 134.016 48.102 192.804l17.893 33.459-33.526 150.866 150.865-33.526z" />
  </svg>
)

Message.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Message.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Message
