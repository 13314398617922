import { createLogic } from 'redux-logic'

import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import { MAX_FILE_SIZE } from 'lib/constants/businessFiles'
import { showNotification } from 'state/notifications/actions'
import { showModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { getFileUploadInfo } from 'utils/file'
import { uploadFile } from '../actions'
import { presignUploadEndpoint } from '../endpoints'
import { PRESIGN_BUSINESS_FILE } from '../types'

const presignFilesOperation = createLogic({
  type: PRESIGN_BUSINESS_FILE,
  latest: false,

  async process({ httpClient, action: { files, parentId } }, dispatch, done) {
    try {
      const allowedBySize = files.filter(file => file.size <= MAX_FILE_SIZE)
      const hasLargeFiles = allowedBySize.length < files.length

      if (hasLargeFiles) {
        dispatch(showNotification({ messageObject: { id: 'myBusinessFiles.uploadSizeExceeded' }, kind: 'error' }))
      }

      const {
        data: { meta },
      } = await httpClient.post(presignUploadEndpoint.url, {
        files: allowedBySize.map(file => getFileUploadInfo(file)),
        parent_id: parentId,
      })

      const filesMeta = meta.files
      if (meta.has_duplicates) {
        dispatch(
          showModal({
            modalType: 'BUSINESS_FILES_UPLOAD_OPTIONS_MODAL',
            modalProps: {
              files,
              filesMeta,
              parentId,
            },
          }),
        )
      } else {
        filesMeta.forEach((fileMeta, index) => {
          dispatch(uploadFile(allowedBySize[index], fileMeta, parentId))
        })
      }
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(showNotification({ messageObject: { id: 'notifications.youDoNotHavePermissions' }, kind: 'error' }))
      }
      requestErrorHandler({ error, dispatch, excludeErrorArray: [403] })
    }
    done()
  },
})

export default presignFilesOperation
