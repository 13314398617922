import React from 'react'
import { compose } from 'ramda'
import { withFormik } from 'formik'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import yup from 'lib/yupLocalised'
import { handleSubmitWithProps } from 'utils/form/handleSubmit'
import { isFutureDate } from 'utils/dateTime'
import { markAsPaid } from 'state/concepts/payment/actions'
import TransactionPreviewModalComponent from './component'

class MarkAsPaidModal extends React.Component {
  setTileDisabled = ({ date }) => {
    const activeDate = DateTime.fromJSDate(date)

    return isFutureDate(activeDate.toISODate())
  }

  render() {
    return <TransactionPreviewModalComponent {...this.props} setTileDisabled={this.setTileDisabled} />
  }
}

const mapDispatchToProps = {
  onSubmit: markAsPaid,
}

export { MarkAsPaidModal as MarkAsPaidModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({ paymentMethod: undefined, paidAt: DateTime.local() }),
    handleSubmit: handleSubmitWithProps(['transaction']),
    validationSchema: yup.object().shape({
      paymentMethod: yup.string().required(),
      paidAt: yup.date().required(),
    }),
  }),
)(MarkAsPaidModal)
