import classNames from 'clsx'
import { getIn } from 'formik'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { has } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { handleInputEvent } from 'utils/inputHelpers'
import CircleChecked from 'views/shared/icons/CircleChecked'
import CircleO from 'views/shared/icons/CircleO'
import InputMessage from '../InputMessage'

const RadioField = ({
  className,
  wrapperClassName,
  radioFieldWrapClassNames,
  mainRadioTextOverlayClassName,
  disabled,
  options,
  field,
  onChange,
  isSegmentControl,
  showErrors,
  form: { touched, errors },
}) => {
  const fieldErrors = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)

  const labelClassNames = classNames(className, 'main-radio', {
    'main-radio--disabled': disabled,
  })

  const handleChange = e => {
    onChange?.(e.target.value, field.value)
    handleInputEvent(field, field.onChange)(e.target.value)
  }

  return (
    <div
      className={classNames(wrapperClassName, {
        'main-input--has-message': fieldTouched && fieldErrors,
        'main-input--has-message-error': fieldTouched && fieldErrors,
      })}
    >
      {options.map(
        ({
          label,
          labelTextClassNames,
          mainRadioDescriptionClassNames,
          value,
          hint,
          description,
          image,
          imageHeight,
          imageWidth,
          disabledElement,
          children,
          childrenProps,
        }) => (
          <div className={classNames('radio-field-wrap', radioFieldWrapClassNames)} key={value}>
            <label
              htmlFor={value}
              className={classNames(
                labelClassNames,
                { 'main-radio--checked': value === field.value && image },
                { 'main-radio--disabled-element': disabledElement && image },
              )}
            >
              <input
                className="main-radio__input"
                type="radio"
                id={value}
                value={value}
                checked={value === field.value}
                onChange={handleChange}
                disabled={disabled || disabledElement}
                name={field.name}
              />
              {!isSegmentControl && (
                <div className="main-radio__icon">
                  {value === field.value ? (
                    <CircleChecked size={18} className="fill-currentcolor" />
                  ) : (
                    <CircleO size={18} className="fill-currentcolor" />
                  )}
                </div>
              )}
              <span className={classNames(mainRadioTextOverlayClassName, { 'main-radio__content-wrapper': image })}>
                {image && (
                  <div className="main-radio__content-image-wrapper">
                    <Image src={image} width={imageWidth} height={imageHeight} alt="img" />
                  </div>
                )}
                {label && (
                  <span
                    data-cy="radio-label"
                    className={classNames('main-radio__text', labelTextClassNames, {
                      'main-radio__text--selected': isSegmentControl && value === field.value,
                    })}
                  >
                    {has('id', label) ? <FormattedMessage {...label} /> : label}
                  </span>
                )}
                {description && (
                  <span className={classNames('main-radio__description', mainRadioDescriptionClassNames)}>
                    {has('id', description) ? <FormattedMessage {...description} /> : description}
                  </span>
                )}
              </span>
              {children && (
                <div className="mt-16 ml-16" {...childrenProps}>
                  {children}
                </div>
              )}
            </label>
            {hint && (
              <p className="ml-32 mb-20">
                <FormattedMessage {...hint} />
              </p>
            )}
          </div>
        ),
      )}
      {showErrors && fieldTouched && fieldErrors && <InputMessage message={fieldErrors} icon="alert" />}
    </div>
  )
}

RadioField.defaultProps = {
  className: null,
  wrapperClassName: null,
  radioFieldWrapClassNames: null,
  mainRadioTextOverlayClassName: null,
  disabled: false,
  onChange: undefined,
  isSegmentControl: false,
  showErrors: false,
  childrenProps: {},
}

RadioField.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  radioFieldWrapClassNames: PropTypes.string,
  mainRadioTextOverlayClassName: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
      value: PropTypes.string,
      hint: PropTypes.shape(),
      description: PropTypes.shape(),
    }),
  ).isRequired,
  field: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  isSegmentControl: PropTypes.bool,
  form: PropTypes.shape().isRequired,
  showErrors: PropTypes.bool,
  childrenProps: PropTypes.shape(),
}

export default RadioField
