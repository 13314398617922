import { createSelector } from '@reduxjs/toolkit'
import { ascend, path, prop, sort } from 'ramda'

import { fetchCustomFieldsEndpoint } from 'state/concepts/customFields/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

const customFieldIdsSelector = path(['customFields', 'customFieldIds'])

export const customFieldsSelector = createSelector(
  [customFieldIdsSelector, dataSelector],
  buildCollection('customField'),
)

export const customFieldsOrderByPrioritySelector = createSelector(customFieldsSelector, sort(ascend(prop('priority'))))

export const customFieldsLoadingSelector = state => loadingSelector(state, fetchCustomFieldsEndpoint.endpoint)
