import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { validateRescheduleBookingEndpoint } from 'state/concepts/bookings/endpoints'
import { VALIDATE_RESCHEDULE_BOOKING } from 'state/concepts/guestBookings/types'
import redirect from 'utils/redirect'
import { guestLinkExpiredRoute } from 'lib/routes'

const validateRescheduleBookingOperation = createLogic({
  type: VALIDATE_RESCHEDULE_BOOKING,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { workspaceCode, uniqCode, res } = action
    const { url } = validateRescheduleBookingEndpoint(uniqCode)
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    try {
      await httpClient.get(url, config)
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }

    done()
  },
})

export default validateRescheduleBookingOperation
