import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

// import SwitchFieldComponent from './component';
import SwitchComponent from '../Switch'

// eslint-disable-next-line react/prefer-stateless-function
class SwitchField extends React.Component {
  static propTypes = {
    checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    uncheckedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func,
    }).isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    checkedValue: true,
    uncheckedValue: false,
    onChange: noop,
  }

  isChecked = value => value === this.props.checkedValue

  handleChange = () => {
    const {
      field,
      checkedValue,
      uncheckedValue,
      form: { setFieldValue },
      onChange,
    } = this.props
    const value = field.value === checkedValue ? uncheckedValue : checkedValue
    setFieldValue(field.name, value)
    onChange(value)
  }

  render() {
    const {
      field: { value },
    } = this.props

    return <SwitchComponent {...this.props} isChecked={this.isChecked(value)} onChange={this.handleChange} />
  }
}

export default SwitchField
