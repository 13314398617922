import classNames from 'clsx'
import PropTypes from 'prop-types'
import { pluck } from 'ramda'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { APPOINTMENT_INVITED_MEMBERS_LIMIT } from 'lib/constants/videoCalls'
import { showModal as showModalAction } from 'state/modal/actions'
import { isRescheduled, isScheduled } from 'utils/bookings'
import canAddParticipants from 'utils/bookings/canAddParticipants'
import Plus from 'views/shared/icons/Plus'
import Tooltip from 'views/shared/Tooltip'

const InviteMembersToAppointment = ({ booking }) => {
  const invitedMembersEmails = pluck('email', booking.invitedMembers)
  const invitedMembersCount = invitedMembersEmails.length
  const isBookingScheduled = isScheduled(booking) || isRescheduled(booking)
  const isInviteMembersDisabled = invitedMembersCount === APPOINTMENT_INVITED_MEMBERS_LIMIT
  const handleInviteMembers = useDispatchAction(showModalAction, {
    modalType: 'INVITE_MEMBERS_TO_APPOINTMENT_MODAL',
    modalProps: {
      invitedMembersEmails,
      bookingUniqCode: booking.uniqCode,
    },
  })
  const isAddParticipants = canAddParticipants(booking)

  return (
    isBookingScheduled &&
    isAddParticipants && (
      <Tooltip
        isShown={isInviteMembersDisabled}
        overlayClassName="main-tooltip"
        placement="top"
        icon={
          <button
            type="button"
            className={classNames(
              'main-btn--invite-members main-btn--text-blue font-400 ml-4 main-btn main-btn--text',
              { 'main-btn--invite-members-disabled': isInviteMembersDisabled },
            )}
            disabled={isInviteMembersDisabled}
            onClick={handleInviteMembers}
          >
            <div className="main-btn__icon-wrap">
              <Plus size={12} className="main-btn__icon" />
            </div>
            <span className="main-btn__txt">
              <FormattedMessage id="inviteMembers.title" />
            </span>
          </button>
        }
      >
        <p className="mb-0">
          <FormattedMessage id="inviteMembers.tooltip" values={{ maxItems: APPOINTMENT_INVITED_MEMBERS_LIMIT }} />
        </p>
      </Tooltip>
    )
  )
}

InviteMembersToAppointment.propTypes = {
  booking: PropTypes.shape({
    uniqCode: PropTypes.string.isRequired,
    invitedMembers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default InviteMembersToAppointment
