import { path } from 'ramda'
import { createLogic } from 'redux-logic'

import { SIGNATURE_TYPES } from 'lib/constants/eSignature'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SIGN_FORM_SUBMISSION } from 'state/concepts/form/types'
import {
  currentWorkspaceCodeSelector,
  isEmployeeLoggedInSelector,
  isGuestLoggedInSelector,
} from 'state/concepts/session/selectors'
import { formsSignSuccessRoute } from 'utils/forms'
import redirect from 'utils/redirect'
import { signFormSubmissionEndpoint } from '../endpoints'

const signFormSubmissionOperation = createLogic({
  type: SIGN_FORM_SUBMISSION,
  latest: true,

  async process({ action: { form, values }, httpClient, getState }, dispatch, done) {
    const {
      formSubmissionId,
      bookingId,
      name,
      signatureType,
      isEsignatureDisclosureShown,
      eSignatureAgree,
      signatureImage,
      signatureDraw,
    } = values
    const { url } = signFormSubmissionEndpoint(formSubmissionId)
    const state = getState()

    const workspaceCode = currentWorkspaceCodeSelector(state)
    const isEmployeeLoggedIn = isEmployeeLoggedInSelector(state)
    const isGuestLoggedIn = isGuestLoggedInSelector(state)

    const formData = new FormData()
    formData.append('name', name)
    formData.append('booking_id', bookingId)
    if (isEsignatureDisclosureShown) formData.append('signature_disclosure', eSignatureAgree)
    if (signatureType === SIGNATURE_TYPES.image) formData.append('file', signatureImage, signatureImage.name)
    if (signatureType === SIGNATURE_TYPES.draw) formData.append('file', signatureDraw, signatureDraw.name)

    try {
      const { data } = await httpClient.post(url, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      const nextFormId = path(['meta', 'next_form_id'], data)
      const nextFormStatus = path(['meta', 'next_form_status'], data)

      redirect({
        href: formsSignSuccessRoute({ isEmployeeLoggedIn, workspaceCode, isGuestLoggedIn }),
        workspace: isEmployeeLoggedIn ? workspaceCode : null,
        query: { bookingId: values.bookingId, nextFormId, nextFormStatus },
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default signFormSubmissionOperation
