import { createSelector } from '@reduxjs/toolkit'
import { map, path } from 'ramda'
import build from 'redux-object'

import { dataSelector, loadingSelector, totalCountSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchClientRelationshipTypeEndpoint, fetchClientRelationshipTypesEndpoint } from './endpoints'

export const clientRelationshipTypeIdsSelector = path(['clientRelationshipTypes', 'ids'])

export const clientRelationshipTypeIdSelector = path(['clientRelationshipTypes', 'id'])

export const fetchClientRelationshipTypeLoadingSelector = (state, id) =>
  loadingSelector(state, fetchClientRelationshipTypeEndpoint(id))

export const clientRelationshipTypeSelector = createSelector(
  [clientRelationshipTypeIdSelector, dataSelector],
  (id, data) => (id ? build(data, 'clientRelationshipType', id) : {}),
)

export const clientRelationshipTypeByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'clientRelationshipType', id) : null,
)

export const clientRelationshipTypesLoadingSelector = state =>
  loadingSelector(state, fetchClientRelationshipTypesEndpoint.endpoint)

export const clientRelationshipTypesSelector = createSelector(
  [clientRelationshipTypeIdsSelector, dataSelector],
  buildCollection('clientRelationshipType'),
)

export const clientRelationshipTypesTotalCountSelector = state =>
  totalCountSelector(state, fetchClientRelationshipTypesEndpoint.endpoint)

export const filtersSelector = path(['clientRelationshipTypes', 'filters'])

export const clientRelationshipTypesForFilterSelector = createSelector(
  makeSelectSearchResults('clientRelationshipTypes', 'clientRelationshipType'),
  map(({ id, name }) => ({ key: id, value: id, label: name })),
)
