import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyWailistRoute } from 'lib/routes'
import { currentUserTimezoneOffsetSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { buildCreateParams } from 'utils/waitlistRequests/buildWaitlistParams'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import { createWaitlistRequestEndpoint } from '../endpoints'
import { CREATE_WAITLIST_REQUEST } from '../types'

const createWaitlistRequestOperation = createLogic({
  type: CREATE_WAITLIST_REQUEST,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const state = getState()
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const timezoneOffset = currentUserTimezoneOffsetSelector(state)
    const { url } = createWaitlistRequestEndpoint

    try {
      await httpClient.post(url, buildCreateParams(values, timezoneOffset))

      redirect({ href: companyWailistRoute, workspace: workspaceCode })
      dispatch(showNotification({ messageObject: { id: 'notifications.waitlistRequestCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)

      if (isErrorStatusUnprocessableEntity(error)) {
        const { availabilityPreferencesBase } = formatJsonApiErrors(error.response.data.errors)

        if (availabilityPreferencesBase) {
          dispatch(showNotification({ messageObject: availabilityPreferencesBase, kind: 'error' }))
        }
      }
    }
    done()
  },
})

export default createWaitlistRequestOperation
