import { ENDS_FIELD_KEY_AND_VALUE } from 'lib/constants/bookings'

const recurringBookingFormValuesToParams = ({ weeklyInterval, ends, repeat, byWeekday, repeatCount, untilDate }) =>
  repeat && {
    weekly_interval: weeklyInterval,
    by_weekday: byWeekday,
    ends,
    repeat_count: ends === ENDS_FIELD_KEY_AND_VALUE.after ? repeatCount : undefined,
    until_date: ends === ENDS_FIELD_KEY_AND_VALUE.until ? untilDate.toISO() : undefined,
  }

export default recurringBookingFormValuesToParams
