import { without } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_LOCATIONS,
  SET_BLANK_STATE,
  SET_TOTAL_COUNT,
  SET_CURRENT_PAGE,
  RESET_LOCATIONS,
  SET_FILTER_PARAMS,
  REMOVE_LOCATION,
} from './types'

const locationIds = (state = [], action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return action.locationIds
    case REMOVE_LOCATION:
      return without([action.id], state)
    case RESET_LOCATIONS:
      return []
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const totalCount = (state = 0, action) => {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return action.count
    default:
      return state
  }
}

const pagination = (state = { number: 1, size: 20 }, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, number: action.pageNumber }
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const locationsReducer = combineReducers({
  locationIds,
  isBlankState,
  totalCount,
  pagination,
  filters,
})

export default locationsReducer
