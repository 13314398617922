import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CalendarO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M358.4 0v102.4h307.2v-102.4h102.4v102.4h204.8c28.278 0 51.2 22.923 51.2 51.2v819.2c0 28.278-22.922 51.2-51.2 51.2h-921.6c-28.277 0-51.2-22.922-51.2-51.2v-819.2c0-28.277 22.923-51.2 51.2-51.2h204.8v-102.4h102.4zM921.6 512h-819.2v409.6h819.2v-409.6zM460.8 614.4v204.8h-256v-204.8h256zM256 204.8h-153.6v204.8h819.2v-204.8h-153.6v102.4h-102.4v-102.4h-307.2v102.4h-102.4v-102.4z" />
  </svg>
)

CalendarO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'calendar-icon',
}

CalendarO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CalendarO
