import * as types from './types'

export const fetchWidgets = () => ({
  type: types.FETCH_WIDGETS,
})

export const setWidgets = widgetIds => ({
  type: types.SET_WIDGETS,
  widgetIds,
})

export const setCurrentPage = pageNumber => ({
  type: types.SET_WIDGETS_PAGE,
  pageNumber,
})

export const setSortOrder = sortKey => ({
  type: types.SET_WIDGETS_SORT_ORDER,
  sortKey,
})

export const setBlankState = isBlankState => ({
  type: types.SET_WIDGETS_BLANK_STATE,
  isBlankState,
})
