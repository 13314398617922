import { createLogic } from 'redux-logic'
import { pluck, splitAt, indexOf, last } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { onDelete } from 'utils/attributesForSortable'
import { showNotification } from 'state/notifications/actions'
import { removeCustomFields } from 'state/concepts/customFields/actions'
import { DELETE_CUSTOM_FIELD } from 'state/concepts/customFields/types'
import { customFieldsOrderByPrioritySelector } from 'state/concepts/customFields/selectors'
import { deleteCustomFieldEndpoint } from '../endpoints'

const deleteCustomFieldOperation = createLogic({
  type: DELETE_CUSTOM_FIELD,
  latest: true,

  async process({ action, httpClient, getState }, dispatch, done) {
    const { id } = action
    const { url } = deleteCustomFieldEndpoint(id)

    try {
      await httpClient.delete(url)

      const state = getState()
      const customFields = customFieldsOrderByPrioritySelector(state)
      const customFieldIds = pluck('id', customFields)
      const indexOfDeletedId = indexOf(id, customFieldIds)
      const idsForUpdate = last(splitAt(indexOfDeletedId + 1, customFieldIds))
      const response = updateDataHelper(state.data, 'customField', idsForUpdate, onDelete(customFields))

      dispatch(removeCustomFields([id]))
      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.customFieldWasDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteCustomFieldOperation
