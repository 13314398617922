import { createLogic } from 'redux-logic'

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { RESEND_EMAIL_CHANGE_CONFIRMATION } from 'state/concepts/client/types'
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions'
import { resendEmailChangeConfirmationEndpoint } from 'state/concepts/client/endpoints'

const resendEmailChangeConfirmationOperation = createLogic({
  type: RESEND_EMAIL_CHANGE_CONFIRMATION,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = resendEmailChangeConfirmationEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.emailConfirmationResented' } }))
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors
        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default resendEmailChangeConfirmationOperation
