import { createLogic } from 'redux-logic'

import redirect from 'utils/redirect'
import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_SERVICE_ASSIGNED_FORMS } from 'state/concepts/service/types'
import { formsUuidsByIdsSelector } from 'state/concepts/services/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { servicesAssignedFormsRoute } from 'lib/apiRoutes'

const updateServiceAssignedFormsOperation = createLogic({
  type: UPDATE_SERVICE_ASSIGNED_FORMS,
  latest: true,

  async process(
    {
      action: {
        values: { selectedIntakeFormIds, selectedAgreementIds },
        form,
        serviceId,
        redirectPath,
      },
      httpClient,
      getState,
    },
    dispatch,
    done,
  ) {
    const url = servicesAssignedFormsRoute(serviceId)
    const selectedFormIds = [...selectedIntakeFormIds, ...selectedAgreementIds]

    try {
      const params = {
        form_uuids: formsUuidsByIdsSelector(getState(), selectedFormIds),
      }

      await httpClient.put(url, params)

      const updateServiceAssignedForms = updateDataHelper(getState().data, 'service', serviceId, {
        relationships: {
          forms: {
            data: selectedFormIds.map(id => ({ id, type: 'form' })),
          },
        },
      })

      dispatch(dataApiSuccess({ response: updateServiceAssignedForms }))
      dispatch(showNotification({ messageObject: { id: 'notifications.serviceUpdated' } }))
      await form.resetForm()
      if (redirectPath) {
        redirect({ href: redirectPath, workspace: currentWorkspaceCodeSelector(getState()) })
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateServiceAssignedFormsOperation
