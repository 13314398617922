import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { addSearchResults, resetSearchResults } from 'state/searchResults/actions'
import { GET_EMPLOYEES } from 'state/concepts/filters/types'
import { getEmployeesEndpoint } from 'state/concepts/filters/endpoints'

const getEmployeesOperation = createLogic({
  type: GET_EMPLOYEES,
  latest: true,

  async process({ httpClient, action: { searchQuery, page, resetResults, statuses, roles, filters } }, dispatch, done) {
    const { endpoint, url } = getEmployeesEndpoint
    const params = {
      page: {
        number: page || 1,
        size: 20,
      },
      filter: {
        statuses,
        roles,
        name: searchQuery,
        ...filters,
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    if (resetResults) {
      dispatch(resetSearchResults('employees'))
    }

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })
      const ids = data.data.map(item => item.id)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ employees: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getEmployeesOperation
