import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { validateRescheduleBooking as validateRescheduleBookingAction } from 'state/concepts/bookings/actions'
import { rescheduleErrorSelector, rescheduleValidationLoadingSelector } from 'state/concepts/bookings/selectors'
import RescheduleModalComponent from './component'

class RescheduleModal extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
    validateRescheduleBooking: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { booking, validateRescheduleBooking } = this.props

    validateRescheduleBooking(booking.uniqCode)
  }

  render() {
    return <RescheduleModalComponent {...this.props} />
  }
}

const mapStateToProps = (state, { booking }) => ({
  rescheduleError: rescheduleErrorSelector(state),
  isLoading: rescheduleValidationLoadingSelector(state, booking.uniqCode),
})

const mapDispatchToProps = {
  validateRescheduleBooking: validateRescheduleBookingAction,
}

export { RescheduleModal as RescheduleModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(RescheduleModal)
