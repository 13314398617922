import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import updateDataHelper from 'utils/updateDataHelper'
import { ACTIVATE_EMPLOYEE } from 'state/concepts/employees/types'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { activateEmployeeEndpoint } from 'state/concepts/employees/endpoints'

const activateEmployeeOperation = createLogic({
  type: ACTIVATE_EMPLOYEE,
  latest: true,

  async process({ action: { employeeId }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = activateEmployeeEndpoint(employeeId)

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)
      const state = getState()

      const response = updateDataHelper(state.data, 'userProfile', employeeId, { attributes: { active: true } })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.memberActivated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default activateEmployeeOperation
