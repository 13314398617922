import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { showModal as showModalAction } from 'state/modal/actions'
import AttachedFileActionsComponent from './component'

class AttachedFileActions extends React.Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    fileId: PropTypes.string.isRequired,
    attachmentType: PropTypes.string.isRequired,
  }

  handleRemoveFile = () => {
    const { showModal, fileId, attachmentType } = this.props

    showModal({
      modalType: 'REMOVE_FILE_MODAL',
      modalProps: { fileId, attachmentType },
    })
  }

  render() {
    return <AttachedFileActionsComponent {...this.props} onRemoveFile={this.handleRemoveFile} />
  }
}

const mapDispatchToProps = {
  showModal: showModalAction,
}

export { AttachedFileActions as AttachedFileActionsContainer }
export default connect(null, mapDispatchToProps)(AttachedFileActions)
