import classNames from 'clsx'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { is, omit } from 'ramda'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import CircleCancel from '../icons/CircleCancel'
import Link from '../icons/Link'
import Search from '../icons/Search'

const ICON_COMPONENT = {
  search: Search,
  link: Link,
}

const Input = React.forwardRef(
  (
    {
      id,
      label,
      disabled,
      placeholder,
      intl,
      className,
      type,
      kind,
      size,
      isFocused,
      handleFocus,
      handleBlur,
      dataCy,
      icon,
      allowClear,
      showIcon,
      iconProps,
      cancelIconProps,
      onClear,
      isCopy,
      clear,
      value,
      ...props
    },
    ref,
  ) => {
    const IconComponent = ICON_COMPONENT[icon]

    const onClearClick = () => {
      if (onClear) {
        onClear()
        return
      }

      clear()
      props.onChange({ target: { value: '', id } })
    }

    return (
      <div
        className={classNames(className, 'main-input', {
          'main-input--disabled': disabled,
          'main-input--search': kind === 'search',
          'main-input--md': size === 'medium',
          'main-input--sm': size === 'small',
          'main-input--focus': isFocused,
          'main-input--copy': isCopy,
        })}
      >
        {label && (
          <label className="main-input__label" htmlFor={id}>
            <FormattedMessage {...label} />
          </label>
        )}
        <div className="relative main-input__field-wrap">
          <input
            id={id}
            className="main-input__field"
            data-cy={dataCy}
            type={type}
            placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={ref}
            {...omit(['forwardedRef'], props)}
          />
          {showIcon && <IconComponent size={14} className="main-page__search-icon" {...iconProps} />}
          {allowClear && !!value?.length && (
            <span className="main-page__search-button" role="button" onClick={onClearClick}>
              <CircleCancel
                className="main-page__search-icon main-page__search-icon--clear"
                dataCy="search-clear-button"
                size={12}
                {...cancelIconProps}
              />
            </span>
          )}
        </div>
      </div>
    )
  },
)

Input.displayName = 'Input'

Input.defaultProps = {
  id: undefined,
  label: null,
  placeholder: null,
  className: null,
  disabled: false,
  type: 'text',
  kind: null,
  size: null,
  dataCy: 'input-field',
  icon: 'search',
  allowClear: false,
  showIcon: false,
  onClear: undefined,
  iconProps: {},
  cancelIconProps: {},
  isCopy: false,
  onChange: noop,
  value: '',
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.shape(),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  intl: PropTypes.shape().isRequired,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['search', null]),
  size: PropTypes.oneOf(['medium', 'small', null]),
  dataCy: PropTypes.string,
  isFocused: PropTypes.bool.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  icon: PropTypes.node,
  allowClear: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClear: PropTypes.func,
  iconProps: PropTypes.shape(),
  cancelIconProps: PropTypes.shape(),
  isCopy: PropTypes.bool,
  clear: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default Input
