import { Select } from 'antd'
import classNames from 'clsx'
import debounce from 'lodash/debounce'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { isEmpty, omit } from 'ramda'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import emailPlaceholder from 'assets/images/email-placeholder-fill.svg'
import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import { currentEmployeePermissionsSelector } from 'state/concepts/employee/selectors'
import { getClients as getClientsAction } from 'state/concepts/filters/actions'
import { getClientsEndpoint } from 'state/concepts/filters/endpoints'
import { searchedClientsSelector, selectedClientsSelector } from 'state/concepts/filters/selectors'
import { loadingSelector } from 'state/data/selectors'
import { isBlankStateSearchResultsSelector } from 'state/searchResults/selectors'
import compact from 'utils/compact'
import profileIdentity from 'utils/employees/profileIdentity'
import isEmail from 'utils/isEmail'
import SelectField from 'views/shared/SelectField'
import Plus from 'views/shared/icons/Plus'
import Loader from './Loader'
import NotFoundContent from './NotFoundContent'
import OptionContent from './OptionContent'
import OptionLabel from './OptionLabel'

class DropdownClientField extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    withAvatar: PropTypes.bool,
    isBlankState: PropTypes.bool,
    isLoading: PropTypes.bool,
    clients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedClients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getClients: PropTypes.func.isRequired,
    field: PropTypes.shape().isRequired,
    permissions: PropTypes.shape().isRequired,
    withoutInvitation: PropTypes.bool,
    actionArgs: PropTypes.shape(),
  }

  static defaultProps = {
    withAvatar: false,
    isLoading: undefined,
    isBlankState: undefined,
    mode: 'default',
    withoutInvitation: false,
    actionArgs: {},
  }

  state = {
    isOpen: false,
    searchQuery: '',
    isInvitationEmailValid: false,
  }

  get allowedToInviteClient() {
    const { permissions } = this.props

    return permissions.createClient
  }

  componentDidUpdate(prevProps) {
    const {
      field: { value },
    } = this.props

    if (isEmail(value) && typeof prevProps.field.value === 'undefined') {
      this.setState({ searchQuery: value, isInvitationEmailValid: true })
    }
  }

  debouncedSearchClients = debounce(() => {
    const { getClients, actionArgs } = this.props
    const { searchQuery } = this.state

    getClients({ ...actionArgs, searchQuery, resetResults: true, active: true })
  }, 250)

  handleSearch = searchQuery => {
    this.setState(
      {
        isInvitationEmailValid: isEmail(searchQuery),
        isOpen: true,
        searchQuery,
      },
      this.debouncedSearchClients,
    )
  }

  onFocus = () => {
    const { getClients, actionArgs } = this.props

    getClients({ ...actionArgs, resetResults: true, active: true })
  }

  handleDropdownVisibleChange = isOpen => this.setState({ isOpen })

  renderClientOptions = () => {
    const { mode, withAvatar, clients, selectedClients } = this.props
    const { isOpen } = this.state
    const clientsOptions = isOpen ? clients : compact(selectedClients)

    if (isEmpty(clientsOptions)) return null

    return clientsOptions.map(client => (
      <Select.Option
        label={<OptionLabel mode={mode} client={client} withAvatar={withAvatar} />}
        title={profileIdentity(client)}
        value={client.id}
        key={client.id}
      >
        <OptionContent client={client} />
      </Select.Option>
    ))
  }

  renderInvitationOption = () => {
    const { searchQuery, isInvitationEmailValid } = this.state
    const { withoutInvitation } = this.props

    if (!this.allowedToInviteClient || withoutInvitation || !isInvitationEmailValid) return null

    return (
      <Select.Option
        label={
          <div className="d-flex align-items-center">
            <span className="main-userpic main-userpic--xs mr-8">
              <Image src={emailPlaceholder} alt="" layout="fill" objectFit="cover" />
            </span>
            <span className="truncate-text">{searchQuery}</span>
          </div>
        }
        key="inviteClient"
        value={searchQuery}
      >
        <div className="invite-option d-flex align-items-center" data-cy="invite-new-client-with-email">
          <Plus className="mr-12 in-blue-600" size={14} />
          <span className="text-body in-blue-600">
            <FormattedMessage id="scheduleAppointment.inviteNewClientWithEmail" />
            <br />
            <strong> {searchQuery}</strong>
          </span>
        </div>
      </Select.Option>
    )
  }

  render() {
    const { isLoading, isBlankState, clients, withoutInvitation } = this.props
    const { isOpen, searchQuery } = this.state

    return (
      <SelectField
        placeholder={{
          id: `scheduleAppointment.${
            this.allowedToInviteClient && !withoutInvitation ? 'selectOrInviteClient' : 'selectClient'
          }`,
        }}
        {...omit(['withoutInvitation'], this.props)}
        onSearch={this.handleSearch}
        onFocus={this.onFocus}
        optionLabelProp="label"
        showSearch
        allowClear
        filterOption={false}
        notFoundContent={
          <NotFoundContent
            isLoading={isLoading}
            isBlankState={isBlankState}
            allowedToInviteClient={this.allowedToInviteClient}
            withoutInvitation={withoutInvitation}
          />
        }
        onDropdownVisibleChange={this.handleDropdownVisibleChange}
        open={isOpen}
      >
        {this.renderClientOptions() || (isLoading === false && this.renderInvitationOption())}
        {clients.length && (
          <Select.Option
            disabled
            className={classNames({ 'visibility-hidden min-h-a p-0': isLoading === false })}
            key="waypoint"
          >
            <Loader searchQuery={searchQuery} isLoading={isLoading} />
          </Select.Option>
        )}
      </SelectField>
    )
  }
}

const mapStateToProps = (state, { field }) => {
  const selectedValue = field.value
  const clientIds = isEmail(selectedValue) ? [] : selectedValue

  return {
    isLoading: loadingSelector(state, getClientsEndpoint.endpoint),
    clients: searchedClientsSelector(state),
    selectedClients: selectedClientsSelector(state, clientIds),
    isBlankState: isBlankStateSearchResultsSelector(state, SEARCH_RESULTS_ENTITIES.clients),
    permissions: currentEmployeePermissionsSelector(state),
  }
}

const mapDispatchToProps = {
  getClients: getClientsAction,
}

export { DropdownClientField as DropdownClientFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DropdownClientField)
