import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { chatMembersSelector, chatMembersLoadingSelector } from 'state/concepts/chat/selectors'
import { getChatMembers as getChatMembersAction } from 'state/concepts/chat/actions'
import MembersListComponent from './component'

class MembersList extends React.Component {
  static propTypes = {
    chat: PropTypes.shape().isRequired,
    getChatMembers: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { chat, getChatMembers } = this.props

    getChatMembers(chat.id)
  }

  render = () => <MembersListComponent {...this.props} />
}

const mapDispatchToProps = {
  getChatMembers: getChatMembersAction,
}

const mapStateToProps = (state, { chat: { id } }) => ({
  chatMembers: chatMembersSelector(state),
  isLoading: chatMembersLoadingSelector(state, id),
})

export { MembersList as MembersListContainer }
export default connect(mapStateToProps, mapDispatchToProps)(MembersList)
