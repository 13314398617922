const customNaming = {
  'customNaming.subtitle': '<span>in</span> White-label',
  'customNaming.description':
    'Customize key terminology across your workspace to speak the same language as your _(clients).',
  'customNaming.manageFeature': 'Set custom names',
  'customNaming.overview': 'Custom naming overview',
  'customNaming.speakTheSameLanguage':
    'Make sure you speak the same language as your _(clients) by using familiar words. With the ExpertBox naming management feature, you can:',
  'customNaming.createEnvironment':
    'Create a more familiar environment for your _(clients) and team members by using terms that resonate with them.',
  'customNaming.ensureCommunication':
    'Ensure clear and consistent communication by using the same terminology throughout your workspace.',
  'customNaming.userExperience':
    'Improve the overall user experience by creating a more intuitive and user-friendly workspace.',
  'customNaming.customizeTerms':
    // eslint-disable-next-line expbox/custom-name-validation
    'You can customize some of the main terms in your workspace, such as "client", "expert", "service", "appointment", "form", and "service agreement." For example, if you run an online school, you can call your team members "Teachers" and your clients "Students".',
  'customNaming.namesAppearsEveryWhere':
    'These names will appear everywhere across your workspace, including in emails and on booking pages, making your communications truly personalized.',
  'customNaming.featureSetup': 'Feature set-up and configuration',
  'customNaming.helpCenter': 'To learn more, read our help center articles on <link>naming management</link>.',
}

export default customNaming
