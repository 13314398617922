import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { cancelPlanDowngradeEndpoint } from 'state/concepts/subscriptionPlans/endpoints'
import { CANCEL_PLAN_DOWNGRADE } from 'state/concepts/subscriptionPlans/types'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'

const cancelPlanDowngradeOperation = createLogic({
  type: CANCEL_PLAN_DOWNGRADE,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = cancelPlanDowngradeEndpoint

    try {
      const { data } = await httpClient.post(url, { include: 'subscription-plan,planned-subscription-plan' })
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.yourDowngradeIsCancelled',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default cancelPlanDowngradeOperation
