import { createSelector } from '@reduxjs/toolkit'
import { any, compose, isEmpty, not, omit, path, values } from 'ramda'
import build from 'redux-object'

import {
  currentPageSelector,
  dataSelector,
  endpointMetaSelector,
  loadingSelector,
  nextPageSelector,
  pageCountSelector,
} from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { fetchFilesSharedWithMeEndpoint, fetchMyFolderToMoveEndpoint } from './endpoints'

export const fileSharedWithMeIdsSelector = path(['filesSharedWithMe', 'ids'])
export const currentFolderIdSelector = path(['filesSharedWithMe', 'currentFolderId'])
export const selectedIdsSelector = path(['filesSharedWithMe', 'selectedIds'])
export const filtersSelector = path(['filesSharedWithMe', 'filters'])
export const isBlankStateSelector = path(['filesSharedWithMe', 'isBlankState'])

export const filesSharedWithMeSelector = createSelector(
  [fileSharedWithMeIdsSelector, dataSelector],
  buildCollection('businessStorage'),
)

export const fileSharedWithMeByIdSelector = createSelector([(_, id) => id, dataSelector], (id, data) =>
  id ? build(data, 'businessStorage', id) : {},
)

export const currentFolderSelector = createSelector([currentFolderIdSelector, dataSelector], (id, data) =>
  id ? build(data, 'businessStorage', id) : {},
)

export const fetchFilesSharedWithMeLoadingSelector = state =>
  loadingSelector(state, fetchFilesSharedWithMeEndpoint.endpoint)

export const filesSharedWithMetaSelector = state => endpointMetaSelector(state, fetchFilesSharedWithMeEndpoint.endpoint)

export const myFolderToMovePathSelector = createSelector(
  [(state, fileId) => endpointMetaSelector(state, fetchMyFolderToMoveEndpoint(fileId).endpoint), dataSelector],
  (meta, data) => meta?.path?.map(([id, name]) => [build(data, 'businessStorage', id), name]) || [],
)

export const filesSharedWithMeCurrentPageSelector = state =>
  currentPageSelector(state, fetchFilesSharedWithMeEndpoint.endpoint)
export const filesSharedWithMeNextPageSelector = state =>
  nextPageSelector(state, fetchFilesSharedWithMeEndpoint.endpoint)
export const filesSharedWithMePageCountSelector = state =>
  pageCountSelector(state, fetchFilesSharedWithMeEndpoint.endpoint)

export const hasFiltersSelector = createSelector(
  filtersSelector,
  compose(any(compose(not, isEmpty)), values, omit(['name'])),
)

export const appliedFiltersSelector = (state, name) => path(['filesSharedWithMe', 'filters', name], state)
export const foldersToMoveIdsSelector = path(['filesSharedWithMe', 'folderToMoveIds'])

export const foldersToMoveSelector = createSelector(
  [foldersToMoveIdsSelector, dataSelector],
  buildCollection('businessStorage'),
)
