import cookies from 'next-cookies'

const tokenInterceptor = ctx => config => {
  if (config.headers.Authorization) return config

  const { tokens, guestSession } = cookies(ctx)
  const accessToken = tokens?.access || guestSession?.token

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = accessToken || null
  return config
}
export default tokenInterceptor
