import { createSelector } from '@reduxjs/toolkit'
import { ascend, complement, compose, dissoc, equals, filter, last, path, pathEq, pipe, prop, sort } from 'ramda'

import { TASK_TYPES, VIEWS } from 'lib/constants/tasks'
import { dataSelector, hasMoreSelector, loadingSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { getTaskType } from 'utils/tasks'
import { fetchTasksEndpoint } from './endpoints'
import { filtersInitialState } from './reducer'

const taskIdsSelector = path(['tasks', 'taskIds'])
export const filtersSelector = path(['tasks', 'filters'])
export const hasFiltersSelector = pipe(filtersSelector, complement(equals(filtersInitialState)))
export const isBlankStateSelector = pathEq(0, ['tasks', 'counts', 'allTasksCount'])
export const viewSelector = path(['tasks', 'view'])
export const appliedFiltersSelector = (state, name) => path(['tasks', 'filters', name], state)
export const isCompletedSelector = state => appliedFiltersSelector(state, 'completed')

export const tasksSelector = createSelector(taskIdsSelector, dataSelector, buildCollection('task'))
export const isAssigneeFilterChosenSelector = createSelector(
  [hasFiltersSelector, filtersSelector],
  (hasFilters, filters) => {
    const withoutAssignee = dissoc('assignee')
    return hasFilters && equals(withoutAssignee(filters), withoutAssignee(filtersInitialState))
  },
)
export const tasksOrderedSelector = createSelector(
  [tasksSelector, isCompletedSelector, viewSelector],
  (tasks, isCompleted, view) => (view === VIEWS.list && !isCompleted ? sort(ascend(prop('priority')), tasks) : tasks),
)

export const oldestTaskIdSelector = createSelector(tasksOrderedSelector, compose(prop('id'), last))

export const hasMoreTasksSelector = state => hasMoreSelector(state, fetchTasksEndpoint.endpoint)
export const tasksLoadingSelector = state => loadingSelector(state, fetchTasksEndpoint.endpoint)

export const tasksTotalCountSelector = path(['tasks', 'counts', 'totalCount'])

export const upcomingTasksSelector = createSelector(
  tasksOrderedSelector,
  compose(filter(compose(equals(TASK_TYPES.upcoming), getTaskType)), sort(ascend(prop('dueDate')))),
)

export const overdueTasksSelector = createSelector(
  tasksOrderedSelector,
  compose(filter(compose(equals(TASK_TYPES.overdue), getTaskType)), sort(ascend(prop('dueDate')))),
)

export const noDueDateTasksSelector = createSelector(
  tasksOrderedSelector,
  filter(compose(equals(TASK_TYPES.noDueDate), getTaskType)),
)

export const tasksCountsSelector = path(['tasks', 'counts'])

export const isCreatingTaskSelector = path(['tasks', 'isCreatingTask'])
export const taskToEditSelector = path(['tasks', 'taskToEdit'])
