import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import Router from 'next/router'
import { propOr } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { fetchServices } from 'state/concepts/services/actions'
import { hideModal } from 'state/modal/actions'
import { UPDATE_SERVICE_PROVISION } from 'state/concepts/service/types'
import { serviceProvidersFormToParams } from 'utils/services/serviceFormToParams'
import assignServerProviderErrorToForm from 'utils/services/assignServerProviderErrorToForm'
import { servicesProvisionRoute } from 'lib/apiRoutes'

const updateServiceProvisionOperation = createLogic({
  type: UPDATE_SERVICE_PROVISION,
  latest: true,

  async process({ action: { values, form, componentInitialProps }, httpClient }, dispatch, done) {
    const groupedProvision = values.provision
    const provision = serviceProvidersFormToParams(groupedProvision)

    try {
      const params = {
        provision,
        include: 'user-profiles-services.user-profile',
      }
      const id = propOr(values.serviceId, 'serviceId', componentInitialProps)
      const url = servicesProvisionRoute(id)

      const { data } = await httpClient.put(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))

      if (!Router.router.asPath.includes(`services/${id}`)) {
        dispatch(fetchServices())
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.teamMembersAssigned',
              values: { count: params.provision.length },
            },
          }),
        )
      } else {
        dispatch(showNotification({ messageObject: { id: 'notifications.serviceUpdated' } }))
      }
      form.resetForm()
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        error.response.data.errors = assignServerProviderErrorToForm(error, provision, groupedProvision)
      }

      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default updateServiceProvisionOperation
