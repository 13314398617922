import normalize from 'json-api-normalizer'
import { filter, path, pipe, pluck, includes } from 'ramda'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { guestLinkExpiredRoute } from 'lib/routes'
import { setPendingFormIds } from 'state/concepts/booking/actions'
import { getBookingEndpoint } from 'state/concepts/booking/endpoints'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'
import { SUBMISSION_STATUSES } from 'lib/constants/forms'
import { FETCH_PENDING_FORMS } from '../types'

const fetchPendingForms = createLogic({
  type: FETCH_PENDING_FORMS,
  latest: true,

  async process({ httpClient, action: { workspaceCode, uniqCode, res } }, dispatch, done) {
    const { url, endpoint } = getBookingEndpoint(uniqCode)

    const config = {
      ...buildCustomBaseUrl(['workspaces', workspaceCode]),
      params: {
        include: ['client-profile-forms'],
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, config)
      const pendingForms = filter(
        pipe(path(['attributes', 'status']), status => !includes(status, SUBMISSION_STATUSES)),
        data.included,
      )
      const ids = pluck('id', pendingForms)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data) }))
      dispatch(setPendingFormIds(ids))
    } catch (error) {
      redirect({ href: guestLinkExpiredRoute, response: res })
    }
    done()
  },
})

export default fetchPendingForms
