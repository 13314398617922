import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map, prop } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import isBlankState from 'utils/isBlankState'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_CLIENTS } from 'state/concepts/clients/types'
import { paginationSelector, sortParamsSelector, appliedFiltersSelector } from 'state/concepts/clients/selectors'
import { fetchClientsEndpoint } from 'state/concepts/clients/endpoints'
import { setClients, setBlankState, setTotalCount } from 'state/concepts/clients/actions'

const fetchClientsOperation = createLogic({
  type: FETCH_CLIENTS,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const state = getState()
    const { endpoint, url } = fetchClientsEndpoint
    const params = {
      page: paginationSelector(state),
      sort: sortParamsSelector(state),
      filter: appliedFiltersSelector(state),
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(setBlankState(isBlankState({ params: params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      dispatch(setTotalCount(data.meta.total))
      dispatch(setClients(map(prop('id'), data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchClientsOperation
