import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, Formik } from 'formik'

import Modal from 'views/shared/Modal'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import RadioColorField from 'views/shared/RadioColorField'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import { createFormCategory, updateFormCategory } from 'state/concepts/formCategories/actions'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import colors from 'lib/constants/categoriesColors'
import isPresent from 'utils/isPresent'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants/formCategories'
import formCategorySchema from 'lib/yupLocalised/schemas/formCategory'

const PersistFormCategoryModal = ({ onClose, formCategory }) => {
  const isFormCategoryPresent = isPresent(formCategory)
  const handleSubmit = useFormSubmit(isFormCategoryPresent ? updateFormCategory : createFormCategory)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title">
            <FormattedMessage
              id={isFormCategoryPresent ? 'formCategories.updateModal.title' : 'formCategories.createModal.title'}
            />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <Formik
          initialValues={{
            name: formCategory?.name,
            color: formCategory?.color || colors[0],
            id: formCategory?.id,
          }}
          validationSchema={formCategorySchema}
          onSubmit={handleSubmit}
        >
          <>
            <div className="main-modal__body">
              <Field
                className="mb-24 main-input--input-top-count"
                component={InputWithCounterField}
                label={{ id: 'formCategories.createModal.nameLabel' }}
                name="name"
                id="name"
                icon="alert"
                placeholder={{ id: 'formCategories.createModal.namePlaceholder' }}
                showCount
                maxChars={MAX_INPUT_CONTENT_LENGTH}
              />
              <div className="main-input__label mb-16" data-cy="select-category-color-text">
                <FormattedMessage id="formCategories.createModal.colorLabel" />
              </div>
              <div>
                <Field className="category-color" name="color" component={RadioColorField} options={colors} />
              </div>
            </div>
            <div className="main-modal__footer">
              <Button text={{ id: 'shared.cancel' }} onClick={onClose} kind="flat" />
              <SubmitFormButton text={{ id: isFormCategoryPresent ? 'shared.saveChanges' : 'shared.create' }} />
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

PersistFormCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formCategory: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
  }),
}

PersistFormCategoryModal.defaultProps = {
  formCategory: undefined,
}

export default PersistFormCategoryModal
