import { createLogic } from 'redux-logic'

import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess, dataDelete, dataApiRequest } from 'state/data/actions'
import { DISCONNECT_MANUAL_PAYMENT } from 'state/concepts/paymentSettings/types'
import { disconnectManualPaymentEndpoint } from 'state/concepts/paymentSettings/endpoints'
import { manualPaymentSelector, paymentAccountSelector } from 'state/concepts/paymentSettings/selectors'
import updateDataHelper from 'utils/updateDataHelper'

const disconnectManualPaymentOperation = createLogic({
  type: DISCONNECT_MANUAL_PAYMENT,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = disconnectManualPaymentEndpoint
    const state = getState()
    const { id } = paymentAccountSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.delete(url)

      dispatch(hideModal())
      dispatch(dataDelete({ kind: 'manualPayment', ids: [manualPaymentSelector(state).id] }))
      const response = updateDataHelper(state.data, 'paymentAccount', id, {
        relationships: {
          manualPayment: {
            data: null,
          },
        },
      })
      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.manualPaymentWasDisconnected',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }
    done()
  },
})

export default disconnectManualPaymentOperation
