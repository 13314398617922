import { useState } from 'react'
import PropTypes from 'prop-types'
import { useLifecycles } from 'react-use'

import { EDIT_WEBSITE_PREVIEW_STEPS } from 'lib/constants/website'
import { STEPS } from './steps'

const EditWebsiteSidebar = ({ toggleEditWebsite, ...rest }) => {
  const [currentStep, setCurrentStep] = useState(EDIT_WEBSITE_PREVIEW_STEPS.main)
  const Step = STEPS[currentStep]
  const handleSetCurrentStep = step => () => {
    setCurrentStep(step)
  }
  useLifecycles(toggleEditWebsite, toggleEditWebsite)

  return (
    <div data-cy="ws-header-editor" className="ws-header__editor">
      <Step {...rest} handleSetCurrentStep={handleSetCurrentStep} />
    </div>
  )
}

EditWebsiteSidebar.propTypes = {
  toggleEditWebsite: PropTypes.func.isRequired,
}

export default EditWebsiteSidebar
