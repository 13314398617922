import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propEq } from 'ramda'

import { serviceSelector } from 'state/concepts/service/selectors'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import ExpertSelectComponent from './component'

class ExpertSelect extends React.Component {
  static propTypes = {
    service: PropTypes.shape(),
    setFieldValue: PropTypes.func.isRequired,
    serviceId: PropTypes.string,
  }

  static defaultProps = {
    service: null,
    serviceId: null,
  }

  componentDidUpdate = prevProps => {
    const { setFieldValue, service, serviceId } = this.props

    if (serviceId && prevProps.serviceId !== serviceId && !service.userProfileService) {
      setFieldValue('userProfileId', undefined, false)
    }
  }

  get isNoAssignedExperts() {
    const { service } = this.props

    return propEq(0, 'providerCount')(service)
  }

  render = () => <ExpertSelectComponent {...this.props} isNoAssignedExperts={this.isNoAssignedExperts} />
}

const mapStateToProps = (state, { serviceId }) => ({
  service: serviceSelector(state, serviceId),
  currentEmployee: currentEmployeeSelector(state),
})

export { ExpertSelect as ExpertSelectContainer }
export default connect(mapStateToProps)(ExpertSelect)
