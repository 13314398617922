import { createLogic } from 'redux-logic'
import { CardNumberElement } from '@stripe/react-stripe-js'

import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_PAYMENT_METHOD } from 'state/concepts/billing/types'
import { updatePaymentMethodEndpoint } from 'state/concepts/billing/endpoints'
import { setupIntentClientSecretSelector } from 'state/concepts/billing/selectors'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsSubscriptionRoute } from 'lib/routes'

const updatePaymentMethodOperation = createLogic({
  type: UPDATE_PAYMENT_METHOD,
  latest: true,

  async process(
    { action: { values, form, confirmCardSetup, getStripeElement, setStripeBaseError }, getState, httpClient },
    dispatch,
    done,
  ) {
    const { endpoint, url } = updatePaymentMethodEndpoint

    const state = getState()
    const setupIntentClientSecret = setupIntentClientSecretSelector(state)
    const workspace = currentWorkspaceCodeSelector(state)

    try {
      const { setupIntent, error } = await confirmCardSetup(setupIntentClientSecret, {
        payment_method: {
          card: getStripeElement(CardNumberElement),
          billing_details: {
            address: {
              line1: values.address,
              postal_code: values.index,
              country: values.country,
              state: values.state,
              city: values.city,
            },
          },
        },
      })
      if (error) {
        setStripeBaseError(error.message)
        // eslint-disable-next-line no-throw-literal
        throw { ...error, isStripe: true }
      }

      const params = {
        stripe_payment_method_id: setupIntent.payment_method,
      }

      dispatch(dataApiRequest({ endpoint }))

      await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.paymentMethodHasBeenChanged',
          },
        }),
      )
      redirect({ href: companySettingsSubscriptionRoute, workspace })
    } catch (error) {
      const { isStripe } = error

      if (!isStripe) {
        requestErrorHandler({ error, dispatch, endpoint, form })
      }
      form.setSubmitting(false)
    }

    done()
  },
})

export default updatePaymentMethodOperation
