import { path, prop, values, isEmpty, not, compose, any, pipe } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'

import { dataSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'
import { namespace } from './types'

export const isBlankStateSelector = path([namespace, 'isBlankState'])
export const recurringPaymentIdsSelector = path([namespace, 'recurringPaymentIds'])
export const paginationSelector = path([namespace, 'pagination'])
export const filtersSelector = path([namespace, 'filters'])
export const appliedFiltersSelector = (state, name) => path([namespace, 'filters', name], state)

export const recurringPaymentsSelector = createSelector(recurringPaymentIdsSelector, dataSelector, (ids, data) =>
  buildCollection('recurringPayment', ids, data),
)
export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const hasFiltersSelector = createSelector(filtersSelector, compose(any(compose(not, isEmpty)), values))
export const searchQuerySelector = pipe(filtersSelector, prop('name'))
