import { createLogic } from 'redux-logic'
import { pathOr, prop, keys, pick, omit } from 'ramda'

import { rootHttpClient } from 'lib/httpClient'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import { VALIDATE_PROMOCODES } from 'state/concepts/promocodes/types'
import {
  appliedCodesSelector,
  appliedTrialCodeSelector,
  appliedDiscountCodeSelector,
} from 'state/concepts/promocodes/selectors'
import { setTrialCode, setDiscountCode } from 'state/concepts/promocodes/actions'
import { promocodesValidation as promocodesValidationRoute } from 'lib/apiRoutes'
import requestErrorHandler from 'lib/requestErrorHandler'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'

const validatePromocodesOperation = createLogic({
  type: VALIDATE_PROMOCODES,
  latest: true,

  async process({ action: { form }, getState }, dispatch, done) {
    try {
      const {
        values: { email },
        subscriptionPlan,
      } = form
      const codes = appliedCodesSelector(getState())
      const params = {
        codes,
        email,
        subscription_plan_id: subscriptionPlan.id,
      }
      await rootHttpClient.post(promocodesValidationRoute, params)

      form.setStatus({})
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const errors = pathOr(null, ['response', 'data', 'errors'], error)
        const formattedErrors = formatJsonApiErrors(errors, { rawKeys: true })
        const invalidCodes = keys(prop('codes', formattedErrors))

        const state = getState()
        const appliedTrialCode = appliedTrialCodeSelector(state)
        const appliedDiscountCode = appliedDiscountCodeSelector(state)

        if (invalidCodes.includes(appliedTrialCode)) {
          dispatch(setTrialCode(null))
        }
        if (invalidCodes.includes(appliedDiscountCode)) {
          dispatch(setDiscountCode(null))
        }

        form.setStatus(pick(['codes'], formattedErrors))
        form.setErrors(omit(['codes'], formattedErrors))
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }

    done()
  },
})

export default validatePromocodesOperation
