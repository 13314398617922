import PropTypes from 'prop-types'

import LogoImage from './LogoImage'

const ChatCardLogoComponent = ({ id, isOnline, color, ...rest }) => (
  <div
    style={{ backgroundColor: color, borderColor: color }}
    className="chat-participants-card__img-wrap"
    data-cy="chat-list-chat-logo"
  >
    <LogoImage id={id} {...rest} />
    {isOnline && <span className="main-userpic__online-status" />}
  </div>
)

ChatCardLogoComponent.defaultProps = {
  isOnline: false,
}

ChatCardLogoComponent.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isOnline: PropTypes.bool,
}

export default ChatCardLogoComponent
