import React from 'react'
import PropTypes from 'prop-types'

const ArrowRepeat = ({ size, color, className }) => (
  <svg width={`${size}px`} height={`${size}px`} className={className} fill={color} viewBox="0 0 18 24">
    <path d="M9 1.20711C9 0.761654 8.46143 0.538571 8.14645 0.853553L5.35355 3.64645C5.15829 3.84171 5.15829 4.15829 5.35355 4.35355L8.14645 7.14645C8.46143 7.46143 9 7.23835 9 6.79289V5C12.859 5 16 8.14 16 12C16 12.7656 15.8708 13.501 15.6409 14.1902C15.5784 14.3777 15.6214 14.5861 15.7612 14.7257L16.7527 15.7159C16.8975 15.8606 17.1413 15.8208 17.2245 15.6339C17.7195 14.5217 18 13.2944 18 12C18 7.038 13.963 3 9 3V1.20711ZM1.24733 8.28406C1.10253 8.13945 0.858725 8.17918 0.77551 8.36614C0.280503 9.47828 0 10.7056 0 12C0 16.962 4.037 21 9 21V22.7929C9 23.2383 9.53857 23.4614 9.85355 23.1464L12.6464 20.3536C12.8417 20.1583 12.8417 19.8417 12.6464 19.6464L9.85355 16.8536C9.53857 16.5386 9 16.7617 9 17.2071V19C5.141 19 2 15.86 2 12C2 11.2344 2.12924 10.499 2.35906 9.80978C2.42157 9.62231 2.37863 9.4139 2.23881 9.27426L1.24733 8.28406Z" />
  </svg>
)

ArrowRepeat.defaultProps = {
  size: 24,
  color: '#b1bac9',
  className: '',
}

ArrowRepeat.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default ArrowRepeat
