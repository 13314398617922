import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { prop, propOr } from 'ramda'

import { currentWorkspaceSelector } from 'state/concepts/session/selectors'
import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import profileName from 'utils/profileName'
import isPresent from 'utils/isPresent'
import ConfirmationCodeForm from './ConfirmationCodeForm'
import DeleteForm from './DeleteForm'

const DeleteModal = ({ onClose, currentClient: { currentContact, associatedClientContacts }, currentClient }) => {
  const workspaceName = propOr(null, 'name', useSelector(currentWorkspaceSelector))
  const [isDeleteFormVisible, onDeleteFormToggle] = useToggle(false)
  const intl = useIntl()
  const associatedClientContactsNames = intl.formatList(
    associatedClientContacts?.map(associatedClientContact => profileName(associatedClientContact.clientProfile)) || [],
  )
  const associatedClientNamesResponsibleForBilling = intl.formatList(
    associatedClientContacts
      ?.filter(prop('responsibleForBilling'))
      ?.map(associatedClientContact => profileName(associatedClientContact.clientProfile)) || [],
  )
  const isResponsibleForBilling = currentContact
    ? currentContact.responsibleForBilling
    : isPresent(associatedClientNamesResponsibleForBilling)

  return (
    <Modal className="main-modal main-modal--md mb-32 mt-12" onClose={onClose}>
      <div className="main-modal__container" data-cy="delete-modal-container">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="delete-modal-header">
            <FormattedMessage
              id={`client.account.settings.tab.general.${currentContact ? 'deleteContact' : 'delete'}.modal.title`}
              values={{ clientName: profileName(currentClient) }}
            />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>

        <div className="main-modal__body pb-0">
          <p className="mb-16" data-cy="delete-modal-info-warning">
            <FormattedMessage
              id={`client.account.settings.tab.general.${currentContact ? 'deleteContact' : 'delete'}.modal.body`}
            />
          </p>

          <ul className="bullet-list bullet-list--col" data-cy="modal-informational-messages-block">
            {!currentContact && (
              <li className="bullet-list__item in-blue-gray-800" data-cy="your-account-block">
                <p className="font-600 mb-4" data-cy="your-account-subheader">
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.body.your_account" />
                </p>
                <p data-cy="your-account-message">
                  <FormattedMessage
                    id="client.account.settings.tab.general.delete.modal.body.your_account.text"
                    values={{ workspaceName }}
                  />
                </p>
              </li>
            )}
            <li className="bullet-list__item in-blue-gray-800" data-cy="your-personal-data-block">
              <p className="font-600 mb-4" data-cy="your-personal-data-subheader">
                <FormattedMessage
                  id={`client.account.settings.tab.general.${
                    currentContact ? 'deleteContact' : 'delete'
                  }.modal.body.your_personal_data`}
                />
              </p>
              <p data-cy="your-personal-data-message">
                <FormattedMessage
                  id={`client.account.settings.tab.general.${
                    currentContact ? 'deleteContact' : 'delete'
                  }.modal.body.your_personal_data.text`}
                />
              </p>
            </li>
            {!currentContact && (
              <li className="bullet-list__item in-blue-gray-800" data-cy="your-appointments-block">
                <p className="font-600 mb-4" data-cy="your-appointments-subheader">
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.body.your_appointments" />
                </p>
                <p data-cy="your-appointments-message">
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.body.your_appointments.text" />
                </p>
              </li>
            )}
            {!currentContact && isPresent(associatedClientContacts) && (
              <li className="bullet-list__item in-blue-gray-800" data-cy="other-client-info-block">
                <p className="font-600 mb-4" data-cy="access-client-info">
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.body.accessToOtherClientInformation" />
                </p>
                <p data-cy="access-client-info-text">
                  <FormattedMessage
                    id="client.account.settings.tab.general.delete.modal.body.accessToOtherClientInformation.text"
                    values={{ names: associatedClientContactsNames }}
                  />
                </p>
              </li>
            )}
            {isResponsibleForBilling && (
              <li className="bullet-list__item in-blue-gray-800" data-cy="payments-block">
                <p className="font-600 mb-4" data-cy="access-client-info">
                  <FormattedMessage id="client.account.settings.tab.general.delete.modal.body.payments" />
                </p>
                <p data-cy="access-client-info-text">
                  <FormattedMessage
                    id={`client.account.settings.tab.general.${
                      currentContact ? 'deleteContact' : 'delete'
                    }.modal.body.payments.text`}
                    values={{
                      names: associatedClientNamesResponsibleForBilling,
                      clientName: profileName(currentClient),
                    }}
                  />
                </p>
              </li>
            )}
          </ul>
        </div>
        <div>
          <div className="main-modal__footer flex-wrap" data-cy="delete-modal-footer">
            {isDeleteFormVisible ? (
              <DeleteForm onClose={onClose} currentContact={currentContact} />
            ) : (
              <ConfirmationCodeForm
                onClose={onClose}
                onDeleteFormToggle={onDeleteFormToggle}
                currentContact={currentContact}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentClient: PropTypes.shape().isRequired,
}

export default DeleteModal
