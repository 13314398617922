import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_CLIENT } from 'state/concepts/client/types'
import { updateClientEndpoint } from '../endpoints'

const updateClientOperation = createLogic({
  type: UPDATE_CLIENT,
  latest: true,

  async process({ getState, action: { form, values }, httpClient }, dispatch, done) {
    const { url } = updateClientEndpoint(values.id)

    const params = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
    }

    const response = updateDataHelper(getState().data, 'clientProfile', values.id, {
      attributes: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      },
    })

    try {
      await httpClient.put(url, params)
      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.clientProfileUpdated' } }))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateClientOperation
