import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'ramda'
import { DateTime } from 'luxon'

import { haveSameMonth } from 'utils/dateTime'
import {
  getClientServiceAvailability as getClientServiceAvailabilityAction,
  setBookingDate as setBookingDateAction,
  setBookingTimeSlots as setBookingTimeSlotsAction,
} from 'state/concepts/booking/actions'
import { bookingDateSelector, bookingTimeSlotsSelector } from 'state/concepts/booking/selectors'
import { clientServiceAvailabilityLoadingSelector } from 'state/concepts/services/selectors'
import { timezoneLoadingSelector } from 'state/concepts/timezones/selectors'
import { getTimezones as getTimezonesAction } from 'state/concepts/timezones/actions'
import ClientBookingRescheduleChangeDateComponent from './component'

class ClientBookingRescheduleChangeDate extends React.Component {
  static propTypes = {
    getClientServiceAvailability: PropTypes.func.isRequired,
    setBookingDate: PropTypes.func.isRequired,
    setBookingTimeSlots: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    uniqCode: PropTypes.string.isRequired,
    date: PropTypes.shape().isRequired,
    timeSlots: PropTypes.shape(),
    timezone: PropTypes.string.isRequired,
    getTimezones: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    handleResize: PropTypes.func,
  }

  static defaultProps = {
    timeSlots: null,
    isLoading: true,
    handleResize: undefined,
  }

  componentDidMount = () => {
    const { date, getTimezones } = this.props
    this.fetchAvailabilities(date)
    getTimezones()
  }

  componentDidUpdate = prevProps => {
    const isTimeSlotsChanged = prevProps.timeSlots !== this.props.timeSlots
    const isTimezoneChanged = prevProps.timezone !== this.props.timezone

    if (isTimeSlotsChanged) {
      this.props.handleResize?.()
    }
    if (isTimezoneChanged) {
      this.fetchAvailabilities(this.props.date)
      this.props.setFieldValue('startTime', '')
    }
  }

  get currentTimeSlots() {
    const { timeSlots, date } = this.props
    return timeSlots && timeSlots[date.toISODate()]
  }

  fetchAvailabilities = date => {
    const { uniqCode, timezone } = this.props

    this.props.getClientServiceAvailability({
      date,
      uniqCode,
      timezone,
    })
  }

  setSlotsDate = nextDate => {
    const { timeSlots, setBookingDate, setBookingTimeSlots, handleResize } = this.props
    const date = DateTime.fromJSDate(nextDate)

    setBookingDate(date)
    setBookingTimeSlots(timeSlots)
    handleResize?.()
  }

  setTileDisable = ({ activeStartDate, date }) => {
    const { timeSlots } = this.props
    const activeDate = DateTime.fromJSDate(date)
    const currentMonthDate = DateTime.fromJSDate(activeStartDate)

    return haveSameMonth(activeDate, currentMonthDate) && Boolean(!(timeSlots && timeSlots[activeDate.toISODate()]))
  }

  onClickMonth = date => {
    this.fetchAvailabilities(DateTime.fromJSDate(date))
  }

  onPrevNextClick = ({ activeStartDate }) => {
    this.fetchAvailabilities(DateTime.fromJSDate(activeStartDate))
  }

  onChangeSlot = startTime => () => {
    this.props.setFieldValue('startTime', startTime)
  }

  render = () => (
    <ClientBookingRescheduleChangeDateComponent
      {...this.props}
      {...this.state}
      setSlotsDate={this.setSlotsDate}
      setTileDisable={this.setTileDisable}
      onClickMonth={this.onClickMonth}
      onPrevNextClick={this.onPrevNextClick}
      onChangeSlot={this.onChangeSlot}
      onBackToNearestSlots={this.onBackToNearestSlots}
      timeSlots={this.currentTimeSlots}
    />
  )
}

const mapStateToProps = (state, { uniqCode }) => ({
  date: DateTime.fromISO(bookingDateSelector(state)),
  timeSlots: bookingTimeSlotsSelector(state),
  isLoading: clientServiceAvailabilityLoadingSelector(state, uniqCode) || timezoneLoadingSelector(state),
})

const mapDispatchToProps = {
  getClientServiceAvailability: getClientServiceAvailabilityAction,
  setBookingDate: setBookingDateAction,
  setBookingTimeSlots: setBookingTimeSlotsAction,
  getTimezones: getTimezonesAction,
}

export { ClientBookingRescheduleChangeDate as ClientBookingRescheduleChangeDateContainer }
export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(ClientBookingRescheduleChangeDate)
