import * as types from './types'

export const getCalendarEvent = calendarEventId => ({
  type: types.GET_CALENDAR_EVENT,
  calendarEventId,
})

export const setCalendarEvent = calendarEventId => ({
  type: types.SET_CALENDAR_EVENT,
  calendarEventId,
})
