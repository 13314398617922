import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { DISCONNECT_PAYPAL } from 'state/concepts/paymentSettings/types'
import { dataApiRequest } from 'state/data/actions'
import { disconnectPaypalEndpoint } from 'state/concepts/paymentSettings/endpoints'

const disconnectPaypalOperation = createLogic({
  type: DISCONNECT_PAYPAL,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = disconnectPaypalEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url)
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default disconnectPaypalOperation
