import yup from 'lib/yupLocalised'
import { EMPLOYEE_REMOVE_VALUE } from 'lib/constants/employees'

export default yup.object().shape({
  removeType: yup.string(),
  newFileOwnerId: yup.string().when('removeType', {
    is: removeType => removeType === EMPLOYEE_REMOVE_VALUE.newFileOwner,
    then: () => yup.string().required(),
  }),
})
