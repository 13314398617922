import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pathOr, any, propEq } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { removeOutlookCalendarConnectLink } from 'state/concepts/calendarIntegrations/actions'
import { RECONNECT_OUTLOOK_CALENDAR } from '../types'
import { reconnectOutlookCalendarEndpoint } from '../endpoints'

const reconnectOutlookCalendar = createLogic({
  type: RECONNECT_OUTLOOK_CALENDAR,
  latest: true,

  async process(
    {
      action: {
        connection: { id, name },
        authenticationCode,
        state,
      },
      httpClient,
    },
    dispatch,
    done,
  ) {
    const { url, endpoint } = reconnectOutlookCalendarEndpoint(id)

    try {
      const params = {
        code: authenticationCode,
        state,
        include: 'outlook-calendar-list-items',
      }
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(showNotification({ messageObject: { id: 'notifications.calendarReconnect.success' } }))
    } catch (error) {
      let messageObject = { id: 'notifications.googleCalendarReconnect.tryAgainLater' }
      const errors = pathOr(null, ['response', 'data', 'errors'], error)

      if (any(propEq({ type: 'email_invalid' }, 'meta'), errors)) {
        messageObject = { id: 'notifications.googleCalendarReconnect.invalidAccount', values: { name } }
      }

      dispatch(showNotification({ messageObject, kind: 'error' }))
      requestErrorHandler({ error, dispatch, endpoint, skipHandlers: true })
    }

    dispatch(removeOutlookCalendarConnectLink(id))
    done()
  },
})

export default reconnectOutlookCalendar
