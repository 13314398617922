import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import isBlankState from 'utils/isBlankState'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SEARCH_LOCATIONS } from 'state/concepts/locations/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import { addSearchResults, resetSearchResults, setBlankState } from 'state/searchResults/actions'
import { LOCATION_STATUS } from 'lib/constants/locations'

const searchLocationsOperation = createLogic({
  type: SEARCH_LOCATIONS,
  latest: true,

  async process({ action: { page, resetResults, filter, size }, httpClient }, dispatch, done) {
    const { endpoint, url } = fetchLocationsEndpoint
    const defaultFilter = { status: LOCATION_STATUS.active }
    const params = {
      page: {
        size: size || 100,
        number: page || 1,
      },
      sort: 'name',
      filter: filter || defaultFilter,
    }

    try {
      dispatch(dataApiRequest({ endpoint }))
      if (resetResults) {
        dispatch(resetSearchResults(SEARCH_RESULTS_ENTITIES.locations))
      }

      const { data } = await httpClient.get(url, { params })
      const ids = data.data.map(item => item.id)
      const response = normalize(data, { endpoint })

      dispatch(
        setBlankState({
          [SEARCH_RESULTS_ENTITIES.locations]: isBlankState({ params: {}, data: data.data }),
        }),
      )
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(addSearchResults({ [SEARCH_RESULTS_ENTITIES.locations]: ids }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default searchLocationsOperation
