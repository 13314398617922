import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field, Formik } from 'formik'
import { head, last, split } from 'ramda'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants'
import createFolderSchema from 'lib/yupLocalised/schemas/createFolderSchema'
import { renameMyBusinessFile as renameMyBusinessFileAction } from 'state/concepts/myBusinessFiles/actions'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import { isFolder as isFolderFunc } from 'utils/businessFiles'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'

const RenameBusinessFileModal = ({ onClose, myBusinessFile }) => {
  const renameMyBusinessFile = useFormSubmit(renameMyBusinessFileAction)
  const isFolder = isFolderFunc(myBusinessFile.kind)
  const splittedName = split('.', myBusinessFile.name)
  const name = isFolder ? myBusinessFile.name : head(splittedName)
  const extension = isFolder ? undefined : last(splittedName)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <Formik
          initialValues={{
            name,
            myBusinessFileId: myBusinessFile.id,
            extension,
          }}
          validationSchema={createFolderSchema}
          onSubmit={renameMyBusinessFile}
        >
          <>
            <div className="main-modal__header">
              <h2 className="main-modal__title">
                <FormattedMessage
                  id={`myBusinessFiles.renameMyBusinessFile.modal.title.${isFolder ? 'folder' : 'file'}`}
                />
              </h2>
              <button type="button" onClick={onClose} className="main-modal__close">
                <Cross dataCy="cross-icon" />
              </button>
            </div>
            <div className="main-modal__body">
              <Field
                name="name"
                id="name"
                component={InputWithCounterField}
                className="main-input--input-top-count"
                label={{
                  id: 'myBusinessFiles.createFolder.modal.label',
                }}
                maxChars={MAX_INPUT_CONTENT_LENGTH}
                icon="alert"
              />
            </div>
            <div className="main-modal__footer">
              <div className="d-flex justify-content-end">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton text={{ id: 'shared.saveChanges' }} />
              </div>
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

RenameBusinessFileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  myBusinessFile: PropTypes.shape().isRequired,
}

export default RenameBusinessFileModal
