import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Field } from 'formik'

import { MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH } from 'lib/constants'
import isPresent from 'utils/isPresent'
import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import TextAreaField from 'views/shared/TextAreaField'
import Cross from 'views/shared/icons/Cross'
import SwitchField from 'views/shared/SwitchField'

const CancelModalComponent = ({ onClose, isRefundShown, cancellationRules: { description, bookingRefundAmount } }) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <Form>
      <div className="main-modal__container">
        <div className="main-modal__header">
          <h2 className="main-modal__title" data-cy="cancel-modal-title">
            <FormattedMessage id="cancelBookingModal.cancelAppointment" />
          </h2>
          <button type="button" onClick={onClose} className="main-modal__close">
            <Cross dataCy="cross-icon" />
          </button>
        </div>
        <div className="main-modal__body">
          {isPresent(description) && <p data-cy="cancel-modal-description">{description}</p>}
          <Field
            className="textarea-reason mb-0 full-width main-input--textarea-top-count"
            name="cancellationReason"
            component={TextAreaField}
            id="cancellationReason"
            label={{ id: 'cancelBookingModal.reason' }}
            icon="alert"
            showCount
            maxLength={MAX_TEXTAREA_CONTENT_MIDDLE_LENGTH}
          />
          {isRefundShown && (
            <div className="mt-24">
              <Field
                className="main-switch--reverse main-switch--blue-bg"
                name="refundNeeded"
                id="refundNeeded"
                label={{
                  id: 'cancelBookingModal.refund',
                  values: { refundAmount: bookingRefundAmount },
                }}
                component={SwitchField}
                dataCy="refund-needed-checkbox"
              />
            </div>
          )}
        </div>
        <div className="main-modal__footer">
          <div>
            <Button
              className="main-modal__footer-action mr-16"
              text={{ id: 'shared.leave' }}
              onClick={onClose}
              kind="flat"
              dataCy="cancel-modal-leave-button"
            />
            <SubmitFormButton
              className="main-modal__footer-action main-btn--danger"
              text={{ id: 'cancelBookingModal.cancelAppointment.submit' }}
              kind="danger"
              allowPristine
              dataCy="cancel-modal-cancel-button"
            />
          </div>
        </div>
      </div>
    </Form>
  </Modal>
)

CancelModalComponent.defaultProps = {
  cancellationRules: undefined,
}

CancelModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  isRefundShown: PropTypes.bool.isRequired,
  cancellationRules: PropTypes.shape({
    bookingRefundAmount: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
}

export default CancelModalComponent
