import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import { buildCreateOptionsParams } from 'utils/customField'
import requestErrorHandler from 'lib/requestErrorHandler'
import updateDataHelper from 'utils/updateDataHelper'
import { customFieldsSelector } from 'state/concepts/customFields/selectors'
import { onCreate } from 'utils/attributesForSortable'
import { dataApiSuccess } from 'state/data/actions'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import { addCustomFields } from 'state/concepts/customFields/actions'
import { CREATE_CUSTOM_FIELD } from 'state/concepts/customFields/types'
import { createCustomFieldEndpoint } from 'state/concepts/customFields/endpoints'

const createCustomFieldOperation = createLogic({
  type: CREATE_CUSTOM_FIELD,
  latest: true,

  async process({ action: { form, values, onSuccess }, httpClient, getState }, dispatch, done) {
    const { url } = createCustomFieldEndpoint

    try {
      const state = getState()

      const params = {
        name: values.name,
        field_type: values.fieldType,
        custom_field_options_attributes: buildCreateOptionsParams(values),
        include: 'custom_field_options',
      }

      const { data } = await httpClient.post(url, params)

      const apiResponse = normalize(data)
      const { id } = data.data

      const customFields = customFieldsSelector(state)
      const response = updateDataHelper(state.data, 'customField', pluck('id', customFields), onCreate(customFields))

      dispatch(dataApiSuccess({ response }))
      dispatch(dataApiSuccess({ response: apiResponse }))

      dispatch(hideModal())
      dispatch(addCustomFields([id]))
      if (onSuccess) {
        onSuccess(id)
      } else {
        dispatch(showNotification({ messageObject: { id: 'notifications.customFieldWasCreated' } }))
      }
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default createCustomFieldOperation
