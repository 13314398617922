import { createLogic } from 'redux-logic'

import { FILTER_TASKS } from 'state/concepts/tasks/types'
import { setFilterParams, resetTasks, fetchTasks } from 'state/concepts/tasks/actions'

const filterTasksOperation = createLogic({
  type: FILTER_TASKS,
  latest: true,

  process({ action: { filters } }, dispatch, done) {
    dispatch(setFilterParams(filters))
    dispatch(resetTasks())
    dispatch(fetchTasks())
    done()
  },
})

export default filterTasksOperation
