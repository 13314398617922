import PropTypes from 'prop-types'
import { filter, keys, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import { TWO_FACTOR_SETTING_ICONS, TWO_FACTOR_SETTING_METHODS } from 'lib/constants/twoFactorSettings'
import isPresent from 'utils/isPresent'
import SelectMethodButton from '../SelectMethodButton'

const MethodsList = ({ setStep, twoFactorSettings, workspaceMethods, isSetup }) => {
  const currentMethodChecker = prop('methodChecker', twoFactorSettings)
  const allowedMethods = keys(filter(Boolean, workspaceMethods))

  return (
    <div className="methods-2fa">
      {allowedMethods.map(method => {
        const Icon = TWO_FACTOR_SETTING_ICONS[method]

        if (method === currentMethodChecker && currentMethodChecker === TWO_FACTOR_SETTING_METHODS.email) return null

        return (
          <div className="methods-2fa__element p-16" key={method}>
            <figure className="methods-2fa__icon d-flex align-items-center justify-content-center wh-40-40">
              <Icon size={20} className="in-blue-600" />
            </figure>
            <p className="methods-2fa__title text-body font-700 mb-4">
              <FormattedMessage id={`employeeTwoFactorSettings.selectMethod.methods.${method}.title`} />
            </p>
            <p className="methods-2fa__description text-caption mb-0">
              <FormattedMessage id={`employeeTwoFactorSettings.selectMethod.methods.${method}.description`} />
            </p>
            {allowedMethods.length > 1 && (
              <SelectMethodButton
                method={method}
                isUpdating={isPresent(twoFactorSettings)}
                setStep={setStep}
                isSetup={isSetup}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

MethodsList.defaultProps = {
  twoFactorSettings: null,
  isSetup: false,
}

MethodsList.propTypes = {
  setStep: PropTypes.func.isRequired,
  twoFactorSettings: PropTypes.shape({
    methodChecker: PropTypes.string,
  }),
  workspaceMethods: PropTypes.shape({
    email: PropTypes.bool,
    sms: PropTypes.bool,
    app: PropTypes.bool,
  }).isRequired,
  isSetup: PropTypes.bool,
}

export default MethodsList
