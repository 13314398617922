import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CopyO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M255.99 204.8v-153.6c0-28.277 22.924-51.2 51.2-51.2h614.4c28.278 0 51.2 22.924 51.2 51.2v716.799c0 28.278-22.922 51.2-51.2 51.2h-153.6v153.554c0 28.303-23.035 51.246-51.548 51.246h-613.7c-28.471 0-51.541-22.764-51.541-51.246l0.133-716.709c0.005-28.301 23.042-51.245 51.55-51.245h153.108zM153.725 307.2l-0.114 614.4h511.98v-614.4h-511.866zM358.389 204.8h409.599v512h102.4v-614.4h-512v102.4z" />
  </svg>
)

CopyO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CopyO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CopyO
