import { createLogic } from 'redux-logic'
import { map } from 'ramda'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { ADD_CHAT_MEMBERS } from 'state/concepts/chat/types'
import { hideModal } from 'state/modal/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { addChatMembersEndpoint } from 'state/concepts/chat/endpoints'

const addChatMembersOperation = createLogic({
  type: ADD_CHAT_MEMBERS,
  latest: true,

  async process({ action, getState, httpClient }, dispatch, done) {
    const {
      form,
      chat: { id, chatUserIds: memberIds },
      values: { chatUserIds },
    } = action
    const { endpoint, url } = addChatMembersEndpoint(id)

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.post(url, { chat_user_ids: chatUserIds })

      const response = updateDataHelper(getState().data, 'chat', id, {
        attributes: {
          chatUserIds: [...memberIds, ...map(Number, chatUserIds)],
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.chatMemberAdded' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      form.setSubmitting(false)
    }

    done()
  },
})

export default addChatMembersOperation
