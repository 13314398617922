import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_WORKSPACE_FEATURES } from 'state/concepts/workspaceFeatures/types'
import { fetchFeaturesEndpoint } from 'state/concepts/workspaceFeatures/endpoints'
import { setWorkspaceFeaturesIds } from 'state/concepts/workspaceFeatures/actions'

const fetchFeaturesOperation = createLogic({
  type: FETCH_WORKSPACE_FEATURES,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchFeaturesEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setWorkspaceFeaturesIds(pluck('id', data.data)))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchFeaturesOperation
