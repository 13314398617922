import { FORM_TYPES } from 'lib/constants/forms'

export const TABLE_ENTITIES = {
  [FORM_TYPES.intake]: 'intakeForms',
  [FORM_TYPES.agreement]: 'agreements',
  location: 'location',
  clientRelationshipTypes: 'clientRelationshipTypes',
  documentTemplates: 'documentTemplates',
  formCategories: 'formCategories',
  myBusinessFiles: 'myBusinessFiles',
  trashedFiles: 'trashedFiles',
  filesSharedWithMe: 'filesSharedWithMe',
  storageFiles: 'storageFiles',
}

export const PAGINATION_ITEMS_PER_PAGE = 20
