export const NOTIFICATION_PANEL = {
  close: 'closed',
  open: 'opened',
}

export const NOTIFICATION_TYPES = {
  admin: 'admin',
  appointments: 'appointments',
  chat: 'chat',
  system: 'system',
  team: 'team',
  tasks: 'tasks',
}

export const NOTIFICATION_THEMES_ABOUT_PLAN_UPGRADE = [
  'expire_trial_tomorrow',
  'expire_trial_in_three_days',
  'expire_trial_in_seven_days',
]

export const NOTIFICATION_THEMES_ABOUT_STORAGE_UPGRADE = [
  'real_deleting_files_in_one_day',
  'real_deleting_files_in_seven_days',
]

export const NOTIFICATIONS_THEMES_WITH_UPGRADE_LINK = [
  ...NOTIFICATION_THEMES_ABOUT_PLAN_UPGRADE,
  ...NOTIFICATION_THEMES_ABOUT_STORAGE_UPGRADE,
]

export const REMOVE_NOTIFICATION_TIMEOUT = 5000

export const NOTIFICATION_THEMES = {
  admin: {
    update_profile_by_admin: 'profileUpdatedByAdmin',
    update_profile_by_admin_v2: 'profileUpdatedByAdminV2',
    add_user_to_service_providers_for_user: 'serviceAssigned',
    remove_user_from_service_providers_for_user: 'serviceUnassigned',
    add_user_to_service_providers_for_admin: 'serviceAssignedToTeamMember',
    remove_user_from_service_providers_for_admin: 'serviceUnassignedFromTeemMember',
    create_service: 'serviceCreated',
    update_assigned_service: 'updateAssignedService',
    activate_service: 'serviceActivated',
    deactivate_service: 'serviceDeactivated',
    delete_service: 'serviceDeleted',
    activate_assigned_service: 'myServiceActivated',
    deactivate_assigned_service: 'myServiceDeactivated',
    delete_assigned_service: 'myServiceDeleted',
    update_profile_for_user: 'myProfileUpdated',
    update_service: 'serviceUpdated',
    overdue_payment: 'overduePayment',
    capacity_reached: 'capacityReached',
    capacity_was_auto_upgraded: 'capacityWasAutoUpgraded',
  },
  appointments: {
    new_booking_for_admin: 'appointmentForAdminCreated',
    new_booking_for_provider: 'appointmentForProviderCreated',
    user_cancel_booking: 'userCancelBooking',
    my_appointment_has_been_cancelled: 'myAppointmentHasBeenCancelled',
    my_appointment_has_been_cancelled_v2: 'myAppointmentHasBeenCancelledV2',
    client_cancel_booking_for_admin: 'appointmentForAdminCancelled',
    client_create_reschedule_for_user: 'clientCreateRescheduleForUser',
    client_create_reschedule_for_user_v2: 'clientCreateRescheduleForUserV2',
    client_create_reschedule_for_admin: 'appointmentForAdminRescheduled',
    client_create_reschedule_for_admin_v2: 'appointmentForAdminRescheduledV2',
    client_submits_intake_form: 'clientSubmitsIntakeForm',
    update_recurring_availabilities: 'recurringAvailabilitiesUpdated',
    update_date_availabilities: 'dateAvailabilitiesUpdated',
    update_recurring_availabilities_by_admin: 'recurringAvailabilitiesByAdminUpdated',
    update_recurring_availabilities_by_admin_v2: 'recurringAvailabilitiesByAdminUpdatedV2',
    update_date_availabilities_by_admin: 'dateAvailabilitiesByAdminUpdated',
    update_date_availabilities_by_admin_v2: 'dateAvailabilitiesByAdminUpdatedV2',
    admin_updated_my_recurring_availabilities: 'adminUpdatedMyRecurringAvailability',
    admin_updated_my_date_availabilities: 'adminUpdatedMyDateAvailability',
    appointment_in_a_day: 'appointmentInADay',
    appointment_in_30_mins: 'appointmentIn30Minutes',
    appointment_has_started: 'appointmentHasStarted',
    my_appointment_has_been_reviewed: 'myAppointmentHasBeenReviewed',
    i_was_assigned_to_new_appointment: 'iWasAssignedToNewAppointment',
    i_was_assigned_to_new_appointment_v2: 'iWasAssignedToNewAppointmentV2',
    my_appointment_has_been_confirmed_by_client: 'myAppointmentHasBeenConfirmedByClient',
    my_appointment_has_been_confirmed_by_client_v2: 'myAppointmentHasBeenConfirmedByClientV2',
    my_appointment_has_been_declined_by_client: 'myAppointmentHasBeenDeclinedByClient',
    my_appointment_has_been_declined_by_client_v2: 'myAppointmentHasBeenDeclinedByClientV2',
    my_appointment_has_been_updated: 'myAppointmentHasBeenUpdated',
    my_appointment_has_been_updated_v2: 'myAppointmentHasBeenUpdatedV2',
    my_appointment_has_been_rescheduled: 'myAppointmentHasBeenRescheduled',
    my_appointment_has_been_rescheduled_v2: 'myAppointmentHasBeenRescheduledV2',
    my_recurring_appointment_has_been_cancelled: 'myRecurringAppointmentHasBeenCancelled',
    my_recurring_appointment_has_been_cancelled_v2: 'myRecurringAppointmentHasBeenCancelledV2',
    my_recurring_appointments_has_been_declined_by_client: 'myRecurringAppointmentsHasBeenDeclinedByClient',
    my_recurring_appointments_has_been_declined_by_client_v2: 'myRecurringAppointmentsHasBeenDeclinedByClientV2',
    i_was_assigned_to_recurring_appointments: 'iWasAssignedToRecurringAppointments',
    i_was_assigned_to_recurring_appointments_v2: 'iWasAssignedToRecurringAppointmentsV2',
    my_recurring_appointments_has_been_rescheduled: 'myRecurringAppointmentsHasBeenRescheduled',
    my_form_is_declined: 'myFormIsDeclined',
    when_appointment_start: 'whenAppointmentStart',
    review_forms_after_appointment: 'reviewFormsAfterAppointment',
    unfilled_forms_reminder: 'unfilledFormsReminder',
    receive_form_after_client_signed: 'receiveFormAfterClientSigned',
    receive_a_form_to_fill_out: 'receiveAFormToFillOut',
  },
  chat: {
    i_have_new_message: 'iHaveNewMessage',
    new_document_received: 'newDocumentReceived',
  },
  system: {
    active_video_room: 'activeVideoRoom',
    video_is_ready: 'videoIsReady',
    video_is_ready_v2: 'videoIsReadyV2',
    my_google_calendar_has_got_disconnected: 'myGoogleCalendarHasGotDisconnected',
    expire_trial_tomorrow: 'expireTrialTomorrow',
    expire_trial_in_three_days: 'expireTrialInThreeDays',
    expire_trial_in_seven_days: 'expireTrialInSevenDays',
    my_outlook_calendar_has_got_disconnected: 'myOutlookCalendarHasGotDisconnected',
    my_apple_calendar_has_got_disconnected: 'myAppleCalendarHasGotDisconnected',
    subscription_to_free_on_date_expert: 'subscriptionToFreeOnDateExpert',
    subscription_to_free_on_date_owner: 'subscriptionToFreeOnDateOwner',
    ending_annual_subscription: 'endingAnnualSubscription',
    real_deleting_files_in_one_day: 'realDeletingFilesInOneDay',
    real_deleting_files_in_seven_days: 'realDeletingFilesInSevenDays',
    i_was_assigned_to_client: 'iWasAssignedToClient',
    default_video_call_provider_changed: 'defaultVideoCallProviderChanged',
    default_video_call_provider_changed_to_teams: 'defaultVideoCallProviderChangedToTeams',
    text_document_is_voided: 'textDocumentIsVoided',
    text_document_is_deleted: 'textDocumentIsDeleted',
    text_document_signature_postponed: 'textDocumentSignaturePostponed',
    document_sender_signature_is_not_required: 'documentSenderSignatureIsNotRequired',
    text_document_is_completed: 'textDocumentIsCompleted',
    text_document_is_declined: 'textDocumentIsDeclined',
    text_document_received_for_sign: 'textDocumentReceivedForSign',
    waitlist_request_was_destroyed_by_user_profile: 'waitlistRequestWasDestroyedDyUserProfile',
    waitlist_request_was_destroyed_by_client_profile: 'waitlistRequestWasDestroyedByClientProfile',
    waitlist_request_updated: 'waitlistRequestUpdated',
    waitlist_request_added: 'waitlistRequestAdded',
  },
  team: {
    invite_sign_up_for_user: 'invitationAccepted',
    invite_sign_up_for_admin: 'teamMemberJoined',
    invite_sign_up_for_admin_v2: 'teamMemberJoinedV2',
    update_role_for_admin: 'teamMemberRoleChanged',
    update_role_for_admin_v2: 'teamMemberRoleChangedV2',
    user_deactivation: 'teamMemberDeactivated',
    user_deactivation_v2: 'teamMemberDeactivatedV2',
    user_activation: 'teamMemberActivated',
    user_activation_v2: 'teamMemberActivatedV2',
    user_destroy: 'teamMemberRemoved',
    user_destroy_v2: 'teamMemberRemovedV2',
    update_profile: 'teamMemberProfileUpdated',
    update_role_for_user: 'myRoleChanged',
    ownership_transfer: 'ownershipTransferred',
    team_member_has_appointment_reviewed: 'teamMemberHasAppointmentReviewed',
    i_have_a_new_shared_file: 'iHaveANewSharedFile',
    transfer_files_ownership: 'transferFilesOwnership',
  },
  tasks: {
    provider_assigned_task_to_me: 'providerAssignedTaskToMe',
    my_task_has_a_due_date_today: 'myTaskHasADueDateToday',
  },
}
