import isPresent from 'utils/isPresent'

// eslint-disable-next-line import/prefer-default-export
export function requiredWith(fieldName, error = { id: 'yup.mixed.required' }) {
  return this.test(
    'requiredWith',
    error,
    (value, context) => !isPresent(context.options.parent[fieldName]) || isPresent(value),
  )
}
