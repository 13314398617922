import yup from 'lib/yupLocalised'
import { MAX_CUSTOM_FIELD_NAME_LENGTH, MAX_OPTION_NAME_LENGTH } from 'lib/constants/customField'
import { hasOptions } from 'utils/customField'

export default yup.object().shape({
  name: yup.string().required().max(MAX_CUSTOM_FIELD_NAME_LENGTH),
  fieldType: yup.string().required(),
  fieldOptions: yup.array().when('fieldType', {
    is: hasOptions,
    then: () =>
      yup.array().of(
        yup.object().shape({
          name: yup.string().max(MAX_OPTION_NAME_LENGTH),
        }),
      ),
    otherwise: () => yup.array(),
  }),
})
