import PropTypes from 'prop-types'

import Spinner from 'views/shared/icons/Spinner'
import NotFound from '../NotFound'

const NotFoundContent = ({ isLoading }) => (isLoading !== false ? <Spinner /> : <NotFound />)

NotFoundContent.defaultProps = {
  isLoading: undefined,
}

NotFoundContent.propTypes = {
  isLoading: PropTypes.bool,
}

export default NotFoundContent
