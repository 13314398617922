import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import removeEmployeeCookies from 'utils/removeEmployeeCookies'
import { loginRoute } from 'lib/routes'
import { workspacesHttpClient } from 'lib/httpClient'
import { RESET_PASSWORD_CONFIRM } from 'state/concepts/employee/types'
import { usersResetPasswordRoute } from 'lib/apiRoutes'
import { showNotification } from 'state/notifications/actions'
import { employeeLogoutSuccess } from 'state/concepts/session/actions'
import redirect from 'utils/redirect'

const resetPasswordConfirm = createLogic({
  type: RESET_PASSWORD_CONFIRM,
  latest: true,

  async process({ action: { values, form, token, workspaceCode, workspaceId } }, dispatch, done) {
    try {
      const params = {
        ...values,
        password_confirmation: values.password,
        token,
      }

      await workspacesHttpClient.put(usersResetPasswordRoute, params)

      removeEmployeeCookies({ path: `/${workspaceCode}` })
      dispatch(showNotification({ messageObject: { id: 'notifications.resetPasswordSuccess' } }))

      await redirect({ href: loginRoute })

      dispatch(employeeLogoutSuccess(workspaceId))
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }
    done()
  },
})

export default resetPasswordConfirm
