import { camelCase } from 'lodash'
import { DateTime } from 'luxon'
import { compose, filter, groupBy, identity, path, pluck, prop, propOr, sortBy, split, take, trim } from 'ramda'
import build from 'redux-object'
import { v4 as uuidv4 } from 'uuid'

import { MAX_FILE_SIZE } from 'lib/constants'
import {
  ATTACHMENT_TYPES,
  CHAT_STATUS,
  CHAT_TYPES,
  FORBIDDEN_FILE_EXTENSIONS,
  MAX_CHAT_NAME_LENGTH,
  MAX_CHAT_UNREAD_MESSAGES,
  SYSTEM_CHAT_USER,
} from 'lib/constants/chat'
import isPresent from 'utils/isPresent'
import profileName from 'utils/profileName'
import updateDataHelper from 'utils/updateDataHelper'
import { isDaysEquals, isWeeksEquals } from './dateTime'

export const groupMessagesByDate = compose(
  groupBy(({ createdAt }) => DateTime.fromISO(createdAt).toISODate()),
  sortBy(compose(DateTime.fromISO, prop('createdAt'))),
  filter(identity),
)

export const splitMessage = compose(split('\n'), trim)

export const isMessageCreated = ({ editedAt, destroyed }) => !editedAt && !destroyed
export const isMessageEdited = ({ editedAt }) => !!editedAt
export const isMessageDestroyed = ({ destroyed }) => !!destroyed
export const isSystemMessage = meta => isPresent(meta?.type)

export const isChatActive = ({ status }) => status === CHAT_STATUS.active
export const isChatDirect = ({ chatType }) => chatType === CHAT_TYPES.direct
export const isChatGroup = ({ chatType }) => chatType === CHAT_TYPES.room
export const isChatDeletable = ({ chatType }, isChatOwner, isClient) =>
  chatType === CHAT_TYPES.room ? isChatOwner : !isClient

export const chatUser = message => message.chatUser || SYSTEM_CHAT_USER

export const isUnreadMessage = (message, currentChatUserId, readCursor) =>
  message.chatUser?.id !== currentChatUserId?.toString() &&
  isPresent(readCursor) &&
  DateTime.fromISO(prop('createdAt', message)) > DateTime.fromISO(readCursor)

export const isCurrentUsersMessage = (message, currentUser) => {
  const messageChatUserId = path(['chatUser', 'id'], message)
  const currentChatUserId = prop('chatUserId', currentUser).toString()

  return messageChatUserId === currentChatUserId
}

export const formatTime = time => {
  const date = DateTime.fromISO(time)

  if (isDaysEquals(date, DateTime.local())) {
    return date.toFormat('t')
  }

  if (isWeeksEquals(date, DateTime.local())) {
    return date.toFormat('EEE')
  }

  if (date.hasSame(DateTime.local(), 'year')) {
    return date.toFormat('MMM d')
  }

  return date.toFormat('MMM d, yyyy')
}

export const unreadMessagesCounter = messages =>
  messages > MAX_CHAT_UNREAD_MESSAGES ? `${MAX_CHAT_UNREAD_MESSAGES}+` : messages

export const leaveChatCommand = id => ({
  command: 'message',
  channel: 'ChatChannel',
  action: 'leave',
  data: { id },
})

export const joinChatCommand = id => ({
  command: 'message',
  channel: 'ChatChannel',
  action: 'join',
  data: { id },
})

export const workspacifyChatUser = (isEmployee, messagesResponse) => {
  const chatUsers = build(messagesResponse, 'chatUser')

  if (!isPresent(chatUsers)) {
    return messagesResponse
  }

  const namePrefix = isEmployee ? 'workspace' : ''
  const updatedChatUser = updateDataHelper(messagesResponse, 'chatUser', pluck('id', chatUsers), id => {
    const { attributes } = messagesResponse.chatUser[id]

    return {
      attributes: {
        firstName: propOr(attributes.firstName, camelCase(`${namePrefix}FirstName`), attributes),
        lastName: propOr(attributes.lastName, camelCase(`${namePrefix}LastName`), attributes),
      },
    }
  })

  return { ...messagesResponse, ...updatedChatUser }
}

export const messagePerformer = ({ metaPerformer, meta }) => propOr(profileName(metaPerformer), 'performer', meta)
export const messageMember = ({ metaMember, meta }) => propOr(profileName(metaMember), 'member', meta)

export const formatChatName = users =>
  users
    .reduce((prevVal, { firstName, lastName }) => {
      const fullName = profileName({ firstName, lastName })

      return prevVal ? `${prevVal}, ${fullName}` : fullName
    }, '')
    .slice(0, MAX_CHAT_NAME_LENGTH)

export const allowedFilesBySize = files => Array.from(files).filter(file => file.size <= MAX_FILE_SIZE)

export const allowedFilesByExtension = files =>
  Array.from(files).filter(file => !FORBIDDEN_FILE_EXTENSIONS.includes(file.name.split('.').pop()))

export const convertFilesToAttachments = (files, size) =>
  take(size)(files).map(file => ({
    id: uuidv4(),
    type: ATTACHMENT_TYPES.file,
    blobUrl: URL.createObjectURL(file),
    file,
  }))

export const convertWebmBlobUrlToAttachment = blobUrl => ({
  id: uuidv4(),
  type: ATTACHMENT_TYPES.voiceMessage,
  blobUrl,
  file: {
    type: 'audio/webm',
  },
})

export const generateVoiceMessageName = intl => {
  const name = intl.formatMessage(
    { id: 'chat.voiceMessage.name' },
    { date: DateTime.now().toISODate(), time: DateTime.now().toFormat('hh:mm:ss a') },
  )

  return `${name}.weba`
}

export const getBase64FromUrl = async url => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}
