import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { DECLINE_BOOKING } from 'state/concepts/booking/types'
import { declineBookingEndpoint } from 'state/concepts/booking/endpoints'
import { hideModal } from 'state/modal/actions'
import { showNotification } from 'state/notifications/actions'
import {
  decrementPendingBookingsCount,
  removeClientAccountBookings,
} from 'state/concepts/clientAccountBookings/actions'

const declineBookingOperation = createLogic({
  type: DECLINE_BOOKING,
  latest: true,

  async process({ httpClient, action: { uniqCode, removeWaitlistRequest } }, dispatch, done) {
    const { endpoint, url } = declineBookingEndpoint(uniqCode)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.post(url, { remove_waitlist_request: removeWaitlistRequest })
      const response = normalize(data)

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(removeClientAccountBookings([data.data.id]))
      dispatch(decrementPendingBookingsCount())
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: removeWaitlistRequest ? 'notifications.waitlistBookingWasDeclined' : 'notifications.bookingWasDeclined',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default declineBookingOperation
