import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import redirect from 'utils/redirect'
import { clientLinkExpiredRoute } from 'lib/routes'
import requestErrorHandler from 'lib/requestErrorHandler'
import { GET_PAYMENT_CHECKOUT } from 'state/concepts/payment/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setPaymentId } from 'state/concepts/payment/actions'
import { getPaymentCheckoutEndpoint } from 'state/concepts/payment/endpoints'

const getPaymentCheckoutOperation = createLogic({
  type: GET_PAYMENT_CHECKOUT,
  latest: true,

  async process({ action: { paymentId, response }, httpClient }, dispatch, done) {
    const { url, endpoint } = getPaymentCheckoutEndpoint(paymentId)

    const params = {
      include: ['workspace', 'booking', 'recurring_payment', 'recurring_transactions'],
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })

      dispatch(setPaymentId(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
      redirect({ href: clientLinkExpiredRoute, response })
    }
    done()
  },
})

export default getPaymentCheckoutOperation
