import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { Field, Formik } from 'formik'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import Cross from 'views/shared/icons/Cross'
import InputWithCounterField from 'views/shared/InputWithCounterField'
import { MAX_INPUT_CONTENT_LENGTH } from 'lib/constants'
import createFolderSchema from 'lib/yupLocalised/schemas/createFolderSchema'
import { createFolder as createFolderAction } from 'state/concepts/myBusinessFiles/actions'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import {
  companyBusinessFilesMyFilesFolderRoute,
  companyBusinessFilesMyFilesRoute,
  companyBusinessFilesShareFolderRoute,
} from 'lib/routes'

const CreateFolderModal = ({ onClose }) => {
  const intl = useIntl()
  const { route } = useRouter()
  const redirectToRoute = route.includes(companyBusinessFilesMyFilesRoute)
    ? companyBusinessFilesMyFilesFolderRoute
    : companyBusinessFilesShareFolderRoute
  const createFolder = useFormSubmit(createFolderAction)

  return (
    <Modal className="main-modal main-modal--md" onClose={onClose}>
      <div className="main-modal__container">
        <Formik
          initialValues={{
            name: intl.formatMessage({ id: 'myBusinessFiles.createFolder.modal.defaultValue' }),
            redirectToRoute,
          }}
          validationSchema={createFolderSchema}
          onSubmit={createFolder}
        >
          <>
            <div className="main-modal__header">
              <h2 className="main-modal__title">
                <FormattedMessage id="myBusinessFiles.createFolder.modal.title" />
              </h2>
              <button type="button" onClick={onClose} className="main-modal__close">
                <Cross dataCy="cross-icon" />
              </button>
            </div>
            <div className="main-modal__body">
              <Field
                name="name"
                id="name"
                component={InputWithCounterField}
                className="main-input--input-top-count"
                label={{
                  id: 'myBusinessFiles.createFolder.modal.label',
                }}
                maxChars={MAX_INPUT_CONTENT_LENGTH}
                icon="alert"
              />
            </div>
            <div className="main-modal__footer">
              <div className="d-flex justify-content-end">
                <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
                <SubmitFormButton text={{ id: 'shared.create' }} allowPristine />
              </div>
            </div>
          </>
        </Formik>
      </div>
    </Modal>
  )
}

CreateFolderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CreateFolderModal
