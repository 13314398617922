import normalize from 'json-api-normalizer'
import { pluck } from 'ramda'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setMyFileIds, addMyFileIds, setMyFilesBlankState } from 'state/concepts/clientAccountDocuments/actions'
import { fetchMyFilesEndpoint } from 'state/concepts/clientAccountDocuments/endpoints'
import {
  clientMyFilesFiltersSelector,
  myFilesPaginationSelector,
} from 'state/concepts/clientAccountDocuments/selectors'
import { FETCH_MY_FILES } from 'state/concepts/clientAccountDocuments/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import isBlankState from 'utils/isBlankState'
import isPresent from 'utils/isPresent'
import { PAGINATION_ITEMS_PER_PAGE } from 'lib/constants/tableData'

const fetchMyFilesOperation = createLogic({
  type: FETCH_MY_FILES,
  latest: true,

  async process({ httpClient, getState, action: { options = {} } }, dispatch, done) {
    const { endpoint, url } = fetchMyFilesEndpoint
    const state = getState()
    const filter = clientMyFilesFiltersSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const customBaseUrl = buildCustomBaseUrl(['workspaces', workspaceCode])
    const page = isPresent(options.page)
      ? { number: options.page, size: options.size || PAGINATION_ITEMS_PER_PAGE }
      : myFilesPaginationSelector(state)

    const config = {
      ...customBaseUrl,
      params: {
        page,
        filter: { ...filter },
      },
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, config)
      const response = normalize(data, { endpoint })

      dispatch(setMyFilesBlankState(isBlankState({ params: config.params.filter, data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      const myFileIds = pluck('id', data.data)
      dispatch(options?.append ? addMyFileIds(myFileIds) : setMyFileIds(myFileIds))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchMyFilesOperation
