import { propEq, find, findIndex } from 'ramda'

export const onCreate = sortable => id => {
  const entity = find(propEq(id, 'id'))(sortable)
  const newPriority = entity.priority + 1

  return {
    attributes: { priority: newPriority },
  }
}

export const onUpdate = (sorted, unsorted) => id => {
  const entityIndex = findIndex(propEq(id, 'id'), sorted)
  return {
    attributes: { priority: unsorted[entityIndex].priority },
  }
}

export const onDelete = sortable => id => {
  const entity = find(propEq(id, 'id'))(sortable)
  const newPriority = entity.priority - 1

  return {
    attributes: { priority: newPriority },
  }
}
