import normalize from 'json-api-normalizer'
import { path } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import { usersRegistrationRoute } from 'lib/apiRoutes'
import { COOKIES_DOMAIN } from 'lib/constants'
import { dataLayerPush, GTAG_SIGN_UP_EVENT } from 'lib/gtag'
import { workspacesHttpClient } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { SIGNUP } from 'state/concepts/employeeSignUp/types'
import { appliedCodesSelector } from 'state/concepts/promocodes/selectors'
import { addRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { employeeLoginSuccess } from 'state/concepts/session/actions'
import { dataApiSuccess } from 'state/data/actions'
import { trackSignUpCompleted } from 'utils/mixpanel'
import setEmployeeCookies from 'utils/setEmployeeCookies'
import { setPassword } from 'state/concepts/login/actions'

const signUpOperation = createLogic({
  type: SIGNUP,
  latest: true,

  async process({ action: { values, form }, getState, httpClient }, dispatch, done) {
    try {
      const { email, password, workspaceUrl, workspaceName, captcha } = values
      const state = getState()
      const codes = appliedCodesSelector(state)
      const params = {
        email,
        password,
        captcha,
        workspace_url: workspaceUrl,
        workspace_name: workspaceName,
        include: 'workspace.workspace-two-factor-setting',
        codes,
      }

      const { data } = await workspacesHttpClient.post(usersRegistrationRoute, params)
      const response = normalize(data)
      const currentEmployee = build(response, 'userProfile')[0]
      const uniqCode = path(['workspace', 'uniqCode'], currentEmployee)
      const tokens = data.meta.jwt

      trackSignUpCompleted(currentEmployee)

      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers.Authorization = tokens.access
      setEmployeeCookies(currentEmployee, tokens, {
        path: `/${uniqCode}`,
        domain: COOKIES_DOMAIN,
      })

      dispatch(employeeLoginSuccess(currentEmployee.id, !currentEmployee?.confirmedAt))
      dispatch(addRecentWorkspace(currentEmployee, tokens))
      dispatch(dataApiSuccess({ response }))
      dispatch(setPassword(values.password))

      dataLayerPush({ event: GTAG_SIGN_UP_EVENT })
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default signUpOperation
