import { useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'

const useWavesurfer = (containerRef, options) => {
  const wavesurferRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) return

    const wavesurfer = WaveSurfer.create({ ...options, container: containerRef.current })

    wavesurferRef.current = wavesurfer

    // eslint-disable-next-line consistent-return
    return () => wavesurfer.destroy()
  }, [containerRef, options])

  return wavesurferRef.current
}

export default useWavesurfer
