import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { nanoid } from 'nanoid'

import ArrowRight from 'views/shared/icons/ArrowRight/component'
import DropdownMenuComponent from './component'
import DropdownItem from '../DropdownItem'
import FormattedOrRawMessage from '../FormattedOrRawMessage'

class DropdownMenu extends React.Component {
  state = {
    visible: false,
  }

  static propTypes = {
    onMenuVisibleChange: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  }

  static defaultProps = {
    onMenuVisibleChange: () => {},
  }

  componentDidMount() {
    window.addEventListener('customScrollClick', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('customScrollClick', this.handleClickOutside)
  }

  handleClickOutside = () => this.setState({ visible: false })

  handleVisibleChange = visible => {
    const { onMenuVisibleChange } = this.props

    onMenuVisibleChange(visible)
    this.setState({ visible })
  }

  makeHandleClose = onClick => event => {
    event.stopPropagation()
    onClick?.(event)
    this.setState({ visible: false })
  }

  get items() {
    const { items } = this.props

    return items
      .filter(({ isHidden }) => !isHidden)
      .map(({ itemProps = {}, type, ...rest }) => {
        const { nestedItems = [] } = itemProps
        const visibleNestedItems = nestedItems.filter(({ isHidden }) => !isHidden)

        if (visibleNestedItems.length) {
          return this.createNestedItem(rest, itemProps, visibleNestedItems)
        }

        if (type) {
          return { key: nanoid(), type }
        }

        return this.createSimpleItem(rest, itemProps)
      })
  }

  createNestedItem = (rest, itemProps, visibleNestedItems) => ({
    ...omit(['isHidden'], rest),
    key: nanoid(),
    label: <FormattedOrRawMessage message={itemProps.text} />,
    expandIcon: <ArrowRight size={8} className="ml-auto icon" />,
    children: visibleNestedItems.map(({ itemProps: childItemProps, ...childRest }) =>
      this.createSimpleItem(childRest, childItemProps),
    ),
  })

  createSimpleItem = (rest, itemProps) => ({
    ...omit(['isHidden'], rest),
    key: nanoid(),
    className: itemProps.listItemClassName,
    label: <DropdownItem {...itemProps} onClick={this.makeHandleClose(itemProps?.onClick)} />,
  })

  render = () => (
    <DropdownMenuComponent
      {...this.state}
      {...this.props}
      onVisibleChange={this.handleVisibleChange}
      items={this.items}
    />
  )
}

export default DropdownMenu
