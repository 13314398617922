import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FETCH_AGREEMENT } from 'state/concepts/agreement/types'
import { setAgreementId } from 'state/concepts/agreement/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { fetchAgreementEndpoint } from 'state/concepts/agreement/endpoints'

const fetchAgreementOperation = createLogic({
  type: FETCH_AGREEMENT,
  latest: true,

  async process({ action: { id }, httpClient }, dispatch, done) {
    const { url, endpoint } = fetchAgreementEndpoint(id)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setAgreementId(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchAgreementOperation
