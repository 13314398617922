import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withFormik } from 'formik'

import validationSchema from 'lib/yupLocalised/schemas/deleteLocation'
import { handleSubmit } from 'utils/form/handleSubmit'
import { deleteLocation } from 'state/concepts/locations/actions'
import { isLocationDeletionAndActivationDisabledSelector, locationSelector } from 'state/concepts/locations/selectors'
import { ONLINE_LOCATION_OPTION, DELETE_LOCATION_ACTION_VALUE, LOCATION_STATUS } from 'lib/constants/locations'
import { currentWorkspaceLocationsCountSelector } from 'state/concepts/session/selectors'
import DeleteLocationModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class DeleteLocationModal extends React.Component {
  static propTypes = {
    location: PropTypes.shape({ status: PropTypes.string }).isRequired,
  }

  get isActive() {
    const {
      location: { status },
    } = this.props

    return status === LOCATION_STATUS.active
  }

  render = () => <DeleteLocationModalComponent isActive={this.isActive} {...this.props} />
}

const mapStateToProps = (state, props) => ({
  totalCount: currentWorkspaceLocationsCountSelector(state),
  isLocationDeletionAndActivationDisabled: isLocationDeletionAndActivationDisabledSelector(state),
  location: locationSelector(state, props.locationId),
})

const mapDispatchToProps = {
  onSubmit: deleteLocation,
}

export { DeleteLocationModal as DeleteLocationModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ locationId }) => ({
      action: DELETE_LOCATION_ACTION_VALUE.remove,
      locationId: ONLINE_LOCATION_OPTION.value,
      id: locationId,
    }),
    validationSchema,
    handleSubmit,
  }),
)(DeleteLocationModal)
