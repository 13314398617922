import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { usersSessionResendVerificationCodeRoute } from 'lib/apiRoutes'
import { buildCustomBaseUrl } from 'lib/httpClient'
import { RESEND_OTP_CODE_ON_LOGIN } from 'state/concepts/session/types'
import { employeeLoginMetaSelector } from '../selectors'

const resendOtpCodeOnLoginOperation = createLogic({
  type: RESEND_OTP_CODE_ON_LOGIN,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { jwt } = employeeLoginMetaSelector(getState())

    try {
      await httpClient.get(usersSessionResendVerificationCodeRoute, {
        headers: { Authorization: jwt.access },
        ...buildCustomBaseUrl(['workspaces']),
      })
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }
    done()
  },
})

export default resendOtpCodeOnLoginOperation
