import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { prepareQuestionAttributes, buildFormParams } from 'utils/intakeForms'
import redirect from 'utils/redirect'
import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { dataApiSuccess } from 'state/data/actions'
import { CREATE_INTAKE_FORM } from 'state/concepts/intakeForm/types'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { companySettingsIntakeFormsRoute } from 'lib/routes'
import { createIntakeFormEndpoint } from '../endpoints'

const createIntakeFormOperation = createLogic({
  type: CREATE_INTAKE_FORM,
  latest: true,

  async process({ action: { form, values, onError }, httpClient, getState }, dispatch, done) {
    const { url } = createIntakeFormEndpoint

    const questions = prepareQuestionAttributes(values.formQuestionsAttributes)
    if (!questions.length) {
      form.setStatus({ base: { id: 'company.forms.formQuestionsAttributes.error' } })
      form.setSubmitting(false)
      done()
      return
    }

    try {
      const { data } = await httpClient.post(url, buildFormParams(values, questions))
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      redirect({ href: companySettingsIntakeFormsRoute, workspace: currentWorkspaceCodeSelector(getState()) })
      dispatch(showNotification({ messageObject: { id: 'notifications.intakeFormCreated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
      onError?.()
    }

    done()
  },
})

export default createIntakeFormOperation
