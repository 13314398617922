import imgGoogle from 'assets/images/calendar/googl_calendar.svg'
import imgOutlook from 'assets/images/calendar/outlook.svg'
import imgApple from 'assets/images/calendar/iclaud.svg'

// eslint-disable-next-line import/prefer-default-export
export const calendarEventIconByType = {
  GoogleCalendarListItem: imgGoogle,
  OutlookCalendarListItem: imgOutlook,
  AppleCalendarListItem: imgApple,
}
