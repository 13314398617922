import React from 'react'
import PropTypes from 'prop-types'
import { trim } from 'ramda'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import SelectField from 'views/shared/SelectField'
import { searchLocations as searchLocationsAction } from 'state/concepts/locations/actions'
import { searchedLocationsSelector, selectedLocationsSelector } from 'state/concepts/locations/selectors'
import { loadingSelector } from 'state/data/selectors'
import { fetchLocationsEndpoint } from 'state/concepts/locations/endpoints'
import { LOCATION_STATUS } from 'lib/constants/locations'
import EmptyState from './EmptyState'
import Tooltip from '../Tooltip'

class LocationsSelectField extends React.Component {
  static propTypes = {
    searchLocations: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedLocations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
    notFoundContent: PropTypes.func,
    placeholder: PropTypes.shape(),
  }

  static defaultProps = {
    isLoading: undefined,
    placeholder: { id: 'placeholders.selectLocation' },
    notFoundContent: EmptyState,
  }

  state = {
    search: '',
    isOpen: false,
  }

  get locations() {
    const { locations, selectedLocations } = this.props
    const { isOpen } = this.state
    const currentLocations = isOpen ? locations : selectedLocations

    return currentLocations.map(location => ({
      key: location.id,
      value: location.id,
      label: (
        <Tooltip
          icon={
            location.status === LOCATION_STATUS.active ? (
              location.name
            ) : (
              <span className="disabled-option">
                <span className="disabled-option__text">
                  <FormattedMessage
                    id="createService.offlineLocations.deactivatedOption"
                    values={{ name: location.name }}
                  />
                </span>
              </span>
            )
          }
          align={{
            offset: [0, -10],
          }}
          overlayClassName="main-tooltip main-tooltip--no-pointer"
          placement="top"
        >
          {location.name}
        </Tooltip>
      ),
      content: (
        <div className="truncate-option">
          <p className="mb-0 truncate-text">{location.name}</p>
          <p className="in-blue-gray-300 text-caption mb-0 truncate-text">{location.addressLine}</p>
        </div>
      ),
    }))
  }

  searchWithDebounce = debounce(searchQuery => {
    const { searchLocations } = this.props

    searchLocations({ filter: { name: searchQuery }, resetResults: true })
  }, 250)

  handleSearch = value => {
    this.setState({
      search: value,
    })
    this.searchWithDebounce(trim(value))
  }

  handleDropdownVisibleChange = open => {
    const { searchLocations } = this.props

    if (open) {
      searchLocations({ resetResults: true })
    }

    this.setState({ isOpen: open })
  }

  render() {
    const { searchLocations, isLoading, notFoundContent: NotFoundContent } = this.props
    const { search, isOpen } = this.state

    return (
      <SelectField
        {...this.props}
        open={isOpen}
        type="text"
        mode="multiple"
        optionLabelProp="label"
        selectClassName="main-input__field main-input__field-tags dropdown-tags--blue"
        removeIconSize={12}
        filterOption={false}
        autoClearSearchValue={false}
        options={this.locations}
        onDropdownVisibleChange={this.handleDropdownVisibleChange}
        onSearch={this.handleSearch}
        loaderProps={{
          loadMoreAction: searchLocations,
          isLoading,
          endpoint: fetchLocationsEndpoint.endpoint,
          loaderProps: { bottomOffset: '-20px' },
          spinnerProps: { size: 18 },
          filter: { name: search },
        }}
        notFoundContent={<NotFoundContent search={search} isLoading={isLoading} />}
      />
    )
  }
}

const mapStateToProps = (state, { field: { value } }) => ({
  locations: searchedLocationsSelector(state),
  isLoading: loadingSelector(state, fetchLocationsEndpoint.endpoint),
  selectedLocations: selectedLocationsSelector(state, value),
})

const mapDispatchToProps = {
  searchLocations: searchLocationsAction,
}

export { LocationsSelectField as LocationsSelectFieldContainer }
export default connect(mapStateToProps, mapDispatchToProps)(LocationsSelectField)
