import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { DELETE_CHAT } from 'state/concepts/chat/types'
import { showNotification } from 'state/notifications/actions'
import { resetUploadToAttachmentLinks } from 'state/concepts/chat/actions'
import { deleteChatEndpoint } from '../endpoints'

const deleteChatOperation = createLogic({
  type: DELETE_CHAT,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { chatId } = action
    const { url } = deleteChatEndpoint(chatId)

    try {
      await httpClient.delete(url)

      dispatch(resetUploadToAttachmentLinks())
      dispatch(showNotification({ messageObject: { id: 'notifications.chatHasBeenDeleted' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch })
    }

    done()
  },
})

export default deleteChatOperation
