import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const connectGoogleCalendar = (authenticationCode, state) => ({
  type: types.CONNECT_GOOGLE_CALENDAR,
  authenticationCode,
  state,
})

export const reconnectGoogleCalendar = (connection, authenticationCode, state) => ({
  type: types.RECONNECT_GOOGLE_CALENDAR,
  connection,
  authenticationCode,
  state,
})

export const reconnectOutlookCalendar = (connection, authenticationCode, state) => ({
  type: types.RECONNECT_OUTLOOK_CALENDAR,
  connection,
  authenticationCode,
  state,
})

export const reconnectAppleCalendar = makeFormSubmitAction(types.RECONNECT_APPLE_CALENDAR)

export const fetchCalendarConnections = include => ({
  type: types.FETCH_CALENDAR_CONNECTIONS,
  include,
})

export const setGoogleCalendarConnectionIds = ids => ({
  type: types.SET_GOOGLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const addGoogleCalendarConnectionIds = ids => ({
  type: types.ADD_GOOGLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const addAppleCalendarConnectionIds = ids => ({
  type: types.ADD_APPLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const removeGoogleCalendarConnectionIds = ids => ({
  type: types.REMOVE_GOOGLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const updateGoogleCalendarConnection = ({ calendar }) => ({
  type: types.UPDATE_GOOGLE_CALENDAR_CONNECTION,
  calendar,
})

export const updateOutlookCalendarConnection = ({ calendar }) => ({
  type: types.UPDATE_OUTLOOK_CALENDAR_CONNECTION,
  calendar,
})

export const updateCalendarConnections = makeFormSubmitAction(types.UPDATE_CALENDAR_CONNECTIONS)

export const connectAppleCalendar = makeFormSubmitAction(types.CONNECT_APPLE_CALENDAR)

export const disconnectGoogleCalendar = id => ({
  type: types.DISCONNECT_GOOGLE_CALENDAR,
  id,
})

export const disconnectOutlookCalendar = id => ({
  type: types.DISCONNECT_OUTLOOK_CALENDAR,
  id,
})

export const getGoogleCalendarConnectLink = id => ({
  type: types.GET_GOOGLE_CALENDAR_CONNECT_LINK,
  id,
})

export const setGoogleCalendarConnectLink = (id, link) => ({
  type: types.SET_GOOGLE_CALENDAR_CONNECT_LINK,
  id,
  link,
})

export const removeGoogleCalendarConnectLink = id => ({
  type: types.REMOVE_GOOGLE_CALENDAR_CONNECT_LINK,
  id,
})

export const receiveGoogleCalendar = data => ({
  type: types.RECEIVE_GOOGLE_CALENDAR,
  data,
})

export const addOutlookCalendarConnectionIds = ids => ({
  type: types.ADD_OUTLOOK_CALENDAR_CONNECTION_IDS,
  ids,
})

export const connectOutlookCalendar = (authenticationCode, state) => ({
  type: types.CONNECT_OUTLOOK_CALENDAR,
  authenticationCode,
  state,
})

export const setOutlookCalendarConnectLink = (id, link) => ({
  type: types.SET_OUTLOOK_CALENDAR_CONNECT_LINK,
  id,
  link,
})

export const getOutlookCalendarConnectLink = id => ({
  type: types.GET_OUTLOOK_CALENDAR_CONNECT_LINK,
  id,
})

export const removeOutlookCalendarConnectionIds = ids => ({
  type: types.REMOVE_OUTLOOK_CALENDAR_CONNECTION_IDS,
  ids,
})

export const receiveOutlookCalendar = data => ({
  type: types.RECEIVE_OUTLOOK_CALENDAR,
  data,
})

export const removeOutlookCalendarConnectLink = id => ({
  type: types.REMOVE_OUTLOOK_CALENDAR_CONNECT_LINK,
  id,
})

export const setOutlookCalendarConnectionIds = ids => ({
  type: types.SET_OUTLOOK_CALENDAR_CONNECTION_IDS,
  ids,
})

export const setCalendarIntegrationId = id => ({
  type: types.SET_CALENDAR_INTEGRATION_ID,
  id,
})

export const updateSyncCalendar = ({ calendarSyncId, calendarSyncType, calendarIntegrationId }) => ({
  type: types.UPDATE_SYNC_CALENDAR,
  calendarSyncId,
  calendarSyncType,
  calendarIntegrationId,
})

export const setAppleCalendarConnectionIds = ids => ({
  type: types.SET_APPLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const onReceiveAppleCalendar = data => ({
  type: types.RECEIVE_APPLE_CALENDAR,
  data,
})

export const removeAppleCalendarConnectionIds = ids => ({
  type: types.REMOVE_APPLE_CALENDAR_CONNECTION_IDS,
  ids,
})

export const disconnectAppleCalendar = id => ({
  type: types.DISCONNECT_APPLE_CALENDAR,
  id,
})

export const updateAppleCalendarConnection = ({ calendar }) => ({
  type: types.UPDATE_APPLE_CALENDAR_CONNECTION,
  calendar,
})
