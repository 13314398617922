const clientAccountDocuments = {
  'clientForms.empty.title': 'No forms yet',
  'clientForms.empty.body': 'This is where you’ll see all forms shared with you.',
  'clientForms.requireActions': 'Require actions',
  'clientForms.completed': 'Completed',
  'clientForms.actions.fillOut': 'Fill out',
  'clientForms.actions.reviewAndSign': 'Review and sign',
  'clientForms.actions.view': 'View',
  'clientForms.serviceName': 'For {serviceName} _(appointment)',
  'clientForms.sendBy': 'Sent by {userName}',
  'clientForms.expiresOn': 'Expires on',

  'clientAgreements.empty.title': 'No _(service agreements) yet',
  'clientAgreements.empty.body': 'This is where you’ll see all _(service agreements) shared with you.',

  'clientDocuments.empty.alt': 'No documents yet',
  'clientDocuments.empty.title': 'No documents yet',
  'clientDocuments.empty.body': 'This is where you’ll see all documents shared with you.',
  'clientDocuments.search.placeholder': 'Search by name',
  'clientDocuments.search.noSearchResults': 'There are no results for your search request.',
  'clientDocuments.requireActions': 'Require actions',
  'clientDocuments.otherDocuments': 'Other documents',
  'clientDocuments.sharedBy': 'Shared by {userName}',
}

export default clientAccountDocuments
