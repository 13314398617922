import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CalendarCheck = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M208.666 6.445v101.111h-50.555C102.348 107.556 57 152.904 57 208.667v707.78c0 55.759 45.348 101.107 101.111 101.107h707.776c55.765 0 101.113-45.348 101.113-101.107v-707.78c0-55.763-45.348-101.111-101.113-101.111h-50.554V6.445H714.22v101.111H309.778V6.445H208.667zM158.11 208.668h707.776v101.111H158.11V208.668zm0 202.222h707.776l.103 505.559H158.11V410.89zm495.486 65.762L486.722 643.424l-91.039-90.939-70.698 70.701 161.738 161.835 237.569-237.67-70.695-70.698z" />
  </svg>
)

CalendarCheck.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'calendar-icon',
}

CalendarCheck.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CalendarCheck
