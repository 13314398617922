import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import parseToJSONAPIFormat from 'utils/parseToJsonApiFormat'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { UPDATE_NOTIFICATION_SETTINGS } from 'state/concepts/client/types'
import { updateNotificationSettingsEndpoint } from 'state/concepts/client/endpoints'

const updateNotificationSettingsOperation = createLogic({
  type: UPDATE_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ action: { form, values }, httpClient }, dispatch, done) {
    const { url } = updateNotificationSettingsEndpoint

    const params = parseToJSONAPIFormat(values)

    try {
      const { data } = await httpClient.put(url, params)

      const response = normalize(data)
      dispatch(dataApiSuccess({ response }))

      dispatch(
        showNotification({
          messageObject: { id: 'notifications.clientNotificationSettingsUpdated' },
        }),
      )
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateNotificationSettingsOperation
