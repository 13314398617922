import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

import Input from 'views/shared/Input'
import Search from 'views/shared/icons/Search'
import CircleCancel from '../icons/CircleCancel'

const SearchListComponent = ({
  onChange,
  onClear,
  onBlur,
  onFocus,
  value,
  className,
  placeholder,
  isFocused,
  size,
}) => (
  <div className={classNames('flex-grow-1 relative', className)}>
    <Input
      className="mb-0"
      placeholder={placeholder || { id: 'shared.search' }}
      size={size}
      kind="search"
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      dataCy="search-input-field"
    />

    {value && (
      <span role="button" onClick={onClear}>
        <CircleCancel
          className="main-page__search-icon main-page__search-icon--clear"
          dataCy="search-clear-button"
          size={12}
        />
      </span>
    )}
    <Search size={14} className={classNames('main-page__search-icon', { 'in-blue-600': isFocused })} />
  </div>
)

SearchListComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.shape(),
  size: PropTypes.string,
}

SearchListComponent.defaultProps = {
  className: '',
  placeholder: undefined,
  size: 'small',
}

export default SearchListComponent
