const guestAccountSettings = {
  'guestAccountSettings.general.deleteAccount.title': 'Delete your account',
  'guestAccountSettings.general.deleteAccount.prompt': 'Delete your account?',
  'guestAccountSettings.general.deleteContactAccount.prompt': 'Remove my access to {clientName}’s information?',
  'guestAccountSettings.general.deleteAccount.readCarefully':
    'Before you delete your account and personal data, please read this carefully:',
  'guestAccountSettings.general.deleteContactAccount.readCarefully':
    'Before you remove your access and information, related to this _(client) portal, please read this carefully:',
  'guestAccountSettings.general.deleteAccount.yourAccount': 'Your account',
  'guestAccountSettings.general.deleteContactAccount.yourData': 'Your personal data related to this _(client) portal',
  'guestAccountSettings.general.deleteAccount.lostAccount':
    'If you delete your {workspaceName} account, you’ll no longer be able to use {workspaceName} _(services), and your account and data will be lost.',
  'guestAccountSettings.general.deleteContactAccount.payments': 'Payments',
  'guestAccountSettings.general.deleteContactAccount.billingResponsibility':
    'The billing responsibility and previous payments will be immediately reassigned back to {clientName}.',
  'guestAccountSettings.general.deleteAccount.yourData': 'Your personal data',
  'guestAccountSettings.general.deleteAccount.lostData':
    'We will only keep your data for 7 days before it will be deleted forever. You can reactivate your account and restore all the data within that period.',
  'guestAccountSettings.general.deleteContactAccount.lostData':
    'We will only keep your data for 7 days before it will be deleted forever. You can reactivate your access and restore all the data within that period.',
  'guestAccountSettings.general.deleteAccount.yourAppointments': 'Your _(appointments)',
  'guestAccountSettings.general.deleteAccount.lostAppointments':
    'All your upcoming _(appointments) will be canceled automatically and you will get the refund if it’s possible according to the cancellation policy.',

  'guestAccountSettings.general.deleteAccount.info.confirmationRequired':
    'To keep your account safe, we’ll send a confirmation code to your email.',
  'guestAccountSettings.general.deleteAccount.confirmationButton': 'Get confirmation code',

  'guestAccountSettings.general.deleteAccount.temporaryCodeSent':
    'We just sent you a temporary confirmation code. Please check your inbox.',
  'guestAccountSettings.general.deleteAccount.incorrectCode': 'Incorrect code. Please try again.',
  'guestAccountSettings.general.deleteAccount.confirmationCodeLabel': 'Confirmation code',
  'guestAccountSettings.general.deleteAccount.confirmationCodePlaceholder': 'Enter confirmation code',
  'guestAccountSettings.general.deleteAccount.didntGetCode': 'Didn’t get the code? ',
  'guestAccountSettings.general.deleteAccount.resendLink': 'Resend',
  'guestAccountSettings.general.deleteAccount.deleteAccountButton': 'Delete account',
  'guestAccountSettings.general.deleteContactAccount.deleteAccountButton': 'Delete access',

  'guestAccountSettings.general.deleteAccount.success':
    'All your upcoming _(appointments) will be canceled automatically and you will get the refund if it’s possible according to the cancellation policy.',
  'guestAccountSettings.general.deleteContactAccount.success':
    'Your access is deactivated and scheduled to be revoked.',
}

export default guestAccountSettings
