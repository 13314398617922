import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Spinner from 'views/shared/icons/Spinner'

const NotFoundContent = ({ isLoading }) => {
  if (isLoading !== false) return <Spinner size={24} />

  return <FormattedMessage id="shared.noResultsFound" />
}

NotFoundContent.defaultProps = {
  isLoading: undefined,
}

NotFoundContent.propTypes = {
  isLoading: PropTypes.bool,
}

export default NotFoundContent
