import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Search = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M808.109 736.823 1024 952.714 952.714 1024 736.823 808.109c-77.626 62.11-176.07 99.257-283.139 99.257C203.251 907.366 0 704.117 0 453.684S203.251 0 453.684 0c250.433 0 453.682 203.251 453.682 453.684 0 107.069-37.148 205.514-99.257 283.139zM706.976 699.42c61.64-63.526 99.574-150.18 99.574-245.736 0-194.958-157.908-352.866-352.866-352.866S100.818 258.726 100.818 453.684 258.726 806.55 453.684 806.55c95.556 0 182.21-37.934 245.736-99.574l7.556-7.556z" />
  </svg>
)

Search.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Search.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Search
