/* eslint-disable expbox/custom-name-validation */
const filesPreview = {
  'filesPreview.moveToFolder': 'Move to folder',
  'filesPreview.moveToTrash': 'Move to trash',
  'filesPreview.shareWithTeam': 'Share with team',
  'filesPreview.share': 'Share',
  'filesPreview.withTeam': 'With team',
  'filesPreview.withClient': 'With client',
  'filesPreview.removeMyAccess': 'Remove my access',
  'filesPreview.restore': 'Restore',
  'filesPreview.deleteForever': 'Delete forever',
  'filesPreview.noPreviewAvailable': 'No preview available',
  'filesPreview.pdf.pageCounter': '{pageNumber} of {pageCount}',
}

export default filesPreview
