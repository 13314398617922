import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_CLIENT_IMPORT_ID,
  RESET_CLIENT_IMPORT_ID,
  SET_UPLOAD_PROGRESS,
  CANCEL_UPLOAD_FILE,
  SET_MATCH_FORM_STATE,
  SET_ERRORS_FILE_URL,
  RESET_ERRORS_FILE_URL,
  SET_IS_PROCESSING,
} from './types'

const clientImportId = (state = null, action) => {
  switch (action.type) {
    case SET_CLIENT_IMPORT_ID:
      return action.clientImportId
    case RESET_CLIENT_IMPORT_ID:
      return null
    default:
      return state
  }
}

const uploadProgress = (state = null, action) => {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return action.progress
    case CANCEL_UPLOAD_FILE:
      return null
    default:
      return state
  }
}

const errorsFileUrl = (state = null, action) => {
  switch (action.type) {
    case SET_ERRORS_FILE_URL:
      return action.url
    case RESET_ERRORS_FILE_URL:
      return null
    default:
      return state
  }
}

const matchFormState = (state = null, action) => {
  switch (action.type) {
    case SET_MATCH_FORM_STATE:
      return action.values
    case RESET_CLIENT_IMPORT_ID:
      return null
    default:
      return state
  }
}

const isProcessing = (state = false, action) => {
  switch (action.type) {
    case SET_IS_PROCESSING:
      return action.isProcessing
    case RESET_CLIENT_IMPORT_ID:
      return false
    default:
      return state
  }
}

export default combineReducers({
  clientImportId,
  uploadProgress,
  errorsFileUrl,
  matchFormState,
  isProcessing,
})
