import { DateTime, Interval } from 'luxon'
import {
  addIndex,
  any,
  equals,
  forEach,
  forEachObjIndexed,
  groupBy,
  identity,
  indexOf,
  intersection,
  invoker,
  isEmpty,
  keys,
  last,
  map,
  mapObjIndexed,
  mergeRight,
  omit,
  path,
  pick,
  pipe,
  pluck,
  reduce,
  splitEvery,
  times,
  toPairs,
  uniq,
} from 'ramda'

import { DEFAULT_AVAILABILITY } from 'lib/constants/calendar'
import { DAYS_PER_WEEK, SECONDS_PER_MINUTE } from 'lib/constants/timeUnits'
import { formattedIntervalFromSeconds } from 'utils/dateTime'

export const slotStartTime = (slotIndex, step) => parseInt(slotIndex, 10) * step * SECONDS_PER_MINUTE
export const slotEndTime = (slotIndex, step) => slotStartTime(parseInt(slotIndex, 10) + 1, step)
export const intervalDuration = (startIndex, endIndex, step) =>
  (parseInt(endIndex, 10) - parseInt(startIndex, 10) + 1) * step * SECONDS_PER_MINUTE

/**
 * Transforms object of slotIndexes with to array of arrays of 2 elements
 * with same locationIds - start and end of uninterrupted sequence of indexes
 * @example toUninterruptedIntervals({ 0:{...}, 1:{...}, 2:{...}, 5:{...}, 6:{...}, 9:{...} }) => [[0, 2], [5, 6], [9, 9]]
 */
export const toUninterruptedIntervals = slots => {
  const slotIndexes = keys(slots)

  if (isEmpty(slotIndexes)) {
    return []
  }

  const list = addIndex(reduce)(
    (acc, slotIndex, arrayIndex) => {
      const prevSlotIndex = slotIndexes[arrayIndex - 1]
      const prevSlot = slots[prevSlotIndex]

      // If it's first slot
      if (!prevSlot) {
        return acc
      }

      if (
        slotIndex - prevSlotIndex > 1 ||
        // If different locationIds in current and previous slots
        !equals(slots[slotIndex]?.locationIds || [], prevSlot?.locationIds || [])
      ) {
        acc.push(prevSlotIndex, slotIndex)
      }

      return acc
    },
    [slotIndexes[0]],
    slotIndexes,
  )

  return splitEvery(2, [...list, last(slotIndexes)])
}

// Removes labels from all slots for given day indexes in mapping
// return cleaned SHALLOW copy of mapping
export const clearLabelsByDays = (affectedDays, mapping) => {
  const filteredMapping = pick(affectedDays, mapping)
  const cleansed = map(slots => map(slot => omit(['label'], slot), slots), filteredMapping)

  return { ...mapping, ...cleansed }
}

// Adds interval labels to every first slot of interval (uninterrupted sequence of slots)
// Note: now displaying time of grid measures (multiplied by step),
// maybe should be updated to display effective time if available (interval id present)
export const addIntervalLabels = (mapping, step) => {
  // SHALLOW copy of mapping
  const labeledMapping = { ...mapping }

  forEachObjIndexed((slots, dayIndex) => {
    const intervals = toUninterruptedIntervals(slots)

    forEach(([startIndex, endIndex]) => {
      const start = slotStartTime(startIndex, step)
      const end = slotEndTime(endIndex, step)
      const label = formattedIntervalFromSeconds(start, end)

      labeledMapping[dayIndex][startIndex] = mergeRight(labeledMapping[dayIndex][startIndex], { label })
    }, intervals)
  }, labeledMapping)

  return labeledMapping
}

// Transforms availabilityMapping to availability in format
// [
//  { day_attribute: 'day_value', intervals: [ { start: 1000, duration: 2000 }, ... ] },
//  ...
// ]
export const parseAvailabilityMapping = (availabilityMapping, daysMapping, step, isRecurring) => {
  // select attribute depending on calendar type
  const dayAttribute = isRecurring ? 'day_of_week' : 'date'

  return reduce(
    (acc, [dayIndex, slots]) => {
      const intervals = toUninterruptedIntervals(slots)
      const availability = availabilityMapping[dayIndex]
      return [
        ...acc,
        {
          [dayAttribute]: daysMapping[dayIndex],
          intervals: map(
            ([start, end]) => ({
              start: slotStartTime(start, step),
              duration: intervalDuration(start, end, step),
              online: availability[start].online,
              location_ids: availability[start].locationIds,
            }),
            intervals,
          ),
        },
      ]
    },
    [],
    toPairs(availabilityMapping),
  )
}

// Selects only changed days since initialMapping from availabilityMapping
export const changedDaysFromMapping = (initialMapping, availabilityMapping) =>
  pipe(
    toPairs,
    reduce(
      (acc, [dayIndex, slots]) => (!equals(initialMapping[dayIndex], slots) ? { ...acc, [dayIndex]: slots } : acc),
      {},
    ),
  )(availabilityMapping)

// Transforms changed days from availabilityMapping to availability using `parseAvailabilityMapping`
export const parseChangedDays = (initialMapping, availabilityMapping, daysMapping, step, isRecurring) =>
  parseAvailabilityMapping(changedDaysFromMapping(initialMapping, availabilityMapping), daysMapping, step, isRecurring)

export const applySelectionToMapping = ({
  selectedCells,
  availabilityMapping,
  step,
  isAddMode = true,
  online = DEFAULT_AVAILABILITY.online,
  locationIds = DEFAULT_AVAILABILITY.locationIds,
}) => {
  if (isEmpty(selectedCells)) {
    return availabilityMapping
  }

  // SHALLOW copy of mapping to return later
  const availabilityGridMapping = { ...availabilityMapping }
  const cellsByDay = groupBy(path(['props', 'dayIndex']), selectedCells)

  // iterating selected cells to enable/disable them in mapping depending on mode
  forEachObjIndexed((cells, dayIdx) => {
    if (isAddMode) {
      forEach(cell => {
        availabilityGridMapping[dayIdx][cell.props.slotIndex] = {
          ids: availabilityMapping[dayIdx][cell.props.slotIndex]?.ids || [],
          online,
          locationIds,
        }
      }, cells)
    } else {
      availabilityGridMapping[dayIdx] = omit(map(path(['props', 'slotIndex']), cells), availabilityGridMapping[dayIdx])
    }
  }, cellsByDay)

  // OPTIMIZATION: set to undefined instead of omitting
  //               requires more check for undefined values in mapping
  // forEachObjIndexed((cells, dayIdx) => {
  //   forEach((cell) => {
  //     availabilityGridMapping[dayIdx][cell.props.slotIndex] = isAddMode
  //       ? { ids: [] }
  //       : undefined;
  //   }, cells);
  // }, cellsByDay);

  const affectedDays = keys(cellsByDay)
  // clearing interval labels for days present in selection
  const cleansedMapping = clearLabelsByDays(affectedDays, availabilityGridMapping)
  // add intervals to labels
  return addIntervalLabels(cleansedMapping, step)
}

export const createAvailabilityMapping = (availabilities, daysMapping, step, isRecurring) => {
  // prefilling dayIndexes in resulting `availabilityGridMapping`
  // by taking index of day/date im daysMapping
  const result = addIndex(reduce)((memo, _, index) => ({ ...memo, [index]: {} }), {}, daysMapping)

  // iterating over incoming `availabilities`
  map(({ id, start, duration, dayOfWeek, date, online, locations }) => {
    // calculating slots where availability interval starts and ends
    const end = start + duration
    const startSlot = Math.floor(start / (step * SECONDS_PER_MINUTE))
    const endSlot = Math.ceil(end / (step * SECONDS_PER_MINUTE))

    // depending on calendar type (recurring or date-specific)
    // we lookup day name or date value in `daysMapping` to obtain
    // index of a day we would use as key in resulting `availabilityGridMapping`
    const dayIndex = indexOf(isRecurring ? dayOfWeek : date, daysMapping)

    // skip to next availability if current day/date isn't found in daysMapping
    if (dayIndex === -1) {
      return
    }

    // filling slots from startSlot of interval to endSlot with
    // array of ids of mapped availability interval (array used in case of possible overlap)
    times(index => {
      const slotIndex = startSlot + index
      const slot = result[dayIndex][slotIndex]
      const locationIds = pluck('id', locations)
      result[dayIndex][slotIndex] = {
        ids: slot ? [id, ...slot.ids] : [id],
        online,
        locationIds: slot ? uniq([...locationIds, ...slot.locationIds]) : locationIds,
      }
    }, endSlot - startSlot)
  }, availabilities)

  return addIntervalLabels(result, step)
}

export const selectionIndexes = (key, selectedCells) => pipe(map(path(['props', key])), uniq)(selectedCells)

// move to one day before because we have a sunday as a first day of the week
const calculateSundayWeekStart = weekStart => weekStart.minus({ day: 1 })

export const weekDates = weekStart =>
  map(
    invoker(0, 'toISODate'),
    reduce(
      acc => [...acc, last(acc).plus({ day: 1 })],
      [calculateSundayWeekStart(weekStart)],
      times(identity, DAYS_PER_WEEK - 1),
    ),
  )

export const weekLimits = weekStart => {
  const sundayWeekStart = calculateSundayWeekStart(weekStart)
  return [sundayWeekStart, sundayWeekStart.plus({ day: DAYS_PER_WEEK - 1 })]
}

export const weekLimitsISO = weekStart => map(invoker(0, 'toISODate'), weekLimits(weekStart))
export const weekLimitsISOObj = weekStart => {
  const [startDate, endDate] = weekLimitsISO(weekStart)
  return { startDate, endDate }
}

export const isCurrentWeek = weekStart => Interval.fromDateTimes(...weekLimits(weekStart)).contains(DateTime.now())

export const isPastWeek = weekStart => {
  const currentWeek = DateTime.now().startOf('week')
  return (
    weekStart.year < currentWeek.year ||
    (weekStart.year === currentWeek.year && weekStart.weekNumber < currentWeek.weekNumber)
  )
}

export const simplifyMapping = map(daysVal =>
  mapObjIndexed(slotsVal => pick(['online', 'locationIds'], slotsVal), daysVal),
)

export const getWeekDay = date => {
  const { weekday } = DateTime.fromISO(date)

  return weekday === 7 ? 0 : weekday
}

export const highlightModifier = (slot, appliedFilters) => {
  if (!appliedFilters.locations.length) return true

  const isOnlineFilter = Boolean(any(equals('online'), appliedFilters.locations))
  if (isOnlineFilter && slot?.online) return true

  if (!slot?.locationIds.length) return false

  // If there are no differences between the slot's locationIds
  // and the appliedFilters's locationIds, then the slot is not highlighted
  return intersection(slot?.locationIds, appliedFilters.locations).length > 0
}
