import { createSelector } from '@reduxjs/toolkit'
import { any, filter, flatten, path, pipe, prop, propEq } from 'ramda'
import build from 'redux-object'

import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { SEARCH_RESULTS_ENTITIES } from 'lib/searchResults'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import { dataSelector, loadingSelector, pageCountSelector } from 'state/data/selectors'
import { makeSelectSearchResults } from 'state/searchResults/selectors'
import buildCollection from 'utils/buildCollection'
import { deepCreateSelector } from 'lib/selectors'
import { fetchLocationsEndpoint } from './endpoints'

const locationIdsSelector = path(['locations', 'locationIds'])

export const isBlankStateSelector = path(['locations', 'isBlankState'])
export const filtersSelector = path(['locations', 'filters'])
export const appliedFiltersSelector = (state, name) => path(['locations', 'filters', name], state)

export const locationsSelector = createSelector([locationIdsSelector, dataSelector], buildCollection('location'))
export const locationsTotalCountSelector = path(['locations', 'totalCount'])
export const locationsLoadingSelector = state => loadingSelector(state, fetchLocationsEndpoint.endpoint)
export const locationsPageCountSelector = state => pageCountSelector(state, fetchLocationsEndpoint.endpoint)
export const paginationSelector = path(['locations', 'pagination'])
export const currentPageSelector = pipe(paginationSelector, prop('number'))

export const searchedLocationsSelector = makeSelectSearchResults(SEARCH_RESULTS_ENTITIES.locations, 'location')

export const selectedLocationsSelector = deepCreateSelector(
  [dataSelector, (_, locationIds) => (locationIds ? flatten([locationIds]) : [])],
  (data, locationIds) => buildCollection('location', locationIds, data),
)

export const locationSelector = createSelector([dataSelector, (_, locationId) => locationId], (data, locationId) =>
  locationId ? build(data, 'location', locationId) : null,
)

const activeLocation = propEq('active', 'status')

export const activeLocationsSelector = createSelector(locationsSelector, filter(activeLocation))

export const isAnyActiveLocationSelector = createSelector(locationsSelector, any(activeLocation))

export const isMoreThanOneActiveLocationSelector = pipe(activeLocationsSelector, locations => locations.length > 1)

export const isOnlyOneActiveLocationSelector = pipe(activeLocationsSelector, locations => locations.length === 1)

export const isLocationDeletionAndActivationDisabledSelector = createSelector(
  [
    isMoreThanOneActiveLocationSelector,
    state => isEnableWorkspaceFeatureSelector(state, FEATURE_KEYS.acceptVideoCalls),
  ],
  (isMoreThanOneActiveLocation, isVideoCallsEnabled) => !isMoreThanOneActiveLocation && !isVideoCallsEnabled,
)
