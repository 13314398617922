import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Template = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M302.547 0c.991 0 1.968.062 2.926.182L302.547 0c1.229 0 2.449.097 3.649.287.736.113 1.455.262 2.161.444.236.067.471.131.704.199.721.204 1.43.448 2.12.725l.628.268c.658.28 1.295.592 1.914.932.241.137.488.277.733.424.655.388 1.286.808 1.895 1.256.149.112.31.232.468.356l.283.223 1.901 1.704L458.64 146.455a23.399 23.399 0 0 1 1.918 2.171l-1.918-2.171a23.266 23.266 0 0 1 2.289 2.664c.442.596.862 1.227 1.25 1.879.144.248.284.494.421.744.341.612.653 1.249.937 1.901.087.214.174.425.259.637a21.2 21.2 0 0 1 .736 2.119c.062.233.126.468.187.703a21.309 21.309 0 0 1 .553 2.888c.122.953.184 1.93.184 2.922v279.273c0 38.559-31.259 69.818-69.818 69.818H116.365c-38.559 0-69.818-31.259-69.818-69.818V69.821c0-38.559 31.259-69.818 69.818-69.818zm-23.273 46.545H116.365c-12.853 0-23.273 10.42-23.273 23.273v372.364c0 12.853 10.42 23.273 23.273 23.273h279.273c12.853 0 23.273-10.42 23.273-23.273v-256H302.548c-11.935 0-21.772-8.984-23.116-20.559l-.156-2.714V46.545zm69.818 302.546c12.853 0 23.273 10.42 23.273 23.273s-10.42 23.273-23.273 23.273H162.911c-12.853 0-23.273-10.42-23.273-23.273s10.42-23.273 23.273-23.273zm0-93.091c12.853 0 23.273 10.42 23.273 23.273s-10.42 23.273-23.273 23.273H162.911c-12.853 0-23.273-10.42-23.273-23.273S150.058 256 162.911 256zm-139.635-93.091c12.853 0 23.273 10.42 23.273 23.273s-10.42 23.273-23.273 23.273h-46.545c-12.853 0-23.273-10.42-23.273-23.273s10.42-23.273 23.273-23.273zM325.82 79.477v60.16h60.183l-60.183-60.16z" />
  </svg>
)

Template.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Template.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Template
