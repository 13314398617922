import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { buildUpdateClientProfileFieldsParams } from 'utils/clientFields'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { isEnableWorkspaceFeatureSelector } from 'state/concepts/workspaceFeatures/selectors'
import { UPDATE_CLIENT_PROFILE_FIELDS } from 'state/concepts/client/types'
import { updateClientProfileFieldsEndpoint } from '../endpoints'

const updateClientProfileFieldsOperation = createLogic({
  type: UPDATE_CLIENT_PROFILE_FIELDS,
  latest: true,

  async process({ action: { form, values, onClose }, httpClient, getState }, dispatch, done) {
    const { url } = updateClientProfileFieldsEndpoint(values.id)
    const isEnableCRM = isEnableWorkspaceFeatureSelector(getState(), FEATURE_KEYS.crm)
    const { firstName, lastName, email, phoneNumber, profileFields } = values
    const clientProfileFields = isEnableCRM
      ? { client_profile_fields_attributes: buildUpdateClientProfileFieldsParams(profileFields) }
      : {}

    const params = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      ...clientProfileFields,
      include: ['custom-fields', 'custom-fields.custom-field-options', 'user_profiles'],
    }

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      dispatch(showNotification({ messageObject: { id: 'notifications.clientInformationWasUpdated' } }))
      onClose()
    } catch (error) {
      form.setSubmitting(false)
      requestErrorHandler({ error, dispatch, form })
    }

    done()
  },
})

export default updateClientProfileFieldsOperation
