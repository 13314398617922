import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchClientNotes = (clientId, pageParams = {}) => ({
  type: types.FETCH_CLIENT_NOTES,
  clientId,
  pageParams,
})

export const deleteClientNote = ({ clientId, noteId, feedId }) => ({
  type: types.DELETE_CLIENT_NOTE,
  clientId,
  noteId,
  feedId,
})

export const removeClientNotesIds = ids => ({
  type: types.REMOVE_CLIENT_NOTES_IDS,
  ids,
})

export const addClientNotesIds = ids => ({
  type: types.ADD_CLIENT_NOTES_IDS,
  ids,
})

export const resetClientNotes = () => ({
  type: types.RESET_CLIENT_NOTES,
})

export const setIsAddNoteFormShown = isShown => ({
  type: types.SET_IS_ADD_NOTE_FORM_SHOWN,
  isShown,
})

export const addNote = makeFormSubmitAction(types.ADD_NOTE)

export const editNote = makeFormSubmitAction(types.EDIT_NOTE)

export const addNoteSuccess = id => ({
  type: types.ADD_NOTE_SUCCESS,
  id,
})

export const setNoteToEdit = id => ({
  type: types.SET_NOTE_TO_EDIT,
  id,
})

export const editNoteAccessibility = (noteId, clientId, accessibility) => ({
  type: types.EDIT_NOTE_ACCESSIBILITY,
  noteId,
  clientId,
  accessibility,
})
