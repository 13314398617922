const waitlistFeature = {
  'waitlistFeature.title': 'Waitlist',
  'waitlistFeature.subtitle': '<span>in</span> Booking',
  'waitlistFeature.description':
    'Quickly fill openings and enhance _(client) satisfaction with an easy-to-use waitlist system.',
  'waitlistFeature.overview': 'Waitlist overview',
  'waitlistFeature.overview.text1':
    'Fill openings faster, optimize your schedule, and improve _(client) satisfaction with little effort with _a(client) waiting list.',
  'waitlistFeature.overview.text2': 'Empower your _(clients):',
  'waitlistFeature.overview.text3':
    'Let _(clients) join the waitlist through your online booking page, offering them a chance for earlier _(appointments) even if none are immediately available.',
  'waitlistFeature.overview.text4':
    '_(Clients) can accept or decline _(appointments) scheduled from the waitlist, putting them in control of their schedules.',
  'waitlistFeature.overview.text5': 'Streamline waitlist management:',
  'waitlistFeature.overview.text6':
    'Prioritize and adjust waitlist requests, ensuring they meet _(client) needs and fit your availability.',
  'waitlistFeature.overview.text7':
    'Directly schedule _(appointments) from the waitlist to keep your calendar full and efficient.',
  'waitlistFeature.overview.text8': 'Customize your scheduling approach:',
  'waitlistFeature.overview.text9':
    'Automatically schedule _(appointments) from waitlist requests to fill slots quickly.',
  'waitlistFeature.overview.text10':
    'Alternatively, use automated email and SMS notifications to inform _(clients) about new openings for earlier _(appointment) opportunities.',
  'waitlistFeature.toLearnMores':
    'To learn more, read our help center articles on <link>setting up and managing your waitlist</link>.',
  'waitlistFeature.disable.title': 'Disable waitlist feature?',
  'waitlistFeature.disable.description':
    "After disabling the waitlist, neither you nor _(clients) won't be able to add any more requests to it. All of the existing requests will be cancelled.",
  'waitlistFeature.disable.alert.message': 'Disabling this feature will disconnect Google Meet video integration.',
  'waitlistFeature.manage': 'Set up waitlist',
}

export default waitlistFeature
