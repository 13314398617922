import endpoint from 'utils/endpoint'
import { locationsRoute, locationRoute } from 'lib/apiRoutes'
import {
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  TOGGLE_LOCATION_STATUS,
  EDIT_LOCATION,
  GET_LOCATION,
  DELETE_LOCATION,
} from './types'

export const toggleLocationEndpoint = locationId => endpoint(TOGGLE_LOCATION_STATUS, 'PUT', locationRoute(locationId))
export const fetchLocationsEndpoint = endpoint(FETCH_LOCATIONS, 'GET', locationsRoute)
export const createLocationEndpoint = endpoint(CREATE_LOCATION, 'POST', locationsRoute)
export const editLocationEndpoint = id => endpoint(EDIT_LOCATION, 'PUT', locationRoute(id))
export const deleteLocationEndpoint = id => endpoint(DELETE_LOCATION, 'DELETE', locationRoute(id))
export const getLocationEndpoint = id => endpoint(GET_LOCATION, 'GET', locationRoute(id))
