import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchWidgets } from 'state/concepts/widgets/actions'
import { deleteWidgetEndpoint } from 'state/concepts/widget/endpoints'
import { DELETE_WIDGET } from 'state/concepts/widget/types'
import { showNotification } from 'state/notifications/actions'

const deleteWidgetOperation = createLogic({
  type: DELETE_WIDGET,
  latest: true,

  async process({ action, httpClient }, dispatch, done) {
    const { widgetId } = action
    const { url, endpoint } = deleteWidgetEndpoint(widgetId)

    try {
      dispatch(dataApiRequest({ endpoint }))

      await httpClient.delete(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(fetchWidgets())
      dispatch(showNotification({ messageObject: { id: 'notifications.bookingPageHasBeenRemoved' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default deleteWidgetOperation
