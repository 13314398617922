import { DateTime } from 'luxon'
import { filter, pipe, indexOf, inc, map, prop } from 'ramda'

import {
  CUSTOM_LAST_MODIFIED_RANGE_TYPE,
  CUSTOM_SHARED_RANGE_TYPE,
  CUSTOM_TRASHED_RANGE_TYPE,
  FILE_KINDS,
  PREFILLED_LAST_MODIFIED_RANGES,
  PREFILLED_TRASHED_RANGES,
  PERMISSION_TYPES,
} from 'lib/constants/businessFiles'
import isPresent from 'utils/isPresent'
import {
  companyBusinessFilesMyFilesFolderRoute,
  companyBusinessFilesMyFilesRoute,
  companyBusinessFilesShareFolderRoute,
  companyBusinessFilesShareRoute,
  companyBusinessFilesTrashRoute,
} from 'lib/routes'
import link from './locales/link'

export const isFolder = kind => kind === FILE_KINDS.folder
export const isFile = kind => kind === FILE_KINDS.file

export const isLastModifiedTypeCustom = type => type === CUSTOM_LAST_MODIFIED_RANGE_TYPE
export const isTrashedTypeCustom = type => type === CUSTOM_TRASHED_RANGE_TYPE
export const isSharedTypeCustom = type => type === CUSTOM_SHARED_RANGE_TYPE

export const dateRangeByType = rangeType => {
  const now = DateTime.now()
  const endOfCurrentDay = now.endOf('day')

  const ranges = {
    [PREFILLED_LAST_MODIFIED_RANGES.today]: [now.startOf('day'), endOfCurrentDay],
    [PREFILLED_LAST_MODIFIED_RANGES.lastWeek]: [now.minus({ days: 7 }).startOf('day'), endOfCurrentDay],
    [PREFILLED_LAST_MODIFIED_RANGES.lastMonth]: [now.minus({ months: 1 }).startOf('day'), endOfCurrentDay],
    [PREFILLED_LAST_MODIFIED_RANGES.thisYear]: [now.startOf('year'), now],
    [PREFILLED_LAST_MODIFIED_RANGES.lastYear]: [
      now.minus({ years: 1 }).startOf('year'),
      now.minus({ years: 1 }).endOf('year'),
    ],
  }

  const [modifiedAfter, modifiedBefore] = ranges[rangeType] || [null, null]
  return { modifiedAfter, modifiedBefore }
}

export const trashedDateRangeByType = rangeType => {
  const now = DateTime.now()
  const endOfCurrentDay = now.endOf('day')
  const lastWeek = now.minus({ days: 7 })
  const yesterday = now.minus({ days: 1 })

  const ranges = {
    [PREFILLED_TRASHED_RANGES.today]: [now.startOf('day'), endOfCurrentDay],
    [PREFILLED_TRASHED_RANGES.yesterday]: [yesterday.startOf('day'), yesterday.endOf('day')],
    [PREFILLED_TRASHED_RANGES.thisWeek]: [now.startOf('week'), endOfCurrentDay],
    [PREFILLED_TRASHED_RANGES.lastWeek]: [lastWeek.startOf('week'), lastWeek.endOf('week')],
  }

  const [trashedAfter, trashedBefore] = ranges[rangeType] || [null, null]
  return { trashedAfter, trashedBefore }
}

const createBase = workspaceCode => [
  {
    id: 'businessFiles',
    type: 'businessFiles',
    label: { id: 'myBusinessFiles.title' },
    href: `/${workspaceCode}${companyBusinessFilesMyFilesRoute}`,
    isDisabled: true,
  },

  {
    id: 'files',
    type: 'files',
    label: { id: 'myBusinessFiles.myFiles' },
    href: `/${workspaceCode}${companyBusinessFilesMyFilesRoute}`,
  },
  {
    id: 'sharedFiles',
    type: 'sharedFiles',
    label: { id: 'myBusinessFiles.sharedFiles' },
    href: `/${workspaceCode}${companyBusinessFilesShareRoute}`,
  },
  {
    id: 'trash',
    type: 'trash',
    label: { id: 'myBusinessFiles.trash' },
    href: `/${workspaceCode}${companyBusinessFilesTrashRoute}`,
  },
]

const routesByType = {
  files: companyBusinessFilesMyFilesFolderRoute,
  sharedFiles: companyBusinessFilesShareFolderRoute,
}

export const createCrumbsMapper =
  (workspaceCode, type) =>
  ([id, name]) => ({
    id,
    label: name,
    href: `/${workspaceCode}${routesByType[type]?.(id)}`,
  })

export const createCrumbs = ({
  workspaceCode,
  path = undefined,
  fileCrumbType = 'files',
  crumbsMapperCreator = createCrumbsMapper,
}) => {
  const base = createBase(workspaceCode)
  const computedCrumbType = base.find(({ type }) => type === fileCrumbType)

  if (!isPresent(path)) return [base[0], computedCrumbType]

  const crumbsMapper = crumbsMapperCreator(workspaceCode, fileCrumbType)

  if (path.length === 1) return [base[0], computedCrumbType, ...path.map(crumbsMapper)]

  const isWithOverflowMenu = path.length > 1
  const crumbs = isWithOverflowMenu ? path.slice(-2).map(crumbsMapper) : path.map(crumbsMapper)
  const overflowMenuCrumbs = [computedCrumbType, ...(isWithOverflowMenu ? path.slice(0, -2).map(crumbsMapper) : [])]

  return [
    base[0],
    ...(isWithOverflowMenu
      ? [{ id: 'three-dots-menu-breadcrubs', label: '...', menu: { items: overflowMenuCrumbs } }]
      : [computedCrumbType]),
    ...crumbs,
  ]
}

export const getRestoreTrashedFileSuccessNotification = (data, currentEmployeeId) => {
  const restoredCount = data.meta.restored_files_count + data.meta.restored_folders_count
  const isRestoredFolderKind = isFolder(data.data?.attributes.kind)
  const parentFolderId = data.included?.find(
    ({ id, type }) => type === 'business-storage' && id === data.data?.relationships.parent.data?.id,
  )?.attributes['discarded-at']
    ? null
    : data.data?.relationships.parent.data?.id
  const folderId = isRestoredFolderKind ? data.data?.id : parentFolderId
  const isMyFile = data.data?.relationships?.['user-profile']?.data?.id === currentEmployeeId
  const folderRoute = isMyFile
    ? companyBusinessFilesMyFilesFolderRoute(folderId)
    : companyBusinessFilesShareFolderRoute(folderId)
  const rootRoute = isMyFile ? companyBusinessFilesMyFilesRoute : companyBusinessFilesShareRoute
  const linkRoute = folderId ? folderRoute : rootRoute
  const isAllFilesKind = data.meta.restored_files_count && !data.meta.restored_folders_count
  const isAllFoldersKind = data.meta.restored_folders_count && !data.meta.restored_files_count
  const isAllBothKind = data.meta.restored_files_count && data.meta.restored_folders_count
  let messageId = 'notifications.trashedFileWasRestored'
  if (isAllFilesKind && restoredCount > 1) messageId = 'notifications.trashedFilesWereRestored'
  if (isAllFoldersKind && restoredCount === 1) messageId = 'notifications.trashedFolderWasRestored'
  if (isAllFoldersKind && restoredCount > 1) messageId = 'notifications.trashedFoldersWereRestored'
  if (isAllBothKind && restoredCount > 1) messageId = 'notifications.trashedItemsWereRestored'
  if (restoredCount === 0) messageId = 'notifications.noPermissionToPerformAction'

  return {
    messageObject: {
      id: messageId,
      values: {
        amount: restoredCount,
        link: link(
          linkRoute,
          { id: `notifications.${isAllFoldersKind ? 'showFolderLocation' : 'showFileLocation'}` },
          'text-underline in-green-700',
        ),
      },
    },
    kind: restoredCount === 0 ? 'error' : undefined,
  }
}

export const getPreviewPageNumber = (files, fileId) =>
  pipe(
    filter(({ kind }) => kind === FILE_KINDS.file || !kind),
    map(prop('id')),
    indexOf(fileId),
    inc,
  )(files)

export const hasEditorPermission = (businessStoragePermissions, ancestorsPermissions, currentEmployee) => {
  const ancestorPermission = ancestorsPermissions?.find(({ userProfile }) => userProfile.id === currentEmployee?.id)
  if (ancestorPermission) return ancestorPermission.permission === PERMISSION_TYPES.writer

  return isPresent(
    businessStoragePermissions &&
      businessStoragePermissions.find(
        ({ permission, userProfile }) =>
          userProfile.id === currentEmployee?.id && permission === PERMISSION_TYPES.writer,
      ),
  )
}
