import { cond, equals, T, pathOr, includes, not, always } from 'ramda'

import { dataApiFailure } from 'state/data/actions'
import makeHandleForm from './makeHandleForm'
import makeSetErrorToStore from './makeSetErrorToStore'
import makeShowNotification from './makeShowNotification'

const requestErrorHandler = ({
  error,
  dispatch,
  skipHandlers = false,
  endpoint = '',
  form = {},
  formStatusKeys = ['base'],
  excludeErrorArray = [],
}) => {
  const requestMethod = pathOr(null, ['response', 'config', 'method'], error)
  const requestStatus = pathOr(null, ['response', 'status'], error)
  const excludedErrorArray = [...excludeErrorArray, 401]

  const handleGetRequest = cond([
    [equals(403), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(404), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(422), always(undefined)],
    [T, makeShowNotification(dispatch)],
  ])

  const handleOtherRequests = cond([
    [equals(403), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(422), makeHandleForm(form, error, formStatusKeys)],
    [T, makeShowNotification(dispatch)],
  ])

  if (!skipHandlers && not(includes(requestStatus, excludedErrorArray))) {
    requestMethod === 'get' ? handleGetRequest(requestStatus) : handleOtherRequests(requestStatus)
  }

  if (endpoint) {
    dispatch(dataApiFailure({ endpoint }))
  }
}

export default requestErrorHandler
