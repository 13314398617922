import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Attachment = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M386.982 304.354c10.969-10.969 28.753-10.969 39.723 0s10.969 28.753 0 39.723L305.897 464.885c-62.819 62.819-164.669 62.819-227.488 0s-62.819-164.669 0-227.488L281.655 34.151c45.536-45.536 119.363-45.536 164.899 0s45.536 119.364-.01 164.91L243.088 402.296c-28.252 28.252-74.059 28.252-102.311 0s-28.252-74.058.012-102.323l122.315-122.171c10.976-10.963 28.76-10.952 39.723.024s10.952 28.76-.024 39.723L180.5 339.708c-6.314 6.314-6.314 16.551 0 22.865s16.552 6.314 22.876-.01l203.456-203.235c23.598-23.597 23.598-61.856 0-85.454s-61.856-23.597-85.454 0L118.132 277.12c-40.881 40.881-40.881 107.161 0 148.042s107.162 40.881 148.042 0l120.808-120.808z" />
  </svg>
)

Attachment.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Attachment.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Attachment
