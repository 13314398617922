import PropTypes from 'prop-types'
import classNames from 'clsx'

import ProfileIdentity from 'views/shared/ProfileIdentity'

const OptionLabel = ({ mode, employee, withAvatar }) =>
  mode === 'default' ? (
    <ProfileIdentity user={employee} withAvatar={withAvatar} />
  ) : (
    <span
      className={classNames('dropdown-multiselect-choise', {
        'dropdown-multiselect-choise--deactiv': !employee.active && !employee.invited,
      })}
    >
      <span className="dropdown-multiselect-choise__txt">
        <ProfileIdentity user={employee} withAvatar={withAvatar} />
      </span>
    </span>
  )

OptionLabel.propTypes = {
  mode: PropTypes.string.isRequired,
  employee: PropTypes.shape().isRequired,
  withAvatar: PropTypes.bool.isRequired,
}

export default OptionLabel
