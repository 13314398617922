import normalize from 'json-api-normalizer'
import { trim } from 'ramda'
import { createLogic } from 'redux-logic'

import { usersAccountProfileRoute } from 'lib/apiRoutes'
import { ONBOARDING_STEPS_NAMES } from 'lib/constants/employees'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companyAvailabilityRecurringRoute, companyBookingsRoute } from 'lib/routes'
import { SUBMIT_ONBOARDING_GENERAL } from 'state/concepts/employee/types'
import { updateRecentWorkspace } from 'state/concepts/recentWorkspaces/actions'
import { currentEmployeeSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import isOwner from 'utils/employeePermissions/isOwner'
import isSchedulerPredicate from 'utils/employeePermissions/isScheduler'
import redirect from 'utils/redirect'
import updateDataHelper from 'utils/updateDataHelper'

const submitOnboardingGeneralOperation = createLogic({
  type: SUBMIT_ONBOARDING_GENERAL,
  latest: true,

  async process({ action: { values, form }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const formData = new FormData()
    const currentEmployee = currentEmployeeSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)

    try {
      formData.append('first_name', trim(values.firstName))
      formData.append('last_name', trim(values.lastName))
      formData.append('position', values.position)
      values.about && formData.append('about', values.about)
      formData.append('timezone', values.timezone)
      values.phoneNumber && formData.append('phone_number', values.phoneNumber)

      if (values.avatar === null) {
        formData.append('remove_avatar', true)
      } else if (values.avatar instanceof Blob) {
        formData.append('avatar', values.avatar, values.avatar.name)
      }

      const { data } = await httpClient.put(usersAccountProfileRoute, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })

      const nextStep = isOwner(currentEmployee)
        ? ONBOARDING_STEPS_NAMES.workspaceProfile
        : ONBOARDING_STEPS_NAMES.completed
      const response = updateDataHelper(normalize(data), 'userProfile', currentEmployee.id, {
        attributes: { onboardingStatus: nextStep },
      })

      dispatch(dataApiSuccess({ response }))

      const isScheduler = isSchedulerPredicate(currentEmployeeSelector(getState()))

      if (nextStep === ONBOARDING_STEPS_NAMES.completed) {
        redirect({
          href: isScheduler ? companyBookingsRoute : companyAvailabilityRecurringRoute,
          workspace: workspaceCode,
        })
        dispatch(
          updateRecentWorkspace({
            id: currentEmployee.workspace.id,
            onboardingStatus: ONBOARDING_STEPS_NAMES.completed,
          }),
        )
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }

    done()
  },
})

export default submitOnboardingGeneralOperation
