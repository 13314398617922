import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Tooltip from 'views/shared/Tooltip'
import Download from 'views/shared/icons/Download'
import DeleteO from 'views/shared/icons/DeleteO'
import DropdownMenu from 'views/shared/DropdownMenu'
import Options from 'views/shared/icons/Options'

const AttachedFileActionsComponent = ({ url, onRemoveFile, canRemove, inlineActions }) =>
  inlineActions ? (
    <div className="ml-auto chat-msg-file-icons">
      <Tooltip
        align={{ offset: [0, -2] }}
        on={['hover']}
        placement="top"
        overlayClassName="main-tooltip main-tooltip--no-pointer"
        icon={
          <a
            className="chat-msg-file-icons__control d-flex align-items-center justify-content-center"
            data-cy="chat-action-icon-download-file"
            target="_blank"
            rel="noopener noreferrer"
            href={url}
          >
            <Download size={20} />
          </a>
        }
      >
        <FormattedMessage id="shared.download" />
      </Tooltip>
      {canRemove && (
        <Tooltip
          align={{ offset: [0, -2] }}
          on={['hover']}
          placement="top"
          overlayClassName="main-tooltip main-tooltip--no-pointer"
          icon={
            <span
              className="chat-msg-file-icons__control d-flex align-items-center justify-content-center"
              data-cy="chat-action-icon-remove-file"
              role="button"
              onClick={onRemoveFile}
            >
              <DeleteO size={20} />
            </span>
          }
        >
          <FormattedMessage id="shared.delete" />
        </Tooltip>
      )}
    </div>
  ) : (
    <DropdownMenu
      className="main-dropdown-btn"
      menuClassName="main-dropdown min-w-185"
      icon={
        <button type="button">
          <Options className="main-dropdown-btn__icon" />
        </button>
      }
      items={[
        {
          itemProps: {
            text: { id: 'shared.download' },
            isLink: true,
            href: url,
            target: '_blank',
          },
        },
        {
          isHidden: !canRemove,
          itemProps: {
            text: { id: 'shared.delete' },
            onClick: onRemoveFile,
          },
        },
      ]}
    />
  )

AttachedFileActionsComponent.defaultProps = {
  inlineActions: false,
}

AttachedFileActionsComponent.propTypes = {
  url: PropTypes.string.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  inlineActions: PropTypes.bool,
}

export default AttachedFileActionsComponent
