const employees = {
  'employeesFilters.roles.title': 'By roles',
  'employeesFilters.roles.experts': '_(Experts)',
  'employeesFilters.roles.scheduler': '_(Scheduler)',
  'employeesFilters.roles.administrator': 'Administrators',

  'employees.allEmployees': 'Team members',
  'employees.roles.administrator': 'Administrator',
  'employees.roles.workspaceOwner': 'Workspace owner',
  'employees.roles.scheduler': '_(Scheduler)',
  'employees.title': 'Team',
  'employees.invite': 'Invite',
  'employees.invitations.submit': 'Send invitations',
  'employees.invitations.cancel': 'Cancel',
  'employees.invitations.email': 'Email',
  'employees.invitations.role': 'Role',
  'employees.invitations.addMember': 'Add one more member',
  'employees.invitations.modal.title': 'Invite team members',
  'employees.invitations.modal.subtitle': 'Enter their email addresses, set roles and we will send them invitations',
  'employees.invitations.duplicateEmails': "You've entered one email several times",
  'employees.invitations.errors.alreadyMember': 'Oops! This user is already a member',
  'employees.invitations.errors.alreadyInvited': 'Oops! This user is already invited',
  'employees.invitations.roleOptions.administrator': 'Administrator',
  'employees.invitations.roleOptions.administrator.description':
    'Can manage the team, all _(appointments), _(services), _(clients), documentation, billing, workspace subscription, and more.',
  'employees.invitations.roleOptions.scheduler': '_(Scheduler)',
  'employees.invitations.roleOptions.scheduler.description':
    'Can schedule and manage _(appointments) and _(clients) for all team members.',
  'employees.invitations.roleOptions.user': '_(Expert)',
  'employees.invitations.roleOptions.user.description':
    'Can manage their own _(appointments) and _(clients), and access related documentation.',
  'employees.invitations.usedSeats': '{teamSize} of {teamSeatsCapacity} seats used',
  'employees.assignServices.modal.title': 'Manage assign _(services) to team members',
  'employees.assignServices.modal.subtitle': 'Select _(services) to assign to:',
  'employees.assignServices.pricePerSession': 'Price per session',
  'employees.assignServices.pricePerSessionPlaceholder': '$',
  'employees.assignServices.addServices': 'Add _(services)',
  'employees.assignServices.services': '_(Services)',
  'employees.assignServices.addServicesOtherPrice': 'Add _(services) with another price',
  'employees.listOfTeamMembers': 'List of team members',
  'employees.openMenuToAssign': 'Open this menu to assign this team member to _(services)',
  'employees.canNotInviteMembersModal.title': 'Can’t invite members',
  'employees.canNotInviteMembersModal.body':
    'There are no free team seats. You had to add new seats to invite new members.',
  'employees.canNotInviteMembersModal.submit': 'Manage team seats',
  'employees.defaultVideoCallProviderNotConnected': 'Default video call not connected',

  'employeesTable.teamMember': 'Team member',
  'employeesTable.services': '_(Services)',
  'employeesTable.role': 'Role',
  'employeesTable.twoFactor': 'Two-step',
  'employeesTable.status': 'Status',

  'employeesActions.deactivate': 'Deactivate',
  'employeesActions.activate': 'Activate',
  'employeesActions.removeInvitation': 'Remove invitation',
  'employeesActions.remove': 'Remove',
  'employeesActions.resendInvitation': 'Resend invitation',
  'employeesActions.editRole': 'Change role',
  'employeesActions.assignToService': 'Assign to _a(service)',
  'employeesActions.edit': 'Edit',

  'employeesDeactivateModal.title': 'Deactivate team member account',
  'employeesDeactivateModal.areYouSure': 'Are you sure you want to deactivate {name}?',
  'employeesDeactivateModal.allTheUpcomingAppointments':
    'All the upcoming _(appointments) will be cancelled and _(clients) should be refunded.',
  'employeesDeactivateModal.theTeamMemberWillNot':
    'The team member will not have the access to the workspace until you decide to activate the account. However, all account data will be saved.',
  'employeesDeactivateModal.checkbox.cancelAndRefund': 'Cancel & refund all upcoming _(appointments)',
  'employeesDeactivateModal.checkbox.deactivateTeamMembersAccount': 'Deactivate team member’s account',
  'employeesDeactivateModal.button': 'Deactivate',
  'employeeDeactivateYourselfModal.title': 'Deactivate your account?',
  'employeeDeactivateYourselfModal.description':
    'All the upcoming _(appointments) will be cancelled and _(clients) should be refunded.',
  'employeeDeactivateYourselfModal.description_2':
    'You will not have the access to the workspace until administrator of your workspace decides to activate the account. However, all acount data will be saved.',
  'employeeDeactivateYourselfModal.note':
    'Note: This change will take effect immediately. An administrator of your workspace will need to re-enable your account if you would like to rejoin this workspace and have all your data reactivated.',
  'employeesDeactivateSchedulerModal.title': 'Deactivate team member?',
  'employeesDeactivateSchedulerModal.description':
    '{name} will lose access to the workspace. All their data will be saved.',
  'employeeSchedulerDeactivateYourselfModal.title': 'Deactivate your account?',
  'employeeSchedulerDeactivateYourselfModal.description':
    'You will not have the access to the workspace until administrator of your workspace decides to activate the account. However, all acount data will be saved.',
  'employeeSchedulerDeactivateYourselfModal.note':
    'Note: This change will take effect immediately. An administrator of your workspace will need to re-enable your account if you would like to rejoin this workspace and have all your data reactivated.',
  'employeesActivateSchedulerModal.title': 'Activate team member?',
  'employeesActivateSchedulerModal.description': '{name} will regain her access to workspace.',
  'employeesActivateModal.activateAccount': 'Activate team member account',
  'employeesActivateModal.areYouSure': 'Are you sure you want to activate {name}?',
  'employeesRemoveModal.removeAccount': 'Remove team member account?',
  'employeesRemoveModal.areYouSure': 'Do you want to remove {fullName} from the team?',
  'employeesRemoveModal.actionIsIrreversible':
    'This action is irreversible. {fullName}’s account will be deleted permanently.',
  'employeesRemoveModal.businessFiles': 'Business files',
  'employeesRemoveModal.whatDoYouWantToDo': 'What do you want to do with the business files owned by {fullName}?',
  'employeesRemoveModal.option.deleteFiles': 'Delete files',
  'employeesRemoveModal.option.transferOwnershipToAnotherTeamMember': 'Transfer ownership to another team member',
  'employeesRemoveModal.removeTeamMember': 'Remove team member',
  'employeesRemoveModal.field.newOwner': 'New owner',
  'employeesRemoveModal.field.placeholder': 'Select team member',
  'employeesErrorModal.removeError': 'Removal error',
  'employeesRemoveInvitationModal.removeInvitation': 'Remove team member invitation',
  'employeesRemoveInvitationModal.wantToRemove': 'Do you want to remove the invitation for',
  'employeesChangeRoleModal.title': 'Change role',
  'employeesChangeRoleModal.changeRoleFor': 'Change role for {name}',
  'employeesChangeRoleModal.changeUserRoleTo':
    'Change role for <b>{name}</b> to <b>{role, select, user {_(expert)} administrator {administrator} other {}}</b>.',
}

export default employees
