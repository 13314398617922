const tutorial = {
  'tutorial.skip': 'Skip',
  'tutorial.finish': 'Finish tutorial',
  'tutorial.helpCenter': 'Help Center',
  'tutorial.completeWorkingHours.title': 'Complete your working hours',
  'tutorial.completeWorkingHours.description':
    'Click and drag pointer to highlight a range of time slots when you are going to work. This time will be available to _(clients) for new _(appointments). You can also add or remove some specific slots on the {link} page',
  'tutorial.inviteTeamMembers.title': 'Invite your team members',
  'tutorial.inviteTeamMembers.description':
    'Invite and assign team members who will provide _(services) in your company.',
  'tutorial.createNewService.title': 'Create a new _(service)',
  'tutorial.createNewService.description':
    'Add new _(services) and manage them on this page. You can group _(services) into logical groups using categories.',
  'tutorial.setupBookingPage.title': 'Setup your booking page',
  'tutorial.setupBookingPage.description': 'Customize and setup your booking page to get new _(appointments).',
  'tutorial.connectPaymentMethod.title': 'Connect your payment method',
  'tutorial.connectPaymentMethod.description':
    'Connect your Stripe account to provide paid _(services) and get online payments from your _(clients)',
  'tutorial.dateSpecificAvailability': 'Day-specific availability',
  'tutorial.congratulations.title': 'Congratulations!',
  'tutorial.congratulations.description': 'Now you are ready to gain new _(clients) and manage your company',
}

export default tutorial
