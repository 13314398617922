import React from 'react'
import PropTypes from 'prop-types'

import ProvisionPriceComponent from './component'

class ProvisionPrice extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }

  handlePriceChange = async (price, prevPrice) => {
    const { index, setFieldValue, setFieldTouched } = this.props

    if (price === 0 && prevPrice !== 0) {
      setFieldValue(`provision.${index}.isFreePrice`, true)
    }
    if (price !== 0 && prevPrice === 0) {
      setFieldValue(`provision.${index}.isFreePrice`, false)
    }

    await setFieldTouched(`provision.${index}.employees`)
  }

  handleIsFreePriceChange = async isFreePrice => {
    const { index, setFieldValue, setFieldTouched } = this.props

    isFreePrice ? setFieldValue(`provision.${index}.price`, 0) : setFieldValue(`provision.${index}.price`, undefined)

    await setFieldTouched(`provision.${index}.employees`)
    await setFieldTouched(`provision.${index}.price`)
  }

  render() {
    return (
      <ProvisionPriceComponent
        {...this.props}
        onPriceChange={this.handlePriceChange}
        onIsFreePriceChange={this.handleIsFreePriceChange}
      />
    )
  }
}

export default ProvisionPrice
