const editWebsite = {
  'editWebsite.tabs.general': 'General settings',
  'editWebsite.tabs.content': 'Website content',
  'editWebsite.sidebar.status.title': 'Website status',
  'editWebsite.sidebar.status.description':
    'Published website is available on the internet and appears on search results.',
  'editWebsite.sidebar.status.label': 'Published',
  'editWebsite.general.widget.title': 'Online booking',
  'editWebsite.general.widget.label': 'Booking page',
  'editWebsite.content.legal.title': 'Legal pages',
}

export default editWebsite
