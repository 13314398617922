import classNames from 'clsx'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { take } from 'ramda'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useToggle } from 'react-use'

import { APPOINTMENT_INVITED_MEMBERS_LIMIT, APPOINTMENT_INVITED_MEMBERS_VISIBLE_LIMIT } from 'lib/constants/videoCalls'
import Button from 'views/shared/Button'
import InvitedMember from '../InvitedMember'

const InvitedMembers = ({
  booking: { invitedMembers, id },
  noHover,
  highlighted,
  alsoInvited,
  overlayClassName,
  hideRemoveMemberButton,
}) => {
  const membersLength = invitedMembers.length
  const isToggled = membersLength > APPOINTMENT_INVITED_MEMBERS_VISIBLE_LIMIT
  const [showAll, setShowAll] = useToggle(isToggled)
  const shownMembers = showAll ? take(APPOINTMENT_INVITED_MEMBERS_VISIBLE_LIMIT, invitedMembers) : invitedMembers

  useEffect(() => {
    setShowAll(isToggled)
  }, [membersLength, setShowAll, isToggled])

  if (membersLength === 0) {
    return null
  }

  return (
    <div className={classNames('d-flex flex-column align-items-start', overlayClassName)}>
      <p
        className={classNames(
          { 'in-blue-gray-800 mb-4': !alsoInvited },
          { 'text-caption in-blue-gray-300 pt-8 pb-8 pl-24 pr-24 mb-0': alsoInvited },
        )}
      >
        <FormattedMessage
          id={
            // eslint-disable-next-line no-nested-ternary
            !alsoInvited
              ? membersLength > 1
                ? 'bookings.invitedByEmails'
                : 'bookings.invitedByEmail'
              : 'bookings.alsoInvited'
          }
        />
        {!alsoInvited && (
          <span className="tooltip-doc-counter bg-gray-200 d-inline-flex lh-1 font-400 min-w-16 ml-4 pl-4 pr-4 pt-2 pb-2">
            <span className="in-blue-gray-300">{membersLength}</span>
            <span className="in-gray-700">/</span>
            <span className="in-gray-700">{APPOINTMENT_INVITED_MEMBERS_LIMIT}</span>
          </span>
        )}
      </p>
      <div
        className={classNames('d-flex flex-column align-items-start invited-members', {
          'invited-members--highlighted': highlighted,
          'invited-members--also-invited': alsoInvited,
        })}
      >
        {shownMembers.map(member => (
          <InvitedMember
            member={member}
            key={nanoid()}
            noHover={noHover}
            highlighted={highlighted}
            alsoInvited={alsoInvited}
            bookingId={id}
            hideRemoveMemberButton={hideRemoveMemberButton}
          />
        ))}
      </div>
      {showAll && (
        <Button
          className="main-btn--invite-members main-btn--text-blue font-400 ml-4"
          kind="text"
          text="Show all members"
          icon="options-vertical"
          iconSize={12}
          onClick={setShowAll}
        />
      )}
    </div>
  )
}

InvitedMembers.defaultProps = {
  overlayClassName: '',
  alsoInvited: false,
  noHover: false,
  highlighted: false,
  hideRemoveMemberButton: false,
}

InvitedMembers.propTypes = {
  overlayClassName: PropTypes.string,
  alsoInvited: PropTypes.bool,
  noHover: PropTypes.bool,
  highlighted: PropTypes.bool,
  hideRemoveMemberButton: PropTypes.bool,
  booking: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invitedMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
}

export default InvitedMembers
