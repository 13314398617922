import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import redirect from 'utils/redirect'
import { websiteHttpClient } from 'lib/httpClient'
import { publicWebsiteRoute } from 'lib/apiRoutes'
import { FETCH_PUBLIC_WEBSITE } from 'state/concepts/website/types'
import { dataApiSuccess } from 'state/data/actions'
import { setWebsite } from 'state/concepts/website/actions'
import { workspaceSelector, isClientPortalEnabledSelector } from 'state/concepts/subdomain/selectors'

const fetchPublicWebsiteOperation = createLogic({
  type: FETCH_PUBLIC_WEBSITE,
  latest: true,

  async process({ action: { response }, getState }, dispatch, done) {
    const state = getState()
    const { uniqCode } = workspaceSelector(state)
    const isClientPortalEnabled = isClientPortalEnabledSelector(state)

    try {
      const params = {
        include: 'website-setting,widget,social-network',
      }

      const { data } = await websiteHttpClient.get(publicWebsiteRoute(uniqCode), { params })

      dispatch(setWebsite(data.data.id))
      dispatch(dataApiSuccess({ response: normalize(data) }))
    } catch (_error) {
      redirect({ href: isClientPortalEnabled ? '/client/login' : '/login', response })
    }

    done()
  },
})

export default fetchPublicWebsiteOperation
