import { Field, FieldArray } from 'formik'
import PropTypes from 'prop-types'

import {
  RECURRING_BOOKING_REPEAT_COUNT_MIN,
  RECURRING_BOOKING_REPEAT_COUNT_MAX,
} from 'lib/constants/scheduleAppointment'
import { ENDS_FIELD_KEY_AND_VALUE, ENDS_FIELD_OPTIONS, WEEKLY_INTERVAL_FIELD_OPTIONS } from 'lib/constants/bookings'
import DatePickerField from 'views/shared/DatePickerField'
import ArrowRepeat from 'views/shared/icons/ArrowRepeat'
import SelectField from 'views/shared/SelectField'
import SwitchField from 'views/shared/SwitchField'
import NumericField from 'views/shared/NumericField'
import { formatInputDate } from 'utils/dateTime'
import DaysCheckboxGroupField from './DaysCheckboxGroupField'

const RepeatSectionComponent = ({ repeat, ends, datePickerCellDisabled }) => (
  <>
    <div className="pt-16 ml-32" data-cy="repeat-section-switch">
      <Field
        className="mb-0"
        name="repeat"
        id="repeat"
        label={{ id: 'scheduleAppointment.repeatSection.repeatLabel' }}
        component={SwitchField}
      />
    </div>
    {repeat && (
      <div className="d-flex pt-8 repeat-section__wrap" data-cy="repeat-section">
        <div className="mr-16 pt-36">
          <ArrowRepeat size={24} color="#6e798d" />
        </div>
        <div className="w-100 repeat-section">
          <Field
            type="text"
            id="weeklyInterval"
            name="weeklyInterval"
            component={SelectField}
            label={{ id: 'scheduleAppointment.repeatSection.weeklyIntervalLabel' }}
            options={WEEKLY_INTERVAL_FIELD_OPTIONS}
            placeholder={{ id: 'scheduleAppointment.repeatSection.weeklyIntervalPlaceholder' }}
            data-cy="week-interval-picker"
          />
          <div className="d-flex mt-12">
            <FieldArray name="byWeekday" id="byWeekday" component={DaysCheckboxGroupField} />
          </div>
          <div className="d-flex mt-16">
            <div className="w-50 mr-8">
              <Field
                type="text"
                id="ends"
                name="ends"
                component={SelectField}
                label={{ id: 'scheduleAppointment.repeatSection.endsLabel' }}
                options={ENDS_FIELD_OPTIONS}
                placeholder={{ id: 'scheduleAppointment.repeatSection.endsPlaceholder' }}
                data-cy="ends-field"
              />
            </div>
            <div className="w-50 ml-8">
              {ends === ENDS_FIELD_KEY_AND_VALUE.until ? (
                <Field
                  wrapperClassName="bordered-calendar"
                  labelClassName="main-input__label"
                  label={{ id: 'scheduleAppointment.repeatSection.untilDateLabel' }}
                  id="untilDate"
                  name="untilDate"
                  hideIcon
                  tileDisabled={datePickerCellDisabled}
                  component={DatePickerField}
                  formatInputDate={formatInputDate}
                />
              ) : (
                <Field
                  className="mb-0"
                  name="repeatCount"
                  id="repeatCount"
                  component={NumericField}
                  label={{ id: 'scheduleAppointment.repeatSection.repeatCountLabel' }}
                  icon="alert"
                  showButtons
                  min={RECURRING_BOOKING_REPEAT_COUNT_MIN}
                  max={RECURRING_BOOKING_REPEAT_COUNT_MAX}
                  data-cy="repeat-count-field"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )}
  </>
)

RepeatSectionComponent.propTypes = {
  repeat: PropTypes.bool.isRequired,
  ends: PropTypes.string.isRequired,
  datePickerCellDisabled: PropTypes.func.isRequired,
}

export default RepeatSectionComponent
