import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { restoreContactAccountEndpoint } from 'state/concepts/client/endpoints'
import { RESTORE_CONTACT_ACCOUNT } from 'state/concepts/client/types'
import { clientContactLogin } from 'state/concepts/session/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'

const restoreContactAccountOperation = createLogic({
  type: RESTORE_CONTACT_ACCOUNT,
  latest: true,

  async process({ httpClient, action: { onSuccess, contactId } }, dispatch, done) {
    const { url, endpoint } = restoreContactAccountEndpoint(contactId)
    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.post(url)
      dispatch(dataApiSuccess({ endpoint }))

      if (onSuccess) {
        onSuccess()
      } else {
        dispatch(showNotification({ messageObject: { id: 'notifications.accessRestored' } }))
        dispatch(clientContactLogin(contactId))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default restoreContactAccountOperation
