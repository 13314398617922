import cookies from 'component-cookie'

import isPresent from 'utils/isPresent'

const isClientLoggedIn = () => {
  const currentClientId = cookies('currentClientId')

  return isPresent(currentClientId)
}

export default isClientLoggedIn
