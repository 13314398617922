import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import { buildCustomBaseUrl } from 'lib/httpClient'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { getSignatureDisclosureEndpoint } from '../endpoints'
import { GET_SIGNATURE_DISCLOSURE } from '../types'

const getSignatureDisclosureOperation = createLogic({
  type: GET_SIGNATURE_DISCLOSURE,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { url, endpoint } = getSignatureDisclosureEndpoint

    const workspaceCode = currentWorkspaceCodeSelector(getState())

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, buildCustomBaseUrl(['workspaces', workspaceCode]))
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getSignatureDisclosureOperation
