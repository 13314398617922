import PropTypes from 'prop-types'

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'

const DaysCheckboxFieldComponent = ({ label, labelClassName, field, onChange, checked }) => (
  <>
    <input type="checkbox" id={field.name} name={field.name} onChange={onChange} checked={checked} />
    <label htmlFor={field.name} className={labelClassName}>
      <FormattedOrRawMessage message={label} />
    </label>
  </>
)

DaysCheckboxFieldComponent.defaultProps = {
  labelClassName: '',
}

DaysCheckboxFieldComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  field: PropTypes.shape().isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default DaysCheckboxFieldComponent
