const clientPayment = {
  'clientPayment.header.title.payment': 'Payment {paymentNumber}',
  'clientPayment.header.title.refund': 'Refund {paymentNumber}',
  'clientPayment.header.action.cancelPayment': 'Cancel payment',
  'clientPayment.header.action.resendRequest': 'Resend request',
  'clientPayment.header.action.view.invoice': 'View invoice',
  'clientPayment.header.action.view.receipt': 'View receipt',
  'clientPayment.header.action.view.creditNote': 'View credit note',
  'clientPayment.header.action.downloadPDF.invoice': 'Download invoice',
  'clientPayment.header.action.downloadPDF.receipt': 'Download receipt',
  'clientPayment.header.action.downloadPDF.creditNote': 'Download credit note',
  'clientPayment.header.action.viewAppointment': 'View _(appointment)',
  'clientPayment.header.action.cancelRecurringPayment': 'Cancel recurring payment',
  'clientPayment.header.button.payOnline': 'Pay online',
  'clientPayment.header.button.updatePaymentMethod': 'Update payment method',
  'clientPayment.button.updatePaymentMethod': 'update your payment method',
  'clientPayment.alert.updatePaymentMethod': 'There is a problem with your payment method. Please {link}.',
  'clientPayment.savePaymentMethod': 'Save payment method',
  'clientPayment.currentPaymentMethod': 'Current payment method',
  'clientPayment.newPaymentMethod': 'New payment method',
}

export default clientPayment
