import { without } from 'ramda'
import { createLogic } from 'redux-logic'
import build from 'redux-object'
import normalize from 'json-api-normalizer'

import isBlankState from 'utils/isBlankState'
import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import { removeFileSharedWithMeByIds, setBlankState } from '../actions'
import { RECEIVE_BUSINESS_STORAGE } from '../types'
import { filtersSelector, fileSharedWithMeIdsSelector } from '../selectors'

const receivedBusinessStorageOperation = createLogic({
  type: RECEIVE_BUSINESS_STORAGE,
  latest: true,

  async process({ getState, action: { data } }, dispatch, done) {
    const state = getState()
    const ids = fileSharedWithMeIdsSelector(state)
    const filterParams = filtersSelector(state)
    const currentUserProfile = currentUserProfileSelector(state)
    const businessStorage = build(normalize(data), 'businessStorage', data.data.id)

    if (currentUserProfile.id === businessStorage.userProfile.id) {
      done()
      return
    }

    if (businessStorage.discardedAt) {
      dispatch(removeFileSharedWithMeByIds([businessStorage.id]))
      dispatch(setBlankState(isBlankState({ params: filterParams, data: without([businessStorage.id], ids) })))
    }

    done()
  },
})

export default receivedBusinessStorageOperation
