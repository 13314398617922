import { combineReducers } from '@reduxjs/toolkit'
import * as types from './types'

const activeCategory = (state = null, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_CATEGORY:
      return action.activeCategory
    default:
      return state
  }
}

const navigationReducer = combineReducers({
  activeCategory,
})

export default navigationReducer
