import { noop } from 'lodash'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import isPresent from 'utils/isPresent'
import DatePickerComponent from './component'

class DatePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.shape().isRequired,
    date: PropTypes.shape(),
    onChangeOpen: PropTypes.func,
    showClearButton: PropTypes.bool,
    mountToElement: PropTypes.bool,
    hideSubmit: PropTypes.bool,
  }

  static defaultProps = {
    date: null,
    showClearButton: false,
    mountToElement: false,
    hideSubmit: false,
    onChangeOpen: noop,
  }

  state = {
    isOpen: false,
  }

  get isClearShown() {
    const { date, showClearButton } = this.props

    return isPresent(date) && showClearButton
  }

  handleOpenChange = () =>
    this.setState(prevState => {
      this.props.onChangeOpen(!prevState.isOpen)
      return { isOpen: !prevState.isOpen }
    })

  handleClear = event => {
    event.stopPropagation()

    if (!this.isClearShown) return

    this.props.onChange(null)
    this.handleOpenChange()
  }

  onChangeView = (_, e) => e.stopPropagation()

  onCalendarChange = date => {
    const { hideSubmit } = this.props
    // here date is JS Date with local tz, it's fine since onChange is Toolbar.setDate,
    // which uses only the date part of it, and react-big-calendar uses only local tz
    this.props.onChange(DateTime.fromJSDate(date))

    if (hideSubmit) {
      this.handleOpenChange()
    }
  }

  getPopupContainer = node => (this.props.mountToElement ? node : document.body)

  render = () => (
    <DatePickerComponent
      {...this.props}
      isOpen={this.state.isOpen}
      handleOpenChange={this.handleOpenChange}
      onCalendarChange={this.onCalendarChange}
      onMonthClick={this.onChangeView}
      onYearClick={this.onChangeView}
      onDecadeClick={this.onChangeView}
      isClearShown={this.isClearShown}
      onClear={this.handleClear}
      getPopupContainer={this.getPopupContainer}
      onViewChange={this.onViewChange}
    />
  )
}
export { DatePicker }
export default injectIntl(DatePicker)
