import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { addCalendarEvent } from 'state/concepts/bookings/actions'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { hideSidebar } from 'state/sidebar/actions'
import updateScheduleBookingFormToParams from 'utils/bookings/updateScheduleBookingFormToParams'
import { updateBookingEndpoint } from '../endpoints'
import { UPDATE_PENDING_BOOKING } from '../types'

const updatePendingBookingOperation = createLogic({
  type: UPDATE_PENDING_BOOKING,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const { uniqCode } = values
    const { url } = updateBookingEndpoint(uniqCode)
    const { timezoneOffset } = currentEmployeeSelector(getState())

    const params = {
      ...updateScheduleBookingFormToParams(values, timezoneOffset),
      include: ['service', 'booking-setting', 'user-profile', 'invited-members'],
    }

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data)

      dispatch(dataApiSuccess({ response }))
      dispatch(addCalendarEvent(uniqCode))
      dispatch(hideSidebar())

      dispatch(showNotification({ messageObject: { id: 'notifications.appointmentWasUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default updatePendingBookingOperation
