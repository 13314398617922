export const INSTALLATION_TYPE_BUTTON = 'button'
export const INSTALLATION_TYPE_LINK = 'link'
export const INSTALLATION_TYPE_BOTH = 'both'

export const INSTALLATION_TYPE_OPTIONS = [
  {
    value: INSTALLATION_TYPE_BUTTON,
    label: { id: 'shareBookingPage.integrationType.widgetButton' },
    hint: { id: 'shareBookingPage.integrationType.widgetButton.hint' },
  },
  {
    value: INSTALLATION_TYPE_LINK,
    label: { id: 'shareBookingPage.integrationType.widgetLink' },
    hint: { id: 'shareBookingPage.integrationType.widgetLink.hint' },
  },
  {
    value: INSTALLATION_TYPE_BOTH,
    label: { id: 'shareBookingPage.integrationType.both' },
    hint: { id: 'shareBookingPage.integrationType.both.hint' },
  },
]

export const INTEGRATION_TYPES = {
  embedded: 'embedded',
  side: 'side',
  code: 'code',
  slug: 'slug',
}

export const WIDGET_STATUSES = {
  active: 'active',
  inactive: 'inactive',
}
