import spanWithClass from 'utils/locales/spanWithClass'
import DocumentSuccess from 'views/shared/icons/DocumentSuccess'
import DocumentPending from 'views/shared/icons/DocumentPending'
import DocumentCanceled from 'views/shared/icons/DocumentCanceled'

export const VOID_REASON_MAX_LENGTH = 1000

export const ETHERPAD_DOCUMENT_STATUSES = {
  waitingForSignature: 'waitingForSignature',
  voided: 'voided',
  signed: 'signed',
  declined: 'declined',
}

export const ETHERPAD_DOCUMENT_SIGNER_STATUSES = {
  awaiting: 'awaiting',
  sentForSignature: 'sentForSignature',
  signed: 'signed',
}

export const ETHERPAD_DOCUMENT_ICONS = {
  [ETHERPAD_DOCUMENT_STATUSES.signed]: DocumentSuccess,
  [ETHERPAD_DOCUMENT_STATUSES.waitingForSignature]: DocumentPending,
  [ETHERPAD_DOCUMENT_STATUSES.declined]: DocumentCanceled,
  [ETHERPAD_DOCUMENT_STATUSES.voided]: DocumentCanceled,
}

export const ETHERPAD_DOCUMENT_SIGNER_TYPES = {
  userProfile: 'UserProfile',
  clientProfile: 'ClientProfile',
}

export const DEFAULT_NAME = 'Untitled document'

export const MAX_NAME_INPUT_LENGTH = 240

export const DOWNLOAD_FORMATS = ['doc', 'pdf', 'txt', 'html', 'odt'] // Not support from the box ['docx', 'rtf']

export const ETHERPAD_DOCUMENT_PERMISSIONS = {
  viewer: 'viewer',
  commenter: 'commenter',
  editor: 'editor',
}

export const ETHERPAD_DOCUMENT_PERMISSION_OPTIONS = [
  {
    key: ETHERPAD_DOCUMENT_PERMISSIONS.viewer,
    value: ETHERPAD_DOCUMENT_PERMISSIONS.viewer,
    label: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.viewer.label',
      values: { span: spanWithClass('font-700 main-radio__text--small') },
    },
    description: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.viewer.description',
      values: { span: spanWithClass('text-caption') },
    },
  },
  {
    key: ETHERPAD_DOCUMENT_PERMISSIONS.commenter,
    value: ETHERPAD_DOCUMENT_PERMISSIONS.commenter,
    label: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.commenter.label',
      values: { span: spanWithClass('font-700 main-radio__text--small') },
    },
    description: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.commenter.description',
      values: { span: spanWithClass('text-caption') },
    },
  },
  {
    key: ETHERPAD_DOCUMENT_PERMISSIONS.editor,
    value: ETHERPAD_DOCUMENT_PERMISSIONS.editor,
    label: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.editor.label',
      values: { span: spanWithClass('font-700 main-radio__text--small') },
    },
    description: {
      id: 'etherpadDocuments.shareDocumentWithClientModal.option.editor.description',
      values: { span: spanWithClass('text-caption') },
    },
  },
]

export const SIGNING_TYPES = {
  meAndOthers: 'meAndOthers',
  onlyOther: 'onlyOther',
  onlyMe: 'onlyMe',
}

export const SIGNING_OPTIONS = [
  {
    value: SIGNING_TYPES.meAndOthers,
    label: { id: 'etherpadDocuments.signOptions.meAndOthers.label' },
    description: { id: 'etherpadDocuments.signOptions.meAndOthers.description' },
  },
  {
    value: SIGNING_TYPES.onlyOther,
    label: { id: 'etherpadDocuments.signOptions.onlyOther.label' },
    description: { id: 'etherpadDocuments.signOptions.onlyOther.description' },
  },
  {
    value: SIGNING_TYPES.onlyMe,
    label: { id: 'etherpadDocuments.signOptions.onlyMe.label' },
    description: { id: 'etherpadDocuments.signOptions.onlyMe.description' },
  },
]

export const MAX_SIGNATORIES_SIZE = 20
export const MAX_FULL_NAME_LENGTH = 100
export const MAX_SIGNATURE_LENGTH = 100

export const ETHERPAD_DOCUMENT_VISIBLE_SHARED_PERMISSIONS = 3

export const ACTIONS_WIDTH_WITH_SHARED = 270
export const ACTIONS_WIDTH_WITHOUT_SHARED = 155
