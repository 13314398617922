import yup from 'lib/yupLocalised'

export default yup.object().shape({
  online: yup.boolean().nullable(),
  locationIds: yup.array().when('online', {
    is: false,
    then: () => yup.array().of(yup.string()).required().min(1, { id: 'yup.mixed.required' }),
    otherwise: () => yup.array().of(yup.string()),
  }),
})
