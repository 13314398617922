const widgetFormToParams = ({
  name,
  color,
  status,
  urlSlug,
  position,
  selector,
  buttonText,
  buttonColor,
  buttonAsText,
  buttonPosition,
  buttonIconColor,
  buttonTextColor,
  installationType,
}) => ({
  name,
  color,
  status,
  position,
  selector,
  url_slug: urlSlug,
  button_text: buttonText,
  button_color: buttonColor,
  button_as_icon: !buttonAsText,
  button_position: buttonPosition,
  button_icon_color: buttonIconColor,
  button_text_color: buttonTextColor,
  installation_type: installationType,
})

export default widgetFormToParams
