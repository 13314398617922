import createAgreement from './createAgreement'
import fetchAgreement from './fetchAgreement'
import updateAgreement from './updateAgreement'
import deleteAgreement from './deleteAgreement'
import downloadAgreement from './downloadAgreement'
import updateAgreementActivation from './updateAgreementActivation'

export default [
  createAgreement,
  fetchAgreement,
  updateAgreement,
  deleteAgreement,
  downloadAgreement,
  updateAgreementActivation,
]
