const createService = {
  'createService.listOfServices': 'List of _(services)',
  'createService.newService': 'New _(service)',
  'createService.serviceDetails': '_(Service) details',
  'createService.serviceName': '_(Service) name',
  'createService.category': 'Category',
  'createService.selectCategory': 'Select category',
  'createService.description': 'Description (optional)',
  'createService.descriptionPlaceholder': 'Tell about _(service)',
  'createService.serviceDurations': '_(Service) duration',
  'createService.serviceLocation': '_(Service) location <span>(optional)</span>',
  'createService.locationDescription':
    '_(Clients) can book this _(service) in the following locations (on booking page)',
  'createService.availableOnline': 'Available online',
  'createService.availableOnlineTooltip': 'To make _(service) available online - enable feature ',
  'createService.availableOnlineTooltipAction': 'Accept video calls',
  'createService.offlineLocations': 'Offline locations',
  'createService.offlineLocations.deactivatedOption': '{name} (deactivated)',
  'createService.serviceSettings': '_(Service) settings',
  'createService.timeSlotsType': 'Time slot type',
  'createService.setTypeOfTimeSlots': 'Set type of time slots that your _(clients) will see:',
  'createService.bymMeetingDuration': 'By meeting duration',
  'createService.byCustomInterval': 'By custom interval',
  'createService.setBeforeAfterTime':
    'Set time for _a(expert) to prepare before consultation or make necessary notes after it is completed.',
  'createService.anytime': 'Anytime',
  'createService.minLeadTime': 'Min lead time',
  'createService.videoRecordingOptions': 'Video recording options',
  'createService.recordVideo': '_(Expert) is able to record meetings',
  'createService.shareVideo': '_(Client) is able to record meetings',
  'createService.backToPreviousStep': 'Back to previous step',
  'createService.chooseUnits': 'Choose Unit',
  'createService.bookingPagePayment': 'How does _(client) have to pay through the booking page?',
  'createService.prepaymentRequired': 'Prepayment required',
  'createService.setPaymentMethods': 'Set up payment methods',
  'createService.connectPaymentMethodOwner': 'Connect Stripe or PayPal to charge prepayment.',
  'createService.connectPaymentMethodAdminOrExpert':
    'Ask your workspace owner to connect Stripe or PayPal to charge prepayment.',
  'createService.prepaymentOn': 'Charge _(client) the full price during booking.',
  'createService.prepaymentOff': '_(Client) will be able to pay later with connected payment methods.',
  'createService.serviceStatus': '_(Service) status',
  'createService.serviceStatusDescription':
    'Active _(services) are visible for _(clients) and can be booked. When you deactivate _a(service), _(clients) don’t see it.',
  'createService.serviceStatusAlert':
    'Make the _(service) available online, or assign active offline location, to activate it.',
  'createService.status': 'Status: ',
  'createService.activated': 'Activated',
  'createService.deactivated': 'Deactivated',
  'createService.selectTeamMembersToAssign': 'Select team members to assign (optional)',
  'createService.experts': 'Assign _(experts)',
  'createService.addEmployees': 'Add _(expert)',
  'createService.pricePerSession': 'Price per session',
  'createService.pricePerSessionPlaceholder': '$',
  'createService.addExpertOtherPrice': 'Add _(expert) with another price',
  'createService.cancelNewService': 'Cancel New _(Service)?',
  'createService.cancelText': 'Are you sure you want to cancel new _(service)? All data will be lost.',
}

export default createService
