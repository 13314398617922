import fetchLocations from './fetchLocations'
import filterLocations from './filterLocations'
import searchLocations from './searchLocations'
import createLocation from './createLocation'
import toggleLocationStatus from './toggleLocationStatus'
import editLocation from './editLocation'
import getLocation from './getLocation'
import deleteLocation from './deleteLocation'

export default [
  fetchLocations,
  filterLocations,
  searchLocations,
  createLocation,
  editLocation,
  getLocation,
  toggleLocationStatus,
  deleteLocation,
]
