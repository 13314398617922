import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import build from 'redux-object'

import { DEFAULT_MAPPINGS } from 'lib/constants/customNames'
import { workspacesHttpClient } from 'lib/httpClient'
import { customNamesMappingRoute } from 'lib/apiRoutes'
import isPresent from 'utils/isPresent'
import interpolateLocales from 'utils/locales/interpolateLocales'
import { PROCESS_LOCALES } from 'state/concepts/customNames/types'
import { setLocales } from 'state/intl/actions'

const processLocalesOperation = createLogic({
  type: PROCESS_LOCALES,
  latest: true,

  async process({ action: { workspaceCode } }, dispatch, done) {
    let mappings = DEFAULT_MAPPINGS

    if (isPresent(workspaceCode)) {
      try {
        const { data } = await workspacesHttpClient.get(`/${workspaceCode}${customNamesMappingRoute}`)
        const response = normalize(data, { camelizeKeys: false })
        const customName = build(response, 'custom-name', data.data.id)
        mappings = customName.mappings
        // eslint-disable-next-line no-empty
      } catch (_e) {}
    }

    dispatch(setLocales(interpolateLocales(mappings)))
    done()
  },
})

export default processLocalesOperation
