import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import Spinner from 'views/shared/icons/Spinner'
import { currentPageSelector, pageCountSelector } from 'state/data/selectors'
import { searchChatUsers as searchChatUsersAction } from 'state/concepts/chatUsers/actions'
import { searchChatUsersEndpoint } from 'state/concepts/chatUsers/endpoints'

class Loader extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    searchChatUsers: PropTypes.func.isRequired,
    chat: PropTypes.shape({
      ownerId: PropTypes.number.isRequired,
      chatUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    searchQuery: PropTypes.string.isRequired,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
    chat: null,
  }

  handleEnter = () => {
    const { chat, searchQuery, searchChatUsers, currentPage, pageCount } = this.props

    if (pageCount >= currentPage + 1) {
      searchChatUsers({ chat, query: searchQuery, page: currentPage + 1 })
    }
  }

  render() {
    const { isLoading } = this.props

    if (isLoading !== false) {
      return <Spinner />
    }

    return <Waypoint onEnter={this.handleEnter} bottomOffset="-100px" />
  }
}

const mapStateToProps = state => ({
  pageCount: pageCountSelector(state, searchChatUsersEndpoint.endpoint),
  currentPage: currentPageSelector(state, searchChatUsersEndpoint.endpoint),
})

const mapDispatchToProps = {
  searchChatUsers: searchChatUsersAction,
}

export { Loader as LoaderContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Loader)
