import { compose, filter, prop, find, propEq, reduce, map, pick } from 'ramda'

import isPresent from 'utils/isPresent'
import { FIELD_TYPES, CUSTOM_FIELD_MODAL_ACTIONS } from 'lib/constants/customField'
import Text from 'views/shared/icons/Text'
import CalendarO from 'views/shared/icons/CalendarO'
import CircleChecked from 'views/shared/icons/CircleChecked'
import CheckedSquare from 'views/shared/icons/CheckedSquare'

export const updateOptionsPriority = options => options.map((question, index) => ({ ...question, priority: index }))

export const prepareOptionsAttributes = (options = []) =>
  updateOptionsPriority(filter(compose(isPresent, prop('name')), options))

export const hasOptions = fieldType => [FIELD_TYPES.radioButton, FIELD_TYPES.checkBox].includes(fieldType)

export const buildCreateOptionsParams = ({ fieldType, fieldOptions }) =>
  hasOptions(fieldType) ? prepareOptionsAttributes(fieldOptions) : []

export const getOptionsToDelete = (options, initialOptions) =>
  reduce(
    (acc, option) => {
      if (!find(propEq(option.id, 'id'), options)) {
        return [...acc, { id: option.id, _destroy: true }]
      }
      return acc
    },
    [],
    initialOptions,
  )

export const buildUpdateOptionsParams = ({ fieldType, fieldOptions, initialOptions }) => {
  if (!hasOptions(fieldType)) {
    return []
  }
  const options = prepareOptionsAttributes(fieldOptions)
  const optionsToDelete = getOptionsToDelete(options, initialOptions)

  return [...map(pick(['id', 'name', 'priority']), options), ...optionsToDelete]
}

export const isUpdate = actionType => actionType === CUSTOM_FIELD_MODAL_ACTIONS.update

export const fieldTypeIcons = {
  text: Text,
  date: CalendarO,
  radioButton: CircleChecked,
  checkBox: CheckedSquare,
}
