import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { omit, pluck } from 'ramda'

import generateSortString from 'utils/generateSortString'
import { TABLE_ENTITIES } from 'lib/constants/tableData'
import { tablePaginationSelector, tableOrderDirectionsSelector } from 'state/tableData/selectors'
import { setCurrentPage } from 'state/tableData/actions'
import isPageOverflow from 'utils/isPageOverflow'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setFormIds, fetchForms, setBlankState } from 'state/concepts/forms/actions'
import { FETCH_FORMS } from 'state/concepts/forms/types'
import { fetchFormsEndpoint } from 'state/concepts/forms/endpoints'
import { filtersSelector } from 'state/concepts/forms/selectors'
import isBlankState from 'utils/isBlankState'
import { formsFilterToParams } from 'utils/forms'

const fetchFormsOperation = createLogic({
  type: FETCH_FORMS,
  latest: true,

  async process({ httpClient, getState, action: { page, formType, filters } }, dispatch, done) {
    const { endpoint, url } = fetchFormsEndpoint
    const sort = generateSortString(tableOrderDirectionsSelector(getState(), TABLE_ENTITIES[formType]))

    const params = {
      page: {
        ...tablePaginationSelector(getState(), TABLE_ENTITIES[formType]),
        ...page,
      },
      sort,
      filter: {
        form_type: formType,
        ...formsFilterToParams(filtersSelector(getState())),
        ...filters,
      },
      include: 'form-category',
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(setBlankState(isBlankState({ params: omit(['form_type'], params.filter), data: data.data })))
      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setFormIds(pluck('id', data.data)))
    } catch (error) {
      if (isPageOverflow(error)) {
        dispatch(setCurrentPage(TABLE_ENTITIES[formType], 1))
        dispatch(fetchForms(formType))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default fetchFormsOperation
