import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { currentUserProfileSelector } from 'state/concepts/session/selectors'
import AttachedFileComponent from './component'

class AttachedFileContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({
      chatUserId: PropTypes.number,
    }).isRequired,
    chatUserId: PropTypes.number.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: undefined,
  }

  get canRemove() {
    const {
      currentUser: { chatUserId: currentChatUserId },
      chatUserId,
    } = this.props

    return currentChatUserId === chatUserId
  }

  handleClick = () => {
    const { id, onClick } = this.props

    onClick?.(id)
  }

  render() {
    return <AttachedFileComponent {...this.props} onClick={this.handleClick} canRemove={this.canRemove} />
  }
}

const mapStateToProps = state => ({
  currentUser: currentUserProfileSelector(state),
})

export { AttachedFileContainer as AttachedFileContainerContainer }
export default connect(mapStateToProps)(AttachedFileContainer)
