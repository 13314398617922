import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Pause = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M341.333 192c-47.128 0-85.333 38.205-85.333 85.333v469.336c0 47.123 38.205 85.331 85.333 85.331v0c47.128 0 85.334-38.208 85.334-85.331v-469.336c0-47.128-38.205-85.333-85.334-85.333v0zM682.669 192c-47.13 0-85.336 38.205-85.336 85.333v469.336c0 47.123 38.205 85.331 85.336 85.331v0c47.123 0 85.331-38.208 85.331-85.331v-469.336c0-47.128-38.208-85.333-85.331-85.333v0z" />
  </svg>
)

Pause.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Pause.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Pause
