import fileDownload from 'js-file-download'
import { createLogic } from 'redux-logic'

import { CONTENT_DISPOSITION_FILENAME_REGEX } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import { downloadEmptyFormEndpoint, downloadSubmittedFormEndpoint } from 'state/concepts/intakeForm/endpoints'
import { DOWNLOAD_FORM } from 'state/concepts/intakeForm/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'

const downloadForm = createLogic({
  type: DOWNLOAD_FORM,
  latest: false,

  async process({ httpClient, action: { formId, formSubmissionId } }, dispatch, done) {
    const { endpoint, url } = formSubmissionId
      ? downloadSubmittedFormEndpoint(formSubmissionId)
      : downloadEmptyFormEndpoint(formId)

    dispatch(dataApiRequest({ endpoint }))
    try {
      const { data, headers } = await httpClient.get(url, { responseType: 'blob' })
      const filename = headers['content-disposition'].match(CONTENT_DISPOSITION_FILENAME_REGEX)[1]

      fileDownload(data, filename)

      dispatch(dataApiSuccess({ endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default downloadForm
