import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchPublicWebsite = ({ response }) => ({
  type: types.FETCH_PUBLIC_WEBSITE,
  response,
})

export const fetchPublicWebsiteReviews = (pageParams = {}) => ({
  type: types.FETCH_PUBLIC_WEBSITE_REVIEWS,
  pageParams,
})

export const addReviewIds = reviewIds => ({
  type: types.ADD_REVIEW_IDS,
  reviewIds,
})

export const setWebsite = websiteId => ({
  type: types.SET_WEBSITE,
  websiteId,
})

export const fetchWebsite = (skipErrorHandler = false) => ({
  type: types.FETCH_WEBSITE,
  skipErrorHandler,
})

export const setWebsiteDraft = websiteDraft => ({
  type: types.SET_WEBSITE_DRAFT,
  websiteDraft,
})

export const resetWebsiteDraft = () => ({
  type: types.RESET_WEBSITE_DRAFT,
})

export const createWebsite = makeFormSubmitAction(types.CREATE_WEBSITE)
export const updateGeneralDetails = makeFormSubmitAction(types.UPDATE_GENERAL_DETAILS)
export const updateContentDetails = makeFormSubmitAction(types.UPDATE_CONTENT_DETAILS)
export const fetchPublicProviders = (pageParams = {}) => ({
  type: types.FETCH_PUBLIC_PROVIDERS,
  pageParams,
})

export const addProviders = ids => ({
  type: types.ADD_PROVIDERS,
  ids,
})

export const deleteWebsite = () => ({
  type: types.DELETE_WEBSITE,
})

export const fetchPublicServices = (pageParams = {}) => ({
  type: types.FETCH_PUBLIC_SERVICES,
  pageParams,
})

export const addServices = ids => ({
  type: types.ADD_SERVICES,
  ids,
})

export const fetchPublicLocations = () => ({
  type: types.FETCH_PUBLIC_LOCATIONS,
})

export const addLocations = ids => ({
  type: types.ADD_LOCATIONS,
  ids,
})

export const publishDraftWebsite = () => ({
  type: types.PUBLISH_DRAFT_WEBSITE,
})
