import { createSelector } from '@reduxjs/toolkit'
import { compose, find, path, pathEq, prop, propEq, propOr } from 'ramda'
import build from 'redux-object'

import { currentClientIdSelector, currentEmployeeIdSelector } from 'state/concepts/session/selectors'
import { fetchVideoConferenceEndpoint, toggleVideoRecordingsEndpoint } from 'state/concepts/videoConference/endpoints'
import { dataSelector, loadingSelector } from 'state/data/selectors'
import isPresent from 'utils/isPresent'

export const videoConferenceIdSelector = path(['videoConference', 'videoConferenceId'])

export const isRecordingSelector = path(['videoConference', 'isRecording'])

export const localSettingsSelector = path(['videoConference', 'localSettings'])

export const isPreviewSelector = path(['videoConference', 'isPreview'])

export const isScreenSharedSelector = path(['videoConference', 'isScreenShared'])

export const isSoundTrackedSelector = path(['videoConference', 'isSoundTracked'])

export const chatMessagesSelector = path(['videoConference', 'chatMessages'])

export const isSidebarOpenSelector = path(['videoConference', 'toggleSidebarOpen'])

export const videoRecordEventIdsSelector = path(['videoConference', 'videoRecordEventIds'])

export const videoConferenceSelector = createSelector(
  [videoConferenceIdSelector, dataSelector],
  (videoConferenceId, data) => (videoConferenceId ? build(data, 'videoConference', videoConferenceId) : null),
)

export const videoConferenceLoadingSelector = (state, uniqCode) =>
  loadingSelector(state, fetchVideoConferenceEndpoint(uniqCode).endpoint)

export const toggleRecordingLoadingSelector = (state, uniqCode) =>
  loadingSelector(state, toggleVideoRecordingsEndpoint(uniqCode).endpoint)

export const videoConferenceBookingSelector = createSelector(videoConferenceSelector, prop('booking'))

export const serviceNameSelector = createSelector(videoConferenceBookingSelector, path(['service', 'name']))

export const videoConferenceBookingIdSelector = createSelector(videoConferenceSelector, path(['booking', 'id']))

export const hasRecordsSelector = createSelector(videoRecordEventIdsSelector, isPresent)

export const isRecordEnabledSelector = createSelector(
  [currentEmployeeIdSelector, videoConferenceSelector],
  (currentEmployeeId, videoConference) =>
    currentEmployeeId ? path(['bookingSetting', 'consultantCanRecordVideo'], videoConference) : false,
)

export const isConferenceHostSelector = createSelector(
  [currentEmployeeIdSelector, videoConferenceSelector],
  (currentEmployeeId, videoConference) =>
    !!currentEmployeeId && pathEq(currentEmployeeId, ['booking', 'userProfile', 'id'], videoConference),
)

export const isConferenceClientSelector = createSelector(
  [currentClientIdSelector, videoConferenceSelector],
  (currentClientId, videoConference) =>
    !!currentClientId && pathEq(currentClientId, ['booking', 'clientProfile', 'id'], videoConference),
)

export const participantProfileSelector = createSelector(
  [(_, identity) => identity, videoConferenceSelector],
  (identity, videoConference) => {
    const { id, key, type } = JSON.parse(identity)

    return { ...compose(find(propEq(id || key, 'id')), propOr([], 'participants'))(videoConference), type }
  },
)
