import { DateTime } from 'luxon'

import { TASK_TYPES, VIEWS } from 'lib/constants/tasks'

export const getTaskType = ({ dueDate }) => {
  if (!dueDate) {
    return TASK_TYPES.noDueDate
  }

  if (DateTime.fromISO(dueDate) >= DateTime.now()) {
    return TASK_TYPES.upcoming
  }

  return TASK_TYPES.overdue
}

export const getTaskCounter = task => `${getTaskType(task)}TasksCount`

export const getSort = (isCompleted, view) => {
  if (isCompleted) return '-completed_at'
  return view === VIEWS.list ? 'priority' : 'due_date,-created_at'
}
