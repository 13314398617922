const guest = {
  'guest.account.settings.unsubscribeTitle': 'You are unsubscribed from the mailing list',
  'guest.account.settings.unsubscribeSubtitle':
    'Note! You’ll still receive emails related to your account and personal data updates and refunds.',
  'guest.account.settings.subscribeTitle': 'You are subscribed for the mailing list',
  'guest.account.settings.subscribeAgain': 'Subscribe again',
  'guest.account.settings.unsubscribe': 'Unsubscribe',
}

export default guest
