// Client Account routes
export const clientLoginRoute = '/client/login'
export const clientLinkExpiredRoute = '/client/link_expired'
export const clientRoute = clientId => `/client/${clientId}`
export const clientBookingsRoute = workspaceCode => `/client/${workspaceCode}/bookings`
export const clientChatsRoute = workspaceCode => `/client/${workspaceCode}/chats`
export const clientFormsRoute = workspaceCode => `/client/${workspaceCode}/forms`
export const clientAgreementsRoute = workspaceCode => `/client/${workspaceCode}/agreements`
export const clientDocumentsRoute = workspaceCode => `/client/${workspaceCode}/documents`
export const clientDocumentRoute = (workspaceCode, documentId) => `/client/${workspaceCode}/documents/${documentId}`
export const clientDocumentReviewRoute = (workspaceCode, documentId) =>
  `/client/${workspaceCode}/documents/${documentId}/review`
export const clientFilesRoute = workspaceCode => `/client/${workspaceCode}/files`
export const clientUploadedFilesPreviewRoute = workspaceCode => `/client/${workspaceCode}/uploaded_files/preview`
export const clientSharedFilesPreviewRoute = workspaceCode => `/client/${workspaceCode}/shared_files/preview`
export const clientPaymentsRoute = workspaceCode => `/client/${workspaceCode}/payments`
// TODO: remove this route after removing cypress tests linked to it
export const clientReviewsRoute = workspaceCode => `/client/${workspaceCode}/reviews`
export const clientProfileRoute = workspaceCode => `/client/${workspaceCode}/profile`
export const clientAccountSettingsGeneralRoute = workspaceCode => `/client/${workspaceCode}/account_settings/general`
export const clientAccountSettingsNotificationsRoute = workspaceCode =>
  `/client/${workspaceCode}/account_settings/notifications`
export const clientAccountSettingsRestoreRoute = workspaceCode => `/client/${workspaceCode}/account_settings/restore`
export const clientLoginAccountsRoute = '/client/login/accounts'

export const clientBookingRoute = (workspaceCode, bookingUniqCode) =>
  `/client/${workspaceCode}/bookings/${bookingUniqCode}`
export const clientBookingReviewRoute = (workspaceCode, bookingUniqCode) =>
  `/client/${workspaceCode}/bookings/${bookingUniqCode}/review`
export const clientBookingReviewSuccessRoute = (workspaceCode, bookingUniqCode) =>
  `/client/${workspaceCode}/bookings/${bookingUniqCode}/review/success`
export const clientBookingReviewNotAbleToSubmitRoute = (workspaceCode, bookingUniqCode) =>
  `/client/${workspaceCode}/bookings/${bookingUniqCode}/review/not_able_to_submit`
export const clientBookingReviewExpiredRoute = (workspaceCode, bookingUniqCode) =>
  `/client/${workspaceCode}/bookings/${bookingUniqCode}/review/link_expired`

export const clientChatRoute = (workspaceCode, chatUniqCode) => `/client/${workspaceCode}/chats/${chatUniqCode}`

export const clientPaymentRoute = (workspaceCode, transactionId) => `/client/${workspaceCode}/payments/${transactionId}`
export const clientPaymentCheckoutRoute = (workspaceCode, paymentId) =>
  `/client/${workspaceCode}/payments/${paymentId}/checkout`
export const clientUpdatePaymentMethodRoute = (workspaceCode, paymentId, recurringPaymentId) =>
  `/client/${workspaceCode}/payments/${paymentId}/recurring_payments/${recurringPaymentId}/update_payment_method`

export const clientFormsSubmitRoute = (workspaceCode, clientProfileFormId) =>
  `/client/${workspaceCode}/forms/${clientProfileFormId}/submit`
export const clientFormsSubmissionPreviewRoute = (workspaceCode, formSubmissionId) =>
  `/client/${workspaceCode}/forms/submission_preview/${formSubmissionId}`
export const clientFormsSubmissionSignRoute = (workspaceCode, clientProfileFormId) =>
  `/client/${workspaceCode}/forms/${clientProfileFormId}/sign`

export const clientFormsSubmitSuccessRoute = workspaceCode => `/client/${workspaceCode}/forms/submit/success`
export const clientFormsSignSuccessRoute = workspaceCode => `/client/${workspaceCode}/forms/sign/success`
export const clientFormsDeclineToSignSuccessRoute = workspaceCode =>
  `/client/${workspaceCode}/forms/decline_to_sign/success`

export const clientWaitlistRequestsEditRoute = (workspaceCode, waitlistRequestId) =>
  `/client/${workspaceCode}/waitlist_requests/${waitlistRequestId}/edit`

// Guest Client Account routes
export const guestLinkExpiredRoute = '/guest/link_expired'
export const guestSettingsRoute = workspaceCode => `/guest/${workspaceCode}/settings`
export const guestBookingsRoute = workspaceCode => `/guest/${workspaceCode}/bookings`
export const guestUnsubscribeRoute = workspaceCode => `/guest/${workspaceCode}/unsubscribe`
export const guestFormsRoute = workspaceCode => `/guest/${workspaceCode}/documents`
export const guestFilePreviewRoute = workspaceCode => `/guest/${workspaceCode}/documents/file_preview`

export const guestAccountSettingsRestoreRoute = workspaceCode => `/guest/${workspaceCode}/account_settings/restore`
export const guestAccountSettingsNotificationsRoute = workspaceCode =>
  `/guest/${workspaceCode}/account_settings/notifications`

export const guestPaymentCheckoutRoute = (workspaceCode, paymentId) =>
  `/guest/${workspaceCode}/payments/${paymentId}/checkout`
export const guestBookingRecurrenceRoute = (workspaceCode, bookingRecurrenceId) =>
  `/guest/${workspaceCode}/booking_recurrence/${bookingRecurrenceId}`

export const guestBookingsAcceptRoute = (workspaceCode, bookingUniqCode) =>
  `/guest/${workspaceCode}/bookings/${bookingUniqCode}/accept`

export const guestFormsSubmitRoute = (workspaceCode, clientProfileFormId) =>
  `/guest/${workspaceCode}/documents/${clientProfileFormId}/submit`
export const guestFormsSubmissionSignRoute = (workspaceCode, clientProfileFormId) =>
  `/guest/${workspaceCode}/documents/${clientProfileFormId}/sign`
export const guestFormsSubmitSuccessRoute = workspaceCode => `/guest/${workspaceCode}/documents/submit/success`
export const guestFormsSignSuccessRoute = workspaceCode => `/guest/${workspaceCode}/documents/sign/success`
export const etherpadDocumentsFailToSignRoute = '/etherpad_documents/fail_to_sign'
export const etherpadDocumentsNoPermissionToSignRoute = '/etherpad_documents/no_permission_to_sign'
export const etherpadDocumentsDeclineToSignRoute = '/etherpad_documents/decline_to_sign'
export const guestFormsDeclineToSignSuccessRoute = workspaceCode =>
  `/guest/${workspaceCode}/documents/decline_to_sign/success`
export const guestDocumentNotFoundRoute = '/guest/document_not_found'
export const guestDocumentReviewRoute = (workspaceCode, documentId) =>
  `/guest/${workspaceCode}/etherpad_documents/${documentId}/review`

// Waitlist
export const guestWaitlistBookRoute = workspaceCode => `/guest/${workspaceCode}/bookings/accept_waitlist_request`
export const guestWaitlistAcceptSuccessRoute = workspaceCode =>
  `/guest/${workspaceCode}/bookings/accept_waitlist_request/success`

// In call chat
export const clientInCallChatRoute = (workspaceCode, id) => `/client/${workspaceCode}/in_call_chat/${id}`
