import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import redirect from 'utils/redirect'
import { getPortalUrl } from 'utils/clientPortal'
import requestErrorHandler from 'lib/requestErrorHandler'
import { isErrorStatusNotFound } from 'utils/getErrorStatus'
import { workspacesHttpClient } from 'lib/httpClient'
import { publicSearchRoute } from 'lib/apiRoutes'
import { dataApiSuccess } from 'state/data/actions'
import { FETCH_WORKSPACE } from 'state/concepts/login/types'

const fetchWorkspaceOperation = createLogic({
  type: FETCH_WORKSPACE,
  latest: true,

  async process({ action: { values, form } }, dispatch, done) {
    try {
      const params = {
        url: values.url,
      }
      const { data } = await workspacesHttpClient.get(publicSearchRoute, { params })
      const response = normalize(data)
      const redirectTo = getPortalUrl(values.url)

      dispatch(dataApiSuccess({ response }))
      redirect({ href: `${redirectTo}/login`, reload: true })
    } catch (error) {
      if (isErrorStatusNotFound(error)) {
        form.setErrors({ url: { id: 'login.workspaceNotFound' } })
      } else {
        requestErrorHandler({ error, dispatch, form })
      }
    }
    form.setSubmitting(false)
    done()
  },
})

export default fetchWorkspaceOperation
