import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import { companyBookingsRoute } from 'lib/routes'
import { sendMessage as sendMessageAction } from 'state/concepts/chat/actions'
import { fetchClient as fetchClientAction } from 'state/concepts/client/actions'
import { currentEmployeeSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { deleteWaitlistRequests, fetchWaitlistRequest } from 'state/concepts/waitlistRequests/actions'
import { showModal as showModalAction } from 'state/modal/actions'
import { showSidebar } from 'state/sidebar/actions'
import isAdminOrOwnerOrScheduler from 'utils/employeePermissions/isAdminOrOwnerOrScheduler'
import redirect from 'utils/redirect'
import { isRequestedPredicate, isSuspendedPredicate } from 'utils/waitlistRequests'

const useExpertActions = ({ waitlist, feedId, isWaitlistPage }) => {
  const { id, userProfile, clientProfile, status } = waitlist || {}

  const dispatch = useDispatch()

  const currentEmployee = useSelector(currentEmployeeSelector)
  const workspace = useSelector(currentWorkspaceCodeSelector)

  const deleteWaitlist = useDispatchAction(deleteWaitlistRequests, id, feedId)
  const showModal = useDispatchAction(showModalAction)

  const isSharedByUser = currentEmployee?.id === userProfile?.id
  const isAdminOrOwnerOrSchedulerOrExpertCreator = isAdminOrOwnerOrScheduler(currentEmployee) || isSharedByUser

  const canEdit = isAdminOrOwnerOrSchedulerOrExpertCreator
  const canDelete = isAdminOrOwnerOrSchedulerOrExpertCreator
  const onDelete = () =>
    showModal({
      modalType: 'CONFIRM_MODAL',
      modalProps: {
        title: { id: 'waitlistRequests.actions.delete.title' },
        bodyText: [
          {
            id: 'waitlistRequests.actions.delete.description',
          },
        ],
        kind: 'danger',
        submitText: { id: 'waitlistRequests.deleteRequest' },
        dismissText: { id: 'shared.cancel' },
        onSubmit: deleteWaitlist,
      },
    })

  const isChatFeatureEnabled = useWorkspaceFeature(FEATURE_KEYS.chat)
  const isClientPortalEnabled = useWorkspaceFeature(FEATURE_KEYS.clientPortal)
  const canSendMessage = isChatFeatureEnabled && isClientPortalEnabled

  const onSendMessage = useDispatchAction(sendMessageAction, clientProfile?.id)
  const onWaitlistSendMessage = useDispatchAction(fetchClientAction, clientProfile?.id, onSendMessage)

  const onOpenDetails = useCallback(() => {
    dispatch(fetchWaitlistRequest(id))
    dispatch(
      showSidebar({
        sidebarType: SIDEBAR_COMPONENTS_TYPES.waitlistRequestDetails,
        sidebarProps: { waitlistRequestId: id, isWaitlistPage },
      }),
    )
  }, [dispatch, id, isWaitlistPage])
  const canBookAppointment =
    isAdminOrOwnerOrSchedulerOrExpertCreator && !isSuspendedPredicate(status) && !isRequestedPredicate(status)
  const onBookAppointment = () => {
    redirect({ href: companyBookingsRoute, workspace, query: { waitlistId: waitlist.id } })
  }

  return {
    canEdit,
    onDelete,
    canDelete,
    onSendMessage: isWaitlistPage ? onWaitlistSendMessage : onSendMessage,
    canSendMessage,
    onOpenDetails,
    canBookAppointment,
    onBookAppointment,
  }
}

export default useExpertActions
