const editService = {
  'editService.editService': 'Edit _(service)',
  'editService.details': 'Details',
  'editService.settings': 'Settings',
  'editService.experts': '_(Experts)',

  'editService.documents': 'Documents',
  'editService.documents.header': 'Assign documents',
  'editService.documents.intake.header': 'Forms',
  'editService.documents.intake.description':
    'Select forms _(clients) will be asked to complete before the _(appointment).',
  'editService.documents.intake.notFound.title': 'No forms to assign',
  'editService.documents.intake.notFound.description':
    'Create forms to ask _(clients) questions before _(appointments).',
  'editService.documents.intake.notFound.button': 'Create form',
  'editService.documents.intake.table.header.name': 'Forms',
  'editService.documents.intake.table.header.nameWithSelected':
    '{count} {count, plural, one {form} other {forms}} selected',
  'editService.documents.intake.table.row.previewTooltipText': 'Preview form',
  'editService.documents.intake.table.row.statusDeactivated': 'Deactivated',

  'editService.documents.agreement.header': '_(Service agreements)',
  'editService.documents.agreement.description':
    'Select _(service agreements) to ask _(clients) for their consent before the _(appointment).',
  'editService.documents.agreement.notFound.title': 'No _(service agreements) to assign',
  'editService.documents.agreement.notFound.description':
    'Create _(service agreements) to ask _(clients) for their consent before _(appointments).',
  'editService.documents.agreement.notFound.button': 'Create agreement',
  'editService.documents.agreement.table.header.name': '_(Service agreements)',
  'editService.documents.agreement.table.header.nameWithSelected':
    '{count} {count, plural, one {_(service agreement)} other {_(service agreements)}} selected',
  'editService.documents.agreement.table.row.previewTooltipText': 'Preview _(service agreement)',
  'editService.documents.agreement.table.row.statusDeactivated': 'Deactivated',

  'editService.sidebar.documents.title': 'Documents',
  'editService.sidebar.documents.description': 'Create forms to assign them to _(services).',
  'editService.sidebar.documents.createFormButton': 'Create form',
}

export default editService
