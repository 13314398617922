import normalize from 'json-api-normalizer'
import { path } from 'ramda'
import { createLogic } from 'redux-logic'

import { buildCustomBaseUrl } from 'lib/httpClient'
import { clientBookingReviewExpiredRoute, clientBookingReviewSuccessRoute } from 'lib/routes'
import { setBooking } from 'state/concepts/booking/actions'
import { getClientReviewTemplateEndpoint } from 'state/concepts/clientAccountReview/endpoints'
import { GET_REVIEW_TEMPLATE } from 'state/concepts/clientAccountReview/types'
import { dataApiFailure, dataApiRequest, dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'

const getClientReviewTemplateOperation = createLogic({
  type: GET_REVIEW_TEMPLATE,
  latest: true,

  async process({ httpClient, action }, dispatch, done) {
    const { workspaceCode, token, bookingUniqCode, response } = action
    const { endpoint, url } = getClientReviewTemplateEndpoint(bookingUniqCode)
    const params = {
      include: ['service', 'user-profile', 'review-setting'],
    }
    const config = buildCustomBaseUrl(['workspaces', workspaceCode])

    dispatch(dataApiRequest({ endpoint }))

    try {
      let responseResult
      if (token) {
        responseResult = await httpClient.get(url, { params, ...config, headers: { Authorization: token } })
      } else {
        responseResult = await httpClient.get(url, { params, ...config })
      }

      const { data } = responseResult

      if (path(['data', 'attributes', 'review-submitted'], data)) {
        redirect({
          href: clientBookingReviewSuccessRoute(workspaceCode, bookingUniqCode),
          query: { type: 'reviewed' },
          response,
        })
      } else {
        dispatch(setBooking(data.data.id))
        dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
      }
    } catch (error) {
      redirect({ href: clientBookingReviewExpiredRoute(workspaceCode, bookingUniqCode), response })

      dispatch(dataApiFailure({ endpoint }))
    }
    done()
  },
})

export default getClientReviewTemplateOperation
