import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import updateDataHelper from 'utils/updateDataHelper'
import requestErrorHandler from 'lib/requestErrorHandler'
import { RESEND_FORM } from 'state/concepts/intakeForm/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { fetchClientForms, resetClientForms } from 'state/concepts/companyClientForms/actions'
import { bookingIntakeFormsSelector } from 'state/concepts/booking/selectors'
import { FORM_TYPES } from 'lib/constants/forms'
import { fetchClientFeed } from 'state/concepts/companyClientFeed/actions'
import { resendFormEndpoint } from '../endpoints'

const resendFormOperation = createLogic({
  type: RESEND_FORM,
  latest: true,

  async process({ action: { formId, withAnswers, bookingId, clientId }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = resendFormEndpoint(formId)
    const state = getState()
    const forms = bookingIntakeFormsSelector(state)

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url, { with_answers: withAnswers })

      const updateBookingResponse = bookingId
        ? updateDataHelper(state.data, 'booking', bookingId, {
            relationships: {
              clientProfileForms: {
                data: [
                  ...forms.map(({ id }) => ({ id, type: 'clientProfileForm' })),
                  { id: data.data.id, type: 'clientProfileForm' },
                ],
              },
            },
          })
        : {}

      dispatch(dataApiSuccess({ endpoint, response: { ...normalize(data), ...updateBookingResponse } }))

      if (clientId) {
        dispatch(resetClientForms())
        dispatch(fetchClientForms(clientId, { formType: FORM_TYPES.intake }))
        dispatch(fetchClientFeed(clientId))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default resendFormOperation
