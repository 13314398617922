import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import updateDataHelper from 'utils/updateDataHelper'
import { currentEmployeeIdSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { fetchFilesSharedWithMe } from 'state/concepts/filesSharedWithMe/actions'
import { ALERT_KINDS } from 'lib/constants'
import { isErrorStatusForbidden } from 'utils/getErrorStatus'
import isPresent from 'utils/isPresent'
import { showNotification } from 'state/notifications/actions'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { hideModal } from 'state/modal/actions'
import redirect from 'utils/redirect'
import { companyBusinessFilesShareRoute } from 'lib/routes'
import { SHARE_MY_BUSINESS_FILE } from '../types'
import { shareMyBusinessFileEndpoint } from '../endpoints'

const shareMyBusinessFileOperation = createLogic({
  type: SHARE_MY_BUSINESS_FILE,
  latest: true,

  async process({ httpClient, action, getState }, dispatch, done) {
    const {
      values: { myBusinessFileId, newPermissions, businessStoragePermissions },
      form,
      onBack,
    } = action
    const state = getState()
    const currentEmployeeId = currentEmployeeIdSelector(state)
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const { url, endpoint } = shareMyBusinessFileEndpoint(myBusinessFileId)
    const source = isPresent(newPermissions) ? newPermissions : businessStoragePermissions
    const filePermissionsParams = source.map(({ userProfileId, permission, oldPermission, userProfile, id }) => ({
      id,
      user_profile_id: userProfileId || userProfile?.id,
      permission: permission || oldPermission,
      _destroy: !permission,
    }))
    const params = {
      business_storage_permissions: filePermissionsParams,
      include: ['user-profile', 'parent', 'business-storage-permissions.user-profile'],
    }
    const hasUpdatedOwnPermission = businessStoragePermissions.find(
      ({ userProfile }) => userProfile.id === currentEmployeeId,
    )
    const myRemovedPermission = businessStoragePermissions.find(
      ({ userProfile, permission }) => userProfile.id === currentEmployeeId && !permission,
    )

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.put(url, params)
      const response = normalize(data, { endpoint })

      const ancestorPermissions = source.filter(({ ancestorId }) => ancestorId)
      ancestorPermissions.forEach(({ id, permission }) => {
        const ancestorPermissionResponse = updateDataHelper(getState().data, 'ancestorPermission', id, {
          attributes: {
            permission,
          },
        })
        dispatch(dataApiSuccess({ response: ancestorPermissionResponse }))
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      if (myRemovedPermission?.ancestorId) {
        redirect({ href: companyBusinessFilesShareRoute, workspace: workspaceCode })
      }
      if (myRemovedPermission && !myRemovedPermission.ancestorId) {
        onBack?.()
      }
      !isPresent(newPermissions) &&
        hasUpdatedOwnPermission &&
        !myRemovedPermission?.ancestorId &&
        dispatch(fetchFilesSharedWithMe(undefined, { append: false }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.fileWasShared' },
        }),
      )
    } catch (error) {
      if (isErrorStatusForbidden(error)) {
        dispatch(hideModal())
        dispatch(
          showNotification({
            messageObject: { id: 'notifications.noPermissionToPerformAction' },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
      form.setSubmitting(false)
    }

    done()
  },
})

export default shareMyBusinessFileOperation
