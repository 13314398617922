import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Upload = ({ size, color, className, dataCy }) => (
  <svg
    preserveAspectRatio="none"
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="m230.4 87.403-84.299 84.299c-9.997 9.997-26.207 9.997-36.204 0s-9.997-26.207 0-36.204L237.896 7.499c9.997-9.997 26.206-9.997 36.204 0l127.999 127.999c9.997 9.997 9.997 26.207 0 36.204s-26.207 9.997-36.204 0l-84.298-84.297v245.396c0 14.139-11.461 25.601-25.601 25.601s-25.601-11.461-25.601-25.601V87.406zm230.398 270.996c0-14.139 11.461-25.601 25.601-25.601S512 344.259 512 358.399v89.599c0 35.346-28.654 64-64 64H64.001c-35.346 0-64.001-28.654-64.001-64v-89.599c0-14.139 11.463-25.601 25.601-25.601s25.601 11.461 25.601 25.601v89.599c0 7.069 5.73 12.799 12.799 12.799H448c7.069 0 12.799-5.731 12.799-12.799v-89.599z" />
  </svg>
)

Upload.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Upload.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Upload
