import PropTypes from 'prop-types'
import classNames from 'clsx'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'

import googleMapsUrlCreator from 'utils/location/googleMapsUrlCreator'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import stopPropagation from 'utils/stopPropagation'
import LocationO from '../icons/LocationO'
import OnlineLocationDetail from './OnlineLocationDetail'

const LocationDetails = ({ location, isShort, hasIcon, className, videoCallProvider, isTruncatedDeatailName }) =>
  location ? (
    <div className={classNames('d-flex gap-16', className)} data-cy="location-details">
      <div className="no-shrink w-20 text-center">
        {hasIcon && <LocationO size={18} className="in-blue-gray-200" />}
      </div>
      <div className="location-link d-flex">
        {!isShort && (
          <p className="mb-4 location-link__detail-name mr-4" data-cy="location-detail-name">
            {isTruncatedDeatailName ? (
              <TruncatedTextByWidthTooltip text={location.name} on={['hover']} />
            ) : (
              <span className="mr-4">
                <FormattedOrRawMessage message={location.name} />
              </span>
            )}
          </p>
        )}
        {location.coordinate && (
          <div>
            <a
              href={googleMapsUrlCreator(location.coordinate.x, location.coordinate.y)}
              target="_blank"
              rel="noreferrer"
              onClick={stopPropagation}
              className="main-link d-inline-block va-middle"
              data-cy="location-detail-address-link"
            >
              <TruncatedTextByWidthTooltip text={isShort ? location.name : location.addressLine} on={['hover']} />
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <OnlineLocationDetail isShort={isShort} videoCallProvider={videoCallProvider} />
  )

LocationDetails.defaultProps = {
  location: null,
  isShort: false,
  hasIcon: true,
  className: '',
  isTruncatedDeatailName: true,
}

LocationDetails.propTypes = {
  location: PropTypes.shape(),
  isShort: PropTypes.bool,
  hasIcon: PropTypes.bool,
  videoCallProvider: PropTypes.string.isRequired,
  className: PropTypes.string,
  isTruncatedDeatailName: PropTypes.bool,
}

export default LocationDetails
