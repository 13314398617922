import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { is } from 'ramda'
import { noop } from 'lodash'

import CircleCancel from 'views/shared/icons/CircleCancel'
import Tooltip from 'views/shared/Tooltip'
import { handleInputEvent } from 'utils/inputHelpers'
import InputMessage from '../InputMessage'
import CountrySelect from './CountrySelect'

const PhoneInputField = ({
  id,
  disabled,
  label,
  icon,
  field,
  form: { touched, errors },
  placeholder,
  mainInputClassName,
  isClearShown,
  onClear,
  defaultCountry,
  ...props
}) => {
  const intl = useIntl()
  const [country, setCountry] = useState(defaultCountry)
  const wrapperClassNames = classNames('main-input', mainInputClassName, {
    'main-input--disabled': disabled,
    'main-input--has-message': touched[field.name] && errors[field.name],
    'main-input--has-message-error': touched[field.name] && errors[field.name],
  })

  return (
    <div className={wrapperClassNames}>
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <PhoneInput
          data-cy="phone-input"
          value={field.value}
          placeholder={is(Object, placeholder) ? intl.formatMessage(placeholder) : placeholder}
          onChange={handleInputEvent(field, field.onChange, '')}
          onBlur={handleInputEvent(field, field.onBlur, '')}
          numberInputProps={{ className: 'main-input__field' }}
          countrySelectComponent={CountrySelect}
          onCountryChange={setCountry}
          defaultCountry={country}
          international
          addInternationalOption={false}
          flags={flags}
          disabled={disabled}
          {...props}
        />
        {isClearShown && (
          <Tooltip
            align={{ offset: [0, -6] }}
            placement="top"
            on={['hover']}
            overlayClassName="main-tooltip main-tooltip--no-pointer"
            icon={
              <button
                data-cy="clear-phone"
                className="main-input__phone-input-clear d-flex"
                type="button"
                onClick={onClear}
              >
                <CircleCancel className="main-input__phone-icon-clear" dataCy="search-clear-button" size={12} />
              </button>
            }
          >
            <span>
              <FormattedMessage id="shared.clear" />
            </span>
          </Tooltip>
        )}
      </div>
      {touched[field.name] && errors[field.name] && <InputMessage message={errors[field.name]} icon={icon} />}
    </div>
  )
}

PhoneInputField.defaultProps = {
  id: null,
  disabled: false,
  label: null,
  icon: null,
  placeholder: null,
  mainInputClassName: null,
  defaultCountry: 'US',
  isClearShown: false,
  onClear: noop,
}

PhoneInputField.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  mainInputClassName: PropTypes.string,
  isClearShown: PropTypes.bool,
  onClear: PropTypes.func,
}

export default PhoneInputField
