import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { moveToFolderEndpoint } from '../endpoints'
import { removeMyBusinessFileIds } from '../actions'
import { MOVE_TO_FOLDER } from '../types'

const moveToFolderOperation = createLogic({
  type: MOVE_TO_FOLDER,
  latest: true,

  async process({ httpClient, action: { fileId, parentId, kind, onSuccess } }, dispatch, done) {
    const { url, endpoint } = moveToFolderEndpoint(fileId)

    const params = {
      parent_id: parentId,
    }

    try {
      await httpClient.put(url, params)

      dispatch(hideModal())
      dispatch(removeMyBusinessFileIds([fileId]))
      dispatch(showNotification({ messageObject: { id: 'notifications.businessStorageMoved', values: { kind } } }))
      onSuccess?.()
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default moveToFolderOperation
