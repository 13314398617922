import { complement, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { currentClientPermissionsSelector } from 'state/concepts/client/selectors'

import { currentEmployeePermissionsSelector } from 'state/concepts/employee/selectors'
import { currentClientSelector } from 'state/concepts/session/selectors'

const usePermission = permissionKey => {
  const currentClient = useSelector(currentClientSelector)

  const companyPermissions = useSelector(currentEmployeePermissionsSelector)
  const clientPermissions = useSelector(currentClientPermissionsSelector)

  const permissions = currentClient ? clientPermissions : companyPermissions

  const shouldRedirect = complement(prop)(permissionKey, permissions)

  return shouldRedirect
}

export default usePermission
