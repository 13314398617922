const employeeTwoFactorSettings = {
  'employeeTwoFactorSettings.title': 'Two-step verification',
  'employeeTwoFactorSettings.description':
    'Protect your account from hackers by adding an extra layer of protection. We’ll send you a verification code to enter when you log in to ensure if it’s really you.',
  'employeeTwoFactorSettings.forbidToDisable':
    'You can’t disable two-step verification because it’s required in your workspace.',
  'employeeTwoFactorSettings.enabled': 'Enabled',
  'employeeTwoFactorSettings.email.title': 'Email',
  'employeeTwoFactorSettings.email.description': 'We’ll send a verification code to your email <span>{email}</span>',
  'employeeTwoFactorSettings.sms.title': 'Text message (SMS)',
  'employeeTwoFactorSettings.sms.description':
    'We will send you a security code to your phone number <span>{phoneNumber}</span>',
  'employeeTwoFactorSettings.app.title': 'Authenticator app',
  'employeeTwoFactorSettings.app.description':
    'The authenticator generates a code based on the current time and the secret key.',
  'employeeTwoFactorSettings.enable.title': 'Set up two-step verification',
  'employeeTwoFactorSettings.password.enterPassword': 'To continue, please enter your password.',
  'employeeTwoFactorSettings.password.enterPassword.label': 'Password',
  'employeeTwoFactorSettings.password.submit': 'Confirm password',
  'employeeTwoFactorSettings.password.continue': 'Continue',
  'employeeTwoFactorSettings.disable.title': 'Disable up two-step verification?',
  'employeeTwoFactorSettings.update.title': 'Update two-step verification method',
  'employeeTwoFactorSettings.selectMethod.title': 'Select two-step verification method',
  'employeeTwoFactorSettings.selectMethod.howDoYouWant':
    'How do you want to receive a verification code when you log in?',
  'employeeTwoFactorSettings.selectMethod.currentMethod.email': 'Currently, you are using your <span>email</span>.',
  'employeeTwoFactorSettings.selectMethod.currentMethod.sms': 'Currently, you are using <span>text message</span>.',
  'employeeTwoFactorSettings.selectMethod.currentMethod.app':
    'Currently, you are using <span>authenticator app</span>.',
  'employeeTwoFactorSettings.selectMethod.methods.email.title': 'Email',
  'employeeTwoFactorSettings.selectMethod.methods.email.description': 'Receive a verification code to email address.',
  'employeeTwoFactorSettings.selectMethod.methods.sms.title': 'Text message (SMS)',
  'employeeTwoFactorSettings.selectMethod.methods.sms.description': 'Receive a verification code to phone number.',
  'employeeTwoFactorSettings.selectMethod.methods.app.title': 'Authenticator app',
  'employeeTwoFactorSettings.selectMethod.methods.app.description': 'App generates a verification code for logging in.',
  'employeeTwoFactorSettings.setSms.title': 'Set two-step verification with text message (SMS)',
  'employeeTwoFactorSettings.setSms.description':
    'We’ll text a verification code to this mobile number whenever you log into your account.',
  'employeeTwoFactorSettings.setSms.codeIsSent': 'We just sent you a verification code. Please check your phone.',
  'employeeTwoFactorSettings.setSms.didNotGetCode': 'Didn’t get the code?',
  'employeeTwoFactorSettings.setSms.resendCode': 'Resend code',
  'employeeTwoFactorSettings.setSms.resendAvailableIn': 'Resend available in {remainingTime}',
  'employeeTwoFactorSettings.setSms.enable': 'Enable',
  'employeeTwoFactorSettings.setSms.verifyPhoneNumber': 'Verify phone number',
  'employeeTwoFactorSettings.setSms.otpCode.label': 'Verification code',
  'employeeTwoFactorSettings.setApp.title': 'Set two-step verification with authenticator app',
  'employeeTwoFactorSettings.setApp.step1':
    'Step 1: <span>Scan this QR code with any authenticator app (such as Google Authenticator, Authy, or Microsoft Authenticator).</span>',
  'employeeTwoFactorSettings.setApp.step1.scan': 'Can’t scan? Enter this code manually',
  'employeeTwoFactorSettings.setApp.step2': 'Step 2: <span>Enter the code generated by your authenticator app.</span>',
  'employeeTwoFactorSettings.setApp.otpCode.label': 'Verification code',

  'employeeTwoFactorSettings.backupCodes.title': 'Backup codes',
  'employeeTwoFactorSettings.backupCodes.newCodes': 'Generate new backup codes',
  'employeeTwoFactorSettings.backupCodes.submit': 'Generate backup codes',
  'employeeTwoFactorSettings.backupCodes.description':
    'Backup codes will help you to restore your account if you lose your access to your phone number or authentication app.',
  'employeeTwoFactorSettings.backupCodes.generate': 'Generate new codes',
  'employeeTwoFactorSettings.backupCodes.saveCodes':
    'Save this emergency backup codes and store it somewhere safe. If you ever lose access to your account, you can use this code to log in.',
  'employeeTwoFactorSettings.backupCodes.expired':
    'All of your backup codes were used and became expired. Here are new backup codes.',
  'employeeTwoFactorSettings.backupCodes.notes':
    'Please note that all backup codes generated before will no longer be valid.',
  'employeeTwoFactorSettings.remindMeLater': 'Remind me later',
  'employeeTwoFactorSettings.enableVerification': 'Enable two-step verification',
  'employeeTwoFactorSettings.setupWith.app': 'Set up two-step verification with authenticator app',
  'employeeTwoFactorSettings.protectAccount': 'Protect your account with two-step verification',
  'employeeTwoFactorSettings.learnMore.description':
    'Each time you log into your account, you’ll need your password and a verification code.',
  'employeeTwoFactorSettings.learnMore': 'Learn more',
  'employeeTwoFactorSettings.setupWith.sms': 'Set up two-step verification with text message (SMS)',
  'employeeTwoFactorSettings.setupWith.email': 'Set up two-step verification with email',
  'employeeTwoFactorSettings.authenticationEnabled': 'Two-factor authentication enabled!',
  'employeeTwoFactorSettings.openMyWorkspace': 'Open my workspace',
}

export default employeeTwoFactorSettings
