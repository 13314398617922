import classNames from 'clsx'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import useCurrentTime from 'hooks/audio/useCurrentTime'
import usePlayer from 'hooks/audio/usePlayer'
import useWavesurfer from 'hooks/audio/useWavesurfer'
import { DEFAULT_PLAYER_OPTIONS } from 'lib/constants/audio'
import { currentChatUserIdSelector } from 'state/concepts/session/selectors'
import { formatCountdownSeconds, formatDate } from 'utils/dateTime'
import profileName from 'utils/profileName'
import Actions from 'views/shared/AttachedFile/Actions'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'
import Pause from 'views/shared/icons/Pause'
import Play from 'views/shared/icons/Play'

const VoiceMessagesList = ({ id, createdAt, duration, url, chatUser, chatUserId, attachmentType }) => {
  const waveformRef = useRef(null)
  const playerOptions = useMemo(() => ({ ...DEFAULT_PLAYER_OPTIONS, url }), [url])
  const wavesurfer = useWavesurfer(waveformRef, playerOptions)
  const { isPlaying, handlePlay } = usePlayer(wavesurfer)
  const { currentTime } = useCurrentTime(wavesurfer)
  const currentChatUserId = useSelector(currentChatUserIdSelector)
  const canRemove = chatUserId === currentChatUserId

  return (
    <div className="main-modal--chat-item" data-cy="list-voice-message-info">
      <button type="button" onClick={handlePlay} className="chat-voice-message__player-figure">
        {isPlaying ? (
          <Pause className="chat-voice-message__player-icon" size={16} dataCy="audio-player-pause-button" />
        ) : (
          <Play className="chat-voice-message__player-icon" size={12} dataCy="audio-player-play-button" />
        )}
      </button>
      <div className="wave w-100 d-none" ref={waveformRef} />
      <div role="button" className={classNames('chat-msg-file__info c-pointer ml-12')}>
        <div className="mw-100 mb-0" data-cy="list-voice-message-date">
          <TruncatedTextByWidthTooltip
            text={formatDate(DateTime.fromISO(createdAt))}
            overlayClassName="ant-modal-tooltip"
            on={['hover']}
          />
        </div>
        <div className="d-flex">
          <div className="chat-msg-file__details chat-voice-message__details mr-20">
            <p className="mb-0 text-caption" data-cy="list-voice-message-duration">
              {formatCountdownSeconds(currentTime || duration)}
            </p>
          </div>
          <div
            className="chat-msg-file__details chat-voice-message__details mr-0 mb-0 text-caption"
            data-cy="list-voice-message-author"
          >
            <TruncatedTextByWidthTooltip text={profileName(chatUser)} on={['hover']} />
          </div>
        </div>
      </div>
      <Actions fileId={id} url={url} attachmentType={attachmentType} canRemove={canRemove} />
    </div>
  )
}

VoiceMessagesList.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  attachmentType: PropTypes.string.isRequired,
  chatUserId: PropTypes.number.isRequired,
  chatUser: PropTypes.shape().isRequired,
  duration: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
}

export default VoiceMessagesList
