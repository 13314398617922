import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { GET_ZOOM_CONNECT_LINK } from '../types'
import { getZoomConnectLinkEndpoint } from '../endpoints'
import { setZoomConnectLink } from '../actions'

const getZoomConnectLinkOperation = createLogic({
  type: GET_ZOOM_CONNECT_LINK,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { url, endpoint } = getZoomConnectLinkEndpoint

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(setZoomConnectLink(data.meta.url))
    } catch (error) {
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.zoomConnectionFailed' },
          kind: 'error',
        }),
      )
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default getZoomConnectLinkOperation
