import React from 'react'
import { withFormik } from 'formik'
import { propOr } from 'ramda'

import yup from 'lib/yupLocalised'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import { handleSubmit } from 'utils/form/handleSubmit'
import ManualPaymentModalComponent from './component'

class ManualPaymentModal extends React.Component {
  get isSubmitDisabled() {
    return isSubmitDisabled(this.props)
  }

  render() {
    return <ManualPaymentModalComponent {...this.props} isSubmitDisabled={this.isSubmitDisabled} />
  }
}

export { ManualPaymentModal as ManualPaymentModalContainer }
export default withFormik({
  mapPropsToValues: ({ manualPayment }) => ({ paymentInstructions: propOr('', 'paymentInstructions', manualPayment) }),
  validationSchema: yup.object().shape({
    paymentInstructions: yup.string().required(),
  }),
  handleSubmit,
})(ManualPaymentModal)
