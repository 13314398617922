import { equals } from 'ramda'
import { createLogic } from 'redux-logic'

import { UPDATE_EMPLOYEE_ACCOUNT_SECURITY } from 'state/concepts/employee/types'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import isPresent from 'utils/isPresent'
import { updateEmployeePassword, updateLogoutInterval } from '../actions'

const updateEmployeeAccountSecurityOperation = createLogic({
  type: UPDATE_EMPLOYEE_ACCOUNT_SECURITY,
  latest: true,

  async process({ action: { values, form }, getState }, dispatch, done) {
    const { logoutInterval: initialLogoutInterval } = currentEmployeeSelector(getState())
    const isLogoutEnabled = isPresent(initialLogoutInterval)
    const logoutInterval = initialLogoutInterval?.toString() || null

    if (!equals([isLogoutEnabled, logoutInterval], [values.isLogoutEnabled, values.logoutInterval])) {
      dispatch(updateLogoutInterval(values.logoutInterval, values.isLogoutEnabled, form))
    }
    if (values.oldPassword && values.password) {
      dispatch(updateEmployeePassword(values.oldPassword, values.password, form))
    }
    form.setSubmitting(false)
    done()
  },
})

export default updateEmployeeAccountSecurityOperation
