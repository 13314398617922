import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { CANCEL_PAYMENT } from 'state/concepts/payments/types'
import { cancelTransactionEndpoint } from 'state/concepts/payments/endpoints'
import { hideModal } from 'state/modal/actions'
import { TRANSACTION_STATUSES } from 'lib/constants/payments'

const cancelPaymentOperation = createLogic({
  type: CANCEL_PAYMENT,
  latest: true,

  async process({ httpClient, getState, action: { id } }, dispatch, done) {
    const { url, endpoint } = cancelTransactionEndpoint(id)
    const state = getState()

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      const response = updateDataHelper(state.data, 'transaction', id, {
        attributes: {
          status: TRANSACTION_STATUSES.cancelled,
        },
      })

      dispatch(dataApiSuccess({ endpoint, response }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: { id: 'notifications.paymentCanceled' },
        }),
      )
    } catch (error) {
      if (isErrorStatusUnprocessableEntity(error)) {
        const [{ detail }] = error.response.data.errors

        dispatch(showNotification({ messageObject: detail, kind: 'error' }))
        dispatch(dataApiFailure({ endpoint }))
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }

    done()
  },
})

export default cancelPaymentOperation
