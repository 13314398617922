import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import { isOneEmployeeSelector } from 'state/concepts/company/selectors'
import ProviderFieldsComponent from './component'

class ProviderFields extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
  }

  addRow = () =>
    this.props.push({
      employees: [],
      price: null,
      key: Math.random(),
      isFreePrice: false,
    })

  removeRow = index => () => {
    const { remove, form } = this.props
    if (form.values.provision.length < 2) {
      return
    }

    remove(index)
  }

  render() {
    return <ProviderFieldsComponent {...this.props} addRow={this.addRow} removeRow={this.removeRow} />
  }
}

const mapStateToProps = state => ({
  currentEmployee: currentEmployeeSelector(state),
  isOneEmployee: isOneEmployeeSelector(state),
})

export { ProviderFields as ProviderFieldsContainer }
export default connect(mapStateToProps)(ProviderFields)
