export default [
  '#73b9ff',
  '#facb6e',
  '#a9f87e',
  '#7df89d',
  '#55efc4',
  '#37b894',
  '#f9a679',
  '#80ecec',
  '#7ddcf8',
  '#7d9ef8',
  '#5396ff',
  '#f885fe',
  '#b87df8',
  '#f77575',
  '#f778a8',
  '#a19afe',
  '#837df8',
  '#f4eb4e',
  '#cdf051',
]
