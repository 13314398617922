import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const LocationO = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M511.998 886.101l230.437-230.442c127.269-127.265 127.269-333.608 0-460.876-127.265-127.268-333.608-127.268-460.876 0s-127.268 333.612 0 460.876l230.439 230.442zM511.998 1017.779l-296.278-296.276c-163.63-163.634-163.63-428.929 0-592.56s428.926-163.63 592.56 0c163.629 163.63 163.629 428.926 0 592.56l-296.28 296.276zM511.998 518.334c51.425 0 93.112-41.686 93.112-93.112 0-51.424-41.686-93.112-93.112-93.112s-93.112 41.687-93.112 93.112c0 51.425 41.686 93.112 93.112 93.112zM511.998 611.446c-102.848 0-186.223-83.376-186.223-186.223s83.374-186.223 186.223-186.223c102.847 0 186.223 83.374 186.223 186.223 0 102.847-83.376 186.223-186.223 186.223z" />
  </svg>
)

LocationO.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'location-icon',
}

LocationO.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default LocationO
