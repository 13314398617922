import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'

import updateDataHelper from 'utils/updateDataHelper'
import { dataApiRequest, dataApiSuccess, dataApiFailure } from 'state/data/actions'
import { setUnreadNotificationsCount } from 'state/concepts/notifications/actions'
import { notificationIdsSelector } from 'state/concepts/notifications/selectors'
import { MARK_NOTIFICATIONS_AS_READ } from 'state/concepts/notifications/types'
import { markNotificationsAsReadEndpoint } from 'state/concepts/notifications/endpoints'

const markNotificationsAsReadOperation = createLogic({
  type: MARK_NOTIFICATIONS_AS_READ,
  latest: true,

  async process({ httpClient, getState }, dispatch, done) {
    const { endpoint, url } = markNotificationsAsReadEndpoint
    const state = getState()
    const notificationIds = notificationIdsSelector(state)
    const params = {
      type: 'notification_panel',
    }

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.post(url, params)

      const response = updateDataHelper(state.data, 'notification', notificationIds, {
        attributes: { readAt: DateTime.utc() },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setUnreadNotificationsCount(0))
    } catch {
      dispatch(dataApiFailure({ endpoint }))
    }

    done()
  },
})

export default markNotificationsAsReadOperation
