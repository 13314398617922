import PropTypes from 'prop-types'
import classNames from 'clsx'

import FolderO from 'views/shared/icons/FolderO'
import ImageIcon from 'views/shared/icons/ImageIcon'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'

const ICON_COMPONENTS = {
  'folder-o': FolderO,
  'image-icon': ImageIcon,
}

const DirectoriesBadge = ({ text, icon, iconClassNames }) => {
  const IconComponent = ICON_COMPONENTS[icon]

  return (
    <div className="directories-badge d-inline-flex align-items-center column-gap-6 va-middle pl-8 pr-8">
      <div className="d-flex align-items-center column-gap-4 min-w-0">
        <div className="wh-16-16 d-flex align-items-center justify-content-center">
          <IconComponent size={14} className={classNames('directories__folder-icon', iconClassNames)} />
        </div>
        <div className="min-w-0">
          <p className="text-caption in-blue-gray-800 font-500 mb-0">
            <TruncatedTextByWidthTooltip on={['hover']} overlayClassName="max-w-50" text={text} />
          </p>
        </div>
      </div>
    </div>
  )
}

DirectoriesBadge.defaultProps = {
  iconClassNames: 'in-disabled-icon',
}

DirectoriesBadge.propTypes = {
  icon: PropTypes.oneOf(['folder-o', 'image-icon']).isRequired,
  iconClassNames: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default DirectoriesBadge
