import fetchTrashedFiles from './fetchTrashedFiles'
import filterTrashedFiles from './filterTrashedFiles'
import restoreTrashedFiles from './restoreTrashedFiles'
import emptyTrash from './emptyTrash'
import deleteTrashedFiles from './deleteTrashedFiles'
import receiveRestoredFiles from './receiveRestoredFiles'

export default [
  fetchTrashedFiles,
  filterTrashedFiles,
  restoreTrashedFiles,
  emptyTrash,
  deleteTrashedFiles,
  receiveRestoredFiles,
]
