import PropTypes from 'prop-types'
import { Dropdown as AntdDropdown } from 'antd'

const Dropdown = ({ dropdownRender, on, icon, className, disabled, placement, transitionName, ...props }) => (
  <AntdDropdown
    className={className}
    dropdownRender={dropdownRender}
    trigger={on}
    placement={placement}
    transitionName={transitionName}
    disabled={disabled}
    {...props}
  >
    {icon}
  </AntdDropdown>
)

Dropdown.defaultProps = {
  on: ['click'],
  className: null,
  disabled: false,
  placement: 'bottomRight',
  transitionName: null,
}

Dropdown.propTypes = {
  dropdownRender: PropTypes.func.isRequired,
  on: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover', 'contextMenu'])),
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  transitionName: PropTypes.string,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
}
export default Dropdown
