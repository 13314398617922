import { createLogic } from 'redux-logic'
import build from 'redux-object'
import normalize from 'json-api-normalizer'
import { DateTime } from 'luxon'
import { prop, path, head, includes } from 'ramda'

import { isMessageCreated, isMessageDestroyed, workspacifyChatUser } from 'utils/chat'
import { RECEIVE_MESSAGES } from 'state/concepts/chat/types'
import { chatIdSelector } from 'state/concepts/chat/selectors'
import { chatIdsSelector, oldestOrderTimestampSelector } from 'state/concepts/chats/selectors'
import { isEmployeeLoggedInSelector } from 'state/concepts/session/selectors'
import { fetchChatsEndpoint } from 'state/concepts/chats/endpoints'
import { endpointMetaSelector } from 'state/data/selectors'
import { dataApiSuccess } from 'state/data/actions'
import { addChats, getChat, removeChat, updateLastMessage, updateUnreadCounter } from 'state/concepts/chats/actions'
import { receiveChatMessages } from '../actions'

const receiveMessagesOperation = createLogic({
  type: RECEIVE_MESSAGES,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const state = getState()
    const isEmployee = isEmployeeLoggedInSelector(state)
    const { messages: messagesResponse } = action
    const response = workspacifyChatUser(isEmployee, normalize(messagesResponse))

    dispatch(dataApiSuccess({ response }))

    const messages = build(response, 'message')
    const receivedMessage = head(messages)
    const chatId = prop('chatId', receivedMessage).toString()
    const currentChatId = chatIdSelector(state)

    if (chatId === currentChatId) {
      dispatch(receiveChatMessages(messages))
    }

    const isFetchedChat = includes(chatId, chatIdsSelector(state)) || chatId === currentChatId
    const { endpoint } = fetchChatsEndpoint
    const { hasMore } = endpointMetaSelector(state, endpoint)
    const receivedOrderTimestamp = DateTime.fromISO(path(['chat', 'orderTimestamp'], receivedMessage))
    const oldestOrderTimestamp = DateTime.fromISO(oldestOrderTimestampSelector(state))
    const isDestroyedAndOutOfRange =
      isFetchedChat && isMessageDestroyed(receivedMessage) && receivedOrderTimestamp < oldestOrderTimestamp && hasMore

    if (isDestroyedAndOutOfRange) {
      dispatch(removeChat(chatId))
    } else if (isFetchedChat) {
      dispatch(updateLastMessage(messages))
      dispatch(updateUnreadCounter(messages))
      dispatch(addChats([chatId]))
    } else if (isMessageCreated(receivedMessage)) {
      dispatch(getChat(chatId))
    }

    done()
  },
})

export default receiveMessagesOperation
