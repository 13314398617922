import { camelCase } from 'lodash'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import useWorkspaceFeature from 'hooks/shared/useWorkspaceFeature'
import { FEATURE_KEYS } from 'lib/constants/workspaceFeatures'
import {
  companyClientDocumentAddSignatoriesRoute,
  companyClientDocumentEditSignatoriesRoute,
  companyClientDocumentReviewRoute,
  companyClientDocumentRoute,
} from 'lib/routes'
import {
  deleteEtherpadDocument,
  downloadEtherpadDocument,
  duplicateEtherpadDocument,
  remindToSignEtherpadDocument,
  removeEtherpadDocumentClientAccess,
} from 'state/concepts/etherpadDocuments/actions'
import { currentEmployeeSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showModal as showModalAction } from 'state/modal/actions'
import isAdminOrOwner from 'utils/employeePermissions/isAdminOrOwner'
import {
  isDeclined,
  isSigned,
  isSignerClient,
  isSignerSentForSignature,
  isSignerYou,
  isUntitledDocument,
  isVoided,
  isWaitingForSignature,
} from 'utils/etherpadDocuments'
import isPresent from 'utils/isPresent'
import profileName from 'utils/profileName'
import redirect from 'utils/redirect'
import compact from 'utils/compact'

const useExpertActions = ({
  document,
  feedId = null,
  clientId = null,
  redirectToAfterDelete,
  referrerPage,
  referrerClientTab,
}) => {
  const currentEmployee = useSelector(currentEmployeeSelector)
  const isClientDeactivated = path(['clientProfile', 'discardedAt'], document)
  const workspaceCode = useSelector(currentWorkspaceCodeSelector)
  const isDocumentsEnabled = useWorkspaceFeature(FEATURE_KEYS.textDocuments)
  const isSignatureEnabled = useWorkspaceFeature(FEATURE_KEYS.signature)
  const {
    userProfile,
    id: documentId,
    name,
    documentPermissions,
    clientProfile,
    documentSignatories = [],
  } = document || {}
  const userProfileDocumentSignatory = documentSignatories.find(
    documentSignatory =>
      isSignerYou({ documentSignatory, currentEmployee }) &&
      isSignerSentForSignature(camelCase(documentSignatory.status)),
  )
  const clientIsSigner = isPresent(documentSignatories.find(documentSignatory => isSignerClient(documentSignatory)))
  const isSharedByUser = currentEmployee?.id === userProfile?.id
  const isAdminOrOwnerOrExpertCreator = isAdminOrOwner(currentEmployee) || isSharedByUser

  const showModal = useDispatchAction(showModalAction)

  const status = camelCase(document?.status)
  const isPresentStatus = isPresent(status)
  const isDeclinedStatus = isDeclined(status)
  const isSignedStatus = isSigned(status)
  const isVoidedStatus = isVoided(status)
  const isWaitingForSignatureStatus = isWaitingForSignature(status)

  const canUpdate = !isPresentStatus && isAdminOrOwnerOrExpertCreator
  const canSign = isWaitingForSignatureStatus && isPresent(userProfileDocumentSignatory) && !isClientDeactivated

  const canDuplicate = isDocumentsEnabled && !isClientDeactivated
  const canDelete = isAdminOrOwnerOrExpertCreator

  const canView = isPresentStatus
  const canVoidOrRemindToSign = isWaitingForSignatureStatus && isAdminOrOwnerOrExpertCreator && !isClientDeactivated
  const onRemindToSign = useDispatchAction(remindToSignEtherpadDocument, documentId)

  const needToRename = isUntitledDocument(name)

  const onRename = (modalProps = {}) =>
    showModal({
      modalType: 'RENAME_DOCUMENT_MODAL',
      modalProps: {
        document,
        ...modalProps,
      },
    })

  const canRemoveClientAccess =
    isAdminOrOwnerOrExpertCreator &&
    isPresent(documentPermissions) &&
    !isClientDeactivated &&
    !clientIsSigner &&
    (isSignedStatus || isWaitingForSignatureStatus)

  const canShareWithClient =
    isAdminOrOwnerOrExpertCreator &&
    (((!isPresentStatus || ((isWaitingForSignatureStatus || isSignedStatus) && !clientIsSigner)) &&
      !isPresent(documentPermissions)) ||
      (!isPresentStatus && !isPresent(documentPermissions) && !isDocumentsEnabled)) &&
    !isClientDeactivated

  const canEditClientAccess =
    isAdminOrOwnerOrExpertCreator && isPresent(documentPermissions) && !isPresentStatus && !isClientDeactivated

  const removeClientAccess = useDispatchAction(removeEtherpadDocumentClientAccess, document)
  const onDuplicate = useDispatchAction(duplicateEtherpadDocument, { documentId, clientId, feedId })

  const onDownload = useDispatchAction(downloadEtherpadDocument, { documentId })
  const canDownload = isPresentStatus

  const onEditClientAccess = useDispatchAction(showModalAction, {
    modalType: 'UPDATE_ETHERPAD_DOCUMENT_CLIENT_ACCESS_MODAL',
    modalProps: { document },
  })
  const onRemoveClientAccess = useDispatchAction(showModalAction, {
    modalType: 'CONFIRM_MODAL',
    modalProps: {
      title: { id: 'etherpadDocuments.removeClientAccess.modal.title' },
      bodyText: [
        {
          id: 'etherpadDocuments.removeClientAccess.modal.description',
          values: { clientName: profileName(clientProfile) },
        },
      ],
      kind: 'danger',
      submitText: { id: 'etherpadDocuments.editClientAccess.modal.removeAccess' },
      dismissText: { id: 'shared.cancel' },
      onSubmit: removeClientAccess,
    },
  })

  const onEdit = () =>
    redirect({
      href: companyClientDocumentRoute(clientId, documentId),
      query: compact({ referrerClientTab }),
      workspace: workspaceCode,
    })

  const onView = () =>
    redirect({
      href: companyClientDocumentReviewRoute(clientId, documentId),
      query: compact({ referrerClientTab }),
      workspace: workspaceCode,
    })

  const openShareWithClientModal = useDispatchAction(showModalAction, {
    modalType: 'SHARE_DOCUMENT_WITH_CLIENT_MODAL',
    modalProps: {
      document,
    },
  })

  const onShareWithClient = () => {
    if (!needToRename) {
      return openShareWithClientModal()
    }

    return onRename({
      title: { id: 'etherpadDocuments.renameDocumentBeforeSharingModal.title' },
      submitText: { id: 'shared.saveContinue' },
      onSuccess: openShareWithClientModal,
    })
  }

  const deleteDocument = useDispatchAction(deleteEtherpadDocument, {
    documentId,
    feedId,
    redirectTo: redirectToAfterDelete,
  })
  const onDelete = useDispatchAction(showModalAction, {
    modalType: 'CONFIRM_MODAL',
    modalProps: {
      title: { id: 'etherpadDocuments.deleteDocument.modal.title' },
      bodyText: [{ id: 'etherpadDocuments.deleteDocument.modal.description' }],
      kind: 'danger',
      submitText: { id: 'etherpadDocuments.deleteDocument.modal.delete' },
      dismissText: { id: 'shared.cancel' },
      onSubmit: deleteDocument,
    },
  })

  const onVoid = useDispatchAction(showModalAction, {
    modalType: 'VOID_DOCUMENT_MODAL',
    modalProps: { document, feedId },
  })

  const onSign = useDispatchAction(showModalAction, {
    modalType: 'SIGN_DOCUMENT_MODAL',
    modalProps: { document, clientId },
  })

  const canRequestToSign = !isPresentStatus && isDocumentsEnabled && isSignatureEnabled && !isClientDeactivated

  const redirectToAddSignatory = () => {
    redirect({
      href: companyClientDocumentAddSignatoriesRoute(clientId, documentId),
      workspace: workspaceCode,
      query: { referrerPage },
    })
  }

  const onRequestToSign = () => {
    if (!needToRename) {
      return redirectToAddSignatory()
    }

    return onRename({
      title: { id: 'etherpadDocuments.renameDocumentBeforeSendingForSignature' },
      submitText: { id: 'shared.saveContinue' },
      onSuccess: redirectToAddSignatory,
    })
  }

  const canEditSigners =
    !isSignedStatus &&
    !isVoidedStatus &&
    !isDeclinedStatus &&
    isPresent(documentSignatories) &&
    isAdminOrOwnerOrExpertCreator &&
    !isClientDeactivated

  const onEditSigners = () => {
    redirect({
      href: companyClientDocumentEditSignatoriesRoute(clientId, documentId),
      workspace: workspaceCode,
      query: { referrerPage },
    })
  }
  const canViewSigners = isWaitingForSignatureStatus

  return {
    canUpdate,
    onEdit,
    canView,
    onView,
    onRename,
    canDelete,
    canDuplicate,
    onDuplicate,
    onDownload,
    canDownload,
    onDelete,
    canShareWithClient,
    onShareWithClient,
    canEditClientAccess,
    onEditClientAccess,
    canRemoveClientAccess,
    onRemoveClientAccess,
    canVoidOrRemindToSign,
    onVoid,
    canRequestToSign,
    onRequestToSign,
    canEditSigners,
    onEditSigners,
    onRemindToSign,
    canSign,
    onSign,
    canViewSigners,
  }
}

export default useExpertActions
