import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Download = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M486.399 332.8c14.139 0 25.6 11.462 25.6 25.6v89.599c0 35.346-28.654 64-64 64H64.001c-35.346 0-64.001-28.654-64.001-64v-89.6c0-14.139 11.461-25.6 25.6-25.6s25.6 11.462 25.6 25.6v89.6c0 7.07 5.73 12.799 12.799 12.799h383.998c7.07 0 12.799-5.73 12.799-12.799v-89.6c0-14.139 11.462-25.6 25.6-25.6zm-213.721 19.367.077-.039.615-.576-.69.615zm-2.421 1.903c-.368.246-.75.486-1.134.716l-2.149 1.151c-.654.31-1.341.603-2.046.863l-1.278.436a25.56 25.56 0 0 1-7.65 1.163 25.578 25.578 0 0 1-9.091-1.664 25.427 25.427 0 0 1-8.306-5.16l-.354-.332L109.9 222.9c-9.997-9.997-9.997-26.206 0-36.204s26.206-9.997 36.204 0l84.298 84.29V25.599c0-14.139 11.462-25.6 25.6-25.6s25.6 11.461 25.6 25.6l-.012 245.35 84.31-84.252c9.997-9.997 26.206-9.997 36.204 0s9.997 26.206 0 36.204L272.24 352.591a25.15 25.15 0 0 1-1.983 1.476z" />
  </svg>
)

Download.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Download.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Download
