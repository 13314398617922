const calendarSync = {
  'calendarSync.title': 'Integrations',

  'calendarSync.menu.calendarSync': 'Calendar sync',
  'calendarSync.googleCalendarSyncing': 'Google Calendar syncing',
  'calendarSync.connectYourCalendar':
    'Connect up to 4 Google Calendars to sync your events and prevent double booking on ExpertBox.',
  'calendarSync.connectGoogleCalendar': 'Connect Google Calendar',
  'calendarSync.connectAnotherGoogleCalendar': 'Connect another Google Calendar',
  'calendarSync.allowAccess':
    'Allow access to your Google Calendar account to sync events to your _(Appointments) page.',
  'calendarSync.googleCalendarConnected': 'Google Calendar is connected',
  'calendarSync.googleCalendarDisconnected': 'Google Calendar is disconnected',
  'calendarSync.googleCalendarConnecting': 'Google Calendar is connecting. Please wait...',
  'calendarSync.googleCalendarRemoving': 'Removing Google Calendar sync. Please wait...',
  'calendarSync.reconnect': 'Reconnect',
  'calendarSync.reloadInFewMinutes':
    'Reload page in a few minutes to adjust your calendar connection. Syncing calendar might take some time',
  'calendarSync.calendarsToSync': 'Calendars to sync',
  'calendarSync.syncCalendars': 'Show calendars',
  'calendarSync.setAsBusy': 'Set as unavailable',
  'calendarSync.changesSaved': 'Calendar settings were updated',

  'calendarSync.info.syncCalendars': 'View events from these calendars on your _(Appointments) page',
  'calendarSync.info.setAsBusy': '_(Clients) won’t be able to book a time slot if there is a conflicting event',

  'googleCalendarConnectionRemoveModal.removeConnection': 'Disconnect calendar?',
  'googleCalendarConnectionRemoveModal.areYouSure':
    'Events from this calendar will no longer sync to ExpertBox _(appointments).',
  'googleCalendarConnectionRemoveModal.googleMeetAlert': 'You’re using this calendar for Google Meet video calls.',

  'calendarSync.synchronizedCalendars': 'Synchronized calendars',
  'calendarSync.integrations': 'Integrations',
  'calendarSync.connectCountHint':
    'Connect up to 6 calendars to sync your events to ExpertBox and prevent double bookings.',
  'calendarSync.googleCalendar': 'Google Calendar',
  'calendarSync.appleCalendar': 'iCloud Calendar',
  'calendarSync.calendars': 'Calendars',
  'calendarSync.disconnect': 'Disconnect',
  'calendarSync.showCalendars': 'Show calendars',
  'calendarSync.setAsUnavailable': 'Set as unavailable',
  'calendarSync.microsoftOutlook': 'Microsoft Outlook',
  'calendarSync.tryReconnection':
    'Check that your calendar has all necessary permissions and reconnect it to sync ExpertBox events.',
  'calendarSync.createEventsOn': 'Sync _(appointments) to',
  'calendarSync.whereAddNewEvents': 'ExpertBox _(appointments) will be added as new events.',
  'calendarSync.calendarSelect.placeholder': 'Select calendar to sync with',
  'calendarSync.calendarSelect.dontSyncAppointmentsFromExpertBox': `Don’t sync appointments from ExpertBox`,
  'calendarSync.addingEvents': ' (adding events to this calendar)',
  'calendarSync.connect': 'Connect',
  'calendarSync.appleId': 'Apple ID',
  'calendarSync.credentialsEncrypted': 'Your credentials will be stored and encrypted.',
  'calendarSync.generatePassword': 'Generate an app specific account password to use with ExpertBox at',
  'calendarSync.connectToApple': 'Connect to Apple account',
  'calendarSync.reconnectToApple': 'Reconnect to Apple account',
  'calendarSync.appleId.placeholder': 'appleid@domain.com',
  'calendarSync.connectCalendar': 'Connect calendar',
  'calendarSync.calendarLimit': "You've hit the limit of connected calendars.",
  'calendarSync.googleMeetAlert':
    'To have video calls via Google Meet, let ExpertBox sync _(appointments) to Google Calendar.',
}

export default calendarSync
