import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { FETCH_EMPLOYEES_PROVISION } from 'state/concepts/employees/types'
import { fetchEmployeeProvisionEndpoint } from 'state/concepts/employees/endpoints'

const fetchProvisionOperation = createLogic({
  type: FETCH_EMPLOYEES_PROVISION,
  latest: true,

  async process({ httpClient, action: { employeeId } }, dispatch, done) {
    const { endpoint, url } = fetchEmployeeProvisionEndpoint(employeeId)
    dispatch(dataApiRequest({ endpoint }))

    try {
      const params = { include: 'user-profiles-services.service' }
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchProvisionOperation
