import React from 'react'
import { withFormik } from 'formik'
import { compose } from 'ramda'
import { connect } from 'react-redux'

import validationSchema from 'lib/yupLocalised/schemas/employeesInvitation'
import withHttpClient from 'lib/withHttpClient'
import isSubmitDisabled from 'utils/form/isSubmitDisabled'
import validateInvitation from 'utils/invitations/validateInvitation'
import { handleSubmit } from 'utils/form/handleSubmit'
import { inviteEmployees } from 'state/concepts/employees/actions'
import InvitationModalComponent from './component'

// eslint-disable-next-line react/prefer-stateless-function
class InvitationModal extends React.Component {
  render = () => <InvitationModalComponent {...this.props} isSubmitDisabled={isSubmitDisabled(this.props)} />
}

const mapDispatchToProps = {
  onSubmit: inviteEmployees,
}

export { InvitationModal as InvitationModalContainer }
export default compose(
  connect(null, mapDispatchToProps),
  withHttpClient,
  withFormik({
    mapPropsToValues: () => ({
      invitations: [{ email: '', role: 'user', key: Math.random() }],
    }),
    validate: validateInvitation,
    validationSchema,
    handleSubmit,
    validateOnBlur: false,
  }),
)(InvitationModal)
