import { combineReducers } from '@reduxjs/toolkit'

import { SET_IS_EXPANDED, SET_IS_COMPLETED } from './types'

const isExpanded = (state = true, action) => {
  switch (action.type) {
    case SET_IS_EXPANDED:
      return action.isExpanded
    default:
      return state
  }
}

const isCompleted = (state = false, action) => {
  switch (action.type) {
    case SET_IS_COMPLETED:
      return action.isCompleted
    default:
      return state
  }
}

export default combineReducers({
  isExpanded,
  isCompleted,
})
