import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import profileName from 'utils/profileName'
import useIsOnline from 'hooks/appearance/useIsOnline'
import Avatar from 'views/shared/Avatar'
import UserO from 'views/shared/icons/UserO'

const MemberCardComponent = ({
  canBeRemoved,
  isOwner,
  isDeactivated,
  chatMember,
  onConfirmRemoveChatMember,
  isDeleted,
}) => {
  const isOnline = useIsOnline(chatMember.id)

  return (
    <div className="main-modal-chat-members" data-cy="chat-member">
      {!isDeleted && (
        <div className="d-flex align-items-center">
          <div className="relative">
            <Avatar avatarClassName="mr-16 main-userpic--sm" profile={chatMember} />
            {isOnline && <span className="main-userpic__online-status" />}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <p className="text-subheader mb-0 mr-8" data-cy="chat-member-name">
                {profileName(chatMember)}
              </p>
              {chatMember.memberRole && (
                <span className="main-badge main-badge--expert-label mr-8" data-cy="chat-member-expert-label">
                  <FormattedMessage id={`chat.members.${chatMember.memberRole}`} />
                </span>
              )}
            </div>
            <div className="d-flex align-items-center">
              {isOwner && (
                <span className="text-caption in-gray-700 mr-8" data-cy="chat-owner-label">
                  <FormattedMessage id="chat.members.workspace_owner" />
                </span>
              )}
              {isDeactivated && (
                <p className="text-caption mb-0 in-gray-700" data-cy="chat-member-deactivated-label">
                  <FormattedMessage id="chat.members.deactivated" />
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {isDeleted && (
        <div className="d-flex align-items-center">
          <div className="deleted-account__avatar mr-16">
            <UserO size={15} className="deleted-account__icon" />
          </div>
          <div>
            <div className="d-flex align-items-center op-03">
              <p className="text-body mb-0 mr-8">
                <FormattedMessage id="chat.members.deleted" />
              </p>
            </div>
          </div>
        </div>
      )}
      {canBeRemoved && (
        <button
          className="main-link font-600 ml-auto"
          data-cy="remove-chat-member"
          type="button"
          onClick={onConfirmRemoveChatMember}
        >
          <span>
            <FormattedMessage id="chat.members.remove" />
          </span>
        </button>
      )}
    </div>
  )
}

MemberCardComponent.propTypes = {
  canBeRemoved: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isDeactivated: PropTypes.bool.isRequired,
  chatMember: PropTypes.shape().isRequired,
  onConfirmRemoveChatMember: PropTypes.func.isRequired,
  isDeleted: PropTypes.bool.isRequired,
}

export default MemberCardComponent
