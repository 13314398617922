import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import profileName from 'utils/profileName'

const BillSectionComponent = ({ clientProfile: { email }, clientProfile, paymentMethod }) => (
  <div className="main-card__container" data-cy="client-profile-block">
    <div className="preview-invoice__middle">
      <div className="mb-12">
        <p className="in-blue-gray-300 mb-8" data-cy="bill-to-title">
          <FormattedMessage id="payments.review.billTo" />
        </p>
        <p className="mb-4 font-600" data-cy="bill-to-client-name">
          {profileName(clientProfile)}
        </p>
        <p className="in-blue-gray-300 mb-0" data-cy="bill-to-client-email">
          {email}
        </p>
      </div>
      {paymentMethod && (
        <div className="mb-16 payment-method">
          <p className="in-blue-gray-300 mb-8" data-cy="payment-method-title">
            <FormattedMessage id="payments.review.paymentMethod" />
          </p>
          <p className="mb-0" data-cy="payment-method-value">
            <FormattedMessage {...paymentMethod} />
          </p>
        </div>
      )}
    </div>
  </div>
)

BillSectionComponent.defaultProps = {
  paymentMethod: null,
}

BillSectionComponent.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
  paymentMethod: PropTypes.shape(),
}

export default BillSectionComponent
