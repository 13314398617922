import { createLogic } from 'redux-logic'

import { ALERT_KINDS } from 'lib/constants'
import requestErrorHandler from 'lib/requestErrorHandler'
import { companySettingsDocumentTemplatesRoute } from 'lib/routes'
import redirect from 'utils/redirect'
import updateDataHelper from 'utils/updateDataHelper'
import { isErrorStatusNotFound } from 'utils/getErrorStatus'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { deactivateDocumentTemplateEndpoint } from 'state/concepts/documentTemplates/endpoints'
import { DEACTIVATE_DOCUMENT_TEMPLATE } from 'state/concepts/documentTemplates/types'

const deactivateDocumentTemplateOperation = createLogic({
  type: DEACTIVATE_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ httpClient, getState, action: { documentTemplateId } }, dispatch, done) {
    const { url, endpoint } = deactivateDocumentTemplateEndpoint(documentTemplateId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.put(url)
      const state = getState()

      const response = updateDataHelper(state.data, 'documentTemplate', documentTemplateId, {
        attributes: { status: 'inactive' },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.documentTemplateDeactivated',
          },
        }),
      )
    } catch (error) {
      if (isErrorStatusNotFound(error)) {
        dispatch(hideModal())
        redirect({ href: companySettingsDocumentTemplatesRoute, workspace: workspaceCode })
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.documentTemplateWasDeletedByAnotherUser',
            },
            kind: ALERT_KINDS.error,
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint })
      }
    }
    done()
  },
})

export default deactivateDocumentTemplateOperation
