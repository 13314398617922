import React from 'react'
import { connect } from 'react-redux'

import { disconnectPaypal } from 'state/concepts/paymentSettings/actions'
import { loadingSelector } from 'state/data/selectors'
import { disconnectPaypalEndpoint } from 'state/concepts/paymentSettings/endpoints'
import ConfirmModal from 'views/shared/ConfirmModal'

// eslint-disable-next-line react/prefer-stateless-function
class DisconnectPaypalModal extends React.Component {
  render() {
    return (
      <ConfirmModal
        {...this.props}
        title={{ id: 'paymentSettings.disconnectPaypalModal.title' }}
        bodyText={[{ id: 'paymentSettings.disconnectPaypalModal.body' }]}
        submitText={{ id: 'paymentSettings.disconnect' }}
        kind="danger"
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, disconnectPaypalEndpoint.endpoint),
})

const mapDispatchToProps = {
  onSubmit: disconnectPaypal,
}

export { DisconnectPaypalModal as DisconnectPaypalModalContainer }
export default connect(mapStateToProps, mapDispatchToProps)(DisconnectPaypalModal)
