import { useFormikContext } from 'formik'
import SingletonRouter, { Router } from 'next/router'
import { isNil, path } from 'ramda'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import * as modalActions from 'state/modal/actions'

const useLoseChangesWarning = (props = {}) => {
  const { modalProps, formikProps } = props
  const showModal = useDispatchAction(modalActions.showModal)
  const hideModal = useDispatchAction(modalActions.hideModal)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = isNil(formikProps) ? useFormikContext() : formikProps
  const { dirty, isSubmitting, isCancelling } = context

  const [routerArgs, setRouterArgs] = useState(null)
  const [modalIsOk, setModalIsOk] = useState(false)
  const timeoutRef = useRef(null)

  const handleConfirmDiscard = useCallback(() => {
    setModalIsOk(true)
    hideModal()
  }, [hideModal])

  const showWarning = useCallback(
    () =>
      showModal({
        modalType: 'CONFIRM_MODAL',
        modalProps: {
          title: { id: 'shared.discardChanges' },
          bodyText: [{ id: 'shared.cancelText' }],
          submitText: { id: 'shared.discard' },
          submitClassName: 'main-btn--danger',
          dismissText: { id: 'shared.cancel' },
          ...modalProps,
          onSubmit: handleConfirmDiscard,
        },
      }),
    [handleConfirmDiscard, showModal, modalProps],
  )

  const continueNavigation = useCallback(
    args => {
      const skipDiscard = path([3, 'skipDiscard'], args)
      const nextPath = args[1]
      const isSamePath = SingletonRouter.router.state.asPath === nextPath

      return isCancelling || isSubmitting || !dirty || skipDiscard || isSamePath
    },
    [dirty, isCancelling, isSubmitting],
  )

  useEffect(() => {
    SingletonRouter.router.change = (...args) => {
      setRouterArgs(args)

      if (continueNavigation(args) || !document.hasFocus()) {
        return Router.prototype.change.apply(SingletonRouter.router, args)
      }

      showWarning()
      // eslint-disable-next-line no-promise-executor-return
      return new Promise(resolve => resolve(false))
    }

    if (modalIsOk) {
      timeoutRef.current = setTimeout(() => Router.prototype.change.apply(SingletonRouter.router, routerArgs), 100)
    }
  }, [continueNavigation, modalIsOk, showWarning, routerArgs])

  useUnmount(() => {
    clearTimeout(timeoutRef.current)
    delete SingletonRouter.router.change
  })

  return { handleConfirmDiscard, continueNavigation }
}

export default useLoseChangesWarning
