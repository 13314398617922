import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'
import { map } from 'ramda'

import { PERMISSIONS_DESTROYED_TYPE } from 'lib/constants/businessFiles'
import { dataApiSuccess } from 'state/data/actions'
import redirect from 'utils/redirect'
import { companyBusinessFilesShareRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import isPresent from 'utils/isPresent'
import { hideModal } from 'state/modal/actions'
import { RECEIVE_BUSINESS_STORAGE_PERMISSIONS } from '../types'
import { currentFolderSelector, fileSharedWithMeIdsSelector } from '../selectors'
import { removeFileSharedWithMeByIds } from '../actions'

const receiveBusinessStoragePermissionsOperation = createLogic({
  type: RECEIVE_BUSINESS_STORAGE_PERMISSIONS,
  latest: true,

  async process({ getState, action: { data } }, dispatch, done) {
    const { meta } = data
    const state = getState()
    const currentWorkspaceCode = currentWorkspaceCodeSelector(state)
    const currentFolder = currentFolderSelector(state)
    const ids = fileSharedWithMeIdsSelector(state)
    const currentFolderPresent = isPresent(currentFolder)

    if (meta?.type === PERMISSIONS_DESTROYED_TYPE) {
      const { businessStoragePermissions = [], ancestorsPermissions = [] } = currentFolder || {}
      const allPermissions = [...businessStoragePermissions, ...ancestorsPermissions]
      const permissionIds = map(String, meta.found_permissions_ids)
      dispatch(hideModal())
      currentFolderPresent &&
        allPermissions.some(({ id }) => permissionIds.includes(id)) &&
        redirect({ href: companyBusinessFilesShareRoute, workspace: currentWorkspaceCode })
      !currentFolderPresent &&
        ids.includes(meta.business_storage_id.toString()) &&
        dispatch(removeFileSharedWithMeByIds([meta.business_storage_id.toString()]))
    } else {
      dispatch(dataApiSuccess({ response: normalize(data) }))
    }

    done()
  },
})

export default receiveBusinessStoragePermissionsOperation
