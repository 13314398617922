const namespace = 'clientAccountBookings'

export const FETCH_BOOKINGS = `${namespace}/FETCH_BOOKINGS`
export const FILTER_BOOKINGS = `${namespace}/FILTER_BOOKINGS`
export const SWITCH_BOOKINGS = `${namespace}/SWITCH_BOOKINGS`
export const SET_BOOKINGS = `${namespace}/SET_BOOKINGS`
export const RESET_BOOKINGS = `${namespace}/RESET_BOOKINGS`
export const SET_SORT_ORDER = `${namespace}/SET_SORT_ORDER`
export const SET_FILTER_PARAMS = `${namespace}/SET_FILTER_PARAMS`
export const REMOVE_BOOKINGS = `${namespace}/REMOVE_BOOKINGS`
export const SET_PENDING_BOOKINGS_COUNT = `${namespace}/SET_PENDING_BOOKINGS_COUNT`
export const DECREMENT_PENDING_BOOKINGS_COUNT = `${namespace}/DECREMENT_PENDING_BOOKINGS_COUNT`
