import { combineReducers } from '@reduxjs/toolkit'

import { SET_AGREEMENT_ID } from './types'

const agreementId = (state = null, action) => {
  switch (action.type) {
    case SET_AGREEMENT_ID:
      return action.id
    default:
      return state
  }
}

const agreementReducer = combineReducers({
  agreementId,
})

export default agreementReducer
