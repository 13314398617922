import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const CrossUnrounded = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M93.94 0 0 93.94 418.06 512 0 930.057 93.94 1024 512 605.94 930.057 1024 1024 930.057 605.94 512 1024 93.94 930.057 0 512 418.06 93.94 0z" />
  </svg>
)

CrossUnrounded.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

CrossUnrounded.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default CrossUnrounded
