/* eslint-disable no-template-curly-in-string */

const billing = {
  'billing.title': 'Subscription',
  'billing.addBillingMethod.title': 'Add billing method',
  'billing.updateBillingMethod.title': 'Update billing method',
  'billing.billingHistory.title': 'Billing history',
  'billing.planAndUsage.title': 'Plan and usage',
  'billing.paymentHistory.table.title.amount': 'Amount',
  'billing.paymentHistory.table.title.status': 'Status',
  'billing.paymentHistory.table.title.id': 'ID',
  'billing.paymentHistory.table.title.date': 'Date',
  'billing.paymentHistory.table.title.billingMethod': 'Billing method',
  'billing.paymentHistory.table.empty': 'There are no payments yet',
  'billing.paymentHistory.cardEndingIn': '{cardBrand} ending in ',
  'billing.paymentHistory.status.successful': 'Paid',
  'billing.paymentHistory.status.pending': 'Pending',
  'billing.paymentHistory.status.failed': 'Failed',
  'billing.paymentHistory.recipientInfo': '{recipientName} ({recipientEmail})',
  'billing.paymentHistory.paymentMethodInfo': '{cardBrand} ends in {lastDigits}',
  'billing.editInvoice.title': 'Edit invoice details',
  'billing.addInvoice.title': 'Add invoice details',
  'billing.addDetails': 'Add details',
  'billing.billingAccount.receiptsInfo': 'This information will appear on all your receipts.',
  'billing.billingAccount.addAnything':
    'Add anything you’d like to appear on your invoice under the “Billed to” section, like company name, address, tax ID, etc.',
  'billing.billingAccount.confidentialInfo':
    'For your security, do not include any confidential or financial information.',
  'billing.paymentHistory.action.viewInvoice': 'View invoice',
  'billing.paymentHistory.invoice': 'Invoice',
  'billing.paymentHistory.invoice.title': 'Invoice ID {invoiceId}',
  'billing.paymentHistory.action.downloadPDF': 'Download invoice',
  'billing.paymentHistory.invoice.invoiceId': 'Invoice ID:',
  'billing.paymentHistory.invoice.date': 'Date:',
  'billing.paymentHistory.invoice.paid': 'PAID',
  'billing.paymentHistory.invoice.billedTo': 'Billed to:',
  'billing.paymentHistory.invoice.accountName': 'Account name: ',
  'billing.paymentHistory.invoice.billingMethod': 'Billing method: ',
  'billing.paymentHistory.invoice.cardEndingIn': '{cardBrand} ending in ',
  'billing.paymentHistory.invoice.taxId': 'VAT/GST ID: {taxId}',
  'billing.paymentHistory.invoice.table.description': 'Description',
  'billing.paymentHistory.invoice.table.billingPeriod': 'Billing period',
  'billing.paymentHistory.invoice.table.quantity': 'Quantity',
  'billing.paymentHistory.invoice.table.status': 'Status',
  'billing.paymentHistory.invoice.table.unitPrice': 'Unit price',
  'billing.paymentHistory.invoice.table.amount': 'Amount',
  'billing.paymentHistory.invoice.subtotal': 'Subtotal',
  'billing.paymentHistory.invoice.discount': 'Discount',
  'billing.paymentHistory.invoice.totalAmount': 'Total amount',
  'billing.paymentHistory.invoice.preview.total': '* Total amount included in Stripe transaction fee:',
  'billing.paymentHistory.invoice.preview.charge': '2.9%+ 30¢ per successful card charge +1%',
  'billing.paymentHistory.invoice.preview.additionalFees':
    'Additional fees required for international cards and currency conversion.',
  'billing.currentSubscriptionPlan.title': 'Current subscription plan',
  'billing.currentSubscriptionPlan.trial': 'Trial',
  'billing.currentSubscriptionPlan.trialExtendedTill':
    '{planName} <spanDot></spanDot> <span>Extended till {date}</span>',
  'billing.currentSubscriptionPlan.trialExpiresAt': '{planName} <spanDot></spanDot> Expires on {date}',
  'billing.currentSubscriptionPlan.changePlan': 'Change plan',
  'billing.currentSubscriptionPlan.extendTrial': 'Extend trial',
  'billing.currentSubscriptionPlan.cancelSubscription': 'Cancel subscription',
  'billing.currentSubscriptionPlan.selectPlan': 'Select plan',
  'billing.nextPayment.title': 'Payment',
  'billing.nextPayment.date': 'Next payment on {date}',
  'billing.nextPayment.button': 'Billing details',
  'billing.nextPayment.oldPrice': 'Old price',
  'billing.nextPayment.youSave': 'You save {percentage}%',
  'billing.nextPayment.applyDiscount': 'Apply discount',
  'billing.billingInformation.title': 'Billing information',
  'billing.billingInformation.button': 'Add billing method',
  'billing.billingInformation.addInvoiceDetails': 'Add invoice details',
  'billing.billingInformation.editInvoiceDetails': 'Edit invoice details',
  'billing.billingInformation.invoiceDetails': 'Invoice details: ',
  'billing.billingInformation.deleteInvoiceDetails.title': 'Delete invoice details?',
  'billing.billingInformation.deleteInvoiceDetails.body': 'You can add new invoice details later.',
  'billing.teamSize.title': 'Team size',
  'billing.teamSize.seats': 'Seats',
  'billing.teamSize.seatsUsed': '{current} of {max} seats used',
  'billing.teamSize.manageTeam': 'Manage team',
  'billing.teamSize.manageSeats': 'Manage seats',
  'billing.usingData.progressBarTitle': 'Videocall hours',
  'billing.usingData.progressBarText': '{hoursUsed} hours of {hoursFrom} hours used.',
  'billing.usingData.hrs': '{hours} hrs',
  'billing.usingData.pricePerHour': '${pricePerHour} / hour',
  'billing.usingData.expandStorage': 'Expand storage',
  'billing.usingData.storage': 'Storage',
  'billing.usingData.storageUsed': '{current} of {max} used.',
  'billing.usingData.title': 'Data usage',
  'billing.paymentMethod.cardNumber': 'Card number',
  'billing.paymentMethod.expiresOn.label': 'Expiry date',
  'billing.paymentMethod.cvv.label': 'CVV / CVC',
  'billing.paymentMethod.cvv.tooltip':
    'Card Verification Value/Code is used to validate that a genuine card is being used during a transaction. With a Visa or MasterCard CVV is a three-digit security code printed on the back of the card. On a American Express card, it is four digits shown on the front of the card.',
  'billing.paymentMethod.creditCard': 'Credit/Debit card',
  'billing.paymentMethod.placeholder.cardNumber': '0000 0000 0000 0000',
  'billing.paymentMethod.placeholder.expirationDate': 'MM/YY',
  'billing.paymentMethod.placeholder.cvv': '***',
  'billing.billingMethod.title': 'Billing method',
  'billing.paymentMethod.nameOnCard': 'Name on card',
  'billing.paymentMethod.address': 'Billing address',
  'billing.paymentMethod.zipCode': 'Postal / Zip code',
  'billing.paymentMethod.country': 'Country',
  'billing.paymentMethod.state': 'State',
  'billing.paymentMethod.city': 'City',
  'billing.paymentMethod.endingIn': '{cardBrand} ending in',
  'billing.paymentMethod.expDate': 'Exp. date',
  'billing.paymentMethod.addingBillingMethod':
    'Adding a billing method will start your paid subscription for {subscriptionPlan}. Your trial will be over, and you will be charged a subscription fee of {totalPrice}.',
  'billing.paymentMethod.automaticallyRenewSubscription':
    'We will automatically renew your subscription on a monthly basis. You can always cancel or change the subscription plan.',
  'billing.paymentMethod.editBillingAddress': 'Edit billing address',
  'billing.paymentMethod.replaceCard': 'Replace card',
  'billing.paymentMethod.failedToMakePayment': 'Failed to make a payment {transactionId} for $ {amount}',
  'billing.paymentMethod.review': 'Review payment method',
  'billing.paymentMethod.tryAgain': 'Try again',
  'billing.paymentMethod.validation.cardNumber': 'Enter your card number',
  'billing.paymentMethod.validation.expiryDate': 'Enter expiry date',
  'billing.paymentMethod.validation.cvc': 'Enter your CVC/CVV',
  'billing.paymentMethod.validation.cardName': 'Enter cardholder name',
  'billing.paymentMethod.validation.billingAddress': 'Enter your billing address',
  'billing.paymentMethod.validation.zipCode': 'Enter your Postal / ZIP code',
  'billing.paymentMethod.validation.state': 'Enter your state',
  'billing.paymentMethod.validation.city': 'Enter the city',
  'billing.promocode.modal.discount.title': 'Apply discount',
  'billing.promocode.modal.discount.labelText': 'Enter your promo code to apply discount for the next monthly payment.',
  'billing.promocode.modal.trial.title': 'Extend trial',
  'billing.promocode.modal.trial.labelText': 'Enter your promo code to extend trial period.',
  'billing.promocode.modal.placeholder': 'Enter your promo code',
  'billing.promocode.modal.cancel': 'Cancel',
  'billing.promocode.modal.apply': 'Apply',
  'billing.smsAnalytics.title': 'SMS notifications',
  'billing.smsAnalytics.description': 'This price is already included in your next monthly payment.',
  'billing.smsAnalytics.messagesSent': 'Messages sent',
  'billing.smsAnalytics.pricePerSms': 'Price per sms',
  'billing.smsAnalytics.totalPrice': 'Total price',
  'billing.smsAnalytics.manageSmsNotifications': 'Manage SMS notifications',
  'billing.tabs.planAndUsage': 'Plan and usage',
  'billing.tabs.billingHistory': 'Billing history',
  'billing.planUsage.productName': 'Product name',
  'billing.planUsage.price': 'Price',
  'billing.planUsage.usage': 'Usage',
  'billing.planUsage.total': 'Total',
  'billing.planUsage.team.name': 'Team seats',
  'billing.planUsage.storage.name': 'Storage',
  'billing.planUsage.storage.cancelHint': 'Storage plan will be cancelled on {date}',
  'billing.planUsage.storage.downgradeHint': 'New plan {size} starts on {date}',
  'billing.planUsage.storage.tooltip.title': 'Available storage',
  'billing.planUsage.storage.tooltip.included': '{size} included in {planName}',
  'billing.planUsage.storage.tooltip.additional': '{size} of additional storage',
  'billing.planUsage.storage.tooltip.downgraded': '{size} of additional storage starts on {date}',
  'billing.planUsage.storage.tooltip.cancelled': '{size} of additional storage will be cancelled on {date}',
  'billing.planUsage.storage.tooltip.deleted': '{size} of your files will be deleted on {date}',
  'billing.planUsage.videoCalls.name': 'Group video calls',
  'billing.planUsage.videoRecordings.name': 'Video call recordings',
  'billing.planUsage.smsNotifications.name': 'SMS notifications',
  'billing.planUsage.manageSeats': 'Manage seats',
  'billing.planUsage.addSeats': 'Add seats',
  'billing.planUsage.manageStorage': 'Manage storage plan',
  'billing.planUsage.expandStorage': 'Expand storage',
  'billing.planUsage.manageVideoCalls': 'Manage video calls',
  'billing.planUsage.manageRecordings': 'Manage recordings',
  'billing.planUsage.manageNotifications': 'Manage notifications',
  'billing.planUsage.seatsUsed': 'of {total} {total, plural, one {seat} other {seats}} used',
  'billing.planUsage.ofStorageUsed': 'of {total} used',
  'billing.planUsage.storageUsed': '{total} used',
  'billing.planUsage.hoursUsed': '{hours} {hours, plural, one {hour} other {hours}}',
  'billing.planUsage.messagesUsed': '{messages} {messages, plural, one {message} other {messages}}',
  'billing.planUsage.sizePerMonth': '{size} per month',
  'billing.planUsage.includedInPlan': 'Included in plan',
  'billing.planUsage.hourPerParticipant': 'hour/participant',
  'billing.planUsage.textMessagePrice': `The price of a text message changes based on the country and currency, but it's usually between 2 to 40 cents. Check your bill to see the exact cost.`,
  'billing.planUsage.perMessage': 'per message',
  'billing.planUsage.totalIncludedIn': 'Total included in the next monthly payment ({period}):',
  'billing.planUsage.addPaymentMethod': 'Add payment method',
  'billing.planUsage.updatePaymentMethod': 'Update payment method',
  'billing.subscriptionPlan.upgradePlan': 'Upgrade plan',
  'billing.subscriptionPlan.free': 'Free',
  'billing.subscriptionPlan.title': 'Subscription plan',
  'billing.subscriptionPlan.freeTrial': 'Free Premium Plus trial',
  'billing.subscriptionPlan.remainingDays': 'Remaining days',
  'billing.subscriptionPlan.extendedDays': 'Extended for {extendedDays} days',
  'billing.subscriptionPlan.premium': 'Premium',
  'billing.subscriptionPlan.monthly': 'Monthly',
  'billing.subscriptionPlan.annual': 'Annual (-{discount}%)',
  'billing.subscriptionPlan.perUserMonth': 'per user/month',
  'billing.subscriptionPlan.afterTrialEnd': 'After trial end {sum}',
  'billing.planUsage.afterTrialEndHour': 'After trial end {sum} hour/participant',
  'billing.planUsage.afterTrialEndPerMessage': 'After trial end {sum} per message',
  'billing.planUsage.afterTrialEndStorage': 'After trial end includes {storage} storage',
  'billing.planUsage.pricePerSms': '$0.02 - 0.40',
  'billing.planUsage.defaultStorage': '1 GB',
  'billing.subscriptionPlan.now': 'Now',
  'billing.subscriptionPlan.switchCycle': 'Switch billing cycle',
  'billing.paymentMethod.update': 'Update payment method',
  'billing.paymentMethod.chargeAgain': 'Charge again',
  'billing.paymentMethod.updateBillingAddress': 'Edit billing address',
  'billing.alert.subscriptionDebtAmount':
    'Your workspace is overdue by {total}. To manage your subscription, seats, or storage, please update your payment method and retry the payment.',
  'billing.alert.subscriptionChargeFailed':
    "We couldn't process your recent payment of {total}. To manage your subscription, seats, or storage, please update your payment method and retry the payment.",
  'billing.alert.storageFull':
    "You're out of space. Older files that exceed the storage limit are hidden. Upgrade within 30 days to restore them or they'll be deleted permanently",
  'billing.alert.storageAlmostFull':
    'You’re almost out of space. Upgrade your storage plan now to keep your files safe.',
  'billing.alert.storageWillBeAutoUpgraded':
    'Your storage space is nearly full. It will be auto-upgraded when you run out of space.',
  'billing.alert.subscriptionChargeFailed.buttonText': 'Update payment method',
  'billing.usageInformation.cancelRequestStorageDowngrade': 'Your storage plan will be downgraded to {size} on {date}.',
  'billing.usageInformation.cancelRequestStorageDowngradeEmptySize': 'Your storage plan will be cancelled on {date}.',
  'billing.usageInformation.cancelRequestStorageDowngrade.descriptions': 'This will restore your {size} storage plan.',
  'billing.alert.storage.upgrade': 'Upgrade storage plan',
  'billing.storage.upgrade.button': 'Upgrade storage plan',
  'billing.storage.upgradeNow.button': 'Upgrade storage now',
  'billing.billingCycle.title': 'Choose billing cycle',
  'billing.billingCycle.monthly': 'Monthly',
  'billing.billingCycle.annually': 'Annually',
  'billing.subscriptionPlan.upgradeTo': 'Upgrade plan to {name}',
  'billing.subscriptionPlan.downgradeTo': 'Downgrade plan to {name}',
  'billing.subscriptionPlan.updatePlan': 'Upgrade plan',
  'billing.subscriptionPlan.updateAndPay': 'Upgrade and pay {price}',
  'billing.subscriptionPlan.downgradePlan': 'Downgrade plan',
  'billing.subscriptionPlan.reviewChanges': 'Review changes',
  'billing.subscriptionPlan.selectPlan': 'Select your plan',
  'billing.subscriptionPlan.seePlansDetails': 'See plans details',
  'billing.subscriptionPlan.bestChoice': 'Best choice',
  'billing.switchToPremiumModal.title': 'Get access to <span>Premium Plus</span>?',
  'billing.switchToPremiumModal.description':
    'Upgrade to Premium Plus to empower your business with seamless payments!',
  'billing.switchToPremiumModal.acceptPaymentOnline': 'Accept payments online.',
  'billing.switchToPremiumModal.sendInvoices': 'Send invoices and receipts to _(clients).',
  'billing.switchToPremiumModal.cancel': 'No, I want Premium',
  'billing.switchToPremiumModal.confirm': 'Yes, I want Premium Plus',
  'billing.switchToPremiumModal.automateRefunds': 'Automate your refunds.',
  'billing.switchToPremiumModal.trackTransactions': 'Keep track of all your transactions.',
  'billing.subscriptionPlan.premiumPlus.description':
    'Bill _(clients) effortlessly and automate payments and refunds with Premium Plus!',
  'billing.subscriptionPlan.premium.description':
    'Streamline communication with _(client) portal, secure messaging, document exchange, and more.',
  'billing.subscriptionPlan.includedStorage': 'Included storage: {storage}',
  'billing.manageSubscriptions': 'Manage subscription',
  'billing.downgradeToFree.reviewStorage': 'Review workspace storage',
  'billing.downgradeToFree.reviewStorage.description':
    'ExpertBox will automatically remove older files that exceed the storage limit to free up space for new ones.',
  'billing.downgradeToFree.reviewAppointments': 'Review team members _(appointments)',
  'billing.downgradeToFree.reviewMembers.description':
    'Once you switch to Free plan, all of your team members will lose access to workspace and all their data will be deleted.',
  'billing.downgradeToFree.whatToDoWithAppointments':
    'What do you want to do with upcoming _(appointments) of your team members?',
  'billing.downgradeToFree.cancelAllAppointments': 'Cancel all upcoming _(appointments)',
  'billing.downgradeToFree.cancelAndRefundAllAppointments': 'Cancel and refund all upcoming _(appointments)',
  'billing.downgradeToFree.reassignAppointments': 'Reassign all _(appointments) to you',
  'billing.downgradeToFree.reviewPayments': 'Review payments and refunds',
  'billing.downgradeToFree.reviewPayments.description': 'Payments and refunds feature is not available for Free plan.',
  'billing.downgradeToFree.reviewPayments.alert':
    'Once you switch to Free plan, you won’t be able to collect payments, issue invoices and receipts, or refund _(clients) through ExpertBox anymore. This won’t affect any of the _(appointments) booked previously.',
  'billing.downgradeToFree.summary': 'Summary',
  'billing.downgradeToFree.freeSubscription': 'Free subscription',
  'billing.downgradeToFree.totalAmount': 'Total amount',
  'billing.downgradeToFree.seatsAmount': '1 seat',

  'billing.reviewSubscriptionPlanChange.currentPlan': 'Current plan',
  'billing.reviewSubscriptionPlanChange.plannedPlan': 'New plan',
  'billing.reviewSubscriptionPlanChange.features.teamSeats':
    '{includedTeamSeats} team {includedTeamSeats, plural, one {seat} other {seats}}',
  'billing.reviewSubscriptionPlanChange.features.storage': '{includedStorage} of storage space',
  'billing.reviewSubscriptionPlanChange.features.calendarIntegration': 'Sync up to 6 calendars per team member',
  'billing.reviewSubscriptionPlanChange.features.video': 'Group video calls and video call recordings',
  'billing.reviewSubscriptionPlanChange.features.booking': 'Booking widgets and a website',
  'billing.reviewSubscriptionPlanChange.features.clientPortal': '_(Client) portal',
  'billing.reviewSubscriptionPlanChange.features.chat': 'Secure messaging and document exchange',
  'billing.reviewSubscriptionPlanChange.features.sms': 'SMS reminders to _(experts) and _(clients)',
  'billing.reviewSubscriptionPlanChange.features.acceptPayments': 'Accept payments online',
  'billing.reviewSubscriptionPlanChange.features.onlyPremiumPlus': 'Available only for Premium Plus',
  'billing.reviewSubscriptionPlanChange.alert.postponedApply':
    'Your {currentPlan} plan subscription will be active until {actionDate}. Starting from that date you will switch to {plannedPlan} plan.',
  'billing.reviewSubscriptionPlanChange.alert.postponedApply.withSeatsAndStorageUpgrade':
    'Your {currentPlan} plan subscription will be active until {actionDate}. Starting from that date you will switch to {plannedPlan} plan.\n\nStorage and seats upgrade will be applied immediately.',
  'billing.reviewSubscriptionPlanChange.alert.postponedApply.withSeatsUpgrade':
    'Your {currentPlan} plan subscription will be active until {actionDate}. Starting from that date you will switch to {plannedPlan} plan.\n\nSeats upgrade will be applied immediately.',
  'billing.reviewSubscriptionPlanChange.alert.postponedApply.withStorageUpgrade':
    'Your {currentPlan} plan subscription will be active until {actionDate}. Starting from that date you will switch to {plannedPlan} plan.\n\nStorage upgrade will be applied immediately.',
  'billing.reviewSubscriptionPlanChange.alert.proratedUpgrade':
    'Upgrade now and pay a prorated fee for the new plan on {actionDate}, the first day of your next billing period.',
}

export default billing
