import { DateTime } from 'luxon'
import { uniq } from 'ramda'
import { combineReducers } from '@reduxjs/toolkit'

import { Views } from 'lib/constants/bookings'
import {
  ADD_CALENDAR_EVENT,
  RESET_BOOKING_FORM,
  RESET_CANCELLATION_RULES,
  RESET_FILTERS,
  SET_BOOKINGS_BLANK_STATE,
  SET_BOOKINGS_DATE,
  SET_BOOKINGS_VIEW,
  SET_BOOKING_FORM,
  SET_CALENDAR_EVENTS,
  SET_CANCELLATION_ERROR,
  SET_CANCELLATION_RULES,
  SET_FILTER_PARAMS,
  SET_RESCHEDULE_ERROR,
  SET_TIMEZONE,
} from './types'

const calendarEventsIds = (state = [], action) => {
  switch (action.type) {
    case SET_CALENDAR_EVENTS:
      return action.Ids
    case ADD_CALENDAR_EVENT:
      return uniq([action.id, ...state])
    default:
      return state
  }
}

const filtersInitialState = {
  statuses: [],
  employees: [],
  clients: [],
  services: [],
  calendarTypes: [],
  name: '',
  locations: [],
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    case RESET_FILTERS:
      return filtersInitialState
    default:
      return state
  }
}

// Using local timezone and date without time since react-big-calendar uses only local timezone
const date = (state = DateTime.now().toISODate(), action) => {
  switch (action.type) {
    case SET_BOOKINGS_DATE:
      return action.date
    default:
      return state
  }
}

const timezone = (state = null, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return action.timezone
    default:
      return state
  }
}

const view = (state = Views.WEEK, action) => {
  switch (action.type) {
    case SET_BOOKINGS_VIEW:
      return action.view
    default:
      return state
  }
}

const cancellationRulesInitialState = {
  description: '',
}
const cancellationRules = (state = cancellationRulesInitialState, action) => {
  switch (action.type) {
    case SET_CANCELLATION_RULES:
      return {
        time: action.time,
        description: action.description,
        bookingRefundAmount: action.bookingRefundAmount,
        refundAmount: action.refundAmount,
        refundCount: action.refundCount,
        validateCancellationReason: action.validateCancellationReason,
      }
    case RESET_CANCELLATION_RULES:
      return cancellationRulesInitialState
    default:
      return state
  }
}

const cancellationError = (state = null, action) => {
  switch (action.type) {
    case SET_CANCELLATION_ERROR:
      return action.error
    default:
      return state
  }
}

const rescheduleError = (state = null, action) => {
  switch (action.type) {
    case SET_RESCHEDULE_ERROR:
      return action.error
    default:
      return state
  }
}

const isBlankState = (state = true, action) => {
  switch (action.type) {
    case SET_BOOKINGS_BLANK_STATE:
      return action.isBlankState
    default:
      return state
  }
}

const bookingForm = (state = {}, action) => {
  switch (action.type) {
    case SET_BOOKING_FORM:
      return {
        initialValues: action.initialValues,
        values: action.values,
        id: action.id,
      }
    case RESET_BOOKING_FORM:
      return {}
    default:
      return state
  }
}

export const persistedBookings = combineReducers({ view })

export const bookings = combineReducers({
  calendarEventsIds,
  filters,
  date,
  timezone,
  cancellationRules,
  cancellationError,
  rescheduleError,
  isBlankState,
  bookingForm,
})
