import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import { sendCommand } from 'state/concepts/webSockets/actions'
import { joinChatCommand } from 'utils/chat'
import { RECEIVE_CHAT } from 'state/concepts/chats/types'
import { dataApiSuccess } from 'state/data/actions'
import { addChats } from 'state/concepts/chats/actions'

const receiveChatOperation = createLogic({
  type: RECEIVE_CHAT,
  latest: true,

  process({ action }, dispatch, done) {
    const { data } = action
    const response = normalize(data)

    dispatch(dataApiSuccess({ response }))

    const { id } = data.data
    dispatch(addChats([id]))
    dispatch(sendCommand(joinChatCommand(id)))
    done()
  },
})

export default receiveChatOperation
