const companyClientsImport = {
  'clients.import.importClients': 'Import _(clients)',
  'clients.import.steps.upload': 'Prepare and upload',
  'clients.import.steps.match': 'Match',
  'clients.import.steps.import': 'Import',

  'clients.import.discard.title': 'Discard import?',
  'clients.import.discard.bodyText': 'Do you want to discard importing your _(clients)?',
  'clients.import.discard.confirm': 'Discard import',

  'clients.import.steps.upload.downloadSample': 'Download a sample',
  'clients.import.steps.upload.downloadSampleFile': 'Download a sample CSV file',
  'clients.import.steps.upload.downloadSampleDescription':
    '{link} to learn how to organize your _(client) information.',
  'clients.import.steps.upload.prepareFile': 'Prepare your file',
  'clients.import.steps.upload.prepareFileDescription':
    'Add field names in the first row, e.g. First name, Last name, etc.',
  'clients.import.steps.upload.fillInFields': 'Fill in required fields',
  'clients.import.steps.upload.fillInFieldsDescription':
    'Make sure that all the _(clients) include First name, Last name, and email address columns.',
  'clients.import.steps.upload.uploadFile': 'Upload CSV file',
  'clients.import.steps.upload.deleteFile': 'Delete file',

  'clients.import.steps.match.title': 'Match imported fields to ExpertBox _(client) fields',
  'clients.import.steps.match.matching': 'Please, wait. Matching in progress...',
  'clients.import.steps.match.backToUpload': 'Back to uploading',
  'clients.import.steps.match.unmatchedFields':
    '{count} unmatched {count, plural, one {field} other {fields}} will not be imported.',
  'clients.import.steps.match.requiredFieldsUnmatched':
    'To continue, match the first name, last name and email to the correct ExpertBox _(client) fields.',
  'clients.import.steps.match.duplicatedFields': '{fieldName} can only be matched to one field.',
  'clients.import.steps.match.table.importedFields': 'Imported fields',
  'clients.import.steps.match.table.expertboxFields': 'ExpertBox _(client) fields',
  'clients.import.steps.match.select.placeholder': 'Select field',
  'clients.import.steps.match.select.createCustomField': 'Create custom field',
  'clients.import.steps.match.profileFields.firstName': 'First Name',
  'clients.import.steps.match.profileFields.lastName': 'Last Name',
  'clients.import.steps.match.profileFields.email': 'Email',
  'clients.import.steps.match.profileFields.phoneNumber': 'Phone Number',
  'clients.import.steps.match.profileFields.skip': "Don't import",
  'clients.import.steps.match.result.title': 'Some _(clients) have errors',
  'clients.import.steps.match.result.duplicated':
    '<b>{count}</b> {count, plural, one {_(client)} other {_(clients)}} won’t be imported because they are duplicates.',
  'clients.import.steps.match.result.incomplete':
    '<b>{count}</b> {count, plural, one {_(client)} other {_(clients)}} will be imported with incomplete or incorrect fields.',
  'clients.import.steps.match.result.errors':
    '<b>{count}</b> {count, plural, one {_(client)} other {_(clients)}} won’t be imported because the required fields are missing.',
  'clients.import.steps.match.result.wantToFix': 'Want to fix your file?',
  'clients.import.steps.match.result.viewErrors': 'Take a minute to {link} and solutions, edit file and upload again.',
  'clients.import.steps.match.result.link': 'view errors',

  'clients.import.steps.import.title':
    'What do you want to do if some _(clients) from the imported file already exist in your workspace?',
  'clients.import.steps.import.processing': 'Please, wait. Import in progress...',
  'clients.import.steps.match.backToMatch': 'Back to fields mapping',
  'clients.import.steps.import.options.skipDuplicates.label': 'Skip duplicates',
  'clients.import.steps.import.options.skipDuplicates.description': "Don't change existing _(client) information.",
  'clients.import.steps.import.options.overwriteDuplicates.label': 'Overwrite duplicates',
  'clients.import.steps.import.options.overwriteDuplicates.description':
    'Update existing _(client) information to match your import file.',
  'clients.import.steps.import.result.title': 'Import completed',
  'clients.import.steps.import.result.success':
    '<b>{count}</b> new {count, plural, one {_(client) was} other {_(clients) were}} imported.',
  'clients.import.steps.import.result.skipped':
    '<b>{count}</b> duplicate {count, plural, one {_(client) was} other {_(clients) were}} skipped.',
  'clients.import.steps.import.result.updated':
    '<b>{count}</b> existing {count, plural, one {_(client) was} other {_(clients) were}} updated.',
  'clients.import.steps.import.result.warnings':
    '<b>{count}</b>  {count, plural, one {_(client) was} other {_(clients) were}} imported with incomplete or incorrect fields.',
  'clients.import.steps.import.result.errors':
    '<b>{count}</b> {count, plural, one {_(client) was} other {_(clients) were}} not imported because the required fields are missing.',
  'clients.import.steps.import.result.errorsHint':
    'Make sure that all the _(clients) include First name, Last name, and email address columns.',
}

export default companyClientsImport
