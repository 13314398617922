import PropTypes from 'prop-types'
import classNames from 'clsx'

import FolderO from 'views/shared/icons/FolderO'
import ArrowRight from 'views/shared/icons/ArrowRight'
import TruncatedTextByWidthTooltip from 'views/shared/TruncatedTextByWidthTooltip'

const Directory = ({ name, hasChildren, isSelected, onClick }) => (
  <div
    onClick={onClick}
    role="button"
    className={classNames('directories__element d-flex justify-content-between column-gap-6 pl-24 pr-24 pt-16 pb-16', {
      'directories__element--selected': isSelected,
    })}
  >
    <div className="d-flex align-items-center column-gap-16 min-w-0">
      <div className="wh-24-24 d-flex align-items-center justify-content-center">
        <FolderO size={20} className="directories__folder-icon in-disabled-icon" />
      </div>
      <div className="min-w-0">
        <p className="text-body mb-0">
          <TruncatedTextByWidthTooltip on={['hover']} overlayClassName="max-w-50" text={name} />
        </p>
      </div>
    </div>
    {hasChildren && (
      <div className="wh-24-24 d-flex align-items-center justify-content-center">
        <ArrowRight size={10} className="directories__element-arrow-icon in-disabled-icon" />
      </div>
    )}
  </div>
)

Directory.defaultProps = {
  hasChildren: true,
  isSelected: false,
}

Directory.propTypes = {
  name: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default Directory
