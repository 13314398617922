import React from 'react'
import PropTypes from 'prop-types'
import delay from 'lodash/delay'

import { ALERT_KINDS } from 'lib/constants'
import getDisplayName from 'utils/getDisplayName'

const NOTIFICATION_LIFETIMES = {
  [ALERT_KINDS.success]: 3000,
  [ALERT_KINDS.videocall]: 3000,
}

const closeableNotification = WrappedComponent => {
  class CloseableNotification extends React.Component {
    static propTypes = {
      hideNotification: PropTypes.func.isRequired,
      id: PropTypes.string.isRequired,
      context: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
    }

    static displayName = `closeableNotification(${getDisplayName(WrappedComponent)})`

    componentDidMount = () => {
      const notificationLifetime = NOTIFICATION_LIFETIMES[this.props.kind]

      if (notificationLifetime) {
        delay(this.handleClose, notificationLifetime)
      }
    }

    componentWillUnmount = () => {
      if (this.props.kind !== ALERT_KINDS.success) {
        this.handleClose()
      }
    }

    handleClose = () => {
      const { id, context } = this.props
      this.props.hideNotification({ id, context })
    }

    render = () => <WrappedComponent {...this.props} onClose={this.handleClose} />
  }

  return CloseableNotification
}

export default closeableNotification
