import { combineReducers } from '@reduxjs/toolkit'

import {
  SET_CLIENT_RELATIONSHIP_TYPES_IDS,
  SET_CLIENT_RELATIONSHIP_TYPE_ID,
  SET_FILTER_PARAMS,
  RESET_CLIENT_RELATIONSHIP_TYPE_ID,
} from './types'

const ids = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENT_RELATIONSHIP_TYPES_IDS:
      return action.ids
    default:
      return state
  }
}

const id = (state = null, action) => {
  switch (action.type) {
    case SET_CLIENT_RELATIONSHIP_TYPE_ID:
      return action.id
    case RESET_CLIENT_RELATIONSHIP_TYPE_ID:
      return null
    default:
      return state
  }
}

export const filtersInitialState = {
  name: '',
}
const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return { ...state, ...action.filterParams }
    default:
      return state
  }
}

const clientRelationshipTypesReducer = combineReducers({
  ids,
  filters,
  id,
})

export default clientRelationshipTypesReducer
