import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { pageCountSelector, currentPageSelector } from 'state/data/selectors'
import { searchServices as searchServicesAction } from 'state/concepts/services/actions'
import { fetchServicesEndpoint } from 'state/concepts/services/endpoints'
import Spinner from 'views/shared/icons/Spinner'

class Loader extends React.Component {
  static propTypes = {
    searchServices: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    excludeServices: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    userProfileId: PropTypes.string,
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
    excludeServices: null,
    statuses: [],
    userProfileId: undefined,
  }

  handleLoadMore = () => {
    const { searchServices, currentPage, pageCount, searchQuery, excludeServices, statuses, userProfileId } = this.props

    if (pageCount >= currentPage + 1) {
      searchServices({ query: searchQuery, exclude: excludeServices, statuses, userProfileId, page: currentPage + 1 })
    }
  }

  render() {
    const { isLoading } = this.props

    if (isLoading !== false) {
      return <Spinner size={18} />
    }

    return <Waypoint onEnter={this.handleLoadMore} bottomOffset="-100px" />
  }
}

const mapStateToProps = state => {
  const { endpoint } = fetchServicesEndpoint

  return {
    pageCount: pageCountSelector(state, endpoint),
    currentPage: currentPageSelector(state, endpoint),
  }
}

const mapDispatchToProps = {
  searchServices: searchServicesAction,
}

export { Loader as LoaderContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Loader)
