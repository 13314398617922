export const MAX_RECORD_SECONDS = 600

export const DEFAULT_RECORDER_OPTIONS = {
  waveColor: '#388BFF',
  progressColor: '#388BFF',
  height: 30,
  barWidth: 3,
  barHeight: 6,
  barGap: 3,
  barRadius: 2,
  responsive: true,
  cursorColor: 'transparent',
  fillParent: true,
}

export const DEFAULT_PLAYER_OPTIONS = {
  waveColor: '#DDE2E8',
  progressColor: '#388BFF',
  height: 30,
  barWidth: 3,
  barHeight: 1,
  barGap: 3,
  barRadius: 2,
  responsive: true,
  cursorWidth: 0,
  fillParent: true,
}

export const PLAYBACK_RATE_VALUES = [0.75, 1, 1.25, 1.5, 2]
