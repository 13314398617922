import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import {
  removeServiceCategories as removeServiceCategoriesAction,
  setSelectedServiceCategories as setSelectedServiceCategoriesAction,
  filterServicesByCategories as filterServicesByCategoriesAction,
} from 'state/concepts/serviceCategories/actions'
import {
  removeServiceCategoriesLoadingSelector,
  selectedServiceCategoriesWithServicesSelector,
} from 'state/concepts/serviceCategories/selectors'
import ConfirmModal from 'views/shared/ConfirmModal'

const ServiceCategoriesBulkRemoveModal = ({ serviceCategoriesIds, onClose }) => {
  const isLoading = useSelector(removeServiceCategoriesLoadingSelector)
  const withServices = useSelector(selectedServiceCategoriesWithServicesSelector)
  const removeServiceCategories = useDispatchAction(removeServiceCategoriesAction)
  const setSelectedServiceCategories = useDispatchAction(setSelectedServiceCategoriesAction)
  const filterServicesByCategories = useDispatchAction(filterServicesByCategoriesAction)
  const withServicesPresent = withServices.length >= 1

  const bodyText = () => {
    const selectedCount = serviceCategoriesIds.length

    if (withServicesPresent && selectedCount === 1) {
      return [
        [{ id: 'serviceCategories.bulkRemove.modal.body.someCategoryWithService' }],
        [{ id: 'serviceCategories.bulkRemove.modal.body.removeService' }],
      ]
    }

    if (withServicesPresent && selectedCount !== withServices.length) {
      return [
        [{ id: 'serviceCategories.bulkRemove.modal.body.categoriesWithService' }],
        [{ id: 'serviceCategories.bulkRemove.modal.body.removeService' }],
      ]
    }

    if (withServicesPresent) {
      return [
        [
          { id: 'serviceCategories.bulkRemove.modal.body.someCategoriesWithServices' },
          {
            id: 'serviceCategories.bulkRemove.modal.body.categoriesWithServices',
            values: { count: selectedCount },
            isBold: true,
          },
        ],
        [{ id: 'serviceCategories.bulkRemove.modal.body.removeService' }],
      ]
    }

    if (selectedCount === 1) {
      return [[{ id: 'serviceCategories.bulkRemove.modal.body.removeCategory' }]]
    }

    return [
      [
        { id: 'serviceCategories.bulkRemove.modal.body.start' },
        {
          id: 'serviceCategories.bulkRemove.modal.body.categoriesWithoutServices',
          values: { count: selectedCount },
          isBold: true,
        },
      ],
    ]
  }

  const submitKind = withServicesPresent
    ? { submitText: { id: 'serviceCategories.bulkRemove.modal.manageServicesButton' } }
    : { submitText: { id: 'shared.remove' }, kind: 'danger' }

  const handleSubmit = () => {
    if (withServicesPresent) {
      filterServicesByCategories()
    } else {
      removeServiceCategories(serviceCategoriesIds)
      setSelectedServiceCategories([])
    }
  }

  return (
    <ConfirmModal
      title={{ id: 'serviceCategories.bulkRemove.modal.title' }}
      bodyText={bodyText()}
      {...submitKind}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

ServiceCategoriesBulkRemoveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceCategoriesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ServiceCategoriesBulkRemoveModal
