import { pipe, pick, map, uniq } from 'ramda'

import { selectedAvailabilities } from 'lib/calendar/selectionHandling'

const availabilityToFormValues = ({ selectedCells, availabilityMapping, isVideoCallsEnabled, activeLocations }) => {
  const availabilities = selectedAvailabilities(selectedCells, availabilityMapping)
  const uniqAvailabilities = pipe(map(pick(['locationIds', 'online'])), uniq)(availabilities)

  const values =
    uniqAvailabilities.length === 1
      ? { ...uniqAvailabilities[0], online: uniqAvailabilities[0].online && isVideoCallsEnabled }
      : { locationIds: [], online: isVideoCallsEnabled }

  return activeLocations.length === 1 ? { ...values, locationIds: [activeLocations[0].id] } : values
}

export default availabilityToFormValues
