import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import Modal from 'views/shared/Modal'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import SelectField from 'views/shared/SelectField'
import Cross from 'views/shared/icons/Cross'

const VideoConferenceSettingsModalComponent = ({
  audioDevices,
  videoDevices,
  speakerDevices,
  onSubmit,
  onClose,
  isSubmitDisabled,
}) => (
  <Modal className="main-modal main-modal--md" onClose={onClose}>
    <div className="main-modal__container">
      <div className="main-modal__header">
        <h2 className="main-modal__title">
          <FormattedMessage id="videoConference.settingsModal.settings" />
        </h2>
        <button type="button" onClick={onClose} className="main-modal__close">
          <Cross dataCy="cross-icon" />
        </button>
      </div>
      <div className="main-modal__body overflow-x-hidden">
        <Field
          className="mb-16"
          id="videoDevice"
          name="videoDevice"
          label={{ id: 'videoConference.settingsModal.camera' }}
          options={videoDevices}
          component={SelectField}
          labelInValue
        />
        <Field
          name="audioDevice"
          id="audioDevice"
          label={{ id: 'videoConference.settingsModal.microphone' }}
          options={audioDevices}
          component={SelectField}
          labelInValue
        />
        <Field
          name="speakerDevice"
          id="speakerDevice"
          label={{ id: 'videoConference.settingsModal.speakers' }}
          options={speakerDevices}
          component={SelectField}
          labelInValue
        />
      </div>
      <div className="main-modal__footer">
        <div>
          <Button className="mr-16" kind="flat" type="submit" text={{ id: 'shared.cancel' }} onClick={onClose} />
          <SubmitFormButton
            kind={isSubmitDisabled ? 'outline' : null}
            text={{ id: 'shared.update' }}
            onClick={onSubmit}
            isDisabled={isSubmitDisabled}
          />
        </div>
      </div>
    </div>
  </Modal>
)

VideoConferenceSettingsModalComponent.propTypes = {
  audioDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  videoDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  speakerDevices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
}

export default VideoConferenceSettingsModalComponent
