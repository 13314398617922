import endpoint from 'utils/endpoint'
import {
  notificationsRoute,
  notificationRoute,
  notificationsReadRoute,
  notificationsUnreadRoute,
  notificationsBulkActionsReadRoute,
} from 'lib/apiRoutes'
import {
  FETCH_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  MARK_NOTIFICATIONS_AS_READ,
} from './types'

export const fetchNotificationsEndpoint = endpoint(FETCH_NOTIFICATIONS, 'GET', notificationsRoute)
export const removeNotificationEndpoint = notificationId =>
  endpoint(REMOVE_NOTIFICATION, 'DELETE', notificationRoute(notificationId))

export const markNotificationAsReadEndpoint = notificationId =>
  endpoint(MARK_NOTIFICATION_AS_READ, 'POST', notificationsReadRoute(notificationId))

export const markNotificationAsUnreadEndpoint = notificationId =>
  endpoint(MARK_NOTIFICATION_AS_UNREAD, 'POST', notificationsUnreadRoute(notificationId))

export const markNotificationsAsReadEndpoint = endpoint(
  MARK_NOTIFICATIONS_AS_READ,
  'POST',
  notificationsBulkActionsReadRoute,
)
