import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import classNames from 'clsx'
import TruncateMarkup from 'react-truncate-markup'

import Tooltip from 'views/shared/Tooltip'
import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage'

const TruncatedTextByWidthTooltip = ({
  text,
  lines,
  onTruncate,
  isTruncated,
  overlayClassName,
  on,
  placement,
  intl,
  align,
}) => (
  <Tooltip
    placement={placement}
    overlayClassName={classNames('main-tooltip', overlayClassName)}
    isShown={isTruncated}
    on={on}
    align={align}
    icon={
      <div>
        <TruncateMarkup lines={lines} onTruncate={onTruncate}>
          <div style={{ wordWrap: 'break-word' }}>
            {is(Object, text) ? intl.formatMessage({ id: text.id }, text.values) : text}
          </div>
        </TruncateMarkup>
      </div>
    }
  >
    <div>
      <FormattedOrRawMessage message={text} />
    </div>
  </Tooltip>
)

TruncatedTextByWidthTooltip.defaultProps = {
  overlayClassName: undefined,
  on: undefined,
  placement: 'bottom',
  lines: 1,
  align: undefined,
}

TruncatedTextByWidthTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  lines: PropTypes.number,
  onTruncate: PropTypes.func.isRequired,
  isTruncated: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string,
  on: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover', 'contextMenu'])),
  placement: PropTypes.string,
  intl: PropTypes.shape().isRequired,
  align: PropTypes.shape({
    offset: PropTypes.arrayOf(PropTypes.number),
  }),
}

export default TruncatedTextByWidthTooltip
