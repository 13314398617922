const acceptPaymentsFeature = {
  'acceptPaymentsFeature.title': 'Accept payments',
  'acceptPaymentsFeature.subtitle': '<span>in</span> Payments',
  'acceptPaymentsFeature.description': 'Accept payments through ExpertBox using online or manual payment methods.',
  'acceptPaymentsFeature.manage': 'Set up payments',
  'acceptPaymentsFeature.overview': 'Accept payments overview',
  'acceptPaymentsFeature.subheader':
    'ExpertBox offers multiple payment options to help you get paid quickly and easily. You can connect to an online payment provider like Stripe or PayPal or take advantage of support for manual payments to get paid outside the ExpertBox platform.',
  'acceptPaymentsFeature.collectPayments': 'Collect payments online:',
  'acceptPaymentsFeature.clientsPayInstantly': 'Let _(clients) pay instantly and effortlessly by credit card.',
  'acceptPaymentsFeature.flexiblePaid':
    'Get paid up front, right from your booking page, or let _(clients) pay after the _(appointment) is over.',
  'acceptPaymentsFeature.trackPaymentRequests': 'Keep track of all payment requests and their statuses.',
  'acceptPaymentsFeature.manualPayments':
    'If you’d like to accept payments by cash, check, bank transfer, or any other methods outside of the ExpertBox platform, you can add a manual payment method:',
  'acceptPaymentsFeature.paymentInstructions':
    'Write payment instructions to include in your payment requests to _(clients).',
  'acceptPaymentsFeature.markAsPaid': 'Mark requests as paid once you have received the money.',
  'acceptPaymentsFeature.viewTransactions':
    'You can view all payment transactions in one place, making it easy to track and manage your finances:',
  'acceptPaymentsFeature.paymentHistory': 'View your complete payment history.',
  'acceptPaymentsFeature.filterPayments': 'Filter payments by _(service), _(client), status, or date range.',
  'acceptPaymentsFeature.downloadInvoices': 'View and download receipts, invoices, and credit notes.',
  'acceptPaymentsFeature.toLearnMores':
    'To learn more, read or help center article on <link>accepting payments in ExpertBox</link>.',
  'acceptPaymentsFeature.disableAcceptPayments': 'Disable accepting payments?',
  'acceptPaymentsFeature.disableAcceptPaymentsWarning.paragraphOne':
    'You won’t be able to collect payments, issue invoices and receipts, or refund _(clients) though ExpertBox anymore.',
  'acceptPaymentsFeature.disableAcceptPaymentsWarning.paragraphTwo':
    'Once you disable the feature, your _(clients) won’t be able to pay you through ExpertBox.',
  'acceptPaymentsFeature.alert.text':
    'Unfortunately, you cannot disconnect Stripe now. Your _(Clients) use this method for auto-billing in recurring payments. Please, cancel these recurring payments first.',
}

export default acceptPaymentsFeature
