import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { clientBookingsRoute } from 'lib/routes'
import { currentUserTimezoneOffsetSelector, currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'
import { showNotification } from 'state/notifications/actions'
import redirect from 'utils/redirect'
import { updateClientWaitlistRequestValuesToParams } from 'utils/waitlistRequests/buildWaitlistParams'
import { updateClientWaitlistRequestEndpoint } from '../endpoints'
import { UPDATE_CLIENT_WAITLIST_REQUEST } from '../types'

const updateClientWaitlistRequestOperation = createLogic({
  type: UPDATE_CLIENT_WAITLIST_REQUEST,
  latest: true,

  async process({ httpClient, getState, action: { values, form } }, dispatch, done) {
    const state = getState()
    const { waitlistRequestId } = values
    const workspaceCode = currentWorkspaceCodeSelector(state)
    const timezoneOffset = currentUserTimezoneOffsetSelector(state)
    const { url } = updateClientWaitlistRequestEndpoint(waitlistRequestId)

    try {
      await httpClient.put(url, updateClientWaitlistRequestValuesToParams(values, timezoneOffset))

      redirect({ href: clientBookingsRoute(workspaceCode) })
      dispatch(showNotification({ messageObject: { id: 'notifications.clientWaitlistRequestUpdated' } }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
      form.setSubmitting(false)
    }
    done()
  },
})

export default updateClientWaitlistRequestOperation
