import { createLogic } from 'redux-logic'
import formatJsonApiErrors from 'utils/formatJsonApiErrors'
import Router from 'next/router'
import { pathOr } from 'ramda'

import requestErrorHandler from 'lib/requestErrorHandler'
import { companyBookingsRoute } from 'lib/routes'
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus'
import redirect from 'utils/redirect'
import { showNotification } from 'state/notifications/actions'
import { hideModal, showModal } from 'state/modal/actions'
import { REMOVE_EMPLOYEE } from 'state/concepts/employees/types'
import { fetchEmployees } from 'state/concepts/employees/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { removeEmployeeEndpoint } from 'state/concepts/employees/endpoints'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const removeEmployeeOperation = createLogic({
  type: REMOVE_EMPLOYEE,
  latest: true,

  async process({ action: { employeeId, values, form }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = removeEmployeeEndpoint(employeeId)
    const state = getState()
    const { newFileOwnerId } = values
    const params = newFileOwnerId ? { params: { new_file_owner_id: newFileOwnerId } } : {}

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.delete(url, params)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(showNotification({ messageObject: { id: 'notifications.memberRemoved' } }))
      if (Router.router.asPath.includes(`employees/${employeeId}`)) {
        redirect({ href: companyBookingsRoute, workspace: currentWorkspaceCodeSelector(state) })
      } else {
        dispatch(fetchEmployees())
      }
    } catch (error) {
      const errors = pathOr(null, ['response', 'data', 'errors'], error)
      const formattedErrors = formatJsonApiErrors(errors)
      if (isErrorStatusUnprocessableEntity(error) && formattedErrors.base) {
        dispatch(
          showModal({
            modalType: 'EMPLOYEES_ERROR_MODAL',
            modalProps: { error: formattedErrors.base },
          }),
        )
      } else {
        requestErrorHandler({ error, dispatch, endpoint, form })
        form.setSubmitting(false)
      }
    }
    done()
  },
})

export default removeEmployeeOperation
