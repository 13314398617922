import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { reviewsByProviderIdSelector } from 'state/concepts/website/selectors'
import Review from './Review'

const Reviews = ({ color, id }) => {
  const reviews = useSelector(state => reviewsByProviderIdSelector(state, id))

  return (
    <div className="widget-sidebar__info pt-24 pb-24 bg-white">
      <div className="widget-sidebar__container">
        <div className="text-left">
          {reviews.map(review => (
            <Review key={review.id} {...review} color={color} />
          ))}
        </div>
      </div>
    </div>
  )
}

Reviews.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default Reviews
