import { pickBy, keys, includes, groupBy, isEmpty, isNil, prop } from 'ramda'

const checkDuplicates = (form, { without } = {}) => {
  const { setStatus, values } = form
  const invitations = [...values.invitations]

  if (!isNil(without)) {
    invitations.splice(without, 1)
  }

  const duplicateEmails = keys(
    pickBy((value, key) => !isEmpty(key) && value.length > 1, groupBy(prop('email'), invitations)),
  )

  if (!isEmpty(duplicateEmails)) {
    setStatus({
      invitations: pickBy(({ email }) => includes(email, duplicateEmails), invitations),
    })
  } else {
    setStatus(undefined)
  }
}

export default checkDuplicates
