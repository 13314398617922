const website = {
  'website.page.experts': '_(Experts)',
  'website.page.services': '_(Services)',
  'website.page.locations': 'Locations',
  'website.page.reviews': 'Reviews',
  'website.page.contacts': 'Contacts',
  'website.page.clientPortal': '_(Client) portal',
  'website.page.bookAppointment': 'Book _(appointment)',
  'website.page.privacyPolicy': 'Privacy Policy',
  'website.page.termsOfUse': 'Terms of use',
  'website.page.poweredBy': 'Powered by',
  'website.page.showMore': 'Show more',
  'website.page.showLess': 'Hide',

  'website.page.providers.title': '_(Experts)',
  'website.page.providers.showMore': 'Show more _(experts)',
  'website.page.providers.viewProfile': 'View profile',
  'website.page.services.title': '_(Services) we provide',
  'website.page.services.showMore': 'Show more _(services)',
  'website.page.services.serviceSidebar.expertsAvailable':
    '{count, plural, one {_(expert)} other {_(experts)}} available',
  'website.page.providers.providerSidebar.servicesAvailable':
    '{count, plural, one {_(service)} other {_(services)}} available',
  'website.page.providers.providerSidebar.aboutTab': 'About _(expert)',
  'website.page.providers.providerSidebar.reviewsTab': 'Reviews <span>{reviewsCount}</span>',

  'website.termsAndConditions.modal.title': 'Terms and conditions',
  'website.privacyPolicy.modal.title': 'Privacy policy',

  'website.page.reviews.title': 'Reviews',
  'website.page.reviews.averageRating': '{rating} average rating',
  'website.page.reviews.reviewsCount': '{count} {count, plural, one {review} other {reviews}}',
  'website.page.reviews.loadMore': 'Show more reviews',
  'website.page.reviews.forServiceWithProfile':
    '<span>For the _(service)</span> {serviceName} <span>with</span> {profileName}',

  'website.page.locations.title': 'Locations',
  'website.page.locations.viewOnGoogle': 'View on Google Maps',
  'website.page.locations.onlineAppointments.title': 'Online _(appointments)',
  'website.page.locations.onlineAppointments.description':
    'Book _(appointment) online and have an online call with the selected _(expert) at the appointed time.',

  'website.settings.alert.button': 'Website settings',
  'website.settings.name': '{websiteName} website',
  'website.settings.alert.description.deleted':
    'Connected booking page was deleted. Please select a new booking page to publish your website.',
  'website.settings.alert.description.deactivated':
    'Connected booking page was deactivated. Please select a new booking page to publish your website.',
  'website.settings.status.published': 'Published',
  'website.settings.status.unpublished': 'Unpublished',
  'website.settings.button.publish': 'Review and publish',
  'website.settings.button.settings': 'Settings',
  'website.settings.button.customize': 'Customize website',
  'website.settings.copyWebsite.title': 'Copy website link',
  'website.settings.copyWebsite.notification': 'Link copied to clipboard',
  'website.settings.empty.title': 'Set up your booking website',
  'website.settings.empty.description.activeWidget':
    'Set up a professional-looking website and get more bookings — no coding skills required.',
  'website.settings.empty.description.noActiveWidget':
    'To create a website, you need to have at least one active booking page.',
  'website.settings.empty.button.activeWidget': 'Create website',
  'website.settings.empty.button.noActiveWidget': 'Create booking page',

  'website.settings.general.deleteWebsite.title': 'Delete website',
  'website.settings.general.deleteWebsite.description': 'This will delete the website with all the data and settings.',
  'website.settings.general.deleteWebsite.action': 'Delete website',
  'website.settings.general.deleteWebsite.modal.title': 'Delete the website?',
  'website.settings.general.deleteWebsite.modal.description':
    'You are about to delete the website and all its data. This won’t affect any of the _(appointments) booked via the website.',
}

export default website
