import { combineReducers } from '@reduxjs/toolkit'

import { SET_CUSTOM_NAME } from './types'

const customNameId = (state = null, action) => {
  switch (action.type) {
    case SET_CUSTOM_NAME:
      return action.customNameId
    default:
      return state
  }
}

export default combineReducers({
  customNameId,
})
