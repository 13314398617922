import { configureStore, Tuple } from '@reduxjs/toolkit'
import { Settings } from 'luxon'
import { createWrapper } from 'next-redux-wrapper'
import { createLogicMiddleware } from 'redux-logic'
import { persistReducer, persistStore } from 'redux-persist'

import { IS_PRODUCTION } from 'lib/constants'
import buildHttpClient, { workspacesHttpClient } from 'lib/httpClient'
import isServer from 'utils/isServer'
import conceptsOperations from '../concepts/operationsRoot'
import rootReducer from './reducer'

const operations = [...conceptsOperations]

const withReduxPersist = reducer => {
  // eslint-disable-next-line global-require
  const storage = require('redux-persist/lib/storage').default

  const persistConfig = {
    key: 'app',
    whitelist: ['recentWorkspaces', 'alert', 'subdomain', 'persistedBookings'],
    storage,
  }

  return persistReducer(persistConfig, reducer)
}

/**
 * @info https://github.com/jeffbski/redux-logic/issues/83#issuecomment-623204978
 */
const getSlicedOperation = (deps, quantity = 150) => {
  const slicedOperations = []
  let start = 0
  const { length: end } = operations

  while (start < end) {
    const slice = operations.slice(start, start + quantity)
    start += quantity
    slicedOperations.push(createLogicMiddleware(slice, deps))
  }

  return slicedOperations
}

export const makeStore = () => {
  const operationsDependencies = {
    httpClient: buildHttpClient(),
    workspacesHttpClient,
  }

  const slicedOperations = getSlicedOperation(operationsDependencies)

  const store = configureStore({
    reducer: isServer() ? rootReducer : withReduxPersist(rootReducer),
    middleware: () => new Tuple(...slicedOperations),
    enhancers: getDefaultEnhancers => getDefaultEnhancers({ autoBatch: false }),
    devTools: !IS_PRODUCTION,
  })

  let persistedStore = null
  if (!isServer()) {
    persistedStore = persistStore(store)
  }

  store.logicMiddlewares = [...slicedOperations]
  store.httpClient = operationsDependencies.httpClient
  if (!isServer()) store.persistedStore = persistedStore

  Settings.defaultLocale = store.getState().intl.locale

  return store
}

export default createWrapper(makeStore)
