import { useMount } from 'react-use'

import useDispatchAction from './useDispatchAction'

const useMountAction = (actionCreator, ...staticProps) => {
  const action = useDispatchAction(actionCreator, ...staticProps)
  useMount(action)
}

export default useMountAction
