// ---User (SELF)---
export const usersAccountProfileRoute = '/users/account/profile'
export const usersAccountEmailRoute = '/users/account/email'
export const usersAccountPasswordRoute = '/users/account/password'
export const usersResetPasswordRoute = '/users/reset_password'
export const usersInvitationsRegistrationRoute = '/users/invitations/registration'
export const usersAccountUserTutorialRoute = '/users/account/user_tutorial'
export const usersAccountConfirmationResendRoute = '/users/account/confirmation/resend'
export const usersAccountEmailResendChangeRequestRoute = '/users/account/emails/resend_change_request'
export const usersAccountUserOnboardingContentRoute = '/users/account/user_onboarding_content'
export const usersAccountConfirmationRoute = '/users/account/confirmation'
export const usersAccountNotificationSettingsRoute = '/users/account/notification_settings'
export const usersAccountLogoutIntervalRoute = '/users/account/logout_interval'
export const usersValidationsClientRoute = '/users/validations/client'
export const usersAccountTwoFactorSettingsMatchPasswordRoute = '/users/account/two_factor_settings/match_password'
export const usersAccountTwoFactorSettingsRoute = '/users/account/two_factor_settings'
export const usersAccountTwoFactorSettingsVerifyPhoneNumberRoute =
  '/users/account/two_factor_settings/verify_phone_number'
export const usersAccountTwoFactorSettingsAppProvisionUriRoute =
  '/users/account/two_factor_settings/app_provisioning_uri'
export const usersAccountTwoFactorSettingsBackupCodesRoute = '/users/account/two_factor_settings/backup_codes'
export const updateCalendarViewSettingRoute = '/users/account/calendar_view_setting'

// ---Client (SELF)---
export const clientsAccountClientOnboardingContentRoute = '/clients/account/client_onboarding_content'
export const clientsAccountProfileRoute = '/clients/account/profile'
export const clientsAccountDeletionConfirmationCodeRoute = '/clients/account/deletion/send_code'
export const clientsAccountBookingsRoute = '/clients/booking_feeds'
export const clientsAccountRoute = '/clients/account'
export const clientsAccountEmailRoute = '/clients/account/email'
export const clientsAccountAvatarRoute = '/clients/account/avatar'
export const clientsAccountConfirmationRoute = '/clients/account/confirmation'
export const clientsAccountConfirmationResendRoute = '/clients/account/confirmation/resend'
export const clientsAccountEmailResendChangeRequestRoute = '/clients/account/emails/resend_change_request'
export const clientsAccountLogoutIntervalRoute = '/clients/account/logout_interval'
export const clientsAccountClientNotificationSettingsRoute = '/clients/account/client_notification_settings'
export const getClientReviewTemplateRoute = bookingUniqCode => `/bookings/${bookingUniqCode}/review_templates`
export const clientCreateReviewRoute = '/reviews'
export const clientsProfileForms = '/clients/client_profile_forms'
export const clientsProfileFormRoute = id => `/clients/client_profile_forms/${id}`
export const clientsAccountSharedFiles = '/clients/shared_files'
export const clientsAccountUploadedFiles = '/clients/uploaded_files'
export const clientsAccountSharedDocuments = '/clients/shared_documents'
export const clientsAccountViewSharedFiles = `/clients/shared_files/view`
export const clientsAccountRestoreRoute = '/clients/account/restore'
export const clientContactsAccountRestoreRoute = id => `/client_contacts/${id}/account/restore`
export const clientsBookingRecurrenceRoute = recurrenceId => `/clients/booking_recurrence/${recurrenceId}/bookings`
export const clientsUsersAssignRoute = clientId => `/clients/${clientId}/users/assign`

// ---Bookings---
export const calendarEventsRoute = '/calendar_events'
export const bookingsRoute = '/bookings'
export const bookingRoute = bookingId => `/bookings/${bookingId}`
export const bookingsCancelRoute = bookingId => `/bookings/${bookingId}/cancel`
export const recurringBookingsCancelRoute = bookingId => `/bookings/${bookingId}/recurrings/cancel`
export const bookingsValidationsCancelRoute = bookingId => `/bookings/${bookingId}/validations/cancel`
export const bookingsValidationsRescheduleRoute = bookingId => `/bookings/${bookingId}/validations/reschedule`
export const bookingsRescheduleRoute = bookingId => `/bookings/${bookingId}/reschedule`
export const bookingsVideoRecordRoute = videoRecordId => `/bookings/video_records/${videoRecordId}`
export const bookingAcceptRoute = bookingId => `/bookings/${bookingId}/accept`
export const bookingDeclineRoute = bookingId => `/bookings/${bookingId}/decline`
export const declineAllRecurringBookingsRoute = bookingUniqCode => `/bookings/${bookingUniqCode}/recurrings/decline`
export const bookingsConflictsRoute = 'bookings/conflicts'
export const bookingsRecurrencesRoute = 'bookings/booking_recurrences'
export const rescheduleRecurringBookingsRoute = bookingId => `${bookingRoute(bookingId)}/recurrings/reschedule`
export const bookingAvailabilityRoute = bookingId => `/bookings/${bookingId}/availabilities`
export const bookingChatHistoryRoute = bookingUniqCode => `/bookings/${bookingUniqCode}/in_call_chat_histories`

// ---EtherpadDocuments---
export const clientDocumentsRoute = clientId => `/clients/${clientId}/documents`
export const clientsDocumentRoute = documentId => `/clients/documents/${documentId}`
export const clientsDocumentDeclineRoute = documentId => `/clients/documents/${documentId}/decline`
export const clientsDocumentSignatoriesRoute = documentId => `/clients/documents/${documentId}/signatories`
export const clientsDocumentDuplicateRoute = documentId => `/clients/documents/${documentId}/duplicate`
export const clientsDocumentVoidRoute = documentId => `/clients/documents/${documentId}/void`
export const clientsDocumentSignRoute = documentId => `/clients/documents/${documentId}/sign`
export const clientsDocumentDownloadRoute = documentId => `/clients/documents/${documentId}/download`
export const clientsDocumentRemindToSignRoute = documentId => `/clients/documents/${documentId}/remind_to_sign`
export const clientsDocumentReviewRoute = documentId => `/clients/documents/${documentId}/review`
export const clientDocumentPermissionsRoute = (clientId, documentId) =>
  `/clients/${clientId}/documents/${documentId}/document_permissions`
export const clientsDocumentPermissionRoute = ({ clientId, documentId, permissionId }) =>
  `/clients/${clientId}/documents/${documentId}/document_permissions/${permissionId}`
export const clientsDocumentTouchRoute = documentId => `/clients/documents/${documentId}/touch`

// ---WaitlistRequests---
export const waitlistRequestsRoute = '/waitlist_requests'
export const waitlistRequestsBookingRoute = '/waitlist_requests/booking'
export const waitlistRequestRoute = id => `/waitlist_requests/${id}`
export const waitlistRequestResolveRoute = id => `/waitlist_requests/${id}/resolve`
export const clientsWaitlistRequestRoute = id => `clients/waitlist_requests/${id}`

// ---WaitlistSettings---
export const waitlistSettingRoute = '/waitlist_setting'

// ---DocumentTemplates---
export const documentTemplatesRoute = '/document_templates'
export const documentTemplateRoute = documentTemplateId => `/document_templates/${documentTemplateId}`
export const documentTemplatePreviewRoute = documentTemplateId => `/document_templates/${documentTemplateId}/review`
export const documentTemplateDiscardRoute = documentTemplateId => `/document_templates/${documentTemplateId}/discard`
export const documentTemplateDeactivatedRoute = documentTemplateId =>
  `/document_templates/${documentTemplateId}/deactivation`
export const documentTemplateActivatedRoute = documentTemplateId =>
  `/document_templates/${documentTemplateId}/activation`
export const documentTemplateTouchRoute = documentTemplateId => `/document_templates/${documentTemplateId}/touch`
export const documentTemplatePublishRoute = documentTemplateId => `/document_templates/${documentTemplateId}/publish`

// ---BusinessFiles---
export const myBusinessFilesRoute = '/business_storages'
export const myBusinessFilesFoldersRoute = '/business_storages/folders'
export const myBusinessFileRoute = myBusinessFileId => `/business_storages/${myBusinessFileId}`
export const duplicateMyBusinessFileRoute = myBusinessFileId => `/business_storages/files/${myBusinessFileId}/duplicate`
export const myBusinessFilePermissionsRoute = myBusinessFileId => `/business_storages/${myBusinessFileId}/permissions`
export const businessStoragesPresignRoute = '/business_storages/presign'
export const businessStoragesUploadRoute = '/business_storages/upload'
export const businessStoragesRestoreRoute = '/business_storages/restore'
export const trashedFilesRoute = '/business_storages/trashed_files'
export const businessStoragesDownloadRoute = '/business_storages/download'
export const businessStoragesDownloadCancelRoute = downloadId => `/business_storages/download/${downloadId}/cancel`
export const sharedFilesRoute = '/business_storages/shared'
export const addClientBusinessFilesRoute = clientId => `/clients/${clientId}/client_attachments/business_storage`
export const emptyTrashRoute = '/business_storages/empty_trash'
export const businessStoragesMoveRoute = fileId => `/business_storages/${fileId}/move`
export const businessStoragesMoveMyFoldersRoute = fileId => `/business_storages/${fileId}/move/my_folders`
export const businessStoragesSharedFoldersMoveRoute = fileId => `/business_storages/${fileId}/move/shared_folders`
export const storageFilesRoute = '/storage_files'
export const businessStoragesTrashRoute = '/business_storages/trash'

// ---Files---
export const multipartsCompleteRoute = '/multipart_uploads/complete'

// ---ClientRelationshipTypes---
export const clientRelationshipTypesRoute = '/client_relationship_types'
export const clientRelationshipTypeRoute = id => `/client_relationship_types/${id}`

// ---WorkspaceSignatureSettings---
export const workspaceSignatureSettingRoute = '/workspace_signature_setting'
export const signatureDisclosureRoute = '/signature_disclosure'

// ---Invite members---
export const inviteMembersRoute = bookingUniqCode => `/bookings/${bookingUniqCode}/invited_members`
export const inviteMemberRoute = id => `/bookings/invited_members/${id}`

// ---Web sockets---
export const webSocketsAuthenticateRoute = '/web_sockets/authenticate'
export const webSocketsPublicAuthenticateRoute = '/web_sockets/public/authenticate'

// ---Chats---
export const chatsRoute = '/chats'
export const chatsUnreadCountRoute = '/chats/unreads'
export const chatRoute = id => `/chats/${id}`
export const chatLeaveRoute = id => `/chats/${id}/leaves`
export const chatMessagesRoute = chatId => `/chats/${chatId}/messages`
export const chatMessagesMessageRoute = messageId => `/chats/messages/${messageId}`
export const chatAttachmentsUploadRoute = chatId => `/chats/${chatId}/attachments/upload`
export const chatsAttachmentsUploadRoute = `/chats/attachments/upload`
export const chatsAttachmentsUploadAttachmentRoute = attachmentId => `/chats/attachments/${attachmentId}/upload`
export const chatsMessagesReadRoute = chatId => `/chats/${chatId}/messages/read`
export const chatsAttachmentsRoute = chatId => `/chats/${chatId}/attachments`
export const chatsAttachmentRoute = attachmentId => `/chats/attachments/${attachmentId}`
export const chatMembersRoute = chatId => `/chats/${chatId}/members`
export const chatRemoveMemberRoute = (chatId, memberId) => `/chats/${chatId}/members/${memberId}`
export const chatUsersRoute = '/chat_users'
export const inCallChatRoute = id => `/in_call_chats/${id}`

// ---Forms---
export const formsRoute = '/forms'
export const formRoute = formId => `/forms/${formId}`
export const formSubmissionRoute = submissionId => `/forms/form_submissions/${submissionId}`
export const formSignSubmissionRoute = submissionId => `/forms/form_submissions/${submissionId}/signs`
export const formSubmissionsRoute = '/forms/form_submissions'
export const formSubmissionsDraftRoute = '/forms/form_submissions/draft'
export const formSubmissionsClearRoute = '/forms/form_submissions/clear'
export const formActivationRoute = formId => `/forms/${formId}/activation`
export const formDeactivationRoute = formId => `/forms/${formId}/deactivation`
export const formSubmissionFormAnswerFilesPresignRoute = '/forms/form_submissions/form_answer_files/presign'
export const formValidationsSettingsRoute = '/forms/validation/settings'
export const formDuplicationRoute = formId => `/forms/${formId}/duplication`
export const formEmptyDownloadRoute = formId => `/forms/${formId}/download`
export const formSubmittedDownloadRoute = formSubmissionId => `/forms/form_submissions/${formSubmissionId}/download`
export const clientProfileFormResendRoute = id => `/clients/client_profile_forms/${id}/resend`
export const clientProfileFormDeclineToSignRoute = id => `/clients/client_profile_forms/${id}/decline`

// ---FormCategories---
export const formCategoriesRoute = '/form_categories'
export const formCategoryRoute = id => `/form_categories/${id}`

// ---Agreements---
export const agreementsRoute = '/agreements'
export const agreementRoute = id => `/agreements/${id}`
export const agreementDownloadRoute = submissionId => `/agreements/agreement_submissions/${submissionId}/download`

// ---Users---
export const usersRoute = '/users'
export const usersDeactivationRoute = userId => `/users/${userId}/deactivation`
export const usersActivationRoute = userId => `/users/${userId}/activation`
export const userRoute = userId => `/users/${userId}`
export const userInvitationsRoute = userId => `/users/${userId}/invitations`
export const usersInvitationsRoute = '/users/invitations'
export const usersInvitationsStatusRoute = '/users/invitations/status'
export const usersInvitationsResendRoute = userId => `/users/${userId}/invitations/resend`
export const usersRoleRoute = userId => `/users/${userId}/role`
export const usersProvisionRoute = userId => `/users/${userId}/provision`
export const usersRecurringAvailabilityRoute = userId => `/users/${userId}/recurring_availability`
export const usersDateAvailabilityRoute = userId => `/users/${userId}/date_availability`
export const usersAvailabilitiesRoute = `/users/unified_availabilities`
export const usersRegistrationRoute = 'users/registration'
export const clientConsultantsRoute = 'clients/users'
export const usersTwoFactorAuthRoute = userId => `/users/${userId}/two_factor_setting`

// ---Workspace---
export const workspaceRoute = '/'
export const workspaceTwoFactorSettingRoute = '/workspace_two_factor_setting'

// ---Ownership---
export const ownershipRoute = '/ownership'

// ---Widgets---
export const widgetsRoute = '/widgets'
export const widgetRoute = widgetId => `/widgets/${widgetId}`
export const widgetsSendInstructionRoute = widgetId => `/widgets/${widgetId}/send_instruction`
export const widgetsServicesRoute = widgetId => `/widgets/${widgetId}/services`
export const servicesWidgetRoute = '/widgets/services'
export const widgetsValidationsSettingsRoute = '/widgets/validations/settings'

// ---Services---
export const servicesRoute = '/services'
export const servicesValidationsDetailsRoute = '/services/validations/details'
export const servicesValidationsSettingsRoute = '/services/validations/settings'
export const servicesBulkActionsDeactivationRoute = '/services/bulk_actions/deactivation'
export const servicesBulkActionsActivationRoute = '/services/bulk_actions/activation'
export const servicesDetailsRoute = serviceId => `/services/${serviceId}`
export const servicesSettingsRoute = serviceId => `/services/${serviceId}/setting`
export const servicesProvisionRoute = serviceId => `/services/${serviceId}/provision`
export const servicesAssignedFormsRoute = serviceId => `/services/${serviceId}/assigned_forms`
export const servicesBulkActionsRemoveRoute = '/services/bulk_actions/remove'
export const clientServicesRoute = '/clients/services'

// ---Service categories---
export const serviceCategoriesRoute = '/service_categories'
export const serviceCategoryRoute = categoryId => `/service_categories/${categoryId}`
export const serviceCategoriesBulkActionsActivationRoute = '/service_categories/bulk_actions/activation'
export const serviceCategoriesBulkActionsDeactivationRoute = '/service_categories/bulk_actions/deactivation'
export const serviceCategoriesBulkActionsRemoveRoute = '/service_categories/bulk_actions/remove'

// ---Timezones---
export const timezonesRoute = '/timezones'

// ---Clients---
export const clientsRoute = '/clients'
export const clientRoute = clientId => `/clients/${clientId}`
export const sendInvitationToClientPortalRoute = clientId => `/clients/${clientId}/invitations/send`
export const clientBookingsRoute = clientId => `/clients/${clientId}/bookings`
export const clientAttachmentsRoute = clientId => `/clients/${clientId}/client_attachments`
export const clientAttachmentsPresignRoute = clientId => `/clients/${clientId}/client_attachments/presign`
export const clientAttachmentsUploadRoute = clientId => `/clients/${clientId}/client_attachments/upload`
export const clientAttachmentRoute = (clientId, clientAttachmentId) =>
  `clients/${clientId}/client_attachments/${clientAttachmentId}`
export const clientNotesRoute = clientId => `clients/${clientId}/client_notes`
export const clientNoteRoute = (clientId, clientNoteId) => `clients/${clientId}/client_notes/${clientNoteId}`
export const clientFeedsRoute = clientId => `clients/${clientId}/client_feeds`
export const clientFormsRoute = clientId => `clients/${clientId}/forms`
export const clientShareFilesRoute = clientId => `/clients/${clientId}/shared_files`
export const clientSendDocumentsRoute = clientId => `/clients/${clientId}/forms/share`
export const clientImportsRoute = '/client_imports'
export const validateClientImportRoute = clientImportId => `/client_imports/${clientImportId}/validation`
export const processClientImportRoute = clientImportId => `/client_imports/${clientImportId}/process`
export const clientNoteShareRoute = (clientId, clientNoteId) => `clients/${clientId}/client_notes/${clientNoteId}/share`
export const clientNotePersonalRoute = (clientId, clientNoteId) =>
  `clients/${clientId}/client_notes/${clientNoteId}/personal`
export const clientsSharedFileRoute = sharedFileId => `clients/shared_files/${sharedFileId}`

// ---PaymentAccount---
export const paymentAccountRoute = '/payment_account'
export const stripeConnectLinkRoute = '/stripe_connect/link'
export const stripeConnectRoute = '/stripe_connect'
export const payPalConnectRoute = '/paypal_connect'
export const payPalConnectLinkRoute = '/paypal_connect/link'
export const manualPaymentRoute = '/manual_payment'
export const validateDisconnectPaymentMethodRoute = '/stripe_connect/validations/disconnect'
export const cardReadersRoute = '/stripe_account/card_readers'
export const stripeAccountPosStatusRoute = '/stripe_account/pos_status'

// ---Session---
export const keepAliveRoute = '/keep_alive'
export const clientsSessionRoute = '/clients/session'
export const clientsContactsSessionRoute = id => `/clients/client_contacts/${id}/session`
export const clientsSessionSendCodeRoute = '/clients/session/send_code'
export const usersSessionRoute = '/users/session'
export const usersSessionRefreshRoute = '/users/session/refresh'
export const usersSessionBulkLogout = '/users/session/bulk_logout'
export const clientsSessionRefreshRoute = '/clients/session/refresh'
export const usersSessionTwoFARoute = '/users/session/two_factor_authentication'
export const usersSessionResendVerificationCodeRoute = '/users/session/resend_verification_code'

// ---Public---
export const publicSearchRoute = '/public/search'
export const publicRemind = '/public/remind'
export const publicWebsiteRoute = workspaceCode => `/${workspaceCode}/public/website`
export const publicWidgetProvidersRoute = (workspaceCode, widgetId) =>
  `/${workspaceCode}/public/widgets/${widgetId}/providers`
export const publicWidgetServicesRoute = (workspaceCode, widgetId) =>
  `/${workspaceCode}/public/widgets/${widgetId}/services`
export const publicWebsiteReviewsRoute = (workspaceCode, widgetId) =>
  `/${workspaceCode}/public/widgets/${widgetId}/reviews`
export const publicWidgetLocationsRoute = (workspaceCode, widgetId) =>
  `/${workspaceCode}/public/widgets/${widgetId}/locations`

// ---Payment---
export const transactionsRoute = '/transactions'
export const transactionRoute = transactionId => `/transactions/${transactionId}`
export const transactionRefundRoute = transactionId => `/transactions/${transactionId}/refund`
export const transactionCancelRoute = transactionId => `/transactions/${transactionId}/cancel`
export const transactionMarkAsPaidRoute = transactionId => `/transactions/${transactionId}/mark_as_paid`
export const transactionSendRoute = transactionId => `/transactions/${transactionId}/send`
export const transactionDisableCancellationRoute = transactionId => `transactions/${transactionId}/disable_cancellation`
export const receiptTemplateRoute = '/receipt_template'
export const clientTransactionsRoute = '/clients/transactions'
export const stripeAccountTerminalConnectionTokenRoute = '/stripe_account/terminal_connection_token'

// ---Recurring Payments---
export const recurringPaymentsRoute = '/recurring_payments'
export const recurringPaymentRoute = recurringPaymentId => `/recurring_payments/${recurringPaymentId}`
export const recurringPaymentCancelRoute = recurringPaymentId => `/recurring_payments/${recurringPaymentId}/cancel`
export const recurringPaymentSendRoute = recurringPaymentId => `/recurring_payments/${recurringPaymentId}/send`
export const recurringPaymentTransactionsRoute = recurringPaymentId =>
  `/recurring_payments/${recurringPaymentId}/transactions`
export const recurringPaymentsPaymentMethodRoute = recurringPaymentId =>
  `/recurring_payments/${recurringPaymentId}/payment_method`

// --- Billing ---
export const billingDetailRoute = '/billing_detail'
export const billingAccountRoute = '/billing_account'

// ---Subscription Plans---
export const publicSubscriptionPlansRoute = '/public/subscription_plans'

// ---Subscription Transactions---
export const subscriptionTransactionsRoute = '/subscription_transactions'
export const subscriptionTransactionDownloadRoute = transactionId =>
  `/subscription_transactions/${transactionId}/download`

// ---Subscription---
export const subscriptionsChangeSubscriptionPlanRoute = '/subscriptions/change_subscription_plan'
export const subscriptionsChangeStoragePlanRoute = '/subscriptions/change_storage_plan'
export const subscriptionsChangeStoragePlanSubscriptionRoute = '/subscriptions/storage_plan_subscription'
export const subscriptionsRenewRoute = '/subscriptions/renew'
export const subscriptionsDowngradeStoragePlanRoute = 'subscriptions/downgrades/storage_plans/cancel'
export const switchBillingCycleRoute = '/subscriptions/billing_cycle'
export const cancelSwitchBillingCycleRoute = '/subscriptions/billing_cycle/cancel'
export const cancelPlanDowngradeRoute = '/subscriptions/downgrades/subscription_plans/cancel'

export const subscriptionsTrialUpgradeRoute = '/subscriptions/trial/upgrade'
export const subscriptionsMonthlyUpgradeRoute = '/subscriptions/monthly/upgrade'
export const subscriptionsMonthlyDowngradeRoute = '/subscriptions/monthly/downgrade'
export const subscriptionsAnnualUpgradeRoute = '/subscriptions/annual/upgrade'
export const subscriptionsAnnualDowngradeRoute = '/subscriptions/annual/downgrade'
export const subscriptionsFreeUpgradeRoute = '/subscriptions/free/upgrade'

// ---Subscription Payment Method---
export const paymentMethodRoute = '/payment_method'
export const paymentMethodSetupIntentRoute = '/payment_method/setup_intent'
export const paymentRetryRoute = '/payment_retry'
export const paymentMethodBillingAddressRoute = '/payment_method/billing_address'

// ---Team Seats Subscription---
export const teamSeatsSubscriptionChangeRoute = '/subscriptions/change_team_seats'
export const teamSeatsSubscriptionDowngradeCancelRoute = '/subscriptions/downgrades/team_seats/cancel'

// ---Notifications---
export const notificationsRoute = '/notifications'
export const notificationRoute = notificationId => `/notifications/${notificationId}`
export const notificationsReadRoute = notificationId => `/notifications/${notificationId}/read`
export const notificationsUnreadRoute = notificationId => `/notifications/${notificationId}/unread`
export const notificationsBulkActionsReadRoute = '/notifications/bulk_actions/read'

// ---Pending registration----
export const pendingRegistrationsRoute = '/pending_registrations'

// ---Promocodes---
export const promocodesValidation = '/promocodes/validation'
export const appliedPromocodeRoute = '/applied_promocode'
export const appliedPromocodeValidationRoute = '/applied_promocode/validation'

// ---Connect Google Calendar---
export const googleCalendarConnectionsRoute = '/users/account/google_calendar_connections'
export const googleCalendarConnectionRoute = id => `/users/account/google_calendar_connections/${id}`
export const googleCalendarConnectionsLinkRoute = '/users/account/google_calendar_connections/link'
export const googleCalendarConnectionReconnectRoute = id => `/users/account/google_calendar_connections/${id}/reconnect`
export const outlookCalendarConnectionReconnectRoute = id =>
  `/users/account/outlook_calendar_connections/${id}/reconnect`

// ---Connect Video Call Provider---
export const googleVideoCallIntegrationRoute = '/users/account/google_video_call_integration'
export const microsoftTeamsIntegrationRoute = '/users/account/teams_video_call_integration'
export const microsoftTeamsIntegrationLinkRoute = '/users/account/teams_video_call_integration/link'
export const zoomIntegrationRoute = '/users/account/zoom_video_call_integration'
export const zoomIntegrationLinkRoute = '/users/account/zoom_video_call_integration/link'

// ---Connect Outlook Calendar---
export const outlookCalendarConnectionsRoute = '/users/account/outlook_calendar_connections'
export const outlookCalendarConnectionRoute = id => `/users/account/outlook_calendar_connections/${id}`
export const outlookCalendarConnectionsLinkRoute = '/users/account/outlook_calendar_connections/link'

// ---Connect Apple Calendar---
export const appleCalendarConnectionsRoute = '/users/account/apple_calendar_connections'
export const appleCalendarConnectionRoute = id => `/users/account/apple_calendar_connections/${id}`
export const appleCalendarConnectionReconnectRoute = id => `/users/account/apple_calendar_connections/${id}/reconnect`

// ---Calendar Integrations---
export const userAccountCalendarSyncRoute = '/users/account/calendar_sync'
export const userAccountCalendarIntegrationsRoute = '/users/account/calendar_integrations'

// ---Video Conference---
export const videoConferenceJoinRoute = uniqCode => `/video_conferences/${uniqCode}/join`
export const videoConferenceRoute = uniqCode => `/video_conferences/${uniqCode}`
export const videoConferenceVideoRecordingsRoute = uniqCode => `/video_conferences/${uniqCode}/video_recordings`
export const videoConferenceChatMessagesRoute = uniqCode => `/video_conferences/${uniqCode}/in_call_chat_messages`

// ---Video Calls Integrations---
export const videoCallIntegrationsRoute = '/video_call_integrations'

// ---Custom fields---
export const customFieldsRoute = '/custom_fields'
export const customFieldRoute = id => `/custom_fields/${id}`

// ---Reviews---
export const reviews = '/reviews'
export const reviewRoute = reviewId => `/reviews/${reviewId}`
export const reviewPublicationRoute = id => `/reviews/${id}/publication`

// ---Review settings---
export const reviewSettingRoute = '/review_setting'

// ---Locations---
export const locationsRoute = '/locations'
export const locationRoute = id => `/locations/${id}`

// ---Tasks---
export const tasksRoute = '/tasks'
export const taskRoute = id => `/tasks/${id}`
export const taskCompleteRoute = id => `/tasks/${id}/complete`

// ---Payment---
export const transactionCheckoutRoute = paymentId => `/transactions/${paymentId}/checkout`
export const transactionCheckoutPaymentIntentRoute = paymentId => `transactions/${paymentId}/checkout/payment_intent`

// ---Workspace booking settings---
export const bookingSettingRoute = '/workspace_booking_setting'

// ---Workspace features----
export const workspaceFeaturesRoute = '/workspace_features'
export const workspaceFeatureActivationRoute = featureKey => `/workspace_features/${featureKey}/activation`
export const workspaceFeatureDeactivationRoute = featureKey => `/workspace_features/${featureKey}/deactivation`

// ---Client portal ---
export const clientPortalRoute = '/client_portal'

// ---SMS notifications settings---
export const smsNotificationsSettingsRoute = '/sms_notification_settings'

// ---Website---
export const websiteRoute = `/website`

// ---Custom names---
export const customNamesRoute = '/custom_names'
export const customNamesMappingRoute = '/custom_names/mapping'

// ---Events---
export const calendarEventRoute = calendarEventId => `/external_calendar_events/${calendarEventId}`

// ---Client contacts---
export const clientContactsRoute = '/client_contacts'
export const clientContactRoute = contactId => `/client_contacts/${contactId}`
export const contactClientProfilesRoute = '/client_contacts/contact_client_profiles'
export const clientContactAccountDeletionConfirmationCodeRoute = clientContactId =>
  `/clients/client_contacts/${clientContactId}/deletion/send_code`
export const clientContactAccountRoute = clientContactId => `/clients/client_contacts/${clientContactId}`
