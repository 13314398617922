import { camelCase } from 'lodash'
import { DateTime } from 'luxon'
import {
  all,
  any,
  complement,
  compose,
  difference,
  either,
  filter,
  values as getValues,
  identity,
  includes,
  isNil,
  map,
  omit,
  pathSatisfies,
  pick,
  prop,
  take,
} from 'ramda'
import { v4 as uuidv4 } from 'uuid'

import { FORM_AUTOMATION_FREQUENCIES, MAX_FILE_SIZE, QUESTION_TYPES, STATUS_LOCALE_MAP } from 'lib/constants/form'
import {
  AUTOMATIC_SHARING,
  CLIENT_SUBMISSION_STATUSES,
  FORM_STATUSES,
  RESPONDENT_TYPES,
  SIGNER_TYPES,
  SUBMISSION_STATUSES,
} from 'lib/constants/forms'
import {
  companyClientsSubmitFormRoute,
  companySettingsIntakeFormsPreviewRoute,
  companySettingsIntakeFormsSubmissionPreviewRoute,
} from 'lib/routes'
import { secondsFromDateTime } from 'utils/dateTime'
import isPresent from 'utils/isPresent'
import { isSharingOptionForBooking } from './forms'

export const isRespondentClient = respondent => respondent === RESPONDENT_TYPES.client
export const isRespondentExpert = respondent => respondent === RESPONDENT_TYPES.expert

export const isDeclined = status => status === FORM_STATUSES.declined
export const isPending = status => status === FORM_STATUSES.pending
export const isSubmittedStatus = status => status === FORM_STATUSES.submitted
export const isWaitingForClientSignature = status => status === FORM_STATUSES.waitingClientSignature
export const isWaitingForSignature = status =>
  [FORM_STATUSES.waitingExpertSignature, FORM_STATUSES.waitingClientSignature].includes(status)
export const isWaitingForExpertSignature = status => status === FORM_STATUSES.waitingExpertSignature

export const isStatusInSubmissionState = status => includes(status, SUBMISSION_STATUSES)
export const isStatusInClientSubmissionState = status => includes(status, CLIENT_SUBMISSION_STATUSES)

export const expertCanDeleteForm = ({ formSubmission, isCurrentUserForm, sharingOption, isSubmitted }) => {
  const noSign = !prop('clientSignature', formSubmission) && !prop('expertSignature', formSubmission)

  return isCurrentUserForm && sharingOption === AUTOMATIC_SHARING.noSharing && noSign && isSubmitted
}

export const updateQuestionsPriority = questions =>
  questions.map((question, index) => ({ ...question, priority: index }))

export const isText = questionType => questionType === QUESTION_TYPES.text
export const isAgreement = questionType => questionType === QUESTION_TYPES.agreement
export const isDate = questionType => questionType === QUESTION_TYPES.date
export const isParagraph = questionType => questionType === QUESTION_TYPES.paragraph
export const isCheckBox = questionType => questionType === QUESTION_TYPES.checkBox
export const isRadioButton = questionType => questionType === QUESTION_TYPES.radioButton
export const isFile = questionType => questionType === QUESTION_TYPES.file
export const isDropdown = questionType => questionType === QUESTION_TYPES.dropdown
export const isTime = questionType => questionType === QUESTION_TYPES.time
export const isDuration = questionType => questionType === QUESTION_TYPES.duration
export const isDateTime = questionType => isDate(questionType) || isTime(questionType) || isDuration(questionType)
export const isCRMField = questionType => questionType === QUESTION_TYPES.crm
export const hasOptions = questionType =>
  isRadioButton(questionType) || isCheckBox(questionType) || isDropdown(questionType)

export const prepareQuestionAttributes = (questions = []) => {
  const filteredQuestions = filter(
    ({ name, questionType, crmFieldId }) =>
      isCRMField(questionType) ? isPresent(name) && isPresent(crmFieldId) : isPresent(name),
    questions,
  )

  return updateQuestionsPriority(filteredQuestions)
}

export const prepareOptionsAttributes = (options = [], omitAttributes = []) =>
  prepareQuestionAttributes(map(omit(['id', ...omitAttributes]), options))

export const isQuestionField = questionType =>
  !includes(questionType, [QUESTION_TYPES.subtitle, QUESTION_TYPES.paragraph])

export const buildSignerParams = ({ signatureRequired, signers }) => {
  if (!signatureRequired) return SIGNER_TYPES.none

  if (signers.length === 2) {
    return SIGNER_TYPES.both
  }

  return signers[0]
}

export const signerToFormParams = signer => {
  if (!signer || signer === SIGNER_TYPES.none) {
    return []
  }

  if (signer === SIGNER_TYPES.both) {
    return [SIGNER_TYPES.client, SIGNER_TYPES.expert]
  }

  return [signer]
}

export const buildFormParams = (values, questions) => ({
  name: values.name,
  description: values.description,
  status: values.status,
  frequency:
    !isRespondentExpert(values.respondent) &&
    [AUTOMATIC_SHARING.clientDeletesAccount, AUTOMATIC_SHARING.clientInWorkspace].includes(values.sharingOption)
      ? FORM_AUTOMATION_FREQUENCIES.perClient
      : FORM_AUTOMATION_FREQUENCIES.perAppointment,
  form_category_id: values.formCategoryId,
  respondent: values.respondent,
  signer: buildSignerParams(values),
  form_questions_attributes: questions.map(question => {
    const options = isCRMField(question.questionType) ? [] : ['custom_field_option_id']
    const formQuestionOptions = prepareOptionsAttributes(question.formQuestionOptionsAttributes, options)
    const questionType = isCRMField(question.questionType) ? question.crmQuestionType : question.questionType
    const customFieldId = isCRMField(question.questionType) ? question.crmFieldId : undefined

    return {
      name: question.name,
      priority: question.priority,
      required: question.required,
      question_type: questionType,
      custom_field_id: customFieldId,
      form_question_options_attributes:
        isPresent(formQuestionOptions) && hasOptions(questionType) ? formQuestionOptions : undefined,
    }
  }),
  service_ids: isSharingOptionForBooking(values.sharingOption) ? values.services : [],
  sharing_option:
    values.respondent === RESPONDENT_TYPES.expert &&
    [AUTOMATIC_SHARING.clientDeletesAccount, AUTOMATIC_SHARING.clientInWorkspace].includes(values.sharingOption)
      ? AUTOMATIC_SHARING.noSharing
      : values.sharingOption,
  expiration_period: values.isExpiryPeriod ? values.expiryPeriod : undefined,
})

export const answerValuesAttributes = ({
  questionType,
  checkBoxValue,
  singleChoiceValue,
  attachments,
  destroyedFileIds = [],
  destroyedCheckboxIds = [],
  destroyedSingleChoiceIds = [],
  destroyedDropdownIds = [],
  initialSingleChoiceValue,
  initialCheckboxValue = [],
}) => {
  if (isCheckBox(questionType)) {
    const filteredValues = checkBoxValue.filter(value => !initialCheckboxValue.includes(value))
    const checkboxValues = filteredValues.map(optionId => ({
      form_question_option_id: Number(optionId),
    }))
    const destroyedCheckboxes = destroyedCheckboxIds.map(id => ({ id, _destroy: true }))
    return [...checkboxValues, ...destroyedCheckboxes]
  }

  if (
    (isRadioButton(questionType) || isDropdown(questionType)) &&
    (singleChoiceValue || destroyedDropdownIds || destroyedSingleChoiceIds)
  ) {
    const singleChoiceValues =
      singleChoiceValue !== initialSingleChoiceValue && isPresent(singleChoiceValue)
        ? [{ form_question_option_id: Number(singleChoiceValue) }]
        : []
    const destroyedIds = isRadioButton(questionType) ? destroyedSingleChoiceIds : destroyedDropdownIds
    const destroyedSingleChoice = destroyedIds.map(id => ({ id, _destroy: true }))

    return [...singleChoiceValues, ...destroyedSingleChoice]
  }

  if (isFile(questionType) && (isPresent(attachments) || isPresent(destroyedFileIds))) {
    const newAttachments = compose(
      map(({ file, meta }) => ({
        file: {
          id: meta.fields.key.match(/^uploads\/cache\/(.+)/)[1],
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
      })),
      filter(({ isUploaded }) => !isUploaded),
      filter(identity),
      getValues,
    )(attachments)

    const destroyedAttachments = destroyedFileIds.map(id => ({ id, _destroy: true }))

    return [...newAttachments, ...destroyedAttachments]
  }

  return undefined
}

export const formatValue = (value, questionType) => {
  if ((isDuration(questionType) || isTime(questionType)) && value) {
    return String(secondsFromDateTime(value.toUTC()))
  }
  return value
}

export const formAnswerAttributes = formAnswers =>
  formAnswers.map(
    ({
      value,
      questionId,
      questionType,
      checkBoxValue,
      singleChoiceValue,
      attachments,
      id,
      destroyedFileIds,
      destroyedCheckboxIds,
      destroyedSingleChoiceIds,
      destroyedDropdownIds,
      initialSingleChoiceValue,
      initialCheckboxValue,
    }) => ({
      value: formatValue(value, questionType),
      form_question_id: questionId,
      id,
      form_answer_values_attributes: answerValuesAttributes({
        questionType,
        checkBoxValue,
        singleChoiceValue,
        attachments,
        destroyedFileIds,
        destroyedSingleChoiceIds,
        destroyedDropdownIds,
        destroyedCheckboxIds,
        initialSingleChoiceValue,
        initialCheckboxValue,
      }),
    }),
  )

export const formSubmissionParams = ({ id, formAnswers = [], bookingId, lockVersion }) => ({
  booking_id: bookingId,
  client_profile_form_id: id,
  lock_version: lockVersion,
  form_answers_attributes: formAnswerAttributes(formAnswers),
})

export const updateFormSubmissionParams = ({ formAnswers, lockVersion }) => ({
  lock_version: lockVersion,
  form_answers_attributes: formAnswerAttributes(formAnswers),
})

export const allowedFilesBySize = files => Array.from(files).filter(file => file.size <= MAX_FILE_SIZE)

export const convertFilesToAttachments = (files, size) => take(size)(files).map(file => ({ id: uuidv4(), file }))

export const hasUploadedKey = compose(
  all(
    either(
      pathSatisfies(complement(isNil), ['meta', 'fields', 'key']),
      pathSatisfies(complement(isNil), ['file', 'url']),
    ),
  ),
  filter(identity),
  getValues,
)

export const buildFormHref = ({ respondent, formSubmissionId, formId, id }) => {
  if (formSubmissionId) return companySettingsIntakeFormsSubmissionPreviewRoute(formSubmissionId)

  return respondent === RESPONDENT_TYPES.client
    ? companySettingsIntakeFormsPreviewRoute(formId)
    : companyClientsSubmitFormRoute(id)
}

export const updateFormSubmissionAnswersValue = (formAnswers = []) => {
  const calculateValue = (questionType, value) => {
    if (isDate(questionType) && isPresent(value)) return DateTime.fromISO(value)
    if (isDate(questionType) && !isPresent(value)) return null
    if (!isPresent(value)) return ''
    if (isDuration(questionType) || isTime(questionType)) return DateTime.fromSeconds(Number(value), { zone: 'utc' })
    return value
  }

  return (
    formAnswers?.map(
      ({
        formQuestion: { id: questionId, required, name, questionType, formQuestionOptions },
        formAnswerValues,
        value,
        id,
      }) => ({
        value: calculateValue(questionType, value),
        singleChoiceValue:
          isDropdown(questionType) || isRadioButton(questionType)
            ? formAnswerValues[0]?.formQuestionOption?.id
            : undefined,
        checkBoxValue: isCheckBox(questionType)
          ? formAnswerValues?.map(({ formQuestionOption }) => formQuestionOption?.id)
          : [],
        questionId,
        attachments: isFile(questionType)
          ? formAnswerValues?.reduce(
              (prevVal, answer) => ({
                ...prevVal,
                [answer.id]: {
                  id: answer.id,
                  isUploaded: true,
                  file: {
                    name: answer.filename,
                    url: answer.fileUrl,
                    size: answer.fileSize,
                  },
                },
              }),
              {},
            )
          : {},
        name,
        required,
        questionType,
        formQuestionOptions,
        formAnswerValues,
        id,
        destroyedFileIds: [],
        destroyedCheckboxIds: [],
        destroyedSingleChoiceIds: [],
        destroyedDropdownIds: [],
        initialSingleChoiceValue: formAnswerValues[0]?.formQuestionOption?.id,
        initialCheckboxValue: formAnswerValues?.map(({ formQuestionOption }) => formQuestionOption?.id),
      }),
    ) || []
  )
}

export const hasCrmFields = compose(any(prop('crmFieldId')), prop('formQuestionsAttributes'))

export const unassignCrmFields = (setValues, values) => {
  if (values.respondent === RESPONDENT_TYPES.client) return

  setValues({
    ...values,
    formQuestionsAttributes: values.formQuestionsAttributes.map(question => {
      if (question.questionType !== QUESTION_TYPES.crm) return question

      return {
        ...question,
        questionType: question.crmQuestionType || QUESTION_TYPES.text,
        crmFieldId: question.crmFieldId || null,
        crmQuestionType: question.crmQuestionType || null,
        formQuestionOptionsAttributes: question.formQuestionOptionsAttributes?.map(option => ({
          ...option,
          custom_field_option_id: null,
        })),
      }
    }),
  })
}

export const getCustomFieldOptions = customField =>
  map(
    ({ id, name, priority }) => ({
      name,
      priority,
      custom_field_option_id: Number(id),
    }),
    customField.customFieldOptions || [],
  )

export const questionsToAnswers = formQuestions =>
  formQuestions.map(({ id, required, name, questionType, formQuestionOptions }) => ({
    value: isDate(questionType) ? null : '',
    initialCheckboxValue: [],
    singleChoiceValue: undefined,
    checkBoxValue: [],
    questionId: id,
    attachments: {},
    name,
    required,
    questionType,
    formQuestionOptions,
  }))

export const statusLocale = (status, respondent, declinedBy) => {
  if (isPending(status)) {
    return STATUS_LOCALE_MAP[status][respondent]
  }

  if (isDeclined(status)) {
    return STATUS_LOCALE_MAP[status][declinedBy]
  }

  return camelCase(status)
}

export const hasSomeAnswers = formAnswers =>
  formAnswers?.some(
    ({ value, attachments, checkBoxValue, singleChoiceValue }) =>
      isPresent(value) || isPresent(attachments) || isPresent(singleChoiceValue) || isPresent(checkBoxValue),
  )

export const answersDifference = (currentAnswers, prevAnswers) => {
  const keysToCompare = ['value', 'checkBoxValue', 'singleChoiceValue', 'attachments', 'questionType']
  const current = map(pick(keysToCompare), currentAnswers)
  const prev = map(pick(keysToCompare), prevAnswers)

  return difference(current, prev)
}
