import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMount } from 'react-use'
import classNames from 'clsx'

import Button from 'views/shared/Button'
import logo from 'assets/images/logo-mob.svg'
import { websiteOrDraftSelector } from 'state/concepts/website/selectors'
import profileName from 'utils/profileName'
import { WEBSITE_PROVIDER_TABS } from 'lib/constants/website'
import spanWithClass from 'utils/locales/spanWithClass'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { fetchPublicWebsiteReviews } from 'state/concepts/website/actions'
import Reviews from './Reviews'
import About from './About'

const ProviderSidebar = ({
  onClose,
  avatarUrls,
  firstName,
  lastName,
  position,
  activeServicesCount,
  priceMin,
  priceMax,
  about,
  publishedReviewsCount,
  ...rest
}) => {
  const {
    widget: { color },
  } = useSelector(websiteOrDraftSelector)
  const fetchPublicReviews = useDispatchAction(fetchPublicWebsiteReviews, { size: 1000, number: 1 })
  const [currentTab, setCurrentTab] = useState(WEBSITE_PROVIDER_TABS.about)
  const handleSetCurrentTab = tab => () => {
    setCurrentTab(tab)
  }

  useMount(fetchPublicReviews)

  return (
    <div className="widget-sidebar" data-cy="widget-sidebar">
      <div className="widget-sidebar__close-btn">
        <Button
          className="main-btn--icon-only"
          kind="text"
          icon="cross"
          iconSize={8}
          onClick={onClose}
          dataCy="close-widget-button"
        />
      </div>
      <div className="widget-sidebar__wrap">
        <div className="widget-sidebar__header main-header">
          <Image src={logo} className="border-radius-wrap" alt="logo" width={40} height={40} />
        </div>
        <div className="widget-sidebar__body">
          <div className="expert-info__wrap">
            <div className="pb-8">
              {avatarUrls?.original ? (
                <Image
                  src={avatarUrls.original}
                  className="rounded-full"
                  alt="expert-avatar"
                  width={80}
                  height={80}
                  data-cy="expert-avatar"
                />
              ) : (
                // TODO: Add when Avatar placeholder is done by Designer
                <div
                  style={{
                    width: '80px',
                    height: '80px',
                    backgroundColor: 'grey',
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />
              )}
            </div>
            <div className="text-title font-600" data-cy="expert-name">
              {profileName({ firstName, lastName })}
            </div>
            <div className="expert-info__gray text-subheader" data-cy="expert-position">
              {position}
            </div>
            <ul className="main-tabs justify-content-center mt-12 mb-0">
              <li className="main-tabs__item" data-cy="sidebar-about-tab">
                <span
                  className={classNames('main-tabs__link', {
                    'main-tabs__link--active': currentTab === WEBSITE_PROVIDER_TABS.about,
                  })}
                  style={{ borderColor: currentTab === WEBSITE_PROVIDER_TABS.about ? color : 'transparent' }}
                  onClick={handleSetCurrentTab(WEBSITE_PROVIDER_TABS.about)}
                  role="button"
                >
                  <FormattedMessage id="website.page.providers.providerSidebar.aboutTab" />
                </span>
              </li>
              <li className="main-tabs__item" data-cy="sidebar-reviews-tab">
                <span
                  className={classNames('main-tabs__link', {
                    'main-tabs__link--active': currentTab === WEBSITE_PROVIDER_TABS.reviews,
                  })}
                  style={{ borderColor: currentTab === WEBSITE_PROVIDER_TABS.reviews ? color : 'transparent' }}
                  onClick={handleSetCurrentTab(WEBSITE_PROVIDER_TABS.reviews)}
                  role="button"
                >
                  <FormattedMessage
                    id="website.page.providers.providerSidebar.reviewsTab"
                    values={{ span: spanWithClass('main-badge ml-8'), reviewsCount: publishedReviewsCount || 0 }}
                  />
                </span>
              </li>
            </ul>
          </div>
          {currentTab === WEBSITE_PROVIDER_TABS.about ? (
            <About
              activeServicesCount={activeServicesCount}
              priceMax={priceMax}
              priceMin={priceMin}
              about={about}
              color={color}
            />
          ) : (
            <Reviews {...rest} avatarUrls={avatarUrls} color={color} />
          )}
        </div>
      </div>
    </div>
  )
}

ProviderSidebar.defaultProps = {
  avatarUrls: null,
  about: '',
}

ProviderSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  avatarUrls: PropTypes.shape(),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  activeServicesCount: PropTypes.number.isRequired,
  priceMin: PropTypes.string.isRequired,
  priceMax: PropTypes.string.isRequired,
  about: PropTypes.string,
  publishedReviewsCount: PropTypes.number.isRequired,
}

export default ProviderSidebar
