import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import ArrowLeft from 'views/shared/icons/ArrowLeft'

const DiscardBackButton = ({ onClick, text, dataCy }) => (
  <button data-cy={dataCy} className="text-subheader font-600" type="button" onClick={onClick}>
    <ArrowLeft dataCy="arrow-left-icon" className="mr-20" size={10} />
    <FormattedMessage {...text} />
  </button>
)

DiscardBackButton.defaultProps = {
  dataCy: undefined,
}

DiscardBackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.shape().isRequired,
  dataCy: PropTypes.string,
}

export default DiscardBackButton
