import fetchClientRelationshipTypes from './fetchClientRelationshipTypes'
import searchClientRelationshipTypes from './searchClientRelationshipTypes'
import filterClientRelationshipTypes from './filterClientRelationshipTypes'
import createClientRelationshipType from './createClientRelationshipType'
import fetchClientRelationshipType from './fetchClientRelationshipType'
import updateClientRelationshipType from './updateClientRelationshipType'
import deleteClientRelationshipType from './deleteClientRelationshipType'

export default [
  fetchClientRelationshipTypes,
  searchClientRelationshipTypes,
  filterClientRelationshipTypes,
  createClientRelationshipType,
  fetchClientRelationshipType,
  updateClientRelationshipType,
  deleteClientRelationshipType,
]
