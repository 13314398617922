/* eslint-disable import/no-extraneous-dependencies */
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

import { TWO_FACTOR_SETTING_METHODS } from 'lib/constants/twoFactorSettings'
import Button from 'views/shared/Button'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import AppFields from './AppFields'

const AppStep = ({ onClose, onBack }) => {
  const {
    setFieldValue,
    values: { otpCode },
  } = useFormikContext()

  const handleBackClick = () => {
    setFieldValue('methodChecker', TWO_FACTOR_SETTING_METHODS.email)
    setFieldValue('otpCode', '')
    onBack()
  }

  return (
    <>
      <div className="main-modal__body">
        <AppFields />
      </div>
      <div className="main-modal__footer">
        <Button
          kind="flat"
          className="mr-auto main-btn--icon-gray pl-0 pr-16 min-w-auto"
          text={{ id: 'shared.back' }}
          icon="arrow-left"
          iconSize={10}
          iconWrapWithTextClassName="wh-24-24 d-flex align-items-center justify-content-center"
          onClick={handleBackClick}
        />
        <Button className="mr-16" text={{ id: 'shared.cancel' }} kind="flat" onClick={onClose} />
        <SubmitFormButton className="main-modal__footer-action" text={{ id: 'shared.enable' }} disabled={!otpCode} />
      </div>
    </>
  )
}

AppStep.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default AppStep
