import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { previewDocumentTemplateEndpoint } from '../endpoints'
import { PREVIEW_DOCUMENT_TEMPLATE } from '../types'

const previewDocumentTemplateOperation = createLogic({
  type: PREVIEW_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ action: { documentTemplateId }, httpClient }, dispatch, done) {
    const { url, endpoint } = previewDocumentTemplateEndpoint(documentTemplateId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url)

      dispatch(dataApiSuccess({ response: normalize(data, { endpoint }), endpoint }))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default previewDocumentTemplateOperation
