import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { sendPaymentEndpoint } from 'state/concepts/payment/endpoints'
import { SEND_PAYMENT } from 'state/concepts/payment/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'

const sendPaymentOperation = createLogic({
  type: SEND_PAYMENT,
  latest: true,

  async process({ httpClient, action: { paymentId, isResend } }, dispatch, done) {
    const { url, endpoint } = sendPaymentEndpoint(paymentId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(
        showNotification({
          messageObject: {
            id: isResend ? 'notifications.paymentResend' : 'notifications.paymentSend',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default sendPaymentOperation
