import { groupBy, prop, propEq, omit, includes } from 'ramda'

import {
  ALL_FILTER_OPTIONS,
  AUTOMATIC_SHARING_OPTIONS,
  RESPONDENT_FILTER_OPTIONS,
  STATUS_FILTER_OPTIONS,
  CLIENT_SUBMISSION_STATUSES,
  SUBMISSION_STATUSES,
} from 'lib/constants/forms'
import {
  clientFormsDeclineToSignSuccessRoute,
  clientFormsSignSuccessRoute,
  clientFormsSubmissionSignRoute,
  clientFormsSubmitSuccessRoute,
  companyClientsFormsDeclineToSignSuccessRoute,
  companyClientsFormsSignSuccessRoute,
  companyClientsFormsSubmissionSignRoute,
  companyClientsFormsSubmitSuccessRoute,
  guestFormsDeclineToSignSuccessRoute,
  guestFormsSignSuccessRoute,
  guestFormsSubmissionSignRoute,
  guestFormsSubmitSuccessRoute,
} from 'lib/routes'

export const formsFilterToParams = filters => ({
  ...omit(['formCategoryIds'], filters),
  status: filters.status === ALL_FILTER_OPTIONS.allStatuses ? undefined : filters.status,
  respondent: filters.respondent === ALL_FILTER_OPTIONS.allRespondents ? undefined : filters.respondent,
  form_category_ids: filters.formCategoryIds,
})

export const clientGroupedForms = groupBy(form =>
  includes(form.status, CLIENT_SUBMISSION_STATUSES) ? 'submitted' : 'pending',
)

export const groupedForms = groupBy(form => (includes(form.status, SUBMISSION_STATUSES) ? 'submitted' : 'pending'))

export const formsSubmitSuccessRoute = ({ isEmployeeLoggedIn, isGuestLoggedIn, workspaceCode }) => {
  if (isEmployeeLoggedIn) {
    return companyClientsFormsSubmitSuccessRoute
  }

  return isGuestLoggedIn ? guestFormsSubmitSuccessRoute(workspaceCode) : clientFormsSubmitSuccessRoute(workspaceCode)
}

export const formsSignRoute = ({ isEmployeeLoggedIn, clientProfileFormId, isGuestLoggedIn, workspaceCode }) => {
  if (isEmployeeLoggedIn) {
    return companyClientsFormsSubmissionSignRoute(clientProfileFormId)
  }
  return isGuestLoggedIn
    ? guestFormsSubmissionSignRoute(workspaceCode, clientProfileFormId)
    : clientFormsSubmissionSignRoute(workspaceCode, clientProfileFormId)
}

export const formsSignSuccessRoute = ({ isEmployeeLoggedIn, workspaceCode, isGuestLoggedIn }) => {
  if (isEmployeeLoggedIn) {
    return companyClientsFormsSignSuccessRoute
  }
  return isGuestLoggedIn ? guestFormsSignSuccessRoute(workspaceCode) : clientFormsSignSuccessRoute(workspaceCode)
}

export const formsDeclineSuccessRoute = ({ isEmployeeLoggedIn, workspaceCode, isGuestLoggedIn }) => {
  if (isEmployeeLoggedIn) return companyClientsFormsDeclineToSignSuccessRoute

  return isGuestLoggedIn
    ? guestFormsDeclineToSignSuccessRoute(workspaceCode)
    : clientFormsDeclineToSignSuccessRoute(workspaceCode)
}

export const groupFormSharingOptions = list => groupBy(prop('group'))(list)
export const getFormSharingOptions = isClientRespondent =>
  isClientRespondent
    ? AUTOMATIC_SHARING_OPTIONS
    : AUTOMATIC_SHARING_OPTIONS.filter(option => !propEq('client', 'group', option))

export const isSharingOptionForBooking = sharingOption =>
  AUTOMATIC_SHARING_OPTIONS.filter(({ group }) => group === 'booking').some(({ value }) => value === sharingOption)

export const formFilterLabel = ({ formCategories, formCategoryIds, status, respondent }) => {
  if (formCategoryIds.length === 1) {
    const { label } = formCategories.find(formCategory => formCategory.value === formCategoryIds[0])
    return { id: label }
  }

  return status !== ALL_FILTER_OPTIONS.allStatuses
    ? STATUS_FILTER_OPTIONS.find(option => option.value === status).label
    : RESPONDENT_FILTER_OPTIONS.find(option => option.value === respondent).label
}
