import { camelCase } from 'lodash'
import { T, always, cond, includes, propEq } from 'ramda'

import { stripeAccountTerminalConnectionTokenRoute } from 'lib/apiRoutes'
import {
  GENERAL_TRANSACTION_TYPES,
  PAYMENT_METHODS,
  PREVIEW_AND_PDF_TYPES,
  TRANSACTION_CHARGEABLE_STATUSES,
  TRANSACTION_PAYMENT_METHODS,
  TRANSACTION_PAYMENT_TYPES,
  TRANSACTION_STATUSES,
} from 'lib/constants/payments'
import { RECURRING_PAYMENT_STATUSES } from 'lib/constants/recurringPayments'
import { isRequested } from 'utils/bookings'
import isPresent from 'utils/isPresent'

export const isRecurringPayment = transaction => isPresent(transaction.recurringPayment)

export const isCreditCard = paymentMethod => paymentMethod === TRANSACTION_PAYMENT_METHODS.creditCard

export const isPending = propEq(TRANSACTION_STATUSES.pending, 'status')
export const isSuccess = propEq(TRANSACTION_STATUSES.success, 'status')
export const isCancelled = propEq(TRANSACTION_STATUSES.cancelled, 'status')
export const isDraft = propEq(TRANSACTION_STATUSES.draft, 'status')
export const isOverdue = propEq(TRANSACTION_STATUSES.overdue, 'status')

export const isRefund = propEq(TRANSACTION_PAYMENT_TYPES.refund, 'paymentType')
export const isPaymentForAppointment = propEq(TRANSACTION_PAYMENT_TYPES.paymentForAppointment, 'paymentType')
export const isPaymentRequest = propEq(TRANSACTION_PAYMENT_TYPES.paymentRequest, 'paymentType')

export const isChargeableSuccess = propEq(TRANSACTION_CHARGEABLE_STATUSES.success, 'chargeableStatus')
export const isChargeableFailed = propEq(TRANSACTION_CHARGEABLE_STATUSES.failure, 'chargeableStatus')

export const isInvoiceable = ({ status, paymentType }) =>
  !isRefund({ paymentType }) &&
  includes(status, [TRANSACTION_STATUSES.pending, TRANSACTION_STATUSES.overdue, TRANSACTION_STATUSES.cancelled])
export const isReceiptable = ({ status, paymentType }) =>
  !isRefund({ paymentType }) && includes(status, [TRANSACTION_STATUSES.success])

export const previewAndPdfType = transaction => {
  if (isRefund(transaction)) {
    return PREVIEW_AND_PDF_TYPES.refund
  }

  if (isInvoiceable(transaction)) {
    return PREVIEW_AND_PDF_TYPES.invoice
  }

  if (isReceiptable(transaction)) {
    return PREVIEW_AND_PDF_TYPES.receipt
  }

  return null
}

export const paymentMethodLocalized = transaction => {
  const handlePaymentMethod = cond([
    [({ payerEmail }) => isPresent(payerEmail), always('payPal')],
    [
      ({ lastDigits, terminalName }) => isPresent(lastDigits) && !isPresent(terminalName),
      always('creditCardWithDigits'),
    ],
    [
      ({ lastDigits, terminalName }) => isPresent(lastDigits) && isPresent(terminalName),
      always('creditCardWithDigitsAndTerminalName'),
    ],
    [T, ({ paymentMethod }) => camelCase(paymentMethod)],
  ])

  return {
    id: `payment.paymentMethod.${handlePaymentMethod(transaction)}`,
    values: transaction,
  }
}

export const isChargeableStatusShown = ({ chargeableStatus }) =>
  includes(chargeableStatus, [TRANSACTION_CHARGEABLE_STATUSES.failure, TRANSACTION_CHARGEABLE_STATUSES.success])

export const isPendingPayment = transaction =>
  !isRefund(transaction) && includes(transaction.status, [TRANSACTION_STATUSES.pending, TRANSACTION_STATUSES.overdue])

export const generalPaymentType = transaction =>
  isRefund(transaction) ? GENERAL_TRANSACTION_TYPES.refund : GENERAL_TRANSACTION_TYPES.payment

export const requestPaymentValuesToParams = values => ({
  client_profile_id: values.clientId,
  status: values.status,
  payment_instructions: values.paymentInstructions,
  payment_type: TRANSACTION_PAYMENT_TYPES.paymentRequest,
  transaction_items_attributes: values.services.map(({ service, quantity, price }) => ({
    service_id: service,
    quantity,
    price,
  })),
})

export const updatePaymentValuesToParams = values => ({
  transaction_items: values.services.map(({ service, quantity, price }) => ({
    name: null,
    service_id: service,
    quantity,
    price,
  })),
  payment_instructions: values.paymentInstructions,
})

export const couldBePaid = transaction => isPendingPayment(transaction) && !isRequested(transaction.booking)

export const couldBeMarkedAsPaid = transaction => couldBePaid(transaction) && !transaction.recurringPayment?.autoCharge

export const couldBeChargeWithCardReader = transaction =>
  couldBePaid(transaction) && transaction.posEnabled && !transaction.recurringPayment?.autoCharge

export const canBeMarkedAsRefunded = transaction =>
  isRefund(transaction) && isPending(transaction) && !transaction.lastDigits
export const isCancellable = transaction =>
  (isPaymentRequest(transaction) || isPaymentForAppointment(transaction)) && isPendingPayment(transaction)
export const isEditable = transaction =>
  isPaymentRequest(transaction) &&
  includes(transaction.status, [
    TRANSACTION_STATUSES.pending,
    TRANSACTION_STATUSES.draft,
    TRANSACTION_STATUSES.overdue,
  ]) &&
  !isRecurringPayment(transaction)

export const getMarkAsPaidSubtitleId = transaction => {
  if (isRefund(transaction)) {
    return GENERAL_TRANSACTION_TYPES.refund
  }

  return GENERAL_TRANSACTION_TYPES.payment
}

export const updatePaymentMethodShown = transaction =>
  transaction.recurringPayment?.paymentMethodConnected &&
  !isRefund(transaction) &&
  includes(transaction.status, [
    TRANSACTION_STATUSES.pending,
    TRANSACTION_STATUSES.overdue,
    TRANSACTION_STATUSES.success,
  ])

export const isPayableOnline = transaction =>
  !updatePaymentMethodShown(transaction) && isPendingPayment(transaction) && transaction.payOnline

export const defaultPaymentType = isStripeConnected =>
  isStripeConnected ? PAYMENT_METHODS.stripe : PAYMENT_METHODS.paypal

export const isRefundable = transaction =>
  !isPresent(transaction.refund) &&
  isSuccess(transaction) &&
  isPaymentRequest(transaction) &&
  !isRecurringPayment(transaction)

export const getSubmitPaymentFormButtonText = (isRecurring, isDraftRecurring) => {
  if (!isRecurring) return { id: 'payment.paymentForm.updatePayment' }
  if (isDraftRecurring) return { id: 'payment.paymentForm.saveAndSend' }

  return { id: 'shared.saveChanges' }
}

export const canCancelRecurringPayment = ({ recurringPayment }) =>
  recurringPayment?.autoCharge && recurringPayment?.status === RECURRING_PAYMENT_STATUSES.active

export const fetchConnectionToken = httpClient => async () => {
  const { data } = await httpClient.post(stripeAccountTerminalConnectionTokenRoute)

  return data.meta.stripe.secret
}
