import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { compose } from 'ramda'

import validationSchema from 'lib/yupLocalised/schemas/cancelBooking'
import { FREE_PRICE, ACTIVE_BOOKINGS } from 'lib/constants/bookings'
import { handleSubmit } from 'utils/form/handleSubmit'
import { cancelBooking } from 'state/concepts/bookings/actions'
import { cancellationRulesSelector } from 'state/concepts/bookings/selectors'
import CancelFormModalComponent from './component'

class CancelFormModal extends React.Component {
  static propTypes = {
    booking: PropTypes.shape().isRequired,
    cancellationRules: PropTypes.shape({
      bookingRefundAmount: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
  }

  get isRefundShown() {
    const {
      booking: { status },
      cancellationRules: { bookingRefundAmount },
    } = this.props

    return bookingRefundAmount !== FREE_PRICE && ACTIVE_BOOKINGS.includes(status)
  }

  render = () => (
    <CancelFormModalComponent
      {...this.props}
      isCancellationRuleShown={this.isCancellationRuleShown}
      isRefundShown={this.isRefundShown}
    />
  )
}

const mapStateToProps = state => ({
  cancellationRules: cancellationRulesSelector(state),
})

const mapDispatchToProps = {
  onSubmit: cancelBooking,
}

export { CancelFormModal as CancelFormModalContainer }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ booking: { id, uniqCode, status }, cancellationRules: { bookingRefundAmount } }) => ({
      id,
      uniqCode,
      cancellationReason: '',
      refundNeeded: bookingRefundAmount !== FREE_PRICE && ACTIVE_BOOKINGS.includes(status),
    }),
    validateOnMount: true,
    validationSchema,
    handleSubmit,
  }),
)(CancelFormModal)
