import endpoint from 'utils/endpoint'

import {
  workspaceFeaturesRoute,
  workspaceFeatureActivationRoute,
  workspaceFeatureDeactivationRoute,
} from 'lib/apiRoutes'
import { FETCH_WORKSPACE_FEATURES, UPDATE_WORKSPACE_FEATURE_ACTIVATION } from './types'

export const fetchFeaturesEndpoint = endpoint(FETCH_WORKSPACE_FEATURES, 'GET', workspaceFeaturesRoute)

export const updateWorkspaceFeatureActivationEndpoint = (featureKey, isEnable) =>
  endpoint(
    UPDATE_WORKSPACE_FEATURE_ACTIVATION,
    'POST',
    isEnable ? workspaceFeatureActivationRoute(featureKey) : workspaceFeatureDeactivationRoute(featureKey),
  )
