import endpoint from 'utils/endpoint'
import {
  documentTemplatePreviewRoute,
  documentTemplateRoute,
  documentTemplatesRoute,
  documentTemplateDeactivatedRoute,
  documentTemplateActivatedRoute,
  documentTemplateDiscardRoute,
  documentTemplateTouchRoute,
  documentTemplatePublishRoute,
} from 'lib/apiRoutes'
import * as types from './types'

export const fetchDocumentTemplatesEndpoint = endpoint(types.FETCH_DOCUMENT_TEMPLATES, 'GET', documentTemplatesRoute)
export const createDocumentTemplateEndpoint = endpoint(types.CREATE_DOCUMENT_TEMPLATE, 'POST', documentTemplatesRoute)
export const getDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.GET_DOCUMENT_TEMPLATE, 'GET', documentTemplateRoute(documentTemplateId))
export const renameDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.RENAME_DOCUMENT_TEMPLATE, 'PUT', documentTemplateRoute(documentTemplateId))
export const deleteDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.DELETE_DOCUMENT_TEMPLATE, 'DELETE', documentTemplateRoute(documentTemplateId))
export const previewDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.PREVIEW_DOCUMENT_TEMPLATE, 'GET', documentTemplatePreviewRoute(documentTemplateId))
export const deactivateDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.DEACTIVATE_DOCUMENT_TEMPLATE, 'PUT', documentTemplateDeactivatedRoute(documentTemplateId))
export const activateDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.ACTIVATE_DOCUMENT_TEMPLATE, 'PUT', documentTemplateActivatedRoute(documentTemplateId))
export const discardDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.DISCARD_DOCUMENT_TEMPLATE, 'PUT', documentTemplateDiscardRoute(documentTemplateId))
export const touchDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.TOUCH_DOCUMENT_TEMPLATE, 'PUT', documentTemplateTouchRoute(documentTemplateId))
export const publishDocumentTemplateEndpoint = documentTemplateId =>
  endpoint(types.PUBLISH_DOCUMENT_TEMPLATE, 'PUT', documentTemplatePublishRoute(documentTemplateId))
