import { createLogic } from 'redux-logic'
import { DateTime } from 'luxon'
import normalize from 'json-api-normalizer'

import { buildCustomBaseUrl } from 'lib/httpClient'
import requestErrorHandler from 'lib/requestErrorHandler'
import { TOGGLE_RECORDING } from 'state/concepts/videoConference/types'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { toggleVideoRecordingsEndpoint } from 'state/concepts/videoConference/endpoints'
import { setIsRecording, addVideoRecordEvents } from 'state/concepts/videoConference/actions'
import { videoConferenceSelector } from 'state/concepts/videoConference/selectors'

const toggleRecording = createLogic({
  type: TOGGLE_RECORDING,
  latest: true,

  async process({ action: { isRecording }, httpClient, getState }, dispatch, done) {
    const state = getState()
    const { uniqCode } = videoConferenceSelector(state)

    const { url, endpoint } = toggleVideoRecordingsEndpoint(uniqCode)

    const params = {
      record: isRecording,
      timestamp: DateTime.utc().toISO(),
    }

    try {
      dispatch(dataApiRequest({ endpoint }))
      const { data } = await httpClient.put(url, params, buildCustomBaseUrl(['workspaces']))
      const response = normalize(data)

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setIsRecording(isRecording))
      dispatch(addVideoRecordEvents(response.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default toggleRecording
