import endpoint from 'utils/endpoint'

import { pendingRegistrationsRoute } from 'lib/apiRoutes'
import { CREATE_PENDING_REGISTRATION } from './types'

// eslint-disable-next-line import/prefer-default-export
export const createPendingRegistrationEndpoint = endpoint(
  CREATE_PENDING_REGISTRATION,
  'POST',
  pendingRegistrationsRoute,
)
