import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiSuccess } from 'state/data/actions'
import { showNotification } from 'state/notifications/actions'
import { ASSIGN_CLIENT_EXPERTS } from 'state/concepts/client/types'
import { assignExpertsEndpoint } from '../endpoints'

const assignExpertsOperation = createLogic({
  type: ASSIGN_CLIENT_EXPERTS,
  latest: true,

  async process({ action: { form, values, clientId, onSuccess }, httpClient }, dispatch, done) {
    const { url, endpoint } = assignExpertsEndpoint(clientId)

    const params = {
      user_profile_ids: values.userProfileIds,
      include: 'user_profiles',
    }

    try {
      const { data } = await httpClient.put(url, params)

      dispatch(dataApiSuccess({ endpoint, response: normalize(data) }))

      const nonUnassignUsers = data.meta.non_unassign_users
      if (nonUnassignUsers.length) {
        dispatch(
          showNotification({
            messageObject: {
              id: 'notifications.clientExpertsWasUnassigned',
              values: { users: nonUnassignUsers.join(', ') },
            },
            kind: 'error',
          }),
        )
      }
      onSuccess()
    } catch (error) {
      requestErrorHandler({ error, dispatch, form })
    }

    form.setSubmitting(false)
    done()
  },
})

export default assignExpertsOperation
