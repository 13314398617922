import { createSelector } from '@reduxjs/toolkit'
import { path, pipe, prop } from 'ramda'

import { dataSelector } from 'state/data/selectors'
import buildCollection from 'utils/buildCollection'

const widgetIdsSelector = path(['sidebarWidgets', 'widgetIds'])
export const paginationSelector = path(['sidebarWidgets', 'pagination'])

export const widgetsSelector = createSelector([widgetIdsSelector, dataSelector], buildCollection('widget'))
export const currentPageSelector = pipe(paginationSelector, prop('number'))
export const isBlankStateSelector = path(['sidebarWidgets', 'isBlankState'])
