import PropTypes from 'prop-types'

import Modal from 'views/shared/Modal'
import Cross from 'views/shared/icons/Cross'
import PreviewPdf from 'views/shared/PreviewPdf'

const TransactionPreviewModalComponent = ({ onClose, pdfUrl }) => (
  <Modal className="main-modal main-modal--invoice" onClose={onClose}>
    <button className="main-modal__close-fixed" type="button" onClick={onClose}>
      <Cross dataCy="cross-icon" />
    </button>
    <PreviewPdf width={720} file={pdfUrl} />
  </Modal>
)

TransactionPreviewModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
}

export default TransactionPreviewModalComponent
