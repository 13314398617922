import { useFormikContext } from 'formik'
import Image from 'next/image'
import { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useUnmount } from 'react-use'
import classNames from 'clsx'

import { ALERT_KINDS } from 'lib/constants'
import { formattedShortDate, formattedTime } from 'utils/dateTime'
import isPresent from 'utils/isPresent'
import Alert from 'views/shared/Alert'
import Button from 'views/shared/Button'
import CloudArrow from 'views/shared/icons/CloudArrow'

const ESignatureDropzone = ({ defaultDisplayedName, currentDate }) => {
  const {
    setFieldValue,
    values: { signatureImage, name },
    errors,
  } = useFormikContext()
  const onDrop = useCallback(
    acceptedFiles => {
      setFieldValue('signatureImage', acceptedFiles[0])
    },
    [setFieldValue],
  )
  const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'image/*': ['.png', '.jpg'] },
    maxSize: 10000000,
  })
  const imgSrc = useMemo(() => signatureImage && URL.createObjectURL(signatureImage), [signatureImage])
  const inputProps = getInputProps()
  const rootProps = getRootProps()

  const handleRemove = () => {
    setFieldValue('signatureImage', null)
  }

  useUnmount(() => imgSrc && URL.revokeObjectURL(imgSrc))

  return (
    <>
      {(isPresent(fileRejections) || errors.file) && (
        <Alert
          className="pl-12 pr-12 mt-16"
          type={ALERT_KINDS.error}
          mainAlertIconClassName="w-24 flex-shrink-0"
          iconSize={20}
          message={{ id: 'eSignature.eSignatureDisclosure.fileUpload.alert' }}
        />
      )}
      {signatureImage ? (
        <>
          <div className="signature p-16 mt-16">
            <div className="signature__element-image mb-16">
              <Image src={imgSrc} alt="signature" width={160} height={36} />
            </div>
            <p className="text-body mb-0 in-blue-gray-300">
              <FormattedMessage
                id="etherpadDocuments.document.signedBy"
                values={{
                  fullName: name || defaultDisplayedName,
                }}
              />
            </p>
            <p className="text-body mb-0 in-blue-gray-300 mt-2">
              <FormattedMessage
                id="etherpadDocuments.document.signedBy.date"
                values={{
                  date: `${formattedShortDate(currentDate)} ${formattedTime(currentDate)}`,
                }}
              />
            </p>
          </div>
          <div className="d-flex column-gap-16 mt-16">
            <Button
              kind="secondary"
              size="small"
              text={{ id: 'eSignature.eSignatureDisclosure.fileUpload.update' }}
              onClick={open}
            />
            <Button kind="flat" size="small" text={{ id: 'shared.remove' }} onClick={handleRemove} />
          </div>
        </>
      ) : (
        <div
          className={classNames('esignature-dropzone p-16 mt-16', {
            'esignature-dropzone--drag-active': isDragActive,
          })}
          {...rootProps}
        >
          <input {...inputProps} />
          <div className="text-center">
            <div className="d-flex align-items-center justify-content-center esignature-dropzone__icon ml-auto mr-auto mb-8">
              <div className="d-flex align-items-center justify-content-center esignature-dropzone__icon-inner">
                <CloudArrow size={24} className="in-blue-600" />
              </div>
            </div>
            <p className="text-caption mb-0">
              <FormattedMessage id="eSignature.eSignatureDisclosure.fileUpload.dropHere" />
            </p>
            <p className="text-caption mb-0 in-gray-700">
              <FormattedMessage id="eSignature.eSignatureDisclosure.fileUpload.validationText" />
            </p>
            <Button
              className="mt-16"
              kind="secondary"
              size="small"
              text={{ id: 'eSignature.eSignatureDisclosure.fileUpload.upload' }}
              onClick={open}
            />
          </div>
        </div>
      )}
    </>
  )
}

ESignatureDropzone.propTypes = {
  defaultDisplayedName: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
}

export default ESignatureDropzone
