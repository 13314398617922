import setCookieIsomorphic from 'utils/setCookieIsomorphic'
import { COOKIES_DOMAIN } from 'lib/constants'

const setEtherpadSession = (ctx, sessionId) => {
  const isLocalhost = COOKIES_DOMAIN === 'localhost'

  if (!sessionId) {
    return
  }

  setCookieIsomorphic(ctx, 'sessionID', sessionId, {
    path: '/',
    secure: true,
    sameSite: isLocalhost ? 'none' : 'lax',
    domain: isLocalhost ? null : COOKIES_DOMAIN,
  })
}

export default setEtherpadSession
