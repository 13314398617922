import { createLogic } from 'redux-logic'
import { last } from 'ramda'

import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess, dataRemoveRelationship } from 'state/data/actions'
import { removeEtherpadDocumentClientAccessEndpoint } from '../endpoints'
import { REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS } from '../types'

const removeEtherpadDocumentClientAccessOperation = createLogic({
  type: REMOVE_ETHERPAD_DOCUMENT_CLIENT_ACCESS,
  latest: true,

  async process({ action: { document }, httpClient }, dispatch, done) {
    const documentPermission = last(document.documentPermissions)
    const documentId = document.id
    const permissionId = documentPermission.id
    const { url, endpoint } = removeEtherpadDocumentClientAccessEndpoint({
      clientId: document.clientProfile.id,
      documentId,
      permissionId,
    })

    dispatch(dataApiRequest({ endpoint }))
    try {
      await httpClient.delete(url)

      dispatch(
        dataRemoveRelationship({
          kind: 'document',
          ownerId: documentId,
          relationship: 'documentPermissions',
          ids: [permissionId],
        }),
      )
      dispatch(dataApiSuccess({ endpoint }))

      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.etherpadDocumentClientAccessWasRemoved',
          },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default removeEtherpadDocumentClientAccessOperation
