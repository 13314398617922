import normalize from 'json-api-normalizer'
import { createLogic } from 'redux-logic'
import build from 'redux-object'

import requestErrorHandler from 'lib/requestErrorHandler'
import { setFormId, setFormSubmissionId } from 'state/concepts/form/actions'
import { fetchClientProfileFormEndpoint } from 'state/concepts/form/endpoints'
import { FETCH_CLIENT_PROFILE_FORM } from 'state/concepts/form/types'
import {
  currentWorkspaceCodeSelector,
  isEmployeeLoggedInSelector,
  isGuestLoggedInSelector,
} from 'state/concepts/session/selectors'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { formsSubmitSuccessRoute } from 'utils/forms'
import redirect from 'utils/redirect'
import { isStatusInSubmissionState } from 'utils/intakeForms'

const fetchClientProfileFormOperation = createLogic({
  type: FETCH_CLIENT_PROFILE_FORM,
  latest: true,

  async process({ action: { id }, httpClient, getState }, dispatch, done) {
    const { url, endpoint } = fetchClientProfileFormEndpoint(id)
    const state = getState()

    const workspaceCode = currentWorkspaceCodeSelector(state)
    const isEmployeeLoggedIn = isEmployeeLoggedInSelector(state)
    const isGuestLoggedIn = isGuestLoggedInSelector(state)

    const params = {
      include: [
        'form.form_questions.form_question_options',
        'form-submission.form_answers',
        'form-submission.form_answers.form_question',
        'form-submission.form_answers.form_question.form_question_options',
        'form-submission.form_answers.form_answer_values.form_question_option',
        'form-submission.client_signature',
        'form-submission.expert_signature',
        'form-submission.client_signature.signed_by',
        'form-submission.client_signature.signed_by',
        'workspace-signature-setting',
        'workspace-signature-setting.signature-disclosure',
      ],
    }

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params })
      const response = normalize(data)
      const { form, status, formSubmissionId } = build(response, 'clientProfileForm', data.data.id)
      const submitted = isStatusInSubmissionState(status)

      if (submitted) {
        redirect({
          href: formsSubmitSuccessRoute({ isEmployeeLoggedIn, isGuestLoggedIn, workspaceCode }),
          workspace: isEmployeeLoggedIn ? workspaceCode : null,
        })
      } else {
        dispatch(dataApiSuccess({ response, endpoint }))
        dispatch(setFormId(form.id))
        dispatch(setFormSubmissionId(formSubmissionId))
      }
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default fetchClientProfileFormOperation
