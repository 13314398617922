import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import isPresent from 'utils/isPresent'
import CompanySection from '../CompanySection'
import BillSection from '../BillSection'
import TableSection from '../TableSection'
import TotalSection from '../TotalSection'

const ReceiptComponent = ({
  amount,
  transactionNumber,
  paymentDate,
  receiptTemplate: { message },
  receiptTemplate,
  clientProfile,
  transactionItems,
  paymentMethod,
}) => (
  <>
    <CompanySection receiptTemplate={receiptTemplate}>
      <p className="preview-invoice__title">
        <FormattedMessage id="payments.review.receiptNumber" values={{ transactionNumber }} />
      </p>
      <div>
        <span className="in-blue-gray-300 mr-8" data-cy="receipt-paid-on-title">
          <FormattedMessage id="payments.review.paidOn" />
        </span>
        <span data-cy="receipt-paid-on-date">{paymentDate}</span>
      </div>
    </CompanySection>
    <div className="divider mb-24 mt-16" />
    <BillSection clientProfile={clientProfile} paymentMethod={paymentMethod} />
    <TableSection transactionItems={transactionItems} />
    <div className="preview-invoice__payment-total-wrap">
      <TotalSection title={{ id: 'payments.review.total.totalAmount' }} amount={amount} />
      <TotalSection className="font-600" title={{ id: 'payments.review.total.paidAmount' }} amount={amount} />
    </div>
    <div className="divider mt-24 mb-24" />
    {isPresent(message) && (
      <div className="main-card__container">
        <p className="mb-0" data-cy="message-on-receipt">
          {message}
        </p>
      </div>
    )}
  </>
)

ReceiptComponent.propTypes = {
  clientProfile: PropTypes.shape().isRequired,
  receiptTemplate: PropTypes.shape().isRequired,
  transactionItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amount: PropTypes.string.isRequired,
  paymentDate: PropTypes.string.isRequired,
  transactionNumber: PropTypes.string.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
}

export default ReceiptComponent
