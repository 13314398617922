import endpoint from 'utils/endpoint'

import {
  publicSubscriptionPlansRoute,
  subscriptionsChangeSubscriptionPlanRoute,
  switchBillingCycleRoute,
  cancelSwitchBillingCycleRoute,
  cancelPlanDowngradeRoute,
} from 'lib/apiRoutes'
import {
  FETCH_CHANGE_SUBSCRIPTION_DETAILS,
  FETCH_SUBSCRIPTION_PLANS,
  SWITCH_BILLING_CYCLE,
  CANCEL_SWITCH_BILLING_CYCLE,
  CANCEL_PLAN_DOWNGRADE,
} from './types'

export const fetchSubscriptionPlansEndpoint = endpoint(FETCH_SUBSCRIPTION_PLANS, 'GET', publicSubscriptionPlansRoute)

export const fetchChangeSubscriptionDetailsEndpoint = endpoint(
  FETCH_CHANGE_SUBSCRIPTION_DETAILS,
  'GET',
  subscriptionsChangeSubscriptionPlanRoute,
)

export const switchBillingCycleEndpoint = endpoint(SWITCH_BILLING_CYCLE, 'POST', switchBillingCycleRoute)

export const cancelSwitchBillingCycleEndpoint = endpoint(
  CANCEL_SWITCH_BILLING_CYCLE,
  'POST',
  cancelSwitchBillingCycleRoute,
)

export const cancelPlanDowngradeEndpoint = endpoint(CANCEL_PLAN_DOWNGRADE, 'POST', cancelPlanDowngradeRoute)
