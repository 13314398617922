import cookies from 'component-cookie'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import removeEmployeeCookies from './removeEmployeeCookies'
import setSharedCookies from './setSharedCookies'

const setCookie = (currentClient, jwt, cookieOptions) => {
  cookies('tokens', JSON.stringify(jwt), cookieOptions)
  cookies('currentClientId', currentClient.id, cookieOptions)
  cookies('currentWorkspaceId', currentClient.workspace.id, cookieOptions)
  cookies('currentWorkspaceCode', currentClient.workspace.uniqCode, cookieOptions)
  cookies('path', cookieOptions.path, cookieOptions)
}

const setClientCookies = (currentClient, jwt, cookieOptions = { path: '/client' }) => {
  setSharedCookies(cookieOptions)
  setCookie(currentClient, jwt, cookieOptions)

  removeEmployeeCookies({ path: COOKIES_PATH })
  setCookie(currentClient, jwt, { path: COOKIES_PATH })
}

export default setClientCookies
