const uploadLogo = {
  'uploadLogo.change': 'Change',
  'uploadLogo.changeImage': 'Change Image',
  'uploadLogo.upload': 'Upload logo',
  'uploadLogo.update': 'Update logo',
  'uploadPhoto.upload': 'Upload photo',
  'uploadLogo.uploadImage': 'Upload image',
  'uploadLogo.editImage': 'Edit image',
  'uploadLogo.remove': 'Remove',
  'uploadLogo.selectImage': 'Select other image',
  'uploadLogo.uploadError': 'Image upload error',
  'uploadLogo.formatSizeError': 'Please use JPG or PNG image smaller than 10MB',
  'uploadLogo.notice': 'JPG or PNG. Max size of 10MB',
  'uploadLogo.deleteImage': 'Delete image',
  'uploadLogo.doYouWantToRemove': 'Do you want to remove company logo? You can add it later.',
}

export default uploadLogo
