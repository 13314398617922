import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const LoadReceipt = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="M435.2 0c0 14.131-11.469 25.6-25.6 25.6S384 14.131 384 0h-51.2c0 14.131-11.469 25.6-25.6 25.6S281.6 14.131 281.6 0h-51.2c0 14.131-11.469 25.6-25.6 25.6S179.2 14.131 179.2 0H128c0 14.131-11.469 25.6-25.6 25.6S76.8 14.131 76.8 0H25.6v460.8c0 28.288 22.912 51.2 51.2 51.2h358.4c28.288 0 51.2-22.912 51.2-51.2V0h-51.2zM281.6 409.6H128v-51.2h153.6v51.2zm0-102.4H128V256h153.6v51.2zM384 409.6h-51.2v-51.2H384v51.2zm0-102.4h-51.2V256H384v51.2zm0-128H128V128h256v51.2zm51.2 281.6H76.8v-384h358.4v384z" />
  </svg>
)

LoadReceipt.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: 'load-receipt-icon',
}

LoadReceipt.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default LoadReceipt
