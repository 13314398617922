import PropTypes from 'prop-types'
import { Field } from 'formik'

import CurrencyField from 'views/shared/CurrencyField'
import CheckboxField from 'views/shared/CheckboxField'

const ProvisionPriceComponent = ({ index, onPriceChange, onIsFreePriceChange }) => (
  <>
    <Field
      name={`provision.${index}.price`}
      component={CurrencyField}
      id={`provision.${index}.price`}
      label={{ id: 'employees.assignServices.pricePerSession' }}
      icon="alert"
      placeholder={{ id: 'employees.assignServices.pricePerSessionPlaceholder' }}
      min={0}
      className="mb-8"
      onChange={onPriceChange}
    />
    <Field
      name={`provision.${index}.isFreePrice`}
      id={`provision.${index}.isFreePrice`}
      component={CheckboxField}
      label={{ id: 'provision.freeSession' }}
      onChange={onIsFreePriceChange}
    />
  </>
)

ProvisionPriceComponent.propTypes = {
  index: PropTypes.number.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onIsFreePriceChange: PropTypes.func.isRequired,
}

export default ProvisionPriceComponent
