import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import Button from 'views/shared/Button'
import CardCustom from 'views/shared/icons/CardCustom'
import UserO from 'views/shared/icons/UserO'
import { durationToFormatString } from 'utils/duration'
import FormattedPriceOrRange from 'views/shared/FormattedPriceOrRange'
import logo from 'assets/images/logo-mob.svg'
import { websiteOrDraftSelector } from 'state/concepts/website/selectors'

const ServiceSidebar = ({ onClose, duration, activeProvidersCount, priceMin, priceMax, description, name }) => {
  const {
    widget: { color },
  } = useSelector(websiteOrDraftSelector)

  return (
    <div className="widget-sidebar" data-cy="widget-sidebar">
      <div className="widget-sidebar__close-btn">
        <Button onClick={onClose} className="main-btn--icon-only" kind="text" icon="cross" iconSize={8} />
      </div>
      <div className="widget-sidebar__wrap">
        <div className="widget-sidebar__header main-header">
          <Image src={logo} className="border-radius-wrap" alt="logo" width={40} height={40} />
        </div>
        <div className="widget-sidebar__body">
          <div className="widget-sidebar__info pt-24 pb-24 bg-white">
            <div className="widget-sidebar__container text-left">
              <p className="text-title font-600 mb-4" data-cy="service-name">
                {name}
              </p>
              <p className="expert-info__gray mb-12" data-cy="service-duration">
                {durationToFormatString(duration, { isLong: true, omitZeroes: true })}
              </p>
              <div className="d-flex align-items-center mb-12">
                <UserO size={18} color={color} dataCy="user-icon" />
                <span className="ml-8 expert-info__gray text-subheader" data-cy="service-experts-available">
                  <span className="font-600 mr-4">{activeProvidersCount}</span>
                  <FormattedMessage
                    values={{ count: activeProvidersCount }}
                    id="website.page.services.serviceSidebar.expertsAvailable"
                  />
                </span>
              </div>
              <div className="d-flex align-items-center mb-12">
                <CardCustom size={20} color={color} dataCy="card-icon" />
                <span className="ml-8 expert-info__gray text-subheader font-600" data-cy="service-price">
                  <FormattedPriceOrRange priceMax={priceMax} priceMin={priceMin} />
                </span>
              </div>
              <p className="expert-info__gray mb-0 text-subheader" data-cy="service-description">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ServiceSidebar.defaultProps = {
  description: '',
}

ServiceSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  activeProvidersCount: PropTypes.number.isRequired,
  priceMin: PropTypes.string.isRequired,
  priceMax: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default ServiceSidebar
