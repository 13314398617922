import { createLogic } from 'redux-logic'

import { COOKIES_PATH } from 'lib/constants/videoConference'
import removeEmployeeCookies from 'utils/removeEmployeeCookies'
import redirect from 'utils/redirect'
import { EMPLOYEE_POST_LOGOUT } from 'state/concepts/session/types'
import { EMPLOYEE_LOGOUT_EVENT } from 'lib/constants/broadcastChannel'
import { broadcastMessage } from 'lib/broadcastChannel'
import { currentWorkspaceSelector, broadcastChannelNamespaceSelector } from 'state/concepts/session/selectors'
import { setIsLoggedOutDueToInactivity } from 'state/concepts/login/actions'
import { closeConnection } from 'state/concepts/webSockets/actions'
import { isOpenedConnectionSelector } from 'state/concepts/webSockets/selectors'
import { hideModal } from 'state/modal/actions'
import { APP_HOST, COOKIES_DOMAIN } from 'lib/constants'

const employeePostLogoutOperation = createLogic({
  type: EMPLOYEE_POST_LOGOUT,
  latest: true,

  async process({ action: { isInactive, withoutBroadcast }, getState }, dispatch, done) {
    const state = getState()
    const isOpenedConnection = isOpenedConnectionSelector(state)
    const currentWorkspace = currentWorkspaceSelector(state)
    const namespace = broadcastChannelNamespaceSelector(state)

    removeEmployeeCookies({ path: `/${currentWorkspace.uniqCode}` })
    removeEmployeeCookies({ path: `/${currentWorkspace.uniqCode}`, domain: COOKIES_DOMAIN })
    removeEmployeeCookies({ path: COOKIES_PATH })

    if (isInactive) {
      dispatch(setIsLoggedOutDueToInactivity(true))
    }

    redirect({ href: `${APP_HOST}/login`, reload: true, skipDiscard: true })
    dispatch(hideModal())
    if (isOpenedConnection) {
      dispatch(closeConnection())
    }
    if (!withoutBroadcast) {
      broadcastMessage(`${namespace}/${EMPLOYEE_LOGOUT_EVENT}`)
    }
    done()
  },
})

export default employeePostLogoutOperation
