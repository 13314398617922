import { createLogic } from 'redux-logic'

import updateDataHelper from 'utils/updateDataHelper'
import { showNotification } from 'state/notifications/actions'
import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { EDIT_NOTE_ACCESSIBILITY } from 'state/concepts/companyClientNotes/types'
import { makeNotePersonalEndpoint, shareNoteEndpoint } from 'state/concepts/companyClientNotes/endpoints'
import { isPrivate } from 'utils/clientNote'

const editNoteAccessibility = createLogic({
  type: EDIT_NOTE_ACCESSIBILITY,
  latest: true,

  async process({ getState, httpClient, action: { noteId, clientId, accessibility } }, dispatch, done) {
    const { endpoint, url } = isPrivate({ accessibility })
      ? makeNotePersonalEndpoint(clientId, noteId)
      : shareNoteEndpoint(clientId, noteId)

    dispatch(dataApiRequest({ endpoint }))

    try {
      await httpClient.post(url)

      const response = updateDataHelper(getState().data, 'clientNote', noteId, {
        attributes: {
          accessibility,
        },
      })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(
        showNotification({
          messageObject: { id: `notifications.accessibilityUpdated.${accessibility}` },
        }),
      )
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default editNoteAccessibility
