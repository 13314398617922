export const DISABLE_ACCEPT_VIDEO_CALLS_ACTIONS = {
  stopOnline: 'stopOnline',
  moveToOffline: 'moveToOffline',
}

export const DISABLE_ACCEPT_VIDEO_CALLS_OPTIONS = [
  {
    value: DISABLE_ACCEPT_VIDEO_CALLS_ACTIONS.stopOnline,
    label: { id: 'acceptVideoCallsFeature.disableModalOption.stopOnline' },
  },
  {
    value: DISABLE_ACCEPT_VIDEO_CALLS_ACTIONS.moveToOffline,
    label: { id: 'acceptVideoCallsFeature.disableModalOption.moveToOffline' },
  },
]

export const VIDEO_CALLS_PROVIDERS = {
  expertbox: 'expertbox',
  googleMeet: 'google_meet',
  zoom: 'zoom',
  microsoftTeams: 'microsoft_teams',
}

export const APPOINTMENT_INVITED_MEMBERS_LIMIT = 10
export const APPOINTMENT_INVITED_MEMBERS_VISIBLE_LIMIT = 6

export const DEFAULT_APPOINTMENT_MEMBERS_COUNT = 2
