import acceptBooking from './acceptBooking'
import cancelBooking from './cancelBooking'
import declineAllBookings from './declineAllBookings'
import declineBooking from './declineBooking'
import fetchBookingRecurrence from './fetchBookingRecurrence'
import fetchPendingForms from './fetchPendingForms'
import rescheduleBooking from './rescheduleBooking'
import validateCancelBooking from './validateCancelBooking'
import validateRescheduleBooking from './validateRescheduleBooking'
import bookAppointmentFromWaitlistRequest from './bookAppointmentFromWaitlistRequest'

export default [
  validateRescheduleBooking,
  rescheduleBooking,
  acceptBooking,
  declineBooking,
  declineAllBookings,
  fetchPendingForms,
  validateCancelBooking,
  cancelBooking,
  fetchBookingRecurrence,
  bookAppointmentFromWaitlistRequest,
]
