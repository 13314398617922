import { createLogic } from 'redux-logic'

import { fetchForms } from '../actions'
import { RECEIVE_ACCOUNT_FORM } from '../types'

const receiveAccountFormOperation = createLogic({
  type: RECEIVE_ACCOUNT_FORM,
  latest: true,

  process({ action: { data } }, dispatch, done) {
    const {
      data: {
        attributes: { 'form-type': formType },
      },
    } = data

    dispatch(fetchForms({ formType }))
    done()
  },
})

export default receiveAccountFormOperation
