import * as types from './types'

export const fetchWorkspaceFeatures = () => ({
  type: types.FETCH_WORKSPACE_FEATURES,
})

export const setWorkspaceFeaturesIds = ids => ({
  type: types.SET_WORKSPACE_FEATURES_IDS,
  ids,
})

export const updateWorkspaceFeatureActivation = (
  featureKey,
  isEnable,
  params,
  form,
  closeModal,
  setErrorStatusUnprocessableEntity,
) => ({
  type: types.UPDATE_WORKSPACE_FEATURE_ACTIVATION,
  featureKey,
  isEnable,
  params,
  form,
  closeModal,
  setErrorStatusUnprocessableEntity,
})
