const namespace = 'billing'

export const FETCH_BILLING_DETAIL = `${namespace}/FETCH_BILLING_DETAIL`
export const SET_BILLING_DETAIL_ID = `${namespace}/SET_BILLING_DETAIL_ID`
export const FETCH_SUBSCRIPTION_TRANSACTIONS = `${namespace}/FETCH_SUBSCRIPTION_TRANSACTIONS`
export const SET_SUBSCRIPTION_TRANSACTIONS = `${namespace}/SET_SUBSCRIPTION_TRANSACTIONS`
export const SET_ACTIVE_SUBSCRIPTION_TRANSACTION_ID = `${namespace}/SET_ACTIVE_SUBSCRIPTION_TRANSACTION_ID`
export const SET_SUBSCRIPTION_TRANSACTIONS_PAGE = `${namespace}/SET_SUBSCRIPTION_TRANSACTIONS_PAGE`
export const GET_BILLING_ACCOUNT = `${namespace}/GET_BILLING_ACCOUNT`
export const SET_BILLING_ACCOUNT = `${namespace}/SET_BILLING_ACCOUNT`
export const SET_BILLING_ACCOUNT_ID = `${namespace}/SET_BILLING_ACCOUNT_ID`
export const CREATE_SETUP_INTENT = `${namespace}/CREATE_SETUP_INTENT`
export const SET_SETUP_INTENT_CLIENT_SECRET = `${namespace}/SET_SETUP_INTENT_CLIENT_SECRET`
export const SET_STRIPE_PUBLISHABLE_API_KEY = `${namespace}/SET_STRIPE_PUBLISHABLE_API_KEY`
export const UPDATE_PAYMENT_METHOD = `${namespace}/UPDATE_PAYMENT_METHOD`
export const UPDATE_TEAM_SEATS = `${namespace}/UPDATE_TEAM_SEATS`
export const CANCEL_DOWNGRADE_SEATS = `${namespace}/CANCEL_DOWNGRADE_SEATS`
export const DOWNLOAD_SUBSCRIPTION_TRANSACTION = `${namespace}/DOWNLOAD_SUBSCRIPTION_TRANSACTION`
export const RETRY_PAYMENT = `${namespace}/RETRY_PAYMENT`
export const RENEW_SUBSCRIPTION = `${namespace}/RENEW_SUBSCRIPTION`
export const UPDATE_BILLING_ADDRESS = `${namespace}/UPDATE_BILLING_ADDRESS`
export const CANCEL_DOWNGRADE_STORAGE = `${namespace}/CANCEL_DOWNGRADE_STORAGE`
export const RESET_SUBSCRIPTION_TRANSACTIONS = `${namespace}/RESET_SUBSCRIPTION_TRANSACTIONS`
export const REMOVE_SUBSCRIPTION_TRANSACTIONS = `${namespace}/REMOVE_SUBSCRIPTION_TRANSACTIONS`
