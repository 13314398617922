import { createLogic } from 'redux-logic'
import normalize from 'json-api-normalizer'

import requestErrorHandler from 'lib/requestErrorHandler'
import { dataApiRequest, dataApiSuccess } from 'state/data/actions'
import { setEmployeeAccountNotificationSettings } from 'state/concepts/notificationSettings/actions'
import { FETCH_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS } from 'state/concepts/notificationSettings/types'
import { fetchEmployeeAccountNotificationSettingsEndpoint } from '../endpoints'

const fetchEmployeeAccountNotificationSettingsOperation = createLogic({
  type: FETCH_EMPLOYEE_ACCOUNT_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ httpClient }, dispatch, done) {
    const { endpoint, url } = fetchEmployeeAccountNotificationSettingsEndpoint

    dispatch(dataApiRequest({ endpoint }))

    try {
      const { data } = await httpClient.get(url, { params: { include: 'sms-notification-settings' } })
      const response = normalize(data, { endpoint })

      dispatch(dataApiSuccess({ response, endpoint }))
      dispatch(setEmployeeAccountNotificationSettings(data.data.id))
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }

    done()
  },
})

export default fetchEmployeeAccountNotificationSettingsOperation
