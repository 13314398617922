import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { pageCountSelector, currentPageSelector } from 'state/data/selectors'
import { getClients as getClientsAction } from 'state/concepts/filters/actions'
import { getClientsEndpoint } from 'state/concepts/filters/endpoints'
import Spinner from 'views/shared/icons/Spinner'

class Loader extends React.Component {
  static propTypes = {
    getClients: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }

  static defaultProps = {
    isLoading: undefined,
    currentPage: 1,
    pageCount: 1,
  }

  handleLoadMore = () => {
    const { getClients, currentPage, pageCount, searchQuery } = this.props

    if (pageCount >= currentPage + 1) {
      getClients({ searchQuery, page: currentPage + 1 })
    }
  }

  render() {
    const { isLoading } = this.props

    if (isLoading !== false) {
      return <Spinner size={18} />
    }

    return <Waypoint onEnter={this.handleLoadMore} bottomOffset="-100px" />
  }
}

const mapStateToProps = state => {
  const { endpoint } = getClientsEndpoint

  return {
    pageCount: pageCountSelector(state, endpoint),
    currentPage: currentPageSelector(state, endpoint),
  }
}

const mapDispatchToProps = {
  getClients: getClientsAction,
}

export { Loader as LoaderContainer }
export default connect(mapStateToProps, mapDispatchToProps)(Loader)
