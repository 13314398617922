import classNames from 'clsx'
import PropTypes from 'prop-types'

import isPresent from 'utils/isPresent'
import Recording from './Recording'

const Recordings = ({
  booking,
  onDelete,
  booking: { videoRecords },
  onShowPreview,
  canRemove,
  canManage,
  onConfirmRemove,
  isAppointmentDetails,
}) =>
  isPresent(videoRecords) ? (
    <div
      className={classNames('d-flex flex-column gap-16', {
        'booking-details__records': !isAppointmentDetails,
      })}
    >
      {videoRecords.map(videoRecord => (
        <Recording
          videoRecord={videoRecord}
          key={videoRecord.id}
          booking={booking}
          onDelete={onDelete}
          onShowPreview={onShowPreview}
          canRemove={canRemove}
          canManage={canManage}
          onConfirmRemove={onConfirmRemove}
          isAppointmentDetails={isAppointmentDetails}
        />
      ))}
    </div>
  ) : null

Recordings.defaultProps = {
  onDelete: undefined,
  isAppointmentDetails: false,
}

Recordings.propTypes = {
  booking: PropTypes.shape({
    videoRecords: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onShowPreview: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  onConfirmRemove: PropTypes.func.isRequired,
  isAppointmentDetails: PropTypes.bool,
}

export default Recordings
