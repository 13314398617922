import { createSelector } from '@reduxjs/toolkit'
import { path } from 'ramda'

import { PAGINATION_ITEMS_PER_PAGE } from 'lib/constants/tableData'

const paginationSelector = path(['tableData', 'pagination'])
const orderDirectionsSelector = path(['tableData', 'orderDirections'])

export const tablePaginationSelector = createSelector(
  [paginationSelector, (_, entity) => entity],
  (pagination, entity) => ({
    number: pagination[entity] || 1,
    size: PAGINATION_ITEMS_PER_PAGE,
  }),
)

export const tableOrderDirectionsSelector = createSelector(
  [orderDirectionsSelector, (_, entity) => entity],
  (orderDirections, entity) => orderDirections[entity] || {},
)
