import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Spinner from 'views/shared/icons/Spinner'
import Invite from 'views/shared/icons/Invite'
import MemberCard from './MemberCard'

const MembersListComponent = ({
  isLoading,
  isCurrentUserChatOwner,
  chatMembers,
  chat,
  isChatActive,
  onOpenAddChatMembers,
  manageChatMembers,
}) => {
  if (isLoading !== false) {
    return <Spinner />
  }

  return (
    <>
      {manageChatMembers && (
        <div className="pt-8 pb-8 pl-24 pr-24">
          <button
            className="d-flex align-items-center"
            type="button"
            dataCy="add-chat-members"
            onClick={onOpenAddChatMembers}
            disabled={!isChatActive}
          >
            <span className="invite-btn__icon">
              <Invite size={18} className="in-blue-600" />
            </span>
            <span className="ml-12 in-blue-600 font-600">
              <FormattedMessage id="chat.actions.addMembers" />
            </span>
          </button>
        </div>
      )}
      {chatMembers.map(chatMember => (
        <MemberCard
          key={`${chatMember.memberType}-${chatMember.id}`}
          chatMember={chatMember}
          isCurrentUserChatOwner={isCurrentUserChatOwner}
          chat={chat}
          manageChatMembers={manageChatMembers}
        />
      ))}
    </>
  )
}

MembersListComponent.defaultProps = {
  isLoading: undefined,
  chatMembers: [],
}

MembersListComponent.propTypes = {
  isLoading: PropTypes.bool,
  isCurrentUserChatOwner: PropTypes.bool.isRequired,
  isChatActive: PropTypes.bool.isRequired,
  chatMembers: PropTypes.arrayOf(PropTypes.shape()),
  chat: PropTypes.shape().isRequired,
  onOpenAddChatMembers: PropTypes.func.isRequired,
  manageChatMembers: PropTypes.bool.isRequired,
}

export default MembersListComponent
