import makeFormSubmitAction from 'utils/form/makeFormSubmitAction'
import * as types from './types'

export const fetchCalendarEvents = () => ({
  type: types.FETCH_CALENDAR_EVENTS,
})

export const setCalendarEvents = Ids => ({
  type: types.SET_CALENDAR_EVENTS,
  Ids,
})

export const addCalendarEvent = id => ({
  type: types.ADD_CALENDAR_EVENT,
  id,
})

export const setFilterParams = filterParams => ({
  type: types.SET_FILTER_PARAMS,
  filterParams,
})

export const filterCalendarEvents = filters => ({
  type: types.FILTER_CALENDAR_EVENTS,
  filters,
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
})

export const setBookingsDate = date => ({
  type: types.SET_BOOKINGS_DATE,
  date,
})

export const setTimezone = timezone => ({
  type: types.SET_TIMEZONE,
  timezone,
})

export const setBookingsView = view => ({
  type: types.SET_BOOKINGS_VIEW,
  view,
})

export const cancelBooking = makeFormSubmitAction(types.CANCEL_BOOKING)

export const validateCancelBooking = uniqCode => ({
  type: types.VALIDATE_CANCEL_BOOKING,
  uniqCode,
})

export const setCancellationRules = ({
  time,
  description,
  bookingRefundAmount,
  refundAmount,
  refundCount,
  validateCancellationReason,
}) => ({
  type: types.SET_CANCELLATION_RULES,
  description,
  time,
  bookingRefundAmount,
  refundAmount,
  refundCount,
  validateCancellationReason,
})

export const resetCancellationRules = () => ({
  type: types.RESET_CANCELLATION_RULES,
})

export const setCancellationError = error => ({
  type: types.SET_CANCELLATION_ERROR,
  error,
})

export const validateRescheduleBooking = uniqCode => ({
  type: types.VALIDATE_RESCHEDULE_BOOKING,
  uniqCode,
})

export const setRescheduleError = error => ({
  type: types.SET_RESCHEDULE_ERROR,
  error,
})

export const setBlankState = isBlankState => ({
  type: types.SET_BOOKINGS_BLANK_STATE,
  isBlankState,
})

export const checkTimeConflicts = (filters, setCheckStatus, isRepeat) => ({
  type: types.CHECK_TIME_CONFLICTS,
  filters,
  setCheckStatus,
  isRepeat,
})

export const setBookingForm = ({ initialValues, values, id }) => ({
  type: types.SET_BOOKING_FORM,
  initialValues,
  values,
  id,
})

export const resetBookingForm = () => ({
  type: types.RESET_BOOKING_FORM,
})
