import { createLogic } from 'redux-logic'

import requestErrorHandler from 'lib/requestErrorHandler'
import { showNotification } from 'state/notifications/actions'
import { hideModal } from 'state/modal/actions'
import { dataApiSuccess, dataApiRequest } from 'state/data/actions'
import { discardDocumentTemplateEndpoint } from 'state/concepts/documentTemplates/endpoints'
import redirect from 'utils/redirect'
import { DISCARD_DOCUMENT_TEMPLATE } from 'state/concepts/documentTemplates/types'
import { companySettingsDocumentTemplatesRoute } from 'lib/routes'
import { currentWorkspaceCodeSelector } from 'state/concepts/session/selectors'

const discardDocumentTemplateOperation = createLogic({
  type: DISCARD_DOCUMENT_TEMPLATE,
  latest: true,

  async process({ httpClient, action: { documentTemplateId }, getState }, dispatch, done) {
    const { url, endpoint } = discardDocumentTemplateEndpoint(documentTemplateId)
    const workspaceCode = currentWorkspaceCodeSelector(getState())

    try {
      dispatch(dataApiRequest({ endpoint }))
      await httpClient.put(url)

      dispatch(dataApiSuccess({ endpoint }))
      dispatch(hideModal())
      dispatch(
        showNotification({
          messageObject: {
            id: 'notifications.documentTemplateDiscarded',
          },
        }),
      )
      redirect({ href: companySettingsDocumentTemplatesRoute, workspace: workspaceCode })
    } catch (error) {
      requestErrorHandler({ error, dispatch, endpoint })
    }
    done()
  },
})

export default discardDocumentTemplateOperation
