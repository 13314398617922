import { combineReducers } from '@reduxjs/toolkit'

import { SET_WORKSPACE_FEATURES_IDS } from './types'

const ids = (state = null, action) => {
  switch (action.type) {
    case SET_WORKSPACE_FEATURES_IDS:
      return action.ids
    default:
      return state
  }
}

const workspaceFeaturesReducer = combineReducers({
  ids,
})

export default workspaceFeaturesReducer
