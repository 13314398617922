import * as yup from 'yup'

import yupLocale from 'locales/yup'
import {
  isDurationString,
  durationMin,
  durationMax,
  durationInUnitMin,
  durationInUnitMax,
  secondsPerTimeUnitMin,
  secondsPerTimeUnitMax,
} from './validations/duration'
import { isHexColor, isSelector } from './validations/css'
import { isWorkspaceUrl, passwordMinLength, passwordValidation, passwordNameInclusion } from './validations/workspace'
import { priceMin } from './validations/service'
import { phoneNumber } from './validations/companyProfile'
import { textMaxLength, hasText } from './validations/reactDraftEditor'
import { fileSize, fileType, formAttachment } from './validations/file'
import { isNotUrl } from './validations/tasks'
import { requiredWith } from './validations/requiredWith'
import { email } from './validations/email'

yup.setLocale(yupLocale)

yup.addMethod(yup.string, 'isDurationString', isDurationString)
yup.addMethod(yup.string, 'durationMin', durationMin)
yup.addMethod(yup.string, 'durationMax', durationMax)
yup.addMethod(yup.number, 'durationInUnitMin', durationInUnitMin)
yup.addMethod(yup.number, 'durationInUnitMax', durationInUnitMax)
yup.addMethod(yup.string, 'isHexColor', isHexColor)
yup.addMethod(yup.string, 'isSelector', isSelector)
yup.addMethod(yup.string, 'isWorkspaceUrl', isWorkspaceUrl)
yup.addMethod(yup.string, 'secondsPerTimeUnitMin', secondsPerTimeUnitMin)
yup.addMethod(yup.string, 'secondsPerTimeUnitMax', secondsPerTimeUnitMax)
yup.addMethod(yup.number, 'priceMin', priceMin)
yup.addMethod(yup.string, 'phoneNumber', phoneNumber)
yup.addMethod(yup.object, 'textMaxLength', textMaxLength)
yup.addMethod(yup.object, 'hasText', hasText)
yup.addMethod(yup.mixed, 'fileSize', fileSize)
yup.addMethod(yup.mixed, 'fileType', fileType)
yup.addMethod(yup.string, 'isNotUrl', isNotUrl)
yup.addMethod(yup.string, 'requiredWith', requiredWith)
yup.addMethod(yup.string, 'email', email)
yup.addMethod(yup.object, 'formAttachment', formAttachment)
yup.addMethod(yup.string, 'passwordValidation', passwordValidation)
yup.addMethod(yup.string, 'passwordMinLength', passwordMinLength)
yup.addMethod(yup.string, 'passwordNameInclusion', passwordNameInclusion)

export default yup
